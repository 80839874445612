import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

interface OperationLog {
  logDate: string;
  functionName: string;
  endStatus: string;
  normalError: string;
  fatalError: string;
  userId: string;
  requestJson: string;
  request: string;
  className: string;
}

@Component({
  selector: 'app-operation-log',
  templateUrl: './operation-log.component.html',
  styleUrls: ['./operation-log.component.css']
})
export class OperationLogComponent implements OnInit, OnDestroy {

  public dateBeginForm: FormControl = new FormControl();
  public dateEndForm: FormControl = new FormControl();
  public functionNameForm: FormControl = new FormControl("");
  public endStatusForm: FormControl = new FormControl("");
  public requestForm: FormControl = new FormControl("");

  public logData: OperationLog[];
  public selectedLog: OperationLog;

  public columnIds: string[] = ["logDate", "functionName", "endStatus", "userId", "normalError", "fatalError"];
  public columnDefs: TableColumnDef[] = [
    {columnId:'logDate', header:"日時", width:120, align:"center"},
    {columnId:'functionName', header:"画面名", width:100},
    {columnId:'endStatus', header:"終了状態", width:40},
    {columnId:'userId', header:"ユーザー", width:40},
    {columnId:'normalError', header:"エラー", width:60},
    {columnId:'fatalError', header:"異常", width:60}
  ];

  private subscriptionDateBegin: Subscription;
  private subscriptionDateEnd: Subscription;
  public maxDate: Date;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
    ) { }

  ngOnInit(): void {
    // this.commonService.pageTitle = "操作ログ";
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.maxDate = new Date();

    this.dateEndForm.setValue(this.commonService.copyDate(this.maxDate));
    let begin = new Date();
    begin.setDate(this.maxDate.getDate() - 7);
    this.dateBeginForm.setValue(begin);
  }

  ngOnDestroy() {
    if (this.subscriptionDateBegin) this.subscriptionDateBegin.unsubscribe();
    if (this.subscriptionDateEnd) this.subscriptionDateEnd.unsubscribe();
  }

  getDateBeginMax() {
    let max: Date;
    (this.dateEndForm.value)
      ? max = this.commonService.copyDate(this.dateEndForm.value)
      : max = this.commonService.copyDate(this.maxDate);

    max.setDate(max.getDate() - 1);
    return max;
  }

  getDateEndMax() {
    let max: Date;
    (this.dateEndForm.value)
      ? max = this.commonService.copyDate(this.dateEndForm.value)
      : max = this.commonService.copyDate(this.maxDate);

    max.setDate(max.getDate() - 1);
    return max;
  }

  styleForLog(id:string) {
    return this.styleFor(id, this.columnDefs);
  }
  styleForHeaderLog(id:string) {
    return this.styleForHeader(id, this.columnDefs);
  }

  styleFor(id:string, columnDefs:TableColumnDef[]) {
    for (var colDef of columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id:string, columnDefs:TableColumnDef[]) {
    for (var colDef of columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  clickLog(colDef: TableColumnDef, item: OperationLog) {
    this.selectedLog = item;
  }

  queryLog() {
    this.selectedLog = undefined;
    let request: ReqExecuteQuery = {
      access: {...this.commonService.loginUser},
      queryId: "operationLog/getLog",
      bindVariables: [
        this.commonService.formatDate(this.dateBeginForm.value),
        this.commonService.formatDate(this.dateEndForm.value),
        this.functionNameForm.value,
        this.endStatusForm.value,
        this.requestForm.value
      ]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    const subsc = this.httpBasic.executeQuery(request).subscribe(
      (response: RspExecuteQuery) => {
        subsc.unsubscribe();
        this.receiveByVendor(response);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveByVendor(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.logData = [];
    for (let row of response.rows) {
      let request;
      if (row.colData[6] === "") {
        request =  "";
      } else {
        request =  JSON.stringify(JSON.parse(row.colData[6]), null, "  ");
      }
      let log: OperationLog = {
        logDate: row.colData[0],
        functionName: row.colData[1],
        endStatus: row.colData[2],
        normalError: row.colData[3],
        fatalError: row.colData[4],
        userId: row.colData[5],
        requestJson: row.colData[6],
        request: request,
        className: row.colData[7]
      };
      this.logData.push(log);
    }
  }


}
