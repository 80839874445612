import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Rsp } from 'src/app/response/rsp';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

export interface TaskStatusResponsible {
  isTotal:        boolean;
  storeCd:        string;
  storeName:      string;
  responsible:    string;
  toToday:        number;
  notComplete:    number;
  expired:        number;
  toTodaySub:     number;
  notCompleteSub: number;
  expiredSub:     number;
}

export interface ResTaskDashboardStore extends Rsp {
  statusList: TaskStatusResponsible[];
}

@Component({
  selector: 'app-task-dashboard-store',
  templateUrl: './task-dashboard-store.component.html',
  styleUrls: ['./task-dashboard-store.component.css']
})
export class TaskDashboardStoreComponent implements OnInit, OnDestroy {

  public taskStatusList: TaskStatusResponsible[] = [];
  public columnIds: string[] = ["responsible", "toToday", "notComplete", "expired"];
  public columnDefs: TableColumnDef[] = [
    {columnId: "responsible", header: "担当者", width: 150},
    {columnId: "toToday", header: "本日期限", width: 100, align: "center"},
    {columnId: "notComplete", header: "未完了", width: 100, align: "center"},
    {columnId: "expired", header: "期限切れ", width: 100, align: "center"},
  ];

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private router : Router
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  @HostListener('window:loginComplete', ['$event'])
  handleLoginComplete() {
    if (this.commonService.hasMenu("taskHq") === false && this.commonService.hasMenu("taskStore") === true) {
      this.getStatusList();
    }
  }

  isOpen() {
    if (!this.commonService.openDashboardHq) return false;
    if (this.commonService.menuGroups === undefined) return false;
    if (this.commonService.hasMenu("taskHq") === true) return false;
    if (this.commonService.hasMenu("taskStore") === false) return false;
    return true;
  }

  closeDashboard() {
    this.commonService.openDashboardHq = false;
    window.dispatchEvent(new Event('resize'));
  }

  reSearchDashboard() {
    this.getStatusList();
  }

  onClickCell(item: TaskStatusResponsible, key: string) {
    if (key === "store") return;
    if (!this.commonService.hasMenu("taskStore")) return;

    if (key === "toToday") {
      this.commonService.taskSearchCond = {
        storeCd:                item.storeCd,
        releaseStatus:          "公開済",
        author:                 "",
        statusNotAssigned:      true,
        statusNotStarted:       true,
        statusStarted:          true,
        statusCompleted:        true,
        endDate:                true,
        endDateFrom:            item.isTotal ? 0 : this.commonService.config.task.searchCond.endDateFrom,
        endDateTo:              item.isTotal ? 0 : this.commonService.config.task.searchCond.endDateTo,
        endDateFromDay:         0,
        endDateToDay:           0,
        responsibleClass:       "",
        responsible:            "",
        responsiblePartial:     true,
        taskCategory:           "",
        taskName:               "",
        subStatusNotCompleted:  true,
        subStatusCompleted:     true,
        subDateYesterday:       item.isTotal ? true: false,
        subDateToday:           true,
        subDateTommorow:        item.isTotal ? true : false,
        subResponsible:         item.isTotal ? "" : item.responsible,
        subResponsiblePartial:  item.isTotal ? true : false
      }
    } else if (key === "notComplete") {
      this.commonService.taskSearchCond = {
        storeCd:                item.storeCd,
        releaseStatus:          "公開済",
        author:                 "",
        statusNotAssigned:      true,
        statusNotStarted:       true,
        statusStarted:          true,
        statusCompleted:        false,
        endDate:                true,
        endDateFrom:            this.commonService.config.task.searchCond.endDateFrom,
        endDateTo:              this.commonService.config.task.searchCond.endDateTo,
        endDateFromDay:         0,
        endDateToDay:           0,
        responsibleClass:       "",
        responsible:            "",
        responsiblePartial:     true,
        taskCategory:           "",
        taskName:               "",
        subStatusNotCompleted:  true,
        subStatusCompleted:     item.isTotal ? true : false,
        subDateYesterday:       true,
        subDateToday:           true,
        subDateTommorow:        true,
        subResponsible:         item.isTotal ? "" : item.responsible,
        subResponsiblePartial:  item.isTotal ? true : false
      }
    } else if (key === "expired") {
      this.commonService.taskSearchCond = {
        storeCd:                item.storeCd,
        releaseStatus:          "公開済",
        author:                 "",
        statusNotAssigned:      true,
        statusNotStarted:       true,
        statusStarted:          true,
        statusCompleted:        false,
        endDate:                true,
        endDateFrom:            this.commonService.config.task.searchCond.endDateFrom,
        endDateTo:              item.isTotal ? 0 : this.commonService.config.task.searchCond.endDateTo,
        endDateFromDay:         0,
        endDateToDay:           item.isTotal ? -1 : 0,
        responsibleClass:       "",
        responsible:            "",
        responsiblePartial:     true,
        taskCategory:           "",
        taskName:               "",
        subStatusNotCompleted:  true,
        subStatusCompleted:     item.isTotal ? true : false,
        subDateYesterday:       true,
        subDateToday:           item.isTotal ? true : false,
        subDateTommorow:        item.isTotal ? true : false,
        subResponsible:         item.isTotal ? "" : item.responsible,
        subResponsiblePartial:  item.isTotal ? true : false
      }
    }

    this.commonService.openDashboardHq = false;
    this.commonService.openDashboardStore = false;
    this.commonService.openNotification = false;

    let menuItem = this.getMenuItem("taskStore");
    if (menuItem) {
      this.commonService.pageMenuName = menuItem.menuName;
      this.router.navigate(["taskStore"], {queryParams: {rn: Math.random()}});
    }
  }

  getMenuItem(path: string) {
    for (let menuGroup of this.commonService.menuGroups) {
      for (let menuItem of menuGroup.menuItems) {
        if (path === menuItem.menuPath) return menuItem;
      }
    }
    return undefined;
  }

  getStatusList() {
    let req = {
      access: this.commonService.loginUser,
      endDateFrom: this.commonService.config.task.searchCond.endDateFrom,
      endDateTo:   this.commonService.config.task.searchCond.endDateTo,
      isResponsibleUser: this.commonService.config.task.responsibleIsLoginUser
    };
    this.httpBasic.generalRequest("TaskDashboardStore", req).subscribe(
      (response: ResTaskDashboardStore) => {
        this.receiveTaskDashboardStore(response);
      },
      (error) => {
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveTaskDashboardStore(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.taskStatusList = [];
    response.statusList.forEach(row => this.taskStatusList.push(row));
  }

  getValue(item: TaskStatusResponsible, key: string) {
    if (item.isTotal) {
      if (key === "responsible") return item.storeCd + "：" + item.storeName + " 計";
      return item[key];
    }
    if (key === "responsible") return item[key];
    // return "" + item[key] + "：" + item[key + "Sub"];
    return "" + item[key + "Sub"];
  }
}
