
import { Component, OnInit, OnDestroy, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { ItemRec } from 'src/app/common/item-rec';
import { ItemSimpleRec } from 'src/app/common/item-simple-rec';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { ItemListSimpleComponent } from 'src/app/partsCommon/item-list-simple/item-list-simple.component';
import { ItemSelectSimpleCondition } from 'src/app/partsCommon/item-select-simple/item-select-simple.component';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { ParamItemEditDto, ReqAllStoreParamItemEdit } from 'src/app/request/req-all-store-param-item-edit';
import { ReqAllStoreParamItemSearchDto } from 'src/app/request/req-all-store-param-item-search';
import { RspItemSearch } from 'src/app/response/rsp-item-search';
import { RspItemDto } from 'src/app/response/rsp-item-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspAllStoreParamItemSearch } from 'src/app/response/rsp-all-store-param-item-search';
import { CtgSelectComponent } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { LoginStoreDto } from 'src/app/response/login-store-dto';


@Component({
  selector: 'app-all-store-param-item',
  templateUrl: './all-store-param-item.component.html',
  styleUrls: ['./all-store-param-item.component.css']
})
export class AllStoreParamItemComponent implements OnInit, OnDestroy {

  public minZaiCurrent: string;
  public isMinZaiEdited: boolean;
  public maxZaiCurrent: string;
  public isMaxZaiEdited: boolean;
  public minStockDaysCurrent: string;
  public isMinStockDaysEdited: boolean;
  public maxStockDaysCurrent: string;
  public isMaxStockDaysEdited: boolean;
  public orderStopFlagCurrent: string;
  public isOrderStopFlagEdited: boolean;
  public orderStopSelList: any[];


  public itemSelectSimpleCondition: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fb);
  public itemSelectSimpleConditionOrigin: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fbOrigin);
  public itemSelected: ItemSimpleRec;
  public itemList: ItemRec[] = [];
  public displayColumn: string[] = [
    "store", "orderStop", "minZai", "maxZai", "minStockDays", "maxStockDays"
  ];


  public formGroup: FormGroup;
  private formOriginValue: FormControl[];
  public isCopyEmptyInputForm: FormControl = new FormControl(false);

  public formSetAllItem: FormGroup;
  public orderStopFlag: FormControl = new FormControl("");
  public minZaiForm: FormControl = new FormControl("");
  public maxZaiForm: FormControl = new FormControl("");
  public minStockDaysForm: FormControl = new FormControl("");
  public maxStockDaysForm: FormControl = new FormControl("");

  public errorInputMessages: String[];
  public errorSetAllMessages: String[];

  public isChangedForm: boolean;
  private configAllStoreParamItem = {
    minZai: this.commonService.config.paramCatMinZaiRange,
    maxZai: this.commonService.config.paramCatMaxZaiRange,
    minStockDays: this.commonService.config.paramCatMinStockDaysRange,
    maxStockDays: this.commonService.config.paramCatMaxStockDaysRange,
  };

  private keypadTargetId: string;
  
  @ViewChild(ItemListSimpleComponent, { static: true }) itemListSimpleComponent: ItemListSimpleComponent;
  @ViewChild("sectionSelectCondHeader", { static: true }) sectionSelectCond: SectionHeaderComponent;
  @ViewChild("sectionItemsHeader", { static: true }) sectionItems: SectionHeaderComponent;
  @ViewChild(NumberKeypadComponent, { static: false }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild('allStoreParamItemTable', { static: false }) allStoreParamItemTable: ElementRef;
  @ViewChild(CtgSelectComponent, { static: true }) public CtgSelectComponent: CtgSelectComponent;

  private subscriptionStore: Subscription;
  private subscriptionSearch: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscriptionConfirm: Subscription;
  private subscriptionFormInsertAllItem: Subscription;
  formValue: any;
  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private fbOrigin: FormBuilder,
    private httpBasic: HttpBasicService
  ) {
    // this.formGroup = fb.group({});
  }
  ngOnInit() {
    // this.commonService.pageTitle = '全店パラメータ(商品)';
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.orderStopSelList = this.commonService.getOrderStopSelectList();
    this.isChangedForm = false;
    this.initFormSetAllItems();
  }

  ngOnDestroy() {
    if (this.subscriptionStore) this.subscriptionStore.unsubscribe();
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionFormInsertAllItem) this.subscriptionFormInsertAllItem.unsubscribe();
  }

  doQueryItemSimple() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.copyRequest();
      this.itemListSimpleComponent.doQuery();
      this.itemSelected = undefined;
      this.sectionItems.openSectionBody();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.copyRequest();
          this.itemListSimpleComponent.doQuery();
          this.itemSelected = undefined;
          this.sectionItems.openSectionBody();
          this.isChangedForm = false;
          return;
        }
      }
    );
  }

  doUpdate() {
    let itemChangedList: ParamItemEditDto[] = [];
    this.itemList.forEach(
      item => {
        if (this.isChangedParam(item.storeCdFv)) {
          let storeChangedItem: ParamItemEditDto = {
            storeCdFv: item.storeCdFv,
            itemCdFv: item.itemCdFv,

            minZaiIsNull: item.minZaiIsNull,
            minZaiFn: item.minZaiFn,
            minZaiModIsNull: item.minZaiModIsNull,
            minZaiModFn: -1, // item.minZaiModFn,
            minZaiVersionFn: item.minZaiVersionFn,

            maxZaiIsNull: item.maxZaiIsNull,
            maxZaiFn: item.maxZaiFn,
            maxZaiModIsNull: item.maxZaiModIsNull,
            maxZaiModFn: -1, // item.maxZaiModFn,
            maxZaiVersionFn: item.maxZaiVersionFn,

            minStockDaysIsNull: item.minStockDaysIsNull,
            minStockDaysFn: item.minStockDaysFn,
            minStockDaysModIsNull: item.minStockDaysModIsNull,
            minStockDaysModFn: -1, // item.minStockDaysModFn,
            minStockDaysVersionFn: item.minStockDaysVersionFn,

            maxStockDaysIsNull: item.maxStockDaysIsNull,
            maxStockDaysFn: item.maxStockDaysFn,
            maxStockDaysModIsNull: item.maxStockDaysModIsNull,
            maxStockDaysModFn: -1, // item.maxStockDaysModFn,
            maxStockDaysVersionFn: item.maxStockDaysVersionFn,

            orderStopFlagFn: item.orderStopFlagFn,
            orderStopFlagModFn: -1, // item.orderStopFlagModFn,
            orderStopFlagVersionFn: item.orderStopFlagVersionFn,
          }
          if (this.isChangeInputElement(item.storeCdFv + "-minZai")) {
            storeChangedItem.minZaiModFn = this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-minZai"]);
            storeChangedItem.minZaiModIsNull = this.paramIsNull(this.formGroup.value[item.storeCdFv + "-minZai"]);
          }
          if (this.isChangeInputElement(item.storeCdFv + "-maxZai")) {
            storeChangedItem.maxZaiModFn = this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-maxZai"]);
            storeChangedItem.maxZaiModIsNull = this.paramIsNull(this.formGroup.value[item.storeCdFv + "-maxZai"]);
          }
          if (this.isChangeInputElement(item.storeCdFv + "-minStockDays")) {
            storeChangedItem.minStockDaysModFn = this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-minStockDays"]);
            storeChangedItem.minStockDaysModIsNull = this.paramIsNull(this.formGroup.value[item.storeCdFv + "-minStockDays"]);
          }
          if (this.isChangeInputElement(item.storeCdFv + "-maxStockDays")) {
            storeChangedItem.maxStockDaysModFn = this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-maxStockDays"]);
            storeChangedItem.maxStockDaysModIsNull = this.paramIsNull(this.formGroup.value[item.storeCdFv + "-maxStockDays"]);
          }
          if (this.isChangeInputElement(item.storeCdFv + "-orderStopFlag")) {
            storeChangedItem.orderStopFlagModFn = this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-orderStopFlag"]);
          }
          itemChangedList.push(storeChangedItem);
        }
      }
    )
    var request: ReqAllStoreParamItemEdit;
    request = {
      access: { ...this.commonService.loginUser },
      allStoreParamItemEdit: itemChangedList
    };
    this.commonService.openSpinner("全店パラメータ商品更新", "更新中・・・");
    this.subscriptionSearch = this.httpBasic.updateAllStoreItemParam(request).subscribe(
      data => this.checkResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  checkResult(response: RspAllStoreParamItemSearch) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.itemList.forEach(
      item => {
        if (this.isChangedParam(item.storeCdFv)) {
          if (this.isChangeInputElement(item.storeCdFv + "-minZai"))
            item.setMinZai(this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-minZai"]), item.minZaiVersionFn + 1);
          if (this.isChangeInputElement(item.storeCdFv + "-maxZai"))
            item.setMaxZai(this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-maxZai"]), item.maxZaiVersionFn + 1);
          if (this.isChangeInputElement(item.storeCdFv + "-minStockDays"))
            item.setMinStockDays(this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-minStockDays"]), item.minStockDaysVersionFn + 1);
          if (this.isChangeInputElement(item.storeCdFv + "-maxStockDays"))
            item.setMaxStockDays(this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-maxStockDays"]), item.maxStockDaysVersionFn + 1);
          if (this.isChangeInputElement(item.storeCdFv + "-orderStopFlag"))
            item.setOrderStopFlag(this.parseIntCustom(this.formGroup.value[item.storeCdFv + "-orderStopFlag"]), item.orderStopFlagVersionFn + 1);
        }
      }
    )
    this.initFormGroup();
  }

  paramIsNull(val: string) {
    return val === "" ? true : false;
  }

  itemChange(item: ItemSimpleRec) {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
      var request: ReqAllStoreParamItemSearchDto;
      request = {
        storeCdFv: item.storeCdFv,
        itemCdFv: item.itemCdFv,
      };
      this.itemSelected = item;
      // this.sectionSelectCond.closeSectionBody();
      this.sectionItems.closeSectionBody();

      /* For  */
      this.initFormGroup();
      this.subscriptionSearch = this.httpBasic.getStoreParamItemSearch(request)
        .subscribe(
          data => {
            this.searchStoreResult(data);
            this.scrollTopTable();
          }
          ,
          error => this.httpBasic.handleError(error)
        );
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
          var request: ReqAllStoreParamItemSearchDto;
          request = {
            storeCdFv: item.storeCdFv,
            itemCdFv: item.itemCdFv,
          };
          this.itemSelected = item;
          // this.sectionSelectCond.closeSectionBody();
          this.sectionItems.closeSectionBody();

          /* For  */
          this.initFormGroup();
          this.subscriptionSearch = this.httpBasic.getStoreParamItemSearch(request)
            .subscribe(
              data => {
                this.searchStoreResult(data);
                this.scrollTopTable();
              }
              ,
              error => this.httpBasic.handleError(error)
            );
          return;
        }
        else {
          // this.sectionSelectCond.closeSectionBody();
          this.sectionItems.closeSectionBody();
        }
      }
    );
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }

  searchStoreResult(data: RspItemSearch) {
    let rspStoreParamItemSearch: RspItemSearch = { ...data };

    if (rspStoreParamItemSearch.fatalError && rspStoreParamItemSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspStoreParamItemSearch.fatalError[0].errMsg);
    }
    if (rspStoreParamItemSearch.normalError && rspStoreParamItemSearch.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspStoreParamItemSearch.normalError[0].errMsg);
      return;
    }
    this.itemList = [];
    let rspStoreParamItemDtos: RspItemDto[] = [...rspStoreParamItemSearch.rows];
    for (var rspStoreParamItemDto of rspStoreParamItemDtos) {
      this.itemList.push(new ItemRec(rspStoreParamItemDto, this.commonService));
    }
    this.initFormGroup();
    this.clearProgressState();
  }

  initFormGroup() {
    this.formGroup = this.fb.group({});
    for (let item of this.itemList) {
      let fc = new FormControl(item.orderStopFlagCurrentFn);
      if (!this.commonService.getOrderStopFlagChangeable(item.orderStopFlagCurrentFn)) {
        fc.disable();
      }
      this.formGroup.addControl(item.storeCdFv + "-orderStopFlag", fc);
      fc = new FormControl(item.minZaiCurrentFv);
      this.formGroup.addControl(item.storeCdFv + "-minZai", fc);
      fc = new FormControl(item.maxZaiCurrentFv);
      this.formGroup.addControl(item.storeCdFv + "-maxZai", fc);
      fc = new FormControl(item.minStockDaysCurrentFv);
      this.formGroup.addControl(item.storeCdFv + "-minStockDays", fc);
      fc = new FormControl(item.maxStockDaysCurrentFv);
      this.formGroup.addControl(item.storeCdFv + "-maxStockDays", fc);
    }

    this.formOriginValue = this.formGroup.value;
    this.keypadTargetId = undefined;
    this.handlerChangedForm();
    this.setErrorInputMessages();
  }

  initFormSetAllItems() {
    this.formSetAllItem = this.fb.group({});
    this.formSetAllItem.addControl("orderStopFlag", this.orderStopFlag);
    this.formSetAllItem.addControl("minZaiForm", this.minZaiForm);
    this.formSetAllItem.addControl("maxZaiForm", this.maxZaiForm);
    this.formSetAllItem.addControl("minStockDaysForm", this.minStockDaysForm);
    this.formSetAllItem.addControl("maxStockDaysForm", this.maxStockDaysForm);

    this.formSetAllItem.controls["orderStopFlag"].setValue(0);
    this.errorSetAllMessages = [];
    this.subscriptionFormInsertAllItem = this.formSetAllItem.valueChanges.subscribe(
      value => {
        this.setErrorInsertAllMessages();
      }
    )
  }

  getOrderStopSelList(item: ItemRec) {
    if (!this.commonService.getOrderStopFlagChangeable(item.orderStopFlagCurrentFn)) {
      return this.commonService.config.orderStopFlag;
      // this.errorMessage = "この商品の自動発注種別の選択は許可されていません。"
      // this.orderStopForm.disable();
    } else {
      return this.orderStopSelList;
    }
  }

  handlerChangedForm() {
    if (this.subscriptionFormGroup) {
      this.subscriptionFormGroup.unsubscribe();
      this.subscriptionFormGroup = undefined;
    }

    this.isChangedForm = false;
    this.subscriptionFormGroup = this.formGroup.valueChanges
      .subscribe(valueChange => {
        let formControlChanged = Object.keys(valueChange)
          .find(key => (this.parseIntCustom(valueChange[key]) != this.parseIntCustom(this.formOriginValue[key]))
            || (parseInt(valueChange[key]) == 0 && parseInt(this.formOriginValue[key]) != 0));
        formControlChanged != null ? this.isChangedForm = true : this.isChangedForm = false;
        this.setErrorInputMessages();
      }
      );
  }

  doCancel() {
    this.setCurrentValue();
    this.isChangedForm = false;
  }

  setCurrentValue() {
    if (!this.itemList) return;
    this.initFormGroup();
    this.formGroup.markAsPristine();
  }

  canDeactivate() {
    if (!this.isChangedForm) { return true; }
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  isDisabledSetAllButton() {
    if (this.isCopyEmptyInputForm.value === true) {
      if (this.errorSetAllMessages.length > 0) return true;
    } else {
      return ((parseInt(this.formSetAllItem.value.orderStopFlag) === 0
        && this.formSetAllItem.value.minZaiForm === ""
        && this.formSetAllItem.value.maxZaiForm === ""
        && this.formSetAllItem.value.minStockDaysForm === ""
        && this.formSetAllItem.value.maxStockDaysForm === "") || this.errorSetAllMessages.length > 0)
        ? true : false;
    }
  }

  doSetAll() {
    if(this.commonService.config.allStoreCopyConfirm == true) {
      this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, "全店に適用します。").subscribe(
        data => {
          this.subscriptionConfirm = undefined;
          if (data == true) {
            switch (this.isCopyEmptyInputForm.value) {
              case true: {
                this.itemList.forEach(item => {
                  if (this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].value != 3 && this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].value != 4 && this.formSetAllItem.value.orderStopFlag != 0) {
                    this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].setValue(this.formSetAllItem.value.orderStopFlag);
                  }
                  this.formGroup.controls[item.storeCdFv + "-minZai"].setValue(this.formSetAllItem.value.minZaiForm);
                  this.formGroup.controls[item.storeCdFv + "-maxZai"].setValue(this.formSetAllItem.value.maxZaiForm);
                  this.formGroup.controls[item.storeCdFv + "-minStockDays"].setValue(this.formSetAllItem.value.minStockDaysForm);
                  this.formGroup.controls[item.storeCdFv + "-maxStockDays"].setValue(this.formSetAllItem.value.maxStockDaysForm);
                })
                this.clearKeypadTargetId();
                return;
              }
              case false: {
                this.itemList.forEach(item => {
                  if (this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].value != 3 && this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].value != 4 && this.formSetAllItem.value.orderStopFlag != 0) {
                    this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].setValue(this.formSetAllItem.value.orderStopFlag);
                  }
                  if (this.formSetAllItem.value.minZaiForm !== '') {
                    this.formGroup.controls[item.storeCdFv + "-minZai"].setValue(this.formSetAllItem.value.minZaiForm);
                  }
                  if (this.formSetAllItem.value.maxZaiForm !== '') {
                    this.formGroup.controls[item.storeCdFv + "-maxZai"].setValue(this.formSetAllItem.value.maxZaiForm);
                  }
                  if (this.formSetAllItem.value.minStockDaysForm !== '') {
                    this.formGroup.controls[item.storeCdFv + "-minStockDays"].setValue(this.formSetAllItem.value.minStockDaysForm);
                  }
                  if (this.formSetAllItem.value.maxStockDaysForm !== '') {
                    this.formGroup.controls[item.storeCdFv + "-maxStockDays"].setValue(this.formSetAllItem.value.maxStockDaysForm);
                  }
                })
                this.clearKeypadTargetId();
                return;
              }
              default: return;
            }
          }
        }
      );
    } else {
      switch (this.isCopyEmptyInputForm.value) {
        case true: {
          this.itemList.forEach(item => {
            if (this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].value != 3 && this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].value != 4 && this.formSetAllItem.value.orderStopFlag != 0) {
              this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].setValue(this.formSetAllItem.value.orderStopFlag);
            }
            this.formGroup.controls[item.storeCdFv + "-minZai"].setValue(this.formSetAllItem.value.minZaiForm);
            this.formGroup.controls[item.storeCdFv + "-maxZai"].setValue(this.formSetAllItem.value.maxZaiForm);
            this.formGroup.controls[item.storeCdFv + "-minStockDays"].setValue(this.formSetAllItem.value.minStockDaysForm);
            this.formGroup.controls[item.storeCdFv + "-maxStockDays"].setValue(this.formSetAllItem.value.maxStockDaysForm);
          })
          this.clearKeypadTargetId();
          return;
        }
        case false: {
          this.itemList.forEach(item => {
            if (this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].value != 3 && this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].value != 4 && this.formSetAllItem.value.orderStopFlag != 0) {
              this.formGroup.controls[item.storeCdFv + "-orderStopFlag"].setValue(this.formSetAllItem.value.orderStopFlag);
            }
            if (this.formSetAllItem.value.minZaiForm !== '') {
              this.formGroup.controls[item.storeCdFv + "-minZai"].setValue(this.formSetAllItem.value.minZaiForm);
            }
            if (this.formSetAllItem.value.maxZaiForm !== '') {
              this.formGroup.controls[item.storeCdFv + "-maxZai"].setValue(this.formSetAllItem.value.maxZaiForm);
            }
            if (this.formSetAllItem.value.minStockDaysForm !== '') {
              this.formGroup.controls[item.storeCdFv + "-minStockDays"].setValue(this.formSetAllItem.value.minStockDaysForm);
            }
            if (this.formSetAllItem.value.maxStockDaysForm !== '') {
              this.formGroup.controls[item.storeCdFv + "-maxStockDays"].setValue(this.formSetAllItem.value.maxStockDaysForm);
            }
          })
          this.clearKeypadTargetId();
          return;
        }
        default: return;
      }
    }
    
  }

  doClearFormSetAll() {
    this.formSetAllItem.controls["orderStopFlag"].setValue('0');
    this.formSetAllItem.controls["minZaiForm"].setValue('');
    this.formSetAllItem.controls["maxZaiForm"].setValue('');
    this.formSetAllItem.controls["minStockDaysForm"].setValue('');
    this.formSetAllItem.controls["maxStockDaysForm"].setValue('');
    this.clearKeypadTargetId();
  }

  setErrorInputMessages() {
    this.errorInputMessages = [];
    const config = this.configAllStoreParamItem;
    this.itemList.forEach(item => {
      if (this.isMinZaiError(this.formGroup.value[item.storeCdFv + "-minZai"])) {
        this.errorInputMessages.push(item.storeCdFv + ":" + item.storeNameFv + ":" + "最低陳列 の値は、" + config.minZai.min + "～" + config.minZai.max + "の範囲でなければなりません。");
      }
      if (this.isMaxZaiError(this.formGroup.value[item.storeCdFv + "-maxZai"])) {
        this.errorInputMessages.push(item.storeCdFv + ":" + item.storeNameFv + ":" + "最大陳列 の値は、" + config.maxZai.min + "~" + config.maxZai.max + "の範囲でなければなりません。");
      }
      if (this.isMinStockDaysError(this.formGroup.value[item.storeCdFv + "-minStockDays"])) {
        this.errorInputMessages.push(item.storeCdFv + ":" + item.storeNameFv + ":" + "最低在庫日数 の値は、" + config.minStockDays.min + "~" + config.minStockDays.max + "の範囲でなければなりません。");
      }
      if (this.isMaxStockDaysError(this.formGroup.value[item.storeCdFv + "-maxStockDays"])) {
        this.errorInputMessages.push(item.storeCdFv + ":" + item.storeNameFv + ":" + "最大在庫日数 の値は、" + config.maxStockDays.min + "~" + config.maxStockDays.max + "の範囲でなければなりません。");
      }
      if (this.formGroupValidator(item.storeCdFv, item.storeNameFv, "zai") != null) {
        this.errorInputMessages.push(this.formGroupValidator(item.storeCdFv, item.storeNameFv, "zai"));
      }
      if (this.formGroupValidator(item.storeCdFv, item.storeNameFv, "stock") != null) {
        this.errorInputMessages.push(this.formGroupValidator(item.storeCdFv, item.storeNameFv, "stock"));
      }
    })
  }

  setErrorInsertAllMessages() {
    this.errorSetAllMessages = [];
    const config = this.configAllStoreParamItem;
    if (this.isMinZaiError(this.formSetAllItem.value.minZaiForm)) {
      this.errorSetAllMessages.push("最低陳列 の値は、" + config.minZai.min + "～" + config.minZai.max + "の範囲でなければなりません。");
    }
    if (this.isMaxZaiError(this.formSetAllItem.value.maxZaiForm)) {
      this.errorSetAllMessages.push("最大陳列 の値は、" + config.maxZai.min + "～" + config.maxZai.max + "の範囲でなければなりません。");
    }
    if (this.isMinStockDaysError(this.formSetAllItem.value.minStockDaysForm)) {
      this.errorSetAllMessages.push("最低在庫日数 の値は、" + config.minStockDays.min + "～" + config.minStockDays.max + "の範囲でなければなりません。");
    }
    if (this.isMaxStockDaysError(this.formSetAllItem.value.maxStockDaysForm)) {
      this.errorSetAllMessages.push("最大在庫日数 の値は、" + config.maxStockDays.min + "～" + config.maxStockDays.max + "の範囲でなければなりません。");
    }
    if (this.formSetAllValidator("zai") != null) {
      this.errorSetAllMessages.push(this.formSetAllValidator("zai"));
    }
    if (this.formSetAllValidator("stock") != null) {
      this.errorSetAllMessages.push(this.formSetAllValidator("stock"));
    }
  }

  isMinZaiError(value: string) {
    const config = this.configAllStoreParamItem;
    if (parseFloat(value) - config.minZai.max > 0) {
      return true;
    }
    if (parseInt(value) < config.minZai.min || parseInt(value) > config.minZai.max)
      return true;
    return false;
  }

  isMaxZaiError(value: string) {
    const config = this.configAllStoreParamItem;
    if (parseFloat(value) - config.maxZai.max > 0) {
      return true;
    }
    if (parseInt(value) < config.maxZai.min || parseInt(value) > config.maxZai.max)
      return true;
    return false;
  }

  isMinStockDaysError(value: string) {
    const config = this.configAllStoreParamItem;
    if (parseFloat(value) - config.minStockDays.max > 0) {
      return true;
    }
    if (parseInt(value) < config.minStockDays.min || parseInt(value) > config.minStockDays.max)
      return true;
    return false;
  }

  isMaxStockDaysError(value: string) {
    const config = this.configAllStoreParamItem;
    if (parseFloat(value) - config.maxStockDays.max > 0) {
      return true;
    }
    if (parseInt(value) < config.maxStockDays.min || parseInt(value) > config.maxStockDays.max)
      return true;
    return false;
  }


  formGroupValidator(storeCdFv: string, storeNameFv: string, type: string) {
    switch (type) {
      case "zai": {
        if (parseInt(this.formGroup.get(storeCdFv + "-minZai").value) > parseInt(this.formGroup.get(storeCdFv + "-maxZai").value))
          return storeCdFv + ":" + storeNameFv + ":最低陳列、最大陳列が逆転しています。";
        return null;
      }

      case "stock": {
        if (parseInt(this.formGroup.get(storeCdFv + "-minStockDays").value) > parseInt(this.formGroup.get(storeCdFv + "-maxStockDays").value))
          return storeCdFv + ":" + storeNameFv + ":最低在庫日数、最大在庫日数が逆転しています。";
        return null
      }
      default: return null;
    }
  }

  formSetAllValidator(type: string) {
    switch (type) {
      case "zai": {
        if (type === "zai" && parseInt(this.formSetAllItem.value["minZaiForm"]) > parseInt(this.formSetAllItem.value["maxZaiForm"]))
          return "最低陳列、最大陳列が逆転しています。";
        return null;
      }

      case "stock": {
        if (type === "stock" && parseInt(this.formSetAllItem.value["minStockDaysForm"]) > parseInt(this.formSetAllItem.value["maxStockDaysForm"]))
          return "最低在庫日数、最大在庫日数が逆転しています。";
        return null
      }
      default: return null;
    }
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  is10KeyPadTarget(id: string) {
    if (!this.use10KeyPad()) return false;
    if (id === this.keypadTargetId) return true;
    return false;
  }

  set10KeyPadTarget(id: string, form: FormControl) {

    this.clearKeypadTargetId();
    if (id == "minZaiForm" && !this.commonService.config.paramItemMinZaiEditable) return;
    if (id == "maxZaiForm" && !this.commonService.config.paramItemMaxZaiEditable) return;
    if (id == "minStockDaysForm" && !this.commonService.config.paramItemMinStockDaysEditable) return;
    if (id == "maxStockDaysForm" && !this.commonService.config.paramItemMaxStockDaysEditable) return;
    this.itemList.forEach(item => {
      if (id == "" + item.storeCdFv + "-minZai" && !this.commonService.config.paramItemMinZaiEditable) return;
      if (id == "" + item.storeCdFv + "-maxZai" && !this.commonService.config.paramItemMaxZaiEditable) return;
      if (id == "" + item.storeCdFv + "-minStockDays" && !this.commonService.config.paramItemMinStockDaysEditable) return;
      if (id == "" + item.storeCdFv + "-maxStockDays" && !this.commonService.config.paramItemMaxStockDaysEditable) return;
    })

    this.keypadTargetId = id;
    // this.numberKeypadComponent.inputField = form;
    this.numberKeypadComponent.setTargetForm(form);
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent && this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  parseIntCustom(x: string) {
    const parsed = parseInt(x);
    if (isNaN(parsed)) { return 0; }
    return parsed;
  }

  isChangeInputElement(formControlName: string) {
    return this.parseIntCustom(this.formGroup.value[formControlName]) != this.parseIntCustom(this.formOriginValue[formControlName])
      ? true
      : false;
  }

  isChangedParam(storeCdFv: string) {
    if (this.isChangeInputElement(storeCdFv + "-minZai")
      || this.isChangeInputElement(storeCdFv + "-maxZai")
      || this.isChangeInputElement(storeCdFv + "-minStockDays")
      || this.isChangeInputElement(storeCdFv + "-maxStockDays")
      || this.isChangeInputElement(storeCdFv + "-orderStopFlag")
    )
      return true
  }

  scrollTopTable() {
    if (this.allStoreParamItemTable)
      this.allStoreParamItemTable.nativeElement.scrollTop = 0;
  }

  clearKeypadTargetId() {
    this.keypadTargetId = undefined;
    this.numberKeypadComponent.inputField = undefined;
  }

  copyRequest() {
    this.itemSelectSimpleConditionOrigin.formGroup.patchValue(this.itemSelectSimpleCondition.formGroup.value);
    this.itemSelectSimpleConditionOrigin.ctgSelectCondition.formGroup.patchValue(this.itemSelectSimpleCondition.ctgSelectCondition.formGroup.value);
  }
}
