import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { ReqPbiMenu } from 'src/app/request/req-pbi-menu';
import { RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { RspPbiMenu } from 'src/app/response/rsp-pbi-menu';
import { PbiReportDto, RspReportList } from 'src/app/response/rsp-pbi-report-list';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspMenu } from 'src/app/webservice/menu';

interface PbiMenu {
  menuName: string;
  pbiName: string;
  menuRec: MenuItem;
}

interface PbiRec {
  nameFv: string;
  pbiClientIdFv: string;
  pbiTypeFv: string;
  pbiReportIdFv: string;
  dataSourceFv: string;
}

interface MenuGroup {
  menuGroupId: number;
  menuGroupName: string;
  dispOrder: number;
}

interface MenuItem {
  menuId: number;
  menuGroupId: number;
  menuName: string;
  menuPath: string;
  readOnly: number;
  dispOrder: number;
}

interface PbiItem {
  menuName: string;
  pbiName: string;
  reportId: string;
  reportName: string;
  pbiMenu?: PbiMenu;
  pbiRec?: PbiRec;
  formMenuName?: FormControl;
  formPbiName?: FormControl;
}

@Component({
  selector: 'app-pbi-menu',
  templateUrl: './pbi-menu.component.html',
  styleUrls: ['./pbi-menu.component.css']
})
export class PbiMenuComponent implements OnInit, OnDestroy {

  private subscriptionQuery: Subscription;
  private reportList: PbiReportDto[];
  private pbiRecList: PbiRec[];
  private menuGroupList: MenuGroup[];
  private menuItemList: MenuItem[];
  private pbiMenuList: PbiMenu[];
  public pbiList: PbiItem[];
  private updateTarget: PbiItem;

  private formGroupPbiName: FormGroup = new FormGroup({}, () => {return this.validatePbiName();});
  private formCounter: number  = 1;
  public formGroupSelect: FormControl = new FormControl();

  public tableWidth: any;
  public columnIds: string[] = ["btn", "menuName", "pbiName", "reportId", "reportName"];
  public columnDefs: TableColumnDef[] = [
    {columnId:'menuName', header:"メニュー名", width:200},
    {columnId:'pbiName', header:"DB定義名", width:100},
    {columnId:'reportId', header:"レポートID", width:240},
    {columnId:'reportName', header:"レポート名", width:200}
  ];

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.calcTableWidth();
    this.getReport();
  }

  ngOnDestroy(): void {
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    for (var colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = {"width": "" + width + "px"};
  }

  formName(colId: string, item: PbiItem) {
    if (colId == 'menuName' && item.formMenuName != undefined) return("formMenuName");
    if (colId == 'pbiName' && item.formPbiName != undefined) return("formPbiName");
    return "";
  }

  getReport() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.pbiReportList().subscribe(
      (response) => {this.receiveReportList(response)},
      (error) => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  reloadReport() {
    this.reportList = undefined;
    this.formGroupPbiName = new FormGroup({}, () => {return this.validatePbiName();});
    this.formCounter = 1;
    this.getReport();
  }

  receiveReportList(response: RspReportList) {
    this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    if (this.httpBasic.handleAppError(response)) {
      this.commonService.closeSpinner();
      return;
    }

    this.reportList = [];
    for (let report of response.reports) {
      this.reportList.push(report);
    }

    this.getReportRec();
  }

  getReportRec() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "mng/getPbiDef",
      bindVariables: []
    };

    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveReportRec(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveReportRec(response: RspExecuteQuery) {
    if (this.httpBasic.handleAppError(response)) {
      this.commonService.closeSpinner();
      return;
    }

    this.pbiRecList = [];
    for (let item of response.rows) {
      let rec: PbiRec = {
        nameFv: item.colData[0],
        pbiClientIdFv: item.colData[1],
        pbiTypeFv: item.colData[2],
        pbiReportIdFv: item.colData[3],
        dataSourceFv: item.colData[4]
      };
      this.pbiRecList.push(rec);
    }

    this.getMenuItem();
  }

  getMenuItem() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "mng/getMenuItem",
      bindVariables: []
    };

    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveMenuItem(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveMenuItem(response: RspExecuteQuery) {
    if (this.httpBasic.handleAppError(response)) {
      this.commonService.closeSpinner();
      return;
    }

    this.menuItemList = [];
    for (let item of response.rows) {
      let menu: MenuItem = {
        menuId: parseInt(item.colData[0]),
        menuGroupId: parseInt(item.colData[1]),
        menuName: item.colData[2],
        menuPath: item.colData[3],
        readOnly: parseInt(item.colData[4]),
        dispOrder: parseInt(item.colData[5])
      };
      this.menuItemList.push(menu);
    }

    this.getPbiMenu();
  }

  getPbiMenu() {
    this.pbiMenuList = [];

    let list = this.menuItemList.filter(item => item.menuPath.startsWith("pbi?name="));
    for (let menu of list) {
      let pbiMenu: PbiMenu = {
        menuName: menu.menuName,
        pbiName: menu.menuPath.split("=")[1],
        menuRec: menu
      }
      this.pbiMenuList.push(pbiMenu);
    }

    this.buildPbiList();
    this.getMenuGroup();
  }

  getMenuGroup() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "mng/getMenuGroup",
      bindVariables: ["" + this.commonService.loginUser.roleId]
    };

    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveMenuGroup(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveMenuGroup(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.menuGroupList = [];
    for (let item of response.rows) {
      let menu: MenuGroup = {
        menuGroupId: parseInt(item.colData[0]),
        menuGroupName: item.colData[1],
        dispOrder: parseInt(item.colData[2]),
      };
      this.menuGroupList.push(menu);
    }
    this.menuGroupList.sort((a, b) => {
      if (a.dispOrder < b.dispOrder) return -1;
      if (a.dispOrder > b.dispOrder) return 1;
      return 0;
    });

    let group = this.menuGroupList.find(item => item.menuGroupName == "分析");
    if (group != undefined) {
      this.formGroupSelect.setValue(group.menuGroupId);
    } else {
      this.formGroupSelect.setValue(0);
    }

  }

  buildPbiList() {
    this.pbiList = [];
    for (let report of this.reportList) {
      let item: PbiItem = {
        menuName: undefined,
        pbiName: undefined,
        reportId: report.reportId,
        reportName: report.reportName,
        pbiMenu: undefined,
        pbiRec: undefined,
        formMenuName: undefined,
        formPbiName: undefined
      };
      this.pbiList.push(item);
    }

    for (let rec of this.pbiRecList) {
      let listItem = this.pbiList.find(item => item.reportId == rec.pbiReportIdFv);
      if (listItem) {
        listItem.pbiRec = rec;
        listItem.pbiName = rec.nameFv;
      } else {
        let item: PbiItem = {
          menuName: undefined,
          pbiName: rec.nameFv,
          reportId: "",
          reportName: "",
          pbiMenu: undefined,
          pbiRec: rec,
          formMenuName: undefined,
          formPbiName: undefined
        };
        this.pbiList.push(item);
      }
    }

    for (let menu of this.pbiMenuList) {
      let listItem = this.pbiList.find(item => item.pbiName == menu.pbiName);
      if (listItem) {
        listItem.menuName = menu.menuName;
        listItem.pbiMenu = menu;
      } else {
        let item: PbiItem = {
          menuName: menu.menuName,
          pbiName: menu.pbiName + " (メニュー引数)",
          reportId: "",
          reportName: "",
          pbiMenu: menu,
          pbiRec: undefined,
          formMenuName: undefined,
          formPbiName: undefined
        };
        this.pbiList.push(item);
      }
    }

    for (let item of this.pbiList) {
      if (item.reportId === "") continue;
      if (item.pbiMenu == undefined && item.pbiRec == undefined) {
        item.formMenuName = new FormControl(item.reportName);
        item.formPbiName = new FormControl(this.defaultPbiName(), Validators.required);
        this.formGroupPbiName.addControl(item.reportId, item.formPbiName)
      }
      if (item.pbiMenu == undefined && item.pbiRec != undefined) {
        item.formMenuName = new FormControl(item.reportName);
      }
    }
  }

  defaultPbiName() {
    for (let i = 1; i < 1000 ; i++) {
      if (this.pbiList.find(item => item.pbiName === "pbi-" + i)) continue;
      let list = this.pbiList.filter(item => item.formPbiName != undefined);
      if (list.find(item => item.formPbiName.value === "pbi-" + i)) continue;
      return "pbi-" + i;
    }
    return "";
  }

  styleFor(columnDef:TableColumnDef) {
    return {
      "width": "" + columnDef.width + "px",
      "text-align": columnDef.align ? columnDef.align : "left"
    }
  }

  styleForHeader(columnDef:TableColumnDef) {
    return {
      "width": "" + columnDef.width + "px"
    }
  }

  styleForForm(columnDef:TableColumnDef) {
    return {
      "width": "" + (columnDef.width - 16) + "px",
    }
  }

  isUpdateDisable(item: PbiItem) {
    if (item.formMenuName == undefined && item.formPbiName == undefined) return true;
    if (item.formPbiName != undefined) {
      return item.formPbiName.invalid;
    } else {
      if (item.formMenuName != undefined) {
        return item.formMenuName.invalid;
      } else {
        return true;
      }
    }
  }

  validatePbiName() {
    let isError: boolean = false;
    let errValue = {duplicatePbiName: "DB定義名が重複しています。"};

    if (this.pbiList == undefined) return;
    for (let item of this.pbiList) {
      if (item.formPbiName == undefined) continue;
      let form = item.formPbiName;
      if (form.value === "") continue;
      if (this.pbiRecList.find(item => item.nameFv === form.value)) {
        isError = true;
        form.setErrors(errValue);
        continue;
      }
      let list = this.pbiList.filter(item => item.formPbiName != undefined && form != item.formPbiName);
      if (list && list.find(item => item.formPbiName.value === form.value)) {
        isError = true;
        form.setErrors(errValue);
        continue;
      }
      form.setErrors(null);
    }

    if (isError) return errValue;
    return null;
  }

  doUpdate(item: PbiItem) {
    this.updateTarget = item;

    let request: ReqPbiMenu = {
      access: this.commonService.loginUser,
      db: [],
      menu: [],
      delDb: [],
      delMenu: []
    };

    if (item.formPbiName) {
      request.db.push({
        nameFv: item.formPbiName.value,
        pbiClientIdFv: "",
        pbiTypeFv: "report",
        pbiReportIdFv: item.reportId,
        dataSourceFv: ""
       });
    }

    if (item.formMenuName && item.formMenuName.value != "") {
      request.menu.push({
        menuId: -1,
        menuGroupId: parseInt(this.formGroupSelect.value),
        menuName: item.formMenuName.value,
        menuPath: "pbi?name=" + (item.formPbiName ? item.formPbiName.value : item.pbiName),
        isReadonly: true
      });
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.PbiMenu(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receivePbiMenu(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receivePbiMenu(response: RspPbiMenu) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    if (this.updateTarget.formPbiName) {
      this.updateTarget.pbiName = this.updateTarget.formPbiName.value;
      this.formGroupPbiName.removeControl(this.updateTarget.reportId);
      this.updateTarget.formPbiName = undefined;
    }

    if (this.updateTarget.formMenuName && this.updateTarget.formMenuName.value != "") {
      this.updateTarget.menuName = this.updateTarget.formMenuName.value;
      this.updateTarget.formMenuName = undefined;
      this.getMenu();
    }
  }

  doDelete(item: PbiItem) {
    this.updateTarget = item;

    let request: ReqPbiMenu = {
      access: this.commonService.loginUser,
      db: [],
      menu: [],
      delDb: [],
      delMenu: []
    };

    if (item.pbiRec) {
      request.delDb.push({
        nameFv: item.pbiRec.nameFv,
        pbiClientIdFv: item.pbiRec.pbiClientIdFv,
        pbiTypeFv: item.pbiRec.pbiTypeFv,
        pbiReportIdFv: item.pbiRec.pbiReportIdFv,
        dataSourceFv: item.pbiRec.dataSourceFv
       });
    }

    if (item.pbiMenu) {
      request.delMenu.push({
        menuId: item.pbiMenu.menuRec.menuId,
        menuGroupId: item.pbiMenu.menuRec.menuGroupId,
        menuName: item.pbiMenu.menuRec.menuName,
        menuPath: item.pbiMenu.menuRec.menuPath,
        isReadonly: (item.pbiMenu.menuRec.readOnly == 1 ? false : true)
      });
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.PbiMenu(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveDelete(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDelete(response: RspPbiMenu) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    if (this.updateTarget.pbiMenu) {
      this.getMenu();
    }

    for (let i = 0; i < this.pbiList.length; i++) {
      if (this.updateTarget == this.pbiList[i]) {
        this.pbiList.splice(i, 1)
      }
    }
    let tmp = [...this.pbiList];
    this.pbiList = tmp;
  }

  getMenu() {
    this.commonService.openSpinner(this.commonService.pageTitle, "メニュー取得中・・・")

    let subsc = this.httpBasic.getMenu().subscribe(
        response => {
          subsc.unsubscribe();
          this.receiveMenu(response);
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
    );
  }
  
  receiveMenu(response: RspMenu) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.commonService.menuGroups = [...response.menuGroups];
  }

}
