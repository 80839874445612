import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { OpestatViewContext } from '../opestat-view-context';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CommonService } from 'src/app/service/common.service';
import { OpestatViewMainComponent } from './opestat-view-main.component';
import { OpestatViewDistrComponent } from './opestat-view-distr.component';

@Component({
  selector: 'app-opestat-view',
  templateUrl: './opestat-view.component.html',
  styleUrls: ['./opestat-view.component.css']
})
export class OpestatViewComponent implements OnInit {

  @Input() public viewContext : OpestatViewContext;
  @ViewChild(SectionHeaderComponent, {static: true}) sectionHeader: SectionHeaderComponent;
  @ViewChild(OpestatViewMainComponent, {static: false}) opestatViewMainComponent: OpestatViewMainComponent;
  @ViewChild(OpestatViewDistrComponent, {static: false}) opestatViewDistrComponent: OpestatViewDistrComponent;

  public opestatType: number;

  constructor(
    private commonService: CommonService, 
    private httpBasic: HttpBasicService
    ) {
  }

  ngOnInit() {
  }

  doQuery() {
    this.opestatType = this.viewContext.formGroup.get("type").value;
    this.openSection();
    this.opestatViewMainComponent.doQuery();
    this.opestatViewDistrComponent.doQuery();
  }

  openSection() {
    this.sectionHeader.openSectionBody();
  }

  closeSection() {
    this.sectionHeader.closeSectionBody();
  }
}
