import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { OpestatViewContext } from '../opestat-view-context';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { Subscription } from 'rxjs';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CommonService } from 'src/app/service/common.service';
import { DateAdapter } from '@angular/material/core';
import { RspOpestat, RspOpestatMainDto } from 'src/app/response/rsp-opestat-main';
import { ReqOpestatMain } from 'src/app/request/req-opestat-main';
import { OpestatMainRec } from 'src/app/common/opestat-main-rec';
import { TableColumnDef } from 'src/app/common/table-column-def';

@Component({
  selector: 'app-opestat-view-main',
  templateUrl: './opestat-view-main.component.html',
  styleUrls: ['./opestat-view-main.component.css']
})
export class OpestatViewMainComponent implements OnInit, OnDestroy {

  public isMain: boolean;
  public opestatList: OpestatMainRec[]=[];
  public tableWidth: any;
  
  public displayColumnIds: string[]=[];
  stickyColumnDefs: TableColumnDef[] = [];
  columnDefs: TableColumnDef[] = [];
  displayColumnDefs: TableColumnDef[] = [];  
  undisplayColumnDefs: TableColumnDef[] = [];

  subscriptionSearch:Subscription;
  
  @Input() public viewContext : OpestatViewContext;
  @ViewChild(SectionHeaderComponent, {static: true}) sectionHeader: SectionHeaderComponent;

  constructor(private commonService: CommonService, 
    private httpBasic: HttpBasicService,
    private dateAdapter: DateAdapter<any>) { }

  ngOnDestroy(): void {
    if(this.subscriptionSearch){
      this.subscriptionSearch.unsubscribe();
    }
  }

  ngOnInit() {
    this.isMain = parseInt(this.viewContext.formGroup.get("type").value) != OpestatViewContext.ConditionTypeByDistribution;
    this.displayColumnDefs = [];
    this.columnDefs = [];
    this.setColumnId();
    this.calcTableWidth();
  }

  setColumnId() {
    this.displayColumnIds = [];

    for (var colDef of this.stickyColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
    for (var colDef of this.displayColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
  }

  styleFor(id:string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id:string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    for (var colDef of this.stickyColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = {"width": "" + width + "px"};
  }

  doQuery() {
    this.isMain = parseInt(this.viewContext.formGroup.get("type").value) != OpestatViewContext.ConditionTypeByDistribution;
    if (!this.isMain) return;

    this.calcColumn();
    this.doQueryBody();
  }

  calcColumn(){
    var typeFn = Number(this.viewContext.formGroup.get("type").value);
    this.displayColumnDefs = [];
    this.displayColumnIds = [];
    var conditionColumnDefs : TableColumnDef[] = [];
    var typeColumnDefs : TableColumnDef;
    var summaryRecommColumnDefs : TableColumnDef[] = [      
      {columnId:'orderAddRateFn', header:"追加率", width:60, align:"right"},
      {columnId:'orderUpRateFn', header:"上方率", width:60, align:"right"},
      {columnId:'orderMatchRateFn', header:"一致率", width:60, align:"right"},
      {columnId:'orderDownRateFn', header:"下方率", width:60, align:"right"},
      {columnId:'orderDelRateFn', header:"削除率", width:60, align:"right"},

      //{columnId:'orderCountFn', header:"発注回数", width:60, align:"right"},      
      {columnId:'orderAddCountFn', header:"追加件数", width:60, align:"right"},
      {columnId:'orderUpCountFn', header:"上方件数", width:60, align:"right"},
      {columnId:'orderMatchCountFn', header:"一致件数", width:60, align:"right"},
      {columnId:'orderDownCountFn', header:"下方件数", width:60, align:"right"},
      {columnId:'orderDelCountFn', header:"削除件数", width:60, align:"right"}
    ];
    var summarySoldOutColumnDefs : TableColumnDef[] = [
      //{columnId:'soldOutSkuCountFn', header:"対象SKU数", width:60, align:"right"},
      {columnId:'soldOutRatioFn', header:"欠品率", width:60, align:"right"},
      {columnId:'soldOutCntPerDayStoreFn', header:"欠品SKU数", width:60, align:"right", tooltip:"店舗・日当り"}
    ];

    const ctg0ColName = this.commonService.literal.ctg0Name;
    const ctg1ColName = this.commonService.literal.ctg1Name;
    const ctg2ColName = this.commonService.literal.ctg2Name;
    const ctg3ColName = this.commonService.literal.ctg3Name;

    let storeColDef   = {columnId:'storeFv',  header:"店舗",      width:90,   align:"left"};    
    let storeGroupColDef = {columnId:'storeGroupNameFv',  header:"店舗グループ",  width:90,   align:"left"};    
    let periodColDef  = {columnId:'periodFv', header:"期間",      width:150,  align:"center"};
    let sectionColDef = {columnId:'ctg0Fv',   header:ctg0ColName, width:160,  align:"left"};
    let largeColDef   = {columnId:'ctg1Fv',   header:ctg1ColName, width:160,  align:"left"};
    let middleColDef  = {columnId:'ctg2Fv',   header:ctg2ColName, width:160,  align:"left"};   
    let smallColDef   = {columnId:'ctg3Fv',   header:ctg3ColName, width:160,  align:"left"};
    let rankColDef    = {columnId:'rankFv',   header:"ランク",     width:60,   align:"center"};

    switch (typeFn) {
      //ByStore
      case OpestatViewContext.ConditionTypeByStore:
        conditionColumnDefs = [periodColDef];
        if(this.viewContext.ctgFromGroup.get("ctgCd0").value != ""){
          conditionColumnDefs.push(sectionColDef);        
          if(this.viewContext.ctgFromGroup.get("ctgCd1").value != ""){
            conditionColumnDefs.push(largeColDef);        
            if(this.viewContext.ctgFromGroup.get("ctgCd2").value != ""){
              conditionColumnDefs.push(middleColDef);        
              if(this.viewContext.ctgFromGroup.get("ctgCd3").value != ""){
                conditionColumnDefs.push(smallColDef);  
              }
            }
          }
        }
        if(this.viewContext.formGroup.get("rank").value != "")
          conditionColumnDefs.push(rankColDef);
        
        typeColumnDefs = storeColDef;
        break;

      //ByStoreGroup
      case OpestatViewContext.ConditionTypeByStoreGroup:
        conditionColumnDefs = [periodColDef];
        if(this.viewContext.ctgFromGroup.get("ctgCd0").value != ""){
          conditionColumnDefs.push(sectionColDef);        
          if(this.viewContext.ctgFromGroup.get("ctgCd1").value != ""){
            conditionColumnDefs.push(largeColDef);        
            if(this.viewContext.ctgFromGroup.get("ctgCd2").value != ""){
              conditionColumnDefs.push(middleColDef);        
              if(this.viewContext.ctgFromGroup.get("ctgCd3").value != ""){
                conditionColumnDefs.push(smallColDef);  
              }
            }
          }
        }
        if(this.viewContext.formGroup.get("rank").value != "")
          conditionColumnDefs.push(rankColDef);
        
        typeColumnDefs = storeGroupColDef;
        break;

      //ByCategory
      case OpestatViewContext.ConditionTypeByCategory:
        conditionColumnDefs = [
          periodColDef,
        ];
        if (parseInt(this.viewContext.formGroup.get("targetStoreType").value) == 1) {
          conditionColumnDefs.push(storeColDef);
        } else {
          conditionColumnDefs.push(storeGroupColDef);
        }
        if(this.viewContext.formGroup.get("rank").value != "")
          conditionColumnDefs.push(rankColDef);
        
        if(this.viewContext.ctgFromGroup.get("ctgCd0").value != ""){
          conditionColumnDefs.push(sectionColDef);        
          if(this.viewContext.ctgFromGroup.get("ctgCd1").value != ""){
            conditionColumnDefs.push(largeColDef); 
            if(this.viewContext.ctgFromGroup.get("ctgCd2").value != ""){
              conditionColumnDefs.push(middleColDef);              
              typeColumnDefs = smallColDef; 
            }else
              typeColumnDefs = middleColDef; 
          }else
            typeColumnDefs = largeColDef; 
        }else
          typeColumnDefs = sectionColDef; 
        break;

      //ByRank
      case OpestatViewContext.ConditionTypeByRank:
        conditionColumnDefs = [
          periodColDef,
        ];
        if (parseInt(this.viewContext.formGroup.get("targetStoreType").value) == 1) {
          conditionColumnDefs.push(storeColDef);
        } else {
          conditionColumnDefs.push(storeGroupColDef);
        }
        if(this.viewContext.ctgFromGroup.get("ctgCd0").value != ""){
          conditionColumnDefs.push(sectionColDef);        
          if(this.viewContext.ctgFromGroup.get("ctgCd1").value != ""){
            conditionColumnDefs.push(largeColDef);        
            if(this.viewContext.ctgFromGroup.get("ctgCd2").value != ""){
              conditionColumnDefs.push(middleColDef);        
              if(this.viewContext.ctgFromGroup.get("ctgCd3").value != ""){
                conditionColumnDefs.push(smallColDef); 
              }
            }
          }
        }
        typeColumnDefs = rankColDef; 
        break;

      //ByMonth
      case OpestatViewContext.ConditionTypeByMonth:
        conditionColumnDefs = [];
        if (parseInt(this.viewContext.formGroup.get("targetStoreType").value) == 1) {
          conditionColumnDefs.push(storeColDef);
        } else {
          conditionColumnDefs.push(storeGroupColDef);
        }
        if(this.viewContext.ctgFromGroup.get("ctgCd0").value != ""){
          conditionColumnDefs.push(sectionColDef);        
          if(this.viewContext.ctgFromGroup.get("ctgCd1").value != ""){
            conditionColumnDefs.push(largeColDef);        
            if(this.viewContext.ctgFromGroup.get("ctgCd2").value != ""){
              conditionColumnDefs.push(middleColDef);        
              if(this.viewContext.ctgFromGroup.get("ctgCd3").value != ""){
                conditionColumnDefs.push(smallColDef);  
              }
            }
          }
        }
        if(this.viewContext.formGroup.get("rank").value != "")
          conditionColumnDefs.push(rankColDef);
        
        typeColumnDefs = {columnId:'periodMonthFv', header:"期間(月)", width:80, align:"center"}; 
        break;

      //ByWeek
      case OpestatViewContext.ConditionTypeByWeek:
        conditionColumnDefs = [];
        if (parseInt(this.viewContext.formGroup.get("targetStoreType").value) == 1) {
          conditionColumnDefs.push(storeColDef);
        } else {
          conditionColumnDefs.push(storeGroupColDef);
        }
        if(this.viewContext.ctgFromGroup.get("ctgCd0").value != ""){
          conditionColumnDefs.push(sectionColDef);        
          if(this.viewContext.ctgFromGroup.get("ctgCd1").value != ""){
            conditionColumnDefs.push(largeColDef);        
            if(this.viewContext.ctgFromGroup.get("ctgCd2").value != ""){
              conditionColumnDefs.push(middleColDef);        
              if(this.viewContext.ctgFromGroup.get("ctgCd3").value != ""){
                conditionColumnDefs.push(smallColDef);  
              }
            }
          }
        }
        if(this.viewContext.formGroup.get("rank").value != "")
          conditionColumnDefs.push(rankColDef);
        
        typeColumnDefs = {columnId:'periodWeekFv', header:"期間(週)", width:80, align:"center"}; 
        break;

      //ByWeekDay
      case OpestatViewContext.ConditionTypeByWeekday:
        conditionColumnDefs = [];
        if (parseInt(this.viewContext.formGroup.get("targetStoreType").value) == 1) {
          conditionColumnDefs.push(storeColDef);
        } else {
          conditionColumnDefs.push(storeGroupColDef);
        }
        if(this.viewContext.ctgFromGroup.get("ctgCd0").value != ""){
          conditionColumnDefs.push(sectionColDef);        
          if(this.viewContext.ctgFromGroup.get("ctgCd1").value != ""){
            conditionColumnDefs.push(largeColDef);        
            if(this.viewContext.ctgFromGroup.get("ctgCd2").value != ""){
              conditionColumnDefs.push(middleColDef);        
              if(this.viewContext.ctgFromGroup.get("ctgCd3").value != ""){
                conditionColumnDefs.push(smallColDef); 
              }
            }
          }
        }
        if(this.viewContext.formGroup.get("rank").value != "")
          conditionColumnDefs.push(rankColDef);
        conditionColumnDefs.push(periodColDef);        
        typeColumnDefs = {columnId:'weekDayFv', header:"曜日", width:40, align:"center"}; 
        break;
      default:
        break;
    };
    this.displayColumnDefs = this.displayColumnDefs.concat(conditionColumnDefs);
    this.displayColumnDefs = this.displayColumnDefs.concat(typeColumnDefs);

    if(this.viewContext.formGroup.get("ratioType").get("recommAccptRatio").value)      
      this.displayColumnDefs = this.displayColumnDefs.concat(summaryRecommColumnDefs);
    if(this.viewContext.formGroup.get("ratioType").get("soldOutRatio").value)      
      this.displayColumnDefs = this.displayColumnDefs.concat(summarySoldOutColumnDefs);
    this.setColumnId();    
    this.calcTableWidth();
    this.columnDefs = this.displayColumnDefs;
  }

  doQueryBody(){
    let type = this.viewContext.formGroup.get("type").value;
    let rank = "";
    if(type != OpestatViewContext.ConditionTypeByRank)
      rank= this.viewContext.formGroup.get("rank").value;
    else 
      rank= this.commonService.config.ranks.join(":");
    var request : ReqOpestatMain = {
      access: {...this.commonService.loginUser},
      typeFn: type,
      outputRarFn: this.viewContext.formGroup.get("ratioType").get("recommAccptRatio").value ? 1 : 0,
      outputSoldOutFn: this.viewContext.formGroup.get("ratioType").get("soldOutRatio").value ? 1 : 0,
      distTypeFn: 0,
      dateBegin: this.dateAdapter.format(this.viewContext.formGroup.get("dateBegin").value, ""),
      dateEnd: this.dateAdapter.format(this.viewContext.formGroup.get("dateEnd").value, ""),
      storeCdFv: this.viewContext.formGroup.get("targetStoreCode").value,
      storeGroupTypeCodeFn: 0,
      storeGroupCodeFn: 0,
      ctgLevelFn: this.commonService.config.maxCtgLevel.toString(),
      ctgCd0Fv: this.viewContext.ctgFromGroup.get("ctgCd0").value,
      ctgCd1Fv: this.viewContext.ctgFromGroup.get("ctgCd1").value,
      ctgCd2Fv: this.viewContext.ctgFromGroup.get("ctgCd2").value,
      ctgCd3Fv: this.viewContext.ctgFromGroup.get("ctgCd3").value,      
      rankFv: rank,
      rangeNoFn: 0,
      page: {pageNum: 0, dispNum: 10000}
    };

    let storeGroupTypeCode = parseInt(this.viewContext.formGroup.get("storeGroupTypeCodeForByStore").value);
    let storeGroupCode = parseInt(this.viewContext.formGroup.get("storeGroupCodeForByStore").value);
    if (type == OpestatViewContext.ConditionTypeByStore) {
      if (storeGroupTypeCode != 0) {
        request.storeGroupTypeCodeFn = storeGroupTypeCode;
        request.storeGroupCodeFn = storeGroupCode;
      }
    } else if (type == OpestatViewContext.ConditionTypeByStoreGroup) {
      request.storeGroupTypeCodeFn = parseInt(this.viewContext.formGroup.get("storeGroupTypeCodeForByStoreGroup").value);
    } else {
      if (parseInt(this.viewContext.formGroup.get("targetStoreType").value) != 1) {
        request.storeGroupTypeCodeFn = parseInt(this.viewContext.formGroup.get("targetStoreGroupTypeCode").value);
        request.storeGroupCodeFn = parseInt(this.viewContext.formGroup.get("targetStoreGroupCode").value);
      }
    }
    this.subscriptionSearch = this.httpBasic.opestatMainSearch(request).subscribe(
      data => this.totalResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.commonService.openSpinner(this.commonService.pageTitle, "集計中・・・");
  }

  totalResult(data: RspOpestat){
    this.clearProgressState();

    var rspOpestat : RspOpestat = {...data};

    if (rspOpestat.fatalError && rspOpestat.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspOpestat.fatalError[0].errMsg);
      return;
    }
    if (rspOpestat.normalError && rspOpestat.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspOpestat.normalError[0].errMsg);
      return;
    }

    this.opestatList = [];

    var rspOpestats : RspOpestatMainDto[] = [...rspOpestat.result];
    for (var rspOpestatDto of rspOpestats)
      this.opestatList.push(new OpestatMainRec(rspOpestatDto, this.commonService));
  }
  
  clearProgressState () {
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
    this.commonService.closeSpinner();
  }

  /*
  openSection() {
    this.sectionHeader.openSectionBody();
  }

  closeSection() {
    this.sectionHeader.closeSectionBody();
  }
  */

  copyToCsv() {
    this.commonService.getCsvFromTable('opestat-list-table-' + this.viewContext.id);
  }
}
