import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ItemImageDataRec, ItemImageRec } from '../0_def/imageDbDefs';
import { Subscription } from 'rxjs';
import { ReqAddItemImage, ReqDeleteImageData, ReqGetItemImage, ReqUpdateMainImageId, RspAddItemImage, RspDeleteImageData, RspGetItemImage, RspUpdateMainImageId } from 'src/app/webservice/imageDb';
import { VideoDevice } from '../0_def/videoDevice';

@Component({
  selector: 'app-image-db-edit',
  templateUrl: './image-db-edit.component.html',
  styleUrls: ['./image-db-edit.component.css']
})
export class ImageDbEditComponent implements OnInit, OnDestroy {

  public formItemCd: FormControl = new FormControl("");
  public formItemName: FormControl = new FormControl("");
  public formItemStandard: FormControl = new FormControl("");
  public formMainImage: FormControl = new FormControl("x:0");
  public formFile: FormControl = new FormControl();

  public itemImageRec: ItemImageRec;

  private myKeyForShare: string;
  private subsc: Subscription[] = [];

  public videoDevices: VideoDevice[] = [];

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService
  ) { 
    this.myKeyForShare = this.commonService.config.imageDb.keyForShare;
  }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.subsc.push(this.formFile.valueChanges.subscribe(
      () => {this.fileChanged();}
    ));
    this.subsc.push(this.formMainImage.valueChanges.subscribe(
      () => {this.updateMainImageId();}
    ));

    this.getVideoDevices();
  }

  ngOnDestroy() {
    this.subsc.forEach((subsc) => subsc.unsubscribe());

    this.videoDevices.forEach((device) => {
      device.stopVideo();
    });
  }

  getVideoDevices() {
    navigator.mediaDevices.enumerateDevices().then(
      (devices) => {
        let videos;
        if (this.commonService.config.imageDb.labelFilter !== "") {
          videos = devices.filter((device) => device.kind === "videoinput" && device.label.indexOf(this.commonService.config.imageDb.labelFilter) >= 0 );
        } else {
          videos = devices.filter((device) => device.kind === "videoinput");
        }
        videos.forEach((device) => {
          let deviceClass: VideoDevice = new VideoDevice();
          deviceClass.deviceId = device.deviceId;
          deviceClass.label = device.label;
          this.videoDevices.push(deviceClass);
          console.log("deviceId:", deviceClass.deviceId, "  label:", deviceClass.label);
        });
        if (this.videoDevices.length == 0) {
          this.commonService.openNotificationDialog(this.commonService.pageTitle, "この端末では撮影機能は利用できません。");
        } else {
          setTimeout(() => { this.startVideos(); }, 500);
        }
      }
    );
  }

  startVideos() {
    this.videoDevices.forEach((video) => video.startVideo());
  }

  queryItem() {
    this.formMainImage.setValue("x:0", {emitEvent: false});

    let request: ReqGetItemImage = {
      access: this.commonService.loginUser,
      itemCd: this.formItemCd.value
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemImage", request).subscribe(
      (response: RspGetItemImage) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.ReceiveQueryItem(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  ReceiveQueryItem(response: RspGetItemImage) {
    if (this.httpBasic.handleAppError(response)) return;

    this.itemImageRec = {
      itemCd:         response.itemImage.itemCd,
      itemName:       response.itemImage.itemName,
      itemStandard:   response.itemImage.itemStandard,
      width:          response.itemImage.width,
      height:         response.itemImage.height,
      depth:          response.itemImage.depth,
      weight:         response.itemImage.weight,
      imageData:      [],
      onMaster:       true,
      mainImageKeyForShare: response.itemImage.mainImageKeyForShare,
      mainImageId:    response.itemImage.mainImageId
    };
    if (this.itemImageRec.itemName === "") {
      this.itemImageRec.itemName = "商品マスタに存在しない商品です"
      this.itemImageRec.onMaster = false;
    }
    response.itemImage.imageData.forEach((dto) => {
      let rec: ItemImageDataRec = {
        keyForShare:    dto.keyForShare,
        imageId:        dto.imageId,
        itemCd:         dto.itemCd,
        mainImage:      (response.itemImage.mainImageKeyForShare === dto.keyForShare &&
                         response.itemImage.mainImageId === dto.imageId) ? true : false,
        image:          dto.image,
        thumbnail:      dto.thumbnail
      };
      this.itemImageRec.imageData.push(rec);
    });
    this.sortImages();
    this.setBaseInfo(response.itemImage.mainImageKeyForShare, response.itemImage.mainImageId);
  }

  setBaseInfo(keyForShare: string, mainItemId: number) {
    this.formItemName.setValue(this.itemImageRec.itemName);
    this.formItemStandard.setValue(this.itemImageRec.itemStandard);
    if (mainItemId > 0) {
      this.formMainImage.setValue(keyForShare + ":" + mainItemId, {emitEvent: false});
    }
  }

  getCameraImage() {
    if (!this.itemImageRec) return;

    this.videoDevices.forEach((device) => {
      device.saveImage();
    });

    this.saveCameraImage(0);
  }

  saveCameraImage(index: number) {
    if (index >= this.videoDevices.length) return;

    let device = this.videoDevices[index];
    let imageData: ItemImageDataRec = {
      keyForShare: this.myKeyForShare,
      imageId: -1,
      itemCd: this.itemImageRec.itemCd,
      mainImage: false,
      image: device.fullImage,
      thumbnail: device.thumbnail
    };
    this.addImage(imageData, ()=>{ this.saveCameraImage(++index); });
  }

  getImageFile() {
    if (!this.itemImageRec) return;

    let elem = document.getElementById("input-file") as HTMLInputElement;
    elem.value = "";
    elem.click();
  }

  fileChanged() {
    let fileInput = document.getElementById('input-file')  as HTMLInputElement;
    let file;
    if (fileInput.files[0]) {
      file = fileInput.files[0];
    }

    let fileReader = new FileReader();
    this.commonService.openSpinner(this.commonService.pageTitle, "読込み中・・・")
    fileReader.onload = () => {
      this.commonService.closeSpinner();
      this.readComplete(fileReader);
    }
    fileReader.onerror = () => {
      this.commonService.closeSpinner();
      this.commonService.openErrorDialog(this.commonService.pageTitle, "ファイルの読み込みに失敗しました。<br><br>"+fileReader.error);
      return;
    };

    fileReader.readAsDataURL(file);
  }

  async readComplete(fileReader: FileReader) {
    let url = fileReader.result.toString()
    if (!url.startsWith("data:image")) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "画像データではありません。");
      return;
    }

    let thumbnail = await this.getThumbnailFromImage(url);
    if (!this.itemImageRec.imageData) {
      this.itemImageRec.imageData = [];
    }
    let imageData: ItemImageDataRec = {
      keyForShare: this.myKeyForShare,
      imageId: -1,
      itemCd: this.itemImageRec.itemCd,
      mainImage: false,
      image: url,
      thumbnail: thumbnail
    };
    this.addImage(imageData);
  }

  async getThumbnailFromImage(imageUrl) {
    const THUMBNAIL_WIDTH = 200;
    try {
      if (!imageUrl) return;
      const canvas = document.createElement('canvas');
      canvas.style.display = 'none';

      var image = new Image();
      image.crossOrigin = 'anonymous';
      await new Promise<void>((resolve, reject) => {
        image.onload = function (imageEvent) {
          canvas.width = THUMBNAIL_WIDTH;
          canvas.height = (image.height * THUMBNAIL_WIDTH) / image.width;
          resolve();
        }
        image.addEventListener('error', err => {
          reject(err);
        });
        image.src = imageUrl;
      })
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      return canvas.toDataURL('image/png');
    } catch (error) {
      return null
    }
  }

  addImage(itemImageData: ItemImageDataRec, callback?: ()=>void) {
    let request: ReqAddItemImage = {
      access: this.commonService.loginUser,
      itemImageData: {
        keyForShare:    itemImageData.keyForShare,
        imageId:        itemImageData.imageId,
        itemCd:         itemImageData.itemCd,
        image:          itemImageData.image,
        thumbnail:      itemImageData.thumbnail
      }
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("AddItemImage", request).subscribe(
      (response: RspAddItemImage) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveAddImage(response, itemImageData, callback);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveAddImage(response: RspAddItemImage, itemImageData: ItemImageDataRec, callback?: ()=>void) {
    if (this.httpBasic.handleAppError(response)) return;

    itemImageData.imageId = response.imageId;
    itemImageData.mainImage = response.mainImage;
    if (itemImageData.mainImage) {
      this.itemImageRec.mainImageKeyForShare = itemImageData.keyForShare;
      this.itemImageRec.mainImageId = itemImageData.imageId;
      this.formMainImage.setValue(itemImageData.keyForShare + ":"+ response.imageId, {emitEvent: false});
    }
    this.itemImageRec.imageData.push(itemImageData);
    this.sortImages();
    if (callback) {
      callback();
    }
  }

  updateMainImageId() {
    let mainKeys = (<string>this.formMainImage.value).split(":");
    let mainImageKeyForShare = mainKeys[0];
    let mainImageId = parseInt(mainKeys[1]);

    this.itemImageRec.mainImageKeyForShare = mainImageKeyForShare;
    this.itemImageRec.mainImageId = mainImageId;
    for (let i = 0; i < this.itemImageRec.imageData.length; i++) {
      let img = this.itemImageRec.imageData[i];
      if (img.keyForShare === mainImageKeyForShare && img.imageId == mainImageId) {
        this.itemImageRec.imageData[i].mainImage = true;
        break;
      }
    }

    let request: ReqUpdateMainImageId = {
      access: this.commonService.loginUser,
      itemCd: this.itemImageRec.itemCd,
      mainImageKeyForShare: mainKeys[0],
      mainImageId: parseInt(mainKeys[1])
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdateMainImageId", request).subscribe(
      (response: RspUpdateMainImageId) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveUpdateMainImageId(response);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpdateMainImageId(response: RspUpdateMainImageId) {
    if (this.httpBasic.handleAppError(response)) return;
  }

  deleteImage(image: ItemImageDataRec) {
    let index = this.itemImageRec.imageData.findIndex((rec) => rec == image);
    if (index < 0) return;

    this.itemImageRec.imageData.splice(index, 1);
    if (this.itemImageRec.imageData.length > 0 && image.mainImage) {
      let newMain = this.itemImageRec.imageData[0];
      this.formMainImage.setValue(newMain.keyForShare + ":" + newMain.imageId, {emitEvent: true});
    }

    let request: ReqDeleteImageData = {
      access: this.commonService.loginUser,
      itemCd: image.itemCd,
      keyForShare: image.keyForShare,
      imageId: image.imageId
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("DeleteItemImageData", request).subscribe(
      (response: RspDeleteImageData) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveDeleteImage(response);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDeleteImage(response: RspDeleteImageData) {
    if (this.httpBasic.handleAppError(response)) return;
  }

  sortImages() {
    this.itemImageRec.imageData.sort((a, b) => {
      if (a.keyForShare === this.myKeyForShare) {
        if (b.keyForShare !== this.myKeyForShare) return -1
        return 0;
      }
      if (b.keyForShare === this.myKeyForShare) {
        return 1;
      }
      return 0;
    });
    let tmp = [...this.itemImageRec.imageData];
    this.itemImageRec.imageData = tmp;
  }


  canDeactivate() {
    return true;
    /*
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
    */
  }
}
