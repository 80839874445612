import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { StoreGroupNameRec } from 'src/app/common/store-group-name-rec';
import { StoreGroupRec } from 'src/app/common/store-group-rec';
import { StoreGroupTypeRec } from 'src/app/common/store-group-type-rec';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { StoreGroupDto } from 'src/app/request/req-store-group-add';
import { StoreGroupNameAddDto } from 'src/app/request/req-store-group-name-add';
import { StoreGroupNameDeleteDto } from 'src/app/request/req-store-group-name-delete';
import { StoreGroupNameUpdateDto } from 'src/app/request/req-store-group-name-update';
import { StoreGroupTypeAddDto } from 'src/app/request/req-store-group-type-add';
import { StoreGroupTypeDeleteDto } from 'src/app/request/req-store-group-type-delete';
import { StoreGroupTypeUpdateDto } from 'src/app/request/req-store-group-type-update';
import { RspGetStoreGroupName } from 'src/app/response/rsp-get-store-group-name';
import { RspGetStoreGroupType } from 'src/app/response/rsp-get-store-group-type';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

class MatrixCell {
  groupNameCd: number;
  val: boolean;
}
class MatrixLine {
  storeCd: string;
  storeName: string;
  cells: MatrixCell[];
}

@Component({
  selector: 'app-store-group-mng',
  templateUrl: './store-group-mng.component.html',
  styleUrls: ['./store-group-mng.component.css'],
})
export class StoreGroupMngComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  public directInputForm: FormControl = new FormControl("");
  public storeGroupTypeForm: FormControl = new FormControl("");
  public storeGroupNameForm: FormControl = new FormControl("");
  public storeGroupTypeInsert: FormControl = new FormControl("");
  public storeGroupTypeUpdate: FormControl = new FormControl("");

  public storeGroupName: FormControl = new FormControl("");
  public storeGroupNameUpdate: FormControl = new FormControl("");


  public storeGroupTypeList: StoreGroupTypeRec[] = [];

  public selectedGroupType: StoreGroupTypeRec;
  public sumited$ = new Subject();
  public insertStoreGroupName$ = new Subject();
  public deleteStoreGroupName$ = new Subject();

  public storeGroupList: StoreGroupRec[] = [];
  public storeGroupNameList: StoreGroupNameRec[] = [];
  public selectedStoreGroupName: StoreGroupNameRec;
  public storeMatrix: MatrixLine[];

  public tableWidth: any;
  public columnIds: string[] = [];
  public columnDefs: TableColumnDef[] = [];
  public columnDefGroupDef: TableColumnDef = { columnId: "", header: "", width: 80, align: "center" };
  public tabSelection: number = 0;

  private isReadonly: boolean = false;
  private keypadTargetId: string;
  private subscription$ = new Subject();
  private mapOld = new Map<string, boolean>([]);
  private myMapChanges = new Map<string, boolean>([]);
  private listReflect: string[] = [];
  private storeGroupTypeFormOld: number;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = "店舗グループ管理";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("storegroupmng");

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    this.httpBasic.getStoreGroupType().pipe(takeUntil(this.subscription$)).subscribe(
      (response) => { this.receiveStoreGroupType(response) },
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
    // SELECT STORE GROUP TYPE
    this.storeGroupTypeForm.valueChanges.pipe(
      takeUntil(this.subscription$),
      switchMap(() => this.getListChanges().pipe(
        take(1)))
    ).subscribe(
      (matrixChanges) => {
        if (matrixChanges.length > 0) {
          this.commonService.openYesNoDialog('店舗構成', '変更が保存されていません。変更内容を破棄しますか？').pipe(
            take(1)).subscribe(
              data => {
                if (data === true) {
                  this.myMapChanges.clear();
                  this.selectGroupType(this.storeGroupTypeForm.value);
                } else {
                  this.storeGroupTypeForm.setValue(this.storeGroupTypeFormOld, { emitEvent: false });
                }
              });
        } else {
          this.selectGroupType(this.storeGroupTypeForm.value);
        }
      }
    );
    // Check input textarea
    this.directInputForm.valueChanges.pipe(takeUntil(this.subscription$)).subscribe(
      value => {
        this.chunk(value);
      }
    );
    // GROUP Changes
    this.storeGroupNameForm.valueChanges.pipe(takeUntil(this.subscription$)).subscribe(
      (groupCd) => { this.selectGroup(groupCd); }
    );
    // Submit matrix table
    this.sumited$.pipe(takeUntil(this.subscription$),
      tap(() => this.formGroup.markAsDirty({ onlySelf: true })),
      tap(() => this.formGroup.updateValueAndValidity({ onlySelf: true })),
      switchMap(() => this.getListChanges().pipe(
        take(1)))
    ).subscribe(matrixChanges => {
      if (matrixChanges.length > 0) {
        this.saveGroupDef(matrixChanges)
      }
    });
    // Insert Store Group Name
    this.insertStoreGroupName$.pipe(takeUntil(this.subscription$),
      switchMap(() => this.getListChanges().pipe(take(1)))
    ).subscribe(matrixChanges => {
      if (matrixChanges.length > 0) {
        this.commonService.openYesNoDialog('店舗構成', '変更が保存されていません。変更内容を破棄しますか？').pipe(
          takeUntil(this.subscription$)).subscribe(
            data => {
              if (data === true) {
                this.doInsertStoreGroupName();
              }
            });
      } else {
        this.doInsertStoreGroupName();
      }
    })
    // Delete Group name
    this.deleteStoreGroupName$.pipe(takeUntil(this.subscription$),
      switchMap(() => this.getListChanges().pipe(take(1)))
    ).subscribe(matrixChanges => {
      if (matrixChanges.length > 0) {
        this.commonService.openYesNoDialog('店舗構成', '変更が保存されていません。変更内容を破棄しますか？').pipe(
          takeUntil(this.subscription$)).subscribe(
            data => {
              if (data === true) {
                this.doDeleteStoreGroupName();
              }
            });
      } else {
        this.doDeleteStoreGroupName();
      }
    })
  }

  ngOnDestroy() {
    if (this.commonService.config.isHSD && document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.subscription$.next();
    this.subscription$.complete();
  }

  clearProgressState() {
    this.commonService.closeSpinner();
  }

  receiveStoreGroupType(response: RspGetStoreGroupType) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    this.storeGroupTypeList = [];
    for (let type of response.storeGroupTypes) {
      let typeRec = new StoreGroupTypeRec();
      typeRec.storeGroupTypeCd = type.storeGroupTypeCdFn;
      typeRec.storeGroupType = type.storeGroupTypeFv;
      this.storeGroupTypeList.push(typeRec);
    }
  }

  receiveStoreGroupName(response: RspGetStoreGroupName) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.storeGroupNameList = [];

    for (let name of response.storeGroupNames) {
      let nameRec = new StoreGroupNameRec();
      nameRec.storeGroupTypeCd = name.storeGroupTypeCdFn;
      nameRec.storeGroupName = name.storeGroupNameFv;
      nameRec.storeGroupCd = name.storeGroupCdFn;
      this.storeGroupNameList.push(nameRec);
    }
  }

  tabChanged(tabIndex: number) {
    this.tabSelection = tabIndex;
  }

  selectGroupType(groupTypeCd: number) {
    if (this.selectedGroupType && this.selectedGroupType.storeGroupTypeCd === groupTypeCd) return;

    let type = this.findStoreGroupType(groupTypeCd);
    if (type == undefined) return;
    this.storeGroupTypeFormOld = groupTypeCd;
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.selectedGroupType = type;
      this.selectedStoreGroupName = undefined;
      this.storeGroupNameForm.setValue("");
      this.directInputForm.setValue("");
      this.directInputForm.markAsPristine();
      this.storeGroupSearch(this.selectedGroupType);
      return;
    }

    (deactivate as Observable<boolean>).pipe(takeUntil(this.subscription$)).subscribe(
      response => {
        if (response == true) {
          this.selectedGroupType = type;
          this.selectedStoreGroupName = undefined;
          this.storeGroupNameForm.setValue("");
          this.directInputForm.setValue("");
          this.directInputForm.markAsPristine();
          this.storeGroupSearch(this.selectedGroupType);
        }
      }
    );
  }


  storeGroupSearch(type: StoreGroupTypeRec) {
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
    forkJoin([
      this.httpBasic.getStoreGroupName(type.storeGroupTypeCd),
      this.httpBasic.getGroupTypeList(type.storeGroupTypeCd)
    ]
    ).pipe(takeUntil(this.subscription$)).subscribe(
      response => {
        this.storeGroupNameList = [];
        this.storeGroupList = [];
        this.mapOld.clear();
        this.myMapChanges.clear();
        this.receiveStoreGroupSearch(response);
        if (this.storeGroupNameList.length > 0) {
          this.storeGroupNameForm.setValue(this.storeGroupNameList[0].storeGroupCd);
        }
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveStoreGroupSearch(response) {
    this.clearProgressState();
    if (response[0] && this.httpBasic.handleAppError(response[0])) { return; }
    if (response[1] && this.httpBasic.handleAppError(response[1])) { return; }
    if (response[0] && response[0].storeGroupNames !== undefined && response[0].storeGroupNames.length >= 0) {
      this.storeGroupNameList = [];
      response[0].storeGroupNames.forEach(element => {
        const storeGroupItem: StoreGroupNameRec = {
          storeGroupCd: element.storeGroupCdFn,
          storeGroupTypeCd: element.storeGroupTypeCdFn,
          storeGroupName: element.storeGroupNameFv
        };
        this.storeGroupNameList.push(
          storeGroupItem
        );
      });
    }

    if (response[1] && response[1].result !== undefined && response[1].result !== null && response[1].result.length >= 0) {
      this.storeGroupList = [];
      response[1].result.forEach(element => {
        const storeGroupItem: StoreGroupRec = {
          storeGroupTypeCd: 0,
          storeGroupCd: +element.storeGroupCdFv,
          storeGroupName: element.storeGroupNameFv,
          storeCd: element.storeCdFv,
          storeName: element.storeNameFv,
          storeDispName: element.storeCdFv + ':' + element.storeNameFv,
        };
        this.storeGroupList.push(
          storeGroupItem
        );
      });
    }
    this.buildMatrix();
    this.buildFormGroup();
    this.buildTableColumn();
    this.calcTableWidth();
  }

  selectedGroupTypeId() {
    return this.selectedGroupType ? this.selectedGroupType.storeGroupTypeCd : 0;
  }
  selectedGroupTypeName() {
    return this.selectedGroupType ? this.selectedGroupType.storeGroupType : "";
  }
  selectedGroupNameId() {
    return this.selectedStoreGroupName ? this.selectedStoreGroupName.storeGroupCd : 0;
  }
  selectedGroupName() {
    return this.selectedStoreGroupName ? this.selectedStoreGroupName.storeGroupName : "";
  }

  selectGroup(groupCd: number) {
    let tmp = this.findStoreGroupName(groupCd);
    if (tmp == undefined) return;
    this.selectedStoreGroupName = tmp;
    this.buildDirectInput();
  }

  isSelectedGroup(columnId: string) {
    if (!this.selectedStoreGroupName) return false;
    if (this.selectedStoreGroupName.storeGroupCd == parseInt(columnId.substring(8))) return true;
    return false;
  }


  saveGroupDef(listChanges: StoreGroupDto[]) {
    this.commonService.openSpinner(this.commonService.pageTitle, '登録中・・・');
    this.httpBasic.addStoreGroup(listChanges).pipe(takeUntil(this.subscription$)).subscribe(
      response => {
        // receiveStoreGroupSearch
        this.mapOld.clear();
        this.myMapChanges.clear();
        this.storeGroupList = [];
        const arrayRes = new Array();
        arrayRes[1] = response;
        this.receiveStoreGroupSearch(arrayRes);
        this.buildDirectInput();
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }
  getListChanges(): Observable<StoreGroupDto[]> {
    const list: StoreGroupDto[] = [];
    for (const [key, value] of this.myMapChanges) {
      if (this.mapOld.has(key) && this.mapOld.get(key) === this.myMapChanges.get(key)) {
        continue;
      }
      const storeGroupItem: StoreGroupDto = {
        storeCdFv: key.split('-')[0].substring(2),
        storeGroupCdFv: key.split('-')[1].substring(3),
        storeGroupTypeCdFv: this.selectedGroupType.storeGroupTypeCd,
        isCheck: value
      };
      list.push(storeGroupItem);
    }
    return of(list);
  }

  cancelGroupDef() {
    this.myMapChanges.clear();
    for (const [key, value] of this.mapOld) {
      this.formGroup.get(key).setValue(value, { emitEvent: false })
    }

  }

  findStoreGroupType(code: number) {
    for (let type of this.storeGroupTypeList) {
      if (type.storeGroupTypeCd == code) return type;
    }
    return undefined;
  }

  findStoreGroupName(code: number) {
    for (let gname of this.storeGroupNameList) {
      if (gname.storeGroupCd == code) return gname;
    }
    return undefined;
  }

  findStoreGroup(storeCd: string, groupCd: number) {
    for (let sgroup of this.storeGroupList) {
      if (sgroup.storeCd === storeCd && sgroup.storeGroupCd == groupCd) return sgroup;
    }
    return undefined;
  }
  findStoreGroupChanges(storeCd: string, groupCd: number) {
    // UPDATE MATRIX
    if (this.myMapChanges.size > 0) {
      const list = [];
      for (const [key, value] of this.myMapChanges) {
        if (this.mapOld.has(key) && this.mapOld.get(key) === this.myMapChanges.get(key)) {
          continue;
        }
        const storeGroupItem: StoreGroupDto = {
          storeCdFv: key.split('-')[0].substring(2),
          storeGroupCdFv: key.split('-')[1].substring(3),
          storeGroupTypeCdFv: this.selectedGroupType.storeGroupTypeCd,
          isCheck: value
        };
        if (storeGroupItem.storeCdFv === storeCd && storeGroupItem.storeGroupCdFv == groupCd) {
          return value;
        };
      }
    }
    return null;
  }

  buildMatrix() {
    this.storeMatrix = [];
    let stores = this.commonService.stores;
    if (this.commonService.config.includeNonAutoOrderStore.storeGroup) {
      stores = this.commonService.allStores;
    }

    for (let store of stores) {
      let line: MatrixLine = new MatrixLine();
      line.storeCd = store.storeCd;
      line.storeName = store.storeCd + ":" + store.storeName;
      line.cells = [];
      for (let gname of this.storeGroupNameList) {
        let cell: MatrixCell = new MatrixCell();
        let sg = this.findStoreGroup(store.storeCd, gname.storeGroupCd);
        cell.groupNameCd = gname.storeGroupCd;
        cell.val = (sg == undefined) ? false : true;
        line.cells.push(cell);
      }
      this.storeMatrix.push(line);
    }
  }

  formName(storeCd: string, groupNameCd: number) {
    return "sc" + storeCd + "-gnc" + groupNameCd;
  }
  formNameForHtml(storeCd: string, columnId: string) {
    return this.formName(storeCd, parseInt(columnId.substring(8))); /* remove gnamecd_ */
  }

  buildFormGroup() {
    this.formGroup = this.fb.group({});
    for (let line of this.storeMatrix) {
      const formGroup = this.fb.group({});
      for (let cell of line.cells) {
        let fc = new FormControl();
        fc.setValue(cell.val);
        this.formGroup.addControl(this.formName(line.storeCd, cell.groupNameCd), fc);
        this.mapOld.set(this.formName(line.storeCd, cell.groupNameCd), cell.val);
        formGroup.addControl(this.formName(line.storeCd, cell.groupNameCd), fc);
        let sgChanges = this.findStoreGroupChanges(line.storeCd, cell.groupNameCd);
        if (sgChanges !== null) {
          this.formGroup.get(this.formName(line.storeCd, cell.groupNameCd)).setValue(sgChanges);
          this.myMapChanges.set(this.formName(line.storeCd, cell.groupNameCd), sgChanges);
        }
        fc.valueChanges.pipe(takeUntil(this.subscription$)).subscribe(
          (control) => this.matricChanged(this.formName(line.storeCd, cell.groupNameCd), formGroup, control)
        );
      }
    }
  }
  buildDirectInput() {
    if (!this.selectedStoreGroupName) return;
    const storeGroup = this.storeGroupNameForm.value;

    const storesKey = Object.keys(this.formGroup.value).filter(key =>
      this.keyToGroupCode(key, storeGroup) && this.formGroup.get(key).value === true).map(key => key.split('-')[0].substring(2));
    let stores = '';
    for (const store of storesKey) {
      stores += store + "\n"
    }
    this.directInputForm.setValue(stores);
    this.directInputForm.markAsPristine();
  }

  buildTableColumn() {
    this.columnIds = ["storeName"];
    /*
      Column for storeName is written in HTML/CSS because ngstyle does not work correctly
      in case of sticky column.
    */
    this.columnDefs = [];
    for (let gname of this.storeGroupNameList) {
      this.columnIds.push("gnamecd_" + gname.storeGroupCd);

      let def = { ...this.columnDefGroupDef };
      def.columnId = "gnamecd_" + gname.storeGroupCd;
      def.header = gname.storeGroupName;
      this.columnDefs.push(def);
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    width = width + 150 + 1 + 8;    // for store name
    for (var colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }
  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  matricChanged(control: string, groupStore: FormGroup, value: boolean) {
    if (!groupStore.dirty) {
      return;
    }
    const arrayKeyOfFormGroup = Object.keys(groupStore.value);
    arrayKeyOfFormGroup.map(key => {
      this.formGroup.get(key).setValue(false, { emitEvent: false });
      this.myMapChanges.set(key, false);
    });
    this.formGroup.get(control).setValue(value, { emitEvent: false });
    this.myMapChanges.set(control, value);
  }

  canDeactivate(): boolean | Observable<boolean> {
    let isDeactive = true;
    this.getListChanges().pipe(takeUntil(this.subscription$)).subscribe(val => {
      if (val.length > 0) {
        isDeactive = false;
      }
    });
    if (isDeactive) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
  doInsertStoreGroupType() {
    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    var request: StoreGroupTypeAddDto = {
      storeGroupTypeFv: this.storeGroupTypeInsert.value.trim()
    }
    this.httpBasic.addStoreGroupType(request).pipe(takeUntil(this.subscription$)).subscribe(
      result => {
        this.storeGroupTypeInsert.setValue('', { emitEvent: false })
        this.receiveStoreGroupType(result);
        if (result.fatalError && result.fatalError.length > 0 || result.normalError && result.normalError.length > 0) {
          return;
        }
        const maxID = Math.max(...this.storeGroupTypeList.map(grType => grType.storeGroupTypeCd));
        this.storeGroupTypeForm.setValue(maxID, { emitEvent: true });
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  isDisabledInsertStoreGroupType() {
    return this.storeGroupTypeInsert.value.trim() === "";
  }

  doInsertStoreGroupName() {
    this.commonService.openSpinner(this.commonService.pageTitle, '登録中・・・');
    const request: StoreGroupNameAddDto = {
      storeGroupNameFv: this.storeGroupName.value.trim(),
      storeGroupTypeCdFn: this.storeGroupTypeForm.value
    }
    this.httpBasic.addStoreGroupName(request).subscribe(
      result => {
        const array = [];
        array[0] = result;
        this.mapOld.clear();
        this.myMapChanges.clear();
        this.receiveStoreGroupSearch(array);
        if (result.fatalError && result.fatalError.length > 0 || result.normalError && result.normalError.length > 0) {
          return;
        }
        this.storeGroupName.setValue('', { emitEvent: false });
        const maxID = Math.max(...this.storeGroupNameList.map(grType => grType.storeGroupCd));
        this.storeGroupNameForm.setValue(maxID, { emitEvent: true });
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  isDisabledInsertStoreGroupName() {
    if (this.selectedGroupType !== undefined && this.selectedGroupType.storeGroupType !== '' && this.storeGroupName.value.trim() !== '') {
      return false;
    }
    return true;
  }

  doUpdateStoreGroupType() {
    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    var request: StoreGroupTypeUpdateDto = {
      storeGroupTypeFv: this.storeGroupTypeUpdate.value.trim(),
      storeGroupTypeCdFn: this.storeGroupTypeForm.value
    }
    this.httpBasic.updateStoreGroupType(request).subscribe(
      result => {
        this.receiveStoreGroupType(result);
        if (result.fatalError && result.fatalError.length > 0 || result.normalError && result.normalError.length > 0) {
          return;
        }
        this.selectedGroupType.storeGroupType = this.storeGroupTypeUpdate.value;
        this.storeGroupTypeUpdate.setValue('', { emitEvent: false });
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  doUpdateStoreGroupName() {
    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    var request: StoreGroupNameUpdateDto = {
      storeGroupNameFv: this.storeGroupNameUpdate.value.trim(),
      storeGroupNameCdFn: this.storeGroupNameForm.value,
      storeGroupTypeCdFn: this.storeGroupTypeForm.value
    }
    this.httpBasic.updateStoreGroupName(request).subscribe(
      result => {
        const array = [];
        array[0] = result;
        this.receiveStoreGroupSearch(array);
        if (result.fatalError && result.fatalError.length > 0 || result.normalError && result.normalError.length > 0) {
          return;
        }
        if (this.storeGroupNameList.length > 0 && this.selectedStoreGroupName) {
          this.selectedStoreGroupName.storeGroupName = this.storeGroupNameUpdate.value;
          this.storeGroupNameForm.setValue(this.selectedStoreGroupName.storeGroupCd, { emitEvent: false });
        }
        this.storeGroupNameUpdate.setValue('', { emitEvent: false });
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  doDeleteStoreGroupType() {
    let msg = "<span > 店舗グループ種別 「 " + this.selectedGroupType.storeGroupType + "」 を削除しますか？";
    msg += "<br>この種別の店舗グループも同時に削除されます。";
    msg += "</span>";
    this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).pipe(takeUntil(this.subscription$)).subscribe(
      data => {
        if (data == true) {
          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          const request: StoreGroupTypeDeleteDto = {
            storeGroupTypeCdFn: this.storeGroupTypeForm.value
          }
          this.httpBasic.deleteStoreGroupType(request).subscribe(
            result => {
              this.receiveStoreGroupType(result);
              if (result.fatalError && result.fatalError.length > 0 || result.normalError && result.normalError.length > 0) {
                return;
              }
              this.storeGroupNameList = [];
              this.storeGroupTypeForm.setValue(this.storeGroupTypeList[0].storeGroupTypeCd)

            },
            error => {
              this.clearProgressState();
              this.httpBasic.handleError(error);
            }
          );
        }
      }
    );
    return;
  }

  doDeleteStoreGroupName() {
    this.commonService.openSpinner(this.commonService.pageTitle, '削除中・・・');
    const request: StoreGroupNameDeleteDto = {
      storeGroupNameCdFn: this.storeGroupNameForm.value,
      storeGroupTypeCdFn: this.storeGroupTypeForm.value
    }
    this.httpBasic.deleteStoreGroupName(request).subscribe(
      result => {
        this.selectedStoreGroupName = undefined;
        const array = [];
        array[0] = result;
        this.mapOld.clear();
        this.myMapChanges.clear();
        this.receiveStoreGroupSearch(array);
        if (this.storeGroupNameList.length > 0) {
          this.storeGroupNameForm.setValue(this.storeGroupNameList[0].storeGroupCd);
        } else {
          this.storeGroupNameForm.setValue('');
          this.directInputForm.setValue('', { emitEvent: false });
        }
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );

  }

  chunk(value: any) {
    if (value === undefined || value === null || value === '') {
      this.listReflect = [];
      return;
    }
    const result = value.split('\n');
    this.listReflect = result.map(elment => elment.trim()).filter(element => element !== '');

  }
  applyStoreCode() {
    const storeGroup = this.storeGroupNameForm.value;
    Object.keys(this.formGroup.value).filter(key => this.keyToGroupCode(key, storeGroup)).map(
      key => {
        this.formGroup.get(key).setValue(false, { emitEvent: false })
        this.myMapChanges.set(key, false);
      }
    );
    if (this.listReflect.length < 1) {
      return;
    }
    const storeCds = [];
    let stores = this.commonService.stores;
    if (this.commonService.config.includeNonAutoOrderStore.storeGroup) {
      stores = this.commonService.allStores;
    }
    for (const store of stores) {
      storeCds.push(store.storeCd)
    }
    this.listReflect.filter(key => storeCds.includes(key)).map(element => {
      if (/\s/g.test(element)) {
        Object.keys(this.formGroup.value).filter(key => this.keyToStoreCode(key, element.replace(' ', ''))).map(
          key => {
            this.formGroup.get(key).setValue(false, { emitEvent: false })
            this.myMapChanges.set(key, false);
          }
        )
        const key = this.formName(element.replace(' ', ''), storeGroup);
        if (this.formGroup.get(key)) {
          this.formGroup.get(key).setValue(true, { emitEvent: false });
          this.myMapChanges.set(key, true);
        }

      } else {
        Object.keys(this.formGroup.value).filter(key => this.keyToStoreCode(key, element)).map(
          key => {
            this.formGroup.get(key).setValue(false, { emitEvent: false })
            this.myMapChanges.set(key, false);
          }
        )
        const key = this.formName(element, storeGroup);
        if (this.formGroup.get(key)) {
          this.formGroup.get(key).setValue(true, { emitEvent: false });
          this.myMapChanges.set(key, true);
        }

      }
    });
  }
  keyToStoreCode(key: string, element: string): boolean {
    return key.split('-')[0].substring(2) === element;
  }
  keyToGroupCode(key: string, element: any): boolean {
    return key.split('-')[1].substring(3) == element;
  }
  customTrackBy(index, item) { return item.columnId; }

  isDisabledUpdateStoreGroupType() {
    if (this.selectedGroupType !== undefined && this.selectedGroupType.storeGroupType !== '' && this.storeGroupTypeUpdate.value.trim() !== '') {
      if (this.storeGroupTypeUpdate.value.trim() !== this.selectedGroupType.storeGroupType) {
        return false;
      }
    }
    return true;
  }
  isDisableUpdateStoreGroupName() {
    if (this.selectedStoreGroupName !== undefined && this.selectedStoreGroupName.storeGroupName !== '' && this.storeGroupNameUpdate.value.trim() !== '') {
      if (this.selectedStoreGroupName.storeGroupName !== this.storeGroupNameUpdate.value.trim()) {
        return false;
      }
    }
    return true;
  }
}
