import { DecimalPipe } from '@angular/common';
import { RspForecastUpDownDto } from 'src/app/response/rsp-forecast-up-down';

export class ForecastUpDownRec {
    storeCdFv: string;
    storeNameFv: string;
    ctg0CdFv: string;
    ctg0NameFv: string;
    ctg1CdFv: string;
    ctg1NameFv: string;
    ctg2CdFv: string;
    ctg2NameFv: string;
    ctg3CdFv: string;
    ctg3NameFv: string;
    rankFv: string;
    rateFn: number;
    dateBeginFv: string;
    dateEndFv: string;
    noiseCutFn: number;

    storeFv: string;
    ctg0Fv: string;
    ctg1Fv: string;
    ctg2Fv: string;
    ctg3Fv: string;
    rateFv: string;
    noiseCutFv: string;

    constructor(rspForecastUpDownDto: RspForecastUpDownDto, decimalPipe: DecimalPipe) {
        for (var key of Object.keys(rspForecastUpDownDto)) {
            this[key] = rspForecastUpDownDto[key];
        }

        if (this.storeCdFv != null)
            this.storeFv = this.storeCdFv + ":" + this.storeNameFv;
        if (this.ctg0CdFv != null)
            this.ctg0Fv = this.ctg0CdFv + ":" + this.ctg0NameFv;
        if (this.ctg1CdFv != null)
            this.ctg1Fv = this.ctg1CdFv + ":" + this.ctg1NameFv;
        if (this.ctg2CdFv != null)
            this.ctg2Fv = this.ctg2CdFv + ":" + this.ctg2NameFv;
        if (this.ctg3CdFv != null)
            this.ctg3Fv = this.ctg3CdFv + ":" + this.ctg3NameFv;
        this.rateFv = decimalPipe.transform(this.rateFn, "1.2-2");
        if (this.noiseCutFn != 0)
            this.noiseCutFv = "有効";
        else
            this.noiseCutFv = "無効";
    }
}