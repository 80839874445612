import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Rsp } from 'src/app/response/rsp';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

export interface TaskStatusStore {
  storeCd:      string;
  storeName:    string;
  toToday:      number;
  notComplete:  number;
  expired:      number;
}
export interface ResTaskDashboardHq extends Rsp {
  statusList: TaskStatusStore[];
}

@Component({
  selector: 'app-task-dashboard-hq',
  templateUrl: './task-dashboard-hq.component.html',
  styleUrls: ['./task-dashboard-hq.component.css']
})
export class TaskDashboardHqComponent implements OnInit, OnDestroy {

  public taskStatusList: TaskStatusStore[] = [];
  public columnIds: string[] = ["store", "toToday", "notComplete", "expired"];
  public columnDefs: TableColumnDef[] = [
    {columnId: "store", header: "店舗", width: 150},
    {columnId: "toToday", header: "本日期限", width: 100, align: "center"},
    {columnId: "notComplete", header: "未完了", width: 100, align: "center"},
    {columnId: "expired", header: "期限切れ", width: 100, align: "center"},
  ];

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private router : Router
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  @HostListener('window:loginComplete', ['$event'])
  handleLoginComplete() {
    if (this.commonService.hasMenu("taskHq") === true) {
      this.getStatusList();
    }
  }

  isOpen() {
    if (!this.commonService.openDashboardHq) return false;
    if (this.commonService.menuGroups === undefined) return false;
    if (!this.commonService.hasMenu("taskHq")) return false;
    return true;
  }

  closeDashboard() {
    this.commonService.openDashboardHq = false;
    window.dispatchEvent(new Event('resize'));
  }

  reSearchDashboard() {
    this.getStatusList();
  }

  onClickCell(item: TaskStatusStore, key: string) {
    if (key === "store") return;
    if (item.storeCd === "*" && !this.commonService.hasMenu("taskHq")) return;
    if (item.storeCd !== "*" && !this.commonService.hasMenu("taskStore")) return;

    if (key === "toToday") {
      this.commonService.taskSearchCond = {
        storeCd:                item.storeCd === "*" ? "" : item.storeCd,
        releaseStatus:          "公開済",
        author:                 "",
        statusNotAssigned:      true,
        statusNotStarted:       true,
        statusStarted:          true,
        statusCompleted:        true,
        endDate:                true,
        endDateFrom:            0,
        endDateTo:              0,
        endDateFromDay:         0,
        endDateToDay:           0,
        responsibleClass:       "",
        responsible:            "",
        responsiblePartial:     true,
        taskName:               "",
        subStatusNotCompleted:  true,
        subStatusCompleted:     true,
        subDateYesterday:       true,
        subDateToday:           true,
        subDateTommorow:        true,
        subResponsible:         "",
        subResponsiblePartial:  true,
        taskCategory:           ""
      }
    } else if (key === "notComplete") {
      this.commonService.taskSearchCond = {
        storeCd:                item.storeCd === "*" ? "" : item.storeCd,
        releaseStatus:          "公開済",
        author:                 "",
        statusNotAssigned:      true,
        statusNotStarted:       true,
        statusStarted:          true,
        statusCompleted:        false,
        endDate:                true,
        endDateFrom:            this.commonService.config.task.searchCond.endDateFrom,
        endDateTo:              this.commonService.config.task.searchCond.endDateTo,
        endDateFromDay:         0,
        endDateToDay:           0,
        responsibleClass:       "",
        responsible:            "",
        responsiblePartial:     true,
        taskName:               "",
        subStatusNotCompleted:  true,
        subStatusCompleted:     true,
        subDateYesterday:       true,
        subDateToday:           true,
        subDateTommorow:        true,
        subResponsible:         "",
        subResponsiblePartial:  true,
        taskCategory:           ""
      }
    } else if (key === "expired") {
      this.commonService.taskSearchCond = {
        storeCd:                item.storeCd === "*" ? "" : item.storeCd,
        releaseStatus:          "公開済",
        author:                 "",
        statusNotAssigned:      true,
        statusNotStarted:       true,
        statusStarted:          true,
        statusCompleted:        false,
        endDate:                true,
        endDateFrom:            this.commonService.config.task.searchCond.endDateFrom,
        endDateTo:              0,
        endDateFromDay:         0,
        endDateToDay:           -1,
        responsibleClass:       "",
        responsible:            "",
        responsiblePartial:     true,
        taskName:               "",
        subStatusNotCompleted:  true,
        subStatusCompleted:     true,
        subDateYesterday:       true,
        subDateToday:           true,
        subDateTommorow:        true,
        subResponsible:         "",
        subResponsiblePartial:  true,
        taskCategory:           ""
      }
    }

    this.commonService.openDashboardHq = false;
    this.commonService.openDashboardStore = false;
    this.commonService.openNotification = false;

    if (item.storeCd === "*") {
      let menuItem = this.getMenuItem("taskHq");
      if (menuItem) {
        this.commonService.pageMenuName = menuItem.menuName;
        this.router.navigate(["taskHq"], {queryParams: {rn: Math.random()}});
      }
    } else {
      let menuItem = this.getMenuItem("taskStore");
      if (menuItem) {
        this.commonService.pageMenuName = menuItem.menuName;
        this.router.navigate(["taskStore"], {queryParams: {rn: Math.random()}});
      }
    }
  }

  getMenuItem(path: string) {
    for (let menuGroup of this.commonService.menuGroups) {
      for (let menuItem of menuGroup.menuItems) {
        if (path === menuItem.menuPath) return menuItem;
      }
    }
    return undefined;
  }

  getStatusList() {
    let req = {
      access: this.commonService.loginUser,
      includeNonAutoOrderStoreTask: this.commonService.config.includeNonAutoOrderStore.task,
      endDateFrom: this.commonService.config.task.searchCond.endDateFrom,
      endDateTo:   this.commonService.config.task.searchCond.endDateTo
    };
    // this.httpBasic.generalRequest("TaskDashboardHq", req).subscribe(
    //   (response: ResTaskDashboardHq) => {
    //     this.receiveTaskDashboardHq(response);
    //   },
    //   (error) => {
    //     this.httpBasic.handleError(error);
    //   }
    // );
  }

  receiveTaskDashboardHq(response: ResTaskDashboardHq) {
    if (this.httpBasic.handleAppError(response)) return;
    this.taskStatusList = [];
    response.statusList.forEach(row => this.taskStatusList.push(row));
    this.taskStatusList.sort((a, b) => {
      if (a.storeCd === "*") return -1;
      if (b.storeCd === "*") return 1;
      if (this.commonService.zeroPadding(a.storeCd, 100) < this.commonService.zeroPadding(b.storeCd, 100)) return -1;
      if (this.commonService.zeroPadding(a.storeCd, 100) > this.commonService.zeroPadding(b.storeCd, 100)) return 1;
      return 0;
    });
  }

  getValue(item: TaskStatusStore, key: string) {
    switch(key) {
      case "store":
        return item.storeCd + "：" + item.storeName;
    }
    return item[key];
  }

}
