import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { NoisecutHistoryDto, RspNoisecutHistory } from 'src/app/response/rsp-noisecut-history';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CommonService } from 'src/app/service/common.service';
import { ReqPageDto } from 'src/app/request/req-page-dto';

@Component({
  selector: 'app-noisecut-history',
  templateUrl: './noisecut-history.component.html',
  styleUrls: ['./noisecut-history.component.css']
})
export class NoisecutHistoryComponent implements OnInit, OnDestroy {

  public histList: NoisecutHistoryDto[] = [];
  public displayColumnIds: string[] = ["noisecutTypeName", "noisecutName", "dateBegin", "dateEnd", "orderNum"];
  public colDefs: TableColumnDef[] = [
    {columnId:'noisecutTypeName', header:"区分", width:100, align:"center"},
    {columnId:'noisecutName', header:"企画名", width:300, align:"left"},
    {columnId:'dateBegin', header:"開始日", width:80, align:"center"},
    {columnId:'dateEnd', header:"終了日", width:80, align:"center"},
    {columnId:'orderNum', header:"数量", width:40, align:"right"}
  ];
  public tableWidth: any;
  public pagenatorMaxWidth: any;
  public storeCd: string = "";
  public itemCd: string = "";
  public itemName: string = "";
  public recordCount: number = 0;

  private subscriptionSearch : Subscription;

  @ViewChild(MatPaginator, {static: false}) matPaginator: MatPaginator;

  constructor(
    public httpBasic: HttpBasicService,
    public commonService: CommonService) { }

  ngOnInit() {
    this.calcNoisecutTableWidth();
    /*
    this.matPaginator.pageIndex = 0;
    this.matPaginator.pageSize = this.commonService.paginatorOption.pageSizeOptions[
      this.commonService.paginatorOption.pageSizeIndex
    ];
    */
  }

  ngOnDestroy() {
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
    }
  }

  styleFor(id:string) {
    for (var colDef of this.colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id:string) {
    for (var colDef of this.colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  calcNoisecutTableWidth() {
    var width = 1;   // For left border

    for (var colDef of this.colDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = {"width": "" + width + "px"};
    this.pagenatorMaxWidth = {"max-width": "" + width + "px"};
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }

  getNoisecutHistory(storeCd: string, itemCd: string, itemName: string) {
    this.storeCd = storeCd;
    this.itemCd = itemCd;
    this.itemName = itemName;

    this.matPaginator.pageIndex = 0;
    this.getNoisecutHistoryBody();
  }

  getNoisecutHistoryBody() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    let page: ReqPageDto = {
      pageNum: this.matPaginator.pageIndex,
      dispNum: this.matPaginator.pageSize
    };

    this.subscriptionSearch = this.httpBasic.getNoisecutHistory(
      this.storeCd, this.itemCd, page
    ).subscribe(
      (response) => this.receiveNoisecutHistory(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveNoisecutHistory(response: RspNoisecutHistory) {
    this.clearProgressState();

    if (this.httpBasic.handleAppError(response)) return;

    this.recordCount = response.recordCount;
    this.histList = [...response.history];
  }

  pageChanged(event: PageEvent) {
    if (this.histList == undefined || this.histList.length == 0) return;
    this.getNoisecutHistoryBody();
  }

  getValue(item: NoisecutHistoryDto, key: string) {
    switch(key) {
      case "noisecutTypeName":
        if (item.noisecutTypeName == "客注") return this.commonService.literal.customerOrder;
        return item[key];
      default:
        return item[key];
    }
  }
}
