import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import { HttpBasicService } from 'src/app/service/http-basic.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService,
    private router: Router
    ) {
  }

  ngOnInit() {
    if (this.commonService.loginUser) {
      // this.httpBasic.logout();
      this.commonService.loginUser = undefined;
    }
    this.router.navigate(['login']);
  }

}
