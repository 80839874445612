import { Injectable, OnInit } from '@angular/core';
import { TableColumnDef } from '../common/table-column-def';
import { MatrixType } from '../page/analysis/analysis-context';

export interface GraphType {
  id: number;
  type: string;
  name: string;
  groupType: boolean;
}

export interface GraphOption {
  graphType: number;
  showLabels: boolean;
  animations: boolean;
  xAxis: boolean;
  yAxis: boolean;
  showYAxisLabel: boolean;
  showXAxisLabel: boolean;
  xAxisLabel: string;
  yAxisLabel: string;
  legend: boolean;
  legendTitle: string;
  legendPosition?: string;      // 'right', 'below'
  timeline: boolean;
  autoScale: boolean;
  yScaleMin: number;
  yScaleMax: number;
  gradient: boolean;
  roundDomains: boolean;
  trimYAxisTicks: boolean;
  labels: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  static GRAPH_TYPE_LINE = 0;
  static GRAPH_TYPE_AREA = 1;
  static GRAPH_TYPE_STACKED_AREA = 2;
  static GRAPH_TYPE_NORMALIZED_AREA = 3;
  static GRAPH_TYPE_VERTICAL_BAR = 4;
  static GRAPH_TYPE_GROUPED_VERTICAL_BAR = 5;
  static GRAPH_TYPE_STACKED_VERTICAL_BAR = 6;
  static GRAPH_TYPE_NORMALIZED_VERTICAL_BAR = 7;
  static GRAPH_TYPE_PIE = 8;

  static graphTypes: GraphType[] = [
    {id: 0, type:"Line", name: "折れ線グラフ", groupType: true},
    {id: 1, type:"Area", name: "面グラフ", groupType: true},
    {id: 2, type:"StackedArea", name: "積み上げ面グラフ", groupType: true},
    {id: 3, type:"NormalizedArea", name: "正規化面グラフ", groupType: true},
    {id: 4, type:"VerticalBar", name: "縦棒グラフ", groupType: false},
    {id: 5, type:"GroupedVerticalBar", name: "グループ化縦棒グラフ", groupType: true},
    {id: 6, type:"StackedVerticalBar", name: "積み上げ縦棒グラフ", groupType: true},
    {id: 7, type:"NormalizedVerticalBar", name: "正規化縦棒グラフ", groupType: true},
    {id: 8, type:"Pie", name: "円グラフ", groupType: false}
  ];
  
  constructor(
  ) {
  }

  /*
    Line/Stacked Are/Normalized Are Chart (Grouped by ItemName)
    {
      "name":"販売",
      "series": [
        {"name": "2020/10/16", "value": 4951},
        {"name": "2020/10/30", "value": 4957}
      ]
    }
  */
  buildGraphDataGroupedByItemName(matrixType: MatrixType, matrixData: Object[], matrixColDef: TableColumnDef[]) {
    let graphData = [];
    let startIndex = matrixType.rowHeaderCols.length + 1;
    for (let mrow of matrixData) {
      let series = [];
      for (let i = startIndex; i < matrixColDef.length; i++) {
        let name = matrixColDef[i].header;
        let value = mrow[matrixColDef[i].columnId];
        if (value == undefined || value === "") continue;
        series.push({name: name, value: value});
      }

      let seriesName = "";
      for (let i = 0; i < matrixType.rowHeaderCols.length; i++) {
        if (seriesName !== "") {
          seriesName += "-" + mrow[matrixColDef[i].columnId];
        } else {
          seriesName += mrow[matrixColDef[i].columnId];
        }
      }
      if (seriesName === "") {
        seriesName = mrow["__itemName"];
      } else if (matrixType.dataHeaderCols.length > 1) {
        seriesName += "-" + mrow["__itemName"];
      }
      graphData.push({name: seriesName, series: series});
    }

    return graphData;
  }

  /*
    Grouped/Stacked/Normalized Bar Chart (Grouped by XAxis)
    {
      "name": "2020/10/16",
      "series": [
        {"name": "在庫", "value": 40632},
        {"name": "販売", "value": 1953}
      ]
    }
  */
  buildGraphDataGroupedByXAxis(matrixType: MatrixType, matrixData: Object[], matrixColDef: TableColumnDef[]) {
    let graphData = [];
    let startIndex = matrixType.rowHeaderCols.length + 1;
    for (let i = startIndex; i < matrixColDef.length; i++) {
      let series = [];
      for (let mrow of matrixData) {
        let name = "";
        for (let i = 0; i < matrixType.rowHeaderCols.length; i++) {
          if (name !== "") {
            name += "-" + mrow[matrixColDef[i].columnId];
          } else {
            name += mrow[matrixColDef[i].columnId];
          }
        }
        if (name === "") {
          name += mrow["__itemName"];
        } else if (matrixType.dataHeaderCols.length > 1) {
          name += "-" + mrow["__itemName"];
        }
        let value = mrow[matrixColDef[i].columnId];
        if (value == undefined || value === "") continue;
        series.push({name: name, value: value});
      }
      let seriesName = matrixColDef[i].header;
      graphData.push({name: seriesName, series: series});
    }

    return graphData;
  }

  /*
    Bar Chart, Pie Chart (No Group)
    {"name": "2020/10/16", "value": 4951}
  */
  buildGraphDataNoGroupe(matrixType: MatrixType, matrixData: Object[], matrixColDef: TableColumnDef[]) {
    let graphData = [];
    let startIndex = matrixType.rowHeaderCols.length + 1;
    let mrow = matrixData[0];
    for (let i = startIndex; i < matrixColDef.length; i++) {
      let name = matrixColDef[i].header;
      let value = mrow[matrixColDef[i].columnId];
      if (value == undefined || value === "") continue;
      graphData.push({name: name, value: value});
    }

    return graphData;
  }

  selectableGraphTypes(dataItemCount: number): GraphType[] {
    let graphTypes: GraphType[] = [];

    for (let type of GraphService.graphTypes) {
      if (!type.groupType && dataItemCount > 1) continue;
      graphTypes.push(type);
    }
    return graphTypes;
  }


}
