import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { ItemSelectSimpleCondition } from 'src/app/partsCommon/item-select-simple/item-select-simple.component';
import { FormBuilder } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ItemListSimpleComponent } from 'src/app/partsCommon/item-list-simple/item-list-simple.component';
import { ItemSimpleRec } from 'src/app/common/item-simple-rec';
import { Subscription } from 'rxjs';
import { ReqItemSearch } from 'src/app/request/req-item-search';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspItemSearch } from 'src/app/response/rsp-item-search';
import { ItemRec } from 'src/app/common/item-rec';
import { NoisecutHistoryComponent } from 'src/app/partsCommon/noisecut-history/noisecut-history.component';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit, OnDestroy {

  static tabSummary: number = 0;
  static tabHistory: number = 1;
  static tabNoisecut: number = 2;

  public isReadonly: boolean;
  public itemSelectSimpleCondition: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fb);
  public itemSelectSimpleConditionOrigin: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fbOrigin);
  public flagsSelectedItem = false;
  public itemSelected: ItemSimpleRec;
  public itemOfSummary: ItemSimpleRec;
  public itemOfHistory: ItemSimpleRec;
  public itemOfNoisecut: ItemSimpleRec;
  public itemDetail: ItemRec;
  public tabSelection: number = 0;

  private subscriptionItem: Subscription;

  @ViewChild(ItemListSimpleComponent, {static: true}) itemListSimpleComponent: ItemListSimpleComponent;
  @ViewChild(NoisecutHistoryComponent, {static: false}) noisecutHistoryComponent: NoisecutHistoryComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private fbOrigin: FormBuilder
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = '商品';
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("item");
  }

  ngOnDestroy() {
    if (this.subscriptionItem) {
      this.subscriptionItem.unsubscribe();
    }
  }

  doQueryItemSimple() {
    this.copyRequest();
    this.itemListSimpleComponent.doQuery();
  }

  itemChange(item: ItemSimpleRec) {
    this.flagsSelectedItem = true;
    if (this.itemSelected && this.itemSelected.storeCdFv != item.storeCdFv) {
      this.itemOfSummary = undefined;
      this.itemOfHistory = undefined;
      this.itemOfNoisecut = undefined;
    }
    this.itemSelected = item;
    if (this.tabSelection == ItemComponent.tabSummary &&
      this.itemOfSummary != this.itemSelected) {
      // Summary tab is active. query item summary now.
      this.itemOfSummary = this.itemSelected;
      this.getItem(item);
    }
    if (this.tabSelection == ItemComponent.tabHistory &&
      this.itemOfHistory != this.itemSelected) {
      // History tab is active. Push this item to ItemHistList @Input.
      this.itemOfHistory = this.itemSelected;
    }
    if (this.tabSelection == ItemComponent.tabNoisecut &&
      this.itemOfNoisecut != this.itemSelected) {
      // Noisecut tab is active. query noisecut now.
      this.itemOfNoisecut = this.itemSelected;
      this.noisecutHistoryComponent.getNoisecutHistory(
        this.itemOfNoisecut.storeCdFv,
        this.itemOfNoisecut.itemCdFv,
        this.itemOfNoisecut.itemNameFv
      );
    }
  }

  tabChanged(tabIndex: number) {
    this.tabSelection = tabIndex;

    if (tabIndex == ItemComponent.tabSummary) {
      if (this.itemSelected && this.itemOfSummary != this.itemSelected) {
        // Summary tab is activated. query item summary.
        this.itemOfSummary = this.itemSelected;
        this.getItem(this.itemOfSummary);
      }
    }

    if (tabIndex == ItemComponent.tabHistory) {
      if (this.itemSelected && this.itemOfHistory != this.itemSelected) {
        // History tab is activated. Push this item to ItemHistList @Input.
        this.itemOfHistory = this.itemSelected;
      }
    }

    if (tabIndex == ItemComponent.tabNoisecut) {
      if (this.itemSelected && this.itemOfNoisecut != this.itemSelected) {
        // Noisecut tab is activated. query noisecut.
        this.itemOfNoisecut = this.itemSelected;
        this.noisecutHistoryComponent.getNoisecutHistory(
          this.itemOfNoisecut.storeCdFv,
          this.itemOfNoisecut.itemCdFv,
          this.itemOfNoisecut.itemNameFv
        );
      }
    }
  }

  clearProgressStateItem() {
    this.commonService.closeSpinner();
    this.subscriptionItem.unsubscribe();
    this.subscriptionItem = undefined;
  }

  getItem(item: ItemSimpleRec) {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    var request : ReqItemSearch = {
      access: {...this.commonService.loginUser},
      itemSearchCondition : {
        calcTrgtDateFd: this.commonService.salesDate,
        // storeCdFv: this.itemSelectSimpleCondition.formGroup.get("storeCd").value,
        storeCdFv: item.storeCdFv,
        ctgCd0Fv: "",
        ctgCd1Fv: "",
        ctgCd2Fv: "",
        ctgCd3Fv: "",
        rackCd1Fn: 0,
        rackCd2Fn: 0,
        rackCd3Fn: 0,
        itemCdFv: item.itemCdFv,
        itemCdEqualSearch: true,
        itemNameFv: "",
        orderCurrentOperator: 0,
        orderCurrentNum: 0,
        orderInitialOperator: 0,
        orderInitialNum: 0,
        stockCurrentOperator: 0,
        stockCurrentNum: 0,
        stockInitialOperator: 0,
        stockInitialNum: 0,
      },
      page: {
        pageNum: 0,
        dispNum: 1
      }
    }

    this.subscriptionItem = this.httpBasic.itemSearch(request).subscribe(
      response => this.receiveItem(response),
      error => {
        this.clearProgressStateItem();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveItem(response: RspItemSearch) {
    this.clearProgressStateItem();
    var rspItemSearch: RspItemSearch = {...response};

    if (rspItemSearch.fatalError && rspItemSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspItemSearch.fatalError[0].errMsg);
      return;
    }
    if (rspItemSearch.normalError && rspItemSearch.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspItemSearch.normalError[0].errMsg);
      return;
    }

    if (rspItemSearch.recordCount == 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "商品が見つかりませんでした。");
      return;
    }

    this.itemDetail = new ItemRec(rspItemSearch.rows[0], this.commonService);
  }

  copyRequest() {
    this.itemSelectSimpleConditionOrigin.formGroup.patchValue(this.itemSelectSimpleCondition.formGroup.value);
    this.itemSelectSimpleConditionOrigin.ctgSelectCondition.formGroup.patchValue(this.itemSelectSimpleCondition.ctgSelectCondition.formGroup.value);
  }
}
