import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { ReqGetUsers } from "src/app/request/req-get-users";
import { RspGetUsers } from "src/app/response/rsp-get-users";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { NotificationUpdateDto, NotificationUpdateResultDto } from "src/app/webservice/notification";

export interface NotificationList {
  notificationId: number;
  storeCd: string;
  storeName: string;
  roleId: number;
  roleName: string;
  userId: string;
  userName: string;
}

export class NotificationRec {

  public notificationToType:  number;                     // 1:店舗 / 2: 役割 / 3: ユーザID
  public notificationList:    NotificationList[] = [];
  /*
  public notificationIds:     number[] = [];
  public storeCds:            string[] = [];
  public storeNames:          string[] = [];
  public roleIds:             number[] = [];
  public users:               {userId: string, userName: string, storeCd: string}[] = [];
  */
  public notificationClass:   string = "";
  public notificationType:    string = "text";
  public notification:        string = "";
  public notifierId:          string = "";
  public notifierName:        string = "";
  public notifierStoreCd:     string = "";
  public notifierRoleId:      number = -1;
  public notifier:            string = "";
  public notificationDate:    string = "";
  public expirationDate:      string = "";
  public stores:              {storeCd: string, storeName: string}[];
  public roles:               {roleId: number, roleName: string}[];
  public storeUserList:       {userId: string, userName: string, userSelect: FormControl}[];
  public storeSelectType:     string;

  public edit: {
    isNew:              boolean;
    isDirtyStore:       boolean;
    isDirtyRole:        boolean;
    isDirtyUser:        boolean;
    userIds:            string[];
    stores:             {storeCd: string, storeName: string, storeSelect: FormControl}[];
    roles:              {roleId: number, roleName: string, roleSelect: FormControl}[];
    users:              Map<string, {userId: string, userName: string, userSelect: FormControl}[]>;
    userStoreCd:        FormControl;
    notificationToType: FormControl;
    storeSelectType:    FormControl;
    notificationType:   FormControl;
    notification:       FormControl;
    // notifierId:       FormControl;
    notifier:           FormControl;
    notificationDate:   FormControl;
    expirationDate:     FormControl;
  }

  private toTypeChangeListener: () => {} ;
  private subsc: Subscription[] = [];

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService
  ) {
    if (this.commonService.config.includeNonAutoOrderStore.user === false) {
      this.stores = [...this.commonService.stores];
    } else {
      // this.stores = [...this.commonService.allStores];
      this.stores = [];
      this.commonService.allStores.forEach((store) => {
        store = {...store};
        this.stores.push(store);
        if (!this.commonService.stores.find((autoStore) => autoStore.storeCd === store.storeCd)) {
          store.storeName += "（非自動発注）";
        }
      });
      this.stores.forEach((store) => {
        store = {...store};
      });
    }
    if (this.commonService.loginUser.stores.length <= 1) return;
    if (!this.commonService.config.honbu) return;
    if (this.commonService.config.honbu.storeCd !== this.commonService.loginUser.realStoreCd) return;
    this.stores.push({storeCd: this.commonService.config.honbu.storeCd, storeName: this.commonService.config.honbu.storeName + "（非自動発注）"});
  }

  setToTypeChangeListener(fn: ()=>{}) {
    this.toTypeChangeListener = fn;
  }

  setRoles(roles: {roleId: number, roleName: string}[]) {
    this.roles = roles;
    if (this.edit) this.setEditRoles();
  }

  getValue(key: string) {
    return this[key];
  }

  canDelete() {
    if (this.notificationDate > this.commonService.formatDate(new Date())) return true;
    return false;
  }

  isDirty() {
    if (!this.edit) return false;
    if (this.edit.notificationToType.value === "1") return this.edit.isDirtyStore;
    if (this.edit.notificationToType.value === "2") return this.edit.isDirtyRole;
    if (this.edit.notificationToType.value === "3") return this.edit.isDirtyUser;
    return false;
  }

  prepareEdit() {
    this.cleanupEdit();

    this.storeSelectType = "1";
    if (this.notificationToType === 1) {
      if (this.notificationList.length > 0 && this.notificationList[0].storeCd === "*") {
        this.storeSelectType = "2";
      }
    }

    this.edit = {
      isNew:              false,
      isDirtyStore:       false,
      isDirtyRole:        false,
      isDirtyUser:        false,
      userIds:            [],
      stores:             [],
      roles:              [],
      users:              new Map<string, {userId: string, userName: string, userSelect: FormControl}[]>(),
      userStoreCd:        new FormControl(this.commonService.loginUser.storeCd),
      notificationToType: new FormControl("" + this.notificationToType),
      storeSelectType:    new FormControl(this.storeSelectType),
      notificationType:   new FormControl(this.notificationType),
      notification:       new FormControl(this.notification),
      notifier:           new FormControl(this.notifier),
      notificationDate:   new FormControl(this.commonService.getDate(this.notificationDate)),
      expirationDate:     new FormControl(this.commonService.getDate(this.expirationDate))
    };
    this.notificationList.forEach((item) => {
      if (item.userId !== undefined && item.userId !== "") this.edit.userIds.push(item.userId);
    });

    this.subsc.push(this.edit.notificationType.valueChanges.subscribe((val) => {this.valueChangedCommon();}));
    this.subsc.push(this.edit.notificationDate.valueChanges.subscribe((val) => {this.valueChangedNotificationDate();}));
    this.subsc.push(this.edit.expirationDate.valueChanges.subscribe((val) => {this.valueChangedExpirationDate();}));
    this.subsc.push(this.edit.notification.valueChanges.subscribe((val) => {this.valueChangedCommon();}));
    this.subsc.push(this.edit.notifier.valueChanges.subscribe((val) => {this.valueChangedCommon();}));
    this.subsc.push(this.edit.storeSelectType.valueChanges.subscribe((val) => {this.valueChangedStoreSelectType(val);}));
    this.valueChangedStoreSelectType(this.storeSelectType, true);
    this.edit.notificationToType.setValue("" + this.notificationToType);
    this.subsc.push(this.edit.notificationToType.valueChanges.subscribe((val) => {
      /*
      if (val === '3') {
        this.edit.expirationDate.disable({emitEvent: false});
      } else {
        if (this.commonService.formatDate(this.edit.expirationDate.value) === "2099/12/31") {
          let dateBegin = this.edit.notificationDate.value;
          let dateEnd = this.commonService.getDate(this.commonService.formatDate(new Date()));
          dateEnd.setDate(dateEnd.getDate() + 1);
          if (dateEnd.getTime() < dateBegin.getTime()) {
            dateEnd = this.commonService.copyDate(dateBegin);
          }
          this.edit.expirationDate.setValue(dateEnd);  
        }
        this.edit.expirationDate.enable({emitEvent: false});
      }
      */
      if (this.toTypeChangeListener) this.toTypeChangeListener();
    }));
    this.subsc.push(this.edit.userStoreCd.valueChanges.subscribe((val) => {this.getStoreUserList(val);}));

    this.stores.forEach((store) => {
      let form = new FormControl(false);
      if (this.notificationToType === 1) {
        if (this.notificationList.find((item) => item.storeCd === store.storeCd)) {
          form.setValue(true);
        }
      }
      this.subsc.push(form.valueChanges.subscribe((val) => {this.valueChangedStore();}));
      this.edit.stores.push({
        storeCd: store.storeCd,
        storeName: store.storeName,
        storeSelect: form
      });
    });

    if (this.roles) this.setEditRoles();

    this.getStoreUserList(this.edit.userStoreCd.value);
  }

  setEditRoles() {
    this.edit.roles = [];
    this.roles.forEach((role) => {
      let form = new FormControl(false);
      if (this.notificationToType === 2) {
        if (this.notificationList.find((item) => item.roleId === role.roleId)) {
          form.setValue(true);
        }
      }
      this.subsc.push(form.valueChanges.subscribe((val) => {this.valueChangedRole();}));
      this.edit.roles.push({
        roleId: role.roleId,
        roleName: role.roleName,
        roleSelect: form
      });
    });
  }

  getStoreUserList(storeCd: string) {
    if (this.edit.users.has(this.edit.userStoreCd.value)) {
      this.storeUserList = this.edit.users.get(this.edit.userStoreCd.value);
      return;
    };

    let request: ReqGetUsers = {
      access: this.commonService.loginUser,
      storeCd: this.edit.userStoreCd.value
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetUsers", request).subscribe(
      (response: RspGetUsers) => {
        if (this.httpBasic.handleAppError(response)) return;
        this.storeUserList = [];
        response.users.forEach((dto) => {
          let form: FormControl = new FormControl(false);
          if (this.notificationToType === 3) {
            if (this.notificationList.find((item) => item.userId === dto.userId)) form.setValue(true);
          }
          this.subsc.push(form.valueChanges.subscribe((val) => {this.valueChangedUser(dto.userId, val);}));
          this.storeUserList.push({userId: dto.userId, userName: dto.userName, userSelect: form})
        });
        this.edit.users.set(storeCd, [...this.storeUserList]);
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  mergeUsers() {
    let list: NotificationList[] = [...this.notificationList];

    let storeCds = this.edit.users.keys();
    for (let storeCd of storeCds) {
      let users = this.edit.users.get(storeCd);
      users.forEach((user) => {
        if (user.userSelect.value) {
          if (!list.find((listItem) => user.userId === listItem.userId)) {
            let storeName = this.stores.find((store) => store.storeCd === storeCd)?.storeName;
            if (!storeName) storeName = "";
            list.push({
              notificationId: -1,
              storeCd: storeCd,
              storeName: storeName,
              roleId: -1,
              roleName: "",
              userId: user.userId,
              userName: user.userName
            });
          }
        } else {
          let index: number;
          if ((index = list.findIndex((listItem) => user.userId === listItem.userId)) >= 0) {
            list.splice(index, 1);
          }
        }
      });
    }

    return list;
  }

  saveEdit(updateResultList: NotificationUpdateResultDto[]) {
    this.notificationList = updateResultList;
    this.notificationToType = parseInt(this.edit.notificationToType.value);
    this.notificationType = this.edit.notificationType.value;
    this.notification = this.edit.notification.value;
    this.notifier = this.edit.notifier.value;
    this.notificationDate = this.commonService.formatDate(this.edit.notificationDate.value);
    this.expirationDate = this.commonService.formatDate(this.edit.expirationDate.value);
    this.notificationList = this.mergeUsers();

    this.edit.isNew = false;
    this.edit.isDirtyStore = false;
    this.edit.isDirtyRole = false;
    this.edit.isDirtyUser = false;
  }

  listForSave() {
    let deleteList: number[];
    let updateList: NotificationUpdateDto[] = [];

    if (this.notificationToType !== parseInt(this.edit.notificationToType.value)) {
      deleteList = this.notificationList.map((item) => item.notificationId);
    } else if (this.notificationToType === 1) {
      // 宛先: 店舗
      if (parseInt(this.storeSelectType) === 1) {
        if (parseInt(this.edit.storeSelectType.value) === 1) {
          // 個別 ---> 個別
          deleteList = [];
          this.notificationList.forEach((item) => {
            let store = this.edit.stores.find((store) => store.storeCd === item.storeCd);
            if (!store || store.storeSelect.value === false) {
              deleteList.push(item.notificationId);
            }
          });
        } else {
          // 個別 ---> 全店
          deleteList = this.notificationList.map((item) => item.notificationId);
        }
      } else {
        if (parseInt(this.edit.storeSelectType.value) === 1) {
          // 全店 ---> 個別
          deleteList = this.notificationList.map((item) => item.notificationId);
        } else {
          // 全店 ---> 全店
          deleteList = [];
        }
      }
    } else if (this.notificationToType === 2) {
      // 宛先: 役割
      deleteList = [];
      this.notificationList.forEach((item) => {
        let role = this.edit.roles.find((role) => role.roleId === item.roleId);
        if (!role || role.roleSelect.value === false) {
          deleteList.push(item.notificationId);
        }
      });
    } else {
      // 宛先: ユーザ
      deleteList = [];
      let newList = this.mergeUsers();
      this.notificationList.forEach((item) => {
        if (!newList.find((newItem) => item.notificationId === newItem.notificationId)) {
          deleteList.push(item.notificationId);
        }
      });
    }

    if (parseInt(this.edit.notificationToType.value) === 1) {
      updateList = this.updateListStore();
    } else if (parseInt(this.edit.notificationToType.value) === 2) {
      updateList = this.updateListRole();
    } else {
      updateList = this.updateListUser();
    }

    return {deleteList: deleteList, updateList: updateList};
  }

  updateListStore() {
    let updateList: NotificationUpdateDto[] = [];
    if (parseInt(this.edit.storeSelectType.value) === 2) {
      let dto = this.getUpdateBaseDto();
      let orgItem = this.notificationList.find((item) => item.storeCd === "*");
      if (orgItem) {
        dto.notificationId = orgItem.notificationId;
      }
      dto.storeCd = "*";
      dto.storeName = "全店"
      updateList.push(dto);
    } else {
      this.edit.stores.forEach((store) => {
        if (store.storeSelect.value) {
          let dto = this.getUpdateBaseDto();
          let orgItem = this.notificationList.find((item) => item.storeCd === store.storeCd);
          if (orgItem) {
            dto.notificationId = orgItem.notificationId;
          }
          dto.storeCd = store.storeCd;
          dto.storeName = store.storeName;
          updateList.push(dto);
        } 
      });
    }

    return updateList;
  }

  updateListRole() {
    let updateList: NotificationUpdateDto[] = [];
    this.edit.roles.forEach((role) => {
      if (role.roleSelect.value) {
        let dto = this.getUpdateBaseDto();
        let orgItem = this.notificationList.find((item) => item.roleId === role.roleId);
        if (orgItem) {
          dto.notificationId = orgItem.notificationId;
        }
        dto.roleId = role.roleId;
        dto.roleName = role.roleName;
        updateList.push(dto);
      }
    });

    return updateList;
  }

  updateListUser() {
    let updateList: NotificationUpdateDto[] = [];
    let list = this.mergeUsers();
    list.forEach((user) => {
      let dto = this.getUpdateBaseDto();
      let orgItem = this.notificationList.find((item) => item.userId === user.userId);
      if (orgItem) {
        dto.notificationId = orgItem.notificationId;
      }
      dto.userId = user.userId;
      dto.userName = user.userName;
      /*
      dto.expirationDate = "2099/12/31";
      this.edit.expirationDate.setValue(this.commonService.getDate("2099/12/31"));
      */
      updateList.push(dto);
    });

    return updateList;
  }

  getUpdateBaseDto() {
    let dto: NotificationUpdateDto = {
      notificationId:      -1,
      notificationToType:  parseInt(this.edit.notificationToType.value),
      storeCd:             "",
      storeName:           "",
      roleId:              -1,
      roleName:            "",
      userId:              "",
      userName:            "",
      notificationClass:   this.notificationClass,
      notificationType:    this.edit.notificationType.value,
      notification:        this.edit.notification.value,
      notifierId:          this.commonService.loginUser.userId,
      notifierName:        this.commonService.loginUser.userName,
      notifierStoreCd:     this.commonService.loginUser.storeCd,
      notifierRoleId:      this.commonService.loginUser.roleId,
      notifierRoleName:    this.commonService.loginUser.roleName,
      notifier:            this.edit.notifier.value,
      notificationDate:    this.commonService.formatDate(this.edit.notificationDate.value),
      expirationDate:      this.commonService.formatDate(this.edit.expirationDate.value)
    };

    return dto;
  }

  cancelEdit() {
    this.prepareEdit();
  }

  cleanupEdit() {
    this.subsc?.forEach((subsc) => subsc.unsubscribe());
    if (this.edit) delete this.edit;
  }

  valueChangedCommon() {
    if (!this.edit) return;
    this.edit.isDirtyStore = true;
    this.edit.isDirtyRole = true;
    this.edit.isDirtyUser = true;
  }

  valueChangedNotificationDate() {
    this.valueChangedCommon();
    let begin = this.edit.notificationDate.value;
    let end = this.edit.expirationDate.value;

    if (end.getTime() >= begin.getTime()) return;

    let newEnd = this.commonService.copyDate(begin);
    newEnd.setDate(newEnd.getDate() + 1);
    this.edit.expirationDate.setValue(newEnd, {emitEvent: false});
  }

  valueChangedExpirationDate() {
    let begin = this.edit.notificationDate.value;
    let end = this.edit.expirationDate.value;

    if (end.getTime() >= begin.getTime()) return;

    let newBegin = this.commonService.copyDate(end);
    let today = this.commonService.getDate(this.commonService.formatDate(new Date()));
    if (today.getTime() < end.getTime()) {
      newBegin.setDate(end.getDate() - 1);
    }
    this.edit.notificationDate.setValue(newBegin, {emitEvent: false});
  }

  valueChangedStore() {
    if (this.edit) this.edit.isDirtyStore = true;
  }

  valueChangedRole() {
    if (this.edit) this.edit.isDirtyRole = true;
  }

  valueChangedUser(userId: string, sel: boolean) {
    if (!this.edit) return;
    this.edit.isDirtyUser = true;
    let index = this.edit.userIds.findIndex((item) => item === userId);
    if (sel) {
      if (index < 0) {
        this.edit.userIds.push(userId);
      }
    } else {
      if (index >= 0) {
        this.edit.userIds.splice(index, 1);
      }
    }
  }

  valueChangedStoreSelectType(val: string, isPrepare?: boolean) {
    if (val === "1") {
      this.edit.stores.forEach((store) => store.storeSelect.enable({emitEvent: false}));
    } else {
      this.edit.stores.forEach((store) => store.storeSelect.disable({emitEvent: false}));
    }
    if (!isPrepare) this.edit.isDirtyStore = true;
  }

  saveDisable() {
    if (!this.isDirty()) return true;
    if (this.edit.notification.value === "") return true;

    if (this.edit.notificationToType.value === "1") {
      if (this.storeSelectType !== this.edit.storeSelectType.value) return false;
      if (parseInt(this.edit.storeSelectType.value) !== 2) {
        for (let i = 0; i < this.edit.stores.length; i++) {
          if (this.edit.stores[i].storeSelect.value === true) return !this.edit.isDirtyStore;
        }
        return true;
      }
      return !this.edit.isDirtyStore;
    }

    if (this.edit.notificationToType.value === "2") {
      for (let i = 0; i < this.edit.roles.length; i++) {
        if (this.edit.roles[i].roleSelect.value === true) return !this.edit.isDirtyRole;
      }
      return true;
    }

    if (this.edit.notificationToType.value === "3") {
      if (this.edit.userIds.length > 0) return !this.edit.isDirtyUser;
      return true;
    }

    return false;
  }

  cancelDisable() {
    if (this.edit.isDirtyStore) return false;
    if (this.edit.isDirtyRole) return false;
    if (this.edit.isDirtyUser) return false;
    return true;
  }
}
