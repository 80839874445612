import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class MyMatPaginatorIntlModule extends MatPaginatorIntl{
  public itemsPerPageLabel:string = "表示行数：";
}
