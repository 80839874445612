import { ItemSimpleRec } from 'src/app/common/item-simple-rec';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ReqItemHistSearch } from 'src/app/request/req-item-hist-seach';
import { Subscription } from 'rxjs';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspItemHistSearch, RspItemHistDto } from 'src/app/response/rsp-item-hist-seach';
import { ItemHistRec } from 'src/app/common/item-hist-rec';
import { SimpleChanges } from '@angular/core';
import { TableItemDialogComponent } from 'src/app/dialog/table-item-dialog/table-item-dialog.component';
import { MatTable } from '@angular/material/table';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-item-hist-list',
  templateUrl: './item-hist-list.component.html',
  styleUrls: ['./item-hist-list.component.css']
})
export class ItemHistListComponent implements OnInit, OnDestroy, OnChanges {

  public columnDefs: TableColumnDef[] = [
    {columnId:'calcTrgtDateFd', header:"日付", width:80, align:"center"},
    {columnId:'orderRecommendBaraNumFn', header:"発注推奨数(バラ)", width:100, align:"right"},
    {columnId:'stkNumFn', header:"在庫", width:60, align:"right"},
    {columnId:'stkEditNumFn', header:"在庫訂正", width:60, align:"right"},
    {columnId:'salesNumFn', header:"販売", width:60, align:"right"},
    {columnId:'orderBaraNumFn', header: this.commonService.config.orderBaraNumColumn?.title, width:60, align:"right", 
      hidden: this.commonService.config.orderBaraNumColumn == undefined ? true : this.commonService.config.orderBaraNumColumn.hidden},
    {columnId:'rcvBaraNumFn', header:"納品", width:60, align:"right"},
    {columnId:'orderDateFd', header:"発注日", width:80, align:"center"},
    {columnId:'inMoveNumFn', header:"移動(入)", width:60, align:"right"},
    {columnId:'outMoveNumfn', header:"移動(出)", width:60, align:"right"},
    {columnId:'disNumFn', header:"廃棄", width:60, align:"right"},
    {columnId:'rtnNumFn', header:"返品", width:60, align:"right"},
    {columnId:'transNumFn', header:"振替", width:60, align:"right"},
    {columnId:'revNumFn', header:"特売補正", width:60, align:"right"}
  ];
  public displayColumnDefs: TableColumnDef[] = [];
  public undisplayColumnDefs: TableColumnDef[] = [];
  public displayColumnIds: string[];
  public tableWidth: any;
  public pagenatorMaxWidth: any;
  public recordCount: number = 0;
  public maxDate: Date;
  public formGroup: FormGroup;
  public itemHistList: ItemHistRec[] = [];

  private subscriptionSearch: Subscription;
  private subscriptionDialog: Subscription;
  private flagsGetHistData: boolean = false;

  @Input() itemHistListComponent: ItemHistListComponent;
  @Input('itemSelected') public itemSelected: ItemSimpleRec;
  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;
  @ViewChild(MatPaginator, {static:false}) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder, public httpBasic: HttpBasicService) {
    this.formGroup = this.fb.group({
      itemCd: [{ disabled: false }],
      itemName: [{ disabled: false }],
      beginDate: [, Validators.required],
      endDate: [, Validators.required]
    });
  }

  ngOnInit() {
    for (var colDef of this.columnDefs) {      
      if(!colDef.hidden)
        this.displayColumnDefs.push(colDef);
    }    

    this.setColumnId();
    this.calcTableWidth();
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + this.commonService.config.itemHistDefaultEndDayOffset);
    var end = this.commonService.copyDate(this.maxDate);
  
    var begin = this.commonService.copyDate(end);
    begin.setDate(begin.getDate() - this.commonService.config.itemHistDefaultDays + 1);
    this.formGroup.get("beginDate").setValue(begin);
    this.formGroup.get("endDate").setValue(end);
  }

  ngOnDestroy() {
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
    }
    if (this.subscriptionDialog) {
      this.subscriptionDialog.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.flagsGetHistData) {
      this.matPagenator.pageIndex = 0;
      this.doQueryBody();
    }
    /*
    else {
      this.flagsGetHistData = true;
    }
    */
  }

  setColumnId() {
    this.displayColumnIds = [];
    for (var colDef of this.displayColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
  }

  styleFor(id:string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id:string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = {"width": "" + width + "px"};
    this.pagenatorMaxWidth = {"max-width": "" + width + "px"}
  }

  dateCanPrevYear(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return true;
  }
  dateCanPrevMonth(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return true;
  }
  dateCanPrevWeek(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return true;
  }

  dateCan(dates: number) {
    var d = this.formGroup.get("endDate").value as Date;
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    d.setDate(d.getDate() + dates);
    if (d.getTime() > this.maxDate.getTime()) return false;
    return true;
  }

  dateCanNextYear(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return (this.dateCan(364));
  }
  dateCanNextMonth(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return (this.dateCan(28));
  }
  dateCanNextWeek(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return (this.dateCan(7));
  }

  dateShift(dates: number) {
    if (dates > 0) {
      let e = this.formGroup.get("endDate").value as Date;
      e.setDate(e.getDate() + dates);
      this.formGroup.get("endDate").setValue(e);
      let b = this.formGroup.get("beginDate").value as Date;
      b.setDate(b.getDate() + dates);
      this.formGroup.get("beginDate").setValue(b);
    } else {
      let b = this.formGroup.get("beginDate").value as Date;
      b.setDate(b.getDate() + dates);
      this.formGroup.get("beginDate").setValue(b);
      let e = this.formGroup.get("endDate").value as Date;
      e.setDate(e.getDate() + dates);
      this.formGroup.get("endDate").setValue(e);
    }
  }
  getDateBeginMax() {
    if (this.formGroup.get("endDate").value) {
      return this.formGroup.get("endDate").value;
    }
    return this.maxDate;
  }
  prevYear() {
    this.dateShift(-364);
  }
  prevMonth() {
    this.dateShift(-28);
  }
  prevWeek() {
    this.dateShift(-7);
  }
  nextYear() {
    this.dateShift(364);
  }
  nextMonth() {
    this.dateShift(28);
  }
  nextWeek() {
    this.dateShift(7);
  }

  pageChanged(pageEvent: PageEvent) {
    this.doQueryBody();
  }

  doQuery() {
    this.flagsGetHistData = true;
    this.matPagenator.pageIndex = 0;
    this.doQueryBody();
  }

  doQueryBody() {
    let request: ReqItemHistSearch = {
      access: { ...this.commonService.loginUser },
      itemHistSeachCondition: {
        storeCdFv: this.itemSelected.storeCdFv,
        itemCdFv: this.itemSelected.itemCdFv,
        beginDate: formatDate(this.formGroup.get('beginDate').value, 'yyyy-MM-dd', 'en_US'),
        endDate: formatDate(this.formGroup.get('endDate').value, 'yyyy-MM-dd', 'en_US'),
      },
      page: {
        pageNum: this.matPagenator.pageIndex,
        dispNum: this.matPagenator.pageSize
      }
    };

    this.subscriptionSearch = this.httpBasic.itemHistSearch(request).subscribe(
      data => this.SearchResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }

  SearchResult(data: RspItemHistSearch) {
    this.clearProgressState();
    let rspItemHistSearch: RspItemHistSearch = { ...data };
    if (rspItemHistSearch.fatalError && rspItemHistSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspItemHistSearch.fatalError[0].errMsg);
    }
    if (rspItemHistSearch.normalError && rspItemHistSearch.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspItemHistSearch.normalError[0].errMsg);
      return;
    }
    this.itemHistList = [];
    this.recordCount = rspItemHistSearch.recordCount;

    if (this.recordCount > 0) {
      let rspItemHistDtos: RspItemHistDto[] = [...rspItemHistSearch.rows];
      for (let rspItemHistDto of rspItemHistDtos) {
        this.itemHistList.push(new ItemHistRec(rspItemHistDto, this.commonService));
      }
    }
    console.table(this.itemHistList);
  }

  dialogResult(data: any) {
    this.subscriptionDialog.unsubscribe();
    this.subscriptionDialog = undefined;
    this.matTable.renderRows();
  }

  selectTableItem() {
    scrollTo(0, 10);
    const dialogRef = this.commonService.dialog.open(TableItemDialogComponent, {
      disableClose: true,
      data: { display: this.displayColumnDefs, nonDisplay: this.undisplayColumnDefs }
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => {
        this.setColumnId();
        this.calcTableWidth();
        this.dialogResult(data);
      }
    );
  }

}
