import { CtgHistRec } from '../../common/ctg-hist-rec';
import { RspCtgHistSearch, RspCtgHistDto } from '../../response/rsp-ctg-hist-search';
import { HttpBasicService } from '../../service/http-basic.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CtgSelectCondition, CtgSelectComponent } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { TableItemDialogComponent } from 'src/app/dialog/table-item-dialog/table-item-dialog.component';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ReqCtgHistSearch } from 'src/app/request/req-ctg-hist-search';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-ctg-hist',
  templateUrl: './ctg-hist.component.html',
  styleUrls: ['./ctg-hist.component.css']
})
export class CtgHistComponent implements OnInit, OnDestroy {

  public isReadonly: boolean;
  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public formGroup: FormGroup;
  public storeForm: FormControl;
  public beginDateForm: FormControl;
  public endDateForm: FormControl;
  public maxDate: Date;
  public columnDefs: TableColumnDef[] = [
    { columnId: 'calcTrgtDateFd', header: "日付", width: 80, align: "center" },
    { columnId: 'orderRecommendBaraNumFn', header: "発注推奨数(バラ)", width: 100, align: "right" },
    // { columnId: 'orderRealBaraNumFn', header: "実発注数(バラ)", width: 100, align: "right" },
    { columnId: 'stkNumFn', header: "在庫", width: 60, align: "right" },
    { columnId: 'salesNumFn', header: "販売", width: 60, align: "right" },
    { columnId: 'rcvBaraNumFn', header: "納品", width: 60, align: "right" },
    { columnId: 'inMoveNumFn', header: "移動(入)", width: 60, align: "right" },
    { columnId: 'outMoveNumfn', header: "移動(出)", width: 60, align: "right" },
    { columnId: 'disNumFn', header: "廃棄", width: 60, align: "right" },
    { columnId: 'rtnNumFn', header: "返品", width: 60, align: "right" },
    { columnId: 'transNumFn', header: "振替", width: 60, align: "right" },
    { columnId: 'revNumFn', header: "特売補正", width: 60, align: "right" }
  ];
  public displayColumnDefs: TableColumnDef[] = [];
  public unDisplayColumnDefs: TableColumnDef[] = [];
  public displayColumnIds: string[];
  public tableWidth: any;
  public paginatorMaxWidth: any;
  public recordCount: number = 0;
  public ctgHistList: object[] = [];

  public beginDateByDayOrigin: Date = null;
  public endDateByDayOrigin: Date = null;
  public beginDateByMonthOrigin: Date = null;
  public endDateByMonthOrigin: Date = null;

  private subscriptionSearch: Subscription;
  private subscriptionStoreCd: Subscription;
  private subscriptionDialog: Subscription;
  private subscriptionCalcType: Subscription;
  private subscriptionCtg: Subscription;
  private isQueryRequested: boolean = false;

  @ViewChild(CtgSelectComponent, { static: true }) public ctgSelectComponent: CtgSelectComponent;
  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    public httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = '受払履歴(カテゴリ)';
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("ctghist");

    this.formGroup = this.fb.group(
      {
        storeCd: [this.commonService.loginUser.storeCd],
        calcType: ["1"],
        beginDate: [, Validators.required],
        endDate: [, Validators.required]
      });

    this.storeForm = this.formGroup.get("storeCd") as FormControl;
    // this.storeForm.setValue(this.commonService.loginUser.storeCd);
    this.subscriptionStoreCd = this.storeForm.valueChanges.subscribe(
      data => {
        this.ctgSelectComponent.setStoreCd(this.storeForm.value);
        this.clearDataSearch();
      }
    );
    this.setDefaultDate();
    this.subscriptionCalcType = this.formGroup.get("calcType").valueChanges.subscribe(
      () => {
        this.setDefaultDate();
        this.clearDataSearch();
      }
    );
    this.formGroup.get("beginDate").valueChanges.subscribe(
      () => {
        if(this.formGroup.get("calcType").value == 1) {
          this.beginDateByDayOrigin = this.formGroup.get("beginDate").value;
        } else {
          this.beginDateByMonthOrigin = this.formGroup.get("beginDate").value;
        }
      }
    );
    this.formGroup.get("endDate").valueChanges.subscribe(
      () => {
        if(this.formGroup.get("calcType").value == 1) {
          this.endDateByDayOrigin = this.formGroup.get("endDate").value;
        } else {
          this.endDateByMonthOrigin = this.formGroup.get("endDate").value;
        }
      }
    );
    this.subscriptionCtg = this.ctgSelectCondition.formGroup.valueChanges.subscribe(
      () => this.clearDataSearch()
    );
    for (var colDef of this.columnDefs) {
      this.displayColumnDefs.push(colDef);
    }

    this.setColumnId();
    this.calcTableWidth();
  }

  ngOnDestroy() {
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
    }
    if (this.subscriptionStoreCd) {
      this.subscriptionStoreCd.unsubscribe();
    }
    if (this.subscriptionDialog) {
      this.subscriptionDialog.unsubscribe();
    }
    if (this.subscriptionCtg) {
      this.subscriptionCtg.unsubscribe();
    }
    if (this.subscriptionCalcType) {
      this.subscriptionCalcType.unsubscribe();
    }
  }

  isValid() {
    if (this.ctgSelectCondition.formGroup.get("ctgCd0").value === "") return false;
    return true;
  }

  getDateBeginMax() {
    let max: Date;
    (this.formGroup.get("endDate").value)
      ? max = this.commonService.copyDate(this.formGroup.get("endDate").value)
      : max = this.commonService.copyDate(this.maxDate);

    max.setDate(max.getDate() - 1);
    return max;
  }

  getDateEndMax() {
    let max: Date;
    (this.endDateForm.value)
      ? max = this.commonService.copyDate(this.endDateForm.value)
      : max = this.commonService.copyDate(this.maxDate);

    max.setDate(max.getDate() - 1);
    return max;
  }

  getDateEndMin() {
    let min: Date = this.commonService.copyDate(this.formGroup.get("beginDate").value);

    min.setDate(min.getDate() + 1);
    return min;
  }

  dateCanPrevYear(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return true;
  }

  dateCanPrevMonth(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return true;
  }

  dateCanPrevWeek(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return true;
  }

  dateCan(dates: number) {
    var d = this.formGroup.get("endDate").value as Date;
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    d.setDate(d.getDate() + dates);
    if (d.getTime() > this.maxDate.getTime()) return false;
    return true;
  }

  dateCanNextYear(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return (this.dateCan(364));
  }

  dateCanNextMonth(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return (this.dateCan(28));
  }

  dateCanNextWeek(): boolean {
    if (!this.formGroup.get("beginDate").value) return false;
    if (!this.formGroup.get("endDate").value) return false;

    return (this.dateCan(7));
  }

  dateShift(dates: number) {
    if (dates > 0) {
      let e = this.formGroup.get("endDate").value as Date;
      e.setDate(e.getDate() + dates);
      this.formGroup.get("endDate").setValue(e);
      let b = this.formGroup.get("beginDate").value as Date;
      b.setDate(b.getDate() + dates);
      this.formGroup.get("beginDate").setValue(b);
    } else {
      let b = this.formGroup.get("beginDate").value as Date;
      b.setDate(b.getDate() + dates);
      this.formGroup.get("beginDate").setValue(b);
      let e = this.formGroup.get("endDate").value as Date;
      e.setDate(e.getDate() + dates);
      this.formGroup.get("endDate").setValue(e);
    }
    this.clearDataSearch();
  }

  prevYear() {
    this.dateShift(-364);
  }

  prevMonth() {
    this.dateShift(-28);
  }

  prevWeek() {
    this.dateShift(-7);
  }

  nextYear() {
    this.dateShift(364);
  }

  nextMonth() {
    this.dateShift(28);
  }

  nextWeek() {
    this.dateShift(7);
  }

  setColumnId() {
    this.displayColumnIds = [];
    for (var colDef of this.displayColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
  }

  styleFor(id: string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
    this.paginatorMaxWidth = { "max-width": "" + width + "px" }
  }

  dialogResult(data: any) {
    this.subscriptionDialog.unsubscribe();
    this.subscriptionDialog = undefined;
    this.matTable.renderRows();
  }

  selectTableItem() {
    scrollTo(0, 10);
    const dialogRef = this.commonService.dialog.open(TableItemDialogComponent, {
      disableClose: true,
      data: { display: this.displayColumnDefs, nonDisplay: this.unDisplayColumnDefs }
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => {
        this.setColumnId();
        this.calcTableWidth();
        this.dialogResult(data);
      }
    );
  }

  doQuery() {
    if (!this.isValid())
      return;
    this.matPaginator.pageIndex = 0;
    this.isQueryRequested = true;
    this.querySearch();
  }

  querySearch() {
    var request: ReqCtgHistSearch = {
      access: { ...this.commonService.loginUser },
      ctgHistSearch: {
        storeCdFv: this.formGroup.value.storeCd,

        ctg0CdFv: this.ctgSelectCondition.formGroup.get("ctgCd0").value,
        ctg1CdFv: this.ctgSelectCondition.formGroup.get("ctgCd1").value,
        ctg2CdFv: this.ctgSelectCondition.formGroup.get("ctgCd2").value,
        ctg3CdFv: this.ctgSelectCondition.formGroup.get("ctgCd3").value,
        ctgLevelFn: this.ctgSelectCondition.formGroup.get("ctgLevel").value,

        calcTypeFn: parseInt(this.formGroup.get("calcType").value),
        beginDateFv: formatDate(this.formGroup.get("beginDate").value, 'yyyy/MM/dd', 'en_US'),
        endDateFv: formatDate(this.formGroup.get("endDate").value, 'yyyy/MM/dd', 'en_US'),
      },
      page: {
        pageNum: this.matPaginator.pageIndex,
        dispNum: this.matPaginator.pageSize
      }
    }

    this.subscriptionSearch = this.httpBasic.ctgHistSearch(request).subscribe(
      data => this.SearchResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
  }

  pageChanged(pageEvent: PageEvent) {
    if (!this.isValid())
      return;
    if (!this.isQueryRequested)
      return;
    this.querySearch();
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }

  SearchResult(data: RspCtgHistSearch) {
    this.clearProgressState();
    let rspCtgHistSearch: RspCtgHistSearch = { ...data };
    if (rspCtgHistSearch.fatalError && rspCtgHistSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspCtgHistSearch.fatalError[0].errMsg);
    }
    if (rspCtgHistSearch.normalError && rspCtgHistSearch.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspCtgHistSearch.normalError[0].errMsg);
      return;
    }

    this.ctgHistList = [];
    this.recordCount = rspCtgHistSearch.recordCount;
    if (this.recordCount > 0) {
      let rspCtgHistDtos: RspCtgHistDto[] = [...rspCtgHistSearch.rows];
      for (let rspCtgHistDto of rspCtgHistDtos) {
        this.ctgHistList.push(new CtgHistRec(rspCtgHistDto, this.commonService));
      }
    }
  }

  setDefaultDate() {
    const calcType = this.formGroup.get("calcType").value;
    switch (calcType) {
      case "1": {
        this.maxDate = new Date();
        this.maxDate.setDate(this.maxDate.getDate() - 1);
        var end = this.commonService.copyDate(this.maxDate);
        var begin = this.commonService.copyDate(end);
        begin.setDate(begin.getDate() - this.commonService.config.itemHistDefaultDays + 1);
        if(this.beginDateByDayOrigin === null && this.endDateByDayOrigin === null){
          this.formGroup.get("beginDate").setValue(begin);
          this.formGroup.get("endDate").setValue(end);
        } else {
          this.formGroup.get("beginDate").setValue(this.beginDateByDayOrigin);
          this.formGroup.get("endDate").setValue(this.endDateByDayOrigin);
        }

        return;
      }
      case "2": {
        this.maxDate = new Date();
        // this.maxDate.setDate(this.maxDate.getDate() + this.commonService.config.itemHistDefaultEndDayOffset);
        if (this.maxDate.getDay() == 0) {
          this.maxDate.setDate(this.maxDate.getDate() - 7);
        } else {
          this.maxDate.setDate(this.maxDate.getDate() - this.maxDate.getDay());
        }
        var end = this.commonService.copyDate(this.maxDate);
        end.setDate(end.getDate() - end.getDay());

        var begin = this.commonService.copyDate(end);
        // begin.setDate(begin.getDate() - this.commonService.config.itemHistDefaultDays + 1);
        begin.setDate(begin.getDate() - 83);

        if(this.beginDateByMonthOrigin === null && this.endDateByMonthOrigin === null){
          this.formGroup.get("beginDate").setValue(begin);
          this.formGroup.get("endDate").setValue(end);
        } else {
          this.formGroup.get("beginDate").setValue(this.beginDateByMonthOrigin);
          this.formGroup.get("endDate").setValue(this.endDateByMonthOrigin);
        }
      }
      default: return;
    }
  }

  dateBeginFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return (this.formGroup.get("calcType").value == 2) ? day == 1 : true;
  }

  dateEndFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return (this.formGroup.get("calcType").value == 2) ? day == 0 : true;
  }

  clearDataSearch() {
    this.ctgHistList = [];
    this.setDefaultPage();
  }

  setDefaultPage() {
    // this.matPagenator.pageSize = this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex];
    this.matPaginator.pageIndex = 0;
    this.recordCount = 0;
    this.isQueryRequested = false;
  }
  isNumber(val): boolean { return typeof val === 'number'; }

}
