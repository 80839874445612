import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { CommonService } from 'src/app/service/common.service';
import { CtgService } from 'src/app/service/ctgService';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ExpiryItemMasterRec } from '../0_def/expiry-item-master-rec';
import { ReqGetItemExpiryItemMaster, ReqGetItemExpiryMasterSingle, ReqSaveItemExpiryItemMaster, RspGetItemExpiryItemMaster, RspGetItemExpiryMasterSingle, RspSaveItemExpiryItemMaster, SaveItemExpiryItemMasterDto } from 'src/app/webservice/item-expiry';
import { ExpiryMasterRec } from '../0_def/expiry-master-rec';
import { ItemExpiryCtgDialogComponent } from '../item-expiry-ctg-dialog/item-expiry-ctg-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-item-expiry-item-master',
  templateUrl: './item-expiry-item-master.component.html',
  styleUrls: ['./item-expiry-item-master.component.css']
})
export class ItemExpiryItemMasterComponent implements OnInit, OnDestroy {

  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public storeForm: FormControl = new FormControl();
  public itemCdForm: FormControl = new FormControl("");
  public itemNameForm: FormControl = new FormControl("");
  public noRuleForm: FormControl = new FormControl(false);
  public keypadForm: FormControl = new FormControl(false);
  public showForm: FormControl = new FormControl(true);
  public showNoneForm: FormControl = new FormControl(false);
  public listSave: SaveItemExpiryItemMasterDto[] = [];
  public isUniformity: boolean = true;
  public maxClgLevel: number = this.commonService.config.maxCtgLevel;

  public expiryRecs: ExpiryItemMasterRec[];
  public expiryFilterdRecs: ExpiryItemMasterRec[];
  public recordCount: number = 0;
  public expiryShowRecs: ExpiryItemMasterRec[] = [];
  public selectedExpiryRec: ExpiryItemMasterRec;
  public isDirty: boolean = false;
  public columnIds: string[] = [
    // "delCheck", "itemCd", "itemName", "standard",
    "delCheck", "itemCdNameStandard", "expiryType", "discountType",
    "dateAction0", "dateAction1", "dateAction2", "dateAction3", "dateAction4"
  ];

  public columnDefs: TableColumnDef[] = [
    {columnId: "itemCd", header: "商品コード", width: 90, align: "center"},
    {columnId: "itemName", header: "商品名", width: 200, align: "left", rowTooltip: true},
    {columnId: "standard", header: "規格", width: 100, align: "left", rowTooltip: true}
  ]
  public dateActionColumnDefs: TableColumnDef[] = [
    {columnId: "dateAction0", header: "設定1", width: 220, align: "center"},
    {columnId: "dateAction1", header: "設定2", width: 220, align: "center"},
    {columnId: "dateAction2", header: "設定3", width: 220, align: "center"},
    {columnId: "dateAction3", header: "設定4", width: 220, align: "center"},
    {columnId: "dateAction4", header: "設定5", width: 220, align: "center"}
  ]

  private defaultShowLevel: number;
  private ctgSelectConditionQueried: CtgSelectCondition;
  // private subscChanges: Subscription[] = [];
  private subscStore: Subscription;
  // private subscNoRule: Subscription;

  @ViewChild(CtgSelectComponent, { static: false }) ctgSelectComponent: CtgSelectComponent;
  @ViewChild(NumberKeypadComponent, { static: false }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild(MatTable, {static:false}) matTable: MatTable<any>;
  @ViewChild(MatPaginator, {static:false}) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    public ctgService: CtgService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.storeForm.setValue(this.commonService.loginUser.storeCd);
    this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    this.subscStore = this.storeForm.valueChanges.subscribe((value) => {this.storeChanged(value);});
    /*
      At query time, set showNoneForm to true.

    this.subscNoRule = this.noRuleForm.valueChanges.subscribe((value) => {
      if (value) this.showNoneForm.setValue(true);
    });
    */
    this.setTableHeight();
  }

  ngOnDestroy(): void {
    // this.subscChanges.forEach((subsc) => {subsc.unsubscribe();});
    this.subscStore?.unsubscribe();
    // this.subscNoRule?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "expiry-rec-list";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 56;
    let margin = 10 + 5 + 5;
    // let itemBox = 24 + 24 + 5;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  filterRecs() {
    this.expiryFilterdRecs = [];

    for (let i = 0; i < this.expiryRecs.length; i++) {
      let rec = this.expiryRecs[i];
      let isShow = false;
      if (this.showForm.value === true && rec.hasDef()) isShow = true;
      if (this.showNoneForm.value === true && !rec.hasDef()) isShow = true;

      if (isShow) this.expiryFilterdRecs.push(rec);
    }

    this.recordCount = this.expiryFilterdRecs.length;
  }

  reDisplay() {
    this.filterRecs();

    this.matPagenator.pageIndex = 0;
    this.set10KeyPadTarget(undefined);
    this.pageChanged(null);
  }


  setDirty() {
    this.isDirty = true;
    this.uniformity();
    this.disableStore();
    this.ctgSelectComponent.disableAll();
    this.itemCdForm.disable();
    this.itemNameForm.disable();
  }

  enableStore() {
    this.subscStore?.unsubscribe();
    this.storeForm.enable();
    this.subscStore = this.storeForm.valueChanges.subscribe((value) => {this.storeChanged(value);});
  }

  disableStore() {
    this.subscStore?.unsubscribe();
    this.storeForm.disable();
  }

  storeChanged(value) {
    if (this.storeForm.disabled) return;
    this.ctgSelectComponent.setStoreCd(value);
    this.recordCount = 0;
    this.expiryShowRecs = undefined;
  }

  ctgSelectLevel() {
    return this.ctgSelectCondition?.getCurrentSelection().ctgLevel;
  }

  disableQuery() {
    if (this.isDirty) return true;
    if (this.ctgSelectLevel() >= 0) return false;
    if (this.itemCdForm.value !== "") return false;
    if (this.itemNameForm.value !== "") return false;
    if (this.noRuleForm.value) return false;
    return true;
  }

  queryExpiry() {
    if (this.noRuleForm.value) {
      this.showNoneForm.setValue(true);
    }

    this.ctgSelectConditionQueried = new CtgSelectCondition(this.fb);
    this.ctgSelectCondition.copyTo(this.ctgSelectConditionQueried);

    let request: ReqGetItemExpiryItemMaster = {
      maxCtgLevel: this.maxClgLevel,
      access:   this.commonService.loginUser,
      ...this.ctgSelectCondition.getCurrentSelection(),
      itemCd:   this.itemCdForm.value,
      itemName: this.itemNameForm.value,
      // stopFlags: this.commonService.getOrderStopValuesWithoutCase(),
      noRule: this.noRuleForm.value
    }
    this.defaultShowLevel = request.ctgLevel;

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemExpiryItemMaster", request).subscribe(
      (response: RspGetItemExpiryItemMaster) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveExpiry(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveExpiry(response: RspGetItemExpiryItemMaster) {
    if (this.httpBasic.handleAppError(response)) return;

    this.expiryRecs = [];
    this.selectedExpiryRec = undefined;
    this.set10KeyPadTarget(undefined);
    response.rows.forEach((dto) => {
      let rec = new ExpiryItemMasterRec(this.commonService);
      rec.storeCd = dto.storeCd;
      rec.itemCd = dto.itemCd;
      rec.itemName = dto.itemName;
      rec.standard = dto.standard;
      rec.salesPrice = dto.salesPrice;
      rec.expiryType = dto.expiryType;
      rec.discountType = dto.discountType;
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry0, action: dto.action0, discount: dto.discount0 ?? 0});
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry1, action: dto.action1, discount: dto.discount1 ?? 0});
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry2, action: dto.action2, discount: dto.discount2 ?? 0});
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry3, action: dto.action3, discount: dto.discount3 ?? 0});
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry4, action: dto.action4, discount: dto.discount4 ?? 0});

      let ctgRec = new ExpiryMasterRec(this.commonService);
      rec.ctg = ctgRec;
      ctgRec.storeCd = dto.storeCd;
      ctgRec.ctgLevel = dto.ctgLevel;
      ctgRec.ctgCds = [dto.ctgCd0, dto.ctgCd1, dto.ctgCd2, dto.ctgCd3];
      ctgRec.ctgNames = [dto.ctgName0, dto.ctgName1, dto.ctgName2, dto.ctgName3];
      ctgRec.expiryType = undefined;
      ctgRec.discountType = undefined;
      ctgRec.dateDef = undefined;

      rec.prepareEdit(this.defaultShowLevel);

      rec.setListner(() => {this.setDirty()});
      this.expiryRecs.push(rec);
    });
    this.filterRecs();
    if (this.matPagenator) this.matPagenator.pageIndex = 0;
    this.pageChanged(null);
  }

  pageChanged(event: PageEvent) {
    let start: number;
    let end: number;
    if (this.matPagenator) {
      start = this.matPagenator.pageIndex * this.matPagenator.pageSize;
      end = start + this.matPagenator.pageSize;
    } else {
      start = 0;
      end = this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex];
    }
    if (end > this.recordCount) end = this.recordCount;

    this.expiryShowRecs = this.expiryFilterdRecs?.slice(start, end);
    document.getElementById("expiry-rec-list")?.scrollTo(0, 0);
    this.selectedExpiryRec = undefined;
  }

  clickRow(row: ExpiryItemMasterRec) {
    this.selectedExpiryRec = row;
  }

  set10KeyPadTarget(form: FormControl) {
    if (!this.keypadForm.value) return;
    // if (this.numberKeypadComponent) this.numberKeypadComponent.inputField = form;
    if (this.numberKeypadComponent) this.numberKeypadComponent.setTargetForm(form);
  }

  is10KeyPadTarget(form: FormControl) {
    if (!this.keypadForm.value) return;
    if (this.numberKeypadComponent && form === this.numberKeypadComponent.inputField) return true;
    return false;
  }

  saveEdit() {

    this.reqListSave();

    let request: ReqSaveItemExpiryItemMaster = {
      access:   this.commonService.loginUser,
      ...this.ctgSelectConditionQueried.getCurrentSelection(),
      listItem: this.listSave
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("SaveItemExpiryItemMaster", request).subscribe(
      (response: RspSaveItemExpiryItemMaster) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveSaveEdit(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );

    this.isDirty = false;
    this.isUniformity = true;
    this.enableStore();
    this.ctgSelectComponent.enableAll();
    this.itemCdForm.enable();
    this.itemNameForm.enable();
  }

  reqListSave() {
    this.listSave = [];
    this.expiryRecs.forEach((rec) => {
      if (rec.edit.isDirty || rec.edit.delForm.value) {
        let sortData = rec.edit.dateDef.map(item => {
          if (isNaN(parseInt(item.discountForm.value))) item.discountForm.setValue(0);
          if (item.datesToExpiryForm.value != "" && item.actionForm.value == "" && item.discountForm.value > 0) {
            switch (rec.edit.discountType.value) {
              case "1": {
                item.actionForm.setValue("" + item.discountForm.value + "%引き");
                break;
              }
              case "2": {
                item.actionForm.setValue("" + item.discountForm.value + "円引き");
                break;
              }
              case "3": {
                item.actionForm.setValue("" + item.discountForm.value + "円販売");
                break;
              }
            }
          }
          return item.datesToExpiryForm.value === "" || (item.actionForm.value === "" && item.discountForm.value === 0) ?
            {// 「日数n」あるいは「対応n/値引n」のどちらかが指定されていない場合は無視する。（空欄扱い）
              datesToExpiry: -1,
              action: "",
              discount: 0
            } :
            {
              datesToExpiry: parseInt(item.datesToExpiryForm.value !== "" ? item.datesToExpiryForm.value : -1),
              action: item.actionForm.value,
              discount: item.discountForm.value
            }
        });

        if (rec.edit.expiryType.value === "2") {
          sortData.sort((a, b) => {
            if (a.datesToExpiry > b.datesToExpiry) return 1;
            if (a.datesToExpiry < b.datesToExpiry) return -1;
            return 0;
          });
        } else {
          sortData.sort((a, b) => {
            if (a.datesToExpiry < 0) return -1;
            if (b.datesToExpiry < 0) return 1;
            if (a.datesToExpiry > b.datesToExpiry) return -1;
            if (a.datesToExpiry < b.datesToExpiry) return 1;
            return 0;
          });
        }

        let itemSave: SaveItemExpiryItemMasterDto = {
          storeCd: rec.storeCd,
          itemCd: rec.itemCd,
          itemName: rec.itemName,
          standard: rec.standard,
          salesPrice: rec.salesPrice,
          datesToExpiry0: sortData[4].datesToExpiry,
          datesToExpiry1: sortData[3].datesToExpiry,
          datesToExpiry2: sortData[2].datesToExpiry,
          datesToExpiry3: sortData[1].datesToExpiry,
          datesToExpiry4: sortData[0].datesToExpiry,
          action0: sortData[4].action,
          action1: sortData[3].action,
          action2: sortData[2].action,
          action3: sortData[1].action,
          action4: sortData[0].action,
          expiryType: rec.edit.expiryType.value,
          discountType: rec.edit.discountType.value,
          discountRate0: sortData[4].discount,
          discountRate1: sortData[3].discount,
          discountRate2: sortData[2].discount,
          discountRate3: sortData[1].discount,
          discountRate4: sortData[0].discount,
          discount0: sortData[4].discount,
          discount1: sortData[3].discount,
          discount2: sortData[2].discount,
          discount3: sortData[1].discount,
          discount4: sortData[0].discount,
          editMode: (rec.edit.delForm.value || sortData[4].datesToExpiry < 0) ? 0 : 1
        }
        this.listSave.push(itemSave);

        rec.expiryType = rec.edit.expiryType.value;
        rec.discountType = rec.edit.discountType.value;
        for (let i = 0; i < sortData.length; i++) {
          let sdata = sortData[i];
          let org = rec.dateDef[4-i];
          let edit = rec.edit.dateDef[4-i];
          if (rec.edit.delForm.value === true || sortData[4].datesToExpiry < 0) {
            org.datesToExpiry = -1;
            org.action = "";
            org.discount = 0;
            edit.datesToExpiryForm.setValue("");
            edit.actionForm.setValue("");
            edit.discountForm.setValue(0);
          } else {
            org.datesToExpiry = sdata.datesToExpiry;
            org.action = sdata.action;
            org.discount = sdata.discount;
            edit.datesToExpiryForm.setValue(sdata.datesToExpiry < 0 ? "" : sdata.datesToExpiry);
            edit.actionForm.setValue(sdata.action);
            edit.discountForm.setValue(sdata.discount);
          }
        }
        rec.edit.isDirty = false;
        rec.edit.isNew = false;
        rec.edit.delForm.setValue(false);
      }
    });
  }

  receiveSaveEdit(response: RspSaveItemExpiryItemMaster) {
    if (this.httpBasic.handleAppError(response)) return;
  }

  uniformity() {
    this.expiryRecs?.forEach((rec) => {
      if (rec.edit.isDirty) {
        for (let i = 0; i < rec.edit.dateDef.length; i++) {
          for (let j = 1; j < rec.edit.dateDef.length; j++) {
            if ((i != j) && rec.edit.dateDef[i].datesToExpiryForm.value == rec.edit.dateDef[j].datesToExpiryForm.value
              && rec.edit.dateDef[i].datesToExpiryForm.value != "" && rec.edit.dateDef[j].datesToExpiryForm.value != "") {
              this.isUniformity = true;
              return;
            } else {
              this.isUniformity = false;
            }
          }
        }
      }
    });
  }

  clearEdit() {
    this.isUniformity = true;
    this.isDirty = false;
    this.enableStore();
    this.ctgSelectComponent.enableAll();
    this.itemCdForm.enable();
    this.itemNameForm.enable();
    this.expiryRecs.forEach((rec) => {rec.clearEdit();})
    this.reDisplay();
  }

  openCtgDialog() {
    if (this.selectedExpiryRec.ctg.ctgCds[0] === "") {
      this.selectedExpiryRec.ctg.dateDef = [];
      for (let i = 0; i < 5; i++) {
        this.selectedExpiryRec.ctg.dateDef.push({
          datesToExpiry: -1,
          action: "",
          discount: 0
        });
      }
    }
    if (this.selectedExpiryRec.ctg.dateDef) {
      this.openCtgDialogBody();
    } else {
      this.getCtgMaster();
    }
  }

  openCtgDialogBody() {
    let subsc = this.dialog.open(ItemExpiryCtgDialogComponent, {
      width: "600px",
      data: this.selectedExpiryRec,
      disableClose: true
    }).afterClosed().subscribe(
      (response: boolean) => {
        subsc.unsubscribe();
        if (response) {
          this.appyCtgSetting();
        }
      }
    );
  }

  getCtgMaster() {
    let request: ReqGetItemExpiryMasterSingle = {
      access: this.commonService.loginUser,
      storeCd:    this.selectedExpiryRec.storeCd,
      ctgLevel:   this.selectedExpiryRec.ctg.ctgLevel,
      ctgCd0:     this.selectedExpiryRec.ctg.ctgCds[0],
      ctgCd1:     this.selectedExpiryRec.ctg.ctgCds[1],
      ctgCd2:     this.selectedExpiryRec.ctg.ctgCds[2],
      ctgCd3:     this.selectedExpiryRec.ctg.ctgCds[3]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemExpiryMasterSingle", request).subscribe(
      (response: RspGetItemExpiryMasterSingle) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveCtgMaster(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveCtgMaster(response: RspGetItemExpiryMasterSingle) {
    if (this.httpBasic.handleAppError(response)) return;

    let dto = response.row;
    this.selectedExpiryRec.ctg.expiryType = dto.expiryType;
    this.selectedExpiryRec.ctg.discountType = dto.discountType;
    let ctgRec = this.selectedExpiryRec.ctg;
    ctgRec.dateDef = [];
    ctgRec.dateDef.push({datesToExpiry: dto.datesToExpiry0, action: dto.action0, discount: dto.discount0 ?? 0});
    ctgRec.dateDef.push({datesToExpiry: dto.datesToExpiry1, action: dto.action1, discount: dto.discount1 ?? 0});
    ctgRec.dateDef.push({datesToExpiry: dto.datesToExpiry2, action: dto.action2, discount: dto.discount2 ?? 0});
    ctgRec.dateDef.push({datesToExpiry: dto.datesToExpiry3, action: dto.action3, discount: dto.discount3 ?? 0});
    ctgRec.dateDef.push({datesToExpiry: dto.datesToExpiry4, action: dto.action4, discount: dto.discount4 ?? 0});

    this.openCtgDialogBody();
  }

  appyCtgSetting() {
    this.selectedExpiryRec.edit.isDirty = true;
    this.selectedExpiryRec.edit.expiryType.setValue("" + this.selectedExpiryRec.ctg.expiryType);
    this.selectedExpiryRec.edit.discountType.setValue("" + this.selectedExpiryRec.ctg.discountType);
    let item = this.selectedExpiryRec.edit.dateDef;
    let ctg = this.selectedExpiryRec.ctg.dateDef;
    for (let i = 0; i < ctg.length; i++) {
      item[i].datesToExpiryForm.setValue(ctg[i].datesToExpiry < 0 ? "" : ctg[i].datesToExpiry);
      item[i].actionForm.setValue(ctg[i].action);
      item[i].discountForm.setValue(ctg[i].discount);
    }
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

}

