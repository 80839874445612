import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CtgSelectCondition, CtgSelectComponent } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { ReqParamCtgSearchDto } from 'src/app/request/req-param-ctg-search';
import { Subscription } from 'rxjs';
import { CtgParamComponent } from 'src/app/partsCommon/ctg-param/ctg-param.component';

@Component({
  selector: 'app-ctg-param-edit',
  templateUrl: './ctg-param-edit.component.html',
  styleUrls: ['./ctg-param-edit.component.css']
})
export class CtgParamEditComponent implements OnInit, OnDestroy  {

  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public storeCdForm: FormControl = new FormControl(this.commonService.loginUser.storeCd);
  public storeCdSubscription: Subscription;
  public reqParamCtgSearchDto: ReqParamCtgSearchDto;
  public isReadonly: boolean;
  public ctgPath: string = "";

  @ViewChild(CtgParamComponent, {static: true}) public ctgParamComponent : CtgParamComponent;
  @ViewChild(CtgSelectComponent, {static: true}) public ctgSelectElement : CtgSelectComponent;
  public ctg0LabelWidth: string;

  constructor(public commonService: CommonService,
    private fb: FormBuilder) { }

  ngOnInit() {
    // this.commonService.pageTitle = "カテゴリ・パラメータ";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("ctgparam");

    this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    this.storeCdSubscription = this.storeCdForm.valueChanges.subscribe(
      data => this.ctgSelectElement.setStoreCd(this.storeCdForm.value)
    );

    this.ctg0LabelWidth = "" + this.ctgSelectElement.getCtg0LabelWidth() + "px";
  }

  ngOnDestroy() {
    if (this.storeCdSubscription) {
      this.storeCdSubscription.unsubscribe();
    }
  }

  isValid() {
    return this.ctgSelectCondition.formGroup.get("ctgCd0").value != "" ? true: false;
  }

  doQuery() {
    var request: ReqParamCtgSearchDto;
    request = {
      storeCdFv: this.storeCdForm.value,
      ctgLevelFn: this.ctgSelectCondition.formGroup.get("ctgLevel").value,
      ctgCd0Fv: this.ctgSelectCondition.formGroup.get("ctgCd0").value,
      ctgCd1Fv: this.ctgSelectCondition.formGroup.get("ctgCd1").value,
      ctgCd2Fv: this.ctgSelectCondition.formGroup.get("ctgCd2").value,
      ctgCd3Fv: this.ctgSelectCondition.formGroup.get("ctgCd3").value,
      rankFv: ""
    };

    this.ctgParamComponent.doQuery(request);

    this.ctgPath = "[" + this.ctgSelectElement.ctg0Name + "]";
    if (this.ctgSelectCondition.formGroup.get("ctgLevel").value >= 1) {
      this.ctgPath += " - [" + this.ctgSelectElement.ctg1Name + "]";
    }
    if (this.ctgSelectCondition.formGroup.get("ctgLevel").value >= 2) {
      this.ctgPath += " - [" + this.ctgSelectElement.ctg2Name + "]";
    }
    if (this.ctgSelectCondition.formGroup.get("ctgLevel").value >= 3) {
      this.ctgPath += " - [" + this.ctgSelectElement.ctg3Name + "]";
    }
  }

}
