import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ExpiryGroup, ExpiryItemRec } from '../0_def/expiry-item-rec';
import { Subscription } from 'rxjs';
import { OrderGroupDto, ReqAddOrderGroupMst, ReqGetOrderGroupList, RspAddOrderGroupMst, RspGetOrderGroupList } from 'src/app/webservice/order-group';
import { DialogService } from 'src/app/service/dialog.service';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { ExpiryDashboardDto, ReqAddToGroup, RspAddToGroup } from 'src/app/webservice/item-expiry';
import { ReqExtraItem, RspExtraItem } from 'src/app/webservice/extra-item';
import { ItemExpiryService } from '../1_service/item-Expiry.service';
import { PrinterService } from 'src/app/service/printer.service';

@Component({
  selector: 'app-item-expiry-initial',
  templateUrl: './item-expiry-initial.component.html',
  styleUrls: ['./item-expiry-initial.component.css']
})
export class ItemExpiryInitialComponent implements OnInit, OnDestroy {

  public tabSelection: number = 0;
  public storeForm: FormControl = new FormControl();
  public orderGroupForm: FormControl = new FormControl("");
  public orderGroupNameForm: FormControl = new FormControl("");
  public targetOrderGroup: OrderGroupDto;
  public itemCdForm: FormControl = new FormControl("");
  public itemNameForm: FormControl = new FormControl("");
  public itemPriceForm: FormControl = new FormControl(0);
  public itemTaxForm: FormControl = new FormControl(0);
  public registStoreCd: string;
  public registItemCd: string;
  public registGroupId: number;

  public orderGroupList: OrderGroupDto[] = [];
  public orderGroupFilterdList: OrderGroupDto[] = [];
  public expiryItem: ExpiryItemRec = undefined;
  public expiryItemList: ExpiryItemRec[] = [];

  private subsc: Subscription[] = [];

  @Input() dialogInput: ExpiryDashboardDto;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private dialogService: DialogService,
    private itemExpiryService: ItemExpiryService,
    private printerService: PrinterService
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    if (!this.dialogInput) {
      this.storeForm.setValue(this.commonService.loginUser.storeCd);
    } else {
      this.storeForm.setValue(this.dialogInput.storeCd);
      this.storeForm.disable();
    }

    this.subsc.push(this.storeForm.valueChanges.subscribe(
      (value) => {
        this.expiryItemList = [];
        this.getOrderGroup();
      }
    ));
    this.subsc.push(this.orderGroupForm.valueChanges.subscribe(
      (value) => {
        this.expiryItem = undefined;
        if (value) {
          this.orderGroupFilterdList = this.orderGroupList.filter(option => option.orderGroupUsrCd.includes(value));
        } else {
          this.orderGroupFilterdList = this.orderGroupList.slice();
        }
        if (this.orderGroupFilterdList.length === 1) {
          this.targetOrderGroup = this.orderGroupFilterdList[0];
          this.orderGroupNameForm.setValue(this.orderGroupFilterdList[0].orderGroupName);
        } else {
          this.targetOrderGroup = undefined;
          this.orderGroupNameForm.setValue("");
        }
        if (this.dialogInput) {
          this.orderGroupFilterdList = [];
        }
      }
    ));
    this.getOrderGroup();
    this.setTableHeight();
    this.printerService.getPrinterLayoutList();

    // this.itemCdForm.setValue("4902522650045");
  }

  ngOnDestroy(): void {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
    if (this.printerService.printer?.isConnected()) this.printerService.printer.disconnect();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "dialog-close-btn";
    let dialogReserve = 0;
    if (document.getElementById(id)) {
      dialogReserve = this.commonService.getHeightBelow(id) + 5;
    }
    id = "expiry-item-list";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 0;    // 56;
    let margin = 10;
    let btnBox = 0;             // 24;
    let height = remHeight - paginatorHeight - margin - btnBox - dialogReserve;
    if (height < 200) height = 200;
    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  tabChanged(event) {

  }

  getOrderGroup() {
    let request: ReqGetOrderGroupList = {
      access: this.commonService.loginUser,
      storeCd: this.storeForm.value,
      mode: "ItemExpiry"
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderGroupList", request).subscribe(
      (response: RspGetOrderGroupList) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveGetOrderGroup(response);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveGetOrderGroup(response: RspGetOrderGroupList) {
    if (this.httpBasic.handleAppError(response)) return;

    this.orderGroupList = response.rows;

    if (!this.dialogInput) {
      this.orderGroupForm.setValue("", { emitEvent: true });
      this.orderGroupNameForm.setValue("", { emitEvent: true });
    } else {
      this.orderGroupForm.setValue(this.dialogInput.groupUsrCd, { emitEvent: true });
      this.orderGroupNameForm.setValue(this.dialogInput.groupName, { emitEvent: true });
      this.orderGroupForm.disable();
      this.orderGroupNameForm.disable();
      setTimeout(() => { document.getElementById("item-code")?.focus(); }, 0);
    }
  }

  selectOrderGroup() {
    this.dialogService.openOrderGroupSelectDialog(
      this.commonService.pageTitle,
      { currentGroupUsrCd: "", allGroups: this.orderGroupList }
    ).subscribe((response: OrderGroupDto) => {
      if (!response) return;
      this.orderGroupForm.setValue(response.orderGroupUsrCd, { emitEvent: true });
    });
  }

  onGroupScan(val: string) {
    this.orderGroupForm.setValue(val);
  }

  openGroupBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data != undefined && data != null && data !== "") {
          this.orderGroupForm.setValue(data);
        }
      }
    )
  }

  disableAddOrderGroup() {
    if (this.dialogInput) return true;
    if (this.orderGroupForm.value == "") return true;
    // if (this.orderGroupNameForm.value == "") return true;
    // if (this.orderGroupFilterdList.length > 1) return true;
    if (this.orderGroupFilterdList.length === 1 && this.orderGroupNameForm.value === this.orderGroupFilterdList[0].orderGroupName) return true;
    return false;
  }

  addOrderGroup() {
    let request: ReqAddOrderGroupMst = {
      access: this.commonService.loginUser,
      storeCd: this.storeForm.value,
      groupUsrCd: this.orderGroupForm.value,
      groupName: this.orderGroupNameForm.value,
      beginDate: this.commonService.formatDate(new Date()),
      endDate: "2099/12/31",
      type: 2
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("AddOrderGroupMst", request).subscribe(
      (response: RspAddOrderGroupMst) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveAddOrderGroup(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveAddOrderGroup(response: RspAddOrderGroupMst) {
    if (this.httpBasic.handleAppError(response)) return;

    this.targetOrderGroup = {
      storeCd: this.storeForm.value,
      orderGroupId: response.groupId,
      orderGroupUsrCd: this.orderGroupForm.value,
      orderGroupName: this.orderGroupNameForm.value,
      beginDate: this.commonService.formatDate(new Date()),
      endDate: "2099/12/31"
    };
    let index = this.orderGroupList.findIndex((item) => item.orderGroupUsrCd === this.orderGroupForm.value);
    if (index >= 0) {
      this.orderGroupList.splice(index, 1, this.targetOrderGroup);
    } else {
      this.orderGroupList.push(this.targetOrderGroup);
    }
    this.orderGroupList.sort((a, b) => {
      if (a.orderGroupUsrCd < b.orderGroupUsrCd) return -1;
      if (a.orderGroupUsrCd > b.orderGroupUsrCd) return 1;
      return 0;
    });
    this.orderGroupFilterdList = this.orderGroupList.filter(option => option.orderGroupUsrCd.includes(this.orderGroupForm.value));
  }

  orderGroupValid() {
    let groupUsrCd = this.orderGroupForm.value
    if (!groupUsrCd) return false;
    let groups = this.orderGroupList.filter((item) => item.orderGroupUsrCd === groupUsrCd);
    if (!groups) return false;
    if (groups.length !== 1) return false;
    return true;
  }

  async queryItem() {
    if (!this.itemCdForm.value) return;
    this.expiryItem = undefined;

    /*
    let rec: ExpiryItemRec = this.expiryItemList.find((rec) => rec.storeCd === this.storeForm.value && rec.itemCd === this.itemCdForm.value);
    if (rec) {
      this.expiryItem = rec;
      return;
    }
    */

    let storeCd = this.storeForm.value;
    let itemCd = this.itemCdForm.value;
    let groupUsrCd = this.orderGroupForm.value;

    this.itemExpiryService.getExpiryItemByJan(storeCd, itemCd, groupUsrCd).then(
      (rec) => {
        if (rec === null) {
          this.notInItemMaster();
          this.expiryItem = rec;
          return;
        } else if (rec === undefined) {
          this.expiryItem = rec;
          return;     // error
        }
        if (!rec.targetGroup) {
          /*
          this.registStoreCd = storeCd;
          this.registItemCd = itemCd;
          this.registGroupId = this.targetOrderGroup.orderGroupId;
          */
          this.notInItemMaster();
          rec.targetGroup = undefined;
          this.expiryItem = rec;
          return;
        }
        /*
        rec.setAction();
        rec.prepareEdit();
        */
        rec.setListner((mode) => {
          this.changeListener(mode);
        });
        this.expiryItem = rec;
        this.addToExpiryItemList(this.expiryItem);
      }
    );
  }

  addToExpiryItemList(expiryItem) {
    if (!this.expiryItemList) this.expiryItemList = [];

    let index = this.expiryItemList.findIndex((rec) => rec.itemCd === expiryItem.itemCd);
    if (index >= 0) this.expiryItemList.splice(index, 1);
    this.expiryItemList = [expiryItem, ...this.expiryItemList];

    this.setTableHeight();
  }

  notInItemMaster() {
    this.expiryItem = null;
    this.registStoreCd = this.storeForm.value;
    this.registItemCd = this.itemCdForm.value;
    this.registGroupId = this.targetOrderGroup.orderGroupId;
    this.itemNameForm.setValue("");
    this.itemPriceForm.setValue(0);
    this.itemTaxForm.setValue(8);
  }

  onItemScan(itemCd) {
    this.itemCdForm.setValue(itemCd);
    this.queryItem();
  }

  openItemBarcodeDialog() {
    if (this.expiryItem?.isDirty) return;

    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data != undefined && data != null && data !== "") {
          this.itemCdForm.setValue(data);
          this.queryItem();
        }
      }
    )
  }

  registItemValid() {
    if (this.itemNameForm.value == "") return false;
    if (this.itemPriceForm.value == 0) return false;
    if (this.itemPriceForm.value == "") return false;
    if (this.itemTaxForm.value == 0) return false;
    if (this.itemTaxForm.value == "") return false;
    return true;
  }

  registItemMaster() {
    let request: ReqExtraItem = {
      access: this.commonService.loginUser,
      mode: "update",
      storeCd: this.registStoreCd,
      itemCd: this.registItemCd,
      itemName: this.itemNameForm.value,
      salesPrice: this.itemPriceForm.value,
      tax: this.itemTaxForm.value
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("ExtraItem", request).subscribe(
      (response: RspExtraItem) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveRegistItemMaster(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveRegistItemMaster(response: RspExtraItem) {
    if (this.httpBasic.handleAppError(response)) return;

    this.registItemToGroup();
  }

  inOrderGroup() {
    if (this.expiryItem.groupList.length === 0) return false;
    if (!this.expiryItem.groupList.find((item) => item.groupUsrCd === this.targetOrderGroup.orderGroupUsrCd)) return false;
    return true;
  }

  registItemToGroup() {
    let request: ReqAddToGroup = {
      access: this.commonService.loginUser,
      storeCd: this.registStoreCd,
      itemCd: this.registItemCd,
      groupId: this.registGroupId,
      expiryGroupLabel: this.commonService.literal["itemExpiryGroup"]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("ItemExpiryAddToGroup", request).subscribe(
      (response: RspAddToGroup) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveRegistItemToGroup(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveRegistItemToGroup(response: RspAddToGroup) {
    if (this.httpBasic.handleAppError(response)) return;

    this.queryItem();
  }

  removedFromGroup(group: ExpiryGroup) {
    this.expiryItem = undefined;
    // this.itemCdForm.setValue("");
  }

  changeListener(mode: boolean) {
    if (!this.dialogInput) {
      if (mode) {
        this.storeForm.disable({ emitEvent: false });
        this.orderGroupForm.disable({ emitEvent: false });
        this.itemCdForm.disable({ emitEvent: false });
      } else {
        this.storeForm.enable({ emitEvent: false });
        this.orderGroupForm.enable({ emitEvent: false });
        this.itemCdForm.enable({ emitEvent: false });
      }
    }
  }

  isDirty() {
    if (!this.expiryItem) return false;
    if (!this.expiryItem.targetGroup) return false;
    return this.expiryItem.targetGroup.edit.isDirty;
  }

  openLabelPrinterDialog() {
    this.dialogService.openLabelPrinterDialog(this.printerService);
  }

  printerNotReady() {
    if (!this.printerService.printerConfig) return true;
    if (!this.printerService.printerConfig.printer) return true;
    if (!this.printerService.printerConfig.printer.isConnected()) return true;
    return false;
  }

  canDeactivate() {
    if (!this.isDirty()) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
