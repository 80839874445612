import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ItemSimpleRec } from 'src/app/common/item-simple-rec';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ItemListSimpleComponent } from 'src/app/partsCommon/item-list-simple/item-list-simple.component';
import { ItemSelectSimpleCondition } from 'src/app/partsCommon/item-select-simple/item-select-simple.component';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { ReqItemReplaceDelDto } from 'src/app/request/req-item-replace-delete';
import { ReqItemReplaceSearch } from 'src/app/request/req-item-replace-search';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { RspItemReplaceDto, RspItemReplaceInit } from 'src/app/response/rsp-item-replace';
import { RspItemReplaceSearch } from 'src/app/response/rsp-item-replace-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { takeUntil } from 'rxjs/operators';
import { ReqItemReplaceAddDto } from 'src/app/request/req-item-replace-add';

@Component({
  selector: 'app-item-replace',
  templateUrl: './item-replace.component.html',
  styleUrls: ['./item-replace.component.css']
})
export class ItemReplaceComponent implements OnInit, OnDestroy {
  // memory leak
  private subscription$ = new Subject();

  public formGroup: FormGroup;
  public tableWidth: any;
  public columnIds: string[] = ["removeItem", "itemCdSFv", "itemNameSFv", "itemCdDFv", "itemNameDFv", "dateBeginFv"];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'itemCdSFv', header: "商品コード(元)", width: 100, align: "center" },
    { columnId: 'itemNameSFv', header: "商品名(元)", width: 300 },
    { columnId: 'itemCdDFv', header: "商品コード(先)", width: 100, align: "center" },
    { columnId: 'itemNameDFv', header: "商品名(先)", width: 300 },
    { columnId: 'dateBeginFv', header: "発効日", width: 100, align: "center" }
  ];
  public itemSelectSimpleCondition: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fb);
  public itemSelectSimpleConditionOrigin: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fbOrigin);

  isDisableItemD = true;
  isDisableItemS = true;
  isDisableItemCdDDirect = false;
  isDisableItemCdSDirect = false;

  public stores: LoginStoreDto[];
  public replaceList: RspItemReplaceDto[] = [];

  public selectedItem: ItemSimpleRec;
  get f() {
    return this.formGroup.controls;
  }
  @ViewChild(ItemListSimpleComponent, { static: true }) itemListSimpleComponent: ItemListSimpleComponent;
  @ViewChild("sectionSelectCondHeader", { static: true }) sectionSelectCond: SectionHeaderComponent;
  @ViewChild("sectionItemsHeader", { static: true }) sectionItems: SectionHeaderComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private fbOrigin: FormBuilder,
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = "商品差替え"
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.stores = [...this.commonService.stores];
    this.initFormGroup();
    this.initItemReplace();
    this.calcTableWidth();

    this.formGroup.controls.itemCdDDirect.valueChanges
      .pipe(takeUntil(this.subscription$))
      .subscribe(value => {
        if (value === this.f.itemCdS.value) {
          // disable InplutD
          this.isDisableItemCdDDirect = true;
        } else {
          this.isDisableItemCdDDirect = false;
        }
      });

    this.formGroup.controls.itemCdSDirect.valueChanges
      .pipe(takeUntil(this.subscription$))
      .subscribe(value => {
        if (value === this.f.itemCdD.value) {
          // disable InplutS
          this.isDisableItemCdSDirect = true;
        } else {
          this.isDisableItemCdSDirect = false;

        }
      });
    this.formGroup.controls.itemCdS.valueChanges
      .pipe(takeUntil(this.subscription$))
      .subscribe(value => {
        if (value === this.f.itemCdDDirect.value) {
          this.isDisableItemCdDDirect = true;
        } else {
          this.isDisableItemCdSDirect = false;
        }
      });

    this.formGroup.controls.itemCdD.valueChanges
      .pipe(takeUntil(this.subscription$))
      .subscribe(value => {
        if (value === this.f.itemCdSDirect.value) {
          this.isDisableItemCdSDirect = true;
        } else {
          this.isDisableItemCdDDirect = false;
        }
      });
  }

  ngOnDestroy() {
    this.subscription$.next();
    this.subscription$.complete();

  }

  initItemReplace() {
    this.httpBasic.initItemReplace().pipe(takeUntil(this.subscription$)).subscribe(
      result => {
        this.checkResult(result);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      itemCdS: [],
      itemNameS: [],
      itemCdSDirect: [""],
      itemCdD: [],
      itemNameD: [],
      itemCdDDirect: [""],
      dateBegin: []
    }
    );
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 削除ボタン
    for (var colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }
  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }
  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  onClickCell(columnId: string, item: Object) {

  }

  doQueryItemSimple() {
    this.copyRequest();
    this.itemListSimpleComponent.doQuery();
    this.selectedItem = undefined;
    this.sectionItems.openSectionBody();
  }

  doCancel() {
    this.formGroup.reset();
    this.isValidSelect();
    // if (this.selectedItem !== undefined && this.itemListSimpleComponent.itemSimpeList !== []) {
    if (this.selectedItem !== undefined && this.itemListSimpleComponent.itemSimpeList.length !== 0) {
        this.sectionItems.openSectionBody();
    }
  }

  doInsert() {
    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    const request: ReqItemReplaceAddDto = {
      itemCdSFv: this.formGroup.get("itemCdS").value,
      itemNameSFv: this.formGroup.get("itemNameS").value,
      itemCdDFv: this.formGroup.get("itemCdD").value,
      itemNameDFv: this.formGroup.get("itemNameD").value,

      dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US')
    };
    this.httpBasic.addItemReplace(request).pipe(takeUntil(this.subscription$)).subscribe(
      result => {
        this.checkResult(result);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  doDelete(itemReplace: RspItemReplaceDto) {
    let msg = "<span >次の差替え情報を削除しますか？";
    msg += "<br><br>商品(元)：　" + itemReplace.itemCdSFv + ": " + itemReplace.itemNameSFv;
    msg += "<br>商品(先)：　" + itemReplace.itemCdDFv + ": " + itemReplace.itemNameDFv;
    msg += "<br>発効日：　" + itemReplace.dateBeginFv;
    msg += "</span>";
    this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).pipe(takeUntil(this.subscription$)).subscribe(
      data => {
        if (data === true) {
          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          const request: ReqItemReplaceDelDto = {
            itemCdSFv: itemReplace.itemCdSFv,
            itemNameSFv: itemReplace.itemNameSFv,
            itemCdDFv: itemReplace.itemCdDFv,
            itemNameDFv: itemReplace.itemNameDFv,

            dateBeginFv: itemReplace.dateBeginFv
          };
          this.httpBasic.deleteItemReplace(request).pipe(takeUntil(this.subscription$))
            .subscribe(
              result => this.checkResult(result),
              error => {
                this.clearProgressState();
                this.httpBasic.handleError(error);
              }
            );
        }
      }
    );
    return;
  }

  itemChange(item: ItemSimpleRec) {
    this.selectedItem = item;
    this.isValidSelect();
  }

  itemSelect(itemCd: string, itemName: string) {
    if (!this.selectedItem) {
      return;
    }
    this.formGroup.get(itemCd).setValue(this.selectedItem.itemCdFv);
    this.formGroup.get(itemName).setValue(this.selectedItem.itemNameFv);
    // disable button btn select product
    this.isValidSelect();
  }


  directInputS() {
    if (this.formGroup.get("itemCdSDirect").value === "") {
      return;
    }
    this.itemReplaceSearch(this.formGroup.get('itemCdSDirect').value, 'itemCdS', 'itemNameS');
    if(this.f.itemCdD.value != this.f.itemCdS.value)
    {
      this.isDisableItemCdDDirect = false;
      this.isDisableItemCdSDirect = false;
    }
  }

  directInputD() {
    if (this.formGroup.get('itemCdDDirect').value === '') {
      return;
    }
    this.itemReplaceSearch(this.formGroup.get('itemCdDDirect').value, 'itemCdD', 'itemNameD');
    if(this.f.itemCdD.value != this.f.itemCdS.value)
    {
      this.isDisableItemCdDDirect = false;
      this.isDisableItemCdSDirect = false;
    }
  }

  selectTableItem() {
    // TODO ?
  }

  isValidSelect() {
    if (this.selectedItem === undefined) {
      return false;
    }
    const itemCdsValue = '' + this.formGroup.get('itemCdS').value;
    const itemCdDValue = '' + this.formGroup.get('itemCdD').value;
    if (itemCdsValue !== '' && itemCdDValue === '' && itemCdsValue === this.selectedItem.itemCdFv) {
      this.isDisableItemD = true;
      this.isDisableItemS = false;

    } else if (itemCdDValue !== '' && itemCdsValue === '' && itemCdDValue === this.selectedItem.itemCdFv) {
      this.isDisableItemD = false;
      this.isDisableItemS = true;

    } else if (itemCdDValue !== '' && itemCdsValue !== '') {
      if (itemCdsValue === this.selectedItem.itemCdFv) {
        this.isDisableItemD = true;
        this.isDisableItemS = false;
        return;
      }
      if (itemCdDValue === this.selectedItem.itemCdFv) {
        this.isDisableItemS = true;
        this.isDisableItemD = false;
        return;
      }
      if (itemCdDValue !== this.selectedItem.itemCdFv && itemCdDValue !== this.selectedItem.itemCdFv) {
        this.isDisableItemS = false;
        this.isDisableItemD = false;
        return;
      }
    } else {
      this.isDisableItemS = false;
      this.isDisableItemD = false;
    }

  }

  isValidDirectInput(itemCdDirect: string) {
    if (this.formGroup.get(itemCdDirect).value === null || this.formGroup.get(itemCdDirect).value === "") return false;
    return true;
  }

  isValidSubmit() {
    if (this.formGroup.get("itemCdS").value === this.formGroup.get("itemCdD").value
      || (this.formGroup.get("itemCdS").value === null && this.formGroup.get("itemNameS").value === null)
      || (this.formGroup.get("itemCdD").value === null && this.formGroup.get("itemNameD").value === null)
      || this.formGroup.get("dateBegin").value === null) return false;
    return true;
  }

  getDateEndMin() {
    let min = new Date();
    min.setDate(min.getDate() + 14);
    return min;
  }

  clearProgressState() {
    this.commonService.closeSpinner();
  }

  itemReplaceSearch(itemCdDirect: string, itemCd: string, itemName: string) {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    const request: ReqItemReplaceSearch = {
      itemCdFv: itemCdDirect,
      access: { ...this.commonService.loginUser }
    };
    this.httpBasic.itemReplaceSearch(request).pipe(takeUntil(this.subscription$)).subscribe(
      data => {
        this.checkResultItem(data.result[0], itemCd, itemName, itemCdDirect);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  checkResultItem(response: RspItemReplaceSearch, itemCd: string, itemName: string, itemCdValue: string = "") {
    if (response !== undefined) {
      this.formGroup.get(itemCd).setValue(response.itemCdFv);
      this.formGroup.get(itemName).setValue(response.itemNameFv);
    } else {
      this.formGroup.get(itemCd).setValue(itemCdValue);
      this.formGroup.get(itemName).setValue("未登録商品");
    }
    this.isValidSelect();
    // TODO Check
    this.clearProgressState();
  }

  checkResult(response: RspItemReplaceInit) {
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.replaceList = [];
    const data = response.result;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      this.replaceList.push({
        itemCdSFv: element.itemCdSFv,
        itemNameSFv: element.itemNameSFv,
        itemCdDFv: element.itemCdDFv,
        itemNameDFv: element.itemNameDFv,
        dateBeginFv: element.dateBeginFv,
      });
    }

  }

  copyRequest() {
    this.itemSelectSimpleConditionOrigin.formGroup.patchValue(this.itemSelectSimpleCondition.formGroup.value);
    this.itemSelectSimpleConditionOrigin.ctgSelectCondition.formGroup.patchValue(this.itemSelectSimpleCondition.ctgSelectCondition.formGroup.value);
  }
}
