import { Component, Inject, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ItemSearchConDto, ItemSimDto, ReqPromSearchCondDto, ReqPromSimItemSearch, RspPromSimItemSearch } from 'src/app/page/prom-sim/0_def/promSimDef';
import { ReqItemByJanList } from 'src/app/request/req-item-by-jan-list';
import { ItemByJanListDto, RspItemByJanList } from 'src/app/response/rsp-item-by-jan-list';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

export interface PromSelectData {
  request: ReqPromSimItemSearch,
  rows: ItemSimDto[]
};

@Component({
  selector: 'app-prom-select-list-dialog',
  templateUrl: './prom-select-list-dialog.component.html',
  styleUrls: ['./prom-select-list-dialog.component.css']
})
export class PromSelectListDialogComponent implements OnInit, OnDestroy, OnChanges {

  public recordCount: number = 0;
  public fileResult: string = "";
  file: File;
  public isError: boolean = false;
  public isReadOnly: boolean = false;
  public formFile: FormControl = new FormControl();
  public formItemList: FormControl = new FormControl("");
  public itemListByForm : string[] = [];
  public itemResults: ItemByJanListDto[] = [];
  public itemResultsDisplay: ItemByJanListDto[];
  public itemListOrigin: string[] = [];
  private subscriptionFile: Subscription;
  @ViewChild(MatTable, {static:true}) matTable: MatTable<any>;

  public columnIds: string[] = ["itemCdFv", "itemNameFv", "standardFv", "confirmStatusFn"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "itemCdFv", header: "商品コード", width: 120, align: "center" },
    { columnId: "itemNameFv", header: "商品名", width: 250, align: "left" },
    { columnId: "standardFv", header: "規格", width: 80, align: "left" }
  ];

  constructor(
    public dialogRef: MatDialogRef<PromSelectListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PromSelectData,
    public commonService: CommonService,
    private httpBasic: HttpBasicService
  ) { }
  ngOnChanges() {

  }

  ngOnInit() {
    this.subscriptionFile = this.formFile.valueChanges.subscribe(
      () => {this.fileChanged();}
    );
    if(this.data.rows == undefined) this.data.rows = [];
    this.data.rows.forEach((el) => {
      this.itemListOrigin.push(el.itemCdFv);
    })
  }

  OnChanges() {
    
  }

  ngOnDestroy() {
    if (this.subscriptionFile) this.subscriptionFile.unsubscribe();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onClose() {
    
    let itemCdList: ItemSearchConDto[] = [];
    this.itemResults.forEach(item => itemCdList.push(
      {
        itemCdFv: item.itemCdFv,
        editCost: -1,
        editSell: -1
      }
    ))
    let newRequest: ReqPromSimItemSearch = {
      access: this.commonService.loginUser,
      itemSearchCond: {
        listItemCdFv: []
      },
      promSearchCond: this.genReqPromSearchCondDto(),
      promOrderDto: null,
      promSimDto: null,
      maxCtgLevel: this.commonService.config.maxCtgLevel,
      editMode: 1
    }
    let request = this.data.request ?? newRequest;
    request.editMode = 1;
    request.itemSearchCond.listItemCdFv = itemCdList;
   
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemSimList", request).subscribe(
      (response : RspPromSimItemSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveItemResult(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
     }
    );
  }

  receiveItemResult(response: RspPromSimItemSearch) {
    if (this.httpBasic.handleAppError(response)) return;
    let itemListResult: ItemSimDto[] = [];
    response?.rows.forEach(row => itemListResult.push(row))
    this.dialogRef.close(itemListResult);
  }

  genReqPromSearchCondDto() {
    let today = new Date;
    const dto: ReqPromSearchCondDto = { 
      startDate: this.commonService.formatDate(today),
      finishDate : this.commonService.formatDate(today),
      storeGroupType : -1,
      storeGroupcd : -1
    }
    return dto;
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    }
  }

  fileChanged() {
    let fileInput = document.getElementById('input-file')  as HTMLInputElement;
    if (fileInput.files[0]) {
      this.file = fileInput.files[0];
      this.readCsv();
      this.isError = false;
      this.isReadOnly = true;
    } 
  }

  addItemByCSV() {
    let elem = document.getElementById("input-file") as HTMLInputElement;
    elem.value = "";
    elem.click();
  }

  clearItemList() {
    this.isReadOnly = false;
    this.file = undefined;
    this.formItemList.setValue("");
    this.formItemList.markAsDirty;
    this.formFile.setValue("");
    this.formFile.markAsDirty;
    this.itemListByForm = [];
  }

  getItemList() {
    this.itemResults = []; 
    this.itemResultsDisplay = []; 
    this.itemListByForm = [];
    let itemData : string = this.formItemList.value.trim();
    let lines = itemData.split("\n");

    for (let line of lines) {
      line = line.replace("\r", "");
      line = line.replace(/ /g, "");
      line = line.replace(/　/g, "");   /* 全角スペース */
      if (line != "" && !this.itemListByForm.find(element=> element == line)) {
        this.itemListByForm.push(line);

      }
    }
    
    let request: ReqItemByJanList = {
      access: this.commonService.loginUser,
      storeCdFv: this.commonService.loginUser.storeCd,
      janCdList: this.itemListByForm
    };
  
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("ItemByJanList", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePromList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );   
  }

  receivePromList(response: RspItemByJanList) {
    if (this.httpBasic.handleAppError(response)) return;
    this.itemResults = []; 
    this.itemResultsDisplay = []; 
    response?.items.forEach(itemDto => {
      if(itemDto.notFound) {
        this.itemResultsDisplay.push({
          ...itemDto,
          itemNameFv: "商品マスターに存在しません"
        })
      }else {
        this.itemResults.push(itemDto);
        this.itemResultsDisplay.push(itemDto);
      }  
    })
    this.itemListOrigin.forEach(itemCd => {
      this.itemResults.forEach(row =>{
        if(itemCd == row.itemCdFv){this.itemResults.splice(this.itemResults.indexOf(row), 1)}
      })
    })
    this.formItemList.markAsDirty();
  //  this.clearItemList();
  }

  readCsv() {
    if (!this.file) return;
    let fileReader = new FileReader();
    this.commonService.openSpinner(this.commonService.pageTitle, "読込み中・・・")
    fileReader.onload = () => {      
      this.readComplete(fileReader);
    }
    fileReader.onerror = () => {
      this.isError = true;
      this.commonService.closeSpinner();

      if (fileReader.error.name == "NotReadableError") {
        this.commonService.openErrorDialog(this.commonService.pageTitle, 
          "ファイルの読み込みに失敗しました。<br><br>" +
          "ファイル選択後にファイルが変更されました。<br>" +
          "ファイルを再度選択してください。"
          );
        return;
      }
      if (fileReader.error.name == "NotFoundError") {
        this.commonService.openErrorDialog(this.commonService.pageTitle, 
          "ファイルの読み込みに失敗しました。<br><br>" +
          "ファイルが見つかりません。<br>" +
          "ファイルを再度選択してください。"
          );
        return;
      }
      this.commonService.openErrorDialog(this.commonService.pageTitle, "ファイルの読み込みに失敗しました。<br><br>"+fileReader.error);
      return;
    };
    fileReader.readAsText(this.file, "SJIS");
  }

  readComplete(fileReader: FileReader) {
    this.fileResult = fileReader.result.toString().replace("\r", "");
    let alllines = this.fileResult.split("\n");
    let listItems: string[] = [];
    for (let i = 0; i < alllines.length; i++) {
      if (i === 0 && !this.commonService.config.isReadCsvFirstRow) {
        continue;
      }
      let line = alllines[i];
      if (line.trim() === "") continue;
      let cells = line.split("\t"); 
      for (let j = 0; j < cells.length; j++) {
        listItems.push(cells[j].trim())
      }
    }
    let itemData: string = listItems.toString();
    this.formItemList.setValue(itemData.replace(/,/g,"\n"));
    this.commonService.closeSpinner();
  }

  isDisableBtn() {
    if(this.itemResults?.length > 0) return false;
    return true;
  }

}
