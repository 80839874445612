import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ErrorNotificationData {
  errorTitle : string;
  errorMessage : string;
  btnText?: string;
};

@Component({
  selector: 'app-error-notification-dialog',
  templateUrl: './error-notification-dialog.component.html',
  styleUrls: ['./error-notification-dialog.component.css']
})
export class ErrorNotificationDialogComponent implements OnInit {

  public btnText: string = "戻る";

  constructor(
    public dialogRef: MatDialogRef<ErrorNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorNotificationData) { }

  ngOnInit() {
    if (this.data.btnText && this.data.btnText !== "") {
      this.btnText = this.data.btnText;
    }
  }

}
