import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { ItemRec } from "src/app/common/item-rec";
import { TableColumnDef } from "src/app/common/table-column-def";
import { BarcodeDialogComponent } from "src/app/dialog/barcode-dialog/barcode-dialog.component";
import { ItemSummaryDialogComponent } from "src/app/dialog/item-summary-dialog/item-summary-dialog.component";
import { OrderEditBgcolorDialogComponent } from "src/app/dialog/order-edit-bgcolor/order-edit-bgcolor-dialog.component";
import { NumberKeypadComponent } from "src/app/partsCommon/number-keypad/number-keypad.component";
import { RspItemDto } from "src/app/response/rsp-item-search";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { OrderGroupNameDto, ReqAddOrderGroupItem, ReqGetOrdersByUser, ReqUpdateOrderByUser, ReqUpdateOrderByUserDto, RspAddOrderGroupItem, RspGetOrdersByUser, RspUpdateOrderByUser } from "src/app/webservice/order-by-user";
import { OrderByUser, OrderGroup, OrderGroupItem } from "./order-by-user";
import { SelectOrderGroupComponent } from "./select-order-group/select-order-group.component";

@Component({
  selector: "app-order-by-user",
  templateUrl: "./order-by-user.component.html",
  styleUrls: ["./order-by-user.component.css"]
})
export class OrderByUserComponent implements OnInit, OnDestroy {

  public isOrderEditable = false;
  public isStockEditable = false;

  public selectedOrderGroup: OrderGroup;
  public recordCount: number = 0;
  public orderList: OrderGroupItem[] = [];
  public isDirty: boolean = false;
  public formItemCode: FormControl = new FormControl("");
  public formItemName: FormControl = new FormControl("");
  public formItemStandard: FormControl = new FormControl("");
  public isAddDisable: boolean = true;
  public searchedItem: OrderGroupItem;
  public addItem: OrderByUser;
  public isGroupButtonOpen: boolean = false;

  private janMap = {};
  private caseJanMap = {};
  public orderGroups: OrderGroup[] = [];
  public noOrderGroup: OrderGroup;
  public orderGroupNames: OrderGroupNameDto[] = [];
  public groupItems: OrderByUser[] = [];
  public caseItems: OrderByUser[] = [];
  public noGroupItems: OrderByUser[] = [];

  /*
  public columnIdsHeader: string[] = ["checkBox", "itemCdFv", "itemNameFv", "standardFv", "orderNumForm", "orderLotFn", "orderBaraNumFn", "stockNumForm",
    "orderBacklog", "deliveryDate", "minZaiFn", "maxZaiFn", "itemClass", "salesResult", "orderResult", "itemLocation"];
  public columnIdsRow: string[] = ["checkBox", "itemCdFv", "itemNameFv", "standardFv", "orderNumForm", "orderLotFn", "orderBaraNumFn", "stockNumForm",
    "orderBacklog", "deliveryDate", "minZaiFn", "maxZaiFn", "itemClass", "salesResult1", "salesResult2",
    "salesResult3", "salesResult4", "orderResult1", "orderResult2", "orderResult3", "orderResult4", "itemLocation"];
  */
  public columnIdsHeader: string[] = ["checkBox", "itemCdFv", "itemNameFv", "standardFv", "orderBacklog", "orderNumForm", "orderLotFn", "orderBaraNumFn",
    "stockNumForm", "deliveryDate", "minZaiFn", "maxZaiFn", "itemClass", "salesResult", "orderResult", "itemLocation"];
  public columnIdsRow: string[] = ["checkBox", "itemCdFv", "itemNameFv", "standardFv", "orderBacklog", "orderNumForm", "orderLotFn", "orderBaraNumFn",
    "stockNumForm", "deliveryDate", "minZaiFn", "maxZaiFn", "itemClass", "salesResult1", "salesResult2",
    "salesResult3", "salesResult4", "orderResult1", "orderResult2", "orderResult3", "orderResult4", "itemLocation"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "standardFv", header: "規格", width: 60, maxWidth: 60, align: "left", colspan: 1, rowTooltip: true },
    { columnId: "orderLotFn", header: "単", width: 20, align: "right", colspan: 1, tooltip: "発注単位（ロット）" },
    { columnId: "orderBaraNumFn", header: "バラ", width: 30, align: "right",tooltip: "発注単位に調整したバラ発注数" },
    { columnId: "orderBacklog", header: "入荷", width: 40, maxWidth: 40, align: "right", colspan: 1, tooltip: "入荷予定（カッコ内は定番以外）" },
    { columnId: "itemLocation", header: "棚", width: 100, maxWidth: 100, align: "left", colspan: 1, rowTooltip: true },
    { columnId: "deliveryDate", header: "納品日", width: 75, maxWidth: 75, align: "center", colspan: 1 },
    { columnId: "minZaiFn", header: "低", width: 30, align: "right", colspan: 1, tooltip: "最低陳列" },
    { columnId: "maxZaiFn", header: "大", width: 30, maxWidth: 50, align: "right", colspan: 1, tooltip: "最大陳列" },
    { columnId: "itemClass", header: "区分", width: 40, align: "left", colspan: 1, rowTooltip: true},
    // { columnId: "orderClass", header: "発注区分", width: 40, align: "left", colspan: 1},
    { columnId: "salesResult", header: "販売", width: 80, align: "right", colspan: 4 },    // For header only
    { columnId: "salesResult1", header: "", width: 28, align: "right", colspan: 1 },
    { columnId: "salesResult2", header: "", width: 28, align: "right", colspan: 1 },
    { columnId: "salesResult3", header: "", width: 28, align: "right", colspan: 1 },
    { columnId: "salesResult4", header: "", width: 28, align: "right", colspan: 1 },
    { columnId: "orderResult", header: "発注", width: 80, align: "right", colspan: 4 },    // For header only
    { columnId: "orderResult1", header: "", width: 25, align: "right", colspan: 1 },
    { columnId: "orderResult2", header: "", width: 20, align: "right", colspan: 1 },
    { columnId: "orderResult3", header: "", width: 20, align: "right", colspan: 1 },
    { columnId: "orderResult4", header: "", width: 20, align: "right", colspan: 1 }
  ];

  private subscForm: Subscription;
  private subscFormItemCode: Subscription;
  private propClosingTime: Date;
  private serverTime: Date;
  private deviceTime: Date;
  private timeLag: number;
  private closingTimeList: Date[];
  private timerId: any = -1;

  /* param for change orderList */
  public orderUpdateChangeMap: Map<string, ReqUpdateOrderByUserDto> = new Map();
  public orderBaraUpdateChangeMap: Map<string, string> = new Map();
  public orderAndStockFormSubscriptions: Subscription[] = [];
  /* end param for change orderList */

  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) matPagenator: MatPaginator;
  @ViewChild(NumberKeypadComponent, { static: true }) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    if (this.commonService.config.orderGroup.targetItemStopFlags === undefined) {
      this.commonService.config.orderGroup.targetItemStopFlags = this.commonService.getOrderableStopList();
    }
    let len = this.route.snapshot.url.length;
    if (len > 0) {
      let url: string = this.route.snapshot.url[len - 1].path;
      if (url == "orderByUser" || url == "orderStockByUser") this.isOrderEditable = true;
      if (url == "stockByUser" || url == "orderStockByUser") this.isStockEditable = true;
    }

    this.subscFormItemCode = this.formItemCode.valueChanges.subscribe(
      (data) => {
        this.searchedItem = undefined;
        this.formItemName.setValue("");
        this.formItemStandard.setValue("");
      }
    );
    this.getOrderItems();
    this.setTableHeight();
  }

  ngOnDestroy(): void {
    this.subscFormItemCode?.unsubscribe();
    this.subscForm?.unsubscribe();

    this.clearTimer();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "item-table-box";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 56;
    let margin = 10 + 5;
    let itemBox = 29;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - itemBox - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  orderUpdate() {
    const request: ReqUpdateOrderByUser = {
      access: this.commonService.loginUser,
      itemList: Array.from(this.orderUpdateChangeMap, (([name, value]) => value)) || []
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdateOrderAndStock", request).subscribe(
      (response: RspUpdateOrderByUser) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiverOrderUpdate(request, response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiverOrderUpdate(request: ReqUpdateOrderByUser, response: RspUpdateOrderByUser) {
    let isAdjusted: boolean = false;

    this.httpBasic.handleAppError(response)

    this.unSubscriptionOrderAndStockForm();
    const resOrderUpdateList = response.resultUpdateOrderList;
    const resStockUpdateList = response.resultUpdateStockList;

    for (const reqItem of request.itemList) {
      // change items values from response
      let itemUpdatedInOrderGroup = this.orderGroups.find(groupTmp => reqItem.groupId === groupTmp.groupId)
            ?.items.find(itemTmp => reqItem.itemCdFv === itemTmp.item.itemCdFv);
      if (!itemUpdatedInOrderGroup) {
        itemUpdatedInOrderGroup = this.noOrderGroup?.items.find(itemTmp => reqItem.itemCdFv === itemTmp.item.itemCdFv);
      }
      if (!itemUpdatedInOrderGroup) continue;

      const orderItemUpdated = resOrderUpdateList.find(resOrderUpdate => reqItem.itemCdFv === resOrderUpdate.itemCdFv);
      if (orderItemUpdated) {
        if (itemUpdatedInOrderGroup.item.orderDate !== orderItemUpdated.orderDateFv) {
          // Order Date is adjusted to the next orderable date
          isAdjusted = true;
          itemUpdatedInOrderGroup.item.orderDateAdjusted(
            orderItemUpdated.orderDateFv,
            orderItemUpdated.orderNumFn,
            orderItemUpdated.versionFn
          );
        } else {
          itemUpdatedInOrderGroup.item.orderNumModFn = orderItemUpdated.orderNumFn;
          itemUpdatedInOrderGroup.item.orderNumVersionFn = orderItemUpdated.versionFn;
          itemUpdatedInOrderGroup.item.orderNumForm.setValue(itemUpdatedInOrderGroup.item.getValue("orderNumFn"));
        }
      }

      const stockItemUpdated = resStockUpdateList.find(resStockUpdate => reqItem.itemCdFv === resStockUpdate.itemCdFv);
      if (stockItemUpdated) {
        itemUpdatedInOrderGroup.item.stockNumModFn = stockItemUpdated.stockNumFn;
        itemUpdatedInOrderGroup.item.stockNumVersionFn = stockItemUpdated.versionFn;
        itemUpdatedInOrderGroup.item.stockNumForm.setValue(itemUpdatedInOrderGroup.item.getValue("stockNumFn"));
      }

      // update row in groups
      if (reqItem.rowAction === 1 && reqItem.groupId !== -1) {
        this.removeItemFromOldGroupItem(reqItem.groupId, reqItem.itemCdFv);
        let orderGroupStillHadRemovedItem = [];
        this.orderGroups.forEach(group => group.items?.forEach(item => {
          if (item.item.itemCdFv === reqItem.itemCdFv) {
            orderGroupStillHadRemovedItem.push(group.groupId);
          }
        }));
        if (orderGroupStillHadRemovedItem.length <= 0) {
          if (!this.noOrderGroup) {
            /*
            this.noOrderGroup = {
              groupId: -1,
              groupUsrCd: "",
              groupName: this.commonService.literal["orderGroup"] + "無し",
              itemsBara: [],
              items: []
            };
            */
            this.createNoOrderGroup();
          }
          this.noOrderGroup.itemsBara.push(itemUpdatedInOrderGroup);
          this.noOrderGroup.items.push(itemUpdatedInOrderGroup);
          if (itemUpdatedInOrderGroup.item.caseOrderItems) {
            itemUpdatedInOrderGroup.item.caseOrderItems.forEach((item) => {
              let gitem = new OrderGroupItem();
              gitem.item = item;
              this.noOrderGroup.items.push((gitem));
            });
          }
        }
        this.pageChanged();
      }
    }
    this.orderCancel();

    if (isAdjusted) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, "締め時間を超えたため、翌発注日に調整された商品があります。");
      this.checkClosingTimeAll();
      this.serverTime = new Date();
      let tm = response.serverTime.split(":");
      this.serverTime.setHours(parseInt(tm[0]), parseInt(tm[1]), parseInt(tm[2]));
      this.deviceTime = new Date();
      this.timeLag = this.serverTime.getTime() - this.deviceTime.getTime();
      this.nextClosingTime();
    }
  }

  orderCancel() {
    this.unSubscriptionOrderAndStockForm();
    this.orderGroups.forEach((group) => {
      group.items?.forEach((item) => { item.cancel(); })
    });
    this.noOrderGroup?.items?.forEach((item) => { item.cancel(); });

    this.orderUpdateChangeMap.clear();
    this.orderBaraUpdateChangeMap.clear();
    this.initHandleChangeOrderAndStockNum();
  }

  getOrderItems() {
    let request: ReqGetOrdersByUser = {
      access: this.commonService.loginUser,
      mode: "user",
      groupId: 0,
      itemCd: "",
      orderableStopFlag: this.commonService.getOrderableStopList(),       // Only orderable items for noGroupItems
      allowCaseOrder: this.commonService.config.orderEditCaseItem,
      orderableStopFlagCase: this.commonService.config.orderEditCaseItem === false ? [] : this.commonService.getOrderableCaseStopList()
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderByUser", request).subscribe(
      (response: RspGetOrdersByUser) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveOrderItems(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveOrderItems(response: RspGetOrdersByUser) {
    if (this.httpBasic.handleAppError(response)) return;

    this.propClosingTime = new Date();
    let tm = response.propClosingTime.split(":");
    this.propClosingTime.setHours(parseInt(tm[0]), parseInt(tm[1]), parseInt(tm[2]));
    this.serverTime = new Date();
    tm = response.serverTime.split(":");
    this.serverTime.setHours(parseInt(tm[0]), parseInt(tm[1]), parseInt(tm[2]));
    this.deviceTime = new Date();
    this.timeLag = this.serverTime.getTime() - this.deviceTime.getTime();

    this.closingTimeList = [this.propClosingTime];
    this.groupItems = [];
    this.caseItems = [];

    if (this.commonService.config.orderEditCaseItem) {
      response.caseItems?.forEach((dto) => {
        let item = this.caseJanMap[dto.itemCdFv];
        if (item == undefined) {
          let newitem = new OrderByUser(this.commonService);
          newitem.initByDto(dto);
          this.caseItems.push(newitem);
          this.caseJanMap[newitem.itemCdFv] = newitem;
          this.addItemClosingTime(newitem.closingTime);
        } else {
          item.addOrderDate(
            dto.orderDate,
            dto.orderNumVersionFn > 0 ? dto.orderNumModFn : dto.orderNumFn,
            dto.orderNumVersionFn
          );
        }
      });
    }

    response.groupItems.forEach((dto) => {
      let item: OrderByUser = this.janMap[dto.itemCdFv];
      if (!item) {
        item = new OrderByUser(this.commonService);
        item.initByDto(dto);
        if (this.commonService.config.orderEditCaseItem) {
          item.caseOrderItems = this.caseItems.filter((item) => item.unitItemCdFv === dto.itemCdFv);
        }
        this.groupItems.push(item);
        this.janMap[item.itemCdFv] = item;
        this.addItemClosingTime(item.closingTime);
      }
    });

    this.noGroupItems = [];
    response.noGroupItems.forEach((dto) => {
      let item = this.janMap[dto.itemCdFv];
      if (!item) {
        item = new OrderByUser(this.commonService);
        item.initByDto(dto);
        if (this.commonService.config.orderEditCaseItem) {
          item.caseOrderItems = this.caseItems.filter((item) => item.unitItemCdFv === dto.itemCdFv);
        }
        this.noGroupItems.push(item);
        this.janMap[item.itemCdFv] = item;
        this.addItemClosingTime(item.closingTime);
      }
    });

    this.orderGroups = [];
    for (let dto of response.orderGroups) {
      let orderGroup: OrderGroup = {
        groupId: dto.groupId,
        groupUsrCd: dto.groupUsrCd,
        groupName: dto.groupName,
        // items: []
        itemsBara: undefined,
        items: undefined
      };
      this.orderGroups.push(orderGroup);
    }

    if (this.noGroupItems.length > 0) {
      /*
      this.noOrderGroup = {
        groupId: -1,
        groupUsrCd: "",
        groupName: this.commonService.literal["orderGroup"] + "無し",
        itemsBara: [],
        items: []
      };
      */
      this.createNoOrderGroup();
      this.noGroupItems.forEach((item) => {
        let gitem = new OrderGroupItem();
        gitem.item = item;
        this.noOrderGroup.itemsBara.push(gitem);
      });

      if (this.isOrderEditable && this.commonService.config.orderGroup.itemSortByOrderNum) {
        if (this.commonService.config.orderEditInBara) {
          this.noOrderGroup.itemsBara.sort((a, b) => {
            if (a.getValue("orderBaraNumFn") > b.getValue("orderBaraNumFn")) return -1;
            if (a.getValue("orderBaraNumFn") < b.getValue("orderBaraNumFn")) return 1;
            return 0;
          });
        } else {
          this.noOrderGroup.itemsBara.sort((a, b) => {
            if (a.getValue("orderNumFn") > b.getValue("orderNumFn")) return -1;
            if (a.getValue("orderNumFn") < b.getValue("orderNumFn")) return 1;
            return 0;
          });
        }
      }
      if (this.commonService.config.orderEditCaseItem === false) {
        this.noOrderGroup.items = [...this.noOrderGroup.itemsBara];
      } else {
        this.noOrderGroup.itemsBara.forEach((item) => {
          this.noOrderGroup.items.push(item);
          if (item.item.caseOrderItems) {
            item.item.caseOrderItems.sort((a, b) => {
              if (a.caseConvFactFn < b.caseConvFactFn) return -1;
              if (a.caseConvFactFn > b.caseConvFactFn) return 1;
              return 0;
            });
            item.item.caseOrderItems.forEach((item) => {
              let gitem = new OrderGroupItem();
              gitem.item = item;
              this.noOrderGroup.items.push(gitem);
            });
          }
        });
      }
    } else {
      this.noOrderGroup = undefined;
    }

    this.orderGroupNames = response.orderGroupNames;

    if (this.orderGroups.length > 0) {
      this.selectOrderGroup(this.orderGroups[0]);
    } else if (this.noOrderGroup != undefined) {
      this.selectOrderGroup(this.noOrderGroup);
    } else {
      this.selectedOrderGroup = undefined;
      this.orderList = [];
    }

    this.checkClosingTimeAll();

    this.unSubscriptionOrderAndStockForm();
    this.initHandleChangeOrderAndStockNum();
    this.setTableHeight();

    this.nextClosingTime();
  }

  createNoOrderGroup() {
    this.noOrderGroup = {
      groupId: -1,
      groupUsrCd: "",
      groupName: this.commonService.literal["orderGroup"] + "無し",
      itemsBara: [],
      items: []
    };
  }

  getOrderItemsByGroup(groupId: number, orderGroup?: OrderGroup) {
    let request: ReqGetOrdersByUser = {
      access: this.commonService.loginUser,
      mode: "group",
      groupId: groupId,
      itemCd: "",
      orderableStopFlag: this.commonService.config.orderGroup.targetItemStopFlags,      // this.commonService.getOrderableStopList(),
      allowCaseOrder: this.commonService.config.orderEditCaseItem,
      orderableStopFlagCase: this.commonService.config.orderEditCaseItem === false ? [] : this.commonService.getOrderableCaseStopList()
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderByUser", request).subscribe(
      (response: RspGetOrdersByUser) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveOrderItemsByGroup(response, orderGroup);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveOrderItemsByGroup(response: RspGetOrdersByUser, trgtOrderGroup?: OrderGroup) {
    if (this.httpBasic.handleAppError(response)) return;

    if (this.commonService.config.orderEditCaseItem) {
      response.caseItems?.forEach((dto) => {
        let item = this.caseJanMap[dto.itemCdFv];
        if (item == undefined) {
          let newitem = new OrderByUser(this.commonService);
          newitem.initByDto(dto);
          this.caseItems.push(newitem);
          this.caseJanMap[newitem.itemCdFv] = newitem;
          this.addItemClosingTime(newitem.closingTime);
        } else {
          item.addOrderDate(
            dto.orderDate,
            dto.orderNumVersionFn > 0 ? dto.orderNumModFn : dto.orderNumFn,
            dto.orderNumVersionFn
          );
        }
      });
    }

    response.groupItems.forEach((dto) => {
      let item = this.janMap[dto.itemCdFv];
      if (item == undefined) {
        let newitem = new OrderByUser(this.commonService);
        newitem.initByDto(dto);
        if (this.commonService.config.orderEditCaseItem) {
          newitem.caseOrderItems = this.caseItems.filter((item) => item.unitItemCdFv === dto.itemCdFv);
        }
        this.groupItems.push(newitem);
        this.janMap[newitem.itemCdFv] = newitem;
        this.addItemClosingTime(newitem.closingTime);
      } else {
        item.addOrderDate(
          dto.orderDate,
          dto.orderNumVersionFn > 0 ? dto.orderNumModFn : dto.orderNumFn,
          dto.orderNumVersionFn
        );
      }
    });

    if (trgtOrderGroup) {
      trgtOrderGroup.itemsBara = [];
      trgtOrderGroup.items = [];
    }

    if (response.orderGroups.length === 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, "対象商品が存在しません。");
      return;
    }
    let dto = response.orderGroups[0];
    let orderGroup: OrderGroup;
    if (trgtOrderGroup) {
      orderGroup = trgtOrderGroup;
    } else {
      orderGroup = {
        groupId: dto.groupId,
        groupUsrCd: dto.groupUsrCd,
        groupName: dto.groupName,
        itemsBara: [],
        items: []
      };
      this.orderGroups.push(orderGroup);
    }
    for (let itemDto of dto.items) {
      let item = this.janMap[itemDto.itemCd];
      if (item != undefined) {
        let gitem = new OrderGroupItem();
        gitem.item = item;
        orderGroup.itemsBara.push(gitem);
      } else {
        // console.log(itemDto.itemCd + " in " + dto.groupName + " not found in groupItems");
      }
    }
    if (this.isOrderEditable && this.commonService.config.orderGroup.itemSortByOrderNum) {
      if (this.commonService.config.orderEditInBara) {
        orderGroup.itemsBara.sort((a, b) => {
          if (a.getValue("orderBaraNumFn") > b.getValue("orderBaraNumFn")) return -1;
          if (a.getValue("orderBaraNumFn") < b.getValue("orderBaraNumFn")) return 1;
          return 0;
        });
      } else {
        orderGroup.itemsBara.sort((a, b) => {
          if (a.getValue("orderNumFn") > b.getValue("orderNumFn")) return -1;
          if (a.getValue("orderNumFn") < b.getValue("orderNumFn")) return 1;
          return 0;
        });
      }
    }
    if (this.commonService.config.orderEditCaseItem === false) {
      orderGroup.items = [...orderGroup.itemsBara];
    } else {
      // if (orderGroup.items === undefined) orderGroup.items = [];
      orderGroup.itemsBara.forEach((item) => {
        orderGroup.items.push(item);
        if (item.item.caseOrderItems) {
          item.item.caseOrderItems.sort((a, b) => {
            if (a.caseConvFactFn < b.caseConvFactFn) return -1;
            if (a.caseConvFactFn > b.caseConvFactFn) return 1;
            return 0;
          });
          item.item.caseOrderItems.forEach((item) => {
            let gitem = new OrderGroupItem();
            gitem.item = item;
            orderGroup.items.push(gitem);
          });
        }
      });
    }

    this.selectOrderGroup(orderGroup);

    let serverTime = this.calcServerTime();
    this.groupItems.forEach((item) => {item.checkClosingTime(this.propClosingTime, serverTime)});
    // this.noGroupItems.forEach((item) => {item.checkClosingTime(this.propClosingTime, serverTime)});

    this.unSubscriptionOrderAndStockForm();
    this.initHandleChangeOrderAndStockNum();
    this.setTableHeight();

    this.nextClosingTime();
  }

  getOrderItemsByJan(janCd: string) {
    let jan = janCd;
    if (this.commonService.config.itemQuery?.janZeroPadding?.enable &&
        jan.length < this.commonService.config.itemQuery?.janZeroPadding?.toLength
       ) {
      jan = this.commonService.zeroPadding(janCd, this.commonService.config.itemQuery.janZeroPadding.toLength)
    }

    let request: ReqGetOrdersByUser = {
      access: this.commonService.loginUser,
      mode: "jan",
      groupId: 0,
      // itemCd: janCd,
      itemCd: jan,
      orderableStopFlag: this.commonService.config.orderGroup.targetItemStopFlags,      // this.commonService.getOrderableStopList(),
      allowCaseOrder: this.commonService.config.orderEditCaseItem,
      orderableStopFlagCase: this.commonService.getOrderableCaseStopList()
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderByUser", request).subscribe(
      (response: RspGetOrdersByUser) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveOrderItemsByJan(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.clearSearchItem();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveOrderItemsByJan(response: RspGetOrdersByUser) {
    if (this.httpBasic.handleAppError(response)) {
      this.clearSearchItem();
      return;
    }

    if (response.groupItems.length == 0) {
      this.clearSearchItem();
      this.commonService.openErrorDialog(this.commonService.pageTitle, "商品が見つからないか、発注が許可されていません。");
      return;
    }

    if (this.commonService.config.orderEditCaseItem) {
      response.caseItems.forEach((dto) => {
        let item = this.caseJanMap[dto.itemCdFv];
        if (item == undefined) {
          let newitem = new OrderByUser(this.commonService);
          newitem.initByDto(dto);
          this.caseItems.push(newitem);
          this.caseJanMap[newitem.itemCdFv] = newitem;
          this.addItemClosingTime(newitem.closingTime);
        } else {
          item.addOrderDate(
            dto.orderDate,
            dto.orderNumVersionFn > 0 ? dto.orderNumModFn : dto.orderNumFn,
            dto.orderNumVersionFn
          );
        }
      });
    }

    response.groupItems.forEach((dto) => {
      this.addItem = this.janMap[dto.itemCdFv];
      if (this.addItem == undefined) {
        this.addItem = new OrderByUser(this.commonService);
        this.addItem.initByDto(dto);
        if (this.commonService.config.orderEditCaseItem) {
          this.addItem.caseOrderItems = this.caseItems.filter((item) => item.unitItemCdFv === dto.itemCdFv);
        }
        this.janMap[this.addItem.itemCdFv] = this.addItem;
        this.addItemClosingTime(this.addItem.closingTime);
      } else {
        this.addItem.addOrderDate(
          dto.orderDate,
          dto.orderNumVersionFn > 0 ? dto.orderNumModFn : dto.orderNumFn,
          dto.orderNumVersionFn
        );
      }
    });
    this.addItem.checkClosingTime(this.propClosingTime, this.calcServerTime());

    this.setAddItemFormValue();
    // When case item, we receive unit item code.
    if (this.gotoItemPage(this.addItem.itemCdFv)) this.isAddDisable = true;

    this.nextClosingTime();
  }

  getGroupName(group: OrderGroup) {
    if (this.commonService.config.orderGroup?.showGroupCode) {
      return group.groupUsrCd + ":" + group.groupName;
    } else {
      return group.groupName;
    }
  }

  selectOrderGroup(orderGroup: OrderGroup) {
    if (!orderGroup.items) {
      this.getOrderItemsByGroup(orderGroup.groupId, orderGroup);
      return;
    }

    this.selectedOrderGroup = orderGroup;
    this.matPagenator.pageIndex = 0;
    this.recordCount = orderGroup.items.length;
    this.pageChanged();

    this.formItemCode.setValue("");
    this.formItemName.setValue("");
    this.formItemStandard.setValue("");
    this.isAddDisable = true;

    this.groupButtonOpenClose(false);
  }

  pageChanged() {
    this.orderList = [];
    let count = 0;
    // this.recordCount = this.selectedOrderGroup.items.length || 0;
    this.recordCount = this.selectedOrderGroup.items.length || 0;
    for (let i = this.matPagenator.pageIndex * this.matPagenator.pageSize; i < this.recordCount && count < this.matPagenator.pageSize; i++) {
      if (this.selectedOrderGroup === this.noOrderGroup) {
        this.selectedOrderGroup.items[i].delFlagForm.disable();
        this.selectedOrderGroup.items[i].item.orderNumForm.enable({emitEvent: false});
        this.selectedOrderGroup.items[i].item.orderBaraNumForm.enable({emitEvent: false});
        this.selectedOrderGroup.items[i].item.stockNumForm.enable({emitEvent: false});
      } else {
        /*
        if (this.commonService.config.orderEditCaseItem && this.selectedOrderGroup.items[i].item.unitItemCdFv === "") {
          this.selectedOrderGroup.items[i].delFlagForm.enable();
        } else {
          this.selectedOrderGroup.items[i].delFlagForm.disable();
        }
        */
        if (this.commonService.config.orderEditCaseItem) {
          if (this.selectedOrderGroup.items[i].item.unitItemCdFv === "") {
            this.selectedOrderGroup.items[i].delFlagForm.enable();
          } else {
            this.selectedOrderGroup.items[i].delFlagForm.disable();
          }
        } else {
          this.selectedOrderGroup.items[i].delFlagForm.enable();
        }

        this.selectedOrderGroup.items[i].item.orderNumForm.enable();
        this.selectedOrderGroup.items[i].item.orderBaraNumForm.enable();
        this.selectedOrderGroup.items[i].item.stockNumForm.enable();
      }
      this.orderList.push(this.selectedOrderGroup.items[i]);
      count++;
    }
    this.numberKeypadComponent.inputField = undefined;
    this.subscForm?.unsubscribe();
    this.matTable.renderRows();

    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  isKeyboardLocked() {
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  is10KeyPadTarget(form: FormControl) {
    if (form === this.numberKeypadComponent.inputField) return true;
    return false;
  }

  set10KeyPadTarget(form: FormControl, isJan?: boolean) {
    this.numberKeypadComponent.setTargetForm(form);
    if (isJan) this.numberKeypadComponent.clearAtFirstInput(false);

    this.subscForm?.unsubscribe();
    if (!this.isDirty) {
      this.subscForm = form.valueChanges.subscribe(
        (value) => { this.isDirty = true; }
      );
    }
  }

  set10KeyPadTargetStd(form: FormControl) {
    this.set10KeyPadTarget(form);
    this.numberKeypadComponent.setMaxCol(10);
  }

  set10KeyPadTargetJanCd(form: FormControl) {
    this.set10KeyPadTarget(form, true);
    this.numberKeypadComponent.setMaxCol(13);
    this.numberKeypadComponent.setStringMode();
  }
  
  /*
  openOrderGroupDialog() {
    let subsc = this.commonService.dialog.open(SelectOrderGroupComponent, {
      disableClose: true,
      data: this.orderGroupNames
    }).afterClosed().subscribe(
      (groupId) => {
        subsc.unsubscribe();
        if (!groupId) return;
        let group = this.orderGroups.find((group) => group.groupId === groupId);
        if (group != undefined) {
          this.selectOrderGroup(group);
        } else {
          this.getOrderItemsByGroup(groupId);
        }
      }
    );
  }
  */
  openOrderGroupDialog() {
    let subsc = this.commonService.dialog.open(SelectOrderGroupComponent, {
      disableClose: true,
      data: {
        orderGroupNames: this.orderGroupNames,
        orderGroups: this.orderGroups,
        selectedOrderGroup: this.selectedOrderGroup
      }
    }).afterClosed().subscribe(
      (groupId) => {
        subsc.unsubscribe();
        if (!groupId) return;
        let group = this.orderGroups.find((group) => group.groupId === groupId);
        if (group != undefined) {
          this.selectOrderGroup(group);
        } else {
          this.getOrderItemsByGroup(groupId);
        }
      }
    );
  }

  searchJan() {
    this.searchedItem = undefined;
    this.formItemName.setValue("");
    this.formItemStandard.setValue("");
    let itemCode = this.formItemCode.value;
    if (itemCode == undefined || itemCode === "") return;
    if (this.selectedOrderGroup == undefined) return;

    /*
    let itemIndex = this.selectedOrderGroup.items.findIndex((gitem) => gitem.item.itemCdFv === itemCode);
    if (itemIndex >= 0) {
      this.matPagenator.pageIndex = Math.floor(itemIndex / this.matPagenator.pageSize);
      this.pageChanged(undefined);
      this.searchedItem = this.orderList.find((gitem) => gitem.item.itemCdFv === itemCode);
      // if (this.matTable) this.matTable.renderRows();
      return;
    }
    */

    if (this.gotoItemPage(itemCode)) return;

    this.addItem = this.janMap[itemCode];
    if (this.addItem != undefined) {
      this.setAddItemFormValue();
      return;
    }
    this.getOrderItemsByJan(itemCode);
  }

  gotoItemPage(itemCode: string): boolean {
    let itemIndex = this.selectedOrderGroup.items.findIndex((gitem) => gitem.item.itemCdFv === itemCode);
    if (itemIndex >= 0) {
      this.matPagenator.pageIndex = Math.floor(itemIndex / this.matPagenator.pageSize);
      this.pageChanged();
      this.searchedItem = this.orderList.find((gitem) => gitem.item.itemCdFv === itemCode);

      setTimeout(() => {this.scrollToSearchedItem(itemCode);}, 0);

      return true;
    }
    return false;
  }

  scrollToSearchedItem(itemCode: string) {
    let containerElement = document.getElementById("item-table-box");
    if (!containerElement) return;
    let trElement = document.getElementById(itemCode);
    if (!trElement) return;
    let containerRect = containerElement.getBoundingClientRect();
    let trRect = trElement.getBoundingClientRect();
    if (trRect.bottom > containerRect.bottom) {
      let headerElement = document.getElementById("table-header");
      if (!headerElement) return;
      let headerRect = headerElement.getBoundingClientRect();
      let y = trRect.top - headerRect.bottom ;
      containerElement.scrollTo(0, y);
    }
  }

  setAddItemFormValue() {
    this.formItemCode.setValue(this.addItem.itemCdFv);
    this.formItemName.setValue(this.addItem.itemNameFv);
    this.formItemStandard.setValue(this.addItem.standardFv);
    if (this.selectedOrderGroup != this.noOrderGroup) {
      this.isAddDisable = false;
    } else {
      this.isAddDisable = true;
    }
  }

  onScan(janCode: string) {
    this.formItemCode.setValue(janCode);
    this.searchJan();
  }

  addItemToGroup() {
    // Add to WEB_ORDER_GROUP_ITEMS table
    let request: ReqAddOrderGroupItem = {
      access: this.commonService.loginUser,
      groupItem: {
        groupId: this.selectedOrderGroup.groupId,
        storeCdFv: this.commonService.loginUser.storeCd,
        itemCdFv: this.addItem.itemCdFv
      }
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("AddOrderGroupItem", request).subscribe(
      (response: RspAddOrderGroupItem) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiverAddItemToGroup(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.clearSearchItem();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiverAddItemToGroup(response: RspAddOrderGroupItem) {
    if (this.httpBasic.handleAppError(response)) return;

    let gitem = new OrderGroupItem();
    gitem.item = this.addItem;
    this.selectedOrderGroup.itemsBara.push(gitem);
    this.selectedOrderGroup.items.push(gitem);
    this.recordCount++;
    if (this.commonService.config.orderEditCaseItem === true) {
      if (this.addItem.caseOrderItems) {
        this.addItem.caseOrderItems.sort((a, b) => {
          if (a.caseConvFactFn < b.caseConvFactFn) return -1;
          if (a.caseConvFactFn > b.caseConvFactFn) return 1;
          return 0;
        });
        this.addItem.caseOrderItems.forEach((item) => {
          let index = this.selectedOrderGroup.items.findIndex((gitem) => gitem.item.itemCdFv === item.itemCdFv);
          if (index >= 0) {
            this.selectedOrderGroup.items.splice(index, 1);
          }
          let gitem = new OrderGroupItem();
          gitem.item = item;
          this.selectedOrderGroup.items.push(gitem);
          this.recordCount++;
        });
      }
    }

    this.searchedItem = gitem;
    let itemIndex = this.selectedOrderGroup.items.length - 1;
    this.matPagenator.pageIndex = Math.floor(itemIndex / this.matPagenator.pageSize);
    this.pageChanged();
    // Remove from noGroupItems if the added item is in noGroupItems
    this.removeNewItemFromNoGroupItem(gitem.item.itemCdFv);
    this.clearSearchItem();
  }

  rowBgColor(gitem: OrderGroupItem) {
    if (this.isOrderEditable == false) return 1;          // 背景色 無し

    let orderStop = gitem.item.getValue("orderStopFlagFnValue")
    let def = this.commonService.config.orderStopFlag.find((item) => item.value === orderStop);
    /*
    if (def == undefined) return 3;
    if (def.isAutoOrder == false) return 3;
    if (!gitem.item.orderableWeekday()) return 2;
    */
    if (def == undefined || def.isAutoOrder == false) {
      // ケース品
      if (def.caseItem) {
        if (!gitem.item.isClosed) return 6;
        if (!gitem.item.orderableWeekday()) return 6; // 発注不可曜日
        return 7;
      }
  
      // 手動発注
      if (!gitem.item.orderableWeekday()) return 4; // 発注不可曜日
      if (!gitem.item.isClosed) return 3;           // 発注可能曜日、締め前
      return 4;                                     // 発注可能曜日、締め後
    }
    // 自動発注
    if (!gitem.item.orderableWeekday()) return 2;   // 発注不可曜日
    if (!gitem.item.isClosed) return 1;             // 発注可能曜日、締め前
    return 2;                                       // 発注可能曜日、締め後
  }

  openItemSummaryDialog(gitem: OrderGroupItem) {
    let orderByUser: OrderByUser = gitem.item;
    let itemRec: ItemRec = new ItemRec((orderByUser as unknown) as RspItemDto, this.commonService);
    if (orderByUser.caseItems != undefined) itemRec.caseItems = orderByUser.caseItems;
    let subsc = this.commonService.dialog.open(ItemSummaryDialogComponent, {
      disableClose: false,
      data: itemRec
    }).afterClosed().subscribe(
      () => {
        subsc.unsubscribe();
        if (orderByUser.caseItems == undefined) orderByUser.caseItems = itemRec.caseItems;
      }
    );
  }

  handlerChangeDeleteCheckbox(checked: boolean, item: OrderGroupItem) {
    if (!item || !item.item) return;
    const key = `${this.selectedOrderGroup.groupId}_${item.item.itemCdFv}`
    if (checked) {
      if (this.orderUpdateChangeMap.has(key)) {
        this.orderUpdateChangeMap.get(key).rowAction = 1;
        return;
      }
      let pdate = item.item.getNextOrderableDate(item.item.orderDate);
      let pdateNext = item.item.getNextOrderableDate(item.item.orderDateNext);
  
      const newMapItem: ReqUpdateOrderByUserDto = {
        groupId: this.selectedOrderGroup.groupId,
        storeCdFv: this.commonService.loginUser.storeCd,
        itemCdFv: item.item.itemCdFv,
        closingTime: item.item.closingTime,

        orderNumFn: item.item.getValue('orderNumForm'),
        orderNumModFn: item.item.orderNumModFn,
        orderNumVersionFn: item.item.orderNumVersionFn,
        orderDate: item.item.orderDate,
        orderDateNext: item.item.orderDateNext,
        deliveryDate: item.item.deliveryDate,
        deliveryDateNext: item.item.deliveryDateNext,
  
        stockNumFn: item.item.getValue('stockNumForm'),
        stockNumModFn: item.item.stockNumModFn,
        stockNumVersionFn: item.item.stockNumVersionFn,

        rowAction: 1, // 0: noChange; 1: delete; 2: add
        isUpdateOrder: false,
        isUpdateStock: false
      };
      this.orderUpdateChangeMap.set(key, newMapItem);
      return;
    }
    // else
    if (this.orderUpdateChangeMap.has(key)) {
      const updateMapItem = this.orderUpdateChangeMap.get(key)
      updateMapItem.rowAction = 0;
      if (!updateMapItem.isUpdateOrder && !updateMapItem.isUpdateStock) {
        this.orderUpdateChangeMap.delete(key);
      }
    }
  }

  handlerChangeOrderNumItem(itemArg: OrderGroupItem) {
    let orderGroupHadChangedItem = [];
    for (let i = 0; i < this.orderGroups.length; i++) {
      let group = this.orderGroups[i];
      if (!group.items) continue;
      group.items.forEach((item) => {
        if (item.item.itemCdFv === itemArg.item.itemCdFv) {
          orderGroupHadChangedItem.push(group.groupId);
        }
      });
    }
    let group = this.noOrderGroup;
    if (group?.items) {
      group.items.forEach(item => {
        if (item.item.itemCdFv === itemArg.item.itemCdFv) {
          orderGroupHadChangedItem.push(group.groupId);
        }
      });
    }

    if (orderGroupHadChangedItem.length <= 0) return;
    for (const groupId of orderGroupHadChangedItem) {
      this.synchronizeOrderNumChangeInUpdateMap(groupId, itemArg);
    }
  }

  handlerChangeOrderBaraNumItem(gitem: OrderGroupItem) {
    let bara = Math.ceil(gitem.item.orderBaraNumForm.value / gitem.item.orderLotFn);
    gitem.item.orderNumForm.setValue(bara);

    if (!gitem.item.getValue("orderDate") || gitem.item.getValue("orderDate").trim() === "") return;
    const key = gitem.item.itemCdFv;
    const originOrderBaraNum = gitem.item.getValue("orderBaraNumFn");

    const isChangeOrderBaraNum = originOrderBaraNum !== gitem.item.orderBaraNumForm.value;
    if (isChangeOrderBaraNum) {
      if (!this.orderBaraUpdateChangeMap.has(key)) {
        this.orderBaraUpdateChangeMap.set(key, gitem.item.itemCdFv);
        return;
      }
    } else {
      if (this.orderBaraUpdateChangeMap.has(key)) {
        this.orderBaraUpdateChangeMap.delete(key);
      }
    }
  }

  handlerChangeStockNumItem(itemArg: OrderGroupItem) {
    let orderGroupHadChangedItem = [];
    for (let i = 0; i < this.orderGroups.length; i++) {
      let group = this.orderGroups[i];
      if (!group.items) continue;
      group.items.forEach(item => {
        if (item.item.itemCdFv === itemArg.item.itemCdFv) {
          orderGroupHadChangedItem.push(group.groupId);
        }
      });
    }
    let group = this.noOrderGroup;
    if (group?.items) {
      group.items.forEach(item => {
        if (item.item.itemCdFv === itemArg.item.itemCdFv) {
          orderGroupHadChangedItem.push(group.groupId);
        }
      });
    }
    if (orderGroupHadChangedItem.length <= 0) return;
    for (const groupId of orderGroupHadChangedItem) {
      this.synchronizeStockNumChangeInUpdateMap(groupId, itemArg);
    }
  }

  synchronizeOrderNumChangeInUpdateMap(groupIdArg: number, item: OrderGroupItem) {
    if (!item || !item.item || !item.item.getValue("orderDate") || item.item.getValue("orderDate").trim() === "") return;
    const key = `${groupIdArg}_${item.item.itemCdFv}`
    const originOrderNum = item.item.getValue("orderNumFn");

    const isChangeOrderNum = originOrderNum !== item.item.getValue("orderNumForm");
    if (isChangeOrderNum) {
      if (this.orderUpdateChangeMap.has(key)) {
        const updateMapItem = this.orderUpdateChangeMap.get(key)
        updateMapItem.isUpdateOrder = true;
        updateMapItem.orderNumFn = item.getValue("orderNumForm")
        return;
      }

      let pdate = item.item.getNextOrderableDate(item.item.orderDate);
      let pdateNext = item.item.getNextOrderableDate(item.item.orderDateNext);
      const newMapItem: ReqUpdateOrderByUserDto = {
        groupId: groupIdArg,
        storeCdFv: this.commonService.loginUser.storeCd,
        itemCdFv: item.item.itemCdFv,
        closingTime: item.item.closingTime,
  
        orderNumFn: item.item.getValue('orderNumForm'),
        orderNumModFn: item.item.orderNumModFn,
        orderNumVersionFn: item.item.orderNumVersionFn,
        orderDate: item.item.orderDate,
        orderDateNext: item.item.orderDateNext,
        deliveryDate: item.item.deliveryDate,
        deliveryDateNext: item.item.deliveryDateNext,

        stockNumFn: item.item.getValue('stockNumForm'),
        stockNumModFn: item.item.stockNumModFn,
        stockNumVersionFn: item.item.stockNumVersionFn,

        rowAction: 0, // 0: noChange; 1: delete; 2: add
        isUpdateOrder: true,
        isUpdateStock: false
      };
      this.orderUpdateChangeMap.set(key, newMapItem);
      return;
    }
    // else
    if (this.orderUpdateChangeMap.has(key)) {
      const updateMapItem = this.orderUpdateChangeMap.get(key)
      updateMapItem.isUpdateOrder = false;
      updateMapItem.orderNumFn = item.getValue("orderNumForm")
      if (updateMapItem.rowAction === 0 && !updateMapItem.isUpdateStock) {
        this.orderUpdateChangeMap.delete(key);
      }
    }
  }

  synchronizeStockNumChangeInUpdateMap(groupIdArg: number, item: OrderGroupItem) {
    if (!item || !item.item) return;
    const key = `${groupIdArg}_${item.item.itemCdFv}`
    const originStockNum = item.item.getValue("stockNumFn");

    const isChangeStockNum = originStockNum !== item.item.getValue("stockNumForm");
    if (isChangeStockNum) {
      if (this.orderUpdateChangeMap.has(key)) {
        const updateMapItem = this.orderUpdateChangeMap.get(key)
        updateMapItem.isUpdateStock = true;
        updateMapItem.stockNumFn = item.getValue("stockNumForm")
        return;
      }
      let pdate = item.item.getNextOrderableDate(item.item.orderDate);
      let pdateNext = item.item.getNextOrderableDate(item.item.orderDateNext);
      const newMapItem: ReqUpdateOrderByUserDto = {
        groupId: groupIdArg,
        storeCdFv: this.commonService.loginUser.storeCd,
        itemCdFv: item.item.itemCdFv,
        closingTime: item.item.closingTime,

        orderNumFn: item.item.getValue('orderNumForm'),
        orderNumModFn: item.item.orderNumModFn,
        orderNumVersionFn: item.item.orderNumVersionFn,
        orderDate: item.item.orderDate,
        orderDateNext: item.item.orderDateNext,
        deliveryDate: item.item.deliveryDate,
        deliveryDateNext: item.item.deliveryDateNext,

        stockNumFn: item.item.getValue('stockNumForm'),
        stockNumModFn: item.item.stockNumModFn,
        stockNumVersionFn: item.item.stockNumVersionFn,

        rowAction: 0, // 0: noChange; 1: delete; 2: add
        isUpdateOrder: false,
        isUpdateStock: true
      };
      this.orderUpdateChangeMap.set(key, newMapItem);
      return;
    }
    // else
    if (this.orderUpdateChangeMap.has(key)) {
      const updateMapItem = this.orderUpdateChangeMap.get(key)
      updateMapItem.isUpdateStock = false;
      updateMapItem.stockNumFn = item.getValue("stockNumForm")
      if (updateMapItem.rowAction === 0 && !updateMapItem.isUpdateOrder) {
        this.orderUpdateChangeMap.delete(key);
      }
    }
  }

  removeNewItemFromNoGroupItem(itemCd: string) {
    if (this.noOrderGroup == undefined) return;
    let item: OrderGroupItem;
    for (let i = 0; i < this.noOrderGroup.itemsBara?.length; i++) {
      if (this.noOrderGroup.itemsBara[i].item.itemCdFv === itemCd) {
        item = this.noOrderGroup.itemsBara[i];
        this.noOrderGroup.itemsBara.splice(i, 1);
        break;
      }
    }
    for (let i = 0; i < this.noOrderGroup.items?.length; i++) {
      if (this.noOrderGroup.items[i].item.itemCdFv === itemCd) {
        this.noOrderGroup.items.splice(i, 1);
        break;
      }
    }
    if (item?.item.caseOrderItems) {
      for (let i = 0; i < item.item.caseOrderItems.length; i++) {
        let itemCd = item.item.caseOrderItems[i].itemCdFv;
        for (let i = 0; i < this.noOrderGroup.items?.length; i++) {
          if (this.noOrderGroup.items[i].item.itemCdFv === itemCd) {
            this.noOrderGroup.items.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  removeItemFromOldGroupItem(groupId: number, itemCd: string) {
    const oldOwnerGroup = this.orderGroups.find(group => group.groupId === groupId)
    let item: OrderGroupItem;
    for (let i = 0; i < oldOwnerGroup.itemsBara.length; i++) {
      if (oldOwnerGroup.itemsBara[i].item.itemCdFv === itemCd) {
        item = oldOwnerGroup.itemsBara[i];
        oldOwnerGroup.itemsBara.splice(i, 1);
        // this.matTable.renderRows();
        break;
      }
    }
    for (let i = 0; i < oldOwnerGroup.items.length; i++) {
      if (oldOwnerGroup.items[i].item.itemCdFv === itemCd) {
        oldOwnerGroup.items.splice(i, 1);
        // this.matTable.renderRows();
        break;
      }
    }
    if (item.item.caseOrderItems) {
      for (let i = 0; i < item.item.caseOrderItems.length; i++) {
        let itemCd = item.item.caseOrderItems[i].itemCdFv;
        for (let i = 0; i < oldOwnerGroup.items.length; i++) {
          if (oldOwnerGroup.items[i].item.itemCdFv === itemCd) {
            oldOwnerGroup.items.splice(i, 1);
            // this.matTable.renderRows();
            break;
          }
        }
      }
    }
    this.matTable.renderRows();
  }

  initHandleChangeOrderAndStockNum() {
    let itemCdMap = {};
    this.unSubscriptionOrderAndStockForm();
    this.orderGroups.forEach(group => {
      group.items?.forEach(item => {
        this.orderAndStockFormSubscriptions.push(item.item.orderNumForm.valueChanges.subscribe(data => this.handlerChangeOrderNumItem(item)));
        if (this.commonService.config.orderEditInBara) {
          this.orderAndStockFormSubscriptions.push(item.item.orderBaraNumForm.valueChanges.subscribe(data => this.handlerChangeOrderBaraNumItem(item)));
        }
        if (!itemCdMap[item.item.itemCdFv]) {
          itemCdMap[item.item.itemCdFv] = true;
          let form: FormControl;
          if (this.commonService.config.orderEditInBara) {
            form = item.item.orderBaraNumForm;
          } else {
            form = item.item.orderNumForm;
          }
          this.orderAndStockFormSubscriptions.push(form.valueChanges
            .pipe(
              startWith(form.value),
              pairwise()
            )
            .subscribe(([prevVal, newVal]) => {
              this.commonService.valueCheckOrderNum(form, prevVal, newVal);
            })
          );
        }
        this.orderAndStockFormSubscriptions.push(item.item.stockNumForm.valueChanges.subscribe(data => this.handlerChangeStockNumItem(item)));
      });
    });

    if (this.noOrderGroup) {
      this.noOrderGroup.items.forEach(item => {
        this.orderAndStockFormSubscriptions.push(item.item.orderNumForm.valueChanges.subscribe(data => this.handlerChangeOrderNumItem(item)));
        if (this.commonService.config.orderEditInBara) {
          this.orderAndStockFormSubscriptions.push(item.item.orderBaraNumForm.valueChanges.subscribe(data => this.handlerChangeOrderBaraNumItem(item)));
        }
        this.orderAndStockFormSubscriptions.push(item.item.stockNumForm.valueChanges.subscribe(data => this.handlerChangeStockNumItem(item)));
      });
    }
  }

  unSubscriptionOrderAndStockForm() {
    if (!this.orderAndStockFormSubscriptions || this.orderAndStockFormSubscriptions.length <= 0) return;
    this.orderAndStockFormSubscriptions.forEach(sub => {
      sub.unsubscribe();
    })
    this.orderAndStockFormSubscriptions = []
  }

  clearSearchItem() {
    this.addItem = undefined;
    this.isAddDisable = true;
    this.searchedItem = undefined;
    this.formItemName.setValue("");
    this.formItemStandard.setValue("");
  }

  calcServerTime() {
    let server = new Date();
    let device = new Date();
    server.setTime(device.getTime() + this.timeLag);

    return server;
  }

  addItemClosingTime(itemClosingTime: number) {
    if (itemClosingTime == 0) return;

    let h = Math.floor(itemClosingTime / 100);
    let m = itemClosingTime % 100;
    let closingTime = new Date();
    closingTime.setHours(h, m, 0);

    if (!this.closingTimeList.find((item) => {
      item.getTime() == closingTime.getTime();
    })) {
      this.closingTimeList.push(closingTime);
      this.closingTimeList.sort((a, b) => {
        if (a.getTime() < b.getTime()) return -1;
        if (a.getTime() == b.getTime()) return 0;
        return 1;
      });
    }
  }

  checkClosingTimeAll() {
    let serverTime = this.calcServerTime();
    this.groupItems.forEach((item) => {item.checkClosingTime(this.propClosingTime, serverTime)});
    this.noGroupItems.forEach((item) => {item.checkClosingTime(this.propClosingTime, serverTime)});
  }

  nextClosingTime() {
    let now = this.calcServerTime();
    let next: Date;
    for (let ct of this.closingTimeList) {
      if (ct.getTime() <= now.getTime()) continue;
      next = ct;
    }
    if (!next) {
      this.clearTimer();
      return;
    }
    this.startTimer(next.getTime() - now.getTime());
  }

  startTimer(timeout: number) {
    this.clearTimer();
    this.timerId = setTimeout(
      () => {
        this.checkClosingTimeAll();
        this.nextClosingTime();
      }, timeout);
  }

  clearTimer() {
    if (this.timerId < 0) return;
    clearTimeout(this.timerId);
    this.timerId = -1;
  }

  groupButtonOpenClose(mode: boolean) {
    if (mode === this.isGroupButtonOpen) return;
    let id = "group-button-container";
    let height = 60;
    if (mode) {
      let remHeight = this.commonService.getHeightBelow(id);
      let margin = 10;
      height = remHeight - margin;
    } else {
      height = 66;
    }
    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";

    this.isGroupButtonOpen = mode;
    this.setTableHeight();
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          this.formItemCode.setValue(data);
          this.searchJan();
        }
      }
    )
  }

  bgColor(item: OrderGroupItem, key: string) {
    if (key === "orderResult1") {
      if (item.item.orderResult1IsToday == true) return 1;
      return 0;
    }
    if (key === "orderBaraNumFn") {
      if (!this.commonService.config.orderEditInBara) return 0;
      if (item.item.orderBaraNumForm.value === "") return 0;
      if (parseInt(item.item.orderBaraNumForm.value) === item.item.getValue("orderBaraNumFn")) return 0;
      return 2;
    }
    return 0;
  }

  openBgcolorDialog() {
    let subsc = this.commonService.dialog.open(OrderEditBgcolorDialogComponent, {
      disableClose: false,
      data: "orderByUser"
    }).afterClosed().subscribe(
      () => {
        subsc.unsubscribe();
      }
    );
  }

  /*
  stockEditable(item: OrderByUser) {
    if (!this.isStockEditable) return false;
    let flag = this.commonService.config.orderStopFlag.find((flag) => flag.value === item.getValue("orderStopFlagFnValue"));
    if (!flag) return false;
    if (flag.caseItem && !this.commonService.config.stockEditCaseItem) return false;
    return true;
  }
  */

  canDeactivate() {
    if (this.orderUpdateChangeMap?.size <= 0 && this.orderBaraUpdateChangeMap?.size <= 0) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
