import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { NotificationUpdateDto, ReqGetNotificationEditList, ReqNotificationUpdate, RspGetNotificationEditList, RspNotificationUpdate } from 'src/app/webservice/notification';
import { NotificationRec } from '../notification-rec';
import { ActivatedRoute } from '@angular/router';
import { ReqGetRole } from 'src/app/request/req-get-role';
import { RspGetRole } from 'src/app/response/rsp-get-role';

@Component({
  selector: 'app-notification-edit',
  templateUrl: './notification-edit.component.html',
  styleUrls: ['./notification-edit.component.css']
})
export class NotificationEditComponent implements OnInit, OnDestroy {

  public brcMode: boolean = false;
  public tabSelection: number = 0;
  public notificationList: NotificationRec[] = [];
  public notificationShowList: NotificationRec[] = [];
  public recordCount: number = 0;
  public columnIds: string[];
  public columnIdsBrc: string[] = ["edit-btn", "del-btn", "notificationDate", "expirationDate", "notificationType", "notification", "notifierId", "notifier"];
  public columnIdsStd: string[] = ["edit-btn", "del-btn", "notificationDate", "expirationDate", "notification", "notifierId"];
  public columnDefs: TableColumnDef[] = [
    {columnId: "notificationDate", header: "通知日", width: 80, align: "center"},
    {columnId: "expirationDate", header: "終了日", width: 80, align: "center"},
    {columnId: "notificationType", header: "タイプ", width: 50, align: "center"},
    {columnId: "notification", header: "通知内容", width: 500, align: "left"},
    {columnId: "notifierId", header: "通知者ID", width: 100, align: "left"},
    {columnId: "notifier", header: "通知元", width: 100, align: "left"}
  ];
  public selectedNotification: NotificationRec;
  public roles: {roleId: number, roleName: string}[];
  public notificationDateMin: Date;
  
  // private dirtyFlag: boolean = false;
  private subscStore: Subscription;

  @ViewChild("notificationList", { read: MatTable, static: false }) matTable: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private route: ActivatedRoute
  ) {
    this.notificationDateMin = this.commonService.getDate(this.commonService.formatDate(new Date()));
  }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    let len = this.route.snapshot.url.length;
    let path: string = "";
    if (len > 0) {
      path = this.route.snapshot.url[len - 1].path;
    }
    if (path === "notificationEditBrc") {
      this.brcMode = true;
      this.columnIds = this.columnIdsBrc;
    } else {
      this.brcMode = false;
      this.columnIds = this.columnIdsStd;
    }

    this.getRoles().then(
      () => {
        this.getNotificationList();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.selectedNotification) this.selectedNotification.cleanupEdit();
    this.subscStore?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

    setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    if (this.tabSelection == 0) {
      this.setMaxHeight("notification-list", true, 24);
    } else {
      if (this.selectedNotification) this.setMaxHeight("store-select", false, 24);
      if (this.selectedNotification) this.setMaxHeight("role-select", false, 24);
      if (this.selectedNotification) this.setMaxHeight("user-select", false, 24);
    }
  }

  getRoles(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let request: ReqGetRole = {
        access: this.commonService.loginUser,
      };
  
      let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
      let subsc = this.httpBasic.generalRequest("Role", request).subscribe(
        (response: RspGetRole) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.receiveGetRoles(response);
          resolve();
        },
        (error) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.httpBasic.handleError(error);
          reject();
        }
      );
    });
  }

  receiveGetRoles(response: RspGetRole) {
    if (this.httpBasic.handleAppError(response)) return;

    this.roles = [];
    response.roles.forEach((role) => {
      if (!this.roles.find((item) => item.roleId === role.roleId)) {
        this.roles.push({roleId: role.roleId, roleName: role.roleName});
      }
    });
  }

  getNotificationList() {
    let request: ReqGetNotificationEditList = {
      access: this.commonService.loginUser
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetNotificationEditList", request).subscribe(
      (response: RspGetNotificationEditList) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveNotificationList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveNotificationList(response: RspGetNotificationEditList) {
    if (this.httpBasic.handleAppError(response)) return;

    let current: NotificationRec = undefined;
    this.notificationList = [];
    response.rows.forEach((row) => {
      let storeCd = row.storeCd;
      let storeName = row.storeName;
      let notificationClass = row.notificationClass;
      if (storeCd === "*") storeName = "全店";
      if (current == undefined || current.notificationClass !== notificationClass) {
        current = new NotificationRec(this.commonService, this.httpBasic);
        current.setToTypeChangeListener(this.toTypeChangeListener.bind(this));
        current.setRoles(this.roles);
        current.notificationList = [];
        current.notificationToType = row.notificationToType;
        current.notificationList.push({
          notificationId: row.notificationId,
          storeCd: storeCd,
          storeName: storeName,
          roleId: row.roleId,
          roleName: row.roleName,
          userId: row.userId,
          userName: row.userName
        });
        current.notificationClass = notificationClass;
        current.notificationType = row.notificationType;
        current.notification = row.notification;
        current.notifierId = row.notifierId;
        current.notifierStoreCd = row.notifierStoreCd;
        current.notifierName = row.notifierName;
        current.notifierRoleId = row.notifierRoleId;
        current.notifier = row.notifier;
        current.notificationDate = row.notificationDate;
        current.expirationDate = row.expirationDate;
        this.notificationList.push(current);
      } else {
        current.notificationList.push({
          notificationId: row.notificationId,
          storeCd: storeCd,
          storeName: storeName,
          roleId: row.roleId,
          roleName: row.roleName,
          userId: row.userId,
          userName: row.userName
        });
      }
    });
    this.recordCount = this.notificationList.length;
    this.matPagenator.pageIndex = 0;
    this.pageChanged(null);
    this.setTableHeight();
  }

  setMaxHeight(id: string, paginator: boolean, btnBox: number) {
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = paginator ? 56 : 0;
    let margin = 10 + 5;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  tabChanged(event: number) {
    this.setTableHeight();
  }

  pageChanged(event: PageEvent) {
    let start = this.matPagenator.pageIndex * this.matPagenator.pageSize;
    let end = start + this.matPagenator.pageSize;
    if (end > this.recordCount) end = this.recordCount;
    this.notificationShowList = this.notificationList.slice(start, end);

    let id = "notification-list";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  editNotification(rec: NotificationRec) {
    if (this.selectedNotification) this.selectedNotification.cleanupEdit();
    rec.prepareEdit();
    this.selectedNotification = rec;

    setTimeout(() => { this.tabSelection = 1; });
  }

  enableDisableStoreSelect(mode: boolean) {
    this.selectedNotification.edit.stores.forEach((item) => {
      if (mode) {
        item.storeSelect.enable({emitEvent: false});
      } else {
        item.storeSelect.disable({emitEvent: false});
      }
    });
  }

  deleteNotification(rec: NotificationRec) {
    let msg = "<span >次の通知を削除しますか？";
    msg += "<br><br>";
    msg += "<table>"
    msg += "<tr><td>通知日</td><td>" + rec.notificationDate + "</td></tr>";
    msg += "<tr><td>終了日</td><td>" + rec.expirationDate + "</td></tr>";
    msg += "<tr><td>タイプ</td><td>" + rec.notificationType + "</td></tr>";
    if (rec.notification.length > 40) {
      msg += "<tr><td>通知内容</td><td>" + rec.notification.substring(0, 40) + "・・・</td></tr>";
    } else {
      msg += "<tr><td>通知内容</td><td>" + rec.notification + "</td></tr>";
    }
    msg += "<tr><td>通知者ID</td><td>" + rec.notifierId + "</td></tr>";
    msg += "<tr><td>通知元</td><td>" + rec.notifier + "</td></tr>";
    msg += "</table>"
    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      data => {
        subsc.unsubscribe();
        if (data === true) {
          this.deleteNotificationBody(rec);
        }
      }
    );
    return;
  }

  deleteNotificationBody(rec: NotificationRec) {
    let request: ReqNotificationUpdate = {
      access: this.commonService.loginUser,
      deleteList: [],
      updateList: []
    };
    rec.notificationList.forEach((item) => {request.deleteList.push(item.notificationId);});

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("NotificationUpdate", request).subscribe(
      (response: RspNotificationUpdate) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveDeleteNotification(response, rec);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDeleteNotification(response: RspNotificationUpdate, rec: NotificationRec) {
    if (this.httpBasic.handleAppError(response)) return;

    let index = this.notificationList.findIndex((notification) => notification == rec);
    if (index >= 0) {
      this.notificationList.splice(index, 1);
      this.pageChanged(null);
    }
  }

  newNotification() {
    let notification = new NotificationRec(this.commonService, this.httpBasic);
    notification.setToTypeChangeListener(this.toTypeChangeListener.bind(this));
    notification.setRoles(this.roles);
    notification.notificationToType = 1;
    notification.notificationClass = "EDIT-" + (new Date()).getTime();
    notification.notificationType = "text";
    notification.notification = "";
    notification.notifierId = this.commonService.loginUser.userId;
    notification.notifierName = this.commonService.loginUser.userName;
    notification.notifierStoreCd = this.commonService.loginUser.storeCd;
    notification.notifierRoleId = this.commonService.loginUser.roleId;
    if (this.brcMode) {
      notification.notifier = "システム";
    } else {
      notification.notifier = "";
    }
    let d = new Date();
    d.setDate(d.getDate() + 1);
    notification.notificationDate = this.commonService.formatDate(d);
    d.setDate(d.getDate() + 1);
    notification.expirationDate = this.commonService.formatDate(d);
    this.notificationList.push(notification);
    this.recordCount = this.notificationList.length;
    this.pageChanged(null);

    this.editNotification(notification);
    notification.edit.isNew = true;
    notification.edit.isDirtyStore = true;
    notification.edit.isDirtyRole = true;
    notification.edit.isDirtyUser = true;
  }

  isDirty() {
    if (!this.selectedNotification) return false;
    if (this.selectedNotification.isDirty()) return true;
    return false;
  }

  rowColor(rec: NotificationRec) {
    if (!this.selectedNotification) return 0;
    if (rec !== this.selectedNotification) return 0;
    if (this.selectedNotification.isDirty()) return 2;
    return 1;
  }

  storeSelectAll() {
    this.selectedNotification.edit.stores.forEach((store) => {store.storeSelect.setValue(true);});
  }

  storeUnselectAll() {
    this.selectedNotification.edit.stores.forEach((store) => {store.storeSelect.setValue(false);});
  }

  saveDisable() {
    if (!this.selectedNotification) return true;
    return this.selectedNotification.saveDisable();
  }

  cancelDisable() {
    if (!this.selectedNotification) return true;
    return this.selectedNotification.cancelDisable();
  }

  saveEdit() {
    let list = this.selectedNotification.listForSave();

    let request: ReqNotificationUpdate = {
      access: this.commonService.loginUser,
      deleteList: list.deleteList,
      updateList: list.updateList
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("NotificationUpdate", request).subscribe(
      (response: RspNotificationUpdate) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveSaveEdit(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSaveEdit(response: RspNotificationUpdate) {
    if (this.httpBasic.handleAppError(response)) return;

    this.selectedNotification.saveEdit(response.updateResultList);
  }

  cancelEdit() {
    if (this.selectedNotification.edit.isNew) {
      this.selectedNotification.cleanupEdit();
      let index = -1;
      for (let i = this.recordCount - 1; i >= 0; i--) {
        if (this.notificationList[i] == this.selectedNotification) {
          this.notificationList.splice(i, 1);
          this.recordCount = this.notificationList.length;
          this.tabSelection = 0;
          this.selectedNotification = undefined;
          setTimeout(() => { this.pageChanged(null); });
          return;
        }
      }
      return;
    }
    this.selectedNotification.cancelEdit();
    this.editNotification(this.selectedNotification);
  }

  toTypeChangeListener() {
    this.setTableHeight();
  }

  canDeactivate() {
    if (!this.isDirty()) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
