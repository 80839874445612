import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import * as pbi from 'powerbi-client';
import { Subscription } from 'rxjs';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspPbiToken } from 'src/app/response/rsp-pbi-token';
import { ReqPbiToken } from 'src/app/request/req-pbi-token';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-pbi-analysis',
  templateUrl: './pbi-analysis.component.html',
  styleUrls: ['./pbi-analysis.component.css']
})
export class PbiAnalysisComponent implements OnInit, OnDestroy {

  private containerElement: HTMLElement;
  private containerWidth: number;
  private containerHeight: number;
  private powerbi: pbi.service.Service;
  private report: pbi.Embed;
  private subscriptionQuery: Subscription;
  private subscriptionRoute: Subscription;
  private isBootstrap: boolean = false;

  @ViewChild('reportContainer', { static: true }) reportContainer: ElementRef;
 
  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    // this.commonService.pageTitle = "特売分析";
    this.commonService.pageTitle = this.commonService.pageMenuName;

    // window.addEventListener("resize", ()=>{this.resized();});
    setTimeout(()=>{this.resized();}, 0);

    this.subscriptionRoute = this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.pbiInit(params.get("name"));
      }
    );
  }

  ngOnDestroy() {
    if (this.subscriptionRoute) {
      this.subscriptionRoute.unsubscribe();
    }
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
    }
    if (this.isBootstrap) {
      this.powerbi.reset(this.reportContainer.nativeElement);
    }
  }

  pbiInit(reportName: string) {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    let request: ReqPbiToken = {
      access: this.commonService.loginUser,
      analysisName: reportName
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "初期化中・・・");
    this.subscriptionQuery = this.httpBasic.pbiToken(request).subscribe(
      (response) => {this.receiveToken(response)},
      (error) => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveToken(response: RspPbiToken) {
    this.commonService.closeSpinner();
    this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    if (this.httpBasic.handleAppError(response)) return;

    this.showReport(response.embedToken, response.pbiReportId, response.pbiType, response.pbiEmbedUrl);
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.resized();
  }

  resized() {
    let repWidth = 825;
    let repHeight = 500;
    let innerContainerElement = document.getElementById("inner-container");
    let containerElement = this.reportContainer.nativeElement;
    // let containerElement = document.getElementById("report-frame");
    let containerRect = containerElement.getBoundingClientRect();
    let height = window.innerHeight - containerRect.top - 40;
    let width = innerContainerElement.getBoundingClientRect().width - 10;
    if (width / height > repWidth / repHeight) {
      width = height * repWidth / repHeight;
      width = Math.round(width);
    } else {
      height = width * repHeight / repWidth;
      height = Math.round(height);
    }
    containerElement.style["height"] = "" + height + "px";
    containerElement.style["width"] = "" + width + "px";
  }

  showReport(token: string, reporttId: string, pbiType: string, embedUrl: string) {
    this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    if (this.isBootstrap) {
      this.powerbi.reset(this.reportContainer.nativeElement);
    }
    this.powerbi.bootstrap(this.reportContainer.nativeElement, { type: pbiType });
    this.isBootstrap = true;

    let settings: pbi.IEmbedSettings = {
      filterPaneEnabled: true,
      navContentPaneEnabled: true,
    };
    let config: pbi.IEmbedConfiguration = {
      type: pbiType,
      tokenType: pbi.models.TokenType.Embed,
      accessToken: token,
      embedUrl: embedUrl,
      id: reporttId,
      filters: [],
      settings: settings
    };
    let reportContainer = this.reportContainer.nativeElement;
    this.report = this.powerbi.embed(reportContainer, config);
    /*
    this.report.off("loaded");
    this.report.on("loaded", () => {
      console.log("Loaded");
      // this.setTokenExpirationListener(Token.expiration, 2);
    });
    this.report.on("error", () => {
      console.log("Error");
    });
    */
  }
}
