import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpiryDashboardDto } from 'src/app/webservice/item-expiry';
import { ItemExpiryInitialComponent } from '../item-expiry-initial/item-expiry-initial.component';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-item-expiry-initial-dialog',
  templateUrl: './item-expiry-initial-dialog.component.html',
  styleUrls: ['./item-expiry-initial-dialog.component.css']
})
export class ItemExpiryInitialDialogComponent implements OnInit {

  @ViewChild(ItemExpiryInitialComponent, {static: true}) itemExpiryInitialComponent: ItemExpiryInitialComponent;

  constructor(
    public dialogRef: MatDialogRef<ItemExpiryInitialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExpiryDashboardDto,
    public commonService: CommonService) {
    }

  ngOnInit() {
    setTimeout(() => {this.setHeightBody();}, 500);
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setHeight();
  }

  setHeight() {
    setTimeout(() => {this.setHeightBody();}, 0);
  }

  setHeightBody() {
    let id = "app-item-expiry-initial";
    let remHeight = this.commonService.getHeightBelow(id);
    let margin = 10;
    let btnBox = 24;
    let height = remHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.height = "" + height + "px";
  }

  isDirty() {
    return this.itemExpiryInitialComponent?.isDirty();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
