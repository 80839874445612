
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { UserDto } from 'src/app/response/rsp-get-users';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqUpdateTaskResultRec, RspUpdateTaskResultRec, TaskImageRecDto } from 'src/app/webservice/task';
import { PhotoItem, SubTask, SubTaskResult } from '../0_def/taskDefs';
import { TaskStoreRec } from '../0_def/taskStoreRec';
import { TaskService } from '../1_service/taskService';
import { TaskPhotoDialogComponent } from '../photo-dialog/task-photo-dialog.component';

@Component({
  selector: 'app-store-edit',
  templateUrl: './store-edit.component.html',
  styleUrls: ['./store-edit.component.css']
})
export class StoreEditComponent implements  OnInit, OnDestroy, OnChanges  {

  public editTaskStore: TaskStoreRec;
  public minDate: Date;
  public dateBegin: Date;
  public dateEnd: Date;
  public formFile: FormControl = new FormControl();
  public filename: string = "";
  public isFileReadError: boolean = false;
  public selectedSubTask: SubTask;
  public selectedSubTaskResult: SubTaskResult;
  public selectedPhoto: PhotoItem;
  public showResponsileSelector: boolean = false;
  public showResponsileSelectorSub: boolean = false;
  public staffList: string[] = [...this.taskService.staffListAll];
  public userList: UserDto[] = [];

  public subTasks: SubTask[];

  private subscResponse: Subscription[] = [];
  private subscriptionNotifier: Subscription;
  @Input("taskStoreRec") taskStoreRec: TaskStoreRec;
  @Input() notifier: Subject<any>;
  @Output() viewTaskOtherStoreChanged: EventEmitter<any> = new EventEmitter();
  @Output() viewTaskOtherStoreCommentChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild("photoTable", {read: MatTable, static: false}) photoTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    private httpBasic:    HttpBasicService,
    public taskService:   TaskService,
    private cdr: ChangeDetectorRef
    ) {
      this.minDate = new Date();
    }

  ngOnInit(): void {
    this.subscriptionNotifier = this.notifier.subscribe(action => {
      this.selectedSubTask = undefined;
      this.selectedSubTaskResult = undefined;
    })
  }

  ngOnDestroy(): void {
    this.taskService.endEditStore();
    this.subscResponse.forEach((subsc) => subsc.unsubscribe());
    this.subscriptionNotifier.unsubscribe();
  }

  ngOnChanges(): void {
    if (!this.taskStoreRec) {
      this.editTaskStore = undefined;
      return;
    }
    this.editTaskStore = this.taskStoreRec;
    this.selectedSubTask = undefined;
    this.selectedSubTaskResult = undefined;
    this.selectedPhoto = undefined;

    this.subTasks = [];
    this.editTaskStore.subTasks.forEach((subtask) => {
      if (!subtask.subTaskTargetStores || subtask.subTaskTargetStores.find((store) => store.storeCd === this.editTaskStore.storeCd)) {
        this.subTasks.push(subtask);
      }
    });

    let isObsolete = false;
    // check deleted from subTaskTarget
    for (let i = 0; i < this.subTasks.length; i++) {
      let subtask = this.subTasks[i];
      if (!this.editTaskStore.subTaskResults.find((rec) => rec.subTaskId === subtask.subTaskId)) {
        isObsolete = true;
        break;
      }
    }
    if (!isObsolete) {
      for (let i = 0; i < this.editTaskStore.subTaskResults.length; i++) {
        let result = this.editTaskStore.subTaskResults[i];
        if (!this.subTasks.find((rec) => rec.subTaskId === result.subTaskId)) {
          isObsolete = true;
          break;
        }
      }
    }
    if (isObsolete) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "タスクが再作成されました。タスクの検索からやり直してください。");
      setTimeout(() => {
        this.taskService.editTaskStore.isObsolete = true;
        this.taskService.editTaskStore = undefined;
      }, 0);
      return;
    }

    this.userList = this.editTaskStore.userList;
    this.refreshSubscription();
  }

  filterResponsible(value: string) {
    if (value === "") {
      this.staffList = [...this.taskService.staffListAll];
    } else {
      this.staffList = this.taskService.staffListAll.filter((item) => item.indexOf(value) >= 0);
    }
  }

  styleForResponsileSelector() {
    if (!this.showResponsileSelector) {
      return {"display": "none"};
    }
    return {};
  }

  setResponsible(value: string) {
    this.editTaskStore.setResponsible(value);
    // document.getElementById("input-responsible")?.focus();
    this.showResponsileSelector = false;
  }

  setResponsibleSub(value: string) {
    this.selectedSubTaskResult.edits.responsible.setValue(value);
    this.editTaskStore.markAsDirty();
    // document.getElementById("input-responsible-sub")?.focus();
    this.showResponsileSelectorSub = false;
  }

  focusResponsile() {
    if (!this.staffList) return;
    // if (this.staffList.length == 0) return;
    this.filterResponsible(this.editTaskStore.edits.responsible.value);
    this.showResponsileSelector = true;
    /*
    let elem = document.getElementById("input-responsible");
    let rect = elem.getBoundingClientRect();
    let posX = rect.left + window.pageXOffset;
    let posY = rect.top + window.pageYOffset + rect.height;
    let width = rect.width;

    setTimeout(() => {
      let elemSelector = document.getElementById("responsile-selector-box");
      if (elemSelector) {
        elemSelector.style.left = "" + posX + "px";
        // elemSelector.style.top = "" + posY + "px";
        elemSelector.style.top = "" + posY;
        elemSelector.style.width = "" + width + "px";
      }
    }, 0);
    */
  }

  focusResponsileSub() {
    if (!this.staffList) return;
    // if (this.staffList.length == 0) return;
    this.filterResponsible(this.selectedSubTaskResult.edits.responsible.value);
    this.showResponsileSelectorSub = true;
    /*
    let elem = document.getElementById("input-responsible-sub");
    let rect = elem.getBoundingClientRect();
    let posX = rect.left + window.pageXOffset;
    let posY = rect.top + window.pageYOffset + rect.height;
    let width = rect.width;

    setTimeout(() => {
      let elemSelector = document.getElementById("responsile-sub-selector-box");
      if (elemSelector) {
        elemSelector.style.left = "" + posX + "px";
        // elemSelector.style.top = "" + posY + "px";
        elemSelector.style.top = "" + posY;
        elemSelector.style.width = "" + width + "px";
      }
    }, 0);
    */
  }

  blurResponsile() {
    setTimeout(() => {
      this.showResponsileSelector = false;
      if (this.commonService.config.task.responsibleCopyToSub && this.editTaskStore.responsible === "") {
        this.editTaskStore.subTaskResults.forEach((sub) => {
          if (sub.responsible === "" && sub.edits.responsible.value === "") {
            sub.edits.responsible.setValue(this.editTaskStore.edits.responsible.value);
          }
        });
      }
    }, 300);
  }

  blurResponsileSub() {
    setTimeout(() => {
      this.showResponsileSelectorSub = false;
    }, 300);
  }

  openReference() {
    window.open(this.editTaskStore.referenceUrl, "PippiTask");
  }

  openSubTaskReference(url: string) {
    window.open(url, "PippiTask");
  }

  selectSubTask(subTask: SubTask) {
    if (this.editTaskStore.subTaskResults.length < 1) {
      return
    }

    this.selectedSubTask = subTask;
    this.selectedSubTaskResult = this.editTaskStore.subTaskResults.find((item) => item.subTaskId == subTask.subTaskId);
    this.editTaskStore.checkStartAndComplete();
    this.cdr.detectChanges();
    // if (!this.editTaskStore.isStarted) {
    //   this.selectedSubTaskResult.edits.isComplete.disable();
    // }
    if(this.taskService.isViewTaskStore){
      this.selectedSubTaskResult.edits.isWorking.disable({emitEvent:false});
      this.selectedSubTaskResult.edits.isComplete.disable({emitEvent:false});
      this.selectedSubTaskResult.edits.responsible.disable({emitEvent:false});
      this.selectedSubTaskResult.edits.responsibleUser.disable({emitEvent:false});
      this.selectedSubTaskResult.edits.comment.disable({emitEvent:false});
    } else if (!this.selectedSubTaskResult.isComplete){
      this.selectedSubTaskResult.edits.isWorking.enable({emitEvent:false});
      this.selectedSubTaskResult.edits.isComplete.enable({emitEvent:false});
      this.selectedSubTaskResult.edits.responsible.enable({emitEvent:false});
      this.selectedSubTaskResult.edits.responsibleUser.enable({emitEvent:false});
      this.selectedSubTaskResult.edits.comment.enable({emitEvent:false});
    }
    this.selectedPhoto = undefined;
    this.viewTaskOtherStoreChanged.emit(undefined);
  }

  selectPhoto(photo: PhotoItem) {
    this.selectedPhoto = photo;

    if (!photo.dataUrl || photo.dataUrl === "") {
      // Get Image Data from a server.
      this.taskService.getImageData(photo, (image)=>{
        photo.dataUrl = image.dataUrl;
        photo.isDeleted = image.isDeleted;
      });
    }
  }

  newPhoto() {
    let newTitle: string = "" + (this.selectedSubTaskResult.edits.photos.length + 1);
    while (this.selectedSubTaskResult.edits.photos.find(item => (
      newTitle === item?.title ||
      (!isNaN(parseInt(item?.title)) && parseInt(item?.title) > parseInt(newTitle))
    ))) {
      newTitle = "" + (parseInt(newTitle) + 1);
    }

    let newPhoto : PhotoItem = {
      taskId:             this.editTaskStore.taskId,
      storeCd:            this.editTaskStore.storeCd,
      subTaskId:          this.selectedSubTask.subTaskId,
      photoId:            -1,
      title:              newTitle,
      isNew:              true,
      isUpdated:          false,
      isDeleted:          false
    };

    let subsc = this.commonService.dialog.open(TaskPhotoDialogComponent, {
      disableClose: true,
      data: newPhoto
    }).afterClosed().subscribe(
      (data: PhotoItem) => {
        subsc.unsubscribe();
        if (!data) return;
        /*
        data.photoId = this.selectedSubTaskResult.edits.nextPhotoId;
        this.selectedSubTaskResult.edits.nextPhotoId++;
        this.selectedSubTaskResult.edits.photos.push(data);
        */
        this.editTaskStore.addPhoto(this.selectedSubTaskResult, data);
        this.photoTable?.renderRows();
        this.editTaskStore.markAsDirty();
        this.selectedPhoto = data;
        this.editTaskStore.checkStartAndComplete();

      }
    );
  }

  editPhoto() {
    let subsc = this.commonService.dialog.open(TaskPhotoDialogComponent, {
      disableClose: true,
      data: this.selectedPhoto
    }).afterClosed().subscribe(
      (data) => {
        subsc.unsubscribe();
        if (!data) return;
        this.photoTable?.renderRows();
        this.editTaskStore.markAsDirty();
      }
    );
  }

  deletePhoto() {
    if (this.selectedPhoto.isDeleted) {
      this.selectedPhoto.isDeleted = false;
    } else {
      this.selectedPhoto.isDeleted = true;
    }

    this.editTaskStore.checkStartAndComplete();

    this.editTaskStore.markAsDirty();
  }

  async saveTaskStore() {

    let request: ReqUpdateTaskResultRec = await this.editTaskStore.commitEdit();

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.updateTaskResultRec(request).subscribe(
      (response: RspUpdateTaskResultRec) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveSaveTaskStore(response, request.imagesNew);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSaveTaskStore(response: RspUpdateTaskResultRec, imagesNew: TaskImageRecDto[]) {
    if (this.httpBasic.handleAppError(response)) {
      if (response.normalError.length > 0 && response.normalError[0].errId === "ME000000") {
        this.editTaskStore.isObsolete = true;
        this.taskService.editTaskStore = undefined;
      }
      return;
    }

    this.editTaskStore.resultStatus = response.resultStatus;
    // Update imageIds of new images
    for (let i = 0; i < imagesNew.length; i++) {
      imagesNew[i].imageId = response.imagesNew[i].imageId;
    }
    this.taskService.subTaskOtherStoresRecs = undefined;
    // this.selectedSubTask = undefined;
    // this.selectedSubTaskResult = undefined;
    if (this.selectedPhoto?.isDeleted) this.selectedPhoto = undefined;
    this.editTaskStore.prepareEdit();
    // Update staff list
    this.taskService.staffListAll = [];
    
    response.staffs.forEach((item) => this.taskService.staffListAll.push(item.staffName));

    this.refreshSubscription();
  }

  cancelEdit() {
    this.editTaskStore.clearEdit();
    this.refreshSubscription();
    this.selectedSubTask = undefined;
    this.selectedPhoto = undefined;
    this.selectedSubTaskResult?.edits.photos.map(photo => photo.isDeleted = false);
  }

  refreshSubscription() {
    this.subscResponse.forEach((subsc) => subsc.unsubscribe());
    this.subscResponse = [];
    if (this.commonService.config.task.responsibleIsLoginUser) {
      this.subscResponse.push(this.editTaskStore.edits.responsibleUser.valueChanges.subscribe(
        (value) => {
          this.editTaskStore.edits.responsibleName = this.taskService.getResponsibleName(this.editTaskStore.userList, value);
          if (this.commonService.config.task.responsibleCopyToSub && this.editTaskStore.responsibleUserId === "") {
            this.editTaskStore.subTaskResults.forEach((sub) => {
              if (sub.responsibleUserId === "" && sub.edits.responsibleUser.value === "") {
                sub.edits.responsibleUser.setValue(this.editTaskStore.edits.responsibleUser.value, {emitEvent: false});
                sub.edits.responsibleName = this.editTaskStore.edits.responsibleName;
              }
            });
          }
        }
      ));
    } else {
      this.subscResponse.push(this.editTaskStore.edits.responsible.valueChanges.subscribe(
        (value) => {
          this.filterResponsible(value);
        }
      ));
    }
    this.editTaskStore.subTaskResults.forEach((result) => {
      if (this.commonService.config.task.responsibleIsLoginUser) {
        this.subscResponse.push(result.edits.responsibleUser.valueChanges.subscribe(
          (value) => {
            result.edits.responsibleName = this.taskService.getResponsibleName(this.editTaskStore.userList, value);
          }
        ));
      } else {
        this.subscResponse.push(result.edits.responsible.valueChanges.subscribe(
          (value) => { this.filterResponsible(value); }
        ));
      }
    });
  }
  onViewTaskOtherStore() {
    this.viewTaskOtherStoreChanged.emit(this.selectedSubTask);
  }

  onViewTaskOtherStoreComment() {
    this.viewTaskOtherStoreCommentChanged.emit(this.editTaskStore);
  }

  previewFileSubTask(subTask: SubTask){
    if(!subTask.subTaskAttachment){  
      const request = {
        taskId: this.editTaskStore.taskId,
        storeCd: null,
        subTaskId: subTask.subTaskId,
        access: this.commonService.loginUser
      }
      if(this.commonService.loginUser.roleId != 0) {
        request.storeCd = this.editTaskStore.storeCd;
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.subTaskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            subTask.subTaskAttachment = response.attachment;
            subTask.subTaskFileName = response.attachmentFilename;
            await this.receivePreviewFileSubTask(subTask.subTaskAttachment, subTask.subTaskFileName);  
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {  
      this.receivePreviewFileSubTask(subTask.subTaskAttachment, subTask.subTaskFileName);
    }
  }

  async receivePreviewFileSubTask(attachment: string, filename: string) {
    let mimeType;
    let base64 = attachment.split(';base64,')[1];
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
      
      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;
      default: mimeType ='NoSupport';
    }
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      if (mimeType == "NoSupport") {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `サポートされていないファイル形式`);
      }else {
        var iframe = "<iframe src='" + url + "' frameborder='0' style='border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;' allowfullscreen></iframe>";
        var openTab = window.open();
        openTab.document.open();
        openTab.document.write(iframe);
        openTab.document.title = filename; 
        openTab.document.close();
      } 
    });
  }

  async downLoadFileSubTask(subTask: SubTask) {
    if(!subTask.subTaskAttachment){  
      const request = {
        taskId: this.editTaskStore.taskId,
        storeCd: null,
        subTaskId: subTask.subTaskId,
        access: this.commonService.loginUser
      }
      if(this.commonService.loginUser.roleId != 0) {
        request.storeCd = this.editTaskStore.storeCd;
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.subTaskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            subTask.subTaskAttachment = response.attachment;
            subTask.subTaskFileName = response.attachmentFilename;
            await this.receivedownLoadFileSubTask(subTask.subTaskAttachment, subTask.subTaskFileName);  
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {  
      this.receivedownLoadFileSubTask(subTask.subTaskAttachment, subTask.subTaskFileName);
    }
  }

  async receivedownLoadFileSubTask(attachment: string, filename: string) {
    let mimeType;
    let base64 = attachment.split(';base64,')[1];
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
      
      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;
      default: mimeType ='NoSupport';
    }
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      this.commonService.downloadBlob(blob, filename);
    });
  }
}
