import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpBasicService } from './http-basic.service';
import { ReqExecuteQuery } from '../request/req-execute-query';
import { RspExecuteQuery } from '../response/rsp-execute-query';

export interface HtmlSelectItem {
  id:           number;
  listName:     string;
  storeCd:      string;
  itemName:     string;
  sortKey:      number;
}

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService    
  ) {
  }

  getSelectItemList(listName: string, storeCd: string, callback: (list:HtmlSelectItem[])=>void) {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "misc/getHtmlSelectItems",
      bindVariables: [
        listName,
        storeCd
      ]
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response: RspExecuteQuery) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveSelectItemList(response, callback);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSelectItemList(response: RspExecuteQuery, callback: (list:HtmlSelectItem[])=>void) {
    if (this.httpBasic.handleAppError(response)) return;

    let list:HtmlSelectItem[] = [];
    response.rows.forEach((row) => {
      let rec: HtmlSelectItem = {
        id:       parseInt(row.colData[0]),
        listName: row.colData[1],
        storeCd:  row.colData[2],
        itemName: row.colData[3],
        sortKey:  parseInt(row.colData[4])
      };
      list.push(rec);
    });

    callback(list);
  }


}
