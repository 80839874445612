import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';

export interface ColorItem {
  isCase:         string;
  autoOrder:      string;
  weekday:        string;
  closingTime:    string;
  orderDate:      string;
  color :         string;
};

@Component({
  selector: 'order-edit-bgcolor-dialog',
  templateUrl: './order-edit-bgcolor-dialog.component.html',
  styleUrls: ['./order-edit-bgcolor-dialog.component.css']
})
export class OrderEditBgcolorDialogComponent implements OnInit {

  public columnIds: string[] = [
    "autoOrder",
    "weekday",
    "closingTime",
    "orderDate"
  ];
  public columnIdsCase: string[] = [
    "isCase",
    "autoOrder",
    "weekday",
    "closingTime",
    "orderDate"
  ];
  public columnDefs: TableColumnDef[] = [
    {columnId: "isCase", header: "単品/ケース", width: 90},
    {columnId: "autoOrder", header: "自動発注", width: 70},
    {columnId: "weekday", header: "曜日", width: 70},
    {columnId: "closingTime", header: "締時間", width: 70},
    {columnId: "orderDate", header: "発注日", width: 70},
  ];

  public bgColorList: ColorItem[] = [
    {isCase: "単品", autoOrder: "対象", weekday: "発注可能", closingTime: "締時間前", orderDate: "当日", color: "white"},
    {isCase: "単品", autoOrder: "対象", weekday: "発注可能", closingTime: "締時間後", orderDate: "翌発注日", color: "#FFE9A8"},
    {isCase: "単品", autoOrder: "対象", weekday: "発注不可", closingTime: "", orderDate: "翌発注日", color: "#FFE9A8"},
    {isCase: "単品", autoOrder: "対象外", weekday: "発注可能", closingTime: "締時間前", orderDate: "当日", color: "#FFCDD2"},
    {isCase: "単品", autoOrder: "対象外", weekday: "発注可能", closingTime: "締時間後", orderDate: "翌発注日", color: "#e1d6fa"},
    {isCase: "単品", autoOrder: "対象外", weekday: "発注不可", closingTime: "", orderDate: "翌発注日", color: "#e1d6fa"}
  ];
  public bgColorListCase: ColorItem[] = [
    {isCase: "ケース", autoOrder: "対象外", weekday: "発注可能", closingTime: "締時間前", orderDate: "当日", color: "#BCD6EF"},
    {isCase: "ケース", autoOrder: "対象外", weekday: "発注可能", closingTime: "締時間後", orderDate: "翌発注日", color: "#859dcf"},
    {isCase: "ケース", autoOrder: "対象外", weekday: "発注不可", closingTime: "", orderDate: "翌発注日", color: "#859dcf"},
  ];

  constructor(
    public dialogRef: MatDialogRef<OrderEditBgcolorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public orderEditComponent: string,
    public commonService: CommonService) {

    if (this.commonService.config.orderEditCaseItem) {
      this.bgColorListCase.forEach((item) => {
        this.bgColorList.push(item);
      });
      this.columnIds = this.columnIdsCase;
    }
  
    if (orderEditComponent === "orderStock") {
      this.bgColorList.push(
        {isCase: "単品/ケース", autoOrder: "発注禁止", weekday: "発注不可", closingTime: "", orderDate: "", color: "#dbdbdb"}
      );
    }
  }

  ngOnInit() {
  }

  styleFor(column: TableColumnDef, item: ColorItem) {
    let style = this.commonService.styleFor(column);
    style["background-color"] = item.color;
    return style;
  }
 
}
