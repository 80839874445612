import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-barcode-button',
  templateUrl: './barcode-button.component.html',
  styleUrls: ['./barcode-button.component.css']
})
export class BarcodeButtonComponent implements OnInit {

  private focused: boolean = false;
  private barcode: string = "";

  @Output("onScan") onScan: EventEmitter<string> = new EventEmitter();
  @Output("onFocus") onFocus: EventEmitter<any> = new EventEmitter();
  @Output("onBlur") onBlur: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (!this.focused) return;

    event.preventDefault();

    if (event.key == "Enter") {
      if (this.barcode != "") {
        this.onScan.emit(this.barcode);
      }
      this.barcode = "";
      return;
    }
  
    if (event.ctrlKey || event.metaKey || event.altKey) {
      this.barcode = "";
      return;
    }

    this.barcode += event.key;
  }

  forcusIn() {
    this.focused = true;
    this.onFocus.emit();
  }

  forcusOut() {
    this.focused = false;
    this.barcode = "";
    this.onBlur.emit();
  }
}
