import { FormControl } from "@angular/forms";

export interface ItemDaylyUpdDto {
  userId: string;
  storeCd: string;
  itemCd: string;
  trgtDate: string;
  rcvNum: string;
}

export interface WeeklyOrderDto {
  itemCd: string;
  itemName: string;
  itemStd: string;
  mkrName: string;
  suppName: string;
  itemOrderTime: string;
  ctgCd: string;
  ctgNm: string;
  lotNm: string;
  minzaiNm: string;
  flightNm: string;
  stkNm: string;
  dispItem: string;
  r_1: string;
  r_2: string;
  r_3: string;
  r_4: string;
  r_5: string;
  r_6: string;
  r_7: string;
  r_8: string;
  r_9: string;
  total?: string;
  attrOrder?: string;
  attrDaily?: string;
  confirmFn?: string;
}

export class WeeklyOrderRec {
  public column1: string = "";
  public column2: string = "";
  public column3: string = "";
  public column4: string = "";
  public column5: string = "";
  public column6: string = "";
  public column7: string = "";
  public itemCd: string = "";
  public itemName: string = "";
  public itemStd: string = "";
  public mkrName: string = "";
  public suppName: string = "";
  public itemOrderTime: string = "";
  public ctgCd: string = "";
  public ctgNm: string = "";
  public lotNm: string = "";
  public minzaiNm: string = "";
  public flightNm: string = "";
  public stkNm: string = "";
  public dispItem: string = "";
  public attrOrder: string = "";
  public attrDaily: any = "";
  public confirmFn: string = "";
  public r_1: string = "";
  public r_2: string = "";
  public r_3: string = "";
  public r_4: string = "";
  public r_5: string = "";
  public r_6: string = "";
  public r_7: string = "";
  public r_8: string = "";
  public r_9: string = "";
  public total: string = "";
  public orderLtToday: string = "";
  public formR4: FormControl = new FormControl();
  public formR5: FormControl = new FormControl();
  public formR6: FormControl = new FormControl();
  public formR7: FormControl = new FormControl();
  public formR8: FormControl = new FormControl();
  public formR9: FormControl = new FormControl();
  public trgtDate4: string = this.getTrgtDate(1);
  public trgtDate5: string = this.getTrgtDate(2);
  public trgtDate6: string = this.getTrgtDate(3);
  public trgtDate7: string = this.getTrgtDate(4);
  public trgtDate8: string = this.getTrgtDate(5);
  public trgtDate9: string = this.getTrgtDate(6);
  public originDto: WeeklyOrderDto;
  constructor(dto?: WeeklyOrderDto) {
    if (dto)
      this.patchValue(dto);
  }

  patchValue(obj): void {
    for (let key in obj) {
      if (Object.keys(this).find((recKey) => recKey === key)) {
        this[key] = obj[key];
      }
    }
    let r_1 = isNaN(parseInt(this.r_1)) ? 0 : parseInt(this.r_1);
    let r_2 = isNaN(parseInt(this.r_2)) ? 0 : parseInt(this.r_2);
    let r_3 = isNaN(parseInt(this.r_3)) ? 0 : parseInt(this.r_3);
    let r_4 = isNaN(parseInt(this.r_4)) ? 0 : parseInt(this.r_4);
    let r_5 = isNaN(parseInt(this.r_5)) ? 0 : parseInt(this.r_5);
    let r_6 = isNaN(parseInt(this.r_6)) ? 0 : parseInt(this.r_6);
    let r_7 = isNaN(parseInt(this.r_7)) ? 0 : parseInt(this.r_7);
    let r_8 = isNaN(parseInt(this.r_8)) ? 0 : parseInt(this.r_8);
    let r_9 = isNaN(parseInt(this.r_9)) ? 0 : parseInt(this.r_9);
    let totalValue = r_4 + r_5 + r_6 + r_7 + r_8 + r_9;
    if (this.dispItem == '発注数') {
      this.r_1 = r_1 * parseInt(this.lotNm) + '';
      this.r_2 = r_2 * parseInt(this.lotNm) + '';
      this.r_3 = r_3 * parseInt(this.lotNm) + '';
      this.r_4 = r_4 * parseInt(this.lotNm) + '';
      this.r_5 = r_5 * parseInt(this.lotNm) + '';
      this.r_6 = r_6 * parseInt(this.lotNm) + '';
      this.r_7 = r_7 * parseInt(this.lotNm) + '';
      this.r_8 = r_8 * parseInt(this.lotNm) + '';
      this.r_9 = r_9 * parseInt(this.lotNm) + '';
    }
    this.lotNm = isNaN(parseInt(this.lotNm)) ? 0 + '' : this.lotNm;
    this.total = this.dispItem == '発注数' ? totalValue * parseInt(this.lotNm) + '' : totalValue + '';
    this.column2 = this.itemOrderTime;
    this.column3 = this.suppName.split("breakLine").join(" \n ");
    this.column4 = this.lotNm;
    this.column5 = this.minzaiNm;
    this.column6 = this.stkNm;
    this.column7 = this.flightNm;
    this.formR4.setValue(this.r_4);
    this.formR5.setValue(this.r_5);
    this.formR6.setValue(this.r_6);
    this.formR7.setValue(this.r_7);
    this.formR8.setValue(this.r_8);
    this.formR9.setValue(this.r_9);
    this.originDto = { ...obj };
  }

  getTrgtDate(dateNext: number) {
    const today = new Date();
    const tomorrow = new Date();

    // Cộng thêm n day ngày
    tomorrow.setDate(today.getDate() + dateNext);
    const day = tomorrow.getDate();
    const month = tomorrow.getMonth() + 1;
    const year = tomorrow.getFullYear();
    return `${year}/${('0' + month).slice(-2)}/${('0' + day).slice(-2)}`;
  }
}

