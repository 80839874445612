import { TableColumnDef } from "src/app/common/table-column-def";
import { GraphOption } from "src/app/service/graph.service";

export interface MatrixType {
    id: string;
    name: string;
    xAxisCol: string;
    rowHeaderCols: string[];
    dataHeaderCols: string[];
    graphType?: number;
    xAxisLabel?: string;
    yAxisLabel?: string;
} 

export class AnalysisContext {
    analysisNo: number;
    analysisType: string;
    name: string;
    status: string;                 // "initial", "queried", "dirty"
    condition: Object;
    listData: Object[];
    listColDef: TableColumnDef[];
    listDisplayColumn: string[];
    dimension: number;              // Row header column count in list data + 1
    matrixTypes: MatrixType[];
    graphOption: GraphOption;

    constructor(no: number) {
        this.analysisNo = no;
        this.setDefaultName();
        this.analysisType = "";
        this.status = "initial";
    }

    setDefaultName() {
        this.name = "分析-" + this.analysisNo;
    }

    setDirty() {
        if (this.status == "queried") {
            this.status = "dirty";
        }
    }

    setType(type: string) {
        this.analysisType = type;
        this.setDirty();
    }
}
