import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TaskSearchCond } from 'src/app/common/config';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSearchTaskRec, RspSearchTaskRec, RspTaskFileInfo } from 'src/app/webservice/task';
import { StoreTargetListItemChecked, SubTask, TaskTargetStore } from '../0_def/taskDefs';
import { TaskRec } from '../0_def/taskRec';
import { TaskService } from '../1_service/taskService';
import { ReqDeleteTaskRec, ReqTaskFile, TaskRecDto } from './../../../webservice/task';
import { DbService, HtmlSelectItem } from 'src/app/service/db.servce';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { ReqGetUsers } from 'src/app/request/req-get-users';
import { RspGetUsers } from 'src/app/response/rsp-get-users';
import { HqStoreResultComponent } from '../4_hq-store-result/hq-store-result.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HqStoreResult2Component } from '../4_hq-store-result2/hq-store-result2.component';
import { ViewOtherStoreComponent } from '../7_view-other-store/view-other-store.component';
import { ViewOtherStoreCommentComponent } from '../7_view-other-store comment/view-other-store-comment.component';

@Component({
  selector: 'app-hq-main',
  templateUrl: './hq-main.component.html',
  styleUrls: ['./hq-main.component.css']
})
export class HqMainComponent implements OnInit, OnDestroy, AfterViewChecked {

  private tabIndexList: number = 0;
  private tabIndexEdit: number = 1;
  private tabIndexProgress: number = 2;
  private tabIndexReference: number = 3;
  private tabIndexReferenceComment: number = 4;

  public tabSelection: number = 0;
  public formGroupCondition: FormGroup;
  public dateBegin: Date;
  public dateEnd: Date;
  private autoSearch: boolean = true;
  public viewTask: TaskRec;
  public taskRec: TaskRec;
  public taskRecComment: TaskRec;
  public requestTaskSearch: ReqSearchTaskRec;
  public taskList: TaskRec[];
  public selectedTask: TaskRec;
  public currentTask: SubTask[];
  public targetStores: StoreTargetListItemChecked[];
  public targetStoreSelected: Map<string, boolean> = new Map();
  public subtaskSelected: Map<string, boolean> = new Map();
  public subtasks: SubTask[];
  public isFromTaskHq: boolean = false;
  public columnIds: string[] = ["authorFv","releaseStatusText", "taskName", "priority", "dateBegin", "dateEnd",
    "datesToEndText", "taskStatus", "editBtn", "progressBtn", "referenceBtn", "referenceCommentBtn"];
  public columnIdsWithCategory: string[] = ["authorFv","releaseStatusText", "taskCategory", "taskName", "priority", "dateBegin", "dateEnd",
    "datesToEndText", "taskStatus", "editBtn", "progressBtn", "referenceBtn", "referenceCommentBtn"];
  public columnDefs: TableColumnDef[] = [
    {columnId:"authorFv", header:"指示者", width:100},
    {columnId:"releaseStatusText", header:"公開", width:60, align:"center"},
    {columnId:"taskCategory", header:"作業分類", width:200},
    {columnId:"taskName", header:"タスク名", width:300},
    {columnId:"priority", header:"優先度", width:60, align:"center"},
    {columnId:"dateBegin", header:"開始日", width:100, align:"center"},
    {columnId:"dateEnd", header:"終了日", width:100, align:"center"},
    {columnId:"datesToEndText", header:"残日数", width:60, align:"center"},
    {columnId:"progress", header:"完了店舗", width:80, align:"center"},
    {columnId:"taskStatus", header:"ステータス", width:70, align:"center"}
  ];
  public newTaskEditing: string;
  private subscRoute: Subscription;
  private subscStoreCd: Subscription;
  public taskCategories: HtmlSelectItem[] = [];
  public storeList: LoginStoreDto[];
  public loginUsers: {userId: string; userName: string;}[] = [];
  public formAuthorStoreCd: FormControl;
  public formAuthorLoginUserId: FormControl;

  @ViewChild(HqStoreResult2Component, { static: false }) hqStoreResult2Component: HqStoreResult2Component;
  @ViewChild(ViewOtherStoreComponent, { static: false }) viewOtherStoreComponent: ViewOtherStoreComponent;
  @ViewChild(ViewOtherStoreCommentComponent, { static: false }) viewOtherStoreCommentComponent: ViewOtherStoreCommentComponent;

  @ViewChild(DateRangeComponent, {static: true}) dateRangeComponent: DateRangeComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic:    HttpBasicService,
    public taskService:   TaskService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dbService:    DbService
  ) {
    this.initCond();
    if (this.commonService.config.task.useTaskCategory) {
      this.columnIds = [...this.columnIdsWithCategory];
    }
    if (this.commonService.config.includeNonAutoOrderStore.task === false) {
      this.storeList = [...this.commonService.stores]
    } else {
      this.storeList = [...this.commonService.allStores]
    }
  }

  ngOnInit(): void {
    if (this.commonService.stores.length <= 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この機能を使用する権限がありません。");
      this.commonService.router.navigate(['top']);
      return;
    }

    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.getTasks();
    this.getTaskCategories();

    this.subscRoute = this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        if (this.commonService.taskSearchCond !== undefined) {
          this.initCond();
          this.autoSearch = true;
          this.getTasks();
        }
      }
    );
  }

  initCond() {
    let cond: TaskSearchCond
    if (this.commonService.taskSearchCond !== undefined) {
      cond = {...this.commonService.taskSearchCond};
      this.commonService.taskSearchCond = undefined;
    } else {
      cond = {...this.commonService.config.task.searchCond};
    }

    this.formGroupCondition = new FormGroup({
      // releaseStatus:        new FormControl(true),
      releaseStatusValue:   new FormControl(-1),
      statusNotStarted:     new FormControl(cond.statusNotStarted),
      statusStarted:        new FormControl(cond.statusStarted),
      statusCompleted:      new FormControl(cond.statusCompleted),
      dateCheck:            new FormControl(cond.endDate),
      taskName:             new FormControl(cond.taskName),
      taskCategory:         new FormControl(cond.taskCategory),
      authorPartial:        new FormControl(true),
      authorFv:             new FormControl(cond.author),
      authorStoreCd:        new FormControl(""),
      authorLoginUserId:    new FormControl(""),
      responsibleClass:     new FormControl(cond.responsibleClass)
    });
    this.formAuthorStoreCd = <FormControl>this.formGroupCondition.get("authorStoreCd");
    this.formAuthorLoginUserId = <FormControl>this.formGroupCondition.get("authorLoginUserId");
    this.subscStoreCd = this.formAuthorStoreCd.valueChanges.subscribe(
      (value) => { this.authorStoreCdChanged(); } 
    );

    if (cond.releaseStatus === "未公開") {
      this.formGroupCondition.get("releaseStatusValue").setValue(0);
    } else if (cond.releaseStatus === "公開済") {
      this.formGroupCondition.get("releaseStatusValue").setValue(1);
    }

    this.dateBegin = new Date();
    this.dateBegin.setMonth(this.dateBegin.getMonth() + cond.endDateFrom);
    this.dateBegin.setDate(this.dateBegin.getDate() + cond.endDateFromDay);
    this.dateEnd = new Date();
    this.dateEnd.setMonth(this.dateEnd.getMonth() + cond.endDateTo);
    this.dateEnd.setDate(this.dateEnd.getDate()  + cond.endDateToDay);
  }

  getTaskCategories() {
    if (!this.commonService.config.task.useTaskCategory) return;
    this.dbService.getSelectItemList("task:taskCategory", "", (list) => { this.receiveTaskCategories(list); })
  }

  receiveTaskCategories(list: HtmlSelectItem[]) {
    this.taskCategories = list;
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.taskService.endEdit();
    this.subscRoute?.unsubscribe();
    this.subscStoreCd?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    if (this.tabSelection === 2) {
      this.hqStoreResult2Component?.setTableHeight();
    } else if (this.tabSelection === 3) {
      this.viewOtherStoreComponent?.setTableHeight();
    } else if (this.tabSelection === 4) {
      this.viewOtherStoreCommentComponent.setTableHeight();
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    this.setTableHeight();
  }

  flipCondStatus(formName: string) {
    let form = this.formGroupCondition.get(formName);
    if (form.value) {
      form.setValue(false);
    } else {
      form.setValue(true);
    }
  }

  checkCondStatus(value: boolean) {
    // this.formGroupCondition.get("releaseStatus").setValue(value);
    this.formGroupCondition.get("statusNotStarted").setValue(value);
    this.formGroupCondition.get("statusStarted").setValue(value);
    this.formGroupCondition.get("statusCompleted").setValue(value);
  }

  getTaskConfirm() {
    if (this.isNewTaskEditing()) {
      this.getTasks();
      return;
    }

    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.getTasks();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.getTasks();
        }
      }
    );
  }

  getTasks() {
    if (!this.isNewTaskEditing()) {
      this.clearProgressEditTemp();
    }

    let dateEndMin;
    let dateEndMax;
    if (this.autoSearch) {
      dateEndMin = this.commonService.formatDate(this.dateBegin);
      dateEndMax = this.commonService.formatDate(this.dateEnd);
      this.autoSearch = false;
    } else {
      let range = this.dateRangeComponent.getDateRange();
      dateEndMin = this.commonService.formatDate(range.dateBegin);
      dateEndMax = this.commonService.formatDate(range.dateEnd);
    }

    let request: ReqSearchTaskRec = {
      access: this.commonService.loginUser,
      releaseStatus: parseInt(this.formGroupCondition.get("releaseStatusValue").value),
      taskStatus: [],
      dateEndMin: this.formGroupCondition.get("dateCheck").value ? dateEndMin : "",
      dateEndMax: this.formGroupCondition.get("dateCheck").value ? dateEndMax : "",
      taskName: this.formGroupCondition.get("taskName").value.trim(),
      authorFv: "",
      authorStoreCd: "",
      authorLoginUserId: "",
      responsibleClass: this.formGroupCondition.get("responsibleClass").value.trim(),
      taskCategory: this.formGroupCondition.get("taskCategory").value
    }
    if (this.formGroupCondition.get("authorPartial").value) {
      request.authorFv = this.formGroupCondition.get("authorFv").value.trim();
    } else {
      request.authorStoreCd = this.formAuthorStoreCd.value;
      request.authorLoginUserId = this.formAuthorLoginUserId.value;
    }

    if (this.formGroupCondition.get("statusNotStarted").value) request.taskStatus.push(this.taskService.taskStatusNotStarted);
    if (this.formGroupCondition.get("statusStarted").value) request.taskStatus.push(this.taskService.taskStatusStarted);
    if (this.formGroupCondition.get("statusCompleted").value) request.taskStatus.push(this.taskService.taskStatusCompleted);
    this.requestTaskSearch = request;

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.searchTaskRec(request).subscribe(
      (response: RspSearchTaskRec) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveGetTask(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetTask(response: RspSearchTaskRec) {
    if (this.httpBasic.handleAppError(response)) return;
    this.taskList = [];
    response.taskRecs.forEach((rec) => {
      let taskRec = new TaskRec(this.commonService, this.httpBasic, this.taskService);
      let targetStores: TaskTargetStore[] = JSON.parse(rec.targetStores);
      let subTasks: SubTask[] = JSON.parse(rec.subTasks);
      delete rec.targetStores;
      delete rec.subTasks;
      taskRec.patchValue(rec);
      taskRec.targetStores = targetStores;
      taskRec.subTasks = subTasks;
      /* For compatibility againt old version */
      subTasks.forEach((subTask) => {
        if (!subTask.subTaskUrl) subTask.subTaskUrl = "";
        if (!subTask.workTimeH) subTask.workTimeH = 0;
        if (!subTask.workTimeM) subTask.workTimeM = 0;
      });
      this.taskList.push(taskRec);
    });

    if (this.commonService.taskOpenTaskId > 0) {
      let item = this.taskList.find((rec) => rec.taskId === this.commonService.taskOpenTaskId);
      this.commonService.taskOpenTaskId = -1;
      if (item) this.editTask(item);
    }
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    };
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    };
  }

  isWarnColor(item: TaskRec, columnId: string) {
    if (columnId != "datesToEndText") return false;
    if (item.datesToEndText === "") return false;
    if (item.datesToEnd >= 0) return false;
    return true;
  }
  isWarnYellowBgColor(item: TaskRec, columnId: string) {
    if (columnId != "datesToEndText") return false;
    if (item.datesToEndText === "") return false;
    if (item.datesToEnd > 7 || item.datesToEnd < 0) return false;
    return true;
  }

  selectTask(item: TaskRec) {
    this.selectedTask = item;
  }

  editTask(item: TaskRec) {
    this.selectedTask = item;
    const request: ReqTaskFile = {
      taskId: this.selectedTask.taskId,
      access: this.commonService.loginUser,
      mode: 1
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.taskRecFileInfoSearch(request).subscribe(
      (response: RspTaskFileInfo) => {
        if(response) {
          response.taskRecFileInfo.forEach(file => item.md5List.push(file.md5));
          item.attachmentList = response.taskRecFileInfo.map(file => Object.assign(file));
          this.taskService.startEdit(item);
          this.openAllTab(item);
          this.tabSelection = this.tabIndexEdit;
          this.commonService.closeSpinner();
        }
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )
  }

  openAllTab(item: TaskRec) {
    if (this.commonService.config.task.hqOpenAllTab) {
      if (item.releaseStatusBool) {
        this.progressOfTask(item, false);
        this.referenceOfTask(item, false);
        this.referenceOfTaskComment(item, false);
      } else {
        this.viewTask = undefined;
        this.taskRec = undefined;
        this.currentTask = undefined;
        this.taskRecComment = undefined;
      }
    }
  }

  newTask() {
    let newTask: TaskRec = new TaskRec(this.commonService, this.httpBasic, this.taskService);
    if (this.commonService.config.task.responsibleClassDefIndex >= 0 &&
        this.commonService.config.task.responsibleClassDefIndex < this.commonService.config.taskResponsibleClasses.length) {
      newTask.responsibleClass = this.commonService.config.taskResponsibleClasses[this.commonService.config.task.responsibleClassDefIndex];
    }
    this.taskService.startEdit(newTask);
    newTask.markAsDirty();
    this.tabSelection = this.tabIndexEdit;
  }

  copyEdit() {
    if(!this.selectTask) return;
    let newTask: TaskRec = new TaskRec(this.commonService, this.httpBasic, this.taskService);
    
    const request: ReqTaskFile = {
      taskId: this.selectedTask.taskId,
      access: this.commonService.loginUser,
      mode: -1
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.taskRecFileInfoSearch(request).subscribe(
      (response: RspTaskFileInfo) => {
        if(response){
          response.taskRecFileInfo.forEach(file => this.selectedTask.md5List.push(file.md5));
          this.selectedTask.attachmentList = response.taskRecFileInfo.map((file, index) => {return {...file, taskId: -1, attachment: file.attachment, attachmentId: index, sortFn: index}});
          this.selectedTask.subAttachmentList = response.subTaskRecFileInfoList?.map(sub => {return {...sub, taskId: -1}});
          
          this.commonService.closeSpinner(); 
          Object.assign(newTask, this.selectedTask);
          newTask.taskId = -1;
          newTask.releaseStatus = 0;
          newTask.releaseStatusBool = false;
          newTask.taskStatus = '未着手';
          this.taskService.startEdit(newTask);

          newTask.markAsDirty();
          this.tabSelection = this.tabIndexEdit;          
        }
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  deleteTaskConfirm() {
    let msg = "タスク「 " + this.selectedTask.taskName + " 」を削除しますか？"
    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      (response) => {
        subsc.unsubscribe();
        if (response) this.deleteTask();
      }
    );
  }

  deleteTask() {
    let dateEndMin;
    let dateEndMax;
    if (this.autoSearch) {
      dateEndMin = this.commonService.formatDate(this.dateBegin);
      dateEndMax = this.commonService.formatDate(this.dateEnd);
      this.autoSearch = false;
    } else {
      let range = this.dateRangeComponent.getDateRange();
      dateEndMin = this.commonService.formatDate(range.dateBegin);
      dateEndMax = this.commonService.formatDate(range.dateEnd);
    }

    let request: ReqDeleteTaskRec = {
      taskId: this.selectedTask.taskId,
      access: this.commonService.loginUser,
      releaseStatus: parseInt(this.formGroupCondition.get("releaseStatusValue").value),
      taskStatus: [],
      dateEndMin: this.formGroupCondition.get("dateCheck").value ? dateEndMin : "",
      dateEndMax: this.formGroupCondition.get("dateCheck").value ? dateEndMax : "",
      taskName: this.formGroupCondition.get("taskName").value.trim(),
      authorFv: "",
      authorStoreCd: "",
      authorLoginUserId: "",
      responsibleClass: this.formGroupCondition.get("responsibleClass").value.trim(),
      taskCategory: this.formGroupCondition.get("taskCategory").value
    }
    if (this.formGroupCondition.get("authorPartial").value) {
      request.authorFv = this.formGroupCondition.get("authorFv").value.trim();
    } else {
      request.authorStoreCd = this.formAuthorStoreCd.value;
      request.authorLoginUserId = this.formAuthorLoginUserId.value;
    }

    if (this.formGroupCondition.get("statusNotStarted").value) request.taskStatus.push(this.taskService.taskStatusNotStarted);
    if (this.formGroupCondition.get("statusStarted").value) request.taskStatus.push(this.taskService.taskStatusStarted);
    if (this.formGroupCondition.get("statusCompleted").value) request.taskStatus.push(this.taskService.taskStatusCompleted);

    this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
    let subsc = this.httpBasic.deleteTaskRec(request).subscribe(
      (response: RspSearchTaskRec) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveGetTask(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );

    // Clear progress after delete
    this.taskService.taskDeleted(this.selectedTask);
    this.selectedTask = undefined;
  }

  disableDelete() {
    if (!this.selectedTask) return true;
    if (this.commonService?.config?.canDeleteReleasedTask) return false;
    if (this.selectedTask.releaseStatusBool && !this.selectedTask.isNotActivatedReleaseDate) return true;
    return false;
  }

  isNewTaskEditing() {
    if (this.taskService.editTask && this.taskService.editTask.taskId < 0) {
      this.newTaskEditing = "新規作成のタスクを編集中です。";
      return true;
    }
  }

  progressOfTask(item: TaskRec, tabChange?: boolean) {
    if (tabChange === undefined) tabChange = true;
    this.selectedTask = item;
    const request: ReqTaskFile = {
      taskId: this.selectedTask.taskId,
      access: this.commonService.loginUser,
      mode: 1
    }
    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.taskRecFileInfoSearch(request).subscribe(
      (response: RspTaskFileInfo) => {
        if(response) { 
          response.taskRecFileInfo.forEach(file => item.md5List.push(file.md5));
          item.attachmentList = response.taskRecFileInfo.map(file => Object.assign(file));   
          this.viewTask = item;
          if (tabChange) this.tabSelection = this.tabIndexProgress;
          this.commonService.closeSpinnerForSubComp(ref);
        }
      },
      error => {
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    )
  }

  referenceOfTask(item: TaskRec, tabChange?: boolean) {
    if (tabChange === undefined) tabChange = true;
    this.selectedTask = item;
    this.taskRec = item;
    const request: ReqTaskFile = {
      taskId: this.selectedTask.taskId,
      access: this.commonService.loginUser,
      mode: 1
    }
    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.taskRecFileInfoSearch(request).subscribe(
      (response: RspTaskFileInfo) => {
        if(response) {
          response.taskRecFileInfo.forEach(file => item.md5List.push(file.md5)); 
          item.attachmentList = response.taskRecFileInfo.map(file => Object.assign(file));                
          this.taskRec = item;
          if (tabChange) this.tabSelection = this.tabIndexReference;
          this.commonService.closeSpinnerForSubComp(ref);
        }
      },
      error => {
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    )
    this.initSubtask();
  }

  referenceOfTaskComment(item: TaskRec, tabChange?: boolean) {
    if (tabChange === undefined) tabChange = true;
    this.selectedTask = item;
    this.taskRecComment = item;
    if (tabChange) this.tabSelection = this.tabIndexReferenceComment;
  }

  initSubtask() {
    this.isFromTaskHq = true;
    this.subtasks = [];
    this.subtaskSelected = new Map();
    this.currentTask = [];
    this.targetStores = [];
    if(this.taskRec.subTasks.length < 1) return;
    this.taskRec.subTasks.map(subtask => {
      this.subtasks.push(subtask);
      this.currentTask.push(subtask);
      this.subtaskSelected.set(subtask.subTaskId+'', subtask.photoReport);
    });
    this.taskRec.targetStores.map(store => {
      this.targetStores.push({
        storeCd: store.storeCd,
        storeNameWithCode: store.storeNameWithCode,
        storeNameWithCodeCheck: store.storeNameWithCode,
        storeName: store.storeName,
      })
      this.targetStoreSelected.set(store.storeNameWithCode, true);
    });
  }

  canDeactivate() {
    if (this.taskService.canEdit()) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  clearProgressEditTemp(){
    this.selectedTask = undefined;
    this.taskService.endEdit();
    this.viewTask = undefined;
  }

  clearTaskList(event) {
    this.taskList = [];
    this.selectedTask = undefined;
    this.viewTask = undefined;
  }

  refreshTaskList(taskRecs: TaskRecDto[]) {
    this.taskList = [];
    taskRecs.forEach((rec) => {
      let taskRec = new TaskRec(this.commonService, this.httpBasic, this.taskService);
      let targetStores: TaskTargetStore[] = JSON.parse(rec.targetStores);
      let subTasks: SubTask[] = JSON.parse(rec.subTasks);
      delete rec.targetStores;
      delete rec.subTasks;
      taskRec.patchValue(rec);
      taskRec.targetStores = targetStores;
      taskRec.subTasks = subTasks;
      this.taskList.push(taskRec);
    });
  }

  refreshSelectedTask(taskId: number) {
    this.selectedTask = this.taskList.find(task => task.taskId === taskId);
  }

  authorStoreCdChanged() {
    this.formAuthorLoginUserId.setValue("");

    let storeCd = this.formAuthorStoreCd.value;
    if (storeCd === "") {
      this.loginUsers = [];
    } else {
      this.getUsers(storeCd);
    }
  }

  getUsers(storeCd: string) {
    let request: ReqGetUsers = {
      access: this.commonService.loginUser,
      storeCd: storeCd
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetUsers", request).subscribe(
      (response: RspGetUsers) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveGetUsers(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetUsers(response: RspGetUsers) {
    if (this.httpBasic.handleAppError(response)) return;

    this.loginUsers = [];
    response.users.forEach((dto) => {
      let user = {
        userId: dto.userId,
        userName: dto.userName
      };
      this.loginUsers.push(dto);
    });
  }
}
