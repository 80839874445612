import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { PhotoItem } from '../0_def/taskDefs';

@Component({
  selector: 'app-task-photo-dialog',
  templateUrl: './task-photo-dialog.component.html',
  styleUrls: ['./task-photo-dialog.component.css']
})
export class TaskPhotoDialogComponent implements OnInit, OnDestroy {

  public isCameraActive: boolean = false;
  public formPhotoTitle: FormControl = new FormControl("");
  public formFile: FormControl = new FormControl();
  public videoWidth: number = 400;
  public videoHeight: number = 400;
  private video: HTMLVideoElement;
  private canvas: HTMLCanvasElement;
  private subscFile: Subscription;
  private dataUrl: string;

  constructor(
    public dialogRef: MatDialogRef<TaskPhotoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhotoItem,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    if (this.data.title) {
      this.formPhotoTitle.setValue(this.data.title);
    }
    if (this.data.dataUrl) {
      let img = document.getElementById("img-view") as HTMLImageElement;
      img.src = this.data.dataUrl;
    }
    this.subscFile = this.formFile.valueChanges.subscribe(
      () => {this.fileChanged();}
    );
  }

  ngOnDestroy() {
    this.stopCamera();
    this.subscFile?.unsubscribe();
  }

  startCamera() {
    this.isCameraActive = true;
    setTimeout(()=>{this.startCameraBody();}, 0);
  }

  startCameraBody() {
    this.video = document.getElementById("camera-view") as HTMLVideoElement;

    let constraints = {
      audio: false,
      video: {
        width: this.videoWidth,
        height: this.videoHeight,
        // facingMode: "user"                     // フロントカメラ
        facingMode: "environment"                 // リアカメラ
      }
    };

    navigator.mediaDevices.getUserMedia(constraints)
    .then((stream) => {
      this.video.srcObject = stream;
      this.video.onloadedmetadata = (e) => {
        this.video.play();
      };
    })
    .catch((err) => {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この端末ではご利用いただけない機能です");
      this.onCancel();
    });
  }

  cancelCamera() {
    this.stopCamera();
    this.isCameraActive = false;
  }

  stopCamera() {
    if (!this.video) return;
    let stream: MediaStream = this.video.srcObject as MediaStream;
    if (!stream) return; 
    let tracks: MediaStreamTrack[] = stream.getTracks();
    for (let track of tracks) {
      track.stop();
    }
    this.video.srcObject = null;
  }

  onShutter() {
    this.canvas = document.getElementById("photo-view") as HTMLCanvasElement;

    let shuttfer = document.getElementById("shutter-sound") as HTMLAudioElement;
    shuttfer.play();      // シャッター音
    this.video.pause();

    let ctx = this.canvas.getContext("2d");
    ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    this.stopCamera();
    this.dataUrl = this.canvas.toDataURL("image/jpeg");
    let img = document.getElementById("img-view") as HTMLImageElement;
    img.src = this.dataUrl;
    this.isCameraActive = false;
  }

  onConfirmed() {
    if (this.data.title !== this.formPhotoTitle.value) {
      this.data.title = this.formPhotoTitle.value;
      this.data.isUpdated = true;
    }
    if (this.dataUrl) {
      this.data.dataUrl = this.dataUrl;
      this.data.isImageUpdated = true;
    }
    this.dialogRef.close(this.data);
  }

  /*
  onRestart() {
    this.startCamera();
  }
  */

  onCancel() {
    this.dialogRef.close();
  }

  disableConfirm() {
    if (this.formPhotoTitle.value === "") return true;            // title is required
    if (this.dataUrl) return false;                               // Image updated
    if (!this.data.dataUrl || this.dataUrl === "" ) return true;  // No image
    if (this.formPhotoTitle.dirty) return false;                  // title updated
    return true;
  }

  getFile() {
    if (this.isCameraActive) return;

    let elem = document.getElementById("input-file") as HTMLInputElement;
    elem.value = "";
    elem.click();
  }

  fileChanged() {
    let fileInput = document.getElementById('input-file')  as HTMLInputElement;
    let file;
    if (fileInput.files[0]) {
      file = fileInput.files[0];
    }

    let fileReader = new FileReader();
    this.commonService.openSpinner(this.commonService.pageTitle, "読込み中・・・")
    fileReader.onload = () => {      
      this.commonService.closeSpinner();
      this.readComplete(fileReader);
    }
    fileReader.onerror = () => {
      this.commonService.closeSpinner();
      this.commonService.openErrorDialog(this.commonService.pageTitle, "ファイルの読み込みに失敗しました。<br><br>"+fileReader.error);
      return;
    };

    fileReader.readAsDataURL(file);
  }

  readComplete(fileReader: FileReader) {
    let url = fileReader.result.toString()
    if (!url.startsWith("data:image")) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "画像データではありません。");
      return;
    }
    this.dataUrl = url;
    let img = document.getElementById("img-view") as HTMLImageElement;
    img.src = this.dataUrl;
    this.isCameraActive = false;
  }
}
