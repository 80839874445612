import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CsvData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CsvTableComponent } from 'src/app/partsCommon/csv-table/csv-table.component';
import { ReqCsvVisitorsCheckDto } from 'src/app/request/req-csv-visitors-check';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqCsvVisitorsUpdateDto } from 'src/app/request/req-csv-visitors-update';
import { RspCsvVisitorsCheck } from 'src/app/response/rsp-csv-visitors-check';
import { RspCsvVisitorsUpdate } from 'src/app/response/rsp-csv-visitors-update';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { RspExecuteQuery } from 'src/app/response/rsp-execute-query';

interface Visitor {
  storeCdFv: string;
  storeNameFv: string;
  calcTrgtDateFv: string;
  cusEstNumFv: number | string;
  cusResultNumFv: number | string;
}

@Component({
  selector: 'app-csv-visitors',
  templateUrl: './csv-visitors.component.html',
  styleUrls: ['./csv-visitors.component.css']
})
export class CsvVisitorsComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  // public maxDate: Date;
  public minDateBegin: Date;
  public subscriptionCheck: Subscription;
  public subscriptionUpdate: Subscription;

  private isReadonly: boolean;
  public visitorsList: CsvData;
  public columnDefs: TableColumnDef[] = [
    { columnId: "storeCdFv", header: "店舗コード", width: 70, align: "center" },
    { columnId: "storeNameFv", header: "店舗名", width: 150, csvComment: "[空欄可]" },
    { columnId: "calcTrgtDateFv", header: "来店日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式]" },
    { columnId: "cusEstNumFv", header: "予測客数", width: 100, align: "right", csvComment: "[0以上の数値 or 空欄]", numberPipe: "" },
    { columnId: "cusResultNumFv", header: "実績客数", width: 100, align: "right", csvComment: "[0以上の数値 or 空欄]", numberPipe: "" }
  ];
  public displayColumnDefs: TableColumnDef[] = [];
  public displayColumnIds: string[];
  public vistorsQueryList: Visitor[];

  @ViewChild(CsvTableComponent, { static: false }) csvTableComponent: CsvTableComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("csvVisitors");

    if (this.commonService.stores.length <= 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この機能を使用する権限がありません。");
      this.commonService.router.navigate(['top']);
      return;
    }
    else if (this.commonService.stores.length <= 1) {
      this.displayColumnDefs = [...this.columnDefs]
        .filter(column => (column.columnId !== "storeCdFv" && column.columnId != "storeNameFv"));
    }
    else {
      this.displayColumnDefs = [...this.columnDefs]
    }
    this.displayColumnIds = [];
    for (let col of this.displayColumnDefs) {
      this.displayColumnIds.push(col.columnId);
    }

    this.formGroup = this.fb.group(
      {
        dateBegin: [, Validators.required],
        dateEnd: [, Validators.required],
      }
    );

    let today = new Date();
    this.minDateBegin = new Date(2010, 0, 1);
    let begin = this.commonService.copyDate(today);
    begin.setDate(begin.getDate() - 28);
    this.formGroup.get("dateBegin").setValue(begin);
    this.formGroup.get("dateEnd").setValue(today);

    window.addEventListener("resize", () => { this.resized(); });
    setTimeout(() => { this.resized(); }, 0);
  }

  ngOnDestroy(): void {
    if (this.subscriptionCheck) {
      this.subscriptionCheck.unsubscribe();
      this.subscriptionCheck = undefined;
    }
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
      this.subscriptionUpdate = undefined;
    }
    window.removeEventListener("resize", () => { this.resized(); });
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionCheck) {
      this.subscriptionCheck.unsubscribe();
      this.subscriptionCheck = undefined;
    }
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
      this.subscriptionUpdate = undefined;
    }
  }

  getCsvData(csvData: CsvData) {
    this.visitorsList = { ...csvData };
    if (this.csvTableComponent) {
      this.csvTableComponent.init(this.visitorsList);
    }
  }

  isDisableUpdateBtn() {
    if (this.visitorsList == undefined) return true;
    if (this.visitorsList.errorCount == undefined) return true;
    if (this.visitorsList.errorCount > 0) return true;
    return false;
  }

  getDataCheckOnTable() {
    let request: ReqCsvVisitorsCheckDto[] = [];
    this.visitorsList.lines.map(value => {
      let item: ReqCsvVisitorsCheckDto = {
        calcTrgtDateFv: value.columnData['calcTrgtDateFv'].trim(),
        cusEstNumFv: value.columnData['cusEstNumFv'].trim(),
        cusResultNumFv: value.columnData['cusResultNumFv'].trim()
      }

      if (this.commonService.stores.length && this.commonService.stores.length > 1) {
        item.storeCdFv = value.columnData['storeCdFv'].trim();
        item.storeNameFv = value.columnData['storeNameFv'].trim();
      }
      request.push(item);
    });
    return request;
  }

  getDataUpdateOnTable() {
    let request: ReqCsvVisitorsUpdateDto[] = [];
    this.visitorsList.lines.map(value => {
      let item: ReqCsvVisitorsUpdateDto = {
        calcTrgtDateFv: value.columnData['calcTrgtDateFv'].trim(),
        cusEstNumFv: value.columnData['cusEstNumFv'].trim(),
        cusResultNumFv: value.columnData['cusResultNumFv'].trim()
      }

      if (this.commonService.stores.length && this.commonService.stores.length > 1) {
        item.storeCdFv = value.columnData['storeCdFv'].trim();
        item.storeNameFv = value.columnData['storeNameFv'].trim();
      }
      request.push(item);
    });
    return request;
  }

  doConfirm() {
    const request: ReqCsvVisitorsCheckDto[] = this.getDataCheckOnTable();

    if (request.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "データがありません。");
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "確認中・・・");
    this.subscriptionCheck = this.httpBasic.checkCsvVisitors(request).subscribe(
      (response: RspCsvVisitorsCheck) => {
        this.checkResultCsvVisitorsCheck(response);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
    this.csvTableComponent.handleError();
  }

  doUpdate() {
    const request: ReqCsvVisitorsUpdateDto[] = this.getDataUpdateOnTable();

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.subscriptionUpdate = this.httpBasic.updateCsvVisitors(request).subscribe(
      (response: RspCsvVisitorsUpdate) => {
        this.checkResultVisitorsUpdate(response);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  checkResultCsvVisitorsCheck(response: RspCsvVisitorsCheck) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    if (response.result.length > 0) {
      response.result.map((item) => {
        if (item.errorMsgs) {
          this.visitorsList.lines[item.line].errorMsg = item.errorMsgs;
          this.visitorsList.lines[item.line].warningMsg = item.warningMsgs;
        }
      });
      this.csvTableComponent.handleError();
    }
  }

  checkResultVisitorsUpdate(response: RspCsvVisitorsUpdate) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.vistorsQueryList = undefined;

    this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');
  }

  resized() {
    let containerElement = document.getElementById("table-inner-container");
    if (!containerElement) return;
    let containerRect = containerElement.getBoundingClientRect();
    let containerHeight = window.innerHeight - containerRect.top - 70;
    containerElement.style["max-height"] = "" + containerHeight + "px";
  }

  tabChanged(tabNumber: number) {
    if (tabNumber == 1) {
      setTimeout(()=>{this.resized();}, 0);
    }
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "max-width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "max-width": "" + colDef.width + "px"
    }
  }

  doQuery() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "misc/getVisitors",
      bindVariables: [
        (this.commonService.stores.length > 1 ? '*' : this.commonService.loginUser.storeCd),
        this.commonService.formatDate(this.formGroup.get("dateBegin").value),
        this.commonService.formatDate(this.formGroup.get("dateEnd").value)
      ]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subs = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subs.unsubscribe();
        this.receiveVisitorList(response);
      },
      (error) => {
        subs.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveVisitorList(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.vistorsQueryList = [];
    for (let row of response.rows) {
      let rec: Visitor = {
        storeCdFv: row.colData[0],
        storeNameFv: row.colData[1],
        calcTrgtDateFv: row.colData[2],
        cusEstNumFv: row.colData[3] === "" ? "" : parseInt(row.colData[3]),
        cusResultNumFv: row.colData[4] === "" ? "" : parseInt(row.colData[4])
      }
      this.vistorsQueryList.push(rec);
    }

    setTimeout(() => { this.resized(); }, 0);
  }

}
