import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskImageRecDto } from 'src/app/webservice/task';

@Component({
  selector: 'app-task-photo-view-dialog',
  templateUrl: './task-photo-view-dialog.component.html',
  styleUrls: ['./task-photo-view-dialog.component.css']
})
export class TaskPhotoViewDialogComponent {
  public isErrorImage: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<TaskPhotoViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TaskImageRecDto,
  ) { }

  setError() {
    this.isErrorImage = true;
  }

  onCancel() {
    this.dialogRef.close();
  }
}
