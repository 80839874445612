import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CameraDef, CameraLayout, CameraLayoutConfig } from 'src/app/page/camera/CameraDef';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { ExecuteQueryRecDto, RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CameraDialogComponent } from 'src/app/dialog/camera-dialog/camera-dialog.component';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements OnInit, OnDestroy, AfterViewChecked {

  public stores: LoginStoreDto[];
  public storeForm : FormControl = new FormControl(this.commonService.loginUser.storeCd);
  public listTypeForm : FormControl = new FormControl("レイアウト一覧");
  public tabForm : FormControl = new FormControl(false);
  public intervalForm : FormControl = new FormControl(1000);

  public tableWidthLayout: any;
  public columnIdsLayout: string[] = ["layoutName","comment"];
  public columnDefsLayout: TableColumnDef[] = [
    {columnId:'layoutName', header:"レイアウト", width:300},
    {columnId:'comment', header:"備考", width:300}
  ];
  public tableWidthCamera: any;
  public columnIdsCamera: string[] = ["store", "cameraName", "comment"];
  public columnDefsCamera: TableColumnDef[] = [
    {columnId:'store', header:"店舗", width:150},
    {columnId:'cameraName', header:"カメラ", width:150},
    {columnId:'comment', header:"備考", width:300}
  ];

  public cameraList: CameraDef[] = [];
  public cameraListByStore: CameraDef[] = [];
  public layoutList: CameraLayout[] = [];
  public selectedLayout: CameraLayout;
  public layoutConfigList: CameraLayoutConfig[] = [];
  public layoutConfigMatrix: CameraLayoutConfig[][] = [];

  public openWindows: {storeCdFv: string, cameraCdFv: string, win:WindowProxy}[] = [];

  private subscriptionQuery: Subscription;
  private subscriptionListType: Subscription;
  private subscriptionStore: Subscription;
  private subscriptionTab: Subscription;
  private subscriptionDialog: Subscription;
  // private isReloading = false;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private changeDetectorRef: ChangeDetectorRef,
    public http: HttpClient) {
  }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    if (this.commonService.stores.length > 1) {
      this.stores = [{storeCd: "*", storeName:"全店"}, ...this.commonService.stores];
    } else {
      this.stores = [...this.commonService.stores];
    }

    this.subscriptionListType = this.listTypeForm.valueChanges.subscribe(
      (value) => {
        if (value === "カメラ一覧") {
          this.getCameraListByStore(this.storeForm.value);
        }
      }
    );
    this.subscriptionStore = this.storeForm.valueChanges.subscribe(
      (value) => {
          this.getCameraListByStore(this.storeForm.value);
      }
    );
    this.subscriptionTab = this.tabForm.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.intervalForm.disable();
        } else {
          this.intervalForm.enable();
        }
      }
    );

    this.calcTableWidthLayout();
    this.calcTableWidthCamera();
    this.getCameraList();

    setTimeout(()=>{this.resized();}, 0);
    window.addEventListener("resize", this.resized);
  }

  ngOnDestroy() {
    if (this.subscriptionQuery) this.subscriptionQuery.unsubscribe();
    if (this.subscriptionListType) this.subscriptionListType.unsubscribe();
    if (this.subscriptionStore) this.subscriptionStore.unsubscribe();
    if (this.subscriptionTab) this.subscriptionTab.unsubscribe();
    if (this.subscriptionDialog) this.subscriptionDialog.unsubscribe();

    window.removeEventListener("resize", this.resized);
    this.closeAll();
    this.closeAllWindows();
  }

  ngAfterViewChecked() {
    this.resized();
  }

  resized() {
    let cameraContainerElement = document.getElementById("camera-container");
    let clientRect = cameraContainerElement.getBoundingClientRect();
    let scrollTop = clientRect.top;
    let scrollHeight = window.innerHeight - scrollTop - 45;
    cameraContainerElement.style["height"] = "" + scrollHeight + "px";
  }

  clearProgressState () {
    if (this.subscriptionQuery) this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    this.commonService.closeSpinner();
  }

  getCameraList() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "camera/getCameraDef",
      bindVariables: ["*"]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.executeQuery(request).subscribe(
      (response) => this.receiveCameraList(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveCameraList(response: RspExecuteQuery) {
    this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    // this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.cameraList = [];
    for (let item of response.rows) {
      let camera = new CameraDef(item);
      this.cameraList.push(camera);
    }

    this.getLayoutList();
  }

  getLayoutList() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "camera/getCameraLayout",
      bindVariables: []
    };

    // this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.executeQuery(request).subscribe(
      (response) => this.receiveLayoutList(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveLayoutList(response: RspExecuteQuery) {
    this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    // this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.layoutList = [];
    for (let item of response.rows) {
      let layout = new CameraLayout(item);
      this.layoutList.push(layout);
    }

    this.getLayoutConfigList();
  }

  getLayoutConfigList() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "camera/getCameraLayoutConfig",
      bindVariables: []
    };

    // this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.executeQuery(request).subscribe(
      (response) => this.receiveLayoutConfigList(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveLayoutConfigList(response: RspExecuteQuery) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.layoutConfigList = [];
    for (let item of response.rows) {
      let config = new CameraLayoutConfig(item);
      this.layoutConfigList.push(config);
    }

    this.buildLayoutTree();
  }

  findCamera(cameraCd: string) : CameraDef | null{
    for (let camera of this.cameraList) {
      if (camera.cameraCd === cameraCd) return camera;
    }

    return null;
  }

  findCameraConfig(layoutCd: number, x: number, y: number) : CameraLayoutConfig | null{
    for (let config of this.layoutConfigList) {
      if (config.layoutCd == layoutCd &&
        config.posX == x &&
        config.posY == y
        ) return config;
    }

    return null;
  }

  findCameraConfigInLayout(layout: CameraLayout, x: number, y: number) : CameraLayoutConfig | null{
    for (let config of layout.config) {
      if (config.posX == x && config.posY == y) return config;
    }

    return null;
  }

  buildLayoutTree() {
    for (let config of this.layoutConfigList) {
      config.camera = this.findCamera(config.cameraCd);
    }

    for (let layout of this.layoutList) {
      for (let y = 0; y < layout.countY; y++) {
        for (let x = 0; x < layout.countX; x++) {
          let config = this.findCameraConfig(layout.layoutCd, x, y);
          if (config == null) {
            let emptyConfig: ExecuteQueryRecDto = {
              colData: ["" + layout.layoutCd, "", "" + x, "" + y, "0", "0"]
            }
            config = new CameraLayoutConfig(emptyConfig);
          }
          layout.config.push(config);
        }
      }
    }
  }

  buildConfigMatrix() {
    this.layoutConfigMatrix = [];
    for (let y = 0; y < this.selectedLayout.countY; y++) {
      this.layoutConfigMatrix.push([]);
      for (let x = 0; x < this.selectedLayout.countX; x++) {
        this.layoutConfigMatrix[y].push(this.findCameraConfigInLayout(this.selectedLayout, x, y));
      }
    }
  }

  getCameraListByStore(storeCd: string) {
    if (storeCd == "*") {
      this.cameraListByStore = [...this.cameraList];
      return;
    }
    this.cameraListByStore = [];
    if (storeCd === "") return;
    for (let camera of this.cameraList) {
      if (camera.storeCd === storeCd)
      this.cameraListByStore.push(camera);
    }
  }

  getTitle(): string {
    if (this.selectedLayout) {
      return "レイアウト：" + this.selectedLayout.layoutName;
    }
    return "レイアウト：未選択";
  }

  calcTableWidthLayout() {
    var width = 1;                              // For left border
    for (var colDef of this.columnDefsLayout) {
      width = width + colDef.width + 1 + 8;     // 1 for right border, 8 for padding
    }
    this.tableWidthLayout = {"width": "" + width + "px"};
  }
  calcTableWidthCamera() {
    var width = 1;                              // For left border
    for (var colDef of this.columnDefsCamera) {
      width = width + colDef.width + 1 + 8;     // 1 for right border, 8 for padding
    }
    this.tableWidthCamera = {"width": "" + width + "px"};
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    }
  }

  styleForImageContainer(config: CameraLayoutConfig) {
    return {
      "width": "" + config.sizeX + "px",
      "height": "" + config.sizeY + "px"
    };
  }

  onClickCellLayout(layout: CameraLayout) {
    this.selectLayout(layout);
  }
  onClickCellCamera(camera: CameraDef) {
    this.openCamera(camera);
  }

  selectLayout(layout: CameraLayout) {
    if (this.selectedLayout && this.selectedLayout == layout) return;

    this.selectedLayout = layout;
    this.buildConfigMatrix();
    this.openLayout(layout);
  }

  /*
  layoutCamera() {
    this.openListList = [];
    let layoutWidth: number = 0;
    let list: CameraDef[] = [];

    for (let camera of this.openList) {
      if (layoutWidth + camera.sizeX + 10 > this.cameraContainerWidth) {
        if (layoutWidth == 0) {
          list.push(camera);
        }
        this.openListList.push(list);
        list = [];
        layoutWidth = 0;
      }
      layoutWidth += camera.sizeX + 10;
      list.push(camera);
    }
    if (list.length > 0) {
      this.openListList.push(list);
    }
    this.changeDetectorRef.detectChanges();
  }
  */

  openCamera(camera: CameraDef) {
    if(!this.tabForm.value) {
      this.openCameraDialog(camera);
      /*
      if (camera.isOpened) return;
      camera.isOpened = true;
      this.loadImage(camera);
      */
    } else {
      let win = window.open(camera.url, camera.storeCd+":"+camera.cameraCd);
      for (let opened of this.openWindows) {
        if (opened.storeCdFv === camera.storeCd &&
            opened.cameraCdFv === camera.cameraCd) return;
      }
      this.openWindows.push({
        storeCdFv: camera.storeCd,
        cameraCdFv: camera.cameraCd,
        win: win
      });
    }
  }

  openLayout(layout: CameraLayout) {
    this.closeAll();
    for (let config of layout.config) {
      this.openConfig(config);
    }
  }
  openConfig(config: CameraLayoutConfig) {
    if (!config.camera) return;
    let camera = config.camera;
    if (camera.isOpened) return;
    camera.isOpened = true;
    this.loadImage(camera);
  }

  closeCamera(camera: CameraDef) {
    /*
    for (let i = 0; i < this.openList.length; i++) {
      if (this.openList[i].storeCd === camera.storeCd &&
          this.openList[i].cameraCd === camera.cameraCd) {
            this.openList[i].isOpened = false;
            this.openList.splice(i, 1);
            break;
      }
    }
    */
    camera.isOpened = false;
    camera.imageUrl = undefined;
    camera.errorMsg = undefined;
    if (camera.timerId) {
      clearTimeout(camera.timerId);
      camera.timerId = undefined;
    }

    for (let i = 0; i < this.openWindows.length; i++) {
      if (this.openWindows[i].storeCdFv === camera.storeCd &&
        this.openWindows[i].cameraCdFv === camera.cameraCd) {
          this.openWindows[i].win.close();
          this.openWindows.splice(i, 1);
          break;
      }
    }
  }

  closeAll() {
    /*
    for (let i = 0; i < this.openList.length; i++) {
      this.openList[i].isOpened = false;
      if (this.openList[i].timerId) {
        clearTimeout(this.openList[i].timerId);
        this.openList[i].timerId = undefined;
      }
    }
    this.openList = [];
    */
    for (let camera of this.cameraList) {
      if (!camera.isOpened) continue;
      camera.isOpened = false;
      camera.imageUrl = undefined;
      camera.errorMsg = undefined;
      if (camera.timerId) {
        clearTimeout(camera.timerId);
        camera.timerId = undefined;
      }
    }

  }

  closeAllWindows() {
    for (let win of this.openWindows) {
      win.win.close();
    }
  }

  loadImage(camera: CameraDef): void {
    const url = camera.url;
    camera.isLoading = true;
    let subsc = this.http.get(url, { responseType: 'blob' }).subscribe(
      (val) => {
        subsc.unsubscribe();
        this.createImageFromBlob(camera, val);
      },
      (error) => {
        subsc.unsubscribe();
        camera.isLoading = false;
        camera.timerId = undefined;
        camera.imageUrl = undefined;

        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          camera.errorMsg = "Network/内部エラー：サーバーとの通信でエラーが発生しました。";
        } else if (error.error instanceof ProgressEvent) {
          // Timeout ?
          camera.errorMsg = "Network/内部エラー：サーバーとの通信でエラーが発生しました。";
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          camera.errorMsg = "サーバーエラー、エラー・ステータス：" + error.status;
        }
    
        this.startTimer(camera);
      }
    );
  }

  createImageFromBlob(camera: CameraDef, image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        camera.isLoading = false;
        if ((reader.result as string).length <= 5) {
          camera.imageUrl = undefined;
          camera.timerId = undefined;
          camera.errorMsg = "カメラ画像を取得できません。";
        } else {
          camera.imageUrl = reader.result;
          camera.timerId = undefined;
          camera.errorMsg = undefined;
        }
        this.startTimer(camera);
      },
      false
    );
    reader.addEventListener(
      'error', 
      () => {
        camera.isLoading = false;
        camera.timerId = undefined;
        camera.imageUrl = undefined;
        camera.errorMsg = "カメラ画像を取得できません。";
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  startTimer(camera: CameraDef) {
    if (!camera.isOpened) return;
    if (camera.timerId) {
      clearTimeout(camera.timerId);
      camera.timerId = undefined;
    }
    camera.timerId = setTimeout(
      ()=>{
        camera.timerId = undefined;
        this.loadImage(camera);
      },
      this.intervalForm.value);
  }

  openCameraDialog(camera: CameraDef) {
    if (!camera.isOpened) {
      camera.isOpened = true;
      this.loadImage(camera);
    }
    const dialogRef = this.commonService.dialog.open(CameraDialogComponent, {
      disableClose: false,
      data: camera,
      maxWidth: "95vw",
      position: {top: "5px"}
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => this.afterCameraDialog(camera)
    );
  }

  afterCameraDialog(dialogCamera: CameraDef) {
    this.subscriptionDialog.unsubscribe();
    this.subscriptionDialog = undefined;
    if (this.selectedLayout) {
      for (let config of this.selectedLayout.config) {
        if (config.camera && config.camera.cameraCd === dialogCamera.cameraCd) return;  // opened by layout
      }
    }
    // Not opened in layout
    this.closeCamera(dialogCamera);
  }

}
