import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';

export interface ErrorNotificationData {
  errorTitle: string;
  errorMessage: string;
  btnText?: string;
  storeCd: string;
  productCd: string;
  inventoryId: string;
  inventoryMonth: string;
  inventoryProcessStatus: string;
};

@Component({
  selector: 'app-spiv00101-error-notification-dialog',
  templateUrl: './spiv00101-error-notification-dialog.component.html',
  styleUrls: ['./spiv00101-error-notification-dialog.component.css']
})
export class Spiv00101ErrorNotificationDialogComponent implements OnInit {

  public btnText: string = "閉じる";

  constructor(
    public dialogRef: MatDialogRef<Spiv00101ErrorNotificationDialogComponent>,
    public commonService: CommonService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ErrorNotificationData) { }

  ngOnInit() {

  }

  goInventoryInput() {
    this.router.navigate(
      ['/SPIV00051'],
    );
  }
}
