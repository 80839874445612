import { RspParamCtgDto } from '../response/rsp-param-ctg-search';

export class CtgParamRec {
    storeCdFv: string;
    storeNameFv?: string;
    ctgLevelFn: number;
    ctgCd0Fv: string;
    ctgCd1Fv: string;
    ctgCd2Fv: string;
    ctgCd3Fv: string;
    ctgNameFv: string;
    rankFv: string;
    minZaiFn: number;
    minZaiModFn: number;
    minZaiVersionFn: number;
    maxZaiFn: number;
    maxZaiModFn: number;
    maxZaiVersionFn: number;
    minStockDaysFn: number;
    minStockDaysModFn: number;
    minStockDaysVersionFn: number;
    maxStockDaysFn: number;
    maxStockDaysModFn: number;
    maxStockDaysVersionFn: number;

    minZaiCurrent: number;
    minZaiUpdated: boolean;
    maxZaiCurrent: number;
    maxZaiUpdated: boolean;
    minStockDaysCurrent: number;
    minStockDaysUpdated: boolean;
    maxStockDaysCurrent: number;
    maxStockDaysUpdated: boolean;
    autoOrderLotModFn?: string;

    constructor(paramCtgDto: RspParamCtgDto) {
        for (var key of Object.keys(paramCtgDto)) {
            this[key] = paramCtgDto[key];
        }

        if (this.minZaiVersionFn > 0) {
            this.minZaiCurrent = this.minZaiModFn;
            this.minZaiUpdated = true;
        } else {
            this.minZaiCurrent = this.minZaiFn;
            this.minZaiUpdated = false;
        }
        if (this.maxZaiVersionFn > 0) {
            this.maxZaiCurrent = this.maxZaiModFn;
            this.maxZaiUpdated = true;
        } else {
            this.maxZaiCurrent = this.maxZaiFn;
            this.maxZaiUpdated = false;
        }

        if (this.minStockDaysVersionFn > 0) {
            this.minStockDaysCurrent = this.minStockDaysModFn;
            this.minStockDaysUpdated = true;
        } else {
            this.minStockDaysCurrent = this.minStockDaysFn;
            this.minStockDaysUpdated = false;
        }
        if (this.maxStockDaysVersionFn > 0) {
            this.maxStockDaysCurrent = this.maxStockDaysModFn;
            this.maxStockDaysUpdated = true;
        } else {
            this.maxStockDaysCurrent = this.maxStockDaysFn;
            this.maxStockDaysUpdated = false;
        }
    }

    updMinZai(val: number, version: number) {
        if (version > 0) {
            this.minZaiModFn = val;
            this.minZaiVersionFn = version;
            this.minZaiCurrent = this.minZaiModFn;
            this.minZaiUpdated = true;
        }
    }

    updMaxZai(val: number, version: number) {
        if (version > 0) {
            this.maxZaiModFn = val;
            this.maxZaiVersionFn = version;
            this.maxZaiCurrent = this.maxZaiModFn;
            this.maxZaiUpdated = true;
        }
    }

    updMinStockDays(val: number, version: number) {
        if (version > 0) {
            this.minStockDaysModFn = val;
            this.minStockDaysVersionFn = version;
            this.minStockDaysCurrent = this.minStockDaysModFn;
            this.minStockDaysUpdated = true;
        }
    }

    updMaxStockDays(val: number, version: number) {
        if (version > 0) {
            this.maxStockDaysModFn = val;
            this.maxStockDaysVersionFn = version;
            this.maxStockDaysCurrent = this.maxStockDaysModFn;
            this.maxStockDaysUpdated = true;
        }
    }
}
