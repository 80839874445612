import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/service/common.service";

export class RoleMenu {
  public roleId: number;
  public menuGroupId: number;
  public menuGroupName: string;
  public menuId: number;
  public menuName: string;
  public menuReadonlyControl: boolean;
  public menuReadonly: boolean;
  public roleReadonly: boolean;
  public isSelected: boolean;
  public menuPath: string;
  public ctgListener: ()=> void;
  public itemListener: ()=> void;

  public edit: {
    formSelect: FormControl;
    formReadOnly: FormControl;
  };

  private subsc: Subscription[] = [];

  constructor(private commonService: CommonService) {
  }

  prepareEdit() {
    if (this.edit) this.clearEdit();

    this.edit = {
      formSelect: new FormControl(this.isSelected),
      formReadOnly: new FormControl(this.roleReadonly)
    }
    this.subsc.push(
      this.edit.formSelect.valueChanges.subscribe((val) => {this.selectChanged(val);})
    );
    this.subsc.push(
      this.edit.formReadOnly.valueChanges.subscribe((val) => {this.readOnlyChanged(val);})
    );
  }

  setCtgListener(func: ()=> void) {
    this.ctgListener = func;
  }

  setItemListener(func: ()=> void) {
    this.itemListener = func;
  }

  cancelEdit() {
    if (!this.edit) return;
    this.clearEdit();
    this.prepareEdit();
  }

  clearEdit() {
    if (!this.edit) return;
    this.subsc.forEach((subsc) => subsc.unsubscribe());
    this.subsc = [];
    delete this.edit;
  }

  saveEdit() {
    this.isSelected = this.edit.formSelect.value;
    this.roleReadonly = this.edit.formReadOnly.value;
  }

  selectChanged(val) {
    if (this.ctgListener) {
      if (this.menuPath == "ctgparam") {
        this.ctgListener();
      } else if (this.menuPath == "itemparam") {
        this.itemListener();
      }
    }
  }

  readOnlyChanged(val) {
    if (this.ctgListener) {
      if (this.menuPath == "ctgparam") {
        this.ctgListener();
      } else if (this.menuPath == "itemparam") {
        this.itemListener();
      }
    }
  }
}
