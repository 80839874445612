import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild, ElementRef, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from 'src/app/dialog/spinner-dialog/spinner-dialog.component';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { AnalysisContext } from '../analysis-context';
import { AnalysisViewStandardComponent } from '../view/analysis-view-standard/analysis-view-standard.component';
import { CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { GraphService } from 'src/app/service/graph.service';

export interface ViewSize {
  width: number;
  height: number;
}

@Component({
  selector: 'app-analysis-main',
  templateUrl: './analysis-main.component.html',
  styleUrls: ['./analysis-main.component.css']
})
export class AnalysisMainComponent implements OnInit, OnDestroy, AfterViewChecked {

  public isReadonly: boolean = true;

  private viewMainContainerElement: HTMLElement;

  public viewMainTop: number;
  public viewMainHeight: number;
  public viewMainWidth: number;
  public viewWidthTopAuto: boolean = true;     // For future enhancement. Now, only true is available.
  public viewWidthBottomAuto: boolean = true;  // For future enhancement. Now, only true is available.
  public viewHeightAuto: boolean = true;       // For future enhancement. Now, only true is available.
  public viewSubSize: {
    Top: ViewSize;
    Bottom: ViewSize;
  } = {
    Top: {width: 0, height: 0},
    Bottom: {width: 0, height: 0}
  }
  public viewContainerSize: {
    LT: ViewSize;
    RT: ViewSize;
    LB: ViewSize;
    RB: ViewSize;
  } = {
    LT: {width: 0, height: 0},
    RT: {width: 0, height: 0},
    LB: {width: 0, height: 0},
    RB: {width: 0, height: 0}
  }

  private subscriptionAnalysisType: Subscription;
  private subscriptionAnalysisName: Subscription;
  private subscriptionViewSwitchLT: Subscription;
  private subscriptionViewSwitchRT: Subscription;
  private subscriptionViewSwitchLB: Subscription;
  private subscriptionViewSwitchRB: Subscription;

  private maxContextNo: number = 0;
  public contexts: AnalysisContext[] = [];
  public selectedContext: AnalysisContext;

  public formAnalysisType: FormControl = new FormControl("");
  public formAnalysisName: FormControl = new FormControl("");
  public formViewSwitchLT: FormControl = new FormControl();
  public formViewSwitchRT: FormControl = new FormControl();
  public formViewSwitchLB: FormControl = new FormControl();
  public formViewSwitchRB: FormControl = new FormControl();

  public viewShowLT: boolean;
  public viewShowRT: boolean;
  public viewShowLB: boolean;
  public viewShowRB: boolean;

  @ViewChild("viewLT", {static:false}) viewComponentLT: AnalysisViewStandardComponent;
  @ViewChild("viewRT", {static:false}) viewComponentRT: AnalysisViewStandardComponent;
  @ViewChild("viewLB", {static:false}) viewComponentLB: AnalysisViewStandardComponent;
  @ViewChild("viewRB", {static:false}) viewComponentRB: AnalysisViewStandardComponent;

  /* For Demo */

  public demo1 = {
    analysisNo: 1,
    analysisType: "販売数推移",
    name: "1:築地店 販売数推移",
    status: "queried",
    condition: {beginDate:"2019/12/01", endDate:"2020/03/08"},
    listData: [
      {date: "2019/12/01", sales: 20583},
      {date: "2019/12/08", sales: 20594},
      {date: "2019/12/15", sales: 20592},
      {date: "2019/12/22", sales: 19790},
      {date: "2019/12/29", sales: 18422},
      {date: "2020/01/05", sales: 21277},
      {date: "2020/01/12", sales: 19610},
      {date: "2020/01/19", sales: 21683},
      {date: "2020/01/26", sales: 20915},
      {date: "2020/02/02", sales: 21463},
      {date: "2020/02/09", sales: 21147},
      {date: "2020/02/16", sales: 21841},
      {date: "2020/02/23", sales: 24327},
      {date: "2020/03/01", sales: 22356},
      {date: "2020/03/08", sales: 23084}
    ],
    listColDef: [
      { columnId: "date", header: "日付", width: 70, headerWidth: 40, dataWidth: 70, align: "center" },
      { columnId: "sales", header: "販売数", width: 60, headerWidth: 60, dataWidth: 40, align: "right", numberPipe: ""}
    ],
    listDisplayColumn: ["date", "sales"],
    dimension: 1,
    matrixTypes: [{id: "マトリックス", name: "マトリックス", xAxisCol: "date", rowHeaderCols: [], dataHeaderCols: ["sales"]}],
    graphOption: {
      graphType: GraphService.GRAPH_TYPE_LINE,
      showLabels: true,
      animations: false,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: true,
      showXAxisLabel: true,
      xAxisLabel: "日付",
      yAxisLabel: "販売数",
      legend: false,
      legendTitle: "凡例",
      timeline: false,
      autoScale: true,
      yScaleMax: undefined,
      gradient: false,
      roundDomains: true,
      trimYAxisTicks: false,
      labels: true
    }
  };

  public demo2 = {
    analysisNo: 2,
    analysisType: "在庫数推移",
    name: "店舗別 販売・在庫推移",
    status: "queried",
    condition: {beginDate:"2019/12/01", endDate:"2020/03/08"},
    listData: [
      {date: "2019/12/01", store: "1:築地店", sales: 6561, stocks: 20583},
      {date: "2019/12/08", store: "1:築地店", sales: 6178, stocks: 20594},
      {date: "2019/12/15", store: "1:築地店", sales: 6342, stocks: 20592},
      {date: "2019/12/22", store: "1:築地店", sales: 6990, stocks: 19790},
      {date: "2019/12/29", store: "1:築地店", sales: 7225, stocks: 18422},
      {date: "2020/01/05", store: "1:築地店", sales: 8551, stocks: 21277},
      {date: "2020/01/12", store: "1:築地店", sales: 7371, stocks: 19610},
      {date: "2020/01/19", store: "1:築地店", sales: 8395, stocks: 21683},
      {date: "2020/01/26", store: "1:築地店", sales: 9353, stocks: 20915},
      {date: "2020/02/02", store: "1:築地店", sales: 7728, stocks: 21463},
      {date: "2020/02/09", store: "1:築地店", sales: 7304, stocks: 21147},
      {date: "2020/02/16", store: "1:築地店", sales: 7613, stocks: 21841},
      {date: "2020/02/23", store: "1:築地店", sales: 7626, stocks: 24327},
      {date: "2020/03/01", store: "1:築地店", sales: 7179, stocks: 22356},
      {date: "2020/03/08", store: "1:築地店", sales: 7691, stocks: 23084},
      {date: "2019/12/01", store: "2:一番町店", sales: 7178, stocks: 20262},
      {date: "2019/12/08", store: "2:一番町店", sales: 7009, stocks: 20415},
      {date: "2019/12/15", store: "2:一番町店", sales: 7007, stocks: 20500},
      {date: "2019/12/22", store: "2:一番町店", sales: 7069, stocks: 18957},
      {date: "2019/12/29", store: "2:一番町店", sales: 6467, stocks: 14357},
      {date: "2020/01/05", store: "2:一番町店", sales: 8415, stocks: 21760},
      {date: "2020/01/12", store: "2:一番町店", sales: 7856, stocks: 19180},
      {date: "2020/01/19", store: "2:一番町店", sales: 8184, stocks: 21976},
      {date: "2020/01/26", store: "2:一番町店", sales: 7775, stocks: 21025},
      {date: "2020/02/02", store: "2:一番町店", sales: 8478, stocks: 20911},
      {date: "2020/02/09", store: "2:一番町店", sales: 7909, stocks: 21065},
      {date: "2020/02/16", store: "2:一番町店", sales: 7915, stocks: 21080},
      {date: "2020/02/23", store: "2:一番町店", sales: 9407, stocks: 22069},
      {date: "2020/03/01", store: "2:一番町店", sales: 8400, stocks: 20546},
      {date: "2020/03/08", store: "2:一番町店", sales: 8744, stocks: 21846}
    ],
    listColDef: [
      { columnId: "date",   header: "日付",    width: 70,  headerWidth: 40, dataWidth: 70,  align: "center" },
      { columnId: "store",  header: "店舗",    width: 100, headerWidth: 40, dataWidth: 100, align: "left"},
      { columnId: "sales",  header: "販売数",  width: 60,  headerWidth: 60, dataWidth: 40,  align: "right", numberPipe: ""},
      { columnId: "stocks", header: "在庫数",  width: 60,  headerWidth: 60, dataWidth: 40,  align: "right", numberPipe: "" }
    ],
    listDisplayColumn: ["date", "store", "sales", "stocks"],
    dimension: 2,
    matrixTypes: [
      {id: "推移-販売・在庫", name: "推移-販売・在庫", xAxisCol: "date", rowHeaderCols: ["store"], dataHeaderCols: ["sales", "stocks"],
        graphType: GraphService.GRAPH_TYPE_GROUPED_VERTICAL_BAR, yAxisLabel: "販売数・在庫数"},
      {id: "推移-販売",      name: "推移-販売",       xAxisCol: "date", rowHeaderCols: ["store"], dataHeaderCols: ["sales"], yAxisLabel: "販売数"},
      {id: "推移-在庫",      name: "推移-在庫",       xAxisCol: "date", rowHeaderCols: ["store"], dataHeaderCols: ["stocks"], yAxisLabel: "在庫数"}
    ],
    graphOption: {
      graphType: GraphService.GRAPH_TYPE_GROUPED_VERTICAL_BAR,
      showLabels: true,
      animations: false,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: true,
      showXAxisLabel: true,
      xAxisLabel: "日付",
      yAxisLabel: "販売・在庫数",
      legend: true,
      legendTitle: "凡例",
      timeline: false,
      autoScale: true,
      yScaleMax: undefined,
      gradient: false,
      roundDomains: true,
      trimYAxisTicks: false,
      labels: true
    }
  };

  public demo3 = {
    analysisNo: 3,
    analysisType: "欠品推移",
    name: "1:築地店 欠品推移",
    status: "queried",
    condition: {beginDate:"2019/12/01", endDate:"2020/03/08"},
    listData: [
      {date: "2019/12/01", soldout: 16},
      {date: "2019/12/08", soldout: 7},
      {date: "2019/12/15", soldout: 11},
      {date: "2019/12/22", soldout: 8},
      {date: "2019/12/29", soldout: 10},
      {date: "2020/01/05", soldout: 7},
      {date: "2020/01/12", soldout: 5},
      {date: "2020/01/19", soldout: 7},
      {date: "2020/01/26", soldout: 7},
      {date: "2020/02/02", soldout: 10},
      {date: "2020/02/09", soldout: 7},
      {date: "2020/02/16", soldout: 8},
      {date: "2020/02/23", soldout: 11},
      {date: "2020/03/01", soldout: 11},
      {date: "2020/03/08", soldout: 18}
    ],
    listColDef: [
      { columnId: "date", header: "日付", width: 70, headerWidth: 40, dataWidth: 70, align: "center" },
      { columnId: "soldout", header: "欠品数", width: 60, headerWidth: 60, dataWidth: 40, align: "right", numberPipe: ""}
    ],
    listDisplayColumn: ["date", "soldout"],
    dimension: 1,
    matrixTypes: [{id: "マトリックス", name: "マトリックス", xAxisCol: "date", rowHeaderCols: [], dataHeaderCols: ["soldout"]}],
    graphOption: {
      graphType: GraphService.GRAPH_TYPE_LINE,
      showLabels: true,
      animations: false,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: true,
      showXAxisLabel: true,
      xAxisLabel: "日付",
      yAxisLabel: "欠品数",
      legend: false,
      legendTitle: "凡例",
      timeline: false,
      autoScale: false,
      yScaleMax: undefined,
      gradient: false,
      roundDomains: true,
      trimYAxisTicks: false,
      labels: true
    }
  };

  public demo4 = {
    analysisNo: 4,
    analysisType: "一致率推移",
    name: "1:築地店 一致率推移",
    status: "queried",
    condition: {beginDate:"2019/12/01", endDate:"2020/03/08"},
    listData: [
      {date: "2020/11/02", add: 14.4, up: 0.3, match: 83.9, down: 0.3, del: 1.1, addcount: 51, upcount: 1, matchcount: 296, downcount: 1, delcount: 4},
      {date: "2020/11/09", add: 18, up: 0.6, match: 79, down: 0, del: 2.4, addcount: 60, upcount: 2, matchcount: 263, downcount: 0, delcount: 8},
      {date: "2020/11/16", add: 5.8, up: 0, match: 91.9, down: 0, del: 2.3, addcount: 20, upcount: 0, matchcount: 317, downcount: 0, delcount: 8},
      {date: "2020/11/23", add: 11.6, up: 0, match: 83.3, down: 0, del: 5.1, addcount: 43, upcount: 0, matchcount: 309, downcount: 0, delcount: 19},
      {date: "2020/11/30", add: 9.7, up: 0, match: 82.7, down: 0, del: 7.7, addcount: 29, upcount: 0, matchcount: 248, downcount: 0, delcount: 23},
      {date: "2020/12/07", add: 5.5, up: 0, match: 90.7, down: 0, del: 3.9, addcount: 17, upcount: 0, matchcount: 282, downcount: 0, delcount: 12},
      {date: "2020/12/14", add: 9.5, up: 0.6, match: 84.2, down: 0.3, del: 5.4, addcount: 32, upcount: 2, matchcount: 283, downcount: 1, delcount: 18}
    ],
    listColDef: [
      { columnId: "date", header: "日付", width: 70, headerWidth: 40, dataWidth: 70, align: "center" },
      { columnId: "add", header: "追加率", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: "1.1-1"},
      { columnId: "up", header: "上方率", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: "1.1-1"},
      { columnId: "match", header: "一致率", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: "1.1-1"},
      { columnId: "down", header: "下方率", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: "1.1-1"},
      { columnId: "del", header: "削除率", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: "1.1-1"},
      { columnId: "addcount", header: "追加", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: ""},
      { columnId: "upcount", header: "上方", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: ""},
      { columnId: "matchcount", header: "一致", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: ""},
      { columnId: "downcount", header: "下方", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: ""},
      { columnId: "delcount", header: "削除", width: 40, headerWidth: 40, dataWidth: 30, align: "right", numberPipe: ""}
    ],
    listDisplayColumn: ["date", "add", "up", "match", "down", "del", "addcount", "upcount", "matchcount", "downcount", "delcount"],
    dimension: 1,
    matrixTypes: [
      {id: "推移-率", name: "推移-率", xAxisCol: "date", rowHeaderCols: [], dataHeaderCols: ["add", "up", "match", "down", "del"],
        graphType:GraphService.GRAPH_TYPE_GROUPED_VERTICAL_BAR, yAxisLabel: "率"},
      {id: "推移-件数", name: "推移-件数", xAxisCol: "date", rowHeaderCols: [], dataHeaderCols: ["addcount", "upcount", "matchcount", "downcount", "delcount"],
        graphType:GraphService.GRAPH_TYPE_GROUPED_VERTICAL_BAR, yAxisLabel: "件数"},
    ],
    graphOption: {
      graphType: GraphService.GRAPH_TYPE_GROUPED_VERTICAL_BAR,
      showLabels: true,
      animations: false,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: true,
      showXAxisLabel: true,
      xAxisLabel: "日付",
      yAxisLabel: "率",
      legend: true,
      legendTitle: "凡例",
      timeline: false,
      autoScale: true,
      yScaleMax: undefined,
      gradient: false,
      roundDomains: true,
      trimYAxisTicks: false,
      labels: true
    }
  };

  public demo5 = {
    analysisNo: 5,
    analysisType: "販売数推移",
    name: "2:一番町店 販売数推移",
    status: "queried",
    condition: {beginDate:"2019/12/01", endDate:"2020/03/08"},
    listData: [
      {date: "2019/12/01", sales: 20262},
      {date: "2019/12/08", sales: 20415},
      {date: "2019/12/15", sales: 20500},
      {date: "2019/12/22", sales: 18957},
      {date: "2019/12/29", sales: 14357},
      {date: "2020/01/05", sales: 21760},
      {date: "2020/01/12", sales: 19180},
      {date: "2020/01/19", sales: 21976},
      {date: "2020/01/26", sales: 21025},
      {date: "2020/02/02", sales: 20911},
      {date: "2020/02/09", sales: 21065},
      {date: "2020/02/16", sales: 21080},
      {date: "2020/02/23", sales: 22069},
      {date: "2020/03/01", sales: 20546},
      {date: "2020/03/08", sales: 21846}
    ],
    listColDef: [
      { columnId: "date", header: "日付", width: 70, headerWidth: 40, dataWidth: 70, align: "center" },
      { columnId: "sales", header: "販売数", width: 60, headerWidth: 60, dataWidth: 40, align: "right", numberPipe: ""}
    ],
    listDisplayColumn: ["date", "sales"],
    dimension: 1,
    matrixTypes: [{id: "マトリックス", name: "マトリックス", xAxisCol: "date", rowHeaderCols: [], dataHeaderCols: ["sales"]}],
    graphOption: {
      graphType: GraphService.GRAPH_TYPE_LINE,
      showLabels: true,
      animations: false,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: true,
      showXAxisLabel: true,
      xAxisLabel: "日付",
      yAxisLabel: "販売数",
      legend: false,
      legendTitle: "凡例",
      timeline: false,
      autoScale: true,
      yScaleMax: undefined,
      gradient: false,
      roundDomains: true,
      trimYAxisTicks: false,
      labels: true
    }
  };

  public demo6 = {
    analysisNo: 6,
    analysisType: "在庫数推移",
    name: "2:一番町店 在庫数推移",
    status: "queried",
    condition: {beginDate:"2019/12/01", endDate:"2020/03/08"},
    listData: [
      {date: "2019/12/01", stocks: 7178},
      {date: "2019/12/08", stocks: 7009},
      {date: "2019/12/15", stocks: 7007},
      {date: "2019/12/22", stocks: 7069},
      {date: "2019/12/29", stocks: 6467},
      {date: "2020/01/05", stocks: 8415},
      {date: "2020/01/12", stocks: 7856},
      {date: "2020/01/19", stocks: 8184},
      {date: "2020/01/26", stocks: 7775},
      {date: "2020/02/02", stocks: 8478},
      {date: "2020/02/09", stocks: 7909},
      {date: "2020/02/16", stocks: 7915},
      {date: "2020/02/23", stocks: 9407},
      {date: "2020/03/01", stocks: 8400},
      {date: "2020/03/08", stocks: 8744}      
    ],
    listColDef: [
      { columnId: "date", header: "日付", width: 70, headerWidth: 40, dataWidth: 70, align: "center" },
      { columnId: "stocks", header: "在庫数", width: 60, headerWidth: 60, dataWidth: 40, align: "right", numberPipe: ""}
    ],
    listDisplayColumn: ["date", "stocks"],
    dimension: 1,
    matrixTypes: [{id: "マトリックス", name: "マトリックス", xAxisCol: "date", rowHeaderCols: [], dataHeaderCols: ["stocks"]}],
    graphOption: {
      graphType: GraphService.GRAPH_TYPE_LINE,
      showLabels: true,
      animations: false,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: true,
      showXAxisLabel: true,
      xAxisLabel: "日付",
      yAxisLabel: "在庫数",
      legend: true,
      legendTitle: "凡例",
      timeline: false,
      autoScale: true,
      yScaleMax: undefined,
      gradient: false,
      roundDomains: true,
      trimYAxisTicks: false,
      labels: true
    }
  };

  public demo7 = {
    analysisNo: 7,
    analysisType: "欠品推移",
    name: "2:一番町店 欠品推移",
    status: "queried",
    condition: {beginDate:"2019/12/01", endDate:"2020/03/08"},
    listData: [
      {date: "2019/12/01", soldout: 15},
      {date: "2019/12/08", soldout: 7},
      {date: "2019/12/15", soldout: 12},
      {date: "2019/12/22", soldout: 16},
      {date: "2019/12/29", soldout: 21},
      {date: "2020/01/05", soldout: 8},
      {date: "2020/01/12", soldout: 6},
      {date: "2020/01/19", soldout: 9},
      {date: "2020/01/26", soldout: 8},
      {date: "2020/02/02", soldout: 10},
      {date: "2020/02/09", soldout: 8},
      {date: "2020/02/16", soldout: 9},
      {date: "2020/02/23", soldout: 9},
      {date: "2020/03/01", soldout: 10},
      {date: "2020/03/08", soldout: 19}
    ],
    listColDef: [
      { columnId: "date", header: "日付", width: 70, headerWidth: 40, dataWidth: 70, align: "center" },
      { columnId: "soldout", header: "欠品数", width: 60, headerWidth: 60, dataWidth: 40, align: "right", numberPipe: ""}
    ],
    listDisplayColumn: ["date", "soldout"],
    dimension: 1,
    matrixTypes: [{id: "マトリックス", name: "マトリックス", xAxisCol: "date", rowHeaderCols: [], dataHeaderCols: ["soldout"]}],
    graphOption: {
      graphType: GraphService.GRAPH_TYPE_LINE,
      showLabels: true,
      animations: false,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: true,
      showXAxisLabel: true,
      xAxisLabel: "日付",
      yAxisLabel: "欠品数",
      legend: false,
      legendTitle: "凡例",
      timeline: false,
      autoScale: true,
      yScaleMax: undefined,
      gradient: false,
      roundDomains: true,
      trimYAxisTicks: false,
      labels: true
    }
  };

  public demo8 = {
    analysisNo: 8,
    analysisType: "一致率推移",
    name: "2:一番町店 一致率推移",
    status: "queried",
    condition: {beginDate:"2019/12/01", endDate:"2020/03/08"},
    listData: [
      {date: "2019/12/01", matchrate: 88},
      {date: "2019/12/08", matchrate: 75},
      {date: "2019/12/15", matchrate: 83},
      {date: "2019/12/22", matchrate: 87},
      {date: "2019/12/29", matchrate: 65},
      {date: "2020/01/05", matchrate: 78},
      {date: "2020/01/12", matchrate: 85},
      {date: "2020/01/19", matchrate: 82},
      {date: "2020/01/26", matchrate: 88},
      {date: "2020/02/02", matchrate: 79},
      {date: "2020/02/09", matchrate: 81},
      {date: "2020/02/16", matchrate: 78},
      {date: "2020/02/23", matchrate: 81},
      {date: "2020/03/01", matchrate: 33},
      {date: "2020/03/08", matchrate: 56}
    ],
    listColDef: [
      { columnId: "date", header: "日付", width: 70, headerWidth: 40, dataWidth: 70, align: "center" },
      { columnId: "matchrate", header: "一致率", width: 60, headerWidth: 60, dataWidth: 40, align: "right", numberPipe: ""}
    ],
    listDisplayColumn: ["date", "matchrate"],
    dimension: 1,
    matrixTypes: [{id: "マトリックス", name: "マトリックス", xAxisCol: "date", rowHeaderCols: [], dataHeaderCols: ["matchrate"]}],
    graphOption: {
      graphType: GraphService.GRAPH_TYPE_LINE,
      showLabels: true,
      animations: false,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: true,
      showXAxisLabel: true,
      xAxisLabel: "日付",
      yAxisLabel: "一致率",
      legend: true,
      legendTitle: "凡例",
      timeline: false,
      autoScale: false,
      yScaleMax: 100,
      gradient: false,
      roundDomains: true,
      trimYAxisTicks: false,
      labels: true
    }
  };

  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public dateBegin: FormControl = new FormControl();
  public dateEnd: FormControl = new FormControl();

  convert(indata: Object) : AnalysisContext {
    let context = new AnalysisContext(indata["analysisNo"]);
    for (let key in indata) {
      context[key] = indata[key];
    }
    context.listData = [...indata["listData"]];
    context.listColDef = [...indata["listColDef"]];
    context.listDisplayColumn = [...indata["listDisplayColumn"]];
    return context;
  }

  demoGetStore() {
    if (!this.selectedContext) return "";
    return this.selectedContext.name.split(" ")[0];
  }

  demoSetDate(beginDate: string, endDate: string) {
    if (!this.selectedContext) return "";
    this.dateBegin.setValue(this.commonService.getDate(beginDate));
    this.dateEnd.setValue(this.commonService.getDate(endDate));
  }

  dateShift(dates : number) {
    if (dates > 0) {
      let e = this.dateEnd.value as Date;
      e.setDate(e.getDate() + dates);
      this.dateEnd.setValue(e);
      let b = this.dateBegin.value as Date;
      b.setDate(b.getDate() + dates);
      this.dateBegin.setValue(b);
    } else {
      let b = this.dateBegin.value as Date;
      b.setDate(b.getDate() + dates);
      this.dateBegin.setValue(b);
      let e = this.dateEnd.value as Date;
      e.setDate(e.getDate() + dates);
      this.dateEnd.setValue(e);
    }
    this.dateBegin.markAsDirty();
    this.dateEnd.markAsDirty();
  }

  prevYear() {
    this.dateShift(-364);
  }
  prevMonth() {
    this.dateShift(-28);
  }
  prevWeek() {
    this.dateShift(-7);
  }
  nextYear() {
    this.dateShift(364);
  }
  nextMonth() {
    this.dateShift(28);
  }
  nextWeek() {
    this.dateShift(7);
  }




  /* For Demo End */

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef) {

    // this.ctgSelectCondition = new CtgSelectCondition(this.fb);
  }

  ngOnInit(): void {
    // this.commonService.pageTitle = "分析";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("analysis");
    window.addEventListener("resize", ()=>{this.viewMainContainerSize();});

    this.formViewSwitchLT.setValue(true);
    this.formViewSwitchRT.setValue(false);
    this.formViewSwitchLB.setValue(false);
    this.formViewSwitchRB.setValue(false);
    this.viewUpdateVisiblity();
  
    this.subscriptionAnalysisType = this.formAnalysisType.valueChanges.subscribe((val)=> {this.analysisTypeChanged(val);});
    this.subscriptionAnalysisName = this.formAnalysisName.valueChanges.subscribe((val)=> {this.analysisNameChanged(val);});
    this.subscriptionViewSwitchLT = this.formViewSwitchLT.valueChanges.subscribe((sw:boolean)=>{this.viewSwitchControl(sw, 'LT');})
    this.subscriptionViewSwitchRT = this.formViewSwitchRT.valueChanges.subscribe((sw:boolean)=>{this.viewSwitchControl(sw, 'RT');})
    this.subscriptionViewSwitchLB = this.formViewSwitchLB.valueChanges.subscribe((sw:boolean)=>{this.viewSwitchControl(sw, 'LB');})
    this.subscriptionViewSwitchRB = this.formViewSwitchRB.valueChanges.subscribe((sw:boolean)=>{this.viewSwitchControl(sw, 'RB');})

    // For Demo this.addContext();

    /* For Demo start */
    this.contexts.push(this.convert(this.demo1));
    this.contexts.push(this.convert(this.demo2));
    this.contexts.push(this.convert(this.demo3));
    this.contexts.push(this.convert(this.demo4));
    this.contexts.push(this.convert(this.demo5));
    this.contexts.push(this.convert(this.demo6));
    this.contexts.push(this.convert(this.demo7));
    this.contexts.push(this.convert(this.demo8));
    /* For Demo end */

    this.selectContext(this.contexts[0]);
    setTimeout(()=>{this.viewMainContainerSize();}, 0);
  }

  ngOnDestroy() {
    window.removeEventListener("resize", ()=>{this.viewMainContainerSize();});

    this.unsubsc(this.subscriptionAnalysisType);
    this.unsubsc(this.subscriptionAnalysisName);
    this.unsubsc(this.subscriptionViewSwitchLT);
    this.unsubsc(this.subscriptionViewSwitchRT);
    this.unsubsc(this.subscriptionViewSwitchLB);
    this.unsubsc(this.subscriptionViewSwitchRB);
  

  }

  unsubsc(subsc: Subscription) {
    if (subsc) subsc.unsubscribe();
  }

  ngAfterViewChecked() {
    // this.viewMainContainerSize();
  }

  onSectionOpen() {
    this.viewMainContainerSize();
  }
  onSectionClose() {
    this.viewMainContainerSize();
  }

  viewUpdateVisiblity() {
    this.viewShowLT = this.formViewSwitchLT.value;
    this.viewShowRT = this.formViewSwitchRT.value;
    this.viewShowLB = this.formViewSwitchLB.value;
    this.viewShowRB = this.formViewSwitchRB.value;
  }

  viewMainContainerSize() {
    this.viewMainContainerElement = document.getElementById("view-main-container");
    let bodyElement = document.getElementsByTagName("body")[0];
    let clientRect = this.viewMainContainerElement.getBoundingClientRect();
    this.viewMainTop = clientRect.top;
    // this.viewMainWidth = window.innerWidth - 5 * 2 - 4;   // 4 for app.conponent margin
    this.viewMainWidth = bodyElement.clientWidth - 5 * 2 - 4;   // 4 for app.conponent margin
    this.viewMainHeight = window.innerHeight - this.viewMainTop - 37;

    this.viewMainContainerElement.style["height"] = "" + this.viewMainHeight + "px";

    this.viewCalcContainerSize();
    // this.cd.detectChanges();
  }

  viewSubIsVisible(id: string) {
    if (id == "Top") {
      return (this.formViewSwitchLT.value || this.formViewSwitchRT.value)
    } else {
      return (this.formViewSwitchLB.value || this.formViewSwitchRB.value)
    }
  }

  viewCalcContainerSize() {
    let width = this.viewMainWidth;
    if (this.formViewSwitchLT.value && this.formViewSwitchRT.value) {
      width = Math.floor((width - 5) / 2);          // 5 for margin
      this.viewContainerSize["LT"].width = width;
      this.viewContainerSize["RT"].width = width;
    } else if (this.formViewSwitchLT.value) {
      this.viewContainerSize["LT"].width = width;
      this.viewContainerSize["RT"].width = 0;
    } else if (this.formViewSwitchRT.value) {
      this.viewContainerSize["LT"].width = 0;
      this.viewContainerSize["RT"].width = width;
    } else {
      this.viewContainerSize["LT"].width = 0;
      this.viewContainerSize["RT"].width = 0;
    }
    width = this.viewMainWidth;
    if (this.formViewSwitchLB.value && this.formViewSwitchRB.value) {
      width = Math.floor((width - 5) / 2);          // 5 for margin
      this.viewContainerSize["LB"].width = width;
      this.viewContainerSize["RB"].width = width;
    } else if (this.formViewSwitchLB.value) {
      this.viewContainerSize["LB"].width = width;
      this.viewContainerSize["RB"].width = 0;
    } else if (this.formViewSwitchRB.value) {
      this.viewContainerSize["LB"].width = 0;
      this.viewContainerSize["RB"].width = width;
    } else {
      this.viewContainerSize["LB"].width = 0;
      this.viewContainerSize["RB"].width = 0;
    }

    let height = this.viewMainHeight - 2 * 5;     // 5 for margin
    if (this.viewSubIsVisible("Top") && this.viewSubIsVisible("Bottom")) {
      height = Math.floor((height - 5) / 2);
      this.viewContainerSize["LT"].height = this.formViewSwitchLT.value ? height : 0;
      this.viewContainerSize["RT"].height = this.formViewSwitchRT.value ? height : 0;
      this.viewContainerSize["LB"].height = this.formViewSwitchLB.value ? height : 0;
      this.viewContainerSize["RB"].height = this.formViewSwitchRB.value ? height : 0;
    } else if (this.viewSubIsVisible("Top")) {
      this.viewContainerSize["LT"].height = this.formViewSwitchLT.value ? height : 0;
      this.viewContainerSize["RT"].height = this.formViewSwitchRT.value ? height : 0;
      this.viewContainerSize["LB"].height = 0;
      this.viewContainerSize["RB"].height = 0;
    } else if (this.viewSubIsVisible("Bottom")) {
      this.viewContainerSize["LT"].height = 0;
      this.viewContainerSize["RT"].height = 0;
      this.viewContainerSize["LB"].height = this.formViewSwitchLB.value ? height : 0;
      this.viewContainerSize["RB"].height = this.formViewSwitchRB.value ? height : 0;
    } else {
      this.viewContainerSize["LT"].height = 0;
      this.viewContainerSize["RT"].height = 0;
      this.viewContainerSize["LB"].height = 0;
      this.viewContainerSize["RB"].height = 0;
    }

    this.setViewContainerSize("LT");
    this.setViewContainerSize("RT");
    this.setViewContainerSize("LB");
    this.setViewContainerSize("RB");
  }

  setViewContainerSize(position: string) {
    switch (position) {
      case "LT":
        if (this.formViewSwitchLT.value && this.viewComponentLT)
          this.viewComponentLT.setViewSize(this.viewContainerSize[position].width, this.viewContainerSize[position].height);
        break;
      case "RT":
        if (this.formViewSwitchRT.value && this.viewComponentRT)
          this.viewComponentRT.setViewSize(this.viewContainerSize[position].width, this.viewContainerSize[position].height);
        break;
      case "LB":
        if (this.formViewSwitchLB.value && this.viewComponentLB)
          this.viewComponentLB.setViewSize(this.viewContainerSize[position].width, this.viewContainerSize[position].height);
        break;
      case "RB":
        if (this.formViewSwitchRB.value && this.viewComponentRB)
          this.viewComponentRB.setViewSize(this.viewContainerSize[position].width, this.viewContainerSize[position].height);
        break;
    }
  }

  getViewContainerStye(viewId: string) {
    return {
      "width": "" + this.viewContainerSize[viewId].width + "px",
      "height": "" + this.viewContainerSize[viewId].height + "px"
    }
  }

  viewSwitchControl(sw: boolean, viewId: string) {
    this.viewUpdateVisiblity();
    this.viewMainContainerSize();
    // this.cd.detectChanges();
  }

  addContext() {
    let context = new AnalysisContext(++this.maxContextNo);
    this.contexts.push(context);
    this.selectContext(context);
  }

  deleteContext() {

  }

  clickContext(context) {
    this.selectContext(context);
  }

  selectContext(context: AnalysisContext) {
    this.selectedContext = context;
    this.formAnalysisName.setValue(context.name);
    this.formAnalysisType.setValue(context.analysisType);

    /* For Demo start */
    this.demoCondition()
    /* For Demo end */
  }

  analysisTypeChanged(value: string) {
    this.selectedContext.setType(value);
  }

  analysisNameChanged(value: string) {
    this.selectedContext.name = value;
  }

  checkAnalysisName() {
    if (this.selectedContext.name === "") {
      this.selectedContext.setDefaultName();
      this.formAnalysisName.setValue(this.selectedContext.name);
    }    
  }

  /* For Demo start */
  demoCondition() {
    if (!this.selectedContext) return;
    this.demoSetDate(this.selectedContext.condition["beginDate"], this.selectedContext.condition["endDate"]);
  }
  /* For Demo end */
}
