import { Component, OnInit } from '@angular/core';

import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  today : Date = new Date();

  constructor(public commonService: CommonService,
    private router : Router) {}

  ngOnInit() {
  }
}
