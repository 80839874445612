import { Subject } from "rxjs";
import { LabelPrinter } from "./label-pinter";
import { environment } from "src/environments/environment";
import { WebSockNeo7 } from "src/app/common/web-sock-neo7";

export class LabelPrinterNeo7 extends LabelPrinter {

  public ipAddr: string;
  private url: string;
  // private websock: WebSock;
  private websock: WebSockNeo7;
  // private websock: WebSockNeo7I;
  private printerSubject: Subject<any>;

  constructor() {
    super();
    // this.websock = new WebSock();
    this.websock = new WebSockNeo7();
  }

  public getPrinterType(): string {
    return "neo7";
  }

  public setIpAddr(ipAddr: string) {
    this.ipAddr = ipAddr;
  }

  public getIpAddr(): string {
    return this.ipAddr;
  }

  public connect(ipAddr?: string) {
    if (environment.production) {
      this.url = "http://" + ipAddr + ":80/";
      // this.url = "http://10.100.201.11/yabe/neo7/index.html";
    } else {
      this.url = "http://10.100.201.11/yabe/neo7/index.html";
    }
    this.printerSubject = this.websock.connect(this.url);
    return this.printerSubject;
  }

  async connectAsync(ipAddr?: string, isTest?: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve) => resolve(true));
  }

  public disconnect() {
    this.websock.disconnect();
  }

  public write(printData: any) {
    this.printerSubject.next(printData);
  }

  public isConnected(): boolean {
    /*
    if (this.websock?.ws?.readyState === WebSocket.OPEN) {
      return true;
    } else {
      return false;
    }
    */
    if (this.websock) {
      return this.websock.isConnected;
    } else {
      return false;
    }
  }

  public getSubject(): Subject<any> {
    return this.printerSubject;
  }
}
