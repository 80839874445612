import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqGetOrderGroupUserMulti, ReqUpdateOrderGroupUserMulti, RspGetOrderGroupUserMulti, RspUpdateOrderGroupUserMulti, UpdateOrderGroupUserMultiDto } from 'src/app/webservice/order-group-user-multi';
import { OrderGroupRec } from './0_defs/order-group';
import { OrderGroupUserRec } from './0_defs/order-group-user';
import { StoreUserRec } from './0_defs/store-user';

@Component({
  selector: 'app-order-group-user-multi',
  templateUrl: './order-group-user-multi.component.html',
  styleUrls: ['./order-group-user-multi.component.css']
})
export class OrderGroupUserMultiComponent implements OnInit, OnDestroy {

  public isDirty: boolean = false;
  public tabSelection: number = 0;
  public storeForm: FormControl = new FormControl();
  public minBeginDate: Date;
  public minEndDate: Date;
  public byGroupActiveForm: FormControl = new FormControl(true);
  public byGroupNotActiveForm: FormControl = new FormControl(true);
  public byUserActiveForm: FormControl = new FormControl(true);
  public byUserNotActiveForm: FormControl = new FormControl(true);

  private orderGroupUsers: OrderGroupUserRec[];

  public byGroupGroupMap = {};
  public byGroupGroupList: OrderGroupRec[] = [];
  public byGroupGroupShowList: OrderGroupRec[] = [];
  public byGroupSelectedGroup: OrderGroupRec;
  public columnIdsByGroupGroup: string[] = ["dateBegin", "groupUsrCd", "groupName", "assignCount"];
  public columnDefsByGroupGroup: TableColumnDef[] = [
    {columnId: "groupUsrCd", header: "コード", width: 80, align: "center"},
    {columnId: "groupName", header: this.commonService.literal.orderGroup, width: 200},
    {columnId: "dateBegin", header: "有効日", width: 90, align: "center"},
    {columnId: "assignCount", header: "担当者数", width: 60, align: "right"}
  ];
  public byGroupUserList: OrderGroupUserRec[] = [];
  public byGroupUserShowList: OrderGroupUserRec[] = [];
  public columnIdsByGroupUser: string[] = ["userId", "userName", "dateBeginForm", "dateEndForm"];
  public columnDefsByGroupUser: TableColumnDef[] = [
    {columnId: "userId", header: "ユーザID", width: 100},
    {columnId: "userName", header: "ユーザ名", width: 200}
  ];

  public byUserUserMap = {};
  public byUserUserList: StoreUserRec[] = [];
  public byUserSelectedUser: StoreUserRec;
  public columnIdsByUserUser: string[] = ["userId", "userName", "assignCount"];
  public columnDefsByUserUser: TableColumnDef[] = [
    {columnId: "userId", header: "ユーザID", width: 100},
    {columnId: "userName", header: "ユーザ名", width: 200},
    {columnId: "assignCount", header: "担当数", width: 60, align: "right"}
  ];
  public byUserGroupList: OrderGroupUserRec[] = [];
  public byUserGroupShowList: OrderGroupUserRec[] = [];
  public columnIdsByUserGroup: string[] = ["groupDateBegin", "groupUsrCd", "groupName", "dateBeginForm", "dateEndForm"];
  public columnDefsByUserGroup: TableColumnDef[] = [
    {columnId: "groupDateBegin", header: "有効日", width: 90, align: "center"},
    {columnId: "groupUsrCd", header: "コード", width: 80},
    {columnId: "groupName", header: this.commonService.literal.orderGroup, width: 200}
  ];

  public subsc: Subscription[] = [];

  @ViewChild("byGroupUserList", {static: false, read: MatTable}) byGroupUserListTable: MatTable<any>;
  @ViewChild("byUserGroupList", {static: false, read: MatTable}) byUserGroupListTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.storeForm.setValue(this.commonService.loginUser.storeCd);

    this.minBeginDate = new Date();
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minBeginDate.getDate() - 1);

    this.subsc.push(
      this.byGroupActiveForm.valueChanges.subscribe(() => this.filterByGroupGroup())
    );
    this.subsc.push(
      this.byGroupNotActiveForm.valueChanges.subscribe(() => this.filterByGroupGroup())
    );
    this.subsc.push(
      this.byUserActiveForm.valueChanges.subscribe(() => this.filterByUserGroup())
    );
    this.subsc.push(
      this.byUserNotActiveForm.valueChanges.subscribe(() => this.filterByUserGroup())
    );
  }

  ngOnDestroy(): void {
    this.byGroupGroupList.forEach((item) => item.cleanupEdit());
    this.byUserUserList.forEach((item) => item.cleanupEdit());
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    if (this.tabSelection == 0) {
      this.setMaxHeight("by-group-group-box");
      this.setMaxHeight("by-group-user-box");
    } else {
      this.setMaxHeight("by-user-user-box");
      this.setMaxHeight("by-user-group-box");
    }
  }

  setMaxHeight(id: string) {
    let remHeight = this.commonService.getHeightBelow(id);
    // let paginatorHeight = 56;
    let paginatorHeight = 0;
    let margin = 10 + 5;
    // let btnBox = 24;
    let btnBox = 0;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  tabChanged(event: number) {
    this.setTableHeight();
  }

  getOrderGroupUserMulti() {
    let request: ReqGetOrderGroupUserMulti = {
      access: this.commonService.loginUser,
      storeCd: this.storeForm.value
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderGroupUserMulti", request).subscribe(
      (response: RspGetOrderGroupUserMulti) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveOrderGroupUserMulti(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveOrderGroupUserMulti(response: RspGetOrderGroupUserMulti) {
    if (this.httpBasic.handleAppError(response)) return;

    this.byGroupGroupList = [];
    this.byGroupGroupMap = {};
    this.byUserUserList = [];
    this.byGroupUserShowList = [];
    this.byUserGroupList = [];
    this.byUserGroupShowList = [];
    this.byUserUserMap = {};
    this.orderGroupUsers = [];
    this.byGroupSelectedGroup = undefined;

    response.orderGroups.forEach((dto) => {
      let rec = new OrderGroupRec(this.commonService);
      rec.storeCd = dto.storeCd;
      rec.groupId = dto.orderGroupId;
      rec.groupUsrCd = dto.orderGroupUsrCd;
      rec.groupName = dto.orderGroupName;
      rec.dateBegin = this.commonService.getDate(dto.beginDate);
      rec.dateEnd = this.commonService.getDate(dto.endDate);
      this.byGroupGroupList.push(rec);
      this.byGroupGroupMap["" + rec.groupId] = rec;
    });

    response.storeUsers.forEach((dto) => {
      let rec = new StoreUserRec(this.commonService);
      rec.storeCd = dto.storeCd;
      rec.userId = dto.userId;
      rec.userName = dto.userName;
      this.byUserUserList.push(rec);
      this.byUserUserMap[rec.userId] = rec;
    });

    response.orderGroupUsers.forEach((dto) => {
      let rec = new OrderGroupUserRec(this.commonService);
      rec.storeCd = dto.storeCd;
      rec.userId = dto.userId;
      rec.groupId = dto.groupId;
      rec.dateBegin = this.commonService.getDate(dto.beginDate);
      rec.dateEnd = this.commonService.getDate(dto.endDate);

      let group: OrderGroupRec = this.byGroupGroupMap["" + rec.groupId];
      let user: StoreUserRec = this.byUserUserMap[rec.userId];
      if (group != undefined && user != undefined) {
        rec.groupUsrCd = group.groupUsrCd;
        rec.groupName = group.groupName;
        rec.groupDateBegin = group.getValue("dateBegin"); //this.commonService.formatDate(group.dateBegin);
        rec.userName = user.userName;
        rec.setListner((user) => {this.orderGroupUserChangeListener(user);});
        rec.prepareEdit();
        this.orderGroupUsers.push(rec);

        group.addUser(rec);
        user.addGroup(rec);
      }
    });

    this.byGroupGroupList.forEach((rec) => rec.prepareEdit());
    this.byUserUserList.forEach((rec) => rec.prepareEdit());
    this.byUserUserList.sort((a, b) => {
      if (a.userName < b.userName) return -1;
      if (a.userName > b.userName) return 1;
      return 0;
    });

    this.filterByGroupGroup();
    this.setTableHeight();
  }

  clearSelectedGroup() {
    this.byGroupSelectedGroup = undefined;
    this.byGroupUserList = [];
    this.byGroupUserShowList = [];
  }

  filterByGroupGroup() {
    this.byGroupGroupShowList = [];
    this.byGroupGroupList.forEach((rec) => {
      if (rec.getValue("dateBegin") === "") {
        if (this.byGroupActiveForm.value) {
          this.byGroupGroupShowList.push(rec);
        } else {
          if (rec == this.byGroupSelectedGroup) this.clearSelectedGroup();
        }
      } else {
        if (this.byGroupNotActiveForm.value) {
          this.byGroupGroupShowList.push(rec);
        } else {
          if (rec == this.byGroupSelectedGroup) this.clearSelectedGroup();
        }
      }
    });
  }

  filterByUserGroup() {
    this.byUserGroupShowList = [];
    this.byUserGroupList.forEach((rec) => {
      if (rec.groupDateBegin === "") {
        if (this.byUserActiveForm.value) this.byUserGroupShowList.push(rec);
      } else {
        if (this.byUserNotActiveForm.value) this.byUserGroupShowList.push(rec);
      }
    });
  }

  byGroupClickRow(item: OrderGroupRec) {
    this.byGroupSelectedGroup = item;

    if (item.edit.users.length < this.byUserUserList.length) {
      this.addNotAssignedUsers(item);
    }

    this.byGroupUserList = [...item.edit.users];
    this.byGroupUserList.sort((a, b) => {
      if (a.userName < b.userName) return -1;
      if (a.userName > b.userName) return 1;
      return 0;
    });
    this.byGroupUserShowList = [...this.byGroupUserList];
  }

  byUserClickRow(item: StoreUserRec) {
    this.byUserSelectedUser = item;

    if (item.edit.groups.length < this.byGroupGroupList.length) {
      this.addNotAssignedGroups(item);
    }

    this.byUserGroupList = [...item.edit.groups];
    this.byUserGroupList.sort((a, b) => {
      if (a.groupUsrCd < b.groupUsrCd) return -1;
      if (a.groupUsrCd > b.groupUsrCd) return 1;
      if (a.groupDateBegin < b.groupDateBegin) -1;
      if (a.groupDateBegin > b.groupDateBegin) 1;
      return 0;
    });
    // this.byUserGroupShowList = [...this.byUserGroupList];
    this.filterByUserGroup();
  }

  addNotAssignedUsers(group: OrderGroupRec) {
    this.byUserUserList.forEach((user) => {
      if (!group.findUser(user.userId)) {
        let rec = new OrderGroupUserRec(this.commonService);
        rec.storeCd = group.storeCd;
        rec.groupId = group.groupId;
        rec.groupUsrCd = group.groupUsrCd;
        rec.groupName = group.groupName;
        rec.groupDateBegin = group.getValue("dateBegin");
        rec.userId = user.userId;
        rec.userName = user.userName;
        // begin/end date is undefined
        rec.setListner((user) => {this.orderGroupUserChangeListener(user);});
        rec.prepareEdit();
        group.addUser(rec);
        let storeUser: StoreUserRec = this.byUserUserMap[user.userId];
        storeUser.addGroup(rec);
      }
    });
  }

  addNotAssignedGroups(user: StoreUserRec) {
    this.byGroupGroupList.forEach((group) => {
      if (!user.findGroup(group.groupId)) {
        let rec = new OrderGroupUserRec(this.commonService);
        rec.storeCd = group.storeCd;
        rec.groupId = group.groupId;
        rec.groupUsrCd = group.groupUsrCd;
        rec.groupName = group.groupName;
        rec.groupDateBegin = group.getValue("dateBegin");
        rec.userId = user.userId;
        rec.userName = user.userName;
        // begin/end date is undefined
        rec.setListner((group) => {this.orderGroupUserChangeListener(group);});
        rec.prepareEdit();
        user.addGroup(rec);
        let orderGroup: OrderGroupRec = this.byGroupGroupMap[group.groupId];
        orderGroup.addUser(rec);
      }
    });
  }

  orderGroupUserChangeListener(groupUser: OrderGroupUserRec) {
    this.setDirty(true);
    let group = this.byGroupGroupMap["" + groupUser.groupId];
    group.updateAssign();
    let user = this.byUserUserMap[groupUser.userId];
    user.updateAssign();
  }

  setDirty(val: boolean) {
    this.isDirty = val;
    if (val) {
      this.storeForm.disable();
    } else {
      this.storeForm.enable();
    }
  }

  saveEdit() {
    /*

      TODO

    */

    let dirtyOrderGroupUsers: OrderGroupUserRec[] = [];
    this.orderGroupUsers = [];

    this.byGroupGroupList.forEach((rec) => {
      rec.saveEdit();
      rec.edit.users.forEach((user) => {
        if (user.edit.isDirty) dirtyOrderGroupUsers.push(user);
        if (user.hasAssign()) this.orderGroupUsers.push(user);
      });
    });
    this.byUserGroupList.forEach((rec) => {rec.saveEdit();});

    let updateList: UpdateOrderGroupUserMultiDto[] = [];
    dirtyOrderGroupUsers.forEach((rec) => {
      rec.saveEdit();
      let dateBegin;
      let dateEnd;
      if (!rec.dateBegin) {
        dateBegin = "";
        dateEnd = "";
      } else {
        dateBegin = this.commonService.formatDate(rec.dateBegin);
        if (!rec.dateEnd) {
          dateEnd = "2099/12/31";
        } else {
          dateEnd = this.commonService.formatDate(rec.dateEnd);
        }
      }
      let dto: UpdateOrderGroupUserMultiDto = {
        storeCd:  rec.storeCd,
        groupId:  rec.groupId,
        userId:   rec.userId,
        dateBegin:  dateBegin,
        dateEnd:    dateEnd,
        isDelete:   false
      };
      updateList.push(dto);
    });

    let request: ReqUpdateOrderGroupUserMulti = {
      access: this.commonService.loginUser,
      updateList: updateList
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdateOrderGroupUserMulti", request).subscribe(
      (response: RspUpdateOrderGroupUserMulti) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveUpdate(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpdate(response: RspUpdateOrderGroupUserMulti) {
    if (this.httpBasic.handleAppError(response)) return;

    this.setDirty(false);
  }

  cancelEdit() {
    this.byGroupGroupList.forEach((rec) => rec.cleanupEdit());
    this.byUserUserList.forEach((rec) => rec.cleanupEdit());
    this.orderGroupUsers.forEach((rec) => rec.prepareEdit());
    this.byGroupGroupList.forEach((rec) => rec.prepareEdit());
    this.byUserUserList.forEach((rec) => rec.prepareEdit());
    this.setDirty(false);

    if (this.byGroupSelectedGroup) this.byGroupClickRow(this.byGroupSelectedGroup);
    if (this.byUserSelectedUser) this.byUserClickRow(this.byUserSelectedUser);
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
