import { RspOrderStopTermItemDto } from 'src/app/response/rsp-order-stop-term-item';

export class OrderStopTermItemRec {
    dateTypeFn: number;
    dateBeginFv: string;
    dateEndFv: string;
    storeCdFv: string;
    storeNameFv: string;
    itemCdFv:  string;
    itemNameFv: string;

    storeCd: string;
    itemCd: string;
    itemName: string;
    dateType: string;
    dateBegin: string;
    dateEnd: string;
    constructor(rspOrderStopTermItemInitDto: RspOrderStopTermItemDto) {
        for (var key of Object.keys(rspOrderStopTermItemInitDto)) {
            this[key] = rspOrderStopTermItemInitDto[key];
        }

        if (this.storeCdFv != null)
            this.storeCd = this.storeCdFv + ":" + this.storeNameFv;
        if (this.itemCdFv != null)
            this.itemCd = this.itemCdFv;
        if (this.itemNameFv != null)
            this.itemName = this.itemNameFv;   
        if (this.dateBeginFv != null)
            this.dateBegin = this.dateBeginFv;
        if (this.dateEndFv != null)
            this.dateEnd = this.dateEndFv;
            this.setDateType(this.dateTypeFn)
    }

    setDateType(type: number) {
        if (type == 1) {
            this.dateType = "発注日";
        } else {
            this.dateType = "納品日";
        }
    }
}

