import { CommonService } from '../service/common.service';
import { RspCtgHistDto } from '../response/rsp-ctg-hist-search';

export class CtgHistRec {
    NO: number;
    calcTrgtDateFd: string;
    orderRecommendBaraNumFn: number;
    orderRealBaraNumFn: number;
    stkNumFn: number;
    stkEditNumFn: number;
    salesNumFn: number;
    rcvBaraNumFn: number;
    inMoveNumFn: number;
    outMoveNumfn: number;
    disNumFn: number;
    rtnNumFn: number;
    transNumFn: number;
    revNumFn: number;

    constructor(private ctgHistDto: RspCtgHistDto, private commonService: CommonService) {
        for (var key of Object.keys(ctgHistDto)) {
            this[key] = ctgHistDto[key];
        }
    }
}