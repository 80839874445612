import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ItemDaylyUpdDto, WeeklyOrderRec } from '../0_def/weekly_order-rec';
import { Service } from '../1_service/service';
import { CtgSelectEobCondition } from '../ctg-eob-select/ctg-eob-select.component';


@Component({
  selector: 'app-weekly_order',
  templateUrl: './weekly_order.component.html',
  styleUrls: ['./weekly_order.component.css']
})
export class WeeklyOrderComponent implements OnInit, OnDestroy {

  public spans = [];
  public formGroupCondition: FormGroup;
  public itemListDaily: WeeklyOrderRec[];
  public itemListDailyOrigin: WeeklyOrderRec[];
  public selectedItem: WeeklyOrderRec;
  public maxSelectableLevel: number = 3;
  public tabSelection: number = 0;
  public recordCount: number;
  public recordCountDisplay: number;
  private subscriptionFormR4: Subscription;
  private subscriptionFormR5: Subscription;
  private subscriptionFormR6: Subscription;
  private subscriptionFormR7: Subscription;
  private subscriptionFormR8: Subscription;
  private subscriptionFormR9: Subscription;
  public ctgSelectEobCondition: CtgSelectEobCondition = new CtgSelectEobCondition(this.fb);

  constructor(public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private service: Service,
  ) {
  }
  @ViewChild(MatTable, { static: true }) matTable: MatTable<any>;

  public columnIds: string[] = ["column1", "column2", "column3", "column4", "column5", "column6", "column7", "dispItem", "r_1", "r_2", "total", "r_3", "r_4", "r_5", "r_6", "r_7", "r_8", "r_9"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "column1", header: "商品", width: 200, align: "center" },
    { columnId: "column2", header: "発注〆時間", width: 70, align: "center" },
    { columnId: "column3", header: "仕入先", width: 150, align: "left" },
    { columnId: "column4", header: "ロット数", width: 70, align: "center" },
    { columnId: "column5", header: "最低陳列数", width: 70, align: "center" },
    { columnId: "column6", header: "在庫数", width: 70, align: "center" },
    { columnId: "column7", header: "便No", width: 70, align: "center" },
  ];

  public columDefsDayly: TableColumnDef[] = [
    { columnId: "dispItem", header: "項目", width: 150, align: "left" },
    { columnId: "r_1", header: this.service.getDateDaily(-2), width: 70, align: "right" },
    { columnId: "r_2", header: this.service.getDateDaily(-1), width: 70, align: "right" },
    { columnId: "total", header: "合計", width: 80, align: "right" },
    { columnId: "r_3", header: this.service.getDateDaily(0), width: 70, align: "right" },
    { columnId: "r_4", header: this.service.getDateDaily(1), width: 70, align: "right" },
    { columnId: "r_5", header: this.service.getDateDaily(2), width: 70, align: "right" },
    { columnId: "r_6", header: this.service.getDateDaily(3), width: 70, align: "right" },
    { columnId: "r_7", header: this.service.getDateDaily(4), width: 70, align: "right" },
    { columnId: "r_8", header: this.service.getDateDaily(5), width: 70, align: "right" },
    { columnId: "r_9", header: this.service.getDateDaily(6), width: 70, align: "right" },
  ]

  ngOnInit(): void {
    this.commonService.pageTitle = "EOB画面";
    this.ctgSelectEobCondition.storeCd = this.commonService.loginUser.storeCd;
    this.formGroupCondition = new FormGroup({
      itemCd: new FormControl(),
      itemName: new FormControl(""),
      makerName: new FormControl(""),
      orderNumSort: new FormControl("0"),
      itemCdSort: new FormControl("1"),
      filterOrderNumValue: new FormControl("1"),
      hasFilterOrderNum: new FormControl(false)
    });
  }

  ngOnDestroy() {
    this.clearProgress();
  }

  doSearch() {
    let recordCount = this.itemListDaily ? this.itemListDaily.length : 0;
    let itemCdCondition = this.formGroupCondition.get("itemCd").value;
    if (recordCount > 0 && itemCdCondition) {
      this.focusItem(itemCdCondition);
      this.scroll(itemCdCondition);
    } else {
      if (this.getItemDaylyChangeList().length > 0) {
        let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
          "変更内容を破棄しますか？").subscribe(
            (response: boolean) => {
              subsc.unsubscribe();
              if (response) {
                this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
                this.doSearchBody();
              }
            });
      } else {
        this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
        this.doSearchBody();
      }
    }
  }

  doSearchBody() {
    let request = {
      access: this.commonService.loginUser,
      storeCd: this.commonService.loginUser.storeCd,
      ctgCd: "",
      makerName: this.formGroupCondition.get("makerName").value,
      itemCd: this.formGroupCondition.get("itemCd").value == null ? "" : this.formGroupCondition.get("itemCd").value,
      itemName: this.formGroupCondition.get("itemName").value,
      orderNumSort: this.formGroupCondition.get("orderNumSort").value,
      itemCdSort: this.formGroupCondition.get("itemCdSort").value,
      hasFilterOrderNum: this.formGroupCondition.get("hasFilterOrderNum").value ? "1" : "0",
      filterOrderNumValue: this.formGroupCondition.get("filterOrderNumValue").value
    }
    let ctgCd0 = this.ctgSelectEobCondition.formGroup.get("ctgCd0").value;
    let ctgCd1 = this.ctgSelectEobCondition.formGroup.get("ctgCd1").value;
    let ctgCd2 = this.ctgSelectEobCondition.formGroup.get("ctgCd2").value;
    let ctgCd3 = this.ctgSelectEobCondition.formGroup.get("ctgCd3").value;
    if (ctgCd0) request.ctgCd = ctgCd0;
    if (ctgCd1) request.ctgCd = ctgCd1;
    if (ctgCd2) request.ctgCd = ctgCd2;
    if (ctgCd3) request.ctgCd = ctgCd3;
    let subc = this.httpBasic.generalRequest("ItemListDaylySearch", request).subscribe(
      (response) => {
        subc.unsubscribe();
        this.receiveGetItemListDayly(response);
      },
      (error) => {
        subc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetItemListDayly(response) {
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.commonService.closeSpinner();
    this.itemListDaily = [];
    this.itemListDailyOrigin = [];
    if (response.rows.length == 0) return;
    const selectedItemCds = response.rows.filter((e) => e.lotNm && e.dispItem == 'ORDER_EDIT_VALUE');
    response.rows.forEach(((row, index) => {
      let tmp = selectedItemCds.find(e => e.itemCd == row.itemCd);
      let isOrderNumRow = tmp && row.dispItem == '発注数' ? true : false; //add lotNm in row 発注数
      let dto = {
        itemCd: row.itemCd,
        itemName: row.itemName,
        itemStd: row.itemStd,
        mkrName: row.mkrName,
        suppName: row.suppName,
        itemOrderTime: row.itemOrderTime,
        ctgCd: row.ctgCd,
        ctgNm: row.ctgNm,
        lotNm: isOrderNumRow ? tmp.lotNm : row.lotNm,
        minzaiNm: row.minzaiNm,
        flightNm: row.flightNm,
        stkNm: row.stkNm,
        dispItem: row.dispItem,
        r_1: isOrderNumRow ? tmp.r_1 : row.r_1,
        r_2: isOrderNumRow ? tmp.r_2 : row.r_2,
        r_3: isOrderNumRow ? tmp.r_3 : row.r_3,
        r_4: isOrderNumRow ? tmp.r_4 : row.r_4,
        r_5: isOrderNumRow ? tmp.r_5 : row.r_5,
        r_6: isOrderNumRow ? tmp.r_6 : row.r_6,
        r_7: isOrderNumRow ? tmp.r_7 : row.r_7,
        r_8: isOrderNumRow ? tmp.r_8 : row.r_8,
        r_9: isOrderNumRow ? tmp.r_9 : row.r_9,
        orderLtToday: row.orderLtToday,
        attrOrder: row.attrOrder,
        attrDaily: row.attrDaily,
        confirmFn: row.confirmFn
      }
      this.itemListDaily.push(new WeeklyOrderRec(dto));
    }));
    this.itemListDailyOrigin = this.itemListDaily?.length > 0 ? [...this.itemListDaily].map(rec => new WeeklyOrderRec(rec?.originDto)) : [];
    this.recordCount = this.itemListDaily?.length ?? 0;
    this.recordCountDisplay = this.itemListDaily?.filter(item => item.dispItem == 'ORDER_EDIT_VALUE').length ?? 0;
    this.cacheSpan('itemCd', d => d.itemCd);
  }

  focusItem(itemCdCondition) {
    this.selectedItem = undefined;
    this.selectedItem = this.itemListDaily.find(item => item.itemCd == itemCdCondition);
  }

  getItemDaylyChangeList(): ItemDaylyUpdDto[] {
    let changeList: ItemDaylyUpdDto[] = [];
    if (this.itemListDaily == undefined) this.itemListDaily = [];
    let changeForm4List: ItemDaylyUpdDto[] = this.itemListDaily.filter(item => { return this.isChangeFormR4(item) && item.dispItem == "ORDER_EDIT_VALUE" }).map(item => this.genNewReqSaveDto(item, item.formR4.value, item.trgtDate4));
    let changeForm5List: ItemDaylyUpdDto[] = this.itemListDaily.filter(item => { return this.isChangeFormR5(item) && item.dispItem == "ORDER_EDIT_VALUE" }).map(item => this.genNewReqSaveDto(item, item.formR5.value, item.trgtDate5));
    let changeForm6List: ItemDaylyUpdDto[] = this.itemListDaily.filter(item => { return this.isChangeFormR6(item) && item.dispItem == "ORDER_EDIT_VALUE" }).map(item => this.genNewReqSaveDto(item, item.formR6.value, item.trgtDate6));
    let changeForm7List: ItemDaylyUpdDto[] = this.itemListDaily.filter(item => { return this.isChangeFormR7(item) && item.dispItem == "ORDER_EDIT_VALUE" }).map(item => this.genNewReqSaveDto(item, item.formR7.value, item.trgtDate7));
    let changeForm8List: ItemDaylyUpdDto[] = this.itemListDaily.filter(item => { return this.isChangeFormR8(item) && item.dispItem == "ORDER_EDIT_VALUE" }).map(item => this.genNewReqSaveDto(item, item.formR8.value, item.trgtDate8));
    let changeForm9List: ItemDaylyUpdDto[] = this.itemListDaily.filter(item => { return this.isChangeFormR9(item) && item.dispItem == "ORDER_EDIT_VALUE" }).map(item => this.genNewReqSaveDto(item, item.formR9.value, item.trgtDate9));
    changeList = [...changeForm4List, ...changeForm5List, ...changeForm6List, ...changeForm7List, ...changeForm8List, ...changeForm9List];
    return changeList;
  }

  genNewReqSaveDto(item: WeeklyOrderRec, rcvNum, trgtDate): ItemDaylyUpdDto {
    const dto: ItemDaylyUpdDto = {
      userId: this.commonService.loginUser.userId,
      storeCd: this.commonService.loginUser.storeCd,
      itemCd: item.itemCd,
      rcvNum: rcvNum,
      trgtDate: trgtDate,
    }
    return dto;
  }

  doSave() {
    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
      "内容を保存します。よろしいですか？").subscribe(
        (response: boolean) => {
          subsc.unsubscribe();
          if (response) {
            this.doSaveBody();
          }
        });
  }

  doSaveBody() {
    let request = {
      access: this.commonService.loginUser,
      updateList: this.getItemDaylyChangeList()
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中。。。");
    let subc = this.httpBasic.generalRequest("ItemListDaylyUpdate", request).subscribe(
      (response) => {
        subc.unsubscribe();
        this.receiveDoSaveBody(response);
      },
      (error) => {
        subc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDoSaveBody(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.doSearchBody();
    this.commonService.openNotificationDialog(this.commonService.pageTitle, "保存しました。");
  }

  doCancel() {
    this.itemListDaily = this.itemListDailyOrigin?.length > 0 ? [...this.itemListDailyOrigin].map(rec => new WeeklyOrderRec(rec?.originDto)) : [];
  }
  /**
   * Evaluated and store an evaluation of the rowspan for each row.
   * The key determines the column it affects, and the accessor determines the
   * value that should be checked for spanning.
   */
  cacheSpan(key, accessor) {
    for (let i = 0; i < this.itemListDaily.length;) {
      let currentValue = accessor(this.itemListDaily[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.itemListDaily.length; j++) {
        if (currentValue != accessor(this.itemListDaily[j])) {
          break;
        }
        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }
      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }

  onInputNum(colDef: TableColumnDef, formControl: FormControl, item: WeeklyOrderRec, event: any) {
    let editValue = parseInt(event.target.value);
    let oldValue = item[colDef.columnId]
    if (isNaN(editValue) && event.target.value != "") {
      this.commonService.openErrorDialog(this.commonService.pageTitle, `${event.target.value} - ${colDef.header}: 正しい数値を入力して下さい。`);
      formControl.setValue(oldValue);
      return;
    }
    if (editValue > 9990) {
      formControl.setValue(oldValue);
      this.commonService.openErrorDialog(this.commonService.pageTitle, "発注（ロット）数は最大で9999までです。");
      return;
    }
    let orderRow: WeeklyOrderRec = this.itemListDaily.find((row) => row.itemCd === item.itemCd && row.dispItem === '発注数');
    this.subscriptionFormR4 = item.formR4.valueChanges.subscribe((value) => {
      let editValue = value == "" ? 0 : value;
      orderRow.r_4 = parseInt(editValue) * parseInt(item.lotNm) + '';
    });
    this.subscriptionFormR5 = item.formR5.valueChanges.subscribe((value) => {
      let editValue = value == "" ? 0 : value;
      orderRow.r_5 = parseInt(editValue) * parseInt(item.lotNm) + '';
    });
    this.subscriptionFormR6 = item.formR6.valueChanges.subscribe((value) => {
      let editValue = value == "" ? 0 : value;
      orderRow.r_6 = parseInt(editValue) * parseInt(item.lotNm) + '';
    });
    this.subscriptionFormR7 = item.formR7.valueChanges.subscribe((value) => {
      let editValue = value == "" ? 0 : value;
      orderRow.r_7 = parseInt(editValue) * parseInt(item.lotNm) + '';
    });
    this.subscriptionFormR8 = item.formR8.valueChanges.subscribe((value) => {
      let editValue = value == "" ? 0 : value;
      orderRow.r_8 = parseInt(editValue) * parseInt(item.lotNm) + '';
    });
    this.subscriptionFormR9 = item.formR9.valueChanges.subscribe((value) => {
      let editValue = value == "" ? 0 : value;
      orderRow.r_9 = parseInt(editValue) * parseInt(item.lotNm) + '';
    });
    this.itemListDaily = this.itemListDaily.map(row => {
      if (row.itemCd === orderRow.itemCd && row.dispItem == '発注数') {
        row = orderRow;
      }
      return row;
    })
    formControl.setValue(event.target.value);
    this.matTable?.renderRows();
  }

  refeshValue(item: WeeklyOrderRec) {
    let orderRow: WeeklyOrderRec = this.itemListDaily.find((row) => row.itemCd === item.itemCd && row.dispItem === '発注数');
    let r4 = isNaN(parseInt(item.formR4.value)) ? 0 : parseInt(item.formR4.value);
    let r5 = isNaN(parseInt(item.formR5.value)) ? 0 : parseInt(item.formR5.value);
    let r6 = isNaN(parseInt(item.formR6.value)) ? 0 : parseInt(item.formR6.value);
    let r7 = isNaN(parseInt(item.formR7.value)) ? 0 : parseInt(item.formR7.value);
    let r8 = isNaN(parseInt(item.formR8.value)) ? 0 : parseInt(item.formR8.value);
    let r9 = isNaN(parseInt(item.formR9.value)) ? 0 : parseInt(item.formR9.value);
    let total = r4 + r5 + r6 + r7 + r8 + r9;
    item.total = total + '';
    orderRow.total = total * parseInt(item.lotNm) + '';
    this.itemListDaily = this.itemListDaily.map(row => {
      if (row.itemCd === orderRow.itemCd && row.dispItem == '発注数') {
        row = orderRow;
      }
      return row;
    });
    this.matTable?.renderRows();
    this.clearProgress();
  }

  clearProgress() {
    if (this.subscriptionFormR4) {
      this.subscriptionFormR4.unsubscribe();
    }
    if (this.subscriptionFormR5) {
      this.subscriptionFormR5.unsubscribe();
    }
    if (this.subscriptionFormR6) {
      this.subscriptionFormR6.unsubscribe();
    }
    if (this.subscriptionFormR7) {
      this.subscriptionFormR7.unsubscribe();
    }
    if (this.subscriptionFormR8) {
      this.subscriptionFormR8.unsubscribe();
    }
    if (this.subscriptionFormR9) {
      this.subscriptionFormR9.unsubscribe();
    }
  }

  setOrderNumSort(priv: string) {
    switch (priv) {
      case "降順":
        this.formGroupCondition.get("orderNumSort").setValue("0");
        break;
      case "昇順":
        this.formGroupCondition.get("orderNumSort").setValue("1");
        break;
    }
  }

  setItemCdSort(priv: string) {
    switch (priv) {
      case "降順":
        this.formGroupCondition.get("itemCdSort").setValue("0");
        break;
      case "昇順":
        this.formGroupCondition.get("itemCdSort").setValue("1");
        break;
    }
  }

  setFilterOrderNumValue(priv: string) {
    switch (priv) {
      case "有":
        this.formGroupCondition.get("filterOrderNumValue").setValue("1");
        break;
      case "無":
        this.formGroupCondition.get("filterOrderNumValue").setValue("0");
        break;
    }
  }

  isChangeFormR4(item: WeeklyOrderRec) {
    const value = item?.r_4 ?? 0;
    const valueEdit = isNaN(item.formR4.value) ? 0 : item.formR4.value;
    return value != valueEdit;
  }

  isChangeFormR5(item: WeeklyOrderRec) {
    const value = item?.r_5 ?? 0;
    const valueEdit = isNaN(item.formR5.value) ? 0 : item.formR5.value;
    return value != valueEdit;
  }

  isChangeFormR6(item: WeeklyOrderRec) {
    const value = item?.r_6 ?? 0;
    const valueEdit = isNaN(item.formR6.value) ? 0 : item.formR6.value;
    return value != valueEdit;
  }

  isChangeFormR7(item: WeeklyOrderRec) {
    const value = item?.r_7 ?? 0;
    const valueEdit = isNaN(item.formR7.value) ? 0 : item.formR7.value;
    return value != valueEdit;
  }

  isChangeFormR8(item: WeeklyOrderRec) {
    const value = item?.r_8 ?? 0;
    const valueEdit = isNaN(item.formR8.value) ? 0 : item.formR8.value;
    return value != valueEdit;
  }

  isChangeFormR9(item: WeeklyOrderRec) {
    const value = item?.r_9 ?? 0;
    const valueEdit = isNaN(item.formR9.value) ? 0 : item.formR9.value;
    return value != valueEdit;
  }

  checkSaveDisable() {
    if (this.itemListDaily == undefined || this.itemListDaily?.length == 0) return true;
    if (this.getItemDaylyChangeList().length == 0) return true;
    return false;
  }

  checkCancelDisable() {
    if (this.itemListDaily == undefined || this.itemListDaily?.length == 0) return true;
    if (this.getItemDaylyChangeList().length == 0) return true;
    return false;
  }

  styleForRowDayly(colDef: TableColumnDef, item: WeeklyOrderRec) {
    let checkValue = item[colDef.columnId];
    let nonEditColumn = colDef.columnId == "dispItem" || colDef.columnId == "r_1" || colDef.columnId == "r_2" || colDef.columnId == "total";
    let initStyle = {
      "width": "" + colDef.width + "px",
      "min-width": "" + colDef.width + "px",
      "max-width": "" + (colDef.maxWidth != undefined ? colDef.maxWidth : colDef.width) + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
    if (item.dispItem == 'ORDER_EDIT_VALUE') { // style row 1
      let attrOrder = JSON.parse(item.attrOrder);
      let isEditable = attrOrder[colDef.columnId] == '1';
      let attrDaily = item.attrDaily.split(",");
      let colIndex = parseInt(colDef.columnId.substring(2)); // r_1 -> 1
      let isInDaylyEdit = attrDaily[colIndex - 1] == 1;
      let odrLtToday = parseInt(item.orderLtToday);
      let dateBeforeToday = 2;
      if (colIndex == 4 && this.isChangeFormR4(item)) {   // style for col 4 is changed
        return {
          ...initStyle,
          "background": "#9DCCE0"
        }
      }
      if (colIndex == 5 && this.isChangeFormR5(item)) {   // style for col 5 is changed
        return {
          ...initStyle,
          "background": "#9DCCE0"
        }
      }
      if (colIndex == 6 && this.isChangeFormR6(item)) {   // style for col 6 is changed
        return {
          ...initStyle,
          "background": "#9DCCE0"
        }
      }
      if (colIndex == 7 && this.isChangeFormR7(item)) {   // style for col 7 is changed
        return {
          ...initStyle,
          "background": "#9DCCE0"
        }
      }
      if (colIndex == 8 && this.isChangeFormR8(item)) {   // style for col 8 is changed
        return {
          ...initStyle,
          "background": "#9DCCE0"
        }
      }
      if (colIndex == 9 && this.isChangeFormR9(item)) {   // style for col 9 is changed
        return {
          ...initStyle,
          "background": "#9DCCE0"
        }
      }
      if (colDef.columnId == "r_1" || colDef.columnId == "r_2" || colDef.columnId == "r_3") { } // none style
      else if (colIndex > 3 && colIndex - 2 <= odrLtToday) {  // init style for row
        return {
          ...initStyle,
          "background": "#FF4400"
        }
      } else {
        if (isEditable) {
          if (isInDaylyEdit) { // 訂正の場合は「水色」にする
            return {
              ...initStyle,
              "background": "#9DCCE0"
            }
          } else if (checkValue != 0 || odrLtToday + 1 == colIndex - dateBeforeToday) { // 推奨値がない場合も通常LTの納品日は背景色緑
            return {
              ...initStyle,
              "background": "#B9C42F"
            }
          }
        } else if (!isEditable && isInDaylyEdit) {
          return {
            ...initStyle,
            "background": "#9DCCE0"
          }
        }
      }
    }
    if (item.dispItem == '発注数' && !nonEditColumn) { // style row 2
      if (checkValue != 0) {
        return {
          ...initStyle,
          "color": "#ff0000"
        }
      }
    }
    if (item.dispItem == '納品予定' && !nonEditColumn) { // style row 3
      return {
        ...initStyle,
        "background": "#EA9198"
      }
    }
    if (item.dispItem == '販売予測数' && !nonEditColumn) { // style row 4
      return {
        ...initStyle,
        "color": "#075eb5"
      };
    }
    if (item.dispItem == '値引金額') { //style row 9
      return {
        ...initStyle,
        "border-bottom-width": "medium"
      }
    } else {
      return initStyle;
    }
  }

  scroll(id: string) {
    let containerElement = document.getElementById(id);
    if (containerElement) containerElement.scrollIntoView({ behavior: 'smooth' });
  }

  onEnter() {
    this.doSearch();
  }

  getDataTest(index: number, name: string) {
    let line = (index / 9) + 1;
    return `Line${line}-${name}`
  }

  canDeactivate() {
    if (this.getItemDaylyChangeList().length == 0) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}