import { FormControl } from "@angular/forms";
import { Req } from "../../../request/req";
import { Rsp } from "../../../response/rsp";

export interface UrgentOrderListDto {
  itemCd: string;
  mkrFv: string;
  itemName: string;
  stdFv: string;
  minZaiFn: string;
  stkNumFn: string;
  suppLotFn: string;
  orderSuppLotFn: string;
  orderBara: number;
  rcvDateFv: string;
  binCdFv: string;
  costPriceFn: string;
  sellPriceFn: string;
  timeFv: string;
  suppCd: string;
  suppName: string;
  editFlag: number;
  confirmFn: string;
}

export class UrgentOrderList {
  public orderSuppLotForm: FormControl = new FormControl("");
  public itemCd: string = "";
  public mkrFv: string = "";
  public itemName: string = "";
  public stdFv: string = "";
  public minZaiFn: string = "";
  public stkNumFn: string = "";
  public suppLotFn: string = "";
  public orderSuppLotFn: string = "";
  public orderBara: number = 0;
  public rcvDateFv: string = "";
  public binCdFv: string = "";
  public costPriceFn: string = "";
  public sellPriceFn: string = "";
  public timeFv: string = "";
  public suppCd: string = "";
  public suppName: string = "";
  public editFlag: number = 0;
  public confirmFn: string = "";
  public originDto: UrgentOrderListDto;

  constructor(dto?: UrgentOrderListDto) {
    if (dto)
      this.patchValue(dto);
  }

  patchValue(obj): void {
    for (let key in obj) {
      if (Object.keys(this).find((recKey) => recKey === key)) {
        this[key] = obj[key];
      }
    }
    this.orderSuppLotForm.setValue(this.orderSuppLotFn);
    this.orderBara = Number.isNaN(parseInt(this.orderSuppLotForm.value)) ? 0 : parseInt(this.orderSuppLotForm.value) * parseInt(this.suppLotFn);

    this.originDto = { ...obj };
  }

  isEdited() {
    if (this.orderSuppLotForm.value !== this.orderSuppLotFn) return true;
    return false
  }
}

export interface ReqUrgentOrderListSearch extends Req {
  storeCd: string;
  ctgCd0Fv: string;
  ctgCd1Fv: string;
  ctgCd2Fv: string;
  ctgCd3Fv: string;
  itemCd: string;
  itemName: string;
  makerName: string;
}

export interface ReqUrgentOrderListUpdate extends Req {
  itemUpdate: UrgentOrderListDto[];
  ctgCd0Fv: string;
  ctgCd1Fv: string;
  ctgCd2Fv: string;
  ctgCd3Fv: string;
  itemCd: string;
  itemName: string;
  makerName: string
}

export interface RspUrgentOrderListSearch extends Rsp {
  rows: UrgentOrderListDto[];
}

export interface RspUrgentOrderListUpdate extends Rsp {
  rows: UrgentOrderListDto[];
}
