import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { ReqParamCtgSearchDto } from 'src/app/request/req-param-ctg-search';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspParamCtgSearch } from 'src/app/response/rsp-param-ctg-search';
import { Subscription } from 'rxjs';
import { CtgParamRec } from 'src/app/common/ctg-param-rec';
import { MatTable } from '@angular/material/table';
import { ParamCtgEditDialogComponent } from 'src/app/dialog/param-ctg-edit-dialog/param-ctg-edit-dialog.component';
import { CtgPathRec } from 'src/app/common/ctg-path-rec';
import { RspCtgPath } from 'src/app/response/rsp-ctg-path';

@Component({
  selector: 'app-ctg-param',
  templateUrl: './ctg-param.component.html',
  styleUrls: ['./ctg-param.component.css']
})
export class CtgParamComponent implements OnInit, OnDestroy {

  private subscriptionSearch: Subscription;
  public ctgParamList: CtgParamRec[] = [];
  public ctgPathRec: CtgPathRec;
  private subscriptionCtgPath: Subscription;

  public displayColumnIds: string[] = [
    'rank',
    'minZai',
    'maxZai',
    'minStockDays',
    'maxStockDays'
  ];

  @Input() editable: boolean = false;
  @ViewChild(MatTable, {static:true}) matTable: MatTable<any>;

  constructor(
    private commonService:CommonService,
    private httpBasic: HttpBasicService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
    }
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
    if (this.subscriptionCtgPath) this.subscriptionCtgPath.unsubscribe();
    this.subscriptionCtgPath = undefined;
  }

  getParamCtg(rspParmStgSearch: RspParamCtgSearch) {
    // this.clearProgressState();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;

    if (rspParmStgSearch.fatalError && rspParmStgSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("カテゴリ・パラメータ", rspParmStgSearch.fatalError[0].errMsg);
      return;
    }
    if (rspParmStgSearch.normalError && rspParmStgSearch.normalError.length > 0) {
      this.commonService.openErrorDialog("カテゴリ・パラメータ", rspParmStgSearch.normalError[0].errMsg);
      return;
    }

    this.ctgParamList = [];
    for (var rspParamCtg of rspParmStgSearch.result) {
      this.ctgParamList.push(new CtgParamRec(rspParamCtg));
    }
    this.matTable.renderRows();
    this.ctgPath();
  }

  doQuery(reqParamCtgSearchDto: ReqParamCtgSearchDto) {
    let ref = this.commonService.openSpinnerForSubComp("カテゴリ・パラメータ", "検索中・・・");
    this.subscriptionSearch = this.httpBasic.paramCtgSearch(reqParamCtgSearchDto)
    .subscribe(
      (data) => {
        this.commonService.closeSpinnerForSubComp(ref);
        this.getParamCtg(data);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    )
  }

  ctgPath() {
    if (this.ctgParamList.length == 0) {
      this.clearProgressState();
      return;
    }
    this.subscriptionCtgPath = this.httpBasic.ctgPath(
      this.ctgParamList[0].storeCdFv,
      this.ctgParamList[0].ctgLevelFn,
      this.ctgParamList[0].ctgCd0Fv,
      this.ctgParamList[0].ctgCd1Fv,
      this.ctgParamList[0].ctgCd2Fv,
      this.ctgParamList[0].ctgCd3Fv,
    ).subscribe(
      data => this.getCtgPath(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  getCtgPath(response: RspCtgPath) {
    this.clearProgressState();

    this.ctgPathRec = new CtgPathRec(response.rows);
  }

  onClickCell(columnId:string, ctgParamRec: CtgParamRec) {
    if (!this.editable) {
      return;
    }

    if ((columnId === "minZai" && this.commonService.config.paramCatMinZaiEditable) ||
        (columnId === "maxZai" && this.commonService.config.paramCatMaxZaiEditable) ||
        (columnId === "minStockDays" && this.commonService.config.paramCatMinStockDaysEditable) ||
        (columnId === "maxStockDays" && this.commonService.config.paramCatMaxStockDaysEditable)) {
     this.openCtgParamEditDialog(ctgParamRec);
     return;
    }
  }

  openCtgParamEditDialog(ctgParamRec: CtgParamRec) {
    const dialogRef = this.commonService.dialog.open(ParamCtgEditDialogComponent, {
      disableClose: true,
      data: {ctgParamRec: ctgParamRec, ctgPath: this.ctgPathRec},
      maxWidth: "95vw",
      position: {top: "5px"}
    });
    dialogRef.afterClosed().subscribe(
      data => this.ctgParamEditResult(data)
    )
  }
  ctgParamEditResult(data: any) {
    this.matTable.renderRows();
  }

}
