import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { LabelPrinterConfig } from '../label-pinter';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { PrinterService } from 'src/app/service/printer.service';
import { PrinterLayoutDto } from 'src/app/webservice/printer';
import { QrcodeDialogComponent } from 'src/app/dialog/qrcode-dialog/qrcode-dialog.component';
import { LabelPrinterNeo7B } from '../label-printer-neo7B';

@Component({
  selector: 'label-printer-dialog',
  templateUrl: './label-printer-dialog.component.html',
  styleUrls: ['./label-printer-dialog.component.css']
})
export class LabelPrinterDialogComponent implements OnInit, OnDestroy {

  public  printerConfig: LabelPrinterConfig;
  private connSubsc: Subscription;
  public  printerTypeForm: FormControl;
  public  selectedLayout: PrinterLayoutDto;

  // For Neo7
  public  ipAddrForm: FormControl = new FormControl("");

  constructor(
    public dialogRef: MatDialogRef<LabelPrinterDialogComponent>,
    public commonService: CommonService,
    public printerService: PrinterService,
    @Inject(MAT_DIALOG_DATA) public data: LabelPrinterConfig
  ){
    if (data) {
      this.printerConfig = data;
      this.selectedLayout = this.printerService.printerLayoutList.find((dto) => dto.layoutCd === data.labelLayoutKey);
    } else {
      this.printerConfig = {
        printer: undefined,
        // printCount: 1,
        labelLayoutKey: this.printerService.printerLayoutList.length > 0 ? this.printerService.printerLayoutList[0].layoutCd : "",
        labelLayoutName: this.printerService.printerLayoutList.length > 0 ? this.printerService.printerLayoutList[0].name : ""
      };
      this.selectedLayout = this.printerService.printerLayoutList[0];
    }
    // this.printCountForm = new FormControl(this.printerConfig.printCount);
    // this.layoutForm = new FormControl(this.printerConfig.labelLayoutKey);
    let printer = this.printerConfig.printer;
    this.printerTypeForm = new FormControl(printer ? printer.getPrinterType() : this.commonService.config.printer.models[0].name);
    this.ipAddrForm.setValue(this.printerConfig.ipAddr ?? "");
  }

  ngOnInit(): void {
    // this.setListHeight();
  }

  ngOnDestroy(): void {
    this.connSubsc?.unsubscribe();
  }

  /*
  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setListHeight();
  }

  setListHeight() {
    setTimeout(() => { this.setListHeightBody(); }, 0);
  }

  setListHeightBody() {
    let id = "layout-list-section";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight == undefined) return;
    let height = remHeight - 25;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem == undefined) return;
    if (elem) elem.style.maxHeight = "" + height + "px";
  }
  */

  connectPrinter() {
    // this.connectPrinterNeo7();
  }

  /*
  connectPrinterNeo7() {
    this.printerConfig.printer = new LabelPrinterNeo7();
    let subsc = this.printerConfig.printer.connect(this.ipAddrForm.value).subscribe(
      (response) => {
      },
      (error) => {
        this.commonService.openErrorDialog(this.commonService.pageTitle, "プリンター接続エラー。");
        this.printerConfig.printer.disconnect();
        // this.connSubsc.unsubscribe();
        this.connSubsc = undefined;
      },
      () => {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, "プリンターとの接続が切れました。");
        this.printerConfig.printer.disconnect();
        // this.connSubsc.unsubscribe();
        this.connSubsc = undefined;
      }
    );
  }
  */

  async connectPrinterTest() {
    let neo7 = new LabelPrinterNeo7B(this.commonService);
    this.printerConfig.printer = neo7;
    this.printerConfig.ipAddr = this.ipAddrForm.value;
    neo7.setIpAddr(this.ipAddrForm.value);
    let result = await neo7.connectAsync(this.ipAddrForm.value, true);

    if (result) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, "接続に成功しました。");
    } else {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "接続に失敗しました。");
    }
  }

  disconnectPrinter() {
    this.printerConfig.printer.disconnect();
    this.connSubsc?.unsubscribe();
    this.connSubsc = undefined;
  }

  onClose() {
    this.printerConfig.labelLayoutKey = this.selectedLayout?.layoutCd;
    this.printerConfig.labelLayoutName = this.selectedLayout?.name;
    this.printerConfig.ipAddr = this.ipAddrForm.value;
    if (this.printerConfig.printer) {
      if (this.printerConfig.printer.getPrinterType() === "neo7") {
        let printer: LabelPrinterNeo7B = (<LabelPrinterNeo7B>this.printerConfig.printer);
        if (this.ipAddrForm.value !== printer.getIpAddr()) {
          printer.testResult = false;
        }
      }
    } else {
      if (this.printerTypeForm.value === "neo7") {
        let printer = new LabelPrinterNeo7B(this.commonService);
        this.printerConfig.printer = printer;
        printer.setIpAddr(this.printerConfig.ipAddr);
      }
    }

    this.dialogRef.close(this.printerConfig);
  }

  openQrcodeDialog() {
    this.openQrcodeDialogBody().subscribe(
      (response) => {
        if (response) {
          this.ipAddrForm.setValue(response);
        }
      }
    );
  }

  openQrcodeDialogBody() : Observable<string> {
    const dialogRef = this.commonService.dialog.open(QrcodeDialogComponent, {
      // width: "330px",
      // maxWidth: "95vw",
      disableClose: true,
      autoFocus: false
    });

    return dialogRef.afterClosed();
  }
}
