import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CtgParamRec } from 'src/app/common/ctg-param-rec';
import { CtgPathRec } from 'src/app/common/ctg-path-rec';
import { ReqParamCtgEdit } from 'src/app/request/req-param-ctg-edit';
import { Subscription } from 'rxjs';
import { RspParamCtgEdit } from 'src/app/response/rsp-param-ctg-edit';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';

@Component({
  selector: 'app-param-ctg-edit-dialog',
  templateUrl: './param-ctg-edit-dialog.component.html',
  styleUrls: ['./param-ctg-edit-dialog.component.css']
})
export class ParamCtgEditDialogComponent implements OnInit, OnDestroy {

  public paramFormGroup: FormGroup;
  public minZaiForm: FormControl;
  public maxZaiForm: FormControl;
  public minStockDaysForm: FormControl;
  public maxStockDaysForm: FormControl;

  /*
  public errorMessageZai: string = "";
  public errorMessageStockDays: string = "";
  */
  public ctgParamRec: CtgParamRec;
  public ctgPathRec: CtgPathRec;
  public subscriptionParamCtgEdit: Subscription;

  public APPLY_LOWER_NON_DIRTY: number = 1;
  public APPLY_LOWER_ALL: number = 2
  public APPLY_LOWER_NONE: number = 3

  public opt_non_dirty_label: string;
  public opt_all_label: string;
  public opt_none_label: string;

  private keypadTargetId: string;

  @ViewChild(NumberKeypadComponent, {static: true}) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public dialogRef: MatDialogRef<ParamCtgEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {ctgParamRec: CtgParamRec, ctgPath: CtgPathRec},
    public httpBasic: HttpBasicService,
    public commonService: CommonService,
    private fb: FormBuilder) { 

    this.ctgParamRec = data.ctgParamRec;
    this.ctgPathRec = data.ctgPath;
  }

  ngOnInit() {
    this.paramFormGroup = this.fb.group({
      // optionForm: [""+this.APPLY_LOWER_NON_DIRTY],
      optionForm: [""+this.APPLY_LOWER_ALL],
      zaiForm: this.fb.group({
          minZaiForm : [
            this.ctgParamRec.minZaiCurrent,
            [Validators.required,
            (fc:FormControl)=> {
              return this.paramRangeValidator(
                fc,
                this.commonService.config.paramCatMinZaiRange.min,
                this.commonService.config.paramCatMinZaiRange.max,
                "最低陳列");
            }]
          ],
          maxZaiForm : [
            this.ctgParamRec.maxZaiCurrent,
            [Validators.required,
            (fc:FormControl)=> {
              return this.paramRangeValidator(
                fc,
                this.commonService.config.paramCatMaxZaiRange.min,
                this.commonService.config.paramCatMaxZaiRange.max,
                "最大陳列");
            }],
          ],
          dummy : ['']
        }, {
          validators: this.zaiValidator
        }),
      stockForm: this.fb.group({
          minStockDaysForm : [
            this.ctgParamRec.minStockDaysCurrent,
            [Validators.required,
            (fc:FormControl)=> {
              return this.paramRangeValidator(
                fc,
                this.commonService.config.paramCatMinStockDaysRange.min,
                this.commonService.config.paramCatMinStockDaysRange.max,
                "最低在庫日数");
            }]
          ],
          maxStockDaysForm : [
            this.ctgParamRec.maxStockDaysCurrent,
            [Validators.required,
            (fc:FormControl)=> {
              return this.paramRangeValidator(
                fc,
                this.commonService.config.paramCatMaxStockDaysRange.min,
                this.commonService.config.paramCatMaxStockDaysRange.max,
                "最大在庫日数");
            }]
          ],
          dummy : ['']
        }, {
          validators: this.stockValidator
        }),
      dummy : ['']
    }, {
      validators: (fg:FormGroup) => {
        this.groupValidator(fg, 
          this.ctgParamRec.minZaiCurrent,
          this.ctgParamRec.maxZaiCurrent,
          this.ctgParamRec.minStockDaysCurrent,
          this.ctgParamRec.maxStockDaysCurrent,
          )
      }
    });
    this.opt_non_dirty_label = "「 " + this.ctgParamRec.ctgNameFv + " 」 以下のカテゴリ（本日未修正）に適用";
    this.opt_all_label = "「 " + this.ctgParamRec.ctgNameFv + " 」 以下のカテゴリ（すべて）に適用";
    this.opt_none_label = "「 " + this.ctgParamRec.ctgNameFv + " 」 カテゴリのみに適用";

    this.minZaiForm = this.paramFormGroup.get("zaiForm").get("minZaiForm") as FormControl;
    this.maxZaiForm = this.paramFormGroup.get("zaiForm").get("maxZaiForm") as FormControl;
    this.minStockDaysForm = this.paramFormGroup.get("stockForm").get("minStockDaysForm") as FormControl;
    this.maxStockDaysForm = this.paramFormGroup.get("stockForm").get("maxStockDaysForm") as FormControl;

    if (!this.commonService.config.paramCatMinZaiEditable) {
      this.paramFormGroup.get("zaiForm").get("minZaiForm").disable();
    }
    if (!this.commonService.config.paramCatMaxZaiEditable) {
      this.paramFormGroup.get("zaiForm").get("maxZaiForm").disable();
    }
    if (!this.commonService.config.paramCatMinStockDaysEditable) {
      this.paramFormGroup.get("stockForm").get("minStockDaysForm").disable();
    }
    if (!this.commonService.config.paramCatMaxStockDaysEditable) {
      this.paramFormGroup.get("stockForm").get("maxStockDaysForm").disable();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionParamCtgEdit) {
      this.subscriptionParamCtgEdit.unsubscribe();
    }
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  is10KeyPadTarget(id: string) {
    if (!this.use10KeyPad()) return false;
    if (id === this.keypadTargetId) return true;
    return false;
  }

  set10KeyPadTarget(id: string, form: FormControl) {
    this.keypadTargetId = id;
    // this.numberKeypadComponent.inputField = form;
    this.numberKeypadComponent.setTargetForm(form);
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  groupValidator(fg:FormGroup, minZai:number, maxZai:number, minStock:number, maxStock:number) {
    if (parseInt(fg.get("zaiForm").get("minZaiForm").value) == minZai &&
        parseInt(fg.get("zaiForm").get("maxZaiForm").value) == maxZai &&
        parseInt(fg.get("stockForm").get("minStockDaysForm").value) == minStock &&
        parseInt(fg.get("stockForm").get("maxStockDaysForm").value) == maxStock ) {
          fg.get("dummy").setErrors({NoChange: "値の変更がありません。"});
    } else {
      fg.get("dummy").setErrors(null);
    }
  }

  zaiValidator(fg:FormGroup) {
    var err = {BadValue: "最低、最大が逆転しています。"};

    if (parseInt(fg.get("minZaiForm").value) > parseInt(fg.get("maxZaiForm").value)) {
      fg.get("dummy").setErrors(err);
    } else {
      fg.get("dummy").setErrors(null);
    }
  }

  stockValidator(fg:FormGroup) {
    var err = {BadValue: "最低、最大が逆転しています。"};

    if (parseInt(fg.get("minStockDaysForm").value) > parseInt(fg.get("maxStockDaysForm").value)) {
      fg.get("dummy").setErrors(err);
    } else {
      fg.get("dummy").setErrors(null);
    }
  }

  paramRangeValidator(fc: FormControl, min:number, max:number, type:string) {
    var err = {"ValueRange": type + "の範囲は [ " + min + " ～ " + max + " ] です。"};
    if (parseInt(fc.value) < min || parseInt(fc.value) > max) {
      return err;
    } else {
      return null;
    }
  }

  doUpdate() {
    var minZai = parseInt(this.paramFormGroup.get("zaiForm").get("minZaiForm").value);
    var maxZai = parseInt(this.paramFormGroup.get("zaiForm").get("maxZaiForm").value);
    var minStockDays = parseInt(this.paramFormGroup.get("stockForm").get("minStockDaysForm").value);
    var maxStockDays = parseInt(this.paramFormGroup.get("stockForm").get("maxStockDaysForm").value);

    var reqParamCtgEdit: ReqParamCtgEdit = {
      access: {...this.commonService.loginUser},
      option: parseInt(this.paramFormGroup.get("optionForm").value),
      paramCtgEdit: {
        storeCdFv: this.ctgParamRec.storeCdFv,
        ctgLevelFn: this.ctgParamRec.ctgLevelFn,
        ctgCd0Fv: this.ctgParamRec.ctgCd0Fv,
        ctgCd1Fv: this.ctgParamRec.ctgCd1Fv,
        ctgCd2Fv: this.ctgParamRec.ctgCd2Fv,
        ctgCd3Fv: this.ctgParamRec.ctgCd3Fv,
        rankFv: this.ctgParamRec.rankFv,
        minZaiFn: this.ctgParamRec.minZaiFn,
        minZaiVersionFn: this.ctgParamRec.minZaiVersionFn,
        minZaiModFn: this.ctgParamRec.minZaiCurrent != minZai ? minZai : this.ctgParamRec.minZaiModFn,
        maxZaiFn: this.ctgParamRec.maxZaiFn,
        maxZaiVersionFn: this.ctgParamRec.maxZaiVersionFn,
        maxZaiModFn: this.ctgParamRec.maxZaiCurrent != maxZai ? maxZai : this.ctgParamRec.maxZaiModFn,
        minStockDaysFn: this.ctgParamRec.minStockDaysFn,
        minStockDaysVersionFn: this.ctgParamRec.minStockDaysVersionFn,
        minStockDaysModFn: this.ctgParamRec.minStockDaysCurrent != minStockDays ? minStockDays : this.ctgParamRec.minStockDaysModFn,
        maxStockDaysFn: this.ctgParamRec.maxStockDaysFn,
        maxStockDaysVersionFn: this.ctgParamRec.maxStockDaysVersionFn,
        maxStockDaysModFn: this.ctgParamRec.maxStockDaysCurrent != maxStockDays ? maxStockDays : this.ctgParamRec.maxStockDaysModFn
      }
    }

    this.subscriptionParamCtgEdit = this.httpBasic.updateCtgParam(reqParamCtgEdit)
      .subscribe(data => this.checkResult(data),
                 error => {
                   this.clearProgressState();
                   this.httpBasic.handleError(error);
                 });

    this.commonService.openSpinner("カテゴリ・パラメータ", "更新中・・・");
  }

  clearProgressState() {
    this.subscriptionParamCtgEdit.unsubscribe();
    this.subscriptionParamCtgEdit = undefined;
    this.commonService.closeSpinner();
  }

  checkResult(response: RspParamCtgEdit) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("カテゴリ・パラメータ", response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog("カテゴリ・パラメータ", response.normalError[0].errMsg);
      return;
    }

    this.ctgParamRec.updMinZai(response.result.minZaiModFn, response.result.minZaiVersionFn);
    this.ctgParamRec.updMaxZai(response.result.maxZaiModFn, response.result.maxZaiVersionFn);
    this.ctgParamRec.updMinStockDays(response.result.minStockDaysModFn, response.result.minStockDaysVersionFn);
    this.ctgParamRec.updMaxStockDays(response.result.maxStockDaysModFn, response.result.maxStockDaysVersionFn);

    this.dialogRef.close();
  }
}