import { DecimalPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqForecastUpDownAddDto } from '../../request/req-forecast-up-down-add';
import { ReqForecastUpDownDeleteDto } from '../../request/req-forecast-up-down-delete';
import { RspForecastUpDown } from '../../response/rsp-forecast-up-down';
import { ForecastUpDownRec } from './forecast-up-down-rec';

@Component({
  selector: 'app-forecast-up-down',
  templateUrl: './forecast-up-down.component.html',
  styleUrls: ['./forecast-up-down.component.css']
})
export class ForecastUpDownComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public tableWidth: any;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];

  public columnDefs: TableColumnDef[] = [
    { columnId: 'storeFv', header: "店舗", width: 150 },
    { columnId: 'ctg0Fv', header: this.commonService.literal.ctg0Name, width: 200 },
    { columnId: 'ctg1Fv', header: this.commonService.literal.ctg1Name, width: 200 },
    { columnId: 'ctg2Fv', header: this.commonService.literal.ctg2Name, width: 200 },
    { columnId: 'ctg3Fv', header: this.commonService.literal.ctg3Name, width: 200 },
    { columnId: 'rankFv', header: "ランク", width: 60, align: "center" },
    { columnId: 'rateFv', header: "倍率", width: 60, align: "right" },
    { columnId: 'dateBeginFv', header: "開始日", width: 100, align: "center" },
    { columnId: 'dateEndFv', header: "終了日", width: 100, align: "center" },
    { columnId: 'noiseCutFv', header: "ノイズカット", width: 100, align: "center" }
  ];

  public stores: LoginStoreDto[];
  public upDownList: ForecastUpDownRec[] = [];
  private subscriptionInit: Subscription;
  private subscriptionStoreCd: Subscription;
  private subscriptionRate: Subscription;
  private subscriptionDateEnd: Subscription;
  private subscriptionDateBegin: Subscription;
  private subscriptionConfirm: Subscription;

  @ViewChild(CtgSelectComponent, { static: true }) public CtgSelectComponent: CtgSelectComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = "予測 UP/DOWN";
    this.commonService.pageTitle = this.commonService.pageMenuName;

    if (this.commonService.stores.length > 1) {
      this.stores = [{ storeCd: "*", storeName: "全店" }, ...this.commonService.stores];
    } else {
      this.stores = [...this.commonService.stores];
    }
    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.storeCd],
      rate: ["1.00"],
      dateBegin: [],
      dateEnd: [],
      noiseCut: [true],
      overwrite: [false],
      rank: [this.commonService.config.ranks[0] || ""]
    }
    );
    this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    this.setDisplayCol();
    this.initForecastUpDown();
    this.subscriptionStoreCd = this.formGroup.get('storeCd').valueChanges
      .subscribe(
        () => {
          this.initForecastUpDownStoreChange();
        }
      );


    this.showHideNoiseCutButton();
    this.subscriptionRate = this.formGroup.get("rate").valueChanges
      .subscribe(
        () => {
          this.showHideNoiseCutButton();
        }
      );
    this.subscriptionDateBegin = this.formGroup.get("dateBegin").valueChanges
      .subscribe(
        () => {
          this.showHideNoiseCutButton();
        }
      );
    this.subscriptionDateEnd = this.formGroup.get("dateEnd").valueChanges
      .subscribe(
        () => {
          this.showHideNoiseCutButton();
        }
      );
  }

  ngOnDestroy() {
    if (this.subscriptionInit) {
      this.subscriptionInit.unsubscribe();
    }
    if (this.subscriptionStoreCd) {
      this.subscriptionStoreCd.unsubscribe();
    }
    if (this.subscriptionRate) {
      this.subscriptionRate.unsubscribe();
    }
    if (this.subscriptionDateBegin) {
      this.subscriptionDateBegin.unsubscribe();
    }
    if (this.subscriptionDateEnd) {
      this.subscriptionDateEnd.unsubscribe();
    }
  }

  setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = ["removeItem"];

    for (var colDef of this.columnDefs) {
      if (colDef.columnId === "storeCdFv" && this.commonService.stores.length <= 1) continue;
      if (colDef.columnId === "ctg0Fv" && this.commonService.config.maxCtgLevel < 1) continue;
      if (colDef.columnId === "ctg1Fv" && this.commonService.config.maxCtgLevel < 2) continue;
      if (colDef.columnId === "ctg2Fv" && this.commonService.config.maxCtgLevel < 3) continue;
      if (colDef.columnId === "ctg3Fv" && this.commonService.config.maxCtgLevel < 4) continue;

      this.displayColumnDefs.push(colDef);
      this.displayColumnIds.push(colDef.columnId);
      this.calcTableWidth();
    }
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 削除ボタン
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }

  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  onClickCell(columnId: string, item: Object) {
  }

  selectTableItem() {
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  initForecastUpDown() {
    this.subscriptionInit = this.httpBasic.initForecastUpDown(this.formGroup.get("storeCd").value)
      .subscribe(
        data => {
          this.checkResult(data);
        },
        error => this.httpBasic.handleError(error)
      );
  }

  transformRate() {
    if (this.isRateValid()) {
      let val = this.decimalPipe.transform(this.formGroup.get("rate").value, "1.2-2");
      this.formGroup.get("rate").setValue(val);
    } else {
      this.formGroup.get("rate").setValue("1.00");
    }
  }

  searchCtg() {
    if (this.formGroup.get("storeCd").value === "*") {
      this.CtgSelectComponent.setStoreCd("");
    }
    else {
      this.CtgSelectComponent.setStoreCd(this.formGroup.get("storeCd").value);
    }
  }

  initForecastUpDownStoreChange() {
    this.subscriptionInit = this.httpBasic.initForecastUpDown(this.formGroup.get("storeCd").value)
      .subscribe(
        data => {
          this.checkResult(data);
          this.searchCtg();
        },
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error)
        }
      );
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
  }

  isValidSubmit() {
    if (this.ctgSelectCondition.formGroup.get("ctgCd0").value === "") return false;
    if (this.formGroup.get("rank").value == null || this.formGroup.get("rank").value === "") return false;
    if (this.formGroup.get("dateBegin").value == null) return false;
    if (this.formGroup.get("dateEnd").value == null) return false;
    if (this.formGroup.get("rate").value == null) return false;
    if (this.formGroup.get("rate").value <= 0) return false;

    return true;
  }

  getDateBeginMin() {
    if (this.formGroup.get('dateEnd').value != null && this.formGroup.get('dateBegin').value > this.formGroup.get('dateEnd').value)
      this.formGroup.get('dateEnd').setValue(null);

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

  getDateEndMin() {
    if (this.formGroup.get('dateBegin').value != null) {
      return this.formGroup.get('dateBegin').value;
    }

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

  doSubmit() {
    // rate out of range config
    if (this.parseFloatCustom(this.formGroup.get("rate").value) < this.commonService.config.forecastRangeMin
      || this.parseFloatCustom(this.formGroup.get("rate").value) > this.commonService.config.forecastRangeMax) {
      this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, "倍率が推奨範囲外です。登録しますか。")
        .subscribe(
          (data) => {
            this.subscriptionConfirm = undefined;
            if (data == true) {
              // beginDate -> endDate > 7 days
              if (this.calcDateToDate(this.formGroup.get("dateBegin").value, this.formGroup.get("dateEnd").value) > 7) {
                this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, "期間が７日を超えています。登録しますか。")
                  .subscribe(
                    (data) => {
                      this.subscriptionConfirm = undefined;
                      if (data == true) {
                        this.doSubmitBody();
                      }
                    }
                  );
                return;
              }
              this.doSubmitBody();
            }
          }
        );
      return;
    }
    // beginDate -> endDate > 7 days
    if (this.calcDateToDate(this.formGroup.get("dateBegin").value, this.formGroup.get("dateEnd").value) > 7) {
      this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, "期間が７日を超えています。登録しますか。")
        .subscribe(
          (data) => {
            this.subscriptionConfirm = undefined;
            if (data == true) {
              this.doSubmitBody();
            }
          }
        );
      return;
    }
    this.doSubmitBody();
  }

  doSubmitBody() {
    let storeName: string = this.stores.filter(store => store.storeCd === this.formGroup.get("storeCd").value)[0].storeName;
    if (storeName == undefined || storeName == null)
      return;
    let request: ReqForecastUpDownAddDto = {
      storeCdFv: this.formGroup.get("storeCd").value,
      storeNameFv: storeName,

      ctg0CdFv: this.ctgSelectCondition.formGroup.get("ctgCd0").value,
      ctg0NameFv: this.ctgSelectCondition.ctg0Name,

      ctg1CdFv: this.ctgSelectCondition.formGroup.get("ctgCd1").value,
      ctg1NameFv: this.ctgSelectCondition.ctg1Name,

      ctg2CdFv: this.ctgSelectCondition.formGroup.get("ctgCd2").value,
      ctg2NameFv: this.ctgSelectCondition.ctg2Name,

      ctg3CdFv: this.ctgSelectCondition.formGroup.get("ctgCd3").value,
      ctg3NameFv: this.ctgSelectCondition.ctg3Name,

      ctgLevelFn: this.ctgSelectCondition.formGroup.get("ctgLevel").value,
      rankFv: this.formGroup.get("rank").value,
      dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
      dateEndFv: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US'),
      rateFn: parseFloat(this.formGroup.get("rate").value.replace(',', '')),
      noiseCutFn: (this.formGroup.get("noiseCut").disabled) ? 0 : this.parseIntFromBoolean(this.formGroup.get("noiseCut").value),
      overwriteFn: this.parseIntFromBoolean(this.formGroup.get("overwrite").value)

    }

    this.subscriptionInit = this.httpBasic.addForecastUpDown(request)
      .subscribe(data =>
        this.checkResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        });

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
  }

  checkResult(response: RspForecastUpDown) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    this.upDownList = [];
    for (var forecastUpDown of response.result) {
      this.upDownList.push(new ForecastUpDownRec(forecastUpDown, this.decimalPipe));
    }
  }

  doCancel() {
    this.ctgSelectCondition.formGroup.controls["ctgCd0"].setValue("");
    this.formGroup.controls["dateBegin"].setValue(null);
    this.formGroup.controls["dateEnd"].setValue(null);
    this.formGroup.controls["rank"].setValue(this.commonService.config.ranks[0] || "");
    this.formGroup.controls["rate"].setValue("1.00");
    this.formGroup.controls["noiseCut"].setValue(true);
    this.formGroup.controls["overwrite"].setValue(false);
  }

  isHiddenDeleteButton(storeCdFv: string) {
    if (this.commonService.stores.length > 1) return false;
    return (storeCdFv === "*") ? true : false;
  }

  doDelete(forecastUpDown: ForecastUpDownRec) {
    /*
    let msg = "次の予測UP/DOWNを削除しますか？";
    if (forecastUpDown.storeCdFv != undefined && forecastUpDown.storeCdFv !== "")
      msg = msg + "<br><br>店舗: " + forecastUpDown.storeFv;
    if (forecastUpDown.ctg0CdFv != undefined && forecastUpDown.ctg0CdFv !== "")
      msg = msg + "<br>" + this.commonService.literal.ctg0Name + ": " + forecastUpDown.ctg0Fv;
    if (forecastUpDown.ctg1CdFv != undefined && forecastUpDown.ctg1CdFv !== "")
      msg = msg + "<br>" + this.commonService.literal.ctg1Name + ": " + forecastUpDown.ctg1Fv;
    if (forecastUpDown.ctg2CdFv != undefined && forecastUpDown.ctg2CdFv !== "")
      msg = msg + "<br>" + this.commonService.literal.ctg2Name + ": " + forecastUpDown.ctg2Fv;
    if (forecastUpDown.ctg3CdFv != undefined && forecastUpDown.ctg3CdFv !== "")
      msg = msg + "<br>" + this.commonService.literal.ctg3Name + ": " + forecastUpDown.ctg3Fv;

      if (forecastUpDown.rankFv != undefined && forecastUpDown.rankFv !== "")
      msg = msg + "<br>ランク: " + forecastUpDown.rankFv;
    if (forecastUpDown.rateFn != undefined)
      msg = msg + "<br>倍率: " + forecastUpDown.rateFn;
    if (forecastUpDown.dateBeginFv != null)
      msg = msg + "<br>開始日: " + forecastUpDown.dateBeginFv;
    if (forecastUpDown.dateEndFv != null)
      msg = msg + "<br>終了日: " + forecastUpDown.dateEndFv;
    */

    let msg = "次の予測UP/DOWNを削除しますか？<br>";
    msg += "<br><table class='no-border'>";
    if (forecastUpDown.storeCdFv != undefined && forecastUpDown.storeCdFv !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>店舗：　</td>" + "<td class='no-border'>" + forecastUpDown.storeFv + "</td>";
      msg += "</tr>";
    }
    if (forecastUpDown.ctg0CdFv != undefined && forecastUpDown.ctg0CdFv !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>" + this.commonService.literal.ctg0Name + "：　</td>" + "<td class='no-border'>" + forecastUpDown.ctg0Fv + "</td>";
      msg += "</tr>";
    }
    if (forecastUpDown.ctg1CdFv != undefined && forecastUpDown.ctg1CdFv !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>" + this.commonService.literal.ctg1Name + "：　</td>" + "<td class='no-border'>" + forecastUpDown.ctg1Fv + "</td>";
      msg += "</tr>";
    }
    if (forecastUpDown.ctg2CdFv != undefined && forecastUpDown.ctg2CdFv !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>" + this.commonService.literal.ctg2Name + "：　</td>" + "<td class='no-border'>" + forecastUpDown.ctg2Fv + "</td>";
      msg += "</tr>";
    }
    if (forecastUpDown.ctg3CdFv != undefined && forecastUpDown.ctg3CdFv !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>" + this.commonService.literal.ctg3Name + "：　</td>" + "<td class='no-border'>" + forecastUpDown.ctg3Fv + "</td>";
      msg += "</tr>";
    }
    if (forecastUpDown.rankFv != undefined && forecastUpDown.rankFv !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>ランク：　</td>" + "<td class='no-border'>" + forecastUpDown.rankFv + "</td>";
      msg += "</tr>";
    }
    if (forecastUpDown.rateFn != undefined) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>倍率：　</td>" + "<td class='no-border'>" + forecastUpDown.rateFn + "</td>";
      msg += "</tr>";
    }
    if (forecastUpDown.dateBeginFv != null) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>開始日：　</td>" + "<td class='no-border'>" + forecastUpDown.dateBeginFv + "</td>";
      msg += "</tr>";
    }
    if (forecastUpDown.dateEndFv != null) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>終了日：　</td>" + "<td class='no-border'>" + forecastUpDown.dateEndFv + "</td>";
      msg += "</tr>";
    }
    msg += "</table>";
  
    this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      (data) => {
        this.subscriptionConfirm = undefined;
        if (data == true) {
          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          let request: ReqForecastUpDownDeleteDto = {
            searchStoreCdFv: this.formGroup.get("storeCd").value,
            storeCdFv: forecastUpDown.storeCdFv,
            ctg0CdFv: forecastUpDown.ctg0CdFv || "",
            ctg1CdFv: forecastUpDown.ctg1CdFv || "",
            ctg2CdFv: forecastUpDown.ctg2CdFv || "",
            ctg3CdFv: forecastUpDown.ctg3CdFv || "",
            rankFv: forecastUpDown.rankFv,
            dateBeginFv: forecastUpDown.dateBeginFv,
            dateEndFv: forecastUpDown.dateEndFv
          };
          this.subscriptionInit = this.httpBasic.deleteForecastUpDown(request)
            .subscribe(
              data => this.checkResult(data),
              error => {
                this.clearProgressState();
                this.httpBasic.handleError(error);
              }
            )
        }
      }
    );
  }

  parseFloatCustom(x: string) {
    const parsed = parseFloat(x.replace(',', ''));
    if (isNaN(parsed)) { return -1; }
    return parsed;
  }

  parseIntFromBoolean(x: boolean) {
    if (x == undefined || x == null)
      return 0;
    return x ? 1 : 0;
  }

  showHideNoiseCutButton() {
    if (this.parseFloatCustom(this.formGroup.get("rate").value) <= 1 || this.calcDateToDate(this.formGroup.get("dateBegin").value, this.formGroup.get("dateEnd").value) > 7) {
      this.formGroup.get("noiseCut").disable();
    } else {
      this.formGroup.get("noiseCut").enable();
    }
  }

  isRateValid() {
    if (this.formGroup.get("rate").value.trim() === "")
      return false;
    if (isNaN(this.formGroup.get("rate").value))
      return false;
    if (this.parseFloatCustom(this.formGroup.get("rate").value) < 0 || this.parseFloatCustom(this.formGroup.get("rate").value) >= 10000)
      return false;
    return true;
  }

  calcDateToDate(begin: string, end: string) {
    let dateBegin = new Date(begin);
    let dateEnd = new Date(end);

    if (begin == null) return 0;
    return (dateEnd.getTime() - dateBegin.getTime()) / (1000 * 3600 * 24);
  }
}
