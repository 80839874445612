import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { RspSpbs00031Search, RspSpbs00031SearchDto } from 'src/app/response/rsp-spbs00031-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Spbs00031UpdateDto } from 'src/app/request/req-spbs00031-update';
import { DropdownList } from 'src/app/request/req-dropdown-list';
import { InvMonthSearchDto } from 'src/app/request/req-inventory-month-list';
import { MessageService } from 'src/app/service/message.service';
import * as MessageID from 'src/app/common/const-message-id';
import { ReqSpbs00031Search } from 'src/app/request/req-spbs00031-search';
import * as config from 'src/assets/config.json';

@Component({
  selector: 'app-spbs00031',
  templateUrl: './spbs00031.component.html',
  styleUrls: ['./spbs00031.component.css']
})

export class Spbs00031Component implements OnInit, OnDestroy {

  @ViewChild("sectionSearchArea", { static: true }) sectionSearchArea: SectionHeaderComponent;
  @ViewChild("sectionSearchResult", { static: true }) sectionSearchResult: SectionHeaderComponent;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  private subscriptionInit: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscriptionSearch: Subscription;
  private dropdownSubscription: Subscription;
  private subscriptionPreview: Subscription;
  private subscriptionUpdate: Subscription;

  public formGroup: FormGroup;

  public tableWidth: any;
  public columnIds: any = ['INSTRUCTIONID','STORECD','STORENM','INSTRUCTIONUSRCD','IMPORTFILENM','CONFIRMDATE','FINISHDATE','SHOPCOMMENT'];
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'INSTRUCTIONID', header: "指示番号", width: 100, align: "right" },
    { columnId: 'STORECD', header: "店舗コード", width: 100, align: "right" },
    { columnId: 'STORENM', header: "店舗名称", width: 100, align: "left" },
    { columnId: 'INSTRUCTIONUSRCD', header: "指示担当者", width: 100, align: "left" },
    { columnId: 'IMPORTFILENM', header: "指示ファイル名", width: 100, align: "left" },
    { columnId: 'CONFIRMDATE', header: "確認情報", width: 100, align: "center" },
    { columnId: 'FINISHDATE', header: "完了報告", width: 100, align: "center" },
    { columnId: 'SHOPCOMMENT', header: "店舗コメント", width: 400, align: "left" }
  ];
  public stores: any[];
  public instructionUsers: any[];
  public paginatorMaxWidth: any;
  public dateMessage: any;
  public recordCount: number = 0;
  private isQueryRequested: boolean = false;
  public confirmConditions: any[] = [];
  
  public CMPNYCD : string;

  public resultList: RspSpbs00031SearchDto[] = [];
  public savedList: RspSpbs00031SearchDto[] = [];

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    private messageEntity: MessageService
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.initFormGroup();
    this.calcTableWidth();
    this.initList();
  }
  
  async initList(){
    await this.fnDropdown();
    await this.getStoreLists();
  }

  getStoreLists(): Promise<void>{
    return new Promise((resolve, reject) => {
      var request: InvMonthSearchDto = {
        storeCd: this.commonService.loginUser.realStoreCd,
        cmpyCd: this.CMPNYCD,
        access: { ...this.commonService.loginUser },
      };
      this.subscriptionSearch = this.httpBasic.generalRequest("GetStoreList", request).subscribe(
        response => {
          this.receiveStoreList(response);
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.subscriptionSearch.unsubscribe();
          this.subscriptionSearch = undefined;
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveStoreList(response) {
    if (this.httpBasic.handleAppError(response)) return;

    this.stores = [];
    if (response.rows.length > 0) {
      this.CMPNYCD = response.rows[0].cmpyCd;
      this.stores = response.rows;
      // this.formGroup.get("storeCd").setValue(response.rows[0].storeCd);
    }
  }

  fnDropdown(){
     // リクエスト
    var request: DropdownList = {
      RCDKBNS: ["0142"],
      CMPNYCD: this.CMPNYCD,
      access: { ...this.commonService.loginUser },
    };
    this.dropdownSubscription = this.httpBasic.generalRequest("GetDropdownList", request).subscribe(
      response => {
        this.confirmConditions = this.fnFilterRCDKBN("0142",response.rows);
      },
      error => {
        this.commonService.closeSpinner();
        this.dropdownSubscription.unsubscribe();
        this.dropdownSubscription = undefined;
        this.httpBasic.handleError(error);
      }
    );
  }

  fnFilterRCDKBN(rcdkbn: String, listRCDKBN: any) {
    return listRCDKBN.filter((el: any) => el.RCDKBN == rcdkbn)
  }

  ngOnDestroy() {
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      storeCd: "",//this.commonService.loginUser.realStoreCd,
      instructionUserCd : "",
      dateBegin : "",
      dateEnd : "",
      condition : ""
    });
  }

  doCancel() {
    this.formGroup.controls["storeCd"].setValue('');
    this.formGroup.controls["instructionUserCd"].setValue('');
    this.formGroup.controls["dateBegin"].setValue(null);
    this.formGroup.controls["dateEnd"].setValue(null);
    this.formGroup.controls["condition"].setValue("");
    this.resultList = [];
    this.recordCount = 0;
  }

  doSearch() {
    this.matPaginator.pageIndex = 0;
    this.isQueryRequested = true;
    this.querySearch();
  }

  querySearch() {
    this.isQueryRequested = true;
    let dateBegin =  this.formGroup.get("dateBegin")?.value ? formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US') : "";
    let dateEnd = this.formGroup.get("dateEnd")?.value ? formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US') : "";
    var request: ReqSpbs00031Search = {
      access: { ...this.commonService.loginUser },
      spbs00031Search: {
        storeCdFv: this.formGroup.get("storeCd").value,
        instructionUserCdFv: this.formGroup.get("instructionUserCd").value,
        dateBeginFv: dateBegin,
        dateEndFv: dateEnd,
        conditionFv: this.formGroup.get("condition").value
      },
      page: {
        pageNum: this.matPaginator.pageIndex,
        dispNum: this.matPaginator.pageSize
      }
    }

    this.subscriptionSearch = this.httpBasic.searchHeadquaterInstructionConfirm(request).subscribe(
      data => this.SearchResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
  }

  pageChanged(pageEvent: PageEvent) {
    if (!this.isQueryRequested)
      return;
    this.keepData();
    this.querySearch();
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }
  
  SearchResult(data: RspSpbs00031Search) {
    this.clearProgressState();
    let searchResult: RspSpbs00031Search = { ...data };
    if (searchResult.fatalError && searchResult.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, searchResult.fatalError[0].errMsg);
    }
    if (searchResult.normalError && searchResult.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, searchResult.normalError[0].errMsg);
      return;
    }

    this.resultList = [];
    this.recordCount = searchResult.recordCount;
    if (this.recordCount > 0) {
      let resultDtos: RspSpbs00031SearchDto[] = [...searchResult.rows];
      for (let i = 0; i < resultDtos.length; i++) {
        resultDtos[i]["CONFIRMCHECK"] = resultDtos[i]['CONFIRMDATE'] != "" && resultDtos[i]['CONFIRMDATE'] != null ? true : false;
        resultDtos[i]["COMPLETECHECK"] = resultDtos[i]['FINISHDATE'] != "" && resultDtos[i]['FINISHDATE'] != null ? true : false;
        resultDtos[i]["SHOPCOMMENTMESSAGE"] = "";
        for(let j = 0; j < this.savedList.length ; j++){
          if(resultDtos[i]["STORECD"] == this.savedList[j]["STORECD"] && resultDtos[i]["INSTRUCTIONID"] == this.savedList[j]["INSTRUCTIONID"]){
            resultDtos[i]["CONFIRMCHECK"] = this.savedList[j]["CONFIRMCHECK"];
            resultDtos[i]["COMPLETECHECK"] = this.savedList[j]["COMPLETECHECK"];
            resultDtos[i]["SHOPCOMMENT"] = this.savedList[j]["SHOPCOMMENT"];
            resultDtos[i]["SHOPCOMMENTMESSAGE"] = this.savedList[j]["SHOPCOMMENTMESSAGE"];
          }
        }
        this.resultList.push(resultDtos[i]);
      }
    }
  }

  doUpdate(){
    this.keepData();
    if(this.fnConfirmValidation()){
      var request: Spbs00031UpdateDto[] = this.savedList;
      this.subscriptionUpdate = this.httpBasic.updateHeadquaterInstructionConfirm(request).subscribe(
        result => this.checkResult(result),
        error => {
          this.commonService.closeSpinner();
          this.subscriptionUpdate.unsubscribe();
          this.subscriptionUpdate = undefined;
          this.httpBasic.handleError(error);
        }
      );
    }else{
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.messageEntity.message[MessageID.ME100020]);
    }
  }

  keepData(){
    let keyExist = false;
    for (let i = 0; i < this.resultList.length; i++) {
      keyExist = false;
      for(let j = 0; j < this.savedList.length; j++){
        if(this.resultList[i]["STORECD"] == this.savedList[j]["STORECD"] && this.resultList[i]["INSTRUCTIONID"] == this.savedList[j]["INSTRUCTIONID"]){
          keyExist = true;
          this.savedList[j]["CONFIRMCHECK"] = this.resultList[i]["CONFIRMCHECK"];
          this.savedList[j]["COMPLETECHECK"] = this.resultList[i]["COMPLETECHECK"];
          this.savedList[j]["SHOPCOMMENT"] = this.resultList[i]["SHOPCOMMENT"];
          this.savedList[j]["SHOPCOMMENTMESSAGE"] = this.resultList[i]["SHOPCOMMENTMESSAGE"];
        }
      }
      if(!keyExist){
        this.savedList.push(this.resultList[i]);
      }
    }
  }

  checkResult(response){
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    
    this.savedList = [];
    this.doSearch();
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    for (var col of this.columnDefs) {
      width = width + col.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
    this.paginatorMaxWidth = { "max-width": "" + width + "px" };
  }

  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }

  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  setDateType(type: string) {
    if (type == "発注日") {
      this.formGroup.get('dateType').setValue("1");
    } else {
      this.formGroup.get('dateType').setValue("2");
    }
  }

  setDefaultPage() {
    this.matPaginator.pageIndex = 0;
    this.recordCount = 0;
    this.isQueryRequested = false;
  }

  previewFileSubTask() {
    let dateBegin =  this.formGroup.get("dateBegin")?.value ? formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US') : "";
    let dateEnd = this.formGroup.get("dateEnd")?.value ? formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US') : "";
    let request = {
    access: { ...this.commonService.loginUser },
      "hqInstructionConfirmCondition": {
        "storeCdFv": this.formGroup.get("storeCd").value,
        "instructionUserCdFv": this.formGroup.get("instructionUserCd").value,
        "condition" : this.formGroup.get("condition").value,
        "dateBegin": dateBegin,
        "dateEnd": dateEnd
      },
      screenCode: "SPBS00031"
    };

    this.subscriptionUpdate = this.httpBasic.generalRequest("HeaderInstructionConfirmPdfOutput",request).subscribe(
      result => this.printPdf(result),
      error => {
        this.commonService.closeSpinner();
        this.subscriptionPreview.unsubscribe();
        this.subscriptionPreview = undefined;
        this.httpBasic.handleError(error);
      }
    );
  }

  printPdf(createPdfResponse: any){
    if (createPdfResponse?.pdfInfo?.fileName) {
      let param = "";
      param += "?usrcd=" + this.commonService.loginUser.userId;
      param += "&lang=" + 'JPN';//this.commonService.loginUser.lang;
      param += "&cmpnycd=" + this.CMPNYCD;//this.loginInfo.cmpnycd;
      param += "&storecd=" + this.commonService.loginUser.storeCd;
      param += "&token=" + this.commonService.loginUser.token;
      param += "&filename=" + createPdfResponse.pdfInfo.fileName;
      param += "&opeDateTime=" + createPdfResponse.pdfInfo.OPEDATETIME;

      // 別のタブを開く
      window.open(config.config.wsBaseUrl + 'Spbs00031PdfDownload' + param, '_blank');

    } else {
      this.httpBasic.handleError("error");
    }
  }

  fnConfirmValidation() {
    let result = true;
    for(let savedData of this.savedList){
      let cmt =savedData["SHOPCOMMENT"]
      if (!(cmt == "" || cmt == null)&& cmt.length > 400) {
        savedData["SHOPCOMMENTMESSAGE"] = this.messageEntity.message[MessageID.ME100011].replace("%1", 400);
        result = false;
        break;
      }else{
        savedData["SHOPCOMMENTMESSAGE"] = "";
      }
    }
    return result;
  }
}
