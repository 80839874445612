import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqGetPromRefList, RspGetPromRefList } from 'src/app/webservice/promOrder';
import { PromRef } from '../0_def/promRef';

@Component({
  selector: 'app-prom-ref-dialog',
  templateUrl: './prom-ref-dialog.component.html',
  styleUrls: ['./prom-ref-dialog.component.css']
})
export class PromRefDialogComponent implements OnInit, OnDestroy {

  public static qryPromCode: string = "";
  public static qryPromName: string = "";
  public static qryDateBegin: Date;
  public static qryDateEnd: Date;
  public maxDate: Date;
  public dateBegin;
  public dateEnd;

  public formQryPromCode: FormControl = new FormControl("");
  public formQryPromName: FormControl = new FormControl("");

  public promList: PromRef[];
  public promShowList: PromRef[];
  public recordCount: number = 0;
  public columnIds: string[] = ["promCd", "promName", "dateBegin", "dateEnd",];
  public columnDefs:  TableColumnDef[] = [
    {columnId: "promCd", header: "企画コード", width: 100, align: "center"},
    {columnId: "promName", header: "企画名", width: 300, align: "leftr"},
    {columnId: "dateBegin", header: "開始日", width: 100, align: "center"},
    {columnId: "dateEnd", header: "終了日", width: 100, align: "center"}
  ];

  public selectedProm: PromRef;

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(DateRangeComponent, {static: true}) dateRangeComponent: DateRangeComponent;

  constructor(
    public dialogRef: MatDialogRef<PromRefDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {storeCd: string, type: string},
    public commonService: CommonService,
    private httpBasic: HttpBasicService) {
  }

  ngOnInit(): void {
    this.formQryPromCode.setValue(PromRefDialogComponent.qryPromCode);
    this.formQryPromName.setValue(PromRefDialogComponent.qryPromName);

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 1);
    if (PromRefDialogComponent.qryDateBegin) {
      this.dateBegin = this.commonService.copyDate(PromRefDialogComponent.qryDateBegin);
      this.dateEnd = this.commonService.copyDate(PromRefDialogComponent.qryDateEnd);
    } else {
      this.dateBegin = this.commonService.copyDate(this.maxDate);
      this.dateBegin.setMonth(this.dateBegin.getMonth() - 3);
      this.dateEnd = this.commonService.copyDate(this.maxDate);
      PromRefDialogComponent.qryDateBegin = this.commonService.copyDate(this.dateBegin);
      PromRefDialogComponent.qryDateEnd = this.commonService.copyDate(this.dateEnd);
    }
  }

  ngOnDestroy(): void {
    
  }

  qryProm() {
    PromRefDialogComponent.qryPromCode = this.formQryPromCode.value;
    PromRefDialogComponent.qryPromName = this.formQryPromName.value;
    let dateRange = this.dateRangeComponent.getDateRange();
    PromRefDialogComponent.qryDateBegin = dateRange.dateBegin;
    PromRefDialogComponent.qryDateEnd = dateRange.dateEnd;

    let request: ReqGetPromRefList = {
      access: this.commonService.loginUser,
      storeCd: this.data.storeCd,
      promCd: this.formQryPromCode.value,
      promName: this.formQryPromName.value,
      promTypes: [],
      qryType: this.data.type,
      dateBegin: this.commonService.formatDate(dateRange.dateBegin),
      dateEnd: this.commonService.formatDate(dateRange.dateEnd)
    };
    if (this.commonService.config.promOrderPromTypes) {
      this.commonService.config.promOrderPromTypes.forEach((promType) => {
        request.promTypes.push(promType);
      });
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetPromRefList", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveProm(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveProm(response: RspGetPromRefList) {
    if (this.httpBasic.handleAppError(response)) return;

    this.promList = [];
    response.rows.forEach((row) => {
      let promCd = row.promCd;
      let prom: PromRef;
      prom = {
        storeCd:        row.storeCd,
        promCd:         promCd,
        promName:       row.promName,
        promDispName:   promCd + "：" + row.promName,
        dateBegin:      row.dateBegin,
        dateEnd:        row.dateEnd,
      };
      this.promList.push(prom);
    });
    this.recordCount = this.promList.length;
    if (this.matPaginator) {
      this.matPaginator.pageIndex = 0;
    }
    this.pageChanged();
  }

  selectProm(prom: PromRef) {
    this.selectedProm = prom;
  }

  pageChanged() {
    this.promShowList = [];
    let start;
    let end;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.promList.length) end = this.promList.length;
    for (let i = start; i < end; i++) {
      this.promShowList.push(this.promList[i]);
    }
  }

  onSelect() {
    this.dialogRef.close(this.selectedProm);
  }

}
