import { CommonService } from 'src/app/service/common.service';
import { RspSubTaskResultOtherStoresCommentRowDto, TaskImageRecDto } from 'src/app/webservice/task';
import { TaskService } from '../1_service/taskService';
import { SubTask } from './taskDefs';

export class SubTaskOtherStoresResultCommentRecDto {

  public taskId: number;
  public storeCd: string;
  public storeName: string;
  public subTaskId: number;
  public subTaskFv: string;
  public subResponsible: string;
  public isComplete: number;
  public completeDateTime: string;
  public imageList: TaskImageRecDto[];
  public isShowIcon: boolean = false;
  public storeNameWithCode: string = '';
  public subTasks: SubTask[] = [];
  public description: string = '';
  public subTaskName: string;
  public completeStatus: string = '';
  public comment: string = "";

  constructor(
    rowDto: RspSubTaskResultOtherStoresCommentRowDto,
    private commonService: CommonService,
    private taskService: TaskService
  ) {
    this.patchValue(rowDto);
  }

  patchValue(obj: Object): void {
    for (let key in obj) {
      this[key] = obj[key];
    }

    if (this.storeCd && this.storeName) {
      this.storeNameWithCode = `${this.storeCd}:${this.storeName}`;
    } else {
      this.storeNameWithCode = this.storeCd || this.storeName || ''
    }

    this.completeStatus = (this.isComplete && this.isComplete === 1) ? '完了' : '未完了'

    try {
      this.subTasks = JSON.parse(this.subTaskFv)
      this.description = this.subTasks.find((item) => item.subTaskId == this.subTaskId).description;
      this.subTaskName = this.subTasks.find((item) => item.subTaskId == this.subTaskId).subTaskName;

    } catch (error) {
      this.subTasks = []
    }
  }
}
