import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { TableColumnDef } from "src/app/common/table-column-def";
import { ReqAccess } from "src/app/request/req-access";
import { ConfirmKbn, ReqSpmt10341Confirm } from "src/app/request/req-spmt10341-get-confirm";
import { RspSpmt10341SearchDto } from "src/app/response/rsp-spmt10341-search";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";

export interface ReqSpmt10341ListSelectDialog {
  confirmKbn: ConfirmKbn;
  messageLoading: string;
  messageSuccess: string;
  messageQuestion: string;
  title: string;
  data: RspSpmt10341SearchDto[];
  access: ReqAccess | any;
}

@Component({
  selector: "app-spmt10341-list-select-dialog",
  templateUrl: "./spmt10341-list-select-dialog.component.html",
  styleUrls: ["./spmt10341-list-select-dialog.component.css"],
})
export class Spmt10341ListSelectDialogComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<RspSpmt10341SearchDto> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10341ListSelectDialogComponent>,
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    @Inject(MAT_DIALOG_DATA) public data: ReqSpmt10341ListSelectDialog
  ) {
    this.dataSource.data = this.data.data;
  }

  get ConfirmKbn() {
    return ConfirmKbn;
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    // do nothing
  }

  calcTableWidth() {
    let width = 1; // For left border
    for (let colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;
    }
    return { width: "" + width + "px" };
  }

  displayedColumns: string[] = [
    "productCd",
    "productName",
    "standardName",
    "storeApprovalStatusNm",
    "urgentOrderNm",
    "officeInputNm",
    "orderQty",
    "stockQty",
    "orderRequestQty",
    "requestMsg",
    "storeComment",
  ];

  columnDefs: TableColumnDef[] = [
    { columnId: "productCd", header: "商品コード", width: 100 },
    { columnId: "productName", header: "商品名称", width: 150 },
    { columnId: "standardName", header: "規格", width: 70 },
    { columnId: "storeApprovalStatusNm", header: "ステータス", width: 70, align: "left" },
    { columnId: "urgentOrderNm", header: "緊急発注フラグ", width: 90 },
    { columnId: "officeInputNm", header: "本部発注フラグ", width: 90 },
    { columnId: "orderQty", header: "入数", width: 50, align: "right", numberPipe: "1.0-0" },
    { columnId: "stockQty", header: "在庫数", width: 60, align: "right", numberPipe: "1.0-0" },
    {
      columnId: "orderRequestQty",
      header: "申請数",
      width: 60,
      align: "right", numberPipe: "1.0-0" 
    },
    { columnId: "requestMsg", header: "メッセージ", width: 150 },
    {
      columnId: "storeComment",
      header: "コメント",
      width: 180,
    },
  ];

  btnConfirmClick() {
    const yesNoRef = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.data.messageQuestion);
    yesNoRef.subscribe((res) => {
      if (res) {
        let req: ReqSpmt10341Confirm = {
          access: this.data.access,
          requestBody: {
            confirmKbn: this.data.confirmKbn,
            confirmList: this.dataSource.data,
          },
        };
        this.httpBasic.beforeRequestStart(this.data.messageLoading);
        this.httpBasic.spmt10341Confirm(req).subscribe({
          next: (res) => {
            if (this.httpBasic.handleAppError(res)) return;
            this.dialogRef.close(true);
          },
          error: (err) => {
            this.httpBasic.afterRequestError(err);
          },
          complete: () => {
            this.httpBasic.afterRequestComplete();
          },
        });
      }
    });
  }
}
