import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import Quagga from 'quagga'; // ES6
import { CommonService } from '../../service/common.service';

export interface CameraData {
  src: string;
};

@Component({
  selector: 'app-barcode-dialog',
  templateUrl: './barcode-dialog.component.html',
  styleUrls: ['./barcode-dialog.component.css']
})
export class BarcodeDialogComponent implements OnInit, OnDestroy {

  private codes: string[] = [];
  private code: string;
  private isDetected: boolean = false;
  private lastScannedCodeDate: number = 0;

  constructor(
    public dialogRef: MatDialogRef<BarcodeDialogComponent>,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.commonService.audioBeep = new Audio();
    this.commonService.audioBeep.src = "assets/audio/beep.mp3";
    this.commonService.audioBeep.load();

    this.startScan();
  }

  ngOnDestroy() {
    // Quagga.stop();
  }

  startScan() {
    let readers = ["ean_reader"];
    if (this.commonService.config.barcodeReaders) {
      readers = this.commonService.config.barcodeReaders;
    }

    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          target: document.querySelector('#interactive.viewport'),
          locate: false,
          constraints: {
            // facingMode: "user"                     // フロントカメラ
            facingMode: 'environment',                // リアカメラ
            successTimeout: 500,
            width: 640,
            height: 480
          }
        },
        locator: {
          patchSize: 'medium',
          halfSample: true
        },
        numOfWorkers: (navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 4),
        singleChannel: false,     // true, // true: only the red color-channel is read
        decoder: {
          readers: readers,
          debug: {
            drawBoundingBox: true,
            showFrequency: false,
            drawScanline: true,
            showPattern: false
          },
        },
        multiple: false
      },
      (err) => {
        if (err) {
          this.handleError('この端末ではご利用いただけない機能です')
          return
        }
        this.codes = [];
        this.isDetected = false;
        Quagga.start();
        Quagga.onDetected((res) => {
          this.onBarcodeScanned(res.codeResult);
        });
      }
    );
  }

  onBarcodeScanned(codeResult: any) {
    if (this.isDetected) return;
    this.isDetected = true;
    /*
    const now = new Date().getTime();
    if (this.lastScannedCodeDate > 0 && now < this.lastScannedCodeDate + 50) {
      this.isDetected = false;
      return;
    }
    this.lastScannedCodeDate = now;
    */

    this.codes.push(codeResult.code);
    if (this.codes.length < 3) {
      this.isDetected = false;
      return
    }
    let isSameAll = false
    if (this.codes.every((v) => v === this.codes[0])) {
      isSameAll = true
    }
    if (!isSameAll) {
      this.codes.shift();
      this.isDetected = false;
      return
    }

    this.code = this.codes[0];
    switch (codeResult.format as string) {
      case "codabar":
      case "code_39":
        // remove start/stop character
        let len = this.codes[0].length;
        this.code = this.codes[0].slice(1, len - 1);
      break;
    }
    // For debug
    // this.code += " " + codeResult.format;
    this.onBeep();
    this.closePopup();
    setTimeout(() => {
      this.commonService.audioBeep.pause();
      this.commonService.audioBeep.currentTime = 0;
    }, 1000);
  }

  handleError(error) {
    this.dialogRef.close();
    this.commonService.openErrorDialog(this.commonService.pageTitle, error);
  }

  onClose() {
    Quagga.stop();
    this.dialogRef.close();
  }

  closePopup() {
    Quagga.stop();
    this.dialogRef.close(this.code);
  }

  isHiddenCaptureImageButton() {
    return false;
  }

  onBeep() {
    this.commonService.audioBeep.pause();
    this.commonService.audioBeep.currentTime = 0;
    this.commonService.audioBeep.loop = false;
    this.commonService.audioBeep.play();
  }
}
