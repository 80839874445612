import { Component, OnInit, OnDestroy, ViewChild, EventEmitter, ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Subscription, Observable } from 'rxjs';
import { NoisecutSearchConditionDto, ReqNoisecutSearch } from 'src/app/request/req-noisecut-search';
import { DateAdapter } from '@angular/material/core';
import { ReqPageDto } from 'src/app/request/req-page-dto';
import { NoisecutDto, RspNoisecutSearch } from 'src/app/response/rsp-noisecut-search';
import { MatTabGroup } from '@angular/material/tabs';
import { ReqItemSimpleSearch, ReqItemSimpleSearchConditionDto } from 'src/app/request/req-item-simple-seach';
import { RspItemSimpleSearch } from 'src/app/response/rsp-item-simple-seach';
import { MatTable } from '@angular/material/table';
import { ReqNoisecutUpdate } from 'src/app/request/req-noisecut-update';
import { RspNoisecutUpdate } from 'src/app/response/rsp-noisecut-update';
import { ItemNoisecutDialogComponent } from 'src/app/dialog/item-noisecut-dialog/item-noisecut-dialog.component';

export class Noisecut {
  isNew: boolean;
  isEdited: boolean;
  isDeleted: boolean;
  id: number;
  storeCd: string;
  itemCd: string;
  noisecutType: string;
  itemName: string;
  standard: string;
  dateBegin: string;
  dateEnd: string;
  orderNum: number;
  orderNumDisp: string;

  constructor(noisecut: NoisecutDto | Noisecut | Item, private commonService: CommonService) {
    if ((noisecut as NoisecutDto).id) {
      // Noisecut or NoisecutDto
      for (let key of Object.keys(noisecut)) {
        this[key] = noisecut[key];
      }
      this.isNew = false; 
    } else {
      // Item
      this.id = -1;
      this.storeCd = noisecut.storeCd;
      this.itemCd = noisecut.itemCd;
      this.itemName = noisecut.itemName;
      this.standard = noisecut.standard;
      if (this.commonService.config.noisecut.prom){
        this.noisecutType = "特売";
      } else {
        this.noisecutType = "客注";
      }
      this.dateBegin = "";
      this.dateEnd = "";
      this.orderNum = 0;
      this.isNew = true; 
    }
    this.isEdited = false;
    this.isDeleted = false; 
    if (this.noisecutType == "客注") {
      this.orderNumDisp = "" + this.orderNum;
    } else {
      this.orderNumDisp = "";
    }
  }

  orderNumEditable() : boolean {
    return this.noisecutType == "特売" ? false : true;
  }

  getValue(id: string) {
    switch (id) {
      case "noisecutType":
        if (this.noisecutType == "客注") return this.commonService.literal.customerOrder;
        return this[id];
      default:
        return this[id];
    }
  }

}

export class Item {
  storeCd: string;
  itemCd: string;
  itemName: string;
  standard: string;
}

@Component({
  selector: 'app-noisecut',
  templateUrl: './noisecut.component.html',
  styleUrls: ['./noisecut.component.css']
})
export class NoisecutComponent implements OnInit, OnDestroy, AfterViewChecked {

  private listTab : number = 0;
  private editTab : number = 2; // 1; to avoid unexpected behavior

  public isReadonly = true;
  public containerWidth: number;
  public ctgSelectCondition: CtgSelectCondition;
  public noisecutColumnIds: string[] = [];
  public itemColumnIds: string[] = [];
  public noisecutItemColumnIds: string[] = [];
  public noisecutColumnDefs: TableColumnDef[] = [
    {columnId:'noisecutType', header:"種別", width:40, align:"center"},
    {columnId:'itemCd', header:"商品コード", width:100, align:"center", editable: true},
    {columnId:'itemName', header:"商品名", width:260},
    {columnId:'standard', header:"規格", width:100},
    {columnId:'dateBegin', header:"開始日", width:80, align:"center"},
    {columnId:'dateEnd', header:"終了日", width:80, align:"center"},
    {columnId:'orderNumDisp', header:"数量", width:40, align:"right"},
  ];
  public itemColumnDefs: TableColumnDef[] = [
    {columnId:'itemCd', header:"商品コード", width:100, align:"center", editable: true},
    {columnId:'itemName', header:"商品名", width:260},
    {columnId:'standard', header:"規格", width:100},
  ];
  public noisecutTableWidth: any;
  public noisecutPagenatorMaxWidth: any;
  public itemTableWidth: any;
  public itemPagenatorMaxWidth: any;
  public noisecutItemTableWidth: any;
  public noisecutItemPagenatorMaxWidth: any;
  public noisecutRecordCount: number;
  public itemRecordCount: number;

  public noisecutList: Noisecut[] = [];
  public itemList: Item[] = [];
  public noisecutItemList: Noisecut[] = [];

  public selectedNoisecut: Noisecut;
  public selectedItem: Item;
  public formGroup: FormGroup;
  public noisecutFormGroup: FormGroup;
  public tabSelection = 0;
  private subscriptionStore: Subscription;
  private subscriptionType: Subscription;
  private subscriptionNoisecut: Subscription;
  private subscriptionItem: Subscription;
  private subscriptionConfirm: Subscription;
  private storeCd;

  public noisecutConditionOrigin: NoisecutSearchConditionDto;
  public itemSearchConditionOrigin: ReqItemSimpleSearchConditionDto;

  @ViewChild(CtgSelectComponent, {static:true}) ctgSelect: CtgSelectComponent;
  @ViewChild("matPagenatorNoisecut", {static:false}) matPagenatorNoisecut: MatPaginator;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  @ViewChild("MatTabGroup", {static:true}) matTabGroup: MatTabGroup;
  @ViewChild("noisecutListTable", {static:false}) noisecutListTable: MatTable<any>;
  @ViewChild("noisecutItemTable", {static:false}) noisecutItemTable: MatTable<any>;

  constructor(public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private dateAdapter : DateAdapter<any>,
    private cd: ChangeDetectorRef) {
    this.ctgSelectCondition = new CtgSelectCondition(this.fb);
  }

  ngOnInit() {
    // this.commonService.pageTitle = "ノイズカット";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("noisecut");

    this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;

    this.calcNoisecutTableWidth();
    this.calcNoisecutItemTableWidth();
    this.calcItemTableWidth();

    for (let col of this.noisecutColumnDefs) {
      this.noisecutColumnIds.push(col.columnId);
    }
    this.itemColumnIds.push("addItem");
    this.noisecutItemColumnIds.push("removeItem");
    for (let col of this.itemColumnDefs) {
      this.itemColumnIds.push(col.columnId);
      this.noisecutItemColumnIds.push(col.columnId);
    }
    this.noisecutItemColumnIds.push("orderNum");

    let dateBegin = this.commonService.getDate(this.commonService.salesDate);
    dateBegin.setDate(dateBegin.getDate() + 1);
    let dateEnd = this.commonService.copyDate(dateBegin);
    dateEnd.setDate(dateBegin.getDate() + 6);

    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.storeCd, Validators.required],
      noisecutType: [""],
      dateBegin: [dateBegin],
      dateEnd: [dateEnd],
      itemCd: [""],
      itemName: [""]
    });
    this.storeCd = this.commonService.loginUser.storeCd;
    if (this.commonService.config.noisecut.prom && !this.commonService.config.noisecut.customerOrder) {
      this.formGroup.get("noisecutType").setValue("特売");
    } else if (!this.commonService.config.noisecut.prom && this.commonService.config.noisecut.customerOrder) {
      this.formGroup.get("noisecutType").setValue("客注");
    }

    this.subscriptionStore = this.formGroup.get("storeCd").valueChanges.subscribe(
      (storeCd) => this.storeChanged(storeCd)
    );

    this.initNoisecutFormGroup();
    // this.tabSelection = this.listTab;
    this.tabChange(this.listTab);

  }

  ngOnDestroy() {
    if (this.subscriptionStore) {
      this.subscriptionStore.unsubscribe();
    }
    if (this.subscriptionType) {
      this.subscriptionType.unsubscribe();
    }
    if (this.subscriptionNoisecut) {
      this.subscriptionNoisecut.unsubscribe();
    }
    if (this.subscriptionItem) {
      this.subscriptionItem.unsubscribe();
    }
    if (this.subscriptionConfirm) {
      this.subscriptionConfirm.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  storeChanged(storeCd: string) {
    if (this.storeCd == storeCd) return;

    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.storeChangedBody(storeCd);
      return;
    }

    this.subscriptionConfirm = (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.storeChangedBody(storeCd);
        } else {
          this.subscriptionConfirm.unsubscribe();
          this.subscriptionConfirm = undefined;
          this.formGroup.get("storeCd").setValue(this.storeCd);
        }
      }
    );
  }

  storeChangedBody(storeCd: string) {
    if (this.subscriptionConfirm) {
      this.subscriptionConfirm.unsubscribe();
      this.subscriptionConfirm = undefined;
    }
    this.clearNoisecutEdit();
    this.storeCd = storeCd;
    this.ctgSelect.setStoreCd(storeCd);
    this.noisecutList = [];
    this.itemList = [];
  
    this.selectedNoisecut = undefined;
    this.selectedItem = undefined;
  }

  tabChange(value: number) {
    // console.log("tab change");
    if (this.tabSelection !== value) {
      this.tabSelection = value;
      // console.log("to: ", value);
    }
  }
  /*
  tabChanged(value: number) {
    console.log("tab changed");
    if (this.tabSelection !== value) {
      this.tabSelection = value;
      console.log("to: ", value);
  
    }
  }
  */

  dateShift(dates : number) {
    if (dates > 0) {
      let e = this.formGroup.get("dateEnd").value as Date;
      e.setDate(e.getDate() + dates);
      this.formGroup.get("dateEnd").setValue(e);
      let b = this.formGroup.get("dateBegin").value as Date;
      b.setDate(b.getDate() + dates);
      this.formGroup.get("dateBegin").setValue(b);
    } else {
      let b = this.formGroup.get("dateBegin").value as Date;
      b.setDate(b.getDate() + dates);
      this.formGroup.get("dateBegin").setValue(b);
      let e = this.formGroup.get("dateEnd").value as Date;
      e.setDate(e.getDate() + dates);
      this.formGroup.get("dateEnd").setValue(e);
    }
  }

  prevMonth() {
    this.dateShift(-28);
  }
  prevWeek() {
    this.dateShift(-7);
  }
  prevDay() {
    this.dateShift(-1);
  }
  nextMonth() {
    this.dateShift(28);
  }
  nextWeek() {
    this.dateShift(7);
  }
  nextDay() {
    this.dateShift(1);
  }

  styleForNoisecut(id:string) {
    for (var colDef of this.noisecutColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeaderNoisecut(id:string) {
    for (var colDef of this.noisecutColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  styleForItem(id:string) {
    for (var colDef of this.itemColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeaderItem(id:string) {
    for (var colDef of this.itemColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  calcNoisecutTableWidth() {
    var width = 1;   // For left border

    for (var colDef of this.noisecutColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.noisecutTableWidth = {"width": "" + width + "px"};
    this.noisecutPagenatorMaxWidth = {"max-width": "" + width + "px"}
  }

  calcItemTableWidth() {
    var width = 1 + 80 + 8;   // For left border
    for (var colDef of this.itemColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.itemTableWidth = {"width": "" + width + "px"};
    this.itemPagenatorMaxWidth = {"max-width": "" + width + "px"}
  }
  calcNoisecutItemTableWidth() {
    var width = 1 + 80 + 8 + 50 + 8;   // For left border
    for (var colDef of this.itemColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.noisecutItemTableWidth = {"width": "" + width + "px"};
    this.noisecutItemPagenatorMaxWidth = {"max-width": "" + width + "px"}
  }

  noisecutCondition() : NoisecutSearchConditionDto {
    var categorySearchCondition : FormGroup = this.ctgSelect.ctgSelectCondition.formGroup;

    var cond: NoisecutSearchConditionDto = {
      exact: 0,
      storeCdFv: this.formGroup.get("storeCd").value,
      noisecutTypeFv: this.formGroup.get("noisecutType").value,
      dateBeginFv: this.dateAdapter.format(this.formGroup.get("dateBegin").value, null),
      dateEndFv: this.dateAdapter.format(this.formGroup.get("dateEnd").value, null),
      ctgCd0Fv: categorySearchCondition.get('ctgLevel').value >= 0 ? 
                categorySearchCondition.get('ctgCd0').value : "",
      ctgCd1Fv: categorySearchCondition.get('ctgLevel').value >= 1 ? 
                categorySearchCondition.get('ctgCd1').value : "",
      ctgCd2Fv: categorySearchCondition.get('ctgLevel').value >= 2 ? 
                categorySearchCondition.get('ctgCd2').value : "",
      ctgCd3Fv: categorySearchCondition.get('ctgLevel').value >= 3 ? 
                categorySearchCondition.get('ctgCd3').value : "",
      itemCdFv: this.formGroup.get('itemCd').value,
      itemNameFv: this.formGroup.get('itemName').value
    };

    return cond;
  }

  itemSearchCondition() : ReqItemSimpleSearchConditionDto {
      let categorySearchCondition: FormGroup = this.ctgSelect.ctgSelectCondition.formGroup;

      let condition: ReqItemSimpleSearchConditionDto = {
          storeCdFv: this.formGroup.get('storeCd').value,
          ctgCd0Fv: categorySearchCondition.get('ctgLevel').value >= 0 ?
                    categorySearchCondition.get('ctgCd0').value : '',
          ctgCd1Fv: categorySearchCondition.get('ctgLevel').value >= 1 ?
                    categorySearchCondition.get('ctgCd1').value : '',
          ctgCd2Fv: categorySearchCondition.get('ctgLevel').value >= 2 ?
                    categorySearchCondition.get('ctgCd2').value : '',
          ctgCd3Fv: categorySearchCondition.get('ctgLevel').value >= 3 ?
                    categorySearchCondition.get('ctgCd3').value : '',
          itemCdFv: this.formGroup.get('itemCd').value,
          itemNameFv: this.formGroup.get('itemName').value,
      }
      return condition;
    }

  clearProgressState() {
    if (this.subscriptionNoisecut) this.subscriptionNoisecut.unsubscribe();
    this.subscriptionNoisecut = undefined;
    if (this.subscriptionItem) this.subscriptionItem.unsubscribe();
    this.subscriptionItem = undefined;
    this.commonService.closeSpinner();
  }

  clickNoisecut() {
    this.matPagenatorNoisecut.pageIndex = 0;
    this.getNoisecutConditionOrigin();
    this.noisecutSearchBody();
  }

  pageChangedNoisecut(event: PageEvent) {
    if (this.noisecutList == undefined || this.noisecutList.length == 0) return;
    this.noisecutSearchBody();
  }

  noisecutSearchBody() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    var page: ReqPageDto = {
      pageNum: this.matPagenatorNoisecut.pageIndex,
      dispNum: this.matPagenatorNoisecut.pageSize
    };

    this.subscriptionNoisecut = this.httpBasic.searchNoisecut(this.noisecutConditionOrigin, page).subscribe(
      (response) => this.receiveNoisecut(response),
      (error) => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
      }
    );
  }

  receiveNoisecut(response: RspNoisecutSearch) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.noisecutList = [];
    this.noisecutRecordCount = response.recordCount;

    for (let noisecut of response.noisecuts) {
      this.noisecutList.push(new Noisecut(noisecut, this.commonService));
    }

    this.selectedNoisecut = undefined;

    // this.tabSelection = this.listTab;
    this.tabChange(this.listTab);
  }

  editLine() {
    if (!this.selectedNoisecut) return;

    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.doEditLine(this.selectedNoisecut);
      return;
    }

    this.subscriptionConfirm = (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.doEditLine(this.selectedNoisecut);
        } else {
          this.subscriptionConfirm.unsubscribe();
          this.subscriptionConfirm = undefined;
        }
      }
    );
  }

  doEditLine(noisecut: Noisecut) {
    if (this.subscriptionConfirm) {
      this.subscriptionConfirm.unsubscribe();
      this.subscriptionConfirm = undefined;
    }
    let editNoisecut = new Noisecut(noisecut, this.commonService);
    this.clearNoisecutEdit();
    this.noisecutItemList.push(editNoisecut);
    this.setNoisecutFormVal(editNoisecut);
    this.setNoisecutFormOrderNum();
    // this.tabSelection = this.editTab;
    this.tabChange(this.editTab);
  }

  editGroup() {
    if (!this.selectedNoisecut) return;

    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.noisecutGroupSearch(this.selectedNoisecut);
      return;
    }

    this.subscriptionConfirm = (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.noisecutGroupSearch(this.selectedNoisecut);
        } else {
          this.subscriptionConfirm.unsubscribe();
          this.subscriptionConfirm = undefined;
        }
      }
    );
  }

  noisecutGroupSearch(noisecut: Noisecut) {
    if (this.subscriptionConfirm) {
      this.subscriptionConfirm.unsubscribe();
      this.subscriptionConfirm = undefined;
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    var cond : NoisecutSearchConditionDto = {
      exact: 1,
      storeCdFv: noisecut.storeCd,
      noisecutTypeFv: noisecut.noisecutType,
      dateBeginFv: noisecut.dateBegin,
      dateEndFv: noisecut.dateEnd,
      ctgCd0Fv: "",
      ctgCd1Fv: "",
      ctgCd2Fv: "",
      ctgCd3Fv: "",
      itemCdFv: "",
      itemNameFv: "",
    };
    var page: ReqPageDto = {
      pageNum: 0,
      dispNum: 10000
    };

    this.subscriptionNoisecut = this.httpBasic.searchNoisecut(cond, page).subscribe(
      (response) => this.receiveNoisecutGroup(response),
      (error) => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
      }
    );
  }

  receiveNoisecutGroup(response: RspNoisecutSearch) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.clearNoisecutEdit();

    for (let noisecut of response.noisecuts) {
      this.noisecutItemList.push(new Noisecut(noisecut, this.commonService));
    }

    if (this.noisecutItemList.length > 0) {
      this.setNoisecutFormVal(this.noisecutItemList[0]);
      this.setNoisecutFormOrderNum();
    }
    // this.tabSelection = this.editTab;
    this.tabChange(this.editTab);
  }

  deleteLine() {
    if (!this.selectedNoisecut) return;

    let msg: string =
      "<strong>"
      + this.selectedNoisecut.itemCd
      + "<br>"
      + this.selectedNoisecut.itemName
      + "<br>"
      + this.selectedNoisecut.standard
      + "<br>"
      + this.selectedNoisecut.noisecutType
      + "<br><br>を削除しますか？"
      + "</strong>"

    this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      data => {
        if (data == true) {
          this.doDeleteLine(this.selectedNoisecut);
        } else {
          this.subscriptionConfirm.unsubscribe();
          this.subscriptionConfirm = undefined;
        }
      }
    );
  }

  doDeleteLine(noisecut: Noisecut) {
    var add: NoisecutDto[] = [];
    var edit: NoisecutDto[] = [];
    var del: NoisecutDto[] = [];

    let dto = this.getNoisecutDto(noisecut);
    del.push(dto);
    let request: ReqNoisecutUpdate = {
      access: this.commonService.loginUser,
      add: add,
      edit: edit,
      delete: del
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    this.subscriptionNoisecut = this.httpBasic.updateNoisecut(request).subscribe(
      (response) => this.receiveDeleteLine(response),
      (error) => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDeleteLine(response: RspNoisecutUpdate) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    for (let i = 0; i < this.noisecutList.length; i++) {
      if (this.noisecutList[i].id == this.selectedNoisecut.id) {
        this.noisecutList.splice(i, 1);
        this.selectedNoisecut = undefined;
        this.noisecutListTable.renderRows();
        return;
      }
    }
    this.selectedNoisecut = undefined;
  }

  clickItemSearch() {
    this.matPagenatorItem.pageIndex = 0;
    this.getItemSimpleSearchConditionOrigin();
    this.itemSearchBody();
  }

  itemSearchBody() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    var request : ReqItemSimpleSearch = {
      access: { ...this.commonService.loginUser },
      itemSimpleSeachCondition: this.itemSearchConditionOrigin,
      page: {
        pageNum: this.matPagenatorItem.pageIndex,
        dispNum: this.matPagenatorItem.pageSize
      }
    };
    if (this.commonService.config.noisecutCaseItem) {
      request.itemSimpleSeachCondition.orderStopFlags = this.commonService.getOrderStopValues();
      request.itemSimpleSeachCondition.withoutCase = false;
    } else {
      request.itemSimpleSeachCondition.orderStopFlags = this.commonService.getOrderStopValuesWithoutCase();
      request.itemSimpleSeachCondition.withoutCase = true;
    }

    this.subscriptionItem = this.httpBasic.itemSimpleSearch(request).subscribe(
      (response) => this.receiveItemSearch(response),
      (error) => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
      }
    );
  }

  pageChangedItem(event: PageEvent) {
    if (this.itemList == undefined || this.itemList.length == 0) return;
    this.itemSearchBody();
  }

  receiveItemSearch(response: RspItemSimpleSearch) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.itemList = [];
    this.itemRecordCount = response.recordCount;

    for (let itemDto of response.rows) {
      let item = new Item();
      item.storeCd = itemDto.storeCdFv;
      item.itemCd = itemDto.itemCdFv;
      item.itemName = itemDto.itemNameFv;
      item.standard = itemDto.standardFv;
      this.itemList.push(item);
    }

    // this.tabSelection = this.editTab;
    this.tabChange(this.editTab);
  }

  onClickCellNoisecut(colId: string, noisecut: Noisecut) {
    if (colId == "itemCd") {
      this.openNoisecutHistoryDialog(noisecut);
      return;
    }
    this.selectedNoisecut = noisecut;
  }

  onClickCellItem(colId: string, item: Item) {
    if (colId == "itemCd") {
      this.openNoisecutHistoryDialog(item);
      return;
    }
    // this.selectedItem = item;
  }

  isSelectedNoisecut(noisecut: Noisecut) : boolean {
    if (!this.selectedNoisecut) return false;
    if (this.selectedNoisecut.id == noisecut.id) return true;
    return false;
  }

  isSelectedItem(item: Item) : boolean {
    if (!this.selectedItem) return false;
    if (this.selectedItem.itemCd == item.itemCd &&
        this.selectedItem.storeCd == item.storeCd) return true;
    return false;
  }

  isItemInNoisecut(item: Noisecut) {
    if (!this.noisecutItemList || this.noisecutItemList.length == 0) {
      return false;
    }
    for (let noisecut of this.noisecutItemList) {
      if (item.itemCd === noisecut.itemCd) {
        return true;
      }
    }
    return false;
  }

  addNoisecut(item: Item) {
    let noisecut = new Noisecut(item, this.commonService);
    let form = new FormControl(
      this.noisecutFormGroup.get("type").value == "特売" ? 0.5 : noisecut.orderNum,
      (control)=> {return this.orderNumValidator(control as FormControl)}
      );
    let form1 = new FormControl(""); /* dummy control for line status */
    this.noisecutFormGroup.addControl(
      noisecut.itemCd,
      form
    );
    this.noisecutFormGroup.addControl(
      "status-" + noisecut.itemCd,
      form1
    );
    this.noisecutFormGroup.get("status-" + noisecut.itemCd).markAsDirty();
    this.noisecutItemList.push(noisecut);
    this.noisecutItemTable.renderRows();
  }

  removeNoisecut(noisecut: Noisecut, index: number) {
    if (noisecut.isNew) {
      this.noisecutFormGroup.removeControl(noisecut.itemCd);
      this.noisecutFormGroup.removeControl("status-" + noisecut.itemCd);
      this.noisecutItemList.splice(index, 1);
    } else {
      noisecut.isDeleted = true;
      this.noisecutFormGroup.get("status-" + noisecut.itemCd).markAsDirty();
    }
    if (this.noisecutItemList.length == 0) {
      this.noisecutFormGroup.markAsPristine();
    }
    this.noisecutItemTable.renderRows();
  }

  unremoveNoisecut(noisecut: Noisecut) {
    noisecut.isDeleted = false;
    this.noisecutItemTable.renderRows();
  }

  initNoisecutFormGroup() {
    if (this.noisecutFormGroup) {
      this.noisecutFormGroup.get("dateEnd").enable();
    }
    if (this.subscriptionType) {
      this.subscriptionType.unsubscribe();
    }
    this.noisecutFormGroup = this.fb.group({
      type: [this.commonService.config.noisecut.prom ? "特売" : "客注"],
      dateBegin: [, Validators.required],
      dateEnd: [, (control: FormControl)=>{return this.dateEndValidator(control)}]
    });
    if (!this.commonService.config.noisecut.prom) {
      this.noisecutFormGroup.get("dateEnd").disable();
    }
    if (this.isReadonly) {
      this.noisecutFormGroup.disable();
      return;
    }
    this.subscriptionType = this.noisecutFormGroup.get("type").valueChanges.subscribe(
      (type: string) => {
        if (type == "特売") {
          this.noisecutFormGroup.get("dateEnd").enable();
          // For validator, temporarily update orderNum formvalue 
          for (let noisecut of this.noisecutItemList) {
            if (this.noisecutFormGroup.get(noisecut.itemCd) &&
                parseInt(this.noisecutFormGroup.get(noisecut.itemCd).value) == 0) {
              this.noisecutFormGroup.get(noisecut.itemCd).setValue(0.5);
            }
          }
        } else {
          this.noisecutFormGroup.get("dateEnd").disable();
          // For validator, restore temporarily updated orderNum formvalue 
          for (let noisecut of this.noisecutItemList) {
            if (this.noisecutFormGroup.get(noisecut.itemCd) &&
                parseFloat(this.noisecutFormGroup.get(noisecut.itemCd).value) == 0.5) {
              this.noisecutFormGroup.get(noisecut.itemCd).setValue(0);
            }
          }
        }
      }
    )
  }

  setNoisecutFormVal(noisecut: Noisecut) {
    this.noisecutFormGroup.get("type").setValue(noisecut.noisecutType);
    this.noisecutFormGroup.get("dateBegin").setValue(this.commonService.getDate(noisecut.dateBegin));
    this.noisecutFormGroup.get("dateEnd").setValue(this.commonService.getDate(noisecut.dateEnd));
    if (noisecut.noisecutType == "特売") {
      this.noisecutFormGroup.get("dateEnd").enable();
    } else {
      this.noisecutFormGroup.get("dateEnd").disable();
    }
  }

  setNoisecutFormOrderNum() {
    let type = this.noisecutFormGroup.get("type").value;
    for (let noisecut of this.noisecutItemList) {
      this.noisecutFormGroup.addControl(
        noisecut.itemCd,
        new FormControl(type == "特売" ? 0.5 : noisecut.orderNum,
          (control)=> {return this.orderNumValidator(control as FormControl)}
        )
      );
      this.noisecutFormGroup.addControl(
        "status-" + noisecut.itemCd,
        new FormControl("")
      );
    }
  }

  cancelNoisecutEdit() {
    for (let i = this.noisecutItemList.length - 1; i >= 0 ; i--) {
      if (this.noisecutItemList[i].isNew) {
        this.noisecutFormGroup.removeControl(this.noisecutItemList[i].itemCd);
        this.noisecutFormGroup.removeControl("status-" + this.noisecutItemList[i].itemCd);
        this.noisecutItemList.splice(i, 1);
        continue;
      }
      this.noisecutItemList[i].isEdited = false;
      this.noisecutItemList[i].isDeleted = false;
      this.noisecutFormGroup.get(this.noisecutItemList[i].itemCd).setValue(
        this.noisecutItemList[i].orderNum
      );
      this.noisecutFormGroup.get(this.noisecutItemList[i].itemCd).markAsPristine();
      this.noisecutFormGroup.get("status-" + this.noisecutItemList[i].itemCd).markAsPristine();
    }
    if (this.noisecutItemList.length > 0) {
      this.setNoisecutFormVal(this.noisecutItemList[0]);
    }
    this.noisecutFormGroup.markAsPristine();
    this.noisecutItemTable.renderRows();
  }

  clearNoisecutEdit() {
    for (let i = this.noisecutItemList.length - 1; i >= 0 ; i--) {
      this.noisecutFormGroup.removeControl(this.noisecutItemList[i].itemCd);
      this.noisecutFormGroup.removeControl("status-" + this.noisecutItemList[i].itemCd);
    }
    if (this.commonService.config.noisecut.prom) {
      this.noisecutFormGroup.get("type").setValue("特売");
    } else {
      this.noisecutFormGroup.get("type").setValue("客注");
      this.noisecutFormGroup.get("dateEnd").disable();
    }
    this.noisecutFormGroup.get("dateBegin").setValue(undefined);
    this.noisecutFormGroup.get("dateEnd").setValue(undefined);
    this.noisecutFormGroup.markAsPristine();
    this.noisecutItemList = [];
    this.noisecutItemTable.renderRows();
  }

  getNoisecutDto(noisecut: Noisecut) : NoisecutDto {
    return {
      id:        noisecut.id,
      storeCd:   noisecut.storeCd,
      itemCd:    noisecut.itemCd,
      noisecutType: noisecut.noisecutType,
      itemName:  noisecut.itemName,
      standard:  noisecut.standard,
      dateBegin: noisecut.dateBegin,
      dateEnd:   noisecut.dateEnd,
      orderNum:  noisecut.orderNum
    };
  }

  checkNoisecutFormDirty() : boolean {
    let dirty = false;

    for (let noisecut of this.noisecutItemList) {
      if (noisecut.isNew || noisecut.isDeleted) {
        dirty = true;
        break;
      }
      if (noisecut.noisecutType != this.noisecutFormGroup.get("type").value ||
        noisecut.dateBegin != this.dateAdapter.format(this.noisecutFormGroup.get("dateBegin").value, null) ||
        noisecut.dateEnd != this.dateAdapter.format(this.noisecutFormGroup.get("dateEnd").value, null) ||
        (this.noisecutFormGroup.get("type").value == "客注" &&
        noisecut.orderNum != this.noisecutFormGroup.get(noisecut.itemCd).value)
      ) {
        dirty = true;
        break;
      }
    }

    if (dirty) {
      this.noisecutFormGroup.markAsDirty();
    } else {
      this.noisecutFormGroup.markAsPristine();
    }
    return dirty;
  }

  updateNoisecut() {
    var add: NoisecutDto[] = [];
    var edit: NoisecutDto[] = [];
    var del: NoisecutDto[] = [];

    if (this.noisecutFormGroup.get("type").value == "特売" &&
        (this.noisecutFormGroup.get("dateBegin").value as Date).getTime()
          > (this.noisecutFormGroup.get("dateEnd").value as Date).getTime()) {
      // Swap begin and end
      let dateEnd = this.noisecutFormGroup.get("dateBegin").value;
      this.noisecutFormGroup.get("dateBegin").setValue(
        this.noisecutFormGroup.get("dateEnd").value
      );
      this.noisecutFormGroup.get("dateEnd").setValue(dateEnd);
    }
    if (this.noisecutFormGroup.get("type").value == "客注") {
      this.noisecutFormGroup.get("dateEnd").setValue(
        this.commonService.copyDate(this.noisecutFormGroup.get("dateBegin").value)
      );
    }

    for (let noisecut of this.noisecutItemList) {
      let dto = this.getNoisecutDto(noisecut);
      /* Form から dto を更新 */
      if (noisecut.isNew) {
        dto.noisecutType = this.noisecutFormGroup.get("type").value;
        dto.dateBegin = this.dateAdapter.format(this.noisecutFormGroup.get("dateBegin").value, null);
        dto.dateEnd = this.dateAdapter.format(this.noisecutFormGroup.get("dateEnd").value, null);
        if (this.noisecutFormGroup.get("type").value == "特売") {
          dto.orderNum = 0;
        } else {
          dto.orderNum = this.noisecutFormGroup.get(noisecut.itemCd).value;
        }
      } else if (!noisecut.isDeleted) {
        if (noisecut.noisecutType != this.noisecutFormGroup.get("type").value ||
          noisecut.dateBegin != this.dateAdapter.format(this.noisecutFormGroup.get("dateBegin").value, null) ||
          noisecut.dateEnd != this.dateAdapter.format(this.noisecutFormGroup.get("dateEnd").value, null) ||
          (this.noisecutFormGroup.get("type").value == "客注" &&
          noisecut.orderNum != this.noisecutFormGroup.get(noisecut.itemCd).value)
        ) {
          noisecut.isEdited = true;
          dto.noisecutType = this.noisecutFormGroup.get("type").value;
          dto.dateBegin = this.dateAdapter.format(this.noisecutFormGroup.get("dateBegin").value, null);
          dto.dateEnd = this.dateAdapter.format(this.noisecutFormGroup.get("dateEnd").value, null);
          if (this.noisecutFormGroup.get("type").value == "特売") {
            dto.orderNum = 0;
          } else {
            dto.orderNum = this.noisecutFormGroup.get(noisecut.itemCd).value;
          }
        }
      }

      if (noisecut.isNew) add.push(dto);
      if (noisecut.isEdited && !noisecut.isDeleted) edit.push(dto);
      if (noisecut.isDeleted) del.push(dto);
    }

    if (add.length + edit.length + del.length > 0) {
      let request: ReqNoisecutUpdate = {
        access: this.commonService.loginUser,
        add: add,
        edit: edit,
        delete: del
      }

      this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
      this.subscriptionNoisecut = this.httpBasic.updateNoisecut(request).subscribe(
        (response) => this.updateNoisecutReceive(response),
        (error) => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    }
  }

  updateNoisecutReceive(response: RspNoisecutUpdate) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.clearNoisecutEdit();
  }

  openNoisecutHistoryDialog(item: Item | Noisecut) {
    const dialogRef = this.commonService.dialog.open(ItemNoisecutDialogComponent, {
      disableClose: false,
      data: {storeCd: item.storeCd, itemCd: item.itemCd, itemName: item.itemName}
    });
  }

  dateEndValidator(control: FormControl) {
    if (!this.noisecutFormGroup) return null;
    if (!this.noisecutFormGroup.get("type")) return null;
    if (this.noisecutFormGroup.get("type").value == "特売") return Validators.required(control);
    return null;
  }

  orderNumValidator(control: FormControl) {
    if (parseFloat(control.value) > 0) return null;
    return {"InvalidValue": "数量は 0 以上でなければなりません。"}
  }

  canDeactivate() {
    if (!this.noisecutFormGroup.dirty) return true;
    if (this.noisecutItemList.length == 0) return true;

    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  getItemSimpleSearchConditionOrigin(){
    this.itemSearchConditionOrigin = this.itemSearchCondition();
  }

  getNoisecutConditionOrigin(){
    this.noisecutConditionOrigin = this.noisecutCondition();
  }
}
