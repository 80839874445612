import { RoleMenu } from './role-menu';

export class Role {
    roleId: number;
    roleName: string;
    trgetStoreFlag: number;
    roleLevel: number;
    roleComment: string;
    version: number;
    userCount: number;
    roleMenus: RoleMenu[];
}
