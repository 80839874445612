import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable } from "@angular/material/table";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { ItemRec } from "src/app/common/item-rec";
import { ItemRec2 } from "src/app/common/item-rec2";
import { TableColumnDef } from "src/app/common/table-column-def";
import { ItemSummaryDialogComponent } from "src/app/dialog/item-summary-dialog/item-summary-dialog.component";
import { OrderEditBgcolorDialogComponent } from "src/app/dialog/order-edit-bgcolor/order-edit-bgcolor-dialog.component";
import { ScanItemEdit2DialogComponent } from "src/app/dialog/scan-item-edit2-dialog/scan-item-edit2-dialog.component";
import { ItemSearchCondition } from "src/app/partsCommon/item-select-condition/item-select-condition.component";
import { NumberKeypadComponent } from "src/app/partsCommon/number-keypad/number-keypad.component";
import { ReqItemSearch, ReqItemViewSortDto } from "src/app/request/req-item-search";
import { RspItemDto, RspItemSearch } from "src/app/response/rsp-item-search";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { ReqUpdateOrderByUser, ReqUpdateOrderByUserDto, RspGetOrdersByUser, RspUpdateOrderByUser } from "src/app/webservice/order-by-user";

@Component({
  selector: "app-order-stock",
  templateUrl: "./order-stock.component.html",
  styleUrls: ["./order-stock.component.css"]
})
export class OrderStockComponent implements OnInit, OnDestroy {

  public isOrderEditable = false;
  public isStockEditable = false;

  public itemSearchCondition : ItemSearchCondition = new ItemSearchCondition(this.fb);
  public recordCount: number = 0;
  public orderList: ItemRec2[] = [];
  public orderListAll: ItemRec2[] = [];
  public isDirty: boolean = false;

  public columnIdsHeader: string[] = ["itemCdFv", "itemNameFv", "standardFv", "orderBacklog", "orderNumForm", "orderLotFn", "orderBaraNumFn",
    "stockNumForm", "deliveryDate", "minZaiFn", "maxZaiFn", "itemClass", "salesResult", "orderResult", "itemLocation"];
  public columnIdsRow: string[] = ["itemCdFv", "itemNameFv", "standardFv", "orderBacklog", "orderNumForm", "orderLotFn", "orderBaraNumFn",
    "stockNumForm", "deliveryDate", "minZaiFn", "maxZaiFn", "itemClass", "salesResult1", "salesResult2",
    "salesResult3", "salesResult4", "orderResult1", "orderResult2", "orderResult3", "orderResult4", "itemLocation"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "standardFv", header: "規格", width: 60, maxWidth: 80, align: "left", colspan: 1, rowTooltip: true },
    { columnId: "orderLotFn", header: "単", width: 20, align: "right", colspan: 1, tooltip: "発注単位（ロット）" },
    { columnId: "orderBaraNumFn", header: "バラ", width: 30, align: "right",tooltip: "発注単位に調整したバラ発注数" },
    { columnId: "orderBacklog", header: "入荷", width: 40, maxWidth: 40, align: "right", colspan: 1, tooltip: "入荷予定（カッコ内は定番以外）" },
    { columnId: "itemLocation", header: "棚", width: 100, maxWidth: 100, align: "left", colspan: 1, rowTooltip: true },
    { columnId: "deliveryDate", header: "納品日", width: 75, maxWidth: 75, align: "center", colspan: 1 },
    { columnId: "minZaiFn", header: "低", width: 30, align: "right", colspan: 1, tooltip: "最低陳列" },
    { columnId: "maxZaiFn", header: "大", width: 30, maxWidth: 50, align: "right", colspan: 1, tooltip: "最大陳列" },
    { columnId: "itemClass", header: "区分", width: 40, align: "left", colspan: 1, rowTooltip: true},
    // { columnId: "orderClass", header: "発注区分", width: 40, align: "left", colspan: 1},
    { columnId: "salesResult", header: "販売", width: 80, align: "right", colspan: 4 },    // For header only
    { columnId: "salesResult1", header: "", width: 28, align: "right", colspan: 1 },
    { columnId: "salesResult2", header: "", width: 28, align: "right", colspan: 1 },
    { columnId: "salesResult3", header: "", width: 28, align: "right", colspan: 1 },
    { columnId: "salesResult4", header: "", width: 28, align: "right", colspan: 1 },
    { columnId: "orderResult", header: "発注", width: 80, align: "right", colspan: 4 },    // For header only
    { columnId: "orderResult1", header: "", width: 20, align: "right", colspan: 1 },
    { columnId: "orderResult2", header: "", width: 20, align: "right", colspan: 1 },
    { columnId: "orderResult3", header: "", width: 20, align: "right", colspan: 1 },
    { columnId: "orderResult4", header: "", width: 20, align: "right", colspan: 1 }
  ];

  private subscForm: Subscription;
  private subscFormItemCode: Subscription;
  private propClosingTime: Date;
  private serverTime: Date;
  private deviceTime: Date;
  private timeLag: number;
  private closingTimeList: Date[];
  private timerId: any = -1;

  /* param for change orderList */
  public orderUpdateChangeMap: Map<string, ReqUpdateOrderByUserDto> = new Map();
  public orderBaraUpdateChangeMap: Map<string, string> = new Map();
  public orderAndStockFormSubscriptions: Subscription[] = [];
  /* end param for change orderList */

  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) matPagenator: MatPaginator;
  @ViewChild(NumberKeypadComponent, { static: true }) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.commonService.config.orderStock?.listColumns) {
      this.columnIdsHeader = ["itemCdFv", "itemNameFv"];
      this.columnIdsRow = ["itemCdFv", "itemNameFv"];
      for (let i = 0; i < this.commonService.config.orderStock?.listColumns.length; i++) {
        let colId = this.commonService.config.orderStock?.listColumns[i];
        if (colId === "salesResult") {
          this.columnIdsHeader.push('salesResult');
          this.columnIdsRow.push('salesResult1','salesResult2','salesResult3','salesResult4');
        } else if (colId === "orderResult") {
          this.columnIdsHeader.push('orderResult');
          this.columnIdsRow.push('orderResult1','orderResult2','orderResult3','orderResult4');
        } else {
          this.columnIdsHeader.push(colId);
          this.columnIdsRow.push(colId);
        }
      }
    }

    this.commonService.pageTitle = this.commonService.pageMenuName;
    // this.getOrderItems();
    // this.setTableHeight();

    let len = this.route.snapshot.url.length;
    if (len > 0) {
      let url: string = this.route.snapshot.url[len - 1].path;
      if (url == "order2" || url == "orderStock") this.isOrderEditable = true;
      if (url == "stock2" || url == "orderStock") this.isStockEditable = true;
    }

    this.itemSearchCondition.formGroup.get('salesDate').setValue(this.commonService.dateSalesDate);
    this.itemSearchCondition.formGroup.get('salesDate').disable();
  }

  ngOnDestroy(): void {
    this.subscFormItemCode?.unsubscribe();
    this.subscForm?.unsubscribe();

    this.clearTimer();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "item-table-box";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight == undefined) return;
    let paginatorHeight = 56;
    let margin = 10 + 5;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem == undefined) return;
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  orderUpdate() {
    const request: ReqUpdateOrderByUser = {
      access: this.commonService.loginUser,
      itemList: Array.from(this.orderUpdateChangeMap, (([name, value]) => value)) || []
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdateOrderAndStock", request).subscribe(
      (response: RspUpdateOrderByUser) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiverOrderUpdate(request, response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiverOrderUpdate(request: ReqUpdateOrderByUser, response: RspUpdateOrderByUser) {
    this.httpBasic.handleAppError(response)

    let isAdjusted: boolean = false;
    this.unSubscriptionOrderAndStockForm();
    const resOrderUpdateList = response.resultUpdateOrderList;
    const resStockUpdateList = response.resultUpdateStockList;

    for (const resItem of resOrderUpdateList) {
      // change items values from response
      let itemOnList: ItemRec2 = this.orderListAll.find(itemTmp => resItem.itemCdFv === itemTmp.itemCdFv);
      if (!itemOnList) continue;
      if (itemOnList.orderDate !== resItem.orderDateFv) {
        // Order Date is adjusted to the next orderable date
        isAdjusted = true;
        itemOnList.orderDateAdjusted(
          resItem.orderDateFv,
          resItem.orderNumFn,
          resItem.versionFn
        );
      } else {
        itemOnList.orderNumModFn = resItem.orderNumFn;
        itemOnList.orderNumVersionFn = resItem.versionFn;
        itemOnList.orderNumForm.setValue(itemOnList.getValue("orderNumFn"));
      }
    }
    for (const resItem of resStockUpdateList) {
      // change items values from response
      let itemOnList: ItemRec2 = this.orderListAll.find(itemTmp => resItem.itemCdFv === itemTmp.itemCdFv);
      if (!itemOnList) continue;
      itemOnList.stockNumModFn = resItem.stockNumFn;
      itemOnList.stockNumVersionFn = resItem.versionFn;
      itemOnList.stockNumForm.setValue(itemOnList.getValue("stockNumFn"));
    }

    this.orderCancel();

    if (isAdjusted) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, "締め時間を超えたため、翌発注日に調整された商品があります。");
      this.checkClosingTimeAll();
      this.serverTime = new Date();
      let tm = response.serverTime.split(":");
      this.serverTime.setHours(parseInt(tm[0]), parseInt(tm[1]), parseInt(tm[2]));
      this.deviceTime = new Date();
      this.timeLag = this.serverTime.getTime() - this.deviceTime.getTime();
      this.nextClosingTime();
    }
  }

  orderCancel() {
    this.unSubscriptionOrderAndStockForm();
    this.orderListAll.forEach((item) => { item.cancel(); });

    this.orderUpdateChangeMap.clear();
    this.orderBaraUpdateChangeMap.clear();
    this.initHandleChangeOrderAndStockNum();
  }

  getItems() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.getItemsBody();
      return;
    }

    let subsc = (deactivate as Observable<boolean>).subscribe(
      (response) => {
        subsc.unsubscribe();
        if (response == true) this.getItemsBody();
      }
    );
  }

  getItemsBody() {
    var itemSearchForm : FormGroup = this.itemSearchCondition.formGroup;
    var categorySearchCondition : FormGroup = this.itemSearchCondition.ctgSelectCondition.formGroup;
    var orderCurrentForm : FormGroup = itemSearchForm.get('orderCurrent') as FormGroup;
    var orderInitialForm : FormGroup = itemSearchForm.get('orderInitial') as FormGroup;
    var stockCurrentForm : FormGroup = itemSearchForm.get('stockCurrent') as FormGroup;
    var stockInitialForm : FormGroup = itemSearchForm.get('stockInitial') as FormGroup;
    var orderStopFlagForm : FormGroup = itemSearchForm.get('orderStopFlagFormGroup') as FormGroup;
    var itemViewSort : FormGroup = itemSearchForm.get('itemViewSort') as FormGroup;

    var request : ReqItemSearch = {
      access: {...this.commonService.loginUser},
      itemSearchCondition : {
        calcTrgtDateFd: this.commonService.formatDate(itemSearchForm.get('salesDate').value),
        storeCdFv: itemSearchForm.get('storeCd').value,
        ctgCd0Fv: categorySearchCondition.get('ctgLevel').value >= 0 ?
                    categorySearchCondition.get('ctgCd0').value : "",
        ctgCd1Fv: categorySearchCondition.get('ctgLevel').value >= 1 ?
                    categorySearchCondition.get('ctgCd1').value : "",
        ctgCd2Fv: categorySearchCondition.get('ctgLevel').value >= 2 ?
                    categorySearchCondition.get('ctgCd2').value : "",
        ctgCd3Fv: categorySearchCondition.get('ctgLevel').value >= 3 ?
                    categorySearchCondition.get('ctgCd3').value : "",
        rackCd1Fn: this.itemSearchCondition.rackSelectCondition.getCd1(),
        rackCd2Fn: this.itemSearchCondition.rackSelectCondition.getCd2(),
        rackCd3Fn: this.itemSearchCondition.rackSelectCondition.getCd3(),
        itemCdFv: itemSearchForm.get('itemCode').value,
        itemCdEqualSearch: false,
        itemNameFv: itemSearchForm.get('itemName').value,
        orderCurrentOperator: orderCurrentForm.get('isEnable').value ? orderCurrentForm.get('operatorType').value : 0,
        orderCurrentNum: orderCurrentForm.get('orderNum').value,
        orderInitialOperator: orderInitialForm.get('isEnable').value ? orderInitialForm.get('operatorType').value : 0,
        orderInitialNum: orderInitialForm.get('orderNum').value,
        stockCurrentOperator: stockCurrentForm.get('isEnable').value ? stockCurrentForm.get('operatorType').value : 0,
        stockCurrentNum: stockCurrentForm.get('stockNum').value,
        stockInitialOperator: stockInitialForm.get('isEnable').value ? stockInitialForm.get('operatorType').value : 0,
        stockInitialNum: stockInitialForm.get('stockNum').value,
        itemViewSorts: this.getItemViewSortDto(itemViewSort),
        orderBaraDisplayFlag: this.commonService?.config?.orderBaraDisplayFlag || false
      },
      multiOrderDate: true,
      page: {
        pageNum: 0,
        dispNum: 1001
      }
    };

    if (this.commonService.getOrderStopSearchCondList()?.length > 0) {
      request.itemSearchCondition.orderStopFlagList = this.getOrderStopFlagSelectedValues(orderStopFlagForm)
    }

    let subsc = this.httpBasic.itemSearch(request).subscribe(
        response => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.receiveItems(response);
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
  }

  receiveItems(response: RspItemSearch) {
    if (this.httpBasic.handleAppError(response)) return;

    let maxItems = 1000;

    this.propClosingTime = new Date();
    let tm = response.propClosingTime.split(":");
    this.propClosingTime.setHours(parseInt(tm[0]), parseInt(tm[1]), parseInt(tm[2]));
    this.serverTime = new Date();
    tm = response.serverTime.split(":");
    this.serverTime.setHours(parseInt(tm[0]), parseInt(tm[1]), parseInt(tm[2]));
    this.deviceTime = new Date();
    this.timeLag = this.serverTime.getTime() - this.deviceTime.getTime();
    this.closingTimeList = [this.propClosingTime];

    this.orderListAll = [];

    let itemCount = 0;
    this.recordCount = response.rows.length;
    for (let i = 0; i < this.recordCount; i++) {
      let dto = response.rows[i];
      let item;
      itemCount++;
      if (itemCount > maxItems) {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `${maxItems}件を超える商品が検索されました。最初の${maxItems}件のみが処理対象となります。`);
        break;
      }
      item = new ItemRec2(this.commonService);
      item.initByDto(dto);
      this.orderListAll.push(item);
      this.addItemClosingTime(item.closingTime);
    }

    if (this.isOrderEditable && this.commonService.config.orderGroup.itemSortByOrderNum) {
      if (this.commonService.config.orderEditInBara) {
        this.orderListAll.sort((a, b) => {
          if (a.getValue("orderBaraNumFn") > b.getValue("orderBaraNumFn")) return -1;
          if (a.getValue("orderBaraNumFn") < b.getValue("orderBaraNumFn")) return 1;
          return 0;
        });
      } else {
        this.orderListAll.sort((a, b) => {
          if (a.getValue("orderNumFn") > b.getValue("orderNumFn")) return -1;
          if (a.getValue("orderNumFn") < b.getValue("orderNumFn")) return 1;
          return 0;
        });
      }

      // Case items follow Unit
      let tmpList: ItemRec2[] = [];
      for (let i = 0; i < this.orderListAll.length; i++) {
        let item = this.orderListAll[i];
        let flag = this.commonService.config.orderStopFlag.find((flag) => flag.value === item.getValue("orderStopFlagFnValue"));
        if (!flag?.caseItem) {
          tmpList.push(item);
          continue;
        }
        let unitItem = this.orderListAll.find((item2) => item2.itemCdFv === item.unitItemCdFv);
        if (!unitItem) {
          tmpList.push(item);
          continue;
        }
        if (unitItem.caseOrderItems == undefined) unitItem.caseOrderItems = [];
        unitItem.caseOrderItems.push(item);
      }
      this.orderListAll = [];
      tmpList.forEach((item) => {
        this.orderListAll.push(item);
        if (item.caseOrderItems != undefined) {
          item.caseOrderItems.sort((a, b) => {
            if (a.caseConvFactFn < b.caseConvFactFn) return -1;
            if (a.caseConvFactFn > b.caseConvFactFn) return 1;
            return 0;
          });
          item.caseOrderItems.forEach((citem) => {
            this.orderListAll.push(citem);
          });
        }
      });
    }

    this.recordCount = this.orderListAll.length;
    if (this.matPagenator) this.matPagenator.pageIndex = 0;

    this.pageChanged();
    this.checkClosingTimeAll();
    this.orderUpdateChangeMap.clear();
    this.unSubscriptionOrderAndStockForm();
    this.initHandleChangeOrderAndStockNum();
    this.setTableHeight();

    this.nextClosingTime();
  }

  pageChanged() {
    this.orderList = [];
    let count = 0;
    for (let i = this.matPagenator.pageIndex * this.matPagenator.pageSize; i < this.recordCount && count < this.matPagenator.pageSize; i++) {
      this.orderList.push(this.orderListAll[i]);
      count++;
    }
    this.numberKeypadComponent.inputField = undefined;
    this.subscForm?.unsubscribe();
    this.matTable.renderRows();

    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  isKeyboardLocked() {
    if (!this.commonService.config.use10KeyPad) return false;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  is10KeyPadTarget(form: FormControl) {
    if (!this.commonService.config.use10KeyPad) return false;
    if (form === this.numberKeypadComponent.inputField) return true;
    return false;
  }

  set10KeyPadTarget(form: FormControl) {
    if (!this.commonService.config.use10KeyPad) return;
    // this.numberKeypadComponent.inputField = form;
    this.numberKeypadComponent.setTargetForm(form);

    this.subscForm?.unsubscribe();
    if (!this.isDirty) {
      this.subscForm = form.valueChanges.subscribe(
        (value) => { this.isDirty = true; }
      );
    }
  }

  rowBgColor(item: ItemRec2) {
    if (this.isOrderEditable == false) return 1;          // 背景色 無し

    let orderStop: number = item.getValue("orderStopFlagFnValue");
    let def = this.commonService.config.orderStopFlag.find((item) => item.value === orderStop);
    if (def == undefined) return 5;                       // 発注禁止
    if (!def.canOrder && !def.extendedOrder) {
      if (!this.commonService.config.orderEditCaseItem) return 5;     // 発注禁止
      if (!def.caseItem) return 5;                                    // 発注禁止
      if (!def.canCaseOrder) return 5;                                // 発注禁止
    }
    if (def.caseItem) {
      if (!item.isClosed) return 6;
      return 7;
    }
    if (item.orderableDateFv === "0000000") return 5;     // 発注可能曜日 なし
    if (def.isAutoOrder == false) {
      if (!item.orderableWeekday()) return 4;             // 手動発注 発注不可曜日
      if (!item.isClosed) return 3;                       // 手動発注 発注可能曜日、締め前
      return 4;                                           // 手動発注 発注可能曜日、締め後
    }
    if (!item.orderableWeekday()) return 2;               // 自動発注 発注不可曜日
    if (!item.isClosed) return 1;                         // 自動発注 発注可能曜日、締め前
    return 2;                                             // 自動発注 発注可能曜日、締め後
  }

  openItemSummaryDialog(item: ItemRec2) {
    let itemRec: ItemRec = new ItemRec((item as unknown) as RspItemDto, this.commonService);
    if (item.caseItems != undefined) itemRec.caseItems = item.caseItems;
    let subsc = this.commonService.dialog.open(ItemSummaryDialogComponent, {
      disableClose: false,
      data: itemRec
    }).afterClosed().subscribe(
      () => {
        subsc.unsubscribe();
        if (item.caseItems == undefined) item.caseItems = itemRec.caseItems;
      }
    );
  }

  openScanItemEdit2Dialog(item: ItemRec2) {
    let itemRec: ItemRec = new ItemRec((item as unknown) as RspItemDto, this.commonService);
    if (item.caseItems != undefined) itemRec.caseItems = item.caseItems;
    let subsc = this.commonService.dialog.open(ScanItemEdit2DialogComponent, {
      disableClose: false,
      data: itemRec
    }).afterClosed().subscribe(
      (data) => {
        subsc.unsubscribe();
        if (item.caseItems == undefined) item.caseItems = itemRec.caseItems;
        if (data == "scanItemEdit2") this.getItems();
      }
    );
  }

  onClickItemCd(itemRec: ItemRec2) {
    if (this.commonService.config.orderStock?.callScanOnItemCd) {
      let deactivate = this.canDeactivate();
      if (deactivate == true) {
        this.openScanItemEdit2Dialog(itemRec);
        return;
      }
      let subsc = (deactivate as Observable<boolean>).subscribe(
        (response) => {
          subsc.unsubscribe();
          if (response) {
            this.orderCancel();
            this.openScanItemEdit2Dialog(itemRec);
          }
        }
      );
    } else {
      this.openItemSummaryDialog(itemRec);
    }
  }

  /*
  handlerChangeOrderNumItem(item: ItemRec2) {
    let orderGroupHadChangedItem = [];
    this.orderGroups.forEach(group => group.items.forEach(item => {
      if (item.item.itemCdFv === itemArg.item.itemCdFv) {
        orderGroupHadChangedItem.push(group.groupId);
      }
    }));
    if (orderGroupHadChangedItem.length <= 0) return;
    for (const groupId of orderGroupHadChangedItem) {
      this.synchronizeOrderNumChangeInUpdateMap(groupId, itemArg);
    }
  }

  handlerChangeStockNumItem(itemArg: OrderGroupItem) {
    let orderGroupHadChangedItem = [];
    this.orderGroups.forEach(group => group.items.forEach(item => {
      if (item.item.itemCdFv === itemArg.item.itemCdFv) {
        orderGroupHadChangedItem.push(group.groupId);
      }
    }));
    if (orderGroupHadChangedItem.length <= 0) return;
    for (const groupId of orderGroupHadChangedItem) {
      this.synchronizeStockNumChangeInUpdateMap(groupId, itemArg);
    }
  }
  */

  synchronizeOrderBaraNumChangeInUpdateMap(item: ItemRec2) {
    let bara = Math.ceil(item.orderBaraNumForm.value / item.orderLotFn);
    item.orderNumForm.setValue(bara);

    if (!item.getValue("orderDate") || item.getValue("orderDate").trim() === "") return;
    const key = item.itemCdFv;
    const originOrderBaraNum = item.getValue("orderBaraNumFn");

    const isChangeOrderBaraNum = originOrderBaraNum !== item.orderBaraNumForm.value;
    if (isChangeOrderBaraNum) {
      if (!this.orderBaraUpdateChangeMap.has(key)) {
        this.orderBaraUpdateChangeMap.set(key, item.itemCdFv);
        return;
      }
    } else {
      if (this.orderBaraUpdateChangeMap.has(key)) {
        this.orderBaraUpdateChangeMap.delete(key);
      }
    }
  }

  synchronizeOrderNumChangeInUpdateMap(item: ItemRec2) {
    if (!item || !item.getValue("orderDate") || item.getValue("orderDate").trim() === "") return;
    const key = item.itemCdFv;
    const originOrderNum = item.getValue("orderNumFn");

    const isChangeOrderNum = originOrderNum !== item.getValue("orderNumForm");
    if (isChangeOrderNum) {
      if (this.orderUpdateChangeMap.has(key)) {
        const updateMapItem = this.orderUpdateChangeMap.get(key)
        updateMapItem.isUpdateOrder = true;
        updateMapItem.orderNumFn = item.getValue("orderNumForm")
        return;
      }
      const newMapItem: ReqUpdateOrderByUserDto = {
        groupId: -1,
        storeCdFv: item.storeCdFv,
        itemCdFv: item.itemCdFv,
        closingTime: item.closingTime,

        orderNumFn: item.getValue('orderNumForm'),
        orderNumModFn: item.orderNumModFn,
        orderNumVersionFn: item.orderNumVersionFn,
        orderDate: item.orderDate,
        orderDateNext: item.orderDateNext,
        deliveryDate: item.deliveryDate,
        deliveryDateNext: item.deliveryDateNext,

        stockNumFn: item.getValue('stockNumForm'),
        stockNumModFn: item.stockNumModFn,
        stockNumVersionFn: item.stockNumVersionFn,

        rowAction: 0, // 0: noChange; 1: delete; 2: add
        isUpdateOrder: true,
        isUpdateStock: false
      };
      this.orderUpdateChangeMap.set(key, newMapItem);
      return;
    }
    // else
    if (this.orderUpdateChangeMap.has(key)) {
      const updateMapItem = this.orderUpdateChangeMap.get(key)
      updateMapItem.isUpdateOrder = false;
      updateMapItem.orderNumFn = item.getValue("orderNumForm")
      if (updateMapItem.rowAction === 0 && !updateMapItem.isUpdateStock) {
        this.orderUpdateChangeMap.delete(key);
      }
    }
  }

  synchronizeStockNumChangeInUpdateMap(item: ItemRec2) {
    if (!item) return;
    const key = item.itemCdFv;
    const originStockNum = item.getValue("stockNumFn");

    const isChangeStockNum = originStockNum !== item.getValue("stockNumForm");
    if (isChangeStockNum) {
      if (this.orderUpdateChangeMap.has(key)) {
        const updateMapItem = this.orderUpdateChangeMap.get(key)
        updateMapItem.isUpdateStock = true;
        updateMapItem.stockNumFn = item.getValue("stockNumForm")
        return;
      }
      const newMapItem: ReqUpdateOrderByUserDto = {
        groupId: -1,
        storeCdFv: item.storeCdFv,
        itemCdFv: item.itemCdFv,
        closingTime: item.closingTime,

        orderNumFn: item.getValue('orderNumForm'),
        orderNumModFn: item.orderNumModFn,
        orderNumVersionFn: item.orderNumVersionFn,
        orderDate: item.orderDate,
        orderDateNext: item.orderDateNext,
        deliveryDate: item.deliveryDate,
        deliveryDateNext: item.deliveryDateNext,

        stockNumFn: item.getValue('stockNumForm'),
        stockNumModFn: item.stockNumModFn,
        stockNumVersionFn: item.stockNumVersionFn,

        rowAction: 0, // 0: noChange; 1: delete; 2: add
        isUpdateOrder: false,
        isUpdateStock: true
      };
      this.orderUpdateChangeMap.set(key, newMapItem);
      return;
    }
    // else
    if (this.orderUpdateChangeMap.has(key)) {
      const updateMapItem = this.orderUpdateChangeMap.get(key)
      updateMapItem.isUpdateStock = false;
      updateMapItem.stockNumFn = item.getValue("stockNumForm")
      if (updateMapItem.rowAction === 0 && !updateMapItem.isUpdateOrder) {
        this.orderUpdateChangeMap.delete(key);
      }
    }
  }

  initHandleChangeOrderAndStockNum() {
    this.unSubscriptionOrderAndStockForm();
    this.orderListAll.forEach(item => {
      this.orderAndStockFormSubscriptions.push(item.orderNumForm.valueChanges.subscribe(data => this.synchronizeOrderNumChangeInUpdateMap(item)))
      if (this.commonService.config.orderEditInBara) {
        this.orderAndStockFormSubscriptions.push(item.orderBaraNumForm.valueChanges.subscribe(data => this.synchronizeOrderBaraNumChangeInUpdateMap(item)))
      }
      let form: FormControl;
      if (this.commonService.config.orderEditInBara) {
        form = item.orderBaraNumForm;
      } else {
        form = item.orderNumForm;
      }
      this.orderAndStockFormSubscriptions.push(form.valueChanges
        .pipe(
          startWith(form.value),
          pairwise()
        )
        .subscribe(([prevVal, newVal]) => {
          this.commonService.valueCheckOrderNum(form, prevVal, newVal);
        })
      );
      this.orderAndStockFormSubscriptions.push(item.stockNumForm.valueChanges.subscribe(data => this.synchronizeStockNumChangeInUpdateMap(item)))
    });
  }

  unSubscriptionOrderAndStockForm() {
    if (!this.orderAndStockFormSubscriptions || this.orderAndStockFormSubscriptions.length <= 0) return;
    this.orderAndStockFormSubscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.orderAndStockFormSubscriptions = []
  }

  calcServerTime() {
    let server = new Date();
    let device = new Date();
    server.setTime(device.getTime() + this.timeLag);

    return server;
  }

  addItemClosingTime(itemClosingTime: number) {
    if (itemClosingTime == 0) return;

    let h = Math.floor(itemClosingTime / 100);
    let m = itemClosingTime % 100;
    let closingTime = new Date();
    closingTime.setHours(h, m, 0);

    if (!this.closingTimeList.find((item) => {
      item.getTime() == closingTime.getTime();
    })) {
      this.closingTimeList.push(closingTime);
      this.closingTimeList.sort((a, b) => {
        if (a.getTime() < b.getTime()) return -1;
        if (a.getTime() == b.getTime()) return 0;
        return 1;
      });
    }
  }

  checkClosingTimeAll() {
    let serverTime = this.calcServerTime();
    this.orderListAll.forEach((item) => {item.checkClosingTime(this.propClosingTime, serverTime)});
  }

  nextClosingTime() {
    let now = this.calcServerTime();
    let next: Date;
    for (let ct of this.closingTimeList) {
      if (ct.getTime() <= now.getTime()) continue;
      next = ct;
    }
    if (!next) {
      this.clearTimer();
      return;
    }
    this.startTimer(next.getTime() - now.getTime());
  }

  startTimer(timeout: number) {
    this.clearTimer();
    this.timerId = setTimeout(
      () => {
        this.checkClosingTimeAll();
        this.nextClosingTime();
      }, timeout);
  }

  clearTimer() {
    if (this.timerId < 0) return;
    clearTimeout(this.timerId);
    this.timerId = -1;
  }

  getOrderStopFlagSelectedValues(orderStopFlagFormGroup: FormGroup): number[] {
    const orderStopFlagList = [...this.commonService.getOrderStopSearchCondList()];
    if (orderStopFlagList?.length <= 0) return [];
    const selectedList: number[] = orderStopFlagList.filter(orderStopFlagItem => {
      return orderStopFlagFormGroup.get(`orderStopFlagItem_${orderStopFlagItem.value}`)?.value === true
    }).map(orderStopFlagItem => orderStopFlagItem?.value);
    return selectedList;
  }

  getItemViewSortDto(itemViewSort: FormGroup): ReqItemViewSortDto[] {
    let res: ReqItemViewSortDto[] = [];
    if (!itemViewSort?.controls) return [];
    for (let groupName in itemViewSort.controls) {
      const fG = itemViewSort?.controls[groupName];
      // if (fG.get('orderType')?.value == '' ||  parseInt(fG.get('orderValue').value) == 0) continue;
      const row: ReqItemViewSortDto = {
        column: groupName,
        orderType: fG.get('orderType')?.value ?? '',
        orderValue: fG.get('orderValue')?.value ? parseInt(fG.get('orderValue').value) : 0
      }
      res.push(row);
    }
    return res;
  }

  bgColor(item: ItemRec2, key: string) {
    if (key === "orderResult1") {
      if (item.orderResult1IsToday == true) return 1;
      return 0;
    }
    if (key === "orderBaraNumFn") {
      if (!this.commonService.config.orderEditInBara) return 0;
      if (item.orderBaraNumForm.value === "") return 0;
      if (parseInt(item.orderBaraNumForm.value) === item.getValue("orderBaraNumFn")) return 0;
      return 2;
    }
    return 0;
  }

  openBgcolorDialog() {
    let subsc = this.commonService.dialog.open(OrderEditBgcolorDialogComponent, {
      disableClose: false,
      data: "orderStock"
    }).afterClosed().subscribe(
      () => {
        subsc.unsubscribe();
      }
    );
  }

  orderEditable(item: ItemRec2) {
    if (!this.isStockEditable) return false;
    let flag = this.commonService.config.orderStopFlag.find((flag) => flag.value === item.getValue("orderStopFlagFnValue"));
    if (!flag) return false;
    if (flag.caseItem && !this.commonService.config.stockEditCaseItem) return false;
    return true;
  }

  stockEditable(item: ItemRec2) {
    if (!this.isStockEditable) return false;
    let flag = this.commonService.config.orderStopFlag.find((flag) => flag.value === item.getValue("orderStopFlagFnValue"));
    if (!flag) return false;
    if (flag.caseItem && !this.commonService.config.stockEditCaseItem) return false;
    return true;
  }

  canDeactivate() {
    if (this.orderUpdateChangeMap?.size <= 0 && this.orderBaraUpdateChangeMap?.size <= 0) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
