import { RspOrderStopTermCtgDto } from 'src/app/response/rsp-order-stop-term-ctg';

export class OrderStopTermCtgRec {
    storeCdFv: string;
    storeNameFv: string;
    ctg0CdFv: string;
    ctg0NameFv: string;
    ctg1CdFv: string;
    ctg1NameFv: string;
    ctg2CdFv: string;
    ctg2NameFv: string;
    ctg3CdFv: string;
    ctg3NameFv: string;
    dateTypeFn: number;
    dateBeginFv: string;
    dateEndFv: string;

    storeFv: string;
    ctg0Fv: string;
    ctg1Fv: string;
    ctg2Fv: string;
    ctg3Fv: string;
    dateTypeFv: string;

    constructor(rspOrderStopTermCtgDto: RspOrderStopTermCtgDto) {
        for (var key of Object.keys(rspOrderStopTermCtgDto)) {
            this[key] = rspOrderStopTermCtgDto[key];
        }

        if (this.storeCdFv != null)
            this.storeFv = this.storeCdFv + ":" + this.storeNameFv;
        if (this.ctg0CdFv != null)
            this.ctg0Fv = this.ctg0CdFv + ":" + this.ctg0NameFv;
        if (this.ctg1CdFv != null)
            this.ctg1Fv = this.ctg1CdFv + ":" + this.ctg1NameFv;
        if (this.ctg2CdFv != null)
            this.ctg2Fv = this.ctg2CdFv + ":" + this.ctg2NameFv;
        if (this.ctg3CdFv != null)
            this.ctg3Fv = this.ctg3CdFv + ":" + this.ctg3NameFv;
        if (this.dateTypeFn != null)
            this.setDateType(this.dateTypeFn)

    }

    setDateType(type: number) {
        if (type == 1) {
            this.dateTypeFv = "発注日";
        } else {
            this.dateTypeFv = "納品日";
        }
    }
}