import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonService } from '../../../service/common.service';
import { ExpiryGroup, ExpiryItemRec } from '../0_def/expiry-item-rec';

@Component({
  selector: 'app-item-expiry-summary',
  templateUrl: './item-expiry-summary.component.html',
  styleUrls: ['./item-expiry-summary.component.css']
})
export class ItemExpirySummaryComponent implements OnInit, OnDestroy, OnChanges {

  @Input() expiryItem: ExpiryItemRec;

  constructor(
    public commonService: CommonService
    ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  getGroupCdName(group: ExpiryGroup) {
    if (group.groupName) {
      return group.groupUsrCd + ":" + group.groupName;
    }
    return group.groupUsrCd;
  }
}
