import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { CameraDef, CameraLayout, CameraLayoutConfig } from 'src/app/page/camera/CameraDef';
import { CameraLayoutConfigDto, ReqCameraLayoutConfigUpdate } from 'src/app/request/req-execute-camera-config-update';
import { ReqExecuteCameraLayoutAdd } from 'src/app/request/req-execute-camera-layout-add';
import { ReqCameraLayoutDelete } from 'src/app/request/req-execute-camera-layout-delete';
import { ReqCameraLayoutUpdate } from 'src/app/request/req-execute-camera-layout-update';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { ResCameraLayoutConfigUpdate } from 'src/app/response/rsp-execute-camera-config-update';
import { RspCameraLayoutAdd } from 'src/app/response/rsp-execute-camera-layout-add';
import { RspCameraLayoutDelete } from 'src/app/response/rsp-execute-camera-layout-delete';
import { RspCameraLayoutUpdate } from 'src/app/response/rsp-execute-camera-layout-update';
import { ExecuteQueryRecDto, RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CameraLayoutConfigComponent } from '../camera-layout-config/camera-layout-config.component';

@Component({
  selector: 'app-camera-layout',
  templateUrl: './camera-layout.component.html',
  styleUrls: ['./camera-layout.component.css'],
})
export class CameraLayoutComponent implements OnInit, OnDestroy {

  public layoutSelectForm: FormControl = new FormControl();
  public addFormGroup: FormGroup;
  public editFormGroup: FormGroup;

  public cameraList: CameraDef[] = [];
  public layoutList: CameraLayout[] = [];
  public selectedLayout: CameraLayout;
  public selectedLayoutCopy: CameraLayout;

  public layoutConfigList: CameraLayoutConfig[] = [];
  public layoutConfigMatrix: CameraLayoutConfig[][] = [];

  private subscriptionQuery: Subscription;
  private subscription$ = new Subject();

  private containerElement: HTMLElement;
  private containerWidth: number;
  private containerHeight: number;
  tabSelection: number = 0;  
  disableControl: boolean = false;
  disableState: boolean;
  disableClearAll: boolean = true;

  @ViewChildren(CameraLayoutConfigComponent) configComponents: QueryList<CameraLayoutConfigComponent>;


  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.addFormGroup = this.fb.group({
      layoutName: ["", [Validators.required, this.nameAddValidator.bind(this), Validators.maxLength(100)]],
      layoutComment: ["", [Validators.maxLength(100)]],
      layoutCountX: [1, [Validators.required, (form) => this.configCountValidator(form)]],
      layoutCountY: [1, [Validators.required, (form) => this.configCountValidator(form)]]
    });
    this.editFormGroup = this.fb.group({
      layoutName: ["", [Validators.required, this.nameEditValidator.bind(this), Validators.maxLength(100)]],
      layoutComment: ["", [Validators.maxLength(100)]],
      layoutCountX: [1, [Validators.required, (form) => this.configCountValidator(form)]],
      layoutCountY: [1, [Validators.required, (form) => this.configCountValidator(form)]]
    });

    this.getCameraList();
    window.addEventListener("resize", () => { this.resized(); });
    setTimeout(() => { this.resized(); }, 0);
    this.addFormGroup.valueChanges.pipe(takeUntil(this.subscription$), debounceTime(100)).subscribe(val => this.disableState = this.disableStateFnc());
    this.editFormGroup.valueChanges.pipe(takeUntil(this.subscription$), debounceTime(100)).subscribe(val => this.disableState = this.disableStateFnc());

  }

  ngOnDestroy(): void {
    if (this.subscriptionQuery) this.subscriptionQuery.unsubscribe();
    this.subscription$.next();
    this.subscription$.complete();
    window.removeEventListener("resize", () => { this.resized(); });
  }

  resized() {
    let innerContainer: HTMLElement = document.getElementById("inner-container");
    this.containerElement = document.getElementById("config-edit");
    if (!this.containerElement) return;
    // let clientRect = this.containerElement.getBoundingClientRect();
    let innerRect = innerContainer.getBoundingClientRect();
    let containerRect = this.containerElement.getBoundingClientRect();
    this.containerWidth = innerRect.width - 5;
    this.containerHeight = window.innerHeight - containerRect.top - 70;
    this.containerElement.style["max-height"] = "" + this.containerHeight + "px";
    this.containerElement.style["width"] = "" + this.containerWidth + "px";
  }

  tabChanged(event: number) {
    this.tabSelection = event;
  }

  canDeactivate(): Observable<boolean> {
    let isDeactive = true;
    if (this.getConfigChanges().length > 0 || this.editFormGroup.dirty) {
      isDeactive = false;
    }
    if (isDeactive) return of(true);
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  selectLayout(layout: CameraLayout) {
    this.selectedLayout = layout;
    this.editFormGroup.reset({
      layoutName: this.selectedLayout.layoutName,
      layoutComment: this.selectedLayout.comment,
      layoutCountX: this.selectedLayout.countX,
      layoutCountY: this.selectedLayout.countY
    });
    this.buildConfigMatrix();
    if (!this.containerElement) setTimeout(() => { this.resized(); }, 0);
  }

  clearProgressState() {
    if (this.subscriptionQuery) this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    this.commonService.closeSpinner();
  }

  getCameraList() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "camera/getCameraDef",
      bindVariables: ["*"]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.executeQuery(request).subscribe(
      (response) => this.receiveCameraList(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveCameraList(response: RspExecuteQuery) {
    this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    if (this.httpBasic.handleAppError(response)) return;

    this.refreshCameraList(response.rows);
    this.getLayoutList();
  }

  getLayoutList() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "camera/getCameraLayout",
      bindVariables: []
    };

    this.subscriptionQuery = this.httpBasic.executeQuery(request).subscribe(
      (response) => this.receiveLayoutList(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveLayoutList(response: RspExecuteQuery) {
    this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    if (this.httpBasic.handleAppError(response)) return;
    this.refreshCameraLayout(response.rows);
    this.getLayoutConfigList();
  }

  getLayoutConfigList() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "camera/getCameraLayoutConfig",
      bindVariables: []
    };

    this.subscriptionQuery = this.httpBasic.executeQuery(request).subscribe(
      (response) => this.receiveLayoutConfigList(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveLayoutConfigList(response: RspExecuteQuery) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    this.refreshCameraConfig(response.rows);
    this.changesSelectionFormFirst();
  }

  findCamera(cameraCd: string): CameraDef | null {
    for (let camera of this.cameraList) {
      if (camera.cameraCd === cameraCd) return camera;
    }

    return null;
  }

  findCameraConfig(layoutCd: number, x: number, y: number): CameraLayoutConfig | null {
    for (let config of this.layoutConfigList) {
      if (config.layoutCd == layoutCd &&
        config.posX == x &&
        config.posY == y
      ) return config;
    }

    return null;
  }

  findCameraConfigInLayout(layout: CameraLayout, x: number, y: number): CameraLayoutConfig | null {
    for (let config of layout.config) {
      if (config.posX == x && config.posY == y) return config;
    }

    return null;
  }

  buildLayoutTree() {
    for (let config of this.layoutConfigList) {
      config.camera = this.findCamera(config.cameraCd);
    }

    for (let layout of this.layoutList) {
      for (let y = 0; y < layout.countY; y++) {
        for (let x = 0; x < layout.countX; x++) {
          let config = this.findCameraConfig(layout.layoutCd, x, y);
          if (config == null) {
            let emptyConfig: ExecuteQueryRecDto = {
              colData: ["" + layout.layoutCd, "", "" + x, "" + y, "0", "0"]
            }
            config = new CameraLayoutConfig(emptyConfig);
          }
          layout.config.push(config);
        }
      }
    }
  }

  buildConfigMatrix() {
    if (this.selectedLayout == undefined) {
      return
    }
    this.layoutConfigMatrix = [];
    for (let y = 0; y < this.selectedLayout.countY; y++) {
      this.layoutConfigMatrix.push([]);
      for (let x = 0; x < this.selectedLayout.countX; x++) {
        this.layoutConfigMatrix[y].push(this.findCameraConfigInLayout(this.selectedLayout, x, y));
      }
    }
  }

  isDirty(): boolean {
    if (this.isDirtyConfig()) return true;
    return false;
  }

  isDirtyConfig(): boolean {
    if (this.configComponents) {
      for (let config of this.configComponents.toArray()) {
        if (config.isDirty() && config.formGroup.valid) {
          this.disableClearAll = false;
          return true;
        } else if (config.isDirty()) {
          this.disableClearAll = false;
          return false;
        }
      }
    }
    this.disableClearAll = true;
    return false;
  }


  configChanged() {
    if (this.isDirty()) {
      this.disableControl = true;
    } else {
      this.disableControl = false;
    }
    this.disableState = this.disableStateFnc();
  }

  // the nameErrorMsg() function is executed every time Angular change detection runs. And that can be many times!. replace to nameValidator
  nameErrorMsg(): string {
    let layoutName: string = this.addFormGroup.get("layoutName").value;
    layoutName = layoutName.trim();
    if (layoutName === "") return "";
    for (let layout of this.layoutList) {
      if (layout.layoutName === layoutName) return "レイアウト名が既に存在します。"
    }
    return "";
  }

  configCountValidator(form: FormControl) {
    let value = parseInt(form.value);
    if (value < 1 || value > 10) {
      return { "invalidValue": "値は、１以上１０以下でなければなりません。" };
    }
    return null;
  }

  addLayout() {
    let body: ReqExecuteCameraLayoutAdd = {
      access: { ...this.commonService.loginUser },
      cameraLayoutAdd: {
        ...this.addFormGroup.value,
        layoutName: this.addFormGroup.get('layoutName').value.trim()
      },
      cameraLayoutCopySource: {
        layoutCd: 0,
        layoutComment: '',
        layoutCountX: 0,
        layoutCountY: 0,
        layoutName: ''
      }
    };
    if (this.selectedLayoutCopy != undefined) {
      body.cameraLayoutCopySource = {
        layoutCd: this.selectedLayoutCopy.layoutCd,
        layoutComment: this.selectedLayoutCopy.comment,
        layoutCountX: this.selectedLayoutCopy.countX,
        layoutCountY: this.selectedLayoutCopy.countY,
        layoutName: this.selectedLayoutCopy.layoutName
      }
    };
    this.commonService.openSpinner(this.commonService.pageTitle, '登録中・・・');
    this.subscriptionQuery = this.httpBasic.addCameraLayout(body).subscribe(
      (res: RspCameraLayoutAdd) => {
        this.receiveCameraLayoutAdd(res);
      },
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveCameraLayoutAdd(response: RspCameraLayoutAdd) {
    // clear progess
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.clearLayout();
    this.refreshCameraList(response.cameraList);
    this.refreshCameraLayout(response.layoutList);
    this.refreshCameraConfig(response.cameralayoutConfigList);
    this.changesSelectionForm(response.cameraSeletion);
  }

  refreshCameraList(cameraList: ExecuteQueryRecDto[]) {
    // refesh cameraList
    this.cameraList = [];
    for (let item of cameraList) {
      let camera = new CameraDef(item);
      this.cameraList.push(camera);
    }
  }

  refreshCameraConfig(cameralayoutConfigList: ExecuteQueryRecDto[]) {
    // refesh layoutConfigList
    this.layoutConfigList = [];
    for (let item of cameralayoutConfigList) {
      let config = new CameraLayoutConfig(item);
      this.layoutConfigList.push(config);
    }
  }

  refreshCameraLayout(layoutList: ExecuteQueryRecDto[]) {
    //refesh camera layout
    this.layoutList = [];
    for (let item of layoutList) {
      let layout = new CameraLayout(item);
      this.layoutList.push(layout);
    }
  }

  changesSelectionForm(layoutCd: number) {
    this.buildLayoutTree();
    if (this.layoutList.length > 0) {
      let layout = this.layoutList.find(layout => layout.layoutCd === layoutCd);
      this.layoutSelectForm.setValue(layout);
      this.selectLayout(layout);
    }
  }

  changesSelectionFormFirst() {
    this.buildLayoutTree();
    if (this.layoutList.length > 0) {
      this.layoutSelectForm.setValue(this.layoutList[0]);
      this.selectLayout(this.layoutList[0]);
    } else {
      delete this.selectedLayout;
      delete this.selectedLayoutCopy;
      this.editFormGroup.reset({
        layoutCountX: 1,
        layoutCountY: 1
      },{ emitEvent: false});
      this.buildConfigMatrix();
      this.layoutSelectForm.setValue(null);
    }
  }

  copyLayout() {
    this.selectedLayoutCopy = this.selectedLayout;
    this.addFormGroup.patchValue({
      layoutName: this.selectedLayoutCopy.layoutName + '_コピー',
      layoutComment: this.selectedLayoutCopy.comment,
      layoutCountX: this.selectedLayoutCopy.countX,
      layoutCountY: this.selectedLayoutCopy.countY
    });
    this.addFormGroup.markAsDirty();
  }

  clearLayout() {
    this.addFormGroup.reset({
      layoutName: '',
      layoutComment: '',
      layoutCountX: 1,
      layoutCountY: 1
    });
    delete this.selectedLayoutCopy;
  }

  editClearLayout() {
    this.editFormGroup.reset({
      layoutName: this.selectedLayout.layoutName,
      layoutComment: this.selectedLayout.comment,
      layoutCountX: this.selectedLayout.countX,
      layoutCountY: this.selectedLayout.countY
    });
  }

  updateLayout() {
    const body: ReqCameraLayoutUpdate = {
      access: { ...this.commonService.loginUser },
      cameraLayoutUpdate: {
        layoutCd: this.layoutSelectForm.value.layoutCd,
        ...this.editFormGroup.value,
        layoutName: this.editFormGroup.get('layoutName').value.trim()
      }
    };
    this.commonService.openSpinner(this.commonService.pageTitle, '更新中・・・');
    this.subscriptionQuery = this.httpBasic.updateCameraLayout(body).subscribe(
      (res: RspCameraLayoutUpdate) => {
        this.receiveCameraLayoutUpdate(res);
      },
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveCameraLayoutUpdate(response: RspCameraLayoutUpdate) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.disableControl = false;
    this.refreshCameraList(response.cameraList);
    this.refreshCameraLayout(response.layoutList);
    this.refreshCameraConfig(response.cameralayoutConfigList);
    this.changesSelectionForm(response.cameraSeletion);
  }

  deleteLayout() {
    this.subscriptionQuery = this.commonService.openYesNoDialog(this.commonService.pageTitle, 'レイアウト「' + this.layoutSelectForm.value.layoutName + '」を削除しますか？').pipe(
      takeWhile(confirm => confirm == true),
      switchMap(() => {
        const body: ReqCameraLayoutDelete = {
          access: { ...this.commonService.loginUser },
          cameraLayout: {
            layoutCd: this.layoutSelectForm.value.layoutCd
          }
        };
        this.commonService.openSpinner(this.commonService.pageTitle, '削除中・・・');
        return this.httpBasic.deleteCameraLayout(body);
      })
    ).subscribe(
      (response: RspCameraLayoutDelete) => {
        this.receiveCameraLayoutDelete(response);
      },
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      });
  }

  receiveCameraLayoutDelete(response: RspCameraLayoutDelete) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    this.refreshCameraList(response.cameraList);
    this.refreshCameraLayout(response.layoutList);
    this.refreshCameraConfig(response.cameralayoutConfigList);
    this.changesSelectionFormFirst();
  }

  updateCameraLayoutConfig() {
    let configs = this.getConfigChanges();
    if (configs.length < 1) {
      return;
    }
    this.commonService.openSpinner(this.commonService.pageTitle, '更新中・・・');
    const body: ReqCameraLayoutConfigUpdate = {
      access: { ...this.commonService.loginUser },
      listCameraLayoutConfigUpdate: configs
    }
    this.httpBasic.updateCameraConfig(body).subscribe(
      (response: ResCameraLayoutConfigUpdate) => {
        this.receiveCameraConfigUpdate(response);
      },
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveCameraConfigUpdate(response: ResCameraLayoutConfigUpdate) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    this.refreshCameraLayout(response.layoutList);
    this.refreshCameraConfig(response.cameralayoutConfigList);
    this.disableControl = false;
    this.disableClearAll = true;
    this.changesSelectionForm(this.layoutSelectForm.value.layoutCd);
  }

  getConfigChanges(): CameraLayoutConfigDto[] {
    let configs: CameraLayoutConfigDto[] = [];
    this.configComponents.forEach((item: CameraLayoutConfigComponent) => {
      if (item.isDirty() && item.formGroup.valid) {
        let config: CameraLayoutConfigDto = {

          cameraCd: item.formGroup.controls.cameraCd.value ? item.formGroup.controls.cameraCd.value : '',
          layoutCd: item.layoutConfig.layoutCd,
          posXFn: item.layoutConfig.posX,
          posYFn: item.layoutConfig.posY,
          sizeXFn: item.formGroup.controls.sizeX.value,
          sizeYFn: item.formGroup.controls.sizeY.value
        };
        configs.push(config);
      }
    });
    return configs;
  }

  changesLayout() {
    this.changesSelectionForm(this.layoutSelectForm.value.layoutCd);
  }

  clearAllConfig() {
    this.configComponents.forEach((item: CameraLayoutConfigComponent) => {
      if (item.isDirty()) {
        item.cancel();
      }
    });
    this.disableClearAll = true;
  }

  disableStateFnc()  {    
    return this.disableControl || this.editFormGroup.dirty
    || !((this.addFormGroup.get('layoutName').value === '' 
    && this.addFormGroup.get('layoutComment').value === '') && (this.addFormGroup.get('layoutCountX').value == 1 
    && this.addFormGroup.get('layoutCountY').value == 1));    
  }

  nameAddValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value === "" || control.value == null) return null;
    let layoutName = control.value.trim();
    if (layoutName === "") {
      return {required:true};
    }
    let isValid = this.layoutList.every(x => x.layoutName !== layoutName);
    return !isValid ? { nameErrorMsg: true } : null;
  }

  nameEditValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value === "" || control.value == null) return null;
    let layoutName = control.value.trim();
    if (layoutName === "") {
      return {required:true};
    }
    if (layoutName === this.selectedLayout.layoutName) return null;
    let isValid = this.layoutList.every(x => x.layoutName !== layoutName);
    return !isValid ? { nameErrorMsg: true } : null;
  }

  compareLayout(t1: CameraLayout, t2: CameraLayout) {
    return t1 && t2 ? t1.layoutCd === t2.layoutCd : t1 === t2;
  }
}
