import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RspGetUsers, UserDto } from 'src/app/response/rsp-get-users';
import { RspGetRole } from 'src/app/response/rsp-get-role';
import { Role } from '../role/role';
import { RspUpdUser } from 'src/app/response/rsp-upd-user';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const UPDUSER_MODE_INSERT: number = 1;
const UPDUSER_MODE_UPDATE: number = 2;
const UPDUSER_MODE_DELETE: number = 3;

export class User {
  isNew: boolean;
  storeCd: string;
  storeName: string;
  userId: string;
  userName: string;
  passwd:  string;
  roleId: number;
  roleName: string;
}

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {

  public isReadonly: boolean;
  public storeForm: FormControl;
  public formGroup: FormGroup;
  public roles: Role[] = [];
  public users: User[] = [];
  public usersShow: User[] = [];
  public recordCount: number = 0;
  public selectedUser: any;
  public capslockMsg: string;
  public stores: LoginStoreDto[];
  public stores1: LoginStoreDto[];
  public allStores: LoginStoreDto[];

  // private passwdValidator = Validators.pattern('(?=.*[A-Z,a-z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}');
  private passwdValidator = Validators.pattern(/^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z\d$@$!%*?&]{8,}$/);
  private subscriptionStore: Subscription;

  public columnIds: string[] = ["storeName", "userId", "userName", "roleName"];
  public columnDefs: TableColumnDef[] = [
    {columnId: "storeName", header: "店舗名", width: 120},
    {columnId: "userId", header: "ユーザID", width: 120},
    {columnId: "userName", header: "ユーザ名", width: 120},
    {columnId: "roleName", header: "役割名", width: 120}
  ];

  @ViewChild(MatTable, {static: true})  matTable: MatTable<any>;
  @ViewChild(MatPaginator, {static:false}) matPagenator: MatPaginator;

  constructor
  (
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) {

    this.storeForm = new FormControl(this.commonService.loginUser.storeCd);
    this.formGroup = fb.group({
      storeCd: ["", Validators.required],
      userId: ["", [Validators.required, Validators.pattern('[A-Za-z0-9_$-]*')]],
      userName: ["", Validators.required],
      passwd: ["", this.passwdValidator],
      confirm: [""],
      roleId: [undefined, (control:FormControl) => {
        return control.value == undefined ? {"role": "役割が未設定です"} : null;
      }]
    },
    {
      validators: (control) => {
          if (control.get("passwd").value == control.get("confirm").value) return null;
          return {"unmatch": "パスワードが不一致"};
        }
    });

    this.formGroup.disable();
  }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("user");
    if (this.commonService.config.includeNonAutoOrderStore.user) {
      this.allStores = this.commonService.allStores;
      this.stores1 = this.commonService.allStores;
    } else {
      this.allStores = this.commonService.stores;
      this.stores1 = this.commonService.stores;
    }
    if (this.commonService.stores.length > 1) {
      // this.stores = [{storeCd: "*", storeName:"全店"}, ...this.commonService.stores];
      this.stores = [{storeCd: "*", storeName:"全店"}, ...this.allStores];
    } else {
      this.stores = [...this.commonService.stores];
      /*
      this.stores = [{
        storeCd: this.commonService.loginUser.storeCd,
        storeName: this.commonService.loginUser.storeName
      }];
      */
      this.stores1 = this.stores;
    }

    this.storeForm.setValue(this.commonService.loginUser.storeCd);
    this.subscriptionStore = this.storeForm.valueChanges.subscribe(
      (storeCd) => {
        this.selectedUser = undefined;
        this.clearFormGroup();
        this.getUsers(storeCd);
      }
    );
    this.getRole();
    this.setTableHeight();
  }

  ngOnDestroy() {
    this.subscriptionStore?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "user-table";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 56;
    let margin = 10;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }
  
  getRole() {
    this.commonService.openSpinner(this.commonService.pageTitle, "初期化中・・・");
    let  subsc = this.httpBasic.getRole().subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveRole(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveRole(response: RspGetRole) {
    if (this.httpBasic.handleAppError(response)) return;

    for (let rspRole of response.roles) {
      let role = new Role();
      role.roleId = rspRole.roleId;
      role.roleName = rspRole.roleName;
      this.roles.push(role);
    }

    this.getUsers(this.commonService.loginUser.storeCd);
  }
  
  getUsers(storeCd: string) {
    this.commonService.openSpinner(this.commonService.pageTitle, "ユーザを検索中・・・");
    let subsc = this.httpBasic.getUsers(storeCd).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveUsers(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUsers(response: RspGetUsers) {
    if (this.httpBasic.handleAppError(response)) return;

    this.users = [];
    for (let userDto of response.users) {
      let user = new User();
      user.isNew = false;
      user.storeCd = userDto.storeCd;
      if (userDto.storeName === "" && userDto.storeCd === this.commonService.config.honbu?.storeCd) {
        user.storeName = userDto.storeCd + "：" + this.commonService.config.honbu.storeName;
      } else {
        user.storeName = userDto.storeCd + "：" + userDto.storeName;
      }
      user.userId = userDto.userId;
      user.userName = userDto.userName;
      user.passwd = userDto.passwd;
      user.roleId = userDto.roleId;
      user.roleName = userDto.roleName;
      this.users.push(user);
    }
    this.sortUsers();
    this.recordCount = this.users.length;
    this.matPagenator.pageIndex = 0;
    this.pageChanged();
  }

  sortUsers() {
    let storeCdMaxLen = 0;
    this.users.forEach((store) => {
      if (store.storeCd.length > storeCdMaxLen) storeCdMaxLen = store.storeCd.length;
    });
    this.users.sort((a, b) => {
      let acd = a.storeCd.padStart(storeCdMaxLen, " ");
      let bcd = b.storeCd.padStart(storeCdMaxLen, " ");
      if (acd < bcd) return -1;
      if (acd > bcd) return 1;
      if (a.userId < b.userId) return -1;
      if (a.userId > b.userId) return 1;
      return 0;
    });
  }

  clickUser(user: User) {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.selectUser(user);
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          if (this.selectedUser && this.selectedUser.isNew) {
            this.deleteCurrentUser();
          }
          this.selectUser(user);
        }
      }
    );
  }

  selectUser(user: User) {
    this.selectedUser = user;

    this.formGroup.get("storeCd").setValue(user.storeCd);
    this.formGroup.get("userId").setValue(user.userId);
    this.formGroup.get("userName").setValue(user.userName);
    this.formGroup.get("passwd").setValue("");
    this.formGroup.get("confirm").setValue("");
    this.formGroup.get("roleId").setValue(user.roleId ? "" + user.roleId: undefined);

    if (user.isNew) {
      this.formGroup.get("passwd").setValidators([Validators.required, this.passwdValidator]);
    } else {
      this.formGroup.get("passwd").setValidators(this.passwdValidator);
    }
    this.formGroup.markAsPristine();
    this.formGroup.enable();
    this.formGroup.get("userId").disable();
    this.capslockMsg = undefined;
  }

  newUser() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.newUserBody();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          if (this.selectedUser && this.selectedUser.isNew < 0) {
            this.deleteCurrentUser();
          }
          this.newUserBody();
        }
      }
    );
  }

  newUserBody() {
    let user = new User();
    user.isNew = true;
    user.storeCd = this.storeForm.value;
    user.userId = "new-user";
    user.userName = "";
    user.passwd = "";
    user.roleId = undefined;
    user.roleName = "";

    this.users.push(user);
    this.recordCount = this.users.length;
    this.lastPage();
    // this.matTable.renderRows();
    this.selectUser(user);
    this.formGroup.get("userId").enable();
    this.formGroup.markAsDirty();
  }

  deleteCurrentUser() {
    let found = false;
    let index = 0;
    for (index = 0; index < this.users.length; index++) {
      if (this.users[index] == this.selectedUser) {
        found = true;
        break;
      }
    }
    if (found == false) return;
    if (this.users[index].isNew) {
      this.afterDeleteUser(index);
      return;
    }

    let msg = "ユーザ「" + this.users[index].userId + "」を削除しますか？";
    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      (response) => {
        subsc.unsubscribe();
        if (response == true) {
          this.deleteUserFromDB(index);
        }
      }
    );
  }

  deleteUserFromDB(index: number) {
    this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
    let subsc = this.httpBasic.updUser(
      UPDUSER_MODE_DELETE,
      this.users[index].userId,
      this.users[index].storeCd,
      this.users[index].storeCd,
      this.users[index].userId,
      this.users[index].userName,
      this.users[index].passwd,
      this.users[index].roleId).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveDeleteUser(response, index);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDeleteUser(response: RspUpdUser, index: number) {
    if (this.httpBasic.handleAppError(response)) return;
    this.afterDeleteUser(index);
  }

  afterDeleteUser(index: number) {
    this.users.splice(index, 1);
    this.recordCount = this.users.length;
    this.pageChanged();
    // this.matTable.renderRows();
    this.selectedUser = undefined;
    this.clearFormGroup();
}

  clearFormGroup() {
    this.formGroup.get("storeCd").setValue("");
    this.formGroup.get("userId").setValue("");
    this.formGroup.get("userName").setValue("");
    this.formGroup.get("passwd").setValue("");
    this.formGroup.get("confirm").setValue("");
    this.formGroup.get("roleId").setValue("");
    this.formGroup.markAsPristine();
    this.formGroup.disable();
    this.capslockMsg = undefined;
  }

  clickUpdate() {
    if (this.selectedUser.isNew) {
      this.insertUser();
    } else {
      this.updateUser();
    }
  }

  setName() {
    let storeCd = this.formGroup.get("storeCd").value;
    let roleId = parseInt(this.formGroup.get("roleId").value);
    if (this.selectedUser.isNew) this.selectedUser.userId = this.formGroup.get("userId").value;
    // this.selectedUser.storeName = storeCd + "：" + this.commonService.stores.find((store) => store.storeCd === storeCd)?.storeName;
    this.selectedUser.storeName = storeCd + "：" + this.allStores.find((store) => store.storeCd === storeCd)?.storeName;
    this.selectedUser.roleName = this.roles.find((role) => role.roleId === roleId)?.roleName;
    this.selectedUser.userName = this.formGroup.get("userName").value;
  }

  updateUser() {
    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    this.setName();
    let passwd = this.selectedUser.passwd;
    if (this.formGroup.get("passwd").value != "") {
      passwd = this.formGroup.get("passwd").value;
    }

    let subsc = this.httpBasic.updUser(
      UPDUSER_MODE_UPDATE,
      this.selectedUser.userId,
      this.selectedUser.storeCd,
      this.formGroup.get("storeCd").value,
      this.formGroup.get("userId").value,
      this.formGroup.get("userName").value,
      passwd,
      this.formGroup.get("roleId").value
    ).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveUpdate(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpdate(response: RspUpdUser) {
    if (this.httpBasic.handleAppError(response)) return;

    // this.dtoToUser(response.user, this.selectedUser);
    this.formGroup.markAsPristine();
    this.capslockMsg = undefined;
  }

  insertUser() {
    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.setName();

    let subsc = this.httpBasic.updUser(
      UPDUSER_MODE_INSERT,
      "",
      "",
      this.formGroup.get("storeCd").value,
      this.formGroup.get("userId").value,
      this.formGroup.get("userName").value,
      this.formGroup.get("passwd").value,
      this.formGroup.get("roleId").value
    ).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveInsert(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveInsert(response: RspUpdUser) {
    if (this.httpBasic.handleAppError(response)) return;
    this.selectedUser.isNew = false;

    // this.dtoToUser(response.user, this.selectedUser);
    this.formGroup.get("userId").disable();
    this.formGroup.markAsPristine();
    this.capslockMsg = undefined;
  }

  /*
  dtoToUser(userDto: UserDto, user: User) {
    user.storeCd = userDto.storeCd;
    // user.storeName = userDto.storeName;
    user.userId = userDto.userId;
    user.userName = userDto.userName;
    user.passwd = userDto.passwd;
    user.roleId = userDto.roleId;
    // user.roleName = userDto.roleName;
    user.isNew = false;
  }
  */

  pageChanged() {
    this.usersShow = [];
    if (this.recordCount == 0) {
      this.matPagenator.pageIndex = 0;
      return;
    }
    let start = this.matPagenator.pageIndex * this.matPagenator.pageSize;
    if (start >= this.recordCount) {
      this.matPagenator.pageIndex--;
      start = this.matPagenator.pageIndex * this.matPagenator.pageSize;
    }
    let end = start + this.matPagenator.pageSize;
    if (end > this.recordCount) end = this.recordCount;
    this.usersShow = this.users.slice(start, end);

    let id = "user-table";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  lastPage() {
    this.matPagenator.pageIndex = Math.floor(this.recordCount / this.matPagenator.pageSize);
    this.pageChanged();
  }

  cancelUser() {
    if (this.selectedUser.isNew) {
      this.deleteCurrentUser();
      return;
    }

    this.selectUser(this.selectedUser);
  }

  canDeactivate() {
    if (!this.formGroup.dirty) return true;

    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  onKeyup(event:any) {
    if (event.getModifierState && event.getModifierState("CapsLock")) {
      this.capslockMsg = "capslock が ON になっています。注意してください。";
    } else {
      this.capslockMsg = undefined;
    }
  }

  onBlur() {
    this.capslockMsg = undefined;
  }
}
