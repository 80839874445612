import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { LabelPrinterConfig } from '../page/printer/label-pinter';
import { Observable } from 'rxjs';
import { LabelPrinterDialogComponent } from '../page/printer/label-printer-dialog/label-printer-dialog.component';
import { OrderGroupDto } from '../webservice/order-group';
import { BarcodeDialogComponent } from '../dialog/barcode-dialog/barcode-dialog.component';
import { OrderGroupSelectDialogComponent } from '../dialog/order-group-select-dialog/order-group-select-dialog.component';
import { PrinterService } from './printer.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private commonService: CommonService) {}

  /*
  public openHtmlSelectItemDialog(title:string, itemList: HtmlSelectItem[]) {
    const dialogRef = this.commonService.dialog.open(HtmlSelectItemDialogComponent, {
      // position: {top: "10px"},
      // width: "95vw",
      maxHeight: "90vh",
      disableClose: true,
      data: {title: title, itemList: itemList}
    });

    return dialogRef.afterClosed();
  }
  */

  /*
  public openLabelPrinterDialog(data: LabelPrinterConfig) : Observable<LabelPrinterConfig> {
    const dialogRef = this.commonService.dialog.open(LabelPrinterDialogComponent, {
      // position: {top: "10px"},
      width: "95vw",
      maxWidth: "95vw",
      // height: "90vh",
      disableClose: true,
      data: data
    });

    return dialogRef.afterClosed();
  }
  */
  public openLabelPrinterDialog(printerService: PrinterService) {
    const dialogRef = this.commonService.dialog.open(LabelPrinterDialogComponent, {
      // position: {top: "10px"},
      width: "600px",
      maxWidth: "600px",
      // height: "90vh",
      disableClose: true,
      data: printerService.printerConfig
    }).afterClosed().subscribe(
      (response: LabelPrinterConfig) => {
        if (!response) return;
        printerService.printerConfig = response;
        if (printerService.printer !== printerService.printerConfig.printer) {
          printerService.printer = printerService.printerConfig.printer;
        }
      }
    );
  }


  public openOrderGroupSelectDialog(title:string, data:{currentGroupUsrCd: string; allGroups: OrderGroupDto[]}) : Observable<OrderGroupDto> {
    const dialogRef = this.commonService.dialog.open(OrderGroupSelectDialogComponent, {
      // position: {top: "10px"},
      // width: "95vw",
      // maxWidth: "95vw",
      maxHeight: "90vh",
      disableClose: true,
      data: data
    });

    return dialogRef.afterClosed();
  }

  openBarcodeDialog() : Observable<string> {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      // width: "330px",
      // maxWidth: "95vw",
      disableClose: true,
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }

}
