import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ItemParam'
})
export class ItemParamPipe implements PipeTransform {

  transform(value: any): any {
    if (value == 0) {
      return "";
    }
    return value;
  }

}
