import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { PromSimItem } from '../0_def/promSim';
import { PromLastDto, PromSimDto, ReqDeletePromSim, ReqSearchLastPromSim, RspPromSimLastSearch, RspPromSimSearch } from '../0_def/promSimDef';
import { PromSimEditComponent } from './../prom-sim-edit/prom-sim-edit.component';

@Component({
  selector: 'app-sale-simulation',
  templateUrl: './prom-sim.component.html',
  styleUrls: ['./prom-sim.component.css']
})
export class PromSimComponent implements OnInit {

  public isReadonly: boolean = false;
  public tabSelection: number = 0;
  public recordCount: number = 0;
  public dateBegin: Date;
  public dateEnd: Date;
  public newPromEditing: string;
  public promSimList: PromSimDto[];
  public promLastList: PromLastDto[];
  public promLastShowList: PromLastDto[];
  public selectedPromSim: PromSimDto;
  public selectedPromLast: PromLastDto;
  public editingPromSim: PromSimDto;
  public editingPromLast: PromLastDto;
  public promSimItems: PromSimItem[];
  public promSimItemsOrigin: PromSimItem[];
  public formQryPromName: FormControl = new FormControl("");
  public columnIds: string[] = ["simPromName", "dateBegin", "dateEnd", "forecastSalesFn", "forecastProfitFn", "forecastRateFn", "operationBtn"];
  public columnIdsLastProm: string[] = ["promName", "dateBegin", "dateEnd"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "simPromName", header: "企画名称", width: 300, align: "left" },
    { columnId: "dateBegin", header: "開始日付", width: 100, align: "center" },
    { columnId: "dateEnd", header: "終了日付", width: 100, align: "center" },
    { columnId: "forecastSalesFn", header: "予測売上金額", width: 100, align: "center", numberPipe: "" },
    { columnId: "forecastProfitFn", header: "予測粗利金額", width: 100, align: "center", numberPipe: "" },
    { columnId: "forecastRateFn", header: "予測粗利率(%)", width: 100, align: "center", numberPipe: "1.1-2" },
  ];
  public columnDefsLastProm: TableColumnDef[] = [
    { columnId: "promName", header: "企画名称", width: 300, align: "left" },
    { columnId: "dateBegin", header: "開始日付", width: 100, align: "center" },
    { columnId: "dateEnd", header: "終了日付", width: 100, align: "center" },
  ];
  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private fbOrigin: FormBuilder,) {
  }

  @ViewChild(PromSimEditComponent, { static: false }) promSimEditComponent: PromSimEditComponent;
  @ViewChild(DateRangeComponent, { static: false }) dateRangeComponent: DateRangeComponent;
  @ViewChild(NumberKeypadComponent, { static: false }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.dateBegin = new Date();
    this.dateBegin.setMonth(this.dateBegin.getMonth() - 1);
    this.dateEnd = new Date();
    this.dateEnd.setDate(this.dateEnd.getDate() - 1);
    this.getPromSim();
  }

  dateChanged(event) {
  }

  operationBtnType(promSim: PromSimDto) {
    /*
      1: No button
      2: 参照・編集 button
      3: 参照・編集 button disabled
      4: 編集中 label
      5: 編集中 label dirty
      6: 参照 button confirmed
    */
    let orderDateEndAfterMargin = this.isDateValid(promSim.dateEnd) ?
      this.commonService.copyDate(new Date(promSim.dateEnd)) :
      null;
    if (orderDateEndAfterMargin) orderDateEndAfterMargin.setDate(orderDateEndAfterMargin.getDate() - (this.commonService?.config?.promOrderDateMargin ?? 1))

    if (!this.isDateValid(promSim.dateBegin)) return 1;
    if (!this.isDateValid(promSim.dateEnd)) return 1;
    if (this.editingPromSim == undefined) {
      if (promSim.confirmStatusFn == 1) return 6;
      return 2;
    }
    if (this.editingPromSim == promSim) {
      if (promSim.confirmStatusFn == 1) return 5;
      return 4;
    }
    if (promSim.confirmStatusFn == 0 && !this.promSimEditComponent.isDisableBtn()) return 3;
    if (promSim.confirmStatusFn == 1) return 6;
    return 2;
  }

  getPromSim() {
    if (!this.isNewPromEditing()) {
      this.clearProgressEditTemp();
    }

    let request = {
      access: this.commonService.loginUser,
      promOrderDateOffset: this.commonService.config?.promOrderDateOffset
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetPromSimList", request).subscribe(
      (response: RspPromSimSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveGetPromSim(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );

  }

  receiveGetPromSim(response: RspPromSimSearch) {
    if (this.httpBasic.handleAppError(response)) return;

    this.selectedPromSim = undefined;
    this.editingPromSim = undefined;
    this.promSimList = [];
    response.rows.forEach((row) => {
      let prom: PromSimDto;
      prom = {
        confirmStatusFn: row.confirmStatusFn,
        dateBegin: row.dateBegin,
        dateEnd: row.dateEnd,
        forecastProfitFn: row.forecastProfitFn,
        forecastRateFn: row.forecastRateFn,
        forecastSalesFn: row.forecastSalesFn,
        simPromCd: row.simPromCd,
        simPromName: row.simPromName,
        storeGroupTypeCdFv: row.storeGroupTypeCdFv,
        storeGroupCdFv: row.storeGroupCdFv,
        saveMode: 0,
        selectMode: 0
      };
      this.promSimList.push(prom);
    });
  }

  isNewPromEditing() {
    if (this.editingPromSim && this.editingPromSim.simPromCd != undefined && this.editingPromSim.saveMode != 0) {
      this.newPromEditing = "新規作成の企画を編集中です。";
      return true;
    }
  }

  clearProgressEditTemp() {
    this.selectedPromSim = undefined;
    this.editingPromSim = undefined;
  }

  doQuery() {
    if (this.matPaginator) this.matPaginator.pageIndex = 0;
    let pageNum = 0;
    let dispNum = this.matPaginator ? this.matPaginator.pageSize : 15;
    this.doQuerBody(pageNum, dispNum);
  } 

  doQuerBody(pageNum, dispNum) {
    let dateRage = this.dateRangeComponent.getDateRange();
    let request: ReqSearchLastPromSim = {
      access: this.commonService.loginUser,
      promNameFv: this.formQryPromName.value.trim(),
      startDateFd: this.commonService.formatDate(dateRage.dateBegin),
      finishDateFd: this.commonService.formatDate(dateRage.dateEnd),
      page: {
        pageNum: pageNum,
        dispNum: dispNum
      }
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("SearchLastPromOrderList", request).subscribe(
      (response: RspPromSimLastSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePromSimPast(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receivePromSimPast(response: RspPromSimLastSearch) {
    if (this.httpBasic.handleAppError(response)) return;

    this.selectedPromLast = undefined;
    this.promLastList = [];
    this.recordCount = response.recordCount;
    if (this.recordCount > 0) {
      response.rows?.forEach((row) => {

        let promSimLast: PromLastDto = {
          promCd: row.promCd,
          promName: row.promName,
          dateBegin: row.dateBegin,
          dateEnd: row.dateEnd,
        };
        this.promLastList.push(promSimLast);
      });
    }
  }

  pageChanged() {
    this.promLastShowList = [];
    if (this.promLastList == undefined || this.promLastList.length == 0) return;
    let pageNum =  this.matPaginator.pageIndex;
    let dispNum = this.matPaginator.pageSize;
    this.doQuerBody(pageNum, dispNum);
  }

  editPromSim(promSim: PromSimDto) {
    this.selectPromSim(promSim);
    promSim.selectMode = 0;
    promSim.saveMode = 0;
    this.editingPromSim = promSim;
    this.tabSelection = 1;
    this.disableReCalSave();
  }

  selectPromSim(promSim: PromSimDto) {
    this.selectedPromSim = promSim;
  }

  selectPromLast(promSim: PromLastDto) {
    this.selectedPromLast = promSim;
  }

  newProm() {
    let newProm: PromSimDto = {
      confirmStatusFn: null,
      dateBegin: this.commonService.formatDate(new Date()),
      dateEnd: this.commonService.formatDate(new Date()),
      forecastProfitFn: 0,
      forecastRateFn: 0,
      forecastSalesFn: 0,
      simPromCd: '',
      simPromName: '',
      storeGroupTypeCdFv: -1,
      storeGroupCdFv: -1,
      selectMode: 1,
      saveMode: 1
    };
    this.editingPromSim = newProm;
    this.disableReCalSave();
    this.tabSelection = 1
  }

  referenceEdit(promSim: PromSimDto) {
    this.selectPromSim(promSim);
    if (!this.promSimEditComponent.isDisableBtn()) {
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
        "新規作成の企画です。企画作成を取消しますか？").subscribe(
          (response: boolean) => {
            subsc.unsubscribe();
            if (response) {
              this.editingPromSim = promSim;
              this.tabSelection = 1;
              this.selectPromSim(promSim);
              this.disableReCalSave();
            }
          });
      return;
    }
    this.editingPromSim = promSim;
    this.tabSelection = 1;
  }

  copyEdit() {
    if (!this.selectedPromSim) return;
    this.selectPromSim(this.selectedPromSim);
    this.editingPromSim = Object.assign(this.selectedPromSim);
    let newProm: PromSimDto = {
      simPromCd: this.editingPromSim.simPromCd,
      simPromName: '',
      dateBegin: this.editingPromSim.dateBegin,
      dateEnd: this.editingPromSim.dateEnd,
      confirmStatusFn: 0,
      storeGroupTypeCdFv: -1,
      storeGroupCdFv: -1,
      selectMode: 3,
      saveMode: 3
    };
    this.editingPromSim = newProm;
    this.tabSelection = 1;
    this.disableReCalSave();
  }

  copyEditPromLast() {
    if (!this.selectedPromLast) return;
    this.selectPromLast(this.selectedPromLast);
    let newProm: PromSimDto = {
      simPromCd: this.selectedPromLast.promCd,
      simPromName: '',
      dateBegin: this.selectedPromLast.dateBegin,
      dateEnd: this.selectedPromLast.dateEnd,
      confirmStatusFn: 0,
      storeGroupTypeCdFv: -1,
      storeGroupCdFv: -1,
      selectMode: 2,
      saveMode: 2
    };
    this.editingPromLast = Object.assign(this.selectedPromLast);
    this.editingPromSim = newProm;
    this.tabSelection = 1;
    this.disableReCalSave();
  }

  deletePromSimConfirm() {
    let msg = "特売シミュレーション「 " + this.selectedPromSim.simPromName + " 」を削除しますか？"
    let sub = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      (response) => {
        sub.unsubscribe();
        if (response) this.deletePromSim();
      }
    );
  }

  deletePromSim() {
    let request: ReqDeletePromSim = {
      access: this.commonService.loginUser,
      simPromCd: this.selectedPromSim.simPromCd,
      startDateFd: this.selectedPromSim.dateBegin,
      finishDateFd: this.selectedPromSim.dateEnd,
      promOrderDateOffset: this.commonService.config?.promOrderDateOffset
    }
    let subsc = this.httpBasic.generalRequest("DeletePromSim", request).subscribe(
      (response: RspPromSimSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveGetPromSim(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  clearPromSim(event: string) {
    if(event == "endEdit") {
      this.selectedPromSim = undefined;
      this.selectedPromLast = undefined;
    }
    if(event == "clearPromSim") {
      this.clearProgressEditTemp();
      this.selectedPromSim = undefined;
      this.selectedPromLast = undefined;
      this.tabSelection = 0;
    }
  }

  refreshPromSimList(promSimList: PromSimDto[]) {
    this.promSimList = [];
    this.promSimList = promSimList;
  }

  isGreyColor(item: PromSimDto) {
    if (item.confirmStatusFn == 0) return false;
    if (item == this.selectedPromSim) return false;
    return true;
  }

  canDeactivate() {

    if (this.editingPromSim == undefined) return true;
    if (this.promSimEditComponent.isDisableBtn()) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  isWarnColor(item: PromSimDto) {
    if (this.editingPromSim == item && !this.promSimEditComponent.isDisableBtn()) return true;
    return false;
  }

  isDateValid(input: string | Date): boolean {
    if (!input) return false;
    if (typeof input == 'string') {
      if (input.trim() == '') return false;
      const date = new Date(input);
      return (date instanceof Date && !isNaN(date.valueOf()));
    }

    return (input instanceof Date && !isNaN(input.valueOf()));
  }

  disableReCalSave() {
    this.promSimEditComponent.disableReCalc = true;
    this.promSimEditComponent.disableSave = true;
    this.promSimEditComponent.disableCancel = true;
  }
}
