import { RspItemDto } from '../response/rsp-item-search';
import { CommonService } from '../service/common.service';
import { CaseItemDto } from '../webservice/caseitem';
import { ItemRenew } from './item-renew';

export class ItemRec {
  NO: number;
  storeCdFv: string;
  storeNameFv?: string;
  itemCdFv: string;
  itemNameFv: string;
  standardFv: string;
  ctgCd0Fv: string;
  ctgName0Fv: string;
  ctgCd1Fv: string;
  ctgName1Fv: string;
  ctgCd2Fv: string;
  ctgName2Fv: string;
  ctgCd3Fv: string;
  ctgName3Fv: string;
  suppNameFv: string;
  orderableDateFv: string;
  orderLotFn: number;
  itemCostPriceFn: number;
  itemSalesPriceFn: number;
  orderStopFlagFn: number;
  orderStopFlagModFn: number;
  orderStopFlagVersionFn: number;
  orderDate: string;
  deliveryDate: string;
  orderNumFn: number;
  orderNumModFn: number;
  orderNumVersionFn: number;
  orderBaraNumFn: number         = 0;
  orderBacklog: number           = 0;
  stockNumFn: number;
  stockNumModFn: number;
  stockNumVersionFn: number;
  rankFv: string;
  itemClass: string              = "";
  itemLocation: string           = "";
  orderPointFn: number = 0;
  orderPointSubFn: number = 0;
  adqNumFn: number = 0;
  salesResult1: number;
  salesResult2: number;
  salesResult3: number;
  salesResult4: number;
  orderResult1: number    = 0;
  orderResult2: number    = 0;
  orderResult3: number    = 0;
  orderResult4: number    = 0;
  minZaiIsNull: boolean;
  minZaiFn: number;
  minZaiModIsNull: boolean;
  minZaiModFn: number;
  minZaiVersionFn: number;
  maxZaiIsNull: boolean;
  maxZaiFn: number;
  maxZaiModIsNull: boolean;
  maxZaiModFn: number;
  maxZaiVersionFn: number;
  minStockDaysIsNull: boolean;
  minStockDaysFn: number;
  minStockDaysModIsNull: boolean;
  minStockDaysModFn: number;
  minStockDaysVersionFn: number;
  maxStockDaysIsNull: boolean;
  maxStockDaysFn: number;
  maxStockDaysModIsNull: boolean;
  maxStockDaysModFn: number;
  maxStockDaysVersionFn: number;
  ctg0Fv: string;
  ctg1Fv: string;
  ctg2Fv: string;
  ctg3Fv: string;
  orderNumCurrentFn: number;
  orderNumCurrentFnUpdated: boolean;
  stockNumCurrentFn: number;
  stockNumCurrentFnUpdated: boolean;
  minZaiCurrentFv: string;
  minZaiCurrentFvUpdated: boolean;
  minZaiCurrentIsNull: boolean;
  maxZaiCurrentFv: string;
  maxZaiCurrentFvUpdated: boolean;
  maxZaiCurrentIsNull: boolean;
  minStockDaysCurrentFv: string;
  minStockDaysCurrentFvUpdated: boolean;
  minStockDaysCurrentIsNull: boolean;
  maxStockDaysCurrentFv: string;
  maxStockDaysCurrentFvUpdated: boolean;
  maxStockDaysCurrentIsNull: boolean;
  orderStopFlagCurrentFn: number;
  orderStopFlagCurrentFnUpdated: boolean;
  orderStopFlagCurrentFvUpdated: boolean;
  orderStopFlagCurrentFv: string;
  orderBaraNumCurrentFn: number = 0;
  autoOrderLotModFn?: number      = 0;
  autoOrderLotFn?: number         = 0;
  autoOrderLotModVersionFn?: number = 0;
  autoOrderLotModCurrentFv?: string;
  closingTime: number;
  
  public caseItems:  CaseItemDto[];
  public fromItems: ItemRenew[];
  public toItems: ItemRenew[];

  constructor(private itemDto: RspItemDto, private commonService:CommonService) {
    for (var key of Object.keys(itemDto)) {
      this[key] = itemDto[key];
    }
    this.ctg0Fv = this.ctgCd0Fv + ":" + this.ctgName0Fv;
    this.ctg1Fv = this.ctgCd1Fv + ":" + this.ctgName1Fv;
    this.ctg2Fv = this.ctgCd2Fv + ":" + this.ctgName2Fv;
    this.ctg3Fv = this.ctgCd3Fv + ":" + this.ctgName3Fv;
    if (this.orderNumVersionFn > 0) {
      this.orderNumCurrentFn = this.orderNumModFn;
      this.orderNumCurrentFnUpdated = true;
    } else {
      this.orderNumCurrentFn = this.orderNumFn;
      this.orderNumCurrentFnUpdated = false;
    }
    if (this.stockNumVersionFn > 0) {
      this.stockNumCurrentFn = this.stockNumModFn;
      this.stockNumCurrentFnUpdated = true;
    } else {
      this.stockNumCurrentFn = this.stockNumFn;
      this.stockNumCurrentFnUpdated = false;
    }

    if (this.minZaiFn == 0) {
      this.minZaiIsNull = true;
    }
    if (this.minZaiModFn == 0) {
      this.minZaiModIsNull = true;
    }
    if (this.minZaiVersionFn > 0) {
      this.minZaiCurrentFv = this.paramCurrent(this.minZaiModFn);
      this.minZaiCurrentFvUpdated = true;
      this.minZaiCurrentIsNull = this.minZaiModIsNull;
    } else {
      this.minZaiCurrentFv = this.paramCurrent(this.minZaiFn);
      this.minZaiCurrentFvUpdated = false;
      this.minZaiCurrentIsNull = this.minZaiIsNull;
    }

    if (this.maxZaiFn == 0) {
      this.maxZaiIsNull = true;
    }
    if (this.maxZaiModFn == 0) {
      this.maxZaiModIsNull = true;
    }
    if (this.maxZaiVersionFn > 0) {
      this.maxZaiCurrentFv = this.paramCurrent(this.maxZaiModFn);
      this.maxZaiCurrentFvUpdated = true;
      this.maxZaiCurrentIsNull = this.maxZaiModIsNull;
    } else {
      this.maxZaiCurrentFv = this.paramCurrent(this.maxZaiFn);
      this.maxZaiCurrentFvUpdated = false;
      this.maxZaiCurrentIsNull = this.maxZaiIsNull;
    }

    if (this.minStockDaysFn == 0) {
      this.minStockDaysIsNull = true;
    }
    if (this.minStockDaysModFn == 0) {
      this.minStockDaysModIsNull = true;
    }
    if (this.minStockDaysVersionFn > 0) {
      this.minStockDaysCurrentFv = this.paramCurrent(this.minStockDaysModFn);
      this.minStockDaysCurrentFvUpdated = true;
      this.minStockDaysCurrentIsNull = this.minStockDaysModIsNull;
    } else {
      this.minStockDaysCurrentFv = this.paramCurrent(this.minStockDaysFn);
      this.minStockDaysCurrentFvUpdated = false;
      this.minStockDaysCurrentIsNull = this.minStockDaysIsNull;
    }

    if (this.maxStockDaysFn == 0) {
      this.maxStockDaysIsNull = true;
    }
    if (this.maxStockDaysModFn == 0) {
      this.maxStockDaysModIsNull = true;
    }
    if (this.maxStockDaysVersionFn > 0) {
      this.maxStockDaysCurrentFv = this.paramCurrent(this.maxStockDaysModFn);
      this.maxStockDaysCurrentFvUpdated = true;
      this.maxStockDaysCurrentIsNull = this.maxStockDaysModIsNull;
    } else {
      this.maxStockDaysCurrentFv = this.paramCurrent(this.maxStockDaysFn);
      this.maxStockDaysCurrentFvUpdated = false;
      this.maxStockDaysCurrentIsNull = this.maxStockDaysIsNull;
    }

    if (this.orderStopFlagVersionFn > 0) {
      this.orderStopFlagCurrentFn = this.orderStopFlagModFn;
      this.orderStopFlagCurrentFnUpdated = true;
    } else {
      this.orderStopFlagCurrentFn = this.orderStopFlagFn;
      this.orderStopFlagCurrentFnUpdated = false;
    }
    this.orderStopFlagCurrentFv = this.commonService.getOrderStopFlagName(this.orderStopFlagCurrentFn);
    this.orderStopFlagCurrentFvUpdated = this.orderStopFlagCurrentFnUpdated;
    this.orderBaraNumCurrentFn = this.orderNumCurrentFn * this.orderLotFn || 0;
  }

  setOrderNum(value: number, version: number) : void {
    this.orderNumModFn = value;
    this.orderNumVersionFn = version;
    this.orderNumCurrentFn = value;
    this.orderNumCurrentFnUpdated = true;
    this.orderBaraNumCurrentFn = this.orderNumCurrentFn * this.orderLotFn || 0;
  }

  setStockNum(value: number, version: number) : void {
    this.stockNumModFn = value;
    this.stockNumVersionFn = version;
    this.stockNumCurrentFn = value;
    this.stockNumCurrentFnUpdated = true;
  }

  setMinZai(val: number, version: number) {
    if (version > 0) {
      this.minZaiModFn = val;
      this.minZaiVersionFn = version;
      this.minZaiCurrentFv = this.paramCurrent(this.minZaiModFn);
      this.minZaiCurrentFvUpdated = true;
      if (this.minZaiModFn == 0) {
        this.minZaiModIsNull = true;
        this.minZaiCurrentIsNull = true;
      } else {
        this.minZaiModIsNull = false;
        this.minZaiCurrentIsNull = false;
      }
    }
  }

  setMaxZai(val: number, version: number) {
    if (version > 0) {
      this.maxZaiModFn = val;
      this.maxZaiVersionFn = version;
      this.maxZaiCurrentFv = this.paramCurrent(this.maxZaiModFn);
      this.maxZaiCurrentFvUpdated = true;
      if (this.maxZaiModFn == 0) {
        this.maxZaiModIsNull = true;
        this.maxZaiCurrentIsNull = true;
      } else {
        this.maxZaiModIsNull = false;
        this.maxZaiCurrentIsNull = false;
      }
    }
  }

  setMinStockDays(val: number, version: number) {
    if (version > 0) {
      this.minStockDaysModFn = val;
      this.minStockDaysVersionFn = version;
      this.minStockDaysCurrentFv = this.paramCurrent(this.minStockDaysModFn);
      this.minStockDaysCurrentFvUpdated = true;
      if (this.minStockDaysModFn == 0) {
        this.minStockDaysModIsNull = true;
        this.minStockDaysCurrentIsNull = true;
      } else {
        this.minStockDaysModIsNull = false;
        this.minStockDaysCurrentIsNull = false;
      }
    }
  }

  setMaxStockDays(val: number, version: number) {
    if (version > 0) {
      this.maxStockDaysModFn = val;
      this.maxStockDaysVersionFn = version;
      this.maxStockDaysCurrentFv = this.paramCurrent(this.maxStockDaysModFn);
      this.maxStockDaysCurrentFvUpdated = true;
      if (this.maxStockDaysModFn == 0) {
        this.maxStockDaysModIsNull = true;
        this.maxStockDaysCurrentIsNull = true;
      } else {
        this.maxStockDaysModIsNull = false;
        this.maxStockDaysCurrentIsNull = false;
      }
    }
  }

  paramCurrent(val: number): string {
    return val == 0 ? "" : "" + val;
  }

  setOrderStopFlag(val: number, version: number) {
    if (version > 0) {
      this.orderStopFlagModFn = val;
      this.orderStopFlagCurrentFn = val;
      this.orderStopFlagCurrentFv = this.commonService.getOrderStopFlagName(this.orderStopFlagCurrentFn);
      this.orderStopFlagVersionFn = version;
      this.orderStopFlagCurrentFnUpdated = true;
      this.orderStopFlagCurrentFvUpdated = true;
    }
  }

}