import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ItemSimpleRec } from 'src/app/common/item-simple-rec';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ItemListSimpleComponent } from 'src/app/partsCommon/item-list-simple/item-list-simple.component';
import { ItemSelectSimpleCondition } from 'src/app/partsCommon/item-select-simple/item-select-simple.component';
import { ReqItemReplaceSearch } from 'src/app/request/req-item-replace-search';
import { ReqItemSimpleSearch } from 'src/app/request/req-item-simple-seach';
import { RspItemReplaceSearch } from 'src/app/response/rsp-item-replace-search';
import { RspItemSimpleSearch } from 'src/app/response/rsp-item-simple-seach';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ItemRenewDto, ReqAddItemRenew, ReqDeleteItemRenew, ReqItemRenewInit, RspItemRenewInit } from 'src/app/webservice/item-replace-new';

interface StoreListItem {
  storeCd: string;
  storeName: string;
  storeNameWithCode: string;
  form: FormControl;
}

@Component({
  selector: 'app-item-replace-new',
  templateUrl: './item-replace-new.component.html',
  styleUrls: ['./item-replace-new.component.css']
})
export class ItemReplaceNewComponent implements OnInit, OnDestroy {
  // memory leak
  private subscription$ = new Subject();

  public formGroup: FormGroup;
  public storeModeForm: FormControl = new FormControl('1');
  public storeList: StoreListItem[];
  public checkedStoreCd = new Set<string>();
  // public tableWidth: any;

  // 商品リスト
  public itemSelectSimpleCondition: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fb);
  public itemSelectSimpleConditionOrigin: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fbOrigin);
  public itemSimpeList: ItemSimpleRec[] = [];
  public recordCount: number = 0;
  public columnIdsItemList: string[] = ["putSource", "putDestination", "itemCdFv", "itemNameFv", "standardFv"];
  public columnDefsItemList: TableColumnDef[] = [
    { columnId: 'itemCdFv', header: '商品コード', width: 90, align: 'center' },
    { columnId: 'itemNameFv', header: '商品名', width: 300 },
    { columnId: 'standardFv', header: '規格', width: 150 },
    { columnId: 'ctg0Fv', header: this.commonService.literal.ctg0Name, width: 200 },
    { columnId: 'ctg1Fv', header: this.commonService.literal.ctg1Name, width: 200 },
    { columnId: 'ctg2Fv', header: this.commonService.literal.ctg2Name, width: 200 }
  ];

  // 登録済み一覧
  public columnIds: string[] = ["removeItem", "storeFv", "itemCdSFv", "itemNameSFv", "itemCdDFv", "itemNameDFv", "dateBeginFv"];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'storeFv', header: "店舗", width: 100, align: "left" },
    { columnId: 'itemCdSFv', header: "商品コード(元)", width: 100, align: "center" },
    { columnId: 'itemNameSFv', header: "商品名(元)", width: 300 },
    { columnId: 'itemCdDFv', header: "商品コード(先)", width: 100, align: "center" },
    { columnId: 'itemNameDFv', header: "商品名(先)", width: 300 },
    { columnId: 'dateBeginFv', header: "発効日", width: 100, align: "center" }
  ];
  public replaceList: ItemRenewDto[] = [];

  isDisableItemCdDDirect = false;
  isDisableItemCdSDirect = false;

  get f() {
    return this.formGroup.controls;
  }

  @ViewChild(ItemListSimpleComponent, { static: true }) itemListSimpleComponent: ItemListSimpleComponent;
  @ViewChild(MatPaginator, { static: false }) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private fbOrigin: FormBuilder,
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.storeList = [];
    let defaultVal = false;
    if (this.commonService.stores.length == 1) defaultVal = true;
    for (let store of this.commonService.stores) {
      let item: StoreListItem = {
        storeCd: store.storeCd,
        storeName: store.storeName,
        storeNameWithCode: store.storeCd + "：" + store.storeName,
        form: new FormControl(defaultVal)
      };
      this.storeList.push(item);
    }

    this.initFormGroup();
    this.initItemReplace();

    this.formGroup.controls.itemCdDDirect.valueChanges
      .pipe(takeUntil(this.subscription$))
      .subscribe(value => {
        if (value === this.f.itemCdS.value) {
          // disable InplutD
          this.isDisableItemCdDDirect = true;
        } else {
          this.isDisableItemCdDDirect = false;
        }
      });

    this.formGroup.controls.itemCdSDirect.valueChanges
      .pipe(takeUntil(this.subscription$))
      .subscribe(value => {
        if (value === this.f.itemCdD.value) {
          // disable InplutS
          this.isDisableItemCdSDirect = true;
        } else {
          this.isDisableItemCdSDirect = false;
        }
      });
    this.formGroup.controls.itemCdS.valueChanges
      .pipe(takeUntil(this.subscription$))
      .subscribe(value => {
        if (value === this.f.itemCdDDirect.value) {
          this.isDisableItemCdDDirect = true;
        } else {
          this.isDisableItemCdSDirect = false;
        }
      });

    this.formGroup.controls.itemCdD.valueChanges
      .pipe(takeUntil(this.subscription$))
      .subscribe(value => {
        if (value === this.f.itemCdSDirect.value) {
          this.isDisableItemCdSDirect = true;
        } else {
          this.isDisableItemCdDDirect = false;
        }
      });
  }

  ngOnDestroy() {
    this.subscription$.next();
    this.subscription$.complete();
  }

  initItemReplace() {
    let request: ReqItemRenewInit = {
      access: this.commonService.loginUser
    }
    let subsc = this.httpBasic.generalRequest("ItemRenewInit", request).subscribe(
      result => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.checkResult(result);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  checkResult(response: RspItemRenewInit) {
    if (this.httpBasic.handleAppError(response)) return;

    this.replaceList = [];
    const data = response.result;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      this.replaceList.push({
        storeCdFv: element.storeCdFv,
        storeNameFv: element.storeNameFv,
        storeFv: element.storeCdFv + "：" + (element.storeCdFv === "*" ? "全店" : element.storeNameFv),
        itemCdSFv: element.itemCdSFv,
        itemNameSFv: element.itemNameSFv,
        itemCdDFv: element.itemCdDFv,
        itemNameDFv: element.itemNameDFv,
        dateBeginFv: element.dateBeginFv,
        entDateFv: element.entDateFv,
        updDateFv: element?.updDateFv,
      });
    }
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      itemCdS: [],
      itemNameS: [],
      itemCdSDirect: [""],
      itemCdD: [],
      itemNameD: [],
      itemCdDDirect: [""],
      dateBegin: []
    }
    );
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    }
  }

  doInsert() {
    const request: ReqAddItemRenew = {
      access: this.commonService.loginUser,
      itemRenewAdd: {
        itemCdSFv: this.formGroup.get("itemCdS").value,
        itemNameSFv: this.formGroup.get("itemNameS").value,
        itemCdDFv: this.formGroup.get("itemCdD").value,
        itemNameDFv: this.formGroup.get("itemNameD").value,
        dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US')
      },
      isAllStoresChecked: this.storeModeForm?.value === "1" ? true : false,
      storeCheckedList: this.storeModeForm?.value === "1" ?
        [] :
        [...this.storeList.filter(store => store?.form.value === true)
          .map(store => { return { storeCd: store?.storeCd, storeName: store.storeName } })]
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("ItemRenewAdd", request).subscribe(
      result => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.checkResult(result);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  doCancel() {
    this.formGroup.reset();
    this.storeUnselectAll();
  }

  doQueryItem() {
    if (this.matPagenator) this.matPagenator.pageIndex = 0;
    this.copyRequest();
    this.doQueryItemBody();
  }

  copyRequest() {
    this.itemSelectSimpleConditionOrigin.formGroup.patchValue(this.itemSelectSimpleCondition.formGroup.value);
    this.itemSelectSimpleConditionOrigin.ctgSelectCondition.formGroup.patchValue(this.itemSelectSimpleCondition.ctgSelectCondition.formGroup.value);
  }

  doQueryItemBody() {
    let pageNum = this.matPagenator.pageIndex;
    let dispNum = this.matPagenator.pageSize;
    if (this.matPagenator) {
      pageNum = this.matPagenator.pageIndex;
      dispNum = this.matPagenator.pageSize;
    } else {
      let pageNum = 0;
      let dispNum = this.commonService.paginatorOption.pageSizeOptions[0];
    }

    let itemSelectSimpleCoditionForm: FormGroup = this.itemSelectSimpleConditionOrigin.formGroup;
    let categorySearchCondition: FormGroup = this.itemSelectSimpleConditionOrigin.ctgSelectCondition.formGroup;
    let request: ReqItemSimpleSearch = {
      access: { ...this.commonService.loginUser },
      itemSimpleSeachCondition: {
        storeCdFv: itemSelectSimpleCoditionForm.get('storeCd').value,
        ctgCd0Fv: categorySearchCondition.get('ctgLevel').value >= 0 ?
          categorySearchCondition.get('ctgCd0').value : '',
        ctgCd1Fv: categorySearchCondition.get('ctgLevel').value >= 1 ?
          categorySearchCondition.get('ctgCd1').value : '',
        ctgCd2Fv: categorySearchCondition.get('ctgLevel').value >= 2 ?
          categorySearchCondition.get('ctgCd2').value : '',
        ctgCd3Fv: categorySearchCondition.get('ctgLevel').value >= 3 ?
          categorySearchCondition.get('ctgCd3').value : '',
        itemCdFv: itemSelectSimpleCoditionForm.get('itemCode').value,
        itemNameFv: itemSelectSimpleCoditionForm.get('itemName').value,
      },
      page: {
        pageNum: pageNum,
        dispNum: dispNum
      }
    };

    let subsc = this.httpBasic.itemSimpleSearch(request).subscribe(
      data => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveItemList(data);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
  }

  receiveItemList(response: RspItemSimpleSearch) {
    if (this.httpBasic.handleAppError(response)) return;

    this.itemSimpeList = [];
    this.recordCount = response.recordCount;
    if (this.recordCount > 0) {
      response.rows.forEach((rspItemSimpleDto) => {
        this.itemSimpeList.push(new ItemSimpleRec(rspItemSimpleDto, this.commonService));
      });
    }
  }

  pageChanged(pageEvent: PageEvent) {
    if (this.itemSimpeList == undefined || this.itemSimpeList.length == 0) return;
    this.doQueryItemBody();
  }

  sourceItemCd() {
    return this.formGroup.get("itemCdS").value;
  }

  destinationItemCd() {
    return this.formGroup.get("itemCdD").value;
  }

  putSource(item: ItemSimpleRec) {
    this.formGroup.get("itemCdS").setValue(item.itemCdFv);
    this.formGroup.get("itemNameS").setValue(item.itemNameFv);
  }

  putDestination(item: ItemSimpleRec) {
    this.formGroup.get("itemCdD").setValue(item.itemCdFv);
    this.formGroup.get("itemNameD").setValue(item.itemNameFv);
  }

  doDelete(itemRenew: ItemRenewDto) {
    let msg = "<span>次の差替え情報を削除しますか？</span><br><br>";
    msg += "<table>"
    msg += "<tr><td>店舗：<td><td>" + itemRenew.storeFv + "</td></tr>";
    msg += "<tr><td>商品(元)：<td><td>" + itemRenew.itemCdSFv + ": " + itemRenew.itemNameSFv + "</td></tr>";
    msg += "<tr><td>商品(先)：<td><td>" + itemRenew.itemCdSFv + ": " + itemRenew.itemNameSFv + "</td></tr>";
    msg += "<tr><td>発効日：<td><td>" + itemRenew.dateBeginFv + "</td></tr>";
    msg += "</table>"
    this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).pipe(takeUntil(this.subscription$)).subscribe(
      data => {
        if (data === true) {
          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          const request: ReqDeleteItemRenew = {
            access: this.commonService.loginUser,
            storeCdFv: itemRenew.storeCdFv,
            itemCdSFv: itemRenew.itemCdSFv,
            itemCdDFv: itemRenew.itemCdDFv,
            dateBeginFv: itemRenew.dateBeginFv
          };
          let subsc = this.httpBasic.generalRequest("DeleteItemRenew", request).subscribe(
            result => {
              subsc.unsubscribe();
              this.commonService.closeSpinner();
              this.checkResult(result);
            },
            error => {
              subsc.unsubscribe();
              this.commonService.closeSpinner();
              this.httpBasic.handleError(error);
            }
          );
        }
      }
    );
    return;
  }

  directInputS() {
    if (this.formGroup.get("itemCdSDirect").value === "") {
      return;
    }
    this.itemReplaceSearch(this.formGroup.get('itemCdSDirect').value, 'itemCdS', 'itemNameS');
    if (this.f.itemCdD.value != this.f.itemCdS.value) {
      this.isDisableItemCdDDirect = false;
      this.isDisableItemCdSDirect = false;
    }
  }

  directInputD() {
    if (this.formGroup.get('itemCdDDirect').value === '') {
      return;
    }
    this.itemReplaceSearch(this.formGroup.get('itemCdDDirect').value, 'itemCdD', 'itemNameD');
    if (this.f.itemCdD.value != this.f.itemCdS.value) {
      this.isDisableItemCdDDirect = false;
      this.isDisableItemCdSDirect = false;
    }
  }

  isValidDirectInput(itemCdDirect: string) {
    if (this.formGroup.get(itemCdDirect).value === null || this.formGroup.get(itemCdDirect).value === "") return false;
    return true;
  }

  isValidSubmit() {
    if (this.formGroup.get("itemCdS").value === this.formGroup.get("itemCdD").value
      || (this.formGroup.get("itemCdS").value === null && this.formGroup.get("itemNameS").value === null)
      || (this.formGroup.get("itemCdD").value === null && this.formGroup.get("itemNameD").value === null)
      || this.formGroup.get("dateBegin").value === null) return false;

    if (this.storeModeForm.value === "1") return true;  // 全店
    if (this.checkedStoreCd.size == 0) return false;

    return true;
  }

  getDateEndMin() {
    let min = new Date();
    min.setDate(min.getDate() + 14);
    return min;
  }

  itemReplaceSearch(itemCdDirect: string, itemCd: string, itemName: string) {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    const request: ReqItemReplaceSearch = {
      access: { ...this.commonService.loginUser },
      itemCdFv: itemCdDirect
    };
    let subsc = this.httpBasic.itemReplaceSearch(request).subscribe(
      data => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.checkResultItem(data.result[0], itemCd, itemName, itemCdDirect);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  checkResultItem(response: RspItemReplaceSearch, itemCd: string, itemName: string, itemCdValue: string = "") {
    if (response !== undefined) {
      this.formGroup.get(itemCd).setValue(response.itemCdFv);
      this.formGroup.get(itemName).setValue(response.itemNameFv);
    } else {
      this.formGroup.get(itemCd).setValue(itemCdValue);
      this.formGroup.get(itemName).setValue("未登録商品");
    }
    this.commonService.closeSpinner();
  }

  storeSelectAll() {
    for (let store of this.storeList) {
      store.form.setValue(true);
      this.checkedStoreCd.add(store.storeCd);
    }
  }

  storeUnselectAll() {
    for (let store of this.storeList) {
      store.form.setValue(false);
    }
    this.checkedStoreCd.clear();
  }

  onItemChecked(storeCd: string, checked: boolean) {
    if (checked) {
      this.checkedStoreCd.add(storeCd);
    } else {
      this.checkedStoreCd.delete(storeCd);
    }
  }

  tabChanged(event) {

  }

  isDateValid(input: string | Date): boolean {
    if (!input) return false;
    if (typeof input == 'string') {
      if (input.trim() == '') return false;
      const date = new Date(input);
      return (date instanceof Date && !isNaN(date.valueOf()));
    }

    return (input instanceof Date && !isNaN(input.valueOf()));
  }

  isHiddenDeleteBtn(item: ItemRenewDto): boolean {
    if (!this.isDateValid(item?.dateBeginFv) || !this.isDateValid(item?.entDateFv)) return true;
    const toDay = this.commonService.copyDate(new Date());
    return this.commonService.copyDate(new Date(item.dateBeginFv)) < toDay &&
      this.commonService.copyDate(new Date(item.entDateFv)) < toDay &&
      (!this.isDateValid(item?.updDateFv) || this.commonService.copyDate(new Date(item.updDateFv)) < toDay)
  }
}
