import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { CommonService } from "src/app/service/common.service";
import { ExpiryMasterRec } from "./expiry-master-rec";

export interface ExpiryItemMasterDateDef {
  datesToExpiry:    number;
  action:           string;
  discount?:        number;
}
export interface ExpiryItemMasterDateEdit {
  datesToExpiryForm:    FormControl;
  actionForm:           FormControl;
  discountForm?:        FormControl;
}

export class ExpiryItemMasterRec {
  private maxDefCount: number = 5;

  public storeCd:       string = "";                    // ""=全店
  public itemCd:        string;
  public itemName:      string;
  public standard:      string;
  public salesPrice:    number = 0;
  public expiryType:    number = 1;                     // 1: 賞味期限  2: 製造年月日
  public discountType:  number = 1;                     // 1: 値引率  2: 値引額  3: 値引販売価格
  public dateDef:       ExpiryItemMasterDateDef[] = [];
  public ctg:           ExpiryMasterRec;
  public edit: {
    isDirty:      boolean;
    isNew:        boolean;
    delForm:      FormControl;
    expiryType:   FormControl;
    discountType: FormControl;
    dateDef:      ExpiryItemMasterDateEdit[];
  };

  private valueCheckNoticed: boolean = false;

  private subsc: Subscription[] = [];
  private changeListener: ()=>void;

  constructor(private commonService: CommonService) {
  }

  setListner(func:()=>void) {
    this.changeListener = func;
  }

  prepareEdit(defaultShowLevel?: number) {
    this.edit = {
      isDirty:      false,
      isNew:        false,
      delForm:      new FormControl(false),
      expiryType:   new FormControl("" + this.expiryType),
      discountType: new FormControl("" + this.discountType),
      dateDef:      []
    };

    for (let i = 0; i < this.maxDefCount; i++) {
      let defEdit: ExpiryItemMasterDateEdit = {
        datesToExpiryForm: new FormControl(""),
        actionForm: new FormControl(""),
        discountForm: new FormControl(0)
      };
      if (i < this.dateDef.length) {
        let dateDef: ExpiryItemMasterDateDef = this.dateDef[i];
        defEdit.datesToExpiryForm.setValue(dateDef.datesToExpiry == -1 ? "" : dateDef.datesToExpiry);
        defEdit.actionForm.setValue(dateDef.action);
        defEdit.discountForm.setValue(dateDef.discount);
      }
      this.edit.dateDef.push(defEdit);
      this.subsc.push(this.edit.delForm.valueChanges.subscribe((value) => this.delChanged(value)));
      this.subsc.push(defEdit.datesToExpiryForm.valueChanges.subscribe((value) => this.datesChanged(defEdit.datesToExpiryForm, value)));
      this.subsc.push(defEdit.actionForm.valueChanges.subscribe((value) => this.actionChanged(defEdit.actionForm, value)));
      this.subsc.push(defEdit.discountForm.valueChanges.subscribe((value) => this.discountChanged(defEdit.discountForm, value)));
      this.subsc.push(defEdit.discountForm.valueChanges.pipe(
        startWith(defEdit.discountForm.value),
        pairwise()
      ).subscribe(([prevVal, newVal]) => {
          this.valueCheckDiscount(defEdit.discountForm, prevVal, newVal);
      }));
      this.subsc.push(this.edit.expiryType.valueChanges.subscribe(() => {
        this.expiryTypeChanged();
      }));
      this.subsc.push(this.edit.discountType.valueChanges.subscribe(() => {
        this.valueCheckNoticed = false;
        this.resetInvalidValue();
        this.discountTypeChanged();
      }));
    }
  }

  hasDef() {
    if (!this.edit) return false;
    for (let i = 0; i < this.edit.dateDef.length; i++) {
      let dates = this.edit.dateDef[i].datesToExpiryForm.value;
      if (dates === "" || dates === 0) continue;
      let action = this.edit.dateDef[i].actionForm.value;
      if (action === "") continue;
      return true;
    }
    return false;
  }

  endEdit() {
    this.cleanupEdit();
  }

  clearEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  cleanupEdit() {
    this.subsc.forEach((subsc) => {subsc.unsubscribe();});
    this.subsc = [];
    delete this.edit;
  }

  isDirty() {
    if (!this.edit) return false;
    return this.edit.isDirty;
  }

  delChanged(value) {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  datesChanged(form: FormControl, value) {
    if (!this.edit) return;
    this.edit.isDirty = true;
    for (let i = 0; i < this.edit.dateDef.length; i++) {
      this.edit.dateDef[i].datesToExpiryForm.setErrors(null);
    }
    let err = {"invalidValue": "値が重複しています。"};
    for (let i = 0; i < this.edit.dateDef.length; i++) {
      if (this.edit.dateDef[i].datesToExpiryForm.value === "") continue;
      /*
      if (parseInt(this.edit.dateDef[i].datesToExpiryForm.value) == 0) {
        this.edit.dateDef[i].datesToExpiryForm.setErrors(err);
      }
      */
      for (let j = i + 1; j < this.edit.dateDef.length; j++) {
        if (this.edit.dateDef[j].datesToExpiryForm.value === "") continue;
        if (parseInt(this.edit.dateDef[i].datesToExpiryForm.value) !=
            parseInt(this.edit.dateDef[j].datesToExpiryForm.value)) continue;
        this.edit.dateDef[i].datesToExpiryForm.setErrors(err);
        this.edit.dateDef[j].datesToExpiryForm.setErrors(err);
      }
    }
    if (this.changeListener) this.changeListener();
  }

  actionChanged(form: FormControl, value) {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  discountChanged(form: FormControl, value) {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  expiryTypeChanged() {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  discountTypeChanged() {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  resetInvalidValue() {
    this.edit.dateDef.forEach((dateDef) => {
      switch(this.edit.discountType.value) {
        case "1": {
          if (dateDef.discountForm.value > 99) dateDef.discountForm.setValue(0);
          return;
        }
        case "2": {
          if (dateDef.discountForm.value > this.salesPrice) dateDef.discountForm.setValue(0);
          return;
        }
        case "3": {
          return;
        }
      }
    });
  }

  valueCheckDiscount(form: FormControl, prevVal: number, newVal: number) {
    switch(this.edit.discountType.value) {
      case "1": {
        if (newVal > 99) {
          if (this.valueCheckNoticed == false) {
            this.commonService.openErrorDialog(this.commonService.pageTitle, "値引率に99を超える値は入力できません。");
            form.setValue(prevVal);
            this.valueCheckNoticed = true;
          }
        } else {
          this.valueCheckNoticed = false;
        }
        return;
      }
      case "2": {
        if (newVal > this.salesPrice) {
          if (this.valueCheckNoticed == false) {
            this.commonService.openErrorDialog(this.commonService.pageTitle, `値引額に ${this.salesPrice} 円を超える値は入力できません。`);
            form.setValue(prevVal);
            this.valueCheckNoticed = true;
          }
        } else {
          this.valueCheckNoticed = false;
        }
        return;
      }
      case "3": {
        this.valueCheckNoticed = false;
        return;
      }
    }
    this.valueCheckNoticed = false;
  }

  getValue(key: string) {
    switch(key) {
      case "itemCdName":
        return this.itemCd + "：" + this.itemName;
      default:
        return this[key];
    }
  }

  getDiscountLabel() {
    switch(this.edit.discountType.value) {
      case "1": {
        return {pre: "値引率", post: "%"};
      }
      case "2": {
        return {pre: "値引額", post: "円"};
      }
      case "3": {
        return {pre: "販売額", post: "円"};
      }
    };
    return {pre: "値引率", post: "%"};
  }

}
