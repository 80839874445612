import { FileListComponent } from './page/task/file-list/file-list.component';
import { BrowserModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CdkTableModule} from '@angular/cdk/table';
import { HttpClientModule } from '@angular/common/http';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
// import {MatBadgeModule} from '@angular/material/badge';
// import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
// import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
// import {MatChipsModule} from '@angular/material/chips';
// import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
// import {MatDividerModule} from '@angular/material/divider';
// import {MatExpansionModule} from '@angular/material/expansion';
// import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
// import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule,MAT_DATE_LOCALE, DateAdapter} from '@angular/material/core';
import {MatPaginatorModule, MatPaginatorIntl} from '@angular/material/paginator';
// import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// import {MatRadioModule} from '@angular/material/radio';
// import {MatSelectModule} from '@angular/material/select';
// import {MatSidenavModule} from '@angular/material/sidenav';
// import {MatSliderModule} from '@angular/material/slider';
// import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
// import {MatTreeModule} from '@angular/material/tree';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './partsCommon/top-bar/top-bar.component';
import { TopPageComponent } from './page/top-page/top-page.component';
import { LoginComponent } from './page/login/login.component';
import { LoginBarComponent } from './partsCommon/login-bar/login-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JpDateAdapterModule } from './module/jp-date-adapter.module';
import { ToolBarComponent } from './partsCommon/tool-bar/tool-bar.component';
import { MyMatPaginatorIntlModule } from './module/my-mat-paginator-intl.module';
import { SectionHeaderComponent } from './partsCommon/section-header/section-header.component';
import { ErrorNotificationDialogComponent } from './dialog/error-notification-dialog/error-notification-dialog.component';
import { CtgSelectComponent } from './partsCommon/ctg-select/ctg-select.component';
import { RackSelectComponent } from './partsCommon/rack-select/rack-select.component';
import { ItemSelectConditionComponent } from './partsCommon/item-select-condition/item-select-condition.component';
import { ItemSummaryComponent } from './partsCommon/item-summary/item-summary.component';
import { OrderEditDialogComponent } from './dialog/order-edit-dialog/order-edit-dialog.component';
import { CtgParamComponent } from './partsCommon/ctg-param/ctg-param.component';
import { StockEditDialogComponent } from './dialog/stock-edit-dialog/stock-edit-dialog.component';
import { SpinnerDialogComponent } from './dialog/spinner-dialog/spinner-dialog.component';
import { OrderComponent } from './page/order/order.component';
import { StockComponent } from './page/stock/stock.component';
import { NumberOnlyDirective } from './directive/number-only.directive';
import { ItemListComponent } from './partsCommon/item-list/item-list.component';
import { ItemParamPipe } from './pipe/item-param.pipe';
import { CtgParamEditComponent } from './page/ctg-param-edit/ctg-param-edit.component';
import { ParamCtgEditDialogComponent } from './dialog/param-ctg-edit-dialog/param-ctg-edit-dialog.component';
import { ItemParamEditComponent } from './page/item-param-edit/item-param-edit.component';
import { ParamItemEditDialogComponent } from './dialog/param-item-edit-dialog/param-item-edit-dialog.component';
import { ItemOrderStopEditComponent } from './page/item-order-stop-edit/item-order-stop-edit.component';
import { ItemOrderStopEditDialogComponent } from './dialog/item-order-stop-edit-dialog/item-order-stop-edit-dialog.component';
import { TableItemDialogComponent } from './dialog/table-item-dialog/table-item-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OpestatPageComponent } from './page/opestat/opestat-page/opestat-page.component';
import { OpestatViewComponent } from './page/opestat/opestat-view/opestat-view.component';
import { OpestatViewMainComponent } from './page/opestat/opestat-view/opestat-view-main.component';
import { OpestatViewDistrComponent } from './page/opestat/opestat-view/opestat-view-distr.component';
import { ItemHistComponent } from './page/item-hist/item-hist.component';
import { ItemSelectSimpleComponent } from './partsCommon/item-select-simple/item-select-simple.component';
import { ItemListSimpleComponent } from './partsCommon/item-list-simple/item-list-simple.component';
import { ItemHistListComponent } from './partsCommon/item-hist-list/item-hist-list.component';
import { ReportComponent } from './page/report/report.component';
import { RoleComponent } from './page/mng/role/role.component';
import { Role2Component } from './page/mng/role2/role2.component';
import { YesNoDialogComponent } from './dialog/yes-no-dialog/yes-no-dialog.component';
import { NoisecutComponent } from './page/noisecut/noisecut.component';
import { PasswdComponent } from './page/mng/passwd/passwd.component';
import { UserComponent } from './page/mng/user/user.component';
import { DownldComponent } from './page/downld/downld.component';
import { ItemNoisecutDialogComponent } from './dialog/item-noisecut-dialog/item-noisecut-dialog.component';
import { LogoutComponent } from './page/logout/logout.component';
import { ItemComponent } from './page/item/item.component';
import { NoisecutHistoryComponent } from './partsCommon/noisecut-history/noisecut-history.component';
import { NumberKeypadComponent } from './partsCommon/number-keypad/number-keypad.component';
import { RackComponent } from './page/rack/rack.component';
import { ScanItemEditComponent } from './page/scan-item-edit/scan-item-edit.component';
import { CtgHistComponent } from './page/ctg-hist/ctg-hist.component';
import { OrderStopTermCtgComponent } from './page/order-stop-term-ctg/order-stop-term-ctg.component';
import { OrderStopTermSuppComponent } from './page/order-stop-term-supp/order-stop-term-supp.component';
import { OrderStopTermStoreComponent } from './page/order-stop-term-store/order-stop-term-store.component';
import { OrderStopTermItemComponent } from './page/order-stop-term-item/order-stop-term-item.component';
import { AllStoreParamItemComponent } from './page/all-store-param-item/all-store-param-item.component';
import { AllStoreParamCtgComponent } from './page/all-store-param-ctg/all-store-param-ctg.component';
import { ExternalLinkComponent } from './page/external-link/external-link.component';
import { ItemReplaceComponent } from './page/item-replace/item-replace.component';
import { ForecastUpDownComponent } from './page/forecast-up-down/forecast-up-down.component';
import { DecimalPipe } from '@angular/common';
import { StoreGroupMngComponent } from './page/store-group-mng/store-group-mng.component';
import { NotificationDialogComponent } from './dialog/notification-dialog/notification-dialog.component';
import { CameraDialogComponent } from './dialog/camera-dialog/camera-dialog.component';
import { CsvReaderComponent } from './partsCommon/csv-reader/csv-reader.component';
import { CsvParamItemComponent } from './page/csv-param-item/csv-param-item.component';
import { CsvTableComponent } from './partsCommon/csv-table/csv-table.component';
import { CsvSeasonalCategoryTargetComponent } from './page/csv-seasonal-category-target/csv-seasonal-category-target.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AnalysisMainComponent } from './page/analysis/main/analysis-main.component';
import { AnalysisViewStandardComponent } from './page/analysis/view/analysis-view-standard/analysis-view-standard.component';
import { GraphOptionDialogComponent } from './dialog/graph-option-dialog/graph-option-dialog.component';
import { VendorShortageComponent } from './page/vendor-shortage/vendor-shortage.component';
import { OperationLogComponent } from './page/operation-log/operation-log.component';
import { CameraComponent } from './page/camera/camera/camera.component';
import { PbiAnalysisComponent } from './page/power-bi/pbi-analysis/pbi-analysis.component';
import { PbiReportListComponent } from './page/power-bi/pbi-report-list/pbi-report-list.component';
import { PbiMenuComponent } from './page/power-bi/pbi-menu/pbi-menu.component';
import { CameraLayoutComponent } from './page/camera/camera-layout/camera-layout.component';
import { CameraLayoutConfigComponent } from './page/camera/camera-layout-config/camera-layout-config.component';
import { CsvNoisecutPromComponent } from './page/csv-noisecut-prom/csv-noisecut-prom.component';
import { DisabledControlDirective } from './directive/disable-control-directive';
import { CsvVisitorsComponent } from './page/csv-visitors/csv-visitors.component';
import { CsvInventoryComponent } from './page/csv-inventory/csv-inventory.component';
import { CsvOrderComponent } from './page/csv-order/csv-order.component';
import { MenuItemComponent } from './page/mng/menu-item/menu-item.component';
import { MenuEditComponent } from './page/mng/menu-edit/menu-edit.component';
import { PromOrderComponent } from './page/prom-order/prom-order/prom-order.component';
import { ForecastUpDownNewComponent } from './page/forecast-up-down-new/forecast-up-down-new.component';
import { CsvUpDownComponent } from './page/csv-up-down/csv-up-down.component';
import { CsvTableStoreComponent } from './partsCommon/csv-table-store/csv-table-store.component';
import { NoisecutNewComponent } from './page/noisecut-new/main/noisecut-new.component';
import { NoisecutSearchComponent } from './page/noisecut-new/search/noisecut-search/noisecut-search.component';
import { DateRangeComponent } from './partsCommon/date-range/date-range.component';
import { HqMainComponent } from './page/task/2_hq-main/hq-main.component';
import { HqEditComponent } from './page/task/3_hq-edit/hq-edit.component';
import { StoreMainComponent } from './page/task/5_store-main/store-main.component';
import { StoreEditComponent } from './page/task/6_store-edit/store-edit.component';
import { TaskPhotoDialogComponent } from './page/task/photo-dialog/task-photo-dialog.component';
import { HqStoreResultComponent } from './page/task/4_hq-store-result/hq-store-result.component';
import { TaskPhotoViewDialogComponent } from './page/task/photo-view-dialog/task-photo-view-dialog.component';
import { ViewOtherStoreComponent } from './page/task/7_view-other-store/view-other-store.component';
import { ItemReplaceNewComponent } from './page/item-replace-new/item-replace-new.component';
import { CsvParamStoreItemComponent } from './page/csv-param-store-item/csv-param-store-item.component';
import { SafeHtmlPipe } from './pipe/sale-html-pipe';
import { PromOrderEditComponent } from './page/prom-order/prom-order-edit/prom-order-edit.component';
import { CtgSelectNewComponent } from './partsCommon/ctg-select-new/ctg-select-new.component';
import { PromRefDialogComponent } from './page/prom-order/prom-ref-dialog/prom-ref-dialog.component';
import { BarcodeDialogComponent } from './dialog/barcode-dialog/barcode-dialog.component';
import { BarcodeButtonComponent } from './partsCommon/barcode-button/barcode-button.component';
import { OrderByUserComponent } from './page/order-by-user/order-by-user.component';
import { NotificationComponent } from './partsCommon/notification/notification.component';
import { SelectOrderGroupComponent } from './page/order-by-user/select-order-group/select-order-group.component';
import { ItemSummaryDialogComponent } from './dialog/item-summary-dialog/item-summary-dialog.component';
import { LossRateComponent } from './page/loss-rate/loss-rate.component';
import { OrderGroupUserSingleComponent } from './page/order-group-user-single/order-group-user-single.component';
import { OrderStockComponent } from './page/order-stock/order-stock.component';
import { ScanItemEdit2Component } from './page/scan-item-edit2/scan-item-edit2.component';
import { PromSimComponent } from './page/prom-sim/prom-sim/prom-sim.component';
import { PromSimEditComponent } from './page/prom-sim/prom-sim-edit/prom-sim-edit.component';
import { PromSelectDialogComponent } from './dialog/prom-select-dialog/prom-select-dialog.component';
import { PromSelectListDialogComponent } from './dialog/prom-select-list-dialog/prom-select-list-dialog.component';
import { OrderApprovalComponent } from './page/order-approval/order-approval/order-approval.component';
import { OrderGroupUserMultiComponent } from './page/order-group-user-multi/order-group-user-multi.component';
import { OrderGroupUserMulti2Component } from './page/order-group-user-multi2/order-group-user-multi2.component';
import { NotificationEditComponent } from './page/notification/notification-edit/notification-edit.component';
import { OrderEditBgcolorDialogComponent } from './dialog/order-edit-bgcolor/order-edit-bgcolor-dialog.component';
import { SubtaskEditDialogComponent } from './page/task/subtask-edit-dialog/subtask-edit-dialog.component';
import { TaskWholeStatusMainComponent } from './page/task/whole-status/task-whole-status-main/task-whole-status-main.component';
import { TaskWholeStatusProgressComponent } from './page/task/whole-status/task-whole-status-progress/task-whole-status-progress.component';
import { TaskWholeStatusCommentsComponent } from './page/task/whole-status/task-whole-status-comments/task-whole-status-comments.component';
import { TaskDashboardHqComponent } from './page/task/task-dashboard-hq/task-dashboard-hq.component';
import { TaskDashboardStoreComponent } from './page/task/task-dashboard-store/task-dashboard-store.component';
import { ViewOtherStoreCommentComponent } from './page/task/7_view-other-store comment/view-other-store-comment.component';
import { ExpiryLogComponent } from './page/expiry-log/expiry-log.component';
import { ScanItemEdit2DialogComponent } from './dialog/scan-item-edit2-dialog/scan-item-edit2-dialog.component';
import { CsvImportDeadlineMasterComponent } from './page/csv-import-deadline-master/csv-import-deadline-master.component';
import { ItemExpiryMasterComponent } from './page/item-expiry/item-expiry-master/item-expiry-master.component';
import { PrinterLayoutComponent } from './page/printer/printer-layout/printer-layout.component';
import { ItemExpiryItemMasterComponent } from './page/item-expiry/item-expiry-item-master/item-expiry-item-master.component';
import { ItemExpiryCtgDialogComponent } from './page/item-expiry/item-expiry-ctg-dialog/item-expiry-ctg-dialog.component';
import { HtmlSelectItemEditComponent } from './page/mng/html-select-item-edit/html-select-item-edit.component';
import { ImageDbEditComponent } from './page/image-db/image-db-edit/image-db-edit.component';
import { ImageDbViewComponent } from './page/image-db/image-db-view/image-db-view.component';
import { StoreEdit2Component } from './page/task/6_store-edit2/store-edit2.component';
import { HqStoreResult2Component } from './page/task/4_hq-store-result2/hq-store-result2.component';
import { WeeklyOrderComponent } from './page/eob/weekly_order/weekly_order.component';
import { OrderListComponent } from './page/eob/order-list/order-list.component';
import { CtgSelectEobComponent } from './page/eob/ctg-eob-select/ctg-eob-select.component';
import { PreOrderListComponent } from './page/eob/pre-order-list/pre-order-list.component';
import { UrgentOrderListComponent } from './page/eob/urgent-order-list/urgent-order-list.component';
import { ResizeColumnDirective } from './page/eob/1_service/resize-column.directive';
import { GuideComponent } from './page/eob/guide/guide.component';
import { ItemExpiryComponent } from './page/item-expiry/item-expiry/item-expiry.component';
import { QrcodeDialogComponent } from './dialog/qrcode-dialog/qrcode-dialog.component';
import { LabelPrinterDialogComponent } from './page/printer/label-printer-dialog/label-printer-dialog.component';
import { ItemExpiryInitialComponent } from './page/item-expiry/item-expiry-initial/item-expiry-initial.component';
import { OrderGroupSelectDialogComponent } from './dialog/order-group-select-dialog/order-group-select-dialog.component';
import { ItemExpiryEditComponent } from './page/item-expiry/item-expiry-edit/item-expiry-edit.component';
import { ItemExpirySummaryComponent } from './page/item-expiry/item-expiry-summary/item-expiry-summary.component';
import { ItemExpiryInitialMainComponent } from './page/item-expiry/item-expiry-initial-main/item-expiry-initial-main.component';
import { ItemExpiryInitialDialogComponent } from './page/item-expiry/item-expiry-initial-dialog/item-expiry-initial-dialog.component';
import { ItemExpiryEditDialogComponent } from './page/item-expiry/item-expiry-edit-dialog/item-expiry-edit-dialog.component';
import { Spiv00061Component } from './page/spiv00061/spiv00061.component';
import { Spiv00101Component } from './page/spiv00101/spiv00101.component';
import { Spiv00101NotificationDialogComponent } from './dialog/spiv00101-notification-dialog/spiv00101-notification-dialog.component';
import { Spiv00101ErrorNotificationDialogComponent } from './dialog/spiv00101-error-notification-dialog/spiv00101-error-notification-dialog.component';
import { Spiv00081Component } from './page/spiv00081/spiv00081.component';
import { SectionHeaderUpComponent } from './partsCommon/section-header-up/section-header-up.component';
import { Spvw10241Component } from './page/spvw10241/spvw10241.component';
import { Spmt10301Component } from './page/spmt10301/spmt10301.component';
import { Spmt10301ConfirmDialogComponent} from './dialog/spmt10301-confirm-dialog/spmt10301-confirm-dialog.component';
import { Spmt10301DetailDialogComponent} from './dialog/spmt10301-detail-dialog/spmt10301-detail-dialog.component';
import { Spmt10331Component } from './page/spmt10331/spmt10331.component';
import { Spmt10331ConfirmDialogComponent} from './dialog/spmt10331-confirm-dialog/spmt10331-confirm-dialog.component';
import { Spmt10331DetailDialogComponent} from './dialog/spmt10331-detail-dialog/spmt10331-detail-dialog.component';
import { Spmt10311Component } from './page/spmt10311/spmt10311.component';
import { Spmt10311DetailDialogComponent} from './dialog/spmt10311-detail-dialog/spmt10311-detail-dialog.component';
import { Spmt10311SlipNoListDialogComponent } from './dialog/spmt10311-slipno-list-dialog/spmt10311-slipno-list-dialog.component';
import { SPIV00091Component } from './page/spiv00091/spiv00091.component';
import { Spbs00031Component } from './page/spbs00031/spbs00031.component';
import { Spiv00051Component } from './page/spiv00051/spiv00051.component';
import { Spiv00111Component } from './page/spiv00111/spiv00111.component';
import { IntegerOnlyDirective } from './directive/integer-only.directive';
import { Spiv00081DetailComponent } from './page/spiv00081/spiv00081.detail.component';
import { Spmt10261Component } from './page/spmt10261/spmt10261.component';
import { Spmt10261DetailDialogComponent } from './dialog/spmt10261-detail-dialog/spmt10261-detail-dialog.component';
import { PositiveNumberOnlyDirective } from './directive/positive-number-only.directive';
import { Spmt10261SlipListDialogComponent } from './dialog/spmt10261-slip-list-dialog/spmt10261-slip-list-dialog.component';
import { CsvExportComponent } from './page/common/csv-export/csv-export.component';
import { Spmt10271Component } from './page/spmt10271/spmt10271.component';
import { Spmt10271DetailDialogComponent } from './dialog/spmt10271-detail-dialog/spmt10271-detail-dialog.component';
import { Spmt10271RejectedDialogComponent } from './dialog/spmt10271-rejected-dialog/spmt10271-rejected-dialog.component';
import { Spmt10271ApprovalDialogComponent } from './dialog/spmt10271-approval-dialog/spmt10271-approval-dialog.component';
import { Spmt10341Component } from './page/spmt10341/spmt10341.component';
import { Spmt10341DetailDialogComponent } from './dialog/spmt10341-detail-dialog/spmt10341-detail-dialog.component';
import { Spmt10341ListSelectDialogComponent } from './dialog/spmt10341-list-select-dialog/spmt10341-list-select-dialog.component';
import { SortConditionDialogComponent } from './dialog/sort-condition-dialog/sort-condition-dialog.component';
import { AbsPipe } from './pipe/abs.pipe';
import { Spmt10291Component } from './page/spmt10291/spmt10291.component';
import { Spmt10291ConfirmDialogComponent } from './dialog/spmt10291-confirm-dialog/spmt10291-confirm-dialog.component';
import { Spmt10291DetailDialogComponent } from './dialog/spmt10291-detail-dialog/spmt10291-detail-dialog.component';
import { Spmt10321Component } from './page/spmt10321/spmt10321.component';
import { Spmt10321DetailDialogComponent } from './dialog/spmt10321-detail-dialog/spmt10321-detail-dialog.component';
import { Spmt10321ConfirmDialogComponent } from './dialog/spmt10321-confirm-dialog/spmt10321-confirm-dialog.component';
import { SignedNumberDirective } from './directive/signed-number.directive'
import { Spor00051Component } from './page/spor00051/spor00051.component';
import { Spor00061Component } from './page/spor00061/spor00061.component';
import { Spvw10231Component } from './page/spvw10231/spvw10231.component';
import { Spvw10251Component } from './page/spvw10251/spvw10251.component';
import { Spvw10251DetailDialogComponent } from './dialog/spvw10251-detail-dialog/spvw10251-detail-dialog.component';
import { Spbs00041Component } from './page/spbs00041/spbs00041.components';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    TopPageComponent,
    LoginComponent,
    LoginBarComponent,
    ToolBarComponent,
    SectionHeaderComponent,
    ErrorNotificationDialogComponent,
    NotificationDialogComponent,
    CtgSelectComponent,
    RackSelectComponent,
    ItemSelectConditionComponent,
    ItemSummaryComponent,
    OrderEditDialogComponent,
    Spvw10241Component,
    Spvw10231Component,
    Spmt10331Component,
    Spmt10311Component,
    CtgParamComponent,
    StockEditDialogComponent,
    SpinnerDialogComponent,
    YesNoDialogComponent,
    OrderComponent,
    Spmt10291ConfirmDialogComponent,
    Spmt10291DetailDialogComponent,
    Spmt10291Component,
    StockComponent,
    NumberOnlyDirective,
    ItemListComponent,
    ItemParamPipe,
    CtgParamEditComponent,
    ParamCtgEditDialogComponent,
    ItemParamEditComponent,
    ParamItemEditDialogComponent,
    ItemOrderStopEditComponent,
    ItemOrderStopEditDialogComponent,
    TableItemDialogComponent,
    OpestatPageComponent,
    OpestatViewComponent,
    OpestatViewMainComponent,
    OpestatViewDistrComponent,
    ItemHistComponent,
    ItemSelectSimpleComponent,
    ItemListSimpleComponent,
    ItemHistListComponent,
    ReportComponent,
    RoleComponent,
    Role2Component,
    NoisecutComponent,
    NoisecutNewComponent,
    PasswdComponent,
    UserComponent,
    DownldComponent,
    ItemNoisecutDialogComponent,
    LogoutComponent,
    ItemComponent,
    NoisecutHistoryComponent,
    NumberKeypadComponent,
    RackComponent,
    ScanItemEditComponent,
    ScanItemEdit2Component,
    CtgHistComponent,
    OrderStopTermCtgComponent,
    OrderStopTermSuppComponent,
    OrderStopTermStoreComponent,
    OrderStopTermItemComponent,
    AllStoreParamItemComponent,
    AllStoreParamCtgComponent,
    ExternalLinkComponent,
    ItemReplaceComponent,
    ForecastUpDownComponent,
    ForecastUpDownNewComponent,
    StoreGroupMngComponent,
    CameraComponent,
    CameraDialogComponent,
    CsvReaderComponent,
    CsvParamItemComponent,
    CsvTableComponent,
    CsvTableStoreComponent,
    CsvSeasonalCategoryTargetComponent,
    CsvUpDownComponent,
    AnalysisMainComponent,
    AnalysisViewStandardComponent,
    GraphOptionDialogComponent,
    VendorShortageComponent,
    OperationLogComponent,
    PbiAnalysisComponent,
    PbiReportListComponent,
    PbiMenuComponent,
    CameraLayoutComponent,
    CameraLayoutConfigComponent,
    CsvNoisecutPromComponent,
    CsvVisitorsComponent,
    DisabledControlDirective,
    CsvInventoryComponent,
    CsvOrderComponent,
    MenuItemComponent,
    MenuEditComponent,
    PromOrderComponent,
    NoisecutSearchComponent,
    DateRangeComponent,
    HqMainComponent,
    HqEditComponent,
    HqStoreResultComponent,
    HqStoreResult2Component,
    StoreMainComponent,
    StoreEditComponent,
    StoreEdit2Component,
    TaskPhotoDialogComponent,
    TaskPhotoViewDialogComponent,
    ViewOtherStoreComponent,
    ViewOtherStoreCommentComponent,
    TaskPhotoDialogComponent,
    ItemReplaceNewComponent,
    CsvParamStoreItemComponent,
    SafeHtmlPipe,
    CsvParamStoreItemComponent,
    PromOrderEditComponent,
    CtgSelectNewComponent,
    PromRefDialogComponent,
    FileListComponent,
    PromSimComponent,
    PromSimEditComponent,
    PromSelectDialogComponent,
    PromSelectListDialogComponent,
    BarcodeDialogComponent,
    BarcodeButtonComponent,
    OrderByUserComponent,
    NotificationComponent,
    SelectOrderGroupComponent,
    OrderGroupSelectDialogComponent,
    ItemSummaryDialogComponent,
    LossRateComponent,
    OrderGroupUserSingleComponent,
    OrderStockComponent,
    ItemExpiryMasterComponent,
    ItemExpiryItemMasterComponent,
    ItemExpiryComponent,
    ItemExpiryInitialMainComponent,
    ItemExpiryInitialComponent,
    ItemExpiryInitialDialogComponent,
    ItemExpiryEditComponent,
    ItemExpiryEditDialogComponent,
    ItemExpirySummaryComponent,
    ItemExpiryCtgDialogComponent,
    OrderApprovalComponent,
    OrderGroupUserMultiComponent,
    OrderGroupUserMulti2Component,
    NotificationEditComponent,
    OrderEditBgcolorDialogComponent,
    SubtaskEditDialogComponent,
    TaskWholeStatusMainComponent,
    TaskWholeStatusProgressComponent,
    TaskWholeStatusCommentsComponent,
    TaskDashboardHqComponent,
    TaskDashboardStoreComponent,
    ExpiryLogComponent,
    ScanItemEdit2DialogComponent,
    CsvImportDeadlineMasterComponent,
    PrinterLayoutComponent,
    PrinterLayoutComponent,
    ImageDbEditComponent,
    ImageDbViewComponent,
    HtmlSelectItemEditComponent,
	  WeeklyOrderComponent,
	  OrderListComponent,
    CtgSelectEobComponent,
    PreOrderListComponent,
    UrgentOrderListComponent,
    ResizeColumnDirective,
    GuideComponent,
    HtmlSelectItemEditComponent,
    QrcodeDialogComponent,
    LabelPrinterDialogComponent,
    Spbs00031Component,
    Spiv00061Component,
    Spiv00101Component,
    Spiv00101NotificationDialogComponent,
    Spiv00101ErrorNotificationDialogComponent,
    Spiv00081Component,
    Spiv00081DetailComponent,
    SectionHeaderUpComponent,
    Spmt10311DetailDialogComponent,
    Spmt10301Component,
    Spmt10301DetailDialogComponent,
    Spmt10301ConfirmDialogComponent,
    Spmt10331ConfirmDialogComponent,
    Spmt10331DetailDialogComponent,
    Spmt10261DetailDialogComponent,
    SPIV00091Component,
    Spiv00051Component,
    Spiv00111Component,
    Spor00051Component,
    Spor00061Component,
    IntegerOnlyDirective,
    SignedNumberDirective,
    CsvExportComponent,
    Spmt10261Component,
    PositiveNumberOnlyDirective,
    Spmt10261SlipListDialogComponent,
    CsvExportComponent,
    Spmt10271DetailDialogComponent,
    Spmt10271ApprovalDialogComponent,
    Spmt10271RejectedDialogComponent,
    Spmt10271Component,
    Spmt10341Component,
    Spmt10341DetailDialogComponent,
    Spmt10311SlipNoListDialogComponent,
    Spmt10341ListSelectDialogComponent,
    Spbs00041Component,
    SortConditionDialogComponent,
    AbsPipe,
    Spmt10321Component,
    Spvw10251Component,
    Spvw10251DetailDialogComponent,
    Spmt10321DetailDialogComponent,
    Spmt10321ConfirmDialogComponent,
    CsvExportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CdkTableModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    // MatBadgeModule,
    // MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    // MatCardModule,
    MatCheckboxModule,
    // MatChipsModule,
    // MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    // MatDividerModule,
    // MatExpansionModule,
    // MatGridListModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    // MatProgressBarModule,
    MatProgressSpinnerModule,
    // MatRadioModule,
    // MatRippleModule,
    // MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    // MatTreeModule,
    DragDropModule,
    NgxChartsModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
    {provide: DateAdapter, useClass: JpDateAdapterModule},
    {provide: MatPaginatorIntl, useClass: MyMatPaginatorIntlModule},
    DecimalPipe
  ],
  entryComponents: [
    ErrorNotificationDialogComponent,
    NotificationDialogComponent,
    SpinnerDialogComponent,
    YesNoDialogComponent,
    OrderEditDialogComponent,
    StockEditDialogComponent,
    ParamCtgEditDialogComponent,
    ParamItemEditDialogComponent,
    ItemOrderStopEditDialogComponent,
    TableItemDialogComponent,
    ItemNoisecutDialogComponent,
    CameraDialogComponent,
    GraphOptionDialogComponent,
    TaskPhotoDialogComponent,
    TaskPhotoViewDialogComponent,
    PromRefDialogComponent,
    PromSelectDialogComponent,
    PromSelectListDialogComponent,
    BarcodeDialogComponent,
    SelectOrderGroupComponent,
    OrderGroupSelectDialogComponent,
    ItemSummaryDialogComponent,
    OrderEditBgcolorDialogComponent,
    ScanItemEdit2DialogComponent,
    ItemExpiryCtgDialogComponent,
    QrcodeDialogComponent,
    LabelPrinterDialogComponent,
    ItemExpiryInitialDialogComponent,
    Spmt10311SlipNoListDialogComponent,
    ItemExpiryEditDialogComponent,
    Spmt10301ConfirmDialogComponent,
    Spmt10301DetailDialogComponent,
    Spmt10261DetailDialogComponent,
    Spmt10331ConfirmDialogComponent,
    Spmt10331DetailDialogComponent,
    Spmt10311DetailDialogComponent,
    Spmt10291ConfirmDialogComponent,
    Spmt10291DetailDialogComponent,
    Spmt10271ApprovalDialogComponent,
    Spmt10271RejectedDialogComponent,
    Spvw10251DetailDialogComponent,
    Spmt10271DetailDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
