import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { NotificationDto, ReqGetNotification, RspGetNotification } from 'src/app/webservice/notification';

/*
export interface NotificationRec {
  notificationId:   number;
  notificationType: string;
  notification:     string;
  notifierId:       string;
  notifier:         string;
  notificationDate: string;
  expirationDate:   string;
}
*/

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private initFlag: boolean = false;
  private initUser: string;
  private maxNotificationId: number = 0;

  public notificationList: NotificationDto[] = [];

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService
  ) {}

  init(callback: ()=> void) {
    if (this.initFlag && this.initUser == this.commonService.loginUser.userId) {
      if (callback) callback();
      return;
    }

    this.notificationList = [];
    this.maxNotificationId = 0;

    this.getNotification(callback);
  }

  getNotification(callback: ()=> void, refresh?: boolean) {
    let request: ReqGetNotification = {
      access: this.commonService.loginUser,
      storeCd: this.commonService.config.honbu ? this.commonService.loginUser.realStoreCd : this.commonService.loginUser.storeCd,
      roleId: this.commonService.loginUser.roleId,
      userId: this.commonService.loginUser.userId,
      retrievedMax: refresh ? 0: this.maxNotificationId
    };

    if (refresh) {
      this.notificationList = [];
      this.maxNotificationId = 0;
    }
    let subsc = this.httpBasic.generalRequest("GetNotification", request).subscribe(
      (response: RspGetNotification) => {
        subsc.unsubscribe();
        this.receiveNotification(response, callback);
      },
      (error) => {
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );

    /*
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "notification/get_notification",
      bindVariables: [
        this.commonService.config.honbu ? this.commonService.loginUser.realStoreCd : this.commonService.loginUser.storeCd,
        this.commonService.loginUser.userId,
        refresh ? "0": "" + this.maxNotificationId
      ]
    };

    if (refresh) {
      this.notificationList = [];
      this.maxNotificationId = 0;
    }
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveNotification(response, callback);
      },
      (error) => {
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
    */
  }

  receiveNotification(response: RspGetNotification, callback: ()=> void) {
    if (this.httpBasic.handleAppError(response)) return;

    for (let row of response.rows) {
      this.notificationList.push(row);
      if (this.maxNotificationId < row.notificationId) {
        this.maxNotificationId = row.notificationId;
      }
    }
    this.initFlag = true;
    this.initUser = this.commonService.loginUser.userId;

    if (callback) callback();
  }

}
