import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appIntegerOnly]'
})
export class IntegerOnlyDirective {
  private regex: RegExp = new RegExp(/^-?\d*$/);
  private specialKeys: Array<string> = ['Backspace', 'Delete', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow: special keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const cursorPosition = this.el.nativeElement.selectionStart;

    // If '-' is pressed, ensure it's only at the start of the input
    if (event.key === '-') {
      // Prevent '-' if it's not at the start or if it already exists
      if (cursorPosition !== 0 || current.includes('-')) {
        event.preventDefault();
      }
      return;
    }

    const next: string = [
      current.slice(0, cursorPosition),
      event.key,
      current.slice(cursorPosition)
    ].join('');

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
