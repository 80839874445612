export class RoleMenu {
    roleId: number;
    menuGroupId: number;
    menuGroupName: string;
    menuId: number;
    menuName: string;
    menuReadonlyControl: boolean;
    menuReadonly: boolean;
    roleReadonly: boolean;
    isSelected: boolean;
}
