import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RoleMenu } from './role-menu';
import { Role } from './role';
import { Subscription, Observable } from 'rxjs';
import { RoleMenuDto, RspGetRole } from 'src/app/response/rsp-get-role';
import { RspUpdRole } from 'src/app/response/rsp-upd-role';
import { ReqUpdRole } from 'src/app/request/req-upd-role';
import { MenuGroupDto } from 'src/app/webservice/menu';

const UPDROLE_MODE_INSERT: number = 1;
const UPDROLE_MODE_UPDATE: number = 2;
const UPDROLE_MODE_DELETE: number = 3;

@Component({
  selector: 'app-role',
  templateUrl: './role2.component.html',
  styleUrls: ['./role2.component.css']
})
export class Role2Component implements OnInit, OnDestroy {

  public isReadonly: boolean;
  public tabSelection: number = 0;
  public roles: Role[] = [];
  public selectedRole: Role;
  public roleFormGroup: FormGroup;
  public roleMenuList: RoleMenu[] = [];
  public roleMobileMenuList: RoleMenu[] = [];
  public displayColumns: string[] = ["isSelected", "menuGroupName", "menuName", "menuReadonly"];
  public displayColumnsMobile: string[] = ["isSelected", "menuGroupName", "menuName"];

  public formCtgMinZai: FormControl = new FormControl(false);
  public formCtgMaxZai: FormControl = new FormControl(false);
  public formCtgMinStockDays: FormControl = new FormControl(false);
  public formCtgMaxStockDays: FormControl = new FormControl(false);
  public formItemMinZai: FormControl = new FormControl(false);
  public formItemMaxZai: FormControl = new FormControl(false);
  public formItemMinStockDays: FormControl = new FormControl(false);
  public formItemMaxStockDays: FormControl = new FormControl(false);

  public ctgParamMenu: RoleMenu;
  public itemParamMenu: RoleMenu;

  private tabIndexMain: number = 0;
  private tabIndexMobileMenu: number = 1;
  private tabIndexPriv: number = 2;

  private subscriptionGetRole: Subscription;
  private subscriptionUpdRole: Subscription;
  // @ViewChild("roleMenuTable", {static: false}) private roleMenuTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder) {

    let fg = new FormGroup({});
    for (let roleMenu of this.allRoleMenus(this.commonService.menuGroups, -1)) {
      fg.registerControl("sel"+roleMenu.menuId, new FormControl(false));
      fg.registerControl("ro"+roleMenu.menuId, new FormControl(true));
    }
    for (let roleMenu of this.allRoleMenus(this.commonService.menuGroupsMobile, -1)) {
      fg.registerControl("selMobile"+roleMenu.menuId, new FormControl(false));
    }

    this.roleFormGroup = fb.group({
    roleName: [, Validators.required],
    roleComment: [],
    trgtStore: [],
    roleLevel: [,
      [Validators.required,
       (control) => {
                   if (parseInt(control.value) < this.minRoleLevel()) {
                    return {"levError": "権限エラー"};
                  }
                  return null;
                  }
      ]
    ],
    roleMenus: fg
    });
  }

  ngOnInit() {
    // this.commonService.pageTitle = "役割管理";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = false;
    if (this.commonService.config.useMobile) {
      this.tabIndexMobileMenu = 1;
      this.tabIndexPriv = 2;
    } else {
      this.tabIndexMobileMenu = -1;
      this.tabIndexPriv = 1;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "初期化中・・・");
    let subsc = this.httpBasic.getRole().subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveRole(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error)
      }
    );

    this.roleFormGroup.disable();
    this.setTableHeight();
  }

  ngOnDestroy() {
    if (this.subscriptionGetRole) {
      this.subscriptionGetRole.unsubscribe();
    }
    if (this.subscriptionUpdRole) {
      this.subscriptionUpdRole.unsubscribe();
    }

    this.roles.forEach((role) => {
      role.roleMenus.forEach((menu) => {menu.clearEdit();})
    });
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  tabChanged(tabNumber: number) {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "role-list";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 0;
    let margin = 10 + 5;
    let btnBox = 48;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.height = "" + height + "px";

    if (this.tabSelection == this.tabIndexMain) {
      this.setMaxHeight("role-menu-container");
    } if (this.tabSelection == this.tabIndexMobileMenu) {
      this.setMaxHeight("role-mobile-menu-container");
    } else {
      this.setMaxHeight("priv-container");
    }
  }

  setMaxHeight(id: string) {
    let remHeight = this.commonService.getHeightBelow(id);
    // let paginatorHeight = 56;
    let paginatorHeight = 0;
    let margin = 5;
    // let btnBox = 24;
    let btnBox = 0;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  minRoleLevel() {
    if (this.commonService.loginUser.roleLevel > 0) {
      return this.commonService.loginUser.roleLevel;
    }
    return 1;
  }

  /*
  clearProgressStateUpdRole() {
    this.commonService.closeSpinner();
    this.subscriptionUpdRole.unsubscribe();
    this.subscriptionUpdRole = undefined;
  }
  */

  receiveRole(response: RspGetRole) {
    if (this.httpBasic.handleAppError(response)) return;

    for (let rspRole of response.roles) {
      let role = new Role();
      role.roleId = rspRole.roleId;
      role.roleName = rspRole.roleName;
      role.trgetStoreFlag = rspRole.trgetStoreFlag;
      role.roleLevel = rspRole.roleLevel;
      role.roleComment = rspRole.roleComment;
      role.version = rspRole.version;
      role.userCount = rspRole.userCount;
      if (!rspRole.roleConfig || rspRole.roleConfig === "") {
        role.roleConfig = {};
      } else {
        role.roleConfig = JSON.parse(rspRole.roleConfig);
      }
      let roleMenus = this.allRoleMenus(this.commonService.menuGroups, role.roleId);
      role.roleMenus = roleMenus;
      for (let rspRoleMenu of rspRole.roleMenus) {
        this.setRoleMenuSelect(roleMenus, rspRoleMenu.menuId, true, 
          rspRoleMenu.roleReadonlyFlag > 0 ? true : false);
      }
      let roleMenusMobile = this.allRoleMenus(this.commonService.menuGroupsMobile, role.roleId);
      role.roleMenusMobile = roleMenusMobile;
      for (let rspRoleMenu of rspRole.roleMenusMobile) {
        this.setRoleMenuSelect(roleMenusMobile, rspRoleMenu.menuId, true, false);
      }

      this.roles.push(role);
    }
    if (this.roles.length > 0) {
      this.selectRole(this.roles[0]);
    }
  }

  clickRole(role: Role) {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.selectRole(role);
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          if (this.selectedRole && this.selectedRole.roleId < 0) {
            this.deleteCurrentRole();
          }
          this.selectRole(role);
        }
      }
    );
  }

  selectRole(role: Role) {
    if (this.selectedRole) {
      this.selectedRole.roleMenus.forEach((menu) => menu.clearEdit());
    }

    this.selectedRole = role;

    this.roleFormGroup.get("roleName").setValue(role.roleName);
    this.roleFormGroup.get("roleComment").setValue(role.roleComment);
    this.roleFormGroup.get("trgtStore").setValue("" + role.trgetStoreFlag);
    this.roleFormGroup.get("roleLevel").setValue("" + role.roleLevel);
    this.roleMenuList = [];
    for (let roleMenu of role.roleMenus) {
      this.roleMenuList.push(roleMenu);
      roleMenu.setCtgListener(() => {this.ctgParamChanged();});
      roleMenu.setItemListener(() => {this.itemParamChanged();});
      roleMenu.prepareEdit();
    }
    this.roleMobileMenuList = [];
    for (let roleMenu of role.roleMenusMobile) {
      this.roleMobileMenuList.push(roleMenu);
      /*
      roleMenu.setCtgListener(() => {this.ctgParamChanged();});
      roleMenu.setItemListener(() => {this.itemParamChanged();});
      */
      roleMenu.prepareEdit();
    }
    this.findCtgParamMenu();
    this.findItemParamMenu();
    this.initCtgParamVal();
    this.initItemParamVal();
    this.ctgParamEnableDisable();
    this.itemParamEnableDisable();

    this.roleFormGroup.markAsPristine();
    this.roleFormGroup.enable();
    if (this.commonService.stores.length <= 1) {
      this.roleFormGroup.get("trgtStore").disable();
    }
    this.formCtgMinZai.markAsPristine();
    this.formCtgMaxZai.markAsPristine();
    this.formCtgMinStockDays.markAsPristine();
    this.formCtgMaxStockDays.markAsPristine();
    this.formItemMinZai.markAsPristine();
    this.formItemMaxZai.markAsPristine();
    this.formItemMinStockDays.markAsPristine();
    this.formItemMaxStockDays.markAsPristine();
  }

  findCtgParamMenu() {
    this.ctgParamMenu = this.roleMenuList.find((menu) => {return menu.menuPath === "ctgparam"});
  }

  findItemParamMenu() {
    this.itemParamMenu = this.roleMenuList.find((menu) => {return menu.menuPath === "itemparam"});
  }

  ctgParamMenuSelected(roleMenu: RoleMenu) {
    if (!roleMenu) return false;
    if (!roleMenu.edit) return false;
    return roleMenu.edit.formSelect.value;
  }

  ctgParamMenuReadOnly(roleMenu: RoleMenu) {
    if (!roleMenu) return false;
    if (!roleMenu.edit) return false;
    return roleMenu.edit.formReadOnly.value;
  }

  setStorePriv(priv: string) {
    if (this.commonService.stores.length <= 1) return;
    switch(priv) {
      case "全店舗":
        this.roleFormGroup.get("trgtStore").setValue("1");
        break;
      case "自店舗":
        this.roleFormGroup.get("trgtStore").setValue("0");
        break;
    }
  }

  allRoleMenus(menuGroups: MenuGroupDto[], roleId: number) : RoleMenu[] {
    var roleMenus: RoleMenu[] = [];

    for (let menuGroup of /* this.commonService.*/ menuGroups) {
      for (let menuItem of menuGroup.menuItems) {
        let roleMenu = new RoleMenu(this.commonService);
        roleMenu.roleId = roleId;
        roleMenu.isSelected = false;
        roleMenu.menuGroupId = menuGroup.menuGroupId;
        roleMenu.menuGroupName = menuGroup.menuGroupName;
        roleMenu.menuId = menuItem.menuId;
        roleMenu.menuName = menuItem.menuName;
        roleMenu.menuReadonlyControl = menuItem.menuReadonly > 0 ? false: true;
        roleMenu.menuReadonly = menuItem.isReadonly > 0 ? true : false;
        roleMenu.roleReadonly = false;          // true;
        roleMenu.menuPath = menuItem.menuPath;
        roleMenus.push(roleMenu);
      }
    }

    return roleMenus;
  }

  setRoleMenuSelect(roleMenus: RoleMenu[], menuId: number, selection: boolean, roleReadonly: boolean) {
    for (let roleMenu of roleMenus) {
      if (roleMenu.menuId == menuId) {
        roleMenu.isSelected = selection;
        roleMenu.roleReadonly = roleReadonly;
        return;
      }
    }
  }

  copyRole(role: Role) : Role {
    let roleNew = new Role();

    roleNew.roleId = -1;
    roleNew.roleName = "新規役割";
    roleNew.roleComment = "";
    roleNew.trgetStoreFlag = role.trgetStoreFlag;
    roleNew.roleLevel = role.roleLevel;
    roleNew.version = 1;
    roleNew.userCount = 0;
    roleNew.roleConfig = {...role.roleConfig};
    roleNew.roleMenus = [];
    for (let roleMenu of role.roleMenus) {
      let roleMenuNew = new RoleMenu(this.commonService);
      roleMenuNew.roleId = roleNew.roleId;
      roleMenuNew.menuGroupId = roleMenu.menuGroupId;
      roleMenuNew.menuGroupName = roleMenu.menuGroupName;
      roleMenuNew.menuId = roleMenu.menuId;
      roleMenuNew.menuName = roleMenu.menuName;
      roleMenuNew.menuReadonlyControl = roleMenu.menuReadonlyControl;
      roleMenuNew.menuReadonly = roleMenu.menuReadonly;
      roleMenuNew.isSelected = roleMenu.isSelected;
      roleMenuNew.roleReadonly = roleMenu.roleReadonly;
      roleMenuNew.menuPath = roleMenu.menuPath;
      roleNew.roleMenus.push(roleMenuNew);
    }
    roleNew.roleMenusMobile = [];
    for (let roleMenu of role.roleMenusMobile) {
      let roleMenuNew = new RoleMenu(this.commonService);
      roleMenuNew.roleId = roleNew.roleId;
      roleMenuNew.menuGroupId = roleMenu.menuGroupId;
      roleMenuNew.menuGroupName = roleMenu.menuGroupName;
      roleMenuNew.menuId = roleMenu.menuId;
      roleMenuNew.menuName = roleMenu.menuName;
      roleMenuNew.menuReadonlyControl = roleMenu.menuReadonlyControl;
      roleMenuNew.menuReadonly = roleMenu.menuReadonly;
      roleMenuNew.isSelected = roleMenu.isSelected;
      roleMenuNew.roleReadonly = roleMenu.roleReadonly;
      roleMenuNew.menuPath = roleMenu.menuPath;
      roleNew.roleMenusMobile.push(roleMenuNew);
    }

    return roleNew;
  }

  newRole() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.newRoleBody();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          if (this.selectedRole && this.selectedRole.roleId < 0) {
            this.deleteCurrentRole();
          }
          this.newRoleBody();
        }
      }
    );
  }

  newRoleBody() {
    let role = undefined;
    if (this.selectedRole) {
      role = this.copyRole(this.selectedRole);
    }
    if (role == undefined) {
      role = new Role();

      role.roleId = -1;
      role.roleName = "新規役割";
      role.roleLevel = this.commonService.loginUser.roleLevel;
      role.roleComment = "";
      role.trgetStoreFlag = "0";    // role.trgetStoreFlag;
      role.version = 1;
      role.userCount = 0;
      role.roleMenus = this.allRoleMenus(this.commonService.menuGroups, role.roleId);
      role.roleMenusMobile = this.allRoleMenus(this.commonService.menuGroupsMobile, role.roleId);
    }
    this.roles.push(role);
    this.selectRole(role);
    this.roleFormGroup.markAsDirty();
  }

  deleteCurrentRole() {
    let found = false;
    let index = 0;
    for (index = 0; index < this.roles.length; index++) {
      if (this.roles[index].roleId == this.selectedRole.roleId) {
        found = true;
        break;
      }
    }
    if (found == false) return;

    if (this.roles[index].roleId < 0) {
      this.afterDeleteRole(index);
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
    let subsc = this.httpBasic.updRole(UPDROLE_MODE_DELETE, this.roles[index]).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveDeleteRole(response, index)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDeleteRole(response: RspUpdRole, index: number) {
    if (this.httpBasic.handleAppError(response)) return;
    this.afterDeleteRole(index);
  }

  afterDeleteRole(index: number) {
    if (this.selectedRole) {
      this.selectedRole.roleMenus.forEach((menu) => menu.cancelEdit());
    }
    this.roles.splice(index, 1);
    if (this.roles.length > 0) {
      this.selectRole(this.roles[0]);
    } else {
      this.selectedRole = undefined;
      this.roleMenuList = [];
      this.roleMobileMenuList = [];
      this.roleFormGroup.get("roleName").setValue("");
      this.roleFormGroup.get("roleComment").setValue("");
      this.roleFormGroup.get("trgtStore").setValue("");
      this.roleFormGroup.get("roleLevel").setValue("");
      this.roleFormGroup.markAsPristine();
      this.formCtgMinZai.markAsPristine();
      this.formCtgMaxZai.markAsPristine();
      this.formCtgMinStockDays.markAsPristine();
      this.formCtgMaxStockDays.markAsPristine();
      this.formItemMinZai.markAsPristine();
      this.formItemMaxZai.markAsPristine();
      this.formItemMinStockDays.markAsPristine();
      this.formItemMaxStockDays.markAsPristine();
      this.roleFormGroup.disable();
      this.ctgParamMenu = undefined;
      this.itemParamMenu = undefined;
      this.ctgParamEnableDisable();
      this.itemParamEnableDisable();
    }
  }

  updateRole() {
    let mode = this.selectedRole.roleId < 0 ? UPDROLE_MODE_INSERT : UPDROLE_MODE_UPDATE;

    let roleMenus: RoleMenuDto[] = [];
    for (let roleMenu of this.selectedRole.roleMenus) {
      roleMenu.isSelected = roleMenu.edit.formSelect.value;
      roleMenu.roleReadonly = roleMenu.edit.formReadOnly.value;
      if (!roleMenu.edit.formSelect.value) continue;
      roleMenus.push({
        roleId: roleMenu.roleId,
        roleReadonlyFlag: roleMenu.edit.formReadOnly.value ? 1 : 0,
        menuGroupId: roleMenu.menuGroupId,
        menuGroupName: roleMenu.menuGroupName,
        menuId: roleMenu.menuId,
        menuName: roleMenu.menuName,
        menuReadonlyFlag: roleMenu.menuReadonly ? 1 : 0
      });
    }
    let roleMenusMobile: RoleMenuDto[] = [];
    for (let roleMenu of this.selectedRole.roleMenusMobile) {
      roleMenu.isSelected = roleMenu.edit.formSelect.value;
      // roleMenu.roleReadonly = roleMenu.edit.formReadOnly.value;
      if (!roleMenu.edit.formSelect.value) continue;
      roleMenusMobile.push({
        roleId: roleMenu.roleId,
        roleReadonlyFlag: 0,                    // roleMenu.edit.formReadOnly.value ? 1 : 0,
        menuGroupId: roleMenu.menuGroupId,
        menuGroupName: roleMenu.menuGroupName,
        menuId: roleMenu.menuId,
        menuName: roleMenu.menuName,
        menuReadonlyFlag: roleMenu.menuReadonly ? 1 : 0
      });
    }

    this.selectedRole.roleConfig["paramCatMinZaiEditable"] = this.formCtgMinZai.value;
    this.selectedRole.roleConfig["paramCatMaxZaiEditable"] = this.formCtgMaxZai.value;
    this.selectedRole.roleConfig["paramCatMinStockDaysEditable"] = this.formCtgMinStockDays.value;
    this.selectedRole.roleConfig["paramCatMaxStockDaysEditable"] = this.formCtgMaxStockDays.value;
    this.selectedRole.roleConfig["paramItemMinZaiEditable"] = this.formItemMinZai.value;
    this.selectedRole.roleConfig["paramItemMaxZaiEditable"] = this.formItemMaxZai.value;
    this.selectedRole.roleConfig["paramItemMinStockDaysEditable"] = this.formItemMinStockDays.value;
    this.selectedRole.roleConfig["paramItemMaxStockDaysEditable"] = this.formItemMaxStockDays.value;

    let request: ReqUpdRole = {
      access: this.commonService.loginUser,
      mode: mode,
      role: {
        roleId: this.selectedRole.roleId,
        roleName: this.roleFormGroup.get("roleName").value,
        roleComment: this.roleFormGroup.get("roleComment").value ? this.roleFormGroup.get("roleComment").value : "",
        trgetStoreFlag: parseInt(this.roleFormGroup.get("trgtStore").value),
        roleLevel: parseInt(this.roleFormGroup.get("roleLevel").value),
        version: this.selectedRole.version,
        userCount: this.selectedRole.userCount,
        roleMenus: roleMenus,
        roleMenusMobile: roleMenusMobile,
        roleConfig: JSON.stringify(this.selectedRole.roleConfig)
      }
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdRole", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveUpdateRole(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpdateRole(response: RspUpdRole) {
    if (this.httpBasic.handleAppError(response)) return;

    let rspRole = response.role;
    this.selectedRole.roleId = rspRole.roleId;
    this.selectedRole.version = rspRole.version;

    this.roleFormGroup.markAsPristine();
    this.formCtgMinZai.markAsPristine();
    this.formCtgMaxZai.markAsPristine();
    this.formCtgMinStockDays.markAsPristine();
    this.formCtgMaxStockDays.markAsPristine();
    this.formItemMinZai.markAsPristine();
    this.formItemMaxZai.markAsPristine();
    this.formItemMinStockDays.markAsPristine();
    this.formItemMaxStockDays.markAsPristine();
    this.selectedRole.roleMenus.forEach((menu) => {
      menu.roleId = rspRole.roleId;
      menu.edit.formSelect.markAsPristine();
      menu.edit.formReadOnly.markAsPristine();
    });
    this.selectedRole.roleMenusMobile.forEach((menu) => {
      menu.roleId = rspRole.roleId;
      menu.edit.formSelect.markAsPristine();
      menu.edit.formReadOnly.markAsPristine();
    });
  }

  cancelRole() {
    if (this.selectedRole.roleId < 0) {
      this.deleteCurrentRole();
      return;
    }
    this.selectRole(this.selectedRole);
  }

  invertBoolean(form: FormControl) {
    if (form.disabled) return;
    form.setValue(!form.value);
  }

  getConfig(key: string) {
    if (!this.commonService.config[key]) return false;
    if (this.selectedRole.roleConfig[key] !== undefined) return this.selectedRole.roleConfig[key];
    return this.commonService.config[key];
  }

  initCtgParamVal() {
    if (!this.ctgParamMenu || !this.ctgParamMenu.edit.formSelect.value || this.ctgParamMenu.edit.formReadOnly.value) {
      this.formCtgMinZai.setValue(false);
      this.formCtgMaxZai.setValue(false);
      this.formCtgMinStockDays.setValue(false);
      this.formCtgMaxStockDays.setValue(false);
    } else {
      this.formCtgMinZai.setValue(this.getConfig("paramCatMinZaiEditable"));
      this.formCtgMaxZai.setValue(this.getConfig("paramCatMaxZaiEditable"));
      this.formCtgMinStockDays.setValue(this.getConfig("paramCatMinStockDaysEditable"));
      this.formCtgMaxStockDays.setValue(this.getConfig("paramCatMaxStockDaysEditable"));
    }
  }

  initItemParamVal() {
    if (!this.itemParamMenu || !this.itemParamMenu.edit.formSelect.value || this.itemParamMenu.edit.formReadOnly.value) {
      this.formItemMinZai.setValue(false);
      this.formItemMaxZai.setValue(false);
      this.formItemMinStockDays.setValue(false);
      this.formItemMaxStockDays.setValue(false);
    } else {
      this.formItemMinZai.setValue(this.getConfig("paramItemMinZaiEditable"));
      this.formItemMaxZai.setValue(this.getConfig("paramItemMaxZaiEditable"));
      this.formItemMinStockDays.setValue(this.getConfig("paramItemMinStockDaysEditable"));
      this.formItemMaxStockDays.setValue(this.getConfig("paramItemMaxStockDaysEditable"));
    }
  }

  ctgParamEnableDisable() {
    this.formCtgMinZai.disable({emitEvent: false});
    this.formCtgMaxZai.disable({emitEvent: false});
    this.formCtgMinStockDays.disable({emitEvent: false});
    this.formCtgMaxStockDays.disable({emitEvent: false});
    if (this.ctgParamMenu && this.ctgParamMenu.edit.formSelect.value && !this.ctgParamMenu.edit.formReadOnly.value) {
      if (this.commonService.config.paramCatMinZaiEditable) this.formCtgMinZai.enable({emitEvent: false});
      if (this.commonService.config.paramCatMaxZaiEditable) this.formCtgMaxZai.enable({emitEvent: false});
      if (this.commonService.config.paramCatMinStockDaysEditable) this.formCtgMinStockDays.enable({emitEvent: false});
      if (this.commonService.config.paramCatMaxStockDaysEditable) this.formCtgMaxStockDays.enable({emitEvent: false});
    }
  }

  itemParamEnableDisable() {
    this.formItemMinZai.disable({emitEvent: false});
    this.formItemMaxZai.disable({emitEvent: false});
    this.formItemMinStockDays.disable({emitEvent: false});
    this.formItemMaxStockDays.disable({emitEvent: false});
    if (this.itemParamMenu && this.itemParamMenu.edit.formSelect.value && !this.itemParamMenu.edit.formReadOnly.value) {
      if (this.commonService.config.paramItemMinZaiEditable) this.formItemMinZai.enable({emitEvent: false});
      if (this.commonService.config.paramItemMaxZaiEditable) this.formItemMaxZai.enable({emitEvent: false});
      if (this.commonService.config.paramItemMinStockDaysEditable) this.formItemMinStockDays.enable({emitEvent: false});
      if (this.commonService.config.paramItemMaxStockDaysEditable) this.formItemMaxStockDays.enable({emitEvent: false});
    }
  }

  ctgParamChanged() {
    this.initCtgParamVal();
    this.ctgParamEnableDisable();
  }

  itemParamChanged() {
    this.initItemParamVal();
    this.itemParamEnableDisable();
  }

  isDirty() {
    if (!this.selectedRole) return false;
    if (this.roleFormGroup.dirty) return true;
    for (let i = 0; i < this.selectedRole.roleMenus.length; i++) {
      if (this.selectedRole.roleMenus[i].edit.formSelect.dirty) return true;
      if (this.selectedRole.roleMenus[i].edit.formReadOnly.dirty) return true;
    }
    for (let i = 0; i < this.selectedRole.roleMenusMobile.length; i++) {
      if (this.selectedRole.roleMenusMobile[i].edit.formSelect.dirty) return true;
      if (this.selectedRole.roleMenusMobile[i].edit.formReadOnly.dirty) return true;
    }
    if (this.formCtgMinZai.dirty) return true;
    if (this.formCtgMaxZai.dirty) return true;
    if (this.formCtgMinStockDays.dirty) return true;
    if (this.formCtgMaxStockDays.dirty) return true;
    if (this.formItemMinZai.dirty) return true;
    if (this.formItemMaxZai.dirty) return true;
    if (this.formItemMinStockDays.dirty) return true;
    if (this.formItemMaxStockDays.dirty) return true;
        
    return false;
  }

  canDeactivate() {
    if (!this.selectedRole) return true;
    if (!this.isDirty()) return true;

    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

}
