import { Component, OnDestroy, OnInit } from '@angular/core';
import { report } from 'process';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { PbiReportDto, RspReportList } from 'src/app/response/rsp-pbi-report-list';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

@Component({
  selector: 'app-pbi-report-list',
  templateUrl: './pbi-report-list.component.html',
  styleUrls: ['./pbi-report-list.component.css']
})
export class PbiReportListComponent implements OnInit, OnDestroy {

  private subscriptionQuery: Subscription;

  public reportList: PbiReportDto[] = undefined;
  public tableWidth: any;
  public columnIds: string[] = ["reportId", "reportName", "reportType", "datasetId", "embedUrl"];
  public columnDefs: TableColumnDef[] = [
    {columnId:'reportId', header:"レポートID", width:240},
    {columnId:'reportName', header:"レポート名", width:200},
    {columnId:'reportType', header:"タイプ", width:100, align:"center"},
    {columnId:'datasetId', header:"データセットID", width:240},
    {columnId:'embedUrl', header:"Embed URL", width:200}
  ];

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
    ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.calcTableWidth();
    this.getReport();
  }

  ngOnDestroy(): void {
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    for (var colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = {"width": "" + width + "px"};
  }

  getReport() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.pbiReportList().subscribe(
      (response) => {this.receiveReportList(response)},
      (error) => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  reloadReport() {
    this.reportList = undefined;
    this.getReport();
  }

  receiveReportList(response: RspReportList) {
    this.commonService.closeSpinner();
    this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    if (this.httpBasic.handleAppError(response)) return;

    this.reportList = [];
    for (let report of response.reports) {
      this.reportList.push(report);
    }
    console.log(this.reportList);
  }

  styleFor(columnDef:TableColumnDef) {
    return {
      "width": "" + columnDef.width + "px",
      "text-align": columnDef.align ? columnDef.align : "left"
    }
  }

  styleForHeader(columnDef:TableColumnDef) {
    return {
      "width": "" + columnDef.width + "px"
    }
  }
}
