import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { filter } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { TaskDashboardHqComponent } from 'src/app/page/task/task-dashboard-hq/task-dashboard-hq.component';
import { TaskDashboardStoreComponent } from 'src/app/page/task/task-dashboard-store/task-dashboard-store.component';
import { NotificationComponent } from '../notification/notification.component';
import { MenuItemDto } from 'src/app/webservice/menu';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})
export class ToolBarComponent implements OnInit {

  today : Date = new Date();
  currentPath: string;

  @ViewChild(TaskDashboardHqComponent, {static:false}) taskDashboardHqComponent: TaskDashboardHqComponent;
  @ViewChild(TaskDashboardStoreComponent, {static:false}) taskDashboardStoreComponent: TaskDashboardStoreComponent;
  @ViewChild(NotificationComponent, {static:false}) notificationComponent: NotificationComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private router : Router,
    private snackBar: MatSnackBar
    ) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(f => f instanceof NavigationEnd)
    ).subscribe((s: any) => {
        this.currentPath = s.url;       // Url includes query params.
    });
  }

  openMenu(menuItem: MenuItemDto) {
    this.commonService.pageMenuName = menuItem.menuName;
    // this.router.navigate([menuItem.menuPath]);

    this.taskDashboardHqComponent?.closeDashboard();
    // this.taskDashboardStoreComponent?.closeDashboard();
    this.notificationComponent?.closeNotification();

    let url: string[] = menuItem.menuPath.split("?");
    if (url.length == 1) {
      this.router.navigate([url[0]]);
      return;
    }

    let params : string[] = url[1].split("&");
    let queryParams = {};
    for (let param of params) {
      let keyValue = param.split("=");
      queryParams[keyValue[0]] = keyValue[1];
    }

    this.router.navigate([url[0]], {queryParams: queryParams});
  }

  saveInitialPath() {
    this.commonService.userConfig["initialPath"] = this.currentPath;
    this.httpBasic.saveUserConfig("「" + this.commonService.pageTitle + "」を初期画面に設定しました。");
  }

  reload() {
    window.location.reload();
  }
}
