import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Rsp } from 'src/app/response/rsp';
import { MatPaginator } from '@angular/material/paginator';
import { LoginStoreDto } from '../../response/login-store-dto';
import { ReqDownloadExpiryLogToExcel } from 'src/app/request/req-download-expiry-log-to-excel';

export interface ExpiryDto {
  storeCd: string;
  storeName: string;
  logDate: string;
  logTime: string;
  itemCd: string;
  itemName: string;
  logContent: string;
  userId: string;
}

export interface RspExpiryLogSearch extends Rsp {
  rows: ExpiryDto[];
  recordCount: number;
}

@Component({
  selector: 'app-expiry-log',
  templateUrl: './expiry-log.component.html',
  styleUrls: ['./expiry-log.component.css']
})
export class ExpiryLogComponent implements OnInit, OnDestroy {
  public formGroupCond: FormGroup;
  public tableWidth: any;
  public pagenatorMaxWidth: any;
  public recordCount: number = 0;
  public dateBegin: Date;
  public dateEnd: Date;
  public expiryLogList:ExpiryDto[] = [];
  public columnIds: string[] = ["storeCd", "storeName", "logDate", "logTime", "itemCd", "itemName",  "logContent", "userId"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "storeCd", header: "店舗CD", width: 50, align: "center" },
    { columnId: "storeName", header: "店舗名", width: 135, align: "left" },
    { columnId: "logDate", header: "日付", width: 80, align: "center" },
    { columnId: "logTime", header: "時間", width: 60, align: "center" },
    { columnId: "itemCd", header: "商品コード", width: 110, align: "center" },
    { columnId: "itemName", header: "商品名", width: 375, align: "left" },
    { columnId: "logContent", header: "ログ内容", width: 205, align: "left" },
    { columnId: "userId", header: "担当者", width: 140, align: "center" },
  ];
  public storeList: LoginStoreDto[];
  public isDownloadEnabled: boolean = false; // ダウンロードボタンの有効/無効を制御するフラグ
  public selectedStoreValue: string = 'option1'; // プルダウンメニューの初期値

  @ViewChild(DateRangeComponent, { static: false }) dateRangeComponent: DateRangeComponent;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  constructor(
     public commonService: CommonService,
     private httpBasic: HttpBasicService,
     private http: HttpClient,
     private fb: FormBuilder) {
      this.formGroupCond = this.fb.group({
        // storeCd: ['ALL',Validators.required],
        storeCd: ['',Validators.required],
        itemCd: ['',Validators.required],
        filterText: ['',Validators.required],
      })
    }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.dateBegin = new Date();
    //this.dateBegin.setMonth(this.dateBegin.getMonth() - 1);
    this.dateBegin.setDate(this.dateBegin.getDate() - 6);
    this.dateEnd = new Date();
    this.dateEnd.setDate(this.dateEnd.getDate());
    this.calcTableWidth();
    if (this.commonService.stores.length > 1) {
      this.formGroupCond.get("storeCd").setValue('ALL');
      this.selectedStoreValue = 'ALL';
    } else {
      this.formGroupCond.get("storeCd").setValue(this.commonService.stores[0].storeCd);
      this.selectedStoreValue = this.commonService.stores[0].storeCd;
    }
    this.storeList = Array.from(this.commonService.stores);
  }

  ngOnDestroy() {

  }

  onValueChanged(): void {
    // 店舗が選択されたか商品コードが入力されたら、ダウンロードボタンを有効にする
    this.isDownloadEnabled = this.selectedStoreValue !== 'ALL' || this.formGroupCond.get("itemCd").value !== '';
  }

  dateChanged(event){

  }

  doSearch() {
    if (this.matPaginator) this.matPaginator.pageIndex = 0;
    let pageNum = 0;
    let dispNum = this.matPaginator ? this.matPaginator.pageSize : 15;
    this.doSearchBody(pageNum, dispNum);
  }

  doSearchBody(pageNum, dispNum) {
    let dateRage = this.dateRangeComponent.getDateRange();
    let itemWk = this.formGroupCond.get("itemCd").value;
    itemWk = checkItemCD(itemWk);
    //this.formGroupCond.get("itemCd").setValue(itemWk);
    if (itemWk == '') itemWk = 'ALL';
    let request= {
      access: this.commonService.loginUser,
      storeCd: this.formGroupCond.get("storeCd").value,
      itemCd: itemWk,
      startDateFd: this.commonService.formatDate(dateRage.dateBegin),
      finishDateFd: this.commonService.formatDate(dateRage.dateEnd),
      page: {
        pageNum: pageNum,
        dispNum: dispNum
      }
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("getExpiryLog", request).subscribe(
      (response: RspExpiryLogSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveSearch(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSearch(response: RspExpiryLogSearch) {
    if (this.httpBasic.handleAppError(response)) return;
    this.expiryLogList = [];
    this.recordCount = response.recordCount;
    if (this.recordCount > 0) {
      response.rows?.forEach(row => {
        let expiry = {
          storeCd:    row.storeCd,
          storeName:  row.storeName,
          itemCd:     row.itemCd,
          itemName:   row.itemName,
          logDate:    this.commonService.formatDate(new Date(row.logDate)),
          logTime:    row.logTime,
          logContent: row.logContent,
          userId:     row.userId,
        }
        this.expiryLogList.push(expiry);
      })
    }
  }

  doOutputExcel() {
    this.doOutputFile("Excel");
  }

  doOutputCsv() {
    this.doOutputFile("CSV");
  }

  doOutputFile(qid: string) {
    let itemWk = this.formGroupCond.get("itemCd").value;
    let storeWk = this.formGroupCond.get("storeCd").value;
    if (itemWk == '' && storeWk == 'ALL') {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, "店舗または商品を指定してください。")
      return;
    }
    itemWk = checkItemCD(itemWk);
    if (itemWk == '') itemWk = 'ALL';
    let dateRage = this.dateRangeComponent.getDateRange();
    let frdt = this.commonService.formatDate(dateRage.dateBegin);
    let todt = this.commonService.formatDate(dateRage.dateEnd);

    let qid0 = qid;
    if (qid0 == "Excel") { qid0 = "itemExpiry/getExpiryLogData"; }
    let req: ReqDownloadExpiryLogToExcel = {
      access: this.commonService.loginUser,
      queryId: qid,
      bindVariables: [
        frdt,
        todt,
        this.formGroupCond.get("storeCd").value,
        itemWk
      ]
    };
    let infoText = 'ログデータをダウンロードします。';
    const dialogRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, infoText);

    const url = this.commonService.config.wsBaseUrl + "DownloadExpiryLogToExcel";
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    //this.http.request('POST', url, { headers: headers, body: req, responseType: 'blob', observe: 'response' })
    //let subsc = this.httpBasic.generalRequest("DownloadExpiryLogToExcel", req)
    //this.http.post(url, req, { headers: headers, responseType: 'blob', observe: 'response' })
    this.http.post(url, req, { headers: headers, responseType: 'blob', observe: 'response' })
      .subscribe((response: HttpResponse<Blob>) => {
        let filename = '';
        let stype = '';
        if (qid == "Excel") {
          filename = 'EXPIRY_LOG_DATA.xlsx';
          stype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else {
          filename = 'EXPIRY_LOG_DATA.csv';
          stype = 'application/text';
        }
        const blob = new Blob([response.body], { type: stype });
        this.commonService.downloadBlob(blob, filename);
        this.commonService.closeSpinnerForSubComp(dialogRef);
      }, (error) => {
        if (error.name === 'TimeoutError') {
          this.commonService.openErrorDialog(this.commonService.pageTitle, "タイムアウト エラー ");
        } else {
          this.commonService.openErrorDialog(this.commonService.pageTitle, "報告 エラー :" + error.name);
        }
        this.commonService.closeSpinnerForSubComp(dialogRef);
      })
  }

  pageChanged() {
    if (this.expiryLogList == undefined || this.expiryLogList.length == 0) return;
    let pageNum =  this.matPaginator.pageIndex;
    let dispNum = this.matPaginator.pageSize;
    this.doSearchBody(pageNum, dispNum);
  }

  calcTableWidth() {
    let width = 1;   // For left border
    for (let colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { 'width': '' + width + 'px' };
    this.pagenatorMaxWidth = { 'max-width': '' + width + 'px' };
  }

  //filterText: string = '';
  filterItems(filterValue: string): void {
    filterValue = filterValue.toLowerCase();
    this.storeList = this.commonService.stores.filter(store =>
      (store.storeName + store.storeCd).toLowerCase().includes(filterValue)
    );
  }

}

function checkChar(txt: String): String {
  var strRet = '';
  for(var i=0 ; i < txt.length ; i++){
      if((txt.charAt(i)>= '0' && txt.charAt(i)<= '9') 
      || (txt.charAt(i)>= 'a' && txt.charAt(i)<= 'z')
      || (txt.charAt(i)>= 'A' && txt.charAt(i)<= 'Z')
      || txt.charAt(i)== '-'
      || txt.charAt(i)== '?'){
        strRet += txt.charAt(i);
      }
  }
  return strRet;
}

function checkItemCD(txt: String): String {
  var strWork = '';
  for(var i=0 ; i < txt.length ; i++){
    var c1 = txt.charAt(i);
      if((c1>= '0' && c1<= '9') 
      || c1== ' '
      || c1== ','
      || c1== '-'
      || c1== '?'){
        strWork += c1;
      }
      if(txt.charAt(i)== '\t'){
        strWork += ' ';
      }
  }
  strWork += '';

  return strWork;
}
