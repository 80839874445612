import { DecimalPipe, formatNumber } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { GraphOption, GraphService, GraphType } from 'src/app/service/graph.service';
import { AnalysisContext, MatrixType } from '../../analysis-context';

@Component({
  selector: 'app-analysis-view-standard',
  templateUrl: './analysis-view-standard.component.html',
  styleUrls: ['./analysis-view-standard.component.css']
})
export class AnalysisViewStandardComponent implements OnInit, OnDestroy {

  public formAnalysisName: FormControl = new FormControl(0);
  public formSwitchTable: FormControl = new FormControl(false);
  public formTableType: FormControl = new FormControl("list");
  // public formMatrixItem: FormControl = new FormControl("");
  public formSwitchGraph: FormControl = new FormControl(false);

  public selectedContext: AnalysisContext;
  public prevContext: AnalysisContext;
  public matrixTableType: string;
  public matrixTableTypeDef: MatrixType;
  public matrixData: Object[];
  public matrixDataDisp: Object[];
  public matrixColDef: TableColumnDef[];
  public matrixXAxisColDef: TableColumnDef;
  public matrixDisplayColumn: string[];
  public matrixColHeaderVertical: boolean = false;
  public graphDataGroupedByItem: any[];
  public graphDataGroupedByXAxis: any[];
  public graphDataNoGroupe: any[];
  public graphView: any;
  public graphOption: GraphOption;
  public showGraph: boolean = true;

  public GraphService = GraphService;

  private subscriptionContext: Subscription;
  private subscriptionSwitchTable: Subscription;
  private subscriptionSwitchGraph: Subscription;
  private subscriptionTableType: Subscription;

  private viewWidth: number;
  private viewHeight: number;
  private viewTableHeight: number;
  private viewGraphHeight: number;
  
  @Input("showView") showView: boolean;
  @Input("contextList") contextList: AnalysisContext[];
  @Input("viewSizeWidth") inWidth: number
  @Input("viewSizeHeight") inHeight: number
  @Input("position") position: string

  constructor(
    public commonService: CommonService,
    private graphService: GraphService,
    private cd: ChangeDetectorRef,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit(): void {
    this.formSwitchTable.disable();
    this.formTableType.disable();
    this.formSwitchGraph.disable();

    this.subscriptionContext = this.formAnalysisName.valueChanges.subscribe((value) => {this.contextChanged(value);});
    this.subscriptionSwitchTable = this.formSwitchTable.valueChanges.subscribe((value) => {this.switchTable(value);});
    this.subscriptionTableType = this.formTableType.valueChanges.subscribe((value) => {this.tableTypeChanged(value);});
    this.subscriptionSwitchGraph = this.formSwitchGraph.valueChanges.subscribe((value) => {this.switchGraph(value);});

    this.setViewSize(this.inWidth, this.inHeight);
    this.viewCalcHeight();
  }

  ngOnDestroy(): void {
    this.unsubsc(this.subscriptionSwitchTable);
    this.unsubsc(this.subscriptionTableType);
  }

  unsubsc(subsc: Subscription) {
    if (subsc) subsc.unsubscribe();
  }

  setViewSize(width: number, height: number) {
    this.viewWidth = width - 5 * 2 - 10;
    this.viewHeight = height - 5 * 2 - 26;
    this.viewCalcHeight();
  }

  viewCalcHeight() {
    if (this.formAnalysisName.value == 0) {
      this.viewTableHeight = 0;
      this.viewGraphHeight = 0;
      this.graphView = [this.viewWidth, this.viewGraphHeight];
      return;
    }
    if (this.formSwitchTable.value) {
      if (this.formSwitchGraph.value) {
        this.viewTableHeight = Math.floor(this.viewHeight / 2) - 5;   // 5 for top margin of table-container
      } else {
        this.viewTableHeight = this.viewHeight - 5;                   // 5 for top margin of table-container
      }
    } else {
      this.viewTableHeight = 0;
    }
    if (this.formSwitchGraph.value) {
      this.showGraph = false;
    } else {
      this.viewGraphHeight = 0;
      this.graphView = [this.viewWidth, this.viewGraphHeight];
    }
    setTimeout(()=>{this.viewRecalcHeight();}, 0);
  }

  viewRecalcHeight() {
    let elem = document.getElementById("graph-container-" + this.position);
    let newHeight;
    if (!elem) {
      newHeight = 0;
    } else {
      newHeight = elem.clientHeight;
    }
    /*
    if (this.graphOption && this.graphOption.legendPosition && this.graphOption.legendPosition == "below") {
      newHeight -= 56;
    }
    */
    if (!this.graphView || this.viewWidth != this.graphView[0] || newHeight != this.graphView[1]) {
      this.viewGraphHeight = newHeight;
      this.graphView = [this.viewWidth, this.viewGraphHeight];
    }
    // this.graphView = [this.viewWidth, this.viewGraphHeight];
    this.showGraph = true;
  }

  editGraphOption() {
    if (!this.selectedContext) return;
    /*
    let subscriptionGraphOption = this.commonService.openGraphOptionDialog(this.graphOption, this.matrixTableTypeDef.dataHeaderCols.length).subscribe(
      data => {
        subscriptionGraphOption.unsubscribe();
        this.viewCalcHeight()}
    );
    */
    this.commonService.openGraphOptionDialog(this.graphOption, this.matrixDataDisp.length);
  }

  findContext(contextNo: number) {
    for (let context of this.contextList) {
      if (context.analysisNo == contextNo) return context;
    }
    return undefined;
  }

  cleanUpMatrixAndGraph() {
    this.matrixTableType = undefined;
    this.matrixTableTypeDef = undefined;
    this.matrixData = undefined;
    this.matrixDataDisp = undefined;
    this.matrixColDef = undefined;
    this.matrixXAxisColDef = undefined;
    this.matrixDisplayColumn = undefined;
    this.graphDataGroupedByItem = undefined;
    this.graphDataGroupedByXAxis = undefined;
    this.graphDataNoGroupe = undefined;
  }

  cleanUpPrevData() {
    this.cleanUpMatrixAndGraph();
    this.formTableType.setValue("list");
    this.formTableType.disable();
    this.formSwitchTable.setValue(false);
    this.formSwitchTable.disable();
    this.formSwitchGraph.setValue(false);
    this.formSwitchGraph.disable();
}

  switchTable(value: boolean) {
    if (value) {
      this.formTableType.enable();
    } else {
      this.formTableType.disable();
    }
    this.viewCalcHeight();
  }

  switchGraph(value: boolean) {
    this.viewCalcHeight();
  }

  switchColHeaderVirtical() {
    this.matrixColHeaderVertical = !this.matrixColHeaderVertical;
    this.viewCalcHeight();
  }

  contextChanged(contextNo) {
    this.cleanUpPrevData();
    let no = parseInt(contextNo);

    if (no == 0) {
      this.selectedContext = undefined;
      return;
    }

    let context = this.findContext(no);
    if (context) {
      this.selectedContext = context;
      this.buildMatrixData(context.matrixTypes[0].id);
      this.buildGraphData();
      this.graphOption = {...context.graphOption};
      this.graphOption.legendPosition = "right";
      this.formSwitchTable.setValue(true);
      this.formSwitchTable.enable();
      this.formSwitchGraph.setValue(false);
      this.formSwitchGraph.enable();
    }
  }

  tableTypeChanged(value: string) {
    if (!this.selectedContext) return;
    if (value != "list") {
      this.cleanUpMatrixAndGraph();
      this.buildMatrixData();
      this.buildGraphData();

      // Correct graph type when current value is not selectable
      let graphTypes: GraphType[] = this.graphService.selectableGraphTypes(this.matrixDataDisp.length);
      if (!graphTypes.find(v => v.id === this.graphOption.graphType)) {
        if (this.matrixTableTypeDef.graphType) {
          this.graphOption.graphType = this.matrixTableTypeDef.graphType;
        } else {
          this.graphOption.graphType = graphTypes[0].id;
        }
      }

      if (this.matrixTableTypeDef.xAxisLabel) {
        this.graphOption.xAxisLabel = this.matrixTableTypeDef.xAxisLabel;
      }
      if (this.matrixTableTypeDef.yAxisLabel) {
        this.graphOption.yAxisLabel = this.matrixTableTypeDef.yAxisLabel;
      }
    }
    this.viewCalcHeight();
  }

  buildMatrixData(tableType?: string) {
    if (!tableType) tableType = this.formTableType.value;
    if (tableType == "list") return;
    if (this.matrixTableType === tableType) return;

    if (this.selectedContext.dimension == 1) {
      this.matrixTableTypeDef = this.getMatrixTableDef(tableType);
      if (!this.matrixTableTypeDef) {
        console.log("MatrixType not found : " + tableType);
        return;
      }
      this.buildMatrixDataDimension1(tableType);
      return;
    }

    this.matrixTableTypeDef = this.getMatrixTableDef(tableType);
    if (!this.matrixTableTypeDef) {
      console.log("MatrixType not found : " + tableType);
      return;
    }
    this.buildMatrixDataDimensionN(tableType);
  }

  getColumnId(no: number) {
    return "__col-" + no;
  }

  getMatrixTableDef(tableType: string): MatrixType {
    return this.selectedContext.matrixTypes.find(v => v.id === tableType);
  }

  getColDefById(id: string): TableColumnDef {
    return this.selectedContext.listColDef.find(v => v.columnId === id);
  }

  getColIdByHeader(header: string, colDefs: TableColumnDef[]) {
    let colDef = colDefs.find(v => v.header === header);
    if (colDef) return colDef.columnId;
    return undefined;
  }

  compareFunc(a:Object, b:Object, colIds: string[]): number {
    for (let id of colIds) {
      if (a[id] === b[id]) continue;    // Compare next column
      if (a[id] < b[id]) {
        return -1;
      }
      return 1;
    }
    return 0;
  }

  sortListData(target: Object[], colIds: string[]) {
    target.sort(function(a, b): number {
      for (let id of colIds) {
        if (a[id] === b[id]) continue;    // Compare next column
        if (a[id] < b[id]) {
          return -1;
        }
        return 1;
      }
      return 0;
    });
  }

  addMatrixColumn(colId: string, colHeader: string, listColDef: TableColumnDef) {
    let colDef = this.matrixColDef.find(v => v.columnId === colId);
    if (!colDef) {
      this.matrixDisplayColumn.push(colId);
      let matrixColDef = {...listColDef};
      matrixColDef.columnId = colId;
      matrixColDef.header = colHeader;
      this.matrixColDef.push(matrixColDef);
      return;
    }
  }

  findMatrixRow(data: Object[], id: string) {
    return data.find(v => v["__itemId"] === id);
  }

  getMaxWidth(colIds: string[]) {
    let maxWidth = {width: 0, headerWidth: 0, dataWidth: 0};
    for (let id of colIds) {
      let def = this.selectedContext.listColDef.find(v => v.columnId === id);
      if (!def) {
        console.log("TableColumn definition not found: " + id);
        continue;
      }
      if (maxWidth.width < def.width) maxWidth.width = def.width;
      if (maxWidth.headerWidth < def.headerWidth ? def.headerWidth : def.width) maxWidth.headerWidth = def.headerWidth ? def.headerWidth : def.width;
      if (maxWidth.dataWidth < def.dataWidth ? def.dataWidth : def.width) maxWidth.dataWidth = def.dataWidth ? def.dataWidth : def.width;
    }
    return maxWidth;
  }

  buildMatrixDataDimension1(tableType: string) {
    this.matrixData = [];
    this.matrixDataDisp = [];
    this.matrixColDef = [];
    this.matrixDisplayColumn = [];

    let maxWidth = this.getMaxWidth(this.matrixTableTypeDef.dataHeaderCols);
    let xAxisColDef = this.selectedContext.listColDef.find(v => v.columnId === this.matrixTableTypeDef.xAxisCol);
    if (!xAxisColDef) {
      console.log("TableColumn definition not found: " + this.matrixTableTypeDef.xAxisCol);
      return;
    }

    let colDef = {
      columnId: "__itemName",
      header: "項目",
      width: maxWidth.width,
      headerWidth: maxWidth.headerWidth,
      dataWidth: maxWidth.dataWidth,
      aligh: "left",
      rowHeaderCol: true};
    this.addMatrixColumn("__itemName", "項目", colDef);

    for (let colId of this.matrixTableTypeDef.dataHeaderCols) {
      let colDef = this.getColDefById(colId);
      if (!colDef) {
        console.log("TableColumn definition not found : " + colId);
        continue;
      }
      this.matrixData.push({
        "__itemId": colDef.columnId,
        "__itemName": colDef.header
      });
    }

    // add data column and assign value
    let colNo = 1;
    for (let listRow of this.selectedContext.listData) {
      let colId = this.getColumnId(colNo++);
      let colHeader = listRow[this.matrixTableTypeDef.xAxisCol];   // assign cell value to column header
      for (let listColId of this.matrixTableTypeDef.dataHeaderCols) {
        let listColDef = this.getColDefById(listColId);
        if (!listColDef) {
          console.log("TableColumn definition not found : " + listColId);
          continue;
        }
        let colDef = {...listColDef};
        colDef.headerWidth = xAxisColDef.dataWidth ? xAxisColDef.dataWidth : xAxisColDef.width;
        colDef.dataWidth = maxWidth.dataWidth;
        colDef.numberPipe = undefined;
        this.addMatrixColumn(colId, colHeader, colDef);
        let row = this.findMatrixRow(this.matrixData, listColId);
        if (row) {
          row[colId] = listRow[listColId];
        } else {
          console.log("MatrixRow not found : " + listColId);
        }
      }
    }

    /* set empty string to undefined cell */
    /*
    for (let matrixRow of this.matrixData) {
      for (let colId of this.matrixDisplayColumn) {
        if (matrixRow[colId] == undefined) matrixRow[colId] = "";
      }
    }
    */

    // Copy matrixData to matrixDataDisp for converting number to formated string
    this.matrixDataDisp = [];
    for (let row of this.matrixData) {
      // let itemName = row["__itemName"];
      // let listColDef = this.getColDefByHeader(itemName, this.selectedContext.listColDef);
      let itemId = row["__itemId"];
      let listColDef = this.selectedContext.listColDef.find(v => v.columnId === itemId);
      let newRow = {...row};
      for (let i = 1; i < this.matrixColDef.length; i++) {
        if (newRow[this.matrixColDef[i].columnId] == undefined) {
          newRow[this.matrixColDef[i].columnId] = "";
          continue;
        }
        if (listColDef.numberPipe && listColDef.numberPipe !== "") {
          newRow[this.matrixColDef[i].columnId] = "" + this.decimalPipe.transform(newRow[this.matrixColDef[i].columnId], listColDef.numberPipe);
        } else {
          newRow[this.matrixColDef[i].columnId] = "" + this.decimalPipe.transform(newRow[this.matrixColDef[i].columnId]);
        }
      }
      this.matrixDataDisp.push(newRow);
    }
    this.matrixTableType = tableType;
  }

  buildMatrixDataDimensionN(tableType: string) {
    this.matrixData = [];
    this.matrixDataDisp = [];
    this.matrixColDef = []
    this.matrixDisplayColumn = [];

    if (!this.selectedContext.listData) return;
    if (this.selectedContext.listData.length == 0) return;

    let maxWidth = this.getMaxWidth(this.matrixTableTypeDef.dataHeaderCols);    // get Max width of data columns
    let xAxisColDef = this.selectedContext.listColDef.find(v => v.columnId === this.matrixTableTypeDef.xAxisCol);
    if (!xAxisColDef) {
      console.log("TableColumn definition not found: " + this.matrixTableTypeDef.xAxisCol);
      return;
    }

    for (let colId of this.matrixTableTypeDef.rowHeaderCols) {
      let listColDef = this.getColDefById(colId);
      if (!listColDef) {
        console.log("TableColumn definition not found : " + colId);
        continue;
      }
      let colDef: TableColumnDef = {...listColDef};
      colDef.rowHeaderCol = true;
      this.addMatrixColumn(colDef.columnId, colDef.header, colDef);
    }

    let itemColDef: TableColumnDef = {
      columnId: "__itemName",
      header: "項目",
      width: maxWidth.width,
      headerWidth: maxWidth.headerWidth,
      dataWidth: maxWidth.dataWidth,
      align: "left",
      rowHeaderCol: true
    };
    this.addMatrixColumn("__itemName", "項目", itemColDef);

    // get all xAxis Data
    let xAxisData: {id: string, value: string}[] = [];
    let xSortedData = [...this.selectedContext.listData];
    this.sortListData(xSortedData, [this.matrixTableTypeDef.xAxisCol]);
    let xMaxValue = "";
    let colNo = 1;
    for (let row of xSortedData) {
      if (row[this.matrixTableTypeDef.xAxisCol] <= xMaxValue) continue;
      xMaxValue = row[this.matrixTableTypeDef.xAxisCol];
      let colId = this.getColumnId(colNo++);
      xAxisData.push({id: colId, value: xMaxValue});

      let colDef = {
        columnId: colId,
        header: xMaxValue,
        width: xAxisColDef.width > maxWidth.width ? xAxisColDef.width : maxWidth.width,
        headerWidth: xAxisColDef.dataWidth ? xAxisColDef.dataWidth : xAxisColDef.width,
        dataWidth: maxWidth.dataWidth,
        align: "right"};
      this.matrixColDef.push(colDef);
      this.matrixDisplayColumn.push(colId);
    }

    // Sort data by row header columns
    let sortedData = [...this.selectedContext.listData];
    this.sortListData(sortedData, this.matrixTableTypeDef.rowHeaderCols);

    // Get distinct values of row header columns and build all matrix rows
    let maxValue = {};
    for (let col of this.matrixTableTypeDef.rowHeaderCols) {
      maxValue[col] = "";
    }
    for (let row of sortedData) {
      if (this.compareFunc(row, maxValue, this.matrixTableTypeDef.rowHeaderCols) <= 0) continue;
      maxValue = row;
      let newRowBase = {};
      for (let col of this.matrixTableTypeDef.rowHeaderCols) {
        newRowBase[col] = maxValue[col];
      }
      newRowBase["__itemName"] = "";
      for (let col of xAxisData) {
        // newRowBase[col] = [];                                  // For summary func
        newRowBase[col.id] = "";
      }
      for (let colId of this.matrixTableTypeDef.dataHeaderCols) {
        let listColDef = this.getColDefById(colId);
        if (!listColDef) {
          console.log("TableColumn definition not found : " + colId);
          continue;
        }
        let newRow = {...newRowBase};
        newRow["__itemId"] = listColDef.columnId;
        newRow["__itemName"] = listColDef.header;
        this.matrixData.push(newRow);
      }
    }

    // Assign data column value
    let compareCols = [...this.matrixTableTypeDef.rowHeaderCols, "__itemName"];
    let marixDataStartIndex = 0;
    for (let listRow of sortedData) {
      for (let i = marixDataStartIndex; i < this.matrixData.length; i++) {
        let matrixRow = this.matrixData[i];
        let comp = this.compareFunc(listRow, matrixRow, this.matrixTableTypeDef.rowHeaderCols);
        if (comp < 0) {
          // listRow < matrixRow: go to next list row
          // marixDataStartIndex = i;
          break;
        }
        if (comp > 0) {
          // listRow > matrixRow: restart matrixData loop
          marixDataStartIndex += this.matrixTableTypeDef.dataHeaderCols.length;
          i = marixDataStartIndex - 1;
          continue;
        }
        let xAxisColId = this.getColIdByHeader(listRow[this.matrixTableTypeDef.xAxisCol], this.matrixColDef);
        let dataColId = matrixRow["__itemId"];
        if (listRow[dataColId]) {
          matrixRow[xAxisColId] = listRow[dataColId];
        }
      }
    }

    // Copy matrixData to matrixDataDisp for converting number to formatted string
    this.matrixDataDisp = [];
    for (let row of this.matrixData) {
      let itemId = row["__itemId"];
      let listColDef = this.selectedContext.listColDef.find(v => v.columnId === itemId);
      let newRow = {...row};
      let startIndex = this.matrixTableTypeDef.rowHeaderCols.length + 1;
      for (let i = startIndex; i < this.matrixColDef.length; i++) {
        if (newRow[this.matrixColDef[i].columnId] === "") continue;
        if (listColDef.numberPipe && listColDef.numberPipe !== "") {
          newRow[this.matrixColDef[i].columnId] = "" + this.decimalPipe.transform(newRow[this.matrixColDef[i].columnId], listColDef.numberPipe);
        } else {
          newRow[this.matrixColDef[i].columnId] = "" + this.decimalPipe.transform(newRow[this.matrixColDef[i].columnId]);
        }
      }
      this.matrixDataDisp.push(newRow);
    }

    this.matrixTableType = tableType;
  }

  buildGraphData() {
    if (!this.matrixData) return;
    this.graphDataGroupedByItem = this.graphService.buildGraphDataGroupedByItemName(this.matrixTableTypeDef, this.matrixData, this.matrixColDef);
    this.graphDataGroupedByXAxis = this.graphService.buildGraphDataGroupedByXAxis(this.matrixTableTypeDef, this.matrixData, this.matrixColDef);
    if (this.matrixDataDisp.length == 1) {
      this.graphDataNoGroupe = this.graphService.buildGraphDataNoGroupe(this.matrixTableTypeDef, this.matrixData, this.matrixColDef);
    }
  }

  styleForTable() {
    return {"max-height": "" + this.viewTableHeight + "px"}
  }

  styleForGraph() {
    return {"height": "" + this.viewGraphHeight + "px"}
  }


  styleFor(colDef: TableColumnDef) {
      return {
        "width": "" + colDef.width + "px",
        "text-align": colDef.align ? colDef.align : "left"
      }
  }
  styleForMatrix(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.dataWidth + "px",
      "min-width": "" + colDef.dataWidth + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
    };
  }
  styleForMatrixHeader(colDef: TableColumnDef) {
    if (!this.matrixColHeaderVertical) {
      return {
        "width": "" + Math.max(colDef.headerWidth, colDef.dataWidth) + "px",
        "min-width": "" + Math.max(colDef.headerWidth, colDef.dataWidth) + "px"
      };
    }

    let xAxisDef = this.getColDefById(this.matrixTableTypeDef.xAxisCol);
    return {
      "width": "" + colDef.dataWidth + "px",
      "min-width": "" + colDef.dataWidth + "px",
      "height": "" + (xAxisDef.dataWidth ? xAxisDef.dataWidth + 4: xAxisDef.width) + "px"
    };
  }

  styleForMatrixHeaderContentContainer(colDef: TableColumnDef) {
    if (!this.matrixColHeaderVertical || colDef.rowHeaderCol) {
      return {
        "width": "" + Math.max(colDef.headerWidth, colDef.dataWidth) + "px",
        "min-width": "" + colDef.dataWidth + "px",
        };
    }

    return {
      "width": "" + colDef.dataWidth + "px",
      "min-width": "" + colDef.dataWidth + "px",
      "transform": "rotate(-90deg)"
    };
  }

  styleForMatrixHeaderContent(colDef: TableColumnDef) {
    if (!this.matrixColHeaderVertical || colDef.rowHeaderCol) {
      return {
        "width": "" + Math.max(colDef.headerWidth, colDef.dataWidth) + "px",
        "min-width": "" + colDef.dataWidth + "px"
        };
    }

    let xAxisDef = this.getColDefById(this.matrixTableTypeDef.xAxisCol);
    let cellHeight = xAxisDef.dataWidth ? xAxisDef.dataWidth + 4: xAxisDef.width;

    let style = {
      "width": "" + cellHeight + "px",
      "min-width": "" + cellHeight + "px",
      // "text-aligh": "left !important"         ngSty;e does not support !important
    };

    if (colDef.dataWidth < cellHeight) {
      let move = (colDef.dataWidth - cellHeight) / 2;
      style["transform"] = `translateX(${move}px)`
    }

    return style;
  }

  copyTable(prefix: string) {
    this.commonService.getCsvFromTable("table-" + this.position);
  }

}
