import { RspOrderStopTermStoreDto } from '../../response/rsp-order-stop-term-store';

export class OrderStopTermStoreRec {
    storeCdFv: string;
    storeNameFv: string;
    dateTypeFn: number;
    dateBeginFv: string;
    dateEndFv: string;

    storeFv: string;
    dateTypeFv: string;

    constructor(rspOrderStopTermStoreDto: RspOrderStopTermStoreDto) {
        for (var key of Object.keys(rspOrderStopTermStoreDto)) {
            this[key] = rspOrderStopTermStoreDto[key];
        }
        if (this.storeCdFv != null)
            this.storeFv = this.storeCdFv + ":" + this.storeNameFv;
        if (this.dateTypeFn != null)
            this.setDateType(this.dateTypeFn)
    }

    setDateType(type: number) {
        if (type == 1) {
            this.dateTypeFv = "発注日";
        } else {
            this.dateTypeFv = "納品日";
        }
    }
}