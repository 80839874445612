import { RspAllStoreParamItemSearch } from '../../response/rsp-all-store-param-item-search';
import { ParamCtgEditDto } from '../../request/req-param-ctg-edit';
import { RspParamCtgSearch } from 'src/app/response/rsp-param-ctg-search';
import { ReqAllStoreParamCtgSearchDto } from '../../request/req-all-store-param-ctg-search';
import { CtgParamRec } from 'src/app/common/ctg-param-rec';
import { NumberKeypadComponent } from '../../partsCommon/number-keypad/number-keypad.component';
import { HttpBasicService } from '../../service/http-basic.service';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { CommonService } from 'src/app/service/common.service';
import { ReqAllStoreParamCtgEdit } from 'src/app/request/req-all-store-param-ctg-edit';

@Component({
  selector: 'app-all-store-param-ctg',
  templateUrl: './all-store-param-ctg.component.html',
  styleUrls: ['./all-store-param-ctg.component.css']
})
export class AllStoreParamCtgComponent implements OnInit, OnDestroy {

  public APPLY_LOWER_NON_DIRTY: number = 1;
  public APPLY_LOWER_ALL: number = 2;
  public APPLY_LOWER_NONE: number = 3;
  public opt_non_dirty_label: string;
  public opt_all_label: string;
  public opt_none_label: string;

  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public ctgList: CtgParamRec[] = [];
  public ctgChangedList: ParamCtgEditDto[] = [];
  public displayColumn: string[] = [
    "store", "minZai", "maxZai", "minStockDays", "maxStockDays"
  ];

  //public storeForm: FormControl = new FormControl(this.commonService.loginUser.storeCd);
  public rankForm: FormControl = new FormControl(this.commonService.config.ranks[0] || "");
  public optionForm: FormControl = new FormControl("" + this.APPLY_LOWER_ALL);
  public storeCdForm: FormControl = new FormControl(this.commonService.loginUser.storeCd);
  public minZaiForm: FormControl = new FormControl("");
  public maxZaiForm: FormControl = new FormControl("");
  public minStockDaysForm: FormControl = new FormControl("");
  public maxStockDaysForm: FormControl = new FormControl("");
  public formGroup: FormGroup;
  private formOriginValue: FormControl[];
  public formSetAllCtg: FormGroup;

  public ctg0Selected: string;
  public ctg1Selected: string;
  public ctg2Selected: string;
  public ctg3Selected: string;
  public rankFormSelected: string;

  public isChangedForm: boolean;
  public errorInputMessages: String[];
  public errorSetAllMessages: String[];

  //private subscriptionStore: Subscription;
  private subscriptionSearch: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscriptionFormSetAllCtg: Subscription;
  private subscriptionConfirm: Subscription;

  private configAllStoreParamCtg = {
    minZai: this.commonService.config.paramCatMinZaiRange,
    maxZai: this.commonService.config.paramCatMaxZaiRange,
    minStockDays: this.commonService.config.paramCatMinStockDaysRange,
    maxStockDays: this.commonService.config.paramCatMaxStockDaysRange,
  }

  private keypadTargetId: string;

  @ViewChild(CtgSelectComponent, { static: true }) public ctgSelectComponent: CtgSelectComponent;
  @ViewChild(NumberKeypadComponent, { static: false }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild('allStoreParamCtgTable', { static: false }) allStoreParamCtgTable: ElementRef;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
  ) {
    // this.formGroup = fb.group({});
  }

  ngOnInit() {
    // this.commonService.pageTitle = '全店パラメータ(カテゴリ)';
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.ctgSelectCondition.storeCd = ""; //this.commonService.loginUser.storeCd;
    // this.subscriptionStore = this.storeForm.valueChanges.subscribe(
    //   (storeCd) => this.storeChanged(storeCd)
    // );
    this.isChangedForm = false;
    this.initFormSetAllCtg();
  }

  ngOnDestroy() {
    //if (this.subscriptionStore) this.subscriptionStore.unsubscribe();
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionFormSetAllCtg) this.subscriptionFormSetAllCtg.unsubscribe();
  }

  // storeChanged(storeCd: string) {
  //   this.ctgSelectComponent.setStoreCd(storeCd);
  // }

  doQuery() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.setFormData();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.setFormData();
        }
      }
    );
  }

  setFormData() {
    this.ctg0Selected = this.ctgSelectCondition.ctg0Name;
    this.ctg1Selected = this.ctgSelectCondition.ctg1Name;
    this.ctg2Selected = this.ctgSelectCondition.ctg2Name;
    this.ctg3Selected = this.ctgSelectCondition.ctg3Name;

    /* request param */
    var request: ReqAllStoreParamCtgSearchDto;
    request = {
      storeCdFv: "", //this.storeCdForm.value,
      ctgLevelFn: this.ctgSelectCondition.formGroup.get("ctgLevel").value,
      ctgCd0Fv: this.ctgSelectCondition.formGroup.get("ctgCd0").value,
      ctgCd1Fv: this.ctgSelectCondition.formGroup.get("ctgCd1").value,
      ctgCd2Fv: this.ctgSelectCondition.formGroup.get("ctgCd2").value,
      ctgCd3Fv: this.ctgSelectCondition.formGroup.get("ctgCd3").value,
      rankFv: this.rankForm.value
    };

    /* query and init form */
    this.subscriptionSearch = this.httpBasic.allStoreParamCtgSearch(request)
      .subscribe(
        data => {
          this.getAllStoreParamCtg(data);
          this.scrollTopTable();
        },
        error => this.httpBasic.handleError(error)
      );

    this.commonService.openSpinner("全店カテゴリ・パラメータ", "検索中・・・");

    let ctgName = this.ctg0Selected;
    if (this.ctg1Selected && this.ctg1Selected !== "") ctgName = this.ctg1Selected;
    if (this.ctg2Selected && this.ctg2Selected !== "") ctgName = this.ctg2Selected;
    if (this.ctg3Selected && this.ctg1Selected !== "") ctgName = this.ctg3Selected;
    this.rankFormSelected = this.rankForm.value;

    this.opt_non_dirty_label = "「 " + ctgName + " 」 以下のカテゴリ（本日未修正）に適用";
    this.opt_all_label = "「 " + ctgName + " 」 以下のカテゴリ（すべて）に適用";
    this.opt_none_label = "「 " + ctgName + " 」 カテゴリのみに適用";
  }

  initFormGroup() {
    this.formGroup = this.fb.group({});

    for (let ctg of this.ctgList) {
      let fc: FormControl;
      fc = (ctg.minZaiCurrent == 0 || ctg.minZaiCurrent == null) ? new FormControl("") : new FormControl(ctg.minZaiCurrent);
      this.formGroup.addControl(ctg.storeCdFv + "-minZai", fc);
      fc = (ctg.maxZaiCurrent == 0 || ctg.maxZaiCurrent == null) ? new FormControl("") : new FormControl(ctg.maxZaiCurrent);
      this.formGroup.addControl(ctg.storeCdFv + "-maxZai", fc);
      fc = (ctg.minStockDaysCurrent == 0 || ctg.minStockDaysCurrent == null) ? new FormControl("") : new FormControl(ctg.minStockDaysCurrent);
      this.formGroup.addControl(ctg.storeCdFv + "-minStockDays", fc);
      fc = (ctg.maxStockDaysCurrent == 0 || ctg.maxStockDaysCurrent == null) ? new FormControl("") : new FormControl(ctg.maxStockDaysCurrent);
      this.formGroup.addControl(ctg.storeCdFv + "-maxStockDays", fc);
    }

    this.formOriginValue = this.formGroup.value;
    this.handlerChangedForm()
    this.setErrorInputMessages();
    this.keypadTargetId = undefined;
  }

  initFormSetAllCtg() {
    this.formSetAllCtg = this.fb.group({});
    this.formSetAllCtg.addControl("minZaiForm", this.minZaiForm);
    this.formSetAllCtg.addControl("maxZaiForm", this.maxZaiForm);
    this.formSetAllCtg.addControl("minStockDaysForm", this.minStockDaysForm);
    this.formSetAllCtg.addControl("maxStockDaysForm", this.maxStockDaysForm);

    this.errorSetAllMessages = []
    this.subscriptionFormSetAllCtg = this.formSetAllCtg.valueChanges.subscribe(
      () => this.setErrorSetAllMessages()
    )
  }

  isValidSearch() {
    return this.ctgSelectCondition.formGroup.get("ctgCd0").value != "" ? true : false;
  }

  handlerChangedForm() {
    if (this.subscriptionFormGroup) {
      this.subscriptionFormGroup.unsubscribe();
      this.subscriptionFormGroup = undefined;
    }

    this.isChangedForm = false;
    this.subscriptionFormGroup = this.formGroup.valueChanges
      .subscribe(valueChange => {
        let formControlChanged = Object.keys(valueChange)
          .find(key => (this.parseIntCustom(valueChange[key]) != this.parseIntCustom(this.formOriginValue[key]))
            || (parseInt(valueChange[key]) == 0 && parseInt(this.formOriginValue[key]) != 0));
        formControlChanged != null ? this.isChangedForm = true : this.isChangedForm = false;
        this.setErrorInputMessages();
      }
      );
  }

  getAllStoreParamCtg(rspAllStoreParamStgSearch: RspParamCtgSearch) {
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
      this.subscriptionSearch = undefined;
    }

    if (rspAllStoreParamStgSearch.fatalError && rspAllStoreParamStgSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspAllStoreParamStgSearch.fatalError[0].errMsg);
    }
    if (rspAllStoreParamStgSearch.normalError && rspAllStoreParamStgSearch.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspAllStoreParamStgSearch.normalError[0].errMsg);
    }

    this.ctgList = [];
    for (var rspParamCtg of rspAllStoreParamStgSearch.result) {
      this.ctgList.push(new CtgParamRec(rspParamCtg));
    }

    this.initFormGroup();
    this.clearProgressState();
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }

  canDeactivate() {
    if (!this.isChangedForm) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  doCancel() {
    this.initFormGroup();
  }

  setErrorInputMessages() {
    this.errorInputMessages = [];
    const config = this.configAllStoreParamCtg;
    this.ctgList.forEach(ctg => {
      if (this.isMinZaiError(this.formGroup.value[ctg.storeCdFv + "-minZai"]) || (this.isChangeInputElement(ctg.storeCdFv + "-minZai") && this.formGroup.value[ctg.storeCdFv + "-minZai"] === "")) {
        this.errorInputMessages.push(ctg.storeCdFv + ":" + ctg.storeNameFv + ":" + "最低陳列 の値は、" + config.minZai.min + "～" + config.minZai.max + "の範囲でなければなりません。");
      }
      if (this.isMaxZaiError(this.formGroup.value[ctg.storeCdFv + "-maxZai"]) || (this.isChangeInputElement(ctg.storeCdFv + "-maxZai") && this.formGroup.value[ctg.storeCdFv + "-maxZai"] === "")) {
        this.errorInputMessages.push(ctg.storeCdFv + ":" + ctg.storeNameFv + ":" + "最大陳列 の値は、" + config.maxZai.min + "~" + config.minZai.max + "の範囲でなければなりません。");
      }
      if (this.isMinStockDaysError(this.formGroup.value[ctg.storeCdFv + "-minStockDays"]) || (this.isChangeInputElement(ctg.storeCdFv + "-minStockDays") && this.formGroup.value[ctg.storeCdFv + "-minStockDays"] === "")) {
        this.errorInputMessages.push(ctg.storeCdFv + ":" + ctg.storeNameFv + ":" + "最低在庫日数 の値は、" + config.minStockDays.min + "~" + config.minStockDays.max + "の範囲でなければなりません。");
      }
      if (this.isMaxStockDaysError(this.formGroup.value[ctg.storeCdFv + "-maxStockDays"]) || (this.isChangeInputElement(ctg.storeCdFv + "-maxStockDays") && this.formGroup.value[ctg.storeCdFv + "-maxStockDays"] === "")) {
        this.errorInputMessages.push(ctg.storeCdFv + ":" + ctg.storeNameFv + ":" + "最大在庫日数 の値は、" + config.maxStockDays.min + "~" + config.maxStockDays.max + "の範囲でなければなりません。");
      }
      if (this.formGroupValidator(ctg.storeCdFv, ctg.storeNameFv, "zai") != null) {
        this.errorInputMessages.push(this.formGroupValidator(ctg.storeCdFv, ctg.storeNameFv, "zai"));
      }
      if (this.formGroupValidator(ctg.storeCdFv, ctg.storeNameFv, "stock") != null) {
        this.errorInputMessages.push(this.formGroupValidator(ctg.storeCdFv, ctg.storeNameFv, "stock"));
      }
    })
  }

  setErrorSetAllMessages() {
    this.errorSetAllMessages = [];
    const config = this.configAllStoreParamCtg;
    if (this.isMinZaiError(this.formSetAllCtg.value.minZaiForm)) {
      this.errorSetAllMessages.push("最低陳列 の値は、" + config.minZai.min + "～" + config.minZai.max + "の範囲でなければなりません。");
    }
    if (this.isMaxZaiError(this.formSetAllCtg.value.maxZaiForm)) {
      this.errorSetAllMessages.push("最大陳列 の値は、" + config.maxZai.min + "～" + config.minZai.max + "の範囲でなければなりません。");
    }
    if (this.isMinStockDaysError(this.formSetAllCtg.value.minStockDaysForm)) {
      this.errorSetAllMessages.push("最低在庫日数 の値は、" + config.minStockDays.min + "～" + config.minStockDays.max + "の範囲でなければなりません。");
    }
    if (this.isMaxStockDaysError(this.formSetAllCtg.value.maxStockDaysForm)) {
      this.errorSetAllMessages.push("最大在庫日数 の値は、" + config.maxStockDays.min + "～" + config.maxStockDays.max + "の範囲でなければなりません。");
    }
    if (this.formSetAllValidator("zai") != null) {
      this.errorSetAllMessages.push(this.formSetAllValidator("zai"));
    }
    if (this.formSetAllValidator("stock") != null) {
      this.errorSetAllMessages.push(this.formSetAllValidator("stock"));
    }
  }

  isMinZaiError(value: string) {
    const config = this.configAllStoreParamCtg;
    if (parseFloat(value) - config.minZai.max > 0) {
      return true;
    }
    if (parseInt(value) < config.minZai.min || parseInt(value) > config.minZai.max)
      return true;
    return false;
  }

  isMaxZaiError(value: string) {
    const config = this.configAllStoreParamCtg;
    if (parseFloat(value) - config.maxZai.max > 0) {
      return true;
    }
    if (parseInt(value) < config.maxZai.min || parseInt(value) > config.maxZai.max)
      return true;
    return false;
  }

  isMinStockDaysError(value: string) {
    const config = this.configAllStoreParamCtg;
    if (parseFloat(value) - config.minStockDays.max > 0) {
      return true;
    }
    if (parseInt(value) < config.minStockDays.min || parseInt(value) > config.minStockDays.max)
      return true;
    return false;
  }

  isMaxStockDaysError(value: string) {
    const config = this.configAllStoreParamCtg;
    if (parseFloat(value) - config.maxStockDays.max > 0) {
      return true;
    }
    if (parseInt(value) < config.maxStockDays.min || parseInt(value) > config.maxStockDays.max)
      return true;
    return false;
  }

  formGroupValidator(storeCdFv: string, storeNameFv: string, type: string) {
    switch (type) {
      case "zai": {
        if (this.parseIntCustom(this.formGroup.get(storeCdFv + "-minZai").value) > this.parseIntCustom(this.formGroup.get(storeCdFv + "-maxZai").value))
          return storeCdFv + ":" + storeNameFv + ":最低陳列、最大陳列が逆転しています。";
        return null;
      }
      case "stock": {
        if (this.parseIntCustom(this.formGroup.get(storeCdFv + "-minStockDays").value) > this.parseIntCustom(this.formGroup.get(storeCdFv + "-maxStockDays").value))
          return storeCdFv + ":" + storeNameFv + ":最低在庫日数、最大在庫日数が逆転しています。";
        return null
      }
      default: return null;
    }
  }

  formSetAllValidator(type: string) {
    switch (type) {
      case "zai": {
        if (type === "zai" && parseInt(this.formSetAllCtg.value["minZaiForm"]) > parseInt(this.formSetAllCtg.value["maxZaiForm"]))
          return "最低陳列、最大陳列が逆転しています。";
        return null;
      }
      case "stock": {
        if (type === "stock" && parseInt(this.formSetAllCtg.value["minStockDaysForm"]) > parseInt(this.formSetAllCtg.value["maxStockDaysForm"]))
          return "最低在庫日数、最大在庫日数が逆転しています。";
        return null
      }
      default: return null;
    }
  }


  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  is10KeyPadTarget(id: string) {
    if (!this.use10KeyPad()) return false;
    if (id === this.keypadTargetId) return true;
    return false;
  }

  set10KeyPadTarget(id: string, form: FormControl) {
    this.keypadTargetId = id;
    // this.numberKeypadComponent.inputField = form;
    this.numberKeypadComponent.setTargetForm(form);
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent && this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  isDisabledSetAllButton() {
    if (this.errorSetAllMessages.length > 0) return true;

    return (this.formSetAllCtg.value.minZaiForm === ""
      && this.formSetAllCtg.value.maxZaiForm === ""
      && this.formSetAllCtg.value.minStockDaysForm === ""
      && this.formSetAllCtg.value.maxStockDaysForm === "")
      ? true : false;
  }

  doSetAll() {
    if (this.commonService.config.allStoreCopyConfirm == true) {
      this.clearKeypadTargetId();
      this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, "全店に適用します。").subscribe(
        (data) => {
          this.subscriptionConfirm = undefined;
          if (data == true) {
            this.ctgList.forEach(ctg => {
              if (this.formSetAllCtg.value.minZaiForm !== "")
                this.formGroup.controls[ctg.storeCdFv + "-minZai"].setValue(this.formSetAllCtg.value.minZaiForm);
              if (this.formSetAllCtg.value.maxZaiForm !== "")
                this.formGroup.controls[ctg.storeCdFv + "-maxZai"].setValue(this.formSetAllCtg.value.maxZaiForm);
              if (this.formSetAllCtg.value.minStockDaysForm !== "")
                this.formGroup.controls[ctg.storeCdFv + "-minStockDays"].setValue(this.formSetAllCtg.value.minStockDaysForm);
              if (this.formSetAllCtg.value.maxStockDaysForm !== "")
                this.formGroup.controls[ctg.storeCdFv + "-maxStockDays"].setValue(this.formSetAllCtg.value.maxStockDaysForm);
            })
          }
        }
      );
    } else {
      this.ctgList.forEach(ctg => {
        if (this.formSetAllCtg.value.minZaiForm !== "")
          this.formGroup.controls[ctg.storeCdFv + "-minZai"].setValue(this.formSetAllCtg.value.minZaiForm);
        if (this.formSetAllCtg.value.maxZaiForm !== "")
          this.formGroup.controls[ctg.storeCdFv + "-maxZai"].setValue(this.formSetAllCtg.value.maxZaiForm);
        if (this.formSetAllCtg.value.minStockDaysForm !== "")
          this.formGroup.controls[ctg.storeCdFv + "-minStockDays"].setValue(this.formSetAllCtg.value.minStockDaysForm);
        if (this.formSetAllCtg.value.maxStockDaysForm !== "")
          this.formGroup.controls[ctg.storeCdFv + "-maxStockDays"].setValue(this.formSetAllCtg.value.maxStockDaysForm);
      })
    }

  }

  doClearFormSetAll() {
    this.formSetAllCtg.controls["minZaiForm"].setValue('');
    this.formSetAllCtg.controls["maxZaiForm"].setValue('');
    this.formSetAllCtg.controls["minStockDaysForm"].setValue('');
    this.formSetAllCtg.controls["maxStockDaysForm"].setValue('');
    this.clearKeypadTargetId();
  }

  parseIntCustom(x: string) {
    const parsed = parseInt(x);
    if (isNaN(parsed)) { return 0; }
    return parsed;
  }

  isChangedParam(storeCdFv: string) {
    if (this.isChangeInputElement(storeCdFv + "-minZai")
      || this.isChangeInputElement(storeCdFv + "-maxZai")
      || this.isChangeInputElement(storeCdFv + "-minStockDays")
      || this.isChangeInputElement(storeCdFv + "-maxStockDays")
    )
      return true;
    return false;
  }

  isChangeInputElement(formControlName: string) {
    return this.parseIntCustom(this.formGroup.value[formControlName]) != this.parseIntCustom(this.formOriginValue[formControlName])
      ? true
      : false;
  }

  doUpdate() {
    if (this.errorInputMessages.length > 0) return;
    this.ctgChangedList = [];
    this.ctgList.forEach(
      ctg => {
        if (this.isChangedParam(ctg.storeCdFv)) {
          let storeChangedCtg: ParamCtgEditDto = {
            storeCdFv: ctg.storeCdFv,
            ctgLevelFn: ctg.ctgLevelFn,
            ctgCd0Fv: ctg.ctgCd0Fv,
            ctgCd1Fv: ctg.ctgCd1Fv,
            ctgCd2Fv: ctg.ctgCd2Fv,
            ctgCd3Fv: ctg.ctgCd3Fv,

            rankFv: this.rankFormSelected,

            minZaiFn: ctg.minZaiFn,
            minZaiModFn: ctg.minZaiModFn,
            minZaiVersionFn: ctg.minZaiVersionFn,

            maxZaiFn: ctg.maxZaiFn,
            maxZaiModFn: ctg.maxZaiModFn,
            maxZaiVersionFn: ctg.maxZaiVersionFn,

            minStockDaysFn: ctg.minStockDaysFn,
            minStockDaysModFn: ctg.minStockDaysModFn,
            minStockDaysVersionFn: ctg.minStockDaysVersionFn,

            maxStockDaysFn: ctg.maxStockDaysFn,
            maxStockDaysModFn: ctg.maxStockDaysModFn,
            maxStockDaysVersionFn: ctg.maxStockDaysVersionFn,
          }

          if (this.isChangeInputElement(ctg.storeCdFv + "-minZai"))
            storeChangedCtg.minZaiModFn = this.parseIntCustom(this.formGroup.value[ctg.storeCdFv + "-minZai"]);
          if (this.isChangeInputElement(ctg.storeCdFv + "-maxZai"))
            storeChangedCtg.maxZaiModFn = this.parseIntCustom(this.formGroup.value[ctg.storeCdFv + "-maxZai"]);
          if (this.isChangeInputElement(ctg.storeCdFv + "-minStockDays"))
            storeChangedCtg.minStockDaysModFn = this.parseIntCustom(this.formGroup.value[ctg.storeCdFv + "-minStockDays"]);
          if (this.isChangeInputElement(ctg.storeCdFv + "-maxStockDays"))
            storeChangedCtg.maxStockDaysModFn = this.parseIntCustom(this.formGroup.value[ctg.storeCdFv + "-maxStockDays"]);

          this.ctgChangedList.push(storeChangedCtg);
        }
      }
    )

    const request: ReqAllStoreParamCtgEdit = {
      access: { ...this.commonService.loginUser },
      option: parseInt(this.optionForm.value),
      allStoreParamCtgEdit: this.ctgChangedList
    }

    this.subscriptionSearch = this.httpBasic.updateAllStoreCtgParam(request)
      .subscribe(data =>
        this.checkResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        });

    this.commonService.openSpinner("全店カテゴリ・パラメータ", "更新中・・・");
  }

  checkResult(response: RspAllStoreParamItemSearch) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.ctgList.forEach(
      ctg => {
        if (this.isChangedParam(ctg.storeCdFv)) {
          if (this.isChangeInputElement(ctg.storeCdFv + "-minZai"))
            ctg.updMinZai(this.parseIntCustom(this.formGroup.value[ctg.storeCdFv + "-minZai"]), ctg.minZaiVersionFn + 1);
          if (this.isChangeInputElement(ctg.storeCdFv + "-maxZai"))
            ctg.updMaxZai(this.parseIntCustom(this.formGroup.value[ctg.storeCdFv + "-maxZai"]), ctg.maxZaiVersionFn + 1);
          if (this.isChangeInputElement(ctg.storeCdFv + "-minStockDays"))
            ctg.updMinStockDays(this.parseIntCustom(this.formGroup.value[ctg.storeCdFv + "-minStockDays"]), ctg.minStockDaysVersionFn + 1);
          if (this.isChangeInputElement(ctg.storeCdFv + "-maxStockDays"))
            ctg.updMaxStockDays(this.parseIntCustom(this.formGroup.value[ctg.storeCdFv + "-maxStockDays"]), ctg.maxStockDaysVersionFn + 1);
        }
      }
    )

    this.initFormGroup();
  }

  scrollTopTable() {
    if (this.allStoreParamCtgTable)
      this.allStoreParamCtgTable.nativeElement.scrollTop = 0;
  }

  clearKeypadTargetId() {
    this.keypadTargetId = undefined;
    this.numberKeypadComponent.inputField = undefined;
  }
}
