import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CommonService } from 'src/app/service/common.service';
import { FormControl } from '@angular/forms';
import { DlListDto, RspDlList } from 'src/app/response/rsp-dl-list';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { Subscription } from 'rxjs';
import { RspDownload } from 'src/app/response/rsp-download';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-downld',
  templateUrl: './downld.component.html',
  styleUrls: ['./downld.component.css']
})
export class DownldComponent implements OnInit, OnDestroy {

  public isReadonly: boolean;
  public storeForm : FormControl = new FormControl(this.commonService.loginUser.storeCd);
  public stores: LoginStoreDto[];
  public dlList: DlListDto[] = [];
  public displayColumns: string[] = ["storeCd", "storeName", "title", "expirationDate", "download"];

  private subscriptionList: Subscription;
  private subscriptionDownload: Subscription;

  constructor
  (public commonService: CommonService,
   private httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = "ダウンロード";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("downld");
    if (this.commonService.stores.length > 1) {
      this.stores = [{storeCd: "*", storeName:"全店"}, ...this.commonService.stores];
      this.storeForm.setValue("*");
    } else {
      this.stores = [...this.commonService.stores];
    }
  }

  ngOnDestroy() {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
    if (this.subscriptionDownload) {
      this.subscriptionDownload.unsubscribe();
    }
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionList) this.subscriptionList.unsubscribe();
    this.subscriptionList = undefined;
    if (this.subscriptionDownload) this.subscriptionDownload.unsubscribe();
    this.subscriptionDownload = undefined;
  }

  getList() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    this.httpBasic.getDlList(this.storeForm.value).subscribe(
      (response) => this.receiveDlList(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDlList(response: RspDlList) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.dlList = [];
    for (let dlItem of response.dlList) {
      let item = new DlListDto();
      item.id = dlItem.id;
      item.storeCd = dlItem.storeCd;
      item.storeName = dlItem.storeName;
      item.title = dlItem.title;
      item.folder = dlItem.folder;
      item.filename = dlItem.filename;
      item.expirationDate = dlItem.expirationDate;
      this.dlList.push(item);
    }
  }

  downld(dlItem: DlListDto, token: string) {
    let link = document.createElement('a');
    link.href = this.commonService.config.wsBaseUrl + "Downld?id=" + dlItem.id + "&t=" + token;
    if (this.commonService.config.myResourceDir && this.commonService.config.myResourceDir != "") {
      link.href += "&d=" + this.commonService.config.myResourceDir;
     }
     link.download = dlItem.filename;
    link.click();
  }

  download(dlItem: DlListDto) {
    this.commonService.openSpinner(this.commonService.pageTitle, "初期化中・・・");

    this.subscriptionDownload = this.httpBasic.download().subscribe(
      (response) => this.receiveDownload(response, dlItem),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDownload(response: RspDownload, dlItem: DlListDto) {

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.downld(dlItem, response.token);
    this.clearProgressState();
  }

}
