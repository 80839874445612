import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReqGetDetail } from 'src/app/request/req-spiv00101';
import { ReqInputGetDetail } from 'src/app/request/req-spiv00101-inventory-input-register';
import { RspGetDetail, RspCheckMatch } from 'src/app/response/rsp-spiv00101';
import { Spiv00051SearchDto } from 'src/app/request/req-spiv00051';
import { StoreDto } from 'src/app/response/rsp-inventory-schedule-list';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MessageService } from 'src/app/service/message.service';
import { Spiv00101NotificationDialogComponent } from 'src/app/dialog/spiv00101-notification-dialog/spiv00101-notification-dialog.component';
import { Spiv00111Component } from '../spiv00111/spiv00111.component';
import { Spiv00101ErrorNotificationDialogComponent } from 'src/app/dialog/spiv00101-error-notification-dialog/spiv00101-error-notification-dialog.component';
import * as MessageID from 'src/app/common/const-message-id';
import { UserInfoDto } from 'src/app/response/rsp-user-info';
@Component({
  selector: 'app-inventory-product-register',
  templateUrl: './spiv00101.component.html',
  styleUrls: ['./spiv00101.component.css']
})

export class Spiv00101Component implements OnInit, OnDestroy {

  @Output() fpiv0101Exist = new EventEmitter<void>();
  @ViewChild("sectionSuppHeader", { static: true }) sectionSupp: SectionHeaderComponent;
  private subscriptionInit: Subscription;
  private subscriptionFormGroup: Subscription;

  public formGroup: FormGroup;
  searchData: Spiv00051SearchDto;
  userInfo: UserInfoDto[] = [];
  stores: StoreDto[] = [];
  pageTitle: string = '棚卸商品マスタ';

  registerOKMsg = this.messageEntity.message[MessageID.MI100002];
  duplicateMsg = this.messageEntity.message[MessageID.ME100016];
  partialNGMsg = this.messageEntity.message[MessageID.ME100017];
  storeConfirmMsg = this.messageEntity.message[MessageID.ME100018];
  headQuarterConfirmMsg = this.messageEntity.message[MessageID.ME100019];
  loginStoreCd: any;
  cmpnyCd: any;
  storeCd: any;
  sysModeCd: any;

  Spiv00111Dialog: MatDialogRef<Spiv00111Component>;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    public messageEntity: MessageService,
    private dialogRef: MatDialogRef<Spiv00101Component>,
    private dialog: MatDialog,
    private router: Router
  ) { }

  async ngOnInit() {
    this.loginStoreCd = this.commonService.loginUser.realStoreCd;
    this.commonService.openSpinner(this.pageTitle, "検索中・・・");
    this.initFormGroup();
    this.setInitPage();
    await this.getUserInfo();
    // await this.getStoreLists();
    this.getDetail();
}

  ngOnDestroy() {
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      userCd: [this.commonService.loginUser.userId],
      cmpyCd: [this.cmpnyCd],
      storeCd: [""],
      storeInfo: [''],
      inventoryId: [""],
      inventoryInfo: [''],
      inventoryMonth: [''],
      inventoryMonthTitle: [''],
      inventoryPartial: [''],
      inventoryProcessStatus: [''],
      inventoryEntryStartDate: [''],
      productCd: [""],
      productNm: [''],
      standardNm: [''],
      classInfo: [''],
      gMainClassCd: [''],
      gMainClassNm: [''],
      gMidClassCd: [''],
      gMidClassNm: [''],
      gSmallClassCd: [''],
      gSmallClassNm: [''],
      gSubClassCd: [''],
      gSubClassNm: [''],
      partnerCd: [''],
      partnerNm: [''],
      stockQty: [''],
      posCostExc: [''],
      sellPriceExc: ['']
    });

    // Set disabled attribute for all form controls
    this.formGroup.disable();
  }

  setInitPage() {
    this.searchData = this.commonService.fpiv0005SearchData;
    this.commonService.fpiv0005SearchData = undefined;

    // Check search data is exist or not
    if (this.searchData != undefined) {
      this.formGroup.get('storeCd').setValue(this.searchData.storeCd);
      this.formGroup.get('inventoryId').setValue(this.searchData.invScheduleId);
      this.formGroup.get('productCd').setValue(this.searchData.productCd);
    }

  }

  getUserInfo() :Promise<void>{
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
      };
      let sub =this.httpBasic.generalRequest("GetUserInfo", request).subscribe(
        response => {
          sub.unsubscribe();
          this.receiveUserInfo(response);
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveUserInfo(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.userInfo = [];
    if (response.rows.length > 0) {
      this.userInfo = response.rows;
      this.cmpnyCd = this.userInfo[0].mainCmpnyCd;
      this.storeCd = this.userInfo[0].mainStoreCd;
      this.sysModeCd = this.userInfo[0].sysModeCd;
    }else{
      this.userInfo = [];
      this.cmpnyCd = "";
      this.storeCd = "";
      this.sysModeCd = "";
    }
  }

  // get 店舗 dropdown list
  getStoreLists(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
      };
      this.httpBasic.generalRequest("GetStoreList", request).subscribe(
        response => {
          this.receiveStoreList(response);
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveStoreList(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.stores = [];
    this.formGroup.get("storeCd").setValue(this.loginStoreCd);
    this.stores = response.rows;
  }

  checkResult(response: RspGetDetail) {
    var check = true;
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.pageTitle, response.normalError[0].errMsg);
      return;
    }
    return check;
  }

  checkMatchResult(response: RspCheckMatch) {
    var check = true;
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.pageTitle, response.normalError[0].errMsg);
      return;
    }
    return check;
  }

  async doRegister() {
    let request: ReqGetDetail = this.getRegisterRequest();
    let ref = this.commonService.openSpinnerForSubComp(this.pageTitle, "登録中・・・");

    // Check validation of input fields
    const match = await this.fnCheckMatch(ref);
    if (match) {
      let subsc = this.httpBasic.generalRequest("Spiv00101InventoryProductRegister", request).subscribe(
        (response: RspGetDetail) => {
          if (this.checkResult(response)) {
            subsc.unsubscribe();
            this.commonService.closeSpinnerForSubComp(ref);

            if (response.cnt > 0) {
              this.toInventoryEntry();
              this.commonService.dialog.open(Spiv00101ErrorNotificationDialogComponent, {
                data: { errorTitle: this.pageTitle, errorMessage: this.duplicateMsg }
              });
            } else {
              const dialogRef = this.commonService.dialog.open(Spiv00101NotificationDialogComponent, {
                data: {
                  title: this.pageTitle,
                  message: this.registerOKMsg
                }
              });
              dialogRef.componentInstance.navigateDetail.subscribe(() => {
                this.navigateToDetail();
              });

              dialogRef.componentInstance.navigateInput.subscribe(() => {
                this.navigateToInput();
              });
            }
          }
        },
        (error) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.httpBasic.handleError(error);
        }
      );
    } else {
      this.commonService.closeSpinnerForSubComp(ref);
      this.commonService.fpiv0005ParentPage = 'fpiv0010';
      this.toInventoryEntry();
      this.commonService.dialog.open(Spiv00101ErrorNotificationDialogComponent, {
        data: { errorTitle: this.pageTitle, errorMessage: this.partialNGMsg }
      });
    }

  }

  getRegisterRequest() {
    let request: ReqGetDetail = {
      access: this.commonService.loginUser,
      userCdFv: this.formGroup.get("userCd").value,
      cmpnyCdFv: this.cmpnyCd,
      storeCdFv: this.formGroup.get("storeCd").value,
      inventoryIdFv: this.formGroup.get("inventoryId").value,
      inventoryMonthFv: this.formGroup.get("inventoryMonth").value,
      productCdFv: this.formGroup.get("productCd").value,
      gMainClassCdFv: this.formGroup.get("gMainClassCd").value,
      gMidClassCdFv: this.formGroup.get("gMidClassCd").value,
      gSmallClassCdFv: this.formGroup.get("gSmallClassCd").value,
      gSubClassCdFv: this.formGroup.get("gSubClassCd").value,
      partnerCdFv: this.formGroup.get("partnerCd").value,
      dataTypeFlgFv: "01",
      screenIdFv: "FPIV0010"
    };
    return request;
  }

  fnCheckMatch(ref: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let request: ReqGetDetail = this.getRegisterRequest();

      let subsc = this.httpBasic.generalRequest("Spiv00101CheckMatchInventoryProduct", request).subscribe(
        (response: RspCheckMatch) => {
          if (response.fatalError && response.fatalError.length > 0) {
            this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
            return;
          }
          if (response.normalError && response.normalError.length > 0) {
            this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
            return;
          }
          switch (response.statusFv) {
            case '01':
            case '02':
              if (this.checkMatchResult(response)) {
                subsc.unsubscribe();
                resolve(response.matchFv);
              }
              break;
            case '03':
              this.commonService.closeSpinnerForSubComp(ref);
              this.toInventoryEntry();
              this.commonService.dialog.open(Spiv00101ErrorNotificationDialogComponent, {
                data: { errorTitle: this.pageTitle, errorMessage: this.storeConfirmMsg }
              });
              break;
            case '04':
              this.commonService.closeSpinnerForSubComp(ref);
              this.toInventoryEntry();
              this.commonService.dialog.open(Spiv00101ErrorNotificationDialogComponent, {
                data: { errorTitle: this.pageTitle, errorMessage: this.headQuarterConfirmMsg }
              });
              break;
          }
        },
        (error) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  doBack() {
    this.dialogRef.close();
    this.toInventoryEntry();
    this.fpiv0101Exist.emit();
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  getDetail() {
    let request: ReqGetDetail = this.getRegisterRequest();
    let subsc = this.httpBasic.generalRequest("Spiv00101GetInventoryProductDetail", request).subscribe(
      (response: RspGetDetail) => {
        subsc.unsubscribe();

        this.commonService.closeSpinner();
        this.formGroup.controls["storeInfo"].setValue(
          response.result[0].storeCdFv !== undefined ? response.result[0].storeCdFv + ':' + response.result[0].storeNmFv : ''
        );
        this.formGroup.controls["storeCd"].setValue(
          response.result[0].storeCdFv !== undefined ? response.result[0].storeCdFv : ''
        );

        this.formGroup.controls["inventoryInfo"].setValue(
          response.result[0].inventoryMonthFv !== undefined ? response.result[0].inventoryMonthFv + ' | ' + response.result[0].inventoryTitleFv : ''
        );
        this.formGroup.controls["inventoryMonth"].setValue(
          response.result[0].inventoryMonthFv !== undefined ? response.result[0].inventoryMonthFv : ''
        );
        this.formGroup.controls["inventoryPartial"].setValue(
          response.result[0].inventoryPartialFv !== undefined ? this.changePartialToText(response.result[0].inventoryPartialFv) : ''
        );
        this.formGroup.controls["inventoryProcessStatus"].setValue(
          response.result[0].inventoryProcessStatusFv !== undefined ? this.changeProcessStatusToText(response.result[0].inventoryProcessStatusFv) : ''
        );
        this.formGroup.controls["inventoryEntryStartDate"].setValue(
          response.result[0].inventoryEntryStartDateFv !== undefined ? response.result[0].inventoryEntryStartDateFv : ''
        );
        this.formGroup.controls["productCd"].setValue(
          response.result[0].productCdFv !== undefined ? response.result[0].productCdFv : ''
        );
        this.formGroup.controls["productNm"].setValue(
          response.result[0].productNmFv !== undefined ? response.result[0].productNmFv : ''
        );
        this.formGroup.controls["standardNm"].setValue(
          response.result[0].standardNmFv !== undefined ? response.result[0].standardNmFv : ''
        );
        this.formGroup.controls["classInfo"].setValue(
          '[' + (response.result[0].gMainClassCdFv !== undefined ? response.result[0].gMainClassCdFv : '') + ':'
          + (response.result[0].gMainClassNmFv !== undefined ? response.result[0].gMainClassNmFv : '') + '] - '
          + '[' + (response.result[0].gMidClassCdFv !== undefined ? response.result[0].gMidClassCdFv : '') + ':'
          + (response.result[0].gMidClassNmFv !== undefined ? response.result[0].gMidClassNmFv : '') + '] - '
          + '[' + (response.result[0].gSmallClassCdFv !== undefined ? response.result[0].gSmallClassCdFv : '') + ':'
          + (response.result[0].gSmallClassNmFv !== undefined ? response.result[0].gSmallClassNmFv : '') + '] - '
          + '[' + (response.result[0].gSubClassCdFv !== undefined ? response.result[0].gSubClassCdFv : '') + ':'
          + (response.result[0].gSubClassNmFv !== undefined ? response.result[0].gSubClassNmFv : '') + ']'
        );
        this.formGroup.controls["gMainClassCd"].setValue(
          response.result[0].gMainClassCdFv !== undefined ? response.result[0].gMainClassCdFv : ''
        );
        this.formGroup.controls["gMidClassCd"].setValue(
          response.result[0].gMidClassCdFv !== undefined ? response.result[0].gMidClassCdFv : ''
        );
        this.formGroup.controls["gSmallClassCd"].setValue(
          response.result[0].gSmallClassCdFv !== undefined ? response.result[0].gSmallClassCdFv : ''
        );
        this.formGroup.controls["gSubClassCd"].setValue(
          response.result[0].gSubClassCdFv !== undefined ? response.result[0].gSubClassCdFv : ''
        );
        this.formGroup.controls["gMainClassNm"].setValue(
          response.result[0].gMainClassNmFv !== undefined ? response.result[0].gMainClassNmFv : ''
        );
        this.formGroup.controls["gMidClassNm"].setValue(
          response.result[0].gMidClassNmFv !== undefined ? response.result[0].gMidClassNmFv : ''
        );
        this.formGroup.controls["gSmallClassNm"].setValue(
          response.result[0].gSmallClassNmFv !== undefined ? response.result[0].gSmallClassNmFv : ''
        );
        this.formGroup.controls["gSubClassNm"].setValue(
          response.result[0].gSubClassNmFv !== undefined ? response.result[0].gSubClassNmFv : ''
        );
        this.formGroup.controls["partnerCd"].setValue(
          response.result[0].partnerCdFv !== undefined ? response.result[0].partnerCdFv : ''
        );
        this.formGroup.controls["partnerNm"].setValue(
          response.result[0].partnerNmFv !== undefined ? response.result[0].partnerNmFv : ''
        );
        this.formGroup.controls["stockQty"].setValue(
          response.result[0].stockQtyFv !== undefined ? response.result[0].stockQtyFv : ''
        );
        this.formGroup.controls["posCostExc"].setValue(
          response.result[0].posCostExcFv !== undefined ? response.result[0].posCostExcFv : ''
        );
        this.formGroup.controls["sellPriceExc"].setValue(
          response.result[0].sellPriceExcFv !== undefined ? response.result[0].sellPriceExcFv : ''
        );

      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  navigateToDetail() {
    this.dialogRef.close();
    // this.inventoryInputRegister(); // no need to register in there, just send the invinputid = null
    this.toInventoryEntryDetail();
    this.Spiv00111Dialog = this.dialog.open(Spiv00111Component, {});
  }

  navigateToInput() {
    this.dialogRef.close();
    this.toInventoryEntry();
    this.router.navigate(
      ['/SPIV00051']
    );
  }

  getInvInputRegisterRequest() {
    let request: ReqInputGetDetail = {
      access: this.commonService.loginUser,
      userCdFv: this.formGroup.get("userCd").value,
      cmpnyCdFv: this.cmpnyCd,
      inventoryIdFv: this.formGroup.get("inventoryId").value,
      inventoryMonthFv: this.formGroup.get("inventoryMonth").value,
      storeCdFv: this.formGroup.get("storeCd").value,
      gMainClassCdFv: this.formGroup.get("gMainClassCd").value,
      gMidClassCdFv: this.formGroup.get("gMidClassCd").value,
      gSmallClassCdFv: this.formGroup.get("gSmallClassCd").value,
      gSubClassCdFv: this.formGroup.get("gSubClassCd").value,
      productCdFv: this.formGroup.get("productCd").value,
      costPriceFv: this.formGroup.get("posCostExc").value,
      sellPriceFv: this.formGroup.get("sellPriceExc").value,
      partnerCdFv: this.formGroup.get("partnerCd").value,
      screenIdFv: "FPIV0010"
    };
    return request;
  }

  inventoryInputRegister() {
    let request: ReqInputGetDetail = this.getInvInputRegisterRequest();
    let subsc = this.httpBasic.generalRequest("Spiv00101InventoryInputRegister", request).subscribe(
      (response: RspGetDetail) => {
        if (this.checkResult(response)) {
          subsc.unsubscribe();
        }
      },
      (error) => {
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  changePartialToText(status: string) {
    var result = "";
    if (status === '01') {
      result = '有';
    } else {
      result = '無';
    }
    return result;
  }

  changeProcessStatusToText(status: string) {
    var result = "";
    switch (status) {
      case '01':
        result = '棚卸開始前';
        break;
      case '02':
        result = '棚卸実施中';
        break;
      case '03':
        result = '店舗確定済';
        break;
      case '04':
        result = '本部確定済';
        break;
    }
    return result;
  }

  toInventoryEntry() {
    this.commonService.fpiv0005ParentPage = 'fpiv0010';
    this.commonService.pageMenuName = "棚卸入力";
    this.commonService.fpiv0005SearchData = {
      storeCd: this.searchData.storeCd,
      invScheduleId: this.searchData.invScheduleId,
      invYearMonth: this.searchData.invYearMonth,
      productCd: this.searchData.productCd,
      productNm: this.formGroup.get('productNm').value,
      gmainClassCd: this.searchData.gmainClassCd,
      gmidClassCd: this.searchData.gmidClassCd,
      gsmallClassCd: this.searchData.gsmallClassCd,
      gsubClassCd: this.searchData.gsubClassCd,
      inventoryQtyFrom: this.searchData.inventoryQtyFrom,
      inventoryQtyTo: this.searchData.inventoryQtyTo,
      stockQtyFrom: this.searchData.stockQtyFrom,
      stockQtyTo: this.searchData.stockQtyTo,
    }
  }

  toInventoryEntryDetail() {
    this.commonService.fpiv0011ParentPage = 'fpiv0010';
    this.commonService.fpiv0005SearchData = {
      storeCd: this.searchData.storeCd,
      invScheduleId: this.searchData.invScheduleId,
      invYearMonth: this.searchData.invYearMonth,
      productCd: this.searchData.productCd,
      productNm: this.formGroup.get('productNm').value,
      gmainClassCd: this.searchData.gmainClassCd,
      gmidClassCd: this.searchData.gmidClassCd,
      gsmallClassCd: this.searchData.gsmallClassCd,
      gsubClassCd: this.searchData.gsubClassCd,
      inventoryQtyFrom: this.searchData.inventoryQtyFrom,
      inventoryQtyTo: this.searchData.inventoryQtyTo,
      stockQtyFrom: this.searchData.stockQtyFrom,
      stockQtyTo: this.searchData.stockQtyTo,
      cmpnyCd: this.cmpnyCd
    }
    this.commonService.fpiv0010RowData = [{
      invInputId: null,
      productCd: this.formGroup.get("productCd") ? this.formGroup.get("productCd").value : '',
      productNm: this.formGroup.get("productNm") ? this.formGroup.get("productNm").value : '',
      standardNm: this.formGroup.get("standardNm") ? this.formGroup.get("standardNm").value : '',
      inventoryQty: 0,
      stockQty: this.formGroup.get("stockQty") ? this.formGroup.get("stockQty").value : 0,
      posPrice: this.formGroup.get("posCostExc") ? this.formGroup.get("posCostExc").value : '',
      posPriceAmt: "",
      salePrice: this.formGroup.get("sellPriceExc") ? this.formGroup.get("sellPriceExc").value : '',
      salePriceAmt: "",
      gmainClass: "",
      gmainClassCd: this.formGroup.get("gMainClassCd") ? this.formGroup.get("gMainClassCd").value : '',
      gmainClassNm: this.formGroup.get("gMainClassNm") ? this.formGroup.get("gMainClassNm").value : '',
      gmidClass: "",
      gmidClassCd: this.formGroup.get("gMidClassCd") ? this.formGroup.get("gMidClassCd").value : '',
      gmidClassNm: this.formGroup.get("gMidClassNm") ? this.formGroup.get("gMidClassNm").value : '',
      gsmallClass: "",
      gsmallClassCd: this.formGroup.get("gSmallClassCd") ? this.formGroup.get("gSmallClassCd").value : '',
      gsmallClassNm: this.formGroup.get("gSmallClassNm") ? this.formGroup.get("gSmallClassNm").value : '',
      gsubClass: "",
      gsubClassCd: this.formGroup.get("gSubClassCd") ? this.formGroup.get("gSubClassCd").value : '',
      gsubClassNm: this.formGroup.get("gSubClassNm") ? this.formGroup.get("gSubClassNm").value : '',
      updatedtime: "",
      partnerCd: this.formGroup.get("partnerCd") ? this.formGroup.get("partnerCd").value : '',
    }];
  }
}

