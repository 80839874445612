import { Component, OnInit, Input, Injector, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonService } from "src/app/service/common.service";
import { MessageService } from "src/app/service/message.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as Env from "src/assets/config.json";
import * as MessageID from 'src/app/common/const-message-id';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

/**
 * CSV Export common method
 * 
 * @author  PhyoNaing Htun
 * @create  2024/07/16
 */
@Component({
  selector: 'app-csvexport',
  templateUrl: './csv-export.component.html',
  styleUrls: ['./csv-export.component.css'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})

export class CsvExportComponent implements OnInit {

  // Defined variables
  public form: FormGroup;
  public headerInclude: FormControl = new FormControl(false);
  public quotationInEachCellInclude: FormControl = new FormControl('1');

  csvExportHeadersLeft: any[] = [];
  csvExportHeadersRight: any[] = [];
  csvExportFields: any[] = [];
  searchCondition: Object = {};
  searchWebService: String = "";
  dynamicFunctionId: String = "";
  dynamicScreenId: String = "";
  cmpnyCd: string = "";
  lang: string = "";

  constructor (
    private fb: FormBuilder,
    public commonService: CommonService,
    public messageEntity: MessageService,
    public httpBasic: HttpBasicService,
    private dialogRef: MatDialogRef<CsvExportComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
  ) {}

  ngOnInit(): void {
    this.searchCondition = this.params.searchCondition;
    this.searchWebService = this.params.searchWebService;
    this.dynamicFunctionId = this.params.functionId;
    this.dynamicScreenId = this.params.screenId;
    this.cmpnyCd = this.params.cmpnyCd;
    this.lang = this.params.lang;
    this.fnInitGetExportInfo();
  }

  fnInitGetExportInfo() {
    let columnRequest = {
      "screenID": this.params.screenId,
      "access": this.commonService.loginUser,
    };

    let subsc = this.httpBasic.generalRequest("CsvExportInit", columnRequest).subscribe(
      (response: any) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) { 
          return;
        }
        this.csvExportFields = this.fnSetIndexHeaders(response.rows);
        this.csvExportHeadersRight = this.csvExportFields;
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  fnSetIndexHeaders(arr: any) {
    return arr.map((el: any, index: any) => {
      el.ITEMNM = `${index + 1} : ${el.ITEMNM}`;
      return el;
    })
  }

  drop(event: CdkDragDrop<any[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  moveAllToRight() {
    this.csvExportHeadersRight = [...this.csvExportFields];
    this.csvExportHeadersLeft = [];
  }

  moveAllToLeft() {
    this.csvExportHeadersLeft = [...this.csvExportFields];
    this.csvExportHeadersRight = [];
  }

  doCsvExport() {
    if (this.csvExportHeadersRight.length == 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.messageEntity.message[MessageID.ME100022]);
    } else {
      let request = {
        access: this.commonService.loginUser,
        "csvRequestDto": {
          "functionId": this.dynamicFunctionId,
          "csvExportHeadersEngName": this.fnGetHeaderEngName(this.csvExportHeadersRight),
          "csvExportHeadersJpnName": this.fnRemoveIndex(this.fnGetHeaderJpnName(this.csvExportHeadersRight)),
          "csvSearchWebService": Env.config.wsBaseUrl + this.searchWebService,
          "csvSearchRequest": JSON.stringify(this.searchCondition),
          "headerInclude": this.headerInclude.value,
          "quotationInEachCellInclude": this.quotationInEachCellInclude.value,
          "screenTitle": this.params.screenTitle,
          "screenId": this.params.screenId,
        }
      }
      this.fnCsvCreate(request);
    }
  }

  fnCsvCreate(request: any) {
    let subsc = this.httpBasic.generalRequest("CsvCreate", request).subscribe(
      (response: any) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) { 
          return;
        }

        var param = "";
        param += "?usrcd=" + this.commonService.loginUser.userId;
        param += "&lang=" + this.lang;
        param += "&cmpnycd=" + this.cmpnyCd;
        param += "&storecd=" + this.commonService.loginUser.storeCd;
        param += "&token=" + this.commonService.loginUser.token;
        param += "&filename=" + response.csvInfo.fileName;
        param += "&functionId=" + response.csvInfo.FUNCID;
        param += "&opeDateTime=" + response.csvInfo.OPEDATETIME;

        // ファイルをダウンロードするWebサービス
        this.httpBasic.callWebServiceForFileDownload(param, 'CsvDownload', this.httpBasic.FILE_TYPE_EXCEL);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  /* To filter column in server side */
  fnGetHeaderEngName(headers: any) {
    return headers.map((el: any) => el['ITEMID']);
  }

  /* Get japanese name header */
  fnGetHeaderJpnName(headers: any) {
    return headers.map((el: any) => el['ITEMNM']);
  }

  /* To remove numbers in front of column names in csv */
  fnRemoveIndex(headers: any) {
    return headers.map((el: any)=>{
      if(el.includes(": ")){
        el = el.split(": ")[1];
      }
      return el
    })
  }
  
  doClose() {
    this.dialogRef.close();
  }

}
