import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { RackSearchDto, RspRackSearch } from 'src/app/response/rsp-rack-search';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from 'src/app/dialog/spinner-dialog/spinner-dialog.component';

export class RackSelectCondition {

  public storeCd: string;
  public formGroup : FormGroup = this.fb.group({
    rackLevel: [0],
    rackCd1: [0],
    rackCd2: [0],
    rackCd3: [0]
  });
  rack1data: RackSearchDto[] = [];
  rack2data: RackSearchDto[] = [];
  rack3data: RackSearchDto[] = [];
  rack1Name: string = "";
  rack2Name: string = "";
  rack3Name: string = "";

  
  rackDummy(level: number, cd1: number, cd2: number, cd3: number, name: string) : RackSearchDto  {
    return {storeCdFv: "1", rackLevelFn:level, rackCd1Fn:cd1, rackCd2Fn: cd2, rackCd3Fn: cd3, rackNameFv: name};
  }

  constructor(public fb: FormBuilder) {
    this.rack1data.push(this.rackNullData(1));
    this.rack2data.push(this.rackNullData(2));
    this.rack3data.push(this.rackNullData(3));
  }

  rackNullData(level: number): RackSearchDto {
    return {
      storeCdFv: "",
      rackLevelFn: level,
      rackCd1Fn: 0,
      rackCd2Fn: 0,
      rackCd3Fn: 0,
      rackNameFv: ""
    };
  }

  getCd1() : number {
    return parseInt(this.formGroup.get('rackCd1').value);
  }
  getCd2() : number {
    return parseInt(this.formGroup.get('rackCd2').value);
  }
  getCd3() : number {
    return parseInt(this.formGroup.get('rackCd3').value);
  }

  removeRackElement(rackData: RackSearchDto[], level: number, id: number) {
    for (let i = 0; i < rackData.length; i++) {
      if (rackData[i]["rackCd"+level+"Fn"] == id) {
        rackData.splice(i, 1);
        return;
      }
    }
  }

  removeRack(level: number, rackCd1: number, rackCd2: number, rackCd3: number) {
    if (level == 1) {
      this.removeRackElement(this.rack1data, 1, rackCd1);
      this.formGroup.get("rackLevel").setValue(0);
      this.formGroup.get("rackCd1").setValue(0);
    }
    if (level == 2) {
      this.removeRackElement(this.rack2data, 2, rackCd2);
      this.formGroup.get("rackLevel").setValue(1);
      this.formGroup.get("rackCd2").setValue(0);
    }
    if (level == 3) {
      this.removeRackElement(this.rack3data, 3, rackCd3);
      this.formGroup.get("rackLevel").setValue(2);
      this.formGroup.get("rackCd3").setValue(0);
    }
  }
}

@Component({
  selector: 'app-rack-select',
  templateUrl: './rack-select.component.html',
  styleUrls: ['./rack-select.component.css']
})
export class RackSelectComponent implements OnInit, OnDestroy {

  private rackSubscription: Subscription;
  private rackCd1Subscription: Subscription;
  private rackCd2Subscription: Subscription;
  private rackCd3Subscription: Subscription;
  private waitInitSubscription: Subscription;
  private spinnerDialogRef: MatDialogRef<any>;

  public formGroup: FormGroup;
  public rack1data: RackSearchDto[];
  public rack2data: RackSearchDto[];
  public rack3data: RackSearchDto[];
  public rack1Name: string = "";
  public rack2Name: string = "";
  public rack3Name: string = "";

  @Input() rackSelectCondition: RackSelectCondition;
  @Input() waitInit: EventEmitter<any>;
  @Output() public changed: EventEmitter<any> = new EventEmitter(); 
  
  constructor(
    public commonService: CommonService,
    private httpBasic : HttpBasicService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.rackSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    this.formGroup = this.rackSelectCondition.formGroup;
    this.rack1data = this.rackSelectCondition.rack1data;
    this.rack2data = this.rackSelectCondition.rack2data;
    this.rack3data = this.rackSelectCondition.rack3data;
    this.rack1Name = this.rackSelectCondition.rack1Name;
    this.rack2Name = this.rackSelectCondition.rack2Name;
    this.rack3Name = this.rackSelectCondition.rack3Name;

    this.setupChangeSubsc();

    if (this.waitInit) {
      this.waitInitSubscription = this.waitInit.subscribe(
        () => {
          this.waitInitSubscription.unsubscribe();
          this.getRackData(1);
        }
      );
    }
    if (this.rack1data.length < 2 && !this.waitInit) {
      this.getRackData(1);
    }
  }

  ngOnDestroy() {
    if (this.rackSubscription) {
      this.rackSubscription.unsubscribe();
    }
    this.clearChangeSubsc();
  }

  setupChangeSubsc() {
    this.rackCd1Subscription = this.rackSelectCondition.formGroup.get("rackCd1").valueChanges.subscribe(
      data => this.rack1Changed(data)
    );
    this.rackCd2Subscription = this.rackSelectCondition.formGroup.get("rackCd2").valueChanges.subscribe(
      data => this.rack2Changed(data)
    );
    this.rackCd3Subscription = this.rackSelectCondition.formGroup.get("rackCd3").valueChanges.subscribe(
      data => this.rack3Changed(data)
    );
  }

  clearChangeSubsc() {
    if (this.rackCd1Subscription) {
      this.rackCd1Subscription.unsubscribe();
    }
    if (this.rackCd2Subscription) {
      this.rackCd2Subscription.unsubscribe();
    }
    if (this.rackCd3Subscription) {
      this.rackCd2Subscription.unsubscribe();
    }
  }

  addRack(rack: RackSearchDto) {
    if (rack.rackLevelFn == 1) {
      this.rack1data.push(rack);
    } else if (rack.rackLevelFn == 2) {
      this.rack2data.push(rack);
    } else {
      this.rack3data.push(rack);
    }
  }

  getRackData(rackLevel:number) {
    this.rackSubscription = this.httpBasic.rackSearch(
      this.rackSelectCondition.storeCd,
      rackLevel,
      this.rackSelectCondition.formGroup.get('rackCd1').value,
      this.rackSelectCondition.formGroup.get('rackCd2').value,
      this.rackSelectCondition.formGroup.get('rackCd3').value
    ).subscribe(
      data => this.setRackData(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.openSpinner(this.commonService.pageTitle, "検索中・・・");
  }

  clearProgressState() {
    this.closeSpinner();
    this.rackSubscription.unsubscribe();
    this.rackSubscription = undefined;
  }

  setRackData(data: RspRackSearch) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(data)) return;

    for (var rack of data.result) {
      switch (rack.rackLevelFn) {
        case 1:
          this.rack1data.push(rack);
          break;
        case 2:
          this.rack2data.push(rack);
          break;
        case 3:
          this.rack3data.push(rack);
          break;
      }
    }
    this.cdr.detectChanges();
  }

  public setStoreCd(storeCd: string) {
    this.rackSelectCondition.storeCd = storeCd;
    this.rackSelectCondition.formGroup.get("rackLevel").setValue(0);
    this.rackSelectCondition.formGroup.get("rackCd1").setValue(0);
    /* Renew rack1 data */
    this.rack1data.splice(1);
    this.getRackData(1);
    this.changed.emit();
  }

  rack1Changed(data: number) {
    this.rack1Name = this.rackDisplayNameByCode(this.rack1data, 1, data);
    this.rackSelectCondition.rack1Name = this.rackDisplayNameByCode(this.rack1data, 1, data);
    this.rackSelectCondition.formGroup.get("rackCd2").setValue(0);
    /* Renew rack2 data */
    this.rack2data.splice(1);
    if (this.rackSelectCondition.formGroup.get("rackCd1").value != 0) {
      this.rackSelectCondition.formGroup.get("rackLevel").setValue(1);
      this.getRackData(2);
    } else {
      this.rackSelectCondition.formGroup.get("rackLevel").setValue(0);
    }
    this.changed.emit();
  }

  rack2Changed(data: number) {
    this.rack2Name = this.rackDisplayNameByCode(this.rack2data, 2, data);
    this.rackSelectCondition.rack2Name = this.rackDisplayNameByCode(this.rack2data, 2, data);

    this.rackSelectCondition.formGroup.get("rackCd3").setValue(0);
    /* Renew rack3 data */
    this.rack3data.splice(1);
    if (this.rackSelectCondition.formGroup.get("rackCd2").value != 0) {
      this.rackSelectCondition.formGroup.get("rackLevel").setValue(2);
      this.getRackData(3);
    } else {
      this.rackSelectCondition.formGroup.get("rackLevel").setValue(1);
    }
    this.changed.emit();
  }

  rack3Changed(data: number) {
    this.rack3Name = this.rackDisplayNameByCode(this.rack3data, 3, data);
    this.rackSelectCondition.rack3Name = this.rackDisplayNameByCode(this.rack3data, 3, data);

    if (this.rackSelectCondition.formGroup.get("rackCd3").value != 0) {
      this.rackSelectCondition.formGroup.get("rackLevel").setValue(3);
    } else {
      this.rackSelectCondition.formGroup.get("rackLevel").setValue(2);
    }
    this.changed.emit();
  }

  rackDisplayName(rack: RackSearchDto) {
    return rack.rackNameFv;
  }

  rackDisplayNameByCode(rackData: RackSearchDto[], level: number, rackCd: number) {
    if (rackCd == 0) return "";

    for (var rackSearchDto of rackData) {
      if (rackSearchDto["rackCd" + level + "Fn"] == rackCd) {
        return rackSearchDto.rackNameFv;
      }
    }

    return "";
  }

  openSpinner(title: string, msg: string) {
    if (this.spinnerDialogRef) return;
    this.spinnerDialogRef = this.commonService.dialog.open(SpinnerDialogComponent, {
      disableClose: true,
      data: {title: title, message: msg}
    });
  }
  closeSpinner() {
    if (this.spinnerDialogRef) {
      this.spinnerDialogRef.close();
      this.spinnerDialogRef = undefined;
    }
  }



}
