import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { ItemRec } from 'src/app/common/item-rec';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReqParamCtgSearchDto } from 'src/app/request/req-param-ctg-search';
import { FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription } from 'rxjs';
import { CtgParamComponent } from 'src/app/partsCommon/ctg-param/ctg-param.component';
import { RspStockEditDto, RspStockEdit } from 'src/app/response/rsp-stock-edit';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';

@Component({
  selector: 'app-stock-edit-dialog',
  templateUrl: './stock-edit-dialog.component.html',
  styleUrls: ['./stock-edit-dialog.component.css']
})
export class StockEditDialogComponent implements OnInit {

  public ctgCondition: ReqParamCtgSearchDto;
  public stockNumForm : FormControl;
  public errorMessage : string;

  subscriptionUpdate : Subscription;
  @ViewChild(CtgParamComponent, {static: true}) ctgParamComponent: CtgParamComponent;
  @ViewChild(NumberKeypadComponent, {static: true}) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public dialogRef: MatDialogRef<StockEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public itemRec: ItemRec,
    public httpBasic: HttpBasicService,
    public commonService: CommonService) { }

  ngOnInit() {
    var level: number;
    if (this.itemRec.ctgCd0Fv && this.itemRec.ctgCd0Fv !== "") level = 0;
    if (this.itemRec.ctgCd1Fv && this.itemRec.ctgCd1Fv !== "") level = 1;
    if (this.itemRec.ctgCd2Fv && this.itemRec.ctgCd2Fv !== "") level = 2;
    if (this.itemRec.ctgCd3Fv && this.itemRec.ctgCd3Fv !== "") level = 3;

    this.ctgCondition = {
      storeCdFv: this.itemRec.storeCdFv,
      ctgLevelFn: level,
      ctgCd0Fv: this.itemRec.ctgCd0Fv,
      ctgCd1Fv: this.itemRec.ctgCd1Fv,
      ctgCd2Fv: this.itemRec.ctgCd2Fv,
      ctgCd3Fv: this.itemRec.ctgCd3Fv,
      rankFv: ""
    }

    this.stockNumForm = new FormControl(this.itemRec.stockNumCurrentFn,
      [Validators.required, (data) => {return this.valueCheck(data as FormControl);}]);

    if (this.numberKeypadComponent) this.numberKeypadComponent.clearAtFirstInput();
  }

  ngOnDestroy() {
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
    }
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  valueCheck(control: FormControl) {
    /* Note: control.value is string, so use == operator */ 
    if (control.value == this.itemRec.stockNumCurrentFn) {
      return {"NoChange": "値が変更されていません。"};
    } else {
      return null;
    }
  }

  onUpdate() {
    this.subscriptionUpdate = this.httpBasic.updateStockNum(this.itemRec, this.stockNumForm.value).subscribe(
      data => this.updateResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );

    this.commonService.openSpinner("在庫訂正", "更新中・・・");
  }

  clearProgressState () {
    this.commonService.closeSpinner();
    this.subscriptionUpdate.unsubscribe();
    this.subscriptionUpdate = undefined;
  }

  updateResult(rspStockEdit: RspStockEdit) {
    this.clearProgressState();

    if (rspStockEdit.fatalError && rspStockEdit.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("在庫訂正", rspStockEdit.fatalError[0].errMsg);
      return;
    }
    if (rspStockEdit.normalError && rspStockEdit.normalError.length > 0) {
      this.errorMessage = rspStockEdit.normalError[0].errMsg;
      return;
    }

    var rspStockEditDto: RspStockEditDto;
    rspStockEditDto = rspStockEdit.result;
    this.itemRec.setStockNum(rspStockEditDto.stockNumFn,
      rspStockEditDto.versionFn);

    this.dialogRef.close();
  }
}
