import { Observable, Observer } from 'rxjs';
import { AnonymousSubject, Subject } from 'rxjs/internal/Subject';

export class WebSockNeo7 {

  public neo7Window: Window;
  // public neo7Window: HTMLIFrameElement;
  public isConnected: boolean = false;
  public onmessage: (msg: string) => void;
  public onerror: (event: string) => void;
  public onopen: (msg: string) => void;
  public onclose: () => void;

  private listener = this.messageListener.bind(this);

  constructor() {
  }

  public messageListener(event: MessageEvent) {
    if (event.source !== this.neo7Window) return;
    // if (event.source !== this.neo7Window.contentWindow) return;
    if (!event.data) return;

    switch(event.data) {
      case "ready": {
        this.isConnected = true;
        if (this.onopen) this.onopen("connected");
        break;
      }
      case "closed": {
        this.isConnected = false;
        if (this.onclose) this.onclose();
        break;
      }
      case "error" : {
        if (this.onerror) this.onerror("error");
        break;
      }
      default: {
        if (this.onmessage) this.onmessage(event.data);
      }
    }
  }

  public connect(url): Subject<any> {
    /*
    let subj: Subject<any> = <Subject<any>>this.create(url).pipe(
      map((response: MessageEvent): any => {
              return response.data;
          }
      )
    );
    */
    let subj: Subject<any> = <Subject<any>>this.create(url);
    return subj;
  }

  private create(url): AnonymousSubject<MessageEvent> {
    window.addEventListener("message", this.listener);

    this.neo7Window = window.open(url + "?" + (new Date()).getTime(), "neo7");
    // var option ='left=0,top=0,width=100,height=50,menubar=no,toolbar=no,location=no,status=no,resizable=no,scrollbars=no';
    // this.neo7Window = window.open(url + "?" + (new Date()).getTime(), "_blank", option);

    if (!this.neo7Window) {
      return undefined;
    }

    let observable = new Observable((obs: Observer<MessageEvent>) => {
      this.onmessage = obs.next.bind(obs);
      this.onerror = obs.error.bind(obs);
      this.onopen = obs.next.bind(obs);
      this.onclose = obs.complete.bind(obs);
      return this.unsubsc.bind(this);
    });
    let observer = {
        error: null,
        complete: null,
        next: (data) => {
            if (this.isConnected) {
                // this.neo7Window.contentWindow.postMessage({ message: data }, "*");
                this.neo7Window.postMessage({ message: data }, "*");
            } else if (this.onerror) {
              this.onerror("not connected");
            }
        }
    };

    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  public disconnect() {
    this.clearConnection();
  }

  public clearConnection() {
    if (this.isConnected && this.onclose) {
      this.onclose();
    }
    this.neo7Window?.close();
    /*
    if (this.neo7Window) {
      this.neo7Window.contentWindow?.close();
      this.neo7Window.src = undefined;
    }
    */
    this.neo7Window = undefined;
    this.isConnected = false;
    this.onmessage = undefined;
    this.onerror = undefined;
    this.onopen = undefined;
    this.onclose = undefined;
    window.removeEventListener("message", this.listener);
  }

  unsubsc() {
    this.onmessage = undefined;
    this.onerror = undefined;
    this.onopen = undefined;
    this.onclose = undefined;
  }
}
