import { CommonService } from 'src/app/service/common.service';
import { ReqTaskImageThumbnailData, RspSubTaskResultOtherStoresRowDto, TaskImageRecDto } from 'src/app/webservice/task';
import { TaskService } from '../1_service/taskService';
import { PhotoItem, SubTask } from './taskDefs';

export class SubTaskOtherStoresResultRecDto {

  public taskId: number;
  public storeCd: string;
  public storeName: string;
  public subTaskId: number;
  public subTaskFv: string;
  public subResponsible: string;
  public isComplete: number;
  public completeDateTime: string;
  public imageList: TaskImageRecDto[];
  public isShowIcon: boolean = false;
  public storeNameWithCode: string = '';
  public subTasks: SubTask[] = [];
  public description: string = '';
  public subTaskName: string;
  public currentImageDisplay: TaskImageRecDto;
  public currentImageDisplayIndex: number = -1;
  public maxImageDisplayIndex: number = -1;
  public completeStatus: string = '';

  constructor(
    rowDto: RspSubTaskResultOtherStoresRowDto,
    private commonService: CommonService,
    private taskService: TaskService
  ) {
    this.patchValue(rowDto);
  }

  patchValue(obj: RspSubTaskResultOtherStoresRowDto): void {
    for (let key in obj) {
      this[key] = obj[key];
    }
    this.storeNameWithCode = this.storeCd + ":" + this.storeName;

    if (obj.imageList && obj.imageList.length > 0) {
      this.imageList = [];
      obj.imageList.forEach((dto) => {
        let imageRec = {...dto};
        imageRec.emoteList = [...dto.emoteList];
        this.imageList.push(imageRec);
      });
      this.maxImageDisplayIndex = this.imageList.length - 1;
      if (this.currentImageDisplayIndex < this.imageList.length) {
        this.currentImageDisplay = this.imageList[this.currentImageDisplayIndex];
      }
    } else {
      this.imageList = undefined;
      this.maxImageDisplayIndex = -1;
    }

    this.completeStatus = (this.isComplete && this.isComplete === 1) ? '完了' : '未完了'

    try {
      this.subTasks = JSON.parse(this.subTaskFv)
      this.description = this.subTasks.find((item) => item.subTaskId == this.subTaskId).description;
      this.subTaskName = this.subTasks.find((item) => item.subTaskId == this.subTaskId).subTaskName;

    } catch (error) {
      this.subTasks = []
    }
  }

  nextImage(callback?: (item) => any) {
    if (this.currentImageDisplayIndex >= this.maxImageDisplayIndex) {
      return;
    }
    const index = this.currentImageDisplayIndex + 1;
    if (!this.imageList[index]) return;
    const next = this.imageList[index];
    if (!next.imageThumbnail) {
      let req: ReqTaskImageThumbnailData = {
        access: this.commonService.loginUser,
        taskId: next.taskId,
        storeCd: next.storeCd,
        subTaskId: next.subTaskId,
        imageId: next.imageId
      }
      this.taskService.getImageThumbnailData(req, (res) => {
        next.imageThumbnail = res.imageData;
        this.imageList[index].emoteList = res.emoteList;
        
        this.currentImageDisplayIndex = index;
        this.currentImageDisplay = { ...next }
        if (callback) callback(this);
      })
    } else {
      this.currentImageDisplayIndex = index;
      this.currentImageDisplay = { ...next };
      if (callback) callback(this);
      return;
    }
  }

  prevImage(callback?: (item) => any) {
    if (this.currentImageDisplayIndex <= 0) {
      return;
    }
    const index = this.currentImageDisplayIndex - 1;
    if (!this.imageList[index]) return;
    const prev = this.imageList[index];
    if (!prev.imageThumbnail) {
      let req: ReqTaskImageThumbnailData = {
        access: this.commonService.loginUser,
        taskId: prev.taskId,
        storeCd: prev.storeCd,
        subTaskId: prev.subTaskId,
        imageId: prev.imageId
      }
      this.taskService.getImageThumbnailData(req, (res) => {
        prev.imageThumbnail = res.imageData;
        this.currentImageDisplayIndex = index;
        this.imageList[index].emoteList = res.emoteList;
        this.currentImageDisplay = { ...prev }
        if (callback) callback(this);
      })
    }
    else {
      this.currentImageDisplayIndex = index;
      this.currentImageDisplay = { ...prev };
      if (callback) callback(this);
      return;
    }
  }

  isFirstImage(): boolean {
    return this.currentImageDisplayIndex <= 0;
  }

  isLastImage(): boolean {
    return this.currentImageDisplayIndex >= this.maxImageDisplayIndex;
  }

  prepareViewImage(callback?: (item: TaskImageRecDto) => any) {
    if (this.currentImageDisplay.image) {
      if (callback) callback({ ...this.currentImageDisplay });
      return;
    }
    let req: PhotoItem = {
      taskId: this.currentImageDisplay.taskId,
      storeCd: this.currentImageDisplay.storeCd,
      subTaskId: this.currentImageDisplay.subTaskId,
      photoId: this.currentImageDisplay.imageId,
      title: this.currentImageDisplay.imageTitle
    }
    this.taskService.getImageData(req, (res) => {
      this.currentImageDisplay.image = res.dataUrl;
      if (callback) callback({ ...this.currentImageDisplay });
      return;
    })
  }
}
