
import { Injectable } from "@angular/core";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";

@Injectable({
    providedIn: 'root'
})
export class Service {

    constructor(
        public commonService: CommonService,
        public httpBasic: HttpBasicService
    ) { }

    /* other services */
    isDateValid(input: string | Date): boolean {
        if (!input) return false;
        if (typeof input == 'string') {
            if (input.trim() == '') return false;
            const date = new Date(input);
            return (date instanceof Date && !isNaN(date.valueOf()));
        }

        return (input instanceof Date && !isNaN(input.valueOf()));
    }

    getDateDaily(dateNext: number) {
        const today = new Date();
        const tomorrow = new Date();

        // Cộng thêm n day ngày
        tomorrow.setDate(today.getDate() + dateNext);

        const monthDate = tomorrow.getMonth() + 1;
        const dayDate = tomorrow.getDate();
        // Lấy số thứ tự của ngày hiện tại
        let current_day = tomorrow.getDay();

        // Biến lưu tên của thứ
        let day_name = '';

        // Lấy tên thứ của ngày hiện tại
        switch (current_day) {
            case 0:
                day_name = "(日)";
                break;
            case 1:
                day_name = "(月)";
                break;
            case 2:
                day_name = "(火)";
                break;
            case 3:
                day_name = "(水)";
                break;
            case 4:
                day_name = "(木)";
                break;
            case 5:
                day_name = "(金)";
                break;
            case 6:
                day_name = "(土)";
        }

        return `${('0' + monthDate).slice(-2)}/${('0' + dayDate).slice(-2)}${day_name}`;
    }
}
