import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqGetOrderGroupUserMulti, ReqUpdateOrderGroupUserMulti, RspGetOrderGroupUserMulti, RspUpdateOrderGroupUserMulti, UpdateOrderGroupUserMultiDto } from 'src/app/webservice/order-group-user-multi';
import { OrderGroup2Rec } from './0_defs/order-group';
import { OrderGroupUser2Rec } from './0_defs/order-group-user';
import { StoreUser2Rec } from './0_defs/store-user';

@Component({
  selector: 'app-order-group-user-multi',
  templateUrl: './order-group-user-multi2.component.html',
  styleUrls: ['./order-group-user-multi2.component.css']
})
export class OrderGroupUserMulti2Component implements OnInit, OnDestroy {

  public isDirty: boolean = false;
  public tabSelection: number = 0;
  public storeForm: FormControl = new FormControl();
  public minBeginDate: Date;
  public minEndDate: Date;
  public activeForm: FormControl = new FormControl(true);
  public notActiveForm: FormControl = new FormControl(true);

  private orderGroupUsers: OrderGroupUser2Rec[];
  public groupMap = {};
  public groupList: OrderGroup2Rec[] = [];
  public groupShowList: OrderGroup2Rec[] = [];
  public selectedGroup: OrderGroup2Rec;
  public userUserMap = {};
  public userList: StoreUser2Rec[] = [];
  private deleteStoreUsers: UpdateOrderGroupUserMultiDto[] = [];

  public columnIds: string[] = [
    "dateBegin", "groupUsrCd", "groupName", "actDateBegin"
  ];
  public columnDefs: TableColumnDef[] = [
    // {columnId: "groupUsrCd", header: "コード", width: 50, align: "center"},
    // {columnId: "groupName", header: this.commonService.literal.orderGroup, width: 150, rowTooltip: true},
    // {columnId: "dateBegin", header: "有効日", width: 70, align: "center"},
    {columnId: "assignCount", header: "担当者数", width: 60, align: "right"},
    {columnId: "actDateBegin", header: "開始日(現)", width: 70, align: "center"}
  ];
  public columnDefsForm: TableColumnDef[] = [
    {columnId: "nextDateBegin", header: "開始日(次)", width: 115, align: "center"}
  ];

  public subsc: Subscription[] = [];

  /*
  @ViewChild("byGroupUserList", {static: false, read: MatTable}) byGroupUserListTable: MatTable<any>;
  @ViewChild("byUserGroupList", {static: false, read: MatTable}) byUserGroupListTable: MatTable<any>;
  */

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService
  ) {
    this.setupColumnDef();
  }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.storeForm.setValue(this.commonService.loginUser.storeCd);

    this.minBeginDate = new Date();
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minBeginDate.getDate() - 1);

    this.subsc.push(
      this.activeForm.valueChanges.subscribe(() => this.filterGroup())
    );
    this.subsc.push(
      this.notActiveForm.valueChanges.subscribe(() => this.filterGroup())
    );
  }

  ngOnDestroy(): void {
    this.groupList.forEach((item) => item.cleanupEdit());
    this.userList.forEach((item) => item.cleanupEdit());
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    this.setMaxHeight("group-box");
  }

  setMaxHeight(id: string) {
    let remHeight = this.commonService.getHeightBelow(id);
    // let paginatorHeight = 56;
    let paginatorHeight = 0;
    let margin = 10 + 5;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  setupColumnDef() {
    let def = {columnId: "actUser1", header: "担当者名(現)", width: 100, rowTooltip: true};
    let defForm = {columnId: "nextUser1", header: "担当者名(次)", width: 160, align: "center", rowTooltip: true};

    for (let i = 0; i < this.commonService.config.orderGroup.maxUser; i++) {
      let newDef = {...def};
      let newDefForm = {...defForm};
      newDef.columnId = "actUser" + (i + 1);
      newDefForm.columnId = "nextUser" + (i + 1);
      this.columnDefs.push(newDef);
      this.columnIds.push(newDef.columnId);
      this.columnDefsForm.push(newDefForm);
    }
    this.columnIds.push("nextDateBegin");
    for (let i = 0; i < this.commonService.config.orderGroup.maxUser; i++) {
      this.columnIds.push("nextUser" + (i + 1));
    }
  }

  tabChanged(event: number) {
    this.setTableHeight();
  }

  getOrderGroupUserMulti() {
    let request: ReqGetOrderGroupUserMulti = {
      access: this.commonService.loginUser,
      storeCd: this.storeForm.value
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderGroupUserMulti", request).subscribe(
      (response: RspGetOrderGroupUserMulti) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveOrderGroupUserMulti(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveOrderGroupUserMulti(response: RspGetOrderGroupUserMulti) {
    if (this.httpBasic.handleAppError(response)) return;

    this.groupList.forEach((rec) => {rec.endEdit();});

    this.groupList = [];
    this.groupMap = {};
    this.userList = [];
    this.userUserMap = {};
    this.orderGroupUsers = [];
    this.selectedGroup = undefined;
    this.deleteStoreUsers = [];

    response.orderGroups.forEach((dto) => {
      let rec = new OrderGroup2Rec(this.commonService);
      rec.storeCd = dto.storeCd;
      rec.groupId = dto.orderGroupId;
      rec.groupUsrCd = dto.orderGroupUsrCd;
      rec.groupName = dto.orderGroupName;
      rec.dateBegin = this.commonService.getDate(dto.beginDate);
      rec.dateEnd = this.commonService.getDate(dto.endDate);
      rec.setListner(() => {this.setDirty(true);});
      this.groupList.push(rec);
      this.groupMap["" + rec.groupId] = rec;
    });

    response.storeUsers.forEach((dto) => {
      let rec = new StoreUser2Rec(this.commonService);
      rec.storeCd = dto.storeCd;
      rec.userId = dto.userId;
      rec.userName = dto.userName;
      this.userList.push(rec);
      this.userUserMap[rec.userId] = rec;
    });

    response.orderGroupUsers.forEach((dto) => {
      let rec = new OrderGroupUser2Rec(this.commonService);
      rec.storeCd = dto.storeCd;
      rec.userId = dto.userId;
      rec.groupId = dto.groupId;
      rec.dateBegin = this.commonService.getDate(dto.beginDate);
      rec.dateEnd = this.commonService.getDate(dto.endDate);

      let group: OrderGroup2Rec = this.groupMap["" + rec.groupId];
      let user: StoreUser2Rec = this.userUserMap[rec.userId];
      if (group != undefined && user != undefined) {
        rec.groupUsrCd = group.groupUsrCd;
        rec.groupName = group.groupName;
        rec.groupDateBegin = group.getValue("dateBegin");
        rec.userName = user.userName;
        rec.prepareEdit();
        this.orderGroupUsers.push(rec);

        group.addUser(rec);
        user.addGroup(rec);
      } else {
        // invalid record
        let delDto: UpdateOrderGroupUserMultiDto = {
          storeCd: dto.storeCd,
          userId: dto.userId,
          groupId: dto.groupId,
          dateBegin: dto.beginDate,
          dateEnd: dto.endDate,
          isDelete: true
        };
        this.deleteStoreUsers.push(delDto);
      }
    });

    this.groupList.forEach((rec) => rec.prepareEdit());
    this.userList.forEach((rec) => rec.prepareEdit());
    this.userList.sort((a, b) => {
      if (a.userName < b.userName) return -1;
      if (a.userName > b.userName) return 1;
      return 0;
    });

    this.filterGroup();
    this.setTableHeight();
  }

  clearSelectedGroup() {
    this.selectedGroup = undefined;
  }

  filterGroup() {
    this.groupShowList = [];
    this.groupList.forEach((rec) => {
      if (rec.getValue("dateBegin") === "") {
        if (this.activeForm.value) {
          this.groupShowList.push(rec);
        } else {
          if (rec == this.selectedGroup) this.clearSelectedGroup();
        }
      } else {
        if (this.notActiveForm.value) {
          this.groupShowList.push(rec);
        } else {
          if (rec == this.selectedGroup) this.clearSelectedGroup();
        }
      }
    });
  }

  clickRow(item: OrderGroup2Rec) {
    this.selectedGroup = item;
  }

  setDirty(val: boolean) {
    this.isDirty = val;
    if (val) {
      this.storeForm.disable();
    } else {
      this.storeForm.enable();
    }
  }

  saveEdit() {
    let updateList: UpdateOrderGroupUserMultiDto[] = [...this.deleteStoreUsers];
    this.orderGroupUsers = [];
    this.groupList.forEach((rec) => {
      updateList = [...updateList, ...rec.saveEdit()];
    });

    let request: ReqUpdateOrderGroupUserMulti = {
      access: this.commonService.loginUser,
      updateList: updateList
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdateOrderGroupUserMulti", request).subscribe(
      (response: RspUpdateOrderGroupUserMulti) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveUpdate(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpdate(response: RspUpdateOrderGroupUserMulti) {
    if (this.httpBasic.handleAppError(response)) return;
    this.setDirty(false);

    this.getOrderGroupUserMulti();
  }

  cancelEdit() {
    this.groupList.forEach((rec) => rec.cleanupEdit());
    this.userList.forEach((rec) => rec.cleanupEdit());
    this.orderGroupUsers.forEach((rec) => rec.prepareEdit());
    this.groupList.forEach((rec) => rec.prepareEdit());
    this.userList.forEach((rec) => rec.prepareEdit());
    this.setDirty(false);
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
