import { Component, OnInit, Inject, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Spmt10331Dto, ReqSpmt10331Confirm } from 'src/app/request/req-spmt10331-confirm';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription } from 'rxjs';
import { ErrorNotificationDialogComponent } from '../error-notification-dialog/error-notification-dialog.component';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { FormControl } from '@angular/forms';
import { AccessInfo } from 'src/app/page/spmt10331/spmt10331.component';
import { MatPaginator } from '@angular/material/paginator';
import { Rsp } from 'src/app/response/rsp';
import { MI200002, MQ200003 } from 'src/app/common/const-message-id';
import { MessageService } from 'src/app/service/message.service';

class Spmt10331ConfirmDialogConstant{
  public static readonly MESSAGESEARCHING: string = '検索中・・・';
}

interface Spmt10331ConfirmTableColumnDef extends TableColumnDef{
  isInputText: boolean;
}

class ItemSelect{
  slipNo: string;
  orgSlipNo: string;
  storeCd: string;
	redBlackFlg: string;
	reqStatus: string;
	reqStatusNm: string;
	totalDtQty: string;
	totalOrderPriceExc: string;
	slipCreateDate: string;
	requesterNm: string;
  approverNm: string;
	slipComment: string;
	approverComment: FormControl;
	delflg: string;
	updDatetime: string;
}

@Component({
  selector: 'app-spmt10331-confirm-dialog',
  templateUrl: './spmt10331-confirm-dialog.component.html',
  styleUrls: ['./spmt10331-confirm-dialog.component.css']
})
export class Spmt10331ConfirmDialogComponent implements OnInit, AfterViewInit {
  public columnIds: string[] = ["slipNo","orgSlipNo","reqStatusNm","totalDtQty","totalOrderPriceExc","slipCreateDate","requesterNm","approverNm","slipComment","approverComment"];

  public columnDefsItems: Spmt10331ConfirmTableColumnDef[] = [
    {columnId: "slipNo", header: "伝票番号", width: 80, align: "left", isInputText: false},
    {columnId: "orgSlipNo", header: "元伝票番号", width: 80, align: "left", isInputText: false},
    {columnId: "reqStatusNm", header: "申請状態", width: 60, align: "left", isInputText: false},
    {columnId: "totalDtQty", header: "数量合計", width: 70, align: "right", isInputText: false, numberPipe: "1.0-0"},
    {columnId: "totalOrderPriceExc", header: "原価金額合計", width: 90, align: "right", isInputText: false, numberPipe: "1.2-2"},
    {columnId: "slipCreateDate", header: "伝票作成日", width: 80, align: "center", isInputText: false},
    {columnId: "requesterNm", header: "申請者", width: 100, align: "left", isInputText: false},
    {columnId: "approverNm", header: "承認者", width: 100, align: "left", isInputText: false},
    {columnId: "slipComment", header: "申請者コメント", width: 150, align: "left", isInputText: false},
    {columnId: "approverComment", header: "承認者コメント", width: 150, align: "left", isInputText: true}
  ];

  public items: ItemSelect[] = [];
  public itemsShow: ItemSelect[] = [];
  public itemTableWidth: any;
  public itemRecordCount: number = 0;
  private subcriptionYesNoDialog: Subscription;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10331ConfirmDialogComponent>,
    public httpBasic: HttpBasicService,
    private message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: {searchResult: Spmt10331Dto[], storeCd: string, accessInfo: AccessInfo},
    public commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.calcTableWidth();
  }

  ngAfterViewInit() {
    this.initTable();
    this.changeDetectorRef.detectChanges();
  }

  initTable(){
    this.data.searchResult.forEach((item: Spmt10331Dto) => {
      this.items.push({
        ...item,
        approverComment: new FormControl('')
      });
    })

    this.itemRecordCount = this.items.length ?? 0;
    this.pageChanged();
  }

  calcTableWidth() {
    let width = 1
    for (let colDef of this.columnDefsItems) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = {"width": "100%"};
  }

  getRowData(){
    return this.items.map((item: ItemSelect) => ({
      ...item,
      approverComment: item.approverComment.value
    }));
  }

  confirmBtn(){
    this.subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200003]).subscribe(data => {
      if(data) this.confirmPrc();
      this.subcriptionYesNoDialog.unsubscribe();
    })
  }

  confirmPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10331ConfirmDialogConstant.MESSAGESEARCHING);
    const request: ReqSpmt10331Confirm = {
      rows: this.getRowData(),
      storeCd: this.data.storeCd,
      access: this.data.accessInfo
    } 
    const subsc = this.httpBasic.confirmSpmt10331(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: Rsp) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });

      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.dialogRef.close(true);
        subcription.unsubscribe();
      });

      return;
    }

    const dialogConfirmRef = this.commonService.dialog.open(NotificationDialogComponent, {
      data: {title: this.commonService.pageTitle, message: this.message.message[MI200002]}
    });
    const subcription = dialogConfirmRef.afterClosed().subscribe(() => {
      this.dialogRef.close(true);
      subcription.unsubscribe();
    });
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }
}