import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ReqAccess } from "src/app/request/req-access";
import { ReqSortConditionConfig } from "src/app/request/req-sort-condition-config";
import { RspSortConditionConfigDto } from "src/app/response/rsp-sort-condition-config";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { Spmt10341DetailDialogComponent } from "../spmt10341-detail-dialog/spmt10341-detail-dialog.component";
interface ReqSortCondition {
  screenId: string;
  access: ReqAccess | any;
  sortConfig: RspSortConditionConfigDto[];
}
@Component({
  selector: "app-sort-condition-dialog",
  templateUrl: "./sort-condition-dialog.component.html",
  styleUrls: ["./sort-condition-dialog.component.css"],
})
export class SortConditionDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SortConditionDialogComponent>,
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    @Inject(MAT_DIALOG_DATA) public data: ReqSortCondition
  ) {}
  sortList: RspSortConditionConfigDto[] = [];

  sortPick: RspSortConditionConfigDto[] = [];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
  ngOnInit(): void {
    let req: ReqSortConditionConfig = {
      access: this.data.access,
      screenId: this.data.screenId,
    };
    this.commonService.openSpinner(this.commonService.pageTitle, "読み込み中...");
    this.httpBasic.getSortConditionConfig(req).subscribe({
      next: (rsp) => {
        if (this.httpBasic.handleAppError(rsp)) return;
        if (rsp.sortConditionList?.length === 0) return;
        if (this.data.sortConfig?.length === 0) {
          this.sortList = rsp.sortConditionList.filter((x) => !x.sortDefault);
          this.sortPick = rsp.sortConditionList.filter((x) => x.sortDefault);
        } else {
          this.sortPick = [...this.data.sortConfig];
          this.sortList = rsp.sortConditionList.filter((x) => this.sortPick.find((y) => y.sortField === x.sortField) === undefined);
        }
      },
      error: (err) => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(err);
      },
      complete: () => {
        this.commonService.closeSpinner();
      },
    });
  }

  btnPickClick() {
    this.dialogRef.close(
      this.sortPick.map((x: any, index: number) => {
        return {
          sortField: x.sortField,
          sortType: x.sortType,
          sortDefault: true,
          sortItemNm: x.sortItemNm,
          sortOrder: index,
        };
      })
    );
  }
}
