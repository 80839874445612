import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ExternalLink } from 'src/app/common/ExternalLink';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { RspGetExternalLink } from 'src/app/response/rsp-get-external-link';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.css']
})
export class ExternalLinkComponent implements OnInit, OnDestroy, AfterViewChecked {

  public stores: LoginStoreDto[];
  public storeForm : FormControl = new FormControl(this.commonService.loginUser.storeCd);
  public iFrameForm : FormControl = new FormControl(true);
  public tableWidth: any;
  public columnIds: string[] = ["storeFv", "linkNameFv", "commentFv"];
  public columnDefs: TableColumnDef[];
  public linkList: ExternalLink[] = [];
  public openList: ExternalLink[] = [];
  public openListList: any[];
  public openWindows: {storeCdFv: string, linkCdFv: string, win:WindowProxy}[] = [];

  private subscriptionRoute: Subscription;
  private subscriptionQuery: Subscription;
  private linkTypeId: string = "";
  private linkTypeName: string = "";
  private linkContainerElement: HTMLElement;
  private linkContainerWidth: number;
  private scrollHeight: number;
  private scrollTop: number;

  constructor(
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    public http: HttpClient) {
  }

  ngOnInit() {
    // this.commonService.pageTitle = "";
    this.commonService.pageTitle = this.commonService.pageMenuName;

    if (this.commonService.stores.length > 1) {
      this.stores = [{storeCd: "*", storeName:"全店"}, ...this.commonService.stores];
    } else {
      this.stores = [...this.commonService.stores];
    }

    this.subscriptionRoute = this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.linkTypeId = params.get("linkid");
        this.getLinkList();
      }
    );

    this.columnDefs = [
      {columnId:'storeFv', header:"店舗", width:150},
      {columnId:'linkNameFv', header:this.linkTypeName, width:150, editable: true},
      {columnId:'commentFv', header:"備考", width:300}
    ];
    this.calcTableWidth();

    window.addEventListener("resize", ()=>{this.resized();});
  }

  ngOnDestroy() {
    if (this.subscriptionRoute) this.subscriptionRoute.unsubscribe();
    // if (this.subscriptionIFrame) this.subscriptionIFrame.unsubscribe();
    if (this.subscriptionQuery) this.subscriptionQuery.unsubscribe();

    window.removeEventListener("resize", ()=>{this.resized();});
    this.closeAllWindows();
  }

  ngAfterViewChecked() {
    this.initScroll();
  }

  resized() {
    this.initScroll();
    this.layoutLink();
  }

  initScroll() {
    this.linkContainerElement = document.getElementById("link-container");
    var clientRect = this.linkContainerElement.getBoundingClientRect();
    this.scrollTop = clientRect.top;
    this.linkContainerWidth = clientRect.width - 5; // 5 for margin-right 
    this.scrollHeight = window.innerHeight - this.scrollTop - 45;
    this.linkContainerElement.style["height"] = "" + this.scrollHeight + "px";
  }

  clearProgressState () {
    if (this.subscriptionQuery) this.subscriptionQuery.unsubscribe();
    this.subscriptionQuery = undefined;
    this.commonService.closeSpinner();
  }

  getLinkList() {
    if (this.commonService.pageTitle === "") this.findLinkName();



    /*
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'Access-Control-Allow-Origin': "*"
      }),
      observe: "response",
      responseType: "blob"
    };
  
    this.http.get(
      this.commonService.config.camURL
    ).subscribe(
      (response) => {console.log(response);},
      (error) => {
        console.log("GET error --->");
        console.log(error);
        console.log("<--- GET error");
      }
    );
    */






    this.closeAllLink();

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.getExternalLink(this.storeForm.value, this.linkTypeId).subscribe(
      (response) => this.receiveList(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveList(response: RspGetExternalLink) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.linkList = [];
    this.openList = [];

    for (let item of response.externalLinks) {
      let link = new ExternalLink(item);
      link.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(link.urlFv);
      this.linkList.push(link);
    }
  }

  findLinkName() {
    this.linkTypeName = "カメラ";   // This is prototype impl.
    this.commonService.pageTitle = this.linkTypeName;
  }

  calcTableWidth() {
    var width = 1;                // For left border
    // width = width + 80 + 1 + 8 + 80 + 1 + 8;   // 開く、閉じるボタン
    for (var colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = {"width": "" + width + "px"};
  }

  styleFor(id:string) {
    return this.styleForBody(this.columnDefs, id);
  }
  styleForBody(colDefs: TableColumnDef[], id:string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id:string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }
  styleForHeaderBody(colDefs: TableColumnDef[], id:string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  onClickCell(id: string, link: ExternalLink) {
    if (id === "linkNameFv") this.openLink(link);
  }

  layoutLink() {
    this.openListList = [];
    let layoutWidth: number = 0;
    let list: ExternalLink[] = [];

    for (let link of this.openList) {
      if (layoutWidth + link.sizeXFn + 10 > this.linkContainerWidth) {
        if (layoutWidth == 0) {
          list.push(link);
        }
        this.openListList.push(list);
        list = [];
        layoutWidth = 0;
      }
      layoutWidth += link.sizeXFn + 10;
      list.push(link);
    }
    if (list.length > 0) {
      this.openListList.push(list);
    }
    this.changeDetectorRef.detectChanges();
  }

  openLink(link: ExternalLink) {
    if(this.iFrameForm.value) {
      for (let opened of this.openList) {
        if (opened.storeCdFv === link.storeCdFv &&
            opened.linkCdFv === link.linkCdFv) return;
      }
      this.openList.push(link);
      this.layoutLink();
    } else {
      let win = window.open(link.urlFv, link.storeCdFv+":"+link.linkCdFv);
      for (let opened of this.openWindows) {
        if (opened.storeCdFv === link.storeCdFv &&
            opened.linkCdFv === link.linkCdFv) return;
      }
      this.openWindows.push({
        storeCdFv: link.storeCdFv,
        linkCdFv: link.linkCdFv,
        win: win
      });
    }
  }

  closeLink(link: ExternalLink) {
    // console.log(link.linkCdFv);
    for (let i = 0; i < this.openList.length; i++) {
      if (this.openList[i].storeCdFv === link.storeCdFv &&
          this.openList[i].linkCdFv === link.linkCdFv) {
            this.openList.splice(i, 1);
            this.layoutLink();
            break;
      }
    }
    for (let i = 0; i < this.openWindows.length; i++) {
      if (this.openWindows[i].storeCdFv === link.storeCdFv &&
        this.openWindows[i].linkCdFv === link.linkCdFv) {
          this.openWindows[i].win.close();
          this.openWindows.splice(i, 1);
          break;
      }
    }
  }

  closeAllLink() {
    this.openList = [];
    this.openListList = [];
  }

  closeAllWindows() {
    for (let win of this.openWindows) {
      win.win.close();
    }
  }

}
