import { formatNumber } from "@angular/common";
import { FormControl } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CommonService } from "src/app/service/common.service";

export class ApprovalItem {
  public static APPROVAL_NOT_REQUIRED: number   = 0;
  public static APPROVAL_ALWAYS: number         = 1;
  public static APPROVAL_COST: number           = 2;
  public static APPROVAL_ORDER_NUM: number      = 3;

  public static STAT_NOT_PROC: number           = 0;
  public static STAT_APPROVED: number           = 1;
  public static STAT_NOT_APPROVED: number       = 2;
  public static STAT_CANCELED: number           = 3;    // Order is editted after approval

  public storeCdFv: string              = "";
  public itemCdFv: string               = "";
  public itemNameFv: string             = "";
  public standardFv: string             = "";
  public ctgCd0Fv: string               = "";
  public ctgName0Fv: string             = "";
  public ctgCd1Fv: string               = "";
  public ctgName1Fv: string             = "";
  public ctgCd2Fv: string               = "";
  public ctgName2Fv: string             = "";
  public ctgCd3Fv: string               = "";
  public ctgName3Fv: string             = "";
  public suppNameFv: string             = "";
  public orderableDateFv: string        = "";
  public orderLotFn: number             = 0;
  public itemCostPriceFn: number        = 0;
  public itemSalesPriceFn: number       = 0;
  public orderStopFlagFn: number        = 0;
  public orderDateFd: string            = "";
  public deliveryDateFd: string         = "";
  public orderNumFn: number             = 0;
  public orderNumModFn: number          = 0;
  public orderNumVersionFn: number      = 0;
  public rankFv: string                 = "";
  public closingTimeFn: number          = 0;
  public approvalType: number           = 0;
  public approvalBoundary: number       = 0;
  public appApprovalType: number        = 0;
  public appApprovalBoundary: number    = 0;
  public appOrderNum: number            = 0;
  public appOrderBaraNum: number        = 0;
  public appCostPrice: number           = 0;
  public appApproval: number            = 0;
  public appApprovalComment: string     = "";
  public appApprovalDate: string        = "";
  public appApprovalTime: string        = "";
  public appUserId: string              = "";
  public appUserName: string            = "";

  /* calculated properties */
  public orderBaraNumFn: number         = 0;
  public orderCostPriceFn: number       = 0;

  private subsc: Subscription[]         = [];
  private changeListener: ()=>void;
  private commentOptions: string[];
  private filteredOptions: Observable<string[]>;

  public edit: {
    isDirty:        boolean;
    approvalForm:   FormControl;
    commentForm:    FormControl;
  }

  constructor(private commonService: CommonService) {
  }

  initByDto(dto) {
    for (let key in dto) {
      this[key] = dto[key];
    }

    this.orderBaraNumFn = this.orderLotFn * (this.orderNumVersionFn > 0 ? this.orderNumModFn : this.orderNumFn);
    this.orderCostPriceFn = this.orderBaraNumFn * this.itemCostPriceFn;
  }

  setListner(func:()=>void) {
    this.changeListener = func;
  }

  appRequire() {
    if (this.approvalType == ApprovalItem.APPROVAL_ALWAYS) return true;
    if (this.approvalType == ApprovalItem.APPROVAL_ORDER_NUM && this.orderBaraNumFn >= this.approvalBoundary) return true;
    if (this.approvalType == ApprovalItem.APPROVAL_COST && this.orderCostPriceFn >= this.approvalBoundary) return true;
    return false;
  }

  getApprovalTypeName(val: number) {
    switch(val) {
      case ApprovalItem.APPROVAL_NOT_REQUIRED: return "承認不要";
      case ApprovalItem.APPROVAL_ALWAYS: return "常に必要";
      case ApprovalItem.APPROVAL_ORDER_NUM: return "数量>=" + formatNumber(this.approvalBoundary, this.commonService.locale); // this.approvalBoundary;
      case ApprovalItem.APPROVAL_COST: return "金額>=" + formatNumber(this.approvalBoundary, this.commonService.locale); // this.approvalBoundary;
    }
    return "";
  }

  getApprovalName(val: number) {
    if (val < 0) val = -val;
    switch(val) {
      case ApprovalItem.STAT_APPROVED: return "承認";
      case ApprovalItem.STAT_NOT_APPROVED: return "否認";
      case ApprovalItem.STAT_NOT_PROC: return "未処理";
    }
    return "";
  }

  notProc() {
    if (this.appApproval = ApprovalItem.STAT_NOT_PROC) return true;
    if (this.appApproval < 0) return true;
    return false;
  }

  getValue(key: string) {
    switch(key) {
      case "orderRecommendNumFn":
        return this.orderNumFn;
      case "orderNumFn":
        return this.orderNumVersionFn > 0 ? this.orderNumModFn : this.orderNumFn;
      case "costPriceFn":
        return this.orderCostPriceFn;
      case "orderStopFv":
        return this.commonService.getOrderStopFlagName(this.orderStopFlagFn);
      case "approvalTypeFv":
        return this.getApprovalTypeName(this.approvalType);
      case "prevOrderNumFn":
        if (this.approvalType == ApprovalItem.APPROVAL_NOT_REQUIRED) return "";
        // if (this.appApproval != ApprovalItem.STAT_NOT_PROC) return this.appOrderNum;
        if (this.appApproval < 0) return this.appOrderNum;
        return "";
      case "prevOrderBaraNumFn":
        if (this.approvalType == ApprovalItem.APPROVAL_NOT_REQUIRED) return "";
        // if (this.appApproval != ApprovalItem.STAT_NOT_PROC) return this.appOrderBaraNum;
        if (this.appApproval < 0) return this.appOrderBaraNum;
        return "";
      case "prevCostPriceFn":
        if (this.approvalType == ApprovalItem.APPROVAL_NOT_REQUIRED) return "";
        // if (this.appApproval != ApprovalItem.STAT_NOT_PROC) return this.appCostPrice;
        if (this.appApproval < 0) return this.appCostPrice;
        return "";
      case "prevApprovalFv":
        if (this.approvalType == ApprovalItem.APPROVAL_NOT_REQUIRED) return "";
        // if (this.appApproval != ApprovalItem.STAT_NOT_PROC) return this.getApprovalName(this.appApproval);
        if (this.appApproval < 0) return this.getApprovalName(this.appApproval);
        return "";
      case "prevCommentFv":
        if (this.approvalType == ApprovalItem.APPROVAL_NOT_REQUIRED) return "";
        // if (this.appApproval != ApprovalItem.STAT_NOT_PROC) return this.appApprovalComment;
        if (this.appApproval < 0) return this.appApprovalComment;
        return "";
      // For Store Mode
      case "approvalType":
        return this.getApprovalTypeName(this.approvalType);
      case "approval":
        if (!this.appRequire()) return this.getApprovalTypeName(ApprovalItem.APPROVAL_NOT_REQUIRED);
        if (this.appApproval < 0) return this.getApprovalName(ApprovalItem.STAT_NOT_PROC);
        return this.getApprovalName(this.appApproval);
      case "approvalDate":
        if (this.appApproval < 0) return "";
        if (this.appApprovalDate === "") return "";
        return this.appApprovalDate + " " + this.appApprovalTime;
      case "approvalComment":
        if (this.appApproval < 0) return "";
        return this.appApprovalComment;
      default:
        if (this[key]) return this[key];
        return "";
    }
  }

  isEdited(key: string) {
    switch(key) {
      case "approval":
        if (this.edit.approvalForm.value !== this.appApproval) return true;
        return false
      case "comment":
        if (this.edit.commentForm.value !== this.appApprovalComment) return true;
        return false
    }
    return false;
  }

  cancel() {
    this.clearEdit();
    this.prepareEdit();
  }

  prepareEdit() {
    this.edit = {
      isDirty:          false,
      approvalForm:     new FormControl(this.appApproval >= 0 ? this.appApproval : ApprovalItem.STAT_NOT_PROC),
      commentForm:      new FormControl(this.appApproval >= 0 ? this.appApprovalComment : "")
    };
    this.subsc.push(
      this.edit.approvalForm.valueChanges.subscribe((val) => {this.approvalChanged();})
    );
    this.subsc.push(
      this.edit.commentForm.valueChanges.subscribe((val) => {this.approvalChanged();})
    );

    if (this.commonService.config.orderApproval?.autocomplete) {
      this.commentOptions = [...this.commonService.config.orderApproval.autocomplete];
    } else {
      this.commentOptions = [];
    }

    this.filteredOptions = this.edit.commentForm.valueChanges.pipe(
      startWith(''),
      map(value => this.commentFilter(value || '')),
    );
  }

  commentFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.commentOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  approvalChanged() {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  endEdit() {
    this.cleanupEdit();
  }

  clearEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  saveEdit() {
    this.edit.isDirty = false;

    this.appApprovalType = this.approvalType;
    this.appApprovalBoundary = this.approvalBoundary;;
    this.appOrderNum = this.orderNumVersionFn > 0 ? this.orderNumModFn : this.orderNumFn;
    this.appOrderBaraNum = this.orderBaraNumFn;
    this.appCostPrice = this.itemCostPriceFn;
    this.appApproval = this.edit.approvalForm.value;
    this.appApprovalComment = this.edit.commentForm.value;
    this.appUserId = this.commonService.loginUser.userId;
    this.appUserName = this.commonService.loginUser.userName;
  
    let now = new Date();
    this.appApprovalDate = this.commonService.formatDate(now);
    this.appApprovalTime = this.commonService.formatTime(now);
  }

  cleanupEdit() {
    this.subsc.forEach((subsc) => {subsc.unsubscribe();});
    this.subsc = [];
    delete this.edit;
  }

  isDirty() {
    if (!this.edit) return false;
    return this.edit.isDirty;
  }
}
