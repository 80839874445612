import { RspOpestatMainDto } from '../response/rsp-opestat-main';
import { CommonService } from '../service/common.service';

export class OpestatMainRec {
  NO: number;
  periodFv: string;
  periodMonthFv: string;  
  periodWeekFv: string;
  weekDayFv: string;
  storeFv: string;
  storeGroupNameFv: string;
  ctg0Fv: string;
  ctg1Fv: string;
  ctg2Fv: string;
  ctg3Fv: string;
  rankFv: string;

  	/* 一致：　出力対象外の場合は 0 */
  orderAddRateFn : string;      /* 追加率 */
  orderUpRateFn : string;      /* 上方率 */
  orderMatchRateFn : string;    /* 一致率 */
  orderDownRateFn : string;    /* 下方率 */
  orderDelRateFn : string;     /* 削除率 */

  orderCountFn : string;			  /* 発注回数 合計 */
	orderAddCountFn : string;		  /* 追加回数 */
  orderUpCountFn : string;		  /* 上方修正回数 */
  orderMatchCountFn : string;		/* 一致回数 */
  orderDownCountFn : string;		/* 下方修正回数 */
  orderDelCountFn : string;     /* 削除回数 */

  /* 欠品：　出力対象外の場合は 0 */
	soldOutSkuCountFn : string;			/* 対象SKU数 (対象範囲合計) */
	soldOutRatioFn : string;				/* 欠品率 ～ 1.0 */
  soldOutCntPerDayStoreFn : string;	/* 欠品SKU数。店舗・日当り */
  
  constructor(private opestatDto: RspOpestatMainDto, private commonService:CommonService) {
    for (var key of Object.keys(opestatDto)) {
      this[key] = opestatDto[key];
    }
    this.periodFv = opestatDto.dateBegin + " ～ " + opestatDto.dateEnd;
    this.periodMonthFv = opestatDto.dateBegin;
    this.periodWeekFv = opestatDto.dateBegin;

    this.storeFv = opestatDto.storeCdFv + ":" + opestatDto.storeNameFv;
    this.storeGroupNameFv = opestatDto.storeGroupNameFv;
    this.ctg0Fv = opestatDto.ctgCd0Fv + ":" + opestatDto.ctgName0Fv;
    this.ctg1Fv = opestatDto.ctgCd1Fv + ":" + opestatDto.ctgName1Fv;
    this.ctg2Fv = opestatDto.ctgCd2Fv + ":" + opestatDto.ctgName2Fv;
    this.ctg3Fv = opestatDto.ctgCd3Fv + ":" + opestatDto.ctgName3Fv;

    this.orderAddRateFn = opestatDto.orderCountFn == 0 ? "0.0" : (Math.round(opestatDto.orderAddCountFn/opestatDto.orderCountFn* 100*10) / 10).toFixed(1);
    this.orderUpRateFn = opestatDto.orderCountFn == 0 ? "0.0" : (Math.round(opestatDto.orderUpCountFn/opestatDto.orderCountFn* 100*10) / 10).toFixed(1);
    this.orderMatchRateFn = opestatDto.orderCountFn == 0 ? "0.0" : (Math.round(opestatDto.orderMatchCountFn/opestatDto.orderCountFn* 100*10) / 10).toFixed(1);
    this.orderDownRateFn = opestatDto.orderCountFn == 0 ? "0.0" : (Math.round(opestatDto.orderDownCountFn/opestatDto.orderCountFn* 100*10) / 10).toFixed(1);
    this.orderDelRateFn = opestatDto.orderCountFn == 0 ? "0.0" : (Math.round(opestatDto.orderDelCountFn/opestatDto.orderCountFn* 100*10) / 10).toFixed(1);

    this.soldOutRatioFn = (Math.round(opestatDto.soldOutRatioFn* 100*10) / 10).toFixed(1);
    this.soldOutCntPerDayStoreFn = opestatDto.soldOutCntPerDayStoreFn.toFixed(1);
  }
}