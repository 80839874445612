import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { ExpiryItemMasterRec } from '../0_def/expiry-item-master-rec';

@Component({
  selector: 'app-item-expiry-ctg-dialog-dialog',
  templateUrl: './item-expiry-ctg-dialog.component.html',
  styleUrls: ['./item-expiry-ctg-dialog.component.css']
})
export class ItemExpiryCtgDialogComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<ItemExpiryCtgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public expiryItemMaster: ExpiryItemMasterRec,
    public commonService: CommonService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  getCtgPath() {
    if (this.expiryItemMaster.ctg.ctgCds[0] === "") {
      return "無し";
    }
    let path = "";

    for (let i = 0; i < this.expiryItemMaster.ctg.ctgCds.length; i++) {
      let cd = this.expiryItemMaster.ctg.ctgCds[i];
      if (cd === "") break;
      if (path !== "") path += " / ";
      path += cd + "：" + this.expiryItemMaster.ctg.ctgNames[i];
    }
    return path;
  }

  onClose() {
    this.dialogRef.close();
  }

  applyCtgParam() {
    this.dialogRef.close(true);
  }

  getExpiryTypeText() {
    switch (this.expiryItemMaster.ctg.expiryType) {
      case 1: {
        return "賞味期限";
      }
      case 2: {
        return "製造年月日";
      }
    }
  }

  getDiscountTypeText() {
    switch (this.expiryItemMaster.ctg.discountType) {
      case 1: {
        return "値引率";
      }
      case 2: {
        return "値引額";
      }
    }
  }

  getDiscountLabel() {
    switch (this.expiryItemMaster.ctg.discountType) {
      case 1: {
        return {pre: "値引率", post: "%"};
      }
      case 2: {
        return {pre: "値引額", post: "円"};
      }
    };
    return {pre: "値引率", post: "%"};
  }


}
