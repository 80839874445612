import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disabledControl]',
})
export class DisabledControlDirective {
  @Input() set disabledControl(state: boolean) {
    const action = state ? 'disable' : 'enable';
    setTimeout(() => this.ngControl.control[action]({ emitEvent: false }));
  }

  constructor(private readonly ngControl: NgControl) { }
}
