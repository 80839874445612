import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NotificationService } from 'src/app/partsCommon/notification/notification.service';
import { CommonService } from '../../service/common.service';
import { TaskService } from '../task/1_service/taskService';

@Component({
  selector: 'app-top-page',
  templateUrl: './top-page.component.html',
  styleUrls: ['./top-page.component.css']
})
export class TopPageComponent implements OnInit, OnDestroy {

  public isReadonly: boolean = false;
  public isReferencePages: boolean = false;

  constructor(
    private commonService: CommonService
    ) {
      this.isReferencePages = commonService.config.webReferencePages && commonService.config.webReferencePages.length > 0
  }

  ngOnInit() {
    this.commonService.pageTitle = "TOP";
    this.commonService.openDashboardHq = true;
    this.commonService.openDashboardStore = true;
    this.commonService.openNotification = true;
  }

  ngOnDestroy() {
    /*
    this.commonService.openDashboardHq = false;
    this.commonService.openDashboardStore = false;
    this.commonService.openNotification = false;
    */
  }

  openPicker(picker) {
    picker.open();
  }
  pickerOpend(elem) {
    elem.focus();
  }
  pickerClosed(elem) {
    elem.blur();
  }

  openErrorDialog() {
    this.commonService.openErrorDialog("Network Error", "サーバーからデータ取得時に通信エラーが発生しました。");
  }

  openWebPage(ref: {title: string, url: string}) {
    window.open(ref.url, "PippiWeb:" + ref.title);
  }
}
