import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ExpiryDashboardDto, ReqDelFromGroup, ReqGetAlertStatus, ReqGetExpiryAlert, ReqGetExpiryDashboard, ReqGetExpiryGroupByJan, ReqSaveExpiry, RspDelFromGroup, RspGetAlertStatus, RspGetExpiryAlert, RspGetExpiryDashboard, RspGetExpiryGroupByJan, RspSaveExpiry, UpdateExpiryAlertDto } from 'src/app/webservice/item-expiry';
import { ExpiryAlertDesc, ExpiryAlertRec } from '../0_def/expiry-alert';
import { DialogService } from 'src/app/service/dialog.service';
import { PrinterService } from 'src/app/service/printer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ItemExpiryInitialDialogComponent } from '../item-expiry-initial-dialog/item-expiry-initial-dialog.component';
import { ItemExpiryEditDialogComponent } from '../item-expiry-edit-dialog/item-expiry-edit-dialog.component';
import { ExpiryItemRec } from '../0_def/expiry-item-rec';
import { ItemExpiryService } from '../1_service/item-Expiry.service';

export interface AlertListItem {
  descIndex:  number;
  alertRec:   ExpiryAlertRec;
}

@Component({
  selector: 'app-item-expiry',
  templateUrl: './item-expiry.component.html',
  styleUrls: ['./item-expiry.component.css']
})
export class ItemExpiryComponent implements OnInit, OnDestroy {

  public isDirty: boolean = false;
  public storeForm: FormControl = new FormControl();
  public columnIdsDashboard: string[] = [
    "orderGroup", "itemCount", "operation"
  ];
  public columnDefsDashboard: TableColumnDef[] = [
    {columnId: "orderGroup", header: this.commonService.literal["itemExpiryGroup"], width: 100, align: "left"},
    {columnId: "itemCount", header: "商品数", width: 90, align: "right"}
  ];
  public dashboardList: ExpiryDashboardDto[] = [];
  public selectedDashboard: ExpiryDashboardDto;
  public columnIdsAlertHdr: string[] = [
    "itemDesc", "expiryDate", "productionDate", "alertMsg", "alertOperation"
  ];
  public columnIdsAlert: string[] = [
    "itemDesc", "expiryDate", "productionDate", "alertMsg", "alertOperation", "itemOperation"
  ];
  public columnDefsAlert: TableColumnDef[] = [
    {columnId: "expiryDate", header: "賞味期限", width: 70, align: "center"},
    {columnId: "productionDate", header: "製造年月日", width: 70, align: "center"},
  ];
  public columDefAlertMsg: TableColumnDef = {columnId: "alertMsg", header: "アラート", width: 120, align: "left"};
  public alertRecList: ExpiryAlertRec[];
  public recordCount: number = 0;
  public alertShowList: AlertListItem[];
  public selectedAlertItem: ExpiryAlertRec;
  private subsc: Subscription[] = [];

  @ViewChild(MatPaginator, {static:false}) matPagenator: MatPaginator;
  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private dialogService: DialogService,
    private printerService: PrinterService,
    private itemExpiryService: ItemExpiryService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.storeForm.setValue(this.commonService.loginUser.storeCd);
    this.subsc.push(this.storeForm.valueChanges.subscribe(
      (val) => {
        this.getAlertDashboard();
      }
    ));

    this.printerService.getPrinterLayoutList();
    this.getAlertDashboard();
  }

  ngOnDestroy(): void {
    this.subsc.forEach((subsc) => {subsc.unsubscribe();});
    if (this.printerService.printer?.isConnected()) this.printerService.printer.disconnect();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "table-box-dashboard";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 0;    // 56;
    let margin = 10;
    let itemBox = 0;
    let btnBox = 0;             // 24;
    let height = remHeight - paginatorHeight - margin - itemBox - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";

    id = "table-box-alert";
    remHeight = this.commonService.getHeightBelow(id);
    paginatorHeight = 56;
    margin = 10;
    itemBox = 0;
    btnBox = 24;
    height = remHeight - paginatorHeight - margin - itemBox - btnBox;
    if (height < 200) height = 200;

    elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  getAlertDashboard(select?: ExpiryDashboardDto) {
    this.dashboardList = [];
    this.alertRecList = undefined;
    this.alertShowList = undefined;
    this.selectedDashboard = undefined;
    this.selectedAlertItem = undefined;

    let request: ReqGetExpiryDashboard = {
      access: this.commonService.loginUser,
      storeCd: this.storeForm.value
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetExpiryDashboard", request).subscribe(
      (response: RspGetExpiryDashboard) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveAlertDashboard(response, select);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveAlertDashboard(response: RspGetExpiryDashboard, select: ExpiryDashboardDto) {
    if (this.httpBasic.handleAppError(response)) return;

    this.dashboardList = response.rows;
    if (select) {
      let rec = this.dashboardList.find((item) => item.groupUsrCd === select.groupUsrCd);
      if (rec.itemCount > 0) this.showAlert(rec);
    }

    this.setTableHeight();
  }

  showAlert(item: ExpiryDashboardDto) {
    if (item.itemCount <= 0) return;
    if (this.selectedDashboard === item) return;
    let deact = this.canDeactivate();
    if (deact === true) {
      this.showAlertBody(item);
    } else {
      deact.subscribe(
        (response) => {
          if (response) this.showAlertBody(item);
        }
      );
    }
  }

  showAlertBody(item: ExpiryDashboardDto) {
    this.alertRecList?.forEach((rec) => rec.cleanupEdit());
    this.alertRecList = undefined;
    this.alertShowList = undefined;
    this.selectedDashboard = item;
    this.selectedAlertItem = undefined;
    this.getAlertRec(item);
  }

  getAlertRec(item: ExpiryDashboardDto) {
    let request: ReqGetExpiryAlert = {
      access: this.commonService.loginUser,
      storeCd: item.storeCd,
      groupUsrCd: item.groupUsrCd,
      maxCtgLevel: this.commonService.config.maxCtgLevel
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetExpiryAlert", request).subscribe(
      (response: RspGetExpiryAlert) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveGetAlertRec(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetAlertRec(response: RspGetExpiryAlert) {
    if (this.httpBasic.handleAppError(response)) return;

    this.isDirty = false;
    this.alertRecList = [];
    let currentExpiryAlert: ExpiryAlertRec = undefined;
    response.alerts.forEach((dto) => {
      if (currentExpiryAlert === undefined || currentExpiryAlert.itemCd !== dto.itemCd) {
        currentExpiryAlert = new ExpiryAlertRec(this.commonService);
        currentExpiryAlert.storeCd = dto.storeCd;
        currentExpiryAlert.itemCd = dto.itemCd;
        currentExpiryAlert.itemName = dto.itemName;
        currentExpiryAlert.itemStandard = dto.itemStandard;
        currentExpiryAlert.itemSalesPrice = dto.itemSalesPrice;
        currentExpiryAlert.itemTax = dto.itemTax;
        currentExpiryAlert.groupId = dto.groupId;
        currentExpiryAlert.groupUsrCd = dto.groupUsrCd;
        currentExpiryAlert.groupName = dto.groupName;
        currentExpiryAlert.expiryType = dto.expiryType;
        currentExpiryAlert.discountType = dto.discountType;
        currentExpiryAlert.dateDef = dto.dateDef;
        currentExpiryAlert.alertDescs = [];
        // currentExpiryAlert.itemGroups = dto.itemGroups;
        currentExpiryAlert.itemGroups = undefined;
        currentExpiryAlert.setChangeListener(this.dirtyListener.bind(this));
        this.alertRecList.push(currentExpiryAlert);
      }
      let desc: ExpiryAlertDesc = {
        alertType: dto.alertType,
        itemDate: dto.itemDate,
        alertRank: dto.alertRank,
        alertStartDate: dto.alertStartDate,
        alertMsg: dto.alertMsg,
        isCompleted: dto.isCompleted,
        isSoldOutDate: dto.isSoldOutDate,
        isSoldOutItem: dto.isSoldOutItem
      };
      currentExpiryAlert.alertDescs.push(desc);
    });
    this.alertRecList.forEach((rec => rec.prepareEdit()));
    this.recordCount = this.alertRecList.length;
    this.buildAlertShowList();
    this.setTableHeight();
  }

  buildAlertShowList() {
    if (!this.alertRecList) {
      this.alertShowList = undefined;
      return;
    }

    let start: number = 0;
    let end: number = this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex];
    if (this.matPagenator) {
      start = this.matPagenator.pageIndex * this.matPagenator.pageSize;
      end = start + this.matPagenator.pageSize;
    }

    this.alertShowList = [];
    for (let i = start; i < this.alertRecList.length && i < end; i++) {
      let rec = this.alertRecList[i];
      for (let j = 0; j < rec.alertDescs.length; j++) {
        this.alertShowList.push({
          descIndex: j,
          alertRec: rec
        });
      }
    }
    if (this.alertShowList.length == 0) {
      this.alertShowList = undefined;
    }
  }

  selectAlertItem(item: ExpiryAlertRec) {
    this.selectedAlertItem = item;
  }


  openRegistDialogDashboard(item: ExpiryDashboardDto) {

    this.commonService.dialog.open(ItemExpiryInitialDialogComponent, {
      // position: {top: "10px"},
      // width: "95vw",
      // maxWidth: "95vw",
      minWidth: "1150px",
      minHeight: "90vh",
      disableClose: true,
      data: item
    }).afterClosed().subscribe(
      () => {
        this.getAlertDashboard(this.selectedDashboard);
      }
    );
  }

  openRegistDialogAlert(item: ExpiryAlertRec, descIndex: number) {
    this.itemExpiryService.getExpiryItemByJan(item.storeCd, item.itemCd, item.groupUsrCd).then(
      (expiryItem: ExpiryItemRec) => {
        this.commonService.dialog.open(ItemExpiryEditDialogComponent, {
          // position: {top: "10px"},
          // width: "95vw",
          // maxWidth: "95vw",
          minWidth: "600px",
          minHeight: "90vh",
          disableClose: true,
          data: expiryItem
        }).afterClosed().subscribe(
          (response: boolean) => {
            // if (response) this.getAlertDashboard(this.selectedDashboard);
            if (response) {
              this.removeGroupItem(item);
              return;
            }
            let desc = item.alertDescs[descIndex];
            if (desc.alertType === -1 && desc.alertRank === -3) {
              // 未登録
              this.getAlertStatus(item, descIndex).then(
                (response: RspGetAlertStatus) => {
                  let desc = item.alertDescs[descIndex];
                  desc.isCompleted = response.isCompleted;
                  desc.isSoldOutDate = response.isSoldOutDate;
                  desc.isSoldOutItem = response.isSoldOutItem;
                  if (desc.isCompleted) {
                    desc.edit.formStatus.setValue("" + 1, {emitEvent: false});
                  } else {
                    desc.edit.formStatus.setValue("" + 0, {emitEvent: false});
                  }
                }
              ).catch(
                (response: RspGetAlertStatus) => {

                }
              )
            }
          }
        );
      }
    )
  }

  removeGroupItem(item: ExpiryAlertRec) {
    this.alertRecList = this.alertRecList.filter((rec) => rec.storeCd !== item.storeCd || rec.itemCd !== item.itemCd || rec.groupUsrCd !== item.groupUsrCd);
    let tmp = this.alertRecList.map((rec) => rec.itemCd);
    this.selectedDashboard.itemCount = new Set(tmp).size;

    this.buildAlertShowList();
  }

  getAlertStatus(item: ExpiryAlertRec, descIndex: number): Promise<RspGetAlertStatus> {
    return new Promise<RspGetAlertStatus>((resolve, reject) => {
      let request: ReqGetAlertStatus = {
        access: this.commonService.loginUser,
        storeCd: item.storeCd,
        itemCd: item.itemCd,
        groupUsrCd: item.groupUsrCd,
        alertType: item.alertDescs[descIndex].alertType,
        itemDate: item.alertDescs[descIndex].itemDate,
        alertRank: item.alertDescs[descIndex].alertRank
      };
      let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
      let subsc = this.httpBasic.generalRequest("GetAlertStatus", request).subscribe(
        (response: RspGetAlertStatus) => {
          this.commonService.closeSpinnerForSubComp(ref);
          subsc.unsubscribe();
          if (this.httpBasic.handleAppError(response)) {
            reject(null);
          } else {
            resolve(response);
          }
        },
        (error) => {
          this.commonService.closeSpinnerForSubComp(ref);
          subsc.unsubscribe();
          this.httpBasic.handleError(error);
          reject(null);
        }
      );
    });
  }

  printableAlert(item: AlertListItem) {
    let alertRec = item.alertRec;
    if (item.descIndex < 0 || item.descIndex >= alertRec.alertDescs.length) return false;
    let desc = alertRec.alertDescs[item.descIndex];
    let rank = desc.alertRank - 1;
    if (rank < 0 || rank >= alertRec.dateDef.length) return false;
    let discount = alertRec.dateDef[rank].discount;
    if (discount === 0) false;

    return true;
  }

  printDiscountLabel(item: AlertListItem) {
    if (!this.printableAlert(item)) return;
    let count = item.alertRec.alertDescs[item.descIndex].edit.formPrintCount.value;
    this.printerService.printDiscountLabel(this.discountLabelMessageNeo7(item, count));
  }

  discountLabelMessageNeo7(item: AlertListItem, count: number) {
    let alertItem = item.alertRec;
    let desc = alertItem.alertDescs[item.descIndex];
    let rank = desc.alertRank - 1;
    let discount = alertItem.dateDef[rank].discount;

    return this.printerService.discountLabelMessageNeo7(
      this.printerService.printerConfig,
      alertItem.itemCd,
      discount,
      alertItem.discountType,
      alertItem.itemSalesPrice,
      alertItem.itemTax,
      count
    );
  }

  neo7response(response) {
    this.printerService.neo7response(response);
  }

  pageChanged(event: PageEvent) {
    this.buildAlertShowList();
  }

  styleForHeader(col: TableColumnDef) {
    return this.commonService.styleForHeader(col);
  }

  styleFor(col: TableColumnDef, item: AlertListItem) {
    let style = this.commonService.styleFor(col);
    if (col.columnId === "alertMsg") {
      return {...style, ...item.alertRec.getColor(item.alertRec.alertDescs[item.descIndex])};
    } else if (col.columnId === "alertStartDate") {
      let startDateStr = item.alertRec.alertDescs[item.descIndex].alertStartDate;
      let today = this.commonService.formatDate(new Date());
      if (startDateStr < today) {
        return {
          ...style,
          "font-weight": "bold",
          "color": "red"
        };
      }
    }
    return style;
  }

  styleForStartDate(item: AlertListItem) {
    let startDateStr = item.alertRec.alertDescs[item.descIndex].alertStartDate;
    let today = this.commonService.formatDate(new Date());
    if (startDateStr < today) {
        return {
          "font-weight": "bold",
          "color": "red"
        };
    }
    return {};
  }

  openLabelPrinterDialog() {
    this.dialogService.openLabelPrinterDialog(this.printerService);
  }

  printerNotReady() {
    if (!this.printerService.printerConfig) return true;
    if (!this.printerService.printerConfig.printer) return true;
    if (!this.printerService.printerConfig.printer.isConnected()) return true;
    return false;
  }

  dirtyListener() {
    this.isDirty = true;
    this.storeForm.disable({emitEvent: false});
  }

  saveAlert() {
    let rows: UpdateExpiryAlertDto[] = [];
    this.alertRecList.forEach((rec) => {
      let dtos = rec.getSaveData();
      dtos.forEach((dto) => rows.push(dto));
    });

    let request: ReqSaveExpiry = {
      access: this.commonService.loginUser,
      dates: [],
      alerts: rows,
      expiryGroupLabel: this.commonService.literal["itemExpiryGroup"]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("SaveExpiry", request).subscribe(
      (response: RspSaveExpiry) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receivesSveAlert(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receivesSveAlert(response: RspSaveExpiry) {
    if (this.httpBasic.handleAppError(response)) return;

    this.alertRecList.forEach((rec) => rec.commitEdit());
    this.isDirty = false;
    this.storeForm.enable({emitEvent: false});
  }

  getGroupName(item: ExpiryDashboardDto) {
    if (item?.groupName) return item.groupUsrCd + "：" + item.groupName;
    return item.groupUsrCd;
  }

  cancelAlert() {
    this.alertRecList?.forEach((rec) => rec.clearEdit());
    this.isDirty = false;
    this.storeForm.enable({emitEvent: false});
  }

  setSoldOutItem(item: ExpiryAlertRec) {
    item.setSoldOutItem();
  }

  getExpiryGroup(item: ExpiryAlertRec): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let request: ReqGetExpiryGroupByJan = {
        access: this.commonService.loginUser,
        storeCd: item.storeCd,
        itemCd: item.itemCd
      };

      let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
      let subsc = this.httpBasic.generalRequest("GetExpiryGroupByJan", request).subscribe(
        (response: RspGetExpiryGroupByJan) => {
            this.commonService.closeSpinnerForSubComp(ref);
            subsc.unsubscribe();
            if (this.httpBasic.handleAppError(response)) reject();
            item.itemGroups = response.itemGroups;
            resolve();
        },
        (error) => {
          this.commonService.closeSpinnerForSubComp(ref);
          subsc.unsubscribe();
          this.httpBasic.handleError(error);
          reject();
        }
      );
    });
  }

  removeFromGroup(item: ExpiryAlertRec) {
    if (!item.itemGroups) {
      this.getExpiryGroup(item).then(
        () => {
          this.removeFromGroupConfirm(item);
        }
      ).catch(
        () => {return;}
      )
    } else {
      this.removeFromGroupConfirm(item);
    }
  }

  removeFromGroupConfirm(item: ExpiryAlertRec) {
    let msg;
    if (item.itemGroups.length == 1) {
      msg = this.commonService.literal["itemExpiryGroup"] + "から削除すると期限管理の対象外となります。<BR>削除しますか？";
    } else {
      msg = this.commonService.literal["itemExpiryGroup"] + "から削除しますか？";
    }

    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      (response) => {
        subsc.unsubscribe();
        if (response) this.removeFromOrderGroupBody(item);
      }
    );
  }

  removeFromOrderGroupBody(item: ExpiryAlertRec) {
    let index = this.alertRecList.findIndex((rec) => rec == item);
    if (index >= 0) {
      this.alertRecList.splice(index, 1);
      this.isDirty = false;
      for (let i = 0; i < this.alertRecList.length; i++) {
        if (this.alertRecList[i].isDirty()) {
          this.isDirty = true;
          break;
        }
      }
      if (this.isDirty) {
        this.storeForm.disable({emitEvent: false});
      } else {
        this.storeForm.enable({emitEvent: false});
      }

      this.buildAlertShowList();
    }

    if (this.selectedDashboard) {
      this.selectedDashboard.itemCount--;
      if (this.selectedDashboard.itemCount === 0) {
        this.selectedDashboard = undefined;
      }
    }

    this.removeGroupFromDbItem(
      item.storeCd,
      item.itemCd,
      item.groupId,
      item.groupUsrCd,
    );
  }

  removeGroupFromDbItem(storeCd: string, itemCd: string, groupId: number, groupUsrCd: string) {
    let request: ReqDelFromGroup = {
      access:     this.commonService.loginUser,
      storeCd:    storeCd,
      itemCd:     itemCd,
      groupId:    groupId,
      groupUsrCd: groupUsrCd
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("ItemExpiryDelFromGroup", request).subscribe(
      (response: RspDelFromGroup) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveRemoveGroupFromDbItem(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveRemoveGroupFromDbItem(response: RspDelFromGroup) {
    if (this.httpBasic.handleAppError(response)) return;

    // this.getAlertDashboard(this.selectedDashboard);
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
