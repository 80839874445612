import { FormControl } from "@angular/forms";
import { CommonService } from "src/app/service/common.service";

export class OrderGroupUser2Rec {

  public today:     Date;
  public storeCd:   string      = "";
  public groupId:   number      = 0;
  public groupUsrCd: string      = "";
  public groupName: string      = "";
  public groupDateBegin: string = "";
  public userId:    string      = "";
  public userName:  string      = "";
  public dateBegin: Date        = null;
  public dateEnd:   Date        = null;

  public  edit: {
    isDirty:        boolean;
    dateBeginForm:  FormControl;
    dateEndForm:    FormControl;
  };

  // private subsc:    Subscription[]  = [];
  private changeListener: (groupUser: OrderGroupUser2Rec)=>void;

  constructor(private commonService: CommonService) {
    let d: Date = new Date();
    this.today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }

  setListner(func:(groupUser: OrderGroupUser2Rec)=>void) {
    this.changeListener = func;
  }

  hasAssign() {
    if (!this.edit) {
      if (this.dateBegin) return true;
      return false;
    }
    if (this.edit.dateBeginForm.value) return true;
    return false;
  }

  isActive() {
    let begin;
    let end;
    if (!this.edit) {
      begin = this.dateBegin;
      end = this.dateEnd;
    } else {
      begin = this.edit.dateBeginForm.value;
      end = this.edit.dateEndForm.value;
    }

    if (!begin) return false;
    if (!end) {
      end = this.commonService.getDate("2099/12/31");
    }
    if (this.today.getTime() < begin.getTime()) return false;
    if (this.today.getTime() > end.getTime()) return false;
    return true;
  }

  isEnded() {
    let begin;
    let end;
    if (!this.edit) {
      begin = this.dateBegin;
      end = this.dateEnd;
    } else {
      begin = this.edit.dateBeginForm.value;
      end = this.edit.dateEndForm.value;
    }
    if (!begin) return false;
    if (!end) return false;
    if (end.getTime() < this.today.getTime()) return true;
    return false;
  }

  prepareEdit() {
    if (this.edit) this.cleanupEdit();
    let dateBegin;
    let dateEnd;
    if (!this.dateBegin) {
      dateBegin = null;
    } else {
      dateBegin = this.commonService.copyDate(this.dateBegin);
    }
    if (!this.dateEnd) {
      dateBegin = null;
    } else {
      if (this.commonService.formatDate(this.dateEnd) == "2099/12/31") {
        dateEnd = null;
      } else {
        dateEnd = this.commonService.copyDate(this.dateEnd);
      }
    }
    this.edit = {
      isDirty: false,
      dateBeginForm:  new FormControl(),
      dateEndForm:  new FormControl()
    };
    if (dateBegin) this.edit.dateBeginForm.setValue(dateBegin);
    if (dateEnd) this.edit.dateEndForm.setValue(dateEnd);
  }

  dateClear(form: FormControl) {
    let val = form.value;
    if (val === null || val === undefined) return;
    form.setValue(undefined);
    if (form == this.edit.dateBeginForm) this.edit.dateEndForm.setValue(undefined);
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener(this);
  }

  /*
  dateBeginChanged(value) {
    this.edit.isDirty= true;
    let dateBegin: Date = this.edit.dateBeginForm.value;
    let dateEnd: Date = this.edit.dateEndForm.value;
    if (!dateBegin) {
      this.edit.dateEndForm.setValue(undefined);
    } else {
      if (dateEnd && dateBegin.getTime() > dateEnd.getTime()) {
        let d = this.commonService.copyDate(this.edit.dateBeginForm.value);
        this.edit.dateEndForm.setValue(d);
      }
    }
    // To Avoid that the updated date value is not refreshed on changing to the other tab.
    this.edit.dateBeginForm.setValue(this.commonService.copyDate(dateBegin));

    if (this.changeListener) this.changeListener(this);
  }
  */

  /*
  dateEndChanged(value) {
    this.edit.isDirty= true;
    let dateBegin: Date = this.edit.dateBeginForm.value;
    let dateEnd: Date = this.edit.dateEndForm.value;
    if (dateEnd) {
      if (!dateBegin || dateBegin.getTime() > dateEnd.getTime()) {
        let d = this.commonService.copyDate(this.edit.dateEndForm.value);
        this.edit.dateBeginForm.setValue(d);
      }
    }
    // To Avoid that the updated date value is not refreshed on changing to the other tab.
    this.edit.dateEndForm.setValue(this.commonService.copyDate(dateEnd));

    if (this.changeListener) this.changeListener(this);
  }
  */

  getValue(key: string) {
    switch(key) {
      default:
        return this[key];
    }
  }

  getBeginMin() {
    if (this.groupDateBegin === "") {
      return this.today;
    }
    return this.commonService.getDate(this.groupDateBegin);
  }

  getEndMin() {
    if (this.groupDateBegin === "") {
      let d = this.commonService.copyDate(this.today);
      d.setDate(d.getDate() - 1);
      return d;
    }
    return this.commonService.getDate(this.groupDateBegin);
  }

  saveEdit() {
    if (!this.edit) return;
    if (!this.edit.isDirty) return;
    let dateBegin = this.edit.dateBeginForm.value;
    let dateEnd = this.edit.dateEndForm.value;
    this.dateBegin = dateBegin ? this.commonService.copyDate(this.edit.dateBeginForm.value) : null;
    this.dateEnd = dateEnd ? this.commonService.copyDate(this.edit.dateEndForm.value) : null;
    this.edit.isDirty = false;
  }

  cancelEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  cleanupEdit() {
    if (this.edit) delete this.edit;
  }

  endEdit() {
    this.cleanupEdit();
  }
}
