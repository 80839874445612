import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { OrderGroupDto } from 'src/app/webservice/order-group';

@Component({
  selector: 'app-order-group-select-dialog',
  templateUrl: './order-group-select-dialog.component.html',
  styleUrls: ['./order-group-select-dialog.component.css']
})
export class OrderGroupSelectDialogComponent implements OnInit {

  public currentGroupUsrCd: string;
  public allGroups: OrderGroupDto[];

  public columnIds: string[] = ["orderGroupUsrCd", "orderGroupName"];
  public columnDefs: TableColumnDef[] = [
    {columnId: "orderGroupUsrCd", header: "コード", width: 60, align: "center"},
    {columnId: "orderGroupName", header: this.commonService.literal.orderGroup, width: 300, align: "left"}
  ];

  constructor(
    public dialogRef: MatDialogRef<OrderGroupSelectDialogComponent>,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: {currentGroupUsrCd: string; allGroups: OrderGroupDto[]}
  ){
    this.currentGroupUsrCd = this.data.currentGroupUsrCd;
    this.allGroups = this.data.allGroups;
  }

  ngOnInit() {
    setTimeout(() => {this.setTableHeight();}, 0);
  }

  setTableHeight() {
    let id = "group-table-box";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight == undefined) return;
    let btnBoxHeight = 34;
    let margin = 10;
    let height = remHeight - btnBoxHeight - margin;
    if (height < 400) height = 400;

    let elem = document.getElementById(id);
    if (elem == undefined) return;
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  groupClicked(item: OrderGroupDto) {
    this.dialogRef.close(item);
  }

  onClose() {
    this.dialogRef.close();
  }
}
