import { formatDate } from '@angular/common';
import { FormControl } from "@angular/forms";
import { RspPromOrderItemSearchDto } from "src/app/webservice/promOrder";

export interface PromOrder {
  promCd: string;
  promName: string;
  dateBegin: string;
  dateEnd: string;
  orderDateBegin: string;
  orderDateEnd: string;
  masterStatus: number;             // PROM_ORDER_MST status: 0:not created, 1:created
  storeList: PromStore[];
  // storeCd: PromOrderStoreList;
  editStatus: number;             // 0:Not editing  1:Editing,Not Dirty  2:Editing,Dirty   3: Editing, (OrderNum!=0, (OrderDate=null || RcvDate=null))
  ctgList?: { ctgTree: {}, ctgFlat: {} };
}

export interface PromStore {
  storeCd: string;
  storeName: string;
}

export interface PromOrderStoreList {
  storeCd: string;
  orderItems: PromOrderItem[];
}

export class PromOrderItem {
  public storeCd: string = "";
  public itemCd: string = "";
  public itemName: string = "";
  public itemStandard: string = "";
  public piValue: number = 0;
  public piCustomers: number = 0;
  public piRecommendNum: number = 0;
  public promForecast: number = 0;
  public promRate: number = 0;
  public promRecommendNum: number = 0;
  public orderBaraNum: number = 0;
  public rcvDate: string = "";
  public orderDate: string = "";
  public formOrderNum: FormControl = new FormControl();
  public formRcvDate: FormControl = new FormControl();
  public formOrderDate: FormControl = new FormControl();

  public orderBaraNumOrigin: number = 0;
  public rcvDateOrigin: string = "";
  public orderDateOrigin: string = "";
  public isNewFlag: boolean = false;
  public newIndex: number = -1;

  public odrLt1Fn: number = 0;
  public odrLt2Fn: number = 0;
  public odrLt3Fn: number = 0;
  public odrLt4Fn: number = 0;
  public odrLt5Fn: number = 0;
  public odrLt6Fn: number = 0;
  public odrLt7Fn: number = 0;

  public originDto: RspPromOrderItemSearchDto;
  public requestedCalcDateByOrderNum: boolean = false;
  public orderDateEditMode: boolean = false;

  constructor(dto?: RspPromOrderItemSearchDto, isNewFlag?: boolean, newIndex?: number) {
    if (dto)
      this.patchValue(dto);

    if (!isNewFlag || newIndex < 0) return;

    this.isNewFlag = isNewFlag;
    this.newIndex = newIndex;
  }

  patchValue(obj: RspPromOrderItemSearchDto): void {
    for (let key in obj) {
      if (Object.keys(this).find((recKey) => recKey === key)) {
        this[key] = obj[key];
      }
    }

    this.formOrderNum.setValue(this?.orderBaraNum ?? 0);

    this.formOrderDate.setValue(this.isDateValid(this?.orderDate) ? new Date(this.orderDate) : null);
    this.formRcvDate.setValue(this.isDateValid(this?.rcvDate) ? new Date(this.rcvDate) : null);
    this.requestedCalcDateByOrderNum = this.isDateValid(this.formRcvDate?.value);

    this.orderDate = this.isDateValid(this?.orderDate) ? formatDate(new Date(this.orderDate), 'yyyy/MM/dd', 'en_US') : '';
    this.rcvDateOrigin = this.isDateValid(this?.rcvDate) ? formatDate(new Date(this.rcvDate), 'yyyy/MM/dd', 'en_US') : '';
    this.orderBaraNumOrigin = this.orderBaraNum;
    this.orderDateOrigin = this.orderDate;
    this.originDto = { ...obj }
  }

  isDateValid(input: string | Date): boolean {
    if (!input) return false;
    if (typeof input == 'string') {
      if (input.trim() == '') return false;
      const date = new Date(input);
      return (date instanceof Date && !isNaN(date.valueOf()));
    }
    return (input instanceof Date && !isNaN(input.valueOf()));
  }
}
