import { CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { StoreGroupNameRec } from 'src/app/common/store-group-name-rec';
import { StoreGroupRec } from 'src/app/common/store-group-rec';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspGetStoreGroupName } from 'src/app/response/rsp-get-store-group-name';

export class OpestatViewContext {

  static ConditionTypeByStore: number = 1;
  static ConditionTypeByCategory: number = 2;
  static ConditionTypeByRank: number = 3;
  static ConditionTypeByMonth: number = 4;
  static ConditionTypeByWeek: number = 5;
  static ConditionTypeByWeekday: number = 6;
  static ConditionTypeByDistribution: number = 7;
  static ConditionTypeByStoreGroup: number = 8;

  static ConditionDistrTypeRecommendationAcceptanceRatio = 1;
  static ConditionDistrTypeSoldOutRatio = 2;

  public id: number;
  public name: string;

  public ctgSelect: CtgSelectCondition;
  public formGroup: FormGroup;
  public ctgFromGroup: FormGroup;
  public subscriptionType: Subscription;
  public subscriptionStoreGroupTypeByStore: Subscription;
  public subscriptionStoreGroupType: Subscription;
  public subscriptionQuery: Subscription;

  public storeGroupNameRec: StoreGroupNameRec[] = [];
  public storeGroupNameRecByStore: StoreGroupNameRec[] = [];

  constructor(
    id: number,
    name: string,
    fb: FormBuilder,
    private commonService: CommonService,
    private httpBasic: HttpBasicService
    ) {
    this.id = id;
    this.ctgSelect = new CtgSelectCondition(fb);
    this.ctgFromGroup = this.ctgSelect.formGroup;

    this.formGroup = fb.group(
      {
        name: [{ value: name, disabled: false }],
        type: [{ value: OpestatViewContext.ConditionTypeByStore, disabled: false }],
        ratioType: fb.group(
          {
            recommAccptRatio: [{ value: true, disabled: false }],
            soldOutRatio: [{ value: true, disabled: false }]
          }
        ),
        distrType: [OpestatViewContext.ConditionDistrTypeRecommendationAcceptanceRatio],
        dateBegin: [, Validators.required],
        dateEnd: [, Validators.required],
        storeGroupTypeCodeForByStore: [{ value: 0, disabled: false }],
        storeGroupCodeForByStore: [{ value: 0, disabled: false }],
        storeGroupTypeCodeForByStoreGroup: [{ value: 0, disabled: false }],
        targetStoreType: [{ value: 1, disabled: false }],
        targetStoreCode: [{ value: "", disabled: false }],
        targetStoreGroupTypeCode: [{ value: 0, disabled: false }],
        targetStoreGroupCode: [{ value: 0, disabled: true }],
        rank: [{ value: "", disabled: false }]
      },
      {
        validators: [ (control) => {return this.validateFormGroup(control as FormGroup)} ]
      }
    );

    this.subscriptionType = this.formGroup.get("type").valueChanges.subscribe(() => this.typeChanged());
    // this.subscriptionStoreGroupTypeByStore = this.formGroup.get("storeGroupTypeCodeForByStore").valueChanges.subscribe(() => this.storeGroupTypeByStoreChanged());
    // this.subscriptionStoreGroupType = this.formGroup.get("targetStoreGroupTypeCode").valueChanges.subscribe(() => this.storeGroupTypeChanged());
    
  }

  onDestroy() {
    /* Called from OpestatPageComponent */
    if (this.subscriptionType) this.subscriptionType.unsubscribe();
    if (this.subscriptionStoreGroupTypeByStore) this.subscriptionStoreGroupTypeByStore.unsubscribe()
    if (this.subscriptionStoreGroupType) this.subscriptionStoreGroupType.unsubscribe();
    if (this.subscriptionQuery) this.subscriptionQuery.unsubscribe();
  }

  validateRatioType(control: FormGroup) {
    if (parseInt(control.get("type").value) == OpestatViewContext.ConditionTypeByDistribution) {
      return null;
    }
    if (control.get("ratioType").get("recommAccptRatio").value == true) return null;
    if (control.get("ratioType").get("soldOutRatio").value == true) return null;
    return { "OutputTypeRequired": "出力が選択されていません。" }
  }

  validateFormGroup(control: FormGroup) {
    var err: object;
    var errNoGroupType = {"StoreGroupTypeRequired": "店舗グループ種別が選択されていません。" };
    var errNoGroup = {"StoreGroupRequired": "店舗グループが選択されていません。" };

    err = this.validateRatioType(control);
    if (err != null) return err;

    if (parseInt(control.get("type").value) == OpestatViewContext.ConditionTypeByStore) {
      if (parseInt(control.get("storeGroupTypeCodeForByStore").value) != 0 &&
          parseInt(control.get("storeGroupCodeForByStore").value) == 0) {
        return errNoGroup;
      }
    } else if (parseInt(control.get("type").value) == OpestatViewContext.ConditionTypeByStoreGroup) {
      if (parseInt(control.get("storeGroupTypeCodeForByStoreGroup").value) == 0) {
        return errNoGroupType;
      }
    } else if (parseInt(control.get("type").value) != OpestatViewContext.ConditionTypeByDistribution) {
      if (parseInt(control.get("targetStoreType").value) != 1) {
        if (parseInt(control.get("targetStoreGroupTypeCode").value) == 0) {
          return errNoGroupType;
        }
        if (parseInt(control.get("targetStoreGroupCode").value) == 0) {
          return errNoGroup;
        }
      }
    }

    return null;
  }

  getName() {
    // if (this.formGroup.get("name").value == "") return ("条件-" + this.id);
    let tmp : string = this.formGroup.get("name").value;
    tmp = tmp.replace(/ /g, "");
    if (tmp == "") return ("条件-" + this.id);
    return this.formGroup.get("name").value;
  }

  typeChanged() {
    if (parseInt(this.formGroup.get("type").value) == OpestatViewContext.ConditionTypeByStore) {
      this.formGroup.get("targetStoreCode").disable();
    } else {
      this.formGroup.get("targetStoreCode").enable();
    }
    if (parseInt(this.formGroup.get("type").value) == OpestatViewContext.ConditionTypeByRank) {
      this.formGroup.get("rank").disable();
    } else {
      this.formGroup.get("rank").enable();
    }
    if (parseInt(this.formGroup.get("type").value) == OpestatViewContext.ConditionTypeByCategory) {
      this.setupMaxSelectable(this.commonService.config.maxCtgLevel - 1);
    } else {
      this.setupMaxSelectable(this.commonService.config.maxCtgLevel);
    }
    if (parseInt(this.formGroup.get('type').value) == OpestatViewContext.ConditionTypeByDistribution) {
      this.formGroup.get("targetStoreType").setValue("1");
      this.formGroup.get("targetStoreGroupTypeCode").disable();
      this.formGroup.get("targetStoreGroupCode").disable();
    } else {
      this.formGroup.get("targetStoreGroupTypeCode").enable();
      if (parseInt(this.formGroup.get("targetStoreGroupTypeCode").value) !== 0)
        this.formGroup.get("targetStoreGroupCode").enable();
    }
  }

  /*
  clearProgressState() {
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
      this.subscriptionQuery = undefined;
    }
    this.commonService.closeSpinner();

  }

  storeGroupTypeByStoreChanged() {
    if (!this.formGroup.get("storeGroupTypeCodeForByStore").dirty) return;
    var typeCd: number = parseInt(this.formGroup.get("storeGroupTypeCodeForByStore").value);
    if (typeCd == 0) {
      // 全店 
      this.formGroup.get("storeGroupCodeForByStore").disable();
      return;
    } else {
      this.formGroup.get("storeGroupCodeForByStore").enable();
    }
    // this.storeGroupNameRecByStore = [];
    this.storeGroupNameRecByStore.length = 0;
  
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.getStoreGroupName(typeCd).subscribe(
      (response) => {this.receiveStoreGroupName(response, 1)},
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  storeGroupTypeChanged() {
    if (!this.formGroup.get("targetStoreGroupTypeCode").dirty) return;
    var typeCd: number = parseInt(this.formGroup.get("targetStoreGroupTypeCode").value);

    // this.storeGroupNameRec = [];
    this.storeGroupNameRec.length = 0;

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.getStoreGroupName(typeCd).subscribe(
      (response) => {this.receiveStoreGroupName(response, 2)},
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveStoreGroupName(response: RspGetStoreGroupName, array: number) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    let resultArray: StoreGroupNameRec[];
    if (array == 1) {
      resultArray = this.storeGroupNameRecByStore;
    } else {
      resultArray = this.storeGroupNameRec
    }

    for (let groupName of response.storeGroupNames) {
      let groupNameRec = new StoreGroupNameRec();
      groupNameRec.storeGroupCd = groupName.storeGroupCdFn;
      groupNameRec.storeGroupName = groupName.storeGroupNameFv;
      groupNameRec.storeGroupTypeCd = groupName.storeGroupTypeCdFn;
      resultArray.push(groupNameRec);
    }

    if (array == 1) {
      if (parseInt(this.formGroup.get("storeGroupCodeForByStore").value) == 0 &&
      resultArray.length > 0) {
        this.formGroup.get("storeGroupCodeForByStore").setValue(resultArray[0].storeGroupCd);
      }
    } else {
      this.formGroup.get("targetStoreGroupCode").enable();
      if (parseInt(this.formGroup.get("targetStoreGroupCode").value) == 0 &&
      resultArray.length > 0) {
        this.formGroup.get("targetStoreGroupCode").setValue(resultArray[0].storeGroupCd);
      }
    }
  }
  */
 
  setupMaxSelectable(maxSelectableLevel: number) {
    if (maxSelectableLevel > 0) {
      if (this.ctgFromGroup.get("ctgCd0").disabled) this.ctgFromGroup.get("ctgCd0").enable();
    } else {
      if (this.ctgFromGroup.get("ctgCd0").enabled) this.ctgFromGroup.get("ctgCd0").disable();
      this.ctgFromGroup.get("ctgCd0").setValue("");
    }
    if (maxSelectableLevel > 1) {
      if (this.ctgFromGroup.get("ctgCd1").disabled) this.ctgFromGroup.get("ctgCd1").enable();
    } else {
      if (this.ctgFromGroup.get("ctgCd1").enabled) this.ctgFromGroup.get("ctgCd1").disable();
      this.ctgFromGroup.get("ctgCd1").setValue("");
    }
    if (maxSelectableLevel > 2) {
      if (this.ctgFromGroup.get("ctgCd2").disabled) this.ctgFromGroup.get("ctgCd2").enable();
    } else {
      if (this.ctgFromGroup.get("ctgCd2").enabled) this.ctgFromGroup.get("ctgCd2").disable();
      this.ctgFromGroup.get("ctgCd2").setValue("");
    }
    if (maxSelectableLevel > 3) {
      if (this.ctgFromGroup.get("ctgCd3").disabled) this.ctgFromGroup.get("ctgCd3").enable();
    } else {
      if (this.ctgFromGroup.get("ctgCd3").enabled) this.ctgFromGroup.get("ctgCd3").disable();
      this.ctgFromGroup.get("ctgCd3").setValue("");
    }
  }

  dateBeginChanged() {
    if (this.formGroup.get("dateBegin").value) {
      this.formGroup.get("dateEnd").enable();
    }
  }

  copyConditionTo(viewContext: OpestatViewContext) {
    var fg = viewContext.formGroup;
    fg.get("type").setValue(this.formGroup.get("type").value);
    fg.get("ratioType").get("recommAccptRatio").setValue(this.formGroup.get("ratioType").get("recommAccptRatio").value);
    fg.get("ratioType").get("soldOutRatio").setValue(this.formGroup.get("ratioType").get("soldOutRatio").value);
    fg.get("distrType").setValue(this.formGroup.get("distrType").value);
    fg.get("dateBegin").setValue(this.commonService.copyDate(this.formGroup.get("dateBegin").value));
    fg.get("dateEnd").setValue(this.commonService.copyDate(this.formGroup.get("dateEnd").value));
    fg.get("storeGroupTypeCodeForByStore").setValue(this.formGroup.get("storeGroupTypeCodeForByStore").value);
    fg.get("storeGroupCodeForByStore").setValue(this.formGroup.get("storeGroupCodeForByStore").value);
    fg.get("storeGroupTypeCodeForByStoreGroup").setValue(this.formGroup.get("storeGroupTypeCodeForByStoreGroup").value);
    fg.get("targetStoreType").setValue(this.formGroup.get("targetStoreType").value);
    fg.get("targetStoreCode").setValue(this.formGroup.get("targetStoreCode").value);
    fg.get("targetStoreGroupTypeCode").setValue(this.formGroup.get("targetStoreGroupTypeCode").value);
    fg.get("targetStoreGroupCode").setValue(this.formGroup.get("targetStoreGroupCode").value);
    fg.get("rank").setValue(this.formGroup.get("rank").value);

    viewContext.storeGroupNameRec = [... this.storeGroupNameRec];
    viewContext.storeGroupNameRecByStore = [... this.storeGroupNameRecByStore];
  
    this.ctgSelect.copyTo(viewContext.ctgSelect);
  }

  markAsPristine() {
    this.formGroup.markAsPristine();
    this.ctgFromGroup.markAsPristine();
  }

  isDirty() : boolean {
    this.formGroup.get("name").markAsPristine();
    if (this.formGroup.dirty) return true;
    if (this.ctgFromGroup.dirty) return true;

    return false;
  }
}
