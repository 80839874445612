import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSearchTaskRec, RspUpdateTaskRec, SubTaskAttachmentDto, TaskAttachmentDto, TaskRecDto } from 'src/app/webservice/task';
import { SubTask } from '../0_def/taskDefs';
import { TaskRec } from '../0_def/taskRec';
import { TaskService } from '../1_service/taskService';
import { SubtaskEditDialogComponent } from '../subtask-edit-dialog/subtask-edit-dialog.component';
import { RspGetStoreGroupType } from 'src/app/response/rsp-get-store-group-type';
import { StoreGroupTypeRec } from 'src/app/common/store-group-type-rec';
import { DbService, HtmlSelectItem } from 'src/app/service/db.servce';
import { DialogService } from 'src/app/service/dialog.service';
import { LoginStoreDto } from 'src/app/response/login-store-dto';

@Component({
  selector: 'app-hq-edit',
  templateUrl: './hq-edit.component.html',
  styleUrls: ['./hq-edit.component.css']
})
export class HqEditComponent implements OnInit, OnDestroy, OnChanges {

  public editTask: TaskRec;
  public minDate: Date;
  public dateBegin: Date;
  public dateEnd: Date;
  public formFile: FormControl = new FormControl();
  public formStoreGroupType: FormControl = new FormControl(-1);
  public filename: string = "";
  private file: File;
  public isFileReadError: boolean = false;
  public selectedSubTask: SubTask;
  private subscFile: Subscription;  
  private subscStoreGroup: Subscription;  
  public isUpdateRelease: boolean = false;
  public isDisablePickerDateRelease: boolean = false;
  public minPickerDateRelease: Date = this.commonService.copyDate(new Date());
  public storeGroupTypeList: StoreGroupTypeRec[] = [];
  public taskCategories: HtmlSelectItem[] = [];
  public storeList: LoginStoreDto[];

  @Input("taskRec") taskRec: TaskRec;
  @Input() requestTaskSearch: ReqSearchTaskRec;
  @Output() clearTaskList = new EventEmitter();
  @Output() refreshTaskList = new EventEmitter<TaskRecDto[]>();
  @Output() selectedTask = new EventEmitter<number>();

  @ViewChild("subTaskTable", {read: MatTable, static: false}) subTaskTable: MatTable<any>;
  @ViewChild(DateRangeComponent, { static: true }) dateRangeComponent: DateRangeComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic:    HttpBasicService,
    public taskService:   TaskService,
    private dbService:    DbService,
    private dialogService: DialogService
    ) {
      this.minDate = new Date();
      if (this.commonService.config.includeNonAutoOrderStore.task === false) {
        this.storeList = [...this.commonService.stores]
      } else {
        this.storeList = [...this.commonService.allStores]
      }
    }

  ngOnInit(): void {
    this.subscFile = this.formFile.valueChanges.subscribe(
      () => {this.fileChanged();}
    );
    this.getStoreGroupTypes();
    let subscStoreGroup = this.formStoreGroupType.valueChanges.subscribe(
      (value) => {
        this.storeGroupTypeChanged();
      }
    );
    this.getTaskCategories();
  }

  ngOnDestroy(): void {
    this.subscFile?.unsubscribe();
    this.subscStoreGroup?.unsubscribe();
  }

  ngOnChanges(): void {
    if (!this.taskRec) {
      this.editTask = undefined;
      return;
    }
    this.editTask = this.taskRec;

    let form = this.editTask.edits.dateBegin;
    if (form.value == undefined || form.value === "") {
      this.dateBegin = undefined;
    } else {
      this.dateBegin = form.value;
    }
    form = this.editTask.edits.dateEnd;
    if (form.value == undefined || form.value === "") {
      this.dateEnd = undefined;
    } else {
      this.dateEnd = form.value;
    }

    this.isDisablePickerDateRelease = (this.editTask?.releaseStatusBool &&
      this.isDateValid(this.editTask?.edits?.releaseDate?.value) &&
      this.commonService.copyDate(new Date(this.taskRec.edits.releaseDate.value)) <= this.commonService.copyDate(new Date())
    )
      ?? false;
    this.minPickerDateRelease = this.commonService.copyDate(new Date());

    this.minDate = this.isDateValid(this.editTask?.edits?.releaseDate?.value) ?
      this.editTask.edits.releaseDate.value :
      this.commonService.copyDate(new Date());

    this.formStoreGroupType.setValue(-1);
    if (this.editTask.releaseStatusBool) {
      this.formStoreGroupType.disable();
    } else {
      this.formStoreGroupType.enable();
    }

    setTimeout(() => this.editTask.markAsPristine());
  }

  getTaskCategories() {
    if (!this.commonService.config.task.useTaskCategory) return;
    this.dbService.getSelectItemList("task:taskCategory", "", (list) => { this.receiveTaskCategories(list); })
  }

  receiveTaskCategories(list: HtmlSelectItem[]) {
    this.taskCategories = list;
  }

  getStoreGroupTypes() {
    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.getStoreGroupType().subscribe(
      (response: RspGetStoreGroupType) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveStoreGroupType(response)
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveStoreGroupType(response: RspGetStoreGroupType) {
    if (this.httpBasic.handleAppError(response)) return;
    this.storeGroupTypeList = [];
    for (let type of response.storeGroupTypes) {
      let typeRec = new StoreGroupTypeRec();
      typeRec.storeGroupTypeCd = type.storeGroupTypeCdFn;
      typeRec.storeGroupType = type.storeGroupTypeFv;
      this.storeGroupTypeList.push(typeRec);
    }
  }

  storeGroupTypeChanged() {
    this.editTask.buildStoreGroupList(this.formStoreGroupType.value, undefined);
  }


  clearDesc() {
    this.editTask.edits.description.setValue("");
    this.editTask.markAsDirty();
  }

  importFileForDesc() {
    let elem = document.getElementById("input-file") as HTMLInputElement;
    elem.value = "";
    elem.click();
  }

  fileChanged() {
    let fileInput = document.getElementById('input-file')  as HTMLInputElement;
    if (fileInput.files[0]) {
      this.filename = fileInput.files[0].name;
      this.file = fileInput.files[0];
      this.isFileReadError = false;
    } else {
      this.filename = this.file.name;
    }

    let fileReader = new FileReader();
    this.commonService.openSpinner(this.commonService.pageTitle, "読込み中・・・")
    fileReader.onload = () => {
      this.commonService.closeSpinner();
      this.readComplete(fileReader);
    }
    fileReader.onerror = () => {
      this.isFileReadError = true;
      this.commonService.closeSpinner();

      if (fileReader.error.name == "NotReadableError") {
        this.commonService.openErrorDialog(this.commonService.pageTitle,
          "ファイルの読み込みに失敗しました。<br><br>" +
          "ファイル選択後にファイルが変更されました。<br>" +
          "ファイルを再度選択してください。"
          );
        return;
      }
      if (fileReader.error.name == "NotFoundError") {
        this.commonService.openErrorDialog(this.commonService.pageTitle,
          "ファイルの読み込みに失敗しました。<br><br>" +
          "ファイルが見つかりません。<br>" +
          "ファイルを再度選択してください。"
          );
        return;
      }
      this.commonService.openErrorDialog(this.commonService.pageTitle, "ファイルの読み込みに失敗しました。<br><br>"+fileReader.error);
      return;
    };

    let codeset: string = "UTF-8";
    let ua = navigator.userAgent;
    if (ua.indexOf('Windows') > 0) {
      codeset = "SJIS";
    }
    fileReader.readAsText(this.file, codeset);
  }

  readComplete(fileReader: FileReader) {
    let desc = this.editTask.edits.description.value;
    this.editTask.edits.description.setValue(desc + fileReader.result.toString());
    this.editTask.edits.description.markAsDirty();
  }

  storeSelectAll() {
    this.editTask.edits.storeList.forEach((store) => store.form.setValue(true));
    this.editTask.edits.storeGroupList.forEach((store) => {
      if (store.store === undefined) store.allChecked = true;
    });
    this.editTask.markAsDirty();
  }

  storeUnselectAll() {
    this.editTask.edits.storeList.forEach((store) => store.form.setValue(false));
    this.editTask.edits.storeGroupList.forEach((store) => {
      if (store.store === undefined) store.allChecked = false;
    });
    this.editTask.markAsDirty();
  }

  selectSubTask(subTask: SubTask) {
    this.selectedSubTask = subTask;
  }

  addNewSubTask() {
    this.editTask.addNewSubTask();
    this.subTaskTable?.renderRows();
  }

  subTaskDetail(subTask: SubTask) {
    this.editTask.syncSubTaskStoreList(subTask);
    let subsc = this.commonService.dialog.open(SubtaskEditDialogComponent, {
      disableClose: true,
      maxWidth: "95vw",
      maxHeight: "95vh",
      // position: {top: "5px"},
      data: {editTask: this.editTask, subTask: subTask, storeGroupTypeList: this.storeGroupTypeList, initialGroupType: this.formStoreGroupType.value}
    }).afterClosed().subscribe(
      (data) => {
        if (data) this.editTask.edits.formGroup.markAsDirty();
        subsc.unsubscribe();
      }
    );
  }

  deleteSubTask(subTask: SubTask) {
    this.editTask.edits.subTasks = this.editTask.edits.subTasks.filter(sub => sub.subTaskId !== subTask.subTaskId);
    // add delete Sub Attachment
    let subAtt: SubTaskAttachmentDto = {
      taskId: this.editTask.taskId,
      subTaskId: subTask.subTaskId,
      storeCd: this.commonService.loginUser.storeCd,
      attachmentTitle: subTask.subTaskFileName,
      attachmentFilename: subTask.subTaskFileName,
      attachment: "deleteFile"
    }
    this.editTask.edits.subAttachmentList.push(subAtt);
    this.subTaskTable?.renderRows();
    this.editTask.markAsDirty();
    this.selectedSubTask = undefined;
  }

  dateChanged(event) {
    this.editTask.edits.dateBegin.setValue(event.dateBegin);
    this.editTask.edits.dateEnd.setValue(event.dateEnd);
    this.editTask.markAsDirty();
  }

  saveTaskPreCheck() {
    if (this.editTask.releaseStatus === 1 && !this.editTask.edits.releaseStatus.value) {
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
        "非公開にすると、登録済みの各店舗の進捗情報は破棄されます。継続しますか？").subscribe(
        (response: boolean) => {
          subsc.unsubscribe();
          if (response) {
            this.saveTask();
          }
        }
      )
    } else {
      this.saveTask();
    }
  }

  saveTask() {
    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let request = this.editTask.getUpdateRequest();
    request.isUpdateRelease = this.isUpdateRelease;
    request = {
      ...request,
      dateEndMax: this.requestTaskSearch.dateEndMax,
      dateEndMin: this.requestTaskSearch.dateEndMin,
      releaseStatus: this.requestTaskSearch.releaseStatus,
      taskName: this.requestTaskSearch.taskName,
      authorFv: this.requestTaskSearch.authorFv,
      taskStatus: this.requestTaskSearch.taskStatus,
      responsibleClass: this.requestTaskSearch.responsibleClass,
      taskCategory: this.requestTaskSearch.taskCategory
    }
    let subsc = this.httpBasic.updateTaskRec(request).subscribe(
      (response: RspUpdateTaskRec) => {
        if (response) {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.receiveSaveTask(response);
        }
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSaveTask(response: RspUpdateTaskRec) {
    if(response.normalError.find(err => err.errId == "ME000000")) {
      // this.editTask.clearEdit();
      this.taskService.endEdit();
      this.clearTaskList.emit('clear true');
    }
    if (this.httpBasic.handleAppError(response)) {
      return;
    }

    this.refreshTaskList.emit(response.taskRecs);
    this.selectedTask.emit(response.taskId);

    if (this.editTask.taskId < 0) {
      this.editTask.taskId = response.taskId;
    }
    this.isUpdateRelease = false;
    this.editTask.prepareEdit();
    this.editTask.buildStoreGroupList(this.formStoreGroupType.value, undefined);
  }

  cancelEdit() {
    this.selectedSubTask = undefined;
    this.editTask.validError = undefined;

    if (this.editTask.taskId < 0) {
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
        "新規作成のタスクです。タスク作成を取消しますか？").subscribe(
          (response: boolean) => {
            subsc.unsubscribe();
            if (response) {
              this.editTask = undefined;
              this.taskService.endEdit();
            }
            // else {
              // this.editTask.clearEdit();
              // this.editTask.markAsDirty();
            // }
          });
      return;
    }

    this.dateBegin = this.isDateValid(this.editTask?.dateBegin) ? this.commonService.copyDate(new Date(this.editTask.dateBegin)) : null;
    this.dateEnd = this.isDateValid(this.editTask?.dateEnd) ? this.commonService.copyDate(new Date(this.editTask.dateEnd)) : null;

    this.isDisablePickerDateRelease = (this.editTask?.releaseStatusBool &&
      this.isDateValid(this.editTask?.releaseDate) &&
      this.commonService.getDate(this.taskRec.releaseDate) <= this.commonService.copyDate(new Date())
    ) ?? false;

    this.editTask.clearEdit();
    this.editTask.buildStoreGroupList(this.formStoreGroupType.value, undefined);
    let d : Date;
    if (this.isDateValid(this.editTask?.edits?.releaseDate?.value)) {
      // d = this.editTask.edits.releaseDate.value;
      this.minDate = this.commonService.copyDate(this.editTask?.edits?.releaseDate?.value);
    } else {
      // d = new Date();
      this.minDate = this.commonService.copyDate(new Date());
    }
    // this.minDate.setTime(d.getTime());
  }

  updateRelease(event: any) {
    /*
    if (!event?.checked && this.editTask.releaseStatus === 1 && ...) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,
        "終了日が過ぎているものは、公開の変更を出来ません。");
    }
    */

    if (event?.checked && this.editTask.releaseStatus === 1) {
      this.editTask?.edits?.releaseDate?.setValue(this.commonService.getDate(this.editTask.releaseDate));
      // this.minDate.setTime(this.editTask?.edits?.releaseDate?.value.getTime());
      this.minDate = this.commonService.copyDate(this.editTask?.edits?.releaseDate?.value);

      this.isDisablePickerDateRelease = true;
      this.isUpdateRelease = false;
      this.editTask.edits.formGroup.get("releaseStatus").markAsPristine();
      this.editTask.edits.formGroup.get("releaseDate").markAsPristine();
      return;
    }
    if (!event?.checked) this.isDisablePickerDateRelease = false;
    this.isUpdateRelease = true;
    if (event?.checked &&
      (!this.isDateValid(this.editTask?.edits?.releaseDate?.value) ||
        this.commonService.copyDate(new Date(this.editTask.edits.releaseDate.value)) < this.commonService.copyDate(new Date())
      )) {
      this.editTask?.edits?.releaseDate?.setValue(this.commonService.copyDate(new Date()));
    }
  }

  handlerReadFileError(readerEvt) {
    this.isFileReadError = true;
    this.commonService.closeSpinner();

    if (readerEvt.error.name == "NotReadableError") {
      this.commonService.openErrorDialog(this.commonService.pageTitle,
        "ファイルの読み込みに失敗しました。<br><br>" +
        "ファイル選択後にファイルが変更されました。<br>" +
        "ファイルを再度選択してください。"
        );
      return;
    }
    if (readerEvt.error.name == "NotFoundError") {
      this.commonService.openErrorDialog(this.commonService.pageTitle,
        "ファイルの読み込みに失敗しました。<br><br>" +
        "ファイルが見つかりません。<br>" +
        "ファイルを再度選択してください。"
        );
      return;
    }
    this.commonService.openErrorDialog(this.commonService.pageTitle, "ファイルの読み込みに失敗しました。<br><br>"+readerEvt.error);
    return;
  }

  handlerUpdateReleaseDate() {
    this.minDate = this.isDateValid(this.editTask?.edits?.releaseDate?.value) ?
      this.editTask.edits.releaseDate.value :
      this.commonService.copyDate(new Date());
  }

  getMaxPickerDateRelease(): Date {
    /*
    return this.isDateValid(this.editTask?.edits?.dateBegin?.value) ?
      this.commonService.copyDate(new Date(this.editTask.edits.dateBegin.value)) :
      null;
    */
    if (this.isDateValid(this.editTask?.edits?.dateEnd?.value)) {
      return this.commonService.copyDate(new Date(this.editTask.edits.dateEnd.value));
    } else {
      return null;
    }
  }

  isDateValid(input: string | Date): boolean {
    if (!input) return false;
    if (typeof input == 'string') {
      if (input.trim() == '') return false;
      const date = new Date(input);
      return (date instanceof Date && !isNaN(date.valueOf()));
    }

    return (input instanceof Date && !isNaN(input.valueOf()));
  }

  async onMultiFileSelected(event: any) {
    let files: FileList = event.target.files;
    let elem = document.getElementById("input-attachment") as HTMLInputElement;
    let taskMd5List = this.editTask.edits.attachmentList.map(f => f.md5);
    for (let i = 0; i < files.length; i++) {
      let f = files[i];
      await  this.commonService.blobToB64(f).then(result => {
        let newfile: TaskAttachmentDto = {
          taskId: this.taskRec.taskId,
          attachmentId: this.taskRec.getMaxFileId(this.editTask.edits.attachmentList), 
          attachmentFilename: f.name, 
          sortFn: this.taskRec.getMaxFileId(this.editTask.edits.attachmentList),
          md5: this.md5(result as string),
          attachmentTitle: f.name,
          attachment: result as string
        }
        if(taskMd5List.includes(newfile.md5)) {
          this.commonService.openNotificationDialog(this.commonService.pageTitle, `ファイル ` + f.name + ` が存在しています。（又は既存）`);
        }else {
          this.editTask.edits.attachmentList = [...this.editTask.edits.attachmentList, newfile];
          this.editTask.markAsDirty();
        }
      });
    }   
  }

  fileChange(files: TaskAttachmentDto[]) { 
    this.editTask.edits.attachmentList = files.map(file => {return{...file,attachmentTitle: file.attachmentTitle}})
    this.editTask.markAsDirty(); 
  }
	
  previewFileSubTask(subTask: SubTask) {
    if(!subTask.edits.subTaskAttachment.value){  
      const request = {
        taskId: this.taskRec.taskId,
        storeCd: null,
        subTaskId: subTask.subTaskId,
        access: this.commonService.loginUser
      }
      if(this.commonService.loginUser.roleId != 0) {
        // In case of screen "お仕事管理（本部）" (role 0) no need to check storeId(Null)
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.subTaskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            subTask.edits.subTaskAttachment.setValue(response.attachment);
            subTask.edits.subTaskFileName.setValue(response.attachmentFilename);
            let attachment = subTask.edits.subTaskAttachment.value;
            let filename = subTask.edits.subTaskFileName.value;
            await this.receivePreviewFileSubTask(attachment, filename);  
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {  
      let attachment = subTask.edits.subTaskAttachment.value;
      let filename = subTask.edits.subTaskFileName.value;
      this.receivePreviewFileSubTask(attachment, filename);
    }
  }

  async receivePreviewFileSubTask(attachment: string, filename: string) {
    let mimeType;
    let base64 = attachment.split(';base64,')[1];
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;

      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;

      default: mimeType ='NoSupport';
    }
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      if (mimeType == "NoSupport") {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `サポートされていないファイル形式`);
      }else {
        var iframe = "<iframe src='" + url + "' frameborder='0' style='border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;' allowfullscreen></iframe>";
        var openTab = window.open();
        openTab.document.open();
        openTab.document.write(iframe);
        openTab.document.title = filename; 
        openTab.document.close();
      } 
    });
  }

  onCheckNullFileSubTask(subTask: SubTask) {
    if (!subTask?.edits?.subTaskFileName.value) return false;
    if (subTask?.edits?.subTaskFileName.value == '') return false;
    return true;
  }

  onFileSubTaskSelected(event: any, subTask: SubTask) {
    if(event.target.files.length == 0) return;
    let file: File = event.target.files[0];
    //Check file duplicate
    if(subTask.subTaskFileName == file.name) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, `ファイル ` + file.name + ` が存在しています。（又は既存）`);
      return;
    }
    //Remove the file first
    this.editTask.edits.subAttachmentList.forEach((file, index) => {
      if(file.subTaskId == subTask.subTaskId) 
        this.editTask.edits.subAttachmentList.splice(index, 1);
    })
    this.commonService.blobToB64(file).then(data => {
      //add sub task info client - not save DB
      subTask.edits.subTaskFileName.setValue(file.name);
      subTask.edits.subTaskAttachment.setValue(data as string);
      subTask.edits.subTaskFileName.markAsDirty();
      // add sub task attachment - save to DB
      let subAtt: SubTaskAttachmentDto = {
        taskId: this.editTask.taskId,
        subTaskId: subTask.subTaskId,
        storeCd: this.commonService.loginUser.storeCd,
        attachmentTitle:file.name,
        attachmentFilename: file.name,
        attachment: data as string
      }
      this.editTask.edits.subAttachmentList.push(subAtt); 
      this.subTaskTable.renderRows();
    })
  }

  deleteMultiFile() {
    if(this.editTask.edits.attachmentList.length == 0) {
      return
    }
    if(this.taskRec.taskId < 0) {
      this.editTask.edits.attachmentList = [];
    }else {
      this.editTask.edits.attachmentList.map(file => {file.attachment = "deleteFile"; file.md5 = "deleteFile"});
    }
    this.editTask.markAsDirty();
  }

  getSubTaskFileName(subTask: SubTask) {
    return subTask.edits.subTaskFileName.value;
  }
  //  A formatted version of a popular md5 implementation.
//  Original copyright (c) Paul Johnston & Greg Holt.
//  The function itself is now 42 lines long.

  md5(inputString) {
    var hc="0123456789abcdef";
    function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
    function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
    function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
    function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
    function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
    function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
    function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
    function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
    function sb(x) {
        var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
        for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
        blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
    }
    var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
    for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
        a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
        b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
        c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
        d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
        a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
        b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
        c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
        d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
        a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
        b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
        c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
        d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
        a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
        b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
        c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
        d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
        a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
        b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
        c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
        d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
        a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
        b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
    }
    return rh(a)+rh(b)+rh(c)+rh(d);
  }

  adjustWorkTime(subTask: SubTask) {
    if (!this.commonService.config.task.workTimeIsHourAndMinutes) return;

    let h = parseInt(subTask.edits.workTimeH.value);
    let m = parseInt(subTask.edits.workTimeM.value);
    if (m >= 60) {
      h += Math.floor(m / 60);
      m %= 60;
    }
    if (parseInt(subTask.edits.workTimeH.value) !== h) {
      subTask.edits.workTimeH.setValue(h);
      subTask.edits.workTimeH.markAsDirty();
    }
    if (parseInt(subTask.edits.workTimeM.value) !== m) {
      subTask.edits.workTimeM.setValue(m);
      subTask.edits.workTimeM.markAsDirty();
    }
  }
}
