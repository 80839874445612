import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReqTaskOtherStoreDownloadXlsx } from "src/app/request/req-task-other-store-download-xlsx";
import { ReqTaskOtherStoreDownloadZip } from "src/app/request/req-task-other-store-download-zip";
import { RspGetUsers, UserDto } from "src/app/response/rsp-get-users";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { ReqSubTaskResult, ReqSubTaskResultOtherStoresSearch, ReqTaskImageData, ReqTaskImageThumbnailData, ReqTaskOtherStoresDownloadPDF, ReqTaskStaffRec, RspSubTaskResult, RspSubTaskResultOtherStoresSearch, RspTaskImageData, RspTaskImageThumbnailData, RspTaskStaffRec } from "src/app/webservice/task";
import { PhotoItem, SubTaskResult } from "../0_def/taskDefs";
import { SubTaskOtherStoresResultCommentRecDto } from "../0_def/taskOtherStoresCommentRec";
import { SubTaskOtherStoresResultRecDto } from "../0_def/taskOtherStoresRec";
import { TaskRec } from "../0_def/taskRec";
import { TaskStoreRec } from "../0_def/taskStoreRec";

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  public taskStatusNotStarted: string = "未着手";
  public taskStatusStarted: string = "着手";
  public taskStatusCompleted: string = "完了";

  public subTaskStatusNotAssigned: string = "担当者未設定";
  public subTaskStatusNotStarted: string = "未着手";
  public subTaskStatusStarted: string = "着手";
  public subTaskStatusCompleted: string = "完了";

  public taskPriorityList: string[] = ["A", "B", "C"]
  public responsibleClasses: string[] = [];
  public staffListAll: string[] = [];

  public editTask: TaskRec;
  // public viewTask:                TaskRec;
  public editTaskStore: TaskStoreRec;
  public isViewTaskStore: boolean = false;
  public storeCd: string;
  public subTaskOtherStoresRecs: SubTaskOtherStoresResultRecDto[] = [];
  public subTaskOtherStoresRecsRecordCount: number = 0;
  public subTaskOtherStoresCommentRecs: SubTaskOtherStoresResultCommentRecDto[] = [];
  public subTaskOtherStoresCommentRecsRecordCount: number = 0;

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService,
    private http: HttpClient
  ) {
    if (this.commonService.config.taskResponsibleClasses) {
      this.responsibleClasses = ["", ...this.commonService.config.taskResponsibleClasses];
    }
  }

  canEdit() {
    if (this.editTask == undefined) return true;
    if (!this.editTask.isDirty()) return true;
    return false;
  }
  canEditStore() {
    if (this.editTaskStore == undefined) return true;
    if (!this.editTaskStore.isDirty()) return true;
    return false;
  }

  isEditing(item: TaskRec) {
    if (this.editTask == undefined) return false;
    if (this.editTask != item) return false;
    return true;
  }
  isEditingStore(item: TaskStoreRec) {
    if (this.editTaskStore == undefined) return false;
    if (this.editTaskStore != item) return false;
    return true;
  }

  isEditingInProgress(item: TaskRec) {

    if (this.editTask == undefined) return false;
    if (this.editTask.taskId != item.taskId) return false;
    if (!this.editTask.isDirty()) return false;
    return true;
  }
  isEditingStoreInProgress(item: TaskStoreRec) {
    if (this.editTaskStore == undefined) return false;
    if (this.editTaskStore != item) return false;
    if (!this.editTaskStore.isDirty()) return false;
    return true;
  }

  startEdit(item: TaskRec) {
    let prev = this.editTask;
    prev?.endEdit();
    this.editTask = item;
    item.prepareEdit();
  }
  startEditStore(item: TaskStoreRec) {
    let prev = this.editTaskStore;
    prev?.endEdit();
    this.editTaskStore = item;
    item.prepareEdit();
  }

  endEdit() {
    let prev = this.editTask;
    this.editTask = undefined;
    prev?.endEdit();
  }
  endEditStore() {
    let prev = this.editTaskStore;
    this.editTaskStore = undefined;
    prev?.endEdit();
  }

  taskDeleted(task: TaskRec) {
    if (task == this.editTask) this.endEdit();
  }

  addStaff(staff: string) {
    if (!staff) return;
    if (staff === "") return;
    if (this.staffListAll.find((item) => item === staff)) return;
    this.staffListAll.push(staff);
    this.staffListAll = this.staffListAll.sort();
  }

  subTaskResult(item: TaskStoreRec, callback: () => void) {
    // Get SubtaskResult rec.
    let request: ReqSubTaskResult = {
      access: this.commonService.loginUser,
      taskId: item.taskId,
      storeCd: item.storeCd
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.subTaskResult(request).subscribe(
      (response: RspSubTaskResult) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveSubTaskResult(response, item, callback);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSubTaskResult(response: RspSubTaskResult, taskStore: TaskStoreRec, callback: () => void) {
    if (this.httpBasic.handleAppError(response)) {
      if (response.normalError.length > 0 && response.normalError[0].errId === "ME000000") {
        taskStore.isObsolete = true;
        this.editTaskStore = undefined;
      }
      return;
    }

    taskStore.subTaskResults = [];
    response.subTaskResults.forEach((item) => {
      let result: SubTaskResult = {
        subTaskId: item.subTaskId,
        responsible: item.subResponsible,
        responsibleUserId: item.subResponsibleUserId ?? "",
        responsibleUserName: item.subResponsibleUserName ?? "",
        isWorking: item.isWorking,
        isComplete: item.isComplete,
        subTaskStatus: item.isComplete ? "完了" : "",
        completeDateTime: item.completeDateTime,
        comment: item.comment,
        /*
        workTimeHResult: Math.floor(item.workTimeResult / 60),
        workTimeMResult: item.workTimeResult % 60,
        */
        workTimeHResult: 0,
        workTimeMResult: item.workTimeResult ,
        deadlineDate: item.deadlineDate,
        photos: []
      };
      if (this.commonService.config.task.workTimeIsHourAndMinutes) {
        result.workTimeHResult = Math.floor(item.workTimeResult / 60);
        result.workTimeMResult = item.workTimeResult % 60;
      }
      taskStore.subTaskResults.push(result);
    });

    taskStore.subTaskResults.forEach((result) => {
      let images = response.images.filter((item) => item.subTaskId == result.subTaskId);
      images.forEach((item) => {
        let photo: PhotoItem = {
          taskId: taskStore.taskId,
          storeCd: taskStore.storeCd,
          subTaskId: result.subTaskId,
          photoId: item.imageId,
          title: item.imageTitle,
          dataUrl: "",
          isNew: false,
          isUpdated: false,
          isDeleted: false,
          delFlagFn: item.delFlagFn
        };
        result.photos.push(photo);
      });
    });

    // callback();
    this.getUserList(taskStore, callback);
  }

  getUserList(taskStore: TaskStoreRec, callback: () => void) {
    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.getUsers(taskStore.storeCd).subscribe(
      (response: RspGetUsers) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveUserList(response, taskStore, callback);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveUserList(response: RspGetUsers, taskStore: TaskStoreRec, callback: () => void) {
    if (this.httpBasic.handleAppError(response)) return;

    taskStore.userList = [];
    response.users.forEach((user) => taskStore.userList.push(user));

    callback();
  }

  /*
  export interface ReqTaskImageData extends Req {
      taskId:             number;
      storeCd:            string;
      subTaskId:          number;
      imageId:            number;
  }
  export interface RspTaskImageData extends Rsp {
      taskId:             number;
      storeCd:            string;
      subTaskId:          number;
      imageId:            number;
      imageTitle:         string;
      imageData:          string;         // Data URL format
      isDeleted:          boolean;
  }
  */
  getImageData(photo: PhotoItem, callback: (item: PhotoItem) => void, noSpinner?: boolean) {
    let request: ReqTaskImageData = {
      access: this.commonService.loginUser,
      taskId: photo.taskId,
      storeCd: photo.storeCd,
      subTaskId: photo.subTaskId,
      imageId: photo.photoId
    };
    if (!noSpinner) var ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "画像取得中・・・");
    let subsc = this.httpBasic.getImageData(request).subscribe(
      (response: RspTaskImageData) => {
        subsc.unsubscribe();
        if (!noSpinner) this.commonService.closeSpinnerForSubComp(ref);
        this.receiveGetImageData(response, callback);
      },
      (error) => {
        subsc.unsubscribe();
        if (!noSpinner) this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetImageData(response: RspTaskImageData, callback: (item: PhotoItem) => void) {
    if (this.httpBasic.handleAppError(response)) return;

    let image: PhotoItem = {
      taskId: response.imageId,
      storeCd: response.storeCd,
      subTaskId: response.subTaskId,
      photoId: response.imageId,
      title: response.imageTitle,
      dataUrl: response.imageData,
      isNew: false,
      isUpdated: false,
      isDeleted: response.isDeleted
    }

    callback(image);
  }

  initStaff(storeCd: string) {
    let request: ReqTaskStaffRec = {
      access: this.commonService.loginUser,
      storeCd: storeCd
    };
    this.storeCd = storeCd;
    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "初期化中・・・");
    let subsc = this.httpBasic.generalRequest("TaskStaffRec", request).subscribe(
      (response: RspTaskStaffRec) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveInitStaff(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveInitStaff(response: RspTaskStaffRec) {
    if (this.httpBasic.handleAppError(response)) return;

    this.staffListAll = [];
    response.staffs.forEach((item) => this.staffListAll.push(item.staffName));
  }

  /* COMMON */

  async getThumbnailFromImage(imageUrl) {
    const THUMBNAIL_WIDTH = this.commonService.config.thumbnailWidth || 200;
    try {
      let retryNum = 0
      if (!imageUrl) return
      const canvas = document.createElement('canvas')
      canvas.style.display = 'none'

      var image = new Image()
      image.crossOrigin = 'anonymous'
      await new Promise<void>((resolve, reject) => {
        image.onload = function (imageEvent) {
          canvas.width = THUMBNAIL_WIDTH
          canvas.height = (image.height * THUMBNAIL_WIDTH) / image.width
          resolve()
        }
        image.src = imageUrl

        image.addEventListener('error', err => {
          reject(err)
        })
      })
      const context = canvas.getContext('2d')!
      context.drawImage(image, 0, 0, canvas.width, canvas.height)

      const thumbnailSrc = canvas.toDataURL('image/png')
      if (thumbnailSrc.length > THUMBNAIL_WIDTH * 30 && retryNum < 5)
        return thumbnailSrc
      retryNum++
      return this.getThumbnailFromImage(imageUrl)
    } catch (error) {
      return null
    }
  }

  /* END COMMON */

  searchSubTaskResultOtherStores(req: ReqSubTaskResultOtherStoresSearch, callback?: () => void) {
    const dialogRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.searchSubTaskResultOtherStores(req).subscribe(
      (response: RspSubTaskResultOtherStoresSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(dialogRef);
        this.receiveSubTaskResultOtherStores(response, callback);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(dialogRef);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSubTaskResultOtherStores(response: RspSubTaskResultOtherStoresSearch, callback?: () => void) {
    if (this.httpBasic.handleAppError(response)) return;
    this.subTaskOtherStoresRecs = [];
    this.subTaskOtherStoresRecs = response.result.map(item => new SubTaskOtherStoresResultRecDto(item, this.commonService, this))
    this.subTaskOtherStoresRecsRecordCount = response.recordCount || 0;

    if (callback) callback();
  }

  getImageThumbnailData(request: ReqTaskImageThumbnailData, callback?: (item: RspTaskImageThumbnailData) => void) {

    this.commonService.openSpinner(this.commonService.pageTitle, "画像取得中・・・");
    let subsc = this.httpBasic.getImageThumbnailData(request).subscribe(
      (response: RspTaskImageThumbnailData) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveGetImageThumbnailData(response, callback);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetImageThumbnailData(response: RspTaskImageThumbnailData, callback?: (item: RspTaskImageThumbnailData) => void) {
    if (this.httpBasic.handleAppError(response)) return;
    if(callback) callback(response);
  }

  exportPdfTaskOtherStores(req: ReqTaskOtherStoresDownloadPDF) {
    const url = this.commonService.config.wsBaseUrl + "TaskOtherStoreDownloadPdf";
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    const dialogRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    this.http.request('POST', url, { headers: headers, body: req, responseType: 'blob', observe: 'response' })
      .subscribe((response: HttpResponse<Blob>) => {
        this.commonService.closeSpinnerForSubComp(dialogRef);

        const resHeaders = response.headers;
        const contentDisposition = resHeaders.get('Content-disposition');
        const r = contentDisposition.split(';')[1].trim().split('=')[1]
        const filename = r.replace(/"/g, '');

        const blob = new Blob([response.body], { type: 'application/pdf' });
        this.commonService.downloadBlob(blob, filename || "PDF");
        /*
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename || "PDF";
        a.click();
        window.URL.revokeObjectURL(url);
        */
      }, error => {
        this.commonService.closeSpinnerForSubComp(dialogRef);
        this.commonService.openErrorDialog(this.commonService.pageTitle, "報告 エラー ");
      })
  }

  taskOtherStoreDownloadZip(req: ReqTaskOtherStoreDownloadZip) {
    const url = this.commonService.config.wsBaseUrl + "TaskOtherStoreDownloadZip";
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    const dialogRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    this.http.request('POST', url, { headers: headers, body: req, responseType: 'blob', observe: 'response' })
      .subscribe((response: HttpResponse<Blob>) => {
        this.commonService.closeSpinnerForSubComp(dialogRef);

        const resHeaders = response.headers;
        const contentDisposition = resHeaders.get('Content-disposition');
        const r = contentDisposition.split(';')[1].trim().split('=')[1]
        const filename = r.replace(/"/g, '');

        const blob = new Blob([response.body], { type: 'application/zip' });
        this.commonService.downloadBlob(blob, filename || "Zip");
        /*
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename || "Zip";
        a.click();
        window.URL.revokeObjectURL(url);
        */
      }, error => {
        this.commonService.closeSpinnerForSubComp(dialogRef);
        this.commonService.openErrorDialog(this.commonService.pageTitle, "報告 エラー ");
      })
  }
  taskOtherStoreDownloadXLsx(req: ReqTaskOtherStoreDownloadXlsx) {
    const url = this.commonService.config.wsBaseUrl + "TaskOtherStoreDownloadXlsx";
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    const dialogRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    this.http.request('POST', url, { headers: headers, body: req, responseType: 'blob', observe: 'response' })
      .subscribe((response: HttpResponse<Blob>) => {
        this.commonService.closeSpinnerForSubComp(dialogRef);

        const resHeaders = response.headers;
        const contentDisposition = resHeaders.get('Content-disposition');
        const r = contentDisposition.split(';')[1].trim().split('=')[1]
        const filename = r.replace(/"/g, '');

        const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        this.commonService.downloadBlob(blob, filename || "Xlsx");
        /*
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename || "Xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        */
      }, error => {
        this.commonService.closeSpinnerForSubComp(dialogRef);
        this.commonService.openErrorDialog(this.commonService.pageTitle, "報告 エラー ");
      })
  }

  pad(v: number){
    return (v < 10) ? '0'+v : v.toString();
  }
  
  getDateString(d: Date){
    let year = d.getFullYear()
    let month = this.pad(d.getMonth()+1)
    let day = this.pad(d.getDate())
    let hour = this.pad(d.getHours())
    let min = this.pad(d.getMinutes())
    let sec = this.pad(d.getSeconds())
    //YYYYMMDDhhmmss
    return year+month+day+hour+min+sec
    //YYYY-MM-DD hh:mm:ss
    //return year+"-"+month+"-"day+" "+hour+":"+min+":"+sec
  }

  getResponsibleName(userList: UserDto[], userId) {
    for (let i = 0; i < userList.length; i++) {
      if (userList[i].userId === userId) return userList[i].userName;
    }
    return "";
  }
}
