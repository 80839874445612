import { FormControl } from "@angular/forms";
import { ItemRec2 } from "src/app/common/item-rec2";

export class OrderGroupItem {
  public item:          OrderByUser;
  public delFlag:       boolean = false;
  public delFlagForm:   FormControl = new FormControl(false);

  getValue(key: string) {
    switch(key) {
      case "delFlag":
        return this.delFlag;
      case "delFlagForm":
        return this.delFlagForm.value;
      default:
        return this.item.getValue(key);
    }
  }

  isEdited(key: string) {
    return this.item.isEdited(key);
  }

  cancel() {
    this.delFlag = false;
    this.delFlagForm.setValue(false);
    this.item.cancel();
  }
}

export interface OrderGroup {
  groupId:      number;
  groupUsrCd:   string;
  groupName:    string;
  itemsBara:    OrderGroupItem[];       // includes bara order items only
  items:        OrderGroupItem[];       // includes bara order items and case order items
}

export class OrderByUser extends ItemRec2 {
}
