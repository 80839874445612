import { CommonService } from 'src/app/service/common.service';
import { SubTaskOtherStoresResultRecDto } from '../0_def/taskOtherStoresRec';
import { TaskStoreRec } from '../0_def/taskStoreRec';
import { ReqTaskImageThumbnailData, RspTaskImageThumbnailData, TaskImageRecDto } from 'src/app/webservice/task';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MatDialogRef } from '@angular/material/dialog';
import { PDFDocument, PDFFont, PDFImage, PDFPage, PageSizes, rgb } from 'pdf-lib';
import * as fontkit from '@pdf-lib/fontkit'
import { environment } from 'src/environments/environment';

interface ImgListItem {
  isImage: boolean;
  image: PDFImage | string;
  width: number;
  height: number;
  scale: number
}

export class TaskImagesPDF {

  private pdfDoc: PDFDocument;
  private pdfPage: PDFPage;
  // private timesRomanFont: PDFFont;
  private customFont: PDFFont;
  private pageSize: {width:number, height: number};
  private margins: {top: number, bottom: number, left: number, right: number, footer: number} = {top: 20, bottom: 40, left: 20, right: 20, footer: 10};
  private fontSize: number = 12;
  private lineHeight: number = 16;
  private columnHeaderWidth: number = 75;
  private textXOffset: number = 2;
  private borderWidth: number = 0.5;
  private nextYPosition: number = 0;
  private detailLineWidth: {
    store:        {rate: number, width: number},
    subTaskName:  {rate: number, width: number},
    status:       {rate: number, width: number},
    compDateTime: {rate: number, width: number},
    responsible:  {rate: number, width: number},
    image:        {rate: number, width: number}
  } = {
    store:        {rate: 14.0, width: 0},
    subTaskName:  {rate: 30.0, width: 0},
    status:       {rate: 12.0, width: 0},
    compDateTime: {rate: 14.0, width: 0},
    responsible:  {rate: 14.0, width: 0},
    image:        {rate: 0,    width: 0}
  };
  private maxPage: number = 0;

  private taskRec: TaskStoreRec;
  private spinnerRef : MatDialogRef<any, any>;

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService
    ) {
  }

  async createAndDownload(taskRec: TaskStoreRec, otherStoreRecs: SubTaskOtherStoresResultRecDto[]) {
    this.spinnerRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "PDF作成中・・・");
    this.taskRec = taskRec;

    let fonturl: string;
    if (environment.production) {
      fonturl = location.href.replace(/\/bin\/.*$/, "/bin/assets/font/ipaexg.ttf");
    } else {
      var href = location.href.split("/");
      fonturl = href[0] + "//" + href[2] + "/assets/font/ipaexg.ttf";
    }
    const fontBytes = await fetch(fonturl).then(res => res.arrayBuffer())

    this.pdfDoc = await PDFDocument.create();
    this.pdfDoc.registerFontkit(fontkit['default']);
    this.customFont = await this.pdfDoc.embedFont(fontBytes);
    this.newPage();
    this.pageSize = this.pdfPage.getSize();
    let drawableSize = this.getDrawableWidth();
    this.detailLineWidth.store.width = Math.floor(this.detailLineWidth.store.rate * drawableSize / 100);
    this.detailLineWidth.subTaskName.width = Math.floor(this.detailLineWidth.subTaskName.rate * drawableSize / 100);
    this.detailLineWidth.status.width = Math.floor(this.detailLineWidth.status.rate * drawableSize / 100);
    this.detailLineWidth.compDateTime.width = Math.floor(this.detailLineWidth.compDateTime.rate * drawableSize / 100);
    this.detailLineWidth.responsible.width = Math.floor(this.detailLineWidth.responsible.rate * drawableSize / 100);
    this.detailLineWidth.image.width
      = drawableSize -
        this.detailLineWidth.store.width -
        this.detailLineWidth.subTaskName.width -
        this.detailLineWidth.status.width -
        this.detailLineWidth.compDateTime.width -
        this.detailLineWidth.responsible.width;

    this.titleLine();
    this.dateRage();
    this.description();
    this.detailHeader();
    for (let i = 0; i < otherStoreRecs.length; i++) {
      await this.detailLine(otherStoreRecs[i]);
    }
    this.writeFooter();

    const pdfBytes = await this.pdfDoc.save();
    const blob = new Blob([pdfBytes], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    let timestamp = this.commonService.formatDateTime(new Date());
    timestamp = timestamp.replace(/\//g, "");
    timestamp = timestamp.replace(/\:/g, "");
    timestamp = timestamp.replace(" ", "_");
    a.href = url;
    a.download = "TaskImages_" + timestamp + ".pdf";
    a.click();
    a.remove();

    this.commonService.closeSpinnerForSubComp(this.spinnerRef);
  }

  newPage() {
    this.pdfPage = this.pdfDoc.addPage(PageSizes.A4);
    this.pdfPage.setFont(this.customFont);
    this.pdfPage.setFontSize(this.fontSize);
    this.maxPage++;
  }

  getX(x: number) {
    return x + this.margins.left;
  }

  getY(y: number) {
    return this.pageSize.height - (y + this.margins.top);
  }

  getDrawableWidth() {
    return this.pageSize.width - this.margins.left - this.margins.right;
  }

  titleLine() {
    this.pdfPage.drawText("タスク", { x: this.getX(0) + this.textXOffset, y: this.getY(0 + this.fontSize), size: this.fontSize });
    this.pdfPage.drawText(
      this.taskRec.taskName, 
      { x: this.getX(this.columnHeaderWidth) + this.textXOffset, y: this.getY(0 +  + this.fontSize), size: this.fontSize });
    this.pdfPage.drawRectangle({
      x: this.getX(0),
      y: this.getY(this.lineHeight + this.borderWidth),
      width: this.columnHeaderWidth,
      height: this.lineHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: this.borderWidth
    });
    this.pdfPage.drawRectangle({
      x: this.getX(this.columnHeaderWidth),
      y: this.getY(this.lineHeight + this.borderWidth),
      width:  this.getDrawableWidth() - this.columnHeaderWidth,
      height: this.lineHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: this.borderWidth
    });
  }

  dateRage() {
    this.pdfPage.drawText("期間", { x: this.getX(0) + this.textXOffset, y: this.getY(this.lineHeight + this.fontSize), size: this.fontSize });
    this.pdfPage.drawText(
      this.taskRec.dateBegin + " - " + this.taskRec.dateEnd,
      { x: this.getX(this.columnHeaderWidth) + this.textXOffset, y: this.getY(this.lineHeight + this.fontSize), size: this.fontSize });
    this.pdfPage.drawRectangle({
      x: this.getX(0),
      y: this.getY(this.lineHeight * 2 + this.borderWidth),
      width: this.columnHeaderWidth,
      height: this.lineHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: this.borderWidth
    });
    this.pdfPage.drawRectangle({
      x: this.getX(this.columnHeaderWidth),
      y: this.getY(this.lineHeight * 2 +  + this.borderWidth),
      width:  this.getDrawableWidth() - this.columnHeaderWidth,
      height: this.lineHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: this.borderWidth
    });
  }

  description() {
    this.pdfPage.drawText("タスク内容", { x: this.getX(0) + this.textXOffset, y: this.getY(this.lineHeight * 2 + this.fontSize), size: this.fontSize });
    let desc = this.wrapedText(this.taskRec.description, this.getDrawableWidth() - this.columnHeaderWidth - 4);
    if (desc.cnt === 0) desc.cnt = 1;
    this.pdfPage.drawText(
      desc.text,
      { x: this.getX(this.columnHeaderWidth) + this.textXOffset,
        y: this.getY(this.lineHeight * 2 + this.fontSize), 
        size: this.fontSize,
        lineHeight: this.lineHeight
      });

    this.pdfPage.drawRectangle({
      x: this.getX(0),
      y: this.getY(this.lineHeight * (2 + desc.cnt) + this.borderWidth),
      width: this.columnHeaderWidth,
      height: this.lineHeight * desc.cnt,
      borderColor: rgb(0, 0, 0),
      borderWidth: this.borderWidth
    });
    this.pdfPage.drawRectangle({
      x: this.getX(this.columnHeaderWidth),
      y: this.getY(this.lineHeight * (2 + desc.cnt) + this.borderWidth),
      width:  this.getDrawableWidth() - this.columnHeaderWidth,
      height: this.lineHeight * desc.cnt,
      borderColor: rgb(0, 0, 0),
      borderWidth: this.borderWidth
    });

    this.nextYPosition = this.lineHeight * (2 + desc.cnt);
  }

  detailHeader() {
    let xPos = 0;
    xPos = this.detailHeaderCell(xPos, this.detailLineWidth.store.width, "店舗名");
    xPos = this.detailHeaderCell(xPos, this.detailLineWidth.subTaskName.width, "子タスク名");
    xPos = this.detailHeaderCell(xPos, this.detailLineWidth.status.width, "ステータス");
    xPos = this.detailHeaderCell(xPos, this.detailLineWidth.compDateTime.width, "完了日時");
    xPos = this.detailHeaderCell(xPos, this.detailLineWidth.responsible.width, "担当者");
    xPos = this.detailHeaderCell(xPos, this.detailLineWidth.image.width, "参照画像");
    this.nextYPosition += this.lineHeight;
  }

  detailHeaderCell(xPos: number, width: number, name: string) {
    this.pdfPage.drawRectangle({
      x: this.getX(xPos),
      y: this.getY(this.nextYPosition + this.lineHeight + this.borderWidth),
      width: width, //  + this.borderWidth,
      height: this.lineHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: this.borderWidth,
      color: rgb(208 / 255, 208 / 255, 208 / 255)
    });
    let textWidth = this.customFont.widthOfTextAtSize(name, this.fontSize);
    this.pdfPage.drawText(
      name,
      { x: this.getX(xPos) + width / 2 - textWidth / 2,
        y: this.getY(this.nextYPosition + this.fontSize),
        size: this.fontSize,
        color: rgb(0, 0, 0)
      });
    return xPos + width;
  }

  async getSubTaskImageData(imgData: TaskImageRecDto): Promise<string> {
    return new Promise((resolve, reject) => {
      if (imgData.imageThumbnail) {
        resolve(imgData.imageThumbnail);
        return;
      }

      let request: ReqTaskImageThumbnailData = {
        access: this.commonService.loginUser,
        taskId: imgData.taskId,
        storeCd: imgData.storeCd,
        subTaskId: imgData.subTaskId,
        imageId: imgData.imageId
      };
      // let subsc = this.httpBasic.generalRequest("TaskImageData", request).subscribe(
      let subsc = this.httpBasic.generalRequest("TaskImageThumbnailData", request).subscribe(
        (response: RspTaskImageThumbnailData) => {
          subsc.unsubscribe();
          if ((response.fatalError && response.fatalError.length > 0) || (response.normalError && response.normalError.length > 0)) {
            resolve ("");
            return;
          }
          // imgData.image = response.imageData;
          imgData.imageThumbnail = response.imageData;
          resolve(response.imageData);
          return;
        },
        (error) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(this.spinnerRef);
          this.httpBasic.handleError(error);
          reject("");
          return;
        }
      );
    });
  }

  async detailLine(storeRec: SubTaskOtherStoresResultRecDto): Promise<void> {
    let imgList: ImgListItem[] = [];
    let itemLineHeight: number = 0;

    for (let i = 0; i < storeRec.imageList.length; i++) {
      let base64Image = await this.getSubTaskImageData(storeRec.imageList[i]);
      if (base64Image === "") {
        let txt = this.wrapedText("画像取得エラー", this.detailLineWidth.image.width);
        imgList.push({
          isImage: false,
          image: txt.text,
          width: 0,
          height: this.lineHeight * txt.cnt,
          scale: 1.0
        });
        itemLineHeight += this.lineHeight * txt.cnt;
        continue;
      }
      let imageType = base64Image.split("/")[1].split(";")[0];            // "data:image/png;base64,iVBORw0KG..."
      let found = ["jpeg", "png"].find((type) => type === imageType);
      if (!found) {
        let txt = this.wrapedText("未サポートの画像種別", this.detailLineWidth.image.width);
        imgList.push({
          isImage: false,
          image: txt.text,
          width: 0,
          height: this.lineHeight * txt.cnt,
          scale: 1.0
        });
        itemLineHeight += this.lineHeight * txt.cnt;
        continue;
      }
      let pdfImage: PDFImage;
      if (imageType == "jpeg") {
        pdfImage = await this.pdfDoc.embedJpg(base64Image);
      } else {
        pdfImage = await this.pdfDoc.embedPng(base64Image);
      }

      let maxWidth = this.detailLineWidth.image.width - 4;
      let maxHeight = maxWidth;
      let size = await this.getImageSize(base64Image);
      let imgWidth = size.width;
      let imgHeight = size.height;
      let scaleWidth = maxWidth / imgWidth;
      let scaleHeight = maxHeight / imgHeight;
      let scale = scaleWidth <= scaleHeight ? scaleWidth : scaleHeight;

      imgList.push({
        isImage: true,
        image: pdfImage,
        width: imgWidth * scale,
        height: imgHeight * scale,
        scale: scale
      });
      itemLineHeight += imgHeight * scale + 4;
    }
    if (itemLineHeight < this.lineHeight) itemLineHeight = this.lineHeight;

    if (this.nextYPosition + itemLineHeight > this.pageSize.height - this.margins.bottom) {
      this.nextYPosition = 0;
      this.newPage();
      this.detailHeader();
      let imgListSub: ImgListItem[] = [];
      let itemLineHeightSub = 0;
      for (let i = 0; i < imgList.length; i++) {
        let img = imgList[i];
        let nextHeight: number;
        if (img.isImage) {
          nextHeight = img.height + 4;
        } else {
          nextHeight = img.height;
        }
        if (this.nextYPosition + itemLineHeightSub + nextHeight <= this.pageSize.height - this.margins.bottom) {
          itemLineHeightSub += nextHeight;
          imgListSub.push(img);
        } else {
          await this.detailLineBody(storeRec, imgListSub, itemLineHeightSub);
          this.nextYPosition = 0;
          this.newPage();
          this.detailHeader();
          imgListSub = [img];
          itemLineHeightSub = nextHeight;
        }
      }
      await this.detailLineBody(storeRec, imgListSub, itemLineHeightSub);
    } else {
      await this.detailLineBody(storeRec, imgList, itemLineHeight);
    }

    return new Promise((resolve) => { resolve(); });
  }

  async detailLineBody(storeRec: SubTaskOtherStoresResultRecDto, imgList: ImgListItem[], itemLineHeight: number): Promise<void> {
    // let imgList: ImgListItem[] = [];
    // let itemLineHeight: number = 0;

    /*
    for (let i = 0; i < storeRec.imageList.length; i++) {
      let base64Image = await this.getSubTaskImageData(storeRec.imageList[i]);
      if (base64Image === "") {
        let txt = this.wrapedText("画像取得エラー", this.detailLineWidth.image.width);
        imgList.push({
          isImage: false,
          image: txt.text,
          width: 0,
          height: this.lineHeight * txt.cnt,
          scale: 1.0
        });
        itemLineHeight += this.lineHeight * txt.cnt;
        continue;
      }
      let imageType = base64Image.split("/")[1].split(";")[0];            // "data:image/png;base64,iVBORw0KG..."
      let found = ["jpeg", "png"].find((type) => type === imageType);
      if (!found) {
        let txt = this.wrapedText("未サポートの画像種別", this.detailLineWidth.image.width);
        imgList.push({
          isImage: false,
          image: txt.text,
          width: 0,
          height: this.lineHeight * txt.cnt,
          scale: 1.0
        });
        itemLineHeight += this.lineHeight * txt.cnt;
        continue;
      }
      let pdfImage: PDFImage;
      if (imageType == "jpeg") {
        pdfImage = await this.pdfDoc.embedJpg(base64Image);
      } else {
        pdfImage = await this.pdfDoc.embedPng(base64Image);
      }

      let maxWidth = this.detailLineWidth.image.width - 4;
      let maxHeight = maxWidth;
      let size = await this.getImageSize(base64Image);
      let imgWidth = size.width;
      let imgHeight = size.height;
      let scaleWidth = maxWidth / imgWidth;
      let scaleHeight = maxHeight / imgHeight;
      let scale = scaleWidth <= scaleHeight ? scaleWidth : scaleHeight;

      imgList.push({
        isImage: true,
        image: pdfImage,
        width: imgWidth * scale,
        height: imgHeight * scale,
        scale: scale
      });
      itemLineHeight += imgHeight * scale + 4;
    }
    if (itemLineHeight < this.lineHeight) itemLineHeight = this.lineHeight;

    if (this.nextYPosition + itemLineHeight > this.pageSize.height) {
      this.nextYPosition = 0;
      this.newPage();
      this.detailHeader();
    }
    */

    let texts = {};
    let txt = this.wrapedText(storeRec.storeCd + ":" + storeRec.storeName, this.detailLineWidth.store.width);
    texts["store"] = txt.text;
    if (itemLineHeight < this.lineHeight * txt.cnt) itemLineHeight = this.lineHeight * txt.cnt;

    txt = this.wrapedText(storeRec.subTaskName, this.detailLineWidth.subTaskName.width);
    texts["subTaskName"] = txt.text;
    if (itemLineHeight < this.lineHeight * txt.cnt) itemLineHeight = this.lineHeight * txt.cnt;

    txt = this.wrapedText(storeRec.completeStatus, this.detailLineWidth.status.width);
    texts["status"] = txt.text;
    if (itemLineHeight < this.lineHeight * txt.cnt) itemLineHeight = this.lineHeight * txt.cnt;

    let comp = storeRec.completeDateTime.replace(" ", "\n");
    txt = this.wrapedText(comp, this.detailLineWidth.compDateTime.width);
    texts["compDateTime"] = txt.text;
    if (itemLineHeight < this.lineHeight * txt.cnt) itemLineHeight = this.lineHeight * txt.cnt;

    txt = this.wrapedText(storeRec.subResponsible, this.detailLineWidth.responsible.width);
    texts["responsible"] = txt.text;
    if (itemLineHeight < this.lineHeight * txt.cnt) itemLineHeight = this.lineHeight * txt.cnt;

    let xPos = 0;
    this.drawDetailText(
      storeRec,
      texts["store"],
      xPos,
      this.detailLineWidth.store.width,
      itemLineHeight);
    xPos += this.detailLineWidth.store.width;

    this.drawDetailText(
      storeRec,
      texts["subTaskName"],
      xPos,
      this.detailLineWidth.subTaskName.width,
      itemLineHeight);
    xPos += this.detailLineWidth.subTaskName.width;

    this.drawDetailText(
      storeRec,
      texts["status"],
      xPos,
      this.detailLineWidth.status.width,
      itemLineHeight);
    xPos += this.detailLineWidth.status.width;

    this.drawDetailText(
      storeRec,
      texts["compDateTime"],
      xPos,
      this.detailLineWidth.compDateTime.width,
      itemLineHeight);
    xPos += this.detailLineWidth.compDateTime.width;

    this.drawDetailText(
      storeRec,
      texts["responsible"],
      xPos,
      this.detailLineWidth.responsible.width,
      itemLineHeight);
    xPos += this.detailLineWidth.responsible.width;

    let yPos = this.nextYPosition;

    if (imgList.length === 0) {
      this.pdfPage.drawRectangle({
        x: this.getX(xPos),
        y: this.getY(yPos + itemLineHeight + this.borderWidth),
        width:  this.detailLineWidth.image.width,
        height: itemLineHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth: this.borderWidth
      });
    }

    for (let i = 0; i < imgList.length; i++) {
      let img = imgList[i];
      let colWidth = this.detailLineWidth.image.width;
      if (img.isImage) {
        this.pdfPage.drawImage(<PDFImage>img.image, {
          x: this.getX(xPos + colWidth / 2 - img.width / 2),
          y: this.getY(yPos + img.height + 2),
          width: img.width,
          height: img.height,
        });
        this.pdfPage.drawRectangle({
          x: this.getX(xPos),
          y: this.getY(yPos + img.height + 4 + this.borderWidth),
          width:  colWidth,
          height: img.height + 4,
          borderColor: rgb(0, 0, 0),
          borderWidth: this.borderWidth
        });
        yPos += img.height + 4;
      } else {
        this.pdfPage.drawText(
          <string>img.image,
          { x: this.getX(xPos) + this.textXOffset,
            y: this.getY(yPos + this.fontSize),
            size: this.fontSize,
            lineHeight: this.lineHeight
        });
        if (imgList.length === 1) {
          this.pdfPage.drawRectangle({
            x: this.getX(xPos),
            y: this.getY(yPos + itemLineHeight + this.borderWidth),
            width:  colWidth,
            height: itemLineHeight,
            borderColor: rgb(0, 0, 0),
            borderWidth: this.borderWidth
          });
          yPos += itemLineHeight;
        } else {
          this.pdfPage.drawRectangle({
            x: this.getX(xPos),
            y: this.getY(yPos + img.height + this.borderWidth),
            width:  colWidth,
            height: img.height,
            borderColor: rgb(0, 0, 0),
            borderWidth: this.borderWidth
          });
          yPos += img.height;
        }
      }
    }

    this.nextYPosition += itemLineHeight;

    return new Promise((resolve) => { resolve(); });
  }

  drawDetailText(storeRec: SubTaskOtherStoresResultRecDto, text: string, xPos: number, width: number, height: number) {
    this.pdfPage.drawText(
      text,
      { x: this.getX(xPos) + this.textXOffset,
        y: this.getY(this.nextYPosition + this.fontSize), 
        size: this.fontSize,
        lineHeight: this.lineHeight
    });
    this.pdfPage.drawRectangle({
      x: this.getX(xPos),
      y: this.getY(this.nextYPosition + height + this.borderWidth),
      width:  width,
      height: height,
      borderColor: rgb(0, 0, 0),
      borderWidth: this.borderWidth
    });
  }

  writeFooter() {
    for (let i = 0; i < this.maxPage; i++) {
      let page = this.pdfDoc.getPage(i);
      let pageNum = "- " + (i + 1) + " / " + this.maxPage + " -";
      let pageNumWidth = this.customFont.widthOfTextAtSize(pageNum, this.fontSize);
      page.drawText(
        pageNum,
        { x: this.pageSize.width / 2 - pageNumWidth / 2,
          y: this.margins.footer,
          size: this.fontSize,
          lineHeight: this.lineHeight
      });
    }
  }

  async getImageSize(base64Image: string): Promise<{width: number, height: number}> {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => {
        resolve({width: img.width, height: img.height});
      };
      img.src = base64Image;
    });
  }

  wrapedText(text: string, areaWidth: number): {text: string, cnt: number} {
    if (text === undefined) return {text: "", cnt: 1};

    let scanText = text;
    let retText = "";
    let cnt  = 0;

    while (scanText !== "") {
      let pos = scanText.indexOf("\n");
      let line;
      if (pos >= 0) {
        line = scanText.slice(0, pos + 1);
        scanText = scanText.slice(pos + 1);
      } else {
        line = scanText;
        scanText = "";
      }
      let textWidth = this.customFont.widthOfTextAtSize(line, this.fontSize);
      if (textWidth > areaWidth) {
        let tempText = "";
        let lastChar = "";
        for (let i = 0; i < line.length; i++) {
          if (this.customFont.widthOfTextAtSize(tempText + line[i], this.fontSize) <= areaWidth) {
            tempText += line[i];
            continue;
          }
          cnt++;
          retText += tempText + "\n";
          tempText = line[i];
        }
        if (tempText !== "" && tempText !== "\n") {
          cnt++;
          retText += tempText + "\n";
        }
      } else {
        cnt++;
        retText += line;
      }
    }

    return {text: retText, cnt: cnt};
  }
}
