import { Subscription } from "rxjs";
import { CommonService } from "src/app/service/common.service";
import { OrderGroupUser2Rec } from "./order-group-user";

export class StoreUser2Rec {

  public userId:      string      = "";
  public userName:    string      = "";
  public storeCd:     string      = "";
  public groups:      OrderGroupUser2Rec[] = [];
  public groupMap:    {} = {};

  public  edit: {
    assignCount:    number;
    groups:         OrderGroupUser2Rec[];
    groupMap:       {};
  };

  private subsc:    Subscription[]  = [];
  private changeListener: ()=>void;

  constructor(private commonService: CommonService) {

  }

  setListner(func:()=>void) {
    this.changeListener = func;
  }

  countAssign() {
    let count = 0;
    if (!this.edit) {
      this.groups.forEach((user) => {
        // if (user.hasAssign()) count++;
        if (user.isActive()) count++;
      });
    } else {
      this.edit.groups.forEach((user) => {
        // if (user.hasAssign()) count++;
        if (user.isActive()) count++;
      });
    }
    return count;
  }

  updateAssign() {
    if (!this.edit) return;
    this.edit.assignCount = this.countAssign();
  }

  prepareEdit() {
    this.edit = {
      assignCount: this.countAssign(),
      groups: [...this.groups],
      groupMap: {...this.groupMap}
    };
    // this.groups.forEach((user) => user.prepareEdit());
  }

  addGroup(group: OrderGroupUser2Rec) {
    if (!this.edit) {
      if (this.findGroup(group.groupId)) return;
      this.groups.push(group);
      this.groupMap["" + group.groupId] = group;
      return;
    }
    if (this.findGroup(group.groupId)) return;
    this.edit.groups.push(group);
    this.edit.groupMap["" + group.groupId] = group;
  }

  findGroup(groupId: number) {
    if (!this.edit) {
      if (!this.groupMap["" + groupId]) return undefined;
      return this.groupMap["" + groupId];
    }
    if (!this.edit.groupMap["" + groupId]) return undefined;
    return this.edit.groupMap["" + groupId];
  }

  getValue(key: string) {
    switch(key) {
      case "assignCount":
        if (this.edit) return this.edit.assignCount;
        return "";
      default:
        return this[key];
    }
  }

  saveEdit() {
    if (!this.edit) return;
    this.groups = [];
    this.groupMap = {};

    this.edit.groups.forEach((rec) => {
      if (rec.hasAssign()) {
        this.groups.push(rec);
        this.groupMap["" + rec.groupId] = rec;
      }
    });
  }

  cancelEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  cleanupEdit() {
    this.edit.groups.forEach((user) => user.cleanupEdit());
    this.subsc.forEach((subsc) => subsc.unsubscribe());
    this.subsc = [];
    if (this.edit) {
      this.edit.groups.forEach((item) => item.cleanupEdit());
      delete this.edit;
    }
  }

  endEdit() {
    this.cleanupEdit();
  }
}
