import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../service/common.service';
import jsQR from 'jsqr';
import { FormControl } from '@angular/forms';

export interface CameraData {
  src: string;
};

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.css']
})
export class QrcodeDialogComponent implements OnInit, OnDestroy {

  private stop: boolean = false;
  private video: HTMLVideoElement;
  private canvasElement: HTMLCanvasElement;
  private canvas: CanvasRenderingContext2D;
  private outputContainer: HTMLElement;
  // private outputMessage: HTMLElement;
  // private outputData: HTMLElement;

  public formCode: FormControl = new FormControl("");

  constructor(
    public dialogRef: MatDialogRef<QrcodeDialogComponent>,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.commonService.audioBeep = new Audio();
    this.commonService.audioBeep.src = "assets/audio/beep.mp3";
    this.commonService.audioBeep.load();

    this.startScan();
  }

  ngOnDestroy() {
    this.video.pause();
    this.stop = true;
  }
  
  startScan() {
    this.video = document.createElement("video");
    this.canvasElement = document.getElementById("canvas") as HTMLCanvasElement;
    this.canvas = this.canvasElement.getContext("2d");
    this.outputContainer = document.getElementById("output");
    // this.outputMessage = document.getElementById("outputMessage");
    // this.outputData = document.getElementById("outputData");

    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(
      (stream) => {
        this.video.srcObject = stream;
        this.video.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
        this.video.play();
        requestAnimationFrame(() => {this.tick()});
      },
      () => {
        this.commonService.openErrorDialog(this.commonService.pageTitle, "この端末ではご利用いただけない機能です");
        this.dialogRef.close();
      }
    );
  }

  tick() {
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      this.canvasElement.hidden = false;
      this.outputContainer.hidden = false;

      this.canvasElement.height = this.video.videoHeight;
      this.canvasElement.width = this.video.videoWidth;
      this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
      var imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
      var code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });
      if (code) {
        this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
        this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
        this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
        this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
        // this.outputMessage.hidden = true;
        // this.outputData.parentElement.hidden = false;
        // this.outputData.innerText = code.data;
        this.formCode.setValue(code.data);
        this.video.pause();
        setTimeout(()=> { this.closePopup(code.data) }, 2000);
        return;
      } else {
        // this.outputMessage.hidden = false;
        // this.outputData.parentElement.hidden = true;
      }
    }
    if (this.stop) return;
    requestAnimationFrame(() => {this.tick()});
  }

  drawLine(begin, end, color) {
    this.canvas.beginPath();
    this.canvas.moveTo(begin.x, begin.y);
    this.canvas.lineTo(end.x, end.y);
    this.canvas.lineWidth = 4;
    this.canvas.strokeStyle = color;
    this.canvas.stroke();
  }

  handleError(error) {
    this.dialogRef.close();
    this.commonService.openErrorDialog(this.commonService.pageTitle, error);
  }

  onClose() {
    this.video.pause();
    this.dialogRef.close();
  }

  closePopup(code) {
    this.video.pause();
    this.dialogRef.close(code);
  }

  isHiddenCaptureImageButton() {
    return false;
  }

  onBeep() {
    this.commonService.audioBeep.pause();
    this.commonService.audioBeep.currentTime = 0;
    this.commonService.audioBeep.loop = false;
    this.commonService.audioBeep.play();
  }
}
