import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { CsvData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-csv-table',
  templateUrl: './csv-table.component.html',
  styleUrls: ['./csv-table.component.css']
})
export class CsvTableComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;


  private maxHeightDefault: number = 400;
  private isError: boolean = false;
  public recordCount: number = 0;
  public pagenatorMaxWidth: any;
  public tableWidth: any;
  public tableContainerStyle: any;
  public displayColumnIds: string[];
  public columnIds: string[] = ["lineNumber"];
  public columnIdsWithError: string[] = ["lineNumber", "errorWaring"];
  public displayColumnDefs: TableColumnDef[] = [];
  public csvDataDisplay: CsvData;
  @Input("columnDef") columnDefs: TableColumnDef[];
  @Input("csvData") csvData: CsvData;
  @Input("maxHeight") maxHeight: number = this.maxHeightDefault;
  // @Input() panigator: boolean = false;
  @Input() paginator: boolean = false;

  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.displayColumnDefs = [...this.columnDefs];
    this.setupColumns();
  }

  ngOnChanges() {
    this.csvDataDisplay = this.csvData;
    this.buildCsvDisplayList(0)
    this.setupColumns();
  }


  init(csvData: CsvData) {
    this.csvData = csvData;
    this.setupColumns();
  }

  handleError() {
    let errorCount = 0;
    let warningCount = 0;
    for (let line of this.csvData.lines) {
      if (line.errorMsg && line.errorMsg.length > 0) errorCount++;
      if (line.warningMsg && line.warningMsg.length > 0) warningCount++;
    }
    this.csvData.errorCount = errorCount;
    this.csvData.warningCount = warningCount;
    this.setupColumns();
  }

  setupColumns() {
    if (this.csvData.errorCount || this.csvData.warningCount &&
      this.csvData.errorCount > 0 || this.csvData.warningCount > 0) {
      this.displayColumnIds = [...this.columnIdsWithError];
      this.isError = true;
    } else {
      this.displayColumnIds = [...this.columnIds];
      this.isError = false;
    }
    for (let col of this.columnDefs) {
      this.displayColumnIds.push(col.columnId);
    }

    this.calcTableWidth();
  }

  styleFor(id: string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    width = width + 60 + 1 + 8;    // for line number
    if (this.isError) {
      width = width + 200 + 1 + 8;    // for Error/Warning Cell
    }

    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  
    this.pagenatorMaxWidth = { "width": "" + width + "px" };
  
    this.tableContainerStyle = {
      // "max-width": "" + (width + 17) + "px",    /* 17 for Vertical scroll bar */
      "max-height": this.maxHeight ? this.maxHeight  + "px" : "unset"
    };
  }

  getLineCount() {
    return this.csvData.lines.length;
  }

  getErrorCount() {
    if (this.csvData.errorCount == undefined) return "";
    return this.csvData.errorCount
  }

  getWarningCount() {
    if (this.csvData.warningCount == undefined) return "";
    return this.csvData.warningCount
  }
  pageChanged(event) {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.buildCsvDisplayList(event);
    this.setupColumns();
    this.commonService.closeSpinner();
  }

  buildCsvDisplayList(event: any) {
    if (!event &&  this.matPaginator) {
      this.matPaginator.pageIndex = 0;
    }
    let start: number;
    let last: number;
    if (this.paginator) {
      if (this.matPaginator) {
        start = this.matPaginator.pageSize * this.matPaginator.pageIndex;
        last = start + this.matPaginator.pageSize;
      } else {
        start = 0;
        last = this.commonService.paginatorOption.pageSizeOptions[0];
      }
    } else {
      start = 0;
      last = this.csvData.lines.length;
    }

    let max = this.csvData.lines.length;
    this.recordCount = this.csvData.lines.length;
    if (max > 100) max = 100;
    this.csvDataDisplay = {
      errorCount: this.csvData.errorCount,
      warningCount: this.csvData.warningCount,
      lines: []
    }
    for (let i = start; i < last && i < this.csvData.lines.length; i++) {
      this.csvDataDisplay.lines.push(this.csvData.lines[i]);
      // if (this.csvData.lines[i].errorMsg.length > 0) this.csvData.errorCount++;
      // if (this.csvData.lines[i].warningMsg.length > 0) this.csvData.warningCount++;
    }
  }
  
}
