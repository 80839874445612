import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { OrderGroupNameDto } from 'src/app/webservice/order-by-user';
import { OrderGroup } from '../order-by-user';

export interface GroupName {
  groupId:      number;
  groupUsrCd:   string;
  groupName:    string;
  userName:     string;
  isSelected:   boolean;
  onButton:     boolean;
  marking:      boolean;
  markingForm:  FormControl;
}

@Component({
  selector: 'app-select-order-group',
  templateUrl: './select-order-group.component.html',
  styleUrls: ['./select-order-group.component.css']
})
export class SelectOrderGroupComponent implements OnInit, OnDestroy {

  public dispData: GroupName[];
  public showData: GroupName[];
  public selectedOrderGroup: GroupName;

  public columnIds: string[] = ["groupUsrCd", "groupName", "userName", "marking"];
  public columnDefs: TableColumnDef[] = [
    {columnId:"groupUsrCd", header: "コード", width:100, align: "left"},
    {columnId:"groupName", header: this.commonService.literal["orderGroup"], width:200, align: "left"},
    {columnId:"userName", header:"ユーザ名", width:300, align: "left"}
  ];

  public formCode: FormControl = new FormControl("");
  public formName: FormControl = new FormControl("");
  private groupMarking: {
    markDate: string;
    groupIds: number[];
  };

  private subsc: Subscription[] = [];

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    public dialogRef: MatDialogRef<SelectOrderGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
        orderGroupNames: OrderGroupNameDto[];
        orderGroups: OrderGroup[];
        selectedOrderGroup: OrderGroup
      }) {

      this.dispData = [];
      let today = this.commonService.formatDate(new Date());
      this.groupMarking = this.commonService.userConfig?.orderByUser?.groupMarking;
      if (!this.groupMarking || this.groupMarking.markDate < today) {
        this.groupMarking = {
          markDate: today,
          groupIds: []
        }
      }

      data.orderGroupNames.forEach((gname) => {
        let mark = this.groupMarking.groupIds.find((groupId) => groupId === gname.groupId) !== undefined ? true : false;
        let item: GroupName = {
          groupId:    gname.groupId,
          groupUsrCd: gname.groupUsrCd,
          groupName:  gname.groupName,
          userName:   gname?.users?.length > 0 ? gname.users.map(user => `${user?.userName !== "" ? user.userName : ""}${user?.userId !== "" ? ' [ ' + user.userId + ' ]' : ''}`).join(", ") : "",
          isSelected: gname.groupId === data.selectedOrderGroup?.groupId,
          onButton:   data.orderGroups.find((group) => group.groupId === gname.groupId) !== undefined ? true : false,
          marking:    mark,
          markingForm: new FormControl(mark)
        };
        this.dispData.push(item);
      });
      this.showData = [...this.dispData];
    }

  ngOnInit(): void {
    this.subsc.push(
      this.formCode.valueChanges.subscribe((val) => {this.filterList();})
    );
    this.subsc.push(
      this.formName.valueChanges.subscribe((val) => {this.filterList();})
    );
  }

  ngOnDestroy(): void {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
  }

  selectOrderGroup(group: GroupName) {
    this.selectedOrderGroup = group;
  }

  onConfirmed() {
    this.updateGroupMark();
    this.dialogRef.close(this.selectedOrderGroup.groupId);
  }

  onClose() {
    this.updateGroupMark();
    this.dialogRef.close();
  }

  updateGroupMark() {
    this.groupMarking.groupIds = [];
    this.dispData.forEach((item) => {
      if (item.markingForm.value) this.groupMarking.groupIds.push(item.groupId);
    });
    if (!this.commonService.userConfig.orderByUser) {
      this.commonService.userConfig.orderByUser = {
        groupMarking: {...this.groupMarking}
      };
    } else {
      this.commonService.userConfig.orderByUser.groupMarking = {...this.groupMarking};
    }
    this.httpBasic.saveUserConfig();
  }

  filterList() {
    let code = this.formCode.value;
    let name = this.formName.value;
    this.showData = [];
    for (let i = 0; i < this.dispData.length; i++) {
      let rec = this.dispData[i];
      if (code !== "" && !rec.groupUsrCd.includes(code)) continue;
      if (name !== "" && !rec.groupName.includes(name)) continue;
      this.showData.push(rec);
    }
  }

  rowColor(gname: GroupName) {
    if (gname === this.selectedOrderGroup) return 1;
    if (gname.isSelected) return 2;
    if (gname.onButton) return 3;
    return 0;
  }

  cancelMark() {
    this.dispData.forEach((item) => {item.markingForm.setValue(item.marking);});
  }

  selectMarkAll() {
    this.dispData.forEach((item) => {item.markingForm.setValue(true);});    
  }

  unselectMarkAll() {
    this.dispData.forEach((item) => {item.markingForm.setValue(false);});    
  }

  onScan(val: string) {
    this.formCode.setValue(val);
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          this.formCode.setValue(data);
        }
      }
    )
  }
}
