import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ItemSimpleRec } from 'src/app/common/item-simple-rec';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ItemListSimpleComponent } from 'src/app/partsCommon/item-list-simple/item-list-simple.component';
import { ItemSelectSimpleCondition } from 'src/app/partsCommon/item-select-simple/item-select-simple.component';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { OrderStopTermItemAddDto } from 'src/app/request/req-order-stop-term-item-add';
import { OrderStopTermItemDelDto } from 'src/app/request/req-order-stop-term-item-delete';
import { ReqStoreInit } from 'src/app/request/req-order-stop-term-item-store-search';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { RspOrderStopTermItemInit } from 'src/app/response/rsp-order-stop-term-item';
import { RspStoreInit } from 'src/app/response/rsp-order-stop-term-item-store-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { OrderStopTermItemRec } from './order-stop-term-item-rec';

@Component({
  selector: 'app-order-stop-term-item',
  templateUrl: './order-stop-term-item.component.html',
  styleUrls: ['./order-stop-term-item.component.css']
})
export class OrderStopTermItemComponent implements OnInit, OnDestroy {

  private subscriptionInit: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscriptionStoreCd: Subscription;
  private subscriptionConfirm: Subscription;

  public formGroup: FormGroup;
  public tableWidth: any;
  public columnIds: string[] = ["removeItem", "storeCd", "itemCd", "itemName", "dateType", "dateBegin", "dateEnd"];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'storeCd', header: "店舗", width: 150 },
    { columnId: 'itemCd', header: "商品コード", width: 100, align: "center" },
    { columnId: 'itemName', header: "商品名", width: 300 },
    { columnId: 'dateType', header: "日付種別", width: 60, align: "center" },
    { columnId: 'dateBegin', header: "開始日", width: 100, align: "center" },
    { columnId: 'dateEnd', header: "終了日", width: 100, align: "center" }
  ];
  public itemSelectSimpleCondition: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fb);
  public itemSelectSimpleConditionOrigin: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fbOrigin);

  public stores: LoginStoreDto[];
  public stopList: OrderStopTermItemRec[] = [];

  public selectedItem: ItemSimpleRec;

  @ViewChild(ItemListSimpleComponent, { static: true }) itemListSimpleComponent: ItemListSimpleComponent;
  @ViewChild("sectionSelectCondHeader", { static: true }) sectionSelectCond: SectionHeaderComponent;
  @ViewChild("sectionItemsHeader", { static: true }) sectionItems: SectionHeaderComponent;
  @ViewChild("sectionCondHeader", { static: true }) sectionCond: SectionHeaderComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private fbOrigin: FormBuilder,
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = "期間指定発注停止（商品）";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    if (this.commonService.stores.length > 1) {
      this.stores = [{ storeCd: "*", storeName: "全店" }, ...this.commonService.stores];
    } else {
      this.stores = [...this.commonService.stores];
    }
    this.initOrderStopTermItemStore(this.commonService.loginUser.storeCd);
    this.initFormGroup();
    this.calcTableWidth();
  }

  initOrderStopTermItemStore(storeCd: string) {
    this.subscriptionInit = this.httpBasic.initOrderStopTermItem(storeCd).subscribe(
      result => {
        this.checkResult(result);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  ngOnDestroy() {
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    if (this.subscriptionStoreCd) this.subscriptionStoreCd.unsubscribe();
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.storeCd],
      itemCd: [],
      itemName: [],
      dateType: ["1"],
      dateBegin: [],
      dateEnd: []
    });
  }

  checkResult(response: RspOrderStopTermItemInit) {
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.stopList = [];
    let itemCount = 0;
    let maxItems = 1000;
    for (var orderStopTermSupp of response.result) {
      itemCount++;
      if (itemCount > maxItems) {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `${maxItems}件を超える商品が検索されました。最初の${maxItems}件のみが処理対象となります。`);
        break;
      }
      this.stopList.push(new OrderStopTermItemRec(orderStopTermSupp));
    }
  }

  doInsert() {
    let storeName: string = this.stores.filter(store => store.storeCd === this.formGroup.get("storeCd").value)[0].storeName;
    if (storeName == undefined || storeName == null)
      return;
    var request: OrderStopTermItemAddDto = {
      storeCdFv: this.formGroup.get("storeCd").value,
      storeNameFv: storeName,
      itemCdFv: this.formGroup.get("itemCd").value,
      itemNameFv: this.formGroup.get("itemName").value,

      dateTypeFn: parseInt(this.formGroup.get("dateType").value),
      dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
      dateEndFv: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US'),
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.httpBasic.addOrderStopTermItem(request).subscribe(
      result => this.checkResult(result),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  doSearch() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.initOrderStopTermItemStore(this.formGroup.get("storeCd").value);
  }

  doCancel() {
    this.formGroup.controls["itemCd"].setValue(null);
    this.formGroup.controls["itemName"].setValue(null);
    this.formGroup.controls["dateBegin"].setValue(null);
    this.formGroup.controls["dateEnd"].setValue(null);
    this.formGroup.controls["dateType"].setValue("1");
    if (this.itemListSimpleComponent.itemSimpeList !== undefined) {
      this.sectionItems.openSectionBody();
    } else {
      this.sectionItems.closeSectionBody();
    }

  }

  doDelete(orderStopTermItem: OrderStopTermItemRec) {
    /*
    let msg = "<span >以下の登録を削除しますか？";
    if (orderStopTermItem.storeCdFv !== undefined && orderStopTermItem.storeCdFv !== "")
      msg += "<br><br>店舗: " + orderStopTermItem.storeCd;
    msg += "<br>商品: " + orderStopTermItem.itemCd + ":" + orderStopTermItem.itemName;
    msg += "<br>日付種別: " + orderStopTermItem.dateType;
    msg += "<br>開始日: " + orderStopTermItem.dateBeginFv;
    msg += "<br>終了日: " + orderStopTermItem.dateEndFv;
    msg += "</span>";
    */
    let msg = "以下の登録を削除しますか？<br>";
    msg += "<br><table class='no-border'>";
    if (orderStopTermItem.storeCdFv != undefined && orderStopTermItem.storeCdFv !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>店舗：　</td>" + "<td class='no-border'>" + orderStopTermItem.storeCd + "</td>";
      msg += "</tr>";
    }
    if (orderStopTermItem.itemCd != undefined && orderStopTermItem.itemCd !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>商品：　</td>" + "<td class='no-border'>" + orderStopTermItem.itemCd + ":" + orderStopTermItem.itemName + "</td>";
      msg += "</tr>";
    }
    if (orderStopTermItem.dateType != undefined && orderStopTermItem.dateType !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>日付種別:　</td>" + "<td class='no-border'>" + orderStopTermItem.dateType + "</td>";
      msg += "</tr>";
    }
    if (orderStopTermItem.dateBeginFv != null) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>開始日：　</td>" + "<td class='no-border'>" + orderStopTermItem.dateBeginFv + "</td>";
      msg += "</tr>";
    }
    if (orderStopTermItem.dateBeginFv != null) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>終了日：　</td>" + "<td class='no-border'>" + orderStopTermItem.dateEndFv + "</td>";
      msg += "</tr>";
    }
    msg += "</table>";


    this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      data => {
        this.subscriptionConfirm = undefined;
        if (data == true) {
          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          let request: OrderStopTermItemDelDto = {
            searchStoreCdFv: this.formGroup.get("storeCd").value,
            storeCdFv: orderStopTermItem.storeCdFv,
            storeNameFv: orderStopTermItem.storeNameFv,
            itemCdFv: orderStopTermItem.itemCdFv,
            itemNameFv: orderStopTermItem.itemNameFv,

            dateTypeFn: orderStopTermItem.dateTypeFn,
            dateBeginFv: orderStopTermItem.dateBeginFv,
            dateEndFv: orderStopTermItem.dateEndFv,
          };
          this.httpBasic.deleteOrderStopTermItem(request)
            .subscribe(
              result => this.checkResult(result),
              error => {
                this.clearProgressState();
                this.httpBasic.handleError(error);
              }
            )
        }
      }
    );
    return;
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 削除ボタン
    for (var colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }
  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  setDateType(type: string) {
    if (type == "発注日") {
      this.formGroup.get('dateType').setValue("1");
    } else {
      this.formGroup.get('dateType').setValue("2");
    }
  }

  onClickCell(columnId: string, item: Object) {

  }

  doQueryItemSimple() {
    this.copyRequest();
    this.itemListSimpleComponent.doQuery();
    this.selectedItem = undefined;
    this.sectionItems.openSectionBody();
  }

  itemChange(item: ItemSimpleRec) {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.setStores(item);
    this.selectedItem = item;
    this.sectionSelectCond.closeSectionBody();
    this.sectionItems.closeSectionBody();
    this.sectionCond.openSectionBody();
    this.formGroup.controls["itemCd"].setValue(this.selectedItem.itemCdFv);
    this.formGroup.controls["itemName"].setValue(this.selectedItem.itemNameFv);
    this.initOrderStopTermItemStore(item.storeCdFv);
  }

  setStores(item: ItemSimpleRec) {
    this.stores = [];
    var request: ReqStoreInit = {
      itemCdFv: item.itemCdFv,
      access: { ...this.commonService.loginUser }
    }
    this.httpBasic.storeSearchItem(request).subscribe(
      result => {
        this.optionStoreByItem(result);
        this.formGroup.get("storeCd").setValue(item.storeCdFv);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  optionStoreByItem(response: RspStoreInit) {
    if (this.commonService.stores.length > 1) {
      this.stores = [{ storeCd: "*", storeName: "全店" }];
    }
    for (var store of response.result) {
      this.stores.push({ storeCd: store.storeCdFv, storeName: store.storeNameFv })
    }
  }

  isValidSubmit() {
    if (this.formGroup.get("itemCd").value === null) return false;
    if (this.formGroup.get("dateBegin").value === null) return false;
    if (this.formGroup.get("dateEnd").value === null) return false;
    return true;
  }

  isHiddenDeleteButton(storeCdFv: string) {
    if (this.commonService.stores.length > 1) return false;
    return (storeCdFv === "*") ? true : false;
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  getDateBeginMin() {
    if (this.formGroup.get('dateEnd').value != null && this.formGroup.get('dateBegin').value > this.formGroup.get('dateEnd').value)
      this.formGroup.get('dateEnd').setValue(null);

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

  getDateEndMin() {
    if (this.formGroup.get('dateBegin').value != null) {
      return this.formGroup.get('dateBegin').value;
    }

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }
  selectTableItem() {

  }

  copyRequest() {
    this.itemSelectSimpleConditionOrigin.formGroup.patchValue(this.itemSelectSimpleCondition.formGroup.value);
    this.itemSelectSimpleConditionOrigin.ctgSelectCondition.formGroup.patchValue(this.itemSelectSimpleCondition.ctgSelectCondition.formGroup.value);
  }
}
