import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface NotificationData {
  title : string;
  message : string;
  btnText?: string;
};

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent implements OnInit {

  public btnText: string = "戻る";
  @Output() onClick = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotificationData) {}

  ngOnInit() {
    if (this.data.btnText && this.data.btnText !== "") {
      this.btnText = this.data.btnText;
    }
  }

  btnClick() {
    this.onClick.emit();
    this.dialogRef.close();
  }

}
