import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-number-keypad',
  templateUrl: './number-keypad.component.html',
  styleUrls: ['./number-keypad.component.css']
})
export class NumberKeypadComponent implements OnInit {

  public formKeyboardLock: FormControl = new FormControl();

  private columnMaxDefault: number = 10;
  private columnMax: number;
  private startInput: boolean = false;
  private stringMode: boolean = false;

  @Input("inputField") inputField: FormControl;
  @Input("allowFloat") allowFloat: boolean = false;

  @Output() valueChange = new EventEmitter<string | number>();

  constructor(
    public commonService: CommonService
  ) {
  }

  ngOnInit() {
    if (this.use10KeyPad() && this.commonService.isMobile()) {
      this.formKeyboardLock.setValue(true);
    } else {
      this.formKeyboardLock.setValue(false);
    }
    this.columnMax = this.columnMaxDefault;
  }

  setTargetForm(form: FormControl) {
    if (this.inputField == form) return;
    this.inputField = form;
    this.startInput = true;
    this.stringMode = false;
  }

  clearAtFirstInput(mode?: boolean) {
    this.startInput = true;
    if (mode == false) {
      this.startInput = false;
    }
  }

  setStringMode() {
    this.stringMode = true;
  }

  setMaxCol(max: number) {
    this.columnMax = max;
  }

  clearMaxCol() {
    this.columnMax = this.columnMaxDefault;
  }

  use10KeyPad() : boolean {
    return this.commonService.config.use10KeyPad;
  }

  onClick(val : string) {
    if (!this.inputField) return;

    let str: string;
    if (this.startInput) {
      str = "";
      this.startInput = false;
    } else {
      str = "" + this.inputField.value;
    }
    switch(val) {
      case "C":
        this.inputField.setValue("");
        break;
      case "Back":
        if (str.length > 0) {
          str = str.slice(0, str.length - 1);          
          this.inputField.setValue(str);
        }
        break;
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        if (this.stringMode) {
          // 桁数制限
          if (str.length < this.columnMax) this.setFieldValue(str + val);
        } else if (str === "0") {
          this.setFieldValue(val);
        } else {
          // 桁数制限
          if (str.length < this.columnMax) this.setFieldValue(str + val);
        }
        break;
      case "00":
        if (this.stringMode) {
          // 桁数制限
          if (str.length < this.columnMax - 1) this.setFieldValue(str + val);
        } else if (str == "") {
          this.setFieldValue("0");
        }
        else if (str != "0") {
          // 桁数制限
          if (str.length < this.columnMax - 1) this.setFieldValue(str + val);
        }
        break;
      case ".":
        if (!this.stringMode && this.allowFloat == false) break;
        if (!str.includes(".")) {
          if (str === "") str = "0";
          this.setFieldValue(str + val);
        }
        break;
      }
    this.inputField.markAsDirty();
    this.inputField.markAsTouched();
    this.valueChange.emit(this.inputField.value);
  }

  setFieldValue(val: string) {
    if (!this.stringMode && this.allowFloat == false) {
      this.inputField.setValue(parseInt(val));
    } else {
      this.inputField.setValue(val);
    }
  }

  onClickKeyboardLock() {
    this.formKeyboardLock.setValue(!this.formKeyboardLock.value);
  }

  isKeyboardLocked() {
    return this.formKeyboardLock.value;
  }
}
