import { Subscription } from "rxjs";
import { CommonService } from "src/app/service/common.service";
import { ExpiryMasterDateDef } from "./expiry-master-rec";
import { FormControl } from "@angular/forms";
import { ExpiryDateActionDto, ReqSaveExpiry, RspSaveExpiry, UpdateExpiryAlertDto, UpdateItemDateDto } from "src/app/webservice/item-expiry";
import { HttpBasicService } from "src/app/service/http-basic.service";

export interface ExpiryDateActionEdit extends ExpiryDateActionDto {
  isDirty:        boolean;
  isNew:          boolean;
  isDeleted:      boolean;
  formResult:     FormControl;
  formPrintCount: FormControl;
}

export interface ExpiryGroup {
  groupId: number;
  groupUsrCd: string;
  groupName: string;
  beginDate: string;
  endDate: string;
  sortKey: string;
  dateActions: ExpiryDateActionDto[];
  edit?: {
    isDirty:  boolean;
    dateActions: ExpiryDateActionEdit[];
  }
}

export interface AlertForAll {
  alertMsg:   string;
  alertRank:  number;
}

export class ExpiryItemRec {

  public storeCd: string                = "";
  public groupId: number                = 0;
  public groupUsrCd: string             = "";
  public groupName: string              = "";
  public beginDate: string              = "";
  public endDate: string                = "";
  public expiryDates: string[]          = [];
  public sortKey: string                ="";
  public edit: any                      = "";
  public itemCd: string                 = "";
  public itemName: string               = "";
  public itemStandard: string           = "";
  public price: number                  = 0;
  public tax: number                    = 8;
  public expiryType: number             = 1;
  public discountType: number           = 1;
  public dateDef: ExpiryMasterDateDef[] = [];
  public groupList: ExpiryGroup[]       = [];
  public targetGroup: ExpiryGroup;
  public dateActionAll: AlertForAll[];
  private today: Date;
  private targetDate: Date;
  private targetDateStr: string;
  public isDirty: boolean               = false;
  public initUpdateList: ExpiryDateActionDto[] = [];

  private subsc: Subscription[] = [];
  private changeListener: (mode: boolean)=>void;

  constructor(
    private commonService: CommonService,
    private httpBasic?: HttpBasicService) {
    let tmp = new Date();
    this.today = new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate());
    this.targetDate = this.commonService.copyDate(this.today);
    this.targetDateStr = this.commonService.formatDate(this.targetDate);
  }

  setTargetDate(target: Date) {
    if (!target) {
      this.targetDate = this.commonService.copyDate(this.today);
      this.targetDateStr = this.commonService.formatDate(this.targetDate);
    } else {
      this.targetDate = this.commonService.copyDate(target);
      this.targetDateStr = this.commonService.formatDate(this.targetDate);
    }
  }

  setListner(func:(mode: boolean)=>void) {
    this.changeListener = func;
  }

  setAction(initial?: boolean) {
    this.dateActionAll = [];
    if (!this.dateDef || this.dateDef.length === 0) {
      if (!this.dateActionAll.find((rec) => rec.alertRank === -4)) {
        this.dateActionAll.push({
          alertMsg:   "期限ルール未登録",
          alertRank:  -4
        });
      }
    }
    this.initUpdateList = [];
    for (let group = 0; group < this.groupList.length; group++) {
      if (!this.groupList[group].dateActions || this.groupList[group].dateActions.length === 0) {
        this.dateActionAll.push({
          alertMsg:   "未登録(" + this.groupList[group].groupUsrCd + ")",
          alertRank:  -3
        });
        continue;
      }
      for (let i = 0; i < this.groupList[group].dateActions.length; i++) {
        let dateAction = this.groupList[group].dateActions[i];
        /*
          Check all item dates for case that rule master defs have been changed.
        */
        let old = {...dateAction};
        this.checkItemDate(dateAction);
        if (old.alertRank !== dateAction.alertRank ||
            old.alertStartDate !== dateAction.alertStartDate ||
            old.alertMsg !== dateAction.alertMsg
        ) {
          if (dateAction.isCompleted && !old.isSoldOutDate) {
            dateAction.isCompleted = false;
          }
          this.initUpdateList.push(dateAction);
        }
        if (dateAction.alertRank > 0 || dateAction.alertRank < -1) {
          if (!this.dateActionAll.find((rec) => rec.alertRank === dateAction.alertRank)) {
            this.dateActionAll.push({
              alertMsg:   dateAction.alertMsg,
              alertRank:  dateAction.alertRank
            });
          }
        }
      }
    }
    if (initial && this.initUpdateList.length > 0) {
      this.initAlertUpdate();
    }
    this.dateActionAll.sort((a, b) => {
      if (a.alertRank < b.alertRank) return -1;
      if (a.alertRank === b.alertRank) return 0;
      return 1;
    });
  }

  checkItemDate(dateAction: ExpiryDateActionDto) {
    if (this.dateDef.length === 0) {
      dateAction.alertStartDate = this.commonService.formatDate(new Date());
      dateAction.alertRank = -4
      dateAction.alertMsg = "期限ルール未登録";
    } else if (this.expiryType === 1 && dateAction.itemDate < this.targetDateStr) {
      let tmp = this.commonService.getDate(dateAction.itemDate);
      tmp.setDate(tmp.getDate() + 1);
      dateAction.alertStartDate = this.commonService.formatDate(tmp);
      dateAction.alertRank = -2;
      dateAction.alertMsg = "期限越え";
    } else {
      let defDate = this.getDefDate(this.commonService.getDate(dateAction.itemDate));
      if (defDate) {
        dateAction.alertStartDate = this.commonService.formatDate(defDate);
        dateAction.alertRank = this.findDefDateIndex(this.commonService.getDate(dateAction.itemDate)) + 1;
        dateAction.alertMsg = this.getDefActionStr(this.commonService.getDate(dateAction.itemDate));
      } else {
        dateAction.alertStartDate = "";
        dateAction.alertRank = 0;
        dateAction.alertMsg = "対応不要";
      }
    }
  }

  setActionAll() {
    this.setAction();
  }

  prepareEdit() {
    this.groupList.forEach((group) => {
      if (!group.edit) {
        group.edit = {
          isDirty: false,
          dateActions: []
        };
        group.dateActions.forEach((rec) => {
          if (rec.isSoldOutItem) {
            var result = 3;
          } else if (rec.isSoldOutDate) {
            result = 2;
          } else if (rec.isCompleted) {
            result = 1;
          } else {
            result = 0;
          }
          let dateAction = {
            ...rec,
            isDirty: false,
            isNew: false,
            isDeleted: false,
            formResult: new FormControl("" + result),
            formPrintCount: new FormControl(1)
          };
          group.edit.dateActions.push(dateAction);
          this.subsc.push(dateAction.formResult.valueChanges.subscribe((val) => {
            this.setDirty(dateAction);
          }));
        });
      }
    });
  }

  setResult(dateAction: ExpiryDateActionEdit, val: number) {
    if (dateAction.formResult.disabled) return;
    dateAction.formResult.setValue("" + val);
  }

  endEdit() {
    this.cleanupEdit();
  }

  cancelEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  saveEdit() {
    let request: ReqSaveExpiry = {
      access: this.commonService.loginUser,
      dates: this.getUpdateDateList(),
      alerts: this.getUpdateAlertList(),
      expiryGroupLabel: this.commonService.literal["itemExpiryGroup"]
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("SaveExpiry", request).subscribe(
      (response: RspSaveExpiry) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveSaveEdit(response);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSaveEdit(response: RspSaveExpiry) {
    if (this.httpBasic.handleAppError(response)) return;

    this.commitEdit();
  }

  commitEdit() {
    for (let i = this.targetGroup.edit.dateActions.length - 1; i >= 0 ; i--) {
      let dateAction = this.targetGroup.edit.dateActions[i];
      if (!dateAction.isDirty) continue;
      if (dateAction.isDeleted) {
        if (!dateAction.isNew) {
          let index = this.targetGroup.dateActions.findIndex((rec) => rec.itemDate === dateAction.itemDate);
          if (index >= 0) this.targetGroup.dateActions.splice(index, 1);
        }
        this.targetGroup.edit.dateActions.splice(i, 1);
        continue;
      } else if (dateAction.isNew) {
        dateAction.isDirty = false;
        dateAction.isNew = false;
        this.targetGroup.dateActions.push({
          storeCd: dateAction.storeCd,
          itemCd: dateAction.itemCd,
          groupId: dateAction.groupId,
          groupUsrCd: dateAction.groupUsrCd,
          itemDate: dateAction.itemDate,
          alertType: dateAction.alertType,
          alertRank: dateAction.alertRank,
          alertStartDate: dateAction.alertStartDate,
          alertMsg: dateAction.alertMsg,
          isCompleted: dateAction.isCompleted,
          isSoldOutDate: dateAction.isSoldOutDate,
          isSoldOutItem: dateAction.isSoldOutItem
        });
      } else {
        dateAction.isDirty = false;
        let isCompleted = false;
        let isSoldOutDate = false;
        let isSoldOutItem = false;
        let val = dateAction.formResult.value;
        switch(val) {
          case "0": {
            break;
          }
          case "1": {
            isCompleted = true;
            break;
          }
          case "2": {
            isCompleted = true;
            isSoldOutDate = true;
            break;
          }
          case "3": {
            isCompleted = true;
            isSoldOutDate = true;
            isSoldOutItem = true;
            break;
          }
        }
      }
    }
    this.targetGroup.edit.isDirty = false;
    this.isDirty = false;
    this.targetGroup.dateActions.sort((a, b) => {
      if (a.itemDate < b.itemDate) return -1;
      if (a.itemDate > b.itemDate) return 1;
      return 0;
    });
    this.setActionAll();
    if (this.changeListener) this.changeListener(false);
  }

  cleanupEdit() {
    this.subsc.forEach((subsc) => {subsc.unsubscribe();});
    this.subsc = [];

    this.groupList.forEach((group) => {
      if (group.edit) delete group.edit;
      // group.dateActions.forEach((action) => action.isDeleted = false);
    });
    this.isDirty = false;
    if (this.changeListener) this.changeListener(false);
  }

  findDefDate(expiryDate: Date): ExpiryMasterDateDef {
    if (this.dateDef.length == 0) return undefined;
    let def: ExpiryMasterDateDef = undefined;
    for (let i = 0; i < this.dateDef.length; i++) {
      def = this.dateDef[i];
      let d = this.commonService.copyDate(expiryDate);
      if (this.expiryType === 1) {
        d.setDate(d.getDate() - def.datesToExpiry);
      } else {
        d.setDate(d.getDate() + def.datesToExpiry);
      }
      if (this.targetDate >= d) return def;
    }
    return undefined;
  }

  findDefDateIndex(expiryDate: Date): number {
    if (this.dateDef.length == 0) return -1;
    if (!expiryDate) return -1;
    let def: ExpiryMasterDateDef = undefined;
    for (let i = 0; i < this.dateDef.length; i++) {
      def = this.dateDef[i];
      let d = this.commonService.copyDate(expiryDate);
      if (this.expiryType === 1) {
        d.setDate(d.getDate() - def.datesToExpiry);
      } else {
        d.setDate(d.getDate() + def.datesToExpiry);
      }
      if (this.targetDate >= d) {
        return i;
      }
    }
    return -1;
  }

  getDefDate(expiryDate: Date): Date {
    if (!expiryDate) return null;
    let def = this.findDefDate(expiryDate);
    if (!def) return null;
    let d = this.commonService.copyDate(expiryDate);
    if (this.expiryType === 1) {
      d.setDate(d.getDate() - def.datesToExpiry);
    } else {
      d.setDate(d.getDate() + def.datesToExpiry);
    }
    return d;
  }

  getColorDateAction(rec: AlertForAll) {
    let base = {"font-weight": "bold", "font-size" : "1.2rem", "padding": "3px"};
    let white = {"color": "black", "background-color": "white", ...base};
    let red = {"color": "red", "background-color": "#FBE5D6", "border" : "solid 2px red", ...base};

    if (this.dateDef.length == 0) return red;
    let index = rec.alertRank;
    if (index < -1) return red;
    if (index <= 0) return white;
    index--;
    if (index < this.commonService.config.itemExpiry.colors.length) {
      return {
        "color": this.commonService.config.itemExpiry.colors[index].fg,
        "background-color": this.commonService.config.itemExpiry.colors[index].bg,
        "border" : "solid 2px " + this.commonService.config.itemExpiry.colors[index].fg,
        ...base
      };
    }
    return {};
  }

  getDefActionStr(expiryDate: Date): string {
    if (!expiryDate) return "";
    let def = this.findDefDate(expiryDate);
    if (!def) return "";
    return def.action;
  }

  hasExpiryDate() {
    for (let i = 0; i < this.groupList.length; i++) {
      let group = this.groupList[i];
      if (!group.dateActions) continue;
      if (group.dateActions.length === 0) continue;
      for (let j = 0; j < group.dateActions.length; j++) {
        if (group.dateActions[j].itemDate !== "") return true;
      }
    }
    return false;
  }

  setSoldOut() {
    this.targetGroup.edit.dateActions.forEach((rec) => {
      rec.formResult.setValue("2");
    });
  }

  /*
  getDiscountRate(dateAction: DateAction) {
    if (dateAction.dateDefIndex < 0 || dateAction.dateDefIndex >= this.dateDef.length) return 0;
    return this.dateDef[dateAction.dateDefIndex].discount;
  }
  */

  addDate(itemDate: string) {
    let dateAction: ExpiryDateActionEdit = this.targetGroup.edit.dateActions.find((rec) => rec.itemDate === itemDate);
    if (dateAction) {
      if (dateAction.isDeleted) {
        dateAction.isDeleted = false;
        dateAction.formResult.setValue("0");
        this.setDirty(dateAction);
        return true;
      }
     return false;
    }

    dateAction = {
      storeCd: this.storeCd,
      itemCd: this.itemCd,
      groupId: this.targetGroup.groupId,
      groupUsrCd: this.targetGroup.groupUsrCd,
      itemDate: itemDate,
      alertType: 1,
      alertRank: -1,
      alertStartDate: "",
      alertMsg: "",
      isCompleted: false,
      isSoldOutDate: false,
      isSoldOutItem: false,

      isDirty: true,
      isNew: true,
      isDeleted: false,
      formResult: new FormControl("0"),
      formPrintCount: new FormControl(1)
    }
    this.subsc.push(dateAction.formResult.valueChanges.subscribe((val) => {
      this.setDirty(dateAction);
    }));
    this.checkItemDate(dateAction);
    this.targetGroup.edit.dateActions.push(dateAction);
    this.targetGroup.edit.dateActions.sort((a, b)=> {
      if (a.itemDate < b.itemDate) return -1;
      if (a.itemDate > b.itemDate) return 1;
      return 0;
    });
    this.setDirty(dateAction);
    return true;
  }

  resetDates() {
    this.targetGroup.edit.dateActions.forEach((rec) => {
      rec.isDeleted = true;
      this.setDirty(rec);
    });
  }

  setDirty(dateAction: ExpiryDateActionEdit) {
    dateAction.isDirty = true;
    this.targetGroup.edit.isDirty = true;
    this.isDirty = true;
    if (this.changeListener) this.changeListener(true);
  }

  getUpdateDateList(): UpdateItemDateDto[] {
    let list: UpdateItemDateDto[] = [];

    for (let i = 0; i < this.targetGroup.edit.dateActions.length; i++) {
      let dateAction = this.targetGroup.edit.dateActions[i];

      if (!dateAction.isDirty) continue;
      let mode = "update";
      if (dateAction.isDeleted) {
        if (dateAction.isNew) continue;
        mode = "del";
      } else if (!dateAction.isNew) {
        continue;
      }
      list.push({
        mode: mode,
        storeCd: this.storeCd,
        itemCd: this.itemCd,
        groupId: this.targetGroup.groupId,
        groupUsrCd: this.targetGroup.groupUsrCd,
        itemDate: dateAction.itemDate
      });
    }

    return list;
  }

  getUpdateAlertList(): UpdateExpiryAlertDto[] {
    let list: UpdateExpiryAlertDto[] = [];

    for (let i = 0; i < this.targetGroup.edit.dateActions.length; i++) {
      let dateAction = this.targetGroup.edit.dateActions[i];

      if (!dateAction.isDirty) continue;
      let mode = "update";
      if (dateAction.isDeleted) {
        if (dateAction.isNew) continue;
        mode = "del";
      }
      let isCompleted = false;
      let isSoldOutDate = false;
      let isSoldOutItem = false;
      let val = dateAction.formResult.value;
      switch(val) {
        case "0": {
          break;
        }
        case "1": {
          isCompleted = true;
          break;
        }
        case "2": {
          isCompleted = true;
          isSoldOutDate = true;
          break;
        }
        case "3": {
          isCompleted = true;
          isSoldOutDate = true;
          isSoldOutItem = true;
          break;
        }
      }
      list.push({
        mode: mode,
        storeCd:        this.storeCd,
        itemCd:         this.itemCd,
        itemName:       "",
        itemStandard:   "",
        itemSalesPrice: 0,
        itemTax:        0,
        groupId:        this.targetGroup.groupId,
        groupUsrCd:     this.targetGroup.groupUsrCd,
        groupName:      "",
        alertType:      1,
        itemDate:       dateAction.itemDate,
        alertRank:      dateAction.alertRank,
        alertStartDate: dateAction.alertStartDate,
        alertMsg:       dateAction.alertMsg,
        isCompleted:    isCompleted,
        isSoldOutDate:  isSoldOutDate,
        isSoldOutItem:  isSoldOutItem,
        expiryType:     this.expiryType,
        discountType:   this.discountType
      });
    }

    return list;
  }

  initAlertUpdate() {
    let list: UpdateExpiryAlertDto[] = [];

    for (let i = 0; i < this.initUpdateList.length; i++) {
      let dateAction = this.initUpdateList[i];
      list.push({
        mode:           "update",
        storeCd:        this.storeCd,
        itemCd:         this.itemCd,
        itemName:       "",
        itemStandard:   "",
        itemSalesPrice: 0,
        itemTax:        0,
        groupId:        this.targetGroup.groupId,
        groupUsrCd:     this.targetGroup.groupUsrCd,
        groupName:      "",
        alertType:      1,
        itemDate:       dateAction.itemDate,
        alertRank:      dateAction.alertRank,
        alertStartDate: dateAction.alertStartDate,
        alertMsg:       dateAction.alertMsg,
        isCompleted:    dateAction.isCompleted,
        isSoldOutDate:  dateAction.isSoldOutDate,
        isSoldOutItem:  dateAction.isSoldOutItem,
        expiryType:     this.expiryType,
        discountType:   this.discountType
      });
    }


    let request: ReqSaveExpiry = {
      access: this.commonService.loginUser,
      dates: [],
      alerts: list,
      expiryGroupLabel: this.commonService.literal["itemExpiryGroup"]
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("SaveExpiry", request).subscribe(
      (response: RspSaveExpiry) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        if (this.httpBasic.handleAppError(response)) return;
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }
}
