import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CtgSelectEobCondition } from '../ctg-eob-select/ctg-eob-select.component';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { ReqUrgentOrderListSearch, ReqUrgentOrderListUpdate, RspUrgentOrderListSearch, RspUrgentOrderListUpdate, UrgentOrderList, UrgentOrderListDto } from '../0_def/urgent-order-list-rec';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-urgent-order-list',
  templateUrl: './urgent-order-list.component.html',
  styleUrls: ['./urgent-order-list.component.css']
})
export class UrgentOrderListComponent implements OnInit {
  public recordCount: number = 0;
  public urgentOrderList: UrgentOrderList[];
  public urgentOrderListDisplay = new MatTableDataSource<UrgentOrderListDto>([]);;
  public urgentOrderListOrigin: UrgentOrderList[];
  public formGroupCondition: FormGroup;
  public ctgSelectEobCondition: CtgSelectEobCondition = new CtgSelectEobCondition(this.fb);
  public selectedRow: UrgentOrderList;

  public columnIds: string[] = ["itemCd", "mkrFv", "itemName", "stdFv", "minZaiFn", "stkNumFn", "suppLotFn", "orderSuppLotFn", "orderBara", "rcvDateFv", "binCdFv", "costPriceFn", "sellPriceFn", "timeFv", "suppCd", "suppName"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "itemCd", header: "商品コード", width: 146, align: "center" },
    { columnId: "mkrFv", header: "メーカー", width: 105, align: "left" },
    { columnId: "itemName", header: "商品名", width: 344, align: "left" },
    { columnId: "stdFv", header: "規格", width: 140, align: "left" },
    { columnId: "minZaiFn", header: "最低陳列", width: 58, align: "right" },
    { columnId: "stkNumFn", header: "在庫数", width: 58, align: "right" },
    { columnId: "suppLotFn", header: "ロット数", width: 73, align: "right" },
    { columnId: "orderSuppLotFn", header: "推奨値(ロット数)", width: 102, align: "right" },
    { columnId: "orderBara", header: "推奨値(バラ数)", width: 102, align: "right" },
    { columnId: "rcvDateFv", header: "納品日", width: 102, align: "center" },
    { columnId: "binCdFv", header: "便No", width: 58, align: "right" },
    { columnId: "costPriceFn", header: "原価", width: 73, align: "right" },
    { columnId: "sellPriceFn", header: "売価", width: 73, align: "right" },
    { columnId: "timeFv", header: "発注〆時間", width: 73, align: "right" },
    { columnId: "suppCd", header: "仕入先コー", width: 73, align: "center" },
    { columnId: "suppName", header: "仕入先名", width: 265, align: "left" }
  ];

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();

  constructor(
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    public commonService: CommonService,
    private _liveAnnouncer: LiveAnnouncer
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.formGroupCondition = this.fb.group({
      itemCd: new FormControl(""),
      itemName: new FormControl(""),
      makerName: new FormControl(""),
    })
    this.ctgSelectEobCondition.storeCd = this.commonService.loginUser.storeCd;
    this.doSearchBody();
  }

  ngAfterViewInit() {
    this.empTbSort.disableClear = true;
    this.urgentOrderListDisplay.sort = this.empTbSort;
  }

  doSearch() {
    if (!this.isDisable()) {
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
        "変更内容を破棄しますか？").subscribe(
          (response: boolean) => {
            subsc.unsubscribe();
            if (response) {
              this.doSearchBody();
            }
          });
    } else this.doSearchBody();
  }

  doSearchBody() {
    let ctgSelectEobCondition: FormGroup = this.ctgSelectEobCondition.formGroup;
    let request: ReqUrgentOrderListSearch = {
      access: this.commonService.loginUser,
      storeCd: this.commonService.loginUser.storeCd,
      itemCd: this.formGroupCondition.get("itemCd").value ?? "",
      itemName: this.formGroupCondition.get("itemName").value,
      makerName: this.formGroupCondition.get("makerName").value,
      ctgCd0Fv: ctgSelectEobCondition.get('ctgLevel').value >= 0 ?
        ctgSelectEobCondition.get('ctgCd0').value : "",
      ctgCd1Fv: ctgSelectEobCondition.get('ctgLevel').value >= 1 ?
        ctgSelectEobCondition.get('ctgCd1').value : "",
      ctgCd2Fv: ctgSelectEobCondition.get('ctgLevel').value >= 2 ?
        ctgSelectEobCondition.get('ctgCd2').value : "",
      ctgCd3Fv: ctgSelectEobCondition.get('ctgLevel').value >= 3 ?
        ctgSelectEobCondition.get('ctgCd3').value : "",
    };
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetUrgentOrderList", request).subscribe(
      (response: RspUrgentOrderListSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveUrgentOrderList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUrgentOrderList(response: RspUrgentOrderListSearch) {
    if (this.httpBasic.handleAppError(response)) { return; }
    this.urgentOrderList = [];
    response.rows.forEach(row => {
      let dto: UrgentOrderListDto = {
        ...row
      }
      this.urgentOrderList.push(new UrgentOrderList(dto));
    });
    this.urgentOrderListOrigin = this.urgentOrderList?.length > 0 ? [...this.urgentOrderList].map(rec => new UrgentOrderList(rec?.originDto)) : [];
    this.recordCount = this.urgentOrderList?.length ?? 0;
    this.pageChanged();
  }

  doSave() {
    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
      "内容を保存します。よろしいですか？").subscribe(
        (response: boolean) => {
          subsc.unsubscribe();
          if (response) {
            this.doSaveBody();
          }
        });
  }

  doSaveBody() {
    let itemUpdate = [];
    this.urgentOrderList?.forEach(item => {
      if (item["orderSuppLotForm"].value != item.orderSuppLotFn && item.confirmFn === "0") {
        let dto = {
          userId: this.commonService.loginUser.userId,
          storeCd: this.commonService.loginUser.storeCd,
          itemCd: item.itemCd,
          rcvDateFv: item.rcvDateFv,
          orderSuppLotFn: item["orderSuppLotForm"].value
        }
        itemUpdate.push(dto);
      }
    });
    let ctgSelectEobCondition: FormGroup = this.ctgSelectEobCondition.formGroup;
    let request: ReqUrgentOrderListUpdate = {
      access: this.commonService.loginUser,
      itemUpdate: itemUpdate,
      itemCd: this.formGroupCondition.get("itemCd").value ?? "",
      itemName: this.formGroupCondition.get("itemName").value,
      makerName: this.formGroupCondition.get("makerName").value,
      ctgCd0Fv: ctgSelectEobCondition.get('ctgLevel').value >= 0 ?
        ctgSelectEobCondition.get('ctgCd0').value : "",
      ctgCd1Fv: ctgSelectEobCondition.get('ctgLevel').value >= 1 ?
        ctgSelectEobCondition.get('ctgCd1').value : "",
      ctgCd2Fv: ctgSelectEobCondition.get('ctgLevel').value >= 2 ?
        ctgSelectEobCondition.get('ctgCd2').value : "",
      ctgCd3Fv: ctgSelectEobCondition.get('ctgLevel').value >= 3 ?
        ctgSelectEobCondition.get('ctgCd3').value : "",
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("UrgentOrderListSave", request).subscribe(
      (response: RspUrgentOrderListUpdate) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveSave(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSave(response: RspUrgentOrderListUpdate) {
    if (this.httpBasic.handleAppError(response)) { return; }
    this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');
    this.urgentOrderList = [];
    response.rows.forEach(row => {
      let dto: UrgentOrderListDto = {
        ...row
      }
      this.urgentOrderList.push(new UrgentOrderList(dto));
    });
    this.urgentOrderListOrigin = this.urgentOrderList?.length > 0 ? [...this.urgentOrderList].map(rec => new UrgentOrderList(rec?.originDto)) : [];
    this.recordCount = this.urgentOrderList?.length ?? 0;
    this.pageChanged();
  }

  pageChanged() {
    let start;
    let end;
    this.urgentOrderListDisplay.data = [];
    this.recordCount = this.urgentOrderList?.length ?? 0;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.recordCount) end = this.recordCount;
    if (this.urgentOrderListDisplay?.data?.length == 1) return;
    else this.urgentOrderListDisplay.data = this.urgentOrderList?.slice(start, end);
  }

  onInputNum(item: UrgentOrderList, event) {
    const parsedValue = parseInt(event.target.value);
    if (item.confirmFn == "1") {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "発注確定済みですから、編集は許可されていません。");
      item.orderSuppLotForm.setValue(item.orderSuppLotFn);
      item.orderBara = parseInt(item.suppLotFn) * (isNaN(parseInt(item.orderSuppLotFn)) ? 0 : parseInt(item.orderSuppLotFn));
      return;
    } if (event.target.value == "") {
      item.orderBara = 0;
      return;
    } if (isNaN(parsedValue)) {
      item.orderSuppLotForm.setValue(item.orderSuppLotFn);
      item.orderBara = parseInt(item.suppLotFn) * (isNaN(parseInt(item.orderSuppLotFn)) ? 0 : parseInt(item.orderSuppLotFn));
      return;
    } if (parsedValue > 9999) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "発注数が多すぎます");
      item.orderSuppLotForm.setValue(item.orderSuppLotFn);
      item.orderBara = parseInt(item.suppLotFn) * (isNaN(parseInt(item.orderSuppLotFn)) ? 0 : parseInt(item.orderSuppLotFn));
      return;
    }

    item.orderSuppLotForm.setValue(event.target.value);
    item.orderBara = parsedValue * parseInt(item.suppLotFn);
  }

  isDisable(): boolean {
    let item = 0;
    this.urgentOrderList?.filter(x => {
      if (x["orderSuppLotForm"].value != x.orderSuppLotFn) item++;
    })
    if (item > 0) return false;
    return true;
  }

  doCancel() {
    this.urgentOrderList = [];
    this.urgentOrderList = this.urgentOrderListOrigin?.length > 0 ? [...this.urgentOrderListOrigin].map(rec => new UrgentOrderList(rec?.originDto)) : [];
    this.pageChanged();
  }

  checkStkNum(value): boolean {
    if (parseInt(value) < 0) return true;
    return false;
  }

  clickRow(row: UrgentOrderList) {
    this.selectedRow = row;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  styleForRow(colDef: TableColumnDef, item: UrgentOrderList) {
    let stkNumValue = isNaN(parseInt(item.stkNumFn)) ? 0 : parseInt(item.stkNumFn);
    let initStyle = {
      "width": "" + colDef.width + "px",
      "min-width": "" + colDef.width + "px",
      "max-width": "" + (colDef.maxWidth != undefined ? colDef.maxWidth : colDef.width) + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
    if (colDef.columnId === "stkNumFn" && stkNumValue < 0) {
      return {
        ...initStyle,
        "color": "#ff0000"
      }
    }
    else {
      return initStyle;
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    };
  }

  onEnter() {
    this.doSearch();
  }

  canDeactivate() {
    if (this.isDisable()) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
