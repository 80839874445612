import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CsvData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CsvTableComponent } from 'src/app/partsCommon/csv-table/csv-table.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

@Component({
  selector: 'app-csv-order',
  templateUrl: './csv-order.component.html',
  styleUrls: ['./csv-order.component.css']
})
export class CsvOrderComponent implements OnInit, OnDestroy {

  private isReadonly: boolean;
  public orderList: CsvData;
  public columnDefs: TableColumnDef[] = [
    { columnId: "storeCd", header: "店舗コード", width: 70, align: "center" },
    { columnId: "storeName", header: "店舗名", width: 150, csvComment: "[空欄可]" },
    { columnId: "itemCd", header: "商品コード", width: 100, align: "center" },
    { columnId: "itemName", header: "商品名", width: 300, align: "left", csvComment: "[空欄可]" },
    { columnId: "orderDate", header: "発注日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式 本日以降]"},
    { columnId: "shipSchDate", header: "納品日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式 本日以降/空欄可]"},
    { columnId: "orderNum", header: "発注数", width: 60, align: "right", csvComment: "[西暦 yyyy/mm/dd 形式]"}
  ];
  public columnDefsNoStore: TableColumnDef[] = [
    { columnId: "itemCd", header: "商品コード", width: 100, align: "center" },
    { columnId: "itemName", header: "商品名", width: 300, align: "left", csvComment: "[空欄可]" },
    { columnId: "orderDate", header: "発注日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式] 本日以降"},
    { columnId: "shipSchDate", header: "納品日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式 本日以降/空欄可]"},
    { columnId: "orderNum", header: "発注数", width: 60, align: "right", csvComment: "[西暦 yyyy/mm/dd 形式]"}
  ];
  public displayColumnDefs: TableColumnDef[] = [];

  @ViewChild(CsvTableComponent, { static: false }) csvTableComponent: CsvTableComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("csvNoisecutProm");

    if (this.commonService.stores.length > 1) {
      this.displayColumnDefs = [...this.columnDefs];
    } else {
      this.displayColumnDefs = [...this.columnDefsNoStore];
    }
  }

  ngOnDestroy(): void {

  }

  clearProgressState() {
    this.commonService.closeSpinner();
  }

  getCsvData(csvData: CsvData) {
    this.orderList = {...csvData};
  }

  isDisableUpdateBtn() {
    if (this.orderList == undefined) return true;
    if (this.orderList.errorCount == undefined) return true;
    if (this.orderList.errorCount > 0) return true;
    return false;
  }

  doConfirm() {

    // For Moc
    this.orderList.lines[0].warningMsg = ["[発注日]:発注出来ない日付です。"];
    this.csvTableComponent.handleError();
  }

  doUpdate() {
    if (this.orderList.warningCount > 0) {
      let msg = "警告があります。登録を継続しますか？";
      this.commonService.openYesNoDialog(this.commonService.pageTitle, msg);
      return;
    }







  }
}
