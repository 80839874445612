import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';

export interface NotificationData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-spiv00101-notification-dialog',
  templateUrl: './spiv00101-notification-dialog.component.html',
  styleUrls: ['./spiv00101-notification-dialog.component.css']
})
export class Spiv00101NotificationDialogComponent implements OnInit {
  public btnCloseText: string = "閉じる";
  public btnInputText: string = "棚卸入力";

  @Output() navigateDetail = new EventEmitter<void>();
  @Output() navigateInput = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<Spiv00101NotificationDialogComponent>,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: NotificationData) { }

  ngOnInit() {}

  goInventoryDetail() {
    this.navigateDetail.emit();
    this.dialogRef.close();
  }

  goInventoryInput() {
    this.navigateInput.emit();
    this.dialogRef.close();
  }
}
