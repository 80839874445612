import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CsvData, CsvLineData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CsvTableStoreComponent } from 'src/app/partsCommon/csv-table-store/csv-table-store.component';
import { ReqCsvParamItemStoreCheck } from 'src/app/request/req-csv-param-item-store-check';
import { ReqCsvParamItemStoreCsvUpdate } from 'src/app/request/req-csv-param-item-store-update';
import { RspCsvItemParamStoreUpdate } from 'src/app/response/rsp-csv-item-param-store-update';
import { RspParamItemStoreCsvCheck } from 'src/app/response/rsp-csv-param-item-store-check';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

export interface CsvParamStoreItemPrioritize extends CsvLineData {
  isPrioritize: boolean;
}

@Component({
  selector: 'app-csv-param-store-item',
  templateUrl: './csv-param-store-item.component.html',
  styleUrls: ['./csv-param-store-item.component.css']
})
export class CsvParamStoreItemComponent implements OnInit, OnDestroy {

  // private subscription$ = new Subject();
  public paramList: CsvData;
  public tableWidth: any;
  public displayColumnIds: string[];
  public columnDefs: TableColumnDef[] = [
    { columnId: "itemCd", header: "商品コード", width: 100, align: "center" },
    { columnId: "itemName", header: "商品名", width: 300, align: "left", csvComment: "[空欄可]" },
    { columnId: "autoOrder", header: "自動発注", width: 70, align: "center", csvComment: "[対象、対象外を指定]" },
    { columnId: "minZai", header: "最低陳列", width: 70, align: "right", csvComment: "[設定削除は0を指定]" },
    { columnId: "maxZai", header: "最大陳列", width: 70, align: "right", csvComment: "[設定削除は0を指定]" },
    { columnId: "minStockDays", header: "最低在庫日数", width: 95, align: "right", csvComment: "[設定削除は0を指定]" },
    { columnId: "maxStockDays", header: "最大在庫日数", width: 95, align: "right", csvComment: "[設定削除は0を指定]" },
    { columnId: "autoOrderLot", header: "自発用ロット", width: 95, align: "right", csvComment: "[設定削除は0を指定、ロットの整数倍を指定]" },
  ];
  public displayColumnDefs: TableColumnDef[] = [];
  public layoutComment: string = "空欄の設定項目は更新されません。";
  public selectedStore = [];
  public selectedStoreCount: number = 0;
  public csvTableHeight: number = 400;
  recheck: boolean = false;
  @ViewChild(CsvTableStoreComponent, { static: false }) csvTableComponent: CsvTableStoreComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    if (this.commonService.stores.length <= 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この機能を使用する権限がありません。");
      this.commonService.router.navigate(['top']);
      return;
    }

    this.displayColumnDefs = [...this.columnDefs];
  }

  ngOnDestroy(): void {
    /*
    this.subscription$.next();
    this.subscription$.complete();
    */
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.resized();
  }

  resized() {
    let id = "csv-table-container";
    let height = this.commonService.getHeightBelow(id);
    if (height) {
      this.csvTableHeight = height - 24 - 15;
    } else {
      this.csvTableHeight = 400;
    }
    if (this.csvTableComponent) {
      this.csvTableComponent.setMaxHeight(this.csvTableHeight);
    } else {
      setTimeout(() => {
        if (this.csvTableComponent) {
          this.csvTableComponent.setMaxHeight(this.csvTableHeight);
        }
      }, 0);
    }
  }
  
  getCsvData(csvData: CsvData) {

    let listPrioritize: CsvParamStoreItemPrioritize[] = [];
    let items = {};
    this.selectedStore = [];

    if (csvData.lines.length > 0) {
      for (let i = 0; i < csvData.lines.length; i++) {
        listPrioritize.push(
          {
            ...csvData.lines[i],
            isPrioritize: true
          });

        let itemCd = csvData.lines[i].columnData['itemCd'].trim();
        let key: string = "" + itemCd;
        if (items[key] != undefined) {
          listPrioritize[items[key]].isPrioritize = false;
        }
        items[key] = i;
      }
    }
    const csvDataPrioritize: CsvLineData[] = [];
    listPrioritize.filter(item => item.isPrioritize).map((value: CsvParamStoreItemPrioritize) => {
      const item: CsvLineData = {
        lineNumber: value.lineNumber,
        columnData: value.columnData,
        errorMsg: value.errorMsg,
        warningMsg: value.warningMsg,
      }
      csvDataPrioritize.push(item);
    });
    csvData.lines = csvDataPrioritize;
    this.paramList = csvData;
    if (this.csvTableComponent) {
      this.csvTableComponent.init(this.paramList);
      this.csvTableComponent.storeUnselectAll();
    }

    setTimeout(()=>this.resized(), 0);
  }

  isDisableUpdateBtn() {
    if(this.selectedStoreCount <= 0) return true;
    if (this.paramList == undefined) return true;
    if (this.paramList.errorCount == undefined) return true;
    if (this.paramList.errorCount > 0) return true;
    // if (this.selectedStore.length <= 0) return true;
    return false;
  }

  doConfirm() {
    const request: ReqCsvParamItemStoreCheck = {
      access: { ...this.commonService.loginUser },
      itemCsvCheck: this.getDataOnTable(),
      paramItemMinZaiRange: this.commonService.config.paramItemMinZaiRange,
      paramItemMaxZaiRange: this.commonService.config.paramItemMaxZaiRange,
      paramItemMinStockDaysRange: this.commonService.config.paramItemMinStockDaysRange,
      paramItemMaxStockDaysRange: this.commonService.config.paramItemMaxStockDaysRange,
      paramItemAutoOrderLotRange: this.commonService.config.paramItemAutoOrderLotRange,
      stores:  this.csvTableComponent?.getSelectedStore()?.map(store =>  {
        const item = {
          storeCd: store.storeCdFv,
          storeName: store.storeNameFv
        } 
        return item;
      })
    };
    this.selectedStore = this.csvTableComponent?.getSelectedStore();
 
    if (request.itemCsvCheck.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "データがありません。");
      return;
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "確認中です・・・");
    this.httpBasic.checkCsvParamItemStore(request).subscribe(
      (response: RspParamItemStoreCsvCheck) => {
        this.receiveResult(response);
        this.recheck = false;
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )
    
  }

  getDataOnTable() {
    const request = [];
    this.paramList.lines.map(value => {
      const item = {
        itemCd: value.columnData['itemCd'],
        itemName: value.columnData['itemName'],
        autoOrder: value.columnData['autoOrder'],
        minZai: value.columnData['minZai'],
        maxZai: value.columnData['maxZai'],
        minStockDays: value.columnData['minStockDays'],
        maxStockDays: value.columnData['maxStockDays'],
        autoOrderLot: value.columnData['autoOrderLot'],
      }
      request.push(item);
    });
    return request;
  }
  
  receiveResult(response: RspParamItemStoreCsvCheck) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return; 
    this.paramList.lines.map(line => {
      line.errorMsg = [],
      line.warningMsg = []
    });
    if (response.result.length > 0) {
      response.result.map((item) => {
        if (item.errorMsgs) {
          this.paramList.lines[item.line].errorMsg = item.errorMsgs;
        }
     
        if (item.warningMsgs?.length > 0) {
          this.paramList.lines[item.line].warningMsg = item.warningMsgs;
        }
 
      });
    }
    this.csvTableComponent.handleError();
  }
  
  doUpdate() {
    if(this.isDisableUpdateBtn()) return;
    const request: ReqCsvParamItemStoreCsvUpdate = {
      access: { ...this.commonService.loginUser },
      itemCsvUpdate: this.getDataOnTable(),
      stores: this.csvTableComponent?.getSelectedStore()?.map(store =>  {
        const item = {
          storeCd: store.storeCdFv,
          storeName: store.storeNameFv
        } 
        return item;
      })
    }

    if (request.itemCsvUpdate.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "テストするデータがありません");
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.httpBasic.updateCsvParamItemStore(request).subscribe(
      (response: RspCsvItemParamStoreUpdate) => {
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) return;
        this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )
    
  }

  storeChange() {
    this.getSelectedStoreCount();
  }

  getSelectedStoreCount() {
    if (!this.csvTableComponent) return this.selectedStoreCount = 0;
    this.selectedStoreCount = this.csvTableComponent.checkedStoreCd.size;
    
    const myArr = Array.from(this.csvTableComponent.checkedStoreCd) 
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];
      if(this.selectedStore.find(store => store.storeCdFv == element) == undefined) {
        return this.recheck = true;
      } else {
        this.recheck = false;
      }     
    }
    
  }
}
