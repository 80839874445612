import { Component, OnInit, OnDestroy, ViewChild, EventEmitter, ChangeDetectorRef, AfterViewInit, AfterViewChecked, HostListener } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Subscription, Observable } from 'rxjs';
import { NoisecutSearchConditionDto, ReqNoisecutSearch } from 'src/app/request/req-noisecut-search';
import { DateAdapter } from '@angular/material/core';
import { ReqPageDto } from 'src/app/request/req-page-dto';
import { NoisecutDto, RspNoisecutSearch } from 'src/app/response/rsp-noisecut-search';
import { MatTabGroup } from '@angular/material/tabs';
import { ReqItemSimpleSearch, ReqItemSimpleSearchConditionDto } from 'src/app/request/req-item-simple-seach';
import { RspItemSimpleSearch } from 'src/app/response/rsp-item-simple-seach';
import { MatTable } from '@angular/material/table';
import { ReqNoisecutUpdate } from 'src/app/request/req-noisecut-update';
import { RspNoisecutUpdate } from 'src/app/response/rsp-noisecut-update';
import { ItemNoisecutDialogComponent } from 'src/app/dialog/item-noisecut-dialog/item-noisecut-dialog.component';

@Component({
  selector: 'app-noisecut-search',
  templateUrl: './noisecut-search.component.html',
  styleUrls: ['./noisecut-search.component.css']
})
export class NoisecutSearchComponent implements OnInit, OnDestroy {

  public isReadonly: boolean;
  private storeCd: string;
  public dateBegin: Date;
  public dateEnd: Date;

  public formGroup: FormGroup;
  private subscStore: Subscription;
  public noisecutList;
  public selectedNoisecut;

  constructor(public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.dateBegin = this.commonService.getDate(this.commonService.salesDate);
    this.dateBegin.setDate(this.dateBegin.getDate() + 1);
    this.dateEnd = this.commonService.copyDate(this.dateBegin);
    this.dateEnd.setDate(this.dateBegin.getDate() + 6);

    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.storeCd, Validators.required],
      noisecutType: [""],
      itemCd: [""],
      itemName: [""]
    });
    this.storeCd = this.commonService.loginUser.storeCd;

    this.subscStore = this.formGroup.get("storeCd").valueChanges.subscribe(
      (storeCd) => this.storeChanged(storeCd)
    );


  }

  ngOnDestroy(): void {
    if (this.subscStore) this.subscStore.unsubscribe();
  }

  resized() {

  }

  storeChanged(storeCd: string) {
    if (this.storeCd == storeCd) return;



    
  }

  clickNoisecut() {

  }

  editLine() {

  }

  editGroup() {

  }

  deleteLine() {

  }


}
