import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { TableColumnDef } from "src/app/common/table-column-def";
import { CommonService } from 'src/app/service/common.service';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { NavigationExtras, Router } from '@angular/router';
import { MessageService } from 'src/app/service/message.service';
import { Observable, Subscription, of } from 'rxjs';
import { formatDate } from '@angular/common';
import { ReqSpbs00041Search} from 'src/app/request/req-spbs00041-search';
import { RspSpbs00041Search, RspSpbs00041SearchDto} from 'src/app/response/rsp-spbs00041-search';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'app-spbs00041',
    templateUrl: './spbs00041.component.html',
    styleUrls: ['./spbs00041.component.css']
})
export class Spbs00041Component implements OnInit, OnDestroy {

    displayedColumns: string[] = ['column1', 'column2'];
    @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
    @ViewChild("sectionSuppHeader", { static: true }) sectionSupp: SectionHeaderComponent;
    @ViewChild("sectionSearchResult", { static: true }) sectionSearchResult: SectionHeaderComponent;
    public formGroup: FormGroup;
    private subscriptionInit: Subscription;
    private subscriptionFormGroup: Subscription;
    public tableWidth: any;
    public columnIds: any = ['COLUMNONE', 'COLUMNTWO', 'COLUMNTHREE', 'COLUMNFOUR', 'COLUMNFIVE'];
    public dateBegin: Date;
    public dateEnd: Date;
    public paginatorMaxWidth: any;
    public searchExpandBtn: boolean;
    public recordCount: number = 0;
    public displayColumnIds: string[];
    public notiCategortyList :  [] = [];
    public spbs00041SearchList :  RspSpbs00041SearchDto[] = [];
    spbs00041SearchListDisplay = new MatTableDataSource<RspSpbs00041SearchDto>([]);
    public KBNNM: string;
    public sanitizedNoti: SafeHtml;
    public displayColumnDefs: TableColumnDef[];
    public columnDefs: TableColumnDef[] = [
        { columnId: 'condition', header: "状態", width: 150, align: "center" },
        { columnId: 'orderDate', header: "発生日時", width: 200, align: "center" },
        { columnId: 'notiId', header: "通知ID", width: 150, align: "center" },
        { columnId: 'notiCategory', header: "通知区分", width: 150},
        { columnId: 'notification', header: "通知内容", width: 400},
      
      
      ];
      constructor(
        public commonService: CommonService,
        private fb: FormBuilder,
        private httpBasic: HttpBasicService,
        private router: Router,
        public messageEntity: MessageService,
        private sanitizer: DomSanitizer,
       
    
      ) {  }
      ngOnInit(): void {
        this.commonService.pageTitle = this.commonService.pageMenuName;
        this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
        this.setDisplayCol();
        this.initLists();
        this.initFormGroup();
        this.searchExpandBtn = true;
    }

    async initLists(): Promise<void> {
        await this.getInitData();
        this.commonService.closeSpinner();
      }

    initFormGroup() { 
        this.formGroup = this.fb.group({
            dateBegin: [new Date()],
            dateEnd: [new Date()],
            condition : [""],
            notiCategory : [""]
          });
    }

    getInitData() :Promise<void>{
        return new Promise((resolve, reject) => {
            var request = {
              access: { ...this.commonService.loginUser },
            };
            let sub =this.httpBasic.generalRequest("Spbs00041Init", request).subscribe(
              response => {
                sub.unsubscribe();
                this.receiveInitData(response);
                resolve();
              },
              error => {
                sub.unsubscribe();
                this.commonService.closeSpinner();
                this.httpBasic.handleError(error);
                reject(error);
              }
            );
          });
    }

    receiveInitData(response:any){
        if (this.httpBasic.handleAppError(response)) return;
        if (response.rows.length === 0) {
          this.notiCategortyList = [];
          return;
      }
  
      this.notiCategortyList = [];
      this.notiCategortyList = response.rows;
      
    }

    

    doSearch(){
        var request: ReqSpbs00041Search = {
            dateBegin: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
            dateEnd: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US'),
            condition : this.formGroup.get("condition").value,
            notiCategory : this.formGroup.get("notiCategory").value,
            access: { ...this.commonService.loginUser }
        }
          this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
          this.httpBasic.generalRequest("Spor00041Search", request).subscribe(
            result => this.receiveSearchData(result),
            error => {
              this.httpBasic.handleError(error);
            }
          );
    }

    receiveSearchData(response : RspSpbs00041Search){
        this.clearProgressState();
        if (response.fatalError && response.fatalError.length > 0) {
        this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
        return;
    }
        if (response.normalError && response.normalError.length > 0) {
        this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
        return;
    }
    if (response.rows.length === 0) {
        this.spbs00041SearchList = [];
        this.spbs00041SearchListDisplay.data = [];
        return;
    }

    this.spbs00041SearchList = [];
    for (var data of response.rows) {
      if(data.condition == '0'){
        data.condition = '未確認';
      }
      if(data.condition == '1'){
        data.condition = '確認済み';
      }
      data.sanitizedNotification = this.sanitizer.bypassSecurityTrustHtml(data.notification);
      this.spbs00041SearchList.push(data);
    }
    this.recordCount = this.spbs00041SearchList?.length ?? 0;
    this.pageChanged();
    }


    doClear(){

        this.initLists();
        this.initFormGroup();
        this.spbs00041SearchList = [];
        this.spbs00041SearchListDisplay.data = [];
   
    }

    setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = [];

    for (var colDef of this.columnDefs) {
      this.displayColumnDefs.push(colDef);
      this.displayColumnIds.push(colDef.columnId);
      this.calcTableWidth();
    }
      }
      
      calcTableWidth() {
        var width = 1;    
        width = width + 100 + 1 + 8;
        width = width + 100 + 1 + 8;            // For left border
        for (var colDef of this.displayColumnDefs) {
          width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
        }
        this.tableWidth = { "width": "" + width + "px" };
        this.paginatorMaxWidth = { "max-width": "" + width + "px" };
      }
    
    
      styleFor(id: string) {
        return this.styleForBody(this.columnDefs, id);
      }
    
    
      styleForBody(colDefs: TableColumnDef[], id: string) {
        for (var colDef of colDefs) {
          if (colDef.columnId === id) {
            return {
              "width": `${colDef.width}px`,
              "max-width": `${colDef.width}px`, 
              "text-align": colDef.align ? colDef.align : "left",
              "white-space": "normal",  
              "word-wrap": "break-word",  
              "overflow": "hidden" ,
              "padding": "10px",
              "border": "1px solid #ddd"
            };
          }
        }
      }




      
    
      styleForHeader(id: string) {
        return this.styleForHeaderBody(this.columnDefs, id);
      }
    
      styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
        for (var colDef of colDefs) {
          if (colDef.columnId === id) {
            return {
              "width": `${colDef.width}px`,
              "max-width": `${colDef.width}px`,  
              "white-space": "normal",  
              "word-wrap": "break-word",  
              "overflow": "hidden"  
            };
          }
        }
      }

      onLinkClick(event: MouseEvent) {

        event.preventDefault();
        const target = event.target as HTMLElement;
        const href = (target as HTMLAnchorElement).href; 
        const pageMenuName = (target as HTMLAnchorElement).innerText; 
        
        const url = new URL(href);
        const screenId = url.pathname.split('/').pop(); 
        this.commonService.pageMenuName = pageMenuName;
        this.router.navigate([`/${screenId}`]);
      }

      getMaxHeight(): string {
        if (this.searchExpandBtn) {
          return '310px';
        } else {
          return '425px';
        }
        return 
      }

      pageChanged() {
        let start;
        let end;
        this.spbs00041SearchListDisplay.data = [];
        this.recordCount = this.spbs00041SearchList?.length ?? 0;
        if (this.matPaginator) {
          start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
          end = start + this.matPaginator.pageSize;
    
          if (start >= this.recordCount) {
            this.matPaginator.pageIndex = Math.ceil(this.recordCount / this.matPaginator.pageSize) - 1;
            start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
            end = start + this.matPaginator.pageSize;
          }
        }
        if (end > this.recordCount) {
          end = this.recordCount;
        }
        this.spbs00041SearchListDisplay.data = this.spbs00041SearchList?.slice(start, end) ?? [];
      }
      
      lastPage() {
        this.matPaginator.pageIndex = Math.floor(this.recordCount / this.matPaginator.pageSize);
        this.pageChanged();
      }
    
    
    ngOnDestroy(): void {
        if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
        if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    }

    clearProgressState() {
        this.commonService.closeSpinner();
        if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
        this.subscriptionInit = undefined;
      }
   
}