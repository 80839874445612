import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { TaskSearchCond } from 'src/app/common/config';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSearchTaskResultRec, ReqTaskFile, RspSearchTaskResultRec, RspTaskFileInfo } from 'src/app/webservice/task';
import { SubTask, TaskTargetStore } from '../0_def/taskDefs';
import { TaskRec } from '../0_def/taskRec';
import { TaskStoreRec } from '../0_def/taskStoreRec';
import { TaskService } from '../1_service/taskService';
import { DbService, HtmlSelectItem } from 'src/app/service/db.servce';
import { ReqGetUsers } from 'src/app/request/req-get-users';
import { RspGetUsers } from 'src/app/response/rsp-get-users';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { StoreEdit2Component } from '../6_store-edit2/store-edit2.component';
import { ViewOtherStoreComponent } from '../7_view-other-store/view-other-store.component';
import { ViewOtherStoreCommentComponent } from '../7_view-other-store comment/view-other-store-comment.component';

@Component({
  selector: 'app-store-main',
  templateUrl: './store-main.component.html',
  styleUrls: ['./store-main.component.css']
})

export class StoreMainComponent implements OnInit, OnDestroy {

  private tabIndexList: number = 0;
  private tabIndexEdit: number = 1;
  private tabViewOtherStore: number = 2;
  private tabViewOtherStoreComment: number = 3;

  subject = new Subject<string>();

  public tabSelection: number = 0;
  public formGroupCondition: FormGroup;
  public dateBegin: Date;
  public dateEnd: Date;
  public subDateBegin: Date;
  public subDateEnd: Date;
  private autoSearch: boolean = true;

  public taskList: TaskStoreRec[];
  public selectedTask: TaskStoreRec;
  public selectedViewOtherTask: TaskStoreRec;
  public selectedViewOtherTaskComment: TaskStoreRec;
  public selectedSubTask: SubTask;
  public taskRecComment: TaskRec;
  public taskCategories: HtmlSelectItem[] = [];
  public storeList: LoginStoreDto[];
  public loginUsers: {userId: string; userName: string;}[] = [];
  public formAuthorStoreCd: FormControl;
  public formAuthorLoginUserId: FormControl;

  public columnIds: string[] = ["authorFv","taskName", "priority", "dateBegin", "dateEnd", "datesToEndText", "resultStatus", "responsible", "editBtn"];
  public columnIdsWithCategory: string[] = ["authorFv","taskCategory", "taskName", "priority", "dateBegin", "dateEnd", "datesToEndText", "resultStatus", "responsible", "editBtn"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "authorFv", header:"指示者", width:100},
    { columnId: "taskCategory", header:"作業分類", width:200},
    { columnId: "taskName", header: "タスク名", width: 300 },
    { columnId: "priority", header: "優先度", width: 60, align: "center" },
    { columnId: "dateBegin", header: "開始日", width: 100, align: "center" },
    { columnId: "dateEnd", header: "終了日", width: 100, align: "center" },
    { columnId: "datesToEndText", header: "残日数", width: 60, align: "center" },
    { columnId: "resultStatus", header: "ステータス", width: 80, align: "center" },
    { columnId: "responsible", header: "担当者", width: 120, align: "left" },
  ];

  private subscStore: Subscription;
  private subscStoreCd: Subscription;
  private subscRoute: Subscription;

  @ViewChild(StoreEdit2Component, { static: false }) storeEdit2Component: StoreEdit2Component;
  @ViewChild(ViewOtherStoreComponent, { static: false }) viewOtherStoreComponent: ViewOtherStoreComponent;
  @ViewChild(ViewOtherStoreCommentComponent, { static: false }) viewOtherStoreCommentComponent: ViewOtherStoreCommentComponent;
  @ViewChild(DateRangeComponent, { static: true }) dateRangeComponent: DateRangeComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    public taskService: TaskService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dbService: DbService
  ) {
    this.initCond();
    if (this.commonService.config.task.useTaskCategory) {
      this.columnIds = [...this.columnIdsWithCategory];
    }
    if (this.commonService.config.includeNonAutoOrderStore.task === false) {
      this.storeList = [...this.commonService.stores]
    } else {
      this.storeList = [...this.commonService.allStores]
    }
  }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.subscStore = this.formGroupCondition.get("store").valueChanges.subscribe(
      (value) => { this.storeChanged(value); }
    );

    this.taskService.initStaff(this.commonService.loginUser.storeCd);
    this.getStoreTasks();
    this.getTaskCategories();

    this.subscRoute = this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        if (this.commonService.taskSearchCond !== undefined) {
          this.initCond();
          this.autoSearch = true;
          this.getStoreTasks();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.taskService.endEditStore();
    this.subscStore?.unsubscribe();
    this.subscStoreCd?.unsubscribe();
    this.subscRoute?.unsubscribe();
    this.subject.complete();
  }

  @HostListener('window:resize', ['$event'])
  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    if (this.tabSelection === 1) {
      this.storeEdit2Component?.setTableHeight();
    } else if (this.tabSelection === 2) {
      this.viewOtherStoreComponent?.setTableHeight();
    } else if (this.tabSelection === 3) {
      this.viewOtherStoreCommentComponent.setTableHeight();
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    this.setTableHeight();
  }

  initCond() {
    let cond: TaskSearchCond
    if (this.commonService.taskSearchCond !== undefined) {
      cond = {...this.commonService.taskSearchCond};
      this.commonService.taskSearchCond = undefined;
    } else {
      cond = {...this.commonService.config.task.searchCond};
    }

    this.formGroupCondition = new FormGroup({
      store:                new FormControl(this.commonService.loginUser.storeCd),
      statusNotAssigned:    new FormControl(cond.statusNotAssigned),
      statusNotStarted:     new FormControl(cond.statusNotStarted),
      statusStarted:        new FormControl(cond.statusStarted),
      statusCompleted:      new FormControl(cond.statusCompleted),
      dateCheck:            new FormControl(cond.endDate),
      taskName:             new FormControl(cond.taskName),
      authorPartial:        new FormControl(true),
      authorFv:             new FormControl(cond.author),
      authorStoreCd:        new FormControl(""),
      authorLoginUserId:    new FormControl(""),
      responsibleClass:     new FormControl(cond.responsibleClass),
      responsible:          new FormControl(cond.responsible),
      responsiblePartial:   new FormControl(cond.responsiblePartial),
      taskCategory:         new FormControl(cond.taskCategory),
      subStatusNotCompleted: new FormControl(cond.subStatusNotCompleted),
      subStatusCompleted:   new FormControl(cond.subStatusCompleted),
      subDateYesterday:     new FormControl(cond.subDateYesterday),
      subDateToday:         new FormControl(cond.subDateToday),
      subDateTommorow:      new FormControl(cond.subDateTommorow),
      subResponsible:       new FormControl(cond.subResponsible),
      subResponsiblePartial: new FormControl(cond.subResponsiblePartial)
    });
    this.formAuthorStoreCd = <FormControl>this.formGroupCondition.get("authorStoreCd");
    this.formAuthorLoginUserId = <FormControl>this.formGroupCondition.get("authorLoginUserId");
    this.subscStoreCd = this.formAuthorStoreCd.valueChanges.subscribe(
      (value) => { this.authorStoreCdChanged(); } 
    );

    this.dateBegin = new Date();
    this.dateBegin.setMonth(this.dateBegin.getMonth()  + cond.endDateFrom);
    this.dateBegin.setDate(this.dateBegin.getDate() + cond.endDateFromDay);
    this.dateEnd = new Date();
    this.dateEnd.setMonth(this.dateEnd.getMonth()  + cond.endDateTo);
    this.dateEnd.setDate(this.dateEnd.getDate()  + cond.endDateToDay);

    if (cond.storeCd !== "") {
      let stores: LoginStoreDto[];
      if (this.commonService.config.includeNonAutoOrderStore.task === false) {
        stores = this.commonService.stores;
      } else {
        stores = this.commonService.allStores;
      }
      if (stores.find((store) => store.storeCd === cond.storeCd)) {
        this.formGroupCondition.get("store").setValue(cond.storeCd);
      }
    }
  }

  getTaskCategories() {
    if (!this.commonService.config.task.useTaskCategory) return;
    this.dbService.getSelectItemList("task:taskCategory", "", (list) => { this.receiveTaskCategories(list); })
  }

  receiveTaskCategories(list: HtmlSelectItem[]) {
    this.taskCategories = list;
  }

  storeChanged(storeCd: string) {

    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.taskService.initStaff(storeCd);
      this.selectedSubTask = undefined;
      this.taskList = [];
      this.clearProgressEditStoreTemp();
      return;
    }
    this.formGroupCondition.controls.store.setValue(this.taskService.storeCd, { emitEvent: false });

    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.taskService.initStaff(storeCd);
          this.selectedSubTask = undefined;
          this.taskList = [];
          this.clearProgressEditStoreTemp();
        }
        this.formGroupCondition.controls.store.setValue(this.taskService.storeCd, { emitEvent: false });
      }
    );
    // this.getStoreTasks();
  }

  flipCondStatus(formName: string) {
    let form = this.formGroupCondition.get(formName);
    if (form.value) {
      form.setValue(false);
    } else {
      form.setValue(true);
    }
  }

  checkCondStatus(value: boolean) {
    this.formGroupCondition.get("statusNotAssigned").setValue(value);
    this.formGroupCondition.get("statusNotStarted").setValue(value);
    this.formGroupCondition.get("statusStarted").setValue(value);
    this.formGroupCondition.get("statusCompleted").setValue(value);
  }

  checkSubCondStatus(value: boolean) {
    this.formGroupCondition.get("subStatusNotCompleted").setValue(value);
    // this.formGroupCondition.get("subStatusExpired").setValue(value);
    this.formGroupCondition.get("subStatusCompleted").setValue(value);
  }

  checkSubDate(value: boolean) {
    this.formGroupCondition.get("subDateYesterday").setValue(value);
    this.formGroupCondition.get("subDateToday").setValue(value);
    this.formGroupCondition.get("subDateTommorow").setValue(value);
  }

  getStoreTasksConfirm() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.getStoreTasks();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.getStoreTasks();
        }
      }
    );
  }

  getStoreTasks() {
    let dateEndMin;
    let dateEndMax;
    if (this.autoSearch) {
      dateEndMin = this.commonService.formatDate(this.dateBegin);
      dateEndMax = this.commonService.formatDate(this.dateEnd);
      this.autoSearch = false;
    } else {
      let range = this.dateRangeComponent.getDateRange();
      dateEndMin = this.commonService.formatDate(range.dateBegin);
      dateEndMax = this.commonService.formatDate(range.dateEnd);
    }

    let request: ReqSearchTaskResultRec = {
      access: this.commonService.loginUser,
      taskId: -1,
      storeCd: this.formGroupCondition.get("store").value,
      resultStatus: [],
      dateEndMin: this.formGroupCondition.get("dateCheck").value ? dateEndMin : "",
      dateEndMax: this.formGroupCondition.get("dateCheck").value ? dateEndMax : "",
      taskName: this.formGroupCondition.get("taskName").value.trim(),
      // authorFv: this.formGroupCondition.get("authorFv").value.trim(),
      authorFv: "",
      authorStoreCd: "",
      authorLoginUserId: "",
      responsibleClass: this.formGroupCondition.get("responsibleClass").value.trim(),
      responsible: this.formGroupCondition.get("responsible").value.trim(),
      responsiblePartial: this.formGroupCondition.get("responsiblePartial").value,
      taskCategory: this.formGroupCondition.get("taskCategory").value,
      subStatusNotCompleted: this.formGroupCondition.get("subStatusNotCompleted").value,
      // subStatusExpired: this.formGroupCondition.get("subStatusExpired").value,
      subStatusCompleted: this.formGroupCondition.get("subStatusCompleted").value,
      subResponsible: this.formGroupCondition.get("subResponsible").value.trim(),
      subResponsiblePartial: this.formGroupCondition.get("subResponsiblePartial").value,
      subDateYesterday: this.formGroupCondition.get("subDateYesterday").value,
      subDateToday: this.formGroupCondition.get("subDateToday").value,
      subDateTommorow: this.formGroupCondition.get("subDateTommorow").value,
      isResponsibleUser: this.commonService.config.task.responsibleIsLoginUser
    }
    if (this.formGroupCondition.get("authorPartial").value) {
      request.authorFv = this.formGroupCondition.get("authorFv").value.trim();
    } else {
      request.authorStoreCd = this.formAuthorStoreCd.value;
      request.authorLoginUserId = this.formAuthorLoginUserId.value;
    }
    if (this.formGroupCondition.get("statusNotAssigned").value) request.resultStatus.push(this.taskService.subTaskStatusNotAssigned);
    if (this.formGroupCondition.get("statusNotStarted").value) request.resultStatus.push(this.taskService.subTaskStatusNotStarted);
    if (this.formGroupCondition.get("statusStarted").value) request.resultStatus.push(this.taskService.subTaskStatusStarted);
    if (this.formGroupCondition.get("statusCompleted").value) request.resultStatus.push(this.taskService.subTaskStatusCompleted);

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.searchTaskResultRec(request).subscribe(
      (response: RspSearchTaskResultRec) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveGetTaskResults(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetTaskResults(response: RspSearchTaskResultRec) {
    if (this.httpBasic.handleAppError(response)) return;
    this.taskList = [];
    let responsible = this.formGroupCondition.get("responsible").value;
    response.taskResultSearchRec.forEach((rec) => {

      let storeRec = new TaskStoreRec(this.commonService, this.taskService, this.httpBasic);
      let targetStores: TaskTargetStore[] = JSON.parse(rec.taskRec.targetStores);
      let subTasks: SubTask[] = JSON.parse(rec.taskRec.subTasks);
      delete rec.taskRec.targetStores;
      delete rec.taskRec.subTasks;
      storeRec.patchValue(rec.taskRec);
      storeRec.targetStores = targetStores;
      storeRec.subTasks = subTasks;
      storeRec.patchValueTaskResult(rec.taskResultRec);
      this.taskList.push(storeRec);
    });
    this.clearProgressEditStoreTemp();
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    };
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    };
  }

  isWarnColor(item: TaskStoreRec, columnId: string) {
    if (columnId != "datesToEndText") return false;
    if (item.datesToEndText === "") return false;
    if (item.datesToEnd >= 0) return false;
    return true;
  }
  isWarnYellowBgColor(item: TaskStoreRec, columnId: string) {
    if (columnId != "datesToEndText") return false;
    if (item.datesToEndText === "") return false;
    if (item.datesToEnd > 7 || item.datesToEnd < 0) return false;
    return true;
  }
  selectTask(item: TaskStoreRec) {
    this.selectedTask = item;
  }

  editTask(item: TaskStoreRec) {
    this.taskService.isViewTaskStore = false;
    this.selectedSubTask = undefined;

    this.selectedTask = item;
    /* 公開後の編集可能に伴い、キャッシュは使用しない
    if (item.subTaskResults && item.subTaskResults.length > 0) {
      this.editTaskBody(item);
      this.subject.next("changesDetech");
      return;
    }
    */
    // this.subTaskResult(item);
    this.taskService.subTaskResult(item, () => { this.editTaskBody(this.selectedTask); });
  }

  editTaskBody(item: TaskStoreRec) {
    const request: ReqTaskFile = {
      taskId: this.selectedTask.taskId,
      access: this.commonService.loginUser,
      mode: 1
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.taskRecFileInfoSearch(request).subscribe(
      (response: RspTaskFileInfo) => {
        if(response) {
          this.commonService.closeSpinner()
          item.attachmentList = response.taskRecFileInfo.map(file => Object.assign(file));
          this.taskService.startEditStore(item);
          this.tabSelection = this.tabIndexEdit;
          ;
        }
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )
  }

  canDeactivate() {
    if (this.taskService.canEditStore()) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  clearProgressEditStoreTemp() {
    this.selectedTask = undefined;
    this.taskService.endEditStore();
    this.taskService.isViewTaskStore = false;

  }

  viewTask(item: TaskStoreRec) {
    let deactivate = this.canDeactivate();
    this.selectedSubTask = undefined;
    if (deactivate == true) {
      this.taskService.endEditStore();
      // this.taskService.isViewTaskStore = true;
      this.taskService.isViewTaskStore = !this.commonService.config.task.isCompletedTaskEditable;
      this.cdr.detectChanges();

      this.selectedTask = item;
      if (item.subTaskResults && item.subTaskResults.length > 0) {
        this.editTaskBody(item);
        return;
      }
      // this.subTaskResult(item);
      this.taskService.subTaskResult(item, () => {
        this.editTaskBody(this.selectedTask);
      });
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.taskService.endEditStore();
          // this.taskService.isViewTaskStore = true;
          this.taskService.isViewTaskStore = !this.commonService.config.task.isCompletedTaskEditable;
          this.cdr.detectChanges();

          this.selectedTask = item;
          if (item.subTaskResults && item.subTaskResults.length > 0) {
            this.editTaskBody(item);
            return;
          }
          // this.subTaskResult(item);
          this.taskService.subTaskResult(item, () => {
            this.editTaskBody(this.selectedTask);
          });
        }
      }
    );
  }

  viewTaskOtherStoreChanged(selectedSubTask: SubTask){
    this.selectedSubTask = selectedSubTask;
    this.selectedViewOtherTask = this.taskService.editTaskStore;
    if(selectedSubTask) {
      this.tabSelection = this.tabViewOtherStore;
    } 
  }
  
  viewTaskOtherStoreCommentChanged(selectedSubTask: SubTask) {
    this.selectedSubTask = selectedSubTask;
    this.selectedViewOtherTaskComment = this.taskService.editTaskStore;
    if(selectedSubTask) {
      this.tabSelection = this.tabViewOtherStoreComment;
    } 
  }

  authorStoreCdChanged() {
    this.formAuthorLoginUserId.setValue("");

    let storeCd = this.formAuthorStoreCd.value;
    if (storeCd === "") {
      this.loginUsers = [];
    } else {
      this.getUsers(storeCd);
    }
  }

  getUsers(storeCd: string) {
    let request: ReqGetUsers = {
      access: this.commonService.loginUser,
      storeCd: storeCd
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetUsers", request).subscribe(
      (response: RspGetUsers) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveGetUsers(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetUsers(response: RspGetUsers) {
    if (this.httpBasic.handleAppError(response)) return;

    this.loginUsers = [];
    response.users.forEach((dto) => {
      let user = {
        userId: dto.userId,
        userName: dto.userName
      };
      this.loginUsers.push(dto);
    });
  }
}
