import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { CsvData, CsvLineData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CsvTableComponent } from 'src/app/partsCommon/csv-table/csv-table.component';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { ReqCsvInventoryCheckDto } from 'src/app/request/req-csv-inventory-check';
import { ReqCsvInventoryHistoryDelete } from 'src/app/request/req-csv-inventory-history-delete';
import { ReqCsvInventoryHistoryDetailDelete } from 'src/app/request/req-csv-inventory-history-detail-delete';
import { ReqCsvInventoryHistoryDetailSearchDto } from 'src/app/request/req-csv-inventory-history-detail-search';
import { ReqCsvInventoryHistorySearchDto } from 'src/app/request/req-csv-inventory-history-search';
import { ReqCsvInventoryUpdateDto } from 'src/app/request/req-csv-inventory-update';
import { RspCsvInventoryCheck } from 'src/app/response/rsp-csv-inventory-check';
import { RspCsvInventoryHistoryDetailSearch, RspCsvInventoryHistoryDetailSearchDto } from 'src/app/response/rsp-csv-inventory-history-detail-search';
import { RspCsvInventoryHistorySearch, RspCsvInventoryHistorySearchDto } from 'src/app/response/rsp-csv-inventory-history-search';
import { RspCsvInventoryUpdate } from 'src/app/response/rsp-csv-inventory-update';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

interface recCountByStore {
  storeCd: string;
  storeName: string;
  recCount: number;
}

class HistoryRec {
  storeCd: string;
  storeName: string;
  stockDate: string;
  recCount: number;
  invStockCount: number;
  stockCount: number;

  constructor(rspCsvInventoryHistorySearchDto: RspCsvInventoryHistorySearchDto) {
    this.storeCd = rspCsvInventoryHistorySearchDto.storeCdFv;
    this.storeName = rspCsvInventoryHistorySearchDto.storeNameFv;
    this.stockDate = formatDate(rspCsvInventoryHistorySearchDto.stockDateFv, 'yyyy/MM/dd', 'en_US');
    this.recCount = rspCsvInventoryHistorySearchDto.recCountFn;
    this.invStockCount = rspCsvInventoryHistorySearchDto.invStockCountFn;
    this.stockCount = rspCsvInventoryHistorySearchDto.stockCountFn;
  }
}
class HistoryDetailRec {
  storeCd: string;
  storeName: string;
  ctgLevel: number;
  ctgCd0: string;
  ctgName0: string;
  ctgCd1: string;
  ctgName1: string;
  ctgCd2: string;
  ctgName2: string;
  ctgCd3: string;
  ctgName3: string;
  liveItems: number;
  recCount: number;
  invStockCount: number;
  stockCount: number;

  constructor(rspCsvInventoryHistoryDetailSearchDto: RspCsvInventoryHistoryDetailSearchDto) {
    this.storeCd = rspCsvInventoryHistoryDetailSearchDto.storeCdFv;
    this.storeName = rspCsvInventoryHistoryDetailSearchDto.storeNameFv;
    this.ctgLevel = rspCsvInventoryHistoryDetailSearchDto.ctgLevelFn;

    this.ctgCd0 = rspCsvInventoryHistoryDetailSearchDto.ctg0CdFv || "";
    this.ctgName0 = rspCsvInventoryHistoryDetailSearchDto.ctg0NameFv || "";
    this.ctgCd1 = rspCsvInventoryHistoryDetailSearchDto.ctg1CdFv || "";
    this.ctgName1 = rspCsvInventoryHistoryDetailSearchDto.ctg1NameFv || "";
    this.ctgCd2 = rspCsvInventoryHistoryDetailSearchDto.ctg2CdFv || "";
    this.ctgName2 = rspCsvInventoryHistoryDetailSearchDto.ctg2NameFv || "";
    this.ctgCd3 = rspCsvInventoryHistoryDetailSearchDto.ctg3CdFv || "";
    this.ctgName3 = rspCsvInventoryHistoryDetailSearchDto.ctg3NameFv || "";

    this.liveItems = rspCsvInventoryHistoryDetailSearchDto.liveItemCountFn;
    this.recCount = rspCsvInventoryHistoryDetailSearchDto.recCountFn;
    this.invStockCount = rspCsvInventoryHistoryDetailSearchDto.invStockCountFn;
    this.stockCount = rspCsvInventoryHistoryDetailSearchDto.stockCountFn;
  }
}

@Component({
  selector: 'app-csv-inventory',
  templateUrl: './csv-inventory.component.html',
  styleUrls: ['./csv-inventory.component.css']
})
export class CsvInventoryComponent implements OnInit {

  private isReadonly: boolean;
  public layoutComment: string = "";
  public deleteComment: string = "";
  private deleteDateMin: Date;
  public csvData: CsvData;
  public csvFilterList: CsvData;
  public csvDisplayList: CsvData;
  public csvColumnIds: string[];
  public columnIdsWithNoError: string[] = ["lineNumber"];
  public columnIdsWithError: string[] = ["lineNumber", "errorWaring"];
  public csvColumnDefs: TableColumnDef[] = [
    { columnId: "storeCd", header: "店舗コード", width: 70, align: "center" },
    { columnId: "storeName", header: "店舗名", width: 100, csvComment: "[空欄可]" },
    { columnId: "itemCd", header: "商品コード", width: 100, align: "center" },
    { columnId: "itemName", header: "商品名", width: 300, align: "left", csvComment: "[空欄可]" },
    { columnId: "location", header: "ロケーション", width: 150, align: "left", csvComment: "[空欄可]" },
    { columnId: "stockCount", header: "在庫数", width: 60, align: "right", csvComment: "[0以上の数値]" },
    { columnId: "stockDate", header: "棚卸日付", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd]" },
    { columnId: "stockTime", header: "棚卸時刻", width: 100, align: "center", csvComment: "[24H:mm:ss]" }
  ];
  public recordCount = 0;

  public recCountList: recCountByStore[];
  public recCountColumnIds: string[] = ["storeName", "recCount"];
  public recCountColumnDefs: TableColumnDef[] = [
    // { columnId: "storeCd", header: "店舗コード", width: 70, align: "center" },
    { columnId: "storeName", header: "フィルター", width: 100 },
    { columnId: "recCount", header: "行数", width: 60, align: "right", numberPipe: "" },
  ];
  public selectedStore: recCountByStore;

  public formGroup: FormGroup;
  public formHistType: FormControl = new FormControl("1");
  public maxDate: Date;
  public minDateBegin: Date;
  private histData: HistoryRec[];
  public histMatrix: Object[];
  public histColumnIds: string[];
  public histColumnIdsDate: string[];
  public histColumnIdsDataHeader: string[];
  public histColumnIdsData: string[];
  public histColumnDefs: TableColumnDef[];
  private histBaseColumnDefs: TableColumnDef[] = [
    { columnId: "storeName", header: "店舗", width: 140 },
  ];
  private histDefaultColumnDef: TableColumnDef = { columnId: "", header: "", width: 70, align: "right", numberPipe: "" };

  public histColumnDefsDate: TableColumnDef[];
  private histBaseColumnDefsDate: TableColumnDef[] = [
    { columnId: "_storeName", header: "店舗", width: 140, colspan: 1, rowspan: 2 },
  ];
  private histDefaultColumnDefDate: TableColumnDef = { columnId: "", header: "", width: 70, colspan: 2, rowspan: 1 };

  public histColumnDefsData: TableColumnDef[];
  private histBaseColumnDefsData: TableColumnDef[] = [
    { columnId: "storeName", header: "", width: 140, colspan: 1, rowspan: 1 },
  ];
  private histDefaultColumnDefData: TableColumnDef = { columnId: "", header: "", width: 70, align: "right", numberPipe: "", colspan: 1, rowspan: 1 };

  public selectedHistCell: { storeCd: string, storeName: string; stockDate: string };
  public selectedHistDetail: { storeCd: string, storeName: string; stockDate: string };
  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);

  public histDetailColumnIds: string[] = [];
  public histDetailColumnDefs: TableColumnDef[] = [
    { columnId: "ctgName0", header: this.commonService.literal.ctg0Name, width: 200 },
    { columnId: "ctgName1", header: this.commonService.literal.ctg1Name, width: 200 },
    { columnId: "ctgName2", header: this.commonService.literal.ctg2Name, width: 200 },
    { columnId: "ctgName3", header: this.commonService.literal.ctg3Name, width: 200 },
    { columnId: "liveItems", header: "有効商品数", width: 100, align: "right", numberPipe: "" },
    { columnId: "recCount", header: "登録件数", width: 100, align: "right", numberPipe: "" },
    { columnId: "invStockCount", header: "棚卸在庫数", width: 100, align: "right", numberPipe: "" },
    { columnId: "stockCount", header: "元在庫数", width: 100, align: "right", numberPipe: "" },
  ];
  public histDetailList: HistoryDetailRec[];

  public subscriptionCheck: Subscription;
  public subscriptionUpdate: Subscription;
  public subscriptionSearch: Subscription;
  public subscriptionConfirm: Subscription;
  public subscriptionHistType: Subscription;

  public isBlockRefreshHistorySelected: boolean = false;
  public tabSelection: number = 0;
  public isHistOpen: boolean = true;
  public isDetailOpen: boolean = true;

  @ViewChild(CsvTableComponent, { static: false }) csvTableComponent: CsvTableComponent;
  @ViewChild(MatPaginator, { static: false }) matPagenator: MatPaginator;
  @ViewChild(CtgSelectComponent, { static: false }) public ctgSelectComponent: CtgSelectComponent;
  @ViewChild("sectionHistoryHeader", { static: true }) sectionHistory: SectionHeaderComponent;
  @ViewChild("sectionHistoryDetailHeader", { static: true }) sectionHistoryDetail: SectionHeaderComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("csvInventory");

    if (this.commonService.stores.length <= 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この機能を使用する権限がありません。");
      this.commonService.router.navigate(['top']);
      return;
    }

    if (this.commonService.stores.length <= 1) {
      this.csvColumnDefs = [...this.csvColumnDefs]
        .filter(column => (column.columnId !== "storeCd" && column.columnId != "storeName"));
    }

    if (this.commonService.config.csvInventoryDateShift) {
      this.layoutComment = "時刻が " +
        this.commonService.config.csvInventoryDateShift.timeBegin + ":00 ～ " +
        this.commonService.config.csvInventoryDateShift.timeEnd + ":59 のデータは、" +
        (this.commonService.config.csvInventoryDateShift.shiftValue > 0 ?
          "翌日の朝" :
          "前日の夜"
        ) +
        "時点の在庫として処理されます。<br>" +
        "上記以外は、棚卸日付で指定した日の" +
        (this.commonService.config.csvInventoryDateShift.shiftValue > 0 ?
          "朝" :
          "夜"
        ) +
        "時点の在庫として処理されます。";
    }

    this.deleteDateMin = new Date();
    if (this.commonService.config.csvInventoryDeleteRange) {
      this.deleteDateMin.setDate(this.deleteDateMin.getDate() - this.commonService.config.csvInventoryDeleteRange);
      this.deleteComment = "削除可能なデータは、在庫日付が " + this.commonService.formatDate(this.deleteDateMin) + " 以降のデータです。";
    } else {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "csvInventoryDeleteRangeの設定がありません。");
      this.deleteDateMin.setDate(this.deleteDateMin.getDate() - 6);
      this.deleteComment = "削除可能なデータは、在庫日付が " + this.commonService.formatDate(this.deleteDateMin) + " 以降のデータです。";
    }

    this.formGroup = this.fb.group(
      {
        dateBegin: [, Validators.required],
        dateEnd: [, Validators.required],
      }
    );

    this.maxDate = new Date();
    this.minDateBegin = new Date(2020, 0, 1);
    let begin = this.commonService.copyDate(this.maxDate);
    begin.setDate(begin.getDate() - 28);
    this.formGroup.get("dateBegin").setValue(begin);
    this.formGroup.get("dateEnd").setValue(this.maxDate);

    this.subscriptionHistType = this.formHistType.valueChanges.subscribe(
      (value) => {
        this.setHeight();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptionCheck) this.subscriptionCheck.unsubscribe();
    if (this.subscriptionUpdate) this.subscriptionUpdate.unsubscribe();
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
    if (this.subscriptionHistType) this.subscriptionHistType.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setHeight();
  }

  setHeight() {
    setTimeout(()=>{this.setHeightBody();}, 0);
  }

  setHeightBody() {
    if (this.tabSelection != 1) return;
    if (this.isHistOpen == false && this.isDetailOpen == false) return;

    let histRecCount = this.histMatrix != undefined ? this.histMatrix.length : 0;
    if (histRecCount == 0) {
      return;
    }
    let detailRecCount = this.histDetailList != undefined ? this.histDetailList.length : 0;

    let histMinHeight = 57 + 48;
    let detailMinHeight = 94 + 48;
    if (detailRecCount == 0) {
      detailMinHeight = 84;
    }
    
    let id = "hist-section";
    let height = this.commonService.getHeightBelow(id);
    if (!height) return;

    let histHeight;
    let detailHeight;

    if (!this.isDetailOpen || !this.selectedHistDetail) {
      let remHeight = height;
      remHeight -= 24;                                  // Section Header height and margins
      if (remHeight <= 0) {
        histHeight = height;
      } else {
        histHeight = remHeight >= histMinHeight ? remHeight : histMinHeight;
      }
    } else if (!this.isHistOpen) {
      let id = "detail-section";
      detailHeight = this.commonService.getHeightBelow(id);
      if (detailHeight <= 0) return;
    } else {
      let remHeight = height;
      remHeight -= 24 + 5 + 5;                           // Section Header height and margins
      remHeight -= histMinHeight + detailMinHeight;
      if (remHeight <= 0) {
        histHeight = histMinHeight;
        detailHeight = detailMinHeight;
      } else {
        histHeight = Math.floor(remHeight * histRecCount / (histRecCount + detailRecCount)) + histMinHeight;
        detailHeight = Math.floor(remHeight * detailRecCount / (histRecCount + detailRecCount)) + detailMinHeight;
      }
    }

    if (this.isHistOpen) {
      let histTableHeight = histHeight - 57;
      id = "history-container-1";
      let containerElement = document.getElementById(id);
      if (containerElement) {
        containerElement.style["max-height"] = "" + histTableHeight + "px";
      }
      id = "history-container-2";
      containerElement = document.getElementById(id);
      if (containerElement) {
        containerElement.style["max-height"] = "" + histTableHeight + "px";
      }
    }

    if (this.isDetailOpen && this.selectedHistDetail) {
      let detailTableHeight = detailHeight - 94;
      id = "history-detail-container";
      let containerElement = document.getElementById(id);
      if (containerElement) {
        containerElement.style["max-height"] = "" + detailTableHeight + "px";
      }
    }
  }

  tabChanged(tabNumber: number) {
    if (tabNumber == 1) {
      this.setHeight();
    }
  }

  histViewSection(open: boolean) {
    this.isHistOpen = open;
    this.setHeight();
  }

  detailViewSection(open: boolean) {
    this.isDetailOpen = open;
    this.setHeight();
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionCheck) {
      this.subscriptionCheck.unsubscribe();
      this.subscriptionCheck = undefined;
    }
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
      this.subscriptionUpdate = undefined;
    }
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
      this.subscriptionSearch = undefined;
    }
  }

  getCsvData(csvData: CsvData) {
    if (this.matPagenator) {
      this.matPagenator.pageIndex = 0;
    }

    this.csvData = { ...csvData };

    this.buildRecCountList();
    this.buildCsvFilterList();
    this.buildCsvDisplayList();
    this.setupColumns();
  }

  setupColumns() {
    if ((this.csvDisplayList.errorCount && this.csvDisplayList.errorCount > 0) ||
      (this.csvDisplayList.warningCount && this.csvDisplayList.warningCount > 0)) {
      this.csvColumnIds = [...this.columnIdsWithError];
    } else {
      this.csvColumnIds = [...this.columnIdsWithNoError];
    }
    for (let col of this.csvColumnDefs) {
      this.csvColumnIds.push(col.columnId);
    }
  }

  isDisableUpdateBtn() {
    if (this.csvData == undefined) return true;
    if (this.csvData.errorCount == undefined) return true;
    if (this.csvData.errorCount > 0) return true;
    return false;
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "max-width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "max-width": "" + colDef.width + "px"
    }
  }

  pageChanged(event: PageEvent) {
    this.buildCsvDisplayList();
  }

  initErrorWarningCount() {
    this.csvData.errorCount = 0;
    this.csvData.warningCount = 0;
    for (let line of this.csvData.lines) {
      if (line.errorMsg.length > 0) this.csvData.errorCount++;
      if (line.warningMsg.length > 0) this.csvData.warningCount++;
    }
  }

  buildCsvFilterList() {
    this.csvFilterList = {
      errorCount: 0,
      warningCount: 0,
      lines: []
    }

    if (this.commonService.stores.length <= 1) {
      for (let line of this.csvData.lines) {
        if (this.selectedStore.storeCd !== "#" && this.commonService.stores[0].storeCd === this.selectedStore.storeCd) {
          this.csvFilterList.lines.push(line);
          if (line.errorMsg.length > 0) this.csvFilterList.errorCount++;
          if (line.warningMsg.length > 0) this.csvFilterList.warningCount++;
          continue;
        }
        if (this.selectedStore.storeCd == "#" &&
          (line.errorMsg && line.errorMsg.length > 0) ||
          (line.warningMsg && line.warningMsg.length > 0)) {
          this.csvFilterList.lines.push(line);
          if (line.errorMsg.length > 0) this.csvFilterList.errorCount++;
          if (line.warningMsg.length > 0) this.csvFilterList.warningCount++;
          continue;
        }
      }
    } else {
      for (let line of this.csvData.lines) {
        if (this.selectedStore.storeCd == "*" || line.columnData["storeCd"] === this.selectedStore.storeCd) {
          this.csvFilterList.lines.push(line);
          if (line.errorMsg.length > 0) this.csvFilterList.errorCount++;
          if (line.warningMsg.length > 0) this.csvFilterList.warningCount++;
          continue;
        }
        if (this.selectedStore.storeCd == "#" &&
          (line.errorMsg && line.errorMsg.length > 0) ||
          (line.warningMsg && line.warningMsg.length > 0)) {
          this.csvFilterList.lines.push(line);
          if (line.errorMsg.length > 0) this.csvFilterList.errorCount++;
          if (line.warningMsg.length > 0) this.csvFilterList.warningCount++;
          continue;
        }
      }
    }

    this.recordCount = this.csvFilterList.lines.length;
  }

  buildCsvDisplayList() {
    let start: number;
    let last: number;
    if (this.matPagenator) {
      start = this.matPagenator.pageSize * this.matPagenator.pageIndex;
      last = start + this.matPagenator.pageSize;
    } else {
      start = 0;
      last = this.commonService.paginatorOption.pageSizeOptions[0];
    }

    this.csvDisplayList = {
      errorCount: 0,
      warningCount: 0,
      lines: []
    }

    let max = this.csvFilterList.lines.length;
    if (max > 100) max = 100;
    for (let i = start; i < last && i < this.csvFilterList.lines.length; i++) {
      this.csvDisplayList.lines.push(this.csvFilterList.lines[i]);
      if (this.csvFilterList.lines[i].errorMsg.length > 0) this.csvDisplayList.errorCount++;
      if (this.csvFilterList.lines[i].warningMsg.length > 0) this.csvDisplayList.warningCount++;
    }

    this.setupColumns();
  }

  initRecCountList() {
    this.recCountList = [
      {
        storeCd: "#",
        storeName: "エラー/警告",
        recCount: 0
      }
    ];

    if (this.commonService.stores.length > 1) {
      this.recCountList.push({
        storeCd: "*",
        storeName: "*：全店",
        recCount: 0
      })
    }

    for (let store of this.commonService.stores) {
      this.recCountList.push({
        storeCd: store.storeCd,
        storeName: store.storeCd + "：" + store.storeName,
        recCount: 0
      });
    }
    this.selectedStore = this.recCountList[1];
  }

  addCount(csvdata: CsvLineData) {
    this.recCountList[1].recCount++;
    if ((csvdata.errorMsg && csvdata.errorMsg.length > 0) ||
      (csvdata.warningMsg && csvdata.warningMsg.length > 0)) {
      this.recCountList[0].recCount++;
    }
    for (let listRec of this.recCountList) {
      if (listRec.storeCd === csvdata.columnData["storeCd"]) {
        listRec.recCount++;
        return;
      }
    }
  }

  buildRecCountList() {
    this.initRecCountList();
    for (let csvdata of this.csvData.lines) {
      this.addCount(csvdata);
    }
  }

  getErrorCount() {
    if (this.csvData.errorCount == undefined) return "";
    return this.csvData.errorCount;
  }

  getWarningCount() {
    if (this.csvData.warningCount == undefined) return "";
    return this.csvData.warningCount;
  }

  clickFilter(store: recCountByStore) {
    if (store.recCount == 0) return;
    if (this.selectedStore && this.selectedStore.storeCd === store.storeCd) return;

    if (this.matPagenator) {
      this.matPagenator.pageIndex = 0;
    }

    this.selectedStore = store;
    this.commonService.openSpinner(this.commonService.pageTitle, "処理中・・・");
    setTimeout(() => { this.doFilter(); }, 0);
  }

  doFilter() {
    this.buildCsvFilterList();
    this.buildCsvDisplayList();
    this.commonService.closeSpinner();
  }

  getDataCheckOnTable() {
    let request: ReqCsvInventoryCheckDto[] = [];
    this.csvData.lines.map(value => {
      let item: ReqCsvInventoryCheckDto = {
        itemCdFv: value.columnData['itemCd'].trim(),
        itemNameFv: value.columnData['itemName'].trim(),
        locationFv: value.columnData['location'].trim(),
        stockCountFv: value.columnData['stockCount'].trim(),
        stockDateFv: value.columnData['stockDate'].trim(),
        stockTimeFv: value.columnData['stockTime'].trim()
      }

      if (this.commonService.stores.length && this.commonService.stores.length > 1) {
        item.storeCdFv = value.columnData['storeCd'].trim();
        item.storeNameFv = value.columnData['storeName'].trim();
      }
      request.push(item);
    });
    return request;
  }

  getDataUpdateOnTable() {
    let request: ReqCsvInventoryUpdateDto[] = [];
    this.csvData.lines.map(value => {
      let item: ReqCsvInventoryUpdateDto = {
        itemCdFv: value.columnData['itemCd'].trim(),
        itemNameFv: value.columnData['itemName'].trim(),
        locationFv: value.columnData['location'].trim(),
        stockCountFv: value.columnData['stockCount'].trim(),
        stockDateFv: value.columnData['stockDate'].trim(),
        stockTimeFv: value.columnData['stockTime'].trim()
      }

      if (this.commonService.stores.length && this.commonService.stores.length > 1) {
        item.storeCdFv = value.columnData['storeCd'].trim();
        item.storeNameFv = value.columnData['storeName'].trim();
      }
      request.push(item);
    });
    return request;
  }

  doConfirm() {

    const request: ReqCsvInventoryCheckDto[] = this.getDataCheckOnTable();

    if (request.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "データがありません。");
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "確認中・・・");
    this.subscriptionCheck = this.httpBasic.checkCsvInventory(request).subscribe(
      (response: RspCsvInventoryCheck) => {
        this.checkResultCsvInventoryCheck(response);
      },
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  doUpdate() {
    const request: ReqCsvInventoryUpdateDto[] = this.getDataUpdateOnTable();

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.subscriptionUpdate = this.httpBasic.updateCsvInventory(request).subscribe(
      (response: RspCsvInventoryUpdate) => {
        this.checkResultInventoryUpdate(response);
      },
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  checkResultCsvInventoryCheck(response: RspCsvInventoryCheck) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    if (response.result.length > 0) {
      response.result.map((item) => {
        if (item.errorMsgs) {
          this.csvData.lines[item.line].errorMsg = item.errorMsgs;
          this.csvData.lines[item.line].warningMsg = item.warningMsgs;
        }
      });
      this.initErrorWarningCount();
      this.buildRecCountList();
      this.buildCsvFilterList();
      this.buildCsvDisplayList();
    }
  }

  checkResultInventoryUpdate(response: RspCsvInventoryUpdate) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.histMatrix = undefined;
    this.histData = [];
    this.selectedHistCell = undefined;
    this.selectedHistDetail = undefined;
    this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');
  }

  getDateBeginMax() {
    if (this.formGroup.get("dateEnd").value) {
      return this.formGroup.get("dateEnd").value;
    }
    return this.maxDate;
  }

  searchHistory() {
    let reqDto: ReqCsvInventoryHistorySearchDto = {
      dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
      dateEndFv: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US')
    }

    if (this.commonService.stores.length <= 1) {
      reqDto.storeCdFv = this.commonService.loginUser.storeCd;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionSearch = this.httpBasic.searchCsvInventoryHistory(reqDto).subscribe(
      (data) => {this.receiveHistory(data);},
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  searchHistoryDetail() {
    let reqDto: ReqCsvInventoryHistoryDetailSearchDto = {
      dateFv: formatDate(this.selectedHistDetail.stockDate, 'yyyy/MM/dd', 'en_US'),
      storeCdFv: this.selectedHistDetail.storeCd,
      ctg0CdFv: this.ctgSelectCondition.formGroup.get("ctgCd0").value,
      ctg1CdFv: this.ctgSelectCondition.formGroup.get("ctgCd1").value,
      ctg2CdFv: this.ctgSelectCondition.formGroup.get("ctgCd2").value,
      ctg3CdFv: this.ctgSelectCondition.formGroup.get("ctgCd3").value,
      ctgLevelFn: this.ctgSelectCondition.formGroup.get("ctgLevel").value,
    }

    if (this.commonService.stores.length <= 1) {
      reqDto.storeCdFv = this.commonService.loginUser.storeCd;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionSearch = this.httpBasic.searchCsvInventoryHistoryDetail(reqDto).subscribe(
      (data) => {this.receiveHistoryDetail(data);},
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  getHistory() {
    this.searchHistory();
  }

  receiveHistory(response: RspCsvInventoryHistorySearch) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.histData = [];
    for (let row of response.result) {
      this.histData.push(new HistoryRec(row))
    }

    if (this.isBlockRefreshHistorySelected) {
      let isExistSelectedHistCellInNewResponse: boolean = false;
      for (let row of this.histData) {
        if (this.selectedHistCell.storeCd == row.storeCd && Date.parse(this.selectedHistCell.stockDate) == Date.parse(row.stockDate)) {
          isExistSelectedHistCellInNewResponse = true;
          break;
        }
      }
      if (!isExistSelectedHistCellInNewResponse) {
        this.selectedHistCell = undefined;
        this.selectedHistDetail = undefined;
      }
      this.isBlockRefreshHistorySelected = false;
    } else {
      this.selectedHistCell = undefined;
      this.selectedHistDetail = undefined;
    }

    this.buildHistMatrix();
    this.sectionHistory.openSectionBody();
    this.setHeight();
  }

  sortHistData(target: Object[], colIds: string[]) {
    target.sort(function (a, b): number {
      for (let id of colIds) {
        if (a[id] === b[id]) continue;    // Compare next column
        if (a[id] < b[id]) {
          return -1;
        }
        return 1;
      }
      return 0;
    });
  }

  findMatrixRow(matric: Object[], storeCd: string) {
    return matric.find(v => v["storeCd"] === storeCd);
  }

  convertToColumnId(dateStr: string) {
    // return "col-" + dateStr;
    return dateStr;
  }

  buildHistMatrix() {
    this.histMatrix = [];
    this.histColumnDefs = [...this.histBaseColumnDefs];
    this.histColumnDefsDate = [...this.histBaseColumnDefsDate];
    this.histColumnDefsData = [...this.histBaseColumnDefsData];
    this.histColumnIds = [];
    this.histColumnIdsDate = [];
    this.histColumnIdsDataHeader = [];
    this.histColumnIdsData = [];

    // if empty histData
    if (!this.histData || !this.histData.length || this.histData.length <= 0) {
      for (let coldef of this.histColumnDefs) {
        this.histColumnIds.push(coldef.columnId);
      }
      for (let coldef of this.histColumnDefsDate) {
        this.histColumnIdsDate.push(coldef.columnId);
      }
      for (let coldef of this.histColumnDefsData) {
        if (coldef.columnId != "storeName") {
          this.histColumnIdsDataHeader.push(coldef.columnId);
        }
        this.histColumnIdsData.push(coldef.columnId);
      }
      for (let store of this.commonService.stores) {
        let newRow = {
          storeCd: store.storeCd,
          storeName: store.storeCd + "：" + store.storeName
        };
        this.histMatrix.push(newRow);
      }
      return;
    }

    // get all xAxis Data
    let xSortedData = [...this.histData];
    this.sortHistData(xSortedData, ["stockDate"]);
    let xMaxValue = "";

    for (let row of xSortedData) {
      if (Date.parse(row.stockDate) <= Date.parse(xMaxValue)) continue;
      xMaxValue = row.stockDate;
      let colDef = { ...this.histDefaultColumnDef };
      colDef.columnId = this.convertToColumnId(xMaxValue);
      colDef.header = xMaxValue;
      this.histColumnDefs.push(colDef);

      colDef = { ...this.histDefaultColumnDefDate };
      colDef.columnId = this.convertToColumnId(xMaxValue);
      colDef.header = xMaxValue;
      this.histColumnDefsDate.push(colDef);

      colDef = { ...this.histDefaultColumnDefData };
      colDef.columnId = this.convertToColumnId(xMaxValue) + "Inventory";
      colDef.header = "棚卸在庫数";
      this.histColumnDefsData.push(colDef);
      colDef = { ...this.histDefaultColumnDefData };
      colDef.columnId = this.convertToColumnId(xMaxValue) + "Stock";
      colDef.header = "元在庫数";
      this.histColumnDefsData.push(colDef);
    }

    for (let coldef of this.histColumnDefs) {
      this.histColumnIds.push(coldef.columnId);
    }
    for (let coldef of this.histColumnDefsDate) {
      this.histColumnIdsDate.push(coldef.columnId);
    }
    for (let coldef of this.histColumnDefsData) {
      if (coldef.columnId != "storeName") {
        this.histColumnIdsDataHeader.push(coldef.columnId);
      }
      this.histColumnIdsData.push(coldef.columnId);
    }

    // build all rows
    for (let store of this.commonService.stores) {
      let newRow = {
        storeCd: store.storeCd,
        storeName: store.storeCd + "：" + store.storeName
      };
      this.histMatrix.push(newRow);
    }

    // add data column and assign value
    for (let listRow of this.histData) {
      let row = this.findMatrixRow(this.histMatrix, listRow.storeCd);
      if (row) {
        row[this.convertToColumnId(listRow.stockDate)] = listRow.recCount;
        row[this.convertToColumnId(listRow.stockDate + "Inventory")] = listRow.invStockCount;
        row[this.convertToColumnId(listRow.stockDate) + "Stock"] = listRow.stockCount;
      }
    }

    /* set value 0 to undefined cell */
    for (let matrixRow of this.histMatrix) {
      for (let colId of this.histColumnIds) {
        if (matrixRow[colId] == undefined) matrixRow[colId] = 0;
      }
      for (let colId of this.histColumnIdsData) {
        if (matrixRow[colId] == undefined) matrixRow[colId] = 0;
      }
    }
  }

  isSelectedCell(item: Object, column: TableColumnDef) {
    if (!this.selectedHistCell) return false;
    if (this.selectedHistCell.storeCd !== item["storeCd"]) return false;
    if (this.selectedHistCell.stockDate !== column.columnId) return false;
    return true;
  }
  isSelectedCellStock(item: Object, column: TableColumnDef) {
    if (!this.selectedHistCell) return false;
    if (this.selectedHistCell.storeCd !== item["storeCd"]) return false;
    if (column.columnId.endsWith("Stock")) return false;
    if (!column.columnId.startsWith(this.selectedHistCell.stockDate)) return false;
    return true;
  }

  isHistClickable(item: Object, column: TableColumnDef) {
    if (column.columnId == "storeCd") return false;
    if (item[column.columnId] > 0) return true;
    return false;
  }
  isHistClickableStock(item: Object, column: TableColumnDef) {
    if (column.columnId == "storeCd") return false;
    if (column.columnId.endsWith("Stock")) return false;
    if (item[column.columnId] > 0) return true;
    return false;
  }

  clickHistCell(item: Object, column: TableColumnDef) {
    if (!this.isHistClickable(item, column)) return;

    this.selectedHistCell = {
      storeCd: item["storeCd"],
      storeName: item["storeName"],
      stockDate: column.columnId
    }
    this.historyDetailCond();
    this.sectionHistoryDetail.openSectionBody();
    this.setHeight();
  }

  clickHistCellStock(item: Object, column: TableColumnDef) {
    if (!this.isHistClickableStock(item, column)) return;

    this.selectedHistCell = {
      storeCd: item["storeCd"],
      storeName: item["storeName"],
      stockDate: column.columnId.substr(0, 10)
    }
    this.historyDetailCond();
    this.sectionHistoryDetail.openSectionBody();

    this.setHeight();
  }

  historyDetailCond() {
    this.selectedHistDetail = { ...this.selectedHistCell };
    this.histDetailList = undefined;

    if (this.ctgSelectComponent) {
      this.ctgSelectComponent.setStoreCd(this.selectedHistDetail.storeCd);
    } else {
      setTimeout(() => { this.ctgSelectComponent.setStoreCd(this.selectedHistDetail.storeCd); }, 0);
    }
  }

  isDeleteDisable() {
    if (!this.selectedHistCell) return true;
    if (Date.parse(this.selectedHistCell.stockDate) < Date.parse(this.commonService.formatDate(this.deleteDateMin))) return true;
    return false;
  }

  deleteHistory() {
    let msg = "次の棚卸情報を削除しますか？<br>";

    msg += "<br><table class='no-border'>";
    msg += "<tr class='no-border'>";
    msg += "<td class='no-border'>店舗：　</td>" + "<td class='no-border'>" + this.selectedHistCell.storeName + "</td>";
    msg += "</tr>";
    msg += "<tr class='no-border'>";
    msg += "<td class='no-border'>在庫日付：　</td>" + "<td class='no-border'>" + this.selectedHistCell.stockDate + "</td>";
    msg += "</tr>";
    msg += "</table>";

    this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      data => {
        this.subscriptionConfirm = undefined;
        if (data == true) {
          let reqDelete: ReqCsvInventoryHistoryDelete = {
            access: { ...this.commonService.loginUser },
            csvInventoryHistoryDelete: {
              storeCdFv: this.selectedHistCell.storeCd,
              dateFv: this.selectedHistCell.stockDate
            },
            csvInventoryHistorySearch: {
              dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
              dateEndFv: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US')
            }
          }

          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          this.subscriptionSearch = this.httpBasic.deleteCsvInventoryHistory(reqDelete).subscribe(
            (data) => {this.receiveHistory(data);},
            (error) => {
              this.clearProgressState();
              this.httpBasic.handleError(error);
            }
          )
        }
      }
    )
  }

  deleteHistoryDetail(rec: HistoryDetailRec) {
    let level = rec.ctgLevel;
    let msg = "次の棚卸情報を削除しますか？<br>";

    msg += "<br><table class='no-border'>";
    msg += "<tr class='no-border'>";
    msg += "<td class='no-border'>店舗：　</td>" + "<td class='no-border'>" + this.selectedHistCell.storeName + "</td>";
    msg += "</tr>";
    msg += "<tr class='no-border'>";
    msg += "<td class='no-border'>在庫日付：　</td>" + "<td class='no-border'>" + this.selectedHistCell.stockDate + "</td>";
    msg += "</tr>";
    for (let i = 0; i <= level + 1; i++) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>" + this.commonService.literal["ctg" + i + "Name"] + "：　</td>";
      msg += "<td class='no-border'>" + rec["ctgName" + i] + "</td>";
      msg += "</tr>";
    }
    msg += "</table>";

    this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      data => {
        this.subscriptionConfirm = undefined;
        if (data == true) {
          let reqDelete: ReqCsvInventoryHistoryDetailDelete = {
            access: { ...this.commonService.loginUser },
            csvInventoryHistoryDetailDelete: {
              storeCdFv: this.selectedHistCell.storeCd,
              dateFv: this.selectedHistCell.stockDate,
              ctgLevelFn: rec.ctgLevel,
              ctg0CdFv: rec.ctgCd0,
              ctg1CdFv: rec.ctgCd1,
              ctg2CdFv: rec.ctgCd2,
              ctg3CdFv: rec.ctgCd3,

            },
            csvInventoryHistoryDetailSearch: {
              dateFv: formatDate(this.selectedHistDetail.stockDate, 'yyyy/MM/dd', 'en_US'),
              storeCdFv: this.selectedHistDetail.storeCd,
              ctg0CdFv: this.ctgSelectCondition.formGroup.get("ctgCd0").value,
              ctg1CdFv: this.ctgSelectCondition.formGroup.get("ctgCd1").value,
              ctg2CdFv: this.ctgSelectCondition.formGroup.get("ctgCd2").value,
              ctg3CdFv: this.ctgSelectCondition.formGroup.get("ctgCd3").value,
              ctgLevelFn: this.ctgSelectCondition.formGroup.get("ctgLevel").value,
            }
          }

          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          this.subscriptionSearch = this.httpBasic.deleteCsvInventoryHistoryDetail(reqDelete).subscribe(
            (data) => {
              this.receiveHistoryDetail(data);
              if (!this.httpBasic.handleAppError(data)) {
                this.isBlockRefreshHistorySelected = true;
                this.getHistory();
              }
            },
            (error) => {
              this.clearProgressState();
              this.httpBasic.handleError(error);
            }
          )
        }
      }
    )
  }

  historyDetailSearch() {
    this.histDetailList = [];
    this.historyDetailColumn();

    this.searchHistoryDetail();
  }

  receiveHistoryDetail(response: RspCsvInventoryHistoryDetailSearch) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    this.histDetailList = [];
    response.result.forEach(resultRow => {
      if (resultRow.ctg0CdFv && resultRow.ctg0CdFv.trim() !== '')
        resultRow.ctg0NameFv = resultRow.ctg0CdFv + ":" + resultRow.ctg0NameFv;
      if (resultRow.ctg1CdFv && resultRow.ctg1CdFv.trim() !== '')
        resultRow.ctg1NameFv = resultRow.ctg1CdFv + ":" + resultRow.ctg1NameFv;
      if (resultRow.ctg2CdFv && resultRow.ctg2CdFv.trim() !== '')
        resultRow.ctg2NameFv = resultRow.ctg2CdFv + ":" + resultRow.ctg2NameFv;
      if (resultRow.ctg3CdFv && resultRow.ctg3CdFv.trim() !== '')
        resultRow.ctg3NameFv = resultRow.ctg3CdFv + ":" + resultRow.ctg3NameFv;
      this.histDetailList.push(new HistoryDetailRec(resultRow));
    })

    this.setHeight();
  }

  historyDetailColumn() {
    this.histDetailColumnIds = [];

    if (!this.isDeleteDisable()) this.histDetailColumnIds.push("deleteItem");

    let maxDisplayLevel = this.ctgSelectCondition.formGroup.get("ctgLevel").value;
    if (maxDisplayLevel < 0) maxDisplayLevel = -1;
    maxDisplayLevel++;
    if (maxDisplayLevel >= this.commonService.config.maxCtgLevel) maxDisplayLevel = this.commonService.config.maxCtgLevel - 1;

    for (let col of this.histDetailColumnDefs) {
      if (col.columnId === "ctgName0" && maxDisplayLevel < 0) continue;
      if (col.columnId === "ctgName1" && maxDisplayLevel < 1) continue;
      if (col.columnId === "ctgName2" && maxDisplayLevel < 2) continue;
      if (col.columnId === "ctgName3" && maxDisplayLevel < 3) continue;
      this.histDetailColumnIds.push(col.columnId);
    }
  }
}
