import { RspOrderStopTermSuppInitDto } from 'src/app/response/rsp-order-stop-term-supp';


export class OrderStopTermSuppRec {
    dateTypeFn: number;
    dateBeginFv: string;
    dateEndFv: string;
    storeCdFv: string;
    storeNameFv: string;
    suppCdFv: string;
    suppNameFv: string;

    storeCd: string;
    suppName: string;
    dateType: string;
    dateBegin: string;
    dateEnd: string;
    constructor(rspOrderStopTermSuppInitDto: RspOrderStopTermSuppInitDto) {
        for (var key of Object.keys(rspOrderStopTermSuppInitDto)) {
            this[key] = rspOrderStopTermSuppInitDto[key];
        }

        if (this.storeCdFv != null)
            this.storeCd = this.storeCdFv + ":" + this.storeNameFv;
        if (this.suppCdFv != null)
            this.suppName = this.suppCdFv + ":" + this.suppNameFv;
        if (this.dateBeginFv != null)
            this.dateBegin = this.dateBeginFv;
        if (this.dateEndFv != null)
            this.dateEnd = this.dateEndFv;
        this.setDateType(this.dateTypeFn)

    }

    setDateType(type: number) {
        if (type == 1) {
            this.dateType = "発注日";
        } else {
            this.dateType = "納品日";
        }
    }
}

