import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SpinnerData {
  title : string;
  message : string;
};

@Component({
  selector: 'app-spinner-dialog',
  templateUrl: './spinner-dialog.component.html',
  styleUrls: ['./spinner-dialog.component.css']
})
export class SpinnerDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SpinnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SpinnerData) {

     }

  ngOnInit() {
  }

}
