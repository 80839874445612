import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ItemImageDataRec, ItemImageRec } from '../0_def/imageDbDefs';
import { ReqGetItemImage, ReqItemImageViewList, RspGetItemImage, RspItemImageViewList } from 'src/app/webservice/imageDb';
import { MatPaginator } from '@angular/material/paginator';
import * as JSZip from 'jszip';

@Component({
  selector: 'app-image-db-view',
  templateUrl: './image-db-view.component.html',
  styleUrls: ['./image-db-view.component.css']
})
export class ImageDbViewComponent implements OnInit, OnDestroy {

  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public formItemCode: FormControl = new FormControl("");
  public formItemName: FormControl = new FormControl("");
  public formDlTarget: FormControl = new FormControl("1");
  public formDlOutput: FormControl = new FormControl("1");

  private itemMaxRows: number = 1000;
  public items: ItemImageRec[] = [];
  public itemsShow: ItemImageRec[] = [];
  public recordCount: number = 0;
  public columnIdsItems: string[] = ["check", "itemCd", "itemName", "itemStandard", "image"];
  public columnDefsItems: TableColumnDef[] = [
    // {columnId: "check", header: "選択", width: 40, align: "center"},
    {columnId: "itemCd", header: "商品コード", width: 100, align: "center"},
    {columnId: "itemName", header: "商品名", width: 300},
    {columnId: "itemStandard", header: "規格", width: 150},
    // {columnId: "image", header: "主画像", width: 100},
  ];
  public allChecked: boolean = false;
  public selectedItem: ItemImageRec = undefined;
  public downloadItems: ItemImageRec[];

  public columnIdsImages: string[] = ["downld", "keyForShare", "image"];
  private myKeyForShare: string;

  @ViewChild("condSectionHeader", {static: true}) condSectionHeader: SectionHeaderComponent;
  @ViewChild(MatPaginator, { static: true }) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) {
    this.myKeyForShare = this.commonService.config.imageDb.keyForShare;
  }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

  }

  ngOnDestroy(): void {
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    this.setTableHeightItem();
    this.setTableHeightImage();
  }

  setTableHeightItem() {
    let id = "item-table-box";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight == undefined) return;
    let paginatorHeight = 56;
    let margin = 10 + 5;
    let sectionHeader = 19;
    let dlSection = 70;
    let height = remHeight - paginatorHeight - margin - sectionHeader - dlSection;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem == undefined) return;
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  setTableHeightImage() {
    let id = "image-table-box";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight == undefined) return;
    let paginatorHeight = 0;
    let margin = 10;
    let sectionHeader = 0;
    let dlSection = 0;
    let height = remHeight - paginatorHeight - margin - sectionHeader - dlSection;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem == undefined) return;
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  doQuery() {
    let ctg = this.ctgSelectCondition.getCurrentSelection();

    let request: ReqItemImageViewList = {
      access: this.commonService.loginUser,
      ctgLevel: ctg.ctgLevel,
      ctgCd0: ctg.ctgCd0,
      ctgCd1: ctg.ctgCd1,
      ctgCd2: ctg.ctgCd2,
      ctgCd3: ctg.ctgCd3,
      itemCd: this.formItemCode.value,
      itemName: this.formItemName.value,
      page: {
        pageNum:  0,
        dispNum:  this.itemMaxRows + 1
      }
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemImageViewList", request).subscribe(
      (response: RspItemImageViewList) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveQuery(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveQuery(response: RspItemImageViewList) {
    if (this.httpBasic.handleAppError(response)) return;

    this.items = [];
    for (let i = 0; i < response.rows.length && i < this.itemMaxRows; i++) {
      let dto = response.rows[i];
      let item: ItemImageRec = {
        itemCd:                 dto.itemCd,
        itemName:               dto.itemName,
        itemStandard:           dto.itemStandard,
        width:                  dto.width,
        height:                 dto.height,
        depth:                  dto.depth,
        weight:                 dto.weight,
        imageData:              undefined,
        onMaster:               dto.itemName !== "" ? true : false,
        mainImageKeyForShare:   dto.mainImageKeyForShare,
        mainImageId:            dto.mainImageId,
        mainImageThumbnail:     dto.mainImageThumbnail,
        check :                 new FormControl(false)
      };
      if (!item.onMaster) {
        item.itemName = "商品マスタに存在しない商品です"
      }
      this.items.push(item);
    }
    this.recordCount = this.items.length;

    if (response.rows.length > this.itemMaxRows) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,
        `${this.itemMaxRows}件を超える商品が検索されました。最初の${this.itemMaxRows}件のみが処理対象となります。`);
    }
    this.matPagenator.pageIndex = 0;
    this.selectedItem = undefined;
    this.pageChanged();

    this.condSectionHeader.closeSectionBody();
  }

  pageChanged() {
    this.allChecked = false;
    this.selectedItem = undefined;
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenator.pageIndex * this.matPagenator.pageSize; i < this.recordCount && count < this.matPagenator.pageSize; i++) {
      this.items[i].check.setValue(false);
      this.itemsShow.push(this.items[i]);
      count++;
    }

    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  allCheck(value: boolean) {
    this.itemsShow.forEach((rec) => rec.check.setValue(value));
    this.allChecked = value;
  }

  updateAllChecked() {
    this.allChecked = this.itemsShow.every((rec) => rec.check.value);
  }

  someChecked() {
    let cntTrue = 0;
    let cntFalse = 0;
    this.itemsShow.forEach((rec) => {
      if (rec.check.value) {
        cntTrue++;
      } else {
        cntFalse++;
      }
    });
    return cntTrue > 0 && cntFalse > 0;
   }

   selectItem(item: ItemImageRec) {
    this.selectedItem = item;
    if (!item.imageData) {
      this.queryItem(item);
    }
   }

   queryItem(item: ItemImageRec, nextIndex?: number) {
    let request: ReqGetItemImage = {
      access: this.commonService.loginUser,
      itemCd: item.itemCd
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "画像取得中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemImage", request).subscribe(
      (response: RspGetItemImage) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.ReceiveQueryItem(response, item, nextIndex);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  ReceiveQueryItem(response: RspGetItemImage, item: ItemImageRec, nextIndex?: number) {
    if (this.httpBasic.handleAppError(response)) return;

    item.imageData = [];
    response.itemImage.imageData.forEach((dto) => {
      let rec: ItemImageDataRec = {
        keyForShare:    dto.keyForShare,
        imageId:        dto.imageId,
        itemCd:         dto.itemCd,
        mainImage:      (response.itemImage.mainImageKeyForShare === dto.keyForShare &&
                         response.itemImage.mainImageId === dto.imageId) ? true : false,
        image:          dto.image,
        thumbnail:      dto.thumbnail
      };
      item.imageData.push(rec);
    });
    this.sortImages(item);
    if (nextIndex !== undefined) {
      this.getNextImage(nextIndex);
    }
  }

  sortImages(item: ItemImageRec) {
    item.imageData.sort((a, b) => {
      if (a.keyForShare === this.myKeyForShare) {
        if (b.keyForShare !== this.myKeyForShare) return -1
        return 0;
      }
      if (b.keyForShare === this.myKeyForShare) {
        return 1;
      }
      return 0;
    });
    let tmp = [...item.imageData];
    item.imageData = tmp;
  }

  downloadSingeImage(item: ItemImageDataRec) {
    let ftype = item.image.split(";")[0].substring(11);
    let index = this.selectedItem.imageData.findIndex((rec) => rec == item);
    let a = document.createElement("a");
    a.href = item.image;
    a.download = item.itemCd + "-" + (index + 1) + "." + ftype;
    a.click();
  }

  downloadImages() {
    this.downloadItems = this.items.filter((rec) => rec.check.value);
    this.getNextImage(0);
  }

  getNextImage(nextIndex: number) {
    for (let i = nextIndex; i < this.downloadItems.length; i++) {
      if (!this.downloadItems[i].imageData) {
        this.queryItem(this.downloadItems[i], i + 1);
        return;
      }
    }

    this.doDownload();
  }

  doDownload() {
    let outputMode = this.formDlOutput.value;
    let zip = new JSZip();
    this.downloadItems.forEach((item) => {
      for (let i = 0; i < item.imageData.length; i++) {
        let imageData = item.imageData[i];
        let target = this.formDlTarget.value;
        let doDownload = false;
        switch(target) {
          case "1": {
            // すべて
            doDownload = true;
            break;
          };
          case "2": {
            // 主画像のみ
            if (imageData.mainImage) doDownload = true;
            break;
          };
          case "3": {
            // 自社登録のみ
            if (imageData.keyForShare === this.myKeyForShare) doDownload = true;
            break;
          };
        }
        if (doDownload) {
          let ftype = imageData.image.split(";")[0].substring(11);
          let fname = item.itemCd + "-" + (i + 1) + "." + ftype;
          if (outputMode === "1") {
            // Zip
            zip.file(fname, imageData.image.replace(/^data:image\/(png|jpeg);base64,/, ''),  {base64: true});
          } else if (outputMode === "2") {
            // 個別ファイル
            let a = document.createElement("a");
            a.href = imageData.image;
            a.download = fname;
            a.click();
          }
        }
      }
    });
    if (outputMode === "1") {
      this.commonService.openSpinner(this.commonService.pageTitle, "ZIP作成中・・・");
      zip.generateAsync({type: 'base64', compression: 'DEFLATE'}).then(base64 => {
        this.commonService.closeSpinner();
        const a = document.createElement('a');
        a.href = `data:application/zip;base64,${base64}`;
        a.download = 'images.zip';
        a.style.display = 'none';
        a.click();
      });
    }
  }
}
