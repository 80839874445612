import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RoleMenu } from './role-menu';
import { Role } from './role';
import { Subscription, Observable } from 'rxjs';
import { RspGetRole } from 'src/app/response/rsp-get-role';
import { RspUpdRole } from 'src/app/response/rsp-upd-role';

const UPDROLE_MODE_INSERT: number = 1;
const UPDROLE_MODE_UPDATE: number = 2;
const UPDROLE_MODE_DELETE: number = 3;

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit, OnDestroy {

  public isReadonly: boolean;
  public roles: Role[] = [];
  public selectedRole: Role;
  public roleFormGroup: FormGroup;
  public roleMenuList: RoleMenu[] = [];
  public displayColumns: string[] = ["isSelected", "menuGroupName", "menuName", "menuReadonly"];

  private subscriptionGetRole: Subscription;
  private subscriptionUpdRole: Subscription;
  // @ViewChild("roleMenuTable", {static: false}) private roleMenuTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder) {

    let fg = new FormGroup({});
    for (let roleMenu of this.allRoleMenus(-1)) {
      fg.registerControl("sel"+roleMenu.menuId, new FormControl(false));
      fg.registerControl("ro"+roleMenu.menuId, new FormControl(true));
    }

    this.roleFormGroup = fb.group({
    roleName: [, Validators.required],
    roleComment: [],
    trgtStore: [],
    roleLevel: [,
      [Validators.required,
       (control) => {
                    /*
                    if (parseInt(control.value) < this.commonService.loginUser.roleLevel) {
                      return {"levError": "権限エラー"};
                    }
                    */
                   if (parseInt(control.value) < this.minRoleLevel()) {
                    return {"levError": "権限エラー"};
                  }
                  return null;
                  }
      ]
    ],
    roleMenus: fg
    });
  }

  ngOnInit() {
    // this.commonService.pageTitle = "役割管理";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("role");

    this.commonService.openSpinner(this.commonService.pageTitle, "初期化中・・・");
    this.subscriptionGetRole = this.httpBasic.getRole().subscribe(
      (response) => this.receiveRole(response),
      (error) => this.httpBasic.handleError(error)
    );

    this.roleFormGroup.disable();
  }

  ngOnDestroy() {
    if (this.subscriptionGetRole) {
      this.subscriptionGetRole.unsubscribe();
    }
    if (this.subscriptionUpdRole) {
      this.subscriptionUpdRole.unsubscribe();
    }
  }

  minRoleLevel() {
    if (this.commonService.loginUser.roleLevel > 0) {
      return this.commonService.loginUser.roleLevel;
    }
    return 1;
  }

  clearProgressStateGetRole() {
    this.commonService.closeSpinner();
    this.subscriptionGetRole.unsubscribe();
    this.subscriptionGetRole = undefined;
  }
  clearProgressStateUpdRole() {
    this.commonService.closeSpinner();
    this.subscriptionUpdRole.unsubscribe();
    this.subscriptionUpdRole = undefined;
  }

  receiveRole(response: RspGetRole) {
    this.clearProgressStateGetRole();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    for (let rspRole of response.roles) {
      let role = new Role();
      role.roleId = rspRole.roleId;
      role.roleName = rspRole.roleName;
      role.trgetStoreFlag = rspRole.trgetStoreFlag;
      role.roleLevel = rspRole.roleLevel;
      role.roleComment = rspRole.roleComment;
      role.version = rspRole.version;
      role.userCount = rspRole.userCount;
      let roleMenus = this.allRoleMenus(role.roleId);
      role.roleMenus = roleMenus;
      for (let rspRoleMenu of rspRole.roleMenus) {
        this.setRoleMenuSelect(roleMenus, rspRoleMenu.menuId, true, 
          rspRoleMenu.roleReadonlyFlag > 0 ? true : false);
      }
      this.roles.push(role);
    }
    if (this.roles.length > 0) {
      this.selectRole(this.roles[0]);
    }
  }

  clickRole(role: Role) {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.selectRole(role);
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          if (this.selectedRole && this.selectedRole.roleId < 0) {
            this.deleteCurrentRole();
          }
          this.selectRole(role);
        }
      }
    );
  }

  selectRole(role: Role) {
    this.selectedRole = role;

    this.roleFormGroup.get("roleName").setValue(role.roleName);
    this.roleFormGroup.get("roleComment").setValue(role.roleComment);
    this.roleFormGroup.get("trgtStore").setValue("" + role.trgetStoreFlag);
    this.roleFormGroup.get("roleLevel").setValue("" + role.roleLevel);
    this.roleMenuList = [];
    for (let roleMenu of role.roleMenus) {
      this.roleMenuList.push(roleMenu);
      this.roleFormGroup.get("roleMenus").get("sel"+roleMenu.menuId).setValue(roleMenu.isSelected);
      this.roleFormGroup.get("roleMenus").get("ro"+roleMenu.menuId).setValue(roleMenu.roleReadonly);
    }

    this.roleFormGroup.markAsPristine();
    this.roleFormGroup.enable();
    if (this.commonService.stores.length <= 1) {
      this.roleFormGroup.get("trgtStore").disable();
    }
  }

  setStorePriv(priv: string) {
    if (this.commonService.stores.length <= 1) return;
    switch(priv) {
      case "全店舗":
        this.roleFormGroup.get("trgtStore").setValue("1");
        break;
      case "自店舗":
        this.roleFormGroup.get("trgtStore").setValue("0");
        break;
    }
  }

  allRoleMenus(roleId: number) : RoleMenu[] {
    var roleMenus: RoleMenu[] = [];

    for (let menuGroup of this.commonService.menuGroups) {
      for (let menuItem of menuGroup.menuItems) {
        let roleMenu = new RoleMenu();
        roleMenu.roleId = roleId;
        roleMenu.isSelected = false;
        roleMenu.menuGroupId = menuGroup.menuGroupId;
        roleMenu.menuGroupName = menuGroup.menuGroupName;
        roleMenu.menuId = menuItem.menuId;
        roleMenu.menuName = menuItem.menuName;
        roleMenu.menuReadonlyControl = menuItem.menuReadonly > 0 ? false : true;
        roleMenu.menuReadonly = menuItem.isReadonly > 0 ? true : false;
        roleMenu.roleReadonly = false;          // true;
        roleMenus.push(roleMenu);
      }
    }

    return roleMenus;
  }

  setRoleMenuSelect(roleMenus: RoleMenu[], menuId: number, selection: boolean, roleReadonly: boolean) {
    for (let roleMenu of roleMenus) {
      if (roleMenu.menuId == menuId) {
        roleMenu.isSelected = selection;
        roleMenu.roleReadonly = roleReadonly;
        return;
      }
    }
  }

  copyRole(role: Role) : Role {
    let roleNew = new Role();

    roleNew.roleId = -1;
    roleNew.roleName = "新規役割";
    roleNew.roleComment = "";
    roleNew.trgetStoreFlag = role.trgetStoreFlag;
    roleNew.roleLevel = role.roleLevel;
    roleNew.version = 1;
    roleNew.userCount = 0;
    roleNew.roleMenus = [];
    for (let roleMenu of role.roleMenus) {
      let roleMenuNew = new RoleMenu();
      roleMenuNew.roleId = roleNew.roleId;
      roleMenuNew.menuGroupId = roleMenu.menuGroupId;
      roleMenuNew.menuGroupName = roleMenu.menuGroupName;
      roleMenuNew.menuId = roleMenu.menuId;
      roleMenuNew.menuName = roleMenu.menuName;
      roleMenuNew.menuReadonlyControl = roleMenu.menuReadonlyControl;
      roleMenuNew.menuReadonly = roleMenu.menuReadonly;
      roleMenuNew.isSelected = roleMenu.isSelected;
      roleMenuNew.roleReadonly = roleMenu.roleReadonly;
      roleNew.roleMenus.push(roleMenuNew);
    }

    return roleNew;
  }

  newRole() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.newRoleBody();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          if (this.selectedRole && this.selectedRole.roleId < 0) {
            this.deleteCurrentRole();
          }
          this.newRoleBody();
        }
      }
    );
  }

  newRoleBody() {
    let role = undefined;
    if (this.selectedRole) {
      role = this.copyRole(this.selectedRole);
    }
    if (role == undefined) {
      role = new Role();

      role.roleId = -1;
      role.roleName = "新規役割";
      role.roleLevel = this.commonService.loginUser.roleLevel;
      role.roleComment = "";
      role.trgetStoreFlag = "0";    // role.trgetStoreFlag;
      role.version = 1;
      role.userCount = 0;
      role.roleMenus = this.allRoleMenus(role.roleId);
    }
    this.roles.push(role);
    this.selectRole(role);
    this.roleFormGroup.markAsDirty();
  }

  deleteCurrentRole() {
    let found = false;
    let index = 0;
    for (index = 0; index < this.roles.length; index++) {
      if (this.roles[index].roleId == this.selectedRole.roleId) {
        found = true;
        break;
      }
    }
    if (found == false) return;
    if (this.roles[index].roleId < 0) {
      this.afterDeleteRole(index);
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
    this.subscriptionUpdRole = this.httpBasic.updRole(UPDROLE_MODE_DELETE, this.roles[index]).subscribe(
      (response) => this.receiveDeleteRole(response, index),
      (error) => {
        this.clearProgressStateUpdRole();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDeleteRole(response: RspUpdRole, index: number) {
    this.clearProgressStateUpdRole();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.afterDeleteRole(index);
  }

  afterDeleteRole(index: number) {
    this.roles.splice(index, 1);
    if (this.roles.length > 0) {
      this.selectRole(this.roles[0]);
    } else {
      this.selectedRole = undefined;
      this.roleMenuList = [];
      this.roleFormGroup.get("roleName").setValue("");
      this.roleFormGroup.get("roleComment").setValue("");
      this.roleFormGroup.get("trgtStore").setValue("");
      this.roleFormGroup.removeControl("roleMenus");
      this.roleFormGroup.registerControl("roleMenus", new FormGroup({}));
      this.roleFormGroup.markAsPristine();
      this.roleFormGroup.disable();
    }
  }

  updateRole() {
    let mode = this.selectedRole.roleId < 0 ? UPDROLE_MODE_INSERT : UPDROLE_MODE_UPDATE;

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");

    let role = new Role();
    role.roleId = this.selectedRole.roleId;
    role.roleName = this.roleFormGroup.get("roleName").value;
    role.roleComment = this.roleFormGroup.get("roleComment").value;
    if (!role.roleComment) role.roleComment = "";
    role.trgetStoreFlag = parseInt(this.roleFormGroup.get("trgtStore").value);
    role.roleLevel = parseInt(this.roleFormGroup.get("roleLevel").value);
    role.version = this.selectedRole.roleLevel;
    role.roleMenus = this.allRoleMenus(this.selectedRole.roleId);
    for (let roleMenu of role.roleMenus) {
      roleMenu.isSelected = this.roleFormGroup.get("roleMenus").get("sel"+roleMenu.menuId).value;
      roleMenu.roleReadonly = this.roleFormGroup.get("roleMenus").get("ro"+roleMenu.menuId).value;
    }

    this.subscriptionUpdRole = this.httpBasic.updRole(mode, role).subscribe(
      (response) => this.receiveUpdateRole(response),
      (error) => {
        this.clearProgressStateUpdRole();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpdateRole(response: RspUpdRole) {
    this.clearProgressStateUpdRole();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    let role = this.selectedRole;
    let rspRole = response.role;
    role.roleId = rspRole.roleId;
    role.roleName = rspRole.roleName;
    role.roleComment = rspRole.roleComment;
    role.trgetStoreFlag = rspRole.trgetStoreFlag;
    role.roleLevel = rspRole.roleLevel;
    role.version = rspRole.version;
    for (let roleMenu of role.roleMenus) {
      roleMenu.isSelected = false;
      roleMenu.roleReadonly = true;
    }
    for (let roleMenuDto of rspRole.roleMenus) {
      this.setRoleMenuSelect(role.roleMenus, roleMenuDto.menuId, true, 
        roleMenuDto.roleReadonlyFlag > 0 ? true : false);
    }

    this.roleFormGroup.markAsPristine();
  }

  cancelRole() {
    if (this.selectedRole.roleId < 0) {
      this.deleteCurrentRole();
      return;
    }

    this.selectRole(this.selectedRole);
  }

  canDeactivate() {
    if (!this.roleFormGroup.dirty) return true;

    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

}
