import { Component } from '@angular/core';
import { CommonService } from './service/common.service';
import { config } from '../assets/config.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bljweb';
  constructor(){
    console.log(config.myResourceDir);
  }
}
