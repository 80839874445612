import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RackSelectCondition, RackSelectComponent } from 'src/app/partsCommon/rack-select/rack-select.component';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ItemByJanListDto, RspItemByJanList } from 'src/app/response/rsp-item-by-jan-list';
import { Subscription } from 'rxjs';
import { RspRackAdd } from 'src/app/response/rsp-rack-add';
import { RackSearchDto } from 'src/app/response/rsp-rack-search';
import { RspRackEdit } from 'src/app/response/rsp-rack-edit';
import { RspRackItem } from 'src/app/response/rsp-rack-item';
import { RspRackItemSearch } from 'src/app/response/rsp-rack-item-search';
import { MatTable } from '@angular/material/table';
import { RspRackRemove } from 'src/app/response/rsp-rack-remove';

@Component({
  selector: 'app-rack',
  templateUrl: './rack.component.html',
  styleUrls: ['./rack.component.css']
})
export class RackComponent implements OnInit, OnDestroy {

  public isReadonly: boolean = false;
  public storeForm: FormControl;
  public rackSelectCondition : RackSelectCondition = new RackSelectCondition(this.fb);
  public tabSelection: number = 0;

  public formJanList: FormControl = new FormControl({value: "", disabled: true});
  public formGroupAdd: FormGroup;
  public formGroupEdit: FormGroup;
  public tableWidth: any;
  // public pagenatorMaxWidth: any;
  public displayColumnIds: string[] = ["itemCdFv", "itemNameFv", "standardFv"];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'itemCdFv', header: '商品コード', width: 90, align: 'center' },
    { columnId: 'itemNameFv', header: '商品名', width: 300 },
    { columnId: 'standardFv', header: '規格', width: 150 }
  ];
  public itemList: ItemByJanListDto[];
  // public recordCount: number;
  public itemRetrieved: boolean = false;
  public itemConfirmed: boolean = false;

  private subscriptionStore: Subscription;
  private subscriptionItems: Subscription;
  private subscriptionRack: Subscription;
  private subscriptionConfirm: Subscription;

  @ViewChild(RackSelectComponent, {static: true}) rackSelectComponent: RackSelectComponent;
  @ViewChild("itemListTable", {static: false}) itemListTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    private httpBacsic: HttpBasicService,
    private fb: FormBuilder
  ) {
    this.formGroupAdd = this.fb.group({
      "name1" : [""],
      "name2" : [""],
      "name3" : [""],
    });
    this.formGroupEdit = this.fb.group({
      "name1" : [""],
      "name2" : [""],
      "name3" : [""],
    });

  }

  ngOnInit() {
    this.isReadonly = this.commonService.checkPrivilege("rack");
    // this.commonService.pageTitle = "棚管理";
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.storeForm = new FormControl(this.commonService.loginUser.storeCd);
    // this.rackSelectComponent.setStoreCd(this.commonService.loginUser.storeCd);
    this.subscriptionStore = this.storeForm.valueChanges.subscribe(
      storeCd => this.rackSelectComponent.setStoreCd(storeCd)
    );
    this.formJanList.disable();

    this.calcTableWidth();
    setTimeout(()=>{this.setHeight();}, 0);
  }

  ngOnDestroy() {
    if (this.subscriptionStore) {
      this.subscriptionStore.unsubscribe();
    }
    if (this.subscriptionItems) {
      this.subscriptionItems.unsubscribe();
    }
    if (this.subscriptionRack) {
      this.subscriptionRack.unsubscribe();
    }
    if (this.subscriptionConfirm) {
      this.subscriptionConfirm.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    setTimeout(()=>{this.setHeight();}, 0);
  }

  setHeight() {
    if (this.tabSelection !== 0) return;

    let id = "item-table-container";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 0;
    let margin = 25;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";

    id = "jan-list";
    remHeight = this.commonService.getHeightBelow(id);
    height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;
    elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  calcTableWidth() {
    let width = 1;   // For left border
    for (let colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { 'width': '' + width + 'px' };
    // this.pagenatorMaxWidth = { 'max-width': '' + width + 'px' };
  }

  styleForHeader(id: string) {
    for (let colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          'width': '' + colDef.width + 'px'
        };
      }
    }
  }

  styleFor(id: string) {
    for (let colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          'width': '' + colDef.width + 'px',
          'text-align': colDef.align ? colDef.align : 'left'
        };
      }
    }
  }

  clearProgressState() {
    this.commonService.closeSpinner()
    if (this.subscriptionItems) {
      this.subscriptionItems.unsubscribe();
      this.subscriptionItems = undefined;
    }
    if (this.subscriptionRack) {
      this.subscriptionRack.unsubscribe();
      this.subscriptionRack = undefined;
    }
  }

  rackSelChanged() {
    this.formJanList.setValue("");
    this.formJanList.markAsPristine();
    this.itemList = [];
    this.itemRetrieved = false;
    this.formJanList.disable();
  }

  getRackItems() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    this.subscriptionRack = this.httpBacsic.rackItemSearch(
      this.storeForm.value,
      this.rackSelectCondition.formGroup.get("rackLevel").value,
      this.rackSelectCondition.formGroup.get("rackCd1").value,
      this.rackSelectCondition.formGroup.get("rackCd2").value,
      this.rackSelectCondition.formGroup.get("rackCd3").value,
    ).subscribe(
      response => this.receiveRackItemSearch(response),
      error => {
        this.commonService.closeSpinner();
        this.httpBacsic.handleError(error);
      }
    );
  }

  receiveRackItemSearch(response: RspRackItemSearch) {
    this.commonService.closeSpinner();
    if (this.httpBacsic.handleAppError(response)) return;

    let janList: string = "";
    this.itemList = [];
    for (let item of response.result) {
      this.itemList.push({notFound: false, ...item});
      janList += item.itemCdFv + "\n"; 
    }

    this.formJanList.setValue(janList);
    this.formJanList.markAsPristine();
    this.itemRetrieved = true;
    this.itemConfirmed = false;
    this.formJanList.enable();
  }

  clearJanList() {
    this.formJanList.setValue("");
    this.formJanList.markAsPristine();
    this.itemList = [];
    this.itemConfirmed = true;
  }

  getItemByJan() {
    let janData : string = this.formJanList.value;
    let lines = janData.split("\n");
    let janList : string[] = [];

    for (let line of lines) {
      line = line.replace("\r", "");
      line = line.replace(/ /g, "");
      line = line.replace(/　/g, "");   /* 全角スペース */
      if (line != "" && !janList.find(element=> element == line)) {
        janList.push(line);
      }
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let withoutCase = true;
    if (this.commonService.config.rackCaseItem) withoutCase = false;
    this.subscriptionItems = this.httpBacsic.getItemByJanList(this.storeForm.value, janList, withoutCase).subscribe(
      (response) => this.receiveItems(response),
      (error) => {
        this.clearProgressState();
        this.httpBacsic.handleError(error);
      }
    );
  }

  receiveItems(response: RspItemByJanList) {
    this.clearProgressState();
    if (this.httpBacsic.handleAppError(response)) return;

    this.itemList = [];
    for (let item of response.items) {
      if (item.notFound) {
        item.itemNameFv = "商品が見つかりません";
      }
      this.itemList.push(item);
    }
    this.itemConfirmed = true;
    this.formJanList.markAsPristine();
  }

  rackItemEntry() {
    let itemCds: string[] = [];

    for (let item of this.itemList) {
      if (!item.notFound) {
        itemCds.push(item.itemCdFv);
      }
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");

    this.subscriptionRack = this.httpBacsic.rackItem(
      this.storeForm.value,
      3,
      this.rackSelectCondition.getCd1(),
      this.rackSelectCondition.getCd2(),
      this.rackSelectCondition.getCd3(),
      itemCds
    ).subscribe(
      (response) => this.receiveRackItemEntry(response),
      (error) => {
          this.clearProgressState();
          this.httpBacsic.handleError(error);
      }     
    );
  }

  receiveRackItemEntry(response: RspRackItem) {
    this.clearProgressState();
    if (this.httpBacsic.handleAppError(response)) return;

    for (let i = this.itemList.length - 1; i >= 0 ; i--) {
      if (this.itemList[i].notFound) {
        this.itemList.splice(i, 1);
      }
    }
    this.itemListTable.renderRows();

    let janList = "";
    for (let item of this.itemList) {
      janList += item.itemCdFv + "\n";
    }
    this.formJanList.setValue(janList);
    this.formJanList.markAsPristine();
    this.itemConfirmed = false;
  }

  addRack(level: number) {
    let name: string;
    if (level == 1) {
      name = this.formGroupAdd.get("name1").value;
    } else if (level == 2) {
      name = this.formGroupAdd.get("name2").value;
    } else {
      name = this.formGroupAdd.get("name3").value;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");

    this.subscriptionRack = this.httpBacsic.rackAdd(
      this.storeForm.value,
      level,
      this.rackSelectCondition.getCd1(),
      this.rackSelectCondition.getCd2(),
      this.rackSelectCondition.getCd3(),
      name
    ).subscribe(
      (response) => this.receiveRackAdd(response),
      (error) => {
          this.clearProgressState();
          this.httpBacsic.handleError(error);
      }     
    );

  }

  receiveRackAdd(response: RspRackAdd) {
    this.clearProgressState();
    if (this.httpBacsic.handleAppError(response)) return;

    this.rackSelectComponent.addRack(response.result)
  }

  editRack(level: number) {
    let name: string;
    if (level == 1) {
      name = this.formGroupEdit.get("name1").value;
    } else if (level == 2) {
      name = this.formGroupEdit.get("name2").value;
    } else {
      name = this.formGroupEdit.get("name3").value;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");

    this.subscriptionRack = this.httpBacsic.rackEdit(
      this.storeForm.value,
      level,
      this.rackSelectCondition.getCd1(),
      this.rackSelectCondition.getCd2(),
      this.rackSelectCondition.getCd3(),
      name
    ).subscribe(
      (response) => this.receiveRackEdit(response),
      (error) => {
          this.clearProgressState();
          this.httpBacsic.handleError(error);
      }     
    );
  }

  receiveRackEdit(response: RspRackEdit) {
    this.clearProgressState();
    if (this.httpBacsic.handleAppError(response)) return;

    let rackDto: RackSearchDto = response.result;
    let rackData: RackSearchDto[];
    if (rackDto.rackLevelFn == 1) {
      rackData = this.rackSelectCondition.rack1data;
      this.rackSelectCondition.rack1Name = rackDto.rackNameFv;
      this.rackSelectComponent.rack1Name = rackDto.rackNameFv;
    } else if (rackDto.rackLevelFn == 2) {
      rackData = this.rackSelectCondition.rack2data;
      this.rackSelectCondition.rack2Name = rackDto.rackNameFv;
      this.rackSelectComponent.rack2Name = rackDto.rackNameFv;
    } else if (rackDto.rackLevelFn == 3) {
      rackData = this.rackSelectCondition.rack3data;
      this.rackSelectCondition.rack3Name = rackDto.rackNameFv;
      this.rackSelectComponent.rack3Name = rackDto.rackNameFv;
    }

    for (let rack of rackData) {
      if (rackDto.rackCd1Fn == rack.rackCd1Fn &&
          rackDto.rackCd2Fn == rack.rackCd2Fn &&
          rackDto.rackCd3Fn == rack.rackCd3Fn) {
            rack.rackNameFv = rackDto.rackNameFv;
      }
    }
  }

  removeRack() {
    let level = this.rackSelectCondition.formGroup.get("rackLevel").value;
    let msg: string;
    if (level == 1) {
      msg = "棚グループ「 " + this.rackSelectCondition.rack1Name + " 」以下すべてを削除しますか？"
    } else if (level == 2) {
      msg = "棚「 " + this.rackSelectCondition.rack2Name + " 」以下すべてを削除しますか？"
    } else if (level == 3) {
      msg = "段「 " + this.rackSelectCondition.rack3Name + " 」を削除しますか？"
    } else {
      return;
    }

    this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      result => this.removeRackBody(result)
    );
  }

  removeRackBody(result: boolean) {
    this.subscriptionConfirm.unsubscribe();
    this.subscriptionConfirm = undefined;

    if (result == false) return;

    this.commonService.openSpinner(this.commonService.pageTitle, "削除・・・");

    this.subscriptionRack = this.httpBacsic.rackRemove(
      this.storeForm.value,
      this.rackSelectCondition.formGroup.get("rackLevel").value,
      this.rackSelectCondition.getCd1(),
      this.rackSelectCondition.getCd2(),
      this.rackSelectCondition.getCd3()
    ).subscribe(
      (response) => this.receiveRemoveRack(response),
      (error) => {
          this.clearProgressState();
          this.httpBacsic.handleError(error);
      }
    );
  }

  receiveRemoveRack(response: RspRackRemove) {
    this.clearProgressState();
    if (this.httpBacsic.handleAppError(response)) return;

    this.rackSelectCondition.removeRack(
      this.rackSelectCondition.formGroup.get("rackLevel").value,
      this.rackSelectCondition.formGroup.get("rackCd1").value,
      this.rackSelectCondition.formGroup.get("rackCd2").value,
      this.rackSelectCondition.formGroup.get("rackCd3").value
    );
  }

  isEmpty(str: string) : boolean {
    if (str.replace(/ /g, "") == "") return true;
    return false;
  }

  tabChanged(tabNumber: number) {
    this.tabSelection = tabNumber;
    setTimeout(()=>{this.setHeight();}, 0);
  }

}
