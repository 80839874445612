import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { CtgSearchDto, RspCtgSearch } from 'src/app/response/rsp-ctg-search';
import { MatDialogRef } from '@angular/material/dialog';

export class CtgSelectCondition {

  public storeCd: string;
  public formGroup : FormGroup = this.fb.group({
    ctgLevel: [-1],
    ctgCd0: [''],
    ctgCd1: [''],
    ctgCd2: [''],
    ctgCd3: ['']
  });
  ctg0data: CtgSearchDto[] = [];
  ctg1data: CtgSearchDto[] = [];
  ctg2data: CtgSearchDto[] = [];
  ctg3data: CtgSearchDto[] = [];
  ctg0Name: string = "";
  ctg1Name: string = "";
  ctg2Name: string = "";
  ctg3Name: string = "";

  constructor(public fb: FormBuilder) {
    this.ctg0data.push(this.ctgNullData(0));
    this.ctg1data.push(this.ctgNullData(1));
    this.ctg2data.push(this.ctgNullData(2));
    this.ctg3data.push(this.ctgNullData(3));
  }

  ctgNullData(level: number): CtgSearchDto {
    return {
      storeCdFv:"",
      ctgLevelFn:level,
      ctgCd0Fv:"",
      ctgCd1Fv:"",
      ctgCd2Fv:"",
      ctgCd3Fv:"",
      ctgNameFv:""
    };
  }

  getCurrentSelection() : {
    storeCd:	string;
    ctgLevel:	number;
    ctgCd0:	string;
    ctgCd1:	string;
    ctgCd2:	string;
    ctgCd3:	string;
  } {
    return {
      storeCd:  this.storeCd,
      ctgLevel: this.formGroup.get("ctgLevel").value,
      ctgCd0:   this.formGroup.get("ctgCd0").value,
      ctgCd1:   this.formGroup.get("ctgCd1").value,
      ctgCd2:   this.formGroup.get("ctgCd2").value,
      ctgCd3:   this.formGroup.get("ctgCd3").value
    }
  }

  copyTo(cond: CtgSelectCondition) {
    cond.storeCd = this.storeCd;
    cond.formGroup.get("ctgLevel").setValue(this.formGroup.get("ctgLevel").value);
    cond.formGroup.get("ctgCd0").setValue(this.formGroup.get("ctgCd0").value);
    cond.formGroup.get("ctgCd1").setValue(this.formGroup.get("ctgCd1").value);
    cond.formGroup.get("ctgCd2").setValue(this.formGroup.get("ctgCd2").value);
    cond.formGroup.get("ctgCd3").setValue(this.formGroup.get("ctgCd3").value);

    cond.ctg0data = [...this.ctg0data];
    cond.ctg1data = [...this.ctg1data];
    cond.ctg2data = [...this.ctg2data];
    cond.ctg3data = [...this.ctg3data];
    cond.ctg0Name = this.ctg0Name;
    cond.ctg1Name = this.ctg1Name;
    cond.ctg2Name = this.ctg2Name;
    cond.ctg3Name = this.ctg3Name;
  }
}

@Component({
  selector: 'app-ctg-select',
  templateUrl: './ctg-select.component.html',
  styleUrls: ['./ctg-select.component.css']
})
export class CtgSelectComponent implements OnInit, OnDestroy {

  // ctgLevelSubscription: Subscription;
  ctgSubscription: Subscription;
  ctgCd0Subscription: Subscription;
  ctgCd1Subscription: Subscription;
  ctgCd2Subscription: Subscription;
  ctgCd3Subscription: Subscription;

  /*
  ctg0data: CtgSearchDto[] = [this.ctgNullData(0)];
  ctg1data: CtgSearchDto[] = [this.ctgNullData(1)];
  ctg2data: CtgSearchDto[] = [this.ctgNullData(2)];
  ctg3data: CtgSearchDto[] = [this.ctgNullData(3)];
  ctg0Name: string = "";
  ctg1Name: string = "";
  ctg2Name: string = "";
  ctg3Name: string = "";
  */
  ctg0data: CtgSearchDto[];
  ctg1data: CtgSearchDto[];
  ctg2data: CtgSearchDto[];
  ctg3data: CtgSearchDto[];
  ctg0Name: string = "";
  ctg1Name: string = "";
  ctg2Name: string = "";
  ctg3Name: string = "";

  private initialSearch: boolean = true;
  private dialogRef: MatDialogRef<any>;

  @Input() ctgSelectCondition: CtgSelectCondition;
  @Input() searchOnInit: boolean = true;
  @Input() maxSelectableLevel: number;
  @Output() initComp: EventEmitter<any> = new EventEmitter();

  @ViewChild("ctg0Label", {read: ElementRef, static: true}) ctg0Label: ElementRef;

  constructor(
    public commonService: CommonService,
    private httpBasic : HttpBasicService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (!this.maxSelectableLevel) {
      this.maxSelectableLevel = this.commonService.config.maxCtgLevel;
    }
    this.ctg0data = this.ctgSelectCondition.ctg0data;
    this.ctg1data = this.ctgSelectCondition.ctg1data;
    this.ctg2data = this.ctgSelectCondition.ctg2data;
    this.ctg3data = this.ctgSelectCondition.ctg3data;

    this.setupChangeSubsc();
    if (this.searchOnInit && this.ctg0data.length < 2) {
      this.getCtgData(0);
    }
  }

  ngOnDestroy() {
    if (this.ctgSubscription) {
      this.ctgSubscription.unsubscribe();
    }
    this.clearChangeSubsc();
  }

  setupChangeSubsc() {
    this.ctgCd0Subscription = this.ctgSelectCondition.formGroup.get("ctgCd0").valueChanges.subscribe(
      data => this.ctg0Changed(data)
    );
    if (this.commonService.config.maxCtgLevel > 1) {
      this.ctgCd1Subscription = this.ctgSelectCondition.formGroup.get("ctgCd1").valueChanges.subscribe(
        data => this.ctg1Changed(data)
      );
    }
    if (this.commonService.config.maxCtgLevel > 2) {
      this.ctgCd2Subscription = this.ctgSelectCondition.formGroup.get("ctgCd2").valueChanges.subscribe(
        data => this.ctg2Changed(data)
      );
    }
    if (this.commonService.config.maxCtgLevel > 3) {
      this.ctgCd3Subscription = this.ctgSelectCondition.formGroup.get("ctgCd3").valueChanges.subscribe(
        data => this.ctg3Changed(data)
      );
    }
  }

  clearChangeSubsc() {
    if (this.ctgCd0Subscription) {
      this.ctgCd0Subscription.unsubscribe();
    }
    if (this.ctgCd1Subscription) {
      this.ctgCd1Subscription.unsubscribe();
    }
    if (this.ctgCd2Subscription) {
      this.ctgCd2Subscription.unsubscribe();
    }
    if (this.ctgCd3Subscription) {
      this.ctgCd2Subscription.unsubscribe();
    }
  }

  getCtgData(ctgLevel:number) {
    this.ctgSubscription = this.httpBasic.ctgSearch(
      this.ctgSelectCondition.storeCd,
      ctgLevel,
      this.ctgSelectCondition.formGroup.get('ctgCd0').value,
      this.ctgSelectCondition.formGroup.get('ctgCd1').value,
      this.ctgSelectCondition.formGroup.get('ctgCd2').value,
      this.ctgSelectCondition.formGroup.get('ctgCd3').value
    ).subscribe(
      data => this.setCtgData(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.dialogRef = this.commonService.openSpinnerForSubComp("カテゴリ検索", "検索中・・・");
  }

  clearProgressState() {
    this.commonService.closeSpinnerForSubComp(this.dialogRef);
    this.ctgSubscription?.unsubscribe();
    this.ctgSubscription = undefined;
  }

  setCtgData(data:RspCtgSearch) {
    this.clearProgressState();

    if (data.fatalError && data.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("カテゴリ検索", data.fatalError[0].errMsg);
      return;
    }
    if (data.normalError && data.normalError.length > 0) {
      this.commonService.openErrorDialog("カテゴリ検索", data.normalError[0].errMsg);
      return;
    }

    var ctgs: CtgSearchDto[] = [...data.rows];
  
    for (var ctg of ctgs) {
      switch (ctg.ctgLevelFn) {
        case 0:
          this.ctg0data.push(ctg);
          if (this.initialSearch) {
            this.initComp.emit();
            this.initialSearch = false;
          }
          break;
        case 1:
          this.ctg1data.push(ctg);
          break;
        case 2:
          this.ctg2data.push(ctg);
          break;
        case 3:
          this.ctg3data.push(ctg);
          break;
      }
    }
    this.cdr.detectChanges();
  }

  public setStoreCd(storeCd: string) {
    this.ctgSelectCondition.storeCd = storeCd;
    this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(null);
    this.ctgSelectCondition.formGroup.get("ctgCd0").setValue("");
    /* Renew ctg0 data */
    this.ctg0data.splice(1);
    this.getCtgData(0);
  }

  ctg0Changed(data: string) {
    if (this.ctgSelectCondition.formGroup.get("ctgCd0").disabled) return;
    this.ctg0Name = this.ctgDisplayNameByCode(this.ctg0data, 0, data);
    this.ctgSelectCondition.ctg0Name = this.ctgDisplayNameByCode(this.ctg0data, 0, data);

    this.ctgSelectCondition.formGroup.get("ctgCd1").setValue("");
    /* Renew ctg1 data */
    this.ctg1data.splice(1);
    if (this.ctgSelectCondition.formGroup.get("ctgCd0").value != "") {
      this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(0);
      if (this.commonService.config.maxCtgLevel > 1) {
        this.getCtgData(1);
      }
    } else {
      this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(-1);
    }
  }

  ctg1Changed(data: string) {
    if (this.ctgSelectCondition.formGroup.get("ctgCd1").disabled) return;
    this.ctg1Name = this.ctgDisplayNameByCode(this.ctg1data, 1, data);
    this.ctgSelectCondition.ctg1Name = this.ctgDisplayNameByCode(this.ctg1data, 1, data);

    this.ctgSelectCondition.formGroup.get("ctgCd2").setValue("");
    /* Renew ctg2 data */
    this.ctg2data.splice(1);
    if (this.ctgSelectCondition.formGroup.get("ctgCd1").value != "") {
      this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(1);
      if (this.commonService.config.maxCtgLevel > 2) {
        this.getCtgData(2);
      }
    } else {
      this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(0);
    }
  }

  ctg2Changed(data: string) {
    if (this.ctgSelectCondition.formGroup.get("ctgCd2").disabled) return;
    this.ctg2Name = this.ctgDisplayNameByCode(this.ctg2data, 2, data);
    this.ctgSelectCondition.ctg2Name = this.ctgDisplayNameByCode(this.ctg2data, 2, data);

    this.ctgSelectCondition.formGroup.get("ctgCd3").setValue("");
    /* Renew ctg3 data */
    this.ctg3data.splice(1);
    if (this.ctgSelectCondition.formGroup.get("ctgCd2").value != "") {
      this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(2);
      if (this.commonService.config.maxCtgLevel > 3) {
          this.getCtgData(3);
      }
    } else {
      this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(1);
    }
  }

  ctg3Changed(data: string) {
    if (this.ctgSelectCondition.formGroup.get("ctgCd3").disabled || this.ctgSelectCondition.formGroup.get("ctgCd3").value == "") return;
    this.ctg3Name = this.ctgDisplayNameByCode(this.ctg3data, 3, data);
    this.ctgSelectCondition.ctg3Name = this.ctgDisplayNameByCode(this.ctg3data, 3, data);

    if (this.ctgSelectCondition.formGroup.get("ctgCd3").value != "") {
      this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(3);
    } else {
      this.ctgSelectCondition.formGroup.get("ctgLevel").setValue(2);
    }
  }

  ctgDisplayName(ctg: CtgSearchDto) {
    var ctgCd;
    switch (ctg.ctgLevelFn) {
      case 0:
        ctgCd = ctg.ctgCd0Fv;
        break;
      case 1:
        ctgCd = ctg.ctgCd1Fv;
        break;
      case 2:
        ctgCd = ctg.ctgCd2Fv;
        break;
      case 3:
        ctgCd = ctg.ctgCd3Fv;
        break;
    }
    if (ctgCd === "") {
      return ctg.ctgNameFv;
    }
    return ctgCd + ":" + ctg.ctgNameFv;
  }

  ctgDisplayNameByCode(ctgData: CtgSearchDto[], level: number, ctgCd: string) {
    if (ctgCd == "") return "";

    for (var ctgSearchDto of ctgData) {
      if (ctgSearchDto["ctgCd" + level + "Fv"] === ctgCd) {
        return ctgCd + ":" + ctgSearchDto.ctgNameFv;
      }
    }

    return "";
  }

  getCtg0LabelWidth() : number {
    return this.ctg0Label.nativeElement.clientWidth;
  }

  enableAll() {
    this.ctgSelectCondition.formGroup.enable();
    this.setupChangeSubsc();
  }

  disableAll() {
    this.clearChangeSubsc();
    this.ctgSelectCondition.formGroup.disable();
  }


}
