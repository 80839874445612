import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../service/common.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(public commonService: CommonService) {
  }

  canDeactivate(component: CanComponentDeactivate) {
    if (this.commonService.isFatalErrorRedirect) {
      this.commonService.isFatalErrorRedirect = false;
      return true;
    }
    return component.canDeactivate ? component.canDeactivate() : true;
  }

}
