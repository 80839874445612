import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ItemRec } from 'src/app/common/item-rec';
import { ReqParamCtgSearchDto } from 'src/app/request/req-param-ctg-search';
import { CtgParamComponent } from 'src/app/partsCommon/ctg-param/ctg-param.component';
import { ParamItemEditDto, ReqParamItemEdit } from 'src/app/request/req-param-item-edit';
import { RspParamItemEdit } from 'src/app/response/rsp-param-item-edit';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';

@Component({
  selector: 'app-param-item-edit-dialog',
  templateUrl: './param-item-edit-dialog.component.html',
  styleUrls: ['./param-item-edit-dialog.component.css']
})
export class ParamItemEditDialogComponent implements OnInit, OnDestroy  {

  public paramFormGroup: FormGroup;
  public minZaiForm: FormControl;
  public maxZaiForm: FormControl;
  public minStockDaysForm: FormControl;
  public maxStockDaysForm: FormControl;

  public errorMessageZai: string = "";
  public errorMessageStockDays: string = "";
  public itemRec: ItemRec;
  public subscriptionParamItemEdit: Subscription;
  public ctgCondition: ReqParamCtgSearchDto;

  private keypadTargetId: string;

  @ViewChild(CtgParamComponent, {static: true}) ctgParamComponent: CtgParamComponent;
  @ViewChild(NumberKeypadComponent, {static: true}) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public dialogRef: MatDialogRef<ParamItemEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemRec,
    public httpBasic: HttpBasicService,
    public commonService: CommonService,
    private fb: FormBuilder) { 

    this.itemRec = data;
  }

  ngOnInit() {

    this.paramFormGroup = this.fb.group({
      zaiForm: this.fb.group({
          minZaiForm : [
            this.itemRec.minZaiCurrentFv,
            (fc:FormControl) => {
              return this.paramRangeValidator(
                fc,
                this.commonService.config.paramItemMinZaiRange.min,
                this.commonService.config.paramItemMinZaiRange.max,
                "最低陳列");
            }
          ],
          maxZaiForm : [
            this.itemRec.maxZaiCurrentFv,
            (fc:FormControl) => {
              return this.paramRangeValidator(
                fc,
                this.commonService.config.paramItemMaxZaiRange.min,
                this.commonService.config.paramItemMaxZaiRange.max,
                "最大陳列");
            }
          ],
          dummy : ['']
        }, {
          validators: this.zaiValidator
        }),
      stockForm: this.fb.group({
          minStockDaysForm : [
            this.itemRec.minStockDaysCurrentFv,
            (fc:FormControl) => {
              return this.paramRangeValidator(
                fc,
                this.commonService.config.paramItemMinStockDaysRange.min,
                this.commonService.config.paramItemMinStockDaysRange.max,
                "最低在庫日数");
            }
          ],
          maxStockDaysForm : [
            this.itemRec.maxStockDaysCurrentFv,
            (fc:FormControl) => {
              return this.paramRangeValidator(
                fc,
                this.commonService.config.paramItemMaxStockDaysRange.min,
                this.commonService.config.paramItemMaxStockDaysRange.max,
                "最大在庫日数");
            }
          ],
          dummy : ['']
        }, {
          validators: this.stockValidator
        }),
      dummy : ['']
    }, {
      validators: (fg:FormGroup) => {
        this.groupValidator(fg, 
          this.itemRec.minZaiCurrentFv,
          this.itemRec.maxZaiCurrentFv,
          this.itemRec.minStockDaysCurrentFv,
          this.itemRec.maxStockDaysCurrentFv,
          )
      }
    });

    this.minZaiForm = this.paramFormGroup.get("zaiForm").get("minZaiForm") as FormControl;
    this.maxZaiForm = this.paramFormGroup.get("zaiForm").get("maxZaiForm") as FormControl;
    this.minStockDaysForm = this.paramFormGroup.get("stockForm").get("minStockDaysForm") as FormControl;
    this.maxStockDaysForm = this.paramFormGroup.get("stockForm").get("maxStockDaysForm") as FormControl;
  
    if (!this.commonService.config.paramItemMinZaiEditable) {
      this.paramFormGroup.get("zaiForm").get("minZaiForm").disable();
    }
    if (!this.commonService.config.paramItemMaxZaiEditable) {
      this.paramFormGroup.get("zaiForm").get("maxZaiForm").disable();
    }
    if (!this.commonService.config.paramItemMinStockDaysEditable) {
      this.paramFormGroup.get("stockForm").get("minStockDaysForm").disable();
    }
    if (!this.commonService.config.paramItemMaxStockDaysEditable) {
      this.paramFormGroup.get("stockForm").get("maxStockDaysForm").disable();
    }

    var level: number;
    if (this.itemRec.ctgCd0Fv && this.itemRec.ctgCd0Fv !== "") level = 0;
    if (this.itemRec.ctgCd1Fv && this.itemRec.ctgCd1Fv !== "") level = 1;
    if (this.itemRec.ctgCd2Fv && this.itemRec.ctgCd2Fv !== "") level = 2;
    if (this.itemRec.ctgCd3Fv && this.itemRec.ctgCd3Fv !== "") level = 3;

    this.ctgCondition = {
      storeCdFv: this.itemRec.storeCdFv,
      ctgLevelFn: level,
      ctgCd0Fv: this.itemRec.ctgCd0Fv,
      ctgCd1Fv: this.itemRec.ctgCd1Fv,
      ctgCd2Fv: this.itemRec.ctgCd2Fv,
      ctgCd3Fv: this.itemRec.ctgCd3Fv,
      rankFv: ""
    }
    this.ctgParamComponent.doQuery(this.ctgCondition);
  }

  ngOnDestroy() {
    if (this.subscriptionParamItemEdit) {
      this.subscriptionParamItemEdit.unsubscribe();
    }
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  is10KeyPadTarget(id: string) {
    if (!this.use10KeyPad()) return false;
    if (id === this.keypadTargetId) return true;
    return false;
  }

  set10KeyPadTarget(id: string, form: FormControl) {
    this.keypadTargetId = id;
    // this.numberKeypadComponent.inputField = form;
    this.numberKeypadComponent.setTargetForm(form);
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  groupValidator(fg:FormGroup, minZai:string, maxZai:string, minStock:string, maxStock:string) {
    if (fg.get("zaiForm").get("minZaiForm").value == minZai &&
        fg.get("zaiForm").get("maxZaiForm").value == maxZai &&
        fg.get("stockForm").get("minStockDaysForm").value == minStock &&
        fg.get("stockForm").get("maxStockDaysForm").value == maxStock ) {
          fg.get("dummy").setErrors({NoChange: "値の変更がありません。"});
    } else {
      fg.get("dummy").setErrors(null);
    }
  }

  zaiValidator(fg:FormGroup) {
    var err = {BadValue: "最低、最大が逆転しています。"};

    if (fg.get("minZaiForm").value != "" &&
        fg.get("maxZaiForm").value != "" &&
        parseInt(fg.get("minZaiForm").value) > parseInt(fg.get("maxZaiForm").value)) {
      fg.get("dummy").setErrors(err);
    } else {
      fg.get("dummy").setErrors(null);
    }
  }

  stockValidator(fg:FormGroup) {
    var err = {BadValue: "最低、最大が逆転しています。"};

    if (fg.get("minStockDaysForm").value != "" &&
        fg.get("maxStockDaysForm").value != "" &&
        parseInt(fg.get("minStockDaysForm").value) > parseInt(fg.get("maxStockDaysForm").value)) {
      fg.get("dummy").setErrors(err);
    } else {
      fg.get("dummy").setErrors(null);
      fg.get("dummy").get
    }
  }

  paramRangeValidator(fc: FormControl, min:number, max:number, type:string) {
    var err = {"ValueRange": type + "の範囲は [ " + min + " ～ " + max + " ] です。"};
    if (parseInt(fc.value) < min || parseInt(fc.value) > max) {
      return err;
    } else {
      return null;
    }
  }

  doUpdate() {
    var minZai = this.paramFormGroup.get("zaiForm").get("minZaiForm").value;
    var maxZai = this.paramFormGroup.get("zaiForm").get("maxZaiForm").value;
    var minStockDays = this.paramFormGroup.get("stockForm").get("minStockDaysForm").value;
    var maxStockDays = this.paramFormGroup.get("stockForm").get("maxStockDaysForm").value;

    var reqParamItemEdit: ReqParamItemEdit = {
      access: {...this.commonService.loginUser},
      paramItemEdit: {
        storeCdFv:              this.itemRec.storeCdFv,
        itemCdFv:               this.itemRec.itemCdFv,
        minZaiIsNull:           this.itemRec.minZaiIsNull,
        minZaiFn:               this.itemRec.minZaiFn,
        minZaiModIsNull:        this.itemRec.minZaiModIsNull,
        minZaiModFn:            -1, // this.itemRec.minZaiModFn,
        minZaiVersionFn:        this.itemRec.minZaiVersionFn,
        maxZaiIsNull:           this.itemRec.maxZaiIsNull,
        maxZaiFn:               this.itemRec.maxZaiFn,
        maxZaiModIsNull:        this.itemRec.maxZaiModIsNull,
        maxZaiModFn:            -1, // this.itemRec.maxZaiModFn,
        maxZaiVersionFn:        this.itemRec.maxZaiVersionFn,
        minStockDaysIsNull:     this.itemRec.minStockDaysIsNull,
        minStockDaysFn:         this.itemRec.minStockDaysFn,
        minStockDaysModIsNull:  this.itemRec.minStockDaysModIsNull,
        minStockDaysModFn:      -1, // this.itemRec.minStockDaysModFn,
        minStockDaysVersionFn:  this.itemRec.minStockDaysVersionFn,
        maxStockDaysIsNull:     this.itemRec.maxStockDaysIsNull,
        maxStockDaysFn:         this.itemRec.maxStockDaysFn,
        maxStockDaysModIsNull:  this.itemRec.maxStockDaysModIsNull,
        maxStockDaysModFn:      -1, // this.itemRec.maxStockDaysModFn,
        maxStockDaysVersionFn:  this.itemRec.maxStockDaysVersionFn,
        autoOrderLotFn:         this.itemRec.autoOrderLotFn,
        autoOrderLotModFn:      -1,
        autoOrderLotModVersionFn: this.itemRec.autoOrderLotModVersionFn
      }
    };

    if (minZai != this.itemRec.minZaiCurrentFv) {
      reqParamItemEdit.paramItemEdit.minZaiModFn = this.paramToNumber(minZai);
      reqParamItemEdit.paramItemEdit.minZaiModIsNull = this.paramIsNull(minZai);
    }
    if (maxZai != this.itemRec.maxZaiCurrentFv) {
      reqParamItemEdit.paramItemEdit.maxZaiModFn = this.paramToNumber(maxZai);
      reqParamItemEdit.paramItemEdit.maxZaiModIsNull = this.paramIsNull(maxZai);
    }
    if (minStockDays != this.itemRec.minStockDaysCurrentFv) {
      reqParamItemEdit.paramItemEdit.minStockDaysModFn = this.paramToNumber(minStockDays);
      reqParamItemEdit.paramItemEdit.minStockDaysModIsNull = this.paramIsNull(minStockDays);
    }
    if (maxStockDays != this.itemRec.maxStockDaysCurrentFv) {
      reqParamItemEdit.paramItemEdit.maxStockDaysModFn = this.paramToNumber(maxStockDays);
      reqParamItemEdit.paramItemEdit.maxStockDaysModIsNull = this.paramIsNull(maxStockDays);
    }

    this.subscriptionParamItemEdit = this.httpBasic.updateItemParam(reqParamItemEdit)
      .subscribe(data => this.checkResult(data),
                 error => {
                   this.clearProgressState();
                   this.httpBasic.handleError(error);
                 });

    this.commonService.openSpinner("商品パラメータ", "更新中・・・");
  }

  paramToNumber(val: string) {
    return val === "" ? 0 : parseInt(val);
  }
  paramIsNull(val: string) {
    return val === "" ? true : false;
  }

  clearProgressState() {
    this.subscriptionParamItemEdit.unsubscribe();
    this.subscriptionParamItemEdit = undefined;
    this.commonService.closeSpinner();
  }

  checkResult(response: RspParamItemEdit) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("商品パラメータ", response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog("商品パラメータ", response.normalError[0].errMsg);
      return;
    }

    this.itemRec.setMinZai(response.result.minZaiModFn, response.result.minZaiVersionFn);
    this.itemRec.setMaxZai(response.result.maxZaiModFn, response.result.maxZaiVersionFn);
    this.itemRec.setMinStockDays(response.result.minStockDaysModFn, response.result.minStockDaysVersionFn);
    this.itemRec.setMaxStockDays(response.result.maxStockDaysModFn, response.result.maxStockDaysVersionFn);

    this.dialogRef.close();
  }
}