import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { OpestatViewContext } from '../opestat-view-context';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ReqOpestatDistrRequest } from 'src/app/request/req-opestat-distr';
import { Subscription } from 'rxjs';
import { RspOpestatDistr, RspOpestatDistrItem } from 'src/app/response/rsp-opestat-distr';
import { OpestatDistrRec, OpestatDistrItemRec } from 'src/app/common/opestat-distr-rec';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-opestat-view-distr',
  templateUrl: './opestat-view-distr.component.html',
  styleUrls: ['./opestat-view-distr.component.css']
})
export class OpestatViewDistrComponent implements OnInit, OnDestroy {

  public isDistr: boolean;
  public opestatList: any[] = [];
  public opestatItemList: any[] = [];
  public columnDefs: TableColumnDef[] = [];
  public itemColumnDefs: TableColumnDef[] = [];

  public tableWidth: any;
  public itemTableWidth: any;
  public pagenatorMaxWidth: any;
  public itemPagenatorMaxWidth: any;
  public protoTypeMsg: string = "";
  public displayColumnIds: string[];
  public itemDisplayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[] = [];
  public itemDisplayColumnDefs: TableColumnDef[] = [];
  public recordCount: number = 0;
  public itemRecordCount: number = 0;
  public isViewItemList: boolean = false;

  private subscriptionSearch: Subscription;
  private subscriptionSearchItem: Subscription;
  private subscriptionDialog: Subscription;

  private minRangeValue: number = 0;
  private maxRangeValue: number = 0;
  private distTypeName: string;

  private selectedColumnId: String;
  private selectedDistrRec: OpestatDistrRec;

  private rangeRar = {
    range1: {min: 0.0, max: 0.1},
    range2: {min: 0.1, max: 0.2},
    range3: {min: 0.2, max: 0.3},
    range4: {min: 0.3, max: 0.4},
    range5: {min: 0.4, max: 0.5},
    range6: {min: 0.5, max: 0.6},
    range7: {min: 0.6, max: 0.7},
    range8: {min: 0.7, max: 0.8},
    range9: {min: 0.8, max: 0.9},
    range10: {min: 0.9, max: 1.0}
  };
 private rangeSoldOut = {
  range1: {min:  0, max:  2},
  range2: {min:  2, max:  4},
  range3: {min:  4, max:  6},
  range4: {min:  6, max:  8},
  range5: {min:  8, max: 10},
  range6: {min: 10, max: 12},
  range7: {min: 12, max: 14},
  range8: {min: 14, max: 16},
  range9: {min: 16, max: 18},
  range10: {min: 18, max: 20},
  range11: {min: 20, max:  100}
};

  @Output('cellClick') public cellClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() public viewContext: OpestatViewContext;
  @ViewChild(MatPaginator, { static: false }) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private changeDetector: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.isDistr = parseInt(this.viewContext.formGroup.get("type").value) == OpestatViewContext.ConditionTypeByDistribution;
  }

  ngOnDestroy() {
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
    if (this.subscriptionSearchItem) this.subscriptionSearchItem.unsubscribe();
    if (this.subscriptionDialog) this.subscriptionDialog.unsubscribe();  
  }

  defsTable() {
    this.columnDefs = [];
    this.displayColumnDefs = [];
    this.opestatList = [];
    if (parseInt(this.viewContext.formGroup.get('type').value) == OpestatViewContext.ConditionTypeByDistribution &&
        parseInt(this.viewContext.formGroup.get('distrType').value) == OpestatViewContext.ConditionDistrTypeRecommendationAcceptanceRatio) {
      this.columnDefs = [
        { columnId: 'type', header: "種別", width: 100, align: "center" },
        { columnId: 'range1', header: "～10%", width: 60, align: "right" },
        { columnId: 'range2', header: "～20%", width: 60, align: "right" },
        { columnId: 'range3', header: "～30%", width: 60, align: "right" },
        { columnId: 'range4', header: "～40%", width: 60, align: "right" },
        { columnId: 'range5', header: "～50%", width: 60, align: "right" },
        { columnId: 'range6', header: "～60%", width: 60, align: "right" },
        { columnId: 'range7', header: "～70%", width: 60, align: "right" },
        { columnId: 'range8', header: "～80%", width: 60, align: "right" },
        { columnId: 'range9', header: "～90%", width: 60, align: "right" },
        { columnId: 'range10', header: "～100%", width: 60, align: "right" }
      ];
    }
    else if (parseInt(this.viewContext.formGroup.get('type').value) == OpestatViewContext.ConditionTypeByDistribution &&
             parseInt(this.viewContext.formGroup.get('distrType').value) == OpestatViewContext.ConditionDistrTypeSoldOutRatio) {
      this.columnDefs = [
        { columnId: 'type', header: "種別", width: 100, align: "center" },
        { columnId: 'range1', header: "～2%", width: 60, align: "right" },
        { columnId: 'range2', header: "～4%", width: 60, align: "right" },
        { columnId: 'range3', header: "～6%", width: 60, align: "right" },
        { columnId: 'range4', header: "～8%", width: 60, align: "right" },
        { columnId: 'range5', header: "～10%", width: 60, align: "right" },
        { columnId: 'range6', header: "～12%", width: 60, align: "right" },
        { columnId: 'range7', header: "～14%", width: 60, align: "right" },
        { columnId: 'range8', header: "～16%", width: 60, align: "right" },
        { columnId: 'range9', header: "～18%", width: 60, align: "right" },
        { columnId: 'range10', header: "～20%", width: 60, align: "right" },
        { columnId: 'range11', header: "20%～", width: 60, align: "right" }
      ];
    }
    for (var colDef of this.columnDefs) {
      if (colDef.columnId != 'type') {
        colDef.editable = true;
      }

      this.displayColumnDefs.push(colDef);
    }
    this.setColumnId();
    this.calcTableWidth();
  }

  defsItemTable() {
    this.itemColumnDefs = [];
    this.itemDisplayColumnDefs = [];
    this.opestatItemList = [];
    if (parseInt(this.viewContext.formGroup.get('type').value) == OpestatViewContext.ConditionTypeByDistribution &&
        parseInt(this.viewContext.formGroup.get('distrType').value) == OpestatViewContext.ConditionDistrTypeRecommendationAcceptanceRatio) {
      this.itemColumnDefs = [
        { columnId: 'itemCdFv', header: '商品コード', width: 90, align: 'center' },
        { columnId: 'itemNameFv', header: '商品名', width: 300 },
        { columnId: 'orderNumber', header: "発注回数", width: 60, align: "right" },
        { columnId: 'addNumber', header: "追加回数", width: 60, align: "right" },
        { columnId: 'upNumber', header: "上方回数", width: 60, align: "right" },
        { columnId: 'matchNumber', header: "一致回数", width: 60, align: "right" },
        { columnId: 'downNumber', header: "下方回数", width: 60, align: "right" },
        { columnId: 'delNumber', header: "削除回数", width: 60, align: "right" },
        { columnId: 'ctg0Fv', header: this.commonService.literal.ctg0Name, width: 200 },
        { columnId: 'ctg1Fv', header: this.commonService.literal.ctg1Name, width: 200 },
        { columnId: 'ctg2Fv', header: this.commonService.literal.ctg2Name, width: 200 }
      ];
    }
    else if (parseInt(this.viewContext.formGroup.get('type').value) == OpestatViewContext.ConditionTypeByDistribution &&
             parseInt(this.viewContext.formGroup.get('distrType').value) == OpestatViewContext.ConditionDistrTypeSoldOutRatio) {
      this.itemColumnDefs = [
        { columnId: 'itemCdFv', header: '商品コード', width: 90, align: 'center' },
        { columnId: 'itemNameFv', header: '商品名', width: 300 },
        { columnId: 'shortageNumber', header: "欠品回数", width: 60, align: "right" },
        { columnId: 'ctg0Fv', header: this.commonService.literal.ctg0Name, width: 200 },
        { columnId: 'ctg1Fv', header: this.commonService.literal.ctg1Name, width: 200 },
        { columnId: 'ctg2Fv', header: this.commonService.literal.ctg2Name, width: 200 }
      ];
    }
    for (var itemColDef of this.itemColumnDefs) {
      this.itemDisplayColumnDefs.push(itemColDef);
    }
    this.setItemColumnId();
    this.calcItemTableWidth();
  }

  setColumnId() {
    this.displayColumnIds = [];
    for (var colDef of this.displayColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
  }

  setItemColumnId() {
    this.itemDisplayColumnIds = [];
    for (var itemColDef of this.itemDisplayColumnDefs) {
      this.itemDisplayColumnIds.push(itemColDef.columnId);
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
    this.pagenatorMaxWidth = { "max-width": "" + width + "px" }
  }
  calcItemTableWidth() {
    var width = 1;   // For left border
    for (var itemColDef of this.itemDisplayColumnDefs) {
      width = width + itemColDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.itemTableWidth = { "width": "" + width + "px" };
    this.itemPagenatorMaxWidth = { "max-width": "" + width + "px" }
  }

  styleFor(id: string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }
  styleForItem(id: string) {
    for (var itemColDef of this.itemColumnDefs) {
      if (itemColDef.columnId === id) {
        return {
          "width": "" + itemColDef.width + "px",
          "text-align": itemColDef.align ? itemColDef.align : "left"
        }
      }
    }
  }
  styleForHeader(id: string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }
  styleForHeaderItem(id: string) {
    for (var itemColDef of this.itemColumnDefs) {
      if (itemColDef.columnId === id) {
        return {
          "width": "" + itemColDef.width + "px"
        }
      }
    }
  }

  doQuery() {
    this.isDistr = parseInt(this.viewContext.formGroup.get("type").value) == OpestatViewContext.ConditionTypeByDistribution;
    if (!this.isDistr) return;

    this.isViewItemList = false;
    this.selectedDistrRec = undefined;
    this.selectedColumnId = undefined;

    this.doQueryBody();
  }

  doQueryBody() {
    if (this.viewContext.formGroup.get('type').value == OpestatViewContext.ConditionTypeByDistribution) {
      let request: ReqOpestatDistrRequest = {
        access: { ...this.commonService.loginUser },
        typeFn: this.viewContext.formGroup.get('type').value,
        distTypeFn: this.viewContext.formGroup.get('distrType').value,
        storeCdFv: this.viewContext.formGroup.get('targetStoreCode').value,
        ctgLevelFn: this.viewContext.ctgFromGroup.get('ctgLevel').value,
        dateBegin: formatDate(this.viewContext.formGroup.get('dateBegin').value, 'yyyy-MM-dd', 'en_US'),
        dateEnd: formatDate(this.viewContext.formGroup.get('dateEnd').value, 'yyyy-MM-dd', 'en_US'),
        ctgCd0Fv: this.viewContext.ctgFromGroup.get('ctgLevel').value >= 0 ?
          this.viewContext.ctgFromGroup.get('ctgCd0').value : '',
        ctgCd1Fv: this.viewContext.ctgFromGroup.get('ctgLevel').value >= 1 ?
          this.viewContext.ctgFromGroup.get('ctgCd1').value : '',
        ctgCd2Fv: this.viewContext.ctgFromGroup.get('ctgLevel').value >= 2 ?
          this.viewContext.ctgFromGroup.get('ctgCd2').value : '',
        ctgCd3Fv: this.viewContext.ctgFromGroup.get('ctgLevel').value >= 2 ?
          this.viewContext.ctgFromGroup.get('ctgCd3').value : '',
        rankFv: this.viewContext.formGroup.get('rank').value,
        isRequestItemList: false,
        typeRange: '',
        minRangeValue: 0,
        maxRangeValue: 0,
        page: {
          pageNum: 0,
          dispNum: 0
        }
      };
      this.subscriptionSearch = this.httpBasic.opestatDistrSearch(request).subscribe(
        data => this.opestatDistrResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        }
      );
      this.commonService.openSpinner(this.commonService.pageTitle, '集計中・・・');

    }
  }

  doQueryItem(type: string, minRange: number, maxRange: number) {
    if (this.viewContext.formGroup.get('type').value == OpestatViewContext.ConditionTypeByDistribution) {
      let request: ReqOpestatDistrRequest = {
        access: { ...this.commonService.loginUser },
        typeFn: this.viewContext.formGroup.get('type').value,
        distTypeFn: this.viewContext.formGroup.get('distrType').value,
        storeCdFv: this.viewContext.formGroup.get('targetStoreCode').value,
        ctgLevelFn: this.viewContext.ctgFromGroup.get('ctgLevel').value,
        dateBegin: formatDate(this.viewContext.formGroup.get('dateBegin').value, 'yyyy-MM-dd', 'en_US'),
        dateEnd: formatDate(this.viewContext.formGroup.get('dateEnd').value, 'yyyy-MM-dd', 'en_US'),
        ctgCd0Fv: this.viewContext.ctgFromGroup.get('ctgLevel').value >= 0 ?
          this.viewContext.ctgFromGroup.get('ctgCd0').value : '',
        ctgCd1Fv: this.viewContext.ctgFromGroup.get('ctgLevel').value >= 1 ?
          this.viewContext.ctgFromGroup.get('ctgCd1').value : '',
        ctgCd2Fv: this.viewContext.ctgFromGroup.get('ctgLevel').value >= 2 ?
          this.viewContext.ctgFromGroup.get('ctgCd2').value : '',
        ctgCd3Fv: this.viewContext.ctgFromGroup.get('ctgLevel').value >= 2 ?
          this.viewContext.ctgFromGroup.get('ctgCd3').value : '',
        rankFv: this.viewContext.formGroup.get('rank').value,
        isRequestItemList: true,
        typeRange: type,
        minRangeValue: minRange,
        maxRangeValue: maxRange,
        page: {
          pageNum: this.matPagenator.pageIndex,
          dispNum: this.matPagenator.pageSize
        }
      };

      this.subscriptionSearchItem = this.httpBasic.opestatDistrSearch(request).subscribe(
        data => this.opestatDistrItemResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        }
      );
      this.commonService.openSpinner(this.commonService.pageTitle, '集計中・・・');
    }
  }

  opestatDistrResult(data: RspOpestatDistr) {
    if (!this.isViewItemList) {
      this.clearProgressState();
    }
    let rspOpestatDistr: RspOpestatDistr = { ...data };
    if (rspOpestatDistr.fatalError && rspOpestatDistr.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspOpestatDistr.fatalError[0].errMsg);
      return;
    }
    if (rspOpestatDistr.normalError && rspOpestatDistr.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspOpestatDistr.normalError[0].errMsg);
      return;
    }

    this.defsTable();
    this.recordCount = rspOpestatDistr.recordCount;
    if (this.recordCount > 0) {
      for (let rspOpestatDistrDto of rspOpestatDistr.resultDstr) {
        this.opestatList.push(new OpestatDistrRec(rspOpestatDistrDto, this.commonService));
      }
    }
  }

  opestatDistrItemResult(data: RspOpestatDistrItem) {
    this.clearProgressItemState();
    let rspOpestatDistrItem: RspOpestatDistrItem = { ...data };
    if (rspOpestatDistrItem.fatalError && rspOpestatDistrItem.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspOpestatDistrItem.fatalError[0].errMsg);
      return;
    }
    if (rspOpestatDistrItem.normalError && rspOpestatDistrItem.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspOpestatDistrItem.normalError[0].errMsg);
      return;
    }
    this.defsItemTable();
    this.itemRecordCount = rspOpestatDistrItem.recordCount;
    if (this.itemRecordCount > 0) {
      for (let rspOpestatDistrItemDto of rspOpestatDistrItem.resultItemList) {
        this.opestatItemList.push(new OpestatDistrItemRec(rspOpestatDistrItemDto, this.commonService));
      }
    }
  }

  pageChanged(pageEvent: PageEvent) {
    this.doQueryItem(this.distTypeName, this.minRangeValue, this.maxRangeValue);
  }

  clearProgressState() {
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
    this.commonService.closeSpinner();
    this.subscriptionSearch = undefined;
  }

  clearProgressItemState() {
    if (this.subscriptionSearchItem) this.subscriptionSearchItem.unsubscribe();
    this.commonService.closeSpinner();
    this.subscriptionSearchItem = undefined;
  }

  isSelectedCell(columnId: string, opestatDistrRec: OpestatDistrRec) {
    if (!this.selectedColumnId) return false;
    if (!this.selectedDistrRec) return false;
    if (this.selectedColumnId != columnId) return;
    if (this.selectedDistrRec.type != opestatDistrRec.type) return false;
    return true;
  }

  onClickCell(columnId: string, opestatDistrRec: OpestatDistrRec) {
    if (columnId !== 'type') {
      if (this.viewContext.isDirty()) return;
      this.selectedColumnId = columnId;
      this.selectedDistrRec = opestatDistrRec;
      this.cellClick.emit(this.viewContext.id);
      this.opestatItemList = [];
      this.isViewItemList = true;
      this.itemRecordCount = 0;
      this.changeDetector.detectChanges();
      this.matPagenator.pageIndex = 0;
      // let rate: number = 1;
      if (parseInt(this.viewContext.formGroup.get('distrType').value) == OpestatViewContext.ConditionDistrTypeRecommendationAcceptanceRatio) {
        this.minRangeValue = this.rangeRar[columnId].min;
        this.maxRangeValue = this.rangeRar[columnId].max;
      } else {
        this.minRangeValue = this.rangeSoldOut[columnId].min;
        this.maxRangeValue = this.rangeSoldOut[columnId].max;
      }

      this.distTypeName = opestatDistrRec.type;
      this.doQueryItem(this.distTypeName, this.minRangeValue, this.maxRangeValue);
    }
  }

  hideItemList() {
    this.isViewItemList = false;
    this.selectedDistrRec = undefined;
    this.selectedColumnId = undefined;
  }

  copyToCsv(prefix: string) {
    this.commonService.getCsvFromTable(prefix + this.viewContext.id);
  }

}
