import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, isDevMode } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { RspSpor00051Search, RspSpor00051SearchDto } from 'src/app/response/rsp-spor00051-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MessageService } from 'src/app/service/message.service';
import * as MessageID from 'src/app/common/const-message-id';
import { ReqSpor00051Search } from 'src/app/request/req-spor00051-search';
import { UserInfoDto } from 'src/app/response/rsp-user-info';
import { RspSpor00051Detail, RspSpor00051DetailDto } from 'src/app/response/rsp-spor00051-detail';
import { ReqSpor00051Detail } from 'src/app/request/req-spor00051-detail';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';

@Component({
  selector: 'app-spor00051',
  templateUrl: './spor00051.component.html',
  styleUrls: ['./spor00051.component.css']
})

export class Spor00051Component implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  @ViewChild("sectionInputHeader", { static: true }) sectionInput: SectionHeaderComponent;
  @ViewChild("sectionSearchHeader", { static: true }) sectionSearch: SectionHeaderComponent;
  @ViewChild("sectionDetailHeader", { static: true }) sectionDetail: SectionHeaderComponent;
  @ViewChild(DateRangeComponent, { static: false }) dateRangeComponent: DateRangeComponent;

  private subscriptionInit: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscriptionSearch: Subscription;
  private subscriptionDetail: Subscription;
  private subscriptionUpdate: Subscription;

  public formGroup: FormGroup;
  public formGroupUpdate: FormGroup;

  public tableWidth: any;
  public columnIds: any = ['PRODUCTCD','PRODUCTNM','ORDERQTY','STOREINVENTORY','NOCONFIRMCNT'];
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'PRODUCTCD', header: "商品コード", width: 80, align: "center" },
    { columnId: 'PRODUCTNM', header: "商品名称", width: 200, align: "left" },
    { columnId: 'ORDERQTY', header: "発注数", width: 80, align: "right" },
    { columnId: 'STOREINVENTORY', header: "自店在庫数", width: 80, align: "right" },
    { columnId: 'NOCONFIRMCNT', header: "未確認数", width: 80, align: "right" }
  ];

  public columnDetailIds: any = ['HEADERCOLUMN','STORECD','STORENM','AVAIABLECNT','OTHERSTORECONFIRMQTY','REMAINQTY','REQUESTCOUNT'];
  public displayColumnDetailIds: string[];
  public columnDetailDefs: TableColumnDef[] = [
    { columnId: 'HEADERCOLUMN', header: "", width: 100, align: "center" },
    { columnId: 'STORECD', header: "店舗コード", width: 100, align: "left" },
    { columnId: 'STORENM', header: "店舗名称", width: 150, align: "left" },
    { columnId: 'AVAIABLECNT', header: "余剰在庫数", width: 100, align: "right" },
    { columnId: 'OTHERSTORECONFIRMQTY', header: "引当可能数", width: 100, align: "right" },
    { columnId: 'REMAINQTY', header: "残数", width: 100, align: "right" },
    { columnId: 'REQUESTCOUNT', header: "希望数", width: 270, align: "right" }
  ];
  public stores: any[];
  public instructionUsers: any[];
  public paginatorMaxWidth: any;
  public dateMessage: any;
  public recordCount: number = 0;
  private isQueryRequested: boolean = false;
  public confirmConditions: any[] = [];
  
  public cmpnyCd : string;
  public storeCd: any;
  public sysModeCd: any;
  public lang: any;
  public prdAttributeKbn: any;

  public searchList: RspSpor00051SearchDto[] = [];
  public detailList: RspSpor00051DetailDto[] = [];
  public resultDetailList: RspSpor00051DetailDto[] = [];
  public userInfo: UserInfoDto[] = [];
  public orderDateBegin: Date;
  public orderDateEnd: Date;

  public productCd : string;
  public productMessage : string;

  public interStoreTransferInsId : string;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    private messageEntity: MessageService
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.initFormGroup();
    this.calcTableWidth(this.columnDefs);
    this.calcTableWidth(this.columnDetailDefs);
    this.initList();
  }
  
  async initList(){
    await this.getUserInfo();
    await this.getStoreLists();
  }

  getUserInfo() :Promise<void>{
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
      };
      let sub =this.httpBasic.generalRequest("GetUserInfo", request).subscribe(
        response => {
          sub.unsubscribe();
          this.receiveUserInfo(response);
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveUserInfo(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.userInfo = [];
    if (response.rows.length > 0) {
      this.userInfo = response.rows;
      this.cmpnyCd = this.userInfo[0].mainCmpnyCd;
      this.storeCd = this.userInfo[0].mainStoreCd;
      this.sysModeCd = this.userInfo[0].sysModeCd;
      this.lang = this.userInfo[0].mainLang;
      this.prdAttributeKbn = this.userInfo[0].prdAttributeKbn;
    }else{
      this.userInfo = [];
      this.cmpnyCd = "";
      this.storeCd = "";
      this.sysModeCd = "";
    }
  }

  getStoreLists(): Promise<void>{
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
        cmpyCd: this.cmpnyCd,
        storeCd: this.storeCd,
        sysModeCd: this.sysModeCd
      };
      this.subscriptionSearch = this.httpBasic.generalRequest("GetStoreList", request).subscribe(
        response => {
          this.receiveStoreList(response);
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.subscriptionSearch.unsubscribe();
          this.subscriptionSearch = undefined;
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveStoreList(response) {
    if (this.httpBasic.handleAppError(response)) return;

    this.stores = [];
    if (response.rows.length > 0) {
      this.stores = response.rows;
      // this.formGroup.get("storeCd").setValue(response.rows[0].storeCd);
    }
  }

  fnFilterRCDKBN(rcdkbn: String, listRCDKBN: any) {
    return listRCDKBN.filter((el: any) => el.RCDKBN == rcdkbn)
  }

  ngOnDestroy() {
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    if (this.subscriptionSearch) this.subscriptionSearch.unsubscribe();
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.realStoreCd],
      productCd: [""],
      productNm: [""]
    });
  }

  doCancel() {
    let dateRange = this.dateRangeComponent.getDateRange();
    this.formGroup.controls["storeCd"].setValue(this.commonService.loginUser.realStoreCd);
    this.formGroup.controls["productCd"].setValue(null);
    this.formGroup.controls["productNm"].setValue(null);
    dateRange.dateBegin = new Date();
    dateRange.dateEnd = new Date();
    this.orderDateBegin = dateRange.dateBegin;
    this.orderDateEnd = dateRange.dateEnd;
    this.clearTable();
  }

  clearTable(){
    this.searchList = [];
    this.detailList = [];
    this.recordCount = 0;
  }

  getProductDetail(): Promise<void> {
    let productCdFv = this.formGroup.get('productCd').value;
    if(productCdFv == ""){
      this.formGroup.get('productNm').setValue("");
      this.productMessage = "";
      return;
    }
    this.productMessage = "";
    return new Promise((resolve, reject) => {
      const request: any = {
        productCd: productCdFv,
        cmpyCd: this.cmpnyCd,
        // storeCd: this.formGroup.get('storeCd').value,
        access: { ...this.commonService.loginUser },
      };

      let subsc = this.httpBasic.generalRequest("GetProductName", request).subscribe(
        response => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.receiveProductName(response);
          resolve();
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveProductName(response: any){
    this.formGroup.get('productNm').setValue("");
    if (response.rows.length > 0) {
      this.formGroup.get('productNm').setValue(response.rows[0].productNm);
    }else{
      this.productMessage = this.messageEntity.message[MessageID.ME100008].replace("%1", this.formGroup.get('productCd').value);
    }
  }

  doSearch() {
    this.matPaginator.pageIndex = 0;
    this.isQueryRequested = true;
    this.querySearch();
  }

  querySearch() {
    let dateRage = this.dateRangeComponent.getDateRange();
    this.isQueryRequested = true;
    var request: ReqSpor00051Search = {
      access: { ...this.commonService.loginUser },
      spor00051Search: {
        cmpnyCd : this.cmpnyCd,
        storeCdFv : this.formGroup.get("storeCd").value,
        orderDateBeginFv : this.commonService.formatDate(dateRage.dateBegin),
        orderDateEndFv : this.commonService.formatDate(dateRage.dateEnd),
        productCdFv : this.formGroup.get("productCd").value,
        productNmFv : this.formGroup.get("productNm").value
      },
      page: {
        pageNum: this.matPaginator.pageIndex,
        dispNum: this.matPaginator.pageSize
      }
    }

    this.subscriptionSearch = this.httpBasic.generalRequest('Spor00051Search',request).subscribe(
      data => this.SearchResult(data),
      error => {
        this.clearSearchProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
  }

  pageChanged(pageEvent: PageEvent) {
    if (!this.isQueryRequested)
      return;
    this.querySearch();
  }

  clearSearchProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }
  clearDetailProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionDetail.unsubscribe();
    this.subscriptionDetail = undefined;
  }
  
  SearchResult(data: RspSpor00051Search) {
    this.clearSearchProgressState();
    let searchResult: RspSpor00051Search = { ...data };
    if (searchResult.fatalError && searchResult.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, searchResult.fatalError[0].errMsg);
    }
    if (searchResult.normalError && searchResult.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, searchResult.normalError[0].errMsg);
      return;
    }

    this.searchList = [];
    this.detailList = [];
    this.interStoreTransferInsId = '';
    this.recordCount = searchResult.recordCount;
    if (this.recordCount > 0) {
      let resultDtos: RspSpor00051SearchDto[] = [...searchResult.rows];
      if(resultDtos.length > 0){
        this.searchList = [];
        this.sectionInput.openSectionBody();
        this.sectionSearch.openSectionBody();
        for (let index = 0; index < resultDtos.length; index++) {
          const element = resultDtos[index];
          this.searchList.push({
            STORECD : element.STORECD ,
            PRODUCTCD : element.PRODUCTCD ,
            PRODUCTNM : element.PRODUCTNM ,
            ORDERQTY : element.ORDERQTY ,
            STOREINVENTORY : element.STOREINVENTORY,
            NOCONFIRMCNT : element.NOCONFIRMCNT,
            INTERSTORETRANSFERINSID : element.INTERSTORETRANSFERINSID
          });
        }
      }
    }
  }

  doUpdate(): Promise<void> {
    let changedList = this.detailList.filter(item => item.requestCountForm.value != null && item.requestCountForm.value != "" && item.requestCountForm.value != undefined);
    if(changedList.length == 0){
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.messageEntity.message[MessageID.ME000002].replace("%1","少なくとも 1 つ"));
    }else if(!this.fnCheckValidation(this.detailList)){
      if(this.detailList.length > 0){
        const updatedDetailList = changedList.map(item => ({
          STORECD: item.STORECD,
          PRODUCTCD: this.productCd,
          INTERSTORETRANSFERINSID: this.interStoreTransferInsId,
          REQUESTCOUNT: item.requestCountForm.value
        }));
        var request: any = {
          access: { ...this.commonService.loginUser },
          spor00051Update :  {
            rows : updatedDetailList,
          }
        }
        return new Promise((resolve, reject) => {
        this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
        this.subscriptionUpdate = this.httpBasic.generalRequest("Spor00051Update",request).subscribe(
          response => {
            this.checkError(response);
            this.subscriptionUpdate.unsubscribe();
            this.commonService.closeSpinner();
            this.subscriptionUpdate = undefined;
            this.clearTable();
            this.detailList = [];
            resolve();
          },
          error => {
            this.subscriptionUpdate.unsubscribe();
            this.commonService.closeSpinner();
            this.httpBasic.handleError(error);
            reject(error);
          }
        )});
      }
    }else{
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.messageEntity.message[MessageID.ME100021]);
    }
  }

  fnCheckValidation(dataList: RspSpor00051DetailDto[]){
    let result = false;
    for(let detailData of dataList){
      result = !this.fnValidSingle(detailData);
    }
    return result;
  }

  fnValidSingle(data: RspSpor00051DetailDto){
    let requestCount = data.requestCountForm.value;
    if(requestCount == "" || requestCount == null){
      data.REQUESTERROR = "";
      return true;
    }else if(isNaN(parseInt(requestCount))){
      data.REQUESTERROR = this.messageEntity.message[MessageID.ME100013];
      return false;
    }else{
      if((parseInt(requestCount) > parseInt(data.REMAINQTY)) || parseInt(requestCount) <= 0){
        data.REQUESTERROR = this.messageEntity.message[MessageID.ME100023].replace("%1", "希望数量").replace("%2","残数量");
        return false;
      }else{
        data.REQUESTERROR = "";
        return true;
      }
    }
  }

  checkResult(response){
    this.checkError(response);
    this.doSearch();
  }

  checkError(response){
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
  }

  onClickCell(id:any, item: any){
    this.interStoreTransferInsId = item["INTERSTORETRANSFERINSID"];
    var request: ReqSpor00051Detail = {
      access: { ...this.commonService.loginUser },
      spor00051Detail: {
        PRODUCTCD : item["PRODUCTCD"],
        CMPNYCD : this.cmpnyCd,
        STORECD : item["STORECD"],
      },
      page: {
        pageNum: this.matPaginator.pageIndex,
        dispNum: this.matPaginator.pageSize
      }
    }
    this.productCd  = item[id];

    this.subscriptionDetail = this.httpBasic.generalRequest('Spor00051Detail',request).subscribe(
      data => this.getDetailResult(data),
      error => {
        this.clearDetailProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
  }

  getDetailResult(data: RspSpor00051Detail) {
    this.clearDetailProgressState();
    let detailResult: RspSpor00051Detail = { ...data };
    if (detailResult.fatalError && detailResult.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, detailResult.fatalError[0].errMsg);
    }
    if (detailResult.normalError && detailResult.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, detailResult.normalError[0].errMsg);
      return;
    }
    this.detailList = [];
    let resultDtos: RspSpor00051DetailDto[] = [...data.rows];
    if(resultDtos.length > 0){
      this.sectionInput.openSectionBody();
      this.sectionSearch.openSectionBody();
      for (let index = 0; index < resultDtos.length; index++) {
        const element = resultDtos[index];
        this.detailList.push({
          STORECD : element.STORECD,
          STORENM : element.STORENM,
          AVAIABLECNT : element.AVAIABLECNT,
          OTHERSTORECONFIRMQTY : element.OTHERSTORECONFIRMQTY,
          REMAINQTY : element.REMAINQTY,
          REQUESTCOUNT: "",
          REQUESTERROR: "",
          requestCountForm: new FormControl()
        });
      }
    }
  }

  calcTableWidth(tableColumns: TableColumnDef[]) {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    for (var col of tableColumns) {
      width = width + col.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
    this.paginatorMaxWidth = { "max-width": "" + width + "px" };
  }

  styleFor(id: string, columnDefs: any) {
    return this.styleForBody(columnDefs, id);
  }

  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string, columnDefs: any) {
    return this.styleForHeaderBody(columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  setDateType(type: string) {
    if (type == "発注日") {
      this.formGroup.get('dateType').setValue("1");
    } else {
      this.formGroup.get('dateType').setValue("2");
    }
  }

  setDefaultPage() {
    this.matPaginator.pageIndex = 0;
    this.recordCount = 0;
    this.isQueryRequested = false;
  }

  fnConfirmValidation(){
    return true;
  }

  validateNumber(event: KeyboardEvent,item: any) {
    const input = event.target as HTMLInputElement;
    const regex = /^[0-9]*$/;
    let value = input.value;
    if (!regex.test(value)) {
      value = value.replace(/[^0-9]/g, '');
      input.value = value;
    }
    input.value = input.value.replace(/[eE]/g, '');
    item.requestCountForm.value = input.value.replace(/[eE]/g, '');
    this.fnValidSingle(item);
  }

  validatePaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedData = clipboardData.getData('text');

    // Only keep digits from the pasted data
    pastedData = pastedData.replace(/[^0-9]/g, '');

    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    const newValue = currentValue.slice(0, input.selectionStart || 0) +
                     pastedData +
                     currentValue.slice(input.selectionEnd || 0);

    input.value = newValue;
  }
}
