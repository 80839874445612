import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ItemSelectSimpleCondition } from 'src/app/partsCommon/item-select-simple/item-select-simple.component';
import { FormGroup } from '@angular/forms';
import { ReqItemSimpleSearch } from 'src/app/request/req-item-simple-seach';
import { Subscription } from 'rxjs';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspItemSimpleSearch, RspItemSimpleDto } from 'src/app/response/rsp-item-simple-seach';
import { ItemSimpleRec } from 'src/app/common/item-simple-rec';

@Component({
  selector: 'app-item-list-simple',
  templateUrl: './item-list-simple.component.html',
  styleUrls: ['./item-list-simple.component.css']
})
export class ItemListSimpleComponent implements OnInit, OnDestroy {

  public tableWidth: any;
  public pagenatorMaxWidth: any;
  public displayColumnIds: string[];
  public recordCount: number;
  public itemSimpeList: ItemSimpleRec[];
  public itemSelected: ItemSimpleRec;
  public displayColumnDefs: TableColumnDef[] = [];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'itemCdFv', header: '商品コード', width: 95, align: 'center' },
    { columnId: 'itemNameFv', header: '商品名', width: 300 },
    { columnId: 'standardFv', header: '規格', width: 150 },
    { columnId: 'ctg0Fv', header: this.commonService.literal.ctg0Name, width: 200 },
    { columnId: 'ctg1Fv', header: this.commonService.literal.ctg1Name, width: 200 },
    { columnId: 'ctg2Fv', header: this.commonService.literal.ctg2Name, width: 200 },
    { columnId: 'ctg3Fv', header: this.commonService.literal.ctg3Name, width: 200 }
  ];

  private subscriptionSearch: Subscription;
  private subscriptionDialog: Subscription;

  @Input('itemSelectSimpleCondition') public itemSelectSimpleCondition: ItemSelectSimpleCondition;
  @Input("orderStopFlagValues") public orderStopFlagValues: number[];
  @Input("withoutCase") public withoutCase: boolean;
  @Output('itemChange') public clickItem: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatPaginator, { static: true }) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    for (let colDef of this.columnDefs) {
      if (colDef.columnId === 'itemCdFv') { colDef.editable = true; }
      if (!colDef.columnId.startsWith("ctg")) {
        this.displayColumnDefs.push(colDef);
      } else {
        let level = parseInt(colDef.columnId.substring(3, 4));
        if (level < this.commonService.config.maxCtgLevel) {
          this.displayColumnDefs.push(colDef);
        }
      }
    }
    this.setColumnId();
    this.calcTableWidth();
    if (!this.orderStopFlagValues) this.orderStopFlagValues = this.commonService.getOrderStopValues();
    if (this.withoutCase === undefined) this.withoutCase = false;
  }

  ngOnDestroy() {
    if (this.subscriptionSearch) { this.subscriptionSearch.unsubscribe(); }
    if (this.subscriptionDialog) { this.subscriptionDialog.unsubscribe(); }
  }

  styleForHeader(id: string) {
    for (let colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          'width': '' + colDef.width + 'px',
          'min-width': '' + colDef.width + 'px',
          'max-width': '' + colDef.width + 'px'
        };
      }
    }
  }

  styleFor(id: string) {
    for (let colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          'width': '' + colDef.width + 'px',
          'min-width': '' + colDef.width + 'px',
          'max-width': '' + colDef.width + 'px',
          'text-align': colDef.align ? colDef.align : 'left'
        };
      }
    }
  }

  setColumnId() {
    this.displayColumnIds = [];
    for (let colDef of this.displayColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
  }

  calcTableWidth() {
    let width = 1;   // For left border
    for (let colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { 'width': '' + width + 'px' };
    this.pagenatorMaxWidth = { 'max-width': '' + width + 'px' };
  }

  pageChanged(pageEvent: PageEvent) {
    if (this.itemSimpeList == undefined || this.itemSimpeList.length == 0) return;
    this.doQueryBody();
  }

  doQuery() {
    this.matPagenator.pageIndex = 0;
    this.doQueryBody();
  }

  doQueryBody() {
    this.itemSelected = undefined;

    let itemSelectSimpleCoditionForm: FormGroup = this.itemSelectSimpleCondition.formGroup;
    let categorySearchCondition: FormGroup = this.itemSelectSimpleCondition.ctgSelectCondition.formGroup;
    let request: ReqItemSimpleSearch = {
      access: { ...this.commonService.loginUser },
      itemSimpleSeachCondition: {
        storeCdFv: itemSelectSimpleCoditionForm.get('storeCd').value,
        ctgCd0Fv: categorySearchCondition.get('ctgLevel').value >= 0 ?
          categorySearchCondition.get('ctgCd0').value : '',
        ctgCd1Fv: categorySearchCondition.get('ctgLevel').value >= 1 ?
          categorySearchCondition.get('ctgCd1').value : '',
        ctgCd2Fv: categorySearchCondition.get('ctgLevel').value >= 2 ?
          categorySearchCondition.get('ctgCd2').value : '',
        ctgCd3Fv: categorySearchCondition.get('ctgLevel').value >= 3 ?
          categorySearchCondition.get('ctgCd3').value : '',
        itemCdFv: itemSelectSimpleCoditionForm.get('itemCode').value,
        itemNameFv: itemSelectSimpleCoditionForm.get('itemName').value,
        orderStopFlags: this.orderStopFlagValues,
        withoutCase: this.withoutCase
      },
      page: {
        pageNum: this.matPagenator.pageIndex,
        dispNum: this.matPagenator.pageSize
      }

    };

    this.subscriptionSearch = this.httpBasic.itemSimpleSearch(request).subscribe(
      data => this.SearchResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }

  SearchResult(data: RspItemSimpleSearch) {
    this.clearProgressState();
    let rspItemSimpleSearch: RspItemSimpleSearch = { ...data };

    if (rspItemSimpleSearch.fatalError && rspItemSimpleSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspItemSimpleSearch.fatalError[0].errMsg);
    }
    if (rspItemSimpleSearch.normalError && rspItemSimpleSearch.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspItemSimpleSearch.normalError[0].errMsg);
      return;
    }

    this.itemSimpeList = [];
    this.recordCount = rspItemSimpleSearch.recordCount;

    if (this.recordCount > 0) {
      let rspItemSimpleDtos: RspItemSimpleDto[] = [...rspItemSimpleSearch.rows];
      for (let rspItemSimpleDto of rspItemSimpleDtos) {
        this.itemSimpeList.push(new ItemSimpleRec(rspItemSimpleDto, this.commonService));
      }
    }
  }

  onClickCell(columnId: string, itemSimpleRec: ItemSimpleRec) {
    if (columnId == "itemCdFv") {
      this.itemSelected = itemSimpleRec;
      this.clickItem.emit(this.itemSelected);
    }
  }

}

