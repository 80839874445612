import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

export interface TaskItem {
  taskId:         number;
  dateRange:      string;
  taskName:       string;
  author:         string;
  priority:       string;
  subTasks:       SubTaskItem[];
}

export interface SubTaskItem {
  taskId:       number;
  subTaskId:    number;
  subTaskName:  string;
  check?:       FormControl;
}

export interface commentItem {
  taskId:       number;
  subTaskId:    number;
  subTaskName:  string;
  storeCd:      string;
  storeName:    string;
  store:        string;
  comment:      string;
}

@Component({
  selector: 'app-task-whole-status-comments',
  templateUrl: './task-whole-status-comments.component.html',
  styleUrls: ['./task-whole-status-comments.component.css']
})
export class TaskWholeStatusCommentsComponent implements OnInit, OnDestroy, OnChanges {

  @Input("condForm") condForm: FormGroup;

  public taskList: TaskItem[] = [];
  public columnIdsTask: string[] = ["dateRange", "taskName", "author", "priority"];
  public columnDefsTask: TableColumnDef[] = [
    {columnId: "dateRange", header: "期間", width: 140},
    {columnId: "taskName", header: "タスク名", width: 200},
    {columnId: "author", header: "指示者", width: 100},
    {columnId: "priority", header: "優先度", width: 50, align: "center"}
  ];
  public subTaskList: SubTaskItem[] = [];
  public columnIdsSubTask: string[] = ["check", "subTaskName"];
  public columnDefsSubTask: TableColumnDef[] = [
    {columnId: "check", header: "選択", width: 40, align: "center"},
    {columnId: "subTaskName", header: "子タスク名", width: 200}
  ];
  public commentList: commentItem[];
  public columnIdscomment: string[] = ["subTaskName", "store", "comment"];
  public columnDefscomment: TableColumnDef[] = [
    {columnId: "subTaskName", header: "子タスク名", width: 200},
    {columnId: "store", header: "店舗", width: 150},
    {columnId: "comment", header: "コメント", width: 300}
  ];

  public selectedTask: TaskItem;
  public showTypeForm: FormControl = new FormControl('1');

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService
  ) { }

  ngOnInit(): void {
    this.doQuery();
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    this.setTableHeightLeft();
    this.setTableHeightRight();
  }

  setTableHeightLeft() {
    let taskCount = this.taskList.length;
    let subTaskCount = this.subTaskList.length;

    let taskId = "task-list-box";
    let subTaskId = "sub-task-list-box";

    let remHeight = this.commonService.getHeightBelow(taskId);
    // let paginatorHeight = 56;
    let paginatorHeight = 0;
    let margin = 20 + 5 * 3;
    let fieldGroup = 24 * 3;
    let height = remHeight - paginatorHeight - margin - fieldGroup;
    let taskMin = 26 + Math.min(taskCount, 5) * 24;
    let subTaskMin = 26 + Math.min(subTaskCount, 3) * 24;

    let taskElem = document.getElementById(taskId);
    let subTaskElem = document.getElementById(subTaskId);
    if (taskElem) taskElem.style.maxHeight = "" + height + "px";
    if (subTaskElem) subTaskElem.style.maxHeight = "" + height + "px";
    let taskHeight = taskElem.getBoundingClientRect().height;
    let subTaskHeight = subTaskElem.getBoundingClientRect().height;

    if (height < taskHeight + subTaskHeight) {
      // subTaskHeight = height * (subTaskHeight / (taskHeight + subTaskHeight));
      subTaskHeight = Math.floor(height / 2);
      if (subTaskHeight < subTaskMin) subTaskHeight = subTaskMin;
      taskHeight = height - subTaskHeight;
      if (taskHeight < taskMin) taskHeight = taskMin;
      if (taskElem) taskElem.style.maxHeight = "" + taskHeight + "px";
      if (subTaskElem) subTaskElem.style.maxHeight = "" + subTaskHeight + "px";
    }
  }

  setTableHeightRight() {
    let id = "comment-list-box";
    let elem = document.getElementById(id);
    if (!elem) return;

    let remHeight = this.commonService.getHeightBelow(id);
    // let paginatorHeight = 56;
    let paginatorHeight = 0;
    let margin = 5;
    let height = remHeight - paginatorHeight - margin;
    if (height < 200) height = 200;
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  subTaskSelectAll(mode: boolean) {
    if (!this.subTaskList) return;
    this.subTaskList.forEach((subTask) => subTask.check.setValue(mode));
  }

  disableQuery() {
    if (!this.selectedTask) return true;
    for (let i = 0; i < this.subTaskList.length; i++) {
      if (this.subTaskList[i].check.value === true) return false;
    }
    return true;
  }

  doQuery() {

    // TODO




    // For Mock
    this.taskList = [
      { taskId: 1,
        dateRange: "2022/08/12~2022/08/30",
        taskName: "ワイン縦型POP",
        author: "店舗内業務推進部",
        priority: "A",
        subTasks: [
          {taskId: 1, subTaskId: 0, subTaskName: "（タスク全体）"},
          {taskId: 1, subTaskId: 1, subTaskName: "子タスク-1"},
          {taskId: 1, subTaskId: 2, subTaskName: "子タスク-2"},
          {taskId: 1, subTaskId: 3, subTaskName: "子タスク-3"}
        ]
      },
      { taskId: 2,
        dateRange: "2022/08/06~2022/09/21",
        taskName: "7/15業務指示②-9：【作業】アカランクッションピーリング・クレンジング展開について",
        author: "商品本部",
        priority: "B",
        subTasks: [
          {taskId: 2, subTaskId: 0, subTaskName: "（タスク全体）"},
          {taskId: 2, subTaskId: 1, subTaskName: "子タスク-1"},
          {taskId: 2, subTaskId: 2, subTaskName: "子タスク-2"},
          {taskId: 2, subTaskId: 3, subTaskName: "子タスク-3"},
          {taskId: 2, subTaskId: 4, subTaskName: "子タスク-4"},
          {taskId: 2, subTaskId: 5, subTaskName: "子タスク-5"},
        ]
      }
    ];

    setTimeout(() => {this.setTableHeightLeft();}, 0);
  }

  doCommentQuery() {
  
    // TODO

    // For Mock
    this.commentList = [
      {taskId: 1, subTaskId: 0, subTaskName: "（タスク全体）", store: "0001：店舗-A", storeCd: "", storeName: "", comment: "コメント-A-0"},
      {taskId: 1, subTaskId: 0, subTaskName: "（タスク全体）", store: "0002：店舗-B", storeCd: "", storeName: "", comment: "コメント-B-0"},
      {taskId: 1, subTaskId: 0, subTaskName: "（タスク全体）", store: "0003：店舗-C", storeCd: "", storeName: "", comment: "コメント-C-0"},
      {taskId: 1, subTaskId: 1, subTaskName: "子タスク-1", store: "0001：店舗-A", storeCd: "", storeName: "", comment: "コメント-A-1"},
      {taskId: 1, subTaskId: 1, subTaskName: "子タスク-1", store: "0002：店舗-B", storeCd: "", storeName: "", comment: "コメント-B-1"},
      {taskId: 1, subTaskId: 1, subTaskName: "子タスク-1", store: "0003：店舗-C", storeCd: "", storeName: "", comment: "コメント-C-1"},
      {taskId: 1, subTaskId: 2, subTaskName: "子タスク-2", store: "0001：店舗-A", storeCd: "", storeName: "", comment: "コメント-A-2"},
      {taskId: 1, subTaskId: 2, subTaskName: "子タスク-2", store: "0002：店舗-B", storeCd: "", storeName: "", comment: "コメント-B-2"},
      {taskId: 1, subTaskId: 2, subTaskName: "子タスク-2", store: "0003：店舗-C", storeCd: "", storeName: "", comment: "コメント-C-2"},
    ];

    setTimeout(() => {this.setTableHeightRight();}, 0);
  }

  selectTask(item: TaskItem) {
    this.subTaskList = [];
    this.commentList = undefined;
    this.selectedTask = item;
    this.buildTaskTaskList();

    setTimeout(() => {this.setTableHeightLeft();}, 0);
  }

  buildTaskTaskList() {
    if (!this.selectedTask) return;
    this.subTaskList = [];
    this.selectedTask.subTasks.forEach((subTask) => {
      if (!subTask.check) subTask.check = new FormControl(true);
      this.subTaskList.push(subTask);
    });
  }
}
