import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/service/common.service";
import { ExpiryMasterDateDef } from "./expiry-master-rec";
import { UpdateExpiryAlertDto } from "src/app/webservice/item-expiry";
import { OrderGroupDto } from "src/app/webservice/order-group";

export interface ExpiryAlertDesc {
  alertType:          number;
  itemDate:          string;
  alertRank:          number;
  alertStartDate:     string;
  alertMsg:           string;
  isCompleted:        boolean;
  isSoldOutDate:      boolean;
  isSoldOutItem:      boolean;
  edit?: {
    isDirty:          boolean;
    formStatus:       FormControl;
    formPrintCount:   FormControl;
  }
}

export class ExpiryAlertRec {

  public storeCd: string                = "";
  public itemCd: string                 = "";
  public itemName: string               = "";
  public itemStandard: string           = "";
  public itemSalesPrice: number         = 0;
  public itemTax: number                = 8;
  public groupId: number                = -1;
  public groupUsrCd: string             = "";
  public groupName: string              = "";
  public expiryType: number             = 1;
  public discountType: number           = 1;
  public dateDef: ExpiryMasterDateDef[] = [];
  public alertDescs:  ExpiryAlertDesc[] = [];
  public itemGroups: OrderGroupDto[] = [];

  private today: Date;
  private targetDate: Date;

  private subsc: Subscription[] = [];
  private changeListener: ()=>void;

  constructor(private commonService: CommonService) {
    let tmp = new Date();
    this.today = new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate());
    this.targetDate = this.commonService.copyDate(this.today);
  }

  setChangeListener(func: ()=>void) {
    this.changeListener = func;
  }

  prepareEdit() {
    this.alertDescs.forEach((desc) => {
      let formVal = 0;
      if (desc.alertType === -1) {
        if (desc.isSoldOutItem || desc.isSoldOutDate || desc.isCompleted) {
          formVal = 1;
        } else {
          formVal = 0;
        }
      } else {
        if (desc.isSoldOutItem) {
          formVal = 3;
        } else if (desc.isSoldOutDate) {
          formVal = 2;
        } else if (desc.isCompleted) {
          formVal = 1;
        } else {
          formVal = 0;
        }
      }
      desc.edit = {
        isDirty: false,
        formStatus: new FormControl("" + formVal),
        formPrintCount: new FormControl(1)
      };
      this.subsc.push(desc.edit.formStatus.valueChanges.subscribe((val) => {
        let mydesc = desc;
        desc.edit.isDirty = true;
        if (this.changeListener) this.changeListener();
        /*
        if (val === "3") {
          this.alertDescs.forEach((desc) => {
            if (desc !== mydesc && desc.edit.formStatus.value !== "3") {
              desc.edit.formStatus.setValue("3", {emitEvent: false});
              desc.edit.isDirty = true;
            }
          });
        } else {
          this.alertDescs.forEach((desc) => {
            if (desc !== mydesc && desc.edit.formStatus.value === "3") {
              desc.edit.formStatus.setValue("2", {emitEvent: false});
              desc.edit.isDirty = true;
            }
          });
        }
        */
      }));
    });
  }

  getSaveData(): UpdateExpiryAlertDto[] {
    let dtos: UpdateExpiryAlertDto[] = [];

    for (let i = 0; i < this.alertDescs.length; i++) {
      let desc = this.alertDescs[i];
      if (!desc.edit.isDirty) continue;
      let status = this.getStatus(desc);
      let dto: UpdateExpiryAlertDto = {
        mode:               "update",
        storeCd:            this.storeCd,
        itemCd:             this.itemCd,
        itemName:           this.itemName,
        itemStandard:       this.itemStandard,
        itemSalesPrice:     this.itemSalesPrice,
        itemTax:            this.itemTax,
        groupId:            this.groupId,
        groupUsrCd:         this.groupUsrCd,
        groupName:          this.groupName,
        alertType:          desc.alertType,
        itemDate:           desc.itemDate,
        alertRank:          desc.alertRank,
        alertStartDate:     desc.alertStartDate,
        alertMsg:           desc.alertMsg,
        isCompleted:        status.isCompleted,
        isSoldOutDate:      status.isSoldOutDate,
        isSoldOutItem:      status.isSoldOutItem,
        expiryType:         this.expiryType,
        discountType:       this.discountType
      };
      dtos.push(dto);
    }

    return dtos;
  }

  commitEdit() {
    this.alertDescs.forEach((desc) => {
      if (desc.edit?.isDirty) {
        let status = this.getStatus(desc);
        desc.isCompleted = status.isCompleted;
        desc.isSoldOutDate = status.isSoldOutDate;
        desc.isSoldOutItem = status.isSoldOutItem;
        desc.edit.isDirty = false;
      }
    });
  }

  getStatus(desc: ExpiryAlertDesc) {
    let formVal = parseInt(desc.edit?.formStatus.value);
    if (formVal === 0) {
      return {
        isCompleted: false,
        isSoldOutDate: false,
        isSoldOutItem: false
      };
    } else if (formVal === 1) {
      return {
        isCompleted: true,
        isSoldOutDate: false,
        isSoldOutItem: false
      };
    } else if (formVal === 2) {
      return {
        isCompleted: true,
        isSoldOutDate: true,
        isSoldOutItem: false
      };
    } else if (formVal === 3) {
      return {
        isCompleted: true,
        isSoldOutDate: true,
        isSoldOutItem: true
      }
    };
    return {
      isCompleted: false,
      isSoldOutDate: false,
      isSoldOutItem: false
    };
  }


  endEdit() {
    this.cleanupEdit();
  }

  clearEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  cleanupEdit() {
    this.subsc.forEach((subsc) => {subsc.unsubscribe();});
    this.subsc = [];
    this.alertDescs.forEach((desc) => { if (desc.edit) delete desc.edit; });
  }

  isDirty() {
    for (let i = 0; i < this.alertDescs.length; i++) {
      if (this.alertDescs[i].edit?.isDirty) return true;
    }
    return false;
  }

  getValue(desc: ExpiryAlertDesc, key: string) {
    switch(key) {
      case "expiryDate": {
        if (desc.alertType === -1) return "";
        if (desc.alertType === 2) return "";
        if (this.expiryType === 2) return "";
        return desc.itemDate;
      }
      case "productionDate": {
        if (desc.alertType === -1) return "";
        if (desc.alertType === 2) return "";
        if (this.expiryType === 1) return "";
        return desc.itemDate;
      }
      case "alertMsg": {
        return desc.alertMsg;
      }
      case "alertStartDate": {
        return desc.alertStartDate;
      }
      default:
        return "";
    }
  }

  getColor(desc) {
    let white = {"color": "black", "background-color": "white"};
    let red = {"color": "#FF0000", "background-color": "#FBE5D6", "font-weight": "bold"};

    if (desc.alertType === 2) return white;
    let rank = desc.alertRank - 1;
    if (rank < 0) return red;
    if (rank > 4) return red;
    return {
      "color": this.commonService.config.itemExpiry.colors[rank].fg,
      "background-color": this.commonService.config.itemExpiry.colors[rank].bg,
      "font-weight": "bold"
    };
  }

  setSoldOutItem() {
    this.alertDescs.forEach((desc) => {
      if (desc.alertType === 1 && desc.edit.formStatus.value !== "2") {
        desc.edit.formStatus.setValue("2", {emitEvent: false});
        desc.edit.isDirty = true;
        if (this.changeListener) this.changeListener();
      }
    });
  }

  hasItemDateAlert() {
    for (let i = 0; i < this.alertDescs.length; i++) {
      if (this.alertDescs[i].alertType === 1) return true;
    }
    return false;
  }
}
