import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Rsp } from 'src/app/response/rsp';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { TaskService } from '../../1_service/taskService';
import { Router } from '@angular/router';
import { WholeStatusExcel } from '../../excel/wholeStatusExcel';

export interface Store {
  storeGroup:     string;
  storeCd:        string;
  storeName:      string;
}

export interface StoreGroup {
  storeGroup:     string;
  count:          number;
}

export interface StoreStatus {
  storeGroup:     string;
  storeCd:        string;
  storeName:      string;
  responsible:    string;
  comment:        string;
  isComplete:     boolean;
  workTimeResult: number;
}

export interface StatusData {
  dateRange:                string;
  dateBegin:                string;
  dateEnd:                  string;
  taskId:                   number;
  taskName:                 string;
  author:                   string;
  priority:                 string;
  workTime:                 number;
  progress:                 string;
  workTimeResultSum?:       number;
  workTimeResultAvg?:       number;
  storeStatus:              StoreStatus[];
}

export interface ListData {
  rowHeader:      boolean;
  rowDataType:    string;
  rowData:        StatusData;
}

export interface ResTaskWholeStatusProgress extends Rsp {
  statusList: StatusData[];
}

@Component({
  selector: 'app-task-whole-status-progress',
  templateUrl: './task-whole-status-progress.component.html',
  styleUrls: ['./task-whole-status-progress.component.css']
})
export class TaskWholeStatusProgressComponent implements OnInit, OnDestroy, OnChanges {

  private allStores: Store[];
  private allStoreGroups: StoreGroup[];
  private statusData: StatusData[];

  public listData: ListData[];

  public columnIdsHeader: string[] = ["detail", "plannedTime", "groupType"]; 
  public columnDefsHeader: TableColumnDef[] = [
    {columnId: "detail", header: "タスク詳細", width: 60, align: "center", colspan: 5},
    {columnId: "plannedTime", header: "計画時間", width: 40, align: "center", colspan: 1},
    {columnId: "groupType", header: "区分", width: 100, align: "center", colspan: 1}
  ];

  public storeGroupHeaderDef = {width: 80, colspan: 1};
  public columnIdsStoreGroupHeader: string[]; 
  public columnDefsStoreGroupHeader: TableColumnDef[];

  public storeCdHeaderDef = {width: 80};
  public columnIdsStoreCdHeader: string[];
  public columnDefsStoreCdHeader: TableColumnDef[];

  public storeNameHeaderDef = {width: 80, align: "center"};
  public columnIdsStoreNameHeader: string[]; 
  public columnDefsStoreNameHeader: TableColumnDef[];

  public columnIdsRowHeader: string[] = ["dateRange", "taskName", "author", "priority", "progress", "workTimeResultAvg", "rowDataType"];
  public columnDefsRowHeader: TableColumnDef[] = [
    {columnId: "dateRange", header: "期間", width: 70, align: "left"},
    {columnId: "taskName", header: "タスク名", width: 200, align: "left"},
    {columnId: "author", header: "指示者", width: 60, align: "left"},
    {columnId: "priority", header: "優先度", width: 40, align: "left"},
    {columnId: "progress", header: "進捗", width: 40, align: "center"},
    // {columnId: "workTime", header: "時間", width: 40, align: "center"},
    {columnId: "workTimeResultAvg", header: "実績平均", width: 40, align: "center"},
    {columnId: "rowDataType", header: "店名", width: 55, align: "left"}
  ];

  public columnIds1: string[];
  public columnIds2: string[];
  public columnIds3: string[];

  private stickyPosition: Object;

  @Input("condForm") condForm: FormGroup;

  constructor(public commonService: CommonService,
    private httpBasic: HttpBasicService,
    public taskService:   TaskService,
    private router : Router
    ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "list-table";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 0;
    let margin = 5;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem != undefined) elem.style.maxHeight = "" + height + "px";
  }

  getRequest() {
    let request = {
      access: this.commonService.loginUser,
      includeNonAutoOrderStoreTask: this.commonService.config.includeNonAutoOrderStore.task,
      authorFv: this.condForm.get('authorFv').value.trim(),
      releaseStatus: parseInt(this.condForm.get('releaseStatusValue').value), 
      responsibleClass: this.condForm.get('responsibleClass').value.trim(),
      storeGroupType: this.condForm.get('storeGroupType').value,
      storeGroup: this.condForm.get('storeGroup').value,
      taskName: this.condForm.get('taskName').value.trim(),
      taskStatus: [],
    }
    if (this.condForm.get("statusNotStarted").value) request.taskStatus.push(this.taskService.taskStatusNotStarted);
    if (this.condForm.get("statusStarted").value) request.taskStatus.push(this.taskService.taskStatusStarted);
    if (this.condForm.get("statusCompleted").value) request.taskStatus.push(this.taskService.taskStatusCompleted);
    return request;
  }

  doQuery(condDateEnd) {
    this.statusData = [];
    this.columnIdsStoreGroupHeader = [];
    this.columnDefsStoreGroupHeader = [];
    this.columnIdsStoreCdHeader = [];
    this.columnDefsStoreCdHeader = [];
    this.columnIdsStoreNameHeader = [];
    this.columnDefsStoreNameHeader = [];
    this.listData = undefined;

    let req = {...this.getRequest(), ...condDateEnd}
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.generalRequest("TaskWholeStatusProgress", req).subscribe(
      (response: ResTaskWholeStatusProgress) => {
        this.receiveTaskWholeStatusProgress(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveTaskWholeStatusProgress(response: ResTaskWholeStatusProgress) {
    if (this.httpBasic.handleAppError(response)) {
      return;
    }
    this.commonService.closeSpinner();
    response.statusList.forEach(row => {
      let sum = 0;
      let sumAvg = 0;
      let cntAvg = 0; 
      row.storeStatus.forEach((dto) => {
        sum += dto.workTimeResult;
        if (dto.isComplete) {
          sumAvg += dto.workTimeResult;
          cntAvg++;
        }
      });
      row.workTimeResultSum = sum;
      if (cntAvg > 0 && sumAvg > 0) {
        row.workTimeResultAvg = Math.round(sumAvg / cntAvg);
      } else {
        row.workTimeResultAvg = 0;
      }
      this.statusData.push(row);
    });
    this.buildListData();
    this.buildColumnDef();
    this.setTableHeight();
    document.getElementById("list-table")?.scrollTo(0, 0);
  }

  buildColumnDef() {
    this.columnIdsStoreGroupHeader = [];
    this.columnDefsStoreGroupHeader = [];
    let i = 0;
    this.allStoreGroups.forEach((group) => {
      this.columnIdsStoreGroupHeader.push("group-" + i);
      this.columnDefsStoreGroupHeader.push({
        columnId: "group-" + i,
        header: group.storeGroup,
        width: this.storeGroupHeaderDef.width,
        colspan: group.count
      });
      i++;
    });

    this.columnIdsStoreCdHeader = [];
    this.columnDefsStoreCdHeader = [];
    this.columnIdsStoreNameHeader = [];
    this.columnDefsStoreNameHeader = [];
    this.allStores.forEach((store) => {
      this.columnIdsStoreCdHeader.push("cd-" + store.storeCd);
      this.columnDefsStoreCdHeader.push({
        columnId: "cd-" + store.storeCd,
        header: store.storeCd,
        ...this.storeCdHeaderDef
      })
      this.columnIdsStoreNameHeader.push("name-" + store.storeCd);
      this.columnDefsStoreNameHeader.push({
        columnId: "name-" + store.storeCd,
        header: store.storeName,
        ...this.storeNameHeaderDef
      })
    });

    this.columnIds1 = [...this.columnIdsHeader, ...this.columnIdsStoreGroupHeader];
    this.columnIds2 = ['workTimeResultSum', 'storeCd', ...this.columnIdsStoreCdHeader];
    this.columnIds3 = [...this.columnIdsRowHeader, ...this.columnIdsStoreNameHeader];

    this.buildStickyPos();
  }

  buildStickyPos() {
    this.stickyPosition = {};
    let current = 0;
    let leftBorader = 1;
    this.columnIdsRowHeader.forEach((colId) => {
      this.stickyPosition[colId] = current;
      let colDef = this.findColDef(colId);
      let width = leftBorader + colDef.width + 1 + 8;    // 1 for right border, 8 for padding;
      leftBorader = 0;
      current += width;
    });
  }

  findColDef(key: string) {
    return this.columnDefsRowHeader.find((item) => item.columnId === key);
  }

  styleForSticky(key: string, header?: number) {
    let style = {
      "position": "sticky",
      "left": "" + this.stickyPosition[key] + "px",
      "z-index": header !== undefined ? 101 : 100
    };
    if (header !== undefined) {
      style["top"] = "" + (24 * (header - 1)) + "px"
    }

    return style;
  }

  buildListData() {
    this.allStores = [];
    this.statusData.forEach((statusData) => {
      statusData.storeStatus.forEach((storeStatus) => {
        if (!this.allStores.find((store) => store.storeCd === storeStatus.storeCd)) {
          this.allStores.push({
            storeGroup: storeStatus.storeGroup,
            storeCd: storeStatus.storeCd,
            storeName: storeStatus.storeName
          });
        }
      })
    });
    this.allStores.sort((a, b) => {
      if (a.storeGroup < b.storeGroup) return -1;
      if (a.storeGroup > b.storeGroup) return 1;
      /*
      if (a.storeCd < b.storeCd) return -1;
      if (a.storeCd > b.storeCd) return 1;
      */
      if (this.commonService.zeroPadding(a.storeCd, 100) < this.commonService.zeroPadding(b.storeCd, 100)) return -1;
      if (this.commonService.zeroPadding(a.storeCd, 100) > this.commonService.zeroPadding(b.storeCd, 100)) return 1;
      return 0;
    });

    this.allStoreGroups = [];
    this.allStores.forEach((store) => {
      let storeGroup = this.allStoreGroups.find((group) => group.storeGroup === store.storeGroup);
      if (storeGroup) {
        storeGroup.count++;
      } else {
        this.allStoreGroups.push({
          storeGroup: store.storeGroup,
          count: 1.
        });
      }
    });

    this.statusData.forEach((statusData) => {
      let storeStatus: StoreStatus[] = [];
      this.allStores.forEach((aStore) => {
        let status = statusData.storeStatus.find((store) => store.storeCd === aStore.storeCd);
        if (status) {
          storeStatus.push(status);
        } else {
          storeStatus.push({
            storeGroup: aStore.storeGroup,
            storeCd: aStore.storeCd,
            storeName: aStore.storeName,
            responsible: "対象外",
            comment: "",
            workTimeResult: 0,
            isComplete: false
          });
        }
      });
      statusData.storeStatus = storeStatus;
    });
    this.statusData.sort((a, b) => {
      if (a.dateEnd < b.dateEnd) return -1;
      if (a.dateEnd > b.dateEnd) return 1;
      return 0;
    });
    this.listData = [];
    this.statusData.forEach((statusData) => {
      this.listData.push({
        rowHeader:      true,
        rowDataType:    "担当者",
        rowData:        statusData
      });
      this.listData.push({
        rowHeader:      false,
        rowDataType:    "作業時間",
        rowData:        statusData
      });
      this.listData.push({
        rowHeader:      false,
        rowDataType:    "コメント",
        rowData:        statusData
      });
    });
  }

  getProgress(item: ListData) {
    let progress = item.rowData.progress;
    let tmp = progress.split("/");
    if (tmp.length < 2) return progress;
    return progress + "<br>" + Math.round((parseInt(tmp[0].trim()) / parseInt(tmp[1].trim())) * 100) + "%";
  }

  getRowData(item: ListData, key: string) {
    let storeCd = key.replace("name-", "");
    let store = item.rowData.storeStatus.find((item) => item.storeCd === storeCd);
    if (item.rowDataType === "担当者") {
      if (store.responsible === "") return "未設定";
      return store.responsible;
    }
    if (item.rowDataType === "作業時間") {
      if (store.responsible === "") return "";
      if (store.responsible === "対象外") return "";
      if (!store.isComplete) return "未完了：" + store.workTimeResult
      return store.workTimeResult;
    }
    if (item.rowDataType === "コメント") {
      if (store.responsible === "") return "";
      if (store.responsible === "対象外") return "";
      return store.comment;
    }
    return "";
  }

  getColor(item: ListData, key: string) {
    let storeCd = key.replace("name-", "");
    let store = item.rowData.storeStatus.find((item) => item.storeCd === storeCd);
    if (!store) return 0;
    if (item.rowDataType == "担当者") {
      if (store.responsible === "") return 1;         // Red
      if (store.responsible === "対象外") return 2;   // Lite gray
    } else if (item.rowDataType == "作業時間") {
      if (store.responsible === "") return 1;                       // Red
      if (store.responsible === "対象外") return 2;                  // Lite gray
      if (!store.isComplete) return 3;                              // Pink
      if (item.rowData.workTime < store.workTimeResult) return 4;   // Yellow
    } else {
      if (store.responsible === "") return 1;         // Red
      if (store.responsible === "対象外") return 2;   // Lite gray
    }
    return 0;
  }

  taskOnClick(item: ListData) {
    let menuItem = this.getMenuItem("taskHq");
    if (!menuItem) return;

    let today: Date = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let endDate:Date = this.commonService.getDate(item.rowData.dateEnd);
    let diffDay = Math.floor((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

    this.commonService.taskSearchCond = {
      storeCd:                "",
      releaseStatus:          "",
      author:                 "",
      statusNotAssigned:      true,
      statusNotStarted:       true,
      statusStarted:          true,
      statusCompleted:        true,
      endDate:                true,
      endDateFrom:            0,
      endDateTo:              0,
      endDateFromDay:         diffDay,
      endDateToDay:           diffDay,
      responsibleClass:       "",
      responsible:            "",
      responsiblePartial:     true,
      taskName:               item.rowData.taskName,
      subStatusNotCompleted:  true,
      subStatusCompleted:     true,
      subDateYesterday:       true,
      subDateToday:           true,
      subDateTommorow:        true,
      subResponsible:         "",
      subResponsiblePartial:  true,
      taskCategory:           ""
    }
    this.commonService.taskOpenTaskId = item.rowData.taskId;

    this.commonService.pageMenuName = menuItem.menuName;
    this.router.navigate(["taskHq"], {queryParams: {rn: Math.random()}});
  }

  getMenuItem(path: string) {
    for (let menuGroup of this.commonService.menuGroups) {
      for (let menuItem of menuGroup.menuItems) {
        if (path === menuItem.menuPath) return menuItem;
      }
    }
    return undefined;
  }

  exportExcel() {
    let excel = new WholeStatusExcel(this.commonService);
    excel.createAndDownload(
      this.listData,
      this.columnDefsStoreGroupHeader,
      this.columnDefsStoreCdHeader,
      this.columnDefsStoreNameHeader
    );
  }

}
