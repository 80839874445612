
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserDto } from 'src/app/response/rsp-get-users';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqUpdateTaskResultRec, RspUpdateTaskResultRec, TaskImageRecDto } from 'src/app/webservice/task';
import { PhotoItem, SubTask, SubTaskResult } from '../0_def/taskDefs';
import { TaskStoreRec } from '../0_def/taskStoreRec';
import { TaskService } from '../1_service/taskService';
import { TaskPhotoDialogComponent } from '../photo-dialog/task-photo-dialog.component';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-store-edit2',
  templateUrl: './store-edit2.component.html',
  styleUrls: ['./store-edit2.component.css']
})
export class StoreEdit2Component implements  OnInit, OnDestroy, OnChanges  {

  public editTaskStore: TaskStoreRec;
  public minDate: Date;
  public dateBegin: Date;
  public dateEnd: Date;
  public formFile: FormControl = new FormControl();
  public filename: string = "";
  public isFileReadError: boolean = false;
  public selectedSubTask: SubTask;
  public selectedSubTaskResult: SubTaskResult;
  public selectedPhoto: PhotoItem;
  public showResponsileSelector: boolean = false;
  public showResponsileSelectorSub: boolean = false;
  public staffList: string[] = [...this.taskService.staffListAll];
  public userList: UserDto[] = [];

  public subTasks: SubTask[];
  // public columnIds: string[] = ['summary', 'description', 'checkBoxPhoto', 'previewFile', 'downloadFile', 'responsible', 'checkBoxComplete', 'completeDateTime'];
  public columnIds: string[] = ["summary", "subTaskInput", "subTaskPhoto"];

  private subscResponse: Subscription[] = [];
  private subscriptionNotifier: Subscription;

  public  filteredOptions: Observable<string[]>;

  @Input("taskStoreRec") taskStoreRec: TaskStoreRec;
  @Input() notifier: Subject<any>;
  @Output() viewTaskOtherStoreChanged: EventEmitter<any> = new EventEmitter();
  @Output() viewTaskOtherStoreCommentChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild("editTable", {read: MatTable, static: false}) editTable: MatTable<any>;
  // @ViewChild("photoTable", {read: MatTable, static: false}) photoTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    private httpBasic:    HttpBasicService,
    public taskService:   TaskService,
    private cdr: ChangeDetectorRef
    ) {
      this.minDate = new Date();
    }

  ngOnInit(): void {
    this.subscriptionNotifier = this.notifier.subscribe(action => {
      this.selectedSubTask = undefined;
      this.selectedSubTaskResult = undefined;
    });
  }

  ngOnDestroy(): void {
    this.taskService.endEditStore();
    this.subscResponse.forEach((subsc) => subsc.unsubscribe());
    this.subscriptionNotifier.unsubscribe();
  }

  // @HostListener('window:resize', ['$event'])
  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "sub-task-table-box";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight !== undefined) {
      let paginatorHeight = 0;
      let margin = 10 + 5;
      let btnBox = 24;
      let height = remHeight - paginatorHeight - margin - btnBox;
      if (height < 270) height = 270;
  
      let elem = document.getElementById(id);
      if (elem) elem.style.maxHeight = "" + height + "px";
    }
    this.photoColumnMaxWidth();
  }

  ngOnChanges(): void {
    if (!this.taskStoreRec) {
      this.editTaskStore = undefined;
      return;
    }
    this.editTaskStore = this.taskStoreRec;
    this.selectedSubTask = undefined;
    this.selectedSubTaskResult = undefined;
    this.selectedPhoto = undefined;

    this.subTasks = [];
    this.editTaskStore.subTasks.forEach((subtask) => {
      if (!subtask.subTaskTargetStores || subtask.subTaskTargetStores.find((store) => store.storeCd === this.editTaskStore.storeCd)) {
        subtask["result"] = this.editTaskStore.subTaskResults.find((item) => item.subTaskId == subtask.subTaskId);
        this.subTasks.push(subtask);
      }
    });

    let isObsolete = false;
    // check deleted from subTaskTarget
    for (let i = 0; i < this.subTasks.length; i++) {
      let subtask = this.subTasks[i];
      if (!this.editTaskStore.subTaskResults.find((rec) => rec.subTaskId === subtask.subTaskId)) {
        isObsolete = true;
        break;
      }
    }
    if (!isObsolete) {
      for (let i = 0; i < this.editTaskStore.subTaskResults.length; i++) {
        let result = this.editTaskStore.subTaskResults[i];
        if (!this.subTasks.find((rec) => rec.subTaskId === result.subTaskId)) {
          isObsolete = true;
          break;
        }
      }
    }
    if (isObsolete) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "タスクが再作成されました。タスクの検索からやり直してください。");
      setTimeout(() => {
        this.taskService.editTaskStore.isObsolete = true;
        this.taskService.editTaskStore = undefined;
      }, 0);
      return;
    }

    this.userList = this.editTaskStore.userList;

    this.filteredOptions = this.editTaskStore.edits.responsible.valueChanges.pipe(
      startWith(''),
      map(value => {
        return value ? this._filter(value as string) : this.staffList.slice();
      }),
    );

    this.getPhotoAll();
    this.refreshSubscription();
    this.setTableHeight();
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.staffList.filter(option => option.toLowerCase().includes(filterValue));
  }

  filterResponsible(value: string) {
    if (value === "") {
      this.staffList = [...this.taskService.staffListAll];
    } else {
      this.staffList = this.taskService.staffListAll.filter((item) => item.indexOf(value) >= 0);
    }
  }

  openReference() {
    window.open(this.editTaskStore.referenceUrl, "PippiTask");
  }

  openSubTaskReference(url: string) {
    window.open(url, "PippiTask");
  }

  selectSubTask(subTask: SubTask) {
    if(this.taskService.isViewTaskStore){
      this.selectedSubTaskResult.edits.isWorking.disable({emitEvent:false});
      this.selectedSubTaskResult.edits.isComplete.disable({emitEvent:false});
      this.selectedSubTaskResult.edits.responsible.disable({emitEvent:false});
      this.selectedSubTaskResult.edits.responsibleUser.disable({emitEvent:false});
      this.selectedSubTaskResult.edits.comment.disable({emitEvent:false});
    } else if (!this.selectedSubTaskResult.isComplete){
      this.selectedSubTaskResult.edits.isWorking.enable({emitEvent:false});
      this.selectedSubTaskResult.edits.isComplete.enable({emitEvent:false});
      this.selectedSubTaskResult.edits.responsible.enable({emitEvent:false});
      this.selectedSubTaskResult.edits.responsibleUser.enable({emitEvent:false});
      this.selectedSubTaskResult.edits.comment.enable({emitEvent:false});
    }
    this.selectedPhoto = undefined;
    this.viewTaskOtherStoreChanged.emit(undefined);
  }

  getPhotoAll() {
    this.editTaskStore.subTaskResults.forEach((result) => {
      result.photos.forEach((photo) => {
        this.getPhoto(photo);
      })
    });
  }

  async getPhoto(photo: PhotoItem) {
    await this.getPhotoAsync(photo);
  }

  async getPhotoAsync(photo: PhotoItem): Promise<void> {
    return new Promise<void>((resolve) => {
      if (photo.delFlagFn !== undefined && photo.delFlagFn > 0) {
        resolve();
        return;
      }
      if (photo.isDeleted !== undefined && photo.isDeleted) {
        resolve();
        return;
      }
      if (photo.dataUrl !== undefined && photo.dataUrl !== "") {
        resolve();
        return;
      }
      this.taskService.getImageData(
        photo, 
        (image)=>{
          photo.dataUrl = image.dataUrl;
          photo.dataUrlOrg = image.dataUrl;
          photo.isDeleted = image.isDeleted;
          photo.delFlagFn = image.delFlagFn;
          resolve();
        },
        true
      );
    });
  }

  selectPhoto(photo: PhotoItem, subTaskResult: SubTaskResult) {
    subTaskResult.edits.selectedPhoto = photo;
  }

  newPhoto(subTask: SubTask, subTaskResult: SubTaskResult) {
    let newTitle: string = "" + (subTaskResult.edits.photos.length + 1);
    while (subTaskResult.edits.photos.find(item => (
      newTitle === item?.title ||
      (!isNaN(parseInt(item?.title)) && parseInt(item?.title) > parseInt(newTitle))
    ))) {
      newTitle = "" + (parseInt(newTitle) + 1);
    }

    let newPhoto : PhotoItem = {
      taskId:             this.editTaskStore.taskId,
      storeCd:            this.editTaskStore.storeCd,
      subTaskId:          subTask.subTaskId,
      photoId:            -1,
      title:              newTitle,
      isNew:              true,
      isUpdated:          false,
      isDeleted:          false
    };

    let subsc = this.commonService.dialog.open(TaskPhotoDialogComponent, {
      disableClose: true,
      data: newPhoto
    }).afterClosed().subscribe(
      (data: PhotoItem) => {
        subsc.unsubscribe();
        if (!data) return;
        this.editTaskStore.addPhoto(subTaskResult, data);
        this.editTaskStore.markAsDirty();
        this.editTaskStore.checkStartAndComplete();
      }
    );
  }

  editPhoto(photo: PhotoItem) {
    let subsc = this.commonService.dialog.open(TaskPhotoDialogComponent, {
      disableClose: true,
      data: photo
    }).afterClosed().subscribe(
      (data) => {
        subsc.unsubscribe();
        if (!data) return;
        this.editTaskStore.markAsDirty();
      }
    );
  }

  deletePhoto(photo: PhotoItem) {
    if (photo.isDeleted) {
      photo.isDeleted = false;
    } else {
      photo.isDeleted = true;
    }

    this.editTaskStore.checkStartAndComplete();
    this.editTaskStore.markAsDirty();
  }

  async saveTaskStore() {

    let request: ReqUpdateTaskResultRec = await this.editTaskStore.commitEdit();

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.updateTaskResultRec(request).subscribe(
      (response: RspUpdateTaskResultRec) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveSaveTaskStore(response, request.imagesNew);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSaveTaskStore(response: RspUpdateTaskResultRec, imagesNew: TaskImageRecDto[]) {
    if (this.httpBasic.handleAppError(response)) {
      if (response.normalError.length > 0 && response.normalError[0].errId === "ME000000") {
        this.editTaskStore.isObsolete = true;
        this.taskService.editTaskStore = undefined;
      }
      return;
    }

    this.editTaskStore.resultStatus = response.resultStatus;
    // Update imageIds of new images
    for (let i = 0; i < imagesNew.length; i++) {
      imagesNew[i].imageId = response.imagesNew[i].imageId;
    }
    this.taskService.subTaskOtherStoresRecs = undefined;
    // this.selectedSubTask = undefined;
    // this.selectedSubTaskResult = undefined;
    if (this.selectedPhoto?.isDeleted) this.selectedPhoto = undefined;
    this.editTaskStore.prepareEdit();
    // Update staff list
    this.taskService.staffListAll = [];
    
    response.staffs.forEach((item) => this.taskService.staffListAll.push(item.staffName));

    this.refreshSubscription();
  }

  cancelEdit() {
    this.editTaskStore.clearEdit();
    this.refreshSubscription();
    this.selectedSubTask = undefined;
    this.selectedPhoto = undefined;
    this.selectedSubTaskResult?.edits.photos.map(photo => photo.isDeleted = false);
    this.editTable?.renderRows();
  }

  refreshSubscription() {
    this.subscResponse.forEach((subsc) => subsc.unsubscribe());
    this.subscResponse = [];
    if (this.commonService.config.task.responsibleIsLoginUser) {
      this.subscResponse.push(this.editTaskStore.edits.responsibleUser.valueChanges.subscribe(
        (value) => {
          this.editTaskStore.edits.responsibleName = this.taskService.getResponsibleName(this.editTaskStore.userList, value);
          if (this.commonService.config.task.responsibleCopyToSub && this.editTaskStore.responsibleUserId === "") {
            this.editTaskStore.subTaskResults.forEach((sub) => {
              if (sub.responsibleUserId === "" && sub.edits.responsibleUser.value === "") {
                sub.edits.responsibleUser.setValue(this.editTaskStore.edits.responsibleUser.value, {emitEvent: false});
                sub.edits.responsibleName = this.editTaskStore.edits.responsibleName;
              }
            });
          }
        }
      ));
    } else {
      this.subscResponse.push(this.editTaskStore.edits.responsible.valueChanges.subscribe(
        (value) => {
          this.filterResponsible(value);
        }
      ));
    }
    this.editTaskStore.subTaskResults.forEach((result) => {
      if (this.commonService.config.task.responsibleIsLoginUser) {
        this.subscResponse.push(result.edits.responsibleUser.valueChanges.subscribe(
          (value) => {
            result.edits.responsibleName = this.taskService.getResponsibleName(this.editTaskStore.userList, value);
          }
        ));
      } else {
        this.subscResponse.push(result.edits.responsible.valueChanges.subscribe(
          (value) => { this.filterResponsible(value); }
        ));
      }
    });
  }
  onViewTaskOtherStore(subTask: SubTask) {
    this.viewTaskOtherStoreChanged.emit(subTask);
  }

  onViewTaskOtherStoreComment() {
    this.viewTaskOtherStoreCommentChanged.emit(this.editTaskStore);
  }

  previewFileSubTask(subTask: SubTask){
    if (!(subTask?.subTaskFileName != '' && subTask?.subTaskFileName != undefined)) return;

    if(!subTask.subTaskAttachment){  
      const request = {
        taskId: this.editTaskStore.taskId,
        storeCd: null,
        subTaskId: subTask.subTaskId,
        access: this.commonService.loginUser
      }
      if(this.commonService.loginUser.roleId != 0) {
        request.storeCd = this.editTaskStore.storeCd;
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.subTaskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            subTask.subTaskAttachment = response.attachment;
            subTask.subTaskFileName = response.attachmentFilename;
            await this.receivePreviewFileSubTask(subTask.subTaskAttachment, subTask.subTaskFileName);  
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {  
      this.receivePreviewFileSubTask(subTask.subTaskAttachment, subTask.subTaskFileName);
    }
  }

  async receivePreviewFileSubTask(attachment: string, filename: string) {
    let mimeType;
    let base64 = attachment.split(';base64,')[1];
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
      
      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;
      default: mimeType ='NoSupport';
    }
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      if (mimeType == "NoSupport") {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `サポートされていないファイル形式`);
      }else {
        var iframe = "<iframe src='" + url + "' frameborder='0' style='border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;' allowfullscreen></iframe>";
        var openTab = window.open();
        openTab.document.open();
        openTab.document.write(iframe);
        openTab.document.title = filename; 
        openTab.document.close();
      } 
    });
  }

  async downLoadFileSubTask(subTask: SubTask) {
    if (!(subTask?.subTaskFileName != '' && subTask?.subTaskFileName != undefined)) return;
    if(!subTask.subTaskAttachment){  
      const request = {
        taskId: this.editTaskStore.taskId,
        storeCd: null,
        subTaskId: subTask.subTaskId,
        access: this.commonService.loginUser
      }
      if(this.commonService.loginUser.roleId != 0) {
        request.storeCd = this.editTaskStore.storeCd;
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.subTaskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            subTask.subTaskAttachment = response.attachment;
            subTask.subTaskFileName = response.attachmentFilename;
            await this.receivedownLoadFileSubTask(subTask.subTaskAttachment, subTask.subTaskFileName);  
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {  
      this.receivedownLoadFileSubTask(subTask.subTaskAttachment, subTask.subTaskFileName);
    }
  }

  async receivedownLoadFileSubTask(attachment: string, filename: string) {
    let mimeType;
    let base64 = attachment.split(';base64,')[1];
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
      
      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;
      default: mimeType ='NoSupport';
    }
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      this.commonService.downloadBlob(blob, filename);
    });
  }

  photoColumnMaxWidth() {
    let id = "photo-header";
    let photoElement = document.getElementById(id);
    if (!photoElement) return undefined;
    let containerRect = photoElement.getBoundingClientRect();
    let winWidth = window.innerWidth;
    let containerElement = document.getElementsByTagName("body").item(0);
    let css = window.getComputedStyle(containerElement);
    let width = winWidth - containerRect.left - 50;
    if (width < 140) width = 140;
    photoElement.style.minWidth = "" + width + "px";
    photoElement.style.maxWidth = "" + width + "px";
  }
}
