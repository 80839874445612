import { Component, OnInit, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CtgSelectCondition, CtgSelectComponent } from '../ctg-select/ctg-select.component';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export class ItemSelectSimpleCondition {
  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);

  public formGroup: FormGroup = this.fb.group({
    storeCd: ['', Validators.required],
    itemCode: [''],
    itemName: ['']
  });

  constructor(private fb: FormBuilder) {
  }
}

@Component({
  selector: 'app-item-select-simple',
  templateUrl: './item-select-simple.component.html',
  styleUrls: ['./item-select-simple.component.css']
})
export class ItemSelectSimpleComponent implements OnInit, OnDestroy {

  // public ctgSelectCondition: CtgSelectCondition;
  @Input('itemSelectSimpleCondition') public itemSelectSimpleCondition: ItemSelectSimpleCondition;
  @Input('allStoresFlag') public allStoresFlag: boolean = false;
  @Output('doQueryItemSimple') public clickQuery: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(CtgSelectComponent, { static: true }) public ctgSelect: CtgSelectComponent;

  storeCdSubscription: Subscription;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder) {

  }

  ngOnInit() {
    this.itemSelectSimpleCondition.formGroup.get('storeCd').setValue((this.allStoresFlag) ? "" : this.commonService.loginUser.storeCd);
    this.itemSelectSimpleCondition.ctgSelectCondition.storeCd = (this.allStoresFlag) ? "" : this.commonService.loginUser.storeCd;
    this.storeCdSubscription = this.itemSelectSimpleCondition.formGroup.get('storeCd').valueChanges
      .subscribe(
        data => this.ctgSelect.setStoreCd(this.itemSelectSimpleCondition.formGroup.get('storeCd').value)
      );
  }

  ngOnDestroy() {
    if (this.storeCdSubscription) {
      this.storeCdSubscription.unsubscribe();
    }
  }

  doQuery() {
    this.clickQuery.emit();
  }
}
