import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { PrinterService } from 'src/app/service/printer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpiryDateActionEdit, ExpiryGroup, ExpiryItemRec } from '../0_def/expiry-item-rec';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ReqDelFromGroup, RspDelFromGroup, RspSaveExpiry } from 'src/app/webservice/item-expiry';

@Component({
  selector: 'app-item-expiry-edit',
  templateUrl: './item-expiry-edit.component.html',
  styleUrls: ['./item-expiry-edit.component.css']
})
export class ItemExpiryEditComponent implements OnInit, OnDestroy, OnChanges  {

  public formDate: FormControl = new FormControl();
  public formDateEcho: FormControl = new FormControl("");
  public minDate: Date;
  public maxDate: Date;

  @Input()  expiryItem: ExpiryItemRec;
  @Output() removedFromGroup: EventEmitter<ExpiryGroup> = new EventEmitter<ExpiryGroup>();

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private printerService: PrinterService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.expiryItem) {
      this.expiryItem.cleanupEdit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.expiryItem.expiryType === 1) {
      this.minDate = this.commonService.getDate(this.commonService.formatDate(new Date()));
      this.maxDate = this.commonService.getDate("2099/12/31");
    } else {
      this.minDate = new Date();
      this.minDate.setDate(this.minDate.getDate() - 365);
      this.maxDate = this.commonService.getDate(this.commonService.formatDate(new Date()));
    }
    this.formDate.setValue(null);
    this.formDateEcho.setValue("");

    this.setHeight();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setHeight();
  }

  setHeight() {
    setTimeout(() => { this.setHeightBody(); }, 0);
  }

  setHeightBody() {
    let id = "dialog-close-btn";
    let dialogReserve = 0;
    if (document.getElementById(id)) {
      dialogReserve = this.commonService.getHeightBelow(id) + 5;
    }
    id = "date-action-container";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 0;    // 56;
    let margin = 10;
    let itemBox = 0;
    let btnBox = 24 + 5 + 24+ 5;             // 24;
    let height = remHeight - paginatorHeight - margin - itemBox - btnBox - dialogReserve;
    if (height < 150) height = 150;

    let elem = document.getElementById(id);

    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  itemDateChanged(event: MatDatepickerInputEvent<any>) {
    if (!this.formDate.value) return;
    let itemDate = this.commonService.formatDate(this.formDate.value);
    this.formDateEcho.setValue(itemDate);
    this.formDate.setValue(undefined);

    var msg: string = "";
    if (this.expiryItem.addDate(itemDate)) {
      if (this.expiryItem.expiryType === 1) {
        msg = "賞味期限 " + itemDate + " を追加しました。"
      } else {
        msg = "製造年月日 " + itemDate + " を追加しました。"
      }
    } else {
      if (this.expiryItem.expiryType === 1) {
        msg = "賞味期限 " + itemDate + " は登録済みです。"
      } else {
        msg = "製造年月日 " + itemDate + " は登録済みです。"
      }
    }
    this._snackBar.open(msg, undefined, {duration: 3000});
  }

  itemDateBoxStyle(dateAction: ExpiryDateActionEdit) {
    if (dateAction.alertRank > 0) {
      let color = this.commonService.config.itemExpiry.colors[dateAction.alertRank - 1];
      return {
        "border" : "solid 2px " + color.fg,
        "background-color": color.bg
      }
    } else if (dateAction.alertRank < -1) {
      return {
        "border" : "solid 2px #FF0000",
        "background-color": "#FBE5D6"
      }
    }
    return {};
  }

  itemDateBoxMsgStyle(dateAction: ExpiryDateActionEdit) {
    if (dateAction.alertRank > 0) {
      let color = this.commonService.config.itemExpiry.colors[dateAction.alertRank - 1];
      return {
        "color" : color.fg,
      }
    } else if (dateAction.alertRank < -1) {
      return {
        "color" : "#FF0000",
      }
    }
    return {};
  }

  setSoldOut() {
    this.expiryItem.setSoldOut();
  }

  resetDates() {
    this.expiryItem.resetDates();
  }

  removeFromGroup() {
    let msg;
    if (this.expiryItem.groupList.length == 1) {
      msg = this.commonService.literal["itemExpiryGroup"] + "から外すと期限管理の対象外となります。<BR>外しますか？";
    } else {
      msg = this.commonService.literal["itemExpiryGroup"] + "から外しますか？";
    }

    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      (response) => {
        subsc.unsubscribe();
        if (response) this.removeFromOrderGroupBody();
      }
    );
  }

  removeFromOrderGroupBody() {
    let index = this.expiryItem.groupList.findIndex((rec) => rec.groupId === this.expiryItem.targetGroup.groupId);
    if (index < 0) return;
    this.expiryItem.groupList.splice(index, 1);

    this.removeGroupFromDbItem(
      this.expiryItem.storeCd,
      this.expiryItem.itemCd,
      this.expiryItem.targetGroup.groupId,
      this.expiryItem.targetGroup.groupUsrCd,
    );
  }

  removeGroupFromDbItem(storeCd: string, itemCd: string, groupId: number, groupUsrCd: string) {
    let request: ReqDelFromGroup = {
      access:     this.commonService.loginUser,
      storeCd:    storeCd,
      itemCd:     itemCd,
      groupId:    groupId,
      groupUsrCd: groupUsrCd
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("ItemExpiryDelFromGroup", request).subscribe(
      (response: RspDelFromGroup) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveRemoveGroupFromDbItem(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )

    let group = this.expiryItem.targetGroup;
    this.expiryItem.targetGroup = undefined;
    this.expiryItem.cancelEdit();
    this.removedFromGroup.emit(group);

  }

  receiveRemoveGroupFromDbItem(response: RspDelFromGroup) {
    if (this.httpBasic.handleAppError(response)) return;
  }

  saveEdit() {
    this.expiryItem.saveEdit();
    this.formDate.setValue(undefined);
  }

  receiveSaveEdit(response: RspSaveExpiry) {
    if (this.httpBasic.handleAppError(response)) return;

    this.expiryItem.commitEdit();
  }

  cancelEdit() {
    this.expiryItem.cancelEdit();
    this.formDate.setValue(undefined);
  }

  printerNotReady() {
    if (!this.printerService.printerConfig) return true;
    if (!this.printerService.printerConfig.printer) return true;
    if (!this.printerService.printerConfig.printer.isConnected()) return true;
    return false;
  }

  printableAlert(dateAction: ExpiryDateActionEdit) {
    let rank = dateAction.alertRank - 1;
    if (rank < 0 || rank >= this.expiryItem.dateDef.length) return false;
    let discount = this.expiryItem.dateDef[rank].discount;
    if (discount <= 0) false;

    return true;
  }

  printDiscountLabel(dateAction: ExpiryDateActionEdit) {
    let count = dateAction.formPrintCount.value;
    if (count < 0) {
      dateAction.formPrintCount.setValue(1);
      count = 1;
    }

    this.printerService.printDiscountLabel(this.discountLabelMessageNeo7(dateAction, count));
  }

  discountLabelMessageNeo7(dateAction: ExpiryDateActionEdit, count: number) {
    let rank = dateAction.alertRank - 1;
    let discount = this.expiryItem.dateDef[rank].discount;

    return this.printerService.discountLabelMessageNeo7(
      this.printerService.printerConfig,
      this.expiryItem.itemCd,
      discount,
      this.expiryItem.discountType,
      this.expiryItem.price,
      this.expiryItem.tax,
      count
    );
  }

  isDirty() {
    if (!this.expiryItem) return false;
    return this.expiryItem.isDirty;
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
