import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { PrinterLayoutDto, ReqDeletePrinterLayout, ReqGetPrinterLayoutImage, ReqGetPrinterLayoutList, ReqUpdatePrinterLayout, RspGetPrinterLayoutImage, RspGetPrinterLayoutList, RspUpdatePrinterLayout } from 'src/app/webservice/printer';

@Component({
  selector: 'app-printer-layout',
  templateUrl: './printer-layout.component.html',
  styleUrls: ['./printer-layout.component.css']
})
export class PrinterLayoutComponent implements OnInit, OnDestroy {

  public formPrinterModel: FormControl = new FormControl();
  public formLayoutCd: FormControl = new FormControl("");
  public formName: FormControl = new FormControl("");
  public formFile: FormControl = new FormControl();
  public imageURL: string;
  public isDirty: boolean = false;
  public isNew: boolean = false;
  public codeErrorMsg: string = "";

  public isLayoutListActive: boolean = false;
  public layoutList: PrinterLayoutDto[] = [];
  public selectedLayout: PrinterLayoutDto;
  
  public columnIds: string[] = ["layoutCd", "name"];
  public columnDefs: TableColumnDef[] = [
    {columnId: "layoutCd", header: "コード", width: 50, align: "center"},
    {columnId: "name", header: "レイアウト名", width: 300}
  ];

  private subsc: Subscription[] = [];

  @ViewChild (MatTable, {static: true}) matTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService
    ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.formPrinterModel.setValue(this.commonService.config.printer.models[0].name);
    this.formLayoutCd.disable({emitEvent: false});
    this.formName.disable({emitEvent: false});

    this.subsc.push(
      this.formLayoutCd.valueChanges.subscribe(
        (value) => {
          this.codeChanged();
        }
      )
    );
    this.subsc.push(
      this.formName.valueChanges.subscribe(
        (value) => {
          this.isDirty = true;
        }
      )
    );

    this.subsc.push(
      this.formFile.valueChanges.subscribe(
        () => {this.fileChanged();}
      )
    );

    this.setTableHeight();
  }

  ngOnDestroy(): void {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "layout-list";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight == undefined) return;
    /*
    let paginatorHeight = 56;
    let margin = 10 + 5;
    let btnBox = 24;
    */
    let paginatorHeight = 0;
    let margin = 10;
    let btnBox = 0;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem == undefined) return;
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  getPrinterLayoutList() {
    let request: ReqGetPrinterLayoutList = {
      access: this.commonService.loginUser,
      model: this.formPrinterModel.value,
      withImage: false
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetPrinterLayoutList", request).subscribe(
      (response: RspGetPrinterLayoutList) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePrinterLayoutList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receivePrinterLayoutList(response: RspGetPrinterLayoutList) {
    if (this.httpBasic.handleAppError(response)) return;

    this.layoutList = response.rows;
    this.layoutList.forEach((dto) => {
      if (dto.image === "") dto.image = undefined;
    })
    this.isLayoutListActive = true;
    this.unselectLayout();
  }

  getLayoutImage() {
    let request: ReqGetPrinterLayoutImage = {
      access: this.commonService.loginUser,
      model: this.selectedLayout.model,
      layoutCd: this.selectedLayout.layoutCd
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetPrinterLayoutImage", request).subscribe(
      (response: RspGetPrinterLayoutImage) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePrinterLayoutImage(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receivePrinterLayoutImage(response: RspGetPrinterLayoutImage) {
    if (this.httpBasic.handleAppError(response)) return;

    if (response.row && response.row.image && response.row.image !== "") {
      this.selectedLayout.image = response.row.image;
      this.imageURL = this.selectedLayout.image;
    } else {
      this.selectedLayout.image = null;
      this.imageURL = null;
    }
  }

  selectLayout(rec: PrinterLayoutDto) {
    if (this.isDirty) {
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle, "編集内容を破棄しますか？").subscribe(
        (response: boolean) => {
          if (response) this.selectLayoutBody(rec);
        }
      );
    } else {
      this.selectLayoutBody(rec);
    }
  }

  selectLayoutBody(rec: PrinterLayoutDto) {
    this.selectedLayout = rec;

    this.formLayoutCd.setValue(rec.layoutCd);
    this.formName.setValue(rec.name);
    this.formLayoutCd.enable({emitEvent: false});
    this.formName.enable({emitEvent: false});
    this.imageURL = rec.image;
    this.isDirty = false;
    this.isNew = false;

    if (this.selectedLayout.image === undefined) {
      this.getLayoutImage();
    }
  }

  unselectLayout() {
    this.selectedLayout = undefined;
    this.formLayoutCd.setValue("");
    this.formName.setValue("");
    this.formLayoutCd.disable({emitEvent:false});
    this.formName.disable({emitEvent:false});
    this.imageURL = undefined;
    this.isDirty = false;
    this.isNew = false;
  }

  newLayout() {
    let rec: PrinterLayoutDto = {
      model: this.formPrinterModel.value,
      layoutCd: "",
      name: "",
      image: undefined
    };
    this.selectLayout(rec);
    this.isNew = true;
    this.isDirty = true;
    document.getElementById("input-code").focus();
  }

  getFile() {
    let elem = document.getElementById("input-file") as HTMLInputElement;
    elem.value = "";
    elem.click();
  }

  fileChanged() {
    let fileInput = document.getElementById('input-file')  as HTMLInputElement;
    let file;
    if (fileInput.files[0]) {
      file = fileInput.files[0];
    }

    let fileReader = new FileReader();
    this.commonService.openSpinner(this.commonService.pageTitle, "読込み中・・・")
    fileReader.onload = () => {      
      this.commonService.closeSpinner();
      this.readComplete(fileReader);
    }
    fileReader.onerror = () => {
      this.commonService.closeSpinner();
      this.commonService.openErrorDialog(this.commonService.pageTitle, "ファイルの読み込みに失敗しました。<br><br>"+fileReader.error);
      return;
    };

    fileReader.readAsDataURL(file);
  }

  readComplete(fileReader: FileReader) {
    let url = fileReader.result.toString()
    if (!url.startsWith("data:image")) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "画像データではありません。");
      return;
    }
    this.imageURL = url;
    this.isDirty = true;
  }

  deleteImage() {
    this.imageURL = null;
    this.isDirty = true;
  }

  saveLayout() {
    this.selectedLayout.model = this.formPrinterModel.value;
    this.selectedLayout.layoutCd = this.formLayoutCd.value;
    this.selectedLayout.name = this.formName.value;
    this.selectedLayout.image = this.imageURL;

    let request: ReqUpdatePrinterLayout = {
      access: this.commonService.loginUser,
      layout: {...this.selectedLayout}
    };
    if (!request.layout.image) request.layout.image = "";

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdatePrinterLayout", request).subscribe(
      (response: RspUpdatePrinterLayout) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveSaveLayout(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSaveLayout(response: RspUpdatePrinterLayout) {
    if (this.httpBasic.handleAppError(response)) return;

    if (this.isNew) {
      this.layoutList.push(this.selectedLayout);
      this.matTable?.renderRows();
    }
    this.isDirty = false;
    this.isNew = false;
  }

  cancelLayout() {
    if (this.isNew) {
      this.unselectLayout();
    } else {
      this.formLayoutCd.setValue(this.selectedLayout.layoutCd);
      this.formName.setValue(this.selectedLayout.name);
      this.imageURL = this.selectedLayout.image;
    }
    this.isDirty = false;
  }

  deleteLayout() {
    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
      this.selectedLayout.layoutCd + "：" +  this.selectedLayout.name + " を削除しますか？").subscribe(
        (response: boolean) => {
          subsc.unsubscribe();
          if (response) this.deleteLayoutBody();
        }
      );
  }

  deleteLayoutBody() {
    let request: ReqDeletePrinterLayout = {
      access: this.commonService.loginUser,
      layout: {...this.selectedLayout}
    };
    request.layout.image = "";

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("DeletePrinterLayout", request).subscribe(
      (response: RspUpdatePrinterLayout) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveDeleteLayout(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDeleteLayout(response: RspUpdatePrinterLayout) {
    if (this.httpBasic.handleAppError(response)) return;

    let index = this.layoutList.findIndex((dto) => dto.model === this.selectedLayout.model && dto.layoutCd === this.selectedLayout.layoutCd);
    if (index >= 0) {
      this.layoutList.splice(index, 1);
      this.matTable.renderRows();
    }
    this.unselectLayout();
  }

  codeChanged() {
    this.isDirty = true;
    this.codeErrorMsg = ""
    let rec = this.layoutList.find((item) => item.layoutCd === this.formLayoutCd.value);
    if (!rec) return;
    if (rec === this.selectedLayout) return;
    this.codeErrorMsg = "コードが重複しています。"
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

}
