import { Directive, Input, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumber]'
})
export class NumberOnlyDirective {

  private el: NgControl;

  @Input() appNumberMaxValue: number;
  @Input() appNumberCurrentValue: number;

  constructor(private ngControl: NgControl) {
    this.el = ngControl;
  }

  // Listen for the input event to also handle copy and paste.
  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Use NgControl patchValue to prevent the issue on validation
    this.el.control.patchValue(value.replace(/[^0-9]/g, ''));
    if (this.appNumberMaxValue !== undefined && this.appNumberCurrentValue !== undefined) {
      if (parseInt(this.el.control.value) > this.appNumberMaxValue) this.el.control.setValue(this.appNumberCurrentValue);
    }
  }
}
