import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { CtgSelectCondition, CtgSelectComponent } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { OpestatViewComponent } from '../opestat-view/opestat-view.component';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OpestatViewContext } from '../opestat-view-context';
import { Subscription } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { StoreGroupTypeRec } from 'src/app/common/store-group-type-rec';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from 'src/app/dialog/spinner-dialog/spinner-dialog.component';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspGetStoreGroupType } from 'src/app/response/rsp-get-store-group-type';
import { StoreGroupNameRec } from 'src/app/common/store-group-name-rec';
import { RspGetStoreGroupName } from 'src/app/response/rsp-get-store-group-name';

@Component({
  selector: 'app-opestat-page',
  templateUrl: './opestat-page.component.html',
  styleUrls: ['./opestat-page.component.css']
})
export class OpestatPageComponent implements OnInit, OnDestroy, AfterViewChecked {

  public isReadonly: boolean = true;

  private maxViewId : number = 0;
  public views : OpestatViewContext[] = [];
  public selectedView : OpestatViewContext;
  public selectedViewIndex : number;
  public selectedForm : FormGroup;
  public selectedCtgSelectCondition: CtgSelectCondition;
  public selectedStoreGroupNameRecByStore: StoreGroupNameRec[];
  public selectedStoreGroupNameRec: StoreGroupNameRec[];

  public ranks: string[] = ["", ...this.commonService.config.ranks];
  public maxDate: Date;
  public minDateBegin: Date;

  private storeGroupTypeAllStore: StoreGroupTypeRec;
  public storeGroupType: StoreGroupTypeRec[] = [];
  public storeGroupTypeByStore: StoreGroupTypeRec[] = [];

  private spinnerDialogRef: MatDialogRef<any>;
  private subscriptionStore : Subscription;
  private subscriptionQuery: Subscription;

  public storeGroupTypeCodeForByStore: FormControl = new FormControl([0]);
  public targetStoreGroupTypeCode: FormControl = new FormControl([0]);

  @ViewChild(CtgSelectComponent, {static: true}) ctgComponent: CtgSelectComponent;
  //  @ViewChild("viewsContainer", {read:ElementRef, static:true}) viewsContainer: ElementRef;
  @ViewChildren(OpestatViewComponent) viewsChildren: QueryList<OpestatViewComponent>;

  viewContainerElement: HTMLElement;
  // scrollInited: boolean = false;
  scrollHeight: number;
  scrollTop: number;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<any>) {

    // this.ctgSelectCondition = new CtgSelectCondition(this.fb);
  }

  ngOnInit() {
    // this.commonService.pageTitle = "一致率・欠品率";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("opestat");
    window.addEventListener("resize", this.initScroll);

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + this.commonService.config.opestatDefaultEndDayOffset);
    this.minDateBegin = new Date(2010, 0, 1);

    this.maxViewId++;
    var v = new OpestatViewContext(this.maxViewId, this.defaultViewName(this.maxViewId), this.fb,
              this.commonService, this.httpBasic);
    v.ctgSelect.storeCd = "";
    v.formGroup.get("targetStoreCode").setValue(this.commonService.loginUser.storeCd);

    /* Setup default date range */
    var end = this.commonService.copyDate(this.maxDate);
    end.setDate(end.getDate() - end.getDay());
    var begin = this.commonService.copyDate(end);
    begin.setDate(begin.getDate() - this.commonService.config.opestatDefaultDays + 1);
    v.formGroup.get("dateBegin").setValue(begin);
    v.formGroup.get("dateEnd").setValue(end);

    this.views.push(v);
    this.setSelected(v);
    this.setDistrType("一致率");
    this.setTargetStoreType("特定店舗");

    this.storeGroupTypeCodeForByStore.valueChanges.subscribe(() => this.storeGroupTypeByStoreChanged());
    this.targetStoreGroupTypeCode.valueChanges.subscribe(() => this.storeGroupTypeChanged());

    if (!this.isSingleStore()) {
      this.storeGroupTypeAllStore = new StoreGroupTypeRec();
      this.storeGroupTypeAllStore.storeGroupTypeCd = 0;
      this.storeGroupTypeAllStore.storeGroupType = "全店";
      this.storeGroupTypeByStore.push(this.storeGroupTypeAllStore);
  
      this.openSpinner(this.commonService.pageTitle, "検索中・・・");
  
      this.subscriptionQuery = this.httpBasic.getStoreGroupType().subscribe(
        (response) => {this.receiveStoreGroupType(response)},
        (error) => {
          this.clearProgressStateStoreGroupType();
          this.httpBasic.handleError(error);
        }
      );
    }
  }

  ngOnDestroy() {
    window.removeEventListener("resize", this.initScroll);
    if (this.subscriptionStore) {
      this.subscriptionStore.unsubscribe();
    }
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
    }
    for (let view of this.views) {
      // if (view.subscriptionType) view.subscriptionType.unsubscribe();
      view.onDestroy();
    }
  }

  clearProgressStateStoreGroupType() {
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
      this.subscriptionQuery = undefined;
    }
    this.closeSpinner();
  }

  clearProgressState() {
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
      this.subscriptionQuery = undefined;
    }
    this.closeSpinner();
  }

  receiveStoreGroupType(response: RspGetStoreGroupType) {
    this.clearProgressStateStoreGroupType();
    if (this.httpBasic.handleAppError(response)) return;

    for (let type of response.storeGroupTypes) {
      let typeRec = new StoreGroupTypeRec();
      typeRec.storeGroupTypeCd = type.storeGroupTypeCdFn;
      typeRec.storeGroupType = type.storeGroupTypeFv;
      this.storeGroupTypeByStore.push(typeRec);
      this.storeGroupType.push(typeRec);
    }
  }

  storeGroupTypeByStoreChanged() {
    var typeCd: number = parseInt(this.storeGroupTypeCodeForByStore.value);
    this.selectedForm.get("storeGroupTypeCodeForByStore").setValue(typeCd);
    this.selectedForm.get("storeGroupCodeForByStore").setValue(0);
    if (!this.storeGroupTypeCodeForByStore.dirty) return;

    if (typeCd == 0) {
      /* 全店 */
      this.selectedForm.get("storeGroupCodeForByStore").disable();
      return;
    } else {
      this.selectedForm.get("storeGroupCodeForByStore").enable();
    }
    this.selectedStoreGroupNameRecByStore.length = 0;
  
    this.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.getStoreGroupName(typeCd).subscribe(
      (response) => {this.receiveStoreGroupName(response, 1)},
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  storeGroupTypeChanged() {
    var typeCd: number = parseInt(this.targetStoreGroupTypeCode.value);
    this.selectedForm.get("targetStoreGroupTypeCode").setValue(typeCd);
    this.selectedForm.get("targetStoreGroupCode").setValue(0);
    if (!this.targetStoreGroupTypeCode.dirty) return;

    this.selectedStoreGroupNameRec.length = 0;

    this.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subscriptionQuery = this.httpBasic.getStoreGroupName(typeCd).subscribe(
      (response) => {this.receiveStoreGroupName(response, 2)},
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveStoreGroupName(response: RspGetStoreGroupName, array: number) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    let resultArray: StoreGroupNameRec[];
    if (array == 1) {
      resultArray = this.selectedStoreGroupNameRecByStore;
    } else {
      resultArray = this.selectedStoreGroupNameRec
    }

    for (let groupName of response.storeGroupNames) {
      let groupNameRec = new StoreGroupNameRec();
      groupNameRec.storeGroupCd = groupName.storeGroupCdFn;
      groupNameRec.storeGroupName = groupName.storeGroupNameFv;
      groupNameRec.storeGroupTypeCd = groupName.storeGroupTypeCdFn;
      resultArray.push(groupNameRec);
    }

    if (array != 1) {
      this.selectedForm.get("targetStoreGroupCode").enable();
    }
  }

  newView() {
    this.maxViewId++;
    var v = new OpestatViewContext(this.maxViewId, this.defaultViewName(this.maxViewId), this.fb,
              this.commonService, this.httpBasic);
    this.views.splice(this.selectedViewIndex + 1, 0, v);
    this.selectedView.copyConditionTo(v);

    this.setSelected(v);
  }

  defaultViewName(id: number) : string {
    return "条件-" + id;
  }

  clickView(view: OpestatViewContext) {
    var elem = document.getElementById("view-" + view.id);
    var scr = elem.offsetTop - this.viewContainerElement.offsetTop;
    this.viewContainerElement.scrollTo({left:0, top:scr, behavior: 'smooth'});
    this.setSelected(view);
  }

  setSelected(view: OpestatViewContext) {
    if (this.selectedView && this.selectedView.id == view.id) {
      return;
    }

    this.selectedView = view;
    /*
    let newArr: StoreGroupNameRec[] = [];
    for (let rec of view.storeGroupNameRecByStore) {
      let sgn = new StoreGroupNameRec();
      sgn.storeGroupCd = rec.storeGroupCd;
      sgn.storeGroupName = rec.storeGroupName;
      sgn.storeGroupTypeCd = rec.storeGroupTypeCd;
      newArr.push(sgn);
    }
    view.storeGroupNameRecByStore = newArr;
    */
    this.selectedViewIndex = this.findView(view);
    this.selectedForm = view.formGroup;
    this.selectedCtgSelectCondition = view.ctgSelect;

    this.selectedStoreGroupNameRecByStore = view.storeGroupNameRecByStore;
    this.selectedStoreGroupNameRec = view.storeGroupNameRec;

    // this.storeGroupTypeCodeForByStore.markAsPristine();
    this.storeGroupTypeCodeForByStore.setValue(
      this.selectedForm.get("storeGroupTypeCodeForByStore").value
    );

    // this.targetStoreGroupTypeCode.markAsPristine();
    this.targetStoreGroupTypeCode.setValue(
      this.selectedForm.get("targetStoreGroupTypeCode").value
    );

  }

  storeChanged(store: string) {
    this.ctgComponent.setStoreCd(store);
  }

  findView(view: OpestatViewContext) : number {
    for (var i = 0; i < this.views.length; i++) {
      if (this.views[i].id == view.id) return i;
    }
    return 0;
  }

  deleteCurrentView() {
    if (this.views.length == 1) return;

    this.views.splice(this.selectedViewIndex, 1);
    if (this.selectedViewIndex >= this.views.length) {
      this.selectedViewIndex = this.views.length - 1;
    }
    // this.selectedView = this.views[this.selectedViewIndex];
    this.setSelected(this.views[this.selectedViewIndex]);
  }

  initScroll() {
    this.viewContainerElement = document.getElementById("views-container");
    var clientRect = this.viewContainerElement.getBoundingClientRect();
    this.scrollTop = clientRect.top;
    this.scrollHeight = window.innerHeight - this.scrollTop - 35;
    this.viewContainerElement.style["height"] = "" + this.scrollHeight + "px";
  }

  ngAfterViewChecked() {
    this.initScroll();
  }

  selectedViewId() : number {
    return this.selectedView ? this.selectedView.id : 0;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.views, event.previousIndex, event.currentIndex);
    if (this.selectedView) {
      this.selectedViewIndex = this.findView(this.selectedView);
    }

    /*
    if (this.selectedView && this.selectedView.id == this.views[event.currentIndex].id) {
      this.selectedViewIndex = event.currentIndex;
    }
    */
  }

  isSingleStore() : boolean {
    if (this.commonService.stores.length == 1) return true;
    return false;
  }

  isDistr() : boolean {
    return parseInt(this.selectedForm.get('type').value) == OpestatViewContext.ConditionTypeByDistribution;
  }

  isByStore() : boolean {
    return parseInt(this.selectedForm.get('type').value) == OpestatViewContext.ConditionTypeByStore;
  }

  isByStoreGroup() : boolean {
    return parseInt(this.selectedForm.get('type').value) == OpestatViewContext.ConditionTypeByStoreGroup;
  }

  isSpecificStore() : boolean {
    if (this.isByStore()) return false;
    if (this.isByStoreGroup()) return false;
    if (this.isDistr()) return true;

    if (parseInt(this.selectedForm.get('targetStoreType').value) == 1) return true;

    return false;
  }

  isStoresOfStoreGroup() : boolean {
    if (this.isByStore()) return false;
    if (this.isByStoreGroup()) return false;
    if (this.isDistr()) return false;

    if (parseInt(this.selectedForm.get('targetStoreType').value) == 2) return true;

    return false;
  }

  setDistrType(type: string) {
    if (type == "一致率") {
      this.selectedForm.get('distrType').setValue("" + OpestatViewContext.ConditionDistrTypeRecommendationAcceptanceRatio);
    } else if (type == "欠品率") {
      this.selectedForm.get('distrType').setValue("" + OpestatViewContext.ConditionDistrTypeSoldOutRatio);
    }
  }

  setTargetStoreType(type: string) {
    if (type == "特定店舗") {
      this.selectedForm.get('targetStoreType').setValue("1");
    } else {
      this.selectedForm.get('targetStoreType').setValue("2");
    }
  }

  isAllStore() : boolean {
    if (parseInt(this.selectedForm.get('storeGroupTypeCodeForByStore').value) == 0) return true;
    return false;
  }

  dateBeginFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return  day == 1;
  }

  dateEndFilter = (d: Date | null): boolean =>{
    const day = (d || new Date()).getDay();
    return day == 0;
  }

  getDateBeginMax() {
    if (this.selectedForm.get("dateEnd").value) {
      return this.selectedForm.get("dateEnd").value;
    }
    return this.maxDate;
  }

  generateName() {
    var name: string = "[ " + this.selectedView.id + " ]";
    switch (parseInt(this.selectedForm.get("type").value)) {
      case OpestatViewContext.ConditionTypeByStore:
        name += "：店舗別";
        break;
      case OpestatViewContext.ConditionTypeByCategory:
        name += "：カテゴリ別";
        break;
      case OpestatViewContext.ConditionTypeByRank:
        name += "：ランク別";
        break;
      case OpestatViewContext.ConditionTypeByMonth:
        name += "：月別";
        break;
      case OpestatViewContext.ConditionTypeByWeek:
        name += "：週別";
        break;
      case OpestatViewContext.ConditionTypeByWeekday:
        name += "：曜日別";
        break;
      case OpestatViewContext.ConditionTypeByDistribution:
        name += "：商品分布";
        break;
        case OpestatViewContext.ConditionTypeByStoreGroup:
          name += "：店舗グループ別";
          break;
      }

    if (parseInt(this.selectedForm.get("type").value) != OpestatViewContext.ConditionTypeByDistribution) {
      let recomm = this.selectedForm.get("ratioType").get("recommAccptRatio").value;
      let soldOut = this.selectedForm.get("ratioType").get("soldOutRatio").value;
      if (recomm && soldOut) {
        name += "：一致率・欠品率";
      } else if (recomm) {
        name += "：一致率";
      } else if (soldOut) {
        name += "：欠品率";
      }
    }
    if (parseInt(this.selectedForm.get("type").value) == OpestatViewContext.ConditionTypeByDistribution) {
      switch (parseInt(this.selectedForm.get("distrType").value)) {
        case OpestatViewContext.ConditionDistrTypeRecommendationAcceptanceRatio:
          name += "：一致率";
          break;
        case OpestatViewContext.ConditionDistrTypeSoldOutRatio:
          name += "：欠品率";
          break;
      }
    }

    if (this.selectedForm.get("dateBegin").value) {
      name += "：" + this.dateAdapter.format(this.selectedForm.get("dateBegin").value, "");
      name += "～";
    }
    if (this.selectedForm.get("dateEnd").value) {
      name += this.dateAdapter.format(this.selectedForm.get("dateEnd").value, "");
    }

    if (parseInt(this.selectedForm.get("type").value) == OpestatViewContext.ConditionTypeByStore) {
      if (!this.isSingleStore()) {
        let code = parseInt(this.selectedForm.get("storeGroupTypeCodeForByStore").value);
        name += "：" + this.findStoreGroupType(code, this.storeGroupTypeByStore);
        if (code != 0) {
          let group = parseInt(this.selectedForm.get("storeGroupCodeForByStore").value);
          name += "-" + this.findStoreGroup(group, this.selectedStoreGroupNameRecByStore);
        }
      }
    } else if (parseInt(this.selectedForm.get("type").value) == OpestatViewContext.ConditionTypeByStoreGroup) {
      let code = parseInt(this.selectedForm.get("storeGroupTypeCodeForByStoreGroup").value);
      name += "：" + this.findStoreGroupType(code, this.storeGroupType);
    } else {
      if (parseInt(this.selectedForm.get("targetStoreType").value) == 1) {
        let store = this.commonService.stores.find(
          (store)=>{
            return store.storeCd === this.selectedForm.get("targetStoreCode").value;
          });
        if (store) {
          name += "：" + store.storeName;
        }
      } else {
        let code = parseInt(this.selectedForm.get("targetStoreGroupTypeCode").value);
        let type = this.findStoreGroupType(code, this.storeGroupType);
        name += "：" + this.findStoreGroupType(code, this.storeGroupType);
        if (type !== "") {
          let group = parseInt(this.selectedForm.get("targetStoreGroupCode").value);
          name += "-" + this.findStoreGroup(group, this.selectedStoreGroupNameRec);
        }
      }
    }
    var ctgForm = this.selectedCtgSelectCondition.formGroup;
    for (let i = 0; i <= parseInt(ctgForm.get("ctgLevel").value); i++) {
      let ctgname = this.selectedCtgSelectCondition["ctg"+i+"Name"];
      if (ctgname && ctgname != "") {
        name += "：" + this.selectedCtgSelectCondition["ctg"+i+"Name"];
      }
    }
    if (parseInt(this.selectedForm.get("type").value) != OpestatViewContext.ConditionTypeByRank &&
        this.selectedForm.get("rank").value != "") {
          name += "：" + this.selectedForm.get("rank").value;
    }

    this.selectedForm.get("name").setValue(name);
  }

  findStoreGroupType(typeCode: number, typeArray:StoreGroupTypeRec[]) {
    for (let typeRec of typeArray) {
      if (typeRec.storeGroupTypeCd == typeCode) return typeRec.storeGroupType;
    }
    return "";
  }
  findStoreGroup(groupCode: number, typeArray:StoreGroupNameRec[]) {
    for (let typeRec of typeArray) {
      if (typeRec.storeGroupCd == groupCode) return typeRec.storeGroupName;
    }
    return "";
  }

  dateCanPrevYear() : boolean {
    if (!this.selectedForm.get("dateBegin").value) return false;
    if (!this.selectedForm.get("dateEnd").value) return false;

    return true;
  }
  dateCanPrevMonth() : boolean {
    if (!this.selectedForm.get("dateBegin").value) return false;
    if (!this.selectedForm.get("dateEnd").value) return false;

    return true;
  }
  dateCanPrevWeek() : boolean {
    if (!this.selectedForm.get("dateBegin").value) return false;
    if (!this.selectedForm.get("dateEnd").value) return false;

    return true;
  }

  dateCan(dates: number) {
    var d = this.selectedForm.get("dateEnd").value as Date;
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    d.setDate(d.getDate() + dates);
    if (d.getTime() > this.maxDate.getTime()) return false;
    return true;
  }


  dateCanNextYear() : boolean {
    if (!this.selectedForm.get("dateBegin").value) return false;
    if (!this.selectedForm.get("dateEnd").value) return false;

    return (this.dateCan(364));
  }
  dateCanNextMonth() : boolean {
    if (!this.selectedForm.get("dateBegin").value) return false;
    if (!this.selectedForm.get("dateEnd").value) return false;

    return (this.dateCan(28));
  }
  dateCanNextWeek() : boolean {
    if (!this.selectedForm.get("dateBegin").value) return false;
    if (!this.selectedForm.get("dateEnd").value) return false;

    return (this.dateCan(7));
  }

  dateShift(dates : number) {
    if (dates > 0) {
      let e = this.selectedForm.get("dateEnd").value as Date;
      e.setDate(e.getDate() + dates);
      this.selectedForm.get("dateEnd").setValue(e);
      let b = this.selectedForm.get("dateBegin").value as Date;
      b.setDate(b.getDate() + dates);
      this.selectedForm.get("dateBegin").setValue(b);
    } else {
      let b = this.selectedForm.get("dateBegin").value as Date;
      b.setDate(b.getDate() + dates);
      this.selectedForm.get("dateBegin").setValue(b);
      let e = this.selectedForm.get("dateEnd").value as Date;
      e.setDate(e.getDate() + dates);
      this.selectedForm.get("dateEnd").setValue(e);
    }
    this.selectedForm.get("dateBegin").markAsDirty();
    this.selectedForm.get("dateEnd").markAsDirty();
  }

  prevYear() {
    this.dateShift(-364);
  }
  prevMonth() {
    this.dateShift(-28);
  }
  prevWeek() {
    this.dateShift(-7);
  }
  nextYear() {
    this.dateShift(364);
  }
  nextMonth() {
    this.dateShift(28);
  }
  nextWeek() {
    this.dateShift(7);
  }

  doQuery() {
    for (var view of this.viewsChildren.toArray()) {
      if (this.selectedView.id == view.viewContext.id) {
        view.viewContext.markAsPristine();
        view.doQuery();
        return;
      }
    }
  }

  openAllView() {
    for (var view of this.viewsChildren.toArray()) {
        view.openSection();
    }
  }

  closeAllView() {
    for (var view of this.viewsChildren.toArray()) {
        view.closeSection();
    }
  }

  /* For Query of StoreGroupType */
  openSpinner(title: string, msg: string) {
    this.spinnerDialogRef = this.commonService.dialog.open(SpinnerDialogComponent, {
      disableClose: true,
      data: {title: title, message: msg}
    });
  }
  closeSpinner() {
    if (this.spinnerDialogRef) {
      this.spinnerDialogRef.close();
      this.spinnerDialogRef = undefined;
    }
  }

}
