import { CommonService } from '../../service/common.service';
import { FormControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

export class LossRateRec {

  public storeCd:           string = "";
  public storeName:         string = "";
  public ctgLevel:          number = 0;
  public ctgCd:             string[] = ["", "", "", ""];
  public ctgName:           string[] = ["", "", "", ""];
  public lossRate:          number = -1;                      // -1: lossRate is not defined.
  public lossRateForm:      FormControl;
  public lossAmount:        number = 0;
  public sumAmount:         number = 0;
  public lossAmountEdit:    number = 0;
  public targetSalesPrice:  number = 0;
  public invalid: boolean = false;
  public lossAmountSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  lossAmount$ = this.lossAmountSubject.asObservable().pipe(map(value => value <= 0 ? "" : value));

  public children:          LossRateRec[];

  constructor(
    public commonService: CommonService
  ) {
  }

  initForm() {
    this.lossRateForm = new FormControl(this.lossRate < 0 ? "" : this.lossRate);
  }

  getValue(key: string) {
    switch(key) {
      case "store":
        return this.storeCd + "：" + this.storeName;
      case "lossRateForm":
        return this.lossRateForm.value;
      case "lossAmount":
        if (this.lossAmountEdit == 0) return "";
        if (this.lossAmountEdit < 0) return "";
        return this.lossAmountEdit;
      default:
        return this[key];
    }
  }

  editLossRate(value: number | string) : void {
    
    if (!this.lossRateForm) this.initForm();
    this.lossRateForm.setValue(value);
    let lossRate = +value / 100;

    if (value === "") {
      this.lossAmountEdit = 0;
      this.lossAmountSubject.next(this.lossAmount);  
    } else {

      let averageDiscount = this.commonService.config.averageDiscountRate;
      let amount = Math.round(this.sumAmount * lossRate * averageDiscount * (1 + lossRate) / (averageDiscount - lossRate + averageDiscount * lossRate));
      this.lossAmountSubject.next(amount);  
    }
  }

  calcLossAmount(lossRate: number) : number {
    if (!this.children) return Math.round(lossRate * this.targetSalesPrice / 100);

    let amount = 0;
    this.children.forEach((child) => {amount += child.calcLossAmount(lossRate)});

    return amount;
  }

  doCalcLossAmount() {
    
    if (this.lossAmountEdit >= 0) return;         // Not dirty
    let value = this.lossRateForm.value;
    let lossRate = +value / 100;
    
    if (value === "") {
      this.lossAmountEdit = 0;
      this.lossAmountSubject.next(this.lossAmount);  
    } else {
      // let amount = Math.round(+value * this.lossAmount / 100);
      let averageDiscount = this.commonService.config.averageDiscountRate;
      let amount = Math.round(this.sumAmount * lossRate * averageDiscount * (1 + lossRate) / (averageDiscount - lossRate + averageDiscount * lossRate));
      this.lossAmountSubject.next(amount);  
    }
  }

  clear() {
    this.editLossRate(this.lossRate < 0 ? "" : this.lossRate);
  }

  isValid() {
    
    let n = this.lossRateForm.value;
   
    if (n === "") return true;
    let val = Number(n);
    if (isNaN(val)) return false;
    if (val < 0 || val > this.commonService.config.lossRateUpperLimit) return false;
    return true;
  }

  isEdited() {
    if (!this.isValid()) return false;
    let n = this.lossRateForm.value;
    if (n === "" && this.lossRate < 0) return false;
    if (n === "" && this.lossRate == 0) return true;
    let val = Number(n);
    if (val != this.lossRate) return true;
    return false;
  }
}