import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { GraphOption, GraphService, GraphType } from 'src/app/service/graph.service';

@Component({
  selector: 'app-order-edit-dialog',
  templateUrl: './graph-option-dialog.component.html',
  styleUrls: ['./graph-option-dialog.component.css']
})
export class GraphOptionDialogComponent implements OnInit, OnDestroy {

  // public GraphService = GraphService;

  // formShowLabels: FormControl = new FormControl();
  formGraphType: FormControl = new FormControl();
  formXAxis: FormControl = new FormControl();
  formYAxis: FormControl = new FormControl();
  formShowYAxisLabel: FormControl = new FormControl();
  formShowXAxisLabel: FormControl = new FormControl();
  formXAxisLabel: FormControl = new FormControl();
  formYAxisLabel: FormControl = new FormControl();
  formAutoScale: FormControl = new FormControl();
  formYScaleMin: FormControl = new FormControl();
  formYScaleMax: FormControl = new FormControl();
  formLegend: FormControl = new FormControl();
  formLegendTitle: FormControl = new FormControl();
  formLegendPosition: FormControl = new FormControl();
  formGradient: FormControl = new FormControl();

  public graphOption: GraphOption;
  public selectableGraphTypes: GraphType[] = [];

  constructor(
    public dialogRef: MatDialogRef<GraphOptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogArg: any,
    private graphService: GraphService) { }

  ngOnInit() {
    this.graphOption = this.dialogArg.graphOption;
    /*
    for (let type of GraphService.graphTypes) {
      if (!type.groupType && this.dialogArg.dataItemCount > 1) continue;
      this.selectableGraphTypes.push(type);
    }
    */
    this.selectableGraphTypes = this.graphService.selectableGraphTypes(this.dialogArg.rowCount);
    this.initOptionValue();
  }

  ngOnDestroy() {
  }

  initOptionValue() {
    this.formGraphType.setValue(this.graphOption.graphType);
    this.formXAxis.setValue(this.graphOption.xAxis);
    this.formYAxis.setValue(this.graphOption.yAxis);
    this.formShowYAxisLabel.setValue(this.graphOption.showYAxisLabel);
    this.formShowXAxisLabel.setValue(this.graphOption.showXAxisLabel);
    this.formXAxisLabel.setValue(this.graphOption.xAxisLabel);
    this.formYAxisLabel.setValue(this.graphOption.yAxisLabel);
    this.formLegend.setValue(this.graphOption.legend);
    this.formLegendTitle.setValue(this.graphOption.legendTitle);
    // this.formLegendPosition.setValue(this.graphOption.legendPosition ? this.graphOption.legendPosition : "right");
    this.formAutoScale.setValue(this.graphOption.autoScale);
    this.formYScaleMin.setValue(this.graphOption.yScaleMin);
    this.formYScaleMax.setValue(this.graphOption.yScaleMax);
    this.formGradient.setValue(this.graphOption.gradient);
  }

  onApply() {
    this.graphOption.graphType = parseInt(this.formGraphType.value);
    this.graphOption.xAxis = this.formXAxis.value;
    this.graphOption.yAxis = this.formYAxis.value;
    this.graphOption.showYAxisLabel = this.formShowYAxisLabel.value;
    this.graphOption.showXAxisLabel = this.formShowXAxisLabel.value;
    this.graphOption.xAxisLabel = this.formXAxisLabel.value;
    this.graphOption.yAxisLabel = this.formYAxisLabel.value;
    this.graphOption.legend = this.formLegend.value;
    this.graphOption.legendTitle = this.formLegendTitle.value;
    // this.graphOption.legendPosition = this.formLegendPosition.value;
    this.graphOption.autoScale = this.formAutoScale.value;
    if (this.formYScaleMin.value && this.formYScaleMin.value !== "") {
      this.graphOption.yScaleMin = parseInt(this.formYScaleMin.value);
    } else {
      this.graphOption.yScaleMin = undefined;
    }
    if (this.formYScaleMax.value && this.formYScaleMax.value != "") {
      this.graphOption.yScaleMax = parseInt(this.formYScaleMax.value);
    } else {
      this.graphOption.yScaleMax = undefined;
    }
    this.graphOption.gradient = this.formGradient.value;

    this.exitDialog();
  }

  exitDialog() {
    this.dialogRef.close();
  }

  isXAxis() {
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_PIE) return false;
    return true;
  }

  isYAxis() {
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_PIE) return false;
    return true;
  }

  isAutoScale() {
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_LINE) return true;
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_AREA) return true;
    return false;
  }

  isYScaleMin() {
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_LINE) return true;
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_AREA) return true;
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_STACKED_AREA) return true;
    // if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_VERTICAL_BAR) return true;
    return false;
  }

  isYScaleMax() {
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_LINE) return true;
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_AREA) return true;
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_STACKED_AREA) return true;
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_VERTICAL_BAR) return true;
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_GROUPED_VERTICAL_BAR) return true;
    if (parseInt(this.formGraphType.value) == GraphService.GRAPH_TYPE_STACKED_VERTICAL_BAR) return true;
    return false;
  }

}
