import { Component, OnInit, Input, Output, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { CtgSelectCondition, CtgSelectComponent } from '../ctg-select/ctg-select.component';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { RackSelectCondition, RackSelectComponent } from '../rack-select/rack-select.component';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';

export class ItemSearchCondition {

  public ctgSelectCondition : CtgSelectCondition = new CtgSelectCondition(this.fb);
  public rackSelectCondition : RackSelectCondition = new RackSelectCondition(this.fb);
  public formGroup : FormGroup = this.fb.group({
    salesDate : ['', Validators.required],
    storeCd : ['', Validators.required],
    itemCode: [''],
    itemName: [''],
    orderCurrent: this.fb.group({
      isEnable: [false],
      operatorType: [0],
      orderNum: [0]
    }),
    orderInitial: this.fb.group({
      isEnable: [false],
      operatorType: [0],
      orderNum: [0]
    }),
    stockCurrent: this.fb.group({
      isEnable: [false],
      operatorType: [0],
      stockNum: [0]
    }),
    stockInitial: this.fb.group({
      isEnable: [false],
      operatorType: [0],
      stockNum: [0]
    }),
    orderStopFlagFormGroup: this.fb.group({ }),
    itemViewSort : this.fb.group({ })
  });

  constructor(private fb: FormBuilder) { }

  /**
 * Deep clones the given AbstractControl, preserving values, validators, async validators, and disabled status.
 * @param control AbstractControl
 * @returns AbstractControl
 */
  cloneAbstractControl<T extends AbstractControl>(control: T): T {
    let newControl: T;

    if (control instanceof FormGroup) {
      const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
      const controls = control.controls;

      Object.keys(controls).forEach(key => {
        formGroup.addControl(key, this.cloneAbstractControl(controls[key]));
      })

      newControl = formGroup as any;
    }
    else if (control instanceof FormArray) {
      const formArray = new FormArray([], control.validator, control.asyncValidator);

      control.controls.forEach(formControl => formArray.push(this.cloneAbstractControl(formControl)))

      newControl = formArray as any;
    }
    else if (control instanceof FormControl) {
      newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
    }
    else {
      throw new Error('Error: unexpected control value');
    }

    if (control.disabled) newControl.disable({ emitEvent: false });

    return newControl;
  }
}

@Component({
  selector: 'app-item-select-condition',
  templateUrl: './item-select-condition.component.html',
  styleUrls: ['./item-select-condition.component.css']
})
export class ItemSelectConditionComponent implements OnInit, OnDestroy {

  public orderCurrent : FormGroup;
  public orderInitial : FormGroup;
  public stockCurrent : FormGroup;
  public stockInitial : FormGroup;
  public orderStopFlagFormGroup : FormGroup;
  public itemViewSort : FormGroup;

  storeCdSubscription : Subscription;
  orderCurrentSubscription : Subscription;
  orderInitialSubscription : Subscription;
  stockCurrentSubscription : Subscription;
  stockInitialSubscription : Subscription;
  itemViewSortSubscription : Subscription;

  public operatorNONE = 0;
  public operatorLT = 1;
  public operatorLE = 2;
  public operatorEQ = 3;
  public operatorGE = 4;
  public operatorGT = 5;

  public operators : {type:number, str:string} [] = [
    {type:this.operatorLT, str:"&nbsp;＜&nbsp;"},
    {type:this.operatorLE, str:"＜＝"},
    {type:this.operatorEQ, str:"&nbsp;＝&nbsp;"},
    {type:this.operatorGE, str:"＞＝"},
    {type:this.operatorGT, str:"&nbsp;＞&nbsp;"}
  ];

  orderStopFlagList: { value: number, name: string, canOrder: boolean, changeable: boolean, searchCond?: boolean }[] = [];

  public notifyEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild(CtgSelectComponent, {static: true}) public ctgSelectElement : CtgSelectComponent;
  @ViewChild(RackSelectComponent, {static: false}) public rackSelectElement : RackSelectComponent;

  @Input() public itemSearchCondition: ItemSearchCondition;
  @Input() public showOrderDate: boolean = true;
  @Input() public orderStopFlag: number[];

  @Output('doQuery') public clickQuery : EventEmitter<any> = new EventEmitter<any>();

  constructor(public commonService: CommonService) { }

  ngOnInit() {
    this.itemSearchCondition.formGroup.get('storeCd').setValue(this.commonService.loginUser.storeCd);
    this.itemSearchCondition.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;

    this.orderCurrent = this.itemSearchCondition.formGroup.get('orderCurrent') as FormGroup;
    this.orderInitial = this.itemSearchCondition.formGroup.get('orderInitial') as FormGroup;
    this.stockCurrent = this.itemSearchCondition.formGroup.get('stockCurrent') as FormGroup;
    this.stockInitial = this.itemSearchCondition.formGroup.get('stockInitial') as FormGroup;
    this.orderStopFlagFormGroup = this.itemSearchCondition.formGroup.get('orderStopFlagFormGroup') as FormGroup;
    this.itemViewSort = this.itemSearchCondition.formGroup.get('itemViewSort') as FormGroup;

    //Thanh edit 2011104
    /*IAKIMOVA IULIIAの依頼：
    エフケイは発注業務画面をちょっと変更したいみたいです。
    元々発注業務画面開いたら、条件が何も絞っていないです。
    これから欲しいのは、画面開くと、既に発注条件に >0という条件が入っていること
    */
    if(this.commonService.config.orderCheckItemSelect){
      this.orderCurrent.get('isEnable').setValue(true);
      this.orderCurrent.get('operatorType').setValue(this.operatorGT); // >
    }

    this.setStatusOrderCurrent();
    this.setStatusOrderInitial();
    this.setStatusStockCurrent();
    this.setStatusStockInitial();



    this.storeCdSubscription = this.itemSearchCondition.formGroup.get('storeCd').valueChanges
    .subscribe(data => this.storeChanged(data));

    this.orderCurrentSubscription = this.orderCurrent.get('isEnable').valueChanges
    .subscribe(data => this.setStatusOrderCurrent());
    this.orderInitialSubscription = this.orderInitial.get('isEnable').valueChanges
    .subscribe(data => this.setStatusOrderInitial());
    this.stockCurrentSubscription = this.stockCurrent.get('isEnable').valueChanges
    .subscribe(data => this.setStatusStockCurrent());
    this.stockInitialSubscription = this.stockInitial.get('isEnable').valueChanges
    .subscribe(data => this.setStatusStockInitial());
    this.initOrderStopFlagForm();
    this.initItemViewSortForm();
  }

  ngOnDestroy()
  {
    if (this.storeCdSubscription) {
      this.storeCdSubscription.unsubscribe;
    }
    if (this.orderCurrentSubscription) {
      this.orderCurrentSubscription.unsubscribe;
    }
    if (this.orderInitialSubscription) {
      this.orderInitialSubscription.unsubscribe;
    }
    if (this.stockCurrentSubscription) {
      this.stockCurrentSubscription.unsubscribe;
    }
    if (this.stockInitialSubscription) {
      this.stockInitialSubscription.unsubscribe;
    }
    this.unsubscribeOrderValue();
  }

  storeChanged(storeCd: string) {
    this.ctgSelectElement.setStoreCd(storeCd);
    if (this.commonService.config.useRack) {
      this.rackSelectElement.setStoreCd(storeCd);
    }
  }

  initRack() {
    /*
    if (this.commonService.config.useRack) {
      this.rackSelectElement.getRackData(1);
    }
    */
   this.notifyEvent.emit();
  }

  setStatusOrderCurrent() {
    if (this.orderCurrent.get('isEnable').value) {
      this.orderCurrent.get('operatorType').enable();
      this.orderCurrent.get('orderNum').enable();
    } else {
      this.orderCurrent.get('operatorType').disable();
      this.orderCurrent.get('orderNum').disable();
    }
  }
  setStatusOrderInitial() {
    if (this.orderInitial.get('isEnable').value) {
      this.orderInitial.get('operatorType').enable();
      this.orderInitial.get('orderNum').enable();
    } else {
      this.orderInitial.get('operatorType').disable();
      this.orderInitial.get('orderNum').disable();
    }
  }
  setStatusStockCurrent() {
    if (this.stockCurrent.get('isEnable').value) {
      this.stockCurrent.get('operatorType').enable();
      this.stockCurrent.get('stockNum').enable();
    } else {
      this.stockCurrent.get('operatorType').disable();
      this.stockCurrent.get('stockNum').disable();
    }
  }
  setStatusStockInitial() {
    if (this.stockInitial.get('isEnable').value) {
      this.stockInitial.get('operatorType').enable();
      this.stockInitial.get('stockNum').enable();
    } else {
      this.stockInitial.get('operatorType').disable();
      this.stockInitial.get('stockNum').disable();
    }
  }

  doQuery() {
    this.clickQuery.emit();
  }

  initOrderStopFlagForm() {
    if (this.orderStopFlag) {
      this.orderStopFlagList = [];
      this.orderStopFlag.forEach((val) => {
        let flag = this.commonService.config.orderStopFlag.find((item) => item.value === val);
        if (flag) this.orderStopFlagList.push(flag);
      });
    } else {
      this.orderStopFlagList = this.commonService.getOrderStopSearchCondList();
    }
    if (this.orderStopFlagList?.length <= 0) return;

    [...this.orderStopFlagList].forEach((orderStopFlagItem) => {
      let fc: FormControl;
      fc = new FormControl(true);
      this.orderStopFlagFormGroup.addControl(`orderStopFlagItem_${orderStopFlagItem.value}`, fc);
    })

  }

  changeAllOrderStopFlag(val: boolean) {
    if (this.orderStopFlagList?.length <= 0) return;

    [...this.orderStopFlagList].forEach((orderStopFlagItem) => {
      this.orderStopFlagFormGroup.get(`orderStopFlagItem_${orderStopFlagItem.value}`).setValue(val);
    })
  }

  initItemViewSortForm(): void {
    if (!this.commonService?.config?.itemViewSort || this.commonService?.config?.itemViewSort?.length <= 0) return;
    for (let item of this.commonService.config.itemViewSort) {
      const newFG = new FormGroup({});
      newFG.addControl('orderType', new FormControl(item?.orderTypeDefault ?? ''));
      newFG.addControl('orderValue', new FormControl(item?.orderValueDefault ?? 0));
      this.itemViewSort.addControl(item.column, newFG);
    }

    // clear overlap init
    for (let groupName in this.itemViewSort.controls) {
      const fG = this.itemViewSort?.controls[groupName];
      const orderValue = fG.get('orderValue') as FormControl;
      if (orderValue?.value != 0) {
        this.clearOverlapOrderValue(orderValue);
      }
    }

    // subscribe clear overlap
    this.subscribeOrderValue();
  }

  subscribeOrderValue(): void {
    this.unsubscribeOrderValue();
    this.itemViewSortSubscription = new Subscription();
    if (!this.itemViewSort?.controls) return;
    for (let groupName in this.itemViewSort.controls) {
      const fG = this.itemViewSort?.controls[groupName];
      const orderValue = fG.get('orderValue') as FormControl;
      const subs = orderValue.valueChanges.subscribe((val) => {
        if (val != 0) {
          this.clearOverlapOrderValue(orderValue)
        }
      })

      this.itemViewSortSubscription.add(subs);
    }
  }

  unsubscribeOrderValue(): void {
    if (this.itemViewSortSubscription) {
      this.itemViewSortSubscription.unsubscribe();
      this.itemViewSortSubscription = undefined;
    }
  }

  clearOverlapOrderValue(fc: FormControl): void {
    if (!this.itemViewSort?.controls) return;
    for (let groupName in this.itemViewSort.controls) {
      const fG = this.itemViewSort?.controls[groupName];
      const orderValueTmp = fG.get('orderValue');
      if (!orderValueTmp || fc == orderValueTmp || orderValueTmp?.value == 0) continue;
      if (fc.value == orderValueTmp?.value) orderValueTmp.setValue(0, { emitEvent: false })
    }
  }

  onScan(barcode: string) {
    this.itemSearchCondition.formGroup.get("itemCode").setValue(barcode);
    this.doQuery();
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          this.itemSearchCondition.formGroup.get("itemCode").setValue(data);
          this.doQuery();
        }
      }
    )
  }
}
