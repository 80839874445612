import { CommonService } from 'src/app/service/common.service';
import { SubTaskOtherStoresResultRecDto } from '../0_def/taskOtherStoresRec';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MatDialogRef } from '@angular/material/dialog';
import * as JSZip from 'jszip';

export class TaskImagesZip {

  private spinnerRef : MatDialogRef<any, any>;
  private imageList: {image: string; fname: string}[] = [];

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService
    ) {
  }

  async createAndDownload(otherStoreRecs: SubTaskOtherStoresResultRecDto[]) {
    this.spinnerRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "ZIP作成中・・・");

    for (let i = 0; i < otherStoreRecs.length; i++) {
      let storeSub = otherStoreRecs[i];
      if (!storeSub.imageList) continue;
      for (let j = 0; j < storeSub.imageList.length; j++) {
        let imageRec = storeSub.imageList[j];
        let base64Image = imageRec.image ?? imageRec.imageThumbnail;
        if (!base64Image) continue;
        let imageType = base64Image.split("/")[1].split(";")[0];            // "data:image/png;base64,iVBORw0KG..."
        let found = ["jpeg", "png", "gif"].find((type) => type === imageType);
        if (!found) {
          continue;
        }
        let fname = storeSub.storeCd + storeSub.storeName + "_" + storeSub.subTaskName + "_" + imageRec.imageId + "." + imageType;
        // zip.file(fname, base64Image.replace(/^data:image\/(png|jpeg|gif);base64,/, ''),  {base64: true});
        this.imageList.push({
          image: base64Image.replace(/^data:image\/(png|jpeg|gif);base64,/, ''),
          fname: fname
        });
      }
    }

    let timestamp = this.commonService.formatDateTime(new Date());
    timestamp = timestamp.replace(/\//g, "");
    timestamp = timestamp.replace(/\:/g, "");
    timestamp = timestamp.replace(" ", "_");

    let maxCntPerZip = 30;
    let start = 0;
    let rem = this.imageList.length;
    let zipCount = 1;
    while (rem > 0) {
      let cnt = maxCntPerZip;
      if (cnt > rem) cnt = rem;
      let list = this.imageList.slice(start, start + cnt);
      let base64 = await this.createZip(list);
      const a = document.createElement('a');
      a.href = `data:application/zip;base64, ${base64}`;
        a.download = "TaskImages_" + timestamp + "_" + zipCount + ".zip";
      a.style.display = 'none';
      a.click();
      rem -= cnt;
      start += cnt;
      zipCount++;
    }

    this.commonService.closeSpinnerForSubComp(this.spinnerRef);
  }

  async createZip(list: {image: string; fname: string}[]): Promise<string> {
    let zip = new JSZip();
    list.forEach((rec) => {
      zip.file(rec.fname, rec.image, {base64: true});
    });

    return zip.generateAsync({type: 'base64', compression: 'DEFLATE'});
  }
}
