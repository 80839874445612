import { CommonService } from '../service/common.service';
import { RspCtgHistDto } from '../response/rsp-ctg-hist-search';

export class StoreGroupTypeRec {
    storeGroupTypeCd: number;
    storeGroupType: string;

    /*
    constructor(private ctgHistDto: RspCtgHistDto, private commonService: CommonService) {
        for (var key of Object.keys(ctgHistDto)) {
            this[key] = ctgHistDto[key];
        }
    }
    */
}