import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { CtgNameItem, CtgService } from 'src/app/service/ctgService';

@Component({
  selector: 'app-ctg-select-new',
  templateUrl: './ctg-select-new.component.html',
  styleUrls: ['./ctg-select-new.component.css']
})
export class CtgSelectNewComponent implements OnInit, OnDestroy, OnChanges {

  public ctgNameList:   CtgNameItem[][];
  public ctgNameEmpty:  CtgNameItem = {
    storeCd:          "",
    storeName:        "",
    ctgLevel:         -1,
    ctgCd:            "",
    ctgName:          "",
    ctgNameWithCode:  ""
  };
  public formGroup:     FormGroup;

  private formSubsc:    Subscription[] = [];
  private disableEmit:  boolean;
  private storeCd:      string;

  @Input("storeCd")   storeCdInput: string = "*";
  @Input("ctgCds")    ctgCds: string | string[];
  @Output("onChange") onChange: EventEmitter<any> = new EventEmitter();

  @Input() set disable(val: any) {
    this._disableFlag = coerceBooleanProperty(val);
  }

  get castedDisableFlag(): boolean {
    return this._disableFlag;
  }

  private _disableFlag: boolean = false;

  constructor(
    public commonService: CommonService,
    private ctgService: CtgService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef) {
    /*
    this.ctgNameList = [];
    for (let i = 0; i < 4; i++) {
      this.ctgNameList.push([]);
    }

    this.formGroup = this.fb.group({
      storeCd:  [this.storeCd],
      L0:       [""],
      L1:       [""],
      L2:       [""],
      L3:       [""]
    });
    */
  }

  ngOnInit() {
    // this.init();
  }

  ngOnDestroy() {
    this.watchFormChange(false);
  }

  ngOnChanges(changes:SimpleChanges) {
    if(coerceBooleanProperty(changes['disable']?.previousValue) !== coerceBooleanProperty(changes['disable']?.currentValue)) {
      return;
    }
    this.init();
  }

  init() {
    this.disableEmit = true;
    this.watchFormChange(false);

    this.ctgNameList = [];
    for (let i = 0; i < 4; i++) {
      this.ctgNameList.push([]);
    }

    this.formGroup = this.fb.group({
      storeCd:  [this.storeCd],
      L0:       [""],
      L1:       [""],
      L2:       [""],
      L3:       [""]
    });

    this.storeCd = this.storeCdInput;
    let ctgPath: string[];
    if (!this.ctgCds) {
      ctgPath = ["", "", "", ""];
    } else {
      if (typeof this.ctgCds == "string") {
        ctgPath = this.ctgService.getCtgPath(this.storeCd, this.ctgCds);
      } else {
        ctgPath = this.ctgCds;
      }
    }

    this.formGroup.get("storeCd").setValue(this.storeCd);
    this.formGroup.get("L0").setValue(ctgPath[0]);
    this.formGroup.get("L1").setValue(ctgPath[1]);
    this.formGroup.get("L2").setValue(ctgPath[2]);
    this.formGroup.get("L3").setValue(ctgPath[3]);

    this.ctgNameList[0] = [this.ctgNameEmpty, ...this.ctgService.getChildNameList(this.storeCd, [])];
    let path: string[] = [];
    for (let level = 0; level < ctgPath.length && level < this.commonService.config.maxCtgLevel - 1; level++) {
      if (!ctgPath[level] || ctgPath[level] === "") break;
      path.push(ctgPath[level]);
      this.ctgNameList[level + 1] = [this.ctgNameEmpty, ...this.ctgService.getChildNameList(this.storeCd, path)];
    }

    this.watchFormChange(true);
    this.disableEmit = false;
    this.cdr.detectChanges();
  }

  watchFormChange(mode: boolean) {
    if (mode) {
      if (this.formSubsc) this.formSubsc.map((subsc) => subsc.unsubscribe());
      this.formSubsc = [];
      for (let key in this.formGroup.controls) {
        let subsc = this.formGroup.get(key).valueChanges.subscribe(
          (value) => {this.formChanged(key, value);}
        );
        this.formSubsc.push(subsc);
      }
    } else {
      if (this.formSubsc) this.formSubsc.map((subsc) => subsc.unsubscribe());
      this.formSubsc = undefined;
    }
  }

  formChanged(key: string, value: string) {
    switch(key) {
      case "storeCd":
        this.formGroup.get("L0").setValue("");
        this.ctgNameList[0] = [this.ctgNameEmpty, ...this.ctgService.getChildNameList(this.storeCd, [])];
        break;
      case "L0":
      case "L1":
      case "L2":
        let level = parseInt(key.substring(1));
        if (level < this.commonService.config.maxCtgLevel - 1 && this.formGroup.get(key).value !== "") {
          let ctgPath = [];
          for (let i = 0; i <= level; i++) {
            ctgPath.push(this.formGroup.get("L" + i).value);
          }
          this.ctgNameList[level + 1] = [this.ctgNameEmpty, ...this.ctgService.getChildNameList(this.storeCd, ctgPath)];
        } else if (level < this.commonService.config.maxCtgLevel - 1) {
          this.ctgNameList[level + 1] = [];
        }
        this.formGroup.get("L" + (level + 1)).setValue("");
        break;
      case "L3":
        break;
    }
    // Higher level ctg changes cause lower level ctg valueChanges.
    // So, eimit event only when the last valueChange
    if (!this.disableEmit && key === "L" + (this.commonService.config.maxCtgLevel - 1)) this.onChange.emit(this.getValue());
  }

  public setStoreCd(storeCd: string) {
    this.formGroup.get("storeCd").setValue(storeCd);
    this.storeCd = storeCd;
  }

  getValue(): string[] {
    return [
      this.formGroup.get("L0").value,
      this.formGroup.get("L1").value,
      this.formGroup.get("L2").value,
      this.formGroup.get("L3").value
    ];
  }



}
