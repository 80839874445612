import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { CsvData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';

interface StoreListItem {
  storeCd: string;
  storeName: string;
  form: FormControl;
}

@Component({
  selector: 'app-csv-table-store',
  templateUrl: './csv-table-store.component.html',
  styleUrls: ['./csv-table-store.component.css']
})
export class CsvTableStoreComponent implements OnInit, OnChanges {
  private maxHeightDefault: number = 400;
  private isError: boolean = false;
  public recordCount: number = 0;
  public pagenatorMaxWidth: any;
  public tableWidth: any;
  public tableContainerStyle: any;
  public tableContainerStoreStyle: any;
  public displayColumnIds: string[];
  public columnIds: string[] = ["lineNumber"];
  public columnIdsWithError: string[] = ["lineNumber", "errorWaring"];
  public displayColumnDefs: TableColumnDef[] = [];
  public csvDataDisplay: CsvData;
  public storeList: StoreListItem[];

  checkedStoreCd = new Set<string>();

  @Input("columnDef") columnDefs: TableColumnDef[];
  @Input("csvData") csvData: CsvData;
  @Input("maxHeight") maxHeight: number = this.maxHeightDefault;
  @Input("storeSelectTitle") storeSelectTitle: string = "対象店舗選択";
  @Input("paginator") paginator: boolean = false;
  @Output() storeChange = new EventEmitter();
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.displayColumnDefs = [...this.columnDefs];
    this.setupColumns();

    this.storeList = [];
    let defaultVal = false;
    if (this.commonService.stores.length == 1) defaultVal = true;
    for (let store of this.commonService.stores) {
      let item: StoreListItem = {
        storeCd: store.storeCd,
        storeName: store.storeCd + "：" + store.storeName,
        form: new FormControl(defaultVal)
      };
      this.storeList.push(item);
    }
  }

  ngOnChanges() {
    this.csvDataDisplay = this.csvData;
    this.buildCsvDisplayList(0)
    this.setupColumns();
    this.adjustHeight();
  }

  init(csvData: CsvData) {
    this.csvData = csvData;
    this.setupColumns();
  }

  handleError() {
    let errorCount = 0;
    let warningCount = 0;
    for (let line of this.csvData.lines) {
      if (line.errorMsg && line.errorMsg.length > 0) errorCount++;
      if (line.warningMsg && line.warningMsg.length > 0) warningCount++;
    }
    this.csvData.errorCount = errorCount;
    this.csvData.warningCount = warningCount;
    this.setupColumns();
  }

  setMaxHeight(height) {
    this.maxHeight = height;
    this.adjustHeight();
  }

  setupColumns() {
    if (this.csvData.errorCount || this.csvData.warningCount &&
      this.csvData.errorCount > 0 || this.csvData.warningCount > 0) {
      this.displayColumnIds = [...this.columnIdsWithError];
      this.isError = true;
    } else {
      this.displayColumnIds = [...this.columnIds];
      this.isError = false;
    }
    for (let col of this.columnDefs) {
      this.displayColumnIds.push(col.columnId);
    }

    this.calcTableWidth();
  }

  styleFor(id: string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    width = width + 60 + 1 + 8;    // for line number
    if (this.isError) {
      width = width + 200 + 1 + 8;    // for Error/Warning Cell
    }

    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
    this.pagenatorMaxWidth = { "width": "" + width + "px" };
  }

  adjustHeight() {
    let countLineHeight = 24 + 5;     /* 5 = top-margin */
    let paginatorHeight = 56;
    if (this.paginator) {
      this.tableContainerStyle = {
        "max-height": this.maxHeight ? (this.maxHeight - countLineHeight - paginatorHeight - 2) + "px" : "unset"
      };
    } else {
      this.tableContainerStyle = {
        "max-height": this.maxHeight ? (this.maxHeight - countLineHeight - 2) + "px" : "unset"
      };
    }

    let storeHeaderHeight = 24 + 5;   /* 5 = top-margin of Store Table */
    this.tableContainerStoreStyle = {
      "max-height": this.maxHeight ? (this.maxHeight - countLineHeight - storeHeaderHeight - 2) + "px" : "unset"
    };
  }

  getLineCount() {
    return this.csvData.lines.length;
  }

  getErrorCount() {
    if (this.csvData.errorCount == undefined) return undefined;
    return this.csvData.errorCount
  }

  getWarningCount() {
    if (this.csvData.warningCount == undefined) return "";
    return this.csvData.warningCount
  }
  pageChanged(event) {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.buildCsvDisplayList(event);
    this.setupColumns();
    this.commonService.closeSpinner();
  }

  buildCsvDisplayList(event: any) {
    if (!event && this.matPaginator) {
      this.matPaginator.pageIndex = 0;
    }
    let start: number;
    let last: number;
    if (this.paginator) {
      if (this.matPaginator) {
        start = this.matPaginator.pageSize * this.matPaginator.pageIndex;
        last = start + this.matPaginator.pageSize;
      } else {
        start = 0;
        last = this.commonService.paginatorOption.pageSizeOptions[0];
      }
    } else {
      start = 0;
      last = this.csvData.lines.length;
    }

    let max = this.csvData.lines.length;
    this.recordCount = this.csvData.lines.length;
    if (max > 100) max = 100;
    this.csvDataDisplay = {
      errorCount: this.csvData.errorCount,
      warningCount: this.csvData.warningCount,
      lines: []
    }
    for (let i = start; i < last && i < this.csvData.lines.length; i++) {
      this.csvDataDisplay.lines.push(this.csvData.lines[i]);
      // if (this.csvData.lines[i].errorMsg.length > 0) this.csvData.errorCount++;
      // if (this.csvData.lines[i].warningMsg.length > 0) this.csvData.warningCount++;
    }
  }

  storeSelectAll() {
    for (let store of this.storeList) {
      store.form.setValue(true);
      this.checkedStoreCd.add(store.storeCd);
    }

    this.storeChange.emit();
  }

  storeUnselectAll() {
    for (let store of this.storeList) {
      store.form.setValue(false);
    }
    this.checkedStoreCd.clear();
    this.storeChange.emit();

  }

  getSelectedStoreCount() {
    let count = 0;
    for (let store of this.storeList) {
      if (store.form.value == true) count++;
    }
    return count;
  }

  getSelectedStore() {
    let stores = [];
    for (let store of this.storeList) {
      if (store.form.value == true) {
        stores.push({ storeCdFv:store.storeCd, storeNameFv: store.storeName })
      };
    }
    return stores;
  }
  
  onItemChecked(storeCd: string, checked: boolean) {

    if (checked) {
      this.checkedStoreCd.add(storeCd);
    } else {
      this.checkedStoreCd.delete(storeCd);
    }
    this.storeChange.emit();
  }
}
