import { FormControl } from "@angular/forms";
import { GetOrderGroupUserItemDto } from "src/app/webservice/GetOrderGroupUserSingle";

export class OrderGroupUserSingle {

  public storeCd: string                = "";
  public groupId: number                = -1;
  public effectiveDate: string          = "";
  public endDate: string                = "";
  public groupUsrCd: string             = "";
  public groupName: string              = "";
  public userIdActive: string           = "";
  public userNameActive: string         = "";
  public dateBeginActive: string        = "";
  public dateEndActive: string          = "";
  public userIdNext: string             = "";
  public userNameNext: string           = "";
  public dateBeginNext: string          = "";
  public dateEndNext: string            = "";
  public userJSONNext: string           = "";
  public dateBeginNextForm: FormControl = new FormControl();
  public userJSONNextForm: FormControl  = new FormControl();

  private today: string = this.formatDate(new Date());
  public minDate: Date;

  constructor() {}

  formatDate(date: Date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}/${('0' + month).slice(-2)}/${('0' + day).slice(-2)}`;
  }

  init() {
    if (this.userIdNext !== "") {
      let user: GetOrderGroupUserItemDto = {
        storeCd: this.storeCd,
        userId: this.userIdNext,
        userName: this.userNameNext
      };
      this.userJSONNext = JSON.stringify(user);
    } else {
      this.userJSONNext = "";
    }
    if (this.dateBeginNext !== "") {
      let d = this.dateBeginNext.split("/");
      this.dateBeginNextForm.setValue(new Date(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2])));
    } else {
      this.dateBeginNextForm.setValue("");
    }
    this.userJSONNextForm.setValue(this.userJSONNext);

    if (this.effectiveDate === "" || this.effectiveDate < this.today) {
      let d = this.today.split("/");
      this.minDate = new Date(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2]));
    } else {
      let d = this.effectiveDate.split("/");
      this.minDate = new Date(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2]));
    }
  }

  getValue(key: string) {
    switch(key) {
      case "effectiveDate":
        if (this.effectiveDate <= this.today) return "";
        return this.effectiveDate;
      case "userNameActive":
        if (this.userNameActive === "") return "";
        return this.userNameActive + "[" + this.userIdActive + "]";
      case "userNameNext":
        let json = this.userJSONNextForm.value;
        if (json === "") return "";
        let user = JSON.parse(json);
        return user.userName + "[" + user.userId + "]";
      default:
        return this[key];
    }
  }

  clearNextDate() {
    this.dateBeginNextForm.setValue("");
  }

  cancel() {
    if (this.dateBeginNext !== "") {
      let d = this.dateBeginNext.split("/");
      this.dateBeginNextForm.setValue(new Date(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2])));
    } else {
      this.dateBeginNextForm.setValue("");
    }
    this.userJSONNextForm.setValue(this.userJSONNext);
    this.dateBeginNextForm.markAsPristine();
    this.userJSONNextForm.markAsPristine();
  }

  isEdited(key: string) {
    switch(key) {
      case "userNext":
        if (this.userJSONNext !== this.userJSONNextForm.value) return true;
        return false;
      case "dateBeginNext":
        if (this.dateBeginNextForm.value === "") {
          if (this.dateBeginNext !== "") return true;
          return false;
        }
        if (this.dateBeginNext !== this.formatDate(this.dateBeginNextForm.value)) return true;
        return false;
    }
    return false;
  }

  isDateError() {
    if (this.userJSONNextForm.value === "") return false;
    if (this.dateBeginNextForm.value === "") return true;
    return false;
  }

  isUserError() {
    if (this.dateBeginNextForm.value === "") return false;
    if (this.userJSONNextForm.value === "") return true;
    return false;
  }

  isError() {
    if (this.isDateError()) return true;
    if (this.isUserError()) return true;
    return false;
  }

  notApplicableField(key: string) {
    switch(key) {
      case "dateBeginActive":
      case "userNameActive":
        if (this.effectiveDate > this.today) return true;
        return false;
      default:
        return false;
    }
  }

}
