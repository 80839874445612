import { CsvLineData } from '../../common/csv-data';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CsvData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CsvTableComponent } from 'src/app/partsCommon/csv-table/csv-table.component';
import { ReqCsvSeasonalCtgTargetCheckDto } from 'src/app/request/req-csv-seasonal-ctg-target-check';
import { ReqCsvSeasonalCtgTargetUpdateDto } from 'src/app/request/req-csv-seasonal-ctg-target-update';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspCsvSeasonalCtgTargetCheck } from '../../response/rsp-csv-seasonal-ctg-target-check';
import { RspCsvSeasonalCtgTargetUpdate } from '../../response/rsp-csv-seasonal-ctg-target-update';
import { RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';

interface CurrentDef {
  forecastDate:   string;
  ctgCd:          string;
  ctgName:        string;
  targetValue:    string;
  orgTargetValue: string;
  updateFlag:     string;
}

@Component({
  selector: 'app-csv-seasonal-category-target',
  templateUrl: './csv-seasonal-category-target.component.html',
  styleUrls: ['./csv-seasonal-category-target.component.css']
})
export class CsvSeasonalCategoryTargetComponent implements OnInit, OnDestroy {

  private isReadonly: boolean;
  public targetList: CsvData;
  public currentDefList: CurrentDef[];
  public tableWidth: any;
  public displayColumnIds: string[];
  public columnDefs: TableColumnDef[] = [
    { columnId: "ctgCd", header: "カテゴリ・コード", width: 120, align: "center", csvComment: "[最下層のカテゴリを指定]" },
    { columnId: "ctgName", header: "カテゴリ名", width: 300, align: "left", csvComment: "[空欄可]" },
    { columnId: "targetValue", header: "目標値", width: 70, align: "right", csvComment: "[１以上の整数を指定]" }
  ];
  public displayColumnDefs: TableColumnDef[] = [];

  public defColumnIds: string[] = ["forecastDate", "ctgCd", "ctgName", "targetValue", "orgTargetValue", "updateFlag"];
  public defColumnDefs: TableColumnDef[] = [
    { columnId: "forecastDate", header: "予測適用日", width: 100, align: "center"},
    { columnId: "ctgCd", header: "カテゴリ・コード", width: 120, align: "center"},
    { columnId: "ctgName", header: "カテゴリ名", width: 200, align: "left"},
    { columnId: "targetValue", header: "目標値(新)", width: 60, align: "right"},
    { columnId: "orgTargetValue", header: "目標値(旧)", width: 60, align: "right"},
    { columnId: "updateFlag", header: "更新", width: 50, align: "center"}
  ];

  public subscriptionCheck: Subscription;
  public subscriptionUpdate: Subscription;

  @ViewChild(CsvTableComponent, { static: false }) csvTableComponent: CsvTableComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = '季節カテゴリ目標値取込み';
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("csvseasonalcategorytarget");

    if (this.commonService.stores.length <= 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この機能を使用する権限がありません。");
      this.commonService.router.navigate(['top']);
      return;
    }

    this.displayColumnDefs = [...this.columnDefs];
  }

  ngOnDestroy() {
    if (this.subscriptionCheck) {
      this.subscriptionCheck.unsubscribe();
    }
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
    }
  }

  getCsvData(csvData: CsvData) {
    this.targetList = this.filterOverlapCtg(csvData);
    if (this.csvTableComponent) {
      this.csvTableComponent.init(this.targetList);
    }
  }

  isDisableUpdateBtn() {
    if (this.targetList == undefined) return true;
    if (this.targetList.errorCount == undefined) return true;
    if (this.targetList.errorCount > 0) return true;
    return false;
  }

  doConfirm() {
    const request: ReqCsvSeasonalCtgTargetCheckDto[] = this.getDataOnTable();

    if (request.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "データがありません。");
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "確認中・・・");
    this.subscriptionCheck = this.httpBasic.checkCsvSeasonalCtgTarget(request).subscribe(
      (response: RspCsvSeasonalCtgTargetCheck) => {
        this.checkResultSCtgTCheck(response);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
    this.csvTableComponent.handleError();
  }

  doUpdate() {
    const request: ReqCsvSeasonalCtgTargetUpdateDto[] = this.getDataOnTable();

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.subscriptionUpdate = this.httpBasic.updateCsvSeasonalCtgTarget(request).subscribe(
      (response: RspCsvSeasonalCtgTargetUpdate) => {
        this.checkResultSCtgTUpdate(response);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  getDataOnTable() {
    let request: ReqCsvSeasonalCtgTargetCheckDto[] = [];
    this.targetList.lines.map(value => {
      let item: ReqCsvSeasonalCtgTargetCheckDto = {
        ctgCdFv: value.columnData['ctgCd'].trim(),
        ctgNameFv: value.columnData['ctgName'].trim(),
        targetValueFv: value.columnData['targetValue'].trim()
      }
      request.push(item);
    });
    return request;
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionCheck) {
      this.subscriptionCheck.unsubscribe();
      this.subscriptionCheck = undefined;
    }
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
      this.subscriptionUpdate = undefined;
    }
  }

  checkResultSCtgTCheck(response: RspCsvSeasonalCtgTargetCheck) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    if (response.result.length > 0) {
      response.result.map((item) => {
        if (item.errorMsgs) {
          this.targetList.lines[item.line].errorMsg = item.errorMsgs;
          this.targetList.lines[item.line].warningMsg = item.warningMsgs;
        }
      });
      this.csvTableComponent.handleError();
    }
  }

  checkResultSCtgTUpdate(response: RspCsvSeasonalCtgTargetUpdate) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');

    this.currentDefList = undefined;
  }

  filterOverlapCtg(targetList: CsvData) {
    let result: CsvData = { ...targetList };
    result.lines = [];
    for (var i = targetList.lines.length - 1; i >= 0; i--) {
      var overlapFlag = 0;
      for (var resultRow of result.lines) {
        if (resultRow.columnData['ctgCd'].trim() === targetList.lines[i].columnData['ctgCd'].trim()) {
          overlapFlag = 1;
          break;
        }
      }
      if (overlapFlag == 0) {
        result.lines.push(targetList.lines[i])
      }
    }
    result.lines.reverse()
    return result;
  }

  tabChanged(tabNumber: number) {
    if (tabNumber == 1) {
      this.getCurrentDefList();
    }
  }

  reloadCurrentDefList() {
    this.currentDefList = undefined;
    this.getCurrentDefList();
  }

  getCurrentDefList() {
    if (this.currentDefList) return;

    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "misc/getSeasonalCtgTarget",
      bindVariables:[
        "" + this.commonService.config.maxCtgLevel
      ]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response: RspExecuteQuery) => {
        subsc.unsubscribe();
        this.receiveCurrentDefList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveCurrentDefList(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.currentDefList = [];
    for (let item of response.rows) {
      let updateFlag = "";
      if (item.colData[5] !== "") {
        if (item.colData[4] !== "") {
          updateFlag = "更新";
        } else {
          updateFlag = "新規";
        }
      }

      let def: CurrentDef = {
        forecastDate: item.colData[0],
        ctgCd: item.colData[1],
        ctgName: item.colData[2],
        targetValue: item.colData[3],
        orgTargetValue: item.colData[4],
        updateFlag: updateFlag
      };
      this.currentDefList.push(def);
    }
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "max-width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "max-width": "" + colDef.width + "px"
    }
  }

  createCsvDate() {
    if (!navigator.clipboard) return; 

    let csv = "";
    let line = "";

    for (let i = 1; i < this.defColumnDefs.length; i++) {
      if (i > 1) line += "\t";
      line += this.defColumnDefs[i].header;
    } 
    csv = line + "\n";

    let prevCtg = "";
    for (let item of this.currentDefList) {
      if (item.ctgCd === prevCtg) continue;
      prevCtg = item.ctgCd;
      line = "";
      for (let i = 1; i < this.defColumnDefs.length; i++) {
        if (i > 1) line += "\t";
        line += item[ this.defColumnDefs[i].columnId ];
      } 
      csv += line + "\n";
    }

    navigator.clipboard.writeText(csv);
    this.commonService.openNotificationDialog(this.commonService.pageTitle, "クリップボードにコピーしました。")
  }

}
