import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CtgSelectNewComponent } from 'src/app/partsCommon/ctg-select-new/ctg-select-new.component';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { CommonService } from 'src/app/service/common.service';
import { CtgService } from 'src/app/service/ctgService';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { OrderStopFlagDto, ReqPromOrderItemSearch, ReqPromOrderItemSearchDto, ReqPromOrderItemUpdate, ReqPromOrderItemUpdateDto, RspPromOrderItemSearch } from 'src/app/webservice/promOrder';
import { PromOrder, PromOrderItem } from '../0_def/promOrder';
import { PromOrderType } from '../0_def/promOrderType';
import { PromRef } from '../0_def/promRef';
import { PromRefDialogComponent } from '../prom-ref-dialog/prom-ref-dialog.component';

export interface CalcPromItemDateInputDto {
  line: number;
  storeCd: string;
  itemCd: string;
  rcvDate: string;
  odrLt1Fn: number;
  odrLt2Fn: number;
  odrLt3Fn: number;
  odrLt4Fn: number;
  odrLt5Fn: number;
  odrLt6Fn: number;
  odrLt7Fn: number;
}
export interface CalcPromItemDateOutputDto {
  line: number;
  storeCd: string;
  itemCd: string;
  rcvDate: string;
  orderDate: string;
}

@Component({
  selector: 'app-prom-order-edit',
  templateUrl: './prom-order-edit.component.html',
  styleUrls: ['./prom-order-edit.component.css']
})
export class PromOrderEditComponent implements OnInit, OnDestroy, OnChanges {

  public isReadonly: boolean = false;
  public promOrder: PromOrder;
  public formStoreCd: FormControl = new FormControl();
  public formPiType: FormControl = new FormControl();
  public formPromOrderType: FormControl = new FormControl();
  public formApplyOption: FormControl = new FormControl();
  public formMinRcvDate: FormControl = new FormControl();
  public formMaxRcvDate: FormControl = new FormControl();
  public formMinOrderDate: FormControl = new FormControl();
  public formRcvDate: FormControl = new FormControl();
  private promRefEmpty: PromRef = { storeCd: "", promCd: "", promName: "", promDispName: "", dateBegin: "", dateEnd: "" };
  public promRefProm: PromRef = { ...this.promRefEmpty };
  public promRefPI: PromRef = { ...this.promRefEmpty };
  public piDateBegin: Date;
  public piDateEnd: Date;
  public piDateMax: Date;
  public minOrderDate: Date;
  public promOrderTypes: PromOrderType[] = [];
  public promOrderTypeOrigin: string = '';
  public orderStopFlagList: OrderStopFlagDto[] = [];

  public promOrderItems: PromOrderItem[] = [];
  public promOrderItemsOrigin: PromOrderItem[] = [];
  public promOrderItemsDispList: PromOrderItem[] = [];
  public recordCount: number = 0;
  public recordCountResOrigin: number = 0;
  public columnIdsHdr0: string[] = ["h0_itemCd", "h0_itemName", "h0_itemStandard", "h0_piHeader", "h0_promHeader", "h0_orderNum", "h0_rcvDate", "h0_orderDate"];
  public columnIdsHdr1: string[] = ["piValue", "piCustomers", "piRecommendNum", "promForecast", "promRate", "promRecommendNum"];
  public columnIdsData: string[] = [
    "itemCd", "itemName", "itemStandard", "piValue", "piCustomers", "piRecommendNum",
    "promForecast", "promRate", "promRecommendNum", "orderNum", "rcvDate", "orderDate"
  ];
  public columnDefsHdr0: TableColumnDef[] = [
    { columnId: "h0_itemCd", header: "商品コード", width: 90, rowspan: 2, colspan: 1 },
    { columnId: "h0_itemName", header: "商品名", width: 240, rowspan: 2, colspan: 1 },
    { columnId: "h0_itemStandard", header: "規格", width: 80, rowspan: 2, colspan: 1 },
    { columnId: "h0_piHeader", header: "PI値×客数", width: 150, rowspan: 1, colspan: 3 },
    { columnId: "h0_promHeader", header: "過去特売倍率", width: 150, rowspan: 1, colspan: 3 },
    { columnId: "h0_orderNum", header: "発注数", width: 60, rowspan: 2, colspan: 1 },
    { columnId: "h0_rcvDate", header: "納品予定日", width: 90, rowspan: 2, colspan: 1 },
    { columnId: "h0_orderDate", header: "発注日", width: 90, rowspan: 2, colspan: 1 }
  ];
  public columnDefsHdr1Data: TableColumnDef[] = [
    { columnId: "itemCd", header: "商品コード", width: 90, align: "center" },
    { columnId: "itemName", header: "商品名", width: 240, align: "left" },
    { columnId: "itemStandard", header: "規格", width: 80, align: "left" },
    { columnId: "piValue", header: "特売PI", width: 50, align: "right", numberPipe: "" },
    { columnId: "piCustomers", header: "客数", width: 50, align: "right", numberPipe: "" },
    { columnId: "piRecommendNum", header: "推奨数", width: 50, align: "right", numberPipe: "" },
    { columnId: "promForecast", header: "予測数", width: 50, align: "right", numberPipe: "" },
    { columnId: "promRate", header: "倍率", width: 50, align: "right", numberPipe: "1.1-1" },
    { columnId: "promRecommendNum", header: "推奨数", width: 50, align: "right", numberPipe: "" },
    { columnId: "orderNum", header: "発注数", width: 60, align: "right" },
    { columnId: "rcvDate", header: "納品予定日", width: 90, align: "center" },
    { columnId: "orderDate", header: "発注日", width: 90, align: "center" }
  ];
  public selectedPromOrderItem: PromOrderItem;
  public selectedCtg: string[] = [];

  private today: string;
  /* param for change promOrderItems */
  public orderNumSubscriptions: Subscription;
  /* end param for change promOrderItems */

  /* param for rev06 */
  public orderDateEndAfterMargin: Date = undefined;
  public orderDateEndAfterMarginInvalid: boolean = false;
  /* end param for rev06 */

  @Input("promOrder") promOrderInput: PromOrder;

  @ViewChild(CtgSelectNewComponent, { static: false }) ctgSelectNewComponent: CtgSelectNewComponent;
  @ViewChild(DateRangeComponent, { static: false }) dateRangeComponent: DateRangeComponent;
  @ViewChild(NumberKeypadComponent, { static: false }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  constructor(
    public commonService: CommonService,
    public ctgService: CtgService,
    private httpBasic: HttpBasicService
  ) { }

  ngOnInit(): void {
    this.today = this.commonService.formatDate(new Date());
    this.formStoreCd.setValue(this.commonService.loginUser.storeCd);
    this.formPiType.setValue("1");
    this.formApplyOption.setValue(true);

    this.piDateMax = new Date();
    this.piDateMax.setDate(this.piDateMax.getDate() - 1);
  }

  ngOnDestroy(): void {
    this.unsubscribeFormOrderNumAndRcvDate();
    this.orderDateEndAfterMargin = undefined;
    this.orderDateEndAfterMarginInvalid = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.promOrderItems = undefined;
    this.promOrderItemsOrigin = undefined;
    this.promOrderItemsDispList = undefined;
    this.promOrder = undefined;
    this.orderDateEndAfterMargin = undefined;
    this.orderDateEndAfterMarginInvalid = false;

    if (!this.promOrderInput) return;

    this.isReadonly = false;
    if (this.promOrderInput.orderDateEnd < this.today) this.isReadonly = true;

    if (this.promOrderInput.storeList && this.promOrderInput.storeList.length > 0) {
      let storeList = this.promOrderInput.storeList;
      let currentStore = this.formStoreCd.value;
      if (!storeList.find((store) => store.storeCd === currentStore)) {
        this.formStoreCd.setValue(storeList[0].storeCd);
      }
    } else {
      this.formStoreCd.setValue("");
    }
    if (this.promOrderInput.ctgList) {
      this.ctgService.initByCtgList(this.promOrderInput.ctgList);
      this.ctgSelectNewComponent?.init();
      this.promOrder = this.promOrderInput;
    } else {
      this.ctgService.initByQuery("prom/getPromCtgName", [this.promOrderInput.promCd], () => {
        this.promOrderInput.ctgList = this.ctgService.getCtgList();
        this.ctgSelectNewComponent?.init();
        this.promOrder = this.promOrderInput;
      });
    }

    this.promRefProm = { ...this.promRefEmpty };
    this.promRefPI = { ...this.promRefEmpty };

    this.piDateEnd = this.commonService.copyDate(this.piDateMax);
    this.piDateBegin = this.commonService.copyDate(this.piDateEnd);
    this.piDateBegin.setMonth(this.piDateBegin.getMonth() - 3);

    this.minOrderDate = this.getMinRcvDate();
    this.formMinRcvDate.setValue(this.minOrderDate);
    this.formMaxRcvDate.setValue(this.getMaxRcvDate());
    this.formMinOrderDate.setValue(this.getMinOrderDate());
    let rcvDate = this.getDefaultRcvDate();
    this.formRcvDate.setValue(rcvDate);

    this.orderDateEndAfterMargin = this.isDateValid(this.promOrderInput.orderDateEnd) ?
      this.commonService.copyDate(new Date(this.promOrderInput.orderDateEnd)) :
      null;
    if (this.orderDateEndAfterMargin) this.orderDateEndAfterMargin.setDate(this.orderDateEndAfterMargin.getDate() - (this.commonService?.config?.promOrderDateMargin ?? 1));
    this.orderDateEndAfterMarginInvalid = this.orderDateEndAfterMargin < this.commonService.copyDate(new Date());
  }

  disableShowList() {
    if (this.formStoreCd.value === "") return true;
    if (this.isReadonly == true) return false;
    if (this.promRefProm.promCd === "") return true;
    if (this.formPiType.value === "2" && this.promRefPI.promCd === "") return true;

    return false;
  }

  openPromRefDialog(type: string) {
    let subsc = this.commonService.dialog.open(PromRefDialogComponent, {
      disableClose: true,
      data: {
        storeCd: this.formStoreCd.value,
        type: type
      }
    }).afterClosed().subscribe(
      (data: PromRef) => {
        subsc.unsubscribe();
        if (!data) return;
        if (type == 'prom') {
          this.promRefProm = data;
        } else {
          this.promRefPI = data;
        }
      }
    );
  }

  doQuery() {
    this.selectedPromOrderItem = undefined;
    this.promOrderItems = [];
    this.promOrderItemsOrigin = [];
    this.promOrderTypes = [];
    this.orderStopFlagList = this.commonService.config?.orderStopFlag?.filter(e => e.canOrder == true);

    let request: ReqPromOrderItemSearch = {
      access: this.commonService.loginUser,
      orderStopFlagList: this.orderStopFlagList,
      promOrderItemSearch: this.genReqPromOrderItemSearchDto()
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("PromOrderItemSearch", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePromOrderItemSearch(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  genReqPromOrderItemSearchDto(): ReqPromOrderItemSearchDto {
    let piDateRage = this.dateRangeComponent?.getDateRange();
    const dto: ReqPromOrderItemSearchDto = {
      promCd: this.promOrderInput?.promCd ?? '',
      promStartDate: this.promOrderInput?.dateBegin ?? '',
      promFinishDate: this.promOrderInput?.dateEnd ?? '',
      storeCd: this.formStoreCd?.value ?? '',
      ctg0Cd: this.selectedCtg[0] ?? '',
      ctg1Cd: this.selectedCtg[1] ?? '',
      ctg2Cd: this.selectedCtg[2] ?? '',
      ctg3Cd: this.selectedCtg[3] ?? '',

      isTimeSearch: parseInt(this.formPiType?.value) == 1 ? 1 : 0,
      piBeginDate: piDateRage?.dateBegin ? this.commonService.formatDate(piDateRage.dateBegin) : '',
      piEndDate: piDateRage?.dateEnd ? this.commonService.formatDate(piDateRage.dateEnd) : '',
      promRefPiCd: this.promRefPI?.promCd ?? '',
      promRefPiStartDate: this.promRefPI?.dateBegin ?? '',
      promRefPiFinishDate: this.promRefPI?.dateEnd ?? '',

      promRefPromCd: this.promRefProm?.promCd ?? '',
      promRefPromStartDate: this.promRefProm?.dateBegin ?? '',
      promRefPromFinishDate: this.promRefProm?.dateEnd ?? '',
    }
    return dto;
  }

  receivePromOrderItemSearch(response: RspPromOrderItemSearch) {
    if (this.httpBasic.handleAppError(response)) {
      return;
    }
    response?.result.forEach((row) => {
      this.promOrderItems.push(new PromOrderItem(row));
    }
    );
    this.promOrderItemsOrigin = this.promOrderItems?.length > 0 ? [...this.promOrderItems].map(rec => new PromOrderItem(rec?.originDto)) : [];
    this.recordCount = this.promOrderItems?.length ?? 0;
    this.recordCountResOrigin = this.recordCount;
    if (this.matPaginator) {
      this.matPaginator.pageIndex = 0;
    }

    response?.orderTypes.forEach(orderType => {
      this.promOrderTypes.push(orderType);
    })

    this.promOrderTypeOrigin = response?.orderTypeFv ?? '';
    this.formPromOrderType.setValue(this.promOrderTypeOrigin);
    this.pageChanged();
    this.setEditingStatus();
  }

  pageChanged() {
    this.promOrderItemsDispList = [];
    this.recordCount = this.promOrderItems?.length || 0;
    this.unsubscribeFormOrderNumAndRcvDate();
    let start;
    let end;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.promOrderItems.length) end = this.promOrderItems.length;
    for (let i = start; i < end; i++) {
      this.promOrderItemsDispList.push(this.promOrderItems[i]);
    }
    this.subscribeFormOrderNumAndRcvDate();
  }

  isKeyboardLocked() {
    if (this.numberKeypadComponent?.isKeyboardLocked()) return true;
    return false;
  }

  is10KeyPadTarget(form: FormControl) {
    if (form === this.numberKeypadComponent?.inputField) return true;
    return false;
  }

  set10KeyPadTarget(form: FormControl) {
    this.numberKeypadComponent.inputField = form;
  }

  selectPromOrder(item: PromOrderItem) {
    this.selectedPromOrderItem = item;
  }

  setAllPiRecommendNum() {
    const applyOption: boolean = this.formApplyOption.value;
    const listEffect: PromOrderItem[] = [];
    for (let item of this.promOrderItems) {
      if (applyOption && item.formOrderNum.value != 0) continue;
      let targetValue = item.piRecommendNum || 0;
      if(targetValue == -1) targetValue = 0;

      if (targetValue != 0 && !item.requestedCalcDateByOrderNum && !this.isDateValid(item.formRcvDate?.value)) {
        item.formOrderNum.setValue(targetValue, { emitEvent: false });
        listEffect.push(item);
      } else {
        item.formOrderNum.setValue(targetValue);
      }
    }
    if (listEffect?.length > 0)
      this.calcRcvAndOrderDateMulti(listEffect, true);
  }

  setAllPromRecommendNum() {
    const applyOption: boolean = this.formApplyOption.value;
    const listEffect: PromOrderItem[] = [];
    for (let item of this.promOrderItems) {
      if (applyOption && item.formOrderNum.value != 0) continue;
      let targetValue = item.promRecommendNum || 0;
      if(targetValue == -1) targetValue = 0;

      if (targetValue != 0 && !item.requestedCalcDateByOrderNum && !this.isDateValid(item.formRcvDate?.value)) {
        item.formOrderNum.setValue(targetValue, { emitEvent: false });
        listEffect.push(item);
      } else {
        item.formOrderNum.setValue(targetValue);
      }
    }
    if (listEffect?.length > 0)
      this.calcRcvAndOrderDateMulti(listEffect, true);
  }

  setAllRcvDate() {
    const dateRcv: Date = new Date(this.formRcvDate.value);
    if (!this.formRcvDate.value || isNaN(dateRcv.getTime())) return;

    for (let item of this.promOrderItems) {
      item.formRcvDate.setValue(dateRcv, { emitEvent: false });
    }

    this.calcRcvAndOrderDateMulti(this.promOrderItems)
  }

  getMaxRcvDate(): Date {
    if (this.isDateValid(this.promOrderInput?.dateEnd))
      return this.commonService.copyDate(new Date(this.promOrderInput.dateEnd));
    return null;
  }

  getMinRcvDate(): Date {
    const actionDatePlus = new Date();
    actionDatePlus.setDate(actionDatePlus.getDate() + (this.commonService?.config?.promOrderDateMargin ?? 1) + 1);

    const orderDatePlus = this.promOrderInput?.orderDateBegin?.trim() != "" ?
      new Date(this.promOrderInput?.orderDateBegin) :
      undefined;
    orderDatePlus.setDate(orderDatePlus.getDate() + 1);

    if (!orderDatePlus) return actionDatePlus;
    if (actionDatePlus >= orderDatePlus) return actionDatePlus;
    return orderDatePlus;
  }

  getDefaultRcvDate(): Date {
    const actionDatePlus = new Date();
    actionDatePlus.setDate(actionDatePlus.getDate() + (this.commonService?.config?.promOrderDateMargin ?? 1) + 1);

    const orderDatePlus = this.promOrderInput?.orderDateBegin?.trim() != "" ?
      new Date(this.promOrderInput?.orderDateBegin) :
      undefined;
    orderDatePlus.setDate(orderDatePlus.getDate() + 1);

    if (!orderDatePlus) return actionDatePlus;
    if (actionDatePlus >= orderDatePlus) return actionDatePlus;
    return orderDatePlus;
  }

  getMinOrderDate(): Date {
    const actionDatePlus = new Date();
    actionDatePlus.setDate(actionDatePlus.getDate() + (this.commonService?.config?.promOrderDateMargin ?? 1));

    const orderDatePlus = this.promOrderInput?.orderDateBegin?.trim() != "" ?
      new Date(this.promOrderInput?.orderDateBegin) :
      undefined;
    orderDatePlus.setDate(orderDatePlus.getDate() + 1);

    if (!orderDatePlus) return actionDatePlus;
    if (actionDatePlus >= orderDatePlus) return actionDatePlus;
    return orderDatePlus;
  }
  
  getMaxOrderDate(rcvDate: Date): Date {
    const orderEndDate = this.isDateValid(this.promOrderInput?.orderDateEnd) ?
      this.commonService.copyDate(new Date(this.promOrderInput.orderDateEnd)) :
      null;

    if (!this.isDateValid(rcvDate) && orderEndDate == null) {
      return null;
    }

    if (!this.isDateValid(rcvDate)) {
      return orderEndDate;
    }
    let rcvDateResult: Date = new Date();
    rcvDateResult.setDate(rcvDate.getDate() -1);
    if (!this.isDateValid(orderEndDate)) {
      return rcvDateResult;
    }

    return orderEndDate > rcvDateResult ? rcvDateResult : orderEndDate;
  }

  autoCalcRcvDatePromItem(item: PromOrderItem) {
    this.calcRcvAndOrderDate(item, true)
  }

  handlerChangeOrderNum(item: PromOrderItem) {
    if (!this.promOrderInput?.promCd || !item) return;
    if (item?.formOrderNum?.value?.toString()?.trim() == '') {
      item.formOrderNum.setValue(0, { emitEvent: false });
    };
    this.autoCalcRcvDatePromItem(item);
    this.setEditingStatus();
    return;
  }

  handlerChangeRcvDate(item: PromOrderItem) {
    if (!this.promOrderInput?.promCd || !item) return;
    this.calcRcvAndOrderDate(item);
  }

  handlerChangeOrderDate(item: PromOrderItem) {
    if (!this.promOrderInput?.promCd || !item) return;
    //this.calcRcvAndOrderDate(item);
    this.setEditingStatus();
  }

  calcRcvAndOrderDate(item: PromOrderItem, isChangingOrderNum?: boolean) {
    if (!this.promOrderInput?.promCd || !item) {
      this.setEditingStatus();
      return;
    }

    if (isChangingOrderNum && (item?.formOrderNum?.value < 0 || item?.formOrderNum?.value == '')) {
      //item.formRcvDate.setValue(null, { emitEvent: false });
      // item.orderDate = '';
      //item.formOrderDate.setValue(null);
      this.setEditingStatus();
      item.requestedCalcDateByOrderNum = false;
      return;
    }

    if (this.commonService?.config?.promOrderDateCalcType == undefined) {
      this.setEditingStatus();
      return;
    }
    const hadRcvDateCalc = this.isDateValid(item.formRcvDate?.value)
    if (isChangingOrderNum && (item?.requestedCalcDateByOrderNum || hadRcvDateCalc)) {
      this.setEditingStatus();
      return;
    }

    const itemDto: CalcPromItemDateInputDto = {
      line: 0,
      storeCd: item.storeCd,
      itemCd: item.itemCd,
      rcvDate: this.isDateValid(item.formRcvDate?.value) ?
        this.commonService.formatDate(item.formRcvDate.value) :
        this.isDateValid(item?.rcvDateOrigin) ?
          item.rcvDateOrigin :
          this.commonService.formatDate(this.getDefaultRcvDate()),
      odrLt1Fn: item.odrLt1Fn,
      odrLt2Fn: item.odrLt2Fn,
      odrLt3Fn: item.odrLt3Fn,
      odrLt4Fn: item.odrLt4Fn,
      odrLt5Fn: item.odrLt5Fn,
      odrLt6Fn: item.odrLt6Fn,
      odrLt7Fn: item.odrLt7Fn
    }

    const rs = this.calcLeadTime([itemDto])

    this.receiveCalcRcvAndOrderDate(rs, item)
    if (isChangingOrderNum && this.isDateValid(item.formRcvDate?.value)) item.requestedCalcDateByOrderNum = true;
  }

  receiveCalcRcvAndOrderDate(rs: CalcPromItemDateOutputDto[], item: PromOrderItem) {
    const rsItem = rs?.length > 0 ? rs[0] : undefined;
    if (!rsItem) {
      this.setEditingStatus();
      return;
    }

    const resRcvDateTmp = this.isDateValid(rsItem?.rcvDate) ? new Date(rsItem.rcvDate) : undefined;
    if (!resRcvDateTmp || rsItem.orderDate >= this.promOrderInput.orderDateEnd) {
      item.formOrderDate.setValue(null);
      item.orderDateEditMode = true;
      // check overlap
      const rcvDateCheckER = this.isDateValid(item.formRcvDate?.value) ? this.commonService.formatDate(item.formRcvDate.value) : this.commonService.formatDate(this.getDefaultRcvDate())
      const sizeCheck = this.isDateValid(item.formRcvDate?.value) ? 1 : 0;
      const existItemCDAndRcvDateSizeER = this.promOrderItems.filter(itemCheck => {
        const rcvDateCheck = this.isDateValid(itemCheck?.formRcvDate?.value) ? itemCheck.formRcvDate.value : undefined;
        return item.itemCd == itemCheck.itemCd &&
          rcvDateCheck && this.commonService.formatDate(rcvDateCheck) == rcvDateCheckER
      })?.length ?? 0;
      if (existItemCDAndRcvDateSizeER > sizeCheck) {
        item.formRcvDate.setValue(null, { emitEvent: false })
        this.commonService.openErrorDialog(this.commonService.pageTitle, "同じ商品に、同じ納品日を設定することはできません。");
      }
      else if (!this.isDateValid(item.formRcvDate?.value)) {
        item.formRcvDate.setValue(new Date(this.commonService.formatDate(this.getDefaultRcvDate())), { emitEvent: false })
      }
      this.setEditingStatus();
      return;
    }

    // clear before check and set
    item.formRcvDate.setValue(null, { emitEvent: false });
    item.formOrderDate.setValue(null);
    // item.orderDate = '';

    const existItemCDAndRcvDateSize = this.promOrderItems.filter(itemCheck => {
      const rcvDateCheck = this.isDateValid(itemCheck?.formRcvDate?.value) ? itemCheck.formRcvDate.value : undefined;
      return item.itemCd == itemCheck.itemCd &&
        rcvDateCheck && this.commonService.formatDate(rcvDateCheck) == this.commonService.formatDate(resRcvDateTmp)
    })?.length ?? 0;

    if (existItemCDAndRcvDateSize > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "同じ商品に、同じ納品日を設定することはできません。");
    } else {
      // item.orderDate = this.isDateValid(rsItem?.orderDate) ? this.commonService.formatDate(new Date(rsItem.orderDate)) : '';
      item.formOrderDate.setValue(this.isDateValid(rsItem?.orderDate) ? new Date(rsItem.orderDate) : null);
      item.formRcvDate.setValue(resRcvDateTmp, { emitEvent: false });
    }
    this.setEditingStatus();
  }

  calcRcvAndOrderDateMulti(items: PromOrderItem[], isChangingOrderNum?: boolean) {
    if (!this.promOrderInput?.promCd || !(items?.length > 0)) return;

    const itemListDto: CalcPromItemDateInputDto[] = items.map((item, index) => {
      return {
        line: index,
        storeCd: item.storeCd,
        itemCd: item.itemCd,
        rcvDate: this.isDateValid(item.formRcvDate?.value) ?
          this.commonService.formatDate(item.formRcvDate.value) :
          this.isDateValid(item?.rcvDateOrigin) ?
            item.rcvDateOrigin :
            this.commonService.formatDate(this.getDefaultRcvDate()),
        odrLt1Fn: item.odrLt1Fn,
        odrLt2Fn: item.odrLt2Fn,
        odrLt3Fn: item.odrLt3Fn,
        odrLt4Fn: item.odrLt4Fn,
        odrLt5Fn: item.odrLt5Fn,
        odrLt6Fn: item.odrLt6Fn,
        odrLt7Fn: item.odrLt7Fn
      }
    })

    const rs = this.calcLeadTime(itemListDto)
    this.receiveCalcRcvAndOrderDateMulti(rs, items)
    if (isChangingOrderNum) {
      items.forEach(item => {
        item.requestedCalcDateByOrderNum = true;
      })
    }
  }

  receiveCalcRcvAndOrderDateMulti(rs: CalcPromItemDateOutputDto[], items: PromOrderItem[]) {
    if (!(rs?.length > 0)) return;

    for (const row of rs) {
      if (!items || !items[row?.line]) continue;

      const item = items[row?.line];

      const resRcvDateTmp = this.isDateValid(row?.rcvDate) ? new Date(row.rcvDate) : undefined;
      if (!resRcvDateTmp || row.orderDate >= this.promOrderInput.orderDateEnd) {
        item.formOrderDate.setValue(null);
        item.orderDateEditMode = true;
        // check overlap
        const rcvDateCheckER = this.isDateValid(item.formRcvDate?.value) ? this.commonService.formatDate(item.formRcvDate.value) : this.commonService.formatDate(this.getDefaultRcvDate())
        const sizeCheck = this.isDateValid(item.formRcvDate?.value) ? 1 : 0;
        const existItemCDAndRcvDateSizeER = this.promOrderItems.filter(itemCheck => {
          const rcvDateCheck = this.isDateValid(itemCheck?.formRcvDate?.value) ? itemCheck.formRcvDate.value : undefined;
          return item.itemCd == itemCheck.itemCd &&
            rcvDateCheck && this.commonService.formatDate(rcvDateCheck) == rcvDateCheckER
        })?.length ?? 0;
        if (existItemCDAndRcvDateSizeER > sizeCheck) {
          item.formRcvDate.setValue(null, { emitEvent: false })
          // this.commonService.openErrorDialog(this.commonService.pageTitle, "同じ商品に、同じ納品日を設定することはできません。");
        }
        else if (!this.isDateValid(item.formRcvDate?.value)) {
          item.formRcvDate.setValue(new Date(this.commonService.formatDate(this.getDefaultRcvDate())), { emitEvent: false })
        }
        this.setEditingStatus();
        continue;
      }

      // clear before check and set
      item.formRcvDate.setValue(null, { emitEvent: false });
      item.formOrderDate.setValue(null);
      // item.orderDate = '';

      const overlapRowsWithItemCd = (items.filter(itemCheck => {
        return item.itemCd == itemCheck.itemCd
      })?.length ?? 0) > 1;

      if (overlapRowsWithItemCd) {
        continue;
      }

      // items[row.line].orderDate = this.isDateValid(row?.orderDate) ? this.commonService.formatDate(new Date(row.orderDate)) : '';
      items[row.line].formOrderDate.setValue(this.isDateValid(row?.orderDate) ? new Date(row.orderDate) : null);
      items[row.line].formRcvDate.setValue(resRcvDateTmp, { emitEvent: false })
    }
    this.setEditingStatus();
  }

  isChangedOrderNum(item: PromOrderItem) {
    const orderNumOrigin = item?.orderBaraNum ?? 0;
    const orderNumEdit = isNaN(item.formOrderNum.value) ? 0 : item.formOrderNum.value;
    return orderNumOrigin != orderNumEdit;
  }

  isChangedRcvDate(item: PromOrderItem) {
    const rcvDateOrigin = this.isDateValid(item?.rcvDateOrigin) ?
      this.commonService.formatDate(new Date(item.rcvDate)) :
      undefined;
    const rcvDateEdit = this.isDateValid(item?.formRcvDate?.value) ?
      this.commonService.formatDate(new Date(item.formRcvDate.value)) :
      undefined;
    return rcvDateOrigin != rcvDateEdit;
  }

  isChangedOrderDate(item: PromOrderItem) {
    const orderDateOrigin = this.isDateValid(item?.orderDateOrigin) ?
      this.commonService.formatDate(new Date(item.orderDateOrigin)) :
      undefined;
    const orderDateEdit = this.isDateValid(item?.formOrderDate?.value) ?
      this.commonService.formatDate(new Date(item.formOrderDate.value)) :
      undefined;
    return orderDateOrigin != orderDateEdit;
  }

  subscribeFormOrderNumAndRcvDate() {
    this.unsubscribeFormOrderNumAndRcvDate();
    this.orderNumSubscriptions = new Subscription();
    for (const item of this.promOrderItems) {
      if (item?.formOrderNum) {
        const subs = item.formOrderNum.valueChanges
          .pipe(
            debounceTime(250),
            startWith('-1'),
            pairwise()
          )
          .subscribe(([prev, next]) => {
            let oldNum = prev;
            if (prev != '-1') {
              oldNum = BigInt(prev?.toString().replace(/[^0-9]/g, '')).toString()
              if (oldNum == '') oldNum = '0'
            }
            let newNum = BigInt(next?.toString().replace(/[^0-9]/g, '')).toString()
            if (newNum == '') newNum = '0'
            if (oldNum != newNum)
              this.handlerChangeOrderNum(item);

          })
        this.orderNumSubscriptions.add(subs);
      }
      if (item?.formRcvDate) {
        const subs = item.formRcvDate.valueChanges.subscribe(() => {
          this.handlerChangeRcvDate(item);
        })
        this.orderNumSubscriptions.add(subs);
      }
      if (item?.formOrderDate) {
        const subs = item.formOrderDate.valueChanges.subscribe(() => {
          this.handlerChangeOrderDate(item);
        })
        this.orderNumSubscriptions.add(subs);
      }
    }
  }

  unsubscribeFormOrderNumAndRcvDate() {
    if (this.orderNumSubscriptions) {
      this.orderNumSubscriptions.unsubscribe();
      this.orderNumSubscriptions = undefined;
    }
  }

  doCancelEdit() {
    this.formPromOrderType.setValue(this.promOrderTypeOrigin);
    this.unsubscribeFormOrderNumAndRcvDate();
    this.promOrderItems = this.promOrderItemsOrigin?.length > 0 ? [...this.promOrderItemsOrigin].map(rec => new PromOrderItem(rec?.originDto)) : [];
    this.recordCount = this.recordCountResOrigin;
    if (this.matPaginator && this.recordCountResOrigin <= this.matPaginator.pageSize * this.matPaginator.pageIndex) {
      this.matPaginator.pageIndex = 0;
    }
    this.selectedPromOrderItem = undefined;
    this.pageChanged();
    this.setEditingStatus();
    this.subscribeFormOrderNumAndRcvDate();
  }

  handlerChangeCtgSelection(val: string[]) {
    this.selectedCtg = [];
    this.selectedCtg = [...val]
  }

  handlerChangePromOrderType() {
    this.setEditingStatus();
  }

  getPromOrderChangeList(): ReqPromOrderItemUpdateDto[] {
    let changeList: ReqPromOrderItemUpdateDto[] = []
    changeList = this.promOrderItems.filter(item => {
      return item?.isNewFlag ||
        this.isChangedRcvDate(item) ||
        this.isChangedOrderNum(item) ||
        this.isChangedOrderDate(item)
    })
      .map(item => this.genNewReqPromOrderItemUpdateDto(item))
    return changeList;
  }

  setEditingStatus() {
    let promOrderChangeList = this.getPromOrderChangeList();
    for ( let item of promOrderChangeList ){
      if (item.orderBaraNum != 0 && (item.orderDate == '' || item.rcvDate == '' )){  
        this.promOrderInput.editStatus = 3;
        return;
      }
    }
    this.promOrderInput.editStatus = (promOrderChangeList?.length > 0 || this.recordCount != this.recordCountResOrigin || this.promOrderTypeOrigin !== this.formPromOrderType?.value) ?
      2 :
      1;
  }

  genPromOrderItemKeyMap(item: PromOrderItem): string {
    const key = `${item.itemCd}_${item.rcvDateOrigin}_${item.orderDateOrigin}_${item?.isNewFlag ? 'new' : 'origin'}_${item?.isNewFlag && item?.newIndex > -1 ? item.newIndex : ''}`;
    return key;
  }

  insertNewPromOrderItem() {
    if (!this.selectedPromOrderItem || this.promOrderItems?.length <= 0) return;
    const selectedItemKey = this.genPromOrderItemKeyMap(this.selectedPromOrderItem);

    const listItemOverlap = this.promOrderItems.filter(item => this.selectedPromOrderItem.itemCd == item?.itemCd &&
      this.selectedPromOrderItem?.rcvDateOrigin == item?.rcvDateOrigin &&
      this.selectedPromOrderItem?.orderBaraNumOrigin == item?.orderBaraNumOrigin &&
      this.selectedPromOrderItem?.orderDateOrigin == item?.orderDateOrigin
    ).sort((a, b) => b?.newIndex - a?.newIndex)

    if (listItemOverlap?.length <= 0) return;

    const isNewFlag = true;
    const newIndex = listItemOverlap[0].newIndex + 1;

    for (let index = 0; index < this.promOrderItems.length; index++) {
      const itemKey = this.genPromOrderItemKeyMap(this.promOrderItems[index]);
      if (selectedItemKey === itemKey) {
        const dto = this.selectedPromOrderItem.originDto;
        const newItem = new PromOrderItem(dto, isNewFlag, newIndex);
        newItem.formOrderNum.setValue(0);
        newItem.orderDate = '';
        newItem.orderDateOrigin = '';
        newItem.rcvDateOrigin = '';
        newItem.requestedCalcDateByOrderNum = false;
        newItem.formRcvDate.setValue(null, { emitEvent: false });
        newItem.formOrderDate.setValue(null);
        this.promOrderItems.splice(index + 1, 0, newItem);

        this.pageChanged();
        this.setEditingStatus();
        return;
      }
    }
    this.pageChanged();
    this.setEditingStatus();
    return;
  }

  doUpdate() {
    this.selectedPromOrderItem = undefined;    
    this.orderStopFlagList = this.commonService.config?.orderStopFlag?.filter(e => e.canOrder == true);
    let request: ReqPromOrderItemUpdate = {
      access: this.commonService.loginUser,
      promOrderItemSearch: this.genReqPromOrderItemSearchDto(),
      promOderItemList: this.getPromOrderChangeList() ?? [],
      promOrderType: this.formPromOrderType?.value ?? '',
      oldPromOrderType: this.promOrderTypeOrigin,
      orderStopFlagList: this.orderStopFlagList
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("PromOrderItemUpdate", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePromOrderItemUpdate(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receivePromOrderItemUpdate(response: RspPromOrderItemSearch) {
    this.httpBasic.handleAppError(response)

    this.selectedPromOrderItem = undefined;
    this.promOrderItems = [];
    this.promOrderItemsOrigin = [];
    this.promOrderTypes = [];

    response?.result.forEach((row) => {
      this.promOrderItems.push(new PromOrderItem(row));
    }
    );
    this.promOrderItemsOrigin = this.promOrderItems?.length > 0 ? [...this.promOrderItems].map(rec => new PromOrderItem(rec?.originDto)) : [];
    this.recordCount = this.promOrderItems?.length ?? 0;
    this.recordCountResOrigin = this.recordCount;
    if (this.matPaginator && this.recordCountResOrigin <= this.matPaginator.pageSize * this.matPaginator.pageIndex) {
      this.matPaginator.pageIndex = 0;
    }

    response?.orderTypes.forEach(orderType => {
      this.promOrderTypes.push(orderType);
    })

    this.promOrderTypeOrigin = response?.orderTypeFv ?? '';
    this.formPromOrderType.setValue(this.promOrderTypeOrigin);
    this.pageChanged();
    this.setEditingStatus();
  }

  genNewReqPromOrderItemUpdateDto(item: PromOrderItem) {
    const dto: ReqPromOrderItemUpdateDto = {
      promCd: this.promOrderInput?.promCd,
      storeCd: item.storeCd,
      itemCd: item.itemCd,
      orderBaraNum: item.formOrderNum?.value ?? 0,
      rcvDate: this.isDateValid(item.formRcvDate?.value) ? this.commonService.formatDate(item.formRcvDate.value) : '',
      orderDate: this.isDateValid(item.formOrderDate?.value) ? this.commonService.formatDate(item.formOrderDate.value) : '',
      // orderDate: item.orderDate,
      isNewFlag: item.isNewFlag,

      oldRcvDate: item.rcvDateOrigin,
      oldOrderDate: item.orderDateOrigin,
      oldOrderBaraNum: item.orderBaraNumOrigin
    }
    return dto;
  }

  onInputOrderNum(formControl: FormControl, event: any) {
    let value = (BigInt(event.target.value.replace(/[^0-9]/g, '')).toString());
    if (value == '') value = '0'
    formControl.setValue(value);
  }

  isDateValid(input: string | Date): boolean {
    if (!input) return false;
    if (typeof input == 'string') {
      if (input.trim() == '') return false;
      const date = new Date(input);
      return (date instanceof Date && !isNaN(date.valueOf()));
    }

    return (input instanceof Date && !isNaN(input.valueOf()));
  }

  // begin replace sql functions
  execGetMinOrderLT(input: CalcPromItemDateInputDto): CalcPromItemDateOutputDto {
    const initRes: CalcPromItemDateOutputDto = {
      line: input?.line ?? -1,
      storeCd: input?.storeCd ?? '',
      itemCd: input?.itemCd ?? '',
      rcvDate: '',
      orderDate: ''
    }

    if (!input || !this.isDateValid(input.rcvDate)) return initRes;

    const pdRcvDate: Date = this.commonService.copyDate(new Date(input.rcvDate));

    let ldCalcTrgtDate: Date = this.commonService.copyDate(new Date());
    ldCalcTrgtDate.setDate(ldCalcTrgtDate.getDate() + (this.commonService?.config?.promOrderDateMargin ?? 1));
    ldCalcTrgtDate.setDate(ldCalcTrgtDate.getDate() + 1);
    let maxLoopDate: Date = this.commonService.copyDate(pdRcvDate);
    maxLoopDate.setDate(maxLoopDate.getDate() + 7);
    let minOrderDate: Date;
    let minRcvDate: Date;

    while (ldCalcTrgtDate < maxLoopDate) {
      const dayOfWeek = ldCalcTrgtDate.getDay();
      if (input[`odrLt${dayOfWeek + 1}Fn`] == undefined) continue;
      const lnSuppLT = input[`odrLt${dayOfWeek + 1}Fn`];
      if (lnSuppLT > 0) {
        minOrderDate = this.commonService.copyDate(ldCalcTrgtDate);
        minRcvDate = this.commonService.copyDate(ldCalcTrgtDate);
        minRcvDate.setDate(minRcvDate.getDate() + lnSuppLT);
        break;
      }
      ldCalcTrgtDate.setDate(ldCalcTrgtDate.getDate() + 1);
    }
    const res = { ...initRes }
    res.orderDate = this.isDateValid(minOrderDate) ? this.commonService.formatDate(minOrderDate) : '';
    res.rcvDate = this.isDateValid(minRcvDate) ? this.commonService.formatDate(minRcvDate) : '';
    return res;
  }

  execGetMaxOrderLT(input: CalcPromItemDateInputDto): CalcPromItemDateOutputDto {
    const initRes: CalcPromItemDateOutputDto = {
      line: input?.line ?? -1,
      storeCd: input?.storeCd ?? '',
      itemCd: input?.itemCd ?? '',
      rcvDate: '',
      orderDate: ''
    }

    if (!input || !this.isDateValid(input.rcvDate)) return initRes;

    const pdRcvDate: Date = this.commonService.copyDate(new Date(input.rcvDate));

    let pdCalcTrgtDate: Date = this.commonService.copyDate(new Date());
    pdCalcTrgtDate.setDate(pdCalcTrgtDate.getDate() + (this.commonService?.config?.promOrderDateMargin ?? 1));
    let ldCalcTrgtDate: Date = this.commonService.copyDate(pdRcvDate);
    ldCalcTrgtDate.setDate(ldCalcTrgtDate.getDate() - 1);
    let minDiffDate: number = 999;
    let minOrderDate: Date;
    let minRcvDate: Date;

    while (ldCalcTrgtDate > pdCalcTrgtDate) {
      const dayOfWeek = ldCalcTrgtDate.getDay();
      if (input[`odrLt${dayOfWeek + 1}Fn`] == undefined) continue;
      const lnSuppLT = input[`odrLt${dayOfWeek + 1}Fn`];
      const tmpDate = this.commonService.copyDate(ldCalcTrgtDate);
      tmpDate.setDate(tmpDate.getDate() + lnSuppLT)
      let lnDateDiff = Math.ceil((this.commonService.copyDate(pdRcvDate).getTime() - tmpDate.getTime()) / (1000 * 60 * 60 * 24));

      if (lnSuppLT > 0 &&
        lnDateDiff >= 0 &&
        lnDateDiff < Math.ceil((this.commonService.copyDate(pdRcvDate).getTime() - this.commonService.copyDate(ldCalcTrgtDate).getTime()) / (1000 * 60 * 60 * 24)) &&
        lnDateDiff < minDiffDate
      ) {
        minDiffDate = lnDateDiff;
        minOrderDate = this.commonService.copyDate(ldCalcTrgtDate);
        minRcvDate = this.commonService.copyDate(ldCalcTrgtDate);
        minRcvDate.setDate(minRcvDate.getDate() + lnSuppLT);
      }

      ldCalcTrgtDate.setDate(ldCalcTrgtDate.getDate() - 1);
    }
    const res = { ...initRes };
    res.orderDate = this.isDateValid(minOrderDate) ? this.commonService.formatDate(minOrderDate) : '';
    res.rcvDate = this.isDateValid(minRcvDate) ? this.commonService.formatDate(minRcvDate) : '';
    return res;
  }

  calcLeadTime(input: CalcPromItemDateInputDto[]): CalcPromItemDateOutputDto[] {
    if (input?.length <= 0) {
      const output = input.map(iValue => {
        const oValue: CalcPromItemDateOutputDto = {
          line: iValue?.line ?? -1,
          storeCd: iValue?.storeCd ?? '',
          itemCd: iValue?.itemCd ?? '',
          rcvDate: '',
          orderDate: ''
        }
        return oValue;
      });
      return output;
    }

    let output: CalcPromItemDateOutputDto[] = input.map(iValue => {
      const maxExec = this.execGetMaxOrderLT(iValue);
      const minExec = this.execGetMinOrderLT(iValue);
      const oValue: CalcPromItemDateOutputDto = {
        line: iValue?.line ?? -1,
        storeCd: iValue?.storeCd ?? '',
        itemCd: iValue?.itemCd ?? '',
        rcvDate: this.isDateValid(maxExec.orderDate) ? maxExec.rcvDate : minExec.rcvDate,
        orderDate: this.isDateValid(maxExec.orderDate) ? maxExec.orderDate : minExec.orderDate
      }
      return oValue;
    })

    return output;
  }
  // end replace sql functions

}
