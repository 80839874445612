import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CtgSelectEobCondition } from '../ctg-eob-select/ctg-eob-select.component';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { OrderList, OrderListDto, ReqOrderListSearch, ReqOrderListUpdate, RspOrderListSearch, RspOrderListUpdate } from '../0_def/order-list-rec';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  public recordCount: number = 0;
  public orderList: OrderList[];
  public orderListDisplay = new MatTableDataSource<OrderListDto>([]);;
  public orderListOrigin: OrderList[];
  public formGroupCondition: FormGroup;
  public ctgSelectEobCondition: CtgSelectEobCondition = new CtgSelectEobCondition(this.fb);
  public selectedRow: OrderList;

  public columnIds: string[] = ["itemCd", "mkrFv", "itemName", "stdFv", "minZaiFn", "stkNumFn", "suppLotFn", "orderSuppLotFn", "orderBara", "orderSuppLotNextDayFn", "rcvDateFvDis", "binCdFv", "costPriceFn", "sellPriceFn", "timeFv", "suppCd", "suppName"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "itemCd", header: "商品コード", width: 146, align: "center" },
    { columnId: "mkrFv", header: "メーカー", width: 105, align: "left" },
    { columnId: "itemName", header: "商品名", width: 344, align: "left" },
    { columnId: "stdFv", header: "規格", width: 140, align: "left" },
    { columnId: "minZaiFn", header: "最低陳列", width: 58, align: "right" },
    { columnId: "stkNumFn", header: "在庫数", width: 58, align: "right" },
    { columnId: "suppLotFn", header: "ロット数", width: 73, align: "right" },
    { columnId: "orderSuppLotFn", header: "推奨値(ロット数)", width: 102, align: "right" },
    { columnId: "orderBara", header: "推奨値(バラ数)", width: 102, align: "right" },
    { columnId: "orderSuppLotNextDayFn", header: "翌日入荷予定", width: 102, align: "right" },
    { columnId: "rcvDateFvDis", header: "納品日", width: 102, align: "center" },
    { columnId: "binCdFv", header: "便No", width: 58, align: "right" },
    { columnId: "costPriceFn", header: "原価", width: 73, align: "right" },
    { columnId: "sellPriceFn", header: "売価", width: 73, align: "right" },
    { columnId: "timeFv", header: "発注〆時間", width: 73, align: "right" },
    { columnId: "suppCd", header: "仕入先コー", width: 73, align: "center" },
    { columnId: "suppName", header: "仕入先名", width: 265, align: "left" }
  ];

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();

  constructor(
    private httpBasic: HttpBasicService,
    private fb: FormBuilder,
    public commonService: CommonService,
    private _liveAnnouncer: LiveAnnouncer
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.formGroupCondition = this.fb.group({
      itemCd: new FormControl(""),
      itemName: new FormControl(""),
      makerName: new FormControl(""),
      orderNumSort: new FormControl("0"),
      itemCdSort: new FormControl("1"),
    })
    this.ctgSelectEobCondition.storeCd = this.commonService.loginUser.storeCd;
    this.doSearchBody();
  }

  ngAfterViewInit() {
    this.empTbSort.disableClear = true;
    this.orderListDisplay.sort = this.empTbSort;
  }

  doSearch() {
    if (!this.isDisable()) {
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
        "変更内容を破棄しますか？").subscribe(
          (response: boolean) => {
            subsc.unsubscribe();
            if (response) {
              this.doSearchBody();
            }
          });
    } else this.doSearchBody();
  }

  doSearchBody() {
    let ctgSelectEobCondition: FormGroup = this.ctgSelectEobCondition.formGroup;
    let request: ReqOrderListSearch = {
      access: this.commonService.loginUser,
      storeCd: this.commonService.loginUser.storeCd,
      itemCd: this.formGroupCondition.get("itemCd").value ?? "",
      itemName: this.formGroupCondition.get("itemName").value,
      makerName: this.formGroupCondition.get("makerName").value,
      orderNumSort: this.formGroupCondition.get("orderNumSort").value,
      itemCdSort: this.formGroupCondition.get("itemCdSort").value,
      ctgCd0Fv: ctgSelectEobCondition.get('ctgLevel').value >= 0 ?
        ctgSelectEobCondition.get('ctgCd0').value : "",
      ctgCd1Fv: ctgSelectEobCondition.get('ctgLevel').value >= 1 ?
        ctgSelectEobCondition.get('ctgCd1').value : "",
      ctgCd2Fv: ctgSelectEobCondition.get('ctgLevel').value >= 2 ?
        ctgSelectEobCondition.get('ctgCd2').value : "",
      ctgCd3Fv: ctgSelectEobCondition.get('ctgLevel').value >= 3 ?
        ctgSelectEobCondition.get('ctgCd3').value : "",
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderList", request).subscribe(
      (response: RspOrderListSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveOrderList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveOrderList(response: RspOrderListSearch) {
    if (this.httpBasic.handleAppError(response)) { return; }
    this.orderList = [];
    response.rows.forEach(row => {
      let dto: OrderListDto = {
        ...row,
        rcvDateFvDis: this.formatDateShort(new Date(row.rcvDateFv))
      }
      this.orderList.push(new OrderList(dto));
    });
    this.orderListOrigin = this.orderList?.length > 0 ? [...this.orderList].map(rec => new OrderList(rec?.originDto)) : [];
    this.recordCount = this.orderList?.length ?? 0;
    this.pageChanged();
  }

  doSave() {
    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
      "内容を保存します。よろしいですか？").subscribe(
        (response: boolean) => {
          subsc.unsubscribe();
          if (response) {
            this.doSaveBody("1", "登録中。。。");
          }
        });
  }

  doSaveBody(mode: string, msg: string) {
    let itemUpdate = [];
    this.orderList?.forEach(item => {
      if (item.orderSuppLotForm.value != item.orderSuppLotFn) {
        let dto = {
          userId: this.commonService.loginUser.userId,
          storeCd: this.commonService.loginUser.storeCd,
          itemCd: item.itemCd,
          rcvNum: item.orderSuppLotForm.value,
          oldNum: item.orderSuppLotFn,
          rcvDate: item.rcvDateFv
        }
        itemUpdate.push(dto);
      }
    });
    let ctgSelectEobCondition: FormGroup = this.ctgSelectEobCondition.formGroup;
    let request: ReqOrderListUpdate = {
      access: this.commonService.loginUser,
      itemUpdate: itemUpdate,
      itemCd: this.formGroupCondition.get("itemCd").value ?? "",
      itemName: this.formGroupCondition.get("itemName").value,
      makerName: this.formGroupCondition.get("makerName").value,
      orderNumSort: this.formGroupCondition.get("orderNumSort").value,
      itemCdSort: this.formGroupCondition.get("itemCdSort").value,
      ctgCd0Fv: ctgSelectEobCondition.get('ctgLevel').value >= 0 ?
        ctgSelectEobCondition.get('ctgCd0').value : "",
      ctgCd1Fv: ctgSelectEobCondition.get('ctgLevel').value >= 1 ?
        ctgSelectEobCondition.get('ctgCd1').value : "",
      ctgCd2Fv: ctgSelectEobCondition.get('ctgLevel').value >= 2 ?
        ctgSelectEobCondition.get('ctgCd2').value : "",
      ctgCd3Fv: ctgSelectEobCondition.get('ctgLevel').value >= 3 ?
        ctgSelectEobCondition.get('ctgCd3').value : "",
      mode: mode,
    }
    this.commonService.openSpinner(this.commonService.pageTitle, msg);
    let subsc = this.httpBasic.generalRequest("OrderListSave", request).subscribe(
      (response: RspOrderListUpdate) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveSave(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  doCheck() {
    let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
      "他の端末により推奨値更新がございますが、更新した内容を保存します。よろしいですか").subscribe(
        (response: boolean) => {
          subsc.unsubscribe();
          if (response) {
            this.doSaveBody("2", "登録中。。。");
          }
        });
  }

  receiveSave(response: RspOrderListUpdate) {
    if (this.httpBasic.handleAppError(response)) { return; }
    if (response.isCheck) return this.doCheck();
    this.commonService.openNotificationDialog(this.commonService.pageTitle, '保存しました。');
    this.orderList = [];
    response.rows.forEach(row => {
      let dto: OrderListDto = {
        ...row,
        rcvDateFvDis: this.formatDateShort(new Date(row.rcvDateFv))
      }
      this.orderList.push(new OrderList(dto));
    });
    this.orderListOrigin = this.orderList?.length > 0 ? [...this.orderList].map(rec => new OrderList(rec?.originDto)) : [];
    this.recordCount = this.orderList?.length ?? 0;
    this.pageChanged();
  }

  pageChanged() {
    let start;
    let end;
    this.orderListDisplay.data = [];
    this.recordCount = this.orderList?.length ?? 0;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.recordCount) end = this.recordCount;
    if (this.orderListDisplay?.data.length == 1) return;
    else this.orderListDisplay.data = this.orderList?.slice(start, end);
  }

  onInputNum(item: OrderList, event) {
    const parsedValue = parseInt(event.target.value);
    if (isNaN(parsedValue)) {
      item.orderSuppLotForm.setValue('');
      item.orderBara = 0;
      return;
    }

    item.orderSuppLotForm.setValue(event.target.value);
    item.orderBara = parsedValue * parseInt(item.suppLotFn);
  }

  isDisable(): boolean {
    let item = 0;
    this.orderList?.filter(x => {
      if (x["orderSuppLotForm"].value != x.orderSuppLotFn) item++;
    })
    if (item > 0) return false;
    return true;
  }

  doCancel() {
    this.orderList = [];
    this.orderList = this.orderListOrigin?.length > 0 ? [...this.orderListOrigin].map(rec => new OrderList(rec?.originDto)) : [];
    this.pageChanged();
  }

  checkStkNum(value): boolean {
    if (parseInt(value) < 0) return true;
    return false;
  }

  clickRow(row: OrderList) {
    this.selectedRow = row;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  styleForRow(colDef: TableColumnDef, item: OrderList) {
    let stkNumValue = isNaN(parseInt(item.stkNumFn)) ? 0 : parseInt(item.stkNumFn);
    let initStyle = {
      "width": "" + colDef.width + "px",
      "min-width": "" + colDef.width + "px",
      "max-width": "" + (colDef.maxWidth != undefined ? colDef.maxWidth : colDef.width) + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
    if (colDef.columnId === "stkNumFn" && stkNumValue < 0) {
      return {
        ...initStyle,
        "color": "red"
      }
    }
    else {
      return initStyle;
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    };
  }

  onEnter() {
    this.doSearch();
  }

  formatDateShort(date: Date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${('0' + month).slice(-2)}/${('0' + day).slice(-2)}`;
  }

  setOrderNumSort(priv: string) {
    switch (priv) {
      case "降順":
        this.formGroupCondition.get("orderNumSort").setValue("0");
        break;
      case "昇順":
        this.formGroupCondition.get("orderNumSort").setValue("1");
        break;
    }
  }

  setItemCdSort(priv: string) {
    switch (priv) {
      case "降順":
        this.formGroupCondition.get("itemCdSort").setValue("0");
        break;
      case "昇順":
        this.formGroupCondition.get("itemCdSort").setValue("1");
        break;
    }
  }

  canDeactivate() {
    if (this.isDisable()) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
