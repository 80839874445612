import { SafeResourceUrl } from '@angular/platform-browser';
import { ExternalLinkDto } from '../response/rsp-get-external-link';

export class ExternalLink {
	linkCdFv:		  string;
	linkTypeIdFv:	string;
	storeCdFv:		string;
  storeNameFv:	string;
  storeFv:      string;
	linkNameFv:		string;
	urlFv:		    string;
  commentFv:		string;
  sizeXFn:      number;
  sizeYFn:      number;
  safeUrl:      SafeResourceUrl;
  imageUrl?:    any;
  isOpened?:    boolean;
  isLoading?:   boolean;
  timerId?:     any;
  errorMsg?:    string;

  constructor(private externalLink: ExternalLinkDto) {
    for (var key of Object.keys(externalLink)) {
      this[key] = externalLink[key];
    }
    this.storeFv = this.storeCdFv + ":" + this.storeNameFv;

    if (!this.sizeXFn || this.sizeXFn == 0) this.sizeXFn = 800;
    if (!this.sizeYFn || this.sizeYFn == 0) this.sizeYFn = 400;
  }
}