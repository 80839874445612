import { Rsp } from './rsp';

export class DlListDto  {
    id: number;
    storeCd: string;
    storeName: string;
    title: string;
    folder: string;
    filename: string;
    expirationDate: string;
}

export interface RspDlList extends Rsp {
    dlList: DlListDto[];
}
