import { Subject } from "rxjs";

export abstract class LabelPrinter {
    abstract getPrinterType(): string;
    abstract connect(ipAddr: string): Subject<any>;
    abstract connectAsync(ipAddr?: string, isTest?: boolean): Promise<boolean>;
    abstract disconnect(): void;
    abstract write(printData: any): void;
    abstract isConnected(): boolean;
    abstract getSubject(): Subject<any>;
}

export interface LabelPrinterConfig {
    printer:            LabelPrinter;
    ipAddr?:            string;
    // printCount:         number;
    labelLayoutKey:     string;
    labelLayoutName:    string;
}

export interface PrinterLayout {
    id:         number;
    model:      string;
    layoutCd:   string;
    name:       string;
    image:      string;
}