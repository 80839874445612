import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { LossRateCtgUpdateDto, ReqLossRateCtgUpdate } from 'src/app/request/req-loss-rate-ctg-update';
import { ReqLossRateSearch } from 'src/app/request/req-loss-rate-search';
import { RspLossRateUpdate } from 'src/app/response/rsp-loss-rate-ctg-update';
import { LossRateSearchDto, RsqLossRateSearch } from 'src/app/response/rsp-loss-rate-search';
import { CommonService } from 'src/app/service/common.service';
import { NumberKeypadComponent } from '../../partsCommon/number-keypad/number-keypad.component';
import { HttpBasicService } from '../../service/http-basic.service';
import { LossRateRec } from './loss-rate-rec';


@Component({
  selector: 'app-loss-rate',
  templateUrl: './loss-rate.component.html',
  styleUrls: ['./loss-rate.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LossRateComponent implements OnInit, OnDestroy {

  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public rankForm: FormControl = new FormControl(this.commonService.config.ranks[0] || "");
  public lossRateForm: FormControl = new FormControl("");
  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;

  public lossRateList: LossRateRec[] = [];
  public displayColumn: string[] = ["store", "lossRate", "lossAmount"];

  public ctg0Selected: string;
  public ctg1Selected: string;
  public ctg2Selected: string;
  public ctg3Selected: string;
  public rankSelected: string;
  public ctgCd0Fv: string;
  public ctgCd1Fv: string;
  public ctgCd2Fv: string;
  public ctgCd3Fv: string;
  public rankFv: string;
  public ctgLevel: string;

  public isDirty: boolean = false;
  public errorMessage: string = "";
  public itemRec: LossRateRec;
  private subscRateForms: Subscription[] = [];


  @ViewChild(CtgSelectComponent, { static: true }) public ctgSelectComponent: CtgSelectComponent;
  @ViewChild(NumberKeypadComponent, { static: false }) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.ctgSelectCondition.storeCd = "";
  }

  ngOnDestroy() {
    this.clearSubsc();
  }

  clearSubsc() {
    this.subscRateForms.forEach((subsc) => { subsc.unsubscribe(); });
    this.subscRateForms = [];
  }

  isDisableSearch() {
    return this.ctgSelectCondition.formGroup.get("ctgCd0").value === "" ? true : false;
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  doQuery() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.doQueryBody();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.doQueryBody();
        }
      }
    );
  }

  doQueryBody() {
    this.ctg0Selected = this.ctgSelectCondition.ctg0Name;
    this.ctg1Selected = this.ctgSelectCondition.ctg1Name;
    this.ctg2Selected = this.ctgSelectCondition.ctg2Name;
    this.ctg3Selected = this.ctgSelectCondition.ctg3Name;
    this.rankSelected = this.rankForm.value;
    this.ctgCd0Fv = this.ctgSelectCondition.formGroup.controls.ctgCd0.value,
    this.ctgCd1Fv = this.ctgSelectCondition.formGroup.controls.ctgCd1.value,
    this.ctgCd2Fv = this.ctgSelectCondition.formGroup.controls.ctgCd2.value,
    this.ctgCd3Fv = this.ctgSelectCondition.formGroup.controls.ctgCd3.value,
    this.ctgLevel = this.ctgSelectCondition.formGroup.controls.ctgLevel.value,
    this.rankFv = this.rankForm.value,

    this.clearSubsc();
    this.lossRateList = [];
    this.commonService.stores.forEach((store) => {
      let item: LossRateRec = new LossRateRec(this.commonService);
      item.storeCd = store.storeCd;
      item.storeName = store.storeName;
      item.ctgLevel =  this.ctgSelectCondition.formGroup.controls.ctgLevel.value;
      item.ctgName[0] = this.ctg0Selected;
      item.ctgName[1] = this.ctg1Selected;
      item.ctgName[2] = this.ctg2Selected;
      item.ctgName[3] = this.ctg3Selected;
      item.initForm();
      let subsc = item.lossRateForm.valueChanges.subscribe((value) => { this.changeListener(value, item); });
      this.subscRateForms.push(subsc);
      this.lossRateList.push(item);
    });
    this.clearErrorMessage();
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    const request: ReqLossRateSearch = {
      access: this.commonService.loginUser,
      ctgCd0Fv: this.ctgSelectCondition.formGroup.controls.ctgCd0.value,
      ctgCd1Fv: this.ctgSelectCondition.formGroup.controls.ctgCd1.value,
      ctgCd2Fv: this.ctgSelectCondition.formGroup.controls.ctgCd2.value,
      ctgCd3Fv: this.ctgSelectCondition.formGroup.controls.ctgCd3.value,
      ctgLevelFn: this.ctgSelectCondition.formGroup.controls.ctgLevel.value,
      rankFv: this.rankForm.value,
      storeCdFv: '*',
      averageDiscountRate: this.commonService.config.averageDiscountRate
    }

    let subsc = this.httpBasic.getLossRate(request).subscribe(
      (response: RsqLossRateSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) return;
        if(response.result && response.result.length > 0) this.receiveLossRate(response.result);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )


  }

  receiveLossRate(lossRateResponse: LossRateSearchDto[]) {
    // let a = this.commonService.config.lossRateUpperLimit; 
    let averageDiscount = this.commonService.config.averageDiscountRate;
    
    for(const row of lossRateResponse) {
      const lossRate = this.lossRateList.find(item => item.storeCd == row.storeCd);
 
      lossRate.sumAmount = row.amountSalesPrice;
      lossRate.lossAmount = row.targetSalesPrice;
      
      lossRate.ctgCd = row.ctgCd;
      lossRate.ctgLevel = row.ctgLevel;
      lossRate.lossRateForm.reset(lossRate.lossRate , {emitEvent: false});
      if(row.lossRate < 0) {
        lossRate.lossRate = -1;      
        lossRate.lossAmountSubject.next(row.targetSalesPrice);
        
      } else {

        let lossAmount = Math.round(row.amountSalesPrice * row.lossRate * averageDiscount * (1+row.lossRate) / (averageDiscount - row.lossRate + averageDiscount * row.lossRate));
        lossRate.lossAmountSubject.next(lossAmount);
        lossRate.lossRate = row.lossRate * 100;   
        
      }
      lossRate.lossRateForm.setValue(lossRate.lossRate < 0 ? "" :  lossRate.lossRate, {emitEvent: false});
      this.cdr.detectChanges();
    }
  }

  doCancel() {
    this.lossRateList.forEach(store => { store.clear(); });
    this.isDirty = false;
  }

  setErrorMessage() {
    this.errorMessage = "入力された値が不正です。";
  }

  clearErrorMessage() {
    this.errorMessage = "";
  }

  is10KeyPadTarget(form: FormControl) {
    if (form == this.numberKeypadComponent?.inputField) return true;
    return false;
  }

  set10KeyPadTargetAll(form: FormControl) {
    if (this.itemRec) {
      this.itemRec.doCalcLossAmount();
    }
    this.clearErrorMessage();
    // this.numberKeypadComponent.inputField = form;
    this.numberKeypadComponent.setTargetForm(form);
    if (form == this.lossRateForm) return;

    let val = form.value;
    if (val === "") return;
    if (!this.isValid(val)) this.setErrorMessage();
  }

  set10KeyPadTarget(item: LossRateRec) {
    if (this.itemRec) {
      this.itemRec.doCalcLossAmount();
    }

    this.clearErrorMessage();
    this.itemRec = item;
    // this.numberKeypadComponent.inputField = item.lossRateForm;
    this.numberKeypadComponent.setTargetForm(item.lossRateForm);
    if (item.lossRateForm == this.lossRateForm) return;

    let val = item.lossRateForm.value;
    if (val === "") return;
    if (!this.isValid(val)) this.setErrorMessage();
  }

  clearKeypadTarget() {
    this.numberKeypadComponent.inputField = undefined;
    this.clearErrorMessage();
  }

  isKeyboardLocked() {
    if (this.numberKeypadComponent && this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  isDisableSetAllButton() {        
    if (this.lossRateForm.value === "") return true;
    return !this.isValid(this.lossRateForm.value);
  }

  doSetAll() {
    if (this.commonService.config.allStoreCopyConfirm == true) {
      this.clearKeypadTarget();
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle, "全店に適用します。").subscribe(
        (data) => {
          subsc.unsubscribe();
          if (data == false) return;
          if (data) {
            this.lossRateList.forEach(store => { store.editLossRate(this.lossRateForm.value); });
          }
        }
      );
    } else {
      this.lossRateList.forEach(store => { store.editLossRate(this.lossRateForm.value); });
    }
  }

  doClearFormSetAll() {
    if (this.commonService.config.allStoreCopyConfirm == true) {
      this.clearKeypadTarget();
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle, "全店の設定を解除します。").subscribe(
        (response) => {
          subsc.unsubscribe();
          if (response == true) {
            this.lossRateList.forEach(store => { store.editLossRate(""); });
          }
        }
      );
    }
  }

  parseIntCustom(x: string) {
    const parsed = parseInt(x);
    if (isNaN(parsed)) { return 0; }
    return parsed;
  }

  doUpdate() {
    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    const lossRateList: LossRateCtgUpdateDto[] = [];
    this.lossRateList.map(lossRate => {
      if(lossRate.isEdited()) {
        lossRateList.push({
          ctgCd0Fv: lossRate.ctgCd[0],
          ctgCd1Fv: lossRate.ctgCd[1] ?? "",
          ctgCd2Fv: lossRate.ctgCd[2] ?? "",
          ctgCd3Fv: lossRate.ctgCd[3] ?? "",
          ctgLevelFn: lossRate.ctgLevel,
          rankFv: this.rankSelected,
          storeCdFv: lossRate.storeCd,
          lossRate: lossRate.lossRateForm.value == "" ? -1 : +lossRate.lossRateForm.value
        });
      }
     
    });

    const req: ReqLossRateCtgUpdate = {
      access: this.commonService.loginUser,
      updateList: lossRateList,
      ctgCd0Fv:  this.ctgCd0Fv ,
      ctgCd1Fv:  this.ctgCd1Fv ,
      ctgCd2Fv:  this.ctgCd2Fv ,
      ctgCd3Fv:  this.ctgCd3Fv ,
      ctgLevelFn: +this.ctgLevel,
      rankFv: this.rankFv,
      storeCdFv: '*',
      averageDiscountRate: this.commonService.config.averageDiscountRate
    }
    this.httpBasic.updateLossRate(req).subscribe(
      (response: RspLossRateUpdate) => {       
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) return;
        this.lossRateForm.setValue("");
        this.isDirty = false;
        this.receiveLossRate(response.result);
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      })
  }


  changeListener(value, rec: LossRateRec) {
    this.isDirty = true;
    
    // if(value === "" && rec.lossRate != -1){
    //   this.setErrorMessage();
    //   rec.lossAmountEdit = -1;      // Set dirty
    //   rec.lossAmountSubject.next(-1);
    //   rec.invalid = true;
    //   return; 
    // } 
    
    if (this.isValid(value)) {
      this.clearErrorMessage();
      rec.invalid = false;

    } else {
        this.setErrorMessage();
        rec.invalid = true;
    }
    
    rec.lossAmountEdit = -1;      // Set dirty
    rec.lossAmountSubject.next(-1);
  }

  blurRate(rec: LossRateRec) {
    rec.doCalcLossAmount();
  }

  isValid(n) {
    if (n === "") return true;
    let val = Number(n);
    if (isNaN(val)) return false;
    if (val < 0 || val > this.commonService.config.lossRateUpperLimit) return false;
    return true;
  }

  isSubmit() {    
    if (this.lossRateList.length < 1) return;
    for (let index = 0; index < this.lossRateList.length; index++) {
      const element = this.lossRateList[index];
      if (!element.isValid()){
        return true;
      }
    }
  }
}
