import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSearchTaskResultRec, RspSearchTaskResultRec, TaskImageRecDto } from 'src/app/webservice/task';
import { PhotoItem, StoreStatusListItem, SubTask, SubTaskResult, TaskTargetStore } from '../0_def/taskDefs';
import { TaskRec } from '../0_def/taskRec';
import { TaskStoreRec } from '../0_def/taskStoreRec';
import { TaskService } from '../1_service/taskService';
import { TaskPhotoDialogComponent } from '../photo-dialog/task-photo-dialog.component';
import { TaskPhotoViewDialogComponent } from '../photo-view-dialog/task-photo-view-dialog.component';

@Component({
  selector: 'app-hq-store-result2',
  templateUrl: './hq-store-result2.component.html',
  styleUrls: ['./hq-store-result2.component.css']
})
export class HqStoreResult2Component implements  OnInit, OnDestroy, OnChanges  {

  public taskStoreList: TaskStoreRec[];
  public selectedTaskStore: TaskStoreRec;
  public selectedSubTask: SubTask;
  public selectedSubTaskResult: SubTaskResult;
  public selectedPhoto: PhotoItem;
  public subTasks: SubTask[];
  public storeStatusList: StoreStatusListItem[];
  public selectedStore: StoreStatusListItem;  
  public columnIdsStoreList: string[] = ["storeNameWithCode", "status"];
  public columnDefsStoreList: TableColumnDef[] = [
    {columnId:'storeNameWithCode', header:"店舗", width:100, align:"left"},
    {columnId:'status', header:"ステータス", width:100, align:"left"}
  ];
  public columnIds: string[] = ["summary", "subTaskInput", "subTaskPhoto"];

  @Input("taskRec") taskRec: TaskRec;
  @ViewChild("subTaskTable", {static: false}) subTaskTable : MatTable<any>; 

  constructor(
    public commonService: CommonService,
    private httpBasic:    HttpBasicService,
    public taskService:   TaskService
    ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(): void {    
    if (!this.taskRec) return;
    this.taskStoreList = undefined;
    this.selectedStore = undefined;
    this.selectedTaskStore = undefined;
    this.selectedSubTask = undefined;
    this.selectedSubTaskResult = undefined;
    this.selectedPhoto = undefined;
    this.subTasks = undefined;

    this.getResult();
    this.setTableHeight();
  }

  // @HostListener('window:resize', ['$event'])
  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "sub-task-table-box";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight !== undefined) {
      let paginatorHeight = 0;
      let margin = 10 + 5;
      let btnBox = 0;
      let height = remHeight - paginatorHeight - margin - btnBox;
      if (height < 270) height = 270;
  
      let elem = document.getElementById(id);
      if (elem) elem.style.maxHeight = "" + height + "px";
    }
    this.photoColumnMaxWidth();
  }

  getResult() {
    let request: ReqSearchTaskResultRec = {
      access:       this.commonService.loginUser,
      taskId:       this.taskRec.taskId,
      storeCd:      "",
      resultStatus: [],
      dateEndMin:   "",
      dateEndMax:   "",
      taskName:     "",
      authorFv:     "",
      authorStoreCd: "",
      authorLoginUserId: "",
      responsibleClass: "",
      responsible: "",
      responsiblePartial: true,
      taskCategory: "",
      subStatusNotCompleted: true,
      subStatusCompleted: true,
      subResponsible: "",
      subResponsiblePartial: true,
      subDateYesterday: true,
      subDateToday: true,
      subDateTommorow: true,
      isResponsibleUser: this.commonService.config.task.responsibleIsLoginUser
    };
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.searchTaskResultRec(request).subscribe(
      (response: RspSearchTaskResultRec) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveGetResult(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetResult(response: RspSearchTaskResultRec) {
    if (this.httpBasic.handleAppError(response)) return;

    this.taskStoreList = [];
    response.taskResultSearchRec.forEach((rec) => {
      let storeRec = new TaskStoreRec(this.commonService, this.taskService, this.httpBasic);
      let targetStores: TaskTargetStore[] = JSON.parse(rec.taskRec.targetStores);
      let subTasks: SubTask[] = JSON.parse(rec.taskRec.subTasks);
      delete rec.taskRec.targetStores;
      delete rec.taskRec.subTasks;
      storeRec.patchValue(rec.taskRec);
      storeRec.targetStores = targetStores;
      storeRec.subTasks = subTasks;
      storeRec.patchValue(rec.taskResultRec);
      this.taskStoreList.push(storeRec);
    });

    this.storeStatusList = [];
    this.taskRec.targetStores.forEach((target) => {
      let store = this.taskStoreList.find((item) => item.storeCd === target.storeCd);
      if (store) {
        let item: StoreStatusListItem = {
          storeCd:            target.storeCd,
          storeName:          target.storeName,
          storeNameWithCode:  target.storeNameWithCode,
          status:             store.resultStatus
        };
        this.storeStatusList.push(item);
      }
    });
  }

  openReference() {
    window.open(this.taskRec.referenceUrl, "PippiTask");
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    }
  }

  selectStore(item: StoreStatusListItem) {
    this.selectedStore = undefined;
    this.selectedTaskStore = undefined;
    this.selectedSubTask = undefined;
    this.selectedSubTaskResult = undefined;
    this.selectedPhoto = undefined;
    this.subTasks = undefined;

    this.selectedStore = item;
    this.selectedTaskStore = this.taskStoreList.find((store) => store.storeCd === item.storeCd);
    this.subTasks = [];
    if (!this.selectedTaskStore.subTaskResults || this.selectedTaskStore.subTaskResults.length == 0) {
      this.taskService.subTaskResult(this.selectedTaskStore, () =>
      {
        this.selectedTaskStore.subTasks.forEach((subtask) => {
          if (subtask.subTaskTargetStores === undefined || subtask.subTaskTargetStores.find((store) => store.storeCd === this.selectedTaskStore.storeCd)) {
            subtask["result"] = this.selectedTaskStore.subTaskResults.find((item) => item.subTaskId == subtask.subTaskId);
            this.subTasks.push(subtask);
          }
        });
        this.setTableHeight();
        this.subTaskTable.renderRows();
        this.getPhotoAll();
      });
    } else {
      this.selectedTaskStore.subTasks.forEach((subtask) => {
        if (subtask.subTaskTargetStores === undefined || subtask.subTaskTargetStores.find((store) => store.storeCd === this.selectedTaskStore.storeCd)) {
          subtask["result"] = this.selectedTaskStore.subTaskResults.find((item) => item.subTaskId == subtask.subTaskId);
          this.subTasks.push(subtask);
        }
      });
      this.setTableHeight();
      this.getPhotoAll();
    }
  }

  getPhotoAll() {
    this.selectedTaskStore.subTaskResults.forEach((result) => {
      result.photos.forEach((photo) => {
        this.getPhoto(photo);
      })
    });
  }

  async getPhoto(photo: PhotoItem) {
    await this.getPhotoAsync(photo);
  }

  async getPhotoAsync(photo: PhotoItem): Promise<void> {
    return new Promise<void>((resolve) => {
      if (photo.delFlagFn !== undefined && photo.delFlagFn > 0) {
        resolve();
        return;
      }
      if (photo.isDeleted !== undefined && photo.isDeleted) {
        resolve();
        return;
      }
      if (photo.dataUrl !== undefined && photo.dataUrl !== "") {
        resolve();
        return;
      }
      this.taskService.getImageData(
        photo, 
        (image)=>{
          photo.dataUrl = image.dataUrl;
          photo.dataUrlOrg = image.dataUrl;
          photo.isDeleted = image.isDeleted;
          photo.delFlagFn = image.delFlagFn;
          resolve();
        },
        true
      );
    });
  }

  subTaskWorkingStatus(subTask: SubTask) {
    if(!subTask) return "";
    let result = this.selectedTaskStore.subTaskResults.find((item) => item.subTaskId == subTask.subTaskId);
    if (result) {
      if (result.isComplete) return "完了";
      if (result.isWorking) return "作業中";
    }
    return "";
  }

  subTaskIsComplete(subTask: SubTask) {
    if(!subTask) return false;
    let result = this.selectedTaskStore.subTaskResults.find((item) => item.subTaskId == subTask.subTaskId);
    if (result && result.isComplete) return true;
    return false;
  }

  selectSubTask(subTask: SubTask) {
    this.selectedSubTask = subTask;
    this.selectedSubTaskResult = this.selectedTaskStore.subTaskResults.find((item) => item.subTaskId == subTask.subTaskId);
    this.selectedSubTaskResult.photos = this.selectedSubTaskResult.photos.filter((photo) => photo.delFlagFn === 0);
    this.selectedPhoto = undefined;
  }

  selectPhoto(photo: PhotoItem) {
    this.selectedPhoto = photo;

    if (!photo.dataUrl || photo.dataUrl === "") {
      // Get Image Data from a server.
      this.taskService.getImageData(photo, (image)=>{
        photo.dataUrl = image.dataUrl;
        photo.isDeleted = image.isDeleted;
      });
    }
  }

  viewPhoto(photoItem: PhotoItem) {
    let image: TaskImageRecDto = {
      taskId:             photoItem.taskId,
      storeCd:            photoItem.storeCd,
      subTaskId:          photoItem.subTaskId,
      imageId:            undefined,
      imageTitle:         photoItem.title,
      image:              photoItem.dataUrl,
      imageThumbnail:     undefined,
      isShowEmoji:        false,
      emoteList:          undefined
    };

    let subsc = this.commonService.dialog.open(TaskPhotoViewDialogComponent, {
      data: image
    }).afterClosed().subscribe(
      (data) => {
        subsc.unsubscribe();
      }
    );
  }
  
  previewFileSubTask(subTask: SubTask){
    if(!subTask.subTaskAttachment){ 
      const request = {
        taskId: this.taskRec.taskId,
        storeCd: null,
        subTaskId: subTask.subTaskId,
        access: this.commonService.loginUser
      }
      if(this.commonService.loginUser.roleId != 0) {
        request.storeCd = this.selectedStore.storeCd;
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.subTaskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            subTask.subTaskAttachment = response.attachment;
            subTask.subTaskFileName = response.attachmentFilename;
            await this.receivePreviewFile(subTask.subTaskAttachment, subTask.subTaskFileName); 
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {
      this.receivePreviewFile(subTask.subTaskAttachment, subTask.subTaskFileName);
    }
  }

  async receivePreviewFile(attachment: string, filename: string) {
    let mimeType;
    let base64 = attachment.split(';base64,')[1];
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
      
      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;

      default: mimeType ='NoSupport';
    }
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      if (mimeType == "NoSupport") {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `サポートされていないファイル形式`);
      }else {
        var iframe = "<iframe src='" + url + "' frameborder='0' style='border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;' allowfullscreen></iframe>";
        var openTab = window.open();
        openTab.document.open();
        openTab.document.write(iframe);
        openTab.document.title = filename; 
        openTab.document.close();
      } 
    });
  }

  downLoadFileSubTask(subTask: SubTask){
    const request = {
      taskId: this.taskRec.taskId,
      storeCd: null,
      subTaskId: subTask.subTaskId,
      access: this.commonService.loginUser
    }
    if(this.commonService.loginUser.roleId != 0) {
      request.storeCd = this.selectedStore.storeCd;
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.subTaskRecFileSearch(request).subscribe(
      async (response) => {
        if(response){
          this.commonService.closeSpinner(); 
          let attachment = response.attachment;
          let filename = response.attachmentFilename;
          await this.receiveDownloadFile(attachment, filename);
        }
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  async receiveDownloadFile(attachment: string, filename: string) {
    let base64 = attachment.split(';base64,')[1];
    let mimeType;
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
      
      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;
      default: mimeType ='NoSupport';
    }
    //decode a Base64-encoded
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      this.commonService.downloadBlob(blob, filename);
    });  
  }
  
  getSubTaskFileName(subTask: SubTask) {
    return subTask.subTaskFileName;
  }

  openSubTaskReference(url: string) {
    window.open(url, "PippiTask");
  }

  photoColumnMaxWidth() {
    let id = "photo-header";
    let photoElement = document.getElementById(id);
    if (!photoElement) return undefined;
    let containerRect = photoElement.getBoundingClientRect();
    let winWidth = window.innerWidth;
    let containerElement = document.getElementsByTagName("body").item(0);
    let css = window.getComputedStyle(containerElement);
    let width = winWidth - containerRect.left - 50;
    if (width < 140) width = 140;
    photoElement.style.minWidth = "" + width + "px";
    photoElement.style.maxWidth = "" + width + "px";
  }

}
