import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { Subscription, Observable } from 'rxjs';
import { ReqItemSearch } from 'src/app/request/req-item-search';
import { RspItemSearch } from 'src/app/response/rsp-item-search';
import { ReqParamItemEdit } from 'src/app/request/req-param-item-edit';
import { RspParamItemEdit } from 'src/app/response/rsp-param-item-edit';
import { ReqOrderStopItemEdit } from 'src/app/request/req-order-stop-item-edit';
import { RspOrderStopItemEdit, OrderStopItemResultDto } from 'src/app/response/rsp-order-stop-item-edit';
import { ItemRec2 } from 'src/app/common/item-rec2';
import { OrderByUserDto, ReqUpdateOrderByUser, ReqUpdateOrderByUserDto, RspUpdateOrderByUser } from 'src/app/webservice/order-by-user';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { isEmpty, pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-scan-item-edit2',
  templateUrl: './scan-item-edit2.component.html',
  styleUrls: ['./scan-item-edit2.component.css']
})
export class ScanItemEdit2Component implements OnInit, OnDestroy {

  public storeForm: FormControl;

  public formGroup: FormGroup;
  public orderForm: FormControl;
  public orderBaraForm: FormControl;
  public stockForm: FormControl;
  public minZaiForm: FormControl;
  public maxZaiForm: FormControl;
  public minStockDaysForm: FormControl;
  public maxStockDaysForm: FormControl;
  public orderStopForm: FormControl;
  public autoOrderLotModForm: FormControl;
  public inputItemCdForm: FormControl = new FormControl("");

  public itemRec: ItemRec2;
  public orderStopList: {value: number, name: string, canOrder: boolean, changeable:boolean}[] = [];
  public orderStopInitial: string = "";
  public orderErrorMessage: string;
  public orderStopErrorMessage: string;
  public isOrderEditable: boolean = false;
  public isOrderEditableItem: boolean = false;
  public isStockEditable: boolean = false;
  public isParamEditable: boolean = false;
  public isOrderStopEditable: boolean = false;

  private keypadTargetId: string;
  private nextJanCode: string = "";
  private fullScreenWithError: boolean = true;    // Recal full screen at ths first query
  private subscriptionStore: Subscription;
  private subscriptionConfirm: Subscription;
  private subscriptionQuery: Subscription;
  private subscriptionBaraNum: Subscription;
  private subscriptionOrderNumCheck: Subscription;

  private propClosingTime: Date;
  private serverTime: Date;
  private deviceTime: Date;
  private timeLag: number;
  private timerId: any = -1;
  private isAdjusted: boolean = false;
  public autoOrderLot: boolean = false;
  private autoOrderLotEditable: boolean = false;
  public autoOrderLotErrorMessage: string;
  public isAutoOrderLotErrorMessage: boolean = false;
  public isAutoOrderLot: boolean = false;
  //public autoOrderLotFn: string = "";
  // public orderStopFlagValues: number[];

  @ViewChild(NumberKeypadComponent, {static: true}) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder
  )
  {
    /*
    this.orderStopFlagValues = [];
    let flagList = this.commonService.getOrderStopSearchCondList();
    for (let i = 0; i < flagList.length; i++) {
      let flag = flagList[i];
      if (!flag.paramEditable) continue;
      if (flag.caseItem) continue;
      this.orderStopFlagValues.push(flag.value);
    }
    */
  }

  ngOnInit() {
    if (this.commonService.config.useItemAutoOrderLot) {
      this.autoOrderLot = true;
      if (this.commonService.config.paramItemAutoOrderLotEditable) {
        this.autoOrderLotEditable = true;
      }
    }
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.isOrderEditable = this.commonService.hasMenu("order2") || this.commonService.hasMenu("orderStock") ||
      this.commonService.hasMenu("orderByUser") || this.commonService.hasMenu("orderStockByUser");
    this.isStockEditable =
      this.commonService.hasMenu("stock") ||
      this.commonService.hasMenu("stock2") || this.commonService.hasMenu("orderStock") ||
      this.commonService.hasMenu("stockByUser") || this.commonService.hasMenu("orderStockByUser");
    this.isParamEditable = this.commonService.hasMenu("itemparam");
    this.isOrderStopEditable = this.commonService.hasMenu("autotrgt");

    this.storeForm = new FormControl(this.commonService.loginUser.storeCd);
    this.subscriptionStore = this.storeForm.valueChanges.subscribe(
      (store) => {this.storeChanged(store);}
    );

    this.formGroup = this.fb.group(
      {
        orderForm: [""],
        orderBaraForm: [""],
        stockForm: [""],
        orderStopForm: [""],
        autoOrderLotModForm: [""],
        paramForm: this.fb.group({
            minZaiForm : [
              "",
              (fc:FormControl) => {
                return this.paramRangeValidator(
                  fc,
                  this.commonService.config.paramItemMinZaiRange.min,
                  this.commonService.config.paramItemMinZaiRange.max,
                  "最低陳列");
              }
            ],
            maxZaiForm : [
              "",
              (fc:FormControl) => {
                return this.paramRangeValidator(
                  fc,
                  this.commonService.config.paramItemMaxZaiRange.min,
                  this.commonService.config.paramItemMaxZaiRange.max,
                  "最大陳列");
              }
            ],
            minStockDaysForm : [
              "",
              (fc:FormControl) => {
                return this.paramRangeValidator(
                  fc,
                  this.commonService.config.paramItemMinStockDaysRange.min,
                  this.commonService.config.paramItemMinStockDaysRange.max,
                  "最低在庫日数");
              }
            ],
            maxStockDaysForm : [
              "",
              (fc:FormControl) => {
                return this.paramRangeValidator(
                  fc,
                  this.commonService.config.paramItemMaxStockDaysRange.min,
                  this.commonService.config.paramItemMaxStockDaysRange.max,
                  "最大在庫日数");
              }
            ]
          }
        )
      },
      {
        validators: (fg:FormGroup) => {return this.groupValidator(fg, this.itemRec)}
      }
    );

    this.orderForm = this.formGroup.get("orderForm") as FormControl;
    this.orderBaraForm = this.formGroup.get("orderBaraForm") as FormControl;
    this.stockForm = this.formGroup.get("stockForm") as FormControl;
    this.orderStopForm = this.formGroup.get("orderStopForm") as FormControl;
    this.minZaiForm = this.formGroup.get("paramForm").get("minZaiForm") as FormControl;
    this.maxZaiForm = this.formGroup.get("paramForm").get("maxZaiForm") as FormControl;
    this.minStockDaysForm = this.formGroup.get("paramForm").get("minStockDaysForm") as FormControl;
    this.maxStockDaysForm = this.formGroup.get("paramForm").get("maxStockDaysForm") as FormControl;
    this.autoOrderLotModForm = this.formGroup.get("autoOrderLotModForm") as FormControl;
    this.formGroup.disable({emitEvent: false});

    this.subscriptionBaraNum = this.orderBaraForm.valueChanges.subscribe((val) => {
      this.baraNumChanged(val);
    });

    let form: FormControl;
    if (this.commonService.config.orderEditInBara) {
      form = this.orderBaraForm;
    } else {
      form = this.orderForm;
    }
    this.subscriptionOrderNumCheck = form.valueChanges
      .pipe(
        startWith(form.value),
        pairwise()
      )
      .subscribe(([prevVal, newVal]) => {
        this.commonService.valueCheckOrderNum(form, prevVal, newVal);
      });

    // this.orderStopList = this.commonService.getOrderStopSelectList();

    if (this.commonService.config.isHSD || this.commonService.config.scanFullScreen) {
      document.getElementById("inner-container").style.transform = "scale(1.0)";
      document.documentElement.requestFullscreen();
      let delay = 1000;
      if (this.commonService.config.delayAfterFullScreen) delay = this.commonService.config.delayAfterFullScreen;
      setTimeout(() => {this.scaleContainer();}, delay);
    }
  }

  scaleScreen() {
    /*
    document.getElementById("inner-container").style.transform = "scale(1.0)";
    document.documentElement.requestFullscreen();
    */
    if (!this.commonService.config.isHSD && !this.commonService.config.scanFullScreen) return;
    let delay = 1000;
    if (this.commonService.config.delayAfterFullScreen) delay = this.commonService.config.delayAfterFullScreen;
    setTimeout(() => {this.scaleContainer();}, delay);
  }

  getCurrentScale(elem: Element) {
    let prop: string = window.getComputedStyle(elem).getPropertyValue('transform');
    if (prop && prop.startsWith("matrix")) {
      return parseFloat(prop.split(",")[3]);
    } else {
      return 1.0;
    }
  }

  scaleContainer() {
    if (!this.commonService.config.isHSD && !this.commonService.config.scanFullScreen) return;
    let id = "inner-container";
    let containerElement = document.getElementById(id);
    let containerRect = containerElement.getBoundingClientRect();
    let areaHeight = window.innerHeight - containerRect.top - 37;
    let areaWidth  = window.innerWidth - 5;
    let scaleH = areaHeight / containerRect.height;
    let keypadRect = document.getElementById("keypad-container").getBoundingClientRect();
    let scaleW = areaWidth / keypadRect.right;
    // let scale = Math.min(scaleH, scaleW);
    let cscale = this.getCurrentScale(containerElement);
    let scale = Math.min(scaleH, scaleW) * cscale;

    document.getElementById(id).style.transformOrigin = "top left";
    document.getElementById(id).style.transform = "scale(" + scale + "," + scale + ")";
  }

  ngOnDestroy() {
    if (this.subscriptionStore) {
      this.subscriptionStore.unsubscribe();
    }
    if (this.subscriptionConfirm) {
      this.subscriptionConfirm.unsubscribe();
    }
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
    }
    this.subscriptionBaraNum?.unsubscribe();
    this.subscriptionOrderNumCheck?.unsubscribe();

    if (this.commonService.config.isHSD || this.commonService.config.scanFullScreen) {
      document.getElementById("inner-container").style.transformOrigin = "top left";
      document.getElementById("inner-container").style.transform = "scale(1.0)";
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    event.preventDefault();

    if (event.key == "Enter" && this.nextJanCode != "") {
      let deactivate = this.canDeactivate();
      if (deactivate == true) {
        this.queryItem();
        return;
      }
      this.subscriptionConfirm = (deactivate as Observable<boolean>).subscribe(
        response => {
          if (response == true) {
            this.queryItem();
          } else {
            this.subscriptionConfirm.unsubscribe();
            this.subscriptionConfirm = undefined;
          }
        }
      );
      return;
    }

    if (isNaN(parseInt(event.key))) {
      this.nextJanCode = "";
      if (this.commonService.config.itemQuery.scanControlV && (event.key === 'v' && (event.ctrlKey || event.metaKey))) {
        navigator.clipboard.readText().then(
          (clipText) => this.nextJanCode = clipText
          )
      }
    } else {
      this.nextJanCode += event.key;
    }

    /* DEBUG
    if (event.key == "a") this.nextJanCode = "4987306011557";
    if (event.key == "b") this.nextJanCode = "4968250280121";
    */
  }

  storeChanged(storeCd: string) {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.clearItem();
      return;
    }
    this.subscriptionConfirm = (deactivate as Observable<boolean>).subscribe(
      response => {
        if (response == true) {
          this.clearItem();
        } else {
          this.subscriptionConfirm.unsubscribe();
          this.subscriptionConfirm = undefined;
        }
      }
    );
  }

  clearItem() {
    this.itemRec = undefined;
    this.orderStopInitial = "";
    this.formGroup.reset();
    this.keypadTargetId = undefined;
    this.numberKeypadComponent.inputField = undefined;
    this.orderErrorMessage = undefined;
    this.orderStopErrorMessage = undefined;
    this.autoOrderLotErrorMessage = undefined;

    this.orderStopForm.disable({emitEvent: false});
    this.autoOrderLotModForm.disable({emitEvent: false});

    /*
    this.orderForm.setValue("");
    this.stockForm.setValue("");
    this.minZaiForm.setValue("");
    this.maxZaiForm.setValue("");
    this.minStockDaysForm.setValue("");
    this.maxStockDaysForm.setValue("");
    this.orderStopForm.setValue("");
    this.formGroup.markAsPristine();
    */
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  is10KeyPadTarget(id: string) {
    // if (!this.use10KeyPad()) return false;
    if (id === this.keypadTargetId) return true;
    return false;
  }

  set10KeyPadTarget(id: string, form: FormControl) {
    let ignore = false;
    if (!this.itemRec && id != "inputItemCd") ignore = true;
    if (id == "orderNum" &&
        !this.commonService.getOrderStopFlagCanOrderEx(this.itemRec.orderStopFlagFn) &&
        !this.commonService.getOrderStopFlagCanCaseOrder(this.itemRec.orderStopFlagFn)) ignore = true;
    if (id == "orderBaraNum" &&
        !this.commonService.getOrderStopFlagCanOrderEx(this.itemRec.orderStopFlagFn) &&
        !this.commonService.getOrderStopFlagCanCaseOrder(this.itemRec.orderStopFlagFn)) ignore = true;
    if (this.itemRec && this.commonService.getOrderStopFlag(this.itemRec.orderStopFlagFn)?.caseItem) {
      if (id == "stockNum" && !this.commonService.config.stockEditCaseItem) ignore = true;
      if (id == "minZai" || id == "maxZai" || id == "minStockDays" || id == "maxStockDays") ignore = true;;
    }
    if (id == "minZai" && !this.commonService.config.paramItemMinZaiEditable) ignore = true;
    if (id == "maxZai" && !this.commonService.config.paramItemMaxZaiEditable) ignore = true;
    if (id == "minStockDays" && !this.commonService.config.paramItemMinStockDaysEditable) ignore = true;
    if (id == "maxStockDays" && !this.commonService.config.paramItemMaxStockDaysEditable) ignore = true;
    if (id == "autoOrderLotMod" && this.autoOrderLot && !this.autoOrderLotEditable) ignore = true;
    if (ignore) {
      this.keypadTargetId = undefined;
      this.numberKeypadComponent.inputField = undefined;
      return;
    }

    this.keypadTargetId = id;
    this.numberKeypadComponent.setTargetForm(form);
    if (id == "inputItemCd") {
      this.numberKeypadComponent.setMaxCol(13);
      this.numberKeypadComponent.setStringMode();
      this.numberKeypadComponent.clearAtFirstInput(false);
    } else {
      this.numberKeypadComponent.clearMaxCol();
    }
  }

  isKeyboardLocked() {
    if (this.numberKeypadComponent?.isKeyboardLocked()) return true;
    return false;
  }

  baraNumChanged(value) {
    if (!this.itemRec) return;
    if (value === "" || value === 0) this.orderForm.setValue(0);

    let lotNum = Math.ceil(this.orderBaraForm.value / this.itemRec.orderLotFn);
    this.orderForm.setValue(lotNum);
  }

  zaiValidator(fg:FormGroup) {
    var err = {BadValue: "最低、最大が逆転しています。"};

    if (fg.get("minZaiForm").value != "" &&
        fg.get("maxZaiForm").value != "" &&
        parseInt(fg.get("minZaiForm").value) > parseInt(fg.get("maxZaiForm").value)) {
      return err;
    } else {
      return null;
    }
  }

  stockDaysValidator(fg:FormGroup) {
    var err = {BadValue: "最低、最大が逆転しています。"};

    if (fg.get("minStockDaysForm").value != "" &&
        fg.get("maxStockDaysForm").value != "" &&
        parseInt(fg.get("minStockDaysForm").value) > parseInt(fg.get("maxStockDaysForm").value)) {
      return err;
    } else {
      return null;
    }
  }

  minZaiValidator(fc:FormControl) {
    return this.paramRangeValidator(
      fc,
      this.commonService.config.paramItemMinStockDaysRange.min,
      this.commonService.config.paramItemMinStockDaysRange.max,
      "最低在庫日数");
  }

  paramRangeValidator(fc: FormControl, min:number, max:number, type:string) {
    var err = {"ValueRange": type + "の範囲は [ " + min + " ～ " + max + " ] です。"};
    if (parseInt(fc.value) < min || parseInt(fc.value) > max) {
      return err;
    } else {
      return null;
    }
  }

  isValueChanged() {
    if (!this.itemRec) return false;

    /*
    if (parseInt(this.orderForm.value) != this.itemRec.orderNumCurrentFn) return true;
    if (parseInt(this.stockForm.value) != this.itemRec.stockNumCurrentFn) return true;
    if (this.minZaiForm.value != this.itemRec.minZaiCurrentFv) return true;
    if (this.maxZaiForm.value != this.itemRec.maxZaiCurrentFv) return true;
    if (this.minStockDaysForm.value != this.itemRec.minStockDaysCurrentFv) return true;
    if (this.maxStockDaysForm.value != this.itemRec.maxStockDaysCurrentFv) return true;
    if (parseInt(this.orderStopForm.value) != this.itemRec.orderStopFlagCurrentFn) return true;
    */
    if (this.commonService.config.orderEditInBara) {
      if (parseInt(this.orderBaraForm.value) != this.itemRec.getValue("orderNumFn") * this.itemRec.orderLotFn) return true;
    } else {
      if (parseInt(this.orderForm.value) != this.itemRec.getValue("orderNumFn")) return true;
    }
    if (parseInt(this.stockForm.value) != this.itemRec.getValue("stockNumFn")) return true;
    if (this.autoOrderLotModForm.value != this.itemRec.getValue("autoOrderLotFnEdit")) return true;
    if (this.minZaiForm.value != this.itemRec.getValue("minZaiFnEdit")) return true;
    if (this.maxZaiForm.value != this.itemRec.getValue("maxZaiFnEdit")) return true;

    if (this.minStockDaysForm.value != this.itemRec.getValue("minStockDaysFnEdit")) return true;
    if (this.maxStockDaysForm.value != this.itemRec.getValue("maxStockDaysFnEdit")) return true;
    if (parseInt(this.orderStopForm.value) != this.itemRec.getValue("orderStopFlagFnValue")) return true;

    return false;
  }

  groupValidator(fg:FormGroup, itemRec: ItemRec2) {
    var isErr = false;
    var err : object;
    var errs : object = {};

    if (!itemRec) return null;

    if (this.isOrderEditable) {
      var orderForm = fg.get("orderForm");
      if (this.commonService.config.orderEditInBara) orderForm = fg.get("orderBaraForm");
      err = Validators.required(orderForm);
      orderForm.setErrors(err);
    }
    if (this.isStockEditable) {
      var stockForm = fg.get("stockForm");
      err = Validators.required(stockForm);
      stockForm.setErrors(err);
    }

    var minZaiForm = fg.get("paramForm").get("minZaiForm");
    var maxZaiForm = fg.get("paramForm").get("maxZaiForm");
    if (minZaiForm.value !== "" && maxZaiForm.value !== "" &&
        parseInt(minZaiForm.value) > parseInt(maxZaiForm.value)) {
      errs["MinZaiBadValue"] = "最低、最大が逆転しています。";
      isErr = true;
    }
    var minStockDaysForm = fg.get("paramForm").get("minStockDaysForm");
    var maxStockDaysForm = fg.get("paramForm").get("maxStockDaysForm");
    if (minStockDaysForm.value !== "" && maxStockDaysForm.value !== "" &&
        parseInt(minStockDaysForm.value) > parseInt(maxStockDaysForm.value)) {
      errs["StockDaysBadValue"] = "最低、最大が逆転しています。";
      isErr = true;
    }

    if (!this.isValueChanged()) {
      errs["NoChange"] = "値の変更がありません。";
      isErr = true;
    }

    if (this.autoOrderLot) {
      let autoOrderLotModFn = this.autoOrderLotModForm.value;
      if ((parseInt(autoOrderLotModFn) === 0) || ((parseInt(autoOrderLotModFn) % this.itemRec.orderLotFn) != 0) && autoOrderLotModFn != "") {
        this.autoOrderLotErrorMessage = "自発用ロットの値はロットの整数倍でなければなりません。";
        this.isAutoOrderLot = true;
      } else {
        this.autoOrderLotErrorMessage = undefined;
        this.isAutoOrderLot = false;
      }
    }

    if (isErr) {
      return errs;
    } else {
      return null;
    }
  }

  btnQuery() {
    if (this.inputItemCdForm.value === "") return;

    this.nextJanCode = this.inputItemCdForm.value;
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.queryItem();
      return;
    }
    this.subscriptionConfirm = (deactivate as Observable<boolean>).subscribe(
      response => {
        if (response == true) {
          this.queryItem();
        } else {
          this.subscriptionConfirm.unsubscribe();
          this.subscriptionConfirm = undefined;
        }
      }
    );

  }

  itemRecProp(key: string) {
    if (!this.itemRec) return '';
    return this.itemRec[key];
    // return this.itemRec.getValue(key);
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
      this.subscriptionQuery = undefined;
    }
  }

  queryItem() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.clearTimer();

    var request : ReqItemSearch = {
      access: {...this.commonService.loginUser},
      itemSearchCondition : {
        calcTrgtDateFd: this.commonService.salesDate,
        // storeCdFv: this.itemSelectSimpleCondition.formGroup.get("storeCd").value,
        storeCdFv: this.storeForm.value,
        ctgCd0Fv: "",
        ctgCd1Fv: "",
        ctgCd2Fv: "",
        ctgCd3Fv: "",
        rackCd1Fn: 0,
        rackCd2Fn: 0,
        rackCd3Fn: 0,
        itemCdFv: this.nextJanCode,
        itemCdEqualSearch: true,
        itemNameFv: "",
        orderCurrentOperator: 0,
        orderCurrentNum: 0,
        orderInitialOperator: 0,
        orderInitialNum: 0,
        stockCurrentOperator: 0,
        stockCurrentNum: 0,
        stockInitialOperator: 0,
        stockInitialNum: 0,
        // TODO orderStopFlagList?: orderStopFlagValues;
      },
      multiOrderDate: true,
      page: {
        pageNum: 0,
        dispNum: 1000
      }
    }

    this.subscriptionQuery = this.httpBasic.itemSearch(request).subscribe(
      response => this.receiveQueryItem(response),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );

  }

  receiveQueryItem(response: RspItemSearch) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;

    if (response.recordCount == 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "商品 [ " + this.nextJanCode + " ] が見つかりませんでした。");
      this.nextJanCode = "";
      return;
    }

    this.propClosingTime = new Date();
    let tm = response.propClosingTime.split(":");
    this.propClosingTime.setHours(parseInt(tm[0]), parseInt(tm[1]), parseInt(tm[2]));
    this.serverTime = new Date();
    tm = response.serverTime.split(":");
    this.serverTime.setHours(parseInt(tm[0]), parseInt(tm[1]), parseInt(tm[2]));
    this.deviceTime = new Date();
    this.timeLag = this.serverTime.getTime() - this.deviceTime.getTime();

    let row = response.rows[0];
    if (response.rows.length > 1) {
      let rowTmp = response.rows.find((item) => item.itemCdFv === this.nextJanCode);
      if (rowTmp) row = rowTmp
    }
    this.nextJanCode = "";
    // this.itemRec = new ItemRec(response.rows[0], this.commonService);
    this.itemRec = new ItemRec2(this.commonService);
    this.itemRec.initByDto((row as any) as OrderByUserDto);
    let serverTime = this.calcServerTime();
    this.itemRec.checkClosingTime(this.propClosingTime, serverTime);

    this.orderStopInitial = this.commonService.getOrderStopFlagName(this.itemRec.orderStopFlagFn);
    this.setCurrentValue();
    this.formGroup.enable({emitEvent: false});
    this.keypadTargetId = undefined;
    this.numberKeypadComponent.inputField = undefined;

    this.isOrderEditableItem = true;
    if (!this.commonService.getOrderStopFlagCanOrder(this.itemRec.orderStopFlagFn)) {
      if (this.commonService.getOrderStopFlagCanOrderEx(this.itemRec.orderStopFlagFn)) {
        this.orderErrorMessage = "この商品は" + this.commonService.getOrderStopFlagName(this.itemRec.orderStopFlagFn) + "商品です。";
        if (this.itemRec.orderableDateFv === "0000000") {
          this.orderErrorMessage += "発注が可能な曜日の設定がありません。";
        }
      } else if (this.commonService.getOrderStopFlagCanCaseOrder(this.itemRec.orderStopFlagFn)) {
        this.orderErrorMessage = "この商品はケース商品です。";
        if (this.itemRec.orderableDateFv === "0000000") {
          this.orderErrorMessage += "発注が可能な曜日の設定がありません。";
        }
      } else {
        this.orderErrorMessage = "この商品は発注が許可されていないため、発注数の変更はできません。";
        this.isOrderEditableItem = false;
      }
    } else {
      if (this.itemRec.orderableDateFv === "0000000") {
        this.orderErrorMessage = "発注が可能な曜日の設定がありません。";
      } else {
        this.orderErrorMessage = undefined;
      }
    }

    if (!this.commonService.getOrderStopFlagChangeable(this.itemRec.orderStopFlagFn)) {
      this.orderStopList = this.commonService.config.orderStopFlag;
      this.orderStopErrorMessage = "この商品の自動発注種別の選択は許可されていません。";
      this.orderStopForm.disable({emitEvent: false});
    } else {
      this.orderStopList = this.commonService.getOrderStopSelectList();
      this.orderStopErrorMessage = undefined;
      this.orderStopForm.enable({emitEvent: false});
    }

    this.setClosingTimer();
  }

  checkOrderWeekday() {
    var orderDate: Date = new Date(
      +this.itemRec.orderDate.substring(0, 4),
      +this.itemRec.orderDate.substring(5, 7) - 1,
      +this.itemRec.orderDate.substring(8, 10)
    );
    var dayOfWeek = orderDate.getDay();
    if (this.itemRec.orderableDateFv.substring(dayOfWeek, dayOfWeek+1) === '0') {
      return false;
    } else {
      return true;
    }
  }

  updateOrderStock() {
    this.isAdjusted = false;

    let dto: ReqUpdateOrderByUserDto = {
      groupId: -1,
      storeCdFv: this.itemRec.storeCdFv,
      itemCdFv: this.itemRec.itemCdFv,
      closingTime: this.itemRec.closingTime,

      orderNumFn: parseInt(this.orderForm.value),
      orderNumModFn: this.itemRec.orderNumModFn,
      orderNumVersionFn: this.itemRec.orderNumVersionFn,
      orderDate: this.itemRec.orderDate,
      orderDateNext: this.itemRec.orderDateNext,
      deliveryDate: this.itemRec.deliveryDate,
      deliveryDateNext: this.itemRec.deliveryDateNext,

      stockNumFn: parseInt(this.stockForm.value),
      stockNumModFn: this.itemRec.stockNumModFn,
      stockNumVersionFn: this.itemRec.stockNumVersionFn,

      rowAction: 0, // 0: noChange; 1: delete; 2: add
      isUpdateOrder: parseInt(this.orderForm.value) != this.itemRec.getValue("orderNumFn"),
      isUpdateStock: parseInt(this.stockForm.value) != this.itemRec.getValue("stockNumFn")
    };
    const request: ReqUpdateOrderByUser = {
      access: this.commonService.loginUser,
      itemList: [dto]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdateOrderAndStock", request).subscribe(
      (response: RspUpdateOrderByUser) => {
        subsc.unsubscribe();
        this.receiverOrderUpdate(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiverOrderUpdate(response: RspUpdateOrderByUser) {
    const resOrderUpdateList = response.resultUpdateOrderList;
    const resStockUpdateList = response.resultUpdateStockList;

    if (resOrderUpdateList.length > 0) {
      let resItem = resOrderUpdateList[0];
      if (this.itemRec.orderDate !== resItem.orderDateFv) {
        // Order Date is adjusted to the next orderable date
        this.itemRec.orderDateAdjusted(
          resItem.orderDateFv,
          resItem.orderNumFn,
          resItem.versionFn
        );
        this.isAdjusted = true;
      } else {
        this.itemRec.orderNumModFn = resItem.orderNumFn;
        this.itemRec.orderNumVersionFn = resItem.versionFn;
        this.orderForm.setValue(this.itemRec.getValue("orderNumFn"));
      }
    }
    if (this.commonService.config.orderEditInBara) {
      let enabled = this.orderBaraForm.enabled;
      if (enabled) this.orderBaraForm.disable({emitEvent: false});
      this.orderBaraForm.setValue(this.itemRec.getValue("orderNumFn") * this.itemRec.orderLotFn);
      if (enabled) this.orderBaraForm.enable({emitEvent: false});
    }

    if (resStockUpdateList.length > 0) {
      let resItem = resStockUpdateList[0];
      this.itemRec.stockNumModFn = resItem.stockNumFn;
      this.itemRec.stockNumVersionFn = resItem.versionFn;
      this.stockForm.setValue(this.itemRec.getValue("stockNumFn"));
    }

    this.updateParam();
  }

  updateParam() {
    if (this.minZaiForm.value == this.itemRec.getValue("minZaiFnEdit") &&
    this.maxZaiForm.value == this.itemRec.getValue("maxZaiFnEdit") &&
    // Yabe this.autoOrderLotModForm.value == this.itemRec.getValue("autoOrderLotModFnEdit") &&
    this.autoOrderLotModForm.value == this.itemRec.getValue("autoOrderLotFnEdit") &&
    this.minStockDaysForm.value == this.itemRec.getValue("minStockDaysFnEdit") &&
    this.maxStockDaysForm.value == this.itemRec.getValue("maxStockDaysFnEdit")) {
      this.updateOrderStop();
      return;
    };

    var minZai = this.minZaiForm.value;
    var maxZai = this.maxZaiForm.value;
    var minStockDays = this.minStockDaysForm.value;
    var maxStockDays = this.maxStockDaysForm.value;
    var autoOrderLot = this.autoOrderLotModForm.value;

    var reqParamItemEdit: ReqParamItemEdit = {
      access: {...this.commonService.loginUser},
      paramItemEdit: {
        storeCdFv:              this.itemRec.storeCdFv,
        itemCdFv:               this.itemRec.itemCdFv,
        minZaiIsNull:           this.itemRec.minZaiIsNull,
        minZaiFn:               this.itemRec.minZaiFn,
        minZaiModIsNull:        this.itemRec.minZaiModIsNull,
        minZaiModFn:            -1, // this.itemRec.minZaiModFn,
        minZaiVersionFn:        this.itemRec.minZaiVersionFn,
        maxZaiIsNull:           this.itemRec.maxZaiIsNull,
        maxZaiFn:               this.itemRec.maxZaiFn,
        maxZaiModIsNull:        this.itemRec.maxZaiModIsNull,
        maxZaiModFn:            -1, // this.itemRec.maxZaiModFn,
        maxZaiVersionFn:        this.itemRec.maxZaiVersionFn,
        minStockDaysIsNull:     this.itemRec.minStockDaysIsNull,
        minStockDaysFn:         this.itemRec.minStockDaysFn,
        minStockDaysModIsNull:  this.itemRec.minStockDaysModIsNull,
        minStockDaysModFn:      -1, // this.itemRec.minStockDaysModFn,
        minStockDaysVersionFn:  this.itemRec.minStockDaysVersionFn,
        maxStockDaysIsNull:     this.itemRec.maxStockDaysIsNull,
        maxStockDaysFn:         this.itemRec.maxStockDaysFn,
        maxStockDaysModIsNull:  this.itemRec.maxStockDaysModIsNull,
        maxStockDaysModFn:      -1, // this.itemRec.maxStockDaysModFn,
        maxStockDaysVersionFn:  this.itemRec.maxStockDaysVersionFn,
        autoOrderLotFn:         this.itemRec.autoOrderLotFn,
        autoOrderLotModFn:      -1,
        autoOrderLotModVersionFn: this.itemRec.autoOrderLotModVersionFn
      }
    };

    if (minZai != this.itemRec.getValue("minZaiFnEdit")) {
      reqParamItemEdit.paramItemEdit.minZaiModFn = this.paramToNumber(minZai);
      reqParamItemEdit.paramItemEdit.minZaiModIsNull = this.paramIsNull(minZai);
    }
    if (maxZai != this.itemRec.getValue("maxZaiFnEdit")) {
      reqParamItemEdit.paramItemEdit.maxZaiModFn = this.paramToNumber(maxZai);
      reqParamItemEdit.paramItemEdit.maxZaiModIsNull = this.paramIsNull(maxZai);
    }
    if (minStockDays != this.itemRec.getValue("minStockDaysFnEdit")) {
      reqParamItemEdit.paramItemEdit.minStockDaysModFn = this.paramToNumber(minStockDays);
      reqParamItemEdit.paramItemEdit.minStockDaysModIsNull = this.paramIsNull(minStockDays);
    }
    if (maxStockDays != this.itemRec.getValue("maxStockDaysFnEdit")) {
      reqParamItemEdit.paramItemEdit.maxStockDaysModFn = this.paramToNumber(maxStockDays);
      reqParamItemEdit.paramItemEdit.maxStockDaysModIsNull = this.paramIsNull(maxStockDays);
    }
    if (autoOrderLot != this.itemRec.getValue("autoOrderLotFnEdit")) {
      reqParamItemEdit.paramItemEdit.autoOrderLotModFn = this.paramToNumber(autoOrderLot);
    }

    this.subscriptionQuery = this.httpBasic.updateItemParam(reqParamItemEdit).subscribe(
      response => this.receiveParam(response),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  paramToNumber(val: string) {
    return val === "" ? 0 : parseInt(val);
  }
  paramIsNull(val: string) {
    return val === "" ? true : false;
  }
  receiveParam(response: RspParamItemEdit) {
    if (this.httpBasic.handleAppError(response)) {
      this.clearProgressState();
      return;
    }
    this.itemRec.minZaiModFn = response.result.minZaiModFn;
    this.itemRec.minZaiVersionFn = response.result.minZaiVersionFn;
    this.itemRec.maxZaiModFn = response.result.maxZaiModFn;
    this.itemRec.maxZaiVersionFn = response.result.maxZaiVersionFn;
    this.itemRec.minStockDaysModFn = response.result.minStockDaysModFn;
    this.itemRec.minStockDaysVersionFn = response.result.minStockDaysVersionFn;
    this.itemRec.maxStockDaysModFn = response.result.maxStockDaysModFn;
    this.itemRec.maxStockDaysVersionFn = response.result.maxStockDaysVersionFn;
    this.itemRec.autoOrderLotModFn = response.result.autoOrderLotModFn;
    this.itemRec.autoOrderLotModVersionFn = response.result.autoOrderLotModVersionFn;
    this.updateOrderStop();
  }

  updateOrderStop() {
    if (parseInt(this.orderStopForm.value) == this.itemRec.getValue("orderStopFlagFnValue")) {
      this.clearProgressState();
      if (this.isAdjusted) {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, "締め時間を超えたため、翌発注日に調整されました。");
      }
        return;
    };

    var request : ReqOrderStopItemEdit = {
      access: {...this.commonService.loginUser},
      orderStopFlag : {
        storeCdFv: this.itemRec.storeCdFv,
        itemCdFv: this.itemRec.itemCdFv,
        orderStopFlagFn: this.itemRec.orderStopFlagFn,
        orderStopFlagModFn: parseInt(this.orderStopForm.value),
        autoOrderLotModFn: this.autoOrderLotModForm.value,
        orderStopFlagVersionFn: this.itemRec.orderStopFlagVersionFn
      }
    }
    this.subscriptionQuery = this.httpBasic.updateItemOrderStop(request).subscribe(
      response => this.receiveOrderStop(response),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }
  receiveOrderStop(response: RspOrderStopItemEdit) {
    this.commonService.closeSpinner();
    if (this.isAdjusted) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, "締め時間を超えたため、翌発注日に調整されました。");
    }
    if (this.httpBasic.handleAppError(response)) {
      this.clearProgressState();
      return;
    }

    var orderStopItemResultDto: OrderStopItemResultDto;
    orderStopItemResultDto = response.result;
    this.itemRec.orderStopFlagModFn = orderStopItemResultDto.orderStopFlagModFn;
    this.itemRec.orderStopFlagVersionFn = orderStopItemResultDto.orderStopFlagVersionFn;
    this.itemRec.autoOrderLotModFn = orderStopItemResultDto.autoOrderLotModFn;
  }

  doCancel() {
    this.setCurrentValue();
    this.keypadTargetId = undefined;
    this.numberKeypadComponent.inputField = undefined;
  }

  setCurrentValue() {
    if (!this.itemRec) return;
    this.orderForm.setValue(this.itemRec.getValue("orderNumFn"));
    let enabled = this.orderBaraForm.enabled;
    if (enabled) this.orderBaraForm.disable({emitEvent: false});
    this.orderBaraForm.setValue(this.itemRec.getValue("orderNumFn") * this.itemRec.orderLotFn);
    if (enabled) this.orderBaraForm.enable({emitEvent: false});
    this.stockForm.setValue(this.itemRec.getValue("stockNumFn"));
    this.minZaiForm.setValue(this.itemRec.getValue("minZaiFnEdit"));
    this.maxZaiForm.setValue(this.itemRec.getValue("maxZaiFnEdit"));
    this.minStockDaysForm.setValue(this.itemRec.getValue("minStockDaysFnEdit"));
    this.autoOrderLotModForm.setValue(this.itemRec.getValue("autoOrderLotFnEdit"));
    this.maxStockDaysForm.setValue(this.itemRec.getValue("maxStockDaysFnEdit"));
    this.orderStopForm.setValue(this.itemRec.getValue("orderStopFlagFnValue"));
    //this.autoOrderLotFn = this.itemRec.autoOrderLotFn;
    this.formGroup.markAsPristine();
  }

  calcServerTime() {
    let server = new Date();
    let device = new Date();
    server.setTime(device.getTime() + this.timeLag);

    return server;
  }

  setClosingTimer() {
    this.clearTimer();
    let closingTime: Date = this.propClosingTime;
    if (this.itemRec.closingTime > 0) {
      let h = Math.floor(this.itemRec.closingTime / 100);
      let m = this.itemRec.closingTime % 100;
      closingTime = new Date();
      closingTime.setHours(h, m, 0);
    }

    let now = this.calcServerTime();
    if (closingTime.getTime() < now.getTime()) return;
    this.startTimer(closingTime.getTime() - now.getTime());
  }

  startTimer(timeout: number) {
    this.clearTimer();
    this.timerId = setTimeout(
      () => {
        let serverTime = this.calcServerTime();
        this.itemRec.checkClosingTime(this.propClosingTime, serverTime);
      }, timeout);
  }

  clearTimer() {
    if (this.timerId < 0) return;
    clearTimeout(this.timerId);
    this.timerId = -1;
  }

  orderDateBgColor() {
    if (!this.itemRec) return 1;
    if (this.isOrderEditableItem == false) return 1;      // 背景色 無し

    let orderStop: number = this.itemRec.getValue("orderStopFlagFnValue");
    let def = this.commonService.config.orderStopFlag.find((item) => item.value === orderStop);
    if (def == undefined) return 1;                       // 発注禁止 背景色 無し
    if (this.commonService.config.orderEditCaseItem) {
      if (!def.canOrder && !def.extendedOrder &&
          !def.caseItem && !def.canCaseOrder) return 1;  // 発注禁止 背景色 無し
    } else {
      if (!def.canOrder && !def.extendedOrder) return 1;  // 発注禁止 背景色 無し
    }
    if (!this.itemRec.orderableWeekday()) {
      if (this.itemRec.orderableDateFv !== "0000000")
        return 2;                                         // 自動発注 発注不可曜日
    }
    if (!this.itemRec.isClosed) return 1;                 // 自動発注 発注可能曜日、締め前
    return 2;                                             // 自動発注 発注可能曜日、締め後
  }

  orderBaraBgColor() {
    if (!this.itemRec) return 0;
    if (this.commonService.config.orderEditInBara === false) return;
    if (this.orderBaraForm.value === "" || this.orderBaraForm.value === 0) return 0;
    if (parseInt(this.orderBaraForm.value) !== parseInt(this.orderForm.value) * this.itemRec.orderLotFn) return 1;
    return 0;
  }

  focusOrderNum(id: string, form: FormControl) {
    // this.orderErrorMessage = "";
    let orderableDateFv = this.itemRec.orderableDateFv;
    if (orderableDateFv === "0000000") {
      this.orderErrorMessage = "発注が可能な曜日の設定がありません。";
      let d = new Date();
      if (this.itemRec.isClosed) {
        d.setDate(d.getDate() + 1);
      }
      this.itemRec.orderDate = this.commonService.formatDate(d);
      d.setDate(d.getDate() + 1);
      this.itemRec.deliveryDate = this.commonService.formatDate(d);
      this.itemRec.orderDateNext = this.commonService.formatDate(d);
      d.setDate(d.getDate() + 1);
      this.itemRec.deliveryDateNext = this.commonService.formatDate(d);
      if (!this.itemRec.isClosed) {
        let serverTime = this.calcServerTime();
        this.itemRec.checkClosingTime(this.propClosingTime, serverTime);
      }
    }

    this.set10KeyPadTarget(id, form)
  }

  blurOrderNum() {
    // this.orderErrorMessage = "";
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          this.inputItemCdForm.setValue(data);
          this.btnQuery();
        }
      }
    )
  }

  canDeactivate() {
    if (!this.isValueChanged()) return true;

    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}

