import { CommonService } from '../service/common.service';
import { RspCtgHistDto } from '../response/rsp-ctg-hist-search';

export class StoreGroupNameRec {
    storeGroupCd: number;
    storeGroupName: string;
    storeGroupTypeCd: number;

    /*
    constructor(private ctgHistDto: RspCtgHistDto, private commonService: CommonService) {
        for (var key of Object.keys(ctgHistDto)) {
            this[key] = ctgHistDto[key];
        }
    }
    */
}