import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CsvData, CsvLineData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

@Component({
  selector: 'app-csv-reader',
  templateUrl: './csv-reader.component.html',
  styleUrls: ['./csv-reader.component.css']
})
export class CsvReaderComponent implements OnInit, OnDestroy {

  public filename: string = "";
  file: File;
  public fileResult: string = "";
  public isError: boolean = false;
  public formFile: FormControl = new FormControl();
  private subscriptionFile: Subscription;

  @Input("columnDef") columnDef: TableColumnDef[];
  @Input("layoutComment") layoutComment: string;
  @Output("csvData") csvData: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private router: Router
  ) { }

  ngOnInit() {
    this.subscriptionFile = this.formFile.valueChanges.subscribe(
      () => {this.fileChanged();}
    );
  }

  ngOnDestroy() {
    if (this.subscriptionFile) this.subscriptionFile.unsubscribe();
  }

  fileChanged() {
    let fileInput = document.getElementById('input-file')  as HTMLInputElement;
    if (fileInput.files[0]) {
      this.filename = fileInput.files[0].name;
      this.file = fileInput.files[0];
      this.isError = false;
    } else {
      this.filename = this.file.name;
    }
  }

  fileDialog() {
    let elem = document.getElementById("input-file") as HTMLInputElement;
    elem.value = "";
    elem.click();
  }

  isDisabled() {
    // let fileInput = document.getElementById('input-file')  as HTMLInputElement;
    // return fileInput.files.length == 0 || this.isError;
    return !this.file || this.isError || (this.router.url == '/csvImportDeadlineMaster' && this.file.type != 'text/csv');

  }

  getFilename() {
    let fileInput = document.getElementById('input-file')  as HTMLInputElement;
    if (fileInput.files.length == 0) return "";
    return fileInput.files[0].name;
  }

  readCsv() {
    // let fileInput = document.getElementById('input-file') as HTMLInputElement;
    // if (fileInput.files.length == 0) return;
    if (!this.file) return;

    let codeset: string = "UTF-8";
    let ua = navigator.userAgent;
    if (ua.indexOf('Windows') > 0) {
      codeset = "SJIS";
    }

    // let file = fileInput.files[0];
    let fileReader = new FileReader();
    this.commonService.openSpinner(this.commonService.pageTitle, "読込み中・・・")
    fileReader.onload = () => {
      this.readComplete(fileReader);
    }
    fileReader.onerror = () => {
      this.isError = true;
      this.commonService.closeSpinner();

      if (fileReader.error.name == "NotReadableError") {
        this.commonService.openErrorDialog(this.commonService.pageTitle,
          "ファイルの読み込みに失敗しました。<br><br>" +
          "ファイル選択後にファイルが変更されました。<br>" +
          "ファイルを再度選択してください。"
          );
        return;
      }
      if (fileReader.error.name == "NotFoundError") {
        this.commonService.openErrorDialog(this.commonService.pageTitle,
          "ファイルの読み込みに失敗しました。<br><br>" +
          "ファイルが見つかりません。<br>" +
          "ファイルを再度選択してください。"
          );
        return;
      }
      this.commonService.openErrorDialog(this.commonService.pageTitle, "ファイルの読み込みに失敗しました。<br><br>"+fileReader.error);
      return;
    };
    // fileReader.readAsText(this.file, "SJIS");
    fileReader.readAsText(this.file, codeset);
  }

  readComplete(fileReader: FileReader) {

    this.fileResult = fileReader.result.toString().replace("\r", "");
    let alllines = this.fileResult.split("\n");
    let lines:CsvLineData[] = [];
    for (let i = 0; i < alllines.length; i++) {
      if (i === 0 && !this.commonService.config.isReadCsvFirstRow) {
        continue;
      }
      let line = alllines[i];
      if (line.trim() === "") continue;

      let csvLineData: CsvLineData = {lineNumber: i+1, columnData: new Object(), errorMsg:[], warningMsg:[]};
      let cells = line.split("\t");

      for (let j = 0; j < this.columnDef.length; j++) {
        if (cells.length > j) {
          let val = this.cutTo99bytes(cells[j].trim(), this.columnDef[j].csvByteLen);
          // val = val.slice(0, 99);
          csvLineData.columnData[this.columnDef[j].columnId] = val;
        } else {
          csvLineData.columnData[this.columnDef[j].columnId] = "";
        }
      }
      lines.push(csvLineData);
    }
    this.commonService.closeSpinner();

    let csvData: CsvData = {errorCount: undefined, warningCount: undefined, lines: lines};
    this.csvData.emit(csvData);
  }

  cutTo99bytes(str: string, csvByteLen: number) {
    let bytelen: number = 0;
    let result: string = "";
    for (let i = 0; i < str.length; i++) {
      let charCode = str.charCodeAt(i);
      let len = 1;
      if (charCode <= 0x7f) {
        len = 1;
      } else if (charCode <= 0x07ff) {
        len = 2;
      } else {
        len = 3;
      }
      if (csvByteLen && bytelen + len <= csvByteLen) {
        result += str.charAt(i);
        bytelen += len;
      } else if (bytelen + len <= 200) {
        result += str.charAt(i);
        bytelen += len;
      } else {
        return result;
      }
    }
    return result;
  }

  layout() {
    let msg = "CSVレイアウト<br>";
    if (this.layoutComment) {
      msg += "<br>" + this.layoutComment + "<br>";
    }
    /*
    for (let i = 0; i < this.columnDef.length; i++) {
      msg += "<br>" + (i + 1) + ":" + this.columnDef[i].header;
      if (this.columnDef[i].csvComment) {
        msg += "&nbsp;" + this.columnDef[i].csvComment;
      }
    }
    */
    msg += "<br><table class='no-border'>";
    for (let i = 0; i < this.columnDef.length; i++) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>" + (i + 1) + "</td>";
      msg += "<td class='no-border'>" + this.columnDef[i].header + "</td>";
      if (this.columnDef[i].csvComment) {
        msg += "<td class='no-border'>" + this.columnDef[i].csvComment + "</td>";
      } else {
        msg += "<td class='no-border'></td>";
      }
      msg += "</tr>";
    }
    msg += "</table>";

    this.commonService.openNotificationDialog(this.commonService.pageTitle, msg);
  }

}
