import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { CommonService } from "src/app/service/common.service";

export interface ExpiryMasterDateDef {
  datesToExpiry:    number;
  action:           string;
  discount?:        number;
}
export interface ExpiryMasterDateEdit {
  datesToExpiryForm:    FormControl;
  actionForm:           FormControl;
  discountForm?:        FormControl;
}

export class ExpiryMasterRec {
  private maxDefCount: number = 5;

  public storeCd:       string = "";                    // ""=全店
  public ctgLevel:      number = 0;                     // 0 to 3
  public ctgCds:        string[] = ["", "", "", ""];
  public ctgNames:      string[] = ["", "", "", ""];
  public expiryType:    number = 1;                     // 1: 賞味期限  2: 製造年月日
  public discountType:  number = 1;                     // 1: 値引率  2: 値引額
  public dateDef:       ExpiryMasterDateDef[] = [];
  public children:      ExpiryMasterRec[] = [];
  public isOpen:        boolean = true;
  public edit: {
    isDirty:      boolean;
    isNew:        boolean;
    isOpen:       boolean;
    expiryType:   FormControl;
    discountType: FormControl;
    delForm:      FormControl;
    dateDef:      ExpiryMasterDateEdit[];
  };

  private valueCheckNoticed: boolean = false;

  private subsc: Subscription[] = [];
  private changeListener: ()=>void;

  constructor(private commonService: CommonService) {
  }

  setListner(func:()=>void) {
    this.changeListener = func;
  }

  prepareEdit(defaultShowLevel?: number) {
    if (defaultShowLevel != undefined) this.isOpen = this.ctgLevel <= defaultShowLevel;
    this.edit = {
      isDirty:      false,
      isNew:        false,
      isOpen:       this.isOpen,
      expiryType:   new FormControl("" + this.expiryType),
      discountType: new FormControl("" + this.discountType),
      delForm:    new FormControl(false),
      dateDef:  []
    };

    for (let i = 0; i < this.maxDefCount; i++) {
      let defEdit: ExpiryMasterDateEdit = {
        datesToExpiryForm: new FormControl(""),
        actionForm: new FormControl(""),
        discountForm: new FormControl(0)
      };
      if (i < this.dateDef.length) {
        let dateDef: ExpiryMasterDateDef = this.dateDef[i];
        defEdit.datesToExpiryForm.setValue(dateDef.datesToExpiry == -1 ? "" : dateDef.datesToExpiry);
        defEdit.actionForm.setValue(dateDef.action);
        defEdit.discountForm.setValue(dateDef.discount);
      }
      this.edit.dateDef.push(defEdit);
      this.subsc.push(this.edit.delForm.valueChanges.subscribe((value) => this.delChanged(value)));
      this.subsc.push(defEdit.datesToExpiryForm.valueChanges.subscribe((value) => this.datesChanged(defEdit.datesToExpiryForm, value)));
      this.subsc.push(defEdit.actionForm.valueChanges.subscribe((value) => this.actionChanged(defEdit.actionForm, value)));
      this.subsc.push(defEdit.discountForm.valueChanges.subscribe((value) => this.discountChanged(defEdit.discountForm, value)));
      this.subsc.push(defEdit.discountForm.valueChanges.pipe(
        startWith(defEdit.discountForm.value),
        pairwise()
      ).subscribe(([prevVal, newVal]) => {
          this.valueCheckDiscount(defEdit.discountForm, prevVal, newVal);
      }));
      this.subsc.push(this.edit.expiryType.valueChanges.subscribe(() => {
        this.expiryTypeChanged();
      }));
      this.subsc.push(this.edit.discountType.valueChanges.subscribe(() => {
        this.valueCheckNoticed = false;
        this.resetInvalidValue();
        this.discountTypeChanged();
      }));
    }
  }

  addChild(rec: ExpiryMasterRec): boolean {
    if (rec.ctgLevel <= this.ctgLevel) return false;
    for (let i = 0; i <= this.ctgLevel; i++) {
      if (rec.ctgCds[i] !== this.ctgCds[i]) return false;
    }
    if (rec.ctgLevel == this.ctgLevel + 1) {
      this.children.push(rec);
      return true;
    } else {
      for (let i = 0; i < this.children.length; i++) {
        if (this.children[i].addChild(rec)) return true;
      }
      return false;
    }
  }

  isChild(rec: ExpiryMasterRec): boolean {
    if (rec.ctgLevel <= this.ctgLevel) return false;
    for (let i = 0; i < this.ctgLevel; i++) {
      if (rec.ctgCds[i] !== this.ctgCds[i]) return false;
    }
    return true;
  }

  hasDef() {
    if (!this.edit) return false;
    for (let i = 0; i < this.edit.dateDef.length; i++) {
      let dates = this.edit.dateDef[i].datesToExpiryForm.value;
      if (dates === "" || dates === 0) continue;
      let action = this.edit.dateDef[i].actionForm.value;
      if (action === "") continue;
      return true;
    }
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].hasDef()) return true;
    }
    return false;
  }

  endEdit() {
    this.cleanupEdit();
  }

  clearEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  saveEdit() {

  }

  cleanupEdit() {
    this.subsc.forEach((subsc) => {subsc.unsubscribe();});
    this.subsc = [];
    delete this.edit;
  }

  isDirty() {
    if (!this.edit) return false;
    return this.edit.isDirty;
  }

  delChanged(value) {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  datesChanged(form: FormControl, value) {
    if (!this.edit) return;
    this.edit.isDirty = true;
    /*
    if (isNaN(value) || parseInt(value) == 0) {
      form.setValue("");
    }
    */
    for (let i = 0; i < this.edit.dateDef.length; i++) {
      this.edit.dateDef[i].datesToExpiryForm.setErrors(null);
    }
    let err = {"invalidValue": "値が重複しています。"};
    for (let i = 0; i < this.edit.dateDef.length; i++) {
      if (this.edit.dateDef[i].datesToExpiryForm.value === "") continue;
      /*
      if (parseInt(this.edit.dateDef[i].datesToExpiryForm.value) == 0) {
        this.edit.dateDef[i].datesToExpiryForm.setErrors(err);
      }
      */
      for (let j = i + 1; j < this.edit.dateDef.length; j++) {
        if (this.edit.dateDef[j].datesToExpiryForm.value === "") continue;
        if (parseInt(this.edit.dateDef[i].datesToExpiryForm.value) !=
            parseInt(this.edit.dateDef[j].datesToExpiryForm.value)) continue;
        this.edit.dateDef[i].datesToExpiryForm.setErrors(err);
        this.edit.dateDef[j].datesToExpiryForm.setErrors(err);
      }
    }
    if (this.changeListener) this.changeListener();
  }

  actionChanged(form: FormControl, value) {
    if (!this.edit) return;
    this.edit.isDirty = true;
    /*
    if (isNaN(value) || parseInt(value) == 0) {
      form.setValue("");
    }
    */
    if (this.changeListener) this.changeListener();
  }

  discountChanged(form: FormControl, value) {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  getValue(key: string) {
    switch(key) {
      case "ctg0":
      case "ctg1":
      case "ctg2":
      case "ctg3":
        let level = parseInt(key.substring(3));
        if (level > this.ctgLevel) return "";
        if (this.ctgCds[level] == "") return "";
        if (this.ctgNames[level] == "") return "";
        return this.ctgCds[level] + "：" + this.ctgNames[level];
      default:
        return "";
    }
  }

  getRadioName(base: string) {
    let name = base;
    for (let i = 0; i < 4; i++) {
      name += ":" + this.ctgCds[i];
    }
    return name;
  }

  getDiscountLabel() {
    switch(this.edit.discountType.value) {
      case "1": {
        return {pre: "値引率", post: "%"};
      }
      case "2": {
        return {pre: "値引額", post: "円"};
      }
    };
    return {pre: "値引率", post: "%"};
  }

  expiryTypeChanged() {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  discountTypeChanged() {
    if (!this.edit) return;
    this.edit.isDirty = true;
    if (this.changeListener) this.changeListener();
  }

  resetInvalidValue() {
    this.edit.dateDef.forEach((dateDef) => {
      switch(this.edit.discountType.value) {
        case "1": {
          if (dateDef.discountForm.value > 99) dateDef.discountForm.setValue(0);
          return;
        }
        case "2": {
          return;
        }
        case "3": {
          return;
        }
      }
    });
  }
  
  /*
  valueCheckDiscount(form: FormControl, prevVal: number, newVal: number) {
    if (prevVal <= 99 && newVal > 99) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "値引率に99を超える値は入力できません。");
      form.setValue(prevVal);
      return;
    }
  }
  */

  valueCheckDiscount(form: FormControl, prevVal: number, newVal: number) {
    switch(this.edit.discountType.value) {
      case "1": {
        if (newVal > 99) {
          if (this.valueCheckNoticed == false) {
            this.commonService.openErrorDialog(this.commonService.pageTitle, "値引率に99を超える値は入力できません。");
            form.setValue(prevVal);
            this.valueCheckNoticed = true;
          }
        } else {
          this.valueCheckNoticed = false;
        }
        return;
      }
      case "2": {
        /*
        if (newVal > this.salesPrice) {
          if (this.valueCheckNoticed == false) {
            this.commonService.openErrorDialog(this.commonService.pageTitle, `値引額に ${this.salesPrice} 円を超える値は入力できません。`);
            form.setValue(prevVal);
            this.valueCheckNoticed = true;
          }
        } else {
          this.valueCheckNoticed = false;
        }
        */
        this.valueCheckNoticed = false;
        return;
      }
      case "3": {
        this.valueCheckNoticed = false;
        return;
      }
    }
    this.valueCheckNoticed = false;
  }
}
