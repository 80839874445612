import { formatDate } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { GetOrderGroupUserItemDto, GetOrderGroupUserSingleDto, ReqGetOrderGroupUserSingle, ReqUpdateOrderGroupUserDto, RspGetOrderGroupUserSingle, RspUpdateOrderGroupUser } from 'src/app/webservice/GetOrderGroupUserSingle';
import { ReqUpdateOrderGroupUser } from '../../webservice/GetOrderGroupUserSingle';
import { OrderGroupUserSingle } from './order-group-user-single';

interface userListItem {
  value: string;         // JSON format
  userName: string;
}

@Component({
  selector: 'app-order-group-user-single',
  templateUrl: './order-group-user-single.component.html',
  styleUrls: ['./order-group-user-single.component.css']
})
export class OrderGroupUserSingleComponent implements OnInit, OnDestroy, AfterViewChecked {

  public storeForm: FormControl = new FormControl(this.commonService.loginUser.storeCd);
  public orderGroupList: OrderGroupUserSingle[];
  public orderGroupListDisp: OrderGroupUserSingle[];
  public recordCount: number = 0;
  public columnIds: string[] = ["effectiveDate", "groupUsrCd", "groupName", "dateBeginActive", "userNameActive", "dateBeginNext", "userNameNext"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "effectiveDate", header: "有効日", width: 80, align: "center" },
    { columnId: "groupUsrCd", header: "コード", width: 80, align: "left" },
    { columnId: "groupName", header: this.commonService.literal["orderGroup"], width: 160, align: "left" },
    { columnId: "dateBeginActive", header: "開始日(現)", width: 80, align: "center" },
    { columnId: "userNameActive", header: "担当者(現)", width: 160, align: "left" }
    // {columnId: "dateBeginNext", header: "開始日(次)", width: 40, align: "center"},
    // {columnId: "userNameNext", header: "担当者(次)", width: 200, align: "left"}
  ];
  public userList: userListItem[];
  public isError: boolean = false;

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  /* param for change group user */
  public groupUserChangeMap: Map<string, ReqUpdateOrderGroupUserDto> = new Map();
  public groupUserFormSubscriptions: Subscription[] = [];
  /* end param for change group user */

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
  }

  ngOnDestroy(): void {
    this.unSubscriptionGroupUserForm();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  doQuery() {
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.doQueryBody();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.doQueryBody();
        }
      }
    );
  }

  doQueryBody() {
    this.recordCount = 0;
    if (this.matPaginator?.pageIndex) this.matPaginator.pageIndex = 0;
    this.orderGroupList = [];
    this.orderGroupListDisp = [];
    this.unSubscriptionGroupUserForm();
    this.orderCancel();

    let request: ReqGetOrderGroupUserSingle = {
      access: this.commonService.loginUser,
      storeCd: this.storeForm.value
    };
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderGroupUserSingle", request).subscribe(
      (response: RspGetOrderGroupUserSingle) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) return;
        this.initUserList(response.userList);
        this.initOrderGroupList(response.groupList);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  initUserList(users: GetOrderGroupUserItemDto[]) {
    this.userList = [];
    users.forEach((user) => {
      let item: userListItem = {
        value: JSON.stringify(user),
        userName: user.userName + "[" + user.userId + "]"
      };
      this.userList.push(item);
    });
  }

  initOrderGroupList(groups: GetOrderGroupUserSingleDto[]) {
    this.orderGroupList = [];

    groups.forEach((dto) => {
      let group = new OrderGroupUserSingle();
      group.storeCd = dto.storeCd;
      group.groupId = dto.groupId;
      group.effectiveDate = dto.beginDate;
      group.endDate = dto.endDate;
      group.groupUsrCd = dto.groupUsrCd;
      group.groupName = dto.groupName;
      group.userIdActive = dto.userIdActive;
      group.userNameActive = dto.userNameActive;
      group.dateBeginActive = dto.dateBeginActive;
      group.dateEndActive = dto.dateEndActive;
      group.userIdNext = dto.userIdNext;
      group.userNameNext = dto.userNameNext;
      group.dateBeginNext = dto.dateBeginNext;
      group.dateEndNext = dto.dateEndNext;
      group.init();
      this.orderGroupList.push(group);
    });
    this.recordCount = this.orderGroupList.length;
    this.setDispayList();
    this.initHandleChangeGroupUserFrom();
  }

  setDispayList() {
    this.orderGroupListDisp = [];

    let pageSize: number;
    let pageIndex: number;
    if (this.matPaginator) {
      pageSize = this.matPaginator.pageSize;
      pageIndex = this.matPaginator.pageIndex;
    } else {
      pageSize = this.commonService.paginatorOption.pageSizeOptions[0];
      pageIndex = 0;
    }

    let count = 0;
    for (let i = pageIndex * pageSize; i < this.recordCount && count < pageSize; i++) {
      this.orderGroupListDisp.push(this.orderGroupList[i]);
      count++;
    }
  }

  pageChanged() {
    this.setDispayList();
  }

  checkError() {
    this.isError = false;
    for (let group of this.orderGroupList) {
      if (group.isError()) {
        this.isError = true;
        return;
      }
    }
  }

  orderGUUpdate() {
    const request: ReqUpdateOrderGroupUser = {
      access: this.commonService.loginUser,
      updateList: Array.from(this.groupUserChangeMap, (([name, value]) => value)) || []
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdateOrderGroupUser", request).subscribe(
      (response: RspUpdateOrderGroupUser) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiverOrderGUUpdate(request, response);
      },
      (error) => {
        subsc.unsubscribe();
        this.orderCancel()
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiverOrderGUUpdate(request: ReqUpdateOrderGroupUser, response: RspUpdateOrderGroupUser) {
    this.httpBasic.handleAppError(response)
    this.unSubscriptionGroupUserForm();

    if (response?.result?.length > 0) {
      for (const resRow of response.result) {
        const updated = this.orderGroupList.find(item => (resRow.groupId === item.groupId && resRow.storeCd === item.storeCd));
        if (!updated) continue;
        updated.userIdActive = resRow.userIdActive
        updated.userNameActive = resRow.userNameActive
        updated.dateBeginActive = resRow.dateBeginActive;
        updated.dateEndActive = resRow.dateEndActive;

        updated.userIdNext = resRow.userIdNext;
        updated.userNameNext = resRow.userNameNext;
        updated.dateBeginNext = resRow.dateBeginNext;
        updated.dateEndNext = resRow.dateEndNext;
        updated.init();
      }
    }
    this.orderCancel();
  }

  orderCancel() {
    this.unSubscriptionGroupUserForm();
    this.orderGroupList.forEach((group) => { group.cancel(); });
    this.groupUserChangeMap.clear();
    this.isError = false;
    this.cdRef.detectChanges();
    this.initHandleChangeGroupUserFrom();
  }

  canDeactivate() {
    if (this.groupUserChangeMap.size <= 0) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  initHandleChangeGroupUserFrom() {
    this.unSubscriptionGroupUserForm();
    this.orderGroupList.forEach(item => {
      this.groupUserFormSubscriptions.push(item.dateBeginNextForm.valueChanges.subscribe(data => {
        this.handlerChangeDateBeginNextForm(item);
        this.checkError();
      }))
      this.groupUserFormSubscriptions.push(item.userJSONNextForm.valueChanges.subscribe(data => {
        this.handlerChangeUserJSONNextForm(item);
        this.checkError();
      }))
    })
  }

  unSubscriptionGroupUserForm() {
    if (!this.groupUserFormSubscriptions || this.groupUserFormSubscriptions.length <= 0) return;
    this.groupUserFormSubscriptions.forEach(sub => {
      sub.unsubscribe();
    })
    this.groupUserFormSubscriptions = []
  }

  handlerChangeDateBeginNextForm(item: OrderGroupUserSingle) {
    const key = `${item.groupId}_${item.storeCd}`;

    if (this.isChangedDateBeginNext(item)) {
      if (this.groupUserChangeMap.has(key)) {
        const updateMapItem = this.groupUserChangeMap.get(key);
        updateMapItem.dateBeginNext = item?.dateBeginNextForm?.value ? formatDate(item?.dateBeginNextForm?.value, 'yyyy/MM/dd', 'en_US') : "";
        return
      }
      let userNextJson: GetOrderGroupUserItemDto
      try {
        userNextJson = JSON.parse(item.userJSONNextForm.value);
      } catch (error) { }

      const newMapItem: ReqUpdateOrderGroupUserDto = {
        storeCd: item.storeCd,
        groupId: item.groupId,
        groupUsrCd: item.groupUsrCd,

        userIdActive: item.userIdActive || "",
        userNameActive: item.userNameActive || "",
        dateBeginActive: item?.dateBeginActive || "",

        userIdNext: userNextJson?.userId || "",
        userNameNext: userNextJson?.userName || "",
        dateBeginNext: item?.dateBeginNextForm?.value ? formatDate(item?.dateBeginNextForm?.value, 'yyyy/MM/dd', 'en_US') : "",
      };
      this.groupUserChangeMap.set(key, newMapItem);
      return;
    }

    if (this.groupUserChangeMap.has(key)) {
      if (!this.isChangedUserJSONNext(item)) {
        this.groupUserChangeMap.delete(key);
      }
    }
  }

  handlerChangeUserJSONNextForm(item: OrderGroupUserSingle) {
    const key = `${item.groupId}_${item.storeCd}`;

    if (this.isChangedUserJSONNext(item)) {
      let userNextJson: GetOrderGroupUserItemDto
      try {
        userNextJson = JSON.parse(item.userJSONNextForm.value);
      } catch (error) { }

      if (this.groupUserChangeMap.has(key)) {
        const updateMapItem = this.groupUserChangeMap.get(key);
        updateMapItem.userIdNext = userNextJson?.userId || ""
        updateMapItem.userNameNext = userNextJson?.userName || ""
        return
      }

      const newMapItem: ReqUpdateOrderGroupUserDto = {
        storeCd: item.storeCd,
        groupId: item.groupId,
        groupUsrCd: item.groupUsrCd,

        userIdActive: item.userIdActive || "",
        userNameActive: item.userNameActive || "",
        dateBeginActive: item?.dateBeginActive || "",

        userIdNext: userNextJson?.userId || "",
        userNameNext: userNextJson?.userName || "",
        dateBeginNext: item?.dateBeginNextForm?.value ? formatDate(item?.dateBeginNextForm?.value, 'yyyy/MM/dd', 'en_US') : "",
      };
      this.groupUserChangeMap.set(key, newMapItem);
      return;
    }

    if (this.groupUserChangeMap.has(key)) {
      if (!this.isChangedDateBeginNext(item)) {
        this.groupUserChangeMap.delete(key);
      }
    }
  }

  isChangedDateBeginNext(item: OrderGroupUserSingle): boolean {
    return (item?.dateBeginNext || "") !== (item?.dateBeginNextForm?.value || "");
  }

  isChangedUserJSONNext(item: OrderGroupUserSingle): boolean {
    return (item?.userJSONNext || "") !== (item?.userJSONNextForm?.value || "");
  }
}
