import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqSpmt10271Search } from 'src/app/request/req-spmt10271-search';
import { DropdownListOfUserDto } from 'src/app/response/rsp-get-dropdown-list-of-user';
import { GetStoreListDto, RspGetStoreList } from 'src/app/response/rsp-get-store-list';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSpmt10271Init } from 'src/app/request/req-spmt10271-init';
import { ReqStatusListDto, RspSpmt10271Init } from 'src/app/response/rsp-spmt10271-init';
import { Spmt10271Dto, RspSpmt10271Search } from 'src/app/response/rsp-spmt10271-search';
import { Spmt10271DetailDialogComponent } from 'src/app/dialog/spmt10271-detail-dialog/spmt10271-detail-dialog.component';
import { ReqSpmt10271Exclusion } from 'src/app/request/req-spmt10271-exclusion';
import { Spmt10271ApprovalDialogComponent } from 'src/app/dialog/spmt10271-approval-dialog/spmt10271-approval-dialog.component';
import { Spmt10271RejectedDialogComponent } from 'src/app/dialog/spmt10271-rejected-dialog/spmt10271-rejected-dialog.component';
import { Spmt10271GetDetailDto } from 'src/app/response/rsp-spmt10271-get-detail';
import { ReqAccess } from 'src/app/request/req-access';
import { ReqGetStoreList } from 'src/app/request/req-get-store-list';
import { Constant } from './spmt10271-approval-constant';
import { MessageService } from "src/app/service/message.service";
import * as ConstMessageID from 'src/app/common/const-message-id';
import { Rsp } from 'src/app/response/rsp';
import { ReqPageDto } from 'src/app/request/req-page-dto';

interface IMOATableSearch extends TableColumnDef {
  color?: string;
  checkFlg?: string;
}

class ItemSelect {
  check: FormControl;
  result: Spmt10271Dto;
}

@Component({
  selector: "app-spmt10271",
  templateUrl: "./spmt10271.component.html",
  styleUrls: ["./spmt10271.component.css"],
})

export class Spmt10271Component implements OnInit, OnDestroy {
  access: ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd: Constant.EMPTY_STRING,
    lang: Constant.EMPTY_STRING,
    storeCd: Constant.EMPTY_STRING
  };
  itemsShow: ItemSelect[] = [];
  items: ItemSelect[] = [];
  itemDetail: Spmt10271GetDetailDto[] = [];
  allChecked: boolean = false;
  itemRecordCount: number = 0;
  tableHeight: number = 400;

  // Message
  clearMsg = this.messageEntity.message[ConstMessageID.MQ200010];
  reqSttMsg = this.messageEntity.message[ConstMessageID.ME200012];
  doesNotExistMsg = this.messageEntity.message[ConstMessageID.MI100001];
  maxItemMsg = this.messageEntity.message[ConstMessageID.MI200003];

  columnId: string[] = ["checkStt", "slipNo", "inStoreCd", "inStoreNm", "reqStatus", "totalQty",
    "totalCostAmt", "slipCreationDate", "outRequesterCd", "outApproverCd", "inApproverCd", "commentHeader", "approverComment"]

  columnDefsItems: IMOATableSearch[] = [
    { columnId: "checkStt", header: "選択", width: 35, align: "center", checkbox: true },
    { columnId: "slipNo", header: "伝票番号", width: 80, align: "left", editable: true },
    { columnId: "inStoreCd", header: "入庫店コード", width: 80, align: "left" },
    { columnId: "inStoreNm", header: "入庫店名称", width: 100, align: "left" },
    { columnId: "reqStatus", header: "申請状態", width: 75, align: "left" },
    { columnId: "totalQty", header: "出庫数量合計", width: 77, align: "right", numberPipe: "1.0-0"},
    { columnId: "totalCostAmt", header: "出庫原価金額合計", width: 90, align: "right", numberPipe: "1.2-2"},
    { columnId: "slipCreationDate", header: "伝票作成日", width: 70, align: "center" },
    { columnId: "outRequesterCd", header: "出庫申請者", width: 75, align: "left" },
    { columnId: "outApproverCd", header: "出庫承認者", width: 75, align: "left" },
    { columnId: "inApproverCd", header: "入庫承認者", width: 75, align: "left" },
    { columnId: "commentHeader", header: "出庫申請者コメント", width: 150, align: "left" },
    { columnId: "approverComment", header: "出庫承認者コメント", width: 150, align: "left" }
  ];

  subcriptionYesNoDialog: Subscription;
  storeCdList: GetStoreListDto[] = [];
  reqStatusList: ReqStatusListDto[] | any[] = [];
  userList: DropdownListOfUserDto[] = [];
  isDisableSrchBtn: boolean = false;
  isDisableConfirmBtn: boolean = true;

  searchCondition: FormGroup;
  searchConditionSubscription: Subscription;
  dialogSubscription: Subscription;
  pickerDateBegin: MatDatepicker<null>;
  pickerDateEnd: MatDatepicker<null>;

  @ViewChild("matPagenatorItem", { static: false }) matPagenatorItem: MatPaginator;
  @ViewChild('slipNoInput', { static: true }) slipNoInput: ElementRef;
  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;
  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private messageEntity: MessageService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.searchConditionInit();
    this.fnGetAccessInfo();
  }

  searchConditionInit() {
    this.searchCondition = this.fb.group({
      outStoreCd: [this.storeCdList[3], Validators.required],
      inStoreCd: [],
      slipCreationDateBegin: [],
      slipCreationDateEnd: [],
      reqStatus: this.fb.array([]),
      slipNo: [Constant.EMPTY_STRING],
      outRequesterCd: [Constant.EMPTY_STRING],
      outApproverCd: [Constant.EMPTY_STRING],
      inApproverCd: [Constant.EMPTY_STRING],
      overwrite: [true]
    });
  }

  fnGetAccessInfo() {
    let req = {
      access: this.access
    };
    this.httpBasic.getUserInfo(req).subscribe((res) => {
      if (this.httpBasic.handleAppError(res)) return;
      if (res.rows.length > 0) {
        this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
        this.access.outStoreCd = res.rows[0].mainStoreCd;
        this.access.inStoreCd = res.rows[0].mainStoreCd;
        this.access.lang = res.rows[0].mainLang;
        this.access.sysModeCd = res.rows[0].sysModeCd;
        this.getStoreList();
        this.fnInitScreen();
      }
    });
  }

  private fnInitScreen() {
    this.getInitPrc();
    this.searchConditionSubscription = this.searchCondition.valueChanges.subscribe(() => {
      this.isDisableSrchBtn = !this.searchCondition.valid;
    });
  }

  ngOnDestroy() {
    if (this.searchConditionSubscription) this.searchConditionSubscription.unsubscribe();
  }

  getDate(ctrlNm: string) {
    if (this.searchCondition.get(ctrlNm).value == null) return Constant.EMPTY_STRING;
    return formatDate(this.searchCondition.get(ctrlNm).value, Constant.FORMAT_DATE, 'en_US');
  }

  getInitPrc() {
    let request: ReqSpmt10271Init = {
      access: this.access
    }
    let subsc = this.httpBasic.initSpmt10271(request).subscribe(
      result => {
        this.setResultInit(result);
        subsc.unsubscribe();
      },
      error => {
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  onCheckBoxChange() {
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    if (!reqStatus.controls.every(controls => controls.value == false)) {
      this.isDisableSrchBtn = false;
    }
  }

  getReqStatusInit(result: RspSpmt10271Init) {
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    reqStatus.clear();
    this.reqStatusList = [];
    result.reqStatusList.forEach((item: ReqStatusListDto) => {
      this.reqStatusList.push(item);
      reqStatus.push(new FormControl(true));
    });
  }

  getStoreList() {
    const request: ReqGetStoreList = {
      storeCd: Constant.EMPTY_STRING,
      userId: Constant.EMPTY_STRING,
      cmpyCd: this.access.cmpnyCd,
      sysModeCd: this.access.sysModeCd,
      access: this.access
    }
    const subsc = this.httpBasic.getStoreList(request).subscribe(
      result => {
        this.checkResultGetStore(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResultGetStore(response: RspGetStoreList) {
    response.rows.forEach((item: GetStoreListDto) => {
      this.storeCdList.push(item);
    })
    this.searchCondition.get(Constant.OUT_STORECD_CTRL).setValue(this.access.outStoreCd);
  }

  setResultInit(result: RspSpmt10271Init) {
    result.userList.forEach((item: DropdownListOfUserDto) => {
      this.userList.push(item);
    })
    this.getReqStatusInit(result);
    
    this.cdr.detectChanges();
    this.slipNoInput.nativeElement.focus();
  }

  pageInit() {
    this.searchConditionInit();
    this.itemsShow = [];
    this.searchCondition.get(Constant.OUT_STORECD_CTRL).setValue(this.access.outStoreCd);
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    reqStatus.clear();
    this.reqStatusList.forEach(() => {
      reqStatus.push(new FormControl(true));
    });
    this.matPagenatorItem._changePageSize(this.commonService.paginatorOption.pageSizeOptions[0])
    this.itemRecordCount = 0;
    this.isDisableSrchBtn = false;
    this.allChecked = false;
    this.isDisableConfirmBtn = true;
    this.slipNoInput.nativeElement.focus();
  }

  isDisableCheckBoxAll() {
    if (this.itemsShow.length == 0 || this.itemsShow.every((i: ItemSelect) => i.check.disabled)) {
      return true;
    }
    return false;
  }

  clearBtn() {
    if (this.itemsShow.length > 0) {
      this.subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.clearMsg).subscribe(data => {
        if (data) {
          this.pageInit();
        }
        this.subcriptionYesNoDialog.unsubscribe();
      })
    } else {
      this.pageInit();
    }
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0) {
      this.pageChanged();
    }
  }

  pageChanged() {
    this.isDisableConfirmBtn = true;
    this.allChecked = false;
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount
      && count < this.matPagenatorItem.pageSize; i++) {
      this.items[i].check.setValue(false);
      this.itemsShow.push(this.items[i]);
      count++;
    }
    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  isCheckedAll() {
    this.allChecked = !this.itemsShow.some(i => i.result.checkStt === Constant.CHECKSTTFLG && i.check.value === false && i.result.delFlg === Constant.CHECKSTTFLG);
  }

  allCheck(value: boolean) {
    this.itemsShow.forEach((item) => {
      if (item.result.checkStt === Constant.CHECKSTTFLG && item.result.delFlg === Constant.CHECKSTTFLG) item.check.setValue(value);
    });
    this.allChecked = value;
    this.confirmBtnChangeStatus();
  }

  confirmBtnChangeStatus() {
    if (this.itemsShow.some(i => i.check.value === true)) {
      this.isDisableConfirmBtn = false;
      return;
    }
    this.isDisableConfirmBtn = true;
  }

  updateAllChecked() {
    this.confirmBtnChangeStatus();
    this.isCheckedAll();
  }

  searchBtn() {
    this.commonService.openSpinner(this.commonService.pageTitle, Constant.MESSAGESEARCHING);
    this.searchPrc();
  }

  getReqStatus() {
    const arr = [];
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    reqStatus.controls.forEach((control: FormControl, index: number) => {
      if (control.value === true)
        arr.push(this.reqStatusList[index].DATACD);
    })
    return arr.toString();
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: Constant.ZERO,
      dispNum: Constant.LIMIT_RECORD
    }
  }

  searchPrc() {
    if (Constant.EMPTY_STRING == this.getReqStatus()) {
      this.commonService.closeSpinner();
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.reqSttMsg.replace(/%1/g, Constant.REQSTATUS_FIELD));
      this.isDisableSrchBtn = true;
      return;
    }
    const request: ReqSpmt10271Search = {
      outStoreCd: this.searchCondition.get(Constant.OUT_STORECD_CTRL).value,
      inStoreCd: this.searchCondition.get(Constant.IN_STORECD_CTRL).value,
      slipCreationDateBegin: this.getDate(Constant.SLIPCREATIONDATEBEGIN_CTRL),
      slipCreationDateEnd: this.getDate(Constant.SLIPCREATIONDATEEND_CTRL),
      slipNo: this.searchCondition.get(Constant.SLIPNO_CONTROL).value,
      reqStatus: this.getReqStatus(),
      outRequesterCd: this.searchCondition.get(Constant.OUTREQUESTER_CTRL).value,
      outApproverCd: this.searchCondition.get(Constant.OUTAPPROVER_CTRL).value,
      inApproverCd: this.searchCondition.get(Constant.INAPPROVER_CTRL).value,
      overwrite: this.searchCondition.get(Constant.OVERWRITE_CTRL).value,
      access: this.access,
      pageDto: this.getPageDto()
    }
    this.clearSearchResult();
    const subsc = this.httpBasic.searchSpmt10271(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10271Search) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      let subscription = this.commonService.openErrorDialogWithAfterCloseDialog(this.commonService.pageTitle,
        response.normalError[0].errMsg).subscribe(() => {
          this.searchBtn();
          subscription.unsubscribe();
        });
      return;
    }

    for (let i = 0; i < Constant.MAXITEMS; i++) {
      if (i >= response.rows.length) break;
      let row: ItemSelect = {
        check: new FormControl(false),
        result: response.rows[i]
      }
      this.items.push(row);
    }

    this.itemRecordCount = this.items.length ?? 0;
    if (response.rows.length == 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.doesNotExistMsg)
      return;
    }
    if (response.rows.length > Constant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.maxItemMsg.replace(/%1/g, Constant.MAXITEMS))
        // `${Constant.MAXITEMS}件を超える商品が検索されました。最初の${Constant.MAXITEMS}件のみが処理対象となります。`);
    }
    this.matPagenatorItem.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
    this.pageChanged();
  }

  clearSearchResult() {
    this.items = this.itemsShow = [];
    this.allChecked = false;
    this.itemRecordCount = 0;
    this.isDisableConfirmBtn = true
  }

  // Get detail on click link
  onClickLink(row: ItemSelect) {
    this.commonService.openSpinner(this.commonService.pageTitle, Constant.MESSAGESEARCHING);
    const request: ReqSpmt10271Exclusion = {
      updateTime: row.result.updateTime,
      slipNo: row.result.slipNo,
      access: this.access
    }
    const subsc = this.httpBasic.checkExclusionSpmt10271(request).subscribe(
      result => {
        this.checkResultClickLink(result, row);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResultClickLink(response: Rsp, row: ItemSelect) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      let subscription = this.commonService.openErrorDialogWithAfterCloseDialog(this.commonService.pageTitle,
        response.normalError[0].errMsg).subscribe(() => {
          this.searchBtn();
          subscription.unsubscribe();
        });
      return;
    }
    this.openModalDetail(row.result)
  }

  openModalDetail(row: Spmt10271Dto) {
    scrollTo(0, 10);
    this.commonService.dialog.open(Spmt10271DetailDialogComponent, {
      disableClose: true,
      data: {
        row: {
          ...row,
          outStoreNm: this.storeCdList.find((x: GetStoreListDto) => x.storeCd == row.outStoreCd).storeDetail
        }
      }
    });
  }

  openModalApproval() {
    scrollTo(0, 10);
    let data: Spmt10271Dto[] = [];
    this.itemsShow.forEach((item: ItemSelect) => {
      if (item.check.value)
        data.push(item.result)
    })
    const dialogRef = this.commonService.dialog.open(Spmt10271ApprovalDialogComponent, {
      data: {
        searchResult: data,
      }
    });

    this.dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
      if (data == true) {
        this.searchBtn();
      }
      this.dialogSubscription.unsubscribe();
      this.dialogSubscription = undefined;
    });
  }

  openModalRejected() {
    scrollTo(0, 10);
    let data: Spmt10271Dto[] = [];
    this.itemsShow.forEach((item: ItemSelect) => {
      if (item.check.value)
        data.push(item.result);
    })

    const dialogRef = this.commonService.dialog.open(Spmt10271RejectedDialogComponent, {
      data: {
        searchResult: data
      }
    });

    this.dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
      if (data == true) {
        this.searchBtn();
      }
      this.dialogSubscription.unsubscribe();
      this.dialogSubscription = undefined;
    });
  }
}
