import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpBasicService } from './http-basic.service';
import { PrinterLayoutDto, ReqGetPrinterLayoutList, RspGetPrinterLayoutList } from '../webservice/printer';
import { LabelPrinter, LabelPrinterConfig } from '../page/printer/label-pinter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LabelPrinterNeo7 } from '../page/printer/label-printer-neo7';
import { Observable } from 'rxjs';
import { LabelPrinterDialogComponent } from '../page/printer/label-printer-dialog/label-printer-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  public printerModelCurrent: string;
  public printerLayoutList: PrinterLayoutDto[];
  public printerConfig: LabelPrinterConfig;
  public printer: LabelPrinter = new LabelPrinterNeo7();

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService,
    private _snackBar: MatSnackBar
    ) {

    this.printerModelCurrent = this.commonService.config.printer.models[0].name;
  }

  getPrinterLayoutList(modelName?: string) {
    if (modelName && modelName !== this.printerModelCurrent) {
      this.printerLayoutList = undefined;
    }

    if (this.printerLayoutList !== undefined) return;

    let request: ReqGetPrinterLayoutList = {
      access: this.commonService.loginUser,
      model: this.printerModelCurrent,
      withImage: true
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetPrinterLayoutList", request).subscribe(
      (response: RspGetPrinterLayoutList) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receivePrinterLayoutList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receivePrinterLayoutList(response: RspGetPrinterLayoutList) {
    if (this.httpBasic.handleAppError(response)) return;

    this.printerLayoutList = response.rows;
    this.printerLayoutList.forEach((dto) => {
      if (dto.image === "") dto.image = null;
    })
  }

  async printDiscountLabel(msg: string, printerConfig?: LabelPrinterConfig) {
    let printer = this.printer;
    if (printerConfig) {
      printer = printerConfig.printer;
    }
    if (!printer?.isConnected()) {
      if (printer?.getPrinterType() === "neo7") {
        this.commonService.openErrorDialog(this.commonService.pageTitle, "接続確認されていません。");
      } else {
        this.commonService.openErrorDialog(this.commonService.pageTitle, "プリンターが接続されていません。");
      }
      return;
    }
    switch(printer.getPrinterType()) {
      case "neo7": {
        let result = await printer.connectAsync();
        if (result) {
          printer.getSubject().subscribe(
            (response) => {
              this.neo7response(response);
            },
            (error) => {
              this.commonService.openErrorDialog(this.commonService.pageTitle, "プリンター印刷エラー。");
            },
            () => {
            }
          );
        } else {
          this.commonService.openErrorDialog(this.commonService.pageTitle, "プリンター接続に失敗しました。");
          return;
        }
        break;
      }
      default:
        return;
    }
    printer.write(msg);
  }

  neo7DiscountType(discountType: number) {
    return discountType;
  }

  discountLabelMessageNeo7(printerConfig: LabelPrinterConfig, itemCd: string, discount: number, discountType: number, price: number, tax: number, count: number) {
    let msg = "\x02" + "DiscountPrint" + "\x1F" + "\x1F" + "\x1F"
      // + "101"     // layoutId
      + printerConfig.labelLayoutKey     // layoutId
      + "\x1F" + "\x1F" + "\x1F" + "\x1F"
      + count       // pages
      + "\x1F"
      + itemCd
      + "\x1F" 
      + "" + this.neo7DiscountType(discountType)
      + "\x1F" 
      + discount
      + "\x1F"
      + price
      + "\x1F"
      + tax
      + "\x03";

      return msg;
  }

  neo7response(response) {
    let results = response.result.split(String.fromCharCode(0x1f));
    if (results[0] == "0") {
      this._snackBar.open("" + results[1] + "枚印刷しました。", undefined, {duration: 3000});
    } else {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, "印刷エラー：" + this.neo7error(results[2]));
    }
  }

  neo7error(ecode: string) {
    switch(ecode) {
      case "1": {
        return "コマンドパラメータ不正";
      }
      case "2": {
        return "カバーオープン";
      }
      case "3": {
        return "ラベルエンド";
      }
      case "4": {
        return "ラベル種類";
      }
      case "5": {
        return "バッテリー電圧低下";
      }
      case "6": {
        return "期限チェックエラー";
      }
      default: {
        return "エラー内容不明";
      }
    }
  }

}
