export class Constant {
  static MAXITEMS: number = 1000;
  static SLIPNO_CONTROL: string = 'slipNo';
  static OUT_STORECD_CTRL: string = 'outStoreCd';
  static IN_STORECD_CTRL: string = 'inStoreCd';
  static EMPTY_STRING: string = '';
  static CHECKSTTFLG: string = '0';
  static CHECK_RED_FLG: string = '02'
  static CHECK_SYSTEM_MESSAGE_FLG: string = '1';
  static CHECK_DELETE_FLG: string = '1';
  static FORMAT_DATE: string = 'yyyy-MM-dd';
  static SLIPCREATIONDATEBEGIN_CTRL: string = 'slipCreationDateBegin';
  static SLIPCREATIONDATEEND_CTRL: string = 'slipCreationDateEnd';
  static REQSTATUS_CTRL: string = 'reqStatus';
  static OUTAPPROVER_CTRL: string = 'outApproverCd';
  static OUTREQUESTER_CTRL: string = 'outRequesterCd';
  static INAPPROVER_CTRL: string = 'inApproverCd';
  static OVERWRITE_CTRL: string = 'overwrite';
  static MESSAGESEARCHING: string = '検索中・・・';
  static PAGETITLE: string = "店間移動出庫承認";
  static LOGICSTOCKQTY_FIELD: string = "理論在庫数";
  static OUTSTOCKQTY_FIELD: string = "出庫数量";
  static REQSTATUS_FIELD: string = "申請状態";
  public static readonly ZERO = 0;
  public static readonly LIMIT_RECORD = 1001;
}
