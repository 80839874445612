import { Component, EventEmitter, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { RspPromSimItemSearch } from 'src/app/page/prom-sim/0_def/promSimDef';
import { CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { ReqItemSimpleSearch } from 'src/app/request/req-item-simple-seach';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ItemSearchConDto, ItemSimDto, ReqPromSearchCondDto, ReqPromSimItemSearch } from './../../page/prom-sim/0_def/promSimDef';

export interface PromSelectData {
  request: ReqPromSimItemSearch,
  rows: ItemSimDto[]
};

export interface ResItemSimpleSearchDto {
  NO: number;
  ctgCd0Fv: string;
  ctgCd1Fv: string;
  ctgCd2Fv: string;
  ctgName0Fv: string;
  ctgName1Fv: string;
  ctgName2Fv: string;
  itemCdFv: string;
  itemNameFv: string;
  standardFv: string;
  storeCdFv: string;
  formChecked: FormControl;
}

export interface PromItemDis extends ResItemSimpleSearchDto{
  ctg2FvDisplay:  string;
}

@Component({
  selector: 'app-prom-select-dialog',
  templateUrl: './prom-select-dialog.component.html',
  styleUrls: ['./prom-select-dialog.component.css']
})
export class PromSelectDialogComponent implements OnInit, OnDestroy, OnChanges {

  public recordCount: number = 0;
  public formsltBox: FormControl = new FormControl(false);
  public formItemCd: FormControl = new FormControl("");
  public formItemName: FormControl = new FormControl("");
  public notifyEvent: EventEmitter<any> = new EventEmitter();
  public ctgSelectCondition : CtgSelectCondition = new CtgSelectCondition(this.fb);
  public promItemList: ItemSimDto[];
  public promItemListShow: any[];
  public selectedProm: ItemSimDto;
  public checkedItems: ResItemSimpleSearchDto[] = [];
  public itemListOrigin: string[] = [];
  public itemSimpleList: ResItemSimpleSearchDto [] = [];
  private subscriptionSearch: Subscription;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  public columnIds: string[] = ["sltBox", "itemCdFv", "itemNameFv", "standardFv", "ctg2FvDisplay"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "itemCdFv", header: "商品コード", width: 120, align: "center" },
    { columnId: "itemNameFv", header: "商品名", width: 300, align: "left" },
    { columnId: "standardFv", header: "規格", width: 150, align: "left" },
    { columnId: "ctg2FvDisplay", header: this.commonService.literal["ctg" + (this.commonService.config.maxCtgLevel - 1) + "Name"], width: 200, align: "left" }
  ];

  constructor(
    public dialogRef: MatDialogRef<PromSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PromSelectData,
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    if(this.data.rows == undefined) this.data.rows = [];
    this.data.rows.forEach((el) => {
      this.itemListOrigin.push(el.itemCdFv);
    })
  }

  OnChanges() {
  }

  ngOnDestroy() {
   
  }

  onCancel() {
    this.dialogRef.close();
  }

  onClose() {
    let listItemCdFv: ItemSearchConDto[] = [];
    this.checkedItems.forEach(item => listItemCdFv.push(
      {
        itemCdFv: item.itemCdFv,
        editCost: -1,
        editSell: -1
      }
    ))
    let newRequest: ReqPromSimItemSearch = {
      access: this.commonService.loginUser,
      itemSearchCond: {
        listItemCdFv: listItemCdFv
      },
      promSearchCond: this.genReqPromSearchCondDto(),
      promOrderDto: null,
      promSimDto: null,
      maxCtgLevel: this.commonService.config.maxCtgLevel,
      editMode: 1
    }
    let request: ReqPromSimItemSearch  = this.data.request ?? newRequest;
    request.editMode = 1;
    request.itemSearchCond.listItemCdFv = listItemCdFv;
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemSimList", request).subscribe(
      (response : RspPromSimItemSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePromList(response)
      },
     (error) => {
        subsc.unsubscribe();
       this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  initValue() {
    this.formItemCd.setValue("");
    this.formItemName.setValue("");
    this.formsltBox.setValue(false);
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px"
    }
  }

  onItemChecked(item: ResItemSimpleSearchDto, checked: boolean) {
    if (checked) {
      item.formChecked.setValue(true);
      this.checkedItems.push(item);
    } else {
      item.formChecked.setValue(false);
      this.checkedItems.forEach( (el, index) => {
        if(el === item) this.checkedItems.splice(index,1);
      })
    }
  }

  genReqPromSearchCondDto() {
    let today = new Date;
    const dto: ReqPromSearchCondDto = { 
      startDate: this.commonService.formatDate(today),
      finishDate : this.commonService.formatDate(today),
      storeGroupType : -1,
      storeGroupcd : -1
    }
    return dto;
  }

  queryProm() {
    this.ctgSelectCondition.formGroup.get("ctgLevel")
    let ctgSelectCondition = this.ctgSelectCondition.formGroup;
    let request: ReqItemSimpleSearch = {
      access: { ...this.commonService.loginUser },
      itemSimpleSeachCondition: {
        storeCdFv: "",
        ctgCd0Fv: ctgSelectCondition.get("ctgLevel").value >= 0 ?
        ctgSelectCondition.get('ctgCd0').value : '',
        ctgCd1Fv: ctgSelectCondition.get('ctgLevel').value >= 1 ?
        ctgSelectCondition.get('ctgCd1').value : '',
        ctgCd2Fv: ctgSelectCondition.get('ctgLevel').value >= 2 ?
        ctgSelectCondition.get('ctgCd2').value : '',
        ctgCd3Fv: ctgSelectCondition.get('ctgLevel').value >= 3 ?
        ctgSelectCondition.get('ctgCd3').value : '',
        itemCdFv: this.formItemCd.value.trim(),
        itemNameFv: this.formItemName.value.trim(),
      },
      page: {
        pageNum: this.matPaginator.pageIndex,
        dispNum: 1001
      }

    };
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
    this.subscriptionSearch = this.httpBasic.itemSimpleSearch(request).subscribe(
      response => {
        this.commonService.closeSpinner();
        this.receiveItemList(response);
      },
      error => {
        this.clearProgressState();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveItemList(response) {
    if (this.httpBasic.handleAppError(response)) return;
    let maxItems = 1000;
    this.selectedProm = undefined;
    this.itemSimpleList = [];
    let itemCount = 0;
    
    for(let i = 0; i < response.rows.length; i++) {
      let dto: ResItemSimpleSearchDto = {formChecked: new FormControl(false),...response.rows[i] };
      itemCount++;
      if (itemCount > maxItems) {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `${maxItems}件を超える商品が検索されました。最初の${maxItems}件のみが処理対象となります。`);
        break;
      }
      this.itemSimpleList.push(dto);
    }
    this.recordCount = this.itemSimpleList.length;
    if (this.matPaginator) {
      this.matPaginator.pageIndex = 0;
    }
    this.pageChanged();
    this.clearProgressState();
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }

  receivePromList(response: RspPromSimItemSearch) {
    if (this.httpBasic.handleAppError(response)) return;
    this.promItemList = [];
    response?.rows.forEach(item => this.promItemList.push(item))
    this.dialogRef.close(this.promItemList);
  }

  pageChanged() {
    this.promItemListShow = [];
    this.recordCount = this.itemSimpleList?.length || 0;
    let start;
    let end;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.itemSimpleList.length) end = this.itemSimpleList.length;
    for (let i = start; i < end; i++) {
      let row: PromItemDis = {

        ctg2FvDisplay: this.itemSimpleList[i].ctgCd2Fv + ' - ' + this.itemSimpleList[i].ctgName2Fv,
        ...this.itemSimpleList[i]
      }
      this.promItemListShow.push(row);
    }
  }

  isDisableBtn() {
    if(this.checkedItems?.length > 0) return false;
    return true;
  }

  initRack() {
    this.notifyEvent.emit();
   }

}
