import { ReqOrderStopTermCtgDeleteDto } from '../../request/req-order-stop-term-ctg-delete';
import { formatDate } from '@angular/common';
import { OrderStopTermCtgAddDto } from '../../request/req-order-stop-term-ctg-add';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CtgSelectCondition, CtgSelectComponent } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { RspOrderStopTermCtg } from 'src/app/response/rsp-order-stop-term-ctg';
import { OrderStopTermCtgRec } from './order-stop-term-ctg-rec';

@Component({
  selector: 'app-order-stop-term-ctg',
  templateUrl: './order-stop-term-ctg.component.html',
  styleUrls: ['./order-stop-term-ctg.component.css']
})
export class OrderStopTermCtgComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public tableWidth: any;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'storeFv', header: "店舗", width: 150 },
    { columnId: 'ctg0Fv', header: this.commonService.literal.ctg0Name, width: 200 },
    { columnId: 'ctg1Fv', header: this.commonService.literal.ctg1Name, width: 200 },
    { columnId: 'ctg2Fv', header: this.commonService.literal.ctg2Name, width: 200 },
    { columnId: 'ctg3Fv', header: this.commonService.literal.ctg3Name, width: 200 },
    { columnId: 'dateTypeFv', header: "日付種別", width: 60, align: "center" },
    { columnId: 'dateBeginFv', header: "開始日", width: 100, align: "center" },
    { columnId: 'dateEndFv', header: "終了日", width: 100, align: "center" }
  ];

  public stores: LoginStoreDto[];
  public stopList: OrderStopTermCtgRec[] = [];

  private subscriptionInit: Subscription;
  private subscriptionStoreCd: Subscription;
  private subscriptionConfirm: Subscription;

  @ViewChild(CtgSelectComponent, { static: true }) public CtgSelectComponent: CtgSelectComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = "期間指定発注停止（カテゴリ）"
    this.commonService.pageTitle = this.commonService.pageMenuName;

    if (this.commonService.stores.length > 1) {
      this.stores = [{ storeCd: "*", storeName: "全店" }, ...this.commonService.stores];
    } else {
      this.stores = [...this.commonService.stores];
    }
    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.storeCd],
      dateType: ["1"],
      dateBegin: [],
      dateEnd: []
    }
    );
    this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    this.setDisplayCol();
    this.initOrderStopTermCtg();
    this.subscriptionStoreCd = this.formGroup.get('storeCd').valueChanges
      .subscribe(
        () => {
          this.initOrderStopTermCtgStoreChange();
        }
      );
  }

  ngOnDestroy() {
    if (this.subscriptionInit) {
      this.subscriptionInit.unsubscribe();
    }
    if (this.subscriptionStoreCd) {
      this.subscriptionStoreCd.unsubscribe();
    }
  }

  setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = ["removeItem"];

    for (var colDef of this.columnDefs) {
      if (colDef.columnId === "storeCdFv" && this.commonService.stores.length <= 1) continue;
      if (colDef.columnId === "ctg0Fv" && this.commonService.config.maxCtgLevel < 1) continue;
      if (colDef.columnId === "ctg1Fv" && this.commonService.config.maxCtgLevel < 2) continue;
      if (colDef.columnId === "ctg2Fv" && this.commonService.config.maxCtgLevel < 3) continue;
      if (colDef.columnId === "ctg3Fv" && this.commonService.config.maxCtgLevel < 4) continue;

      this.displayColumnDefs.push(colDef);
      this.displayColumnIds.push(colDef.columnId);
      this.calcTableWidth();
    }
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 削除ボタン
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }

  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  setDateType(type: string) {
    if (type == "発注日") {
      this.formGroup.get('dateType').setValue("1");
    } else {
      this.formGroup.get('dateType').setValue("2");
    }
  }

  onClickCell(columnId: string, item: Object) {
  }

  selectTableItem() {
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  initOrderStopTermCtg() {
    this.subscriptionInit = this.httpBasic.initOrderStopTermCtg(this.formGroup.get("storeCd").value)
      .subscribe(
        data => {
          this.checkResult(data);
        },
        error => this.httpBasic.handleError(error)
      );
  }

  searchCtg() {
    if (this.formGroup.get("storeCd").value === "*") {
      this.CtgSelectComponent.setStoreCd("");
    }
    else {
      this.CtgSelectComponent.setStoreCd(this.formGroup.get("storeCd").value);
    }
  }

  initOrderStopTermCtgStoreChange() {
    this.subscriptionInit = this.httpBasic.initOrderStopTermCtg(this.formGroup.get("storeCd").value)
      .subscribe(
        data => {
          this.checkResult(data);
          this.searchCtg();
        },
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error)
        }
      );
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
  }

  isValidSubmit() {
    if (this.ctgSelectCondition.formGroup.get("ctgCd0").value === "") return false;
    if (this.formGroup.get("dateBegin").value == null) return false;
    if (this.formGroup.get("dateEnd").value == null) return false;
    return true;
  }

  getDateBeginMin() {
    if (this.formGroup.get('dateEnd').value != null && this.formGroup.get('dateBegin').value > this.formGroup.get('dateEnd').value)
      this.formGroup.get('dateEnd').setValue(null);

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

  getDateEndMin() {
    if (this.formGroup.get('dateBegin').value != null) {
      return this.formGroup.get('dateBegin').value;
    }

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

  doSubmit() {
    let storeName: string = this.stores.filter(store => store.storeCd === this.formGroup.get("storeCd").value)[0].storeName;
    if (storeName == undefined || storeName == null)
      return;
    let request: OrderStopTermCtgAddDto = {
      storeCdFv: this.formGroup.get("storeCd").value,
      storeNameFv: storeName,

      ctg0CdFv: this.ctgSelectCondition.formGroup.get("ctgCd0").value,
      ctg0NameFv: this.ctgSelectCondition.ctg0Name,

      ctg1CdFv: this.ctgSelectCondition.formGroup.get("ctgCd1").value,
      ctg1NameFv: this.ctgSelectCondition.ctg1Name,

      ctg2CdFv: this.ctgSelectCondition.formGroup.get("ctgCd2").value,
      ctg2NameFv: this.ctgSelectCondition.ctg2Name,

      ctg3CdFv: this.ctgSelectCondition.formGroup.get("ctgCd3").value,
      ctg3NameFv: this.ctgSelectCondition.ctg3Name,

      ctgLevelFn: this.ctgSelectCondition.formGroup.get("ctgLevel").value,
      dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
      dateEndFv: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US'),
      dateTypeFn: parseInt(this.formGroup.get("dateType").value)
    }

    this.subscriptionInit = this.httpBasic.addOrderStopTermCtg(request)
      .subscribe(data =>
        this.checkResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        });

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
  }

  checkResult(response: RspOrderStopTermCtg) {
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.stopList = [];
    for (var orderStopTermCtg of response.result) {
      this.stopList.push(new OrderStopTermCtgRec(orderStopTermCtg));
    }
  }

  doCancel() {
    this.ctgSelectCondition.formGroup.controls["ctgCd0"].setValue("");
    this.formGroup.controls["dateBegin"].setValue(null);
    this.formGroup.controls["dateEnd"].setValue(null);
    this.formGroup.controls["dateType"].setValue("1");
  }

  isHiddenDeleteButton(storeCdFv: string) {
    if (this.commonService.stores.length > 1) return false;
    return (storeCdFv === "*") ? true : false;
  }

  doDelete(orderStopTermCtg: OrderStopTermCtgRec) {
    /*
    let msg = "以下の登録を削除しますか？";
    if (orderStopTermCtg.storeCdFv != undefined && orderStopTermCtg.storeCdFv !== "")
      msg = msg + "<br><br>店舗: " + orderStopTermCtg.storeFv;
    if (orderStopTermCtg.ctg0CdFv != undefined && orderStopTermCtg.ctg0CdFv !== "")
      msg = msg + "<br>" + this.commonService.literal.ctg0Name + ": " + orderStopTermCtg.ctg0Fv;
    if (orderStopTermCtg.ctg1CdFv != undefined && orderStopTermCtg.ctg1CdFv !== "")
      msg = msg + "<br>" + this.commonService.literal.ctg1Name + ": " + orderStopTermCtg.ctg1Fv;
    if (orderStopTermCtg.ctg2CdFv != undefined && orderStopTermCtg.ctg2CdFv !== "")
      msg = msg + "<br>" + this.commonService.literal.ctg2Name + ": " + orderStopTermCtg.ctg2Fv;
    if (orderStopTermCtg.ctg3CdFv != undefined && orderStopTermCtg.ctg3CdFv !== "")
      msg = msg + "<br>" + this.commonService.literal.ctg3Name + ": " + orderStopTermCtg.ctg3Fv;
    if (orderStopTermCtg.dateTypeFv != undefined && orderStopTermCtg.dateTypeFv !== "")
      msg = msg + "<br>日付種別: " + orderStopTermCtg.dateTypeFv;
    if (orderStopTermCtg.dateBeginFv != null)
      msg = msg + "<br>開始日: " + orderStopTermCtg.dateBeginFv;
    if (orderStopTermCtg.dateEndFv != null)
      msg = msg + "<br>終了日: " + orderStopTermCtg.dateEndFv;
    */

      let msg = "以下の登録を削除しますか？<br>";
      msg += "<br><table class='no-border'>";
      if (orderStopTermCtg.storeCdFv != undefined && orderStopTermCtg.storeCdFv !== "") {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>店舗：　</td>" + "<td class='no-border'>" + orderStopTermCtg.storeFv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.ctg0CdFv != undefined && orderStopTermCtg.ctg0CdFv !== "") {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>" + this.commonService.literal.ctg0Name + "：　</td>" + "<td class='no-border'>" + orderStopTermCtg.ctg0Fv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.ctg1CdFv != undefined && orderStopTermCtg.ctg1CdFv !== "") {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>" + this.commonService.literal.ctg1Name + "：　</td>" + "<td class='no-border'>" + orderStopTermCtg.ctg1Fv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.ctg2CdFv != undefined && orderStopTermCtg.ctg2CdFv !== "") {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>" + this.commonService.literal.ctg2Name + "：　</td>" + "<td class='no-border'>" + orderStopTermCtg.ctg2Fv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.ctg3CdFv != undefined && orderStopTermCtg.ctg3CdFv !== "") {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>" + this.commonService.literal.ctg3Name + "：　</td>" + "<td class='no-border'>" + orderStopTermCtg.ctg3Fv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.dateTypeFv != undefined && orderStopTermCtg.dateTypeFv !== "") {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>日付種別:　</td>" + "<td class='no-border'>" + orderStopTermCtg.dateTypeFv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.dateBeginFv != null) {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>開始日：　</td>" + "<td class='no-border'>" + orderStopTermCtg.dateBeginFv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.dateBeginFv != null) {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>終了日：　</td>" + "<td class='no-border'>" + orderStopTermCtg.dateEndFv + "</td>";
        msg += "</tr>";
      }
      msg += "</table>";

      this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      (data) => {
        this.subscriptionConfirm = undefined;
        if (data == true) {
          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          let request: ReqOrderStopTermCtgDeleteDto = {
            searchStoreCdFv: this.formGroup.get("storeCd").value,
            storeCdFv: orderStopTermCtg.storeCdFv,
            ctg0CdFv: orderStopTermCtg.ctg0CdFv || "",
            ctg1CdFv: orderStopTermCtg.ctg1CdFv || "",
            ctg2CdFv: orderStopTermCtg.ctg2CdFv || "",
            ctg3CdFv: orderStopTermCtg.ctg3CdFv || "",
            dateTypeFn: orderStopTermCtg.dateTypeFn,
            dateBeginFv: orderStopTermCtg.dateBeginFv,
            dateEndFv: orderStopTermCtg.dateEndFv
          };
          this.subscriptionInit = this.httpBasic.deleteOrderStopTermCtg(request)
            .subscribe(
              data => this.checkResult(data),
              error => {
                this.clearProgressState();
                this.httpBasic.handleError(error);
              }
            )
        }
      }
    );
  }
}
