import { Component, OnInit, ViewChild, AfterViewChecked, OnDestroy } from '@angular/core';
import { ItemSearchCondition } from 'src/app/partsCommon/item-select-condition/item-select-condition.component';
import { ItemListComponent } from 'src/app/partsCommon/item-list/item-list.component';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-item-order-stop-edit',
  templateUrl: './item-order-stop-edit.component.html',
  styleUrls: ['./item-order-stop-edit.component.css']
})
export class ItemOrderStopEditComponent implements OnInit, OnDestroy, AfterViewChecked {

  public isReadonly: boolean;
  public itemSearchCondition: ItemSearchCondition = new ItemSearchCondition(this.fb);
  public itemSearchConditionOrigin: ItemSearchCondition = new ItemSearchCondition(this.fbOrigin);
  public orderStopFlagValues: number[];

  private itemListContainerElement: HTMLElement;
  private scrollHeight: number;
  private scrollTop: number;

  @ViewChild(ItemListComponent, { static: true }) itemListComponent: ItemListComponent;

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private fbOrigin: FormBuilder)
  {
    this.orderStopFlagValues = [];
    let flagList = this.commonService.getOrderStopSearchCondList();
    for (let i = 0; i < flagList.length; i++) {
      let flag = flagList[i];
      if (!flag.paramEditable) continue;
      if (flag.caseItem) continue;
      this.orderStopFlagValues.push(flag.value);
    }
  }

  ngOnInit() {
    // this.commonService.pageTitle = "自動発注対象";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("autotrgt");

    this.itemSearchCondition.formGroup.get('salesDate').setValue(this.commonService.dateSalesDate);
    this.itemSearchCondition.formGroup.get('salesDate').disable();

    window.addEventListener("resize", this.initScroll);
  }

  ngOnDestroy() {
    window.removeEventListener("resize", this.initScroll);
  }

  ngAfterViewChecked() {
    this.initScroll();
  }

  initScroll() {
    this.itemListContainerElement = document.getElementById("item-list-container");
    var clientRect = this.itemListContainerElement.getBoundingClientRect();
    this.scrollTop = clientRect.top;
    this.scrollHeight = window.innerHeight - this.scrollTop - 45;
    this.itemListContainerElement.style["height"] = "" + this.scrollHeight + "px";
  }

  doQuery() {
    this.copyRequest();
    this.itemListComponent.doQuery();
  }

  copyRequest() {
    this.itemSearchConditionOrigin.formGroup = this.itemSearchCondition.cloneAbstractControl(this.itemSearchCondition.formGroup);
    this.itemSearchConditionOrigin.ctgSelectCondition.formGroup.patchValue(this.itemSearchCondition.ctgSelectCondition.formGroup.value);
    this.itemSearchConditionOrigin.rackSelectCondition.formGroup.patchValue(this.itemSearchCondition.rackSelectCondition.formGroup.value);
    this.itemSearchConditionOrigin.formGroup.get('salesDate').setValue(this.commonService.dateSalesDate);
    this.itemSearchConditionOrigin.formGroup.get('salesDate').disable();
  }
}
