import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabHeader } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { SubTaskAttachmentDto } from 'src/app/webservice/task';
import { SubTask } from '../0_def/taskDefs';
import { TaskRec } from '../0_def/taskRec';
import { StoreGroupTypeRec } from 'src/app/common/store-group-type-rec';

@Component({
  selector: 'app-subtask-edit-dialog',
  templateUrl: './subtask-edit-dialog.component.html',
  styleUrls: ['./subtask-edit-dialog.component.css']
})
export class SubtaskEditDialogComponent implements OnInit, OnDestroy {

  public editTask: TaskRec;
  public subTask: SubTask;

  public subTaskName: FormControl;
  public description: FormControl;
  public deadlineDate: FormControl;
  public workTimeH: FormControl;
  public workTimeM: FormControl;
  public photoReport: FormControl;
  public formStoreGroupType: FormControl = new FormControl();
  private subscStoreGroup: Subscription;  
  public storeGroupTypeList: StoreGroupTypeRec[];
  public initialGroupType: number = -1;

  private isDirty:  boolean = false;
  private subsc: Subscription[] = [];

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    public dialogRef: MatDialogRef<SubtaskEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      editTask: TaskRec,
      subTask: SubTask,
      storeGroupTypeList: StoreGroupTypeRec[],
      initialGroupType: number
    }) {
    this.editTask = data.editTask;
    this.subTask = data.subTask;
    this.storeGroupTypeList = data.storeGroupTypeList;
    this.initialGroupType = data.initialGroupType;

    this.subTaskName = new FormControl(this.subTask.edits.subTaskName.value);
    this.description = new FormControl(this.subTask.edits.description.value);
    let d = this.subTask.edits.deadlineDate.value;
    if (!d) {
      this.deadlineDate = new FormControl(this.commonService.getDate(this.editTask.dateEnd));
    } else {
      this.deadlineDate = new FormControl(this.commonService.copyDate(d));
    }
    this.workTimeH = new FormControl(this.subTask.edits.workTimeH.value);
    this.workTimeM = new FormControl(this.subTask.edits.workTimeM.value);
    this.photoReport = new FormControl(this.subTask.edits.photoReport.value);
    if (this.subTask.edits.photoReport.disabled) this.photoReport.disable();
  }

  ngOnInit(): void {
    this.subTask.edits.subTaskStoreList.forEach((store) => {
      this.subsc.push(store.form.valueChanges.subscribe((val) => {this.setDirty();}))
    });
    this.subsc.push(this.formStoreGroupType.valueChanges.subscribe(
      (value) => { this.storeGroupTypeChanged(); }
    ));
    this.formStoreGroupType.setValue(this.initialGroupType);
    if (this.editTask.releaseStatusBool) {
      this.formStoreGroupType.disable();
    } else {
      this.formStoreGroupType.enable();
    }
  }

  ngOnDestroy(): void {
    this.subsc.forEach((subsc) => subsc.unsubscribe());    
  }

  setDirty() {
    this.isDirty = true;
  }

  storeGroupTypeChanged() {
    this.editTask.buildStoreGroupList(this.formStoreGroupType.value, this.subTask);
  }

  storeSelectAll() {
    this.subTask.edits.subTaskStoreList.forEach((store) => {
      store.form.setValue(true);
    });
    this.subTask.edits.subTaskStoreGroupList.forEach((store) => {
      if (store.store === undefined) store.allChecked = true;
    });
  }

  storeUnselectAll() {
    this.subTask.edits.subTaskStoreList.forEach((store) => {
      store.form.setValue(false);
    });
    this.subTask.edits.subTaskStoreGroupList.forEach((store) => {
      if (store.store === undefined) store.allChecked = false;
    });
  }

  getSubTaskFileName(subTask: SubTask) {
    return subTask.edits.subTaskFileName.value;
  }

  onFileSubTaskSelected(event: any, subTask: SubTask) {
    if(event.target.files.length == 0) return;
    let file: File = event.target.files[0];
    //Check file duplicate
    if(subTask.subTaskFileName == file.name) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, `ファイル ` + file.name + ` が存在しています。（又は既存）`);
      return;
    }
    //Remove the file first
    this.editTask.edits.subAttachmentList.forEach((file, index) => {
      if(file.subTaskId == subTask.subTaskId) 
        this.editTask.edits.subAttachmentList.splice(index, 1);
    })
    this.commonService.blobToB64(file).then(data => {
      //add sub task info client - not save DB
      subTask.edits.subTaskFileName.setValue(file.name);
      subTask.edits.subTaskAttachment.setValue(data as string);
      subTask.edits.subTaskFileName.markAsDirty();
      // add sub task attachment - save to DB
      let subAtt: SubTaskAttachmentDto = {
        taskId: this.editTask.taskId,
        subTaskId: subTask.subTaskId,
        storeCd: this.commonService.loginUser.storeCd,
        attachmentTitle:file.name,
        attachmentFilename: file.name,
        attachment: data as string
      }
      this.editTask.edits.subAttachmentList.push(subAtt); 
    })
  }

  onCheckNullFileSubTask(subTask: SubTask) {
    if (!subTask?.edits?.subTaskFileName.value) return false;
    if (subTask?.edits?.subTaskFileName.value == '') return false;
    return true;
  }

  previewFileSubTask(subTask: SubTask) {
    if(!subTask.edits.subTaskAttachment.value){  
      const request = {
        taskId: this.editTask.taskId,
        storeCd: null,
        subTaskId: subTask.subTaskId,
        access: this.commonService.loginUser
      }
      if(this.commonService.loginUser.roleId != 0) {
        // In case of screen "お仕事管理（本部）" (role 0) no need to check storeId(Null)
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.subTaskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            subTask.edits.subTaskAttachment.setValue(response.attachment);
            subTask.edits.subTaskFileName.setValue(response.attachmentFilename);
            let attachment = subTask.edits.subTaskAttachment.value;
            let filename = subTask.edits.subTaskFileName.value;
            await this.receivePreviewFileSubTask(attachment, filename);  
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {  
      let attachment = subTask.edits.subTaskAttachment.value;
      let filename = subTask.edits.subTaskFileName.value;
      this.receivePreviewFileSubTask(attachment, filename);
    }
  }

  async receivePreviewFileSubTask(attachment: string, filename: string) {
    let mimeType;
    let base64 = attachment.split(';base64,')[1];
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;

      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;

      default: mimeType ='NoSupport';
    }
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      if (mimeType == "NoSupport") {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `サポートされていないファイル形式`);
      }else {
        var iframe = "<iframe src='" + url + "' frameborder='0' style='border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;' allowfullscreen></iframe>";
        var openTab = window.open();
        openTab.document.open();
        openTab.document.write(iframe);
        openTab.document.title = filename; 
        openTab.document.close();
      } 
    });
  }

  onClose() {
    this.subTask.edits.subTaskName.setValue(this.subTaskName.value);
    this.subTask.edits.description.setValue(this.description.value);
    this.subTask.edits.deadlineDate.setValue(this.commonService.copyDate(this.deadlineDate.value));
    this.subTask.edits.photoReport.setValue(this.photoReport.value);

    if (this.subTaskName.dirty) this.subTask.edits.subTaskName.markAsDirty();
    if (this.description.dirty) this.subTask.edits.description.markAsDirty();
    if (this.deadlineDate.dirty) this.subTask.edits.deadlineDate.markAsDirty();
    if (this.photoReport.dirty) this.subTask.edits.photoReport.markAsDirty();

    if (this.commonService.config.task.workTimeIsHourAndMinutes) {
      let h = parseInt(this.workTimeH.value);
      let m = parseInt(this.workTimeM.value);
      if (m >= 60) {
        h += Math.floor(m / 60);
        m %= 60;
      }
      this.subTask.edits.workTimeH.setValue(h);
      this.subTask.edits.workTimeM.setValue(m);
    } else {
      this.subTask.edits.workTimeM.setValue(this.workTimeM.value);
    }
    if (this.workTimeH.dirty) this.subTask.edits.workTimeH.markAsDirty();
    if (this.workTimeM.dirty) this.subTask.edits.workTimeM.markAsDirty();

    this.dialogRef.close(this.isDirty);
  }
}
