import { Component, OnInit, OnDestroy, EventEmitter, ViewChild, AfterViewChecked } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription } from 'rxjs';
import { RspReportDef, ReportColumnDefDto } from 'src/app/response/rsp-report-def';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReportRec } from 'src/app/common/report-rec';
import { MatPaginator } from '@angular/material/paginator';
import { RspReport, ReportRecDto } from 'src/app/response/rsp-report';
import { ReqReport } from 'src/app/request/req-report';
import { MatTable } from '@angular/material/table';
import { TableItemDialogComponent } from 'src/app/dialog/table-item-dialog/table-item-dialog.component';
import { ReqItemSearch } from 'src/app/request/req-item-search';
import { RspItemSearch } from 'src/app/response/rsp-item-search';
import { ItemRec } from 'src/app/common/item-rec';
import { OrderEditDialogComponent } from 'src/app/dialog/order-edit-dialog/order-edit-dialog.component';
import { StockEditDialogComponent } from 'src/app/dialog/stock-edit-dialog/stock-edit-dialog.component';
import { ParamItemEditDialogComponent } from 'src/app/dialog/param-item-edit-dialog/param-item-edit-dialog.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, OnDestroy, AfterViewChecked {

  public isReadonly: boolean = true;

  public reportId: string;
  public reportName: string;
  public reportRowHeaders: number;
  public reportComment: string;
  public reportColDefs: ReportColumnDefDto[];
  public reportRecList: ReportRec[];
  private subscriptionRoute: Subscription;
  private subscriptionRepDef: Subscription;
  private subscriptionRepData: Subscription;
  private subscriptionDialog: Subscription
  private subscriptionItem: Subscription;
  public storeForm: FormControl;

  public recordCount: number;
  public tableWidth: any;
  public pagenatorMaxWidth: any;
  public displayColumnIds: string[];
  stickyTableColumnDefs: TableColumnDef[];
  tableColumnDefs: TableColumnDef[];
  displayColumnDefs: TableColumnDef[] = [];
  undisplayColumnDefs: TableColumnDef[] = [];

  private canLinkOrderEdit: boolean;
  private canLinkStockEdit: boolean;
  private canLinkItemParamEdit: boolean;

  @ViewChild(MatTable, {static:false}) matTable: MatTable<any>;
  @ViewChild(MatPaginator, {static:false}) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder) {

  }

  ngAfterViewChecked(): void {
    if (document.getElementById("comment") && this.reportComment) {
      document.getElementById("comment").innerHTML = this.reportComment;
    }
  }

  ngOnInit() {
    // this.commonService.pageTitle = "";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.storeForm = new FormControl(this.commonService.loginUser.storeCd);
    /*
    this.subscriptionRoute = this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.reportId = params.get("repid");
        this.isReadonly = this.commonService.checkPrivilege("report/" + this.reportId);
        this.getDef();
      }
    );
    */
    this.subscriptionRoute = this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.reportId = params.get("name");
        // this.isReadonly = this.commonService.checkPrivilege("report/" + this.reportId);
        this.isReadonly = this.commonService.checkPrivilege("report?name=" + this.reportId);
        if (this.isReadonly == null) return;
        this.getDef();
      }
    );
  
    this.canLinkOrderEdit = !this.commonService.checkPrivilegeNoRedirect("order");
    this.canLinkStockEdit = !this.commonService.checkPrivilegeNoRedirect("stock");
    this.canLinkItemParamEdit = !this.commonService.checkPrivilegeNoRedirect("itemparam");

  }

  ngOnDestroy() {
    if (this.subscriptionRoute) {
      this.subscriptionRoute.unsubscribe();
    }
    if (this.subscriptionRepDef) {
      this.subscriptionRepDef.unsubscribe();
    }
    if (this.subscriptionRepData) {
      this.subscriptionRepData.unsubscribe();
    }
    if (this.subscriptionDialog) {
      this.subscriptionDialog.unsubscribe();
    }
    if (this.subscriptionItem) {
      this.subscriptionDialog.unsubscribe();
    }
  }

  clearProgressStateDef() {
    this.commonService.closeSpinner();
    this.subscriptionRepDef.unsubscribe();
    this.subscriptionRepDef = undefined;
  }

  clearProgressStateData() {
    this.commonService.closeSpinner();
    this.subscriptionRepData.unsubscribe();
    this.subscriptionRepData = undefined;
  }

  clearProgressStateItem() {
    this.commonService.closeSpinner();
    this.subscriptionItem.unsubscribe();
    this.subscriptionItem = undefined;
  }

  getDef() {
    this.commonService.openSpinner("帳票", "初期化中・・・");

    this.subscriptionRepDef = this.httpBasic.getReportDef(this.reportId).subscribe(
      (resp) => this.receiveDef(resp),
      (error) => {
        this.clearProgressStateDef();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDef(response : RspReportDef) {
    this.clearProgressStateDef();
    var rspReportDef: RspReportDef = {...response};

    if (rspReportDef.fatalError && rspReportDef.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("帳票", rspReportDef.fatalError[0].errMsg);
      return;
    }
    if (rspReportDef.normalError && rspReportDef.normalError.length > 0) {
      this.commonService.openErrorDialog("帳票", rspReportDef.normalError[0].errMsg);
      return;
    }

    this.reportName = rspReportDef.name;
    this.reportRowHeaders = rspReportDef.rowHeaders;
    this.reportColDefs = [...rspReportDef.columns];
  
    // this.commonService.pageTitle = this.reportName;
    this.storeForm.setValue(this.commonService.loginUser.storeCd);

    this.stickyTableColumnDefs = undefined;
    this.tableColumnDefs = undefined;
    this.displayColumnIds = undefined;
    this.reportRecList = [];

    this.buildTableColumnDef();
  }

  buildTableColumnDef() {
    this.stickyTableColumnDefs = [];
    this.tableColumnDefs = [];
    this.displayColumnIds = [];

    for (let i = 0; i < this.reportColDefs.length; i++) {
      let colDef =  this.reportColDefs[i];
      let editable = false;
      if (colDef.link) {
        if (colDef.link === "orderedit" && this.canLinkOrderEdit) editable = true;
        if (colDef.link === "stockedit" && this.canLinkStockEdit) editable = true;
        if (colDef.link === "itemparamedit" && this.canLinkItemParamEdit) editable = true;
      }
      let def: TableColumnDef = {
        columnId: colDef.selectCol,
        header: colDef.header,
        width: colDef.width,
        align: colDef.gravity,
        editable: editable,
        link: colDef.link ? colDef.link : ""
      };
      if (i < this.reportRowHeaders) {
        this.stickyTableColumnDefs.push(def);
      } else {
        this.tableColumnDefs.push(def);
      }
      this.displayColumnIds.push(def.columnId);
    }
    this.displayColumnDefs = [...this.tableColumnDefs];
    this.undisplayColumnDefs = [];
    this.calcTableWidth();
  }

  getReport() {
    if (this.matPagenator){
      this.matPagenator.pageIndex = 0;
      this.matPagenator.length = 0;
    }
    this.getReportBody();
  }

  getReportBody() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    var pageIndex = 0;
    var pageSize = this.commonService.paginatorOption.pageSizeOptions[
      this.commonService.paginatorOption.pageSizeIndex
    ];

    if (this.matPagenator) {
      pageIndex = this.matPagenator.pageIndex;
      pageSize = this.matPagenator.pageSize;
    }

    var request : ReqReport = {
      access: {...this.commonService.loginUser},
      reportId: this.reportId,
      storeCd: this.storeForm.value,
      cols: this.reportColDefs,
      page: {
        pageNum: pageIndex,
        dispNum: pageSize
      }
    }

    this.subscriptionRepData = this.httpBasic.getReportData(request).subscribe(
      (response) => this.receiveRepData(response),
      (error) => {
        this.clearProgressStateData();
        this.httpBasic.handleError(error);
      }
    )

  }

  receiveRepData(response: RspReport) {
    this.clearProgressStateData();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.recordCount = response.recordCount;
    this.reportComment = response.comment;

    this.reportRecList = [];
    if (this.recordCount > 0) {
      var recs : ReportRecDto[] = [...response.rows];
      for (var rec of recs) {
        this.reportRecList.push(new ReportRec(rec, this.reportColDefs));
      }
    }

    // this.buildTableColumnDef();
  }

  pageChanged(event: EventEmitter<any>) {
    this.getReportBody();
  }

  styleFor(id:string) {
    for (var colDef of this.tableColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id:string) {
    for (var colDef of this.tableColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  styleForSticky(id:string) {
    let left = 0;
    for (let i = 0; i < this.stickyTableColumnDefs.length; i++) {
      let colDef = this.stickyTableColumnDefs[i]
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left",
          "left": "" + left + "px"
        }
      }
      if (i == 0) left++;   /* For left border */
      left += colDef.width + 1 + 8;
    }
  }

  styleForHeaderSticky(id:string) {
    let left = 0;
    for (let i = 0; i < this.stickyTableColumnDefs.length; i++) {
      let colDef = this.stickyTableColumnDefs[i]
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "left": "" + left + "px"
        }
      }
      if (i == 0) left++;   /* For left border */
      left += colDef.width + 1 + 8;
    }
  }

  calcTableWidth() {
    var width = 0;   // For left border
    for (var colDef of this.stickyTableColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    /*
    for (var colDef of this.tableColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    */
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = {"width": "" + width + "px"};
    this.pagenatorMaxWidth = {"max-width": "" + width + "px"}
  }

  onClickCell(link: string, item: ReportRec) {
    if (link == "") return;
    var itemcd = item.getItemCd(); 
    if (!itemcd) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "商品コードが不明です。");
      return;
    }
    var request : ReqItemSearch = {
      access: {...this.commonService.loginUser},
      itemSearchCondition : {
        calcTrgtDateFd: this.commonService.salesDate,
        storeCdFv: this.storeForm.value,
        ctgCd0Fv: "",
        ctgCd1Fv: "",
        ctgCd2Fv: "",
        ctgCd3Fv: "",
        rackCd1Fn: 0,
        rackCd2Fn: 0,
        rackCd3Fn: 0,
        itemCdFv: itemcd,
        itemCdEqualSearch: true,
        itemNameFv: "",
        orderCurrentOperator: 0,
        orderCurrentNum: 0,
        orderInitialOperator: 0,
        orderInitialNum: 0,
        stockCurrentOperator: 0,
        stockCurrentNum: 0,
        stockInitialOperator: 0,
        stockInitialNum: 0,
      },
      page: {
        pageNum: 0,
        dispNum: 1
      }
    }

    this.subscriptionItem = this.httpBasic.itemSearch(request).subscribe(
      response => this.itemResult(response, link),
      error => {
        this.clearProgressStateItem();
        this.httpBasic.handleError(error);
      }
    );

  this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
  }

  itemResult(response: RspItemSearch, link: string) {
    this.clearProgressStateItem();
    var rspItemSearch: RspItemSearch = {...response};

    if (rspItemSearch.fatalError && rspItemSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspItemSearch.fatalError[0].errMsg);
    }
    if (rspItemSearch.normalError && rspItemSearch.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspItemSearch.normalError[0].errMsg);
      return;
    }

    if (rspItemSearch.recordCount == 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "商品が見つかりませんでした。");
      return;
    }

    var itemRec = new ItemRec(rspItemSearch.rows[0], this.commonService);

    switch(link) {
      case "orderedit":
        this.openOrderEditDialog(itemRec);
        break;
      case "stockedit":
        this.openStockEditDialog(itemRec);
        break;
      case "itemparamedit":
        this.openParamItemEditDialog(itemRec);
        break;
      }
  }

  openOrderEditDialog(itemRec: ItemRec) {
    const dialogRef = this.commonService.dialog.open(OrderEditDialogComponent, {
      disableClose: true,
      data: itemRec
    });
  }

  openStockEditDialog(itemRec: ItemRec) {
    const dialogRef = this.commonService.dialog.open(StockEditDialogComponent, {
      disableClose: true,
      data: itemRec
    });
  }

  openParamItemEditDialog(itemRec: ItemRec) {
    const dialogRef = this.commonService.dialog.open(ParamItemEditDialogComponent, {
      disableClose: true,
      data: itemRec
    });
  }

  selectTableItem() {
    scrollTo(0, 0);
    const dialogRef = this.commonService.dialog.open(TableItemDialogComponent, {
      disableClose: true,
      data: {display: this.displayColumnDefs, nonDisplay: this.undisplayColumnDefs}
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => {
        this.setColumnId();
        this.calcTableWidth();
        this.dialogResult(data);
      }
    );
  }

  setColumnId() {
    this.displayColumnIds = [];

    for (var colDef of this.stickyTableColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
    for (var colDef of this.displayColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
  }

  dialogResult(data: any) {
    this.subscriptionDialog.unsubscribe();
    this.subscriptionDialog = undefined;
    this.matTable.renderRows();
  }
}
