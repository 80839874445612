import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { OrderGroupDto, ReqGetOrderGroupList, RspGetOrderGroupList } from 'src/app/webservice/order-group';
import { DialogService } from 'src/app/service/dialog.service';
import { ItemExpiryService } from '../1_service/item-Expiry.service';
import { ItemExpiryInitialComponent } from '../item-expiry-initial/item-expiry-initial.component';
import { Subscription } from 'rxjs';
import { ReqDelFromGroup, ReqInitItemDate, ReqItemExpiryDelGroup, RspInitItemDate, RspItemExpiryDelGroup } from 'src/app/webservice/item-expiry';

export interface listItem extends OrderGroupDto {
  check:    FormControl;
}

@Component({
  selector: 'app-item-expiry-initial-main',
  templateUrl: './item-expiry-initial-main.component.html',
  styleUrls: ['./item-expiry-initial-main.component.css']
})
export class ItemExpiryInitialMainComponent implements OnInit, OnDestroy {

  public tabSelection: number = 0;
  public storeForm: FormControl = new FormControl();
  public columnIds: string[] = ["groupSelect", "groupIdName"];
  public orderGroupList: listItem[] = [];

  private subsc: Subscription[] = [];

  @ViewChild(ItemExpiryInitialComponent, {static: false}) itemExpiryInitialComponent: ItemExpiryInitialComponent 

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private dialogService: DialogService,
    private itemExpiryService: ItemExpiryService
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.storeForm.setValue(this.commonService.loginUser.storeCd);
    this.subsc.push(this.storeForm.valueChanges.subscribe(
      (val) => {
        this.getOrderGroup();
      }
    ));

    this.getOrderGroup();
  }

  ngOnDestroy(): void {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "table-box-groups";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 0;    // 56;
    let margin = 10;
    let itemBox = 0;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - itemBox - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  tabChanged(event) {
    if (this.tabSelection === 1) {
      this.setTableHeight();
    }
  }

  getOrderGroup() {
    let request: ReqGetOrderGroupList = {
      access: this.commonService.loginUser,
      storeCd: this.storeForm.value,
      mode: "ItemExpiry"
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetOrderGroupList", request).subscribe(
      (response: RspGetOrderGroupList) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveGetOrderGroup(response);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveGetOrderGroup(response: RspGetOrderGroupList) {
    if (this.httpBasic.handleAppError(response)) return;
    this.orderGroupList = response.rows.map((dto) => {
      return {check: new FormControl(false), ...dto};
    });
  }

  selectAll(){
    this.orderGroupList.forEach((item) => item.check.setValue(true));
  }

  unselectAll() {
    this.orderGroupList.forEach((item) => item.check.setValue(false));
  }

  initItemDate() {
    this.commonService.openYesNoDialog(this.commonService.pageTitle, "商品日付を初期化しますか？").subscribe(
      (response) => {
        if (response) {
          this.initItemDateBody();
        }
      }
    )
  }

  initItemDateBody() {
    let request: ReqInitItemDate = {
      access:     this.commonService.loginUser,
      storeCd:    this.storeForm.value,
      groupUsrCd: this.orderGroupList.filter((item) => item.check.value === true).map((item) => item.orderGroupUsrCd)
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("InitItemDate", request).subscribe(
      (response: RspInitItemDate) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveInitItemDate(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )
  }
  
  receiveInitItemDate(response: RspInitItemDate) {
    if (this.httpBasic.handleAppError(response)) return;
  }

  deleteOrderGroup() {
    this.commonService.openYesNoDialog(this.commonService.pageTitle, this.commonService.literal["itemExpiryGroup"] + "を削除しますか？").subscribe(
      (response) => {
        if (response) {
          this.deleteOrderGroupBody();
        }
      }
    )
  }

  deleteOrderGroupBody() {
    let request: ReqItemExpiryDelGroup = {
      access:     this.commonService.loginUser,
      storeCd:    this.storeForm.value,
      group:      this.orderGroupList.filter((item) => item.check.value === true).map(
        (item) => {
          return {
            groupId: item.orderGroupId,
            groupUsrCd: item.orderGroupUsrCd
          }
        })
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("ItemExpiryDelGroup", request).subscribe(
      (response: RspItemExpiryDelGroup) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveDeleteOrderGroup(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveDeleteOrderGroup(response: RspItemExpiryDelGroup) {
    if (this.httpBasic.handleAppError(response)) return;

    this.getOrderGroup();
  }

  selectedCount() {
    let cnt = 0;
    this.orderGroupList.forEach((item) => {
      if (item.check.value) cnt++;
    });

    return cnt;
  }

  canDeactivate() {
    if (!this.itemExpiryInitialComponent.isDirty()) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
