import { RoleMenu } from './role-menu';

export class Role {
    public roleId: number           = 0;
    public roleName: string         = "";
    public trgetStoreFlag: number   = 0;
    public roleLevel: number        = 0;
    public roleComment: string      = "";
    public version: number          = 0;
    public userCount: number        = 0;
    public roleMenus: RoleMenu[]    = [];
    public roleMenusMobile: RoleMenu[]    = [];
    public roleConfig: {}           = {};
}
