import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.css']
})
export class SectionHeaderComponent implements OnInit {

  @Input() public title: string;
  @Input() public sectionBody: HTMLElement;
  @Input() public isSectionBodyOpen : boolean = true;
  @Output("onOpen") public onOpen : EventEmitter<any> = new EventEmitter<any>();
  @Output("onClose") public onClose : EventEmitter<any> = new EventEmitter<any>();

  cssOrgDisplay : string;

  constructor() { }

  ngOnInit() {
    if (this.sectionBody) {
      this.cssOrgDisplay = this.sectionBody.style.display;
      if (!this.isSectionBodyOpen) {
        this.sectionBody.style.display = "none";
      }
    }
  }

  toggleSectionBody() {
    if (this.sectionBody) {
      if (this.isSectionBodyOpen) {
        this.sectionBody.style.display = "none";
        this.onClose.emit();
      } else {
        if (this.cssOrgDisplay) {
          this.sectionBody.style.display = this.cssOrgDisplay;
        } else {
          this.sectionBody.style.display = "";
        }
        this.onOpen.emit();
      }
      this.isSectionBodyOpen = ! this.isSectionBodyOpen;
      window.dispatchEvent(new Event('window:resize'));
    }
  }

  openSectionBody() {
      if (!this.isSectionBodyOpen) {
        this.toggleSectionBody();
      }
  }

  closeSectionBody() {
    if (this.isSectionBodyOpen) {
      this.toggleSectionBody();
    }
  }

}
