import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';import { CommonService } from 'src/app/service/common.service';
import { OrderStopTermSuppRec } from '../order-stop-term-supp/order-stop-term-supp-rec';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Spiv00081DetailSearchDto } from 'src/app/request/req-spiv00081-detail-search';
import { RspSpiv00081DetailListInit } from 'src/app/response/rsp-spiv00081-detail-list';

@Component({
  selector: 'app-spiv00081-detail',
  templateUrl: './spiv00081.detail.component.html',
  styleUrls: ['./spiv00081.detail.component.css'],
})

export class Spiv00081DetailComponent implements OnInit, OnDestroy {

  @ViewChild("sectionSuppHeader", { static: true }) sectionSupp: SectionHeaderComponent;

  public formGroup: FormGroup;
  public stopList: OrderStopTermSuppRec[] = [];
  invDiffDetailList: any = [];

  public tableWidth: any;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'inventoryType', header: "棚卸種別", width: 60, align: "center" },
    { columnId: 'inventoryCnt', header: "棚卸数", width: 100, align: "right" },
    { columnId: 'costPrice', header: "POS原価(抜)", width: 100, align: "right" },
    { columnId: 'costAmount', header: "POS原価金額(抜)", width: 100, align: "right" },
    { columnId: 'sellPrice', header: "売価(抜)", width: 100, align: "right" },
    { columnId: 'sellAmount', header: "売価金額(抜)", width: 100, align: "right" },
    { columnId: 'importDateTime', header: "入力日時", width: 130},
    { columnId: 'importUser', header: "入力担当者", width: 100},
  ];

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    private dialogRef: MatDialogRef<Spiv00081DetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.initFormGroup();
    this.setDisplayCol();
  }

  ngOnDestroy() {}

  initFormGroup() {
    const formatCtg =  (this.data.largeClass ? "[" + this.data.largeClass + "]" : "") +
    (this.data.midClass ? "-[" + this.data.midClass + "]" : "") +
    (this.data.smallClass ? "-[" + this.data.smallClass + "]" : "") +
    (this.data.subClass ? "-[" + this.data.subClass + "]" : "") ;
    this.formGroup = this.fb.group({
      productCd: [{ value: this.data.productCd, disabled: true }],
      productNm: [{ value: this.data.productNm, disabled: true }],
      standardNm: [{ value: this.data.standard, disabled: true }],
      category: [{ value: formatCtg, disabled: true }],
      inventoryCnt: [{ value: this.data.inventoryCnt, disabled: true }],
      stockQty: [{ value: this.data.stockQty, disabled: true }],
      costPrice: [{ value: this.data.costPrice, disabled: true }],
      costAmount: [{ value: this.data.costAmount, disabled: true }],
      sellPrice: [{ value: this.data.sellPrice, disabled: true }],
      sellAmount: [{ value: this.data.sellAmount, disabled: true }],
      diffCount: [{ value: this.data.diffAmount, disabled: true }],
      diffCostAmount: [{ value: this.data.diffCostAmt, disabled: true }],
      diffSellAmount: [{ value: this.data.diffSellAmt, disabled: true }],
    });

    this.doInvDiffDetailSearch(this.data.invDiffId);
  }
  setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = [];

    for (var colDef of this.columnDefs) {
      this.displayColumnDefs.push(colDef);
      this.displayColumnIds.push(colDef.columnId);
      this.calcTableWidth();
    }
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    width = width + 80 + 1 + 8;   // 削除ボタン
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  styleFor(id: string, value?: number) {
    return this.styleForBody(this.columnDefs, id, value);
  }

  styleForBody(colDefs: TableColumnDef[], id: string, value?: number) {
    for (let colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": `${colDef.width}px`,
          "text-align": colDef.align ? colDef.align : "left",
          "color": value < 0 ? "red" : "inherit"
        };
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  doInvDiffDetailSearch(item: any){
    var request: Spiv00081DetailSearchDto = {
      invDiffId: item,
      access: { ...this.commonService.loginUser },
    }

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.generalRequest("Spiv00081DetailSearch",request).subscribe(
      result => {
        this.checkResult(result);
        this.commonService.closeSpinnerForSubComp(ref);
      },
      error => {
        this.httpBasic.handleError(error);
        this.commonService.closeSpinnerForSubComp(ref);
      }
    );
  }

  checkResult(response: RspSpiv00081DetailListInit) {
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.invDiffDetailList = [];
    for (var data of response.result) {
    this.invDiffDetailList.push(data);
     }
  }


  closeDialog() {
    this.dialogRef.close();
  }

}
