import { CommonService } from '../service/common.service';
import { RspOpestatDistrDto, RspOpestatDistrItemDto } from '../response/rsp-opestat-distr';

export class OpestatDistrRec {
    NO: number;
    type: string;
    range1: number;
    range2: number;
    range3: number;
    range4: number;
    range5: number;
    range6: number;
    range7: number;
    range8: number;
    range9: number;
    range10: number;

    constructor(private opestatDistrDto: RspOpestatDistrDto, private commonService: CommonService) {
        for (var key of Object.keys(opestatDistrDto)) {
            this[key] = opestatDistrDto[key];
        }
    }
}

export class OpestatDistrItemRec {
    NO: number;
    storeCdFv: string;
    itemCdFv: string;
    itemNameFv: string;
    addNumber: number;
    upNumber: number;
    matchNumber: number;
    downNumber: number;
    delNumber: number;
    shortageNumber: number;
    ctgCd0Fv: string;
    ctgName0Fv: string;
    ctgCd1Fv: string;
    ctgName1Fv: string;
    ctgCd2Fv: string;
    ctgName2Fv: string;
    ctgCd3Fv: string;
    ctgName3Fv: string;
    ctg0Fv: string;
    ctg1Fv: string;
    ctg2Fv: string;
    ctg3Fv: string;

    constructor(private opestatDistrItemDto: RspOpestatDistrItemDto, private commonService: CommonService) {
        for (var key of Object.keys(opestatDistrItemDto)) {
            this[key] = opestatDistrItemDto[key];
        }
        this.ctg0Fv = this.ctgCd0Fv + ":" + this.ctgName0Fv;
        this.ctg1Fv = this.ctgCd1Fv + ":" + this.ctgName1Fv;
        this.ctg2Fv = this.ctgCd2Fv + ":" + this.ctgName2Fv;
        this.ctg3Fv = this.ctgCd3Fv + ":" + this.ctgName3Fv;
    }
}
