import { FormControl, FormGroup } from "@angular/forms";
import * as JSZip from "jszip";
import { Subscription } from "rxjs";
import { UserDto } from "src/app/response/rsp-get-users";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { ReqTaskFile, ReqUpdateTaskResultRec, RspTaskFileInfo, SubTaskAttachmentDto, SubTaskResultRecDto, TaskAttachmentDto, TaskImageRecDto } from "src/app/webservice/task";
import { TaskService } from "../1_service/taskService";
import { PhotoItem, SubTask, SubTaskResult, TaskTargetStore } from "./taskDefs";
import { map, startWith } from "rxjs/operators";

export class TaskStoreRec {
  // From TaskRec
  taskId:             number = -1;
  taskName:           string = "";
  authorFv:           string = "";
  priority:           string = "" ;
  dateBegin:          string = "" ;
  dateEnd:            string = "";
  releaseStatus:      number = 1;
  releaseStatusBool:  boolean = true;       // Not in DB Table
  releaseStatusText?: string = "公開済み";   // Not in DB Table
  taskStatus:         string = "";
  description:        string = "";
  responsibleClass:   string = "";
  referenceUrl:       string = "";
  attachmentList:     TaskAttachmentDto[] = []; // Task Attachment 
  comment:            string = "";
  targetStoreCount:   number = 1;
  completeStoreCount: number = 1;
  targetStores:       TaskTargetStore[] = [];
  subTaskNextId:      number = 1;
  subTasks:           SubTask[] = [];
  datesToEnd:         number = 0;           // Not in DB Table
  datesToEndText:     string = "";          // Not in DB Table
  progress:           string = "";
  md5List:            string[] = [];
  // For Store
  storeCd:            string = "";
  startDateTime:      string = "";
  resultStatus:       string = "";
  responsible:        string = "";
  responsibleUserId:  string = "";
  responsibleUserName:  string = "";
  commentStore:       string = "";
  subTaskResults:     SubTaskResult[] = [];
  taskCategory:       string = "";
  isObsolete?:        boolean = false;
  edits?:  {
    formGroup:          FormGroup;
    startDateTime:      string;
    resultStatus:       string;
    responsible:        FormControl;
    responsibleUser:    FormControl;
    responsibleName:    string;
    commentStore:       FormControl;
  };

  public photoList: PhotoItem[] = [];
  public errorMessage: string;
  private subscMainResponsible: Subscription;
  private subscResultWorking: Subscription;
  private subscResultComplete: Subscription[] = [];
  private subscWorkingStatus: Subscription[] = [];
  public userList: UserDto[];

  constructor(
    private commonService: CommonService,
    private taskService: TaskService,
    private httpBasic: HttpBasicService) {
  }

  clearResource() {
    this.subscMainResponsible?.unsubscribe();
    this.subscMainResponsible = undefined;
    this.subscResultWorking?.unsubscribe();
    this.subscResultWorking = undefined;
    this.subscResultComplete.forEach((subsc) => subsc.unsubscribe());
    this.subscResultComplete = [];
    this.subscWorkingStatus.forEach((subsc) => subsc.unsubscribe());
    this.subscWorkingStatus = [];
  }

  patchValueTaskResult(obj: Object) {
    for (let key in obj) {
      if (Object.keys(this).find((recKey) => recKey === key)) {
        this[key] = obj[key];
      }
    }
    let dateEnd = this.commonService.getDate(this.dateEnd);
    let d = new Date();
    let today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    // this.datesToEnd = Math.floor((dateEnd.getTime() - new Date().getTime()) / 86400000);
    this.datesToEnd = Math.floor((dateEnd.getTime() - today.getTime()) / 86400000);
    this.datesToEndText = "" + this.datesToEnd;
  }
  
  async patchValue(obj: Object) {
    for (let key in obj) {
      if (Object.keys(this).find((recKey) => recKey === key)) {
        this[key] = obj[key];
      }
    }
    let dateEnd = this.commonService.getDate(this.dateEnd);
    let d = new Date();
    let today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    this.datesToEnd = Math.floor((dateEnd.getTime() - today.getTime()) / 86400000);
    this.datesToEndText = "" + this.datesToEnd;
    try {
    } catch(error) {

    }
  }

  getValue(key: string) {
    switch(key) {
      case "responsible": {
        if (!this.commonService.config.task.responsibleIsLoginUser) return this.responsible;
        if (this.responsibleUserId && this.responsibleUserId !== "") {
          return this.responsibleUserId + ":" + this.responsibleUserName;
        }
        return this.responsible;
      }
      default:
        return this[key];
    }
  }

  prepareEdit(): void {
    this.clearResource();

    this.edits = {
      formGroup:        new FormGroup({}),
      startDateTime:    this.startDateTime,
      resultStatus:     this.resultStatus,
      responsible:      new FormControl(this.responsible),
      responsibleUser:  new FormControl(this.responsibleUserId),
      responsibleName:  this.taskService.getResponsibleName(this.userList, this.responsibleUserId),
      commentStore:     new FormControl(this.commentStore)
    };

    this.edits.formGroup.addControl("responsible", this.edits.responsible);
    this.edits.formGroup.addControl("responsibleUser", this.edits.responsibleUser);
    this.edits.formGroup.addControl("commentStore", this.edits.commentStore);
    if (!this.taskService.isViewTaskStore) {
      //  After editig an already started task, responsible and isStarted cannot be enabled by simply enabling the form.
      //  I don't know why. To avoid this symptom, I use setTimeout.
      //  because control enable()/disable after change detection run 
      setTimeout(() => {this.edits.responsible.enable();}, 0);
      setTimeout(() => {this.edits.responsibleUser.enable();}, 0);
      setTimeout(() => {this.edits.commentStore.enable();}, 0);
      /*
      if (this.responsible == undefined || this.responsible === "") {
        this.edits.isStarted.disable();
      } else {
        setTimeout(() => {this.edits.isStarted.enable();}, 0);
      }
      */
    }
    if (this.taskService.isViewTaskStore) {
      this.edits.responsible.disable();
      this.edits.responsibleUser.disable();
      this.edits.commentStore.disable();
      this.errorMessage = undefined;
    }

    if (this.commonService.config.task.responsibleIsLoginUser) {
      this.subscMainResponsible = this.edits.responsibleUser.valueChanges.subscribe(
        (value) => { this.mainResponsibleChanged(value); }
      );
    } else {
      this.subscMainResponsible = this.edits.responsible.valueChanges.subscribe(
        (value) => { this.mainResponsibleChanged(value); }
      );
    }
    /*
    // Check if subTaskResult exists or not for development phase.
    // Subtasks have to be created when the task is released.
    for (let subTask of this.subTasks) {
      let result = this.subTaskResults.find((item) => item.subTaskId === subTask.subTaskId);
      if (result) continue;
      result = {
        subTaskId:          subTask.subTaskId,
        subTaskStatus:      this.taskService.subTaskStatusNotAssigned,
        isComplete:         false,
        completeDateTime:   "",
        responsible:        "",
        // nextPhotoId:        1,
        photos:             []
      };
      this.subTaskResults.push(result);
    }
    */

    this.subTaskResults.forEach((result) => {
      if (result.isComplete) {
        var workingStatus = "2";
      } else if (result.isWorking) {
        workingStatus = "1"
      } else {
        workingStatus = "0";
      }
      result.photos.forEach((photo) => {
        photo.isDeleted = false;
        photo.isUpdated = false;
        photo.isImageUpdated = false;
        if (photo.titleOrg) {
          photo.title = photo.titleOrg;
        } else {
          photo.titleOrg = photo.title;         // save for cancel
        }
        if (photo.dataUrlOrg) {
          photo.dataUrl = photo.dataUrlOrg;
        } else {
          photo.dataUrlOrg = photo.dataUrl;     // save for cancel
        }
      });
      result.edits = {
        isComplete:         new FormControl(result.isComplete),
        completeDateTime:   result.completeDateTime,
        responsible:        new FormControl(result.responsible),
        responsibleUser:    new FormControl(result.responsibleUserId),
        responsibleName:    this.taskService.getResponsibleName(this.userList, result.responsibleUserId),
        isWorking:          new FormControl(result.isWorking),
        workTimeHResult:    new FormControl(result.workTimeHResult),
        workTimeMResult:    new FormControl(result.workTimeMResult),
        comment:            new FormControl(result.comment),
        photos:             [...result.photos],
        workingStatus:      new FormControl(workingStatus),
        filteredOptions:    undefined,
        selectedPhoto:      undefined
      };

      this.edits.formGroup.addControl("result-" + result.subTaskId + "-working", result.edits.isWorking);
      this.edits.formGroup.addControl("result-" + result.subTaskId + "-comp", result.edits.isComplete);
      this.edits.formGroup.addControl("result-" + result.subTaskId + "-responsible", result.edits.responsible);
      this.edits.formGroup.addControl("result-" + result.subTaskId + "-responsibleUser", result.edits.responsibleUser);
      this.edits.formGroup.addControl("result-" + result.subTaskId + "-work-time-h", result.edits.workTimeHResult);
      this.edits.formGroup.addControl("result-" + result.subTaskId + "-work-time-m", result.edits.workTimeMResult);
      this.edits.formGroup.addControl("result-" + result.subTaskId + "-comment", result.edits.comment);
      this.edits.formGroup.addControl("result-" + result.subTaskId + "-working-status", result.edits.workingStatus);
      result.edits.filteredOptions = result.edits.responsible.valueChanges.pipe(
        startWith(''),
        map(value => {
          return value ? this._filter(value as string) : this.taskService.staffListAll.slice();
        }),
      );

      if (result.isComplete) {
        result.edits.isWorking.disable();
        result.edits.isComplete.disable();
        result.edits.workingStatus.disable();
        // result.edits.responsible.disable();
      }
      this.subscResultWorking = result.edits.isWorking.valueChanges.subscribe(
          (value) => { this.resultWorking(value, result); }
      );
      this.subscResultComplete.push(
        result.edits.isComplete.valueChanges.subscribe(
          (value) => { this.resultComplete(value, result); }
        )
      );
      this.subscResultComplete.push(
        result.edits.workTimeHResult.valueChanges.subscribe(
          (value) => { this.resultCompleteWorkTime(value); }
        )
      );
      this.subscResultComplete.push(
        result.edits.workTimeMResult.valueChanges.subscribe(
          (value) => { this.resultCompleteWorkTime(value); }
        )
      );
      this.subscWorkingStatus.push( result.edits.workingStatus.valueChanges.subscribe(
        (value) => { this.resultWorkingStatus(value, result); }
      ));
    });
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.taskService.staffListAll.filter(option => option.toLowerCase().includes(filterValue));
  }

  async commitEdit(): Promise<ReqUpdateTaskResultRec> {
    let request: ReqUpdateTaskResultRec = {
      access:             this.commonService.loginUser,
      taskResultRec:      undefined,
      subTaskResultRec:   [],
      imagesNew:          [],
      imagesUpdate:       [],
      imagesDelete:       [],
      staffs:             []
    }
    let staffCheck = {};

    let update: boolean;
    update = false;

    if (this.resultStatus !== this.edits.resultStatus) {
      this.resultStatus = this.edits.resultStatus;
      update = true;
    }
    if (this.responsible !== this.edits.responsible.value) {
      this.responsible = this.edits.responsible.value;
      update = true;
    }
    if (this.responsibleUserId !== this.edits.responsibleUser.value) {
      this.responsibleUserId = this.edits.responsibleUser.value;
      update = true;
    }
    if (this.responsibleUserName !== this.edits.responsibleName) {
      this.responsibleUserName = this.edits.responsibleName;
      update = true;
    }
    if (this.commentStore !== this.edits.commentStore.value) {
      this.commentStore = this.edits.commentStore.value;
      update = true;
    }
    if(this.commonService.stores.length > 1) {
      this.storeCd = this.taskService.storeCd;
    } else {
      this.storeCd = this.commonService.loginUser.storeCd;
    }
    request.taskResultRec = {
      taskId: this.taskId,
      storeCd: this.storeCd,
      responsible: this.responsible,
      responsibleUserId: this.responsibleUserId ?? "",
      responsibleUserName: this.responsibleUserName ?? "",
      startDateTime: "",
      resultStatus: this.resultStatus,
      commentStore: this.commentStore,
      subTaskResults: []                  // Not used in this case
    };
    if (this.responsible !== "") {
      request.staffs.push({storeCd: this.storeCd, staffName: this.responsible});
      staffCheck[this.responsible] = true;
    }

    let isCompleteAll: boolean = true;
    this.subTaskResults.forEach((result) => {
      update = false;
      if (result.isWorking !== result.edits.isWorking.value) {
        result.isWorking = result.edits.isWorking.value;
        update = true;
      }
      if (result.isComplete !== result.edits.isComplete.value) {
        result.isComplete = result.edits.isComplete.value;
        update = true;
      }
      if (!result.isComplete) isCompleteAll = false;
      let compDateTIme = result.edits.isComplete.value ? result.edits.completeDateTime : "";
      if (result.completeDateTime !== compDateTIme) {
        result.completeDateTime = compDateTIme;
        update = true;
      }
      if (result.responsible !== result.edits.responsible.value) {
        result.responsible = result.edits.responsible.value;
        update = true;
      }
      if (result.responsibleUserId !== result.edits.responsibleUser.value) {
        result.responsibleUserId = result.edits.responsibleUser.value;
        update = true;
      }
      if (result.responsibleUserName !== result.edits.responsibleName) {
        result.responsibleUserName = result.edits.responsibleName
        update = true;
      }
      if (result.comment !== result.edits.comment.value) {
        result.comment = result.edits.comment.value
        update = true;
      }
      if (result.workTimeHResult !== result.edits.workTimeHResult.value) {
        result.workTimeHResult = parseInt(result.edits.workTimeHResult.value === "" ? 0 : result.edits.workTimeHResult.value);
        update = true;
      }
      if (result.workTimeMResult !== result.edits.workTimeMResult.value) {
        result.workTimeMResult = parseInt(result.edits.workTimeMResult.value === "" ? 0 : result.edits.workTimeMResult.value);
        update = true;
      }

      /*
      if (update) {
        let subResult: SubTaskResultRecDto = {
          taskId:                 this.taskId,
          storeCd:                this.storeCd,
          subTaskId:              result.subTaskId,
          isComplete:             result.isComplete,
          completeDateTime:       result.completeDateTime,
          subResponsible:         result.responsible,
          subResponsibleUserId:   result.responsibleUserId,
          subResponsibleUserName: result.responsibleUserName,
          comment:                result.comment,
          workTimeResult:         result.workTimeHResult * 60 + result.workTimeMResult,
          deadlineDate:           result.deadlineDate
        };
        request.subTaskResultRec.push(subResult);
      }
      */
      let subResult: SubTaskResultRecDto = {
        taskId:                 this.taskId,
        storeCd:                this.storeCd,
        subTaskId:              result.subTaskId,
        isWorking:              result.isWorking,
        isComplete:             result.isComplete,
        completeDateTime:       result.completeDateTime,
        subResponsible:         result.responsible,
        subResponsibleUserId:   result.responsibleUserId,
        subResponsibleUserName: result.responsibleUserName,
        comment:                result.comment,
        workTimeResult:         result.workTimeHResult * 60 + result.workTimeMResult,
        deadlineDate:           result.deadlineDate,
        isUpdate:               update
      };
      request.subTaskResultRec.push(subResult);

      if (result.responsible !== "" && !staffCheck[result.responsible]) {
        request.staffs.push({storeCd: this.storeCd, staffName: result.responsible});
        staffCheck[result.responsible] = true;
      }

      let photos: PhotoItem[] = [];
      let photoUpdated;
      photoUpdated = false;
      for (let photo of result.edits.photos) {
        if (photo.isNew || photo.isUpdated || photo.isImageUpdated) {
          photoUpdated = true;
          if (photo.isNew && photo.isDeleted) continue;

          let image: TaskImageRecDto = {
            taskId:       this.taskId,
            storeCd:      this.storeCd,
            subTaskId:    result.subTaskId,
            imageId:      photo.photoId,
            imageTitle:   photo.title,
            image:        photo.dataUrl
          };
          photo.titleOrg = photo.title;
          photo.dataUrlOrg = photo.dataUrl;

          if (photo.isNew) {
            request.imagesNew.push(image);
          } else if (photo.isUpdated || photo.isImageUpdated) {
            request.imagesUpdate.push(image);
          }
          photo.isNew = false;
          photo.isUpdated = false;
          photo.isImageUpdated = false;
          photos.push(photo);
        } else if (photo.isDeleted) {
          photoUpdated = true;
          let image: TaskImageRecDto = {
            taskId:       this.taskId,
            storeCd:      this.storeCd,
            subTaskId:    result.subTaskId,
            imageId:      photo.photoId,
            imageTitle:   photo.title,
            image:        ""
          };
          request.imagesDelete.push(image);
        } else {
          photos.push(photo);
        }
      }
      if (photoUpdated) {
        result.photos = photos;
        result.edits.photos = result.edits.photos.filter((item) => !item.isDeleted);
      }
    });

    await this.recListImageWithThumbnailSrc(request.imagesNew).then(res => request.imagesNew = res);
    await this.recListImageWithThumbnailSrc(request.imagesUpdate).then(res => request.imagesUpdate = res);

    this.markAsPristine();
    return request;
  }

  recListImageWithThumbnailSrc = async (imageList: TaskImageRecDto[]) => {
    const promises = imageList?.map(async image => {
      const imageRec = { ...image }
      imageRec.imageThumbnail = await this.taskService.getThumbnailFromImage(image.image);
      return imageRec;
    })

    return Promise.all(promises)
  }


  clearEdit(): void {
    this.prepareEdit();
  }

  endEdit() {
    this.clearResource();
    delete this.edits;
    this.subTaskResults.forEach((result) => { delete result.edits; });
  }

  subTaskResultResponsibleForm(subTask: SubTask): FormControl {
    let id = "result-" + subTask.subTaskId + "-responsible";
    return this.edits.formGroup.get(id) as FormControl;
  }

  workingStatus(subTask: SubTask) {
    let id = "result-" + subTask.subTaskId + "-comp";
    if (this.edits.formGroup.get(id)?.value) return "完了"
    id = "result-" + subTask.subTaskId + "-working";
    if (this.edits.formGroup.get(id)?.value) return "作業中"
    return "";
  }

  subTaskResultCompleteForm(subTask: SubTask): FormControl {
    let id = "result-" + subTask.subTaskId + "-comp";
    return this.edits.formGroup.get(id) as FormControl;
  }

  subTaskResponsibleValue(subTask: SubTask) {
    if (this.commonService.config.task.responsibleIsLoginUser) {
      let id = "result-" + subTask.subTaskId + "-responsibleUser";
      let form = this.edits.formGroup.get(id) as FormControl;
      if (form && form.value !== "") {
        let userId = form.value;
        let result = this.subTaskResults.find((result) => result.subTaskId == subTask.subTaskId);
        return userId + ":" + result.edits.responsibleName;
      }
      form = this.subTaskResultResponsibleForm(subTask);
      if (form) return form.value;
      return "";
    } else {
      let form = this.subTaskResultResponsibleForm(subTask);
      if (form) return form.value;
      return "";
    }
  }

  subTaskResultCompleteDateTime(subTask: SubTask): string {
    let result = this.subTaskResults.find((item) => item.subTaskId === subTask.subTaskId);
    return result?.edits.completeDateTime;
  }

  markAsDirty(): void {
    if (!this.edits) return;
    this.edits.formGroup.markAsDirty();
  }

  markAsPristine(): void {
    if (!this.edits) return;
    this.edits.formGroup.markAsPristine();
  }

  isDirty(): boolean {
    if (!this.edits) return false;
    return this.edits.formGroup.dirty;
  }

  isValid(): boolean {
    return true;
  }

  mainResponsibleChanged(responsible: string) {
    if (!this.edits) return;
    this.checkStartAndComplete();
  }

  setResponsible(responsible: string) {
    if (!this.edits) return;
    this.edits.responsible.setValue(responsible);
    this.markAsDirty();
  }

  setResultResponsible() {
    if (!this.edits) return;
    let responsible = this.edits.responsible.value;
    this.subTaskResults.forEach((result) => {
      if (result.edits.responsible.value === "") {
        result.edits.responsible.setValue(responsible);
      }
    });
    this.markAsDirty();
  }

  checkStartAndComplete() {
    this.errorMessage = undefined;
    if (!this.edits) return;
    if (this.commonService.config.task.responsibleIsLoginUser) {
      if (this.edits.responsible.value === "" && this.edits.responsibleUser.value === "") {
        this.errorMessage = "担当者が入力されていない状態で、タスク開始できません。";
        return;
      }
    } else {
      if (this.edits.responsible.value == "") {
        this.errorMessage = "担当者が入力されていない状態で、タスク開始できません。";
        return;
      }
    }

    for (let result of this.subTaskResults) {
      if (result.edits.isComplete.value) {
        let subTask = this.subTasks.find((item) => item.subTaskId == result.subTaskId);
        if (subTask?.photoReport && (!result.edits.photos || result.edits.photos.length == 0 || result.edits.photos.every(photo => photo.isDeleted))) {
          this.errorMessage = "報告写真が無い状態で、報告写真が必要な子タスクを完了にはできません。";
          return;
        }
        if (this.commonService.config.task.isWorktimeResultRequired
          && this.formNumberValue(result.edits.workTimeHResult) === 0
          && this.formNumberValue(result.edits.workTimeMResult) === 0) {
            this.errorMessage = "作業時間実績が入力されていない状態で子タスクを完了にはできません。";
            return;
        }
      }
    }
  }

  isStartedChanged(value: boolean) {
    this.checkStartAndComplete();
  }

  resultWorking(value: boolean, result: SubTaskResult) {
    if (value && !result.isComplete) {
      result.edits.isComplete.setValue(false, {emitEvent: false});
      result.edits.completeDateTime = "";
      this.checkStartAndComplete();
    }
  }

  resultWorkingStatus(value: string, result: SubTaskResult) {
    switch(value) {
      case "0": {
        result.edits.isComplete.setValue(false, {emitEvent: false});
        result.edits.completeDateTime = "";
        result.edits.isWorking.setValue(false, {emitEvent: false});
        break;
      }
      case "1": {
        result.edits.isComplete.setValue(false, {emitEvent: false});
        result.edits.completeDateTime = "";
        result.edits.isWorking.setValue(true, {emitEvent: false});
        break;
      }
      case "2": {
        result.edits.isComplete.setValue(true, {emitEvent: false});
        result.edits.completeDateTime = this.commonService.formatDateTime(new Date());
        result.edits.isWorking.setValue(false, {emitEvent: false});
        break;
      }
    };
    this.markAsDirty();
    this.checkStartAndComplete();
  }

  resultComplete(value: boolean, result: SubTaskResult) {
    if (value) {
      result.edits.completeDateTime = this.commonService.formatDateTime(new Date());
      result.edits.isWorking.setValue(false, {emitEvent: false});
    } else {
      result.edits.completeDateTime = "";
      result.edits.isWorking.setValue(result.isWorking);
    }
    this.checkStartAndComplete();
  }

  resultCompleteWorkTime(value: boolean) {
    this.checkStartAndComplete();
  }

  /*
  updatePhoto(subTask: SubTaskResult, photo: PhotoItem) {
    if (!subTask.edits) return;
    let photoItem = subTask.edits.photos.find((item) => item.photoId === photo.photoId);
    if (photoItem) {
      photoItem.title = photo.title;
      photoItem.dataUrl = photo.dataUrl;
    }
  }
  */
  addPhoto(subTask: SubTaskResult, photo: PhotoItem) {
    if (!subTask.edits) return;
    subTask.edits.photos.push(photo);
  }

  downloadMultiFile() {
    const request: ReqTaskFile = {
      taskId: this.taskId,
      access: this.commonService.loginUser,
      mode: -1
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.taskRecFileInfoSearch(request).subscribe(
      (response: RspTaskFileInfo) => {
        if(response){
          this.receiveDownloadMultiFile(response);
        }
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDownloadMultiFile(response: RspTaskFileInfo) {
    var zip = new JSZip();
    for (let i = 0; i < response.taskRecFileInfo.length; i++) {        
    let f = response.taskRecFileInfo[i];    
    zip.file(f.attachmentFilename, this.dataURLtoBlob(f.attachment), {comment: f.attachmentTitle});
    }
    zip.generateAsync({type: 'blob'})
    .then((content) => {
        const navigator: any = window.navigator;
        if (navigator.msSaveBlob) {
          // For IE10/11 : No download functionality
          navigator.msSaveBlob(content,  'zip_'+ this.taskService.getDateString(new Date()));
        } else {
          // Other browser
          const url = URL.createObjectURL(content);
          let download = document.createElement("a");
          download.href = url;
          download.setAttribute('download', 'zip_'+ this.taskService.getDateString(new Date()))
          download.click();
        }
        this.commonService.closeSpinner();
    }).catch((err) => {
      this.commonService.closeSpinner();
    });
  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  formNumberValue(form: FormControl) {
    let val = parseInt(form.value);
    if (Number.isNaN(val)) return 0;
    return val;
  }
}
