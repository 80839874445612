import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Spmt10301Dto } from 'src/app/response/rsp-spmt10301-search';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSpmt10301GetDetail } from 'src/app/request/req-spmt10301-get-detail';
import { Spmt10301GetDetailDto, RspSpmt10301GetDetail } from 'src/app/response/rsp-spmt10301-get-detail';
import { AccessInfo } from 'src/app/page/spmt10301/spmt10301.component';
import { MatPaginator } from '@angular/material/paginator';
import { MessageService } from 'src/app/service/message.service';
import { MI100001, MI200003 } from 'src/app/common/const-message-id';
import { ReqPageDto } from 'src/app/request/req-page-dto';

class Spmt10301DetailDialogConstant{
  public static readonly DELFLG_STATUS: string = '1';
  public static readonly MAXITEMS: number = 1000;
  public static readonly LIMIT_RECORD = 1001;
  public static readonly ZERO = 0;
  public static readonly MESSAGESEARCHING = '検索中・・・';
  public static readonly EMPTY_STRING = '';
  public static readonly REDBLACK_FLG_01 = '01';
}

@Component({
  selector: 'app-spmt10301-detail-dialog',
  templateUrl: './spmt10301-detail-dialog.component.html',
  styleUrls: ['./spmt10301-detail-dialog.component.css']
})
export class Spmt10301DetailDialogComponent implements OnInit {

  public columnIdFirst: string[] = ["productCd","standardNm","outStockQty","redBlackFlgNm","reqStatusNm","outOrderPriceExc","outPosCostExc","outSellPriceExc","outReqDate","outApproveDate","outCrtDate","outCancelDate","slipDtlComment"]
  public columnIdSecond: string[] = ["productNm","outOrderAmt","outPosAmt","outSellAmt","inApproveDate"]

  public columnDefsItemsFirst: TableColumnDef[] = [
    {columnId: "productCd", header: "商品コード", width: 105, align: "left",rowspan: 1},
    {columnId: "standardNm", header: "規格", width: 85, align: "left",rowspan: 1},
    {columnId: "outStockQty", header: "出庫数量", width: 75, align: "right",rowspan: 1, numberPipe: "1.0-0"},
    {columnId: "redBlackFlgNm", header: "赤黒区分", width: 60, align: "left",rowspan: 2},
    {columnId: "reqStatusNm", header: "申請状態", width: 80, align: "left",rowspan: 2},
    {columnId: "outOrderPriceExc", header: "発注単価", width: 75, align: "right",rowspan: 1, numberPipe: "1.2-2"},
    {columnId: "outPosCostExc", header: "POS原価", width: 75, align: "right",rowspan: 1, numberPipe: "1.2-2"},
    {columnId: "outSellPriceExc", header: "売価", width: 75, align: "right",rowspan: 1, numberPipe: "1.2-2"},
    {columnId: "outReqDate", header: "出庫申請日", width: 75, align: "center",rowspan: 2},
    {columnId: "outApproveDate", header: "出庫承認日", width: 75, align: "center",rowspan: 1},
    {columnId: "outCrtDate", header: "出庫訂正日", width: 75, align: "center",rowspan: 2},
    {columnId: "outCancelDate", header: "出庫取消日", width: 75, align: "center",rowspan: 2},
    {columnId: "slipDtlComment", header: "詳細コメント", width: 155, align: "left",rowspan: 2}
  ];

  public columnDefsItemsSecond: TableColumnDef[] = [
    {columnId: "productNm", header: "商品名称", width: 265, align: "left",rowspan: 1, colspan: 3},
    {columnId: "outOrderAmt", header: "発注金額", width: 75, align: "right",rowspan: 1, colspan: 1, numberPipe: "1.2-2"},
    {columnId: "outPosAmt", header: "POS金額", width: 75, align: "right",rowspan: 1, colspan: 1, numberPipe: "1.2-2"},
    {columnId: "outSellAmt", header: "売価金額", width: 75, align: "right",rowspan: 1, colspan: 1, numberPipe: "1.2-2"},
    {columnId: "inApproveDate", header: "入庫承認日", width: 75, align: "center",rowspan: 1, colspan: 1}
  ]

  public itemTableWidth: any;
  public itemsShow: Spmt10301GetDetailDto[] = [];
  public items: Spmt10301GetDetailDto[] = [];
  public itemRecordCount: number = 0;
  public outStoreNm: string;
  public inStoreNm: string;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10301DetailDialogComponent>,
    public httpBasic: HttpBasicService,
    private message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: {row: Spmt10301Dto, accessInfo: AccessInfo},
    public commonService: CommonService) { }

  ngOnInit() {
    this.calcTableWidth();
    this.getDetailPrc();
  }

  calcTableWidth() {
    this.itemTableWidth = {"width": "100%"};
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: Spmt10301DetailDialogConstant.ZERO,
      dispNum: Spmt10301DetailDialogConstant.LIMIT_RECORD
    }
  }

  getDetailPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10301DetailDialogConstant.MESSAGESEARCHING);
    const request: ReqSpmt10301GetDetail = {
      slipNo: this.data.row.slipNo,
      access: this.data.accessInfo,
      pageDto: this.getPageDto()
    } 
    const subsc = this.httpBasic.getDetailSpmt10301(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10301GetDetail) {
    this.commonService.closeSpinner();
    if(this.httpBasic.handleAppError(response)){
      return;
    }

    if (response.results.length == 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001])
      return;
    }

    if (response.results.length > Spmt10301DetailDialogConstant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,Spmt10301DetailDialogConstant.MAXITEMS));
    }

    response.results.slice(0,1000).forEach((item: Spmt10301GetDetailDto) => {
      this.items.push(item)
    })
    this.itemRecordCount = this.items.length ?? 0;
    this.pageChanged();
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  colStyle(item: Spmt10301GetDetailDto){
    if(item.delflg === Spmt10301DetailDialogConstant.DELFLG_STATUS){
      return 'border-right-gray bg-gray'
    }
    if(item.redBlackFlg !== Spmt10301DetailDialogConstant.REDBLACK_FLG_01){
      return 'text-color-red';
    }
    return Spmt10301DetailDialogConstant.EMPTY_STRING;
  }
}
