import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ItemRec } from '../common/item-rec';
import { Role } from '../page/mng/role/role';
import { ReqAccess } from '../request/req-access';
import { ReqAllStoreParamItemEdit } from '../request/req-all-store-param-item-edit';
import { ReqAllStoreParamItemSearch, ReqAllStoreParamItemSearchDto } from '../request/req-all-store-param-item-search';
import { ReqCsvInventoryCheck, ReqCsvInventoryCheckDto } from '../request/req-csv-inventory-check';
import { ReqCsvInventoryHistoryDelete } from '../request/req-csv-inventory-history-delete';
import { ReqCsvInventoryHistoryDetailDelete } from '../request/req-csv-inventory-history-detail-delete';
import { ReqCsvInventoryHistoryDetailSearch, ReqCsvInventoryHistoryDetailSearchDto } from '../request/req-csv-inventory-history-detail-search';
import { ReqCsvInventoryHistorySearch, ReqCsvInventoryHistorySearchDto } from '../request/req-csv-inventory-history-search';
import { ReqCsvInventoryUpdate, ReqCsvInventoryUpdateDto } from '../request/req-csv-inventory-update';
import { ReqCsvNoisecutPromCheck } from '../request/req-csv-noisecut-prom-check';
import { ReqCsvNoisecutUpdate } from '../request/req-csv-noisecut-prom-update';
import { CsvParamItemCheckDto, ReqCsvParamItemCheck } from '../request/req-csv-param-item-check';
import { CsvParamItemStoreCheckDto, ReqCsvParamItemStoreCheck } from '../request/req-csv-param-item-store-check';
import { ReqCsvParamItemStoreCsvUpdate } from '../request/req-csv-param-item-store-update';
import { CsvParamItemCsvUpdateDto, ReqCsvParamItemCsvUpdate } from '../request/req-csv-param-item-update';
import { ReqCsvSeasonalCtgTargetUpdate, ReqCsvSeasonalCtgTargetUpdateDto } from '../request/req-csv-seasonal-ctg-target-update';
import { ReqCsvUpDownCheck, ReqCsvUpDownCheckDto } from '../request/req-csv-up-down-check';
import { ReqCsvUpDownUpdateDto, ReqForecastUpDown } from '../request/req-csv-up-down-update';
import { ReqCsvVisitorsCheck, ReqCsvVisitorsCheckDto } from '../request/req-csv-visitors-check';
import { ReqCsvVisitorsUpdate, ReqCsvVisitorsUpdateDto } from '../request/req-csv-visitors-update';
import { ReqCtgHistSearch } from '../request/req-ctg-hist-search';
import { ReqCtgPath } from '../request/req-ctg-path';
import { ReqCtgSearch } from '../request/req-ctg-search';
import { ReqDownload } from '../request/req-download';
import { ReqEmojiSearch } from '../request/req-emoji-search';
import { ReqCameraLayoutConfigUpdate } from '../request/req-execute-camera-config-update';
import { ReqExecuteCameraLayoutAdd } from '../request/req-execute-camera-layout-add';
import { ReqCameraLayoutDelete } from '../request/req-execute-camera-layout-delete';
import { ReqCameraLayoutUpdate } from '../request/req-execute-camera-layout-update';
import { ReqExecuteQuery } from '../request/req-execute-query';
import { ReqForecastUpDownAddNew } from '../request/req-forecast-up-down-add-new';
import { ReqGetDlList } from '../request/req-get-dllist';
import { ReqGetExternalLink } from '../request/req-get-external-link';
import { ReqGetRole } from '../request/req-get-role';
import { ReqGetStoreGroupName } from '../request/req-get-store-group-name';
import { ReqGetStoreGroupType } from '../request/req-get-store-group-type';
import { ReqGetUsers } from '../request/req-get-users';
import { ReqItemByJanList } from '../request/req-item-by-jan-list';
import { ReqItemHistSearch } from '../request/req-item-hist-seach';
import { ReqItemReplaceAdd, ReqItemReplaceAddDto } from '../request/req-item-replace-add';
import { ReqItemReplaceDel, ReqItemReplaceDelDto } from '../request/req-item-replace-delete';
import { ReqItemReplaceInit } from '../request/req-item-replace-init';
import { ReqItemReplaceSearch } from '../request/req-item-replace-search';
import { ReqItemSearch } from '../request/req-item-search';
import { ReqItemSimpleSearch } from '../request/req-item-simple-seach';
import { ReqLogin } from '../request/req-login';
import { ReqLossRateCtgUpdate } from '../request/req-loss-rate-ctg-update';
import { ReqLossRateSearch } from '../request/req-loss-rate-search';
import { ReqNoisecutHistory } from '../request/req-noisecut-history';
import { NoisecutSearchConditionDto, ReqNoisecutSearch } from '../request/req-noisecut-search';
import { ReqNoisecutUpdate } from '../request/req-noisecut-update';
import { ReqOpestatDistrRequest } from '../request/req-opestat-distr';
import { ReqOpestatMain } from '../request/req-opestat-main';
import { ReqOrderEdit } from '../request/req-order-edit';
import { ReqOrderStopItemEdit } from '../request/req-order-stop-item-edit';
import { OrderStopTermCtgAddDto, ReqOrderStopTermCtgAdd } from '../request/req-order-stop-term-ctg-add';
import { ReqOrderStopTermCtgDelete, ReqOrderStopTermCtgDeleteDto } from '../request/req-order-stop-term-ctg-delete';
import { OrderStopTermItemAddDto, ReqOrderStopTermItemAdd } from '../request/req-order-stop-term-item-add';
import { OrderStopTermItemDelDto, ReqOrderStopTermItemDel } from '../request/req-order-stop-term-item-delete';
import { ReqOrderStopTermItemInit } from '../request/req-order-stop-term-item-init';
import { ReqStoreInit } from '../request/req-order-stop-term-item-store-search';
import { ReqOrderStopTermStoreInit } from '../request/req-order-stop-term-store-init';
import { OrderStopTermSuppAddDto, ReqOrderStopTermSuppAdd } from '../request/req-order-stop-term-supp-add';
import { OrderStopTermSuppDelDto, ReqOrderStopTermSuppDel } from '../request/req-order-stop-term-supp-delete';
import { ReqOrderStopTermSuppInit } from '../request/req-order-stop-term-supp-init';
import { OrderStopTermSuppSearchDto, ReqOrderStopTermSuppSearch } from '../request/req-order-stop-term-supp-search';
import { ReqPageDto } from '../request/req-page-dto';
import { ReqParamCtgEdit } from '../request/req-param-ctg-edit';
import { ReqParamCtgSearch, ReqParamCtgSearchDto } from '../request/req-param-ctg-search';
import { ReqParamItemEdit } from '../request/req-param-item-edit';
import { ReqUpdPasswd } from '../request/req-passwd';
import { ReqPbiMenu } from '../request/req-pbi-menu';
import { ReqPbiToken } from '../request/req-pbi-token';
import { ReqRackAdd } from '../request/req-rack-add';
import { ReqRackEdit } from '../request/req-rack-edit';
import { ReqRackItem } from '../request/req-rack-item';
import { ReqRackItemSearch } from '../request/req-rack-item-search';
import { ReqRackRemove } from '../request/req-rack-remove';
import { ReqRackSearch } from '../request/req-rack-search';
import { ReqReport } from '../request/req-report';
import { ReqReportDef } from '../request/req-report-def';
import { ReqStockEdit } from '../request/req-stock-edit';
import { ReqAddStoreGroup, StoreGroupDto } from '../request/req-store-group-add';
import { ReqStoreGroupNameAddDto, StoreGroupNameAddDto } from '../request/req-store-group-name-add';
import { ReqStoreGroupNameDeleteDto, StoreGroupNameDeleteDto } from '../request/req-store-group-name-delete';
import { ReqStoreGroupNameUpdateDto, StoreGroupNameUpdateDto } from '../request/req-store-group-name-update';
import { ReqStoreGroupTypeAddDto, StoreGroupTypeAddDto } from '../request/req-store-group-type-add';
import { ReqStoreGroupTypeDeleteDto, StoreGroupTypeDeleteDto } from '../request/req-store-group-type-delete';
import { ReqStoreGroupTypeSearch } from '../request/req-store-group-type-search';
import { ReqStoreGroupTypeUpdateDto, StoreGroupTypeUpdateDto } from '../request/req-store-group-type-update';
import { ReqTaskEmoteImageUpdate } from '../request/req-task-emote-image-update';
import { ReqUpdRole } from '../request/req-upd-role';
import { ReqUpdUser } from '../request/req-upd-user';
import { ReqUserConfigUpdate } from '../webservice/user-config-update';
import { Rsp } from '../response/rsp';
import { RoleMenuDto } from '../response/rsp-get-role';
import { RspStoreGroupList } from '../response/rsp-store-group-search';
import { ReqDeleteTaskRec, ReqSearchTaskRec, ReqSearchTaskResultRec, ReqSubTaskResult, ReqSubTaskResultOtherStoresSearch, ReqTaskFile, ReqTaskFileOne, ReqTaskImageData, ReqTaskImageThumbnailData, ReqTaskRecOtherStoresSearch, ReqUpdateTaskRec, ReqUpdateTaskResultRec } from '../webservice/task';
import { ReqAllStoreParamCtgEdit } from '../request/req-all-store-param-ctg-edit';
import { ReqAllStoreParamCtgSearch, ReqAllStoreParamCtgSearchDto } from '../request/req-all-store-param-ctg-search';
import { ReqCsvSeasonalCtgTargetCheck, ReqCsvSeasonalCtgTargetCheckDto } from '../request/req-csv-seasonal-ctg-target-check';
import { StoreDto } from '../request/req-csv-up-down-check';
import { ReqForecastUpDownAdd, ReqForecastUpDownAddDto } from '../request/req-forecast-up-down-add';
import { ReqForecastUpDownDelete, ReqForecastUpDownDeleteDto } from '../request/req-forecast-up-down-delete';
import { ReqForecastUpDownInit } from '../request/req-forecast-up-down-init';
import { ReqOrderStopTermCtgInit } from '../request/req-order-stop-term-ctg-init';
import { OrderStopTermStoreAddDto, ReqOrderStopTermStoreAdd } from '../request/req-order-stop-term-store-add';
import { ReqOrderStopTermStoreDelete, ReqOrderStopTermStoreDeleteDto } from '../request/req-order-stop-term-store-delete';
import { CommonService } from './common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReqMenu, ReqMenuEdit, ReqMenuItemAdd } from '../webservice/menu';
import { ReqNonInventoryDataSearch } from '../request/req-non-inventory-data-search';
import { ReqSpmt10301Init } from '../request/req-spmt10301-init';
import { ReqSpmt10301Search } from '../request/req-spmt10301-search';
import { ReqSpmt10301GetDetail } from '../request/req-spmt10301-get-detail';
import { ReqSpmt10301Confirm } from '../request/req-spmt10301-confirm';
import { ReqSpmt10301Exclusion } from '../request/req-spmt10301-exclusion';
import { ReqSpvw10231Init } from '../request/req-spvw10231-init';
import { ReqSpvw10231Search } from '../request/req-spvw10231-search';
import { ReqGetDropdownListOfUser } from '../request/req-get-dropdown-list-of-user';
import { ReqSpmt10331Init } from '../request/req-spmt10331-init';
import { ReqSpmt10331Search } from '../request/req-spmt10331-search';
import { ReqSpmt10331GetDetail } from '../request/req-spmt10331-get-detail';
import { ReqSpmt10331Confirm } from '../request/req-spmt10331-confirm';
import { ReqSpmt10331Exclusion } from '../request/req-spmt10331-exclusion';
import { Spbs00031UpdateDto, ReqSpbs00031Update } from '../request/req-spbs00031-update';
import { Spbs00031SearchDto, ReqSpbs00031Search } from '../request/req-spbs00031-search';
import { Spiv00061SearchDto, ReqSpiv00061Search } from '../request/req-spiv00061-search';
import { Spiv00061UpdateDto, ReqSpiv00061Update } from '../request/req-spiv00061-update';
import { Req } from '../request/req';
import { RspSpmt10341Init } from '../response/rsp-spmt10341-init';
import { ReqUserInfoSearch } from '../request/req-user-info-search';
import { RspUserInfo } from '../response/rsp-user-info';
import { ReqSpmt10271GetDetail } from '../request/req-spmt10271-detail';
import { ReqSpmt10271Search } from '../request/req-spmt10271-search';
import { ReqSpmt10271Confirm } from '../request/req-spmt10271-dialog-confirm';
import { ReqSpmt10271Init } from '../request/req-spmt10271-init';
import { ReqSpmt10271Exclusion } from '../request/req-spmt10271-exclusion';
import { ReqSpmt10341Search } from '../request/req-spmt10341-search';
import { RspSpmt10341Search } from '../response/rsp-spmt10341-search';
import { ReqSpmt10341GetDetail } from '../request/req-spmt10341-get-detail';
import { RspSpmt10341GetDetail } from '../response/rsp-spmt10341-get-detail';
import { ReqSpmt10341Confirm } from '../request/req-spmt10341-get-confirm';
import { RspSortConditionConfig } from '../response/rsp-sort-condition-config';
import { ReqSortConditionConfig } from '../request/req-sort-condition-config';
import { ReqGetStoreList } from '../request/req-get-store-list';
import { ReqSpmt10261Search } from '../request/req-spmt10261-search';
import { RspSpmt10261Search } from '../response/rsp-spmt10261-search';
import { ReqSpmt10261Confirm } from '../request/req-spmt10261-confirm';
import { ReqSpmt10261Delete } from '../request/req-spmt10261-delete';
import { RspSpmt10261Delete } from '../response/rsp-spmt10261-delete';
import { ReqSpmt10261SlipList, RspSpmt10261SlipList } from '../dialog/spmt10261-slip-list-dialog/spmt10261-slip-list-dialog.component';
import { ReqSpmt10291Search } from '../request/req-spmt10291-search';
import { ReqSpmt10291Confirm } from '../request/req-spmt10291-confirm';
import { ReqSpmt10291Init } from '../request/req-spmt10291-init';
import { ReqSpmt10291Exclusion } from '../request/req-spmt10291-exclusion';
import { ReqSpmt10291GetDetail } from '../request/req-spmt10291-get-detail';
import { ReqSpmt10321lInit } from '../request/req-spmt10321-init';
import { ReqSpmt10321Search } from '../request/req-spmt10321-search';
import { ReqSpmt10321Exclusion } from '../request/req-spmt10321-exclusion';
import { ReqSpmt10321GetDetail } from '../request/req-spmt10321-get-detail';
import { ReqSpmt10321Confirm } from '../request/req-spmt10321-confirm';
import { ReqSpmt10311Init } from '../request/req-spmt10311-init';
import { ReqSpmt10311GetSlipNoList } from '../request/req-spmt10311-get-slipno-list';
import { ReqSpmt10311Delete } from '../request/req-spmt10311-delete';
import { ReqSpmt10311Confirm } from '../request/req-spmt10311-confirm';
import { ReqSpmt10311GetDetail } from '../request/req-spmt10311-get-detail';
import { ReqSpmt10311Search } from '../request/req-spmt10311-search';
import { ReqSpmt10311CheckBeforeSearch } from '../request/req-spmt10311-check-before-search';
import { ReqSpvw10241Init } from '../request/req-spvw10241-init';
import { ReqSpvw10241Search } from '../request/req-spvw10241-search';
import { ReqSpvw10251Init } from '../request/req-spvw10251-init';
import { ReqSpvw10251Search } from '../request/req-spvw10251-search';
import { ReqSpvw10251GetDetail } from '../request/req-spvw10251-get-detail';
import { ReqSpmt10311Exclusion } from '../request/req-spmt10311-exclusion';

@Injectable({
  providedIn: 'root'
})
export class HttpBasicService {

  private httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    }),
    body: null
  };

  // CSV Export
  static readonly SCREEN_MODE_PLUS: string = 'PLUS';
  static readonly SCREEN_MODE_MINUS: string = 'MINUS';
  public readonly FILE_TYPE_CSV: string = 'CSV';
  public readonly FILE_TYPE_PDF: string = 'PDF';
  public readonly FILE_TYPE_ZIP: string = 'ZIP';
  public readonly FILE_TYPE_EXCEL: string = 'EXCEL';

  // Current Active Requests
  public activeRequests: number = 0;

  constructor(private http: HttpClient,
    private commonService: CommonService,
    private snackBar: MatSnackBar) { }
  /*
    constructor(private http: HttpClient,
    private commonService : CommonService,
    private dateAdapter : DateAdapter<any>) { }
  */

  post(relUrl: string, request: any): Observable<any> {
    var url = this.commonService.config.wsBaseUrl + relUrl;
    return this.http.post(url, request, this.httpOptions);
  }

  generalRequest(serviceName: string, request: any) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post(serviceName, request);
  }

  handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      this.fatalErrorDialog("Network/内部エラー", "サーバーとの通信でエラーが発生しました。");
    } else if (error.error instanceof ProgressEvent) {
      // Timeout ?
      console.error('An error occurred:')
      console.error(error.error);
      this.fatalErrorDialog("Networkエラー", "サーバーとの通信でエラーが発生しました。");
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      this.fatalErrorDialog("サーバーエラー", `エラー・ステータス：${error.status}`);
    }
  }

  handleAppError(response: Rsp): boolean {
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return true;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return true;
    }
    return false;
  }

  fatalErrorDialog(title: string, msg: string) {
    this.commonService.openFatalErrorDialog(title, msg);
  }

  beforeRequestStart(message = "読込中・・・", title = this.commonService.pageTitle): void {
    this.activeRequests++;
    if (this.activeRequests === 1) {
      this.commonService.openSpinner(title, message);
    }
  }

  afterRequestComplete(): void {
    if (this.activeRequests > 0) {
      this.activeRequests--;
    }
    if (this.activeRequests === 0) {
      this.commonService.closeSpinner();
    }
  }

  afterRequestError(error: any): void {
    if (this.activeRequests > 0) {
      this.activeRequests--;
    }
    this.commonService.closeSpinner();
    this.handleError(error);
  }

  login(userid: string, passwd: string): Observable<any> {
    var access: ReqAccess;
    // if (this.commonService.config.myResourceDir && this.commonService.config.myResourceDir != "") {
    //   access = { userId: userid, myResourceDir: this.commonService.config.myResourceDir };
    // } else {
    //   access = { userId: userid };
    // }
    // this.commonService.config.myResourceDir = "Hello";
    // console.log(this.commonService.config.myResourceDir);
    // access = { userId: userid, myResourceDir: this.commonService.config.myResourceDir };
    access = { userId: userid, myResourceDir: "" };
    var request: ReqLogin = { loginCondition: { password: passwd }, access: access };

    //request.access.functionName = this.commonService.pageTitle;
    return this.post("Login", JSON.stringify(request));
  };

  logout() {

  }

  getMenu() {
    var request: ReqMenu = { access: { ...this.commonService.loginUser } };
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Menu", JSON.stringify(request));
  }

  ctgSearch(storeCd: string, ctgLevel: number, ctgCd0: string, ctgCd1: string, ctgCd2: string, ctgCd3: string) {
    var request: ReqCtgSearch = {
      access: { ...this.commonService.loginUser },
      categorySearchCondition: {
        storeCdFv: storeCd,
        ctgLevelFn: ctgLevel,
        ctgCd0Fv: ctgCd0,
        ctgCd1Fv: ctgCd1,
        ctgCd2Fv: ctgCd2,
        ctgCd3Fv: ctgCd3
      }
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post("CategorySearch", JSON.stringify(request));
  }

  rackSearch(storeCd: string, rackLevel: number, rackCd1: string, rackCd2: string, rackCd3: string) {
    var request: ReqRackSearch = {
      access: { ...this.commonService.loginUser },
      storeCdFv: storeCd,
      rackLevelFn: rackLevel,
      rackCd1Fn: rackCd1,
      rackCd2Fn: rackCd2,
      rackCd3Fn: rackCd3
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post("RackSearch", JSON.stringify(request));
  }

  itemSearch(request: ReqItemSearch) {
    request.access.functionName = this.commonService.pageTitle;
    if (request.multiOrderDate == undefined) request.multiOrderDate = false;
    if (this.commonService.config.itemQuery?.janZeroPadding?.enable) {
      if (request.itemSearchCondition.itemCdEqualSearch && request.itemSearchCondition.itemCdFv !== "") {
        if (request.itemSearchCondition.itemCdFv.length < this.commonService.config.itemQuery.janZeroPadding.toLength) {
          request.itemSearchCondition.itemCdFv = this.commonService.zeroPadding(
            request.itemSearchCondition.itemCdFv,
            this.commonService.config.itemQuery.janZeroPadding.toLength
          );
        }
      }
    }
    return this.post("ItemSearch", JSON.stringify(request));
  }

  // 2020/04/20: Nam added
  itemSimpleSearch(request: ReqItemSimpleSearch) {
    request.access.functionName = this.commonService.pageTitle;
    if (!request.itemSimpleSeachCondition.orderStopFlags) {
      request.itemSimpleSeachCondition.orderStopFlags = this.commonService.getOrderStopValues();
    }
    if (request.itemSimpleSeachCondition.withoutCase === undefined) {
      request.itemSimpleSeachCondition.withoutCase = false;
    }
    return this.post("ItemSimpleSearch", JSON.stringify(request));
  }
  // 2020/04/22: Nam added
  itemHistSearch(request: ReqItemHistSearch) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ItemHistSeach", JSON.stringify(request));
  }

  paramCtgSearch(condition: ReqParamCtgSearchDto) {
    var request: ReqParamCtgSearch = {
      access: { ...this.commonService.loginUser },
      paramCtgSearch: { ...condition }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ParamCtgSearch", JSON.stringify(request));
  }

  allStoreParamCtgSearch(condition: ReqAllStoreParamCtgSearchDto) {
    var request: ReqAllStoreParamCtgSearch = {
      access: { ...this.commonService.loginUser },
      allStoreParamCtgSearch: { ...condition }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("AllStoreParamCtgSearch", JSON.stringify(request));
  }

  updateOrderNum(itemRec: ItemRec, newValue: number) {
    var request: ReqOrderEdit = {
      access: { ...this.commonService.loginUser },
      orderEdit: {
        orderDateFv: this.commonService.salesDate,
        storeCdFv: itemRec.storeCdFv,
        itemCdFv: itemRec.itemCdFv,
        orderNumFn: newValue,
        versionFn: itemRec.orderNumVersionFn,
        closingTime: itemRec.closingTime
      }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderEdit", JSON.stringify(request));
  }

  updateStockNum(itemRec: ItemRec, newValue: number) {
    var request: ReqStockEdit = {
      access: { ...this.commonService.loginUser },
      stockEdit: {
        stockDateFv: this.commonService.salesDate,
        storeCdFv: itemRec.storeCdFv,
        itemCdFv: itemRec.itemCdFv,
        stockNumFn: newValue,
        versionFn: itemRec.stockNumVersionFn
      }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("StockEdit", JSON.stringify(request));
  }

  ctgPath(storeCd: string, ctgLevel: number, ctgCd0: string, ctgCd1: string, ctgCd2: string, ctgCd3: string) {
    var request: ReqCtgPath = {
      access: { ...this.commonService.loginUser },
      ctgPathCondition: {
        storeCdFv: storeCd,
        ctgLevelFn: ctgLevel,
        ctgCd0Fv: ctgCd0,
        ctgCd1Fv: ctgCd1,
        ctgCd2Fv: ctgCd2,
        ctgCd3Fv: ctgCd3
      }
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post("CtgPath", JSON.stringify(request));
  }

  updateCtgParam(request: ReqParamCtgEdit) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ParamCtgEdit", JSON.stringify(request));
  }

  updateItemParam(request: ReqParamItemEdit) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ParamItemEdit", JSON.stringify(request));
  }

  updateItemOrderStop(request: ReqOrderStopItemEdit) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopItem", JSON.stringify(request));
  }

  opestatMainSearch(request: ReqOpestatMain) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OpestatMain", JSON.stringify(request));
  }

  opestatDistrSearch(request: ReqOpestatDistrRequest) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OpestatDistr", JSON.stringify(request));
  }

  getReportDef(repId: string) {
    var request: ReqReportDef = {
      access: { ...this.commonService.loginUser },
      reportId: repId
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ReportDef", JSON.stringify(request));
  }

  getReportData(request: ReqReport) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Report", JSON.stringify(request));
  }

  getRole() {
    var request: ReqGetRole = {
      access: { ...this.commonService.loginUser },
    }

    request.access.functionName = this.commonService.pageTitle;
    return this.post("Role", JSON.stringify(request));
  }

  updRole(mode: number, role: Role) {
    let roleMenus: RoleMenuDto[] = [];
    for (let roleMenu of role.roleMenus) {
      if (!roleMenu.isSelected) continue;
      roleMenus.push({
        roleId: roleMenu.roleId,
        roleReadonlyFlag: roleMenu.roleReadonly ? 1 : 0,
        menuGroupId: roleMenu.menuGroupId,
        menuGroupName: roleMenu.menuGroupName,
        menuId: roleMenu.menuId,
        menuName: roleMenu.menuName,
        menuReadonlyFlag: roleMenu.menuReadonly ? 1 : 0
      });
    }

    var request: ReqUpdRole = {
      access: { ...this.commonService.loginUser },
      mode: mode,
      role: {
        roleId: role.roleId,
        roleName: role.roleName,
        trgetStoreFlag: role.trgetStoreFlag,
        roleLevel: role.roleLevel,
        roleComment: role.roleComment ? role.roleComment : "",
        version: role.version,
        userCount: role.userCount,
        roleMenus: roleMenus,
        roleMenusMobile: [],
        roleConfig: ""
      }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("UpdRole", JSON.stringify(request));
  }

  updPasswd(passwd: string) {
    var request: ReqUpdPasswd = {
      access: { ...this.commonService.loginUser },
      passwd: passwd
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Passwd", JSON.stringify(request));
  }

  getUsers(storeCd: string) {
    var request: ReqGetUsers = {
      access: { ...this.commonService.loginUser },
      storeCd: storeCd
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("GetUsers", JSON.stringify(request));
  }

  updUser(mode: number,
    orgUserId: string,
    orgStoreCd: string,
    storeCd: string,
    userId: string,
    userName: string,
    passwd: string,
    roleId: number
  ) {
    var request: ReqUpdUser = {
      access: { ...this.commonService.loginUser },
      mode: mode,
      orgUserId: orgUserId,
      orgStoreCd: orgStoreCd,
      user: {
        storeCd: storeCd,
        storeName: "",
        userId: userId,
        userName: userName,
        passwd: passwd,
        roleId: roleId,
        roleName: ""
      }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("UpdUser", JSON.stringify(request));
  }

  getDlList(storeCd: string) {
    var request: ReqGetDlList = {
      access: { ...this.commonService.loginUser },
      storeCd: storeCd
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("DlList", JSON.stringify(request));
  }

  download() {
    var request: ReqDownload = {
      access: { ...this.commonService.loginUser }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Download", JSON.stringify(request));
  }

  searchNoisecut(condition: NoisecutSearchConditionDto, page: ReqPageDto) {
    var request: ReqNoisecutSearch = {
      access: { ...this.commonService.loginUser },
      noisecutSearchCondition: condition,
      page: page
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("NoisecutSearch", JSON.stringify(request));
  }

  updateNoisecut(request: ReqNoisecutUpdate) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("NoisecutUpdate", JSON.stringify(request));
  }

  getNoisecutHistory(storeCd: string, itemCd: string, page: ReqPageDto) {
    let types = this.commonService.config.promTypes;
    let codes: string[] = [];
    for (let type of types) {
      codes.push(type.promType);
    }

    let request: ReqNoisecutHistory = {
      access: this.commonService.loginUser,
      storeCd: storeCd,
      itemCd: itemCd,
      typeCodes: codes,
      page: { ...page }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("NoisecutHistory", JSON.stringify(request));
  }

  getItemByJanList(storeCd: string, janList: string[], withoutCase?: boolean) {
    let request: ReqItemByJanList = {
      access: this.commonService.loginUser,
      storeCdFv: storeCd,
      janCdList: janList,
      withoutCase: withoutCase == undefined ? false : withoutCase
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ItemByJanList", JSON.stringify(request));
  }

  rackAdd(storeCd: string, level: number, rackCd1: number, rackCd2: number, rackCd3: number, rackName: string) {
    if (level == 1) {
      rackCd1 = 0;
      rackCd2 = 0;
      rackCd3 = 0;
    } else if (level == 2) {
      rackCd2 = 0;
      rackCd3 = 0;
    } else {
      rackCd3 = 0;
    }

    let request: ReqRackAdd = {
      access: this.commonService.loginUser,
      storeCdFv: storeCd,
      rackLevelFn: level,
      rackCd1Fn: rackCd1,
      rackCd2Fn: rackCd2,
      rackCd3Fn: rackCd3,
      rackNameFv: rackName
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("RackAdd", JSON.stringify(request));
  }

  rackEdit(storeCd: string, level: number, rackCd1: number, rackCd2: number, rackCd3: number, rackName: string) {
    let request: ReqRackEdit = {
      access: this.commonService.loginUser,
      storeCdFv: storeCd,
      rackLevelFn: level,
      rackCd1Fn: rackCd1,
      rackCd2Fn: rackCd2,
      rackCd3Fn: rackCd3,
      rackNameFv: rackName
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("RackEdit", JSON.stringify(request));
  }

  rackItem(storeCd: string, level: number, rackCd1: number, rackCd2: number, rackCd3: number, itemCds: string[]) {
    let request: ReqRackItem = {
      access: this.commonService.loginUser,
      storeCdFv: storeCd,
      rackLevelFn: level,
      rackCd1Fn: rackCd1,
      rackCd2Fn: rackCd2,
      rackCd3Fn: rackCd3,
      itemCds: itemCds
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("RackItem", JSON.stringify(request));
  }

  rackItemSearch(storeCd: string, level: number, rackCd1: number, rackCd2: number, rackCd3: number) {
    let request: ReqRackItemSearch = {
      access: this.commonService.loginUser,
      storeCdFv: storeCd,
      rackLevelFn: level,
      rackCd1Fn: rackCd1,
      rackCd2Fn: rackCd2,
      rackCd3Fn: rackCd3
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post("RackItemSearch", JSON.stringify(request));
  }

  rackRemove(storeCd: string, level: number, rackCd1: number, rackCd2: number, rackCd3: number) {
    let request: ReqRackRemove = {
      access: this.commonService.loginUser,
      storeCdFv: storeCd,
      rackLevelFn: level,
      rackCd1Fn: rackCd1,
      rackCd2Fn: rackCd2,
      rackCd3Fn: rackCd3
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("RackRemove", JSON.stringify(request));
  }

  getStoreGroupType() {
    let request: ReqGetStoreGroupType = {
      access: this.commonService.loginUser,
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("GetStoreGroupType", JSON.stringify(request));
  }

  getStoreGroupName(storeGroupTypeCd: number) {
    let request: ReqGetStoreGroupName = {
      access: this.commonService.loginUser,
      storeGroupTypeCdFn: storeGroupTypeCd
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("GetStoreGroupName", JSON.stringify(request));
  }

  getStoreParamItemSearch(condition: ReqAllStoreParamItemSearchDto) {
    var request: ReqAllStoreParamItemSearch = {
      access: { ...this.commonService.loginUser },
      allStoreParamItemSearch: { ...condition }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("AllStoreParamItemSearch", JSON.stringify(request));
  }

  updateAllStoreCtgParam(request: ReqAllStoreParamCtgEdit) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("AllStoreParamCtgEdit", JSON.stringify(request));
  }

  updateAllStoreItemParam(request: ReqAllStoreParamItemEdit) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("AllStoreParamItemEdit", JSON.stringify(request));
  }

  getExternalLink(storeCd: string, linkTypeId: string) {
    let request: ReqGetExternalLink = {
      access: this.commonService.loginUser,
      storeCdFv: storeCd,
      linkTypeIdFv: linkTypeId
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("GetExternalLink", JSON.stringify(request));
  }

  initOrderStopTermCtg(storeCdFv: String) {
    var request: ReqOrderStopTermCtgInit = {
      access: { ...this.commonService.loginUser },
      storeCdFv: storeCdFv,
      maxCtgLevel: this.commonService.config.maxCtgLevel
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermCtgInit", JSON.stringify(request));
  }

  addOrderStopTermCtg(orderStopTermCtgAddDto: OrderStopTermCtgAddDto) {
    var request: ReqOrderStopTermCtgAdd = {
      access: { ...this.commonService.loginUser },
      orderStopTermCtgAdd: orderStopTermCtgAddDto,
      maxCtgLevel: this.commonService.config.maxCtgLevel
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermCtgAdd", JSON.stringify(request));
  }

  deleteOrderStopTermCtg(orderStopTermCtgDeleteDto: ReqOrderStopTermCtgDeleteDto) {
    var request: ReqOrderStopTermCtgDelete = {
      access: { ...this.commonService.loginUser },
      orderStopTermCtgDelete: orderStopTermCtgDeleteDto,
      maxCtgLevel: this.commonService.config.maxCtgLevel
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermCtgDelete", JSON.stringify(request));
  }

  initOrderStopTermSupp(condition: ReqOrderStopTermSuppInit) {
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermSuppInit", JSON.stringify(condition));
  }

  addOrderStopTermSupp(condition: OrderStopTermSuppAddDto) {
    var request: ReqOrderStopTermSuppAdd = {
      access: { ...this.commonService.loginUser },
      orderStopTermSuppAdd: condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermSuppAdd", JSON.stringify(request));
  }
  deleteOrderStopTermSupp(condition: OrderStopTermSuppDelDto) {
    var request: ReqOrderStopTermSuppDel = {
      access: { ...this.commonService.loginUser },
      orderStopTermSuppDelete: condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermSuppDelete", JSON.stringify(request));
  }

  searchOrderStopTermSupp(condition: OrderStopTermSuppSearchDto) {
    var request: ReqOrderStopTermSuppSearch = {
      access: { ...this.commonService.loginUser },
      orderStopTermSuppSearch: condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermSuppSearch", JSON.stringify(request));
  }

  initOrderStopTermItem(storeCd: string) {
    var request: ReqOrderStopTermItemInit = {
      storeCdFv: storeCd,
      access: { ...this.commonService.loginUser }
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermItemInit", JSON.stringify(request));
  }

  storeSearchItem(condition: ReqStoreInit) {
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermItemStoreSearch", JSON.stringify(condition));
  }

  addOrderStopTermItem(condition: OrderStopTermItemAddDto) {
    var request: ReqOrderStopTermItemAdd = {
      access: { ...this.commonService.loginUser },
      orderStopTermItemAdd: condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermItemAdd", JSON.stringify(request));
  }

  deleteOrderStopTermItem(condition: OrderStopTermItemDelDto) {
    var request: ReqOrderStopTermItemDel = {
      access: { ...this.commonService.loginUser },
      orderStopTermItemDelete: condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermItemDelete", JSON.stringify(request));
  }

  initOrderStopTermStore() {
    var request: ReqOrderStopTermStoreInit = {
      access: { ...this.commonService.loginUser },
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermStoreInit", JSON.stringify(request));
  }

  addOrderStopTermStore(orderStopTermStoreAddDto: OrderStopTermStoreAddDto) {
    var request: ReqOrderStopTermStoreAdd = {
      access: { ...this.commonService.loginUser },
      orderStopTermStoreAdd: orderStopTermStoreAddDto,
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermStoreAdd", JSON.stringify(request));
  }

  deleteOrderStopTermStore(orderStopTermStoreDeleteDto: ReqOrderStopTermStoreDeleteDto) {
    var request: ReqOrderStopTermStoreDelete = {
      access: { ...this.commonService.loginUser },
      orderStopTermStoreDelete: orderStopTermStoreDeleteDto,
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("OrderStopTermStoreDelete", JSON.stringify(request));
  }

  ctgHistSearch(request: ReqCtgHistSearch) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("CtgHistSearch", JSON.stringify(request));
  }

  itemReplaceSearch(condition: ReqItemReplaceSearch) {
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("ItemReplaceSearch", JSON.stringify(condition));
  }

  initItemReplace() {
    var request: ReqItemReplaceInit = {
      access: { ...this.commonService.loginUser },
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ItemReplaceInit", JSON.stringify(request));
  }

  addItemReplace(condition: ReqItemReplaceAddDto) {
    var request: ReqItemReplaceAdd = {
      access: { ...this.commonService.loginUser },
      itemReplaceAdd: condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ItemReplaceAdd", JSON.stringify(request));
  }

  deleteItemReplace(condition: ReqItemReplaceDelDto) {
    var request: ReqItemReplaceDel = {
      access: { ...this.commonService.loginUser },
      itemReplaceDelete: condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ItemReplaceDelete", JSON.stringify(request));
  }

  initForecastUpDown(storeCdFv: String) {
    var request: ReqForecastUpDownInit = {
      access: { ...this.commonService.loginUser },
      storeCdFv: storeCdFv,
      maxCtgLevel: this.commonService.config.maxCtgLevel
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ForecastUpDownInit", JSON.stringify(request));
  }

  addForecastUpDown(forecastUpDownAddDto: ReqForecastUpDownAddDto) {
    var request: ReqForecastUpDownAdd = {
      access: { ...this.commonService.loginUser },
      forecastUpDownAdd: forecastUpDownAddDto,
      maxCtgLevel: this.commonService.config.maxCtgLevel
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ForecastUpDownAdd", JSON.stringify(request));
  }

  addForecastUpDownNew(request: ReqForecastUpDownAddNew) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ForecastUpDownAddNew", JSON.stringify(request));
  }

  deleteForecastUpDown(forecastUpDownDeleteDto: ReqForecastUpDownDeleteDto) {
    var request: ReqForecastUpDownDelete = {
      access: { ...this.commonService.loginUser },
      forecastUpDownDelete: forecastUpDownDeleteDto,
      maxCtgLevel: this.commonService.config.maxCtgLevel
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("ForecastUpDownDelete", JSON.stringify(request));
  }

  getGroupTypeList(storeGroupTypeCd: number): Observable<RspStoreGroupList> {
    const request: ReqStoreGroupTypeSearch = {
      access: { ...this.commonService.loginUser },
      storeGroupTypeCdFn: storeGroupTypeCd
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('StoreGroupTypeSearch', request);
  }
  addStoreGroup(storeGroup: StoreGroupDto[]) {
    const request: ReqAddStoreGroup = {
      access: { ...this.commonService.loginUser },
      allStoreGroupTypeAdd: storeGroup
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('StoreGroupAdd', request);

  }
  addStoreGroupType(storeGroupType: StoreGroupTypeAddDto) {
    const request: ReqStoreGroupTypeAddDto = {
      access: { ...this.commonService.loginUser },
      storeGroupTypeAdd: storeGroupType
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('StoreGroupTypeAdd', request);
  }

  addStoreGroupName(storeGroupName: StoreGroupNameAddDto) {
    const request: ReqStoreGroupNameAddDto = {
      access: { ...this.commonService.loginUser },
      storeGroupNameAdd: storeGroupName
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('StoreGroupNameAdd', request);
  }

  updateStoreGroupType(storeGroupType: StoreGroupTypeUpdateDto) {
    const request: ReqStoreGroupTypeUpdateDto = {
      access: { ...this.commonService.loginUser },
      storeGroupTypeUpdate: storeGroupType
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('StoreGroupTypeUpdate', request);
  }

  updateStoreGroupName(storeGroupName: StoreGroupNameUpdateDto) {
    const request: ReqStoreGroupNameUpdateDto = {
      access: { ...this.commonService.loginUser },
      storeGroupNameUpdate: storeGroupName
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('StoreGroupNameUpdate', request);
  }

  deleteStoreGroupType(storeGroupType: StoreGroupTypeDeleteDto) {
    const request: ReqStoreGroupTypeDeleteDto = {
      access: { ...this.commonService.loginUser },
      storeGroupTypeDelete: storeGroupType
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('StoreGroupTypeDelete', request);
  }

  deleteStoreGroupName(storeGroupName: StoreGroupNameDeleteDto) {
    const request: ReqStoreGroupNameDeleteDto = {
      access: { ...this.commonService.loginUser },
      storeGroupNameDelete: storeGroupName
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('StoreGroupNameDelete', request);
  }
  itemCsvErrorCheck(body: CsvParamItemCheckDto[]) {
    const request: ReqCsvParamItemCheck = {
      access: { ...this.commonService.loginUser },
      itemCsvCheck: body,
      paramItemMinZaiRange: this.commonService.config.paramItemMinZaiRange,
      paramItemMaxZaiRange: this.commonService.config.paramItemMaxZaiRange,
      paramItemMinStockDaysRange: this.commonService.config.paramItemMinStockDaysRange,
      paramItemMaxStockDaysRange: this.commonService.config.paramItemMaxStockDaysRange,
      paramItemAutoOrderLotRange: this.commonService.config.paramItemAutoOrderLotRange
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvParamItemCheck', request);
  }

  updateItemCsv(body: CsvParamItemCsvUpdateDto[]) {
    const request: ReqCsvParamItemCsvUpdate = {
      access: { ...this.commonService.loginUser },
      itemCsvUpdate: body,
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvParamItemUpdate', request);
  }

  checkCsvSeasonalCtgTarget(csvSeasonalCtgTargetCheck: ReqCsvSeasonalCtgTargetCheckDto[]) {
    const request: ReqCsvSeasonalCtgTargetCheck = {
      access: { ...this.commonService.loginUser },
      csvSeasonalCtgTargetCheck: csvSeasonalCtgTargetCheck,
      maxCtgLevelFn: this.commonService.config.maxCtgLevel
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvSeasonalCtgTargetCheck', request);
  }

  updateCsvSeasonalCtgTarget(csvSeasonalCtgTargetUpdate: ReqCsvSeasonalCtgTargetUpdateDto[]) {
    const request: ReqCsvSeasonalCtgTargetUpdate = {
      access: { ...this.commonService.loginUser },
      csvSeasonalCtgTargetUpdate: csvSeasonalCtgTargetUpdate,
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvSeasonalCtgTargetUpdate', request);
  }

  executeQuery(request: ReqExecuteQuery) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('ExecuteQuery', request);
  }

  pbiToken(request: ReqPbiToken) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('PbiToken', request);
  }

  pbiEmbedToken(accessToken: string, poportId: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };

    let request = {
      // datasets: [{"id": "cfafbeb1-8037-4d0c-896e-a46fb27ff229"}],
      datasets: [],
      reports: [{ id: poportId }],
      identities: [],
    };

    return this.http.post(
      "https://api.powerbi.com/v1.0/myorg/GenerateToken",
      request,
      httpOptions
    );
  }

  pbiReportList() {
    const request = {
      access: { ...this.commonService.loginUser }
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('PbiReportList', request);
  }

  addCameraLayout(request: ReqExecuteCameraLayoutAdd) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('ExecuteCameraLayoutAdd', request);
  }

  updateCameraLayout(request: ReqCameraLayoutUpdate) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('ExecuteCameraLayoutUpdate', request);
  }

  deleteCameraLayout(request: ReqCameraLayoutDelete) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('ExecuteCameraLayoutDelete', request);
  }

  updateCameraConfig(request: ReqCameraLayoutConfigUpdate) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('ExecuteCameraLayoutConfigUpdate', request);
  }

  checkCsvNoisecutPromTarget(request: ReqCsvNoisecutPromCheck) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvNoisecutPromCheck', request);
  }

  updateCsvNoisecutPromTarget(request: ReqCsvNoisecutUpdate) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvNoisecutPromUpdate', request);
  }

  checkCsvVisitors(csvVisitorsCheck: ReqCsvVisitorsCheckDto[]) {
    const request: ReqCsvVisitorsCheck = {
      access: { ...this.commonService.loginUser },
      csvVisitorsCheck: csvVisitorsCheck,
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvVisitorsCheck', request);
  }

  updateCsvVisitors(csvVisitorsUpdate: ReqCsvVisitorsUpdateDto[]) {
    const request: ReqCsvVisitorsUpdate = {
      access: { ...this.commonService.loginUser },
      csvVisitorsUpdate: csvVisitorsUpdate,
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvVisitorsUpdate', request);
  }

  menuItemAdd(request: ReqMenuItemAdd) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('MenuItemAdd', request);
  }

  menuEdit(request: ReqMenuEdit) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('MenuEdit', request);
  }

  PbiMenu(request: ReqPbiMenu) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('PbiMenu', request);
  }

  checkCsvInventory(csvInventoryCheck: ReqCsvInventoryCheckDto[]) {
    const request: ReqCsvInventoryCheck = {
      access: { ...this.commonService.loginUser },
      csvInventoryCheck: csvInventoryCheck,
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvInventoryCheck', request);
  }

  updateCsvInventory(csvInventoryUpdate: ReqCsvInventoryUpdateDto[]) {
    const request: ReqCsvInventoryUpdate = {
      access: { ...this.commonService.loginUser },
      csvInventoryDateShift: this.commonService.config.csvInventoryDateShift,
      csvInventoryUpdate: csvInventoryUpdate,
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvInventoryUpdate', request);
  }

  searchCsvInventoryHistory(csvInventoryHistorySearchDto: ReqCsvInventoryHistorySearchDto) {
    const request: ReqCsvInventoryHistorySearch = {
      access: { ...this.commonService.loginUser },
      csvInventoryHistorySearch: csvInventoryHistorySearchDto
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvInventoryHistorySearch', request);
  }

  deleteCsvInventoryHistory(request: ReqCsvInventoryHistoryDelete) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvInventoryHistoryDelete', request);
  }

  searchCsvInventoryHistoryDetail(csvInventoryHistoryDetailSearchDto: ReqCsvInventoryHistoryDetailSearchDto) {
    const request: ReqCsvInventoryHistoryDetailSearch = {
      access: { ...this.commonService.loginUser },
      csvInventoryHistoryDetailSearch: csvInventoryHistoryDetailSearchDto
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvInventoryHistoryDetailSearch', request);
  }

  deleteCsvInventoryHistoryDetail(request: ReqCsvInventoryHistoryDetailDelete) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvInventoryHistoryDetailDelete', request);
  }

  checkCsvUpDown(checkCsvUpDownList: ReqCsvUpDownCheckDto[], stores: StoreDto[]) {
    const request: ReqCsvUpDownCheck = {
      csvUpDownCheckList: checkCsvUpDownList,
      stores: stores,
      access: { ...this.commonService.loginUser },
      forecastRangeMax: this.commonService.config.forecastRangeMax,
      forecastRangeMin: this.commonService.config.forecastRangeMin,
      rankConfig: this.commonService.config.ranks
    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvUpDownCheck', request);
  }

  updateCsvUpDown(csvUpDownUpdateList: ReqCsvUpDownUpdateDto[], stores: StoreDto[]) {
    const request: ReqForecastUpDown = {
      csvUpDownUpdateList: csvUpDownUpdateList,
      stores: stores,
      access: { ...this.commonService.loginUser },
      maxCtgLevel: this.commonService.config.maxCtgLevel,
      ctgLabel: [
        this.commonService.literal.ctg0Name,
        this.commonService.literal.ctg1Name,
        this.commonService.literal.ctg2Name,
        this.commonService.literal.ctg3Name
      ],
      rankList: this.commonService.config.ranks

    };
    request.access.functionName = this.commonService.pageTitle;
    return this.post('CsvUpDownUpdate', request);
  }

  updateTaskRec(request: ReqUpdateTaskRec) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskRecUpdate', request);
  }

  searchTaskRec(request: ReqSearchTaskRec) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskRecSearch', request);
  }

  deleteTaskRec(request: ReqDeleteTaskRec) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskRecDelete', request);
  }

  searchTaskResultRec(request: ReqSearchTaskResultRec) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskResultRecSearch', request);
  }

  subTaskResult(request: ReqSubTaskResult) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('SubtaskResult', request);
  }

  updateTaskResultRec(request: ReqUpdateTaskResultRec) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskResultRecUpdate', request);
  }

  getImageData(request: ReqTaskImageData) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskImageData', request);
  }

  searchTaskRecOtherStores(request: ReqTaskRecOtherStoresSearch) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskRecOtherStoresSearch', request);
  }

  searchSubTaskResultOtherStores(request: ReqSubTaskResultOtherStoresSearch) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('SubTaskResultOtherStoresSearch', request);
  }

  getImageThumbnailData(request: ReqTaskImageThumbnailData) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskImageThumbnailData', request);
  }

  getEmoji(request: ReqEmojiSearch ) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskEmoteSearch', request);
  }
  updateTaskEmoteImage(request: ReqTaskEmoteImageUpdate) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post('TaskEmoteImageUpdate', request);
  }
  checkCsvParamItemStore(req: ReqCsvParamItemStoreCheck) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('CsvParamItemStoreCheck', req);
  }
  updateCsvParamItemStore(req: ReqCsvParamItemStoreCsvUpdate) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('CsvParamItemStoreUpdate', req);
  }

  saveUserConfig(msg?: string) {
    let request: ReqUserConfigUpdate = {
      access: this.commonService.loginUser,
      userConfig: JSON.stringify(this.commonService.userConfig)
    }
    let subsc = this.generalRequest("UserConfigUpdate", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        if (this.handleAppError(response)) return;
        if (msg) {
          this.snackBar.open(msg, "", {duration: 2000});
        }
      },
      (error) => {
        subsc.unsubscribe();
        this.handleError(error);
        return;
      }
    );
  }

  getLossRate(reqLossRateSearch: ReqLossRateSearch) {
    return this.post('LossRateSearch', reqLossRateSearch);
  }

  updateLossRate(reqLossRateUpdate: ReqLossRateCtgUpdate){
    return this.post("LossRateUpdate", reqLossRateUpdate)
  }
  taskRecFileInfoSearch(req: ReqTaskFile) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('SearchAllInfo', req);
  }
  taskRecFileSearch(req: ReqTaskFileOne) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('TaskAttachmentSearchOne', req);
  }

  subTaskRecFileInfoSearch(req) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('SubTaskSearchAllInfo', req);
  }

  subTaskRecFileSearch(req) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('SubTaskAttachmentSearchOne', req);
  }

  searchSpmt10331(condition: ReqSpmt10331Search){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10331Search", JSON.stringify(condition));
  }

  getDetailSpmt10331(condition: ReqSpmt10331GetDetail){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10331GetDetail", JSON.stringify(condition));
  }

  confirmSpmt10331(req: ReqSpmt10331Confirm) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10331Confirm', req);
  }

  checkExclusionSpmt10331(req: ReqSpmt10331Exclusion) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10331Exclusion', req);
  }

  initSpmt10331Init(req: ReqSpmt10331Init) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10331Init', req);
  }

  initSpmt10301Init(req: ReqSpmt10301Init) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10301Init', req);
  }

  searchSpmt10301(condition: ReqSpmt10301Search){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10301Search", JSON.stringify(condition));
  }

  getDetailSpmt10301(condition: ReqSpmt10301GetDetail){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10301GetDetail", JSON.stringify(condition));
  }

  confirmSpmt10301(req: ReqSpmt10301Confirm) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10301Confirm', req);
  }

  checkExclusionSpmt10301(req: ReqSpmt10301Exclusion) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10301Exclusion', req);
  }

  searchSpiv00061(condition: Spiv00061SearchDto) {
    var request: ReqSpiv00061Search = {
      access: { ...this.commonService.loginUser },
      spiv00061Search : condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Spiv00061Search", JSON.stringify(request));
  }

  updateSpiv00061(condition: Spiv00061UpdateDto) {
    var request: ReqSpiv00061Update = {
      access: { ...this.commonService.loginUser },
      spiv00061Update : condition
    }
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Spiv00061Update", JSON.stringify(request));
  }

  searchHeadquaterInstructionConfirm(request: ReqSpbs00031Search) {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Spbs00031Search", JSON.stringify(request));
  }
  updateHeadquaterInstructionConfirm(condition: Spbs00031UpdateDto[]) {
    var request: ReqSpbs00031Update = {
      access: { ...this.commonService.loginUser },
      rows : condition
    }
    console.log(condition);
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Spbs00031Update", JSON.stringify(request));
  }
  pdfFileSearch(req) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('pdfFileSearch', req);
  }

  searchSpmt10291(req: ReqSpmt10291Search) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10291Search', req);
  }

  confirmSpmt10291(req: ReqSpmt10291Confirm) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10291Confirm', req);
  }

  initSpmt10291Init(req: ReqSpmt10291Init) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10291Init', req);
  }

  getDropdownListOfUser(req: ReqGetDropdownListOfUser) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('GetDropdownListOfUser', req);
  }

  checkExclusionSpmt10291(req: ReqSpmt10291Exclusion) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10291Exclusion', req);
  }

  getDetailSpmt10291(req: ReqSpmt10291GetDetail){
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10291GetDetail', req);
  }

  getStoreList(req: ReqGetStoreList){
    req.access.functionName = this.commonService.pageTitle;
    return this.post('GetStoreList', req);
  }

  spmt10261ProductSearch(req: ReqSpmt10261Search): Observable<RspSpmt10261Search> {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10261ProductSearch', req);
  }

  spmt10261SlipSearch(req: ReqSpmt10261Search): Observable<RspSpmt10261Search> {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10261SlipSearch', req);
  }

  spmt10261Confirm(req: ReqSpmt10261Confirm): Observable<Rsp> {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10261Confirm', req);
  }

  spmt10261Delete(req: ReqSpmt10261Delete): Observable<RspSpmt10261Delete> {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10261Delete', req);
  }

  getSpmt10261SlipList(req: ReqSpmt10261SlipList): Observable<RspSpmt10261SlipList> {
    req.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10261SlipListSearch", req);
  }

  initCategoryList(req) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post("CategoryListInit", req);
  }

  /**
   * Webサービスをコール。(ファイルダウンロード向け)
   * @param param					パラメータ
   * @param webServiceNm		コールするWebサービス名
   * @param fileType					ファイル種類(CSV・PDF)
   */
  callWebServiceForFileDownload(param: string, webServiceNm: string, fileType: string): void {
    var href = location.href;
    var start = location.origin.length + 1;
    var projectName = href.substring(start, href.indexOf("/", start));
    // ファイルをダウンロード
    switch (fileType) {
      case this.FILE_TYPE_CSV: window.open(this.commonService.config.wsBaseUrl + webServiceNm + param, '_blank');break;
      case this.FILE_TYPE_ZIP: window.open(this.commonService.config.wsBaseUrl + webServiceNm + param, '_blank');break;
      case this.FILE_TYPE_PDF: window.open(this.commonService.config.wsBaseUrl + webServiceNm + param, '_blank');break;
      case this.FILE_TYPE_EXCEL: window.open(this.commonService.config.wsBaseUrl + webServiceNm + param, '_blank');break;
    }
  }

  spmt10341Init(request: Req): Observable<RspSpmt10341Init>{
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10341Init", request);
  }

  spmt10341Search(request: ReqSpmt10341Search): Observable<RspSpmt10341Search>{
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10341Search", request);
  }

  spmt10341GetDetail(request: ReqSpmt10341GetDetail): Observable<RspSpmt10341GetDetail>{
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10341GetDetail", request);
  }

  spmt10341Confirm(request: ReqSpmt10341Confirm): Observable<Rsp>{
    request.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10341Confirm", request);
  }

  getUserInfo(request : ReqUserInfoSearch): Observable<RspUserInfo> {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("GetUserInfo", JSON.stringify(request));
  }

  getDetailSpmt10271(req: ReqSpmt10271GetDetail){
    req.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10271GetDetail", JSON.stringify(req));
  }
  searchSpmt10271(req: ReqSpmt10271Search) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10271Search", JSON.stringify(req));
  }

  approvalConfirm(req: ReqSpmt10271Confirm) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post("ApprovalConfirm", req);
  }

  rejectedConfirm(req: ReqSpmt10271Confirm) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post("RejectedConfirm", req);
  }

  initSpmt10271(req: ReqSpmt10271Init) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10271Init', req);
  }

  checkExclusionSpmt10271(req: ReqSpmt10271Exclusion) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10271Exclusion', req);
  }

  getSortConditionConfig(request : ReqSortConditionConfig): Observable<RspSortConditionConfig> {
    request.access.functionName = this.commonService.pageTitle;
    return this.post("SortConditionInit", JSON.stringify(request));
  }

  initSpmt10321Init(req: ReqSpmt10321lInit) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10321Init', req);
  }
  searchSpmt10321(condition: ReqSpmt10321Search){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10321Search", JSON.stringify(condition));
  }
  checkExclusionSpmt10321(req: ReqSpmt10321Exclusion) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10321Exclusion', req);
  }

  getDetailSpmt10321(req: ReqSpmt10321GetDetail){
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10321GetDetail', req);
  }

  confirmSpmt10321(req: ReqSpmt10321Confirm){
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10321Confirm', req);
  }

  initSpvw10241Init(req: ReqSpvw10241Init) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spvw10241Init', req);
  }

  searchSpvw10241(condition: ReqSpvw10241Search){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spvw10241Search", JSON.stringify(condition));
  }

  initSpmt10311Init(req: ReqSpmt10311Init) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10311Init', req);
  }

  searchSpmt10311(condition: ReqSpmt10311Search){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10311Search", JSON.stringify(condition));
  }

  exclusionSpmt10311(condition: ReqSpmt10311Exclusion){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10311Exclusion", JSON.stringify(condition));
  }

  checkBeforeSearchSpmt10311(condition: ReqSpmt10311CheckBeforeSearch){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10311CheckBeforeSearch", JSON.stringify(condition));
  }

  getDetailSpmt10311(condition: ReqSpmt10311GetDetail){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10311GetDetail", JSON.stringify(condition));
  }

  confirmSpmt10311(req: ReqSpmt10311Confirm) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10311Confirm', req);
  }

  deleteSpmt10311(req: ReqSpmt10311Delete) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spmt10311Delete', req);
  }

  getSlipNoListSpmt10311(req: ReqSpmt10311GetSlipNoList) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post("Spmt10311GetSlipNoList", req);
  }

  initSpvw10231Init(req: ReqSpvw10231Init) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spvw10231Init', req);
  }

  searchSpvw10231(condition: ReqSpvw10231Search){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spvw10231Search", JSON.stringify(condition));
  }

  initSpvw10251Init(req: ReqSpvw10251Init) {
    req.access.functionName = this.commonService.pageTitle;
    return this.post('Spvw10251Init', req);
  }

  searchSpvw10251(condition: ReqSpvw10251Search){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spvw10251Search", JSON.stringify(condition));
  }

  getDetailSpvw10251(condition: ReqSpvw10251GetDetail){
    condition.access.functionName = this.commonService.pageTitle;
    return this.post("Spvw10251GetDetail", JSON.stringify(condition));
  }
}
