import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFullHalfWidth]'
})
export class FullHalfWidthDirective {

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (value) {
      // Allow only full-width and half-width alphabets (A-Z, a-z) and numbers (0-9)
      const filteredValue = value.replace(/[^a-zA-Z0-9Ａ-Ｚａ-ｚ０-９]/g, '');
      this.ngControl.control?.patchValue(filteredValue, { emitEvent: false });
    }
  }
}
