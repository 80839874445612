import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { OrderStopTermSuppRec } from 'src/app/page/order-stop-term-supp/order-stop-term-supp-rec';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { OrderStopTermSuppAddDto } from 'src/app/request/req-order-stop-term-supp-add';
import { OrderStopTermSuppDelDto } from 'src/app/request/req-order-stop-term-supp-delete';
import { ReqOrderStopTermSuppInit } from 'src/app/request/req-order-stop-term-supp-init';
import { OrderStopTermSuppSearchDto } from 'src/app/request/req-order-stop-term-supp-search';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { RspOrderStopTermSuppInit } from 'src/app/response/rsp-order-stop-term-supp';
import { RspOrderStopTermSuppSearch, RspOrderStopTermSuppSearchDto } from 'src/app/response/rsp-order-stop-term-supp-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

@Component({
  selector: 'app-order-stop-term-supp',
  templateUrl: './order-stop-term-supp.component.html',
  styleUrls: ['./order-stop-term-supp.component.css']
})

export class OrderStopTermSuppComponent implements OnInit, OnDestroy {

  @ViewChild("sectionSuppHeader", { static: true }) sectionSupp: SectionHeaderComponent;

  private subscriptionInit: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscriptionConfirm: Subscription;

  public formGroup: FormGroup;

  public tableWidth: any;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'storeCd', header: "店舗", width: 150 },
    { columnId: 'suppName', header: "発注先", width: 300 },
    { columnId: 'dateType', header: "日付種別", width: 60, align: "center" },
    { columnId: 'dateBegin', header: "開始日", width: 100, align: "center" },
    { columnId: 'dateEnd', header: "終了日", width: 100, align: "center" }
  ];
  public tableWidthSupp: any;
  public columnIdsSupp: string[] = ["suppCdFv", "suppNameFv"];
  public columnDefsSupp: TableColumnDef[] = [
    { columnId: 'suppCdFv', header: "発注先コード", width: 100, editable: true },
    { columnId: 'suppNameFv', header: "発注先名", width: 250 }
  ];

  public stores: LoginStoreDto[];
  public stopList: OrderStopTermSuppRec[] = [];

  public suppList: RspOrderStopTermSuppSearchDto[] = [];
  public itemSelected: RspOrderStopTermSuppSearchDto;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = "期間指定発注停止（発注先）";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    if (this.commonService.stores.length > 1) {
      this.stores = [{ storeCd: "*", storeName: "全店" }, ...this.commonService.stores];
    } else {
      this.stores = [...this.commonService.stores];
    }
    this.initFormGroup();
    this.initOrderStopTermSupp(this.commonService.loginUser.storeCd);
    this.calcTableWidthSupp();
    this.setDisplayCol();
    this.itemSelected = undefined;
  }

  ngOnDestroy() {
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
  }

  initOrderStopTermSupp(storeCd: string) {
    var request: ReqOrderStopTermSuppInit = {
      storeCdFv: storeCd,
      access: { ...this.commonService.loginUser }
    }
    this.subscriptionInit = this.httpBasic.initOrderStopTermSupp(request).subscribe(
      result => this.checkResult(result),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.storeCd],
      suppCd: [""],
      suppName: [""],
      dateType: ["1"],
      dateBegin: [],
      dateEnd: []
    });
  }

  doInsert() {
    let storeName: string = this.stores.filter(store => store.storeCd === this.formGroup.get("storeCd").value)[0].storeName;
    if (storeName == undefined || storeName == null)
      return;
    var request: OrderStopTermSuppAddDto = {
      storeCdFv: this.formGroup.get("storeCd").value,
      storeNameFv: storeName,
      suppCdFv: this.formGroup.get("suppCd").value,
      suppNameFv: this.formGroup.get("suppName").value,
      dateTypeFn: parseInt(this.formGroup.get("dateType").value),
      dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
      dateEndFv: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US'),
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.httpBasic.addOrderStopTermSupp(request).subscribe(
      result => this.checkResult(result),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  doCancel() {
    this.formGroup.controls["suppCd"].setValue('');
    this.formGroup.controls["suppName"].setValue('');
    this.formGroup.controls["dateBegin"].setValue(null);
    this.formGroup.controls["dateEnd"].setValue(null);
    this.formGroup.controls["dateType"].setValue("1");
  }

  doDelete(orderStopTermSupp: OrderStopTermSuppRec) {
    /*
    let msg = "<span >以下の登録を削除しますか？";
    msg += "<br><br>店舗: " + orderStopTermSupp.storeCd;
    msg += "<br>発注先コード: " + orderStopTermSupp.suppCdFv;
    msg += "<br>日付種別: " + orderStopTermSupp.dateType;
    msg += "<br>開始日: " + orderStopTermSupp.dateBeginFv;
    msg += "<br>終了日: " + orderStopTermSupp.dateEndFv;
    msg += "</span>";
    */

    let msg = "以下の登録を削除しますか？<br>";
    msg += "<br><table class='no-border'>";
    if (orderStopTermSupp.storeCd != undefined && orderStopTermSupp.storeCd !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>店舗：　</td>" + "<td class='no-border'>" + orderStopTermSupp.storeCd + "</td>";
      msg += "</tr>";
    }
    if (orderStopTermSupp.suppName != undefined && orderStopTermSupp.suppName !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>発注先:　</td>" + "<td class='no-border'>" + orderStopTermSupp.suppName + "</td>";
      msg += "</tr>";
    }
    if (orderStopTermSupp.dateType != undefined && orderStopTermSupp.dateType !== "") {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>日付種別:　</td>" + "<td class='no-border'>" + orderStopTermSupp.dateType + "</td>";
      msg += "</tr>";
    }
    if (orderStopTermSupp.dateBeginFv != null) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>開始日：　</td>" + "<td class='no-border'>" + orderStopTermSupp.dateBeginFv + "</td>";
      msg += "</tr>";
    }
    if (orderStopTermSupp.dateBeginFv != null) {
      msg += "<tr class='no-border'>";
      msg += "<td class='no-border'>終了日：　</td>" + "<td class='no-border'>" + orderStopTermSupp.dateEndFv + "</td>";
      msg += "</tr>";
    }
    msg += "</table>";

    this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      data => {
        this.subscriptionConfirm = undefined;
        if (data == true) {
          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          let request: OrderStopTermSuppDelDto = {
            searchStoreCdFv: this.formGroup.get("storeCd").value,
            storeCdFv: orderStopTermSupp.storeCdFv,
            storeNameFv: orderStopTermSupp.storeNameFv,
            suppCdFv: orderStopTermSupp.suppCdFv,
            suppNameFv: orderStopTermSupp.suppNameFv,
            dateTypeFn: orderStopTermSupp.dateTypeFn,
            dateBeginFv: orderStopTermSupp.dateBeginFv,
            dateEndFv: orderStopTermSupp.dateEndFv,
          };
          this.httpBasic.deleteOrderStopTermSupp(request)
            .subscribe(
              result => this.checkResult(result),
              error => {
                this.clearProgressState();
                this.httpBasic.handleError(error);
              }
            )
        }
      }
    );
    return;
  }

  doSearchSupp() {
    var request: OrderStopTermSuppSearchDto = {
      storeCdFv: this.formGroup.get("storeCd").value,
      suppNameFv: this.formGroup.get("suppName").value,
      suppCdFv: this.formGroup.get("suppCd").value,
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.searchOrderStopTermSupp(request).subscribe(
      result => this.checkResultSupp(result),
      error => {
        this.httpBasic.handleError(error);
      }
    );
  }

  doSearchStore() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.initOrderStopTermSupp(this.formGroup.get("storeCd").value);
    this.suppList = [];
    this.sectionSupp.closeSectionBody();
  }

  checkResult(response: RspOrderStopTermSuppInit) {
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.stopList = [];
    for (let orderStopTermSupp of response.result) {
      this.stopList.push(new OrderStopTermSuppRec(orderStopTermSupp));
    }
  }

  checkResultSupp(response: RspOrderStopTermSuppSearch) {
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }

    this.suppList = [];
    this.sectionSupp.openSectionBody();
    let data = response.result;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      this.suppList.push({
        suppNameFv: element.suppNameFv,
        suppCdFv: element.suppCdFv,
      });
    }
  }

  isHiddenDeleteButton(storeCdFv: string) {
    if (this.commonService.stores.length > 1) return false;
    return (storeCdFv === "*") ? true : false;
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = ["removeItem"];

    for (var colDef of this.columnDefs) {
      this.displayColumnDefs.push(colDef);
      this.displayColumnIds.push(colDef.columnId);
      this.calcTableWidth();
    }
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    width = width + 80 + 1 + 8;   // 削除ボタン
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  calcTableWidthSupp() {
    var width = 1;                // For left border
    for (var colDef of this.columnDefsSupp) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidthSupp = { "width": "" + width + "px" };
  }

  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }

  styleForSupp(id: string) {
    return this.styleForBody(this.columnDefsSupp, id);
  }

  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderSupp(id: string) {
    return this.styleForHeaderBody(this.columnDefsSupp, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  setDateType(type: string) {
    if (type == "発注日") {
      this.formGroup.get('dateType').setValue("1");
    } else {
      this.formGroup.get('dateType').setValue("2");
    }
  }

  onClickCellSupp(columnId: string, item: RspOrderStopTermSuppSearchDto) {
    if (columnId == "suppCdFv") {
      this.itemSelected = item;
      this.formGroup.controls["suppCd"].setValue(this.itemSelected.suppCdFv);
      this.formGroup.controls["suppName"].setValue(this.itemSelected.suppNameFv);
    }
  }

  isValidSubmit() {
    if (this.formGroup.get("suppCd").value === "") return false;
    if (this.formGroup.get("dateBegin").value == null) return false;
    if (this.formGroup.get("dateEnd").value == null) return false;
    return true;
  }

  getDateBeginMin() {
    if (this.formGroup.get('dateEnd').value != null && this.formGroup.get('dateBegin').value > this.formGroup.get('dateEnd').value)
      this.formGroup.get('dateEnd').setValue(null);

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

  getDateEndMin() {
    if (this.formGroup.get('dateBegin').value != null) {
      return this.formGroup.get('dateBegin').value;
    }

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

}
