import { Observable, Observer, Subject } from "rxjs";
import { LabelPrinter } from "./label-pinter";
import { environment } from "src/environments/environment";
import { AnonymousSubject } from "rxjs/internal/Subject";
import { CommonService } from "src/app/service/common.service";

export class LabelPrinterNeo7B extends LabelPrinter {
  public ipAddr: string;
  public testResult: boolean = false;

  private connectTimeout: number = 5;    // sec
  private bridgeWin: Window;
  private listener: ()=> void = this.messageListener.bind(this);
  private readyWait: () => void;
  private onMessage: (msg: string) => void;
  private onError: (event: string) => void;
  private onOpen: (msg: string) => void;
  private onClose: () => void;

  private isConn: boolean = false;

  constructor(
    private commonService: CommonService
  ) {
    super();
  }

  public getPrinterType(): string {
    return "neo7";
  }

  public setIpAddr(ipAddr: string) {
    this.ipAddr = ipAddr;
  }

  public getIpAddr(): string {
    return this.ipAddr;
  }

  connect(ipAddr: string): Subject<any> {
    return null;
  }

  async connectAsync(ipAddr?: string, isTest?: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!ipAddr) ipAddr = this.ipAddr;
      window.addEventListener("message", this.listener);
      let timeoutId = setTimeout(() => {
        this.readyWait = undefined;
        if (isTest) {
          this.closeBrideWindow();
        }
        this.testResult = false;
        resolve(false);
        return;
      }, this.connectTimeout * 1000);
      this.readyWait = () => {
        clearTimeout(timeoutId);
        this.readyWait = undefined;
        if (isTest) {
          this.closeBrideWindow();
        }
        this.testResult = true;
        resolve(true);
        return;
      };
      this.bridgeWindow(ipAddr);
      if (!this.bridgeWin) {
        this.testResult = false;
        resolve(false);
      }
      return;
    });
  }

  getUrl(ipAddr: string): string {
    if (environment.production) {
      return "http://" + ipAddr + ":80/index.html";
    } else {
      return "http://10.100.201.11/yabe/neo7/index.html";
    }
  }

  bridgeWindow(ipAddr?: string) {
    try {
      this.bridgeWin = window.open(this.getUrl(ipAddr) + "?" + (new Date()).getTime(), "neo7");
    } catch (error) {
      this.bridgeWin = null;
    }
    if (this.bridgeWin) {
      this.bridgeWin.onload = () => {
        console.log("bridgeWin: loaded");
      }
      this.bridgeWin.onerror = () => {
        console.log("bridgeWin: error");
      }
    }
  }

  closeBrideWindow() {
    window.removeEventListener("message", this.listener);
    this.bridgeWin?.close();
    this.bridgeWin = undefined;
    this.isConn = false;
  }

  public disconnect() {
    this.bridgeWin?.close();
  }

  public write(printData: any) {
    this.bridgeWin?.postMessage({ message: printData }, "*");
  }

  public isConnected(): boolean {
    // return this.isConn;
    return this.testResult;
  }

  public getSubject(): Subject<any> {
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      this.onMessage = obs.next.bind(obs);
      this.onError = obs.error.bind(obs);
      this.onOpen = obs.next.bind(obs);
      this.onClose = obs.complete.bind(obs);
      return this.unsubsc.bind(this);
    });
    let observer = {
        error: null,
        complete: null,
        next: (data) => {
            if (this.isConn) {
                this.write(data);
            } else if (this.onError) {
              this.onError("not connected");
            }
        }
    };

    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  public messageListener(event: MessageEvent) {
    if (event.source !== this.bridgeWin) return;
    if (!event.data) return;

    switch(event.data) {
      case "ready": {
        this.isConn = true;
        if (this.readyWait) this.readyWait();
        break;
      }
      case "closed": {
        this.isConn = false;
        break;
      }
      case "error" : {
        break;
      }
      default: {
        if (this.onMessage) this.onMessage(event.data);
      }
    }
  }

  unsubsc() {
    this.onMessage = undefined;
    this.onError = undefined;
    this.onOpen = undefined;
    this.onClose = undefined;
  }
}
