import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**
 * Message Service
 * 
 * @author  PhyoNaing Htun
 * @created 2024/06/27
 */
export class MessageService {
  errorMessage: any = {};
  message: any = {};

  constructor() {}

  clear() {
    this.message = {};
  }
}
