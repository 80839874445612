import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RspUpdPasswd } from 'src/app/response/rsp-upd-passwd';

@Component({
  selector: 'app-passwd',
  templateUrl: './passwd.component.html',
  styleUrls: ['./passwd.component.css']
})
export class PasswdComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  public capslockMsg: string;
  private subscriptionUpdate: Subscription;

  constructor
  (
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) {

    this.formGroup = fb.group({
      passwd: ["", [Validators.required,
                    Validators.pattern(/^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z\d$@$!%*?&]{8,}$/)
                  ]
              ],
      confirm: ["", Validators.required]
    },
    {
      validators: (control) => {
          if (control.get("passwd").value == control.get("confirm").value) return null;
          return {"unmatch": "パスワードが不一致"};
        }
    });
  }

  ngOnInit() {
    // this.commonService.pageTitle = "パスワード";
    this.commonService.pageTitle = this.commonService.pageMenuName;
  }

  ngOnDestroy() {
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
    }
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    this.subscriptionUpdate.unsubscribe();
    this.subscriptionUpdate = undefined;
  }

  updatePasswd() {
    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");

    this.subscriptionUpdate = this.httpBasic.updPasswd(this.formGroup.get("passwd").value).subscribe(
      (response) => this.receiveUpdate(response),
      (error) => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpdate(response: RspUpdPasswd) {
    this.clearProgressState();

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
  }

  onKeyup(event:any) {
    if (event.getModifierState("CapsLock")) {
      this.capslockMsg = "capslock が ON になっています。注意してください。";
    } else {
      this.capslockMsg = undefined;
    }
  }

  onBlur() {
    this.capslockMsg = undefined;
  }
}
