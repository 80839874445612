import { ReqOrderStopTermStoreDeleteDto } from '../../request/req-order-stop-term-store-delete';
import { formatDate } from '@angular/common';
import { OrderStopTermStoreAddDto } from '../../request/req-order-stop-term-store-add';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription, Observable } from 'rxjs';
import { OrderStopTermStoreRec } from './order-stop-term-store-rec';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { RspOrderStopTermStore } from 'src/app/response/rsp-order-stop-term-store';

@Component({
  selector: 'app-order-stop-term-store',
  templateUrl: './order-stop-term-store.component.html',
  styleUrls: ['./order-stop-term-store.component.css']
})
export class OrderStopTermStoreComponent implements OnInit {

  public formGroup: FormGroup;
  public tableWidth: any;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'storeFv', header: "店舗", width: 150 },
    { columnId: 'dateTypeFv', header: "日付種別", width: 60, align: "center" },
    { columnId: 'dateBeginFv', header: "開始日", width: 100, align: "center" },
    { columnId: 'dateEndFv', header: "終了日", width: 100, align: "center" }
  ];

  public stores: LoginStoreDto[];
  public stopList: OrderStopTermStoreRec[] = [];

  private subscriptionInit: Subscription;
  private subscriptionStoreCd: Subscription;
  private subscriptionConfirm: Subscription;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
  ) { }

  ngOnInit() {
    // this.commonService.pageTitle = "期間指定発注停止（店舗）"
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.stores = [...this.commonService.stores];
    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.storeCd],
      dateType: ["1"],
      dateBegin: [],
      dateEnd: []
    }
    );

    this.setDisplayCol();
    this.initOrderStopTermStore();
    this.subscriptionStoreCd = this.formGroup.get('storeCd').valueChanges
      .subscribe(
        () => {
          this.initOrderStopTermStore();
        }
      );
  }

  ngOnDestroy() {
    if (this.subscriptionInit) {
      this.subscriptionInit.unsubscribe();
    }
    if (this.subscriptionStoreCd) {
      this.subscriptionStoreCd.unsubscribe();
    }
  }

  setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = ["removeItem"];

    for (var colDef of this.columnDefs) {
      this.displayColumnDefs.push(colDef);
      this.displayColumnIds.push(colDef.columnId);
      this.calcTableWidth();
    }
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    width = width + 80 + 1 + 8;   // 削除ボタン
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }

  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  setDateType(type: string) {
    if (type == "発注日") {
      this.formGroup.get('dateType').setValue("1");
    } else {
      this.formGroup.get('dateType').setValue("2");
    }
  }

  onClickCell() {

  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  initOrderStopTermStore() {
    this.subscriptionInit = this.httpBasic.initOrderStopTermStore()
      .subscribe(
        data => this.checkResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error)
        }
      );
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
  }

  checkResult(response: RspOrderStopTermStore) {
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.stopList = [];
    for (var orderStopTermCtg of response.result) {
      this.stopList.push(new OrderStopTermStoreRec(orderStopTermCtg));
    }
  }

  isValidSubmit() {
    if (this.formGroup.get("dateBegin").value == null) return false;
    if (this.formGroup.get("dateEnd").value == null) return false;
    return true;
  }

  doCancel() {
    this.formGroup.controls["dateBegin"].setValue(null);
    this.formGroup.controls["dateEnd"].setValue(null);
    this.formGroup.controls["dateType"].setValue("1");
  }

  getDateBeginMin() {
    if (this.formGroup.get('dateEnd').value != null && this.formGroup.get('dateBegin').value > this.formGroup.get('dateEnd').value)
      this.formGroup.get('dateEnd').setValue(null);

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

  getDateEndMin() {
    if (this.formGroup.get('dateBegin').value != null) {
      return this.formGroup.get('dateBegin').value;
    }

    let min = new Date();
    min.setDate(min.getDate() + 1);
    return min;
  }

  doSubmit() {
    let storeName: string = this.stores.filter(store => store.storeCd === this.formGroup.get("storeCd").value)[0].storeName;
    if (storeName == undefined || storeName == null)
      return;
    let request: OrderStopTermStoreAddDto = {
      storeCdFv: this.formGroup.get("storeCd").value,
      storeNameFv: storeName,
      dateBeginFv: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
      dateEndFv: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US'),
      dateTypeFn: parseInt(this.formGroup.get("dateType").value)
    }

    this.subscriptionInit = this.httpBasic.addOrderStopTermStore(request)
      .subscribe(data =>
        this.checkResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        });

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
  }

  doDelete(orderStopTermCtg: OrderStopTermStoreRec) {
    /*
    let msg = "以下の登録を削除しますか？";
    if (orderStopTermCtg.storeCdFv != undefined && orderStopTermCtg.storeCdFv !== "")
      msg = msg + "<br><br>店舗: " + orderStopTermCtg.storeFv;
    if (orderStopTermCtg.dateTypeFv != undefined && orderStopTermCtg.dateTypeFv !== "")
      msg = msg + "<br>日付種別: " + orderStopTermCtg.dateTypeFv;
    if (orderStopTermCtg.dateBeginFv != null)
      msg = msg + "<br>開始日: " + orderStopTermCtg.dateBeginFv;
    if (orderStopTermCtg.dateEndFv != null)
      msg = msg + "<br>終了日: " + orderStopTermCtg.dateEndFv;
    */

      let msg = "以下の登録を削除しますか？<br>";
      msg += "<br><table class='no-border'>";
      if (orderStopTermCtg.storeCdFv != undefined && orderStopTermCtg.storeCdFv !== "") {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>店舗：　</td>" + "<td class='no-border'>" + orderStopTermCtg.storeFv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.dateTypeFv != undefined && orderStopTermCtg.dateTypeFv !== "") {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>日付種別:　</td>" + "<td class='no-border'>" + orderStopTermCtg.dateTypeFv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.dateBeginFv != null) {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>開始日：　</td>" + "<td class='no-border'>" + orderStopTermCtg.dateBeginFv + "</td>";
        msg += "</tr>";
      }
      if (orderStopTermCtg.dateBeginFv != null) {
        msg += "<tr class='no-border'>";
        msg += "<td class='no-border'>終了日：　</td>" + "<td class='no-border'>" + orderStopTermCtg.dateEndFv + "</td>";
        msg += "</tr>";
      }
      msg += "</table>";

      this.subscriptionConfirm = this.commonService.openYesNoDialog(this.commonService.pageTitle, msg).subscribe(
      (data) => {
        this.subscriptionConfirm = undefined;
        if (data == true) {
          this.commonService.openSpinner(this.commonService.pageTitle, "削除中・・・");
          let request: ReqOrderStopTermStoreDeleteDto = {
            searchStoreCdFv: this.formGroup.get("storeCd").value,
            storeCdFv: orderStopTermCtg.storeCdFv,
            dateTypeFn: orderStopTermCtg.dateTypeFn,
            dateBeginFv: orderStopTermCtg.dateBeginFv,
            dateEndFv: orderStopTermCtg.dateEndFv
          };
          this.subscriptionInit = this.httpBasic.deleteOrderStopTermStore(request)
            .subscribe(
              data => this.checkResult(data),
              error => {
                this.clearProgressState();
                this.httpBasic.handleError(error);
              }
            )
        }
      }
    );
  }

}
