import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSignedNumber]'
})
export class SignedNumberDirective {

  private el : NgControl;
  @Input() appNumberMaxLength: number;
  @Input() appNumberCurrentValue: string;

  constructor(private ngControl: NgControl) {
    this.el = ngControl;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const valueInput = this.setValueIsOnlyNumber(value)
    if (this.appNumberMaxLength !== undefined && this.appNumberCurrentValue !== undefined){
      if(this.checkIsMaxLength(valueInput)){
        this.el.control.setValue(this.appNumberCurrentValue)
        return;
      }
    }
    let valueCtrl = this.isNumber(valueInput) ? +valueInput : valueInput;
    this.el.control.setValue(valueCtrl);
    this.appNumberCurrentValue = `${valueCtrl}`;
  }

  isNumber(value: string){
    return value !== '' && value !== '-';
  }

  setValueIsOnlyNumber(value: string): string{
    if(value[0] === '-'){
      return '-' + value.slice(1,).replace(/[^0-9]/g, '');
    }
    return value.replace(/[^0-9]/g, '');
  }

  checkIsMaxLength(value: string){
    if(value[0] === '-'){ 
      return value.length > this.appNumberMaxLength;
    }
    return value.length > this.appNumberMaxLength - 1;
  }
}
