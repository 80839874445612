import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription, Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { ReqMenuItemAdd, RspMenu, RspMenuItemAdd } from 'src/app/webservice/menu';

interface MenuGroup {
  menuGroupId: number;
  menuGroupName: string;
  dispOrder: number;
}

interface MenuItem {
	menuId: number;
	menuGroupId: number;
	menuName: string;
	menuPath: string;
  isReadonly: number;
}

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  // private sunscriptionPath: Subscription;
  private subsc: Subscription[] = [];

  public pathList: string[];
  public menuGroupList: MenuGroup[];
  public menuItemList: MenuItem[];

  private menuParam: {path: string, paramName: string}[] = [];
  public paramName: string;

  public isMobile: boolean = false;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
    ) {
  }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    let len = this.route.snapshot.url.length;
    if (len > 0) {
      let url: string = this.route.snapshot.url[len - 1].path;
      if (url == "menuItem") this.isMobile = false;
      if (url == "menuItemMobile") this.isMobile = true;
    }

    this.formGroup = this.fb.group({
      "menuGroup": [0, Validators.required], 
      "menuName": ["", Validators.required],
      "menuPath": ["", Validators.required],
      "paramValue": [""],
      "allPath": [false],
      "readonlyControl": [false]
    })

    /*
    this.pathList = [];
    for (let path of this.router.config) {
      if (path.path === "") continue;
      if (path.path === "**") continue;
      if (path.path === "login") continue;
      if (path.path === "top") continue;
      this.pathList.push(path.path);
      this.pathList.sort();
    }
    */
    this.menuParam.push({path: "pbi", paramName: "name"});
    this.menuParam.push({path: "report", paramName: "name"});

    /*
    this.sunscriptionPath = this.formGroup.get("menuPath").valueChanges.subscribe(
      () => {this.isParamMenu();}
    )
    */
    this.subsc.push(
      this.formGroup.get("menuPath").valueChanges.subscribe(
        () => {this.isParamMenu();}
      )
    );
    this.subsc.push(
      this.formGroup.get("allPath").valueChanges.subscribe(
        () => {this.buildPathList();}
      )
    );

    this.getMenuGroup();
    // this.getMenuItems();
  }

  ngOnDestroy(): void {
    // if (this.sunscriptionPath) this.sunscriptionPath.unsubscribe();
    this.subsc.forEach((subsc) => subsc.unsubscribe());
  }

  initValue() {
    this.formGroup.get("menuName").setValue("");
    this.formGroup.get("menuPath").setValue("");
    this.formGroup.get("paramValue").setValue("");
    this.formGroup.get("allPath").setValue(false);
    this.formGroup.get("readonlyControl").setValue(false);
  }

  isParamMenu() {
    let path = this.formGroup.get("menuPath").value;

    let param = this.menuParam.find(element => element.path === path);
    if (param) {
      this.paramName = "?" + param.paramName + "=";
      this.formGroup.get("paramValue").setValidators(Validators.required);
      this.formGroup.get("paramValue").updateValueAndValidity();
    } else {
      if (this.paramName) {
        this.paramName = undefined;
        this.formGroup.get("paramValue").setValidators(undefined);
        this.formGroup.get("paramValue").updateValueAndValidity();
      }
    }

  }

  getMenuGroup() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: this.isMobile ? "mng/getMenuGroupMobile" : "mng/getMenuGroup",
      bindVariables: ["" + this.commonService.loginUser.roleId]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveMenuGroup(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveMenuGroup(response: RspExecuteQuery) {
    if (this.httpBasic.handleAppError(response)) {
      this.commonService.closeSpinner();
      return;
    }

    this.menuGroupList = [];
    for (let item of response.rows) {
      let menu: MenuGroup = {
        menuGroupId: parseInt(item.colData[0]),
        menuGroupName: item.colData[1],
        dispOrder: parseInt(item.colData[2]),
      };
      this.menuGroupList.push(menu);
    }

    this.getMenuItems();
  }

  getMenuItems() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: this.isMobile ? "mng/getMenuItemMobile" : "mng/getMenuItem",
      bindVariables: []
    };

    // this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveMenuItem(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveMenuItem(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.menuItemList = [];
    for (let item of response.rows) {
      let menu = {
        menuId: parseInt(item.colData[0]),
        menuGroupId: parseInt(item.colData[1]),
        menuName: item.colData[2],
        menuPath: item.colData[3],
        isReadonly: parseInt(item.colData[4])
      };
      this.menuItemList.push(menu);
    }
    this.buildPathList();
  }

  buildPathList() {
    if (!this.commonService.config.ruotesMobile) this.commonService.config.ruotesMobile = [];

    this.pathList = [];
    let routePaths;
    if (this.isMobile) {
      routePaths = this.commonService.config.ruotesMobile;
    } else {
      routePaths = this.router.config;
    }

    for (let path of routePaths) {
      if (path.path === "") continue;
      if (path.path === "**") continue;
      if (path.path === "login") continue;
      if (path.path === "top") continue;
      if (this.formGroup.get("allPath").value === false && this.menuItemList.find(item => item.menuPath === path.path)) continue;
      this.pathList.push(path.path);
      this.pathList.sort();
    }
  }

  addMenu() {
    let path = this.formGroup.get("menuPath").value;
    if (this.menuParam.find(item => item.path === path)) {
      path += this.paramName;
      path += this.formGroup.get("paramValue").value;
    }

    let request: ReqMenuItemAdd = {
      access: {...this.commonService.loginUser},
      menuId: -1,
      menuGroupId: parseInt(this.formGroup.get("menuGroup").value),
      menuName: this.formGroup.get("menuName").value,
      menuPath: path,
      isReadonly: !this.formGroup.get("readonlyControl").value,
      isMobile: this.isMobile
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.menuItemAdd(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveAddMenu(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveAddMenu(response: RspMenuItemAdd) {
    // this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    let newMenu: MenuItem = {
      menuId: response.menuId,
      menuGroupId: response.menuGroupId,
      menuName: response.menuName,
      menuPath: response.menuPath,
      isReadonly: response.isReadonly ? 1 : 0
    }
    this.menuItemList.push(newMenu);
    this.initValue();

    this.getMenu();
  }

  getMenu() {
    if (this.isMobile) return;
    let subsc = this.httpBasic.getMenu().subscribe(
        response => {
          subsc.unsubscribe();
          this.receiveMenu(response);
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
    );
  }
  
  receiveMenu(response: RspMenu) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.commonService.menuGroups = [...response.menuGroups];
    this.commonService.menuGroupsMobile = [...response.menuGroupsMobile];
  }

}
