import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CommonService } from '../../service/common.service';

@Component({
  selector: 'app-login-bar',
  templateUrl: './login-bar.component.html',
  styleUrls: ['./login-bar.component.css']
})
export class LoginBarComponent implements OnInit {

  today : Date = new Date();

  constructor(public commonService: CommonService,
              private router : Router ) {}

  ngOnInit() {
  }
}
