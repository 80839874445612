import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqSubTaskResultOtherStoresCommentSearch, RspSubTaskResultOtherStoresCommentSearch, TaskImageMarkDto } from 'src/app/webservice/task';
import { CommonService } from '../../../service/common.service';
import { HttpBasicService } from '../../../service/http-basic.service';
import { EmojiDto, EmojiImageDto } from '../../../webservice/task';
import { StoreTargetListItemChecked, SubTask } from '../0_def/taskDefs';
import { SubTaskOtherStoresResultCommentRecDto } from '../0_def/taskOtherStoresCommentRec';
import { TaskStoreRec } from '../0_def/taskStoreRec';
import { TaskService } from '../1_service/taskService';

@Component({
  selector: 'view-other-store-comment',
  templateUrl: 'view-other-store-comment.component.html',
  styleUrls: ['view-other-store-comment.component.css'],
})

export class ViewOtherStoreCommentComponent implements OnInit, OnChanges {
  @Input() taskRec: TaskStoreRec;
  @Input() currentSubTask: SubTask;
  @Input() isFromTaskHq: boolean = false;
  @ViewChild('subTaskOtherStoresPaginator', { static: false }) matPaginator: MatPaginator;

  public targetStoreSelected: Map<string, boolean> = new Map();
  public subtaskSelected: Map<string, boolean> = new Map();
  // public imageMarkMap: Map<string, TaskImageMarkDto> = new Map();
  // public emojiMarkMap: Map<string, EmojiImageDto> = new Map();
  // public emojiMarkMapOld: Map<string, EmojiImageDto> = new Map();
  public targetStores: StoreTargetListItemChecked[]; 
  public subTaskColumnDefs: TableColumnDef[] = [
    { columnId: "subTaskId", header: "選択", width: 50, align: "center", checkbox: true },
    { columnId: "subTaskName", header: "子タスク名", width: 350, },
  ];
  public storeColumnDefs: TableColumnDef[] = [
    {columnId:'storeNameWithCodeCheck', header: "選択", width: 50, align: "center", checkbox: true },
    {columnId:'storeNameWithCode', header:"店舗", width: 250, align:"left"},
  ];
  public subtasks: SubTask[];
  displayColumnTableSubTask: string[] = [];
  displayColumnTableStore: string[] = [];
  emojiMst: EmojiDto[];
  private reHandle: boolean = true;
  // emojiImgList: EmojiImageDto[];
  public photoReportFormControl: FormControl = new FormControl(false);
  public statusNotStartedFormControl: FormControl = new FormControl(true);
  // public statusStartedFormControl: FormControl = new FormControl(false);
  public statusCompletedFormControl: FormControl = new FormControl(true);
  public isSubTaskSelected: boolean;
  public isStoreSelected: boolean;
  public commentRecs: SubTaskOtherStoresResultCommentRecDto[] = [];

  public tableTargetStore = 'tbl-target-store';
  public tableSubtask = 'tbl-subtask'; 
  public groupCheckbox = 'grp-checkbox';

  @ViewChild("subTaskOtherStoresPaginator", { static: false, read: MatPaginator }) matPagenator: MatPaginator;

  constructor(
    public taskService: TaskService,
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.taskRec) {
      this.subtasks = [];
      this.targetStores = [];
      this.commentRecs = [];
      return;
    }
    if (this.matPaginator?.pageIndex) this.matPaginator.pageIndex = 0;
    this.taskService.subTaskOtherStoresCommentRecs = [];
    this.taskService.subTaskOtherStoresCommentRecsRecordCount = 0;
    // this.emojiMarkMapOld.clear();
    // this.emojiMarkMap.clear();
    this.commentRecs = [];
    if(changes.taskRec && changes.taskRec.currentValue != changes.taskRec.previousValue) {
      this.initSubtask();
    }
    if(changes.currentSubTask && changes.currentSubTask.currentValue != undefined ) {
      this.initCurrentSubtask();
    }
  }

  ngOnInit() {
    this.displayColumnTableSubTask = this.subTaskColumnDefs.map(column => column.columnId);
    this.displayColumnTableStore = this.storeColumnDefs.map(column => column.columnId);
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "sub-task-table-report";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight !== undefined) {
      let paginatorHeight = 56;
      let margin = 10 + 5;
      let btnBox = 24;
      let height = remHeight - paginatorHeight - margin - btnBox;
      if (height < 125) height = 125;
  
      let elem = document.getElementById(id);
      if (elem) elem.style.maxHeight = "" + height + "px";
    }
  }

  styleFor(id: string) {
    for (var colDef of this.subTaskColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width ? colDef.width + "px" : '100%',
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
    for (var colDef of this.storeColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width ? colDef.width + "px" : '100%',
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    for (var colDef of this.subTaskColumnDefs) {
      if (colDef.columnId === id) {

        return {
          "width": "" + colDef.width ? colDef.width + "px" : '100%'
        }
      }
    }
    for (var colDef of this.storeColumnDefs) {
      if (colDef.columnId === id) {

        return {
          "width": "" + colDef.width ? colDef.width + "px" : '100%'
        }
      }
    }
  }

  onItemChecked(cellCd: string, checked: boolean) {
    if(this.subtaskSelected.has(cellCd.toString())) this.subtaskSelected.set(cellCd, checked);
    if(this.targetStoreSelected.has(cellCd)) this.targetStoreSelected.set(cellCd, checked);
    this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
  }

  clearAll(action: string) {
    if(action === this.tableTargetStore){
      this.targetStoreSelected.forEach((value, key) => {
        this.targetStoreSelected.set(key, false)
      })
    }
    if(action === this.groupCheckbox){
      this.statusNotStartedFormControl.setValue(false);
      this.statusCompletedFormControl.setValue(false);
    }
    if(action === this.tableSubtask){
      this.subtaskSelected.forEach((value, key) => {
        this.subtaskSelected.set(key, false)
      })
    }
    this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
  }

  initSubtask() {
    this.subtasks = [];
    this.targetStores = [];
    this.subtaskSelected = new Map();
    this.subtasks.push({
      subTaskId: -1,
      subTaskName: "(親タスク)",
      subTaskUrl: "",
      description: "",
      deadlineDate: undefined,
      photoReport: false,
      workTimeH: 0,
      workTimeM: 0
    });
    this.subtaskSelected.set("" + -1, true);

    if(this.taskRec.subTasks.length < 1) return;
    this.taskRec.subTasks.map(subtask => {
      this.subtasks.push(subtask);
      this.subtaskSelected.set("" + subtask.subTaskId, true);
    });
    this.taskRec.targetStores.map(store => {
      this.targetStores.push({
        storeCd: store.storeCd,
        storeNameWithCode: store.storeNameWithCode,
        storeNameWithCodeCheck: store.storeNameWithCode,
        storeName: store.storeName,
      })
      this.targetStoreSelected.set(store.storeNameWithCode, true);
    });
    if(this.isFromTaskHq){
      this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
      this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    }
  }

  initCurrentSubtask() {
    if(!this.isFromTaskHq){
      this.subtaskSelected.forEach((val, key) => {
        this.subtaskSelected.set(key, false);
      });
      this.subtaskSelected.set(this.currentSubTask.subTaskId +'', true);
    }
  }

  setAll(action: string){
    if(action === this.tableTargetStore){
      this.targetStoreSelected.forEach((value, key) => {
        this.targetStoreSelected.set(key, true);
      })
    }
    if(action === this.groupCheckbox){
      this.statusNotStartedFormControl.setValue(true);
      this.statusCompletedFormControl.setValue(true);
    }
    if(action === this.tableSubtask){
      this.subtaskSelected.forEach((value, key) => {
        this.subtaskSelected.set(key, true)
      })
    }
    this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
  }

  doSearch() {
    const listStatus = [];
    // this.emojiMarkMap.clear();
    // this.emojiMarkMapOld.clear();
    if (this.statusCompletedFormControl.value) listStatus.push(1); 
    if (this.statusNotStartedFormControl.value) listStatus.push(0);
    const storeTarget = Array.from(this.targetStoreSelected, (([key, value]) => { 
      if (value) {
        for (let index = 0; index < this.targetStores.length; index++) {
          const element = this.targetStores[index];
          if(element.storeNameWithCode == key) return element.storeCd;
        }
      }
    })).filter(item => item != null);
    let request: ReqSubTaskResultOtherStoresCommentSearch = {
      access: this.commonService.loginUser,
      taskId: this.taskRec.taskId,
      listSubTaskIdCond: Array.from(this.subtaskSelected, (([key, value]) => { if (value) return +key})).filter(item => item != null),
      listSubTaskStoreCond: storeTarget,
      listSubTaskStatusCond: listStatus
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("SubTaskResultOtherStoresCommentSearch", request).subscribe(
      (response: RspSubTaskResultOtherStoresCommentSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveComments(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveComments(response: RspSubTaskResultOtherStoresCommentSearch) {
    if (this.httpBasic.handleAppError(response)) return;

    this.taskService.subTaskOtherStoresCommentRecs = [];
    response.result.forEach((row) => {
      let rec = new SubTaskOtherStoresResultCommentRecDto(row, this.commonService, this.taskService);
      if (rec.subTaskId < 0) {
        rec.subTaskName = "(親タスク)";
      }
      this.taskService.subTaskOtherStoresCommentRecs.push(rec);
    });
    this.taskService.subTaskOtherStoresCommentRecsRecordCount = this.taskService.subTaskOtherStoresCommentRecs.length;
    this.pageChanged(null);
  }

  pageChanged(pageEvent: PageEvent) {
    let start = this.matPagenator !== undefined ? this.matPagenator.pageIndex * this.matPagenator.pageSize : 0;
    let end = this.matPagenator !== undefined ? start + this.matPagenator.pageSize : this.commonService.config.pagenatorOptions[0];
    this.commentRecs = this.taskService.subTaskOtherStoresCommentRecs.slice(start, end);
  }

  canDeactivate() {
    return true;
  }
}
