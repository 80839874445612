import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreGroupNameRec } from 'src/app/common/store-group-name-rec';
import { StoreGroupRec } from 'src/app/common/store-group-rec';
import { StoreGroupTypeRec } from 'src/app/common/store-group-type-rec';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { PromSelectDialogComponent } from 'src/app/dialog/prom-select-dialog/prom-select-dialog.component';
import { PromSelectListDialogComponent } from 'src/app/dialog/prom-select-list-dialog/prom-select-list-dialog.component';
import { TableItemDialogComponent } from 'src/app/dialog/table-item-dialog/table-item-dialog.component';
import { CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { RspGetStoreGroupType } from 'src/app/response/rsp-get-store-group-type';
import { CommonService } from 'src/app/service/common.service';
import { CtgService } from 'src/app/service/ctgService';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { PromSimItem } from '../0_def/promSim';
import { ItemSearchConDto, ItemSimDto, ItemSimUpdateDto, PromLastDto, PromSimDto, ReqPromSearchCondDto, ReqPromSimDto, ReqPromSimItemSearch, ReqPromSimItemUpdate, RspPromSimItemSearch, RspPromSimItemUpdate } from '../0_def/promSimDef';

@Component({
  selector: 'app-sale-simulation-edit',
  templateUrl: './prom-sim-edit.component.html',
  styleUrls: ['./prom-sim-edit.component.css']
})

export class PromSimEditComponent implements OnInit, OnChanges {

  public isReadonly: boolean = false;
  public validError: string;
  public recordCount: number = 0;
  public recordCountResOrigin: number = 0;
  public dateBegin: Date;
  public dateEnd: Date;
  public promSim: PromSimDto;
  public selectedPromSimItem: PromSimItem;
  public promSimItems: PromSimItem[];
  public promSimItemsOrigin: PromSimItem[] = [];
  public promSimItemsDisList: PromSimItem[];
  public itemCd: FormControl = new FormControl("");
  public itemName: FormControl = new FormControl("");
  public storeGroupTypeList: StoreGroupTypeRec[] = [];
  public storeGroupNameList: StoreGroupNameRec[] = [];
  private subscription$ = new Subject();
  public selectedGroupType: StoreGroupTypeRec;
  public selectedStoreGroupName: StoreGroupNameRec;
  public storeGroupList: StoreGroupRec[] = [];
  public tableWidth: any;
  public pagenatorMaxWidth: any;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[] = [];
  public undisplayColumnDefs: TableColumnDef[] = [];
  public disableDisplayColumnDefs: TableColumnDef[] = [];
  private subscriptionDialog: Subscription;
  private subscr: Subscription;
  public notifyEvent: EventEmitter<any> = new EventEmitter();
  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public dataDialog: ItemSimDto[] = [];
  public getReqPromSimItem: ReqPromSimItemSearch;
  public formGroupPromInfo: FormGroup;
  public totalEstSaleNum: number = undefined;
  public totalSaleProfit: number = undefined;
  public totalSaleRate: number = undefined;
  public updateCount: number = 0;
  public storeList: string[] = [];
  private listItem: ItemSearchConDto[];
  public disableReCalc: boolean = true;
  public disableSave: boolean = true;
  public disableCancel: boolean = true;

  constructor(
    public commonService: CommonService,
    public ctgService: CtgService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
  ) {
    this.formGroupPromInfo = new FormGroup({
      simPromName: new FormControl(""),
      storeGroupTypeForm: new FormControl(-1),
      storeGroupNameForm: new FormControl(-1),
    });
  }

  @Input("promSim") promSimInput: PromSimDto;
  @Input("promLast") promLastInput: PromLastDto;
  @Output() clearPromSim = new EventEmitter();
  @Output() refreshPromSimList = new EventEmitter<PromSimDto[]>();
  @ViewChild(MatTable, { static: true }) matTable: MatTable<any>;
  @ViewChild(DateRangeComponent, { static: false }) dateRangeComponent: DateRangeComponent;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  public stickyColumnDefs: TableColumnDef[] = [
    { columnId: "checkBox", header: "削除", width: 50, align: "center" },
    { columnId: "itemCdFv", header: "商品コード", width: 120, align: "center" },
    { columnId: "itemNameFv", header: "商品名", width: 300, align: "left" },
  ];
  public columnDefs: TableColumnDef[] = [

    { columnId: "standardFv", header: "規格", width: 100, align: "left" },
    { columnId: "ctg0Fv", header: this.commonService.literal.ctg0Name, width: 200, align: "left" },
    { columnId: "ctg1Fv", header: this.commonService.literal.ctg1Name, width: 200, align: "left" },
    { columnId: "ctg2Fv", header: this.commonService.literal.ctg2Name, width: 200, align: "left" },
    { columnId: "ctg3Fv", header: this.commonService.literal.ctg3Name, width: 200, align: "left" },
    { columnId: "pastCost", header: "過去原価", width: 60, align: "right", numberPipe: "1.2-2" },
    { columnId: "pastSell", header: "過去売価", width: 60, align: "right", numberPipe: "1.2-2" },
    { columnId: "pastSale", header: "販売実績", width: 60, align: "right", numberPipe: "1.0-0" },
    { columnId: "costPrice", header: "原価", width: 60, align: "right" },
    { columnId: "sellPrice", header: "売価", width: 60, align: "right" },
    { columnId: "estSaleNum", header: "予測売上数", width: 100, align: "right", numberPipe: "" },
    { columnId: "estSalePrice", header: "予測売上金額", width: 100, align: "right", numberPipe: "" },
    { columnId: "estSaleProfit", header: "予測粗利金額", width: 100, align: "right", numberPipe: "" },
    { columnId: "estSaleRate", header: "予測粗利率(%)", width: 100, align: "right", numberPipe: "1.0-0" }
  ];

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.selectedGroupType = new StoreGroupTypeRec();
    this.selectedGroupType.storeGroupTypeCd = -1;
    this.selectedGroupType.storeGroupType = "全店";
    this.storeGroupTypeList.push(this.selectedGroupType);

    this.httpBasic.getStoreGroupType().pipe(takeUntil(this.subscription$)).subscribe(
      (response) => {
        this.receiveStoreGroupType(response)
      },
      (error) => {
        this.httpBasic.handleError(error);
      }
    );

    for (var colDef of this.columnDefs) {
      if (colDef.columnId === "storeCdFv" && this.commonService.stores.length <= 1) continue;
      if (colDef.columnId === "ctg0Fv" && this.commonService.config.maxCtgLevel < 1) continue;
      if (colDef.columnId === "ctg1Fv" && this.commonService.config.maxCtgLevel < 2) continue;
      if (colDef.columnId === "ctg2Fv" && this.commonService.config.maxCtgLevel < 3) continue;
      if (colDef.columnId === "ctg3Fv" && this.commonService.config.maxCtgLevel < 4) continue;
      if (colDef.columnId === "costPrice" || colDef.columnId === "sellPrice" || colDef.columnId === "estSaleNum") {
        this.disableDisplayColumnDefs.push(colDef);
      }
      if (colDef.columnId === "ctg0Fv" || colDef.columnId === "ctg1Fv") {
        this.undisplayColumnDefs.push(colDef)
      } else {
        this.displayColumnDefs.push(colDef);
      }

    }

    // SELECT STORE GROUP TYPE
    this.subscr = this.formGroupPromInfo.get("storeGroupTypeForm").valueChanges.subscribe(
      () => { this.selectGroupType(this.formGroupPromInfo.get("storeGroupTypeForm").value) });

    this.setColumnId();
    this.calcTableWidth();
  }

  ngOnChanges() {
    this.promSimItems = undefined;
    this.dataDialog = undefined;
    this.promSimItemsOrigin = undefined;
    this.promSimItemsDisList = undefined;
    this.promSim = undefined;
    this.isReadonly = false;
    this.updateCount = 0;
    this.promSim = this.promSimInput;
    this.dateRangeComponent = undefined;
    if (!this.promSimInput) return;
    if (this.promSim.saveMode == 0) {
      this.dateBegin = new Date(this.promSim.dateBegin);
      this.dateEnd = new Date(this.promSim.dateEnd);
    } else {
      this.dateBegin = new Date;
      this.dateEnd = new Date;
    }
    if (this.promSim.confirmStatusFn == 1) this.isReadonly = true;

    if (this.promSim.selectMode != 1) {
      this.getPromItemsList();
      this.getPromInfo();
    } else {
      this.recordCount = 0;
      this.initValue();
    }
  }

  getPromInfo() {
    if (this.promSim != undefined) {
      this.formGroupPromInfo.get("simPromName").setValue(this.promSim.simPromName);
      this.formGroupPromInfo.get("storeGroupTypeForm").setValue(this.promSim.storeGroupTypeCdFv);
      this.formGroupPromInfo.get("storeGroupNameForm").setValue(this.promSim.storeGroupCdFv);
      this.formGroupPromInfo.markAsPristine();
      this.totalEstSaleNum = (this.updateCount > 0 || this.promSim.forecastSalesFn == 0)  ? this.totalEstSaleNum : this.promSim.forecastSalesFn;
      this.totalSaleProfit = (this.updateCount > 0 || this.promSim.forecastProfitFn == 0)  ? this.totalSaleProfit : this.promSim.forecastProfitFn;
      this.totalSaleRate = (this.updateCount > 0 || this.promSim.forecastRateFn == 0) ? this.totalSaleRate : this.promSim.forecastRateFn;
      this.itemCd.setValue("");
      this.itemName.setValue("");
    }
  }

  initValue() {
    if (this.promSim != undefined) {
      this.formGroupPromInfo.get("simPromName").setValue("");
      this.formGroupPromInfo.get("storeGroupTypeForm").setValue(-1);
      this.formGroupPromInfo.get("storeGroupNameForm").setValue(-1);
      this.formGroupPromInfo.markAsPristine();
      this.totalEstSaleNum = undefined;
      this.totalSaleProfit = undefined;
      this.totalSaleRate = undefined;
      this.itemCd.setValue("");
      this.itemName.setValue("");
    }
    this.updateCount = 0;
  }

  initRack() {
    this.notifyEvent.emit();
  }

  findStoreGroupType(code: number) {
    for (let type of this.storeGroupTypeList) {
      if (type.storeGroupTypeCd == code) return type;
    }
    return undefined;
  }

  findStoGroupName(code: number) {
    for (let type of this.storeGroupNameList) {
      if (type.storeGroupCd == code) return type;
    }
    return undefined;
  }

  selectGroupType(groupTypeCd: number) {
    if (this.selectedGroupType && this.selectedGroupType.storeGroupTypeCd === groupTypeCd) return;

    let type = this.findStoreGroupType(groupTypeCd);
    if (type == undefined) return;
    this.selectedGroupType = type;
    this.selectedStoreGroupName = undefined;

    this.formGroupPromInfo.get("storeGroupNameForm").setValue("");
    this.commonService.closeSpinner();
    this.storeGroupSearch(this.selectedGroupType);
  }

  storeGroupSearch(type: StoreGroupTypeRec) {
    this.commonService.openSpinner(this.commonService.pageTitle, '検索中・・・');
    forkJoin([
      this.httpBasic.getStoreGroupName(type.storeGroupTypeCd),
      this.httpBasic.getGroupTypeList(type.storeGroupTypeCd)
    ]).subscribe(
      response => {
        this.storeGroupNameList = [];
        this.storeGroupList = [];
        this.receiveStoreGroupSearch(response);
        if (this.storeGroupNameList.length > 0) {
          let typeName = this.findStoGroupName(this.promSim.storeGroupCdFv);
          let storeGroupCd = typeName ? typeName.storeGroupCd : this.storeGroupNameList[0].storeGroupCd;

          this.formGroupPromInfo.get("storeGroupNameForm").setValue(storeGroupCd);
        }
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveStoreGroupSearch(response) {
    this.commonService.closeSpinner();
    if (response[0] && this.httpBasic.handleAppError(response[0])) { return; }
    if (response[1] && this.httpBasic.handleAppError(response[1])) { return; }
    if (response[0] && response[0].storeGroupNames !== undefined && response[0].storeGroupNames.length >= 0) {
      this.storeGroupNameList = [];
      response[0].storeGroupNames.forEach(element => {
        const storeGroupItem: StoreGroupNameRec = {
          storeGroupCd: element.storeGroupCdFn,
          storeGroupTypeCd: element.storeGroupTypeCdFn,
          storeGroupName: element.storeGroupNameFv
        };
        this.storeGroupNameList.push(
          storeGroupItem
        );
      });
    }

    if (response[1] && response[1].result !== undefined && response[1].result !== null && response[1].result.length >= 0) {
      this.storeGroupList = [];
      response[1].result.forEach(element => {
        const storeGroupItem: StoreGroupRec = {
          storeGroupTypeCd: 0,
          storeGroupCd: +element.storeGroupCdFv,
          storeGroupName: element.storeGroupNameFv,
          storeCd: element.storeCdFv,
          storeName: element.storeNameFv,
          storeDispName: element.storeCdFv + ':' + element.storeNameFv,
        };
        this.storeGroupList.push(
          storeGroupItem
        );
      });
    }
  }

  receiveStoreGroupType(response: RspGetStoreGroupType) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    for (let type of response.storeGroupTypes) {
      let typeRec = new StoreGroupTypeRec();
      typeRec.storeGroupTypeCd = type.storeGroupTypeCdFn;
      typeRec.storeGroupType = type.storeGroupTypeFv;
      this.storeGroupTypeList.push(typeRec);
    }
  }

  isSingleStore(): boolean {
    if (this.commonService.stores.length == 1) return true;
    return false;
  }

  isAllStore(): boolean {
    if (this.formGroupPromInfo.get("storeGroupTypeForm").value == -1) return true;
    return false;
  }

  selectTableItem() {
    scrollTo(0, 0);
    const dialogRef = this.commonService.dialog.open(TableItemDialogComponent, {
      disableClose: true,
      data: { display: this.displayColumnDefs, nonDisplay: this.undisplayColumnDefs, disableDisplay: this.disableDisplayColumnDefs }
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => {
        this.setColumnId();
        this.calcTableWidth();
        this.dialogResult(data);
      }
    );
  }

  setColumnId() {
    this.displayColumnIds = [];

    for (var colDef of this.stickyColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }

    for (var colDef of this.displayColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    for (var colDef of this.stickyColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
    this.pagenatorMaxWidth = { "max-width": "" + width + "px" }
  }

  dialogResult(data: any) {
    this.subscriptionDialog.unsubscribe();
    this.subscriptionDialog = undefined;
    this.matTable?.renderRows();
  }

  selectPromItem(item: PromSimItem) {
    this.selectedPromSimItem = item;
  }

  dateChanged(event) {
    this.disableReCalc = false;
    this.disableCancel = false;
  }

  genReqPromSearchCondDto() {
    let dateRage = this.dateRangeComponent?.getDateRange();

    const dto: ReqPromSearchCondDto = {
      startDate: dateRage?.dateBegin ? this.commonService.formatDate(dateRage.dateBegin) : this.commonService.formatDate(this.dateBegin),
      finishDate: dateRage?.dateEnd ? this.commonService.formatDate(dateRage.dateEnd) : this.commonService.formatDate(this.dateEnd),
      storeGroupType: this.promSim.storeGroupTypeCdFv,
      storeGroupcd: (this.promSim.storeGroupCdFv == undefined || this.promSim.storeGroupCdFv == null) ? -1 : this.promSim.storeGroupCdFv
    }
    let storeGroupTypeCode = parseInt(this.formGroupPromInfo.get("storeGroupTypeForm").value);
    let storeGroupCode = parseInt(this.formGroupPromInfo.get("storeGroupNameForm").value);
    if (this.formGroupPromInfo.get("storeGroupTypeForm").dirty) {
      dto.storeGroupType = storeGroupTypeCode;
      dto.storeGroupcd = this.isAllStore() ? -1 : storeGroupCode;
    }
    if(!storeGroupCode)  dto.storeGroupcd = -1;
    return dto;
  }

  genReqPromSimDto() {
    const dto: ReqPromSimDto = {
      simPromCd: this.promSim.simPromCd,
      dateBegin: this.promSim.dateBegin,
      dateEnd: this.promSim.dateEnd
    }
    return dto;
  }

  genReqPromSimItemSearch() {
    let editMode: number = this.updateCount > 0 ? 0 : this.promSim.selectMode;

    const request: ReqPromSimItemSearch = {
      access: this.commonService.loginUser,
      itemSearchCond: {
        listItemCdFv: []
      },
      promSearchCond: this.genReqPromSearchCondDto(),
      promOrderDto: this.promSim.selectMode == 2 ? this.promLastInput : null,
      promSimDto: this.genReqPromSimDto(),
      maxCtgLevel: this.commonService.config.maxCtgLevel,
      editMode: editMode,
    }
    return request;
  }

  getPromItemsList(simPromCdNew?: string) {

    let request: ReqPromSimItemSearch = this.genReqPromSimItemSearch();
    if (simPromCdNew) {
      request.promSimDto.simPromCd = simPromCdNew;
    }

    this.getReqPromSimItem = request;
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemSimList", request).subscribe(
      (response: RspPromSimItemSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePromSimItemSearch(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  filterByCondition(list: ItemSimDto[] | PromSimItem[], valueConditions: string[], keyConditions: string[]): any {
    valueConditions.forEach((value: string, i) => {
      if (value) {
        list = list?.filter((item) => {
          return (item[keyConditions[i]]
            .toString()
            .toLowerCase()
            .indexOf(value.toString().toLowerCase()) !== -1)
        });
      }
    });
    return list;
  }

  doQuery() {
    if(!this.promSimItems) return;
    let ctgCd0 = this.ctgSelectCondition.formGroup.get("ctgCd0").value;
    let ctgCd1 = this.ctgSelectCondition.formGroup.get("ctgCd1").value;
    let ctgCd2 = this.ctgSelectCondition.formGroup.get("ctgCd2").value;
    let ctgCd3 = this.ctgSelectCondition.formGroup.get("ctgCd3").value;
    let itemCd = this.itemCd.value.trim();
    let itemName = this.itemName.value.trim();
    let valueCondition: string[] = [ctgCd0, ctgCd1, ctgCd2, ctgCd3, itemCd, itemName];
    let keyCondition: string[] = ['ctg1CdFv', 'ctg2CdFv', 'ctg3CdFv', 'ctg4CdFv', 'itemCdFv', 'itemNameFv'];
    const filteredList: PromSimItem[] = this.filterByCondition(this.promSimItems, valueCondition, keyCondition);
    this.recordCount = filteredList.length || 0;
    if (this.matPaginator) {
      this.matPaginator.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
    }
    this.pageDisplayChanged(filteredList);
  }

  pageDisplayChanged(filteredList) {
    this.promSimItemsDisList = [];
    let start;
    let end;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.recordCount) end = this.recordCount;
    for (let i = start; i < end; i++) {
      this.promSimItemsDisList.push(filteredList[i]);
    }
  }

  receivePromSimItemSearch(response: RspPromSimItemSearch) {
    if (this.httpBasic.handleAppError(response)) {
      return;
    }
    this.selectedPromSimItem = undefined;
    this.dataDialog = [];
    this.promSimItems = [];
    this.promSimItemsOrigin = [];

    response?.rows.forEach((row) => {
      this.promSimItems.push(new PromSimItem(row));
      this.dataDialog.push(row);
    }
    );

    this.promSimItemsOrigin = this.promSimItems?.length > 0 ? [...this.promSimItems].map(rec => new PromSimItem(rec?.originDto)) : [];
    this.recordCount = this.promSimItems?.length ?? 0;
    this.recordCountResOrigin = this.recordCount;
    if (this.matPaginator) {
      this.matPaginator.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
      this.matPaginator.pageSize = this.commonService.config.pagenatorOptions[0];
    }

    this.pageChanged();
    this.getForecast(this.promSimItems);
  }

  pageChanged() {
    let start;
    let end;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.recordCount) end = this.recordCount;
    for (let i = start; i < end; i++) {
      // if (this.promSimItems && this.promSimItems[i]?.pastSale == 0) this.promSimItems[i].pastSale = undefined;
      if (this.promSimItems && this.promSimItems[i]?.estSaleNum == 0) this.promSimItems[i].estSaleNum = undefined;
    }
    if(this.promSimItemsDisList?.length == 1) this.promSimItemsDisList = this.promSimItemsDisList;
    else this.promSimItemsDisList = this.promSimItems?.slice(start, end);
  }

  getForecast(response: PromSimItem[]) {
    let totalSalePrice: number = 0;
    let totalSaleProfit: number = 0;
    let totalSaleRate: number = 0;
    response?.forEach(row => {
      totalSalePrice += row.estSalePrice;
      totalSaleProfit += row.estSaleProfit;
    })
    totalSaleRate = (totalSaleProfit / totalSalePrice) * 100;
    this.totalEstSaleNum = (isNaN(totalSalePrice) || totalSalePrice == 0) ? undefined : totalSalePrice;
    this.totalSaleProfit = (isNaN(totalSaleProfit) || totalSaleProfit == 0) ? undefined : totalSaleProfit;
    this.totalSaleRate = (isNaN(totalSaleRate) || totalSaleRate == 0) ? undefined : totalSaleRate;
  }

  addItem() {
    this.getReqPromSimItem = this.genReqPromSimItemSearch();
    const dialogRef = this.commonService.dialog.open(PromSelectDialogComponent, {
      disableClose: true,
      data: {
        request: this.getReqPromSimItem,
        rows: this.dataDialog
      }
    });
    let subsc = dialogRef.afterClosed().subscribe(
      (data) => {
        subsc.unsubscribe();
        if (!data) return;
        this.addNewItem(data);
        this.matTable?.renderRows();
      }
    );
  }

  addItemList() {
    this.getReqPromSimItem = this.genReqPromSimItemSearch();
    const dialogRef = this.commonService.dialog.open(PromSelectListDialogComponent, {
      disableClose: true,
      data: {
        request: this.getReqPromSimItem,
        rows: this.dataDialog
      }
    });
    let subsc = dialogRef.afterClosed().subscribe(
      (data) => {
        subsc.unsubscribe();
        if (!data) return;
        this.addNewItem(data);
        this.matTable?.renderRows();
      }
    );
  }

  addNewItem(res) {
    let newAddedLists: ItemSimDto[] = [];
    if (this.promSimItems == undefined) {
      this.promSimItems = [];
      this.promSimItemsOrigin = [];
      this.dataDialog = [];
    };
    res.forEach((item: ItemSimDto) => { newAddedLists.push(item) });
    this.promSimItems = this.promSimItems.filter(el => el.delFlag == false);
    newAddedLists.forEach((item: ItemSimDto) => {
      this.promSimItems.push(new PromSimItem(item));
      this.dataDialog.push(item);
    });
    this.ctgSelectCondition.formGroup.controls["ctgCd0"].setValue("");
    this.itemCd.setValue("");
    this.itemName.setValue("");
    this.promSimItemsDisList = this.promSimItems;
    this.recordCount = this.promSimItemsDisList.length;
    this.pageChanged();
    this.getForecast(this.promSimItems);
    this.disableSave = false;
    this.disableCancel = false;
  }

  onItemChecked(item: PromSimItem, checked: boolean) {
    let itemSimDto = this.genItemSimDto(item)
    this.disableSave  = false;
    this.disableCancel = false;
    this.disableReCalc = false;
    if (checked) {
      this.promSimItems.forEach((el) => {
        if (el.itemCdFv === item.itemCdFv) {
          el.delFlag = true;
          item.formChecked.setValue(true);
        }
      })
      this.dataDialog.forEach((el, index) => {
        if (el.itemCdFv === item.itemCdFv) this.dataDialog.splice(index, 1);
      })
    } else {
      this.promSimItems.forEach((el) => {
        if (el.itemCdFv === item.itemCdFv) {
          el.delFlag = false;
          item.formChecked.setValue(false);
        };
      })
      this.dataDialog.push(itemSimDto);
    }
  }

  genItemSimDto(item: PromSimItem) {
    const dto: ItemSimDto = {
      ctg1CdFv: item.ctg0Fv,
      ctg1NameFv: item.ctg1NameFv,
      ctg2CdFv: item.ctg2CdFv,
      ctg2NameFv: item.ctg2NameFv,
      ctg3CdFv: item.ctg3CdFv,
      ctg3NameFv: item.ctg3NameFv,
      ctg4CdFv: item.ctg4CdFv,
      ctg4NameFv: item.ctg4NameFv,
      editCost: item.editCost,
      editSell: item.editSell,
      estSaleNum: item.estSaleNum,
      estSalePrice: item.estSalePrice,
      estSaleProfit: item.estSaleProfit,
      estSaleRate: item.estSaleRate,
      itemCdFv: item.itemCdFv,
      itemNameFv: item.itemNameFv,
      pastCost: item.pastCost,
      pastSale: item.pastSale,
      pastSell: item.pastSell,
      standardFv: item.standardFv,
      promResultCopyFn: item.promResultCopyFn,
    }
    return dto;
  }

  getReqPromSimUpdate(): ReqPromSimItemUpdate {

    let listItems: ItemSimUpdateDto[] = [];
    let today = new Date;
    let dateBeginOld = this.promSim.dateBegin ?? this.commonService.formatDate(today);
    let dateEndOld = this.promSim.dateEnd ?? this.commonService.formatDate(today);
    listItems = this.promSimItems?.filter(item => item.delFlag == false).map(item => this.genNewReqPromSimItemUpdateDto(item));
    let dateRage = this.dateRangeComponent?.getDateRange();
    this.storeList = [];
    this.getForecast(this.promSimItems);
    let request: ReqPromSimItemUpdate = {
      access: this.commonService.loginUser,
      promSimDto: {
        simPromCd: this.promSim.simPromCd,
        simPromName: this.formGroupPromInfo.get("simPromName").value.trim(),
        dateBeginOld: dateBeginOld,
        dateEndOld: dateEndOld,
        dateBegin: this.commonService.formatDate(dateRage.dateBegin),
        dateEnd: this.commonService.formatDate(dateRage.dateEnd),
        forecastSalesFn: this.totalEstSaleNum ?? 0,
        forecastProfitFn: this.totalSaleProfit ?? 0,
        forecastRateFn: this.totalSaleRate ?? 0,
        confirmStatusFn: this.promSim.confirmStatusFn ?? 0,
        storeGroupTypeCdFv: this.promSim.storeGroupTypeCdFv,
        storeGroupCdFv: (this.promSim.storeGroupCdFv == undefined || this.promSim.storeGroupCdFv == null) ? -1 : this.promSim.storeGroupCdFv
      },
      listItem: listItems,
      storeList: [],
      promOrderDateOffset: this.commonService.config?.promOrderDateOffset,
      editMode: this.updateCount > 0 ? 0 : this.promSim.saveMode
    }

    let storeGroupTypeCode = parseInt(this.formGroupPromInfo.get("storeGroupTypeForm").value);
    let storeGroupCode = parseInt(this.formGroupPromInfo.get("storeGroupNameForm").value);
    if (storeGroupTypeCode != 0) {
      request.promSimDto.storeGroupTypeCdFv = storeGroupTypeCode;
      request.promSimDto.storeGroupCdFv = storeGroupCode;
    }

    if (request.promSimDto.storeGroupCdFv == undefined || request.promSimDto.storeGroupCdFv == null || isNaN(request.promSimDto.storeGroupCdFv))
      request.promSimDto.storeGroupCdFv = -1;

    if (this.storeGroupList.length > 0) {
      this.storeGroupList.forEach(el => {
        if (el.storeGroupCd == this.formGroupPromInfo.get("storeGroupNameForm").value) {
          this.storeList.push(el.storeCd);
        }
      })
    } else {
      this.storeList = []
      this.commonService.stores.forEach(store => this.storeList.push(store.storeCd))
    }
    request.storeList = this.storeList;
    return request;
  }

  doUpdate() {
    this.selectedPromSimItem = undefined;
    let request: ReqPromSimItemUpdate = this.getReqPromSimUpdate();
    this.commonService.openSpinner(this.commonService.pageTitle, "変更中・・・");
    let subsc = this.httpBasic.generalRequest("SavePromSim", request).subscribe(
      (response: RspPromSimItemUpdate) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePromSimItemUpdate(this.promSim.confirmStatusFn, response);
        // after save, mode = 0
        this.promSim.selectMode = 0;
        this.disableReCalc = true;
        this.disableSave = true;
        this.disableCancel = true;
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receivePromSimItemUpdate(confirmStatusFn: number, response: RspPromSimItemUpdate) {
    if (this.httpBasic.handleAppError(response)) {
      return;
    }
    let promSimList: PromSimDto[] = [];
    response.rows.forEach((row) => {
      let prom: PromSimDto;
      prom = {
        confirmStatusFn: row.confirmStatusFn,
        dateBegin: row.dateBegin,
        dateEnd: row.dateEnd,
        forecastProfitFn: row.forecastProfitFn,
        forecastRateFn: row.forecastRateFn,
        forecastSalesFn: row.forecastSalesFn,
        simPromCd: row.simPromCd,
        simPromName: row.simPromName,
        storeGroupTypeCdFv: row.storeGroupTypeCdFv,
        storeGroupCdFv: row.storeGroupCdFv,
        saveMode: 0,
        selectMode: 0
      };
      promSimList.push(prom)
    })
    this.disableReCalc = true;
    this.refreshPromSimList.emit(promSimList);
    this.updateCount++;
    this.promSim.confirmStatusFn = confirmStatusFn == null ? 0 : confirmStatusFn;
    this.promSim.simPromCd = response.newPromSimCd;
    this.promSim.dateBegin = this.commonService.formatDate(new Date(response.newDateBegin));
    this.promSim.dateEnd   = this.commonService.formatDate(new Date(response.newDateEnd));
    this.getPromItemsList(this.promSim.simPromCd);
    this.matPaginator.firstPage();
    this.endEdit();
  }

  endEdit() {
    this.getNewPromSimInfo();
    this.formGroupPromInfo.markAsPristine();
    this.dateRangeComponent.formDateBegin.markAsPristine();
    this.dateRangeComponent.formDateEnd.markAsPristine();
    this.clearPromSim.emit('endEdit');
  }

  getNewPromSimInfo() {
    this.promSim.simPromName = this.formGroupPromInfo.get("simPromName").value;
    this.promSim.storeGroupTypeCdFv = parseInt(this.formGroupPromInfo.get("storeGroupTypeForm").value);
    this.promSim.storeGroupCdFv = parseInt(this.formGroupPromInfo.get("storeGroupNameForm").value);
  }

  // getPromItemChangeList(): ItemSimUpdateDto[] {
  //   let changeList: ItemSimUpdateDto[] = [];
  //   if (this.promSimItems == undefined) this.promSimItems = [];
  //   changeList = this.promSimItems.filter(item => {
  //     return item?.isNewFlag ||
  //       this.isChangeCost(item) ||
  //       this.isChangeSell(item) ||
  //       this.isChangeChecked(item)
  //   }).map(item => this.genNewReqPromSimItemUpdateDto(item));

  //   return changeList;
  // }

  // getPromItemAddNew(): ItemSimUpdateDto[] {
  //   let addListNew: PromSimItem[] = [];
  //   let addListNewDto: ItemSimUpdateDto[] = [];
  //   if (this.promSimItems == undefined || this.promSimItemsOrigin == undefined) {
  //     this.promSimItems = [];
  //     this.promSimItemsOrigin = []
  //   };
  //   if (this.promSimItems.length != this.promSimItemsOrigin.length) {
  //     this.promSimItems.forEach(row => {
  //       this.promSimItemsOrigin.forEach(item => {
  //         if (row.itemCdFv != item.itemCdFv) {
  //           addListNew.push(row)
  //         }
  //       })
  //     })
  //   }
  //   addListNewDto = addListNew.map(item => this.genNewReqPromSimItemUpdateDto(item));
  //   return addListNewDto;
  // }

  genNewReqPromSimItemUpdateDto(item: PromSimItem) {
    const dto: ItemSimUpdateDto = {
      simPromCd: this.promSim.simPromCd,
      itemCdFv: item.itemCdFv,
      pastCost: item.pastCost,
      pastSell: item.pastSell,
      pastSale: item.pastSale ?? 0,
      editCost: item.formEditCost.value,
      editSell: item.formEditSell.value,
      estSaleNum: item.estSaleNum,
      promResultCopyFn: item.promResultCopyFn,
    }
    return dto;
  }

  isChangeCost(item: PromSimItem) {
    const CostNumOrigin = item?.editCost ?? 0;
    const CostNumEdit = isNaN(item.formEditCost.value) ? 0 : item.formEditCost.value;
    return CostNumOrigin != CostNumEdit;
    //return isNaN(item.formEditCost.value) ? 0 : item.formEditCost.value != item.pastCost;
  }
  isWarnCost(item: PromSimItem) {
    return isNaN(item.formEditCost.value) ? 0 : item.formEditCost.value != item.pastCost;
  }

  isChangeSell(item: PromSimItem) {
     const SellNumOrigin = item?.editSell ?? 0;
     const SellNumEdit = isNaN(item.formEditSell.value) ? 0 : item.formEditSell.value;
     return SellNumOrigin != SellNumEdit;
    //return isNaN(item.formEditSell.value) ? 0 : item.formEditSell.value != item.pastSell;
  }

  isWarnSell(item: PromSimItem) {
    return isNaN(item.formEditSell.value) ? 0 : item.formEditSell.value != item.pastSell;
  }
  isChangeChecked(item: PromSimItem) {
    const checkedOrigin = false;
    const checkedOn = item.formChecked.value
    return checkedOrigin != checkedOn;
  }

  doCancelEdit() {
    this.selectedPromSimItem = undefined;
    if (this.promSim.selectMode != 0) {
      let subsc = this.commonService.openYesNoDialog(this.commonService.pageTitle,
        "新規作成の企画です。企画作成を取消しますか？").subscribe(
          (response: boolean) => {
            subsc.unsubscribe();
            if (response) {
              this.promSimInput = undefined;
              delete this.promSimInput;
              this.clearPromSim.emit('clearPromSim');
              if (this.promSim.saveMode != 1) { this.getPromInfo() } else { this.initValue() };
            }
          });
      return;
    }

    this.getPromInfo();
    this.promSimItems = this.promSimItemsOrigin?.length > 0 ? [...this.promSimItemsOrigin].map(rec => new PromSimItem(rec?.originDto)) : [];
    this.dataDialog = this.promSimItemsOrigin?.length > 0 ? [...this.promSimItemsOrigin] : [];
    this.recordCount = this.recordCountResOrigin;
    this.dateRangeComponent.formDateBegin.setValue(new Date(this.promSim.dateBegin));
    this.dateRangeComponent.formDateEnd.setValue(new Date(this.promSim.dateEnd));
    this.dateRangeComponent.formDateBegin.markAsPristine();
    this.dateRangeComponent.formDateEnd.markAsPristine();
    this.ctgSelectCondition.formGroup.controls["ctgCd0"].setValue("");
    this.disableReCalc = true;
    this.disableSave = true;
    this.disableCancel = true;
    if (this.matPaginator && this.recordCountResOrigin <= this.matPaginator.pageSize * this.matPaginator.pageIndex) {
      this.matPaginator.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
    }

    this.pageChanged();
  }

  doConfirm(confirmFlag: number) {
    this.selectedPromSimItem = undefined;
    let request: ReqPromSimItemUpdate = this.getReqPromSimUpdate();
    request.promSimDto.confirmStatusFn = confirmFlag;
    this.commonService.openSpinner(this.commonService.pageTitle, "変更中・・・");
    let subsc = this.httpBasic.generalRequest("SavePromSim", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receivePromSimItemUpdate(confirmFlag, response);
        this.isReadonly = confirmFlag == 1 ? true : false;
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  isDisableBtn() {
    if(!this.disableReCalc) return true;
    if (this.formGroupPromInfo.dirty) return false;
    if (this.dateRangeComponent?.formDateBegin.dirty || this.dateRangeComponent?.formDateEnd.dirty) return false;
    if (this.promSimItems?.length != this.promSimItemsOrigin?.length) return false;
    return true;
  }

  isValid(): boolean {
    this.validError = undefined;
    let storeGroupTypeCode = this.formGroupPromInfo.get("storeGroupTypeForm").value;
    let storeGroupCode = this.formGroupPromInfo.get("storeGroupNameForm").value;
    if (this.formGroupPromInfo.get("simPromName").value === "") {
      this.validError = "企画名が設定されていません。"
      return false;
    }
    if ((!storeGroupTypeCode || !storeGroupCode || storeGroupCode == 0) && storeGroupTypeCode != -1) {
      this.validError = "店舗グループが設定されていません。"
      return false;
    }
    return true;
  }

  onInputNum(formControl: FormControl, event: any) {
    this.disableSave = false;
    this.disableCancel = false;
    this.disableReCalc = false;
    let value = parseFloat(event.target.value);
    if (isNaN(value)) value = 0;
    if (value > 9999999) {
      value = 0;
      formControl.setValue(value.toFixed(2), { emitEvent: false });
      this.commonService.openErrorDialog(this.commonService.pageTitle, "入力した数字が大きすぎます。");
    } else{
      formControl.setValue(value.toFixed(2));
    }
  }

  checkItemListEmpty() {
    if (this.promSimItemsDisList == undefined || this.promSimItemsDisList?.length == 0) return true;
    return false;
  }

  ngOnDestroy() {
    if (this.subscriptionDialog) {
      this.subscriptionDialog.unsubscribe();
    }
    if (this.subscr) {
      this.subscr.unsubscribe();
    }
    this.updateCount = 0;
    this.subscription$.next();
    this.subscription$.complete();
  }

  doSearch() {
    let listItemCdFv: ItemSearchConDto[] = [];
    let listItemPromLastCdFv: ItemSearchConDto[] = [];
    if (!this.promSimItems) return;
    this.promSimItems = this.promSimItems.filter(x => !x.delFlag);
    const listPromItems = this.promSimItems.filter(item => {
      let cost = this.isChangeCost(item) ? item.formEditCost.value : -1
      let sell = this.isChangeSell(item) ? item.formEditSell.value : -1
      if(item.promResultCopyFn == 1 && this.promSimInput.selectMode == 2) {
        return this.isChangeCost(item) ||
        this.isChangeSell(item)
      }else {
        listItemCdFv.push(
          {
            itemCdFv: item.itemCdFv,
            editCost: cost,
            editSell: sell
          }
        )
      }
    })

    this.promSimItems.forEach(item => {
      if(this.isChangeCost(item) || this.isChangeSell(item)) {
        listItemPromLastCdFv.push(
          {
            itemCdFv: item.itemCdFv,
            editCost: item.formEditCost.value ?? -1,
            editSell: item.formEditSell.value ?? -1
          }
        )
      }
    })

    let request: ReqPromSimItemSearch = {
      access: this.commonService.loginUser,
      itemSearchCond: {
        listItemCdFv: listItemCdFv
      },
      promSearchCond: this.genReqPromSearchCondDto(),
      promOrderDto: null,
      promSimDto: this.genReqPromSimDto(),
      maxCtgLevel: this.commonService.config.maxCtgLevel,
      editMode: 1
    }

    let requestPromLast: ReqPromSimItemSearch = {
      access: this.commonService.loginUser,
      itemSearchCond: {
        listItemCdFv: listItemPromLastCdFv
      },
      promSearchCond: this.genReqPromSearchCondDto(),
      promOrderDto: {
        dateBegin: null,
        dateEnd: null,
        promCd: this.promSim.simPromCd.split("_")[0],
        promName: this.promSim.simPromName?? null
      },
      promSimDto: this.genReqPromSimDto(),
      maxCtgLevel: this.commonService.config.maxCtgLevel,
      editMode: 2
    }

    this.listItem = listItemCdFv;
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    forkJoin([
      this.listItem.length > 0 ? this.httpBasic.generalRequest("GetItemSimList", request) : of(1),
      this.promSimInput.selectMode == 2 ? this.httpBasic.generalRequest("GetItemSimList", requestPromLast) : of(1)
    ]).subscribe(
      response=> {
        this.commonService.closeSpinner();
        this.receiveDoSearch(response[0], response[1]);
      },
      (error) => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDoSearch(response: RspPromSimItemSearch, responsePromLast: RspPromSimItemSearch) {
    if (this.httpBasic.handleAppError(response) || this.httpBasic.handleAppError(responsePromLast)) {
      return;
    }
    this.disableReCalc = true;
    this.disableSave = false;
    this.disableCancel = false;
    this.selectedPromSimItem = undefined;

    if(response?.rows) {
      this.promSimItems.forEach((e)=>{
        let temp = response.rows.find(element=> element.itemCdFv === e.itemCdFv)
        if(temp) {
          e.pastCost = temp.pastCost;
          e.pastSale = temp.pastSale;
          e.pastSell = temp.pastSell;
          e.estSaleNum = temp.estSaleNum;
          e.estSalePrice = temp.estSalePrice;
          e.estSaleProfit = temp.estSaleProfit;
          e.estSaleRate = temp.estSaleRate;
          if(this.isChangeCost(e)) {
            e.formEditCost.setValue(temp.editCost.toFixed(2) ?? 0);
          }else{
            e.editCost = temp.editCost;
            e.formEditCost.setValue(e.editCost.toFixed(2) ?? 0);
            e.formEditCost.markAsDirty();
          }
          if(this.isChangeSell(e)) {
            e.formEditSell.setValue(temp.editSell.toFixed(2) ?? 0);
          } else{
            e.editSell = temp.editSell;
            e.formEditSell.setValue(e.editSell.toFixed(2) ?? 0);
            e.formEditSell.markAsDirty();
          }
        }
        return e;
      })
    }
    if(responsePromLast?.rows) {
      this.promSimItems.forEach((e)=>{
        let temp = responsePromLast.rows.find(element=> element.itemCdFv === e.itemCdFv)
        if(temp) {
          e.pastCost = temp.pastCost;
          e.pastSale = temp.pastSale;
          e.pastSell = temp.pastSell;
          e.estSaleNum = temp.estSaleNum;
          e.estSalePrice = temp.estSalePrice;
          e.estSaleProfit = temp.estSaleProfit;
          e.estSaleRate = temp.estSaleRate;
          if(this.isChangeCost(e)) {
            e.formEditCost.setValue(temp.editCost.toFixed(2) ?? 0);
          }else{
            e.editCost = temp.editCost;
            e.formEditCost.setValue(e.editCost.toFixed(2) ?? 0);
            e.formEditCost.markAsDirty();
          }
          if(this.isChangeSell(e)) {
            e.formEditSell.setValue(temp.editSell.toFixed(2) ?? 0);
          } else{
            e.editSell = temp.editSell;
            e.formEditSell.setValue(e.editSell.toFixed(2) ?? 0);
            e.formEditSell.markAsDirty();
          }
        }
        return e;
      })
    }

    this.promSimItemsDisList = this.promSimItems;
    this.recordCount = this.promSimItemsDisList?.length || 0;
    if (this.matPaginator) {
      this.matPaginator.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
      this.matPaginator.pageSize = this.commonService.config.pagenatorOptions[0];
    }
    this.pageChanged();
    this.getForecast(this.promSimItems);
  }

  onStoreGroupChange(){
    this.disableReCalc = false;
    this.disableCancel = false;
  }

  checkSaveDisable(){
    if (!this.isDisableReCalc()) return true; // when 表示 enable -> save button is disable
    if (this.isReadonly) return true;
    if (!this.isValid()) return true;
    if (this.disableReCalc && this.disableSave) return true;
    return false;
  }

  checkCancelDisable(){
    if (this.formGroupPromInfo.get("simPromName").value.trim() === '') return false;
    if (this.isReadonly) return true;
   // if (!this.isValid()) return true;
    if (this.disableCancel) return true;
    return false;
  }

  checkConfirmDisable() {
    if (!this.isDisableReCalc()) return true; // when 表示 enable -> Confirm button is disable
    if(this.checkItemListEmpty()) return true;
    if(!this.checkSaveDisable()) return true;
    if(!this.isValid()) return true;
    if(this.promSim.confirmStatusFn == null) return true;
    if(this.promSim.confirmStatusFn == 1) return true;
    if(this.promSim.confirmStatusFn == 0) return false;
    return false;
  }

  checkCancelConfirmDisable() {
    if (!this.isDisableReCalc()) return true; // when 表示 enable -> Cancel Confirm button is disable
    if(this.checkItemListEmpty()) return true;
    if(!this.checkSaveDisable()) return true;
    if(!this.isValid()) return true;
    if(this.promSim.confirmStatusFn == null) return true;
    if(this.promSim.confirmStatusFn == 0) return true;
    if(this.promSim.confirmStatusFn == 1) return false;
    return false;
  }

  onSimPromNameInput(){
    this.disableSave = false;
    this.disableCancel = false;
  }

  isDisableReCalc() {
    if(this.promSimItemsDisList == undefined || this.promSimItemsDisList?.length == 0) return true;
    if(!this.isValid()) return true;
    return this.disableReCalc;
  }
}
