import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CsvData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CsvTableStoreComponent } from 'src/app/partsCommon/csv-table-store/csv-table-store.component';
import { RspForecastUpDown } from 'src/app/response/rsp-forecast-up-down';
import { ForecastUpDownRec } from '../forecast-up-down/forecast-up-down-rec';
import { DecimalPipe } from '@angular/common';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { ReqCsvUpDownCheckDto } from 'src/app/request/req-csv-up-down-check';
import { RspCsvUpDownCheck, RspCsvUpDownCheckDto } from 'src/app/response/rsp-csv-updown-check';
import { ReqCsvUpDownUpdateDto } from 'src/app/request/req-csv-up-down-update';

interface UpDown {
  storeCdFv: string;
  storeNameFv: string;
  calcTrgtDateFv: string;
  cusEstNumFv: number | string;
  cusResultNumFv: number | string;
}

@Component({
  selector: 'app-csv-up-down',
  templateUrl: './csv-up-down.component.html',
  styleUrls: ['./csv-up-down.component.css']
})
export class CsvUpDownComponent implements OnInit, OnDestroy {
  private isReadonly: boolean;
  public csvTableHeight: number = 400;
  public upDownList: CsvData;
  selectedStore: string[] = [];
  isDisableUpdateBtn: boolean = true;
  selectedStoreCount: number = 0;
  public columnDefs: TableColumnDef[] = [
    { columnId: 'ctgCd', header: "カテゴリ・コード", width: 100, csvComment: "[任意の階層]" },
    { columnId: 'ctgName', header: "カテゴリ名", width: 200, csvComment: "[空欄可]" },
    { columnId: 'rank', header: "ランク", width: 60, align: "center", csvComment: "[複数ランク指定可。例：AB]" },
    { columnId: 'rate', header: "倍率", width: 60, align: "right", csvComment: "" },
    { columnId: 'dateBegin', header: "開始日", width: 100, align: "center", csvComment: "[西暦。明日以降の日付を指定]" },
    { columnId: 'dateEnd', header: "終了日", width: 100, align: "center", csvComment: "[西暦。開始日以降の日付を指定]" },
    { columnId: 'noiseCut', header: "ノイズカット", width: 100, align: "center", csvComment: "[有効/無効]" }
  ];

  public formStore: FormControl = new FormControl();
  public stores: LoginStoreDto[];
  public queryColumnIds: string[] = ["storeFv", "ctg0Fv", "ctg1Fv", "ctg2Fv", "ctg3Fv", "rankFv", "rateFv", "dateBeginFv", "dateEndFv", "noiseCutFv"];
  // TODO ctg max config
  public queryColumnDefs: TableColumnDef[] = [
    { columnId: 'storeFv', header: "店舗", width: 150 },
    { columnId: 'ctg0Fv', header: this.commonService.literal.ctg0Name, width: 200 },
    { columnId: 'ctg1Fv', header: this.commonService.literal.ctg1Name, width: 200 },
    { columnId: 'ctg2Fv', header: this.commonService.literal.ctg2Name, width: 200 },
    { columnId: 'ctg3Fv', header: this.commonService.literal.ctg3Name, width: 200 },
    { columnId: 'rankFv', header: "ランク", width: 60, align: "center" },
    { columnId: 'rateFv', header: "倍率", width: 60, align: "right" },
    { columnId: 'dateBeginFv', header: "開始日", width: 100, align: "center" },
    { columnId: 'dateEndFv', header: "終了日", width: 100, align: "center" },
    { columnId: 'noiseCutFv', header: "ノイズカット", width: 100, align: "center" }
  ];
  public upDownQueryList: ForecastUpDownRec[];

  @ViewChild(CsvTableStoreComponent, { static: false }) csvTableComponent: CsvTableStoreComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private decimalPipe: DecimalPipe,
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("csvUpDown");

    if (this.commonService.stores.length <= 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この機能を使用する権限がありません。");
      this.commonService.router.navigate(['top']);
      return;
    }

    if (this.commonService.stores.length > 1) {
      this.stores = [{ storeCd: "*", storeName: "全店" }, ...this.commonService.stores];
    } else {
      this.stores = [...this.commonService.stores];
    }

    this.formStore.setValue("*");

    for (let coldef of this.columnDefs) {
      if (coldef.columnId != "rate") continue;
      coldef.csvComment = "[推奨範囲: " + this.commonService.config.forecastRangeMin + " ～ " + this.commonService.config.forecastRangeMax + "]";
    }
  }

  ngOnDestroy(): void {
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.resized();
  }

  resized() {
    let id = "csv-table-container";
    let height = this.commonService.getHeightBelow(id);
    if (height) {
      this.csvTableHeight = height - 24 - 15;
    } else {
      this.csvTableHeight = 400;
    }
    if (this.csvTableComponent) {
      this.csvTableComponent.setMaxHeight(this.csvTableHeight);
    } else {
      setTimeout(() => {
        if (this.csvTableComponent) {
          this.csvTableComponent.setMaxHeight(this.csvTableHeight);
        }
      }, 0);
    }

    id = "table-inner-container";
    height = this.commonService.getHeightBelow(id);
    if (height) {
      let containerElement = document.getElementById(id);
      if (!containerElement) return;
      containerElement.style["max-height"] = "" + (height - 30) + "px";
    } else {
      this.csvTableHeight = 400;
    }
  }

  getCsvTableHeight() {
    return this.csvTableHeight;
  }

  tabChanged(tabNumber: number) {
    setTimeout(() => { this.resized(); }, 0);
  }

  getCsvData(csvData: CsvData) {
    this.upDownList = { ...csvData };
    this.selectedStore = [];
    if (this.csvTableComponent && this.csvTableComponent.checkedStoreCd.size > 0) {
      this.csvTableComponent.checkedStoreCd.clear();
      this.csvTableComponent.storeUnselectAll();
    }
    this.isDisableUpdateBtnFn();
    if (this.csvTableComponent) {
      this.csvTableComponent.init(this.upDownList);
    }
    setTimeout(() => { this.resized(); }, 0);
  }

  storeChange() {
    this.getSelectedStoreCount();
    this.isDisableUpdateBtnFn();
  }

  isDisableUpdateBtnFn() {
    if (this.selectedStore.length <= 0 ) return this.isDisableUpdateBtn = true;
    if (this.upDownList == undefined) return this.isDisableUpdateBtn = true;
    if (this.upDownList.errorCount == undefined) return this.isDisableUpdateBtn = true;
    if (this.upDownList.errorCount > 0) return this.isDisableUpdateBtn = true;

    if (this.csvTableComponent.checkedStoreCd.size == 0) return this.isDisableUpdateBtn = true;
    let selectedStore = [...this.csvTableComponent.checkedStoreCd];
    for (let index = 0; index < selectedStore.length; index++) {
      const element = selectedStore[index];
      if (!this.selectedStore.includes(element) 
          || this.csvTableComponent.checkedStoreCd.size > this.selectedStore.length) {
          return this.isDisableUpdateBtn = true;
      }
    }
    return this.isDisableUpdateBtn = false;
  }

  doConfirm() {
    const stores = this.csvTableComponent.getSelectedStore();
    const itemUpdown = this.getDataCheckfromTable();
    if (itemUpdown.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "データがありません。");
      return;
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "確認中・・・");
    this.httpBasic.checkCsvUpDown(itemUpdown, stores).subscribe(
      (response: RspCsvUpDownCheck) => {
        this.checkResultCsvUpDownCheck(response);
        if (!response.result) return;
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )

  }

  doUpdate() {
    if (this.csvTableComponent.getErrorCount() > 0) return;
    const stores = this.csvTableComponent.getSelectedStore();
    const itemUpdown = this.getDataUpdatefromTable();
    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.httpBasic.updateCsvUpDown(itemUpdown, stores).subscribe(
      (response: RspForecastUpDown) => {
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) return;
        this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "max-width": "" + colDef.width + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "max-width": "" + colDef.width + "px"
    }
  }

  doQuery() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    let subsc = this.httpBasic.initForecastUpDown(this.formStore.value).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveUpDownList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpDownList(response: RspForecastUpDown) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.upDownQueryList = [];

    if (!response.result) return;
    for (let forecastUpDown of response.result) {
      this.upDownQueryList.push(new ForecastUpDownRec(forecastUpDown, this.decimalPipe));
    }
    setTimeout(() => { this.resized(); }, 0);
  }

  getSelectedStoreCount() {

    if (!this.csvTableComponent) return this.selectedStoreCount = 0;
    return this.selectedStoreCount = this.csvTableComponent.checkedStoreCd.size;
  }

  getDataCheckfromTable(): ReqCsvUpDownCheckDto[] {
    let requestData: ReqCsvUpDownCheckDto[] = [];

    this.upDownList.lines.map((row, index) => {

      const csvUpDownCheckItem: ReqCsvUpDownCheckDto = {
        ctgCdFv: row.columnData['ctgCd'],
        ctgNameFv: row.columnData['ctgName'],
        rankFv: row.columnData['rank'].split('').filter(item => item !== " "),
        rateFv: row.columnData['rate'],
        dateEndFv: row.columnData['dateEnd'],
        dateBeginFv: row.columnData['dateBegin'],
        noiseCutFv: row.columnData['noiseCut']
      }
      requestData.push(csvUpDownCheckItem)
    })
    return requestData;
  }

  getDataUpdatefromTable(): ReqCsvUpDownUpdateDto[] {
    let requestData: ReqCsvUpDownUpdateDto[] = [];

    this.upDownList.lines.map((row, index) => {

      const csvUpDownUpdateItem: ReqCsvUpDownUpdateDto = {
        ctgCdFv: row.columnData['ctgCd'],
        ctgNameFv: row.columnData['ctgName'],
        rankFv: row.columnData['rank'],
        rateFn: row.columnData['rate'],
        dateEndFv: row.columnData['dateEnd'],
        dateBeginFv: row.columnData['dateBegin'],
        noiseCutFn: row.columnData['noiseCut'] === '有効' ? 1 : 0
      }
      requestData.push(csvUpDownUpdateItem)
    })
    return requestData;
  }

  checkResultCsvUpDownCheck(response: RspCsvUpDownCheck) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    this.upDownList.lines.map(line => {
      line.errorMsg = [],
      line.warningMsg = []
    })
    if (response.result.length > 0) {
      response.result.map((item) => {
        if (item.errorMsgs) {
        this.upDownList.lines[item.line].errorMsg = item.errorMsgs;
        this.upDownList.lines[item.line].warningMsg = item.warningMsgs;
        }
      });
    }

    this.selectedStore = this.csvTableComponent.getSelectedStore().map(store => store.storeCdFv);

    this.csvTableComponent.handleError();
    this.upDownList.errorCount = this.csvTableComponent.getErrorCount();
    this.isDisableUpdateBtnFn();
  }
 
  clearProgressState() {
    this.commonService.closeSpinner();
  }


}
