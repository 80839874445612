import { formatNumber } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ApprovalDto, ReqGetApprovalHistory, ReqGetApprovalItem, ReqGetClosingTimeList, ReqUpdateApproval, RspGetApprovalHistory, RspGetApprovalItem, RspGetClosingTimeList, RspUpdateApproval, UpdateApprovalDto } from 'src/app/webservice/order-approval';
import { ApprovalItem } from '../0_def/approval-item';

@Component({
  selector: 'app-order-approval',
  templateUrl: './order-approval.component.html',
  styleUrls: ['./order-approval.component.css']
})
export class OrderApprovalComponent implements OnInit, OnDestroy {

  public isStoreMode: boolean    = false;
  public tabSelection: number = 0;
  public storeForm: FormControl = new FormControl();
  public appRequiredForm: FormControl = new FormControl(true);
  public appNotRequiredForm: FormControl = new FormControl(false);
  public closingTimeForm: FormControl = new FormControl("");
  public itemCodeForm: FormControl = new FormControl("");
  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
/*
  public approvalTypeList: {id: number, typeName: string, form: FormControl}[] = [
    {id: ApprovalItem.APPROVAL_ALWAYS, typeName: "常に必要", form: new FormControl(true)},
    {id: ApprovalItem.APPROVAL_COST, typeName: "発注金額に応じて必要", form: new FormControl(true)},
    {id: ApprovalItem.APPROVAL_ORDER_NUM, typeName: "発注数量に応じて必要", form: new FormControl(true)},
    {id: ApprovalItem.APPROVAL_NOT_REQUIRED, typeName: "承認不要", form: new FormControl(false)}
  ];
*/
  public approvalStatList: {id: number, statName: string, form: FormControl}[] = [
    {id: ApprovalItem.STAT_APPROVED, statName: "承認", form: new FormControl(true)},
    {id: ApprovalItem.STAT_NOT_APPROVED, statName: "否認", form: new FormControl(true)},
    {id: ApprovalItem.STAT_NOT_PROC, statName: "未処理", form: new FormControl(true)},
  ];
  public closingTimeList: {order: string, approval: string, orderName: string, approvalName: string}[] = [];
  /*
  [    {order: "1300", approval: "", orderName: "13:00", approvalName: ""},
    {order: "1500", approval: "", orderName: "15:00", approvalName: ""},
  ]; */

  public itemList: ApprovalItem[] = [];
  public itemShowList: ApprovalItem[] = [];
  public recordCount: number = 0;
  public selectedItem: ApprovalItem;
  public isDirty: boolean = false;

  public histDateBegin: Date;
  public histDateEnd: Date;
  public histItemNameForm: FormControl = new FormControl("");
  public histItemStandardForm: FormControl = new FormControl("");
  public histList: ApprovalDto[] = [];
  public histShowList: ApprovalDto[] = [];
  public histRecordCount: number = 0;

  public columnIdsH1: string[] = [
    "itemCdFv", "itemNameFv", "standardFv", "suppNameFv", "orderDateFd", 
    "orderRecommendNumFn", "orderNumFn", "orderBaraNumFn", "costPriceFn",
    "appovalForm", "commentForm",
    "approvalTypeFv", "orderStopFv",
    "prevStatus"
  ];
  public columnIdsH2: string[] = [
    "prevOrderNumFn",
    "prevOrderBaraNumFn",
    "prevCostPriceFn",
    "prevApprovalFv",
    "prevCommentFv"
  ];

  public columnIdsStore: string[] = [
    "itemCdFv", "itemNameFv", "standardFv", "suppNameFv", "orderDateFd", 
    "orderRecommendNumFn", "orderNumFn", "orderBaraNumFn", "costPriceFn",
    "approval", "approvalComment", "approvalDate",
    "approvalTypeFv", "orderStopFv"
  ];

  public columnIdsRow: string[] = [
    "itemCdFv", "itemNameFv", "standardFv", "suppNameFv", "orderDateFd", 
    "orderRecommendNumFn", "orderNumFn", "orderBaraNumFn", "costPriceFn",
    "appovalForm", "commentForm",
    "approvalTypeFv", "orderStopFv",
    "prevOrderNumFn",
    "prevOrderBaraNumFn",
    "prevCostPriceFn",
    "prevApprovalFv",
    "prevCommentFv"
  ];

  public columnIdsHist: string[] = [
    "approvalType", "orderDate", "orderRecommendNum", "orderNum", "orderBaraNum",
    "costPrice", "approval", "approvalDate", "approvalComment"
  ];

  public columnDefs: TableColumnDef[] = [
    {columnId: "itemCdFv", header: "商品コード", width: 90, align: "center", rowspan:2},
    {columnId: "itemNameFv", header: "商品名", width: 200, align: "left", rowTooltip: true, rowspan:2},
    {columnId: "standardFv", header: "規格", width: 60, align: "left", rowTooltip: true, rowspan:2},
    {columnId: "orderStopFv", header: "発注種別", width: 90, align: "left", rowspan:2},
    {columnId: "approvalTypeFv", header: "承認種別", width: 80, align: "left", rowspan:2, rowTooltip: true},
    {columnId: "orderDateFd", header: "発注日", width: 75, align: "center", rowspan:2},
    {columnId: "suppNameFv", header: "発注先", width: 100, align: "left", rowspan:2, rowTooltip: true},
    {columnId: "orderRecommendNumFn", header: "推奨数", width: 45, align: "right", numberPipe: "", tooltip: "発注推奨数(ロット)", rowspan:2},
    {columnId: "orderNumFn", header: "発注数", width: 45, align: "right", numberPipe: "", tooltip: "ロット", rowspan:2},
    {columnId: "orderBaraNumFn", header: "バラ数", width: 45, align: "right", numberPipe: "", rowspan:2},
    {columnId: "costPriceFn", header: "発注金額", width: 55, align: "right", numberPipe: "", rowspan:2},
    {columnId: "prevStatus", header: "前回承認状況", width: 385, align: "right", numberPipe: "", tooltip: "ロット", rowspan:1, colspan: 5},
    {columnId: "prevOrderNumFn", header: "発注数", width: 45, align: "right", numberPipe: "", tooltip: "ロット", rowspan:1, isChildHeader:true},
    {columnId: "prevOrderBaraNumFn", header: "バラ数", width: 45, align: "right", numberPipe: "", rowspan:1, isChildHeader:true},
    {columnId: "prevCostPriceFn", header: "発注金額", width: 55, align: "right", numberPipe: "", rowspan:1, isChildHeader:true},
    {columnId: "prevApprovalFv", header: "承認", width: 85, align: "center", rowspan:1, isChildHeader:true},
    {columnId: "prevCommentFv", header: "承認コメント", width: 135, align: "left", rowspan:1, isChildHeader:true},
    // For Store Mode
    {columnId: "approval", header: "承認", width: 85, align: "center"},
    {columnId: "approvalDate", header: "承認日時", width: 120, align: "center"},
    {columnId: "approvalComment", header: "承認コメント", width: 200, align: "left"}
  ];

  public columnDefsHist: TableColumnDef[] = [
    {columnId: "approvalType", header: "承認種別", width: 80, align: "left", rowTooltip: true},
    {columnId: "orderDate", header: "発注日", width: 75, align: "center"},
    {columnId: "orderRecommendNum", header: "推奨数", width: 55, align: "right", numberPipe: "", tooltip: "発注推奨数(ロット)"},
    {columnId: "orderNum", header: "発注数", width: 55, align: "right", numberPipe: "", tooltip: "ロット"},
    {columnId: "orderBaraNum", header: "バラ数", width: 55, align: "right", numberPipe: ""},
    {columnId: "costPrice", header: "発注金額", width: 55, align: "right", numberPipe: ""},
    {columnId: "approval", header: "承認", width: 85, align: "center"},
    {columnId: "approvalDate", header: "承認日時", width: 120, align: "center"},
    {columnId: "approvalComment", header: "承認コメント", width: 200, align: "left"}
  ];

  private subsc: Subscription[] = [];
  private subscStore: Subscription;

  @ViewChild(CtgSelectComponent, { static: false }) ctgSelectComponent: CtgSelectComponent;
  @ViewChild(MatPaginator, {static:false}) matPagenator: MatPaginator;
  @ViewChild("histPagenator", {static:false, read: MatPaginator}) histMatPagenator: MatPaginator;
  @ViewChild("histTable", { static: false, read: MatTable}) matTable: MatTable<any>;
  @ViewChild(NumberKeypadComponent, { static: false }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild(DateRangeComponent, { static: false }) dateRangeComponent: DateRangeComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.storeForm.setValue(this.commonService.loginUser.storeCd);
    this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    this.subsc.push(this.storeForm.valueChanges.subscribe((value) => {this.storeChanged(value);}));
    this.subsc.push(this.itemCodeForm.valueChanges.subscribe((value) => {this.histItemChanged(value);}));

    let len = this.route.snapshot.url.length;
    if (len > 0) {
      let url: string = this.route.snapshot.url[len - 1].path;
      if (url == "orderApprovalStore") this.isStoreMode = true;
    }

    this.getClosingTimeList();

    this.histDateBegin = new Date();
    this.histDateBegin.setDate(this.histDateBegin.getDate() - 28);
    this.histDateEnd = new Date();
    this.histDateEnd.setDate(this.histDateEnd.getDate() + 6);

    if (this.isStoreMode) {
      this.columnDefs.forEach((def) => {
        if (def.rowspan) def.rowspan = 1;
      });
    }
  }

  ngOnDestroy(): void {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    if (this.tabSelection == 0) {
      let id = "item-list-box";
      let remHeight = this.commonService.getHeightBelow(id);
      let paginatorHeight = 56;
      let margin = this.isStoreMode ? 5 : 10 + 5;
      let btnBox = this.isStoreMode ? 0 : 24;
      let height = remHeight - paginatorHeight - margin - btnBox;
      if (height < 200) height = 200;
  
      let elem = document.getElementById(id);
      if (elem) elem.style.maxHeight = "" + height + "px";
    } else {
      let id = "hist-list-box";
      let remHeight = this.commonService.getHeightBelow(id);
      let paginatorHeight = 56;
      let margin = 5 + 5;
      let btnBox = 0;
      let height = remHeight - paginatorHeight - margin - btnBox;
      if (height < 200) height = 200;
  
      let elem = document.getElementById(id);
      if (elem) elem.style.maxHeight = "" + height + "px";
    }
  }
  
  getClosingTimeList() {
    let request: ReqGetClosingTimeList = {
      access: this.commonService.loginUser,
      storeCd: this.storeForm.value
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetClosingTimeList", request).subscribe(
      (response: RspGetClosingTimeList) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveClosingTimeList(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveClosingTimeList(response: RspGetClosingTimeList) {
    if (this.httpBasic.handleAppError(response)) return;

    let list: string[] = [];
    response.rows.forEach((dto) => {
      list.push(dto);
    });
    if (!list.find((item) => item === response.defautClosingTime)) {
      list.push(response.defautClosingTime);
    }
    list = list.sort();
    this.closingTimeList = [];
    list.forEach((closingTime) => {
      let approvalTime: string;
      if (this.commonService.config.orderApproval) {
        approvalTime = "0000" + (parseInt(closingTime) + this.commonService.config.orderApproval?.approvalTimeMargin);
        approvalTime = approvalTime.slice(-4);
      } else {
        approvalTime = closingTime;
      }
      this.closingTimeList.push({
        order: closingTime,
        approval: approvalTime,
        orderName: closingTime.substring(0, 2) + ":" + closingTime.substring(2, 4),
        approvalName: approvalTime.substring(0, 2) + ":" + approvalTime.substring(2, 4)
      });
    });
    let now = new Date();
    let nowTimeStr = ("00" + now.getHours()).slice(-2) + ("00" + now.getMinutes()).slice(-2);
    let curr = "";
    if (this.isStoreMode) {
      for (let i = this.closingTimeList.length; i--; i >= 0) {
        if (nowTimeStr >= this.closingTimeList[i].order) break;
        curr = this.closingTimeList[i].order;
      }
    } else {
      for (let i = this.closingTimeList.length; i--; i >= 0) {
        if (nowTimeStr >= this.closingTimeList[i].approval) break;
        curr = this.closingTimeList[i].order;
      }
    }
    this.closingTimeForm.setValue(curr);
  }

  tabChanged(event: number) {
    this.setTableHeight();
  }

  storeChanged(value) {
    if (this.storeForm.disabled) return;
    this.ctgSelectComponent.setStoreCd(value);
    this.getClosingTimeList();
  }

  approvalItemChanged() {
    this.isDirty = true;
  }

  typeSelect(mode: boolean) {
    /*
    this.approvalTypeList.forEach((type) => {
      type.form.setValue(mode);
    }); */
    this.appRequiredForm.setValue(mode);
    this.appNotRequiredForm.setValue(mode);
  }

  statSelect(mode: boolean) {
    this.approvalStatList.forEach((type) => {
      type.form.setValue(mode);
    });
  }

  disableGetApprovalItem(): boolean {
    let resultType: boolean = true;
    let resultStat: boolean = true;

    /*
    for (let i = 0; i < this.approvalTypeList.length; i++) {
      if (this.approvalTypeList[i].form.value) {
        resultType = false;
      }
    }
    */
    if (this.appRequiredForm.value || this.appNotRequiredForm.value) resultType = false;
    if (resultType) return true;
    for (let i = 0; i < this.approvalStatList.length; i++) {
      if (this.approvalStatList[i].form.value) {
        resultStat = false;
      }
    }
    return resultStat;
  }

  approvalTypesCond(): number[] {
    let cond: number[] = [];
    /*
    this.approvalTypeList.forEach((type) => {
      if (type.form.value) cond.push(type.id);
    });
    */
    /*
    if (this.appRequiredForm.value) {
      cond.push(ApprovalItem.APPROVAL_ALWAYS);
      cond.push(ApprovalItem.APPROVAL_COST);
      cond.push(ApprovalItem.APPROVAL_ORDER_NUM);
    }
    if (this.appNotRequiredForm.value) {
      cond.push(ApprovalItem.APPROVAL_NOT_REQUIRED);
    }
    */
    cond.push(ApprovalItem.APPROVAL_NOT_REQUIRED);
    cond.push(ApprovalItem.APPROVAL_ALWAYS);
    cond.push(ApprovalItem.APPROVAL_COST);
    cond.push(ApprovalItem.APPROVAL_ORDER_NUM);

    return cond;
  }

  approvalStatsCond(): number[] {
    let cond: number[] = [];
    this.approvalStatList.forEach((stat) => {
      if (stat.form.value) {
        cond.push(stat.id);
        if (stat.id == ApprovalItem.STAT_NOT_PROC) {
          cond.push(-ApprovalItem.STAT_APPROVED);
          cond.push(-ApprovalItem.STAT_NOT_APPROVED);
        }
      }
    });
    return cond;
  }

  getApprovalItem() {
    let request: ReqGetApprovalItem = {
      access: this.commonService.loginUser,
      storeCd:            this.storeForm.value,
      ...this.ctgSelectCondition.getCurrentSelection(),
      approvalTypes:      this.approvalTypesCond(),
      closingTime:        this.closingTimeForm.value,
      approvalStats:      this.approvalStatsCond()
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetApprovalItem", request).subscribe(
      (response: RspGetApprovalItem) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveApprovalItem(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveApprovalItem(response: RspGetApprovalItem) {
    if (this.httpBasic.handleAppError(response)) return;

    this.isDirty = false;
    this.itemList = [];
    this.selectedItem = undefined;
    for (let i = 0; i < response.rows.length; i++) {
      let dto = response.rows[i];
      let rec = new ApprovalItem(this.commonService);
      rec.initByDto(dto);
      rec.setListner(() => {this.approvalItemChanged();});
      rec.prepareEdit();
      if (this.appRequiredForm.value == false && rec.appRequire()) continue;
      if (this.appNotRequiredForm.value == false && !rec.appRequire()) continue;
      this.itemList.push(rec);
    }
    /*
    response.rows.forEach((dto) => {
      let rec = new ApprovalItem(this.commonService);
      rec.initByDto(dto);
      rec.prepareEdit();
      if (this.appNotRequiredForm.value == true || rec.appRequire()) this.itemList.push(rec);
    });
    */
    this.recordCount = this.itemList.length;
    if (this.matPagenator) {
      this.matPagenator.pageIndex = 0;
    }
    this.pageChanged(null);
    this.setTableHeight();
  }

  pageChanged(event: PageEvent) {
    let start: number;
    let end: number;
    if (this.matPagenator) {
      start = this.matPagenator.pageIndex * this.matPagenator.pageSize;
      end = start + this.matPagenator.pageSize;
    } else {
      start = 0;
      end = this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex];
    }
    if (end > this.recordCount) end = this.recordCount;

    this.itemShowList = this.itemList.slice(start, end);
  }

  clickRow(item: ApprovalItem) {
    this.selectedItem = item;
  }

  showHistory() {
    this.setHistItemCode(this.selectedItem.itemCdFv);
    this.tabSelection = 1;
    this.getApprovalHistory();
  }

  saveEdit() {
    let updateList: UpdateApprovalDto[] = [];
    this.itemList.forEach((rec) => {
      if (rec.isDirty()) {
        rec.saveEdit(); 
        let dto: UpdateApprovalDto = {
          storeCd:            rec.storeCdFv,
          itemCd:             rec.itemCdFv,
          orderDate:          rec.orderDateFd,
          orderRecommend:     rec.orderNumFn,
          orderNum:           rec.orderNumVersionFn > 0 ? rec.orderNumModFn : rec.orderNumFn,
          orderBaraNum:       rec.orderBaraNumFn,
          costPrice:          rec.orderCostPriceFn,
          approvalType:       rec.approvalType,
          approvalBoundary:   rec.approvalBoundary,
          approval:           rec.appApproval,
          approvalComment:    rec.appApprovalComment,
          approvalDateTime:   rec.appApprovalDate + " " + rec.appApprovalTime
        };
        updateList.push(dto);
      }
    });
    this.setDirty(false);

    let request: ReqUpdateApproval = {
      access: this.commonService.loginUser,
      updateList: updateList
    };
    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("UpdateApproval", request).subscribe(
      (response: RspUpdateApproval) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveSave(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error)
      }
    );
  }

  receiveSave(response: RspUpdateApproval) {
    if (this.httpBasic.handleAppError(response)) return;

  }

  clearEdit() {
    this.itemList.forEach((rec) => rec.clearEdit());
    this.isDirty = false;
  }

  setHistItemCode(code: string) {
    this.itemCodeForm.setValue(code);
    this.histItemNameForm.setValue("");
    this.histItemStandardForm.setValue("");
  }

  histItemChanged(value) {
    // this.histItemNameForm.setValue("");
    // this.histItemStandardForm.setValue("");
  }

  getApprovalHistory() {
    this.histItemNameForm.setValue("");
    this.histItemStandardForm.setValue("");
    let drange = this.dateRangeComponent.getDateRange();

    let request: ReqGetApprovalHistory= {
      access: this.commonService.loginUser,
      storeCd:        this.storeForm.value,
      itemCd:         this.itemCodeForm.value,
      dateBegin:      this.commonService.formatDate(drange.dateBegin),
      dateEnd:        this.commonService.formatDate(drange.dateEnd)
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetApprovalHistory", request).subscribe(
      (response: RspGetApprovalHistory) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveApprovalHistory(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveApprovalHistory(response: RspGetApprovalHistory) {
    if (this.httpBasic.handleAppError(response)) return;

    this.histItemNameForm.setValue(response.itemName);
    this.histItemStandardForm.setValue(response.itemStandard);

    this.histList = [];
    response.rows.forEach((dto) => {
      this.histList.push(dto);
    });

    this.histRecordCount = this.histList.length;
    if (this.matPagenator) {
      this.matPagenator.pageIndex = 0;
    }

    this.histPageChanged(null);
    this.setTableHeight();
  }

  histPageChanged(event: PageEvent) {
    let start: number;
    let end: number;
    if (this.histMatPagenator) {
      start = this.histMatPagenator.pageIndex * this.histMatPagenator.pageSize;
      end = start + this.histMatPagenator.pageSize;
    } else {
      start = 0;
      end = this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex];
    }
    if (end > this.histRecordCount) end = this.histRecordCount;

    this.histShowList = this.histList.slice(start, end);
  }

  getApprovalTypeName(val: number, boundary: number) {
    switch(val) {
      case ApprovalItem.APPROVAL_NOT_REQUIRED: return "承認不要";
      case ApprovalItem.APPROVAL_ALWAYS: return "常に必要";
      case ApprovalItem.APPROVAL_ORDER_NUM: return "数量>=" + formatNumber(boundary, this.commonService.locale);
      case ApprovalItem.APPROVAL_COST: return "金額>=" + formatNumber(boundary, this.commonService.locale);
    }
    return "";
  }

  getApprovalName(val: number) {
    if (val < 0) val = -val;
    switch(val) {
      case ApprovalItem.STAT_APPROVED: return "承認";
      case ApprovalItem.STAT_NOT_APPROVED: return "否認";
      case ApprovalItem.STAT_NOT_PROC: return "未処理";
    }
    return "";
  }

  getHistValue(item: ApprovalDto, key: string) {
    switch(key) {
      case "approvalType":
        return this.getApprovalTypeName(item.approvalType, item.approvalBoundary);
      case "approval":
        if (item.approvalType == ApprovalItem.APPROVAL_NOT_REQUIRED) return this.getApprovalTypeName(ApprovalItem.APPROVAL_NOT_REQUIRED, 0);
        return this.getApprovalName(item.approval);
      case "approvalDate":
        if (item.approvalDate === "") return "";
        return item.approvalDate + " " + item.approvalTime;
      default:
        return item[key];
    }
  }

  isKeyboardLocked() {
    if (this.numberKeypadComponent?.isKeyboardLocked()) return true;
    return false;
  }

  set10KeyPadTarget(form: FormControl, maxCol?: number) {
    if (maxCol) this.numberKeypadComponent.setMaxCol(maxCol);
    // this.numberKeypadComponent.inputField = form;
    this.numberKeypadComponent.setTargetForm(form);
  }

  is10KeyPadTarget(form: FormControl) {
    if (!this.numberKeypadComponent) return false;
    if (form === this.numberKeypadComponent.inputField) return true;
    return false;
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          this.setHistItemCode(data);
        }
      }
    )
  }

  setDirty(mode: boolean) {
    this.isDirty = mode;
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
