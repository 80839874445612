import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { ItemRec } from 'src/app/common/item-rec';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReqParamCtgSearchDto } from 'src/app/request/req-param-ctg-search';
import { FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription } from 'rxjs';
import { RspOrderEdit, RspOrderEditDto } from 'src/app/response/rsp-order-edit';
import { CtgParamComponent } from 'src/app/partsCommon/ctg-param/ctg-param.component';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';

@Component({
  selector: 'app-order-edit-dialog',
  templateUrl: './order-edit-dialog.component.html',
  styleUrls: ['./order-edit-dialog.component.css']
})
export class OrderEditDialogComponent implements OnInit, OnDestroy {

  public ctgCondition: ReqParamCtgSearchDto;
  public orderNumForm : FormControl;
  public errorMessage : string;

  subscriptionUpdate : Subscription;
  @ViewChild(CtgParamComponent, {static: true}) ctgParamComponent: CtgParamComponent;
  @ViewChild(NumberKeypadComponent, {static: true}) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public dialogRef: MatDialogRef<OrderEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public itemRec: ItemRec,
    public httpBasic: HttpBasicService,
    public commonService: CommonService) { }

  ngOnInit() {
    var level: number;
    if (this.itemRec.ctgCd0Fv && this.itemRec.ctgCd0Fv !== "") level = 0;
    if (this.itemRec.ctgCd1Fv && this.itemRec.ctgCd1Fv !== "") level = 1;
    if (this.itemRec.ctgCd2Fv && this.itemRec.ctgCd2Fv !== "") level = 2;
    if (this.itemRec.ctgCd3Fv && this.itemRec.ctgCd3Fv !== "") level = 3;

    this.ctgCondition = {
      storeCdFv: this.itemRec.storeCdFv,
      ctgLevelFn: level,
      ctgCd0Fv: this.itemRec.ctgCd0Fv,
      ctgCd1Fv: this.itemRec.ctgCd1Fv,
      ctgCd2Fv: this.itemRec.ctgCd2Fv,
      ctgCd3Fv: this.itemRec.ctgCd3Fv,
      rankFv: ""
    }

    this.orderNumForm = new FormControl(this.itemRec.orderNumCurrentFn,
      [Validators.required, (data) => this.valueCheck(data as FormControl)]);

    if (!this.commonService.getOrderStopFlagCanOrder(this.itemRec.orderStopFlagFn)) {
      if (this.commonService.getOrderStopFlagCanOrderEx(this.itemRec.orderStopFlagFn)) {
        this.errorMessage = "この商品は" + this.commonService.getOrderStopFlagName(this.itemRec.orderStopFlagFn) + "商品です。"
      } else {
        this.errorMessage = "この商品は発注が許可されていないため、発注数の変更はできません。"
        this.orderNumForm.disable();
      }
    } else {
      var orderDate: Date = new Date(
        +this.itemRec.orderDate.substring(0, 4),
        +this.itemRec.orderDate.substring(5, 7) - 1,
        +this.itemRec.orderDate.substring(8, 10)
      );
      var dayOfWeek = orderDate.getDay();
      if (this.itemRec.orderableDateFv.substring(dayOfWeek, dayOfWeek+1) === '0') {
        /* 発注不可曜日 */
        this.errorMessage = "この商品の発注日は「"
        for (var i :number = 0; i < 7; i++) {
          if (this.itemRec.orderableDateFv.substring(i, i+1) !== '0') {
            this.errorMessage += ["日", "月", "火", "水", "木", "金", "土"][i];
          }
        }
        this.errorMessage += "」のため、発注数の変更はできません。"
        this.orderNumForm.disable();
      }
    }
    // this.ctgParamComponent.doQuery(this.ctgCondition);
    if (this.numberKeypadComponent) this.numberKeypadComponent.clearAtFirstInput();
  }

  ngOnDestroy() {
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
    }
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  valueCheck(control: FormControl) {
    /* Note: control.value is string, so use == operator */ 
    if (control.value == this.itemRec.orderNumCurrentFn) {
      return {"NoCahnge": "値が変更されていません。"};
    } else {
      return null;
    }
  }

  onUpdate() {
    this.subscriptionUpdate = this.httpBasic.updateOrderNum(this.itemRec, this.orderNumForm.value).subscribe(
      data => this.updateResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );

    this.commonService.openSpinner("発注訂正", "更新中・・・");
  }

  clearProgressState () {
    this.commonService.closeSpinner();
    this.subscriptionUpdate.unsubscribe();
    this.subscriptionUpdate = undefined;
  }

  updateResult(rspOrderEdit: RspOrderEdit) {
    this.clearProgressState();

    if (rspOrderEdit.fatalError && rspOrderEdit.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("発注訂正", rspOrderEdit.fatalError[0].errMsg);
      return;
    }
    if (rspOrderEdit.normalError && rspOrderEdit.normalError.length > 0) {
      this.errorMessage = rspOrderEdit.normalError[0].errMsg;
      return;
    }

    var rspOrderEditDto: RspOrderEditDto;
    rspOrderEditDto = rspOrderEdit.result;
    this.itemRec.setOrderNum(rspOrderEditDto.orderNumFn,
      rspOrderEditDto.versionFn);

    this.dialogRef.close();
  }

}
