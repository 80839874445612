import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeComponent implements OnInit, OnDestroy, OnChanges {

  public formDateBegin: FormControl = new FormControl();
  public formDateEnd: FormControl = new FormControl();
  // private subscBegin: Subscription;
  // private subscEnd: Subscription;
  private currentRange: {
    dateBegin: Date;
    dateEnd: Date;
  } = {
    dateBegin: undefined,
    dateEnd: undefined
  };
  public maxDate: Date;
  public minDate: Date;
  @Input() isDisable: boolean = false;

  // @Input("dateBegin") dateBegin: Date = new Date();
  @Input() set dateBegin(value: Date) {
    if (!value) {
      this.currentRange.dateBegin = new Date();
    } else {
      this.currentRange.dateBegin = value;
    }
    this.formDateBegin.setValue(this.currentRange.dateBegin, {emitEvent: false});
  };
  @Input() set dateEnd(value: Date) {
    if (!value) {
      this.currentRange.dateEnd = new Date();
    } else {
      this.currentRange.dateEnd = value;
    }
    this.formDateEnd.setValue(this.currentRange.dateEnd, {emitEvent: false});
    // When true or not supplied (the default), both the statusChanges and valueChanges observables emit events with the latest status
    // and value when the control value is updated. When false, no events are emitted.
  };
  // @Input("dateEnd") dateEnd: Date = new Date();
  @Input("minDate") minDateInput: Date;
  @Input("maxDate") maxDateInput: Date;
  @Input("prevYear") isPrevYear: boolean = false;
  @Input("prevMonth") isPrevMonth: boolean = false;
  @Input("prevWeek") isPrevWeek: boolean = false;
  @Input("prevDay") isPrevDay: boolean = false;
  @Input("nextDay") isNextDay: boolean = false;
  @Input("nextWeek") isNextWeek: boolean = false;
  @Input("nextMonth") isNextMonth: boolean = false;
  @Input("nextYear") isNextYear: boolean = false;

  @Output("dateChanged") dateChanged: EventEmitter<any> = new EventEmitter();

  constructor(public commonService: CommonService,
    private dateAdapter : DateAdapter<any>,
  ) {
  }

  ngOnInit(): void {
    // // this.watchValueChange(false);
    // this.currentRange.dateBegin = this.dateBegin;
    // this.currentRange.dateEnd = this.dateEnd;
    // this.minDate = this.minDateInput;
    // this.maxDate = this.maxDateInput;
    // this.applyCurrent();
    // // this.watchValueChange(true);

    /*
    this.subscBegin = this.formDateBegin.valueChanges.subscribe(valueChanges => {
      this.beginChanged(valueChanges);
      this.dateChanged.emit(this.currentRange);
    });
    this.subscEnd = this.formDateEnd.valueChanges.subscribe(valueChanges => {
      this.endChanged(valueChanges);
      this.dateChanged.emit(this.currentRange);
    })
    */
  }

  ngOnDestroy(): void {
    /*
    if (this.subscBegin) this.subscBegin.unsubscribe();
    if (this.subscEnd) this.subscEnd.unsubscribe();
    */
  }

  ngOnChanges() {
    // this.watchValueChange(false);
    // this.currentRange.dateBegin = this.dateBegin;
    // this.currentRange.dateEnd = this.dateEnd;
    this.minDate = this.minDateInput;
    this.maxDate = this.maxDateInput;

    // this.applyCurrent();
    // this.watchValueChange(true);

  }

  getDateRange() {
    return this.currentRange;
  }

  enable() {
    this.isDisable = false;
  }

  disable() {
    this.isDisable = true;
  }

  /*
  watchValueChangeBegin(onOff: boolean) {
    if (onOff) {
      this.subscBegin = this.formDateBegin.valueChanges.subscribe((value) => {this.beginChanged(value);})
    } else {
      if (this.subscBegin) this.subscBegin.unsubscribe();
      this.subscBegin = undefined;
    }
  }

  watchValueChangeEnd(onOff: boolean) {
    if (onOff) {
      this.subscEnd = this.formDateEnd.valueChanges.subscribe((value) => {this.endChanged(value);})
    } else {
      if (this.subscEnd) this.subscEnd.unsubscribe();
      this.subscEnd = undefined;
    }
  }

  watchValueChange(onOff: boolean) {
    this.watchValueChangeBegin(onOff);
    this.watchValueChangeEnd(onOff);
  }
  */

  applyCurrent() {
    this.formDateBegin.setValue(this.currentRange.dateBegin);
    this.formDateEnd.setValue(this.currentRange.dateEnd);
  }

  updateBegin(newDate: Date) {
    // this.watchValueChangeBegin(false);
    this.currentRange.dateBegin = newDate;
    this.formDateBegin.setValue(newDate);
    // this.watchValueChangeBegin(true);
  }

  updateEnd(newDate: Date) {
    // this.watchValueChangeEnd(false);
    this.currentRange.dateEnd = newDate;
    this.formDateEnd.setValue(newDate);
    // this.watchValueChangeEnd(true);
  }

  emitBeginChanged(event) {
    this.beginChanged(event.value);
    this.dateChanged.emit(this.currentRange);
  }

  beginChanged(newDate: Date) {
    // this.dateChanged.emit(this.currentRange);
    this.currentRange.dateBegin = this.formDateBegin.value;
    if ((this.formDateBegin.value as Date).getTime() <= (this.formDateEnd.value as Date).getTime()) return;
    let d = this.commonService.copyDate(this.formDateBegin.value);
    this.updateEnd(d);
  }

  emitEndChanged(event) {
    this.endChanged(event.value);
    this.dateChanged.emit(this.currentRange);
  }

  endChanged(newDate: Date) {
    // this.dateChanged.emit(this.currentRange);
    this.currentRange.dateEnd = this.formDateEnd.value;
    if ((this.formDateBegin.value as Date).getTime() <= (this.formDateEnd.value as Date).getTime()) return;
    let d = this.commonService.copyDate(this.formDateEnd.value);
    this.updateBegin(d);
  }

  dateCan(checkDate: Date, shift: number) {
    if (this.minDate == undefined && this.maxDate == undefined) return true;

    let d: Date = this.commonService.copyDate(checkDate);
    d.setDate(d.getDate() + shift);

    if (this.minDate && d.getTime() < this.minDate.getTime()) return false;
    if (this.maxDate && this.maxDate.getTime() < d.getTime()) return false;

    return true;
  }

  dateCanPrevYear() : boolean {
    return this.dateCan(this.formDateBegin.value, -364);
  }
  dateCanPrevMonth() : boolean {
    return this.dateCan(this.formDateBegin.value, -28);
  }
  dateCanPrevWeek() : boolean {
    return this.dateCan(this.formDateBegin.value, -7);
  }
  dateCanPrevDay() : boolean {
    return this.dateCan(this.formDateBegin.value, -1);
  }
  dateCanNextYear() : boolean {
    return this.dateCan(this.formDateBegin.value, 364);
  }
  dateCanNextMonth() : boolean {
    return this.dateCan(this.formDateBegin.value, 28);
  }
  dateCanNextWeek() : boolean {
    return this.dateCan(this.formDateBegin.value, 7);
  }
  dateCanNextDay() : boolean {
    return this.dateCan(this.formDateBegin.value, 1);
  }

  dateShiftBegin(dates : number) {
    let b = this.formDateBegin.value as Date;
    b.setDate(b.getDate() + dates);
    this.updateBegin(b);
  }

  dateShiftEnd(dates : number) {
    let e = this.formDateEnd.value as Date;
    e.setDate(e.getDate() + dates);
    this.updateEnd(e);
  }

  dateShift(dates : number) {
    if (dates > 0) {
      this.dateShiftEnd(dates);
      this.dateShiftBegin(dates);
    } else {
      this.dateShiftBegin(dates);
      this.dateShiftEnd(dates);
    }
    this.dateChanged.emit(this.currentRange);
  }

  prevYear() {
    this.dateShift(-364);
  }
  prevMonth() {
    this.dateShift(-28);
  }
  prevWeek() {
    this.dateShift(-7);
  }
  prevDay() {
    this.dateShift(-1);
  }
  nextYear() {
    this.dateShift(364);
  }
  nextMonth() {
    this.dateShift(28);
  }
  nextWeek() {
    this.dateShift(7);
  }
  nextDay() {
    this.dateShift(1);
  }

}
