import { Component, OnInit, Input, AfterContentChecked, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CtgParamRec } from 'src/app/common/ctg-param-rec';
import { ItemRec } from 'src/app/common/item-rec';
import { ItemRenew } from 'src/app/common/item-renew';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { ReqParamCtgSearchDto } from 'src/app/request/req-param-ctg-search';
import { RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { RspParamCtgSearch } from 'src/app/response/rsp-param-ctg-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CaseItemDto, ReqGetCaseItem, RspGetCaseItem } from 'src/app/webservice/caseitem';

@Component({
  selector: 'app-item-summary',
  templateUrl: './item-summary.component.html',
  styleUrls: ['./item-summary.component.css']
})
export class ItemSummaryComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked {

  public orderNumCurrent: number;
  public isOrderEdited: boolean;
  public stockNumCurrent: number;
  public isStockEdited: boolean;
  public minZaiCurrent: string;
  public isMinZaiEdited: boolean;
  public maxZaiCurrent: string;
  public isMaxZaiEdited: boolean;
  public minStockDaysCurrent: string;
  public isMinStockDaysEdited: boolean;
  public maxStockDaysCurrent: string;
  public isMaxStockDaysEdited: boolean;
  public orderStopFlagCurrent: string;
  public isOrderStopFlagEdited: boolean;
  public isMinZaiCtgEdited: boolean;
  public isMaxZaiCtgEdited: boolean;
  public isMinStockDaysCtgEdited: boolean;
  public isOrderStopFlagCtgEdited: boolean;
  public autoOrderLotModCurrent: string;
  public ctgNamePath: string;
  public ctgParamRec: CtgParamRec;

  private currentItemRec: ItemRec;
  private spinnerDialogRec: MatDialogRef<any>;
  private subscriptionQuery: Subscription;
  public autoOrderLot: boolean = false;

  @Input() public itemRec: ItemRec;
  @Input('showOrder') public showOrder: boolean = true;
  @Input('showStock') public showStock: boolean = true;
  @Input('showParam') public showParam: boolean = true;
  @Input('showCtgParam') public showCtgParam: boolean = true;
  @Input('showOrderStop') public showOrderStop: boolean = true;
  @Output("screenChanged") screenChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService) {

    if (this.commonService.literal["salePre1"] == undefined) this.commonService.literal["salePre1"] = "１週前";
    if (this.commonService.literal["salePre2"] == undefined) this.commonService.literal["salePre2"] = "２週前";
    if (this.commonService.literal["salePre3"] == undefined) this.commonService.literal["salePre3"] = "３週前";
    if (this.commonService.literal["salePre4"] == undefined) this.commonService.literal["salePre4"] = "４週前";

    if (this.commonService.literal["orderPre1"] == undefined) this.commonService.literal["orderPre1"] = "１回前";
    if (this.commonService.literal["orderPre2"] == undefined) this.commonService.literal["orderPre2"] = "２回前";
    if (this.commonService.literal["orderPre3"] == undefined) this.commonService.literal["orderPre3"] = "３回前";
    if (this.commonService.literal["orderPre4"] == undefined) this.commonService.literal["orderPre4"] = "４回前";
  }

  ngOnInit() {
    if (this.commonService.config.useItemAutoOrderLot) {
      this.autoOrderLot = true;
    }
  }

  ngOnChanges() {
    if (this.itemRec) {
      this.orderNumCurrent = this.itemRec.orderNumCurrentFn;
      this.isOrderEdited = this.itemRec.orderNumCurrentFnUpdated;
      this.stockNumCurrent = this.itemRec.stockNumCurrentFn;
      this.isStockEdited = this.itemRec.stockNumCurrentFnUpdated;
      this.minZaiCurrent = this.itemRec.minZaiCurrentFv;
      this.isMinZaiEdited = this.itemRec.minZaiCurrentFvUpdated;
      this.maxZaiCurrent = this.itemRec.maxZaiCurrentFv;
      this.isMaxZaiEdited = this.itemRec.maxZaiCurrentFvUpdated;
      this.minStockDaysCurrent = this.itemRec.minStockDaysCurrentFv;
      this.isMinStockDaysEdited = this.itemRec.minStockDaysCurrentFvUpdated;
      this.maxStockDaysCurrent = this.itemRec.maxStockDaysCurrentFv;
      this.isMaxStockDaysEdited = this.itemRec.maxStockDaysCurrentFvUpdated;
      this.orderStopFlagCurrent = this.itemRec.orderStopFlagCurrentFv;
      this.isOrderStopFlagEdited = this.itemRec.orderStopFlagCurrentFnUpdated;
      this.autoOrderLotModCurrent = this.itemRec.autoOrderLotModCurrentFv;
      this.isMinZaiCtgEdited = false;
      this.isMaxZaiCtgEdited = false;
      this.isMinStockDaysCtgEdited = false;
      this.isOrderStopFlagCtgEdited = false;

      this.ctgNamePath = "[" + this.itemRec.ctg0Fv + "]";
      if (this.itemRec.ctgCd1Fv && this.itemRec.ctgCd1Fv !== "") {
        this.ctgNamePath += " - [" + this.itemRec.ctg1Fv + "]";
      }
      if (this.itemRec.ctgCd2Fv && this.itemRec.ctgCd2Fv !== "") {
        this.ctgNamePath += " - [" + this.itemRec.ctg2Fv + "]";
      }
      if (this.itemRec.ctgCd3Fv && this.itemRec.ctgCd3Fv !== "") {
        this.ctgNamePath += " - [" + this.itemRec.ctg3Fv + "]";
      }

      if (this.showCtgParam) {
        this.queryCtgParam();
      }

      if (this.commonService.config.useCaseItem && this.itemRec.caseItems == undefined) {
        this.queryCaseItem();
      }
      if (this.itemRec.fromItems == undefined) {
        this.queryRenewHistory();
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
    }
  }

  ngAfterContentChecked() {
    /* this.queryCtgParam(); */
  }

  clearProgressState() {
    this.commonService.closeSpinnerForSubComp(this.spinnerDialogRec);
    if (this.subscriptionQuery) {
      this.subscriptionQuery.unsubscribe();
      this.subscriptionQuery = undefined;
    }
  }

  queryCtgParam() {
    if (!this.showCtgParam) return;
    if (!this.itemRec) return;
    if (this.currentItemRec &&
        this.currentItemRec.storeCdFv === this.itemRec.storeCdFv &&
        this.currentItemRec.itemCdFv === this.itemRec.itemCdFv) return;

    this.currentItemRec = this.itemRec;

    this.spinnerDialogRec = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");

    let level: number;
    if (this.itemRec.ctgCd0Fv && this.itemRec.ctgCd0Fv !== "") level = 0;
    if (this.itemRec.ctgCd1Fv && this.itemRec.ctgCd1Fv !== "") level = 1;
    if (this.itemRec.ctgCd2Fv && this.itemRec.ctgCd2Fv !== "") level = 2;
    if (this.itemRec.ctgCd3Fv && this.itemRec.ctgCd3Fv !== "") level = 3;

    let reqParamCtgSearchDto: ReqParamCtgSearchDto = {
      storeCdFv: this.itemRec.storeCdFv,
      ctgLevelFn: level,
      ctgCd0Fv: this.itemRec.ctgCd0Fv,
      ctgCd1Fv: this.itemRec.ctgCd1Fv,
      ctgCd2Fv: this.itemRec.ctgCd2Fv,
      ctgCd3Fv: this.itemRec.ctgCd3Fv,
      rankFv: this.itemRec.rankFv
    }

    this.subscriptionQuery = this.httpBasic.paramCtgSearch(reqParamCtgSearchDto)
    .subscribe(
      response => this.receiveParamCtg(response),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveParamCtg(response: RspParamCtgSearch) {
    this.clearProgressState();

    if (this.httpBasic.handleAppError(response)) return;

    this.ctgParamRec = undefined;
    if (response.result[0]) {
      this.ctgParamRec = new CtgParamRec(response.result[0]);
    }
  }

  getCtgParamRecValue(prop: string) {
    if (!this.itemRec) return "";
    if (this.ctgParamRec) return this.ctgParamRec[prop];
    return "";
  }

  showCaseItem(item: ItemRec) {
    if (this.commonService.config.useCaseItem == undefined) return false;
    if (!this.commonService.config.useCaseItem) return false;
    if (!this.itemRec) return false;
    if (!item.caseItems) return false;
    if (item.caseItems.length === 0) return false;
    return true;
  }

  queryCaseItem() {
    let request: ReqGetCaseItem = {
      access: this.commonService.loginUser,
      storeCd: this.itemRec.storeCdFv,
      itemCd: this.itemRec.itemCdFv
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetCaseItem", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveCaseItem(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveCaseItem(response: RspGetCaseItem) {
    if (this.httpBasic.handleAppError(response)) return;

    this.itemRec.caseItems = [];
    response.caseItems.forEach((dto) => {
      this.itemRec.caseItems.push(dto);
    });
    this.screenChanged.emit();
  }

  queryRenewHistory() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "itemRenew/get_renew_history",
      bindVariables: [this.itemRec.storeCdFv, this.itemRec.itemCdFv]
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveRenewHistory(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveRenewHistory(response: RspExecuteQuery) {
    if (this.httpBasic.handleAppError(response)) return;

    this.itemRec.fromItems = [];
    this.itemRec.toItems = [];

    response.rows.forEach((dto) => {
      let item = new ItemRenew();
      item.renewDate = dto.colData[0];
      if (this.itemRec.itemCdFv === dto.colData[1]) {
        item.itemCd = dto.colData[4];
        item.itemName = dto.colData[5];
        item.standard = dto.colData[6];
        this.itemRec.toItems.push(item);
      } else {
        item.itemCd = dto.colData[1];
        item.itemName = dto.colData[2];
        item.standard = dto.colData[3];
        this.itemRec.fromItems.push(item);
      }
    });
  }

  showRenewItem() {
    if (!this.itemRec) return false;
    if (!this.itemRec.fromItems) return false;
    if (!this.itemRec.toItems) return false;
    if (this.itemRec.fromItems.length > 0 || this.itemRec.toItems.length > 0) return true;
    return false;
  }

  getValue(key: string) {
    if (!this.itemRec) return "";
    if (key === "ctgNamePath") return this.ctgNamePath;
    if (key === "orderStopFlagCurrent") return this.orderStopFlagCurrent;
    if (key === "orderNumCurrent") {
      if (this.commonService.config.orderEditInBara) {
        return this.orderNumCurrent * this.itemRec.orderLotFn;
      } else {
        return this.orderNumCurrent;
      }
    }
    if (key === "stockNumCurrent") return this.stockNumCurrent;

    if (key === "minZaiCurrent") return this.minZaiCurrent;
    if (key === "maxZaiCurrent") return this.maxZaiCurrent;
    if (key === "minStockDaysCurrent") return this.minStockDaysCurrent;
    if (key === "maxStockDaysCurrent") return this.maxStockDaysCurrent;
    if (key === "autoOrderLotModCurrent") return this.autoOrderLotModCurrent;
    return this.itemRec[key];
  }
}
