import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqSpmt10331Exclusion } from 'src/app/request/req-spmt10331-exclusion';
import { ReqSpmt10331Init } from 'src/app/request/req-spmt10331-init';
import { ReqSpmt10331Search } from 'src/app/request/req-spmt10331-search';
import { DropdownListOfUserDto } from 'src/app/response/rsp-get-dropdown-list-of-user';
import { RspSpmt10331Init } from 'src/app/response/rsp-spmt10331-init'
import { Spmt10331Dto, RspSpmt10331Search } from 'src/app/response/rsp-spmt10331-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Spmt10331ConfirmDialogComponent } from 'src/app/dialog/spmt10331-confirm-dialog/spmt10331-confirm-dialog.component';
import { Spmt10331DetailDialogComponent } from 'src/app/dialog/spmt10331-detail-dialog/spmt10331-detail-dialog.component';
import { ErrorNotificationDialogComponent } from 'src/app/dialog/error-notification-dialog/error-notification-dialog.component';
import { RspUserInfo, UserInfoDto } from 'src/app/response/rsp-user-info';
import { ReqUserInfoSearch } from 'src/app/request/req-user-info-search';
import { ReqGetStoreList } from 'src/app/request/req-get-store-list';
import { RspGetStoreList, GetStoreListDto } from 'src/app/response/rsp-get-store-list';
import { ReqAccess } from 'src/app/request/req-access';
import { ME200012, MI100001, MI200003, MQ200010 } from "src/app/common/const-message-id";
import { Rsp } from 'src/app/response/rsp';
import { DropdownListDto } from 'src/app/response/rsp-spvw10241-init';
import { MessageService } from 'src/app/service/message.service';
import { ReqPageDto } from 'src/app/request/req-page-dto';

class ItemSelect {
  check: FormControl;
  result: Spmt10331Dto;
}

export interface AccessInfo extends ReqAccess {
  cmpnyCd?: string;
  lang?: string;
  sysModeCd?: string;
}

class Spmt10331Constant {
  public static readonly MAXITEMS: number = 1000;
  public static readonly MOVESTT_04: string = '04';
  public static readonly MOVESTT_06: string = '06';
  public static readonly CHECKSTTFLG_1: string = '1';
  public static readonly REQSTATUS_1: string = '1';
  public static readonly REDBLACKFLG_01: string = '01';
  public static readonly REDBLACKFLG_02: string = '02';
  public static readonly DATEFORMAT: string = 'yyyy-MM-dd';
  public static readonly EMPTY_STRING: string = '';
  public static readonly STORECDCTRL: string = 'storeCd';
  public static readonly SLIPNOCTRL: string = 'slipNo';
  public static readonly APPROVERCDCTRL: string = 'approverCd';
  public static readonly REQ_STATUS_CTRL: string = 'reqStatus';
  public static readonly DELFLGCTRL: string = 'delFlg';
  public static readonly REQUESTERCDCTRL: string = 'requesterCd';
  public static readonly SLIPCREATEDATEFROMCTRL: string = 'slipCreateDateFrom';
  public static readonly SLIPCREATEDATETOCTRL: string = 'slipCreateDateTo';
  public static readonly REQSTATUS_JP: string = '申請状態';
  public static readonly MESSAGE_LOADING: string = '検索中・・・';
  public static readonly DELFLG_STATUS = '1';  
  public static readonly ZERO = 0;
  public static readonly LIMIT_RECORD = 1001;
  public static readonly DELFLG_1 = '1';
}

@Component({
  selector: "app-spmt10331",
  templateUrl: "./spmt10331.component.html",
  styleUrls: ["./spmt10331.component.css"]
})
export class Spmt10331Component implements OnInit, OnDestroy {
  public itemsShow: ItemSelect[] = [];
  public items: ItemSelect[] = [];
  public allChecked: boolean = false;
  public itemRecordCount: number = 0;
  
  public columnIds: string[] = ["checkSttFlg","slipNo","orgSlipNo","reqStatusNm","totalDtQty","totalOrderPriceExc","slipCreateDate","requesterNm","approverNm","slipComment","approverComment"];

  public columnDefsItems: TableColumnDef[] = [
    {columnId: "checkSttFlg", header: "選択", width: 40, align: "center",checkbox: true},
    {columnId: "slipNo", header: "伝票番号", width: 80, align: "left", editable: true},
    {columnId: "orgSlipNo", header: "元伝票番号", width: 80, align: "left"},
    {columnId: "reqStatusNm", header: "申請状態", width: 50, align: "left"},
    {columnId: "totalDtQty", header: "数量合計", width: 80, align: "right", numberPipe: '1.0-0'},
    {columnId: "totalOrderPriceExc", header: "原価金額合計", width: 100, align: "right", numberPipe: '1.2-2'},
    {columnId: "slipCreateDate", header: "伝票作成日", width: 80, align: "center"},
    {columnId: "requesterNm", header: "申請者", width: 100, align: "left"},
    {columnId: "approverNm", header: "承認者", width: 100, align: "left"},
    {columnId: "slipComment", header: "申請者コメント", width: 200, align: "left"},
    {columnId: "approverComment", header: "承認者コメント", width: 200, align: "left"}
  ];

  public userInfo: UserInfoDto = null;
  public accessInfo: AccessInfo = {
    ...this.commonService.loginUser
  }
  public storeCdList: GetStoreListDto[] = [];
  public storeCdSearch: string = '';
  public reqStatusList: DropdownListDto[] = [];
  public defaultValueReqStatus: any[] = [1,3];
  public userList: DropdownListOfUserDto[] = [];
  public itemTableWidth: any;
  public paginatorWidth: any;
  public isDisableSrchBtn: boolean = false;
  public isDisableConfirmBtn: boolean = true;
  public isDisabledropdownstatus: boolean = false;
  public isShowSearchCondition: boolean = true;
  public searchCondition: FormGroup;
  private searchConditionSubscription: Subscription;
  public pickerDateBegin: MatDatepicker<null>;
  public pickerDateEnd: MatDatepicker<null>;
  @ViewChild("matPagenatorItem", {static: false}) matPagenatorItem: MatPaginator;
  constructor(
    public commonService: CommonService,
    private message: MessageService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.fnInitFormGroup();
    this.initPrc();
    this.calcTableWidth();
    this.searchConditionValueChange();
  }

  fnInitFormGroup(){
    this.searchCondition = this.fb.group({
      storeCd: [Spmt10331Constant.EMPTY_STRING,Validators.required],
      slipCreateDateFrom: [],
      slipCreateDateTo: [],
      requesterCd: [Spmt10331Constant.EMPTY_STRING],
      approverCd: [Spmt10331Constant.EMPTY_STRING],
      slipNo: [Spmt10331Constant.EMPTY_STRING],
      delFlg: [true],
      reqStatus: new FormArray([])
    });
  }

  async initPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10331Constant.MESSAGE_LOADING);
    await this.getUserInfo().catch(() => { this.commonService.closeSpinner(); });
    await this.getStoreList().catch(() => { this.commonService.closeSpinner(); });
    await this.getInitPrc().catch(() => { this.commonService.closeSpinner(); });
    this.commonService.closeSpinner();
  }

  getUserInfo() {
    let request: ReqUserInfoSearch = {
      access: this.accessInfo
    };
    return new Promise<void>((resolve, reject) => {
      let subsc = this.httpBasic.getUserInfo(request).subscribe(
        result => {
          this.setResultGetUserInfo(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      );
    });
  }

  setResultGetUserInfo(response: RspUserInfo) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length > 0) {
      this.accessInfo = {
        ...this.commonService.loginUser,
        cmpnyCd: response.rows[0].mainCmpnyCd,
        lang: response.rows[0].mainLang,
        sysModeCd: response.rows[0].sysModeCd
      }
      this.userInfo = response.rows[0];
    }
  }

  getInitPrc() {
    let request: ReqSpmt10331Init = {
      access: this.accessInfo
    };

    return new Promise<void>((resolve, reject) => {
      let subsc = this.httpBasic.initSpmt10331Init(request).subscribe(
        result => {
          this.setResultInit(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      );
    });
  }

  setResultInit(result: RspSpmt10331Init) {
    if (this.httpBasic.handleAppError(result)) return;
    this.userList = result.userList;
    this.reqStatusList = result.reqStatusList;
    const reqStatus = this.searchCondition.get(Spmt10331Constant.REQ_STATUS_CTRL) as FormArray;
    this.reqStatusList.forEach((i,index: number) => {
      if(this.defaultValueReqStatus.some((i: number) => i === index)){
        reqStatus.push(new FormControl(true));
        return;
      }
      reqStatus.push(new FormControl(false));
    })
  }

  getStoreList() {
    let request: ReqGetStoreList = {
      access: this.accessInfo,
      storeCd: Spmt10331Constant.EMPTY_STRING,
      userId: Spmt10331Constant.EMPTY_STRING,
      cmpyCd: this.accessInfo.cmpnyCd,
      sysModeCd: this.accessInfo.sysModeCd,
    };

    return new Promise<void>((resolve, reject) => {
      let subsc = this.httpBasic.getStoreList(request).subscribe(
        result => {
          this.setResultStoreList(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      );
    });
  }

  setResultStoreList(response: RspGetStoreList) {
    if (this.httpBasic.handleAppError(response)) return;
    this.storeCdList = response.rows;
    this.searchCondition.get(Spmt10331Constant.STORECDCTRL).setValue(this.userInfo.mainStoreCd);
  }

  searchConditionValueChange() {
    this.searchConditionSubscription = this.searchCondition.valueChanges.subscribe(() => {
      this.updateButtonState();
    });
  }

  updateButtonState() {
    this.isDisableSrchBtn = !this.searchCondition.valid;
  }

  isDisableCheckAll(){
    if(this.itemsShow.length <= 0 || this.itemsShow.every((i: ItemSelect) => i.check.disabled)){
      return true;
    }
    return false;
  }

  openModalConfirm() {
    scrollTo(0, 10);
    let data: Spmt10331Dto[] = [];
    this.itemsShow.forEach((item) => {
      if(item.check.value)
        data.push(item.result);
    });

    const dialogRef = this.commonService.dialog.open(Spmt10331ConfirmDialogComponent, {
      autoFocus: false,
      data: {
        searchResult: data,
        storeCd: this.searchCondition.get(Spmt10331Constant.STORECDCTRL).value,
        accessInfo: this.accessInfo
      }
    });

    let dialogSubscription = dialogRef.afterClosed().subscribe((data: boolean) => {
      if(data) {
        this.searchBtn();
      }
      dialogSubscription.unsubscribe();
    });
  }

  calcTableWidth() {
    this.itemTableWidth = { "width": "100%" };
  }

  pageChangedItem() {
    if(this.itemsShow?.length > 0) this.pageChanged();
  }

  clearSearchResult() {
    this.items = [];
    this.itemsShow = [];
    this.allChecked = false;
    this.itemRecordCount = 0;
    this.isDisableConfirmBtn = true;
  }

  clearBtn() {
    if(this.items.length > 0){
      const subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200010]).subscribe(data => {
        if(data) this.clearPrc();
        subcriptionYesNoDialog.unsubscribe();
      })
      return;
    }
    this.clearPrc();
  }

  clearPrc(){
    this.searchCondition.reset({
      storeCd: this.userInfo.mainStoreCd,
      delFlg: true
    });
    this.clearSearchResult();
    const reqStatusList = this.searchCondition.get(Spmt10331Constant.REQ_STATUS_CTRL) as FormArray;
    reqStatusList.controls.forEach((item: FormControl, index: number) => {
      if(this.defaultValueReqStatus.some((i: number) => i === index)){
        item.setValue(true)
        return;
      }
      item.setValue(false)
    })
    this.storeCdSearch = Spmt10331Constant.EMPTY_STRING;
    this.matPagenatorItem._changePageSize(this.commonService.paginatorOption.pageSizeOptions[0]);
  }

  pageChanged() {
    this.isDisableConfirmBtn = true;
    this.allChecked = false;
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.items[i].check.setValue(false);
      this.itemsShow.push(this.items[i]);
      count++;
    }
    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  allCheck(value: boolean) {
    this.itemsShow.forEach((rec) => {
      if(!rec.check.disabled) {
        rec.check.setValue(value);
      }
    });
    this.allChecked = value;
    this.confirmBtnChangeStatus();
  }

  updateAllChecked() {
    this.confirmBtnChangeStatus();
    this.isCheckedAll();
  }

  isCheckedAll() {
    this.allChecked = !this.itemsShow.some(i => !i.check.disabled &&!i.check.value);
  }

  reqStatusIsNull(){
    const reqStatusLs = this.searchCondition.get(Spmt10331Constant.REQ_STATUS_CTRL) as FormArray;
    if(reqStatusLs.controls.every((item: FormControl) => !item.value)){
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME200012].replace('%1', Spmt10331Constant.REQSTATUS_JP));
      this.isDisableSrchBtn = true;
      return true;
    }
    return false;
  }

  searchClick(){
    this.storeCdSearch = this.searchCondition.get(Spmt10331Constant.STORECDCTRL).value;
    this.searchBtn();
  }
  
  searchBtn() {
    if(this.reqStatusIsNull()) return;
    this.searchPrc();
  }

  getDate(ctrlNm: string) {
    if(this.searchCondition.get(ctrlNm).value === null) return Spmt10331Constant.EMPTY_STRING;
    return formatDate(this.searchCondition.get(ctrlNm).value, Spmt10331Constant.DATEFORMAT, 'en_US');
  }

  getReqStatus(){
    let reqStatusLs = [];
    const reqStatus = this.searchCondition.get(Spmt10331Constant.REQ_STATUS_CTRL) as FormArray;
    reqStatus.controls.forEach((item: FormControl, index: number) => {
      if(item.value){
        reqStatusLs.push(this.reqStatusList[index].DATACD)
      }
    })
    return reqStatusLs.toString();
  }

  searchPrc() {
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10331Constant.MESSAGE_LOADING);
    const request: ReqSpmt10331Search = {
      storeCd: this.storeCdSearch,
      slipCreateDateFrom: this.getDate(Spmt10331Constant.SLIPCREATEDATEFROMCTRL),
      slipCreateDateTo: this.getDate(Spmt10331Constant.SLIPCREATEDATETOCTRL),
      requesterCd: this.searchCondition.get(Spmt10331Constant.REQUESTERCDCTRL).value,
      approverCd: this.searchCondition.get(Spmt10331Constant.APPROVERCDCTRL).value,
      slipNo: this.searchCondition.get(Spmt10331Constant.SLIPNOCTRL).value,
      delFlg: this.searchCondition.get(Spmt10331Constant.DELFLGCTRL).value,
      reqStatus: this.getReqStatus(),
      access: this.accessInfo,
      pageDto: this.getPageDto()
    }
    this.clearSearchResult();
    const subsc = this.httpBasic.searchSpmt10331(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10331Search) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });

      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.searchBtn();
        subcription.unsubscribe();
      });
      return;
    }

    if(response.results.length === 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001]);
      return;
    }

    for(let i = 0; i < Spmt10331Constant.MAXITEMS; i ++){
      if(i >= response.results.length) break;
      let row: ItemSelect = {
        check: new FormControl({value: false, disabled: this.isDisableCheckBox(response.results[i])}),
        result: response.results[i]
      };
      this.items.push(row)
    }

    this.itemRecordCount = this.items.length ?? 0;
    if (response.results.length > 300) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,Spmt10331Constant.MAXITEMS));
    }
    this.matPagenatorItem.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
    this.pageChanged();
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: Spmt10331Constant.ZERO,
      dispNum: Spmt10331Constant.LIMIT_RECORD
    }
  }

  onClickLink(row: ItemSelect) {
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10331Constant.MESSAGE_LOADING);
    const request: ReqSpmt10331Exclusion = {
      slipNo: row.result.slipNo,
      updDatetime: row.result.updDatetime,
      access: this.accessInfo
    };

    const subsc = this.httpBasic.checkExclusionSpmt10331(request).subscribe(
      result => {
        this.checkResultClickLink(result,row);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    );
  }

  checkResultClickLink(response: Rsp, row: ItemSelect) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });

      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.searchBtn();
        subcription.unsubscribe();
      });
      return;
    }
    this.openModalDetail(row.result);
  }

  openModalDetail(row: Spmt10331Dto) {
    scrollTo(0, 10);
    this.commonService.dialog.open(Spmt10331DetailDialogComponent, {
      autoFocus: false,
      data: {
        row,
        accessInfo: this.accessInfo
      }
    });
  }

  isDisableCheckBox(item: Spmt10331Dto){
    return item.reqStatus != Spmt10331Constant.REQSTATUS_1 || item.redBlackFlg != Spmt10331Constant.REDBLACKFLG_01 || item.delflg === Spmt10331Constant.DELFLG_1;
  }

  confirmBtnChangeStatus() {
    this.isDisableConfirmBtn = !this.itemsShow.some(i => i.check.value);
  }

  colStyle(item: Spmt10331Dto, column: TableColumnDef) {
    if(column.checkbox) {
      return Spmt10331Constant.EMPTY_STRING;
    }
    if(item.delflg === Spmt10331Constant.DELFLG_STATUS){
      return 'border-right-gray bg-gray'
    }
    if(item.redBlackFlg !== Spmt10331Constant.REDBLACKFLG_02) {
      return Spmt10331Constant.EMPTY_STRING;
    }
    return 'text-color-red';
  }

  ngOnDestroy() {
    if (this.searchConditionSubscription) this.searchConditionSubscription.unsubscribe();
  }
}
