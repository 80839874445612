import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TaskSearchCondWholeStatus } from 'src/app/common/config';
import { StoreGroupNameRec } from 'src/app/common/store-group-name-rec';
import { StoreGroupTypeRec } from 'src/app/common/store-group-type-rec';
import { Item } from 'src/app/page/noisecut/noisecut.component';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { RspGetStoreGroupName } from 'src/app/response/rsp-get-store-group-name';
import { RspGetStoreGroupType } from 'src/app/response/rsp-get-store-group-type';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { TaskService } from '../../1_service/taskService';
import { TaskWholeStatusCommentsComponent } from '../task-whole-status-comments/task-whole-status-comments.component';
import { TaskWholeStatusProgressComponent } from '../task-whole-status-progress/task-whole-status-progress.component';

@Component({
  selector: 'app-task-whole-status-main',
  templateUrl: './task-whole-status-main.component.html',
  styleUrls: ['./task-whole-status-main.component.css']
})
export class TaskWholeStatusMainComponent implements OnInit, OnDestroy {

  public formGroupCondition: FormGroup;
  public dateBegin: Date;
  public dateEnd: Date;

  public storeGroupTypeList: StoreGroupTypeRec[] = [];
  public storeGroupNameList: StoreGroupNameRec[] = [];
  private autoSearch: boolean = true;
  private subsc: Subscription[] = [];
  private initStoreGroup: string;

  @ViewChild("conditionHeader", { static: true, read: SectionHeaderComponent }) sectionHeaderComponent: SectionHeaderComponent;
  @ViewChild(TaskWholeStatusProgressComponent, { static: false }) taskWholeStatusProgressComponent: TaskWholeStatusProgressComponent;
  @ViewChild(TaskWholeStatusCommentsComponent, { static: false }) taskWholeStatusCommentsComponent: TaskWholeStatusCommentsComponent;
  @ViewChild(DateRangeComponent, { static: false }) dateRangeComponent: DateRangeComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic:    HttpBasicService,
    public taskService:   TaskService,
  ) {
    let cond: TaskSearchCondWholeStatus = {...this.commonService.config.task.searchCondWholeStatus};

    this.formGroupCondition = new FormGroup({
      storeGroupType:       new FormControl(""),
      storeGroup:           new FormControl(""),
      releaseStatusValue:   new FormControl(-1),
      statusNotStarted:     new FormControl(cond.statusNotStarted),
      statusStarted:        new FormControl(cond.statusStarted),
      statusCompleted:      new FormControl(cond.statusCompleted),
      dateCheck:            new FormControl(cond.endDate),
      taskName:             new FormControl(cond.taskName),
      authorFv:             new FormControl(cond.author),
      responsibleClass:     new FormControl(cond.responsibleClass)
    });
    /*
    this.dateBegin = new Date();
    this.dateBegin.setMonth(this.dateBegin.getMonth() - 6);
    this.dateBegin.setDate(this.dateBegin.getDate() + 1);
    this.dateEnd = new Date();
    this.dateEnd.setMonth(this.dateEnd.getMonth() + 6);
    */
    this.dateBegin = new Date();
    this.dateBegin.setMonth(this.dateBegin.getMonth() + cond.endDateFrom);
    this.dateEnd = new Date();
    this.dateEnd.setMonth(this.dateEnd.getMonth() + cond.endDateTo);

    this.formGroupCondition.markAsDirty();
  }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.subsc.push(
      this.formGroupCondition.get("storeGroupType").valueChanges.subscribe((value) => { this.getStoreGroupName(); })
    );

    let cond: TaskSearchCondWholeStatus = {...this.commonService.config.task.searchCondWholeStatus};
    this.getStoreGroupType(cond.storeGroupType !== "");
  }

  ngOnDestroy(): void {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    this.taskWholeStatusProgressComponent?.setTableHeight();
  }

  flipCondStatus(formName: string) {
    let form = this.formGroupCondition.get(formName);
    if (form.value) {
      form.setValue(false);
    } else {
      form.setValue(true);
    }
  }

  checkCondStatus(value: boolean) {
    this.formGroupCondition.get("statusNotStarted").setValue(value);
    this.formGroupCondition.get("statusStarted").setValue(value);
    this.formGroupCondition.get("statusCompleted").setValue(value);
  }

  getStoreGroupType(condInit?: boolean) {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    let subsc = this.httpBasic.getStoreGroupType().subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveStoreGroupType(response, condInit);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveStoreGroupType(response: RspGetStoreGroupType, condInit?: boolean) {
    if (this.httpBasic.handleAppError(response)) return;

    this.storeGroupTypeList = [];
    for (let type of response.storeGroupTypes) {
      let typeRec = new StoreGroupTypeRec();
      typeRec.storeGroupTypeCd = type.storeGroupTypeCdFn;
      typeRec.storeGroupType = type.storeGroupTypeFv;
      this.storeGroupTypeList.push(typeRec);
    }

    let cond: TaskSearchCondWholeStatus = {...this.commonService.config.task.searchCondWholeStatus};
    if (cond.storeGroupType !== "") {
      let groupType = this.storeGroupTypeList.find((item) => item.storeGroupType === cond.storeGroupType);
      if (groupType) {
        if (cond.storeGroup !== "") {
          this.initStoreGroup = cond.storeGroup;
        } else {
          this.initStoreGroup = undefined;
        }
        this.formGroupCondition.get("storeGroupType").setValue(groupType.storeGroupTypeCd);
      }
    }
  }

  getStoreGroupName() {
    if (this.formGroupCondition.get("storeGroupType").value === "") {
      this.storeGroupNameList = [];
      this.formGroupCondition.get("storeGroup").setValue("");
      return;
    }
    let typeCd: number = parseInt(this.formGroupCondition.get("storeGroupType").value);
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");

    let subsc = this.httpBasic.getStoreGroupName(typeCd).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveStoreGroupName(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveStoreGroupName(response: RspGetStoreGroupName) {
    if (this.httpBasic.handleAppError(response)) return;

    this.storeGroupNameList = [];

    for (let name of response.storeGroupNames) {
      let nameRec = new StoreGroupNameRec();
      nameRec.storeGroupTypeCd = name.storeGroupTypeCdFn;
      nameRec.storeGroupName = name.storeGroupNameFv;
      nameRec.storeGroupCd = name.storeGroupCdFn;
      this.storeGroupNameList.push(nameRec);
    }
    this.formGroupCondition.get("storeGroup").setValue("");
    if (this.initStoreGroup) {
      let group = this.storeGroupNameList.find((item) => item.storeGroupName === this.initStoreGroup);
      if (group) {
        this.formGroupCondition.get("storeGroup").setValue(group.storeGroupCd);
      }
      this.initStoreGroup = undefined;
    }
  }

  getStatus() {
    if (this.commonService.config.task.wholeStatusAutoClose) this.sectionHeaderComponent.closeSectionBody();
    this.formGroupCondition.markAsPristine();
    setTimeout(() => {
      this.taskWholeStatusProgressComponent?.doQuery(this.getCondDateEnd());
    },
      100);
  }

  getCondDateEnd() {
    let dateEndMin;
    let dateEndMax;
    if (this.autoSearch) {
      dateEndMin = this.commonService.formatDate(this.dateBegin);
      dateEndMax = this.commonService.formatDate(this.dateEnd);
      this.autoSearch = false;
    } else {
      let range = this.dateRangeComponent.getDateRange();
      dateEndMin = this.commonService.formatDate(range.dateBegin);
      dateEndMax = this.commonService.formatDate(range.dateEnd);
    }
    let dateEnd = {
      dateEndMin: this.formGroupCondition.get("dateCheck").value ? dateEndMin : "",
      dateEndMax: this.formGroupCondition.get("dateCheck").value ? dateEndMax : ""
    }
    return dateEnd;
  }

  isAllStore() {
    if (this.formGroupCondition.get("storeGroupType").value === "") return true;
    return false;
  }
}
