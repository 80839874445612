export class VideoDevice {
  public deviceId:          string;
  public label:             string;
  public video:             HTMLVideoElement;
  public stream:            MediaStream;
  private canvasElement:    HTMLCanvasElement;
  private canvas:           CanvasRenderingContext2D;
  private trimCanvasElement: HTMLCanvasElement;
  private trimCanvas:       CanvasRenderingContext2D;
  public fullImage:         string;
  public thumbnail:         string;
  public stop:              boolean = false;
  public trimStartX:        number = -1;
  public trimStartY:        number = -1;
  public trimMoveX:         number = -1;
  public trimMoveY:         number = -1;

  startVideo() {
    this.stop = false;
    navigator.mediaDevices.getUserMedia({ video: {deviceId: this.deviceId}} ).then(
      (stream) => {
        this.stream = stream;
        this.video = <HTMLVideoElement>document.getElementById("video" + this.deviceId);
        this.trimCanvasElement = <HTMLCanvasElement>document.getElementById("trim" + this.deviceId);
        this.trimCanvas = this.trimCanvasElement.getContext("2d");
        this.video.srcObject = this.stream;
        this.video.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
        this.video.play().then(
          () => {
            this.trimCanvasElement.width = 300;
            this.trimCanvasElement.height = Math.floor(this.video.videoHeight * 300 / this.video.videoWidth);
          }
        );
        this.trimCanvasElement.onmousedown = (event) => {
          this.mounseDown(event);
        }
      }
    )
    .catch((reason) => {
      console.error("error ", reason);
    });
  }

  mounseDown(event: MouseEvent) {
    let rect = this.video.getBoundingClientRect();
    let posX = event.pageX - (window.scrollX + rect.x);
    let posY = event.pageY - (window.scrollY + rect.y);
    this.trimStartX = posX;
    this.trimStartY = posY;
    this.trimMoveX = posX;
    this.trimMoveY = posY;

    this.trimCanvasElement.onmousemove = (event: MouseEvent) => {
      let rect = this.video.getBoundingClientRect();
      this.trimMoveX = event.pageX - (window.scrollX + rect.x);
      this.trimMoveY = event.pageY - (window.scrollY + rect.y);
      var lx, hx, ly, hy: number;
      if (this.trimStartX < this.trimMoveX) {
        lx = this.trimStartX;
        hx = this.trimMoveX;
      } else {
        lx = this.trimMoveX;
        hx = this.trimStartX;
      }
      if (this.trimStartY < this.trimMoveY) {
        ly = this.trimStartY;
        hy = this.trimMoveY;
      } else {
        ly = this.trimMoveY;
        hy = this.trimStartY;
      }
      /*
      console.log("***********");
      console.log("[" + lx + "," + ly + "]");
      console.log("[" + hx + "," + ly + "]");
      console.log("[" + hx + "," + hy + "]");
      console.log("[" + lx + "," + hy + "]");
      console.log("***********");
      */
      this.trimCanvas.clearRect(0, 0, this.trimCanvasElement.width, this.trimCanvasElement.height);
      this.trimCanvas.beginPath();
      this.trimCanvas.moveTo(lx, ly);
      this.trimCanvas.lineTo(hx, ly);
      this.trimCanvas.lineTo(hx, hy);
      this.trimCanvas.lineTo(lx, hy);
      this.trimCanvas.lineTo(lx, ly);
      this.trimCanvas.lineWidth = 4;
      this.trimCanvas.strokeStyle = "red";
      this.trimCanvas.stroke();
    };

    window.onmouseup = () => {
      this.trimCanvasElement.onmousemove = null;
      window.onmouseup = null;
    };
  }

  stopVideo() {
    this.stop = true;
    this.closeVideo();
  }

  saveImage() {
    if (!this.canvasElement) {
      this.canvasElement = <HTMLCanvasElement>document.getElementById("canvas" + this.deviceId);
      this.canvas = this.canvasElement.getContext("2d");
    }
    if (this.trimStartX >= 0) {
      let h = Math.abs(this.trimMoveY - this.trimStartY) * this.video.videoWidth / 300;
      let w = Math.abs(this.trimMoveX - this.trimStartX) * this.video.videoWidth / 300;
      this.canvasElement.height = h;
      this.canvasElement.width = w;
      let xStart = ((this.trimStartX < this.trimMoveX) ? this.trimStartX : this.trimMoveX) * this.video.videoWidth / 300;
      let yStart = ((this.trimStartY < this.trimMoveY) ? this.trimStartY : this.trimMoveY) * this.video.videoWidth / 300;
      this.canvas.drawImage(this.video, xStart, yStart, w, h, 0, 0, w, h);
    } else {
      this.canvasElement.height = this.video.videoHeight;
      this.canvasElement.width = this.video.videoWidth;
      this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
    }
    this.fullImage = this.canvasElement.toDataURL("image/png");

    const THUMBNAIL_WIDTH = 200;
    if (this.canvasElement.width < THUMBNAIL_WIDTH) {
      this.thumbnail = this.fullImage;
    } else {
      this.canvasElement.width = THUMBNAIL_WIDTH;
      this.canvasElement.height = (this.video.videoHeight * THUMBNAIL_WIDTH) / this.video.videoWidth;
      this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
      this.thumbnail = this.canvasElement.toDataURL("image/png");
    }
  }

  closeVideo() {
    this.stream?.getTracks()?.forEach((track) => {track.stop();})
  }

  hasRange() : boolean {
    if (this.trimStartX >= 0) return true;
    return false;
  }

  clearRange() {
    this.trimStartX = -1;
    this.trimStartY = -1;
    this.trimMoveX = -1;
    this.trimMoveY = -1;
    this.trimCanvas.clearRect(0, 0, this.trimCanvasElement.width, this.trimCanvasElement.height);
  }
}