import { formatDate } from "@angular/common";
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ME100006, ME200005, MI100001, MI200003, MI200007, MI200008, MI200009, MQ200001, MQ200007, MQ200008, MQ200009 } from "src/app/common/const-message-id";
import { TableColumnDef } from "src/app/common/table-column-def";
import { ErrorNotificationDialogComponent } from "src/app/dialog/error-notification-dialog/error-notification-dialog.component";
import { NotificationDialogComponent } from "src/app/dialog/notification-dialog/notification-dialog.component";
import { SortConditionDialogComponent } from "src/app/dialog/sort-condition-dialog/sort-condition-dialog.component";
import { Spmt10341DetailDialogComponent } from "src/app/dialog/spmt10341-detail-dialog/spmt10341-detail-dialog.component";
import { Spmt10341ListSelectDialogComponent } from "src/app/dialog/spmt10341-list-select-dialog/spmt10341-list-select-dialog.component";
import { PositiveNumberOnlyError } from "src/app/directive/positive-number-only.directive";
import { NumberKeypadComponent } from "src/app/partsCommon/number-keypad/number-keypad.component";
import { Req } from "src/app/request/req";
import { ReqAccess } from "src/app/request/req-access";
import { ReqGetStoreList } from "src/app/request/req-get-store-list";
import { ReqSortConditionConfig } from "src/app/request/req-sort-condition-config";
import { ConfirmKbn } from "src/app/request/req-spmt10341-get-confirm";
import { ReqSpmt10341GetDetail } from "src/app/request/req-spmt10341-get-detail";
import { ReqSpmt10341Search, ReqSpmt10341SearchDto } from "src/app/request/req-spmt10341-search";
import { RspSortConditionConfigDto } from "src/app/response/rsp-sort-condition-config";
import { RspSpmt10341GetDetailDto } from "src/app/response/rsp-spmt10341-get-detail";
import { CategoryDto, CategoryLevel, RspSpmt10341Init, StoreApprovalStatusDto, UserDto } from "src/app/response/rsp-spmt10341-init";
import { RspSpmt10341Search, RspSpmt10341SearchDto } from "src/app/response/rsp-spmt10341-search";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { MessageService } from "src/app/service/message.service";

interface CustomTableColumnDef extends TableColumnDef {
  type?: "normal" | "checkbox" | "inputText" | "link" | "inputNumber" | "number";
  isDisable?: Function;
  pipe?: "number";
}

const ERROR_PRODUCT_STAUTS = "9";
const STORE_ORDER_REQUEST_STATUS = "0";
const HEADQUATER_ORDER_INPUT = "1";
@Component({
  selector: "app-spmt10341",
  templateUrl: "./spmt10341.component.html",
  styleUrls: ["./spmt10341.component.css"]
})
export class Spmt10341Component implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild(NumberKeypadComponent, { static: true }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private formBuilder: FormBuilder,
    private httpBasic: HttpBasicService,
    public commonService: CommonService,
    private message: MessageService,
    private cdr: ChangeDetectorRef
  ) {
    this.access = {
      ...this.access,
      ...this.commonService.loginUser,
    };
    this.formSearchCondition = this.formBuilder.group({
      storeCd: [this.initSearchCondition.storeCd, Validators.required],
      storeApprovalStatus: [this.initSearchCondition.storeApprovalStatus],
      orderDate: [new Date(), Validators.required],
      userCd: [this.initSearchCondition.userCd],
      ctgLevel: [this.initSearchCondition.ctgLevel],
      ctgCd: [this.initSearchCondition.ctgCd],
      sortConfigText: [this.initSearchCondition.sortConfigText],
      sortConfig: [this.initSearchCondition.sortConfig],
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  keypadTargetId = undefined;
  storeList: any[] = [];
  userList: UserDto[] = [];
  categoryLevel: any[] = [
    { ctgLevel: CategoryLevel.GMID, ctgName: "中分類" },
    { ctgLevel: CategoryLevel.GSMALL, ctgName: "小分類" },
  ];
  categoryList: CategoryDto[] = [];
  storeApprovalStatusList: StoreApprovalStatusDto[] = [];

  access: ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd: "",
    lang: "",
    storeCd: "",
  };

  initSearchCondition: ReqSpmt10341SearchDto = {
    storeCd: this.access.storeCd,
    storeApprovalStatus: "",
    orderDate: formatDate(new Date(), "yyyy/MM/dd", "en_US"),
    userCd: "",
    ctgLevel: CategoryLevel.GMID,
    ctgCd: "",
    sortConfig: [],
    sortConfigText: "",
  };

  sortDefault: RspSortConditionConfigDto[] = [];

  storeSearchCondition: ReqSpmt10341SearchDto = JSON.parse(JSON.stringify(this.initSearchCondition));

  formSearchCondition: FormGroup;

  dataSource: MatTableDataSource<RspSpmt10341SearchDto> = new MatTableDataSource();
  checkAllFlag: boolean = false;
  isLeastOneChecked: boolean = false;
  tableHeight:number = 263;

  get ERROR_PRODUCT_STAUTS() {
    return ERROR_PRODUCT_STAUTS;
  }

  calcTableWidth(): object {
    let width = 1; // For left border
    for (const colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;
    }
    return { width: "" + width + "px" };
  }

  /**
   * Determines if a row is disabled based on its storeApprovalStatus, officeInputFlg, and productStatus.
   *
   * @param {RspSpmt10341SearchDto} row - The row to check.
   * @return {boolean} Returns true if the row is disabled, false otherwise.
   */
  fnIsRowDisabled(row: RspSpmt10341SearchDto): boolean {
    return (
      row.storeApprovalStatus !== STORE_ORDER_REQUEST_STATUS ||
      row.officeInputFlg === HEADQUATER_ORDER_INPUT ||
      row.productStatus === ERROR_PRODUCT_STAUTS
    );
  }

  pageChange($event: any): void {
    this.keypadTargetId = undefined;
    this.numberKeypadComponent.setTargetForm(undefined);
  }

  fnIsDisabledCheckboxAll(): boolean {
    return this.dataSource.data.length === 0 || this.dataSource.data.every((item) => this.fnIsRowDisabled(item));
  }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.fnGetAccessInfo();
  }

  /**
   * Retrieves access information by making a request to the server.
   *
   * @return {void}
   */
  fnGetAccessInfo(): void {
    let req: Req = {
      access: this.access,
    };
    this.httpBasic.beforeRequestStart("読み込み中...");
    this.httpBasic.getUserInfo(req).subscribe({
      next: (res) => {
        if (this.httpBasic.handleAppError(res)) return;
        if (res.rows.length > 0) {
          this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
          this.access.storeCd = res.rows[0].mainStoreCd;
          this.access.sysModeCd = res.rows[0].sysModeCd;
          this.access.lang = res.rows[0].mainLang;
          this.initSearchCondition.storeCd = res.rows[0].mainStoreCd;
          this.formSearchCondition.patchValue({
            storeCd: res.rows[0].mainStoreCd,
          });
          this.fnInitScreen();
          this.getLoginStoreLists();
        }
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => this.httpBasic.afterRequestComplete(),
    });
  }

  getLoginStoreLists() {
    const request: ReqGetStoreList = {
      storeCd: "",
      userId: this.access.userId,
      cmpyCd: this.access.cmpnyCd,
      sysModeCd: this.access.sysModeCd,
      access: this.access,
    };
    this.httpBasic.beforeRequestStart("読み込み中...");
    this.httpBasic.getStoreList(request).subscribe({
      next: (res) => {
        if (this.httpBasic.handleAppError(res)) return;
        this.storeList = res.rows;
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => this.httpBasic.afterRequestComplete(),
    });
  }

  /**
   * Initializes the screen by fetching data for store order approval.
   *
   * @return {void}
   */
  private fnInitScreen(): void {
    this.fnCallInitWs();
    this.fnGetSortConditionConfig();
  }

  private fnCallInitWs() {
    const req: Req = {
      access: this.access,
    };

    this.httpBasic.beforeRequestStart("読み込み中...");
    this.httpBasic.spmt10341Init(req).subscribe({
      next: (res: RspSpmt10341Init) => {
        if (this.httpBasic.handleAppError(res)) return;
        this.userList = res.userList;
        this.categoryList = [
          ...res.middleCategoryList.map((x) => ({ ...x, ctgLevel: CategoryLevel.GMID })),
          ...res.smallCategoryList.map((x) => ({ ...x, ctgLevel: CategoryLevel.GSMALL })),
        ];
        this.storeApprovalStatusList = res.storeApprovalStatusList;
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => this.httpBasic.afterRequestComplete(),
    });
  }

  fnGetSortConditionConfig(): void {
    let req: ReqSortConditionConfig = {
      access: this.access,
      screenId: "SPMT10341",
    };
    this.httpBasic.getSortConditionConfig(req).subscribe({
      next: (rsp) => {
        if (this.httpBasic.handleAppError(rsp)) return;
        if (rsp.sortConditionList?.length === 0) return;
        this.sortDefault = rsp.sortConditionList.filter((x) => x.sortDefault);
        if (this.sortDefault.length === 0) return;
        this.setSortConfigToForm([...this.sortDefault]);
      },
      error: (err) => {
        this.httpBasic.afterRequestComplete();
      },
      complete: () => {
        this.httpBasic.afterRequestComplete();
      },
    });
  }

  private setSortConfigToForm(configs: RspSortConditionConfigDto[]) {
    const sortConfigText = configs.map((x) => x.sortItemNm).join(", ");
    this.formSearchCondition.patchValue({ sortConfigText: sortConfigText, sortConfig: configs });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  btnSearchClick() {
    this.storeSearchCondition = {
      ...this.formSearchCondition.value,
      orderDate: formatDate(this.formSearchCondition.get("orderDate").value, "yyyy/MM/dd", "en_US"),
    };
    this.checkAllFlag = false;
    if (this.formSearchCondition.valid) {
      this.fnClearTable();
      this.fnSearch();
    }
  }

  /**
   * This function performs a search operation.
   *
   * @param {boolean} initFlg - Flag to indicate if it's an initial search
   */
  private fnSearch(initFlg: boolean = true) {
    const req: ReqSpmt10341Search = {
      requestBody: {
        ...this.storeSearchCondition,
      },
      access: this.access,
    };

    this.httpBasic.beforeRequestStart("検索中...");
    this.httpBasic.spmt10341Search(req).subscribe({
      next: (res: RspSpmt10341Search) => {
        if (this.httpBasic.handleAppError(res)) return;
        if (res.recordCount === 0) {
          this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001]);
          this.fnClearTable();
          return;
        }
        if (initFlg && res.recordCount > 1000) {
          this.commonService.openNotificationDialog(
            this.commonService.pageTitle,
            this.message.message[MI200003]?.replace("%1", "1000").replace("%1", "1000")
          );
        }
        this.dataSource.data = res.rows.map((x) => {
          return { ...x, orderRequestQtyForm: new FormControl(x.orderRequestQty) };
        });
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => {
        this.httpBasic.afterRequestComplete();
      },
    });
  }

  /**
   * Handles the click event on a link in the search results table.
   *
   * @param {RspSpmt10341SearchDto} row - The row containing the link that was clicked.
   * @return {void} This function does not return anything.
   */
  onClickLink(row: RspSpmt10341SearchDto): void {
    const req: ReqSpmt10341GetDetail = {
      access: this.access,
      requestBody: {
        storeCd: this.formSearchCondition.value.storeCd,
        productCd: row.productCd,
        orderRequestId: row.orderRequestId,
      },
    };
    this.httpBasic.beforeRequestStart();
    this.httpBasic.spmt10341GetDetail(req).subscribe({
      next: (rsp) => {
        if (this.httpBasic.handleAppError(rsp)) return;
        if (rsp.row.updDateTime !== row.updDateTime) {
          this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME100006]);
          return;
        }
        const ref = this.commonService.dialog.open(Spmt10341DetailDialogComponent, {
          data: {
            ...rsp.row,
            orderRequestQty: row.orderRequestQtyForm.value,
            storeComment: row.storeComment,
          },
        });
        ref.afterClosed().subscribe((res: RspSpmt10341GetDetailDto) => {
          if (res) {
            row = { ...row, ...res };
            row.orderRequestQtyForm = new FormControl(row.orderRequestQty);
            const index = this.dataSource.data.findIndex((x) => x.orderRequestId === row.orderRequestId);
            this.dataSource.data[index] = row;
            this.dataSource._updateChangeSubscription();
          }
        });
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => {
        this.httpBasic.afterRequestComplete();
      },
    });
  }

  onCtgLevelChange() {
    this.formSearchCondition.get("ctgCd").setValue("");
  }

  /**
   * Updates the select flag of the item based on the event,
   * sets the flag for at least one checked item,
   * and updates the check all flag based on the row disable status.
   *
   * @param {RspSpmt10341SearchDto} item - The item to update.
   * @param {any} event - The event triggering the update.
   */
  onChangeRowCheck(item: RspSpmt10341SearchDto, event: any) {
    item.selectFlg = event.checked;
    this.isLeastOneChecked = this.dataSource.data.some((item) => item.selectFlg);
    this.checkAllFlag =
      this.dataSource.data.length > 0 && this.dataSource.data.filter((item) => !this.fnIsRowDisabled(item)).every((item) => item.selectFlg);
  }

  /**
   * Updates the check all flag and the select flags of all items in the data source based on the event.
   *
   * @param {$event} $event - The event triggering the update.
   * @return {void} This function does not return anything.
   */
  checkAll($event: any): void {
    this.checkAllFlag = $event.checked;

    this.dataSource.data.forEach((item) => {
      if (!this.fnIsRowDisabled(item)) item.selectFlg = $event.checked;
    });

    this.isLeastOneChecked = this.dataSource.data.some((item) => item.selectFlg);
  }

  /**
   * Handles the click event for the sort condition button. Opens a dialog to allow the user to select a sort condition,
   * and updates the formSearchCondition form with the selected sort condition.
   *
   * @return {void} This function does not return anything.
   */
  btnSortConditionClick(): void {
    let data = {
      screenId: "SPMT10341",
      access: this.access,
      sortConfig: this.formSearchCondition.get("sortConfig").value,
    };
    const ref = this.commonService.dialog.open(SortConditionDialogComponent, {
      data: data,
    });
    ref.afterClosed().subscribe((res: RspSortConditionConfigDto[]) => {
      if (res) {
        this.setSortConfigToForm(res);
      }
    });
  }

  /**
   * Executes the approval process by calling the `fnConfirm` function with the specified parameters.
   *
   * @return {void} This function does not return anything.
   */
  btnApproveClick(): void {
    this.fnConfirm(ConfirmKbn.APPROVAL, this.message.message[MQ200008], "承認中・・・", this.message.message[MI200008], "承認");
  }

  /**
   * Confirms the user's action by opening a dialog with the provided data and messages.
   * If the user confirms, it opens a notification dialog with the success message and performs additional actions.
   *
   * @param {ConfirmKbn} confirmKbn - The type of confirmation to perform.
   * @param {string} messageQuestion - The question to ask the user.
   * @param {string} messageLoading - The message to display while loading.
   * @param {string} messageSuccess - The success message to display.
   * @param {string} title - The title of the dialog.
   * @return {void} This function does not return anything.
   */
  private fnConfirm(confirmKbn: ConfirmKbn, messageQuestion: string, messageLoading: string, messageSuccess: string, title: string): void {
    const data = this.dataSource.data
      .map((x, index) => {
        return { index, ...x, orderRequestQty: x.orderRequestQtyForm.value, orderRequestQtyForm: undefined };
      })
      .filter((item) => item.selectFlg);
    let firstErrorRow = data.find((x) => x.orderRequestQty < 1 || x.orderRequestQty > 99999);
    if (confirmKbn != ConfirmKbn.REJECT && firstErrorRow) {
      this.commonService.openErrorDialog(
        this.commonService.pageTitle,
        `レコード番号: ${firstErrorRow.index + 1}<br/>` +
          this.message.message[ME200005]?.replace("%1", "申請数").replace("%2", "1").replace("%3", "99999")
      );
      return;
    }
    const ref = this.commonService.dialog.open(Spmt10341ListSelectDialogComponent, {
      data: {
        data: data,
        confirmKbn: confirmKbn,
        messageQuestion: messageQuestion,
        messageLoading: messageLoading,
        messageSuccess: messageSuccess,
        title: title,
        access: this.access,
      },
    });

    ref.afterClosed().subscribe((res) => {
      if (res) {
        const notificationRef = this.commonService.dialog.open(NotificationDialogComponent, {
          data: { title: this.commonService.pageTitle, message: messageSuccess },
        });
        notificationRef.afterClosed().subscribe(() => {
          this.isLeastOneChecked = false;
          this.keypadTargetId = "";
          this.numberKeypadComponent.setTargetForm(undefined);
          this.checkAllFlag = false;
          this.fnSearch(false);
        });
      }
    });
  }

  btnRejectClick() {
    this.fnConfirm(ConfirmKbn.REJECT, this.message.message[MQ200007], "却下中・・・", this.message.message[MI200009], "却下");
  }

  btnSaveTempClick() {
    this.fnConfirm(ConfirmKbn.SAVE_ONLY, this.message.message[MQ200009], "一時保存中・・・", this.message.message[MI200007], "一時保存");
  }

  btnClearClick() {
    if (this.dataSource.data.length === 0) {
      this.fnClear();
      return;
    }
    const dialogRef = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200001]);
    dialogRef.subscribe((res) => {
      if (res) {
        this.fnClear();
      }
    });
  }

  private fnClear() {
    this.formSearchCondition.patchValue({ ...this.initSearchCondition, orderDate: new Date() });
    this.storeSearchCondition = JSON.parse(JSON.stringify(this.initSearchCondition));
    this.setSortConfigToForm([...this.sortDefault]);
    this.fnClearTable();
  }

  private fnClearTable() {
    this.checkAllFlag = false;
    this.dataSource.data = [];
    this.isLeastOneChecked = false;
    this.keypadTargetId = "";
    this.numberKeypadComponent.setTargetForm(undefined);
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  is10KeyPadTarget(id: string) {
    return id === this.keypadTargetId;
  }

  set10KeyPadTarget(id: string, form: FormControl) {
    if (!this.commonService.config.use10KeyPad) return;
    this.keypadTargetId = id;
    this.numberKeypadComponent.setTargetForm(form);
  }

  onValueChange($event: any) {
    if ($event == "") {
      this.numberKeypadComponent.inputField.setValue(0);
    }
  }

  onChangeRowInputQty(formControl: FormControl) {
    if (formControl.value === "") {
      formControl.setValue(0);
    }
  }

  /**
   * Shows an error message if the input number is not within the specified range.
   *
   * @param {HTMLInputElement} inputNum - The input element containing the number.
   * @param {PositiveNumberOnlyError} $event - The error event containing the minimum and maximum values.
   */
  showErrorMoveQty(inputNum: HTMLInputElement, $event: PositiveNumberOnlyError, formControl: FormControl) {
    if ($event.maxValue) {
      this.fnShowError(inputNum, this.message.message[ME200005]?.replace("%1", "申請数").replace("%2", "1").replace("%3", "99999"));
    }
  }

  /**
   * Displays an error notification dialog with the given error message and sets focus back to the input element.
   *
   * @param {HTMLInputElement} el - The input element to blur and focus back to.
   * @param {string} errorMessage - The error message to display in the notification dialog.
   * @return {void} This function does not return anything.
   */
  private fnShowError(el: HTMLInputElement, errorMessage: string): void {
    el.blur();

    const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
      data: {
        errorMessage: errorMessage,
        errorTitle: this.commonService.pageTitle,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      el.focus();
      el.setSelectionRange(el.value.length, el.value.length);
    });
  }

  displayedColumns: string[] = [
    "selectFlg",
    "productCd",
    "productName",
    "standardName",
    "storeApprovalStatusNm",
    "urgentOrderNm",
    "officeInputNm",
    "orderQty",
    "stockQty",
    "orderRequestQtyForm",
    "requestMsg",
    "storeComment",
  ];

  columnDefs: CustomTableColumnDef[] = [
    {
      columnId: "selectFlg",
      header: "選択",
      width: 40,
      type: "checkbox",
      align: "center",
      isDisable: (row: RspSpmt10341SearchDto) => this.fnIsRowDisabled(row),
      checkbox: true,
    },
    { columnId: "productCd", header: "商品コード", width: 100, type: "link" },
    { columnId: "productName", header: "商品名称", width: 150, type: "normal" },
    { columnId: "standardName", header: "規格", width: 70, type: "normal" },
    { columnId: "storeApprovalStatusNm", header: "ステータス", width: 70, type: "normal", align: "left" },
    { columnId: "urgentOrderNm", header: "緊急発注フラグ", width: 90, type: "normal" },
    { columnId: "officeInputNm", header: "本部発注フラグ", width: 90, type: "normal" },
    { columnId: "orderQty", header: "入数", width: 50, type: "normal", align: "right", pipe: "number" },
    { columnId: "stockQty", header: "在庫数", width: 60, type: "normal", align: "right", pipe: "number" },
    {
      columnId: "orderRequestQtyForm",
      header: "申請数",
      width: 60,
      type: "inputNumber",
      align: "right",
      isDisable: (row: RspSpmt10341SearchDto) => this.fnIsRowDisabled(row),
    },
    { columnId: "requestMsg", header: "メッセージ", width: 150, type: "normal" },
    {
      columnId: "storeComment",
      header: "コメント",
      width: 180,
      type: "inputText",
      isDisable: (row: RspSpmt10341SearchDto) => this.fnIsRowDisabled(row),
    },
  ];
}
