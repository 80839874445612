import { Subscription } from "rxjs";
import { CommonService } from "src/app/service/common.service";
import { OrderGroupUserRec } from "./order-group-user";

export class OrderGroupRec {

  private today:      Date;
  public storeCd:     string      = "";
  public groupId:     number      = 0;
  public groupUsrCd:  string      = "";
  public groupName:   string      = "";
  public dateBegin:   Date        = undefined;
  public dateEnd:     Date        = undefined;
  public users:       OrderGroupUserRec[] = [];
  public userMap:     {} = {};

  public  edit: {
    assignCount:    number;
    users:          OrderGroupUserRec[];
    userMap:        {};
  };

  private subsc:    Subscription[]  = [];
  private changeListener: ()=>void;

  constructor(private commonService: CommonService) {
    let d: Date = new Date();
    this.today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }

  setListner(func:()=>void) {
    this.changeListener = func;
  }

  countAssign() {
    let count = 0;
    if (!this.edit) {
      this.users.forEach((user) => {
        // if (user.hasAssign()) count++;
        if (user.isActive()) count++;
      });
    } else {
      this.edit.users.forEach((user) => {
        // if (user.hasAssign()) count++;
        if (user.isActive()) count++;
      });
    }
    return count;
  }

  updateAssign() {
    if (!this.edit) return;
    this.edit.assignCount = this.countAssign();
  }

  prepareEdit() {
    let d: Date = new Date();
    this.edit = {
      assignCount: this.countAssign(),
      users: [...this.users],
      userMap: {...this.userMap}
    };
    // this.users.forEach((user) => user.prepareEdit());
  }

  addUser(user: OrderGroupUserRec) {
    if (!this.edit) {
      if (this.findUser(user.userId)) return;
      this.users.push(user);
      this.userMap[user.userId] = user;
      return;
    }
    if (this.findUser(user.userId)) return;
    this.edit.users.push(user);
    this.edit.userMap[user.userId] = user;
  }

  findUser(userId: string) {
    if (!this.edit) {
      if (!this.userMap[userId]) return undefined;
      return this.userMap[userId];
    }
    if (!this.edit.userMap[userId]) return undefined;
    return this.edit.userMap[userId];
  }

  getValue(key: string) {
    switch(key) {
      case "dateBegin":
        // if (!this.edit) return this.commonService.formatDate(this.dateBegin);
        // if (this.dateBegin.getTime() <= this.edit.today.getTime()) return "";
        if (this.dateBegin.getTime() <= this.today.getTime()) return "";
        return this.commonService.formatDate(this.dateBegin);
      case "assignCount":
        if (this.edit) return this.edit.assignCount;
        return "";
      default:
        return this[key];
    }
  }

  saveEdit() {
    if (!this.edit) return;
    this.users = [];
    this.userMap = {};

    this.edit.users.forEach((rec) => {
      if (rec.hasAssign()) {
        this.users.push(rec);
        this.userMap[rec.userId] = rec;
      }
    });
  }

  cancelEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  cleanupEdit() {
    this.edit.users.forEach((user) => user.cleanupEdit());
    this.subsc.forEach((subsc) => subsc.unsubscribe());
    this.subsc = [];
    if (this.edit) {
      this.edit.users.forEach((item) => item.cleanupEdit());
      delete this.edit;
    }
  }

  endEdit() {
    this.cleanupEdit();
  }
}
