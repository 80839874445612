import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewChecked } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ItemSearchCondition } from 'src/app/partsCommon/item-select-condition/item-select-condition.component';
import { ItemListComponent } from 'src/app/partsCommon/item-list/item-list.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit, OnDestroy, AfterViewChecked {

 public isReadonly: boolean;
 public itemSearchCondition : ItemSearchCondition = new ItemSearchCondition(this.fb);
 public itemSearchConditionOrigin: ItemSearchCondition = new ItemSearchCondition(this.fbOrigin);
 private itemListContainerElement: HTMLElement;
 private scrollHeight: number;
 private scrollTop: number;

 @ViewChild(ItemListComponent, {static:true}) itemListComponent: ItemListComponent;

 constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private fbOrigin: FormBuilder) {
  }

  ngOnInit() {
    // this.commonService.pageTitle = "発注管理";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("order");

    this.itemSearchCondition.formGroup.get('salesDate').setValue(this.commonService.dateSalesDate);
    this.itemSearchCondition.formGroup.get('salesDate').disable();
    window.addEventListener("resize", this.initScroll);
  }

  ngOnDestroy() {
   window.removeEventListener("resize", this.initScroll);
  }

  ngAfterViewChecked() {
   this.initScroll();
 }

  initScroll() {
   this.itemListContainerElement = document.getElementById("item-list-container");
   var clientRect = this.itemListContainerElement.getBoundingClientRect();
   this.scrollTop = clientRect.top;
   this.scrollHeight = window.innerHeight - this.scrollTop - 45;
   this.itemListContainerElement.style["height"] = "" + this.scrollHeight + "px";
 }
 
  doQuery() {
    this.copyRequest();
    this.itemListComponent.doQuery();
  }

  copyRequest() {
    this.itemSearchConditionOrigin.formGroup = this.itemSearchCondition.cloneAbstractControl(this.itemSearchCondition.formGroup);
    this.itemSearchConditionOrigin.formGroup.get('salesDate').setValue(this.commonService.dateSalesDate);
    this.itemSearchConditionOrigin.formGroup.get('salesDate').disable();
    this.itemSearchConditionOrigin.rackSelectCondition.formGroup.patchValue(this.itemSearchCondition.rackSelectCondition.formGroup.value)
    this.itemSearchConditionOrigin.ctgSelectCondition.formGroup.patchValue(this.itemSearchCondition.ctgSelectCondition.formGroup.value);
  }
}
