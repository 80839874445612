import { RspSpmt10341SearchDto } from "../response/rsp-spmt10341-search";
import { Req } from "./req";

export enum ConfirmKbn {
  APPROVAL = "1",
  REJECT = "98",
  SAVE_ONLY = "1000",
}

export interface ReqSpmt10341ConfirmDto {
  confirmKbn: ConfirmKbn;
  confirmList: RspSpmt10341SearchDto[];
}

export interface ReqSpmt10341Confirm extends Req {
  requestBody: ReqSpmt10341ConfirmDto;
}
