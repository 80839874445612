import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { ItemSelectSimpleCondition } from 'src/app/partsCommon/item-select-simple/item-select-simple.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ItemListSimpleComponent } from 'src/app/partsCommon/item-list-simple/item-list-simple.component';
import { ItemSimpleRec } from 'src/app/common/item-simple-rec';

@Component({
  selector: 'app-item-hist',
  templateUrl: './item-hist.component.html',
  styleUrls: ['./item-hist.component.css']
})
export class ItemHistComponent implements OnInit {

  public itemSelectSimpleCondition: ItemSelectSimpleCondition = new ItemSelectSimpleCondition(this.fb);
  public isReadonly: boolean;

  itemSelected: ItemSimpleRec;
  flagsSelectedItem = false;

  @ViewChild(ItemListSimpleComponent, { static: true }) itemListSimpleComponent: ItemListSimpleComponent;

  constructor(public commonService: CommonService, private fb: FormBuilder) { }

  ngOnInit() {
    // this.commonService.pageTitle = '受払履歴(商品)';
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("itemhist");
  }

  doQueryItemSimple() {
    this.itemListSimpleComponent.doQuery();
  }

  itemChange($event) {
    this.flagsSelectedItem = true;
    this.itemSelected = $event;
  }
}
