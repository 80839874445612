import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { NoisecutHistoryComponent } from 'src/app/partsCommon/noisecut-history/noisecut-history.component';

@Component({
  selector: 'app-item-noisecut-dialog',
  templateUrl: './item-noisecut-dialog.component.html',
  styleUrls: ['./item-noisecut-dialog.component.css']
})
export class ItemNoisecutDialogComponent implements OnInit {

  @ViewChild(NoisecutHistoryComponent, {static: true}) noisecutHistoryComponent: NoisecutHistoryComponent;

  constructor(
    public dialogRef: MatDialogRef<ItemNoisecutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public item: {storeCd: string, itemCd: string, itemName: string},
    public commonService: CommonService) { }

  ngOnInit() {
    // Wait for NoisecutHistoryComponent
    setTimeout(()=>{
      this.noisecutHistoryComponent.getNoisecutHistory(
        this.item.storeCd,
        this.item.itemCd,
        this.item.itemName);
      },
      100
    );
  }

}
