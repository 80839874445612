import { ExecuteQueryRecDto } from '../../response/rsp-execute-query';

export class CameraDef {
	cameraCd:		  string;
	storeCd:		  string;
  storeName:	  string;
  store:        string;
	cameraName:		string;
	url:		      string;
  comment:		  string;
  sizeX:        number;
  sizeY:        number;
  imageUrl?:      any;
  isOpened?:      boolean;
  isLoading?:     boolean;
  timerId?:       any;
  errorMsg?:      string;

  constructor(private camera: ExecuteQueryRecDto) {
    this.cameraCd = camera.colData[0];
    this.storeCd = camera.colData[1];
    this.storeName = camera.colData[2];
    this.cameraName = camera.colData[3];
    this.url = camera.colData[4];
    this.comment = camera.colData[5];
    this.sizeX = parseInt(camera.colData[6]);
    this.sizeY = parseInt(camera.colData[7]);

    this.store = this.storeCd + ":" + this.storeName;

    if (!this.sizeX || this.sizeX == 0) this.sizeX = 640;
    if (!this.sizeY || this.sizeY == 0) this.sizeY = 360;
  }
}

export class CameraLayout {
  layoutCd:     number;
  layoutName:   string;
  comment:      string;
  countX:       number;
  countY:       number;
  config?:      CameraLayoutConfig[];

  constructor(private layout: ExecuteQueryRecDto) {
    this.layoutCd = parseInt(layout.colData[0]);
    this.layoutName = layout.colData[1];
    this.comment = layout.colData[2];
    this.countX = parseInt(layout.colData[3]);
    this.countY = parseInt(layout.colData[4]);
    this.config = [];
  }
}

export class CameraLayoutConfig {
	layoutCd:	      	number;
	cameraCd:		      string;
  posX:             number;
  posY:             number;
  sizeX:            number;
  sizeY:            number;
  camera?:          CameraDef;

  constructor(private config: ExecuteQueryRecDto) {
    this.layoutCd = parseInt(config.colData[0]);
    this.cameraCd = config.colData[1];
    this.posX = parseInt(config.colData[2]);
    this.posY = parseInt(config.colData[3]);
    this.sizeX = parseInt(config.colData[4]);
    this.sizeY = parseInt(config.colData[5]);
    this.camera = null;
  }
}

