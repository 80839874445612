import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DbService, HtmlSelectItem } from 'src/app/service/db.servce';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ReqReplaceHtmlSelectItems, RspReplaceHtmlSelectItems } from 'src/app/webservice/html-select-items';

@Component({
  selector: 'app-html-select-item-edit',
  templateUrl: './html-select-item-edit.component.html',
  styleUrls: ['./html-select-item-edit.component.css']
})
export class HtmlSelectItemEditComponent implements OnInit, OnDestroy {

  public title: string;
  public editTarget: string = "";
  public itemList: HtmlSelectItem[] = [];
  public itemListEdit: HtmlSelectItem[] = [];
  public isDirty: boolean;
  public formAddItem: FormControl = new FormControl("");

  private listName: string = "";
  private storeCd: string = "";
  public  itemNameLabel: string = "";

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    private dbService: DbService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.title = this.commonService.pageTitle;

    let len = this.route.snapshot.url.length;
    if (len > 0) {
      this.editTarget = this.route.snapshot.url[len - 1].path;
    }

    switch(this.editTarget) {
      case "taskCategoryEdit": {
        this.listName = "task:taskCategory";
        this.storeCd = "";
        this.itemNameLabel = "作業分類";
        this.getSelectItems();
        break;
      }
      case "taskAuthorEdit": {
        this.listName = "task:author";
        this.storeCd = "";
        this.itemNameLabel = "指示者";
        this.getSelectItems();
        break;
      }
      default:
        break;
    }

    this.setListHeight();
  }

  ngOnDestroy() {
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setListHeight();
  }

  setListHeight() {
    setTimeout(() => { this.setListHeightBody(); }, 0);
  }

  setListHeightBody() {
    let id = "select-item-drag-list";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight == undefined) return;
    let paginatorHeight = 0;
    let margin = 10 + 10 + 5;
    let btnBox = 24 + 24;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem == undefined) return;
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  getSelectItems() {
    if (!this.commonService.config.task.useTaskCategory) return;
    this.dbService.getSelectItemList(this.listName, this.storeCd, (list) => { this.receiveSelectItems(list); })
  }

  receiveSelectItems(list: HtmlSelectItem[]) {
    this.itemList = list;
    this.itemListEdit = [...this.itemList];
  }

  dropSelectItem(event: CdkDragDrop<HtmlSelectItem[]>) {
    moveItemInArray(this.itemListEdit, event.previousIndex, event.currentIndex);
    this.isDirty = true;
  }

  saveEdit() {
    for (let i = 0; i < this.itemListEdit.length; i++) {
      this.itemListEdit[i].sortKey = i;
    }
    this.itemList = [...this.itemListEdit];

    let request: ReqReplaceHtmlSelectItems = {
      access: this.commonService.loginUser,
      listName:   this.listName,
      storeCd:    this.storeCd,
      list:       this.itemList
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("ReplaceHtmlSelectItems", request).subscribe(
      (response: RspReplaceHtmlSelectItems) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveSaveEdit(response);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );

    this.isDirty = false;
  }

  receiveSaveEdit(response: RspReplaceHtmlSelectItems) {
    if (this.httpBasic.handleAppError(response)) return;

    this.itemList = response.rows;
    this.itemListEdit = [...this.itemList];
  }

  cancelEdit() {
    this.itemListEdit = [...this.itemList];
    this.isDirty = false;
  }

  addItem() {
    this.itemListEdit.push({
      id: -1,
      listName: this.listName,
      storeCd: this.storeCd,
      itemName: this.formAddItem.value,
      sortKey: 0
    });
    this.isDirty = true;
  }

  deleteItem(item: HtmlSelectItem) {
    let index = this.itemListEdit.findIndex((rec) => rec == item);
    if (index < 0) return;
    this.itemListEdit.splice(index, 1);
    this.isDirty = true;
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }
}
