import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ItemSearchCondition } from '../item-select-condition/item-select-condition.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { RspItemDto, RspItemSearch } from 'src/app/response/rsp-item-search';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Subscription } from 'rxjs';
import { ItemRec } from 'src/app/common/item-rec';
import { OrderEditDialogComponent } from 'src/app/dialog/order-edit-dialog/order-edit-dialog.component';
import { StockEditDialogComponent } from 'src/app/dialog/stock-edit-dialog/stock-edit-dialog.component';
import { MatTable } from '@angular/material/table';
import { ParamItemEditDialogComponent } from 'src/app/dialog/param-item-edit-dialog/param-item-edit-dialog.component';
import { ItemOrderStopEditDialogComponent } from 'src/app/dialog/item-order-stop-edit-dialog/item-order-stop-edit-dialog.component';
import { TableItemDialogComponent } from 'src/app/dialog/table-item-dialog/table-item-dialog.component';
import { FormGroup } from '@angular/forms';
import { ReqItemViewSortDto, ReqItemSearch } from 'src/app/request/req-item-search';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css']
})
export class ItemListComponent implements OnInit, OnDestroy {

  public recordCount: number;
  public itemList: ItemRec[] = [];
  public tableWidth: any;
  public pagenatorMaxWidth: any;
  public displayColumnIds: string[];

  public stickyColumnDefs: TableColumnDef[] = [
    {columnId:'itemCdFv', header:"商品コード", width:90, align:"center"},
    {columnId:'itemNameFv', header:"商品名", width:300},
  ];

  public columnDefs: TableColumnDef[] = [
    {columnId:'standardFv', header:"規格", width:80},
    {
      columnId:'orderNumCurrentFn', 
      header: this.commonService?.config?.orderBaraDisplayFlag ? "発注(ロット)数":"発注数", 
      width: this.commonService?.config?.orderBaraDisplayFlag ? 80:40, 
      align: "right"
    },
    {columnId:'orderBaraNumCurrentFn', header: "発注数", width: 40, align: "right"},
    {columnId:"orderLotFn", header:"ロット", width:35, align: "right", colspan: 1},
    {columnId:'orderBaraNumFn', header:"発注(バラ)", width:55, align:"right"},
    {columnId:"orderBacklog", header:"入荷予定", width:60, maxWidth:40, align: "right", colspan: 1},
    {columnId:'stockNumCurrentFn', header:"在庫数", width:40, align:"right"},
    {columnId:'deliveryDate', header:"納品予定日", width:80, align:"center"},
    /*
    {columnId:'costPriceFn', header:"原価", width:60, align:"right"},
    {columnId:'salesPriceFn', header:"売値", width:60, align:"right"},
    */
    {columnId:'suppNameFv', header:"発注先", width:200},
    {columnId:'orderStopFlagCurrentFv', header:"自動発注", width:80, align:"center"},
    {columnId:'minZaiCurrentFv', header:"最低陳列", width:60, align:"right"},
    {columnId:'maxZaiCurrentFv', header:"最大陳列", width:60, align:"right"},
    {columnId:'minStockDaysCurrentFv', header:"最低在庫日数", width:80, align:"right"},
    {columnId:'maxStockDaysCurrentFv', header:"最大在庫日数", width:80, align:"right"},
    {columnId:"itemLocation", header:"棚", width:60, maxWidth:60, align: "left", colspan: 1},
    {columnId:"itemClass", header:"区分", width:40, align: "center", colspan: 1},
    {columnId:"salesResult1", header:"販1", width:20, align: "right", colspan: 1},
    {columnId:"salesResult2", header:"販2", width:20, align: "right", colspan: 1},
    {columnId:"salesResult3", header:"販3", width:20, align: "right", colspan: 1},
    {columnId:"salesResult4", header:"販4", width:20, align: "right", colspan: 1},
    {columnId:"orderResult1", header:"発1", width:20, align: "right", colspan: 1},
    {columnId:"orderResult2", header:"発2", width:20, align: "right", colspan: 1},
    {columnId:"orderResult3", header:"発3", width:20, align: "right", colspan: 1},
    {columnId:"orderResult4", header:"発4", width:20, align: "right", colspan: 1},
    {columnId:'ctg0Fv', header:this.commonService.literal.ctg0Name, width:200},
    {columnId:'ctg1Fv', header:this.commonService.literal.ctg1Name, width:200},
    {columnId:'ctg2Fv', header:this.commonService.literal.ctg2Name, width:200},
    {columnId:'ctg3Fv', header:this.commonService.literal.ctg3Name, width:200}
  ];
  public displayColumnDefs: TableColumnDef[] = [];
  public undisplayColumnDefs: TableColumnDef[] = [];

  private subscriptionSearch : Subscription;
  private subscriptionDialog : Subscription;
  private isQueryRequested : boolean = false;

  @Input() itemSearchCondition: ItemSearchCondition;
  @Input() isOrderEditable: boolean = false;
  @Input() isStockEditable: boolean = false;
  @Input() isParamEditable: boolean = false;
  @Input() isOrderStopEditable: boolean = false;
  @Input() propColumns: string[];
  @ViewChild(MatTable, {static:true}) matTable: MatTable<any>;
  @ViewChild(MatPaginator, {static:true}) matPagenator: MatPaginator;
  
  constructor(public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private dateAdapter : DateAdapter<any>) { }

  ngOnInit() {
    this.matPagenator.length = 0;
    this.matPagenator.pageIndex = 0;
    /*
    this.matPagenator.pageSizeOptions = this.commonService.paginatorOption.pageSizeOptions;
    this.matPagenator.pageSize = this.commonService.paginatorOption.pageSizeOptions[
      this.commonService.paginatorOption.pageSizeIndex
    ];
    */

    var ctgLevel = this.itemSearchCondition.ctgSelectCondition.formGroup.get('ctgLevel').value;

    if (this.commonService.config.itemEditOverMenus) {
      this.isOrderEditable = !this.commonService.checkPrivilegeNoRedirect("order");
      this.isStockEditable = !this.commonService.checkPrivilegeNoRedirect("stock");
      this.isParamEditable = !this.commonService.checkPrivilegeNoRedirect("itemparam");
      this.isOrderStopEditable = !this.commonService.checkPrivilegeNoRedirect("autotrgt");
    }

    for (var colDef of this.columnDefs) {
      if (this.propColumns && !this.propColumns.find((col) => colDef.columnId === col)) continue;
      if (colDef.columnId === "ctg0Fv" && this.commonService.config.maxCtgLevel < 1) continue;
      if (colDef.columnId === "ctg1Fv" && this.commonService.config.maxCtgLevel < 2) continue;
      if (colDef.columnId === "ctg2Fv" && this.commonService.config.maxCtgLevel < 3) continue;
      if (colDef.columnId === "ctg3Fv" && this.commonService.config.maxCtgLevel < 4) continue;

      if (colDef.columnId === "orderNumCurrentFn") colDef.editable = this.isOrderEditable;
      if (colDef.columnId === "stockNumCurrentFn") colDef.editable = this.isStockEditable;

      if (colDef.columnId === "minZaiCurrentFv" && this.isParamEditable) {
        colDef.editable = this.commonService.config.paramItemMinZaiEditable;
      }
      if (colDef.columnId === "maxZaiCurrentFv" && this.isParamEditable) {
        colDef.editable = this.commonService.config.paramItemMaxZaiEditable;
      }
      if (colDef.columnId === "minStockDaysCurrentFv" && this.isParamEditable) {
        colDef.editable = this.commonService.config.paramItemMinStockDaysEditable;
      }
      if (colDef.columnId === "maxStockDaysCurrentFv" && this.isParamEditable) {
        colDef.editable = this.commonService.config.paramItemMaxStockDaysEditable;
      }
      if (colDef.columnId === "orderStopFlagCurrentFv") colDef.editable = this.isOrderStopEditable;

      if(colDef.columnId !== "orderBaraNumCurrentFn" || this.commonService?.config?.orderBaraDisplayFlag){
      	this.displayColumnDefs.push(colDef);
      }
    }
    if (this.propColumns) {
      // Sort propColumns order
      let tmp = this.displayColumnDefs;
      this.displayColumnDefs = [];
      this.propColumns.forEach((colId) => {
        let colDef = tmp.find((def) => def.columnId === colId);
        if (colDef) this.displayColumnDefs.push(colDef);
      });
    }

    this.setColumnId();
    this.calcTableWidth();
  }

  orderEditable(colDef: TableColumnDef, item: ItemRec) {
    if (colDef.columnId != "orderNumCurrentFn") return true;
    if (!this.commonService.getOrderStopFlagCanOrder(item.orderStopFlagFn)) return false;
    var orderDate: Date = new Date(
      +item.orderDate.substring(0, 4),
      +item.orderDate.substring(5, 7) - 1,
      +item.orderDate.substring(8, 10)
    );
    var dayOfWeek = orderDate.getDay();
    if (item.orderableDateFv.substring(dayOfWeek, dayOfWeek+1) === '0') return false;

    return true;
  }

  ngOnDestroy() {
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
    }
    if (this.subscriptionDialog) {
      this.subscriptionDialog.unsubscribe();
    }
  }

  setColumnId() {
    this.displayColumnIds = [];

    for (var colDef of this.stickyColumnDefs) {
      this.displayColumnIds.push(colDef.columnId);
    }
    for (var colDef of this.displayColumnDefs) {
      if (colDef.columnId === "deliveryDate" && this.commonService.config.deliveryDateColumn != true) {
        this.displayColumnDefs = this.displayColumnDefs.filter(column => column.columnId !== 'deliveryDate');
        continue;
      }
      this.displayColumnIds.push(colDef.columnId);
    }
  }

  styleFor(id:string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id:string) {
    for (var colDef of this.columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  calcTableWidth() {
    var width = 1;   // For left border
    for (var colDef of this.stickyColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    for (var colDef of this.displayColumnDefs) {
      if (colDef.columnId === "deliveryDate" && this.commonService.config.deliveryDateColumn != true) {
        this.displayColumnDefs = this.displayColumnDefs.filter(column => column.columnId != 'deliveryDate');
        continue;
      }
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = {"width": "" + width + "px"};
    this.pagenatorMaxWidth = {"max-width": "" + width + "px"}
  }

  pageChanged(pageEvent: PageEvent) {
    if (!this.isQueryRequested) return;
    this.doQueryBody();
  }

  onClickCell(columnId:string, itemRec: ItemRec) {
    if (columnId === "orderNumCurrentFn" && this.isOrderEditable) {
     this.openOrderEditDialog(itemRec);
     return;
    }
    if (columnId === "stockNumCurrentFn" && this.isStockEditable) {
     this.openStockEditDialog(itemRec);
     return;
    }
    if (((columnId === "minZaiCurrentFv" && this.commonService.config.paramItemMinZaiEditable) ||
         (columnId === "maxZaiCurrentFv" && this.commonService.config.paramItemMaxZaiEditable) ||
         (columnId === "minStockDaysCurrentFv" && this.commonService.config.paramItemMinStockDaysEditable) ||
         (columnId === "maxStockDaysCurrentFv" && this.commonService.config.paramItemMaxStockDaysEditable)
        ) && this.isParamEditable) {
      this.openParamItemEditDialog(itemRec);
      return;
     }
     if (columnId === "orderStopFlagCurrentFv" && this.isOrderStopEditable) {
      this.openItemOrderStopEditDialog(itemRec);
      return;
    }
  }

  dialogResult(data: any) {
    this.subscriptionDialog.unsubscribe();
    this.subscriptionDialog = undefined;
    this.matTable.renderRows();
  }

  openOrderEditDialog(itemRec: ItemRec) {
    const dialogRef = this.commonService.dialog.open(OrderEditDialogComponent, {
      disableClose: true,
      data: itemRec,
      maxWidth: "95vw",
      position: {top: "5px"}
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => this.dialogResult(data)
    );
  }

  openStockEditDialog(itemRec: ItemRec) {
    const dialogRef = this.commonService.dialog.open(StockEditDialogComponent, {
      disableClose: true,
      data: itemRec,
      maxWidth: "95vw",
      position: {top: "5px"}
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => this.dialogResult(data)
    );
  }

  openParamItemEditDialog(itemRec: ItemRec) {
    const dialogRef = this.commonService.dialog.open(ParamItemEditDialogComponent, {
      disableClose: true,
      data: itemRec,
      maxWidth: "95vw",
      position: {top: "5px"}
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => this.dialogResult(data)
    );
  }

  openItemOrderStopEditDialog(itemRec: ItemRec) {
    const dialogRef = this.commonService.dialog.open(ItemOrderStopEditDialogComponent, {
      disableClose: true,
      data: itemRec,
      maxWidth: "95vw",
      position: {top: "5px"}
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => this.dialogResult(data)
    );
  }

  selectTableItem() {
    scrollTo(0, 0);
    const dialogRef = this.commonService.dialog.open(TableItemDialogComponent, {
      disableClose: true,
      data: {display: this.displayColumnDefs, nonDisplay: this.undisplayColumnDefs}
    });
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => {
        this.setColumnId();
        this.calcTableWidth();
        this.dialogResult(data);
      }
    );
  }

  doQuery() {
    this.isQueryRequested = true;
    this.matPagenator.pageIndex = 0;
    this.doQueryBody();
  }

  doQueryBody() {
    var itemSearchForm : FormGroup = this.itemSearchCondition.formGroup;
    var categorySearchCondition : FormGroup = this.itemSearchCondition.ctgSelectCondition.formGroup;
    var orderCurrentForm : FormGroup = itemSearchForm.get('orderCurrent') as FormGroup;
    var orderInitialForm : FormGroup = itemSearchForm.get('orderInitial') as FormGroup;
    var stockCurrentForm : FormGroup = itemSearchForm.get('stockCurrent') as FormGroup;
    var stockInitialForm : FormGroup = itemSearchForm.get('stockInitial') as FormGroup;
    var orderStopFlagForm : FormGroup = itemSearchForm.get('orderStopFlagFormGroup') as FormGroup;
    var itemViewSort : FormGroup = itemSearchForm.get('itemViewSort') as FormGroup;

    var request : ReqItemSearch = {
      access: {...this.commonService.loginUser},
      itemSearchCondition : {
        calcTrgtDateFd: this.dateAdapter.format(itemSearchForm.get('salesDate').value, null),
        storeCdFv: itemSearchForm.get('storeCd').value,
        ctgCd0Fv: categorySearchCondition.get('ctgLevel').value >= 0 ?
                    categorySearchCondition.get('ctgCd0').value : "",
        ctgCd1Fv: categorySearchCondition.get('ctgLevel').value >= 1 ?
                    categorySearchCondition.get('ctgCd1').value : "",
        ctgCd2Fv: categorySearchCondition.get('ctgLevel').value >= 2 ?
                    categorySearchCondition.get('ctgCd2').value : "",
        ctgCd3Fv: categorySearchCondition.get('ctgLevel').value >= 3 ?
                    categorySearchCondition.get('ctgCd3').value : "",
        rackCd1Fn: this.itemSearchCondition.rackSelectCondition.getCd1(),
        rackCd2Fn: this.itemSearchCondition.rackSelectCondition.getCd2(),
        rackCd3Fn: this.itemSearchCondition.rackSelectCondition.getCd3(),
        itemCdFv: itemSearchForm.get('itemCode').value,
        itemCdEqualSearch: false,
        itemNameFv: itemSearchForm.get('itemName').value,
        orderCurrentOperator: orderCurrentForm.get('isEnable').value ? orderCurrentForm.get('operatorType').value : 0,
        orderCurrentNum: orderCurrentForm.get('orderNum').value,
        orderInitialOperator: orderInitialForm.get('isEnable').value ? orderInitialForm.get('operatorType').value : 0,
        orderInitialNum: orderInitialForm.get('orderNum').value,
        stockCurrentOperator: stockCurrentForm.get('isEnable').value ? stockCurrentForm.get('operatorType').value : 0,
        stockCurrentNum: stockCurrentForm.get('stockNum').value,
        stockInitialOperator: stockInitialForm.get('isEnable').value ? stockInitialForm.get('operatorType').value : 0,
        stockInitialNum: stockInitialForm.get('stockNum').value,
        itemViewSorts: this.getItemViewSortDto(itemViewSort),
        orderBaraDisplayFlag: this.commonService?.config?.orderBaraDisplayFlag || false
      },
      page: {
        pageNum: this.matPagenator.pageIndex,
        dispNum: this.matPagenator.pageSize
      }
    }

    if (this.commonService.getOrderStopSearchCondList()?.length > 0) {
      request.itemSearchCondition.orderStopFlagList = this.getOrderStopFlagSelectedValues(orderStopFlagForm)
    }

    this.subscriptionSearch = this.httpBasic.itemSearch(request).subscribe(
        data => this.SearchResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        }
      );

    this.commonService.openSpinner("商品検索", "検索中・・・");
  }

  clearProgressState () {
    this.commonService.closeSpinner();
    this.subscriptionSearch.unsubscribe();
    this.subscriptionSearch = undefined;
  }

  SearchResult(data: RspItemSearch) {
    this.clearProgressState();
    var rspItemSearch: RspItemSearch = {...data};

    if (rspItemSearch.fatalError && rspItemSearch.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("商品検索", rspItemSearch.fatalError[0].errMsg);
    }
    if (rspItemSearch.normalError && rspItemSearch.normalError.length > 0) {
      this.commonService.openErrorDialog("商品検索", rspItemSearch.normalError[0].errMsg);
      return;
    }

    this.itemList = [];
    this.recordCount = rspItemSearch.recordCount;

    if (this.recordCount > 0) {
      var rspItemDtos : RspItemDto[] = [...rspItemSearch.rows];
      for (var rspItemDto of rspItemDtos) {
        this.itemList.push(new ItemRec(rspItemDto, this.commonService));
      }
    }
  }

  getOrderStopFlagSelectedValues(orderStopFlagFormGroup: FormGroup): number[] {
    const orderStopFlagList = [...this.commonService.getOrderStopSearchCondList()];
    if (orderStopFlagList?.length <= 0) return [];
    const selectedList: number[] = orderStopFlagList.filter(orderStopFlagItem => {
      return orderStopFlagFormGroup.get(`orderStopFlagItem_${orderStopFlagItem.value}`)?.value === true
    }).map(orderStopFlagItem => orderStopFlagItem?.value);
    return selectedList;
  }

  getItemViewSortDto(itemViewSort: FormGroup): ReqItemViewSortDto[] {
    let res: ReqItemViewSortDto[] = [];
    if (!itemViewSort?.controls) return [];
    for (let groupName in itemViewSort.controls) {
      const fG = itemViewSort?.controls[groupName];
      // if (fG.get('orderType')?.value == '' ||  parseInt(fG.get('orderValue').value) == 0) continue;
      const row: ReqItemViewSortDto = {
        column: groupName,
        orderType: fG.get('orderType')?.value ?? '',
        orderValue: fG.get('orderValue')?.value ? parseInt(fG.get('orderValue').value) : 0
      }
      res.push(row);
    }
    return res;
  }
}
