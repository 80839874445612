import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CommonService } from '../service/common.service';
import { MenuItemDto } from '../webservice/menu';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  private nonAutoOrderPath: string[] = [
    "top",
    "user",
    "taskHq",
    "taskStore",
    "itemExpiryMaster",
    "itemExpiry",
    "orderGroupUserMulti2"
  ];

  constructor(
    private commonService: CommonService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.commonService.loginUser) {
      if (this.commonService.autoOrderStore) return true;
      if (this.commonService.loginUser.stores.length > 1) return true;
      if (this.nonAutoOrderPath.find((path) => path === next.url[0].path) !== undefined) return true;
      let menuItem: MenuItemDto = this.commonService.findMenu(next.url[0].path, next.queryParams);
      let menuName = menuItem != null ? menuItem.menuName : "未定義メニュー";
      this.commonService.openNotificationDialog(menuName, "この機能は、自動発注の対象外店舗では使用できません。");
      this.router.navigate(['top']);
    } else {
      this.commonService.urlAfterLogin = next.url[0].path;
      this.commonService.queryParamsAfterLogin = next.queryParams;
      this.router.navigate(['login']);
      return false;
    }
  }
  
}
