import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqTaskFileOne, TaskAttachmentDto } from 'src/app/webservice/task';
import { TaskRec } from '../0_def/taskRec';
import { TaskStoreRec } from '../0_def/taskStoreRec';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})
export class FileListComponent implements OnInit, OnChanges {
  private _files: TaskAttachmentDto[];
  @Output() fileChanges = new EventEmitter();
  @Input() taskRec: TaskRec|TaskStoreRec;
  @Input() disableDelete: boolean;
  @Input() disableDownLoad: boolean;
  @Input()
  set files(value: TaskAttachmentDto[]) {
    this._files = [...value];
  }

  get files(): TaskAttachmentDto[] {
    return this._files;
  }

  constructor(
    public commonService: CommonService,
    private httpBasic:    HttpBasicService
    ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  deleteFile(file: TaskAttachmentDto) {
    file.attachment = "deleteFile";
    file.md5 = "deleteFile";
    this.fileChanges.emit(this.files);
  }

  previewFile(file: TaskAttachmentDto) { 
     if(!file.attachment){  
      const request: ReqTaskFileOne = {
        taskId: this.taskRec.taskId,
        attachmentId: file.attachmentId,
        access: this.commonService.loginUser
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.taskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            file.attachment = response.attachment;
            file.attachmentFilename = response.attachmentFilename;
            await this.receivePreviewFile(file.attachment, file.attachmentFilename);     
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {  
      this.receivePreviewFile(file.attachment, file.attachmentFilename);
    }
  }

  async receivePreviewFile(attachment: string, filename: string) {
    let mimeType;
    let base64 = attachment.split(';base64,')[1];
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'jpeg': mimeType = 'image/jpeg'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'svg': mimeType = 'image/svg+xml'; break;
      case 'tif': mimeType = 'image/tiff'; break;
      case 'tiff': mimeType = 'image/tiff'; break;

      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'mp4': mimeType = 'video/mp4'; break;
      case 'mpeg': mimeType = 'video/mpeg'; break;
      
      case 'doc': mimeType = 'application/msword'; break;
      case 'xls': mimeType = 'application/msexcel'; break;
      case 'ppt': mimeType = 'application/ms-powerpoint'; break;

      case 'vsd': mimeType = 'application/vnd.visio'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
   
      case 'pdf': mimeType = 'application/pdf'; break;
      case 'xml': mimeType = 'application/xml'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;

      default: mimeType ='NoSupport';
    }  
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      if (mimeType == "NoSupport") {
        this.commonService.openNotificationDialog(this.commonService.pageTitle, `サポートされていないファイル形式`);
      }else {
        var iframe = "<iframe src='" + url + "' frameborder='0' style='border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;' allowfullscreen></iframe>";
        var openTab = window.open();
        openTab.document.open();
        openTab.document.write(iframe);
        openTab.document.title = filename; 
        openTab.document.close();
      }   
    });
  }

  emitChangeValue(file: TaskAttachmentDto) {
    if(!file.attachment) {
      const request: ReqTaskFileOne = {
        taskId: this.taskRec.taskId,
        attachmentId: file.attachmentId,
        access: this.commonService.loginUser
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.taskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            file.attachment = response.attachment;
            file.attachmentFilename = response.attachmentFilename;
            this.fileChanges.emit(this.files);
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    }
  }

  changeTitle(file: TaskAttachmentDto) {
    if(file.attachmentTitle == '') {
      file.attachmentTitle = file.attachmentFilename;
    }
  }

  downLoadFile(event: TaskAttachmentDto) {
    if(!event.attachment){ 
      const request: ReqTaskFileOne = {
        taskId: this.taskRec.taskId,
        attachmentId: event.attachmentId,
        access: this.commonService.loginUser
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.taskRecFileSearch(request).subscribe(
        async (response) => {
          if(response){
            this.commonService.closeSpinner();
            event.attachment = response.attachment;
            event.attachmentFilename = response.attachmentFilename;
            await this.receiveDownloadFile(event.attachment, event.attachmentFilename);   
          }
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    }else {
      this.receiveDownloadFile(event.attachment, event.attachmentFilename);
    }
    
  }  

  async receiveDownloadFile(attachment: string, filename: string) {
    let base64 = attachment.split(';base64,')[1];
    let mimeType;
    let ext = this.commonService.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg': mimeType = 'image/JPG'; break;
      case 'gif': mimeType = 'image/GIF'; break;
      case 'bmp': mimeType = 'image/BMP'; break;
      case 'png': mimeType = 'image/PNG'; break;
      case 'pdf': mimeType = 'application/pdf'; break;
      case 'log': mimeType = 'text/plain'; break;
      case 'txt': mimeType = 'text/plain'; break;
      case 'doc': mimeType = 'application/msword'; break;
      case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
      case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'csv': mimeType = 'text/csv'; break;
      case 'json': mimeType = 'application/json'; break;
      case 'sql': mimeType = 'text/plain'; break;
      default: mimeType ='NoSupport';
    }
    await this.commonService.b64ToBlob(base64, mimeType).then((blob: Blob) => {
      this.commonService.downloadBlob(blob, filename);
    });
  }

  getTaskFileName(event: TaskAttachmentDto) {
    return event.attachmentFilename;
  }
}
