import { FormControl, Validators } from "@angular/forms";
import { ItemSimDto } from './promSimDef';

export class PromSimItem {

  public delFlag: boolean = false;
  public formChecked: FormControl = new FormControl();
  public formEditCost: FormControl = new FormControl();
  public formEditSell: FormControl = new FormControl();
  public isNewFlag: boolean = false;
  public storeGroupTypeCdFv: number = -1;
  public storeGroupCdFv: number = -1;
  public ctg1CdFv: string = "";
  public ctg1NameFv: string = "";
  public ctg2CdFv: string = "";
  public ctg2NameFv: string = "";
  public ctg3CdFv: string = "";
  public ctg3NameFv: string = "";
  public ctg4CdFv: string = "";
  public ctg4NameFv: string = "";
  public editCost: number = 0;
  public editSell: number = 0;
  public estSaleNum: number = 0;
  public estSalePrice: number = 0;
  public estSaleProfit: number = 0;
  public estSaleRate: number = 0;
  public itemCdFv: string = "";
  public itemNameFv: string = "";
  public pastCost: number = 0;
  public pastSale: number = 0;
  public pastSell: number = 0;
  public standardFv: string = "";
  public promResultCopyFn: number = 0;
  public ctg0Fv: string = "";
  public ctg1Fv: string = "";
  public ctg2Fv: string = "";
  public ctg3Fv: string = "";
  public originDto: ItemSimDto;
  constructor(dto?: ItemSimDto, isNewFlag?: boolean, newIndex?: number) {
    if (dto)
      this.patchValue(dto);
    if (!isNewFlag || newIndex < 0) return;
    this.isNewFlag = isNewFlag;
  }

  patchValue(obj: ItemSimDto): void {
    for (let key in obj) {
      if (Object.keys(this).find((recKey) => recKey === key)) {
        this[key] = obj[key];
      }
    }
    this.ctg0Fv = this?.ctg1CdFv + ' - ' + this?.ctg1NameFv;
    this.ctg1Fv = this?.ctg2CdFv + ' - ' + this?.ctg2NameFv;
    this.ctg2Fv = this?.ctg3CdFv + ' - ' + this?.ctg3NameFv;
    this.ctg3Fv = this?.ctg4CdFv + ' - ' + this?.ctg4NameFv;
    this.formChecked.setValue(false);
    this.formEditCost.setValue(this?.editCost.toFixed(2) ?? 0);
    this.formEditSell.setValue(this?.editSell.toFixed(2) ?? 0);
    this.originDto = { ...obj }
  }

  isDateValid(input: string | Date): boolean {
    if (!input) return false;
    if (typeof input == 'string') {
      if (input.trim() == '') return false;
      const date = new Date(input);
      return (date instanceof Date && !isNaN(date.valueOf()));
    }
    return (input instanceof Date && !isNaN(input.valueOf()));
  }
}
