import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ItemRec } from 'src/app/common/item-rec';
import { ReqOrderStopItemEdit } from 'src/app/request/req-order-stop-item-edit';
import { RspOrderStopItemEdit, OrderStopItemResultDto } from 'src/app/response/rsp-order-stop-item-edit';

@Component({
  selector: 'app-item-order-stop-edit-dialog',
  templateUrl: './item-order-stop-edit-dialog.component.html',
  styleUrls: ['./item-order-stop-edit-dialog.component.css']
})
export class ItemOrderStopEditDialogComponent implements OnInit, OnDestroy {

  public orderStopForm : FormControl;
  public orderStopList: {value: number, name: string, canOrder: boolean, changeable:boolean}[];
  public orderStopInitial: string;
  public errorMessage : string;

  private subscriptionUpdate : Subscription;

  constructor(
    private dialogRef: MatDialogRef<ItemOrderStopEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public itemRec: ItemRec,
    private httpBasic: HttpBasicService,
    private commonService: CommonService) { }

  ngOnInit() {
    var level: number;
    if (this.itemRec.ctgCd0Fv && this.itemRec.ctgCd0Fv !== "") level = 0;
    if (this.itemRec.ctgCd1Fv && this.itemRec.ctgCd1Fv !== "") level = 1;
    if (this.itemRec.ctgCd2Fv && this.itemRec.ctgCd2Fv !== "") level = 2;
    if (this.itemRec.ctgCd3Fv && this.itemRec.ctgCd3Fv !== "") level = 3;

    this.orderStopForm = new FormControl(
      "" + this.itemRec.orderStopFlagCurrentFn,
      (fc) => this.valueCheck(fc as FormControl)
    );

    this.orderStopInitial = this.commonService.getOrderStopFlagName(this.itemRec.orderStopFlagFn);
    if (!this.commonService.getOrderStopFlagChangeable(this.itemRec.orderStopFlagFn)) {
      this.orderStopList = this.commonService.config.orderStopFlag;
      this.errorMessage = "この商品の自動発注種別の選択は許可されていません。"
      this.orderStopForm.disable();
    } else {
      this.orderStopList = this.commonService.getOrderStopSelectList();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionUpdate) {
      this.subscriptionUpdate.unsubscribe();
    }
  }

  valueCheck(control: FormControl) {
    if (parseInt(control.value) == this.itemRec.orderStopFlagCurrentFn) {
      return {"NoCahnge": "値が変更されていません。"};
    } else {
      return null;
    }
  }

  doUpdate() {
    var request : ReqOrderStopItemEdit = {
      access: {...this.commonService.loginUser},
      orderStopFlag : {
        storeCdFv: this.itemRec.storeCdFv,
        itemCdFv: this.itemRec.itemCdFv,
        orderStopFlagFn: this.itemRec.orderStopFlagFn,
        orderStopFlagModFn: parseInt(this.orderStopForm.value),
        orderStopFlagVersionFn: this.itemRec.orderStopFlagVersionFn
      }
    }
    this.subscriptionUpdate = this.httpBasic.updateItemOrderStop(request).subscribe(
      data => this.updateResult(data),
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    );

    this.commonService.openSpinner("自動発注種別", "更新中・・・");
  }

  clearProgressState () {
    this.commonService.closeSpinner();
    this.subscriptionUpdate.unsubscribe();
    this.subscriptionUpdate = undefined;
  }

  updateResult(rspOrderStopItemEdit: RspOrderStopItemEdit) {
    this.clearProgressState();

    if (rspOrderStopItemEdit.fatalError && rspOrderStopItemEdit.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("自動発注対象", rspOrderStopItemEdit.fatalError[0].errMsg);
      return;
    }
    if (rspOrderStopItemEdit.normalError && rspOrderStopItemEdit.normalError.length > 0) {
      this.errorMessage = rspOrderStopItemEdit.normalError[0].errMsg;
      return;
    }

    var orderStopItemResultDto: OrderStopItemResultDto;
    orderStopItemResultDto = rspOrderStopItemEdit.result;
    this.itemRec.setOrderStopFlag(
      orderStopItemResultDto.orderStopFlagModFn,
      orderStopItemResultDto.orderStopFlagVersionFn
    );

    this.dialogRef.close();
  }
}
