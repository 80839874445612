import { isDataSource } from "@angular/cdk/collections";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/service/common.service";
import { UpdateOrderGroupUserMultiDto } from "src/app/webservice/order-group-user-multi";
import { OrderGroupUser2Rec } from "./order-group-user";

export class OrderGroup2Rec {

  public today:          Date;
  public storeCd:         string      = "";
  public groupId:         number      = 0;
  public groupUsrCd:      string      = "";
  public groupName:       string      = "";
  public dateBegin:       Date        = undefined;
  public dateEnd:         Date        = undefined;
  public activeDateBegin: string      = "";
  public activeUsers:     OrderGroupUser2Rec[] = [];
  public nextDateBegin:   Date        = undefined;
  public nextUsers:       OrderGroupUser2Rec[] = [];
  public userMap:         {} = {};
  public minNextDateBegin:  Date      = undefined;

  public  edit: {
    isDirty:        boolean;
    dateBegin:      FormControl;
    userIds:        FormControl[];
  };

  private subsc:    Subscription[]  = [];
  private changeListener: ()=>void;

  constructor(private commonService: CommonService) {
    let d: Date = new Date();
    this.today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }

  setListner(func:()=>void) {
    this.changeListener = func;
  }

  prepareEdit() {
    if (this.dateBegin.getTime() > this.today.getTime()) {
      this.minNextDateBegin = this.commonService.copyDate(this.dateBegin);
    } else {
      this.minNextDateBegin = this.commonService.copyDate(this.today);
    }
    let actBegin = undefined;
    this.activeUsers.forEach((user) => {
      if (!actBegin) {
        actBegin = user.dateBegin;
      } else {
        if (actBegin.getTime() < user.dateBegin.getTime()) actBegin = user.dateBegin;
      }
    });
    if (actBegin) this.activeDateBegin = this.commonService.formatDate(actBegin);

    this.nextUsers.forEach((user) => {
      if (!this.nextDateBegin) {
        this.nextDateBegin = user.dateBegin;
      } else {
        if (this.nextDateBegin.getTime() > user.dateBegin.getTime()) this.nextDateBegin = user.dateBegin;
      }
    });

    this.edit = {
      isDirty: false,
      dateBegin: new FormControl(),
      userIds: []
    };
    if (this.nextDateBegin) {
      this.edit.dateBegin.setValue(this.commonService.copyDate(this.nextDateBegin));
    }
    for (let i = 0; i < this.commonService.config.orderGroup.maxUser; i++) {
      let form = new FormControl("");
      if (i < this.nextUsers.length) form.setValue(this.nextUsers[i].userId);
      this.edit.userIds.push(form);
      this.subsc.push(
        form.valueChanges.subscribe((val) => {this.userChanged(val);})
      );
    }
    this.formEnable();
  }

  addUser(user: OrderGroupUser2Rec) {
    if (this.activeUsers.length < this.commonService.config.orderGroup.maxUser && user.isActive()) {
      this.activeUsers.push(user);
    } else {
      if (!user.isEnded()) {
        this.nextUsers.push(user);
      }
    }
  }

  /*
  findUser(userId: string) {
    if (!this.edit) {
      if (!this.userMap[userId]) return undefined;
      return this.userMap[userId];
    }
    if (!this.edit.userMap[userId]) return undefined;
    return this.edit.userMap[userId];
  }
  */

  getValue(key: string) {
    if (key.startsWith("actUser")) {
      let i = parseInt(key.replace("actUser", "")) - 1;
      if (i < this.activeUsers.length) {
        let user = this.activeUsers[i];
        return user.userName + "[" + user.userId + "]";
      }
      return "";
    }
    switch(key) {
      case "dateBegin":
        if (this.dateBegin.getTime() <= this.today.getTime()) return "";
        return this.commonService.formatDate(this.dateBegin);
      case "actDateBegin":
        return this.activeDateBegin;
      default:
        return this[key];
    }
  }

  getForm(key: string) {
    if (key == "nextDateBegin") return this.edit.dateBegin;
    if (key.startsWith("nextUser")) {
      let i = parseInt(key.replace("nextUser", "")) - 1;
      if (i < this.edit.userIds.length) return this.edit.userIds[i];
    }
    return new FormControl("");
  }

  dateClear() {
    this.edit.dateBegin.setValue(undefined);
    this.edit.userIds.forEach((rec) => {rec.setValue("");});

    this.dateBeginChanged(undefined);
  }

  dateBeginChanged(val) {
    this.setDirty(true);
    this.formEnable();
  }

  formEnable() {
    if (this.edit.dateBegin.value) {
      this.edit.userIds.forEach((form) => {form.enable({emitEvent: false});});
    } else {
      this.edit.userIds.forEach((form) => {form.disable({emitEvent: false});});
    }
  }

  userChanged(val) {
    if (!this.edit) return;
    this.setDirty(true);
  }

  setDirty(mode: boolean) {
    this.edit.isDirty = mode;
    if (this.changeListener) this.changeListener();
    if (this.changeListener) this.changeListener();
  }

  isNextEmpty() {
    let r = true;
    for (let i = 0; i < this.edit.userIds.length; i++) {
      if (this.edit.userIds[i].value !== "") return false;
    }
    return true;
  }

  saveEdit() {
    if (!this.edit) return [];
    if (!this.edit.isDirty) return [];

    for (let i = 0; i < this.commonService.config.orderGroup.maxUser; i++) {
      let userId1 = this.edit.userIds[i].value;
      if (userId1 === "") continue;
      for (let j = i + 1; j < this.commonService.config.orderGroup.maxUser; j++) {
        let userId2 = this.edit.userIds[j].value;
        if (userId2 === "") continue;
        if (userId1 === userId2) this.edit.userIds[j].setValue("");
      }
    }

    let saveRecs: UpdateOrderGroupUserMultiDto[] = [];
    if(!this.edit.dateBegin.value || this.isNextEmpty()) {
      this.nextUsers.forEach((user) => {
        let dto: UpdateOrderGroupUserMultiDto = {
          storeCd: this.storeCd,
          groupId: this.groupId,
          userId: user.userId,
          dateBegin: this.commonService.formatDate(user.dateBegin),
          dateEnd: this.commonService.formatDate(user.dateEnd),
          isDelete: true
        };
        saveRecs.push(dto);
      });
      this.activeUsers.forEach((user) => {
        if (this.commonService.formatDate(user.dateEnd) !== "2099/12/31") {
          let dto: UpdateOrderGroupUserMultiDto = {
            storeCd: this.storeCd,
            groupId: this.groupId,
            userId: user.userId,
            dateBegin: this.commonService.formatDate(user.dateBegin),
            dateEnd: "2099/12/31",
            isDelete: false
          };
          saveRecs.push(dto);
        }
      });
      return saveRecs;
    }

    this.nextUsers.forEach((user) => {
      let dto: UpdateOrderGroupUserMultiDto = {
        storeCd: this.storeCd,
        groupId: this.groupId,
        userId: user.userId,
        dateBegin: this.commonService.formatDate(user.dateBegin),
        dateEnd: this.commonService.formatDate(user.dateEnd),
        isDelete: true
      };
      saveRecs.push(dto);
    });

    let d: Date = this.commonService.copyDate(this.edit.dateBegin.value);
    d.setDate(d.getDate() - 1);
    let endDate = this.commonService.formatDate(d);
    let dateBegin = this.commonService.formatDate(this.edit.dateBegin.value);
    this.activeUsers.forEach((user) => {
      if (this.commonService.formatDate(user.dateEnd) !== endDate) {
        let dto: UpdateOrderGroupUserMultiDto = {
          storeCd: this.storeCd,
          groupId: this.groupId,
          userId: user.userId,
          dateBegin: this.commonService.formatDate(user.dateBegin),
          dateEnd: endDate,
          isDelete: false
        };
        saveRecs.push(dto);
      }
    });
    for (let i = 0; i < this.edit.userIds.length; i++) {
      let userId = this.edit.userIds[i].value;
      if (userId === "") continue;
      let dto: UpdateOrderGroupUserMultiDto = {
        storeCd: this.storeCd,
        groupId: this.groupId,
        userId: userId,
        dateBegin: dateBegin,
        dateEnd: "2099/12/31",
        isDelete: false
      };
      saveRecs.push(dto);
    }

    return saveRecs;
  }

  findNextUser(userId: string) {
    this.edit.userIds.find((idForm) => {return idForm.value === userId})
  }

  cancelEdit() {
    this.cleanupEdit();
    this.prepareEdit();
  }

  cleanupEdit() {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
    this.subsc = [];
    if (this.edit) {
      delete this.edit;
    }
  }

  endEdit() {
    this.cleanupEdit();
  }
}
