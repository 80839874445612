import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { ItemRec } from 'src/app/common/item-rec';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

@Component({
  selector: 'app-item-summary-dialog',
  templateUrl: './item-summary-dialog.component.html',
  styleUrls: ['./item-summary-dialog.component.css']
})
export class ItemSummaryDialogComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<ItemSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public itemRec: ItemRec,
    public httpBasic: HttpBasicService,
    public commonService: CommonService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
