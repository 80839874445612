import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CtgSelectComponent, CtgSelectCondition } from 'src/app/partsCommon/ctg-select/ctg-select.component';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { CommonService } from 'src/app/service/common.service';
import { CtgService } from 'src/app/service/ctgService';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqGetItemExpiryMaster, ReqSaveItemExpiryMaster, RspGetItemExpiryMaster, SaveItemExpiryMasterDto } from 'src/app/webservice/item-expiry';
import { ExpiryMasterRec } from '../0_def/expiry-master-rec';

@Component({
  selector: 'app-item-expiry-master',
  templateUrl: './item-expiry-master.component.html',
  styleUrls: ['./item-expiry-master.component.css']
})
export class ItemExpiryMasterComponent implements OnInit, OnDestroy {

  public ctgSelectCondition: CtgSelectCondition = new CtgSelectCondition(this.fb);
  public storeForm: FormControl = new FormControl();
  // public keypadForm: FormControl = new FormControl(this.commonService.config.use10KeyPad);
  public keypadForm: FormControl = new FormControl(false);
  public showForm: FormControl = new FormControl(true);
  // public showNewForm: FormControl = new FormControl(true);
  public showNoneForm: FormControl = new FormControl(false);
  public listSave: SaveItemExpiryMasterDto[] = [];
  public isUniformity: boolean = true;
  public maxClgLevel: number = this.commonService.config.maxCtgLevel;

  public expiryRecs: ExpiryMasterRec[];
  public expiryRecTrees: ExpiryMasterRec[];
  public expiryFilterdRecs: ExpiryMasterRec[];
  public recordCount: number = 0;
  public expiryShowRecs: ExpiryMasterRec[] = [];
  public selectedExpiryRec: ExpiryMasterRec;
  public isDirty: boolean = false;
  public columnIds: string[] = ["openClose", "delCheck"];
  public ctgColumnDefs: TableColumnDef[] = [
    /*
    {columnId: "ctg0", header: this.commonService.literal.ctg0Name, width: 100, align: "left", rowTooltip: true},
    {columnId: "ctg1", header: this.commonService.literal.ctg1Name, width: 100, align: "left", rowTooltip: true},
    {columnId: "ctg2", header: this.commonService.literal.ctg2Name, width: 130, align: "left", rowTooltip: true},
    {columnId: "ctg3", header: this.commonService.literal.ctg3Name, width: 140, align: "left", rowTooltip: true}
    */
    {columnId: "ctg0", header: "カテゴリ", width: 200, align: "left", rowTooltip: true}
  ]
  /*
  public dateDefColumnDefs: TableColumnDef[] = [
    {columnId: "date0", header: "日数1", width: 50, align: "center"},
    {columnId: "date1", header: "日数2", width: 50, align: "center"},
    {columnId: "date2", header: "日数3", width: 50, align: "center"},
    {columnId: "date3", header: "日数4", width: 50, align: "center"},
    {columnId: "date4", header: "日数5", width: 50, align: "center"}
  ]
  public actionDefColumnDefs: TableColumnDef[] = [
    {columnId: "action0", header: "対応1", width: 140, align: "center"},
    {columnId: "action1", header: "対応2", width: 140, align: "center"},
    {columnId: "action2", header: "対応3", width: 140, align: "center"},
    {columnId: "action3", header: "対応4", width: 140, align: "center"},
    {columnId: "action4", header: "対応5", width: 140, align: "center"}
  ]
  */
  public dateActionColumnDefs: TableColumnDef[] = [
    {columnId: "dateAction0", header: "設定1", width: 190, align: "center"},
    {columnId: "dateAction1", header: "設定2", width: 190, align: "center"},
    {columnId: "dateAction2", header: "設定3", width: 190, align: "center"},
    {columnId: "dateAction3", header: "設定4", width: 190, align: "center"},
    {columnId: "dateAction4", header: "設定5", width: 190, align: "center"}
  ]

  private defaultShowLevel: number;
  private ctgSelectConditionQueried: CtgSelectCondition;
  // private subscChanges: Subscription[] = [];
  private subscStore: Subscription;

  @ViewChild(CtgSelectComponent, { static: false }) ctgSelectComponent: CtgSelectComponent;
  @ViewChild(NumberKeypadComponent, { static: false }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild(MatTable, {static:false}) matTable: MatTable<any>;
  @ViewChild(MatPaginator, {static:false}) matPagenator: MatPaginator;

  constructor(
    public commonService: CommonService,
    public ctgService: CtgService,
    private httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    /*
    if (this.commonService.loginUser.stores.length > 1) {
      this.storeForm.setValue("");
      this.ctgSelectCondition.storeCd = "";
    } else {
      this.storeForm.setValue(this.commonService.loginUser.storeCd)
      this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    }
    */
    this.storeForm.setValue(this.commonService.loginUser.storeCd);
    this.ctgSelectCondition.storeCd = this.commonService.loginUser.storeCd;
    this.subscStore = this.storeForm.valueChanges.subscribe((value) => {this.storeChanged(value);});

    /*
    let ctgs = [];
    if (this.commonService.config.maxCtgLevel >= 1) ctgs.push("ctg0");
    if (this.commonService.config.maxCtgLevel >= 2) ctgs.push("ctg1");
    if (this.commonService.config.maxCtgLevel >= 3) ctgs.push("ctg2");
    if (this.commonService.config.maxCtgLevel >= 4) ctgs.push("ctg3");
    */
    /*
    this.columnIds = [
      "openClose", "delCheck", ...ctgs,
      "date0", "action0",
      "date1", "action1",
      "date2", "action2",
      "date3", "action3",
      "date4", "action4"
    ];
    */
    this.columnIds = [
      "openClose", "delCheck", "ctg0",           // ...ctgs,
      "expiryType", "discountType",
      "dateAction0", "dateAction1", "dateAction2", "dateAction3", "dateAction4"
    ];
    this.setTableHeight();
  }

  ngOnDestroy(): void {
    // this.subscChanges.forEach((subsc) => {subsc.unsubscribe();});
    this.subscStore?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setTableHeight();
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "expiry-rec-list";
    let remHeight = this.commonService.getHeightBelow(id);
    let paginatorHeight = 56;
    let margin = 10 + 5 + 5;
    // let itemBox = 24 + 24 + 5;
    let btnBox = 24;
    let height = remHeight - paginatorHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.maxHeight = "" + height + "px";
  }

  filterRecs() {
    this.expiryFilterdRecs = [];
    this.filterRecsRecursBody(this.expiryRecTrees);
    this.recordCount = this.expiryFilterdRecs.length;
  }

  filterRecsRecursBody(recs: ExpiryMasterRec[]) {
    if (!recs) return;
    if (recs.length == 0) return;

    for (let i = 0; i < recs.length; i++) {
      let rec = recs[i];
      /*
      let isShow = true;
      if (!this.showForm.value && rec.hasDef()) {
        isShow = false;
      } else if (!this.showNoneForm.value && !rec.hasDef()) {
        isShow = false;
      }
      */
      let isShow = false;
      if (this.showForm.value === true && rec.hasDef()) isShow = true;
      if (this.showNoneForm.value === true && !rec.hasDef()) isShow = true;

      if (isShow) this.expiryFilterdRecs.push(rec);
      if (rec.edit.isOpen) this.filterRecsRecursBody(rec.children);
    }
  }

  reDisplay() {
    this.expiryRecs?.forEach((rec) => {
      if (rec.ctgLevel <= this.defaultShowLevel) {
        rec.edit.isOpen = true;
      } else {
        rec.edit.isOpen = false;
      }
    });
    this.filterRecs();

    if (this.matPagenator) {
      while (this.matPagenator.pageIndex * this.matPagenator.pageSize > this.recordCount) {
        this.matPagenator.pageIndex--;
      }
    }
    this.set10KeyPadTarget(undefined);
    this.pageChanged(null);
  }


  setDirty() {
    this.isDirty = true;
    this.uniformity();
    this.disableStore();
    this.ctgSelectComponent.disableAll();
  }

  enableStore() {
    this.subscStore?.unsubscribe();
    this.storeForm.enable();
    this.subscStore = this.storeForm.valueChanges.subscribe((value) => {this.storeChanged(value);});
  }

  disableStore() {
    this.subscStore?.unsubscribe();
    this.storeForm.disable();
  }

  storeChanged(value) {
    if (this.storeForm.disabled) return;
    this.ctgSelectComponent.setStoreCd(value);
    this.recordCount = 0;
    this.expiryShowRecs = undefined;
  }

  queryExpiry() {
    this.ctgSelectConditionQueried = new CtgSelectCondition(this.fb);
    this.ctgSelectCondition.copyTo(this.ctgSelectConditionQueried);

    let request: ReqGetItemExpiryMaster = {
      maxCtgLevel: this.maxClgLevel,
      access:   this.commonService.loginUser,
      ...this.ctgSelectCondition.getCurrentSelection()
    }
    this.defaultShowLevel = request.ctgLevel;

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetItemExpiryMaster", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveExpiry(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveExpiry(response: RspGetItemExpiryMaster) {
    if (this.httpBasic.handleAppError(response)) return;

    this.expiryRecs = [];
    this.expiryRecTrees = [];
    this.selectedExpiryRec = undefined;
    this.set10KeyPadTarget(undefined);
    let currentRec: ExpiryMasterRec;
    response.rows.forEach((dto) => {
      let rec = new ExpiryMasterRec(this.commonService);
      rec.storeCd = dto.storeCd;
      rec.ctgLevel = dto.ctgLevel;
      rec.ctgCds = [dto.ctgCd0, dto.ctgCd1, dto.ctgCd2, dto.ctgCd3];
      rec.ctgNames = [dto.ctgName0, dto.ctgName1, dto.ctgName2, dto.ctgName3];
      rec.expiryType = dto.expiryType;
      rec.discountType = dto.discountType;
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry0, action: dto.action0, discount: dto.discountRate0 ?? 0});
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry1, action: dto.action1, discount: dto.discountRate1 ?? 0});
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry2, action: dto.action2, discount: dto.discountRate2 ?? 0});
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry3, action: dto.action3, discount: dto.discountRate3 ?? 0});
      rec.dateDef.push({datesToExpiry: dto.datesToExpiry4, action: dto.action4, discount: dto.discountRate4 ?? 0});
      rec.prepareEdit(this.defaultShowLevel);

      rec.setListner(() => {this.setDirty()});
      this.expiryRecs.push(rec);
      if (currentRec) {
        if (!currentRec.addChild(rec)) {
          this.expiryRecTrees.push(rec);
          currentRec = rec;
        }
      } else {
        this.expiryRecTrees.push(rec);
        currentRec = rec;
      }
    });
    this.filterRecs();
    if (this.matPagenator) this.matPagenator.pageIndex = 0;
    this.pageChanged(null);
  }

  pageChanged(event: PageEvent) {
    let start: number;
    let end: number;
    if (this.matPagenator) {
      start = this.matPagenator.pageIndex * this.matPagenator.pageSize;
      end = start + this.matPagenator.pageSize;
    } else {
      start = 0;
      end = this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex];
    }
    if (end > this.recordCount) end = this.recordCount;

    this.expiryShowRecs = this.expiryFilterdRecs?.slice(start, end);
    document.getElementById("expiry-rec-list")?.scrollTo(0, 0);
  }

  clickRow(row: ExpiryMasterRec) {
    this.selectedExpiryRec = row;
  }

  switchOpenClose(rec: ExpiryMasterRec) {
    if (!rec.edit) return;
    if (rec.edit.isOpen) {
      this.closeChild(rec);
    } else {
      this.openChild(rec);
    }
  }

  openChild(rec: ExpiryMasterRec) {
    if (!rec.edit) return;
    if (rec.edit.isOpen) return;
    this.set10KeyPadTarget(undefined);
    rec.edit.isOpen = true;
    if (rec.children.length == 0) return;

    this.filterRecs();
    while (this.matPagenator.pageIndex * this.matPagenator.pageSize > this.recordCount) {
      this.matPagenator.pageIndex--;
    }
    this.pageChanged(null);
  }

  closeChild(rec: ExpiryMasterRec) {
    if (!rec.edit) return;
    if (!rec.edit.isOpen) return;
    this.set10KeyPadTarget(undefined);
    rec.edit.isOpen = false;
    if (rec.children.length == 0) return;

    this.filterRecs();
    while (this.matPagenator.pageIndex * this.matPagenator.pageSize > this.recordCount) {
      this.matPagenator.pageIndex--;
    }
    this.pageChanged(null);
  }

  set10KeyPadTarget(form: FormControl) {
    if (!this.keypadForm.value) return;
    // if (this.numberKeypadComponent) this.numberKeypadComponent.inputField = form;
    if (this.numberKeypadComponent) this.numberKeypadComponent.setTargetForm(form);
  }

  is10KeyPadTarget(form: FormControl) {
    if (!this.keypadForm.value) return;
    if (this.numberKeypadComponent && form === this.numberKeypadComponent.inputField) return true;
    return false;
  }

  saveEdit() {

    this.reqListSave();

    let request: ReqSaveItemExpiryMaster = {
      maxCtgLevel: this.maxClgLevel,
      access:   this.commonService.loginUser,
      ...this.ctgSelectConditionQueried.getCurrentSelection(),
      listItem: this.listSave
    }
    this.defaultShowLevel = request.ctgLevel;

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("SaveItemExpiryMaster", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveExpiry(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );

    this.isDirty = false;
    this.isUniformity = true;
    this.enableStore();
    this.ctgSelectComponent.enableAll();
  }

  reqListSave() {
    this.listSave = [];
    this.expiryRecs.forEach((rec) => {
      if (rec.edit.isDirty || rec.edit.delForm.value) {
        let sortData = rec.edit.dateDef.map(item => {
          if (isNaN(parseInt(item.discountForm.value))) item.discountForm.setValue(0);
          if (item.datesToExpiryForm.value != "" && item.actionForm.value == "" && item.discountForm.value > 0) {
            switch (rec.edit.discountType.value) {
              case "1": {
                item.actionForm.setValue("" + item.discountForm.value + "%引き");
                break;
              }
              case "2": {
                item.actionForm.setValue("" + item.discountForm.value + "円引き");
                break;
              }
            }
          }

          return item.datesToExpiryForm.value === "" || item.actionForm.value === "" ?
            {// 「日数n」あるいは「対応n」のどちらかが指定されていない場合は無視する。（空欄扱い）
              datesToExpiry: -1,
              action: "",
              discount: 0
            } :
            {
              datesToExpiry: parseInt(item.datesToExpiryForm.value !== "" ? item.datesToExpiryForm.value : -1),
              action: item.actionForm.value,
              discount: item.discountForm.value
            }
        });

        if (rec.edit.expiryType.value === "2") {
          sortData.sort((a, b) => {
            if (a.datesToExpiry > b.datesToExpiry) return 1;
            if (a.datesToExpiry < b.datesToExpiry) return -1;
            return 0;
          });
        } else {
          sortData.sort((a, b) => {
            if (a.datesToExpiry < 0) return -1;
            if (b.datesToExpiry < 0) return 1;
            if (a.datesToExpiry > b.datesToExpiry) return -1;
            if (a.datesToExpiry < b.datesToExpiry) return 1;
            return 0;
          });
        }

        let itemSave = {
          isDefined: 0,
          storeCd: rec.storeCd,
          ctgLevel: rec.ctgLevel,
          ctgCd0: rec.ctgCds[0],
          ctgCd1: rec.ctgCds[1],
          ctgCd2: rec.ctgCds[2],
          ctgCd3: rec.ctgCds[3],
          ctgName0: rec.ctgNames[0],
          ctgName1: rec.ctgNames[1],
          ctgName2: rec.ctgNames[2],
          ctgName3: rec.ctgNames[3],
          expiryType: rec.edit.expiryType.value,
          discountType: rec.edit.discountType.value,
          datesToExpiry0: sortData[4].datesToExpiry,
          datesToExpiry1: sortData[3].datesToExpiry,
          datesToExpiry2: sortData[2].datesToExpiry,
          datesToExpiry3: sortData[1].datesToExpiry,
          datesToExpiry4: sortData[0].datesToExpiry,
          action0: sortData[4].action,
          action1: sortData[3].action,
          action2: sortData[2].action,
          action3: sortData[1].action,
          action4: sortData[0].action,
          discountRate0: sortData[4].discount,
          discountRate1: sortData[3].discount,
          discountRate2: sortData[2].discount,
          discountRate3: sortData[1].discount,
          discountRate4: sortData[0].discount,
          discount0: sortData[4].discount,
          discount1: sortData[3].discount,
          discount2: sortData[2].discount,
          discount3: sortData[1].discount,
          discount4: sortData[0].discount,
          editMode: rec.edit.delForm.value ? 0 : 1,
        }
        this.listSave.push(itemSave);

        rec.expiryType = rec.edit.expiryType.value;
        rec.discountType = rec.edit.discountType.value;
      }
    });
  }

  uniformity() {
    this.expiryRecs?.forEach((rec) => {
      if (rec.edit.isDirty) {
        for (let i = 0; i < rec.edit.dateDef.length; i++) {
          for (let j = 1; j < rec.edit.dateDef.length; j++) {
            if ((i != j) && rec.edit.dateDef[i].datesToExpiryForm.value == rec.edit.dateDef[j].datesToExpiryForm.value
              && rec.edit.dateDef[i].datesToExpiryForm.value != "" && rec.edit.dateDef[j].datesToExpiryForm.value != "") {
              this.isUniformity = true;
              return;
            }
            else {
              this.isUniformity = false;
            }
          }
        }
      }
    });
  }

  clearEdit() {
    this.isUniformity = true;
    this.isDirty = false;
    this.enableStore();
    this.ctgSelectComponent.enableAll();
    this.expiryRecs.forEach((rec) => {rec.clearEdit();})
    this.reDisplay();
  }

  canDeactivate() {
    if (!this.isDirty) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

}

