import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CsvData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CsvTableStoreComponent } from 'src/app/partsCommon/csv-table-store/csv-table-store.component';
import { ReqCsvNoisecutPromCheck, ReqCsvNoisecutPromCheckDto } from 'src/app/request/req-csv-noisecut-prom-check';
import { ReqCsvNoisecutPromUpdateDto, ReqCsvNoisecutUpdate } from 'src/app/request/req-csv-noisecut-prom-update';
import { RspCsvNoisecutPromCheck } from 'src/app/response/rsp-csv-noisecut-prom-check';
import { RspCsvNoisecutPromUpdate } from 'src/app/response/rsp-csv-noisecut-prom-update';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

@Component({
  selector: 'app-csv-noisecut-prom',
  templateUrl: './csv-noisecut-prom.component.html',
  styleUrls: ['./csv-noisecut-prom.component.css']
})
export class CsvNoisecutPromComponent implements OnInit, OnDestroy {

  private isReadonly: boolean;
  public noisecutList: CsvData;
  /*
  public columnDefs: TableColumnDef[] = [
    { columnId: "storeCd", header: "店舗コード", width: 70, align: "center" },
    { columnId: "storeName", header: "店舗名", width: 150, csvComment: "[空欄可]" },
    { columnId: "itemCd", header: "商品コード", width: 100, align: "center" },
    { columnId: "itemName", header: "商品名", width: 300, align: "left", csvComment: "[空欄可]" },
    { columnId: "dateBegin", header: "開始日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式]"},
    { columnId: "dateEnd", header: "終了日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式]"}
  ];
  */
  // public columnDefsNoStore: TableColumnDef[] = [
  public columnDefs: TableColumnDef[] = [
    { columnId: "itemCd", header: "商品コード", width: 100, align: "center" },
    { columnId: "itemName", header: "商品名", width: 300, align: "left", csvComment: "[空欄可]" },
    { columnId: "dateBegin", header: "開始日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式]" },
    { columnId: "dateEnd", header: "終了日", width: 100, align: "center", csvComment: "[西暦 yyyy/mm/dd 形式]" }
  ];
  public displayColumnDefs: TableColumnDef[] = [];
  public subscriptionCheck: Subscription;
  public subscriptionUpdate: Subscription;
  public selectedStore: string[] = [];
  public isDisableUpdateBtn: boolean = true;
  public selectedStoreCount: number = 0;
  public csvTableHeight: number = 400;

  // @ViewChild(CsvTableComponent, { static: false }) csvTableComponent: CsvTableComponent;
  @ViewChild(CsvTableStoreComponent, { static: false }) csvTableComponent: CsvTableStoreComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("csvNoisecutProm");
    /*
    if (this.commonService.stores.length > 1) {
      this.displayColumnDefs = [...this.columnDefs];
    } else {
      this.displayColumnDefs = [...this.columnDefsNoStore];
    }
    */
  }

  ngOnDestroy(): void {
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.resized();
  }

  resized() {
    let id = "csv-table-container";
    let height = this.commonService.getHeightBelow(id);
    if (height) {
      this.csvTableHeight = height - 24 - 15;
    } else {
      this.csvTableHeight = 400;
    }
    if (this.csvTableComponent) {
      this.csvTableComponent.setMaxHeight(this.csvTableHeight);
    } else {
      setTimeout(() => {
        if (this.csvTableComponent) {
          this.csvTableComponent.setMaxHeight(this.csvTableHeight);
        }
      }, 0);
    }

    id = "table-inner-container";
    height = this.commonService.getHeightBelow(id);
    if (height) {
      let containerElement = document.getElementById(id);
      if (!containerElement) return;
      containerElement.style["max-height"] = "" + (height - 30) + "px";
    } else {
      this.csvTableHeight = 400;
    }
  }

  clearProgressState() {
    this.commonService.closeSpinner();
  }

  getCsvData(csvData: CsvData) {
    this.noisecutList = { ...csvData };
    this.selectedStore = [];
    if (this.csvTableComponent) {
      this.csvTableComponent.init(this.noisecutList);
    }
    setTimeout(() => {
      if (this.csvTableComponent) {
        this.csvTableComponent.checkedStoreCd.clear();
        this.csvTableComponent.storeUnselectAll();
      }
      this.isDisableUpdateBtnFn();
      this.resized();
    }, 0);
  }

  getDataForCheck() {
    let request: ReqCsvNoisecutPromCheckDto[] = [];
    if (this.csvTableComponent?.checkedStoreCd?.size <= 0) return request;
    this.noisecutList.lines.forEach(value => {
      let item: ReqCsvNoisecutPromCheckDto = {
        line: value.lineNumber,
        itemCdFv: value.columnData['itemCd'].trim(),
        dateBegin: value.columnData['dateBegin'].trim(),
        dateEnd: value.columnData['dateEnd'].trim(),
      }
      request.push(item);
    });
    return request;
  }

  getDataForUpdate() {
    let request: ReqCsvNoisecutPromUpdateDto[] = [];

    if (this.csvTableComponent?.getSelectedStore()?.length <= 0) return request;

    this.csvTableComponent?.getSelectedStore()?.map(store => store.storeCdFv).forEach(storeCdFv => {
      {
        this.noisecutList.lines.forEach(value => {
          let item: ReqCsvNoisecutPromUpdateDto = {
            storeCdFv: storeCdFv,
            itemCdFv: value.columnData['itemCd'].trim(),
            dateBegin: value.columnData['dateBegin'].trim(),
            dateEnd: value.columnData['dateEnd'].trim(),
          }
          request.push(item);
        });
      }
    })

    return request;
  }

  doConfirm() {
    const selectedStore: { storeCd: string, storeName: string }[] = [...this.commonService.loginUser.stores]?.filter(store => {
      return !![...this.csvTableComponent?.checkedStoreCd].find(selectedCd => store?.storeCd === selectedCd)
    })?.map(store => {
      return { storeCd: store?.storeCd, storeName: store?.storeName }
    })

    const request: ReqCsvNoisecutPromCheck = {
      access: this.commonService.loginUser,
      csvNoisecutPromCheck: this.getDataForCheck(),
      storeCheckedList: selectedStore || []
    }

    if (request?.storeCheckedList?.length < 1 || request?.csvNoisecutPromCheck?.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "データがありません。");
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "確認中・・・");

    this.subscriptionCheck = this.httpBasic.checkCsvNoisecutPromTarget(request).subscribe(
      (response: RspCsvNoisecutPromCheck) => {
        this.checkResultNoisecutCheck(response);
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
    this.csvTableComponent.handleError();
  }

  checkResultNoisecutCheck(response: RspCsvNoisecutPromCheck) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    this.noisecutList.lines.map(line => {
      line.errorMsg = [],
      line.warningMsg = []
    })
    if (response.result.length <= 0) return;
    for (const resRow of response.result) {
      const rowUpdate = this.noisecutList.lines.find(row => row.lineNumber === resRow.line);
      if (!rowUpdate) continue;
      if (resRow.errorMsgs?.length > 0) {
        rowUpdate.errorMsg = resRow.errorMsgs;
      }
      if (resRow.warningMsgs?.length > 0) {
        rowUpdate.warningMsg = resRow.warningMsgs;
      }
    }

    this.selectedStore = this.csvTableComponent.getSelectedStore().map(store => store.storeCdFv);
    this.csvTableComponent.handleError();
    this.isDisableUpdateBtnFn();
  }

  doUpdate() {
    let request: ReqCsvNoisecutUpdate = {
      access: this.commonService.loginUser,
      csvNoisecutPromUpdate: this.getDataForUpdate(),
    }
    if (request?.csvNoisecutPromUpdate?.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "テストするデータがありません");
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.httpBasic.updateCsvNoisecutPromTarget(request).subscribe(
      (response: RspCsvNoisecutPromUpdate) => {
        this.clearProgressState();
        if (this.httpBasic.handleAppError(response)) return;
        this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )
  }

  storeChange() {
    this.getSelectedStoreCount();
    this.isDisableUpdateBtnFn();
  }

  /*
  isDisableUpdateBtn() {
    if (this.noisecutList == undefined) return true;
    if (this.noisecutList.errorCount == undefined) return true;
    if (this.noisecutList.errorCount > 0) return true;
    return false;
  }
  */

  isDisableUpdateBtnFn() {
    if (this.selectedStore.length <= 0) return this.isDisableUpdateBtn = true;
    if (this.noisecutList == undefined) return this.isDisableUpdateBtn = true;
    if (this.noisecutList.errorCount == undefined) return this.isDisableUpdateBtn = true;
    if (this.noisecutList.errorCount > 0) return this.isDisableUpdateBtn = true;

    if (this.csvTableComponent.checkedStoreCd.size == 0) return this.isDisableUpdateBtn = true;
    let selectedStore = [...this.csvTableComponent.checkedStoreCd];

    for (let index = 0; index < selectedStore.length; index++) {
      const element = selectedStore[index];
      if (!this.selectedStore.includes(element)
        || this.csvTableComponent.checkedStoreCd.size > this.selectedStore.length) {
        return this.isDisableUpdateBtn = true;
      }
    }
    return this.isDisableUpdateBtn = false;
  }

  getSelectedStoreCount() {
    if (!this.csvTableComponent) return this.selectedStoreCount = 0;
    return this.selectedStoreCount = this.csvTableComponent.checkedStoreCd.size;
  }
}
