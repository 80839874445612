import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewChecked, HostListener } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ItemSearchCondition } from 'src/app/partsCommon/item-select-condition/item-select-condition.component';
import { ItemListComponent } from 'src/app/partsCommon/item-list/item-list.component';

@Component({
  selector: 'app-item-param-edit',
  templateUrl: './item-param-edit.component.html',
  styleUrls: ['./item-param-edit.component.css']
})
export class ItemParamEditComponent implements OnInit, OnDestroy {
 
  public isReadonly: boolean;
  public itemSearchCondition : ItemSearchCondition = new ItemSearchCondition(this.fb);
  public itemSearchConditionOrigin : ItemSearchCondition = new ItemSearchCondition(this.fbOrigin);
  public orderStopFlagValues: number[];

  private itemListContainerElement: HTMLElement;
  private scrollHeight: number;
  private scrollTop: number;

 @ViewChild(ItemListComponent, {static:true}) itemListComponent: ItemListComponent;
 
  constructor(
     public commonService: CommonService,
     private fb: FormBuilder,
     private fbOrigin: FormBuilder)
  {
    this.orderStopFlagValues = [];
    let flagList = this.commonService.getOrderStopSearchCondList();
    for (let i = 0; i < flagList.length; i++) {
      let flag = flagList[i];
      if (!flag.paramEditable) continue;
      if (flag.caseItem) continue;
      this.orderStopFlagValues.push(flag.value);
    }
  }
 
  ngOnInit() {
    // this.commonService.pageTitle = "商品パラメータ";
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("itemparam");

    this.itemSearchCondition.formGroup.get('salesDate').setValue(this.commonService.dateSalesDate);
    this.itemSearchCondition.formGroup.get('salesDate').disable();

    // window.addEventListener("resize", this.initScroll);
   }

   ngOnDestroy() {
    // window.removeEventListener("resize", this.initScroll);
   }

   @HostListener('window:resize', ['$event'])
   handleResize() {
     this.setTableHeight();
   }
 
   setTableHeight() {
     setTimeout(() => { this.setTableHeightBody(); }, 0);
   }
 
   setTableHeightBody() {
     let id = "item-list-inner";
     let remHeight = this.commonService.getHeightBelow(id);
     let paginatorHeight = 56;
     let margin = 5;
     let height = remHeight - paginatorHeight - margin;
     if (height < 200) height = 200;
 
     let elem = document.getElementById(id);
     if (elem) elem.style.maxHeight = "" + height + "px";
   }

   /*
   ngAfterViewChecked() {
    this.initScroll();
  }

   initScroll() {
    this.itemListContainerElement = document.getElementById("item-list-container");
    var clientRect = this.itemListContainerElement.getBoundingClientRect();
    this.scrollTop = clientRect.top;
    this.scrollHeight = window.innerHeight - this.scrollTop - 45;
    this.itemListContainerElement.style["height"] = "" + this.scrollHeight + "px";
  }
  */
     
   doQuery() {
    this.copyRequest();
    this.itemListComponent.doQuery();
    this.setTableHeight();
   }

   copyRequest() {
    this.itemSearchConditionOrigin.formGroup = this.itemSearchCondition.cloneAbstractControl(this.itemSearchCondition.formGroup);
    this.itemSearchConditionOrigin.ctgSelectCondition.formGroup.patchValue(this.itemSearchCondition.ctgSelectCondition.formGroup.value);
    this.itemSearchConditionOrigin.rackSelectCondition.formGroup.patchValue(this.itemSearchCondition.rackSelectCondition.formGroup.value);
    this.itemSearchConditionOrigin.formGroup.get('salesDate').setValue(this.commonService.dateSalesDate);
    this.itemSearchConditionOrigin.formGroup.get('salesDate').disable();
  }
}
