import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorNotificationDialogComponent } from '../dialog/error-notification-dialog/error-notification-dialog.component';
import { SpinnerDialogComponent } from '../dialog/spinner-dialog/spinner-dialog.component';
import { ReqAccess } from '../request/req-access';
import { LoginStoreDto } from '../response/login-store-dto';
import { MenuGroupDto, MenuItemDto } from '../webservice/menu';
import { HttpClient } from '@angular/common/http';
import { Config, OrderStopFlag, TaskSearchCond } from '../common/config';
import { Observable } from 'rxjs';
import { YesNoDialogComponent } from '../dialog/yes-no-dialog/yes-no-dialog.component';
// import { environment } from 'src/environments/environment';
import { NotificationDialogComponent } from '../dialog/notification-dialog/notification-dialog.component';
import { GraphOptionDialogComponent } from '../dialog/graph-option-dialog/graph-option-dialog.component';
import { GraphOption } from './graph.service';
import { TableColumnDef } from '../common/table-column-def';
import { DecimalPipe } from '@angular/common';
import * as Encoding from 'encoding-japanese';
import { FormControl } from '@angular/forms';
import { Spiv00051SearchDto, Spiv00051Dto } from '../request/req-spiv00051';
import { Spiv00081SearchDto } from '../request/req-spiv00081-search';
import { Spiv00091SearchDto } from '../request/req-spiv00091-search';
import { NonInventoryDataDto } from '../request/req-non-inventory-data';
export interface CreateCsvOptions {
  numberFormat?: string;        // DecimalPipe digitsInfo
  numberFormatForKey?: Object;  // {key: "DecimalPipe digitsInfo", ...}
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  configUrl: string = 'assets/config.json';
  public configDefault: Config;
  public config: Config;
  public userConfig: any;
  public literal: any;

  public loginUser: ReqAccess;
  public stores: LoginStoreDto[];
  public allStores: LoginStoreDto[];
  public fullStores: LoginStoreDto[];
  public autoOrderStore: boolean;
  public salesDate: string;
  public dateSalesDate: Date;
  public serviceTimeStart: string;
  public serviceTimeEnd: string;
  public menuGroups: MenuGroupDto[];
  public menuGroupsMobile: MenuGroupDto[];
  public isFatalErrorRedirect: boolean = false;

  public pageTitle: string;
  public pageMenuName: string;
  public urlAfterLogin: string;
  public queryParamsAfterLogin: any;
  public dialog: MatDialog;
  public router: Router;
  private spinnerDialogRef: MatDialogRef<any>;
  public audioBeep: HTMLAudioElement;

  public openDashboardHq: boolean = true;
  public openDashboardStore: boolean = true;
  public taskSearchCond: TaskSearchCond = undefined;
  public taskOpenTaskId: number = -1;

  public openNotification: boolean = true;

  // inventory-product-register
  public fpiv0010ParentPage: string;
  public fpiv0010RowData: Spiv00051Dto[];

  // 棚卸差異確認リスト
  public fpiv0008ParentPage: string;
  public fpiv0008SearchData: Spiv00081SearchDto;
  public fpiv0008StoreCd: string;
  public fpiv0008ProductCd: string;
  public fpiv0008InvDiffId: string;
  public fpiv0008InvMonth: string;

  //未棚卸確認リスト
    public fpiv0009StoreCd : string;
    public fpiv0009YearMonth : string;
    public fpiv0009InvScheduleId : string;
    public fpiv0009ParentPage : string;
    public fpiv0009SearchData: Spiv00091SearchDto;



  // 棚卸入力
  public fpiv0005ParentPage: string;
  public fpiv0005SearchData: Spiv00051SearchDto;
  public fpiv0005RowData: Spiv00051Dto[];

  // 棚卸入力明細
  public fpiv0011ParentPage: string;

  public paginatorOption = {
    pageSizeIndex: 0,
    pageSizeOptions: [25, 50, 100]
  };

  constructor(
    public _router: Router,
    public _dialog: MatDialog,
    public http: HttpClient,
    private decimalPipe: DecimalPipe,
    @Inject(LOCALE_ID) public locale: string
  ) {

    this.router = _router;
    this.dialog = _dialog;
  }

  public openErrorDialog(title: string, message: string, btnText?: string) {
    const dialogRef = this.dialog.open(ErrorNotificationDialogComponent, {
      data: { errorTitle: title, errorMessage: message, btnText: btnText }
    });
  }
  public openNotificationDialog(title: string, message: string, btnText?: string) {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      data: { title: title, message: message, btnText: btnText }
    });
  }

  public openNotificationDialogAfterClose(title:string, message:string, btnText?: string) {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      data: {title: title, message: message, btnText: btnText}
    });
    return dialogRef.afterClosed();
  }

  public openFatalErrorDialog(title: string, message: string) {
    this.loginUser = undefined;
    this.closeSpinner();
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(ErrorNotificationDialogComponent, {
      data: { errorTitle: title, errorMessage: message }
    });
    /*
    this.dialog.open(ErrorNotificationDialogComponent, {
      data: {errorTitle: title, errorMessage: message}
    }).afterClosed().subscribe(
      () => window.location.reload()
    );
    */
    this.isFatalErrorRedirect = true;
    this.router.navigate(['login']);
  }

  openSpinner(title: string, msg: string) {
    this.spinnerDialogRef = this.dialog.open(SpinnerDialogComponent, {
      disableClose: true,
      data: { title: title, message: msg }
    });
  }
  closeSpinner() {
    if (this.spinnerDialogRef) {
      this.spinnerDialogRef.close();
      this.spinnerDialogRef = undefined;
    }
  }
  openSpinnerForSubComp(title: string, msg: string): MatDialogRef<any> {
    return this.dialog.open(SpinnerDialogComponent, {
      disableClose: true,
      data: { title: title, message: msg }
    });
  }
  closeSpinnerForSubComp(dialogRef: MatDialogRef<any>) {
    if (dialogRef) {
      dialogRef.close();
    }
  }

  public openYesNoDialog(title: string, message: string): Observable<boolean> {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      data: { title: title, message: message }
    });

    return dialogRef.afterClosed();
  }

  openGraphOptionDialog(option: GraphOption, rowCount: number) {
    const dialogRef = this.dialog.open(GraphOptionDialogComponent, {
      disableClose: true,
      data: { graphOption: option, rowCount: rowCount },
      maxWidth: "95vw"
      // position: {top: "5px"}
    });
    return dialogRef.afterClosed();
    /*
    this.subscriptionDialog = dialogRef.afterClosed().subscribe(
      data => this.dialogResult(data)
    );
    */
  }

  /*
    true:  when read-only
    false: when not read-only
    null: Privilege Error
  */
  checkPrivilege(path: string) {
    for (var menuGroup of this.menuGroups) {
      for (var menuItem of menuGroup.menuItems) {
        if (path === menuItem.menuPath) {
          return menuItem.isReadonly == 0 ? false : true;
        }
      }
    }
    // Unkown menu path, Redirect to login
    this.openFatalErrorDialog("権限エラー", "この機能の利用は許可されていません。");
    return null;
  }

  /*
  checkPrivilegeByClassName(className: string) {

    for (let route of routes) {
      if (route.component.name === className) {
        return this.checkPrivilege(route.path);
      }
    }
    this.openFatalErrorDialog("内部エラー", "クラス名 [" + className + "] が見つかりません。");
    return null;
  }
  */

  checkPrivilegeNoRedirect(path: string) {
    for (var menuGroup of this.menuGroups) {
      for (var menuItem of menuGroup.menuItems) {
        if (path === menuItem.menuPath) {
          return menuItem.isReadonly == 0 ? false : true;
        }
      }
    }
    return true;
  }

  hasMenu(path: string) {
    if (!this.menuGroups) return false;
    for (var menuGroup of this.menuGroups) {
      for (var menuItem of menuGroup.menuItems) {
        if (path === menuItem.menuPath) return true;
      }
    }
    return false;
  }

  isMobile() {
    let ua = navigator.userAgent;
    if (ua.indexOf('Android') > -1) return true;
    if (ua.indexOf('iPhone') > -1) return true;
    if (ua.indexOf('iPod') > -1) return true;
    if (ua.indexOf('iPad') > -1) return true;
    if (ua.indexOf('Macintosh') > -1 && 'ontouchend' in document) return true;

    return false;
  }

  setConfigDefault() {
    if (this.configDefault.pagenatorOptions) {
      this.paginatorOption.pageSizeOptions = this.configDefault.pagenatorOptions;
    }

    // config default
    if (!this.configDefault.noisecut) {
      this.configDefault.noisecut = { prom: true, customerOrder: true };
    }
    if (!this.configDefault.orderGroup) {
      this.configDefault.orderGroup = {
        targetItemStopFlags: undefined,
        itemSortByOrderNum: true,
        maxUser: 3,
        showGroupCode: false
      };
    } else {
      if (!this.configDefault.orderGroup.maxUser) this.configDefault.orderGroup.maxUser = 3;
      if (!this.configDefault.orderGroup.itemSortByOrderNum) this.configDefault.orderGroup.itemSortByOrderNum = true;
      if (!this.configDefault.orderGroup.showGroupCode) this.configDefault.orderGroup.showGroupCode = false;
    }
    this.configDefault.orderStopFlag.forEach((flag) => {
      if (flag.paramEditable == undefined) flag.paramEditable = true;
      if (flag.extendedOrder == undefined) flag.extendedOrder = false;
      if (flag.isAutoOrder == undefined) flag.isAutoOrder = false;
      if (flag.searchCond == undefined) flag.searchCond = false;
      if (flag.caseItem == undefined) flag.caseItem = false;
      if (flag.canCaseOrder == undefined) flag.canCaseOrder = false;
    });
    if (this.configDefault.orderEditInBara == undefined) {
      this.configDefault.orderEditInBara = false;
    }
    if (this.configDefault.orderEditCaseItem == undefined) {
      this.configDefault.orderEditCaseItem = false;
    }
    if (this.configDefault.stockEditCaseItem == undefined) {
      this.configDefault.stockEditCaseItem = false;
    }
    if (this.configDefault.noisecutCaseItem == undefined) {
      this.configDefault.noisecutCaseItem = false;
    }
    if (this.configDefault.rackCaseItem == undefined) {
      this.configDefault.rackCaseItem = false;
    }

    if (this.configDefault.includeNonAutoOrderStore == undefined) {
      this.configDefault.includeNonAutoOrderStore = {
        user: false,
        itemExpiry: false,
        task: false,
        orderGroupUser: false,
        storeGroup: false
      };
    }
    if (this.configDefault.includeNonAutoOrderStore.orderGroupUser == undefined) {
      this.configDefault.includeNonAutoOrderStore.orderGroupUser = false;
    }
    if (this.configDefault.includeNonAutoOrderStore.storeGroup == undefined) {
      this.configDefault.includeNonAutoOrderStore.storeGroup = false;
    }
    if (this.configDefault.itemExpiry == undefined) {
      this.configDefault.itemExpiry = {};
    }
    if (this.configDefault.itemExpiry.colors == undefined) {
      this.configDefault.itemExpiry.colors = [
        { "fg": "#FF0000", "bg": "#FBE5D6" },
        { "fg": "#FF00FF", "bg": "#FFCCFF" },
        { "fg": "#A64C0E", "bg": "#FFF0E1" },
        { "fg": "#D09E00", "bg": "#FFF2CC" },
        { "fg": "#5B9BD5", "bg": "#DEEBF7" }
      ];
    }
    if (this.configDefault.itemExpiry.usePrinter == undefined) {
      this.configDefault.itemExpiry.usePrinter = false;
    }
    if (this.configDefault.task == undefined) this.configDefault.task = {};
    if (this.configDefault.task.searchCond == undefined) {
      this.configDefault.task.searchCond = {
        storeCd: "",
        releaseStatus: "",
        author: "",
        statusNotAssigned: true,
        statusNotStarted: true,
        statusStarted: true,
        statusCompleted: false,
        endDate: true,
        endDateFrom: -6,
        endDateTo: 6,
        endDateFromDay: 0,
        endDateToDay: 0,
        responsibleClass: "",
        responsible: "",
        responsiblePartial: true,
        taskCategory: "",
        taskName: "",
        subStatusNotCompleted: true,
        subStatusCompleted: true,
        subDateYesterday: true,
        subDateToday: true,
        subDateTommorow: true,
        subResponsible: "",
        subResponsiblePartial: true
      };
    }
    if (this.configDefault.task.searchCond.responsiblePartial == undefined) {
      this.configDefault.task.searchCond.responsiblePartial = true;
    }
    if (this.configDefault.task.searchCond.subStatusNotCompleted == undefined) {
      this.configDefault.task.searchCond.subStatusNotCompleted = true;
    }
    if (this.configDefault.task.searchCond.subStatusCompleted == undefined) {
      this.configDefault.task.searchCond.subStatusCompleted = true;
    }
    if (this.configDefault.task.searchCond.subDateYesterday == undefined) {
      this.configDefault.task.searchCond.subDateYesterday = true;
    }
    if (this.configDefault.task.searchCond.subDateToday == undefined) {
      this.configDefault.task.searchCond.subDateToday = true;
    }
    if (this.configDefault.task.searchCond.subDateTommorow == undefined) {
      this.configDefault.task.searchCond.subDateTommorow = true;
    }
    if (this.configDefault.task.searchCond.subResponsible == undefined) {
      this.configDefault.task.searchCond.subResponsible = "";
    }
    if (this.configDefault.task.searchCond.subResponsiblePartial == undefined) {
      this.configDefault.task.searchCond.subResponsiblePartial = true;
    }
    if (this.configDefault.task.searchCond.taskCategory == undefined) {
      this.configDefault.task.searchCond.taskCategory = "";
    }
    if (this.configDefault.task.searchCondWholeStatus == undefined) {
      this.configDefault.task.searchCondWholeStatus = {
        author: "",
        statusNotStarted: true,
        statusStarted: true,
        statusCompleted: false,
        endDate: true,
        endDateFrom: -6,
        endDateTo: 6,
        responsibleClass: "",
        wholeStatusReport: "",
        storeGroupType: "",
        storeGroup: "",
        taskName: ""
      };
    }
    if (this.configDefault.task.responsibleIsLoginUser == undefined) {
      this.configDefault.task.responsibleIsLoginUser = true;
    }
    if (this.configDefault.task.searchCond.endDateTo < this.configDefault.task.searchCond.endDateFrom) {
      let tmp = this.configDefault.task.searchCond.endDateTo;
      this.configDefault.task.searchCond.endDateTo = this.configDefault.task.searchCond.endDateFrom;
      this.configDefault.task.searchCond.endDateFrom = tmp;
    }
    if (this.configDefault.task.searchCondWholeStatus.endDateTo < this.configDefault.task.searchCondWholeStatus.endDateFrom) {
      let tmp = this.configDefault.task.searchCondWholeStatus.endDateTo;
      this.configDefault.task.searchCondWholeStatus.endDateTo = this.configDefault.task.searchCondWholeStatus.endDateFrom;
      this.configDefault.task.searchCondWholeStatus.endDateFrom = tmp;
    }
    if (this.configDefault.task.isWorktimeResultRequired == undefined) {
      this.configDefault.task.isWorktimeResultRequired = false;
    }
    if (this.configDefault.task.descriptionRows == undefined) {
      this.configDefault.task.descriptionRows = 3;
    }
    if (this.configDefault.task.useTaskCategory == undefined) {
      this.configDefault.task.useTaskCategory = false;
    }
    if (this.configDefault.task.workTimeIsHourAndMinutes == undefined) {
      this.configDefault.task.workTimeIsHourAndMinutes = true;
    }
    if (this.configDefault.task.authorIsStoreAndUser == undefined) {
      this.configDefault.task.authorIsStoreAndUser = false;
    }
    if (this.configDefault.task.isCompletedTaskEditable == undefined) {
      this.configDefault.task.isCompletedTaskEditable = false;
    }
    if (this.configDefault.task.hqOpenAllTab == undefined) {
      this.configDefault.task.hqOpenAllTab = false;
    }
    if (this.configDefault.task.wholeStatusAutoClose == undefined) {
      this.configDefault.task.wholeStatusAutoClose = true;
    }
    if (this.configDefault.task.responsibleClassDefIndex == undefined) {
      this.configDefault.task.responsibleClassDefIndex = -1;
    }
    if (this.configDefault.task.responsibleCopyToSub == undefined) {
      this.configDefault.task.responsibleCopyToSub = undefined;
    }
    if (this.configDefault.task.storeEditMode == undefined) {
      this.configDefault.task.storeEditMode = 1;
    }
    if (!this.configDefault.itemQuery) {
      this.configDefault.itemQuery = {
        janZeroPadding: { enable: false, toLength: 0 },
        scanControlV: false
      }
    }
    if (!this.configDefault.valueCheck) {
      this.configDefault.valueCheck = {
        orderNumUpper: { warning: -1, forbidden: -1 },
        orderNumRepeatingDigits: { count: -1, isFull: true }
      }
    }
    if (!this.configDefault.orderStock) {
      this.configDefault.orderStock = {
        callScanOnItemCd: false,
        listColumns: [
          "standardFv",
          "orderBacklog",
          "orderNumForm",
          "orderLotFn",
          "orderBaraNumFn",
          "stockNumForm",
          "deliveryDate",
          "minZaiFn",
          "maxZaiFn",
          "itemClass",
          "salesResult",
          "orderResult",
          "itemLocation"
        ]
      }
    }
    if (!this.configDefault.imageDb) this.configDefault.imageDb = {};
    if (!this.configDefault.imageDb.keyForShare) this.configDefault.imageDb.keyForShare = "UNKNOWN";
    if (this.configDefault.imageDb.labelFilter === undefined) this.configDefault.imageDb.labelFilter = "LifeCam Studio";
    if (this.configDefault.useMobile === undefined) this.configDefault.useMobile = false;
    if (this.configDefault.ruotesMobile === undefined) this.configDefault.ruotesMobile = [];

    // literal default
    if (!this.literal.customerOrder) this.literal.customerOrder = "客注";
    if (!this.literal.rack) this.literal.rack = "棚";
    if (!this.literal.rack1) this.literal.rack1 = "棚グループ";
    if (!this.literal.rack2) this.literal.rack2 = "棚";
    if (!this.literal.rack3) this.literal.rack3 = "段";
  }

  getDate(dateStr: string): Date {
    var year = parseInt(dateStr.substring(0, 4));
    var month = parseInt(dateStr.substring(5, 7)) - 1;
    var day = parseInt(dateStr.substring(8, 10));
    return new Date(year, month, day);
  }

  copyDate(date: Date): Date {
    return new Date(
      date.getFullYear(), date.getMonth(), date.getDate()
    );
  }

  formatDate(date: Date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}/${('0' + month).slice(-2)}/${('0' + day).slice(-2)}`;
  }

  formatDateShort(date: Date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${(month)}/${(day)}`;
  }

  formatDateTime(date: Date): string {
    let dateTime = this.formatDate(date);
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();

    dateTime += " " + ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);

    return dateTime;
  }

  formatTime(date: Date): string {
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();

    return ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);
  }

  getOrderStopFlag(value: number) {
    for (var flag of this.config.orderStopFlag) {
      if (flag.value == value) return flag;
    }
    return undefined;
  }

  getOrderStopFlagName(value: number) {
    for (var flag of this.config.orderStopFlag) {
      if (flag.value == value) return flag.name;
    }
    return "";
  }

  getOrderStopFlagCanOrder(value: number) {
    for (var flag of this.config.orderStopFlag) {
      if (flag.value == value) return flag.canOrder;
    }
    return "";
  }

  getOrderStopFlagCanOrderEx(value: number) {
    for (var flag of this.config.orderStopFlag) {
      if (flag.value == value) return flag.canOrder || flag.extendedOrder;
    }
    return "";
  }

  getOrderStopFlagCanCaseOrder(value: number) {
    if (!this.config.orderEditCaseItem) return false;
    for (var flag of this.config.orderStopFlag) {
      if (flag.value == value) return flag.caseItem && flag.canCaseOrder;
    }
    return false;
  }

  getOrderStopFlagChangeable(value: number) {
    for (var flag of this.config.orderStopFlag) {
      if (flag.value == value) return flag.changeable;
    }
    return "";
  }

  getOrderStopSelectList(): OrderStopFlag[] {
    var list = [];

    for (var flag of this.config.orderStopFlag) {
      if (flag.changeable) {
        list.push(flag);
      }
    }
    return list;
  }

  getOrderableStopList(): number[] {
    var list = [];

    for (var flag of this.config.orderStopFlag) {
      if (flag.canOrder) {
        list.push(flag.value);
      }
    }
    return list;
  }

  getOrderStopValues(): number[] {
    var list = [];

    for (var flag of this.config.orderStopFlag) {
      list.push(flag.value);
    }
    return list;
  }

  getOrderStopValuesWithoutCase(): number[] {
    var list = [];

    for (var flag of this.config.orderStopFlag) {
      if (!flag.caseItem) {
        list.push(flag.value);
      }
    }
    return list;
  }

  getOrderableCaseStopList(): number[] {
    var list = [];

    for (var flag of this.config.orderStopFlag) {
      if (flag.caseItem && flag.canCaseOrder) {
        list.push(flag.value);
      }
    }
    return list;
  }

  getCsvFromTable(tagid: string) {
    if (!navigator.clipboard) return;
    let elem = document.getElementById(tagid);
    if (!elem) return;

    let csv = "";
    let line = "";

    let thElems = elem.getElementsByTagName("th");
    for (let i = 0; i < thElems.length; i++) {
      if (i > 0) line += "\t";
      line += thElems[i].textContent.trim();
    }
    line = line.replace("削除\t", "");
    csv = line + "\n";

    let tbody = elem.getElementsByTagName("tbody");
    let trElems = tbody[0].getElementsByTagName("tr");
    for (let i = 0; i < trElems.length; i++) {
      if (!trElems[i]?.className?.includes("delete-color-bg")) {
        line = "";
        let tdElems = trElems[i].getElementsByTagName("td");
        for (let j = 0; j < tdElems.length; j++) {
          if (j > 0) line += "\t";
          line += tdElems[j].textContent.trim();
        }
        line = line.replace("\t", "");
        csv += line + "\n";
      }
    }
    navigator.clipboard.writeText(csv);
    this.openNotificationDialog(this.pageTitle, "クリップボードにコピーしました。")
  }

  getPathAndParams(url: string) {
    let path: string[] = url.split("?");
    let queryParams = {};
    if (path.length == 1) {
      return { path: path[0], params: queryParams };
    }

    let params = path[1].split("&");
    for (let param of params) {
      let keyValue = param.split("=");
      queryParams[keyValue[0]] = keyValue[1];
    }
    return { path: path[0], params: queryParams };
  }

  compareParams(param1, param2): boolean {
    for (let key in param1) {
      if (!param2[key]) return false;
      if (param1[key] !== param2[key]) return false;
    }

    for (let key in param2) {
      if (!param1[key]) return false;
    }

    return true;
  }

  findMenu(path: string, params: object): MenuItemDto | null {
    for (let menug of this.menuGroups) {
      for (let menuItem of menug.menuItems) {
        let pathObjMenu = this.getPathAndParams(menuItem.menuPath);
        if (path !== pathObjMenu.path) continue;
        if (this.compareParams(params, pathObjMenu["params"])) return menuItem;
      }
    }
    return null;
  }

  setPageMenu() {
    let menuItem: MenuItemDto = this.findMenu(this.urlAfterLogin, this.queryParamsAfterLogin);
    if (menuItem != null) this.pageMenuName = menuItem.menuName;
  }

  /*
  getHeightBelow(id: string) {
      let containerElement = document.getElementById(id);
      if (!containerElement) return undefined;
      let containerRect = containerElement.getBoundingClientRect();
      return window.innerHeight - containerRect.top - 22;
  }
  */

  getHeightBelow(id: string) {
    let containerElement = document.getElementById(id);
    if (!containerElement) return undefined;
    let containerRect = containerElement.getBoundingClientRect();
    let winHeight = window.innerHeight;
    containerElement = document.getElementsByTagName("body").item(0);
    let css = window.getComputedStyle(containerElement);
    let minHeight = parseInt(css.getPropertyValue("min-height").replace("px", ""));
    if (winHeight < minHeight) winHeight = minHeight;
    return winHeight - containerRect.top - 32;
  }

  styleFor(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "min-width": "" + colDef.width + "px",
      "max-width": "" + (colDef.maxWidth != undefined ? colDef.maxWidth : colDef.width) + "px",
      "text-align": colDef.align ? colDef.align : "left"
    };
  }

  styleForHeader(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "min-width": "" + colDef.width + "px",
      "max-width": "" + (colDef.maxWidth != undefined ? colDef.maxWidth : colDef.width) + "px"
    };
  }

  isMobileiOSChrome() {
    let ua = navigator.userAgent;
    if (ua.indexOf("CriOS") < 0) return false;
    if (ua.indexOf('iPhone') > -1) return true;
    if (ua.indexOf('iPod') > -1) return true;
    if (ua.indexOf('iPad') > -1) return true;
    if (ua.indexOf('Macintosh') > -1 && 'ontouchend' in document) return true;

    return false;
  }

  downloadBlob(blob: Blob, filename: string) {
    const navigator: any = window.navigator;
    const ua = navigator?.userAgent;
    if (navigator?.msSaveBlob) {
      // For IE10/11 : No download functionality
      navigator.msSaveBlob(blob, filename);
    } else if (this.isMobileiOSChrome()) {
      var reader = new FileReader();
      reader.onload = function (e) {
        let download = document.createElement("a");
        download.href = reader.result as string;
        download.download = filename;
        download.click();
      };
      reader.readAsDataURL(blob);
    } else {
      // Other browser
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      let download = document.createElement("a");
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  }

  createCsv(filename: string, header: Object[], data: Object[], dataKeys: string[], options?: CreateCsvOptions) {
    let dialogRef = this.openSpinnerForSubComp(this.pageTitle, "準備中・・・");
    setTimeout(() => {
      this.createCsvBody(filename, header, data, dataKeys, options);
      this.closeSpinnerForSubComp(dialogRef);
    }, 0);
  }

  createCsvBody(filename: string, header: Object[], data: Object[], dataKeys: string[], options?: CreateCsvOptions) {
    let headerOptions = {};
    let csvData: Uint8Array[] = [];
    if (header) header.forEach((row) => {
      csvData = csvData.concat(this.createCsvData([row], undefined, headerOptions));
    });
    csvData = csvData.concat(this.createCsvData(data, dataKeys, options));

    let blob = new Blob(csvData, { type: "text/csv" });
    this.downloadBlob(blob, filename);
    /*
    const navigator: any = window.navigator;
    if (navigator.msSaveBlob) {
      // For IE10/11 : No download functionality
      navigator.msSaveBlob(blob, filename);
    } else {
      // Other browser
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      let download = document.createElement("a");
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
    */
  }

  createCsvHeaderFromColDef(colDefs: TableColumnDef[], columnIds: string[], leadingEmptyColumns?: number): Object {
    let header: Object = {};
    let comment = "# ";           // Comment character added to the first header item
    if (leadingEmptyColumns) {
      for (let i = 0; i < leadingEmptyColumns; i++) {
        header["autoGeneratedColumn-" + i] = comment + " ";
        comment = "";
      }
    }

    columnIds.forEach((id) => {
      let colDef = colDefs.find((col) => col.columnId === id);
      if (colDef) {
        header[id] = comment + colDef.header;
        comment = "";
      }
    });

    return header;
  }

  private createCsvData(data: Object[], outputKeys: string[], options: CreateCsvOptions): Uint8Array[] {
    let uint8CsvData: Uint8Array[] = [];
    if (!data) return uint8CsvData;

    let separator: string = "\n";
    let codeset: string = "UTF8";
    let ua = navigator.userAgent;
    if (ua.indexOf('Windows') > 0) {
      separator = "\r\n";
      codeset = "SJIS";
    }

    let keys: string[];
    if (!outputKeys && data.length > 0) {
      keys = Object.keys(data[0]);
    } else {
      keys = outputKeys;
    }
    data.forEach((row) => {
      let comma = "";
      let uint8Array: Uint8Array;
      for (let key of keys) {
        let column: string = "unkown data type";
        switch (typeof row[key]) {
          case "string":
            const regex = /"/ig;
            column = (row[key] as string).replace(regex, '""');
            if (codeset == "SJIS") {
              // convert to SJIS
              let sjisArray = Encoding.convert(column.split('').map((str) => str.charCodeAt(0)), 'SJIS', 'UNICODE');
              uint8Array = new Uint8Array(sjisArray);
            } else {
              let sjisArray = Encoding.convert(column.split('').map((str) => str.charCodeAt(0)), 'UTF8', 'UNICODE');
              uint8Array = new Uint8Array(sjisArray);
            }
            break;
          case "number":
            let numberFormat = undefined;
            if (options?.numberFormat) numberFormat = options.numberFormat;
            if (options?.numberFormatForKey && options.numberFormatForKey[key]) numberFormat = options.numberFormatForKey[key];
            if (numberFormat) {
              column = this.decimalPipe.transform(row[key], options.numberFormat);
            } else {
              column = "" + row[key];
            }
            uint8Array = new Uint8Array(column.split('').map((str) => str.charCodeAt(0)));
            break;
          case "boolean":
            if (row[key]) {
              column = "1";
            } else {
              column = "0";
            }
            uint8Array = new Uint8Array(column.split('').map((str) => str.charCodeAt(0)));
            break;
          default:
            uint8Array = new Uint8Array([]);
            break;
        }
        if (comma !== "") {
          uint8CsvData.push(new Uint8Array([comma.charCodeAt(0)]));
        }
        uint8CsvData.push(new Uint8Array(['"'.charCodeAt(0)]));
        uint8CsvData.push(uint8Array);
        uint8CsvData.push(new Uint8Array(['"'.charCodeAt(0)]));
        comma = ",";
      }
      uint8CsvData.push(new Uint8Array(separator.split('').map((str) => str.charCodeAt(0))));
    });

    return uint8CsvData;
  }

  getOrderStopSearchCondList(): OrderStopFlag[] {
    if (this.config?.orderStopFlag?.length <= 0) return [];
    return [...this.config?.orderStopFlag?.filter(orderStopItem => (orderStopItem?.searchCond))];
  }

  async b64ToBlob(base64: string, mimeType: string) {
    this.openSpinner(this.pageTitle, "読込み中・・・");
    return new Promise((resolve, reject) => {
      try {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // Create new Blob file
        var blob = new window.Blob([byteArray], { type: mimeType });
        resolve(blob)
        this.closeSpinner();
      } catch (error) {
        reject(error);
        this.closeSpinner();
      }
    })
  }

  async blobToB64(blob) {
    this.openSpinner(this.pageTitle, "読込み中・・・");
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result)
        this.closeSpinner();
      };
      reader.readAsDataURL(blob);
    });
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  zeroPadding(num, len) {
    return (Array(len).join('0') + num).slice(-len);
  }

  valueCheckOrderNum(form: FormControl, prevVal: number, newVal: number) {
    let warning = this.config.valueCheck.orderNumUpper.warning;
    let forbidden = this.config.valueCheck.orderNumUpper.forbidden;
    if (warning < 0 && forbidden < 0) return;
    if (forbidden >= 0) {
      if (prevVal <= forbidden && newVal > forbidden) {
        this.openErrorDialog(this.pageTitle, "発注数の上限値[" + forbidden + "]を超える値は入力できません。", this.config.valueCheck.orderNumUpper.btnText);
        form.setValue(prevVal);
        return;
      }
    }
    if (warning >= 0) {
      if (prevVal < warning && newVal >= warning) {
        this.openNotificationDialog(this.pageTitle, "発注数の警告値[" + warning + "]以上の値が入力されました。", this.config.valueCheck.orderNumUpper.btnText);
        return;
      }
    }
    if (this.config.valueCheck.orderNumRepeatingDigits?.count > 1) {
      let cnt = this.config.valueCheck.orderNumRepeatingDigits?.count - 1;
      let regStr = `([0-9])\\1{${cnt}}`;
      if (this.config.valueCheck.orderNumRepeatingDigits.isFull) {
        regStr = "^" + regStr + "$";
      }
      let reg = new RegExp(regStr);
      if (reg.test("" + prevVal) == false && reg.test("" + newVal) == true) {
        this.openNotificationDialog(this.pageTitle, "同じ数値が繰り返し入力されています。", this.config.valueCheck.orderNumRepeatingDigits.btnText);
        return;
      }
    }
  }

  public openErrorDialogWithAfterCloseDialog(title:string, message:string, btnText?: string) {
    const dialogRef = this.dialog.open(ErrorNotificationDialogComponent, {
      data: {errorTitle: title, errorMessage: message, btnText: btnText}
    });
    return dialogRef.afterClosed();
  }
}
