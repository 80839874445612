import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { DateRangeComponent } from 'src/app/partsCommon/date-range/date-range.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqGetPromOrderList, RspGetPromOrderList } from 'src/app/webservice/promOrder';
import { PromOrder, PromStore } from '../0_def/promOrder';
import { PromOrderEditComponent } from '../prom-order-edit/prom-order-edit.component';

@Component({
  selector: 'app-prom-order',
  templateUrl: './prom-order.component.html',
  styleUrls: ['./prom-order.component.css']
})
export class PromOrderComponent implements OnInit, OnDestroy {

  public tabSelection: number = 0;

  // Query Condition
  public qryDateBegin: Date;
  public qryDateEnd: Date;
  public formQryPromCode: FormControl = new FormControl("");
  public formQryPromName: FormControl = new FormControl("");

  // Query result
  public promOrderList: PromOrder[];
  public promOrderShowList: PromOrder[];
  public recordCount: number = 0;
  public columnIds: string[] = ["promCd", "promName", "dateBegin", "dateEnd", "orderDateEnd", "operationBtn"];
  public columnDefs:  TableColumnDef[] = [
    {columnId: "promCd", header: "企画コード", width: 100, align: "center"},
    {columnId: "promName", header: "企画名", width: 300, align: "leftr"},
    {columnId: "dateBegin", header: "開始日", width: 100, align: "center"},
    {columnId: "dateEnd", header: "終了日", width: 100, align: "center"},
    {columnId: "orderDateEnd", header: "発注期限", width: 100, align: "center"},
  ];
  public selectedPromOrder: PromOrder;
  public editingPromOrder: PromOrder;

  @ViewChild(DateRangeComponent, {static: true}) dateRangeComponent: DateRangeComponent;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(PromOrderEditComponent, {static: true}) promOrderEditComponent: PromOrderEditComponent;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService
  ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    this.qryDateBegin = new Date();
    this.qryDateEnd = new Date();
    this.qryDateEnd.setMonth(this.qryDateEnd.getMonth() + 1);

  }

  ngOnDestroy(): void {

  }

  dateChanged(event) {
    /*
    this.editTask.edits.dateBegin.setValue(event.dateBegin);
    this.editTask.edits.dateEnd.setValue(event.dateEnd);
    this.editTask.markAsDirty();
    */
  }

  qryProm() {
    let dateRage = this.dateRangeComponent.getDateRange();
    let request: ReqGetPromOrderList = {
      access:   this.commonService.loginUser,
      storeCd:  this.commonService.loginUser.stores.length > 1 ? "*" : this.commonService.loginUser.storeCd,
      promCd:   this.formQryPromCode.value,
      promName: this.formQryPromName.value,
      dateBegin:  this.commonService.formatDate(dateRage.dateBegin),
      dateEnd:    this.commonService.formatDate(dateRage.dateEnd),
      promTypes:  []
    }
    if (this.commonService.config.promOrderPromTypes) {
      this.commonService.config.promOrderPromTypes.forEach((promType) => {
        request.promTypes.push(promType);
      });
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetPromOrderList", request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveProm(response)
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveProm(response: RspGetPromOrderList) {
    if (this.httpBasic.handleAppError(response)) return;

    this.selectedPromOrder = undefined;
    this.editingPromOrder = undefined;
    this.promOrderList = [];
    response.rows.forEach((row) => {
      let promCd = row.promCd;
      let prom: PromOrder;
      prom = this.promOrderList.find((item) => item.promCd === promCd);
      if (prom) {
        prom.storeList.push({storeCd: row.storeCd, storeName: row.storeName});
      } else {
        prom = {
          promCd:         promCd,
          promName:       row.promName,
          dateBegin:      row.dateBegin,
          dateEnd:        row.dateEnd,
          orderDateBegin: row.orderDateBegin,
          orderDateEnd:   row.orderDateEnd,
          masterStatus:   row.masterStatus,
          storeList:      [{storeCd: row.storeCd, storeName: row.storeName}],
          editStatus:     0
        };
        this.promOrderList.push(prom);
      }
    });
    this.recordCount = this.promOrderList.length;
    if (this.matPaginator) {
      this.matPaginator.pageIndex = 0;
    }
    this.pageChanged();
  }

  operationBtnType(promOrder: PromOrder) {
    /*
      1: No button
      2: 参照・編集 button
      3: 参照・編集 button disabled
      4: 参照・編集中 label
      5: 参照・編集中 label dirty
    */
    let today = this.commonService.copyDate(new Date());
    let orderDateEndAfterMargin = this.isDateValid(promOrder.orderDateEnd) ?
      this.commonService.copyDate(new Date(promOrder.orderDateEnd)) :
      null;
    if (orderDateEndAfterMargin) orderDateEndAfterMargin.setDate(orderDateEndAfterMargin.getDate() - (this.commonService?.config?.promOrderDateMargin ?? 1))

    if (!this.isDateValid(promOrder.orderDateBegin)) return 1;
    if (!this.isDateValid(promOrder.orderDateEnd)) return 1;
    if (orderDateEndAfterMargin < today && promOrder.masterStatus == 0) return 1;
    if (this.editingPromOrder == undefined) return 2;
    if (this.editingPromOrder == promOrder) {
      if (promOrder.editStatus == 2) return 5;
      return 4;
    }
    if (this.editingPromOrder.editStatus == 2) {
      return 3;
    }
    return 2;
  }

  pageChanged() {
    this.promOrderShowList = [];
    let start;
    let end;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.promOrderList.length) end = this.promOrderList.length;
    for (let i = start; i < end; i++) {
      this.promOrderShowList.push(this.promOrderList[i]);
    }
  }

  selectPromOrder(promOrder: PromOrder) {
    this.selectedPromOrder = promOrder;
  }

  editPromOrder(promOrder: PromOrder) {
    this.editingPromOrder = promOrder;
    this.tabSelection = 1;
    this.selectPromOrder(promOrder);
  }

  canDeactivate() {
    if (this.editingPromOrder == undefined) return true;
    if (this.editingPromOrder.editStatus != 2) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

  isDateValid(input: string | Date): boolean {
    if (!input) return false;
    if (typeof input == 'string') {
      if (input.trim() == '') return false;
      const date = new Date(input);
      return (date instanceof Date && !isNaN(date.valueOf()));
    }

    return (input instanceof Date && !isNaN(input.valueOf()));
  }
}
