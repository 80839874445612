import * as ExcelJS from 'exceljs';
import { CommonService } from 'src/app/service/common.service';
import { SubTaskOtherStoresResultRecDto } from '../0_def/taskOtherStoresRec';
import { TaskStoreRec } from '../0_def/taskStoreRec';
import { ReqTaskImageData, ReqTaskImageThumbnailData, RspTaskImageData, RspTaskImageThumbnailData, TaskImageRecDto } from 'src/app/webservice/task';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MatDialogRef } from '@angular/material/dialog';

export class TaskImagesExcel {

  private workbook: ExcelJS.Workbook;
  private worksheet: ExcelJS.Worksheet;
  private taskRec: TaskStoreRec;
  private spinnerRef : MatDialogRef<any, any>;

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService
    ) {
  }

  async createAndDownload(taskRec: TaskStoreRec, otherStoreRecs: SubTaskOtherStoresResultRecDto[]) {
    this.spinnerRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "EXCEL作成中・・・");

    this.taskRec = taskRec;
    this.workbook = new ExcelJS.Workbook();
    this.worksheet = this.workbook.addWorksheet("タスク画像");

    this.worksheet.columns = [
      {width: 15, style: { font: { name: "メイリオ", size: 10 } }},
      {width: 40, style: { font: { name: "メイリオ", size: 10 } }},
      {width: 20, style: { font: { name: "メイリオ", size: 10 } }},
      {width: 20, style: { font: { name: "メイリオ", size: 10 } }},
      {width: 20, style: { font: { name: "メイリオ", size: 10 } }},
      {width: 30, style: { font: { name: "メイリオ", size: 10 } }}
    ];
    this.worksheet.views = [{showRuler: false}];

    this.titleLine();
    this.dateRage();
    this.description();
    this.detailHeader();
    let startLine = 17;
    for (let i = 0; i < otherStoreRecs.length; i++) {
      startLine = await this.detailLine(otherStoreRecs[i], startLine);
    }

    const uint8Array = await this.workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    let timestamp = this.commonService.formatDateTime(new Date());
    timestamp = timestamp.replace(/\//g, "");
    timestamp = timestamp.replace(/\:/g, "");
    timestamp = timestamp.replace(" ", "_");
    a.href = url;
    a.download = "TaskImages_" + timestamp + ".xlsx";
    a.click();
    a.remove();

    this.commonService.closeSpinnerForSubComp(this.spinnerRef);
  }

  titleLine() {
    let rowNum = 1;
    let row: ExcelJS.Row = this.worksheet.getRow(rowNum);
    row.getCell(1).value = "タスク";
    this.setBorder(row.getCell(1));
    row.getCell(2).value = this.taskRec.taskName;
    this.worksheet.mergeCells(1, 2, 1, 6);
    this.setBorder(row.getCell(2));
  }

  dateRage() {
    let rowNum = 2;
    let row: ExcelJS.Row = this.worksheet.getRow(rowNum);
    row.getCell(1).value = "期間";
    this.setBorder(row.getCell(1));
    row.getCell(2).value = this.taskRec.dateBegin + " - " + this.taskRec.dateEnd;
    this.worksheet.mergeCells(rowNum, 2, rowNum, 6);
    this.setBorder(row.getCell(2));
  }

  description() {
    let rowNum = 3;
    let rowNumEnd = 15;
    let row: ExcelJS.Row = this.worksheet.getRow(rowNum);
    row.getCell(1).value = "タスク内容";
    this.setBorder(row.getCell(1));
    row.getCell(1).alignment = { vertical: 'top', horizontal: 'left' };
    this.worksheet.mergeCells(rowNum, 1, rowNumEnd, 1);
    row.getCell(2).value = this.taskRec.description;
    this.worksheet.mergeCells(rowNum, 2, rowNumEnd, 6);
    this.setBorder(row.getCell(2));
    row.getCell(2).alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
  }

  detailHeader() {
    let rowNum = 16;
    let row: ExcelJS.Row = this.worksheet.getRow(rowNum);
    this.detailHeaderCell(row.getCell(1), "店舗名");
    this.detailHeaderCell(row.getCell(2), "子タスク名");
    this.detailHeaderCell(row.getCell(3), "ステータス");
    this.detailHeaderCell(row.getCell(4), "完了日時");
    this.detailHeaderCell(row.getCell(5), "担当者");
    this.detailHeaderCell(row.getCell(6), "参照画像");
  }

  detailHeaderCell(cell: ExcelJS.Cell, name: string) {
    cell.value = name;
    this.setBorder(cell);
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
    cell.fill = {
      type: "pattern",
      pattern:"solid",
      fgColor: {argb: "D0D0D0"}
    };
  }

  async getSubTaskImageData(imgData: TaskImageRecDto): Promise<string> {
    return new Promise((resolve, reject) => {
      if (imgData.image) {
        resolve(imgData.image);
        return;
      }

      let request: ReqTaskImageData = {
        access: this.commonService.loginUser,
        taskId: imgData.taskId,
        storeCd: imgData.storeCd,
        subTaskId: imgData.subTaskId,
        imageId: imgData.imageId
      };
      let subsc = this.httpBasic.generalRequest("TaskImageData", request).subscribe(
      // let subsc = this.httpBasic.generalRequest("TaskImageThumbnailData", request).subscribe(
        (response: RspTaskImageData) => {
          subsc.unsubscribe();
          if ((response.fatalError && response.fatalError.length > 0) || (response.normalError && response.normalError.length > 0)) {
            resolve ("");
            return;
          }
          imgData.image = response.imageData;
          // imgData.imageThumbnail = response.imageData;
          resolve(response.imageData);
          return;
        },
        (error) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(this.spinnerRef);
          this.httpBasic.handleError(error);
          reject("");
          return;
        }
      );
    });
  }

  async detailLine(storeRec: SubTaskOtherStoresResultRecDto, startLine: number): Promise<number> {
    let imgCnt = storeRec.imageList ? storeRec.imageList.length : 0;
    let rowNumStart = startLine;
    let rowNumEnd = startLine + imgCnt - 1;
    if (imgCnt === 0) {
      rowNumEnd = startLine;
    }
    let rowNum = rowNumStart;
    let row: ExcelJS.Row = this.worksheet.getRow(rowNum);
    row.getCell(1).value = storeRec.storeCd + ":" + storeRec.storeName;
    row.getCell(1).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    row.getCell(2).value = storeRec.subTaskName;
    row.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    row.getCell(3).value = storeRec.completeStatus;
    row.getCell(3).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    row.getCell(4).value = storeRec.completeDateTime;
    row.getCell(4).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    row.getCell(5).value = storeRec.subResponsible;
    row.getCell(5).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    for (let i = 1; i <= 5; i++) {
      let cell = row.getCell(i);
      this.setBorder(cell);
      this.worksheet.mergeCells(rowNumStart, i, rowNumEnd, i);
    }

    let maxWidth = 6 * 37.7;
    let maxHeight = 5.4 * 37.7;

    for (let rowNum = rowNumStart; rowNum <= rowNumEnd; rowNum++) {
      let row: ExcelJS.Row = this.worksheet.getRow(rowNum);
      // row.height = 160;
      this.setBorder(row.getCell(6));
      if (imgCnt === 0) continue;

      let base64Image = await this.getSubTaskImageData(storeRec.imageList[rowNum - rowNumStart]);
      if (base64Image === "") {
        row.getCell(6).value = "画像取得エラー";
        continue;
      }
      let imageType = base64Image.split("/")[1].split(";")[0];            // "data:image/png;base64,iVBORw0KG..."
      let found = ["jpeg", "png", "gif"].find((type) => type === imageType);
      if (!found) {
        row.getCell(6).value = "未サポートの画像種別";
        continue;
      }
      let imageId = this.workbook.addImage({
        base64: base64Image,
        extension: <"jpeg" | "png" | "gif">imageType,
      });
      let size = await this.getImageSize(base64Image);
      let imgWidth = size.width;
      let imgHeight = size.height;
      let scaleWidth = maxWidth / imgWidth;
      let scaleHeight = maxHeight / imgHeight;
      let scale = scaleWidth <= scaleHeight ? scaleWidth : scaleHeight;
      imgWidth *= scale;
      imgHeight *= scale;
      row.height = imgHeight * 0.81;

      this.worksheet.addImage(imageId,
        {
          tl: {col: 5.0 + 0.1, row: rowNum - 1 + 0.1 },
          ext: { width: imgWidth, height: imgHeight },
          editAs: "oneCell"
        }
      );
    }

    return new Promise((resolve) => { resolve(rowNumEnd + 1); });
  }

  async getImageSize(base64Image: string): Promise<{width: number, height: number}> {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => {
        resolve({width: img.width, height: img.height});
      };
      img.src = base64Image;
    });
  }

  setBorder(cell: ExcelJS.Cell) {
    cell.border = {
      top:    { style: "thin", color: { argb: "000000" }},
      left:   { style: "thin", color: { argb: "000000" }},
      right:  { style: "thin", color: { argb: "000000" }},
      bottom: { style: "thin", color: { argb: "000000" }}
    }
  }

}
