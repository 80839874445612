import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CsvData, CsvLineData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CsvTableComponent } from 'src/app/partsCommon/csv-table/csv-table.component';
import { ReqCsvImportDeadlineMaster } from 'src/app/request/req-csv-import-deadline-master';
import { ReqImportDeadlineMasterUpdate } from 'src/app/request/req-csv-import-deadline-master-update';
import { RspCsvCheckDto } from 'src/app/response/rsp-csv-import-deadline-master-check';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

export interface CsvImportDeadlineMasterPrioritize extends CsvLineData {
  isPrioritize: boolean;
}

@Component({
  selector: 'app-csv-import-deadline-master',
  templateUrl: './csv-import-deadline-master.component.html',
  styleUrls: ['./csv-import-deadline-master.component.css']
})
export class CsvImportDeadlineMasterComponent implements OnInit, OnDestroy {

  // private subscription$ = new Subject();
  public paramList: CsvData;
  public tableWidth: any;
  public displayColumnIds: string[];
  public columnDefs: TableColumnDef[] = [
    // { columnId: "noLine", header: "行番号", width: 100, align: "center" },
    // { columnId: "warning", header: "エラー／警告", width: 100, align: "center" },
    { columnId: "storeCd", header: "店舗コード", width: 65, align: "center" },
    { columnId: "storeName", header: "店舗名", width: 70, align: "left", csvComment: "[空欄可]" },
    { columnId: "categoryLevel", header: "カテゴリレベル", width: 80, align: "center" },
    { columnId: "sectionCd", header: "部門コード", width: 90, align: "center" },
    { columnId: "sectionName", header: "部門名", width: 100, align: "left", csvComment: "[空欄可]" },
    { columnId: "lItemCd", header: "大分類コード", width: 90, align: "center" },
    { columnId: "lItemName", header: "大分類名", width: 100, align: "left", csvComment: "[空欄可]" },
    { columnId: "mItemCd", header: "中分類コード", width: 90, align: "center" },
    { columnId: "mItemName", header: "中分類名", width: 100, align: "left", csvComment: "[空欄可]" },
    { columnId: "sItemCd", header: "小分類コード", width: 90, align: "center" },
    { columnId: "sItemName", header: "小分類名", width: 100, align: "left", csvComment: "[空欄可]" },
    { columnId: "itemCd", header: "商品コード", width: 90, align: "center" },
    { columnId: "itemName", header: "商品名", width: 100, align: "left", csvComment: "[空欄可]" },
    { columnId: "day1", header: "期限日数1", width: 48, align: "right" },
    { columnId: "action1", header: "対応1", width: 100, align: "left", csvByteLen: 1000 },
    { columnId: "day2", header: "期限日数2", width: 48, align: "right" },
    { columnId: "action2", header: "対応2", width: 100, align: "left", csvByteLen: 1000 },
    { columnId: "day3", header: "期限日数3", width: 48, align: "right" },
    { columnId: "action3", header: "対応3", width: 100, align: "left", csvByteLen: 1000 },
    { columnId: "day4", header: "期限日数4", width: 48, align: "right" },
    { columnId: "action4", header: "対応4", width: 100, align: "left", csvByteLen: 1000 },
    { columnId: "day5", header: "期限日数5", width: 48, align: "right" },
    { columnId: "action5", header: "対応5", width: 100, align: "left", csvByteLen: 1000 }
  ];
  public displayColumnDefs: TableColumnDef[] = [];
  @ViewChild(CsvTableComponent, { static: false }) csvTableComponent: CsvTableComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
  ) { }

  ngOnInit() {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    if (this.commonService.stores.length <= 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この機能を使用する権限がありません。");
      this.commonService.router.navigate(['top']);
      return;
    }

    this.displayColumnDefs = [...this.columnDefs];
  }

  ngOnDestroy(): void {
    /*
    this.subscription$.next();
    this.subscription$.complete();
    */
  }

  getCsvData(csvData: CsvData) {
    let listPrioritize: CsvImportDeadlineMasterPrioritize[] = [];
    let items = {};

    if (csvData.lines.length > 0) {
      for (let i = 0; i < csvData.lines.length; i++) {
        listPrioritize.push(
          {
            ...csvData.lines[i],
            isPrioritize: true
          });
        let storeCd = csvData.lines[i].columnData['storeCd'].trim();
        let ctg1 = csvData.lines[i].columnData['sectionCd'].trim();
        let ctg2 = csvData.lines[i].columnData['lItemCd'].trim();
        let ctg3 = csvData.lines[i].columnData['mItemCd'].trim();
        let ctg4 = csvData.lines[i].columnData['sItemCd'].trim();
        let itemCd = csvData.lines[i].columnData['itemCd'].trim();
        let key: string = "" + storeCd + "-" + ctg1 + "-" + ctg2 + "-" + ctg3 + "-" + ctg4 + "-" + itemCd;
        if (items[key] != undefined) {
          listPrioritize[items[key]].isPrioritize = false;
        }
        items[key] = i;
      }
    }
    const csvDataPrioritize: CsvLineData[] = [];
    listPrioritize.filter(item => item.isPrioritize).map((value: CsvImportDeadlineMasterPrioritize) => {
      const item: CsvLineData = {
        lineNumber: value.lineNumber,
        columnData: value.columnData,
        errorMsg:   value.errorMsg,
        warningMsg: value.warningMsg,
      }
      csvDataPrioritize.push(item);
    });
    csvData.lines = csvDataPrioritize;
    this.paramList = csvData;
    if (this.csvTableComponent) {
      this.csvTableComponent.init(this.paramList);
    }
  }

  isDisableUpdateBtn() {
    if (this.paramList == undefined) return true;
    if (this.paramList.errorCount == undefined) return true;
    if (this.paramList.errorCount > 0) return true;
    return false;
  }

  doConfirm() {
    const request: ReqCsvImportDeadlineMaster = {
      access: { ...this.commonService.loginUser },
      itemCsvCheck: this.getDataOnTable()
    }
    if (this.getDataOnTable().length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "データがありません。");
      return;
    }
    // this.isFormatDay(request);
    this.commonService.openSpinner(this.commonService.pageTitle, "確認中です・・・");
    let subsc = this.httpBasic.generalRequest("CsvImportDeadlineMasterCheck", request).subscribe(
      (response: RspCsvCheckDto) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveResult(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveResult(response: RspCsvCheckDto) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    if (response.result.length > 0) {

      response.result.map((item) => {
        if (item.errors) {
          this.paramList.lines[item.line].errorMsg = item.errors;

        }
        if (item.warnings) {
          this.paramList.lines[item.line].warningMsg = item.warnings;
        }
      });
    }
    this.csvTableComponent.handleError();
  }

  getDataOnTable() {
    const request = [];
    this.paramList.lines.map(value => {
      const item = {
        storeCd:       value.columnData['storeCd'],
        categoryLevel: isNaN(parseInt(value.columnData['categoryLevel'])) ? '-1' : value.columnData['categoryLevel'],
        sectionCd:     value.columnData['sectionCd'],
        lItemCd:       value.columnData['lItemCd'],
        mItemCd:       value.columnData['mItemCd'],
        sItemCd:       value.columnData['sItemCd'],
        itemCd:        value.columnData['itemCd'],
        day1:          value.columnData['day1'] == '' ? null : value.columnData['day1'],
        day2:          value.columnData['day2'] == '' ? null : value.columnData['day2'],
        day3:          value.columnData['day3'] == '' ? null : value.columnData['day3'],
        day4:          value.columnData['day4'] == '' ? null : value.columnData['day4'],
        day5:          value.columnData['day5'] == '' ? null : value.columnData['day5'],
        action1:       value.columnData['action1'],
        action2:       value.columnData['action2'],
        action3:       value.columnData['action3'],
        action4:       value.columnData['action4'],
        action5:       value.columnData['action5'],
      }
      request.push(item);
    });
    return request;
  }

  doUpdate() {
    const request: ReqImportDeadlineMasterUpdate = {
      access: { ...this.commonService.loginUser },
      itemCsvUpdate: this.getItemUpdate(),
    }

    if (request.itemCsvUpdate.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "テストするデータがありません");
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    let subsc = this.httpBasic.generalRequest("CsvImportDeadlineMasterUpdate", request).subscribe(
      (response: RspCsvCheckDto) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) return;
        this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }
  getItemUpdate(){
    const request = [];
    this.paramList.lines.map(value => {
      let sortData = [];
      sortData.push(
        { days: value.columnData['day1'] == '' || value.columnData['action1'] == '' ? null : value.columnData['day1'],  action: value.columnData['action1'] },
        { days: value.columnData['day2'] == '' || value.columnData['action2'] == '' ? null : value.columnData['day2'],  action: value.columnData['action2'] },
        { days: value.columnData['day3'] == '' || value.columnData['action3'] == '' ? null : value.columnData['day3'],  action: value.columnData['action3'] },
        { days: value.columnData['day4'] == '' || value.columnData['action4'] == '' ? null : value.columnData['day4'],  action: value.columnData['action4'] },
        { days: value.columnData['day5'] == '' || value.columnData['action5'] == '' ? null : value.columnData['day5'],  action: value.columnData['action5'] }
      );

      sortData.sort((a, b) => {
        if (a.days === null || parseInt(a.days) < parseInt(b.days)) {
          return 1;
        }

        if (b.days === null || parseInt(a.days) > parseInt(b.days)) {
          return -1;
        }

        return 0;
      });

      const item = {
        storeCd:       value.columnData['storeCd'],
        categoryLevel: isNaN(parseInt(value.columnData['categoryLevel'])) ? '-1' : value.columnData['categoryLevel'],
        sectionCd:     value.columnData['sectionCd'],
        lItemCd:       value.columnData['lItemCd'],
        mItemCd:       value.columnData['mItemCd'],
        sItemCd:       value.columnData['sItemCd'],
        itemCd:        value.columnData['itemCd'],
        day1:          sortData[0].days,
        day2:          sortData[1].days,
        day3:          sortData[2].days,
        day4:          sortData[3].days,
        day5:          sortData[4].days,
        action1:       sortData[0].action,
        action2:       sortData[1].action,
        action3:       sortData[2].action,
        action4:       sortData[3].action,
        action5:       sortData[4].action,
      }
      request.push(item);
    });
    return request;
  }

  clearProgressState() {
    this.commonService.closeSpinner();
  }
}
