import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MenuEditGroupDto, MenuEditItemDto, ReqMenuEdit, RspMenuEdit } from 'src/app/webservice/menu';

interface MenuItem {
  menuId: number;
  menuGroupId: number;
  menuName: string;
  menuPath: string;
  readOnly: number;
  dispOrder: number;
  roleCount: number;
  form?: FormControl;
  formId?: number;
  isDelete: boolean;
}

interface MenuGroup {
  menuGroupId: number;
  menuGroupName: string;
  dispOrder: number;
  menuItems: MenuItem[];
  menuItemsNew: MenuItem[];
  form?: FormControl;
  formId?: number;
}

@Component({
  selector: 'app-menu-edit',
  templateUrl: './menu-edit.component.html',
  styleUrls: ['./menu-edit.component.css']
})
export class MenuEditComponent implements OnInit, OnDestroy {

  public menuGroup: MenuGroup[];
  public selectedMenuGroup: MenuGroup;
  public menuGroupLeft: MenuGroup;
  public menuGroupRight: MenuGroup;
  public formGroupSelectLeft: FormControl = new FormControl();
  public formGroupSelectRight: FormControl = new FormControl();
  private subscriptionLeft: Subscription;
  private subscriptionRight: Subscription;
  public formGroup: FormGroup = new FormGroup({});
  private formIdCounter: number = 1;
  private newCounter: number = 1;

  public isMobile: boolean = false;

  @ViewChild("menuGroupTable", {static:true}) menuGroupTable: MatTable<any>;
  @ViewChild("menuItemTable", {static:false}) menuItemTable: MatTable<any>;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;

    let len = this.route.snapshot.url.length;
    if (len > 0) {
      let url: string = this.route.snapshot.url[len - 1].path;
      if (url == "menuEdit") this.isMobile = false;
      if (url == "menuEditMobile") this.isMobile = true;
    }

    this.getMenuGroup();
  }

  ngOnDestroy(): void {
    if (this.subscriptionLeft) this.subscriptionLeft.unsubscribe();
    if (this.subscriptionRight) this.subscriptionRight.unsubscribe();
  }

  getMenuGroup() {
    this.selectedMenuGroup = undefined;

    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: this.isMobile ? "mng/getMenuGroupMobile" : "mng/getMenuGroup",
      bindVariables: ["" + this.commonService.loginUser.roleId]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveMenuGroup(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveMenuGroup(response: RspExecuteQuery) {
    if (this.httpBasic.handleAppError(response)) {
      this.commonService.closeSpinner();
      return;
    }

    this.menuGroup = [];
    for (let item of response.rows) {
      let menu: MenuGroup = {
        menuGroupId: parseInt(item.colData[0]),
        menuGroupName: item.colData[1],
        dispOrder: parseInt(item.colData[2]),
        menuItems: [],
        menuItemsNew: [],
        form: new FormControl(item.colData[1], Validators.required),
        formId: this.formIdCounter++
      };
      this.menuGroup.push(menu);
    }
    this.menuGroup.sort((a, b) => {
      if (a.dispOrder < b.dispOrder) return -1;
      if (a.dispOrder > b.dispOrder) return 1;
      return 0;
    });
    if (this.menuGroup.length < 2) this.addGroup();
    if (this.menuGroup.length < 2) this.addGroup();

    this.getMenuItem();
  }

  getMenuItem() {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: this.isMobile ? "mng/getMenuItemMobile" : "mng/getMenuItem",
      bindVariables: []
    };

    // this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveMenuItem(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );

  }

  receiveMenuItem(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    for (let item of response.rows) {
      let menu: MenuItem = {
        menuId: parseInt(item.colData[0]),
        menuGroupId: parseInt(item.colData[1]),
        menuName: item.colData[2],
        menuPath: item.colData[3],
        readOnly: parseInt(item.colData[4]),
        dispOrder: parseInt(item.colData[5]),
        roleCount: parseInt(item.colData[6]),
        isDelete: false
      };
      let group = this.menuGroup.find(item => item.menuGroupId == menu.menuGroupId);
      if (group) {
        menu.form = new FormControl(menu.menuName, Validators.required);
        menu.formId = this.formIdCounter++;
        group.menuItems.push(menu);
      }
    }
    for (let group of this.menuGroup) {
      group.menuItems.sort((a, b) => {
        if (a.dispOrder < b.dispOrder) return -1;
        if (a.dispOrder > b.dispOrder) return 1;
        return 0;
      });
      group.menuItemsNew = [...group.menuItems];
    }

    this.initForm();
  }

  initForm() {
    // this.formGroup = new FormGroup({});
    for (let group of this.menuGroup) {
      this.formGroup.addControl("" + group.formId, group.form);
      for (let item of group.menuItems) {
        this.formGroup.addControl("" + item.formId, item.form);
      }
    }

    this.menuGroupLeft = this.menuGroup[0];
    this.formGroupSelectLeft.setValue(this.menuGroupLeft.formId);
    this.subscriptionLeft = this.formGroupSelectLeft.valueChanges.subscribe((value) => {this.selectGroupLeft(value);})
    this.menuGroupRight = this.menuGroup[1];
    this.formGroupSelectRight.setValue(this.menuGroupRight.formId);
    this.subscriptionRight = this.formGroupSelectRight.valueChanges.subscribe((value) => {this.selectGroupRight(value);})
  }

  tabChanged(page: number) {
    if (page == 0) {
      if (this.menuGroupTable) {
        this.menuGroupTable.renderRows();
      }
      if (this.selectedMenuGroup) {
        if (this.menuItemTable) {
          this.menuItemTable.renderRows();
        } else {
          setTimeout(()=>{this.menuItemTable.renderRows();}, 0)
        }
      }
    } else if (page == 1) {

    } else if (page == 2) {
      /*
      if (this.menuGroup.length >= 1 && this.menuGroupLeft == undefined) {
          this.menuGroupLeft = this.menuGroup[0];
          this.formGroupSelectLeft.setValue(this.menuGroupLeft.formId);
          this.subscriptionLeft = this.formGroupSelectLeft.valueChanges.subscribe((value) => {this.selectGroupLeft(value);})
      }
      if (this.menuGroup.length >= 2 && this.menuGroupRight == undefined) {
        if (this.menuGroupLeft == this.menuGroup[0]) {
          this.menuGroupRight = this.menuGroup[1];
        } else {
          this.menuGroupRight = this.menuGroup[0];
        }
        this.formGroupSelectRight.setValue(this.menuGroupRight.formId);
        this.subscriptionRight = this.formGroupSelectRight.valueChanges.subscribe((value) => {this.selectGroupRight(value);})
      }
      */
    }
  }

  selectGroup(group: MenuGroup) {
    this.selectedMenuGroup = group;
  }

  selectGroupLeft(value: string) {
    let formId = parseInt(value);
    this.menuGroupLeft = this.menuGroup.find(item => item.formId == formId);
  }
  selectGroupRight(value: string) {
    let formId = parseInt(value);
    this.menuGroupRight = this.menuGroup.find(item => item.formId == formId);
  }

  addGroup() {
    let formId = this.formIdCounter++;
    let menuName = "new-" + this.newCounter++;
    let menu: MenuGroup = {
      menuGroupId: -1,
      menuGroupName: menuName,
      dispOrder: 999,
      menuItems: [],
      menuItemsNew: [],
      form: new FormControl(menuName, Validators.required),
      formId: formId
    };
    this.menuGroup.push(menu);
    this.formGroup.addControl("" + menu.formId, menu.form);
    this.formGroup.markAsDirty();

    this.menuGroupTable.renderRows();
  }

  dropMenuGroup(event: CdkDragDrop<MenuGroup[]>) {
    moveItemInArray(this.menuGroup, event.previousIndex, event.currentIndex);
    this.formGroup.markAsDirty();
  }

  deleteMenuItem(item: MenuItem, isDelete: boolean) {
    item.isDelete = isDelete;
    this.formGroup.markAsDirty();
  }

  dropMenuItem(event: CdkDragDrop<MenuItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    this.formGroup.markAsDirty();
  }

  doCancel() {
    for (let group of this.menuGroup) {
      group.form.setValue(group.menuGroupName);
      group.form.markAsPristine();
      group.menuItemsNew = [...group.menuItems];
      for (let item of group.menuItemsNew) {
        item.form.setValue(item.menuName);
        item.form.markAsPristine();
      }
    }
    this.menuGroup.sort((item1, item2) => {
      if (item1.dispOrder < item2.dispOrder) return -1;
      if (item1.dispOrder > item2.dispOrder) return 1;
      return 0;
    });
    this.menuGroupTable.renderRows();
    if (this.menuItemTable) this.menuItemTable.renderRows();
  }

  doUpdate() {
    let groupDispOrder = 10;
    for (let group of this.menuGroup) {
      group.menuGroupName = group.form.value;
      if (group.menuGroupId == 0) {
        // Brycen group
        group.dispOrder = 1000;
      } else {
        group.dispOrder = groupDispOrder;
        groupDispOrder += 10;
      }
      group.form.markAsPristine();
      let itemDispOrder = 10;
      for (let item of group.menuItemsNew) {
        item.menuName = item.form.value;
        item.menuGroupId = group.menuGroupId;
        item.dispOrder = itemDispOrder;
        itemDispOrder += 10;
        item.form.markAsPristine();
      }
      group.menuItems = [...group.menuItemsNew];
    }
    this.formGroup.markAsPristine();

    let request: ReqMenuEdit = {
      access: this.commonService.loginUser,
      menuGroup: [],
      delMenu: [],
      isMobile: this.isMobile
    }

    for (let group of this.menuGroup) {
      let groupDto: MenuEditGroupDto = {
        menuGroupId: group.menuGroupId,
        menuGroupName: group.menuGroupName,
        dispOrder: group.dispOrder,
        menuItems: []
      };
      request.menuGroup.push(groupDto);
      for (let item of group.menuItemsNew) {
        let itemDto: MenuEditItemDto = {
          menuId: item.menuId,
          menuGroupId: item.menuGroupId,
          menuName: item.menuName,
          menuPath: item.menuPath,
          readOnly: item.readOnly,
          dispOrder: item.dispOrder
        }
        if (item.isDelete) {
          request.delMenu.push(itemDto);
          continue;
        }
        groupDto.menuItems.push(itemDto);
      }
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.menuEdit(request).subscribe(
      (response) => {
        subsc.unsubscribe();
        this.receiveUpdate(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveUpdate(response: RspMenuEdit /* RspMenu */) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.commonService.menuGroups = [...response.menuGroups];
    this.commonService.menuGroupsMobile = [...response.menuGroupsMobile];
    this.getMenuGroup();
    /*
    this.menuGroupTable.renderRows();
    this.menuItemTable.renderRows();
    */
  }

}
