import { Component, OnInit, OnDestroy } from '@angular/core';

import { CommonService } from '../../service/common.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { RspLogin } from 'src/app/response/rsp-login';
import { FormBuilder, Validators} from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/common/config';
import { ErrorNotificationDialogComponent } from 'src/app/dialog/error-notification-dialog/error-notification-dialog.component';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { NotificationService } from 'src/app/partsCommon/notification/notification.service';
import { RspMenu } from 'src/app/webservice/menu';
import { MessageService } from 'src/app/service/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  nextUrl: string;
  errMessage : string;
  loginSubscription: Subscription;
  confirmSubscription: Subscription;

  loginFormGroup = this.fb.group({
    userId : ['', Validators.required],
    passwd : ['', Validators.required]
  });

  constructor(private commonService: CommonService,
    private router: Router,
    private httpBasic : HttpBasicService,
    private http: HttpClient,
    public message: MessageService,
    public notificationService: NotificationService,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.commonService.pageTitle = "ログイン";

    if (!this.commonService.config) {
      this.commonService.openSpinner(this.commonService.pageTitle, "初期化中・・・");

      var href = location.href.split("/");
      if (environment.production) {
        if (location.href.endsWith("/bin/login")) {
          this.commonService.configUrl = location.href.replace(/\/bin\/login$/, "/config.json");
        } else {
          this.commonService.configUrl = href[0] + "//" + href[2] + "/PippiWebConf/" + href[3] + "/config.json";
        }
        /*
        this.commonService.configUrl = href[0] + "//" + href[2] + "/PippiWebConf/" + href[3] + "/config.json";
        */
      }

      this.http.get(this.commonService.configUrl + "?" + (new Date()).getTime())    // // add parameter To disable cache
      .subscribe(
        (data:{config:Config, literal:any}) => {
          this.commonService.closeSpinner();
          this.commonService.configDefault = {... data.config};
          this.commonService.literal = {... data.literal};
          this.commonService.setConfigDefault();
          this.commonService.config = {...this.commonService.configDefault};
          /*
          Moved to commonService.setConfigDefault()
          if (this.commonService.config.pagenatorOptions) {
            this.commonService.paginatorOption.pageSizeOptions = this.commonService.config.pagenatorOptions;
          }
          this.commonService.literal = {... data.literal};
          // config default
          if (!this.commonService.config.noisecut) {
            this.commonService.config.noisecut = {prom: true, customerOrder: true};
          }
          if (!this.commonService.config.orderGroup) {
            this.commonService.config.orderGroup = {maxUser: 3, itemSortByOrderNum: true};
          }
          // literal default
          if (!this.commonService.literal.customerOrder) this.commonService.literal.customerOrder = "客注";
          */

          setTimeout(() => this.scaleAndFontSize(), 0);
        },
        (error) => {
          this.commonService.closeSpinner();
          this.commonService.dialog.open(ErrorNotificationDialogComponent, {
            data: {errorTitle: this.commonService.pageTitle, errorMessage: "初期化中にネットワーク・エラーが発生しました。"}
          });
        }
      );
    }

    if (this.commonService.loginUser) {
      /*
      this.httpBasic.logout();
      this.commonService.loginUser = undefined;
      */
     this.confirmSubscription = this.commonService.openYesNoDialog(
       this.commonService.pageTitle,
       "ログアウトしますか？").subscribe(
        (answer: boolean) => this.getAnswer(answer)
       );
    }
  }

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  scaleAndFontSize() {
    if (this.commonService.config.browser == undefined) return;
    let element = document.getElementsByTagName("html")[0];
    if (this.commonService.config.browser.scale) {
      let scale = this.commonService.config.browser.scale / 100;
      element.style.transformOrigin = "top left";
      element.style.transform = "scale(" + scale + "," + scale + ")";
    }
    if (this.commonService.config.browser.fontSize) {
      element.style.fontSize = "" + this.commonService.config.browser.fontSize + "px";
    }
  }

  getAnswer(answer: boolean) {
    this.confirmSubscription.unsubscribe();
    if (answer) {
      this.commonService.loginUser = undefined;
      this.commonService.openDashboardHq = true;
      this.commonService.openDashboardStore = true;
      this.commonService.openNotification = true;
    } else {
      this.router.navigate(['top']);
    }
  }

  doLogin() {
    if (this.loginFormGroup.get("passwd").value == undefined || this.loginFormGroup.get("passwd").value === "") {
      let element = document.getElementById("passwd");
      if (element) element.focus();
      return;
    }
    if (!this.loginFormGroup.valid) {
      return;
    }
    this.errMessage = undefined;

    // Yabe
    let userId: string = this.loginFormGroup.controls["userId"].value;
    userId = userId.trim();
    this.loginFormGroup.controls["userId"].setValue(userId);
    let passwd: string = this.loginFormGroup.controls["passwd"].value;
    passwd = passwd.trim();
    this.loginFormGroup.controls["passwd"].setValue(passwd);

    this.loginSubscription = this.httpBasic.login(
      /*
      this.loginFormGroup.controls["userId"].value,
      this.loginFormGroup.controls["passwd"].value
      */
      userId,
      passwd
    ).subscribe(
        data => this.loginCheckResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        }
    );
    this.loginFormGroup.disable();
    this.commonService.openSpinner("ログイン", "ログイン中・・・")
  }

  clearProgressState () {
    this.loginFormGroup.enable();
    this.commonService.closeSpinner();
    this.loginSubscription.unsubscribe();
    this.loginSubscription = undefined;
  }

  loginCheckResult(response: any) {
    var rspLogin : RspLogin = {...response};

    if (rspLogin.fatalError && rspLogin.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("ログインエラー", rspLogin.fatalError[0].errMsg);
      this.clearProgressState();
      return;
    }

    if (rspLogin.normalError && rspLogin.normalError.length > 0) {
      this.errMessage = rspLogin.normalError[0].errMsg;
      this.clearProgressState();
      return;
    }

    // this.commonService.loginUser = this.commonService.loginUserMoc;
    this.commonService.loginUser = {...rspLogin.login};
    this.commonService.loginUser.realStoreCd = this.commonService.loginUser.storeCd;
    this.commonService.stores = [...rspLogin.login.stores];
    this.commonService.allStores = [...rspLogin.allStores];
    this.commonService.fullStores = [...rspLogin.fullStores];
    this.commonService.salesDate = rspLogin.salesDate;
    this.commonService.dateSalesDate = new Date(
      +rspLogin.salesDate.substring(0, 4),
      +rspLogin.salesDate.substring(5, 7) - 1,
      +rspLogin.salesDate.substring(8, 10)
    );
    this.commonService.serviceTimeStart = rspLogin.serviceTimeStart;
    this.commonService.serviceTimeEnd = rspLogin.serviceTimeEnd;
    this.commonService.config = {...this.commonService.configDefault};
    if (rspLogin.roleConfig !== undefined && rspLogin.roleConfig !== "") {
      /* override config value by roleConfig */
      let roleConfig = JSON.parse(rspLogin.roleConfig);
      for (let key in roleConfig) {
        this.commonService.config[key] = roleConfig[key];
      }
    }
    if (rspLogin.userConfig === "") {
      this.commonService.userConfig = {};
    } else {
      this.commonService.userConfig = JSON.parse(rspLogin.userConfig);
    }
    this.commonService.autoOrderStore = rspLogin.autoOrderStore;
    if (this.commonService.config.honbu && this.commonService.loginUser.storeCd === this.commonService.config.honbu.storeCd) {
      this.commonService.loginUser.storeName = this.commonService.config.honbu.storeName;
      if (this.commonService.stores.length == 1) {
        this.commonService.stores[0].storeName = this.commonService.config.honbu.storeName;
      } else if (this.commonService.stores.length > 1 &&
                 !this.commonService.stores.find((store) => store.storeCd === this.commonService.config.honbu.storeCd)) {
        /* default store code for store selection */
        this.commonService.loginUser.storeCd = this.commonService.stores[0].storeCd;
      }
    } else if (this.commonService.autoOrderStore === false) {
        /* default store code for store selection */
        this.commonService.loginUser.storeCd = this.commonService.stores[0].storeCd;
    }

    this.getMenu();
    
    // Get messages
    if (response.messageRows) {
      for (const m of response.messageRows) {
        this.message.message[m.msgId] = m.msg;
      }
    }

    return;
  }

  getMenu() {
    this.loginSubscription = this.httpBasic.getMenu().subscribe(
        data => this.getMenuCheckResult(data),
        error => {
          this.clearProgressState();
          this.httpBasic.handleError(error);
        }
    );
  }

  getMenuCheckResult(response: any) {
    var rspMenu : RspMenu = {...response};

    this.clearProgressState();

    if (rspMenu.fatalError && rspMenu.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("メニュー", rspMenu.fatalError[0].errMsg);
      return;
    }
    if (rspMenu.normalError && rspMenu.normalError.length > 0) {
      this.errMessage = rspMenu.normalError[0].errMsg;
      return;
    }
    this.commonService.menuGroups = [...rspMenu.menuGroups];
    this.commonService.menuGroupsMobile = [...rspMenu.menuGroupsMobile];

    if (this.commonService.urlAfterLogin) {
      this.router.navigate([this.commonService.urlAfterLogin], {queryParams: this.commonService.queryParamsAfterLogin});
      this.commonService.setPageMenu();
      this.commonService.urlAfterLogin = undefined;
      this.commonService.queryParamsAfterLogin = undefined;
    } else {
      if (this.commonService.userConfig?.initialPath) {
        let pathAndParam = this.commonService.getPathAndParams(this.commonService.userConfig.initialPath);
        this.commonService.urlAfterLogin = pathAndParam.path.substr(1);     // remove leading slash
        this.commonService.queryParamsAfterLogin = pathAndParam.params;
        this.router.navigate([this.commonService.urlAfterLogin], {queryParams: this.commonService.queryParamsAfterLogin});
        this.commonService.setPageMenu();
        this.commonService.urlAfterLogin = undefined;
        this.commonService.queryParamsAfterLogin = undefined;
        } else {
        this.router.navigate(['top']);
      }
    }

    window.dispatchEvent(new Event('loginComplete'));
  }

  onKeyup(event:any) {
    if (event.getModifierState && event.getModifierState("CapsLock")) {
      this.errMessage = "capslock が ON になっています。注意してください。";
    } else {
      this.errMessage = undefined;
    }
  }

  onScan(barcode: string) {
    let userid = barcode;
    if (this.commonService.config.loginBarcode) {
      userid = barcode.substring(
        this.commonService.config.loginBarcode.start,
        this.commonService.config.loginBarcode.end);
    }
    this.loginFormGroup.get("userId").setValue(userid);
    this.focusPasswd();
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          let userid = data;
          if (this.commonService.config.loginBarcode) {
            userid = data.substring(
              this.commonService.config.loginBarcode.start,
              this.commonService.config.loginBarcode.end);
          }
          this.loginFormGroup.get("userId").setValue(userid);
          this.focusPasswd();
        }
      }
    )
  }

  focusPasswd() {
    let element = document.getElementById("passwd");
    if (element) element.focus();
  }

}
