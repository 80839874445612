import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormControl, FormArray, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { formatDate } from '@angular/common';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { SectionHeaderUpComponent } from 'src/app/partsCommon/section-header-up/section-header-up.component';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { CommonService } from 'src/app/service/common.service';
import { ReqSpor00061Search} from 'src/app/request/req-spor00061-search';
import { RspSpor00061Search, RspSpor00061SearchDto } from 'src/app/response/rsp-spor00061-search';
import { RspSpor00061Update, RspSpor00061UpdateDto } from 'src/app/response/rsp-spor00061-update';
import { RspGetNotification, RspGetNotificationDto } from 'src/app/response/rsp-get-notification';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { StoreDto } from 'src/app/response/rsp-inventory-schedule-list';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NavigationExtras, Router } from '@angular/router';
import { InvMonthSearchDto } from 'src/app/request/req-inventory-month-list';
import { MessageService } from 'src/app/service/message.service';
import { UserInfoDto } from 'src/app/response/rsp-user-info';
import * as MessageID from 'src/app/common/const-message-id';
import { ReqSpor00061Update, Spor00061UpdateDto } from 'src/app/request/req-spor00061-update';
import { ReqSpor00061Insert, Spor00061InsertDto } from 'src/app/request/req-spor00061-insert';
import { ReqNonInventoryDataSearchProductNm } from 'src/app/request/req-non-inventory-data-search-productNm';
@Component({
  selector: 'app-spor000611',
  templateUrl: './spor00061.component.html',
  styleUrls: ['./spor00061.component.css']
})

export class Spor00061Component implements OnInit, OnDestroy {

  @ViewChild("sectionSuppHeader", { static: true }) sectionSupp: SectionHeaderComponent;
  @ViewChild("sectionHeader", { static: true }) sectionClassificationHeader: SectionHeaderUpComponent;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  private subscriptionInit: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscriptionConfirm: Subscription;
  public maxDate: Date;
  public minDateBegin: Date;
  public dateBegin: Date;
  public dateEnd: Date;
  public formGroup: FormGroup;
  public tableWidth: any;
  public cnt : number;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  // public columnIds: string[] = ["checkFlg","storeCd", "storeNm","un", "stockNumber","posCost", "salePrice","mainClass", "midClass","smallClass", "subClass"];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'requestedQty', header: "希望数", width: 100, align: "right" },
    { columnId: 'remainingStockQty', header: "残数", width: 100, align: "right" },
    { columnId: 'confirmQty', header: "引当可能数", width: 100, align: "right" },
    { columnId: 'avaliableQty', header: "余剰在庫数", width: 100, align: "right"},
    { columnId: 'storeNm', header: "店舗名称", width: 200},
    { columnId: 'storeCd', header: "店舗コード", width: 100 },
    { columnId: 'productNm', header: "商品名称", width: 250 },
    { columnId: 'productCd', header: "商品コード", width: 250},
    // { columnId: 'inventoryNo', header: "棚卸番号", width: 100},
    // { columnId: 'number', header: "行", width: 50},
    // { columnId: 'outgoingStockQty', header: "出庫数", width: 300, align: "right" },
  ];
  
  public qtyFormGroup: FormGroup;
  public formGroups: FormGroup[] = [];
  public paginatorMaxWidth: any;
  public storeCd : any;
  public sysModeCd: any;
  public searchExpandBtn: boolean;
  public stores: StoreDto[] = [];
  public updatedStoreList : any;
  public notificationList : RspGetNotificationDto[] = [];
  public spor00061SearchList :  RspSpor00061SearchDto[] = [];
  spor00061NotiList :  Spor00061InsertDto[] = []
  spor00061SearchListDisplay = new MatTableDataSource<RspSpor00061SearchDto>([]);
  public storeList: any;
  public displayColumns: string[] = ["isSelected", "menuGroupName", "menuName", "menuReadonly"];
  public loginStoreCd: any;
  public cmpCd: any; 
  public recordCount: number = 0;
  public start: number = 0;
  public btnDisabled :boolean;
  FUNCTIONID: string = "FPOR0006";
  NOTISUMMARY: string = "店間移動依頼確認";
  errorMsg = this.messageEntity.message[MessageID.ME100023].replace("%1", "出庫数").replace("%2","希望数量");
  userInfo: UserInfoDto[] = [];
  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    private router: Router,
    public messageEntity: MessageService,

  ) { }

  ngOnInit() {
    this.storeCd = this.commonService.loginUser.realStoreCd;
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.searchExpandBtn = true;
    this.btnDisabled = true;
    this.setDisplayCol();
    this.initFormGroup();
    this.initLists();
   

  }
  async initLists(): Promise<void> {

    // Load init data
    await this.getUserInfo();
    await this.initstoreList();
    this.commonService.closeSpinner();

  }
 
  ngOnDestroy() {
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.storeCd],
      productCd : [""],
      productNm : [""],
      dateType: ["1"],
      dateBegin: [new Date()],
      dateEnd: [new Date()]
    }
    );
    const qtyFormGroup = new FormGroup({
      outgoingStockQty: new FormControl('', [
          Validators.min(0),
          Validators.max(parseInt('')),
          Validators.max(parseInt('')),
          this.integerValidator()
      ]),
      transferReqId: new FormControl(''),
      inStoreCd : new FormControl(''),
      productCd : new FormControl(''),
      gMainClassCd : new FormControl(''),
      gMidClassCd : new FormControl(''),
      gSmallClassCd : new FormControl(''),
      gSubClassCd : new FormControl(''),
      updateTime : new FormControl(''),
  });
  this.formGroups = [];
  }
  getUserInfo() :Promise<void>{
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
      };
      let sub =this.httpBasic.generalRequest("GetUserInfo", request).subscribe(
        response => {
          sub.unsubscribe();
          this.receiveUserInfo(response);
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveUserInfo(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.userInfo = [];
    if (response.rows.length > 0) {
      this.userInfo = response.rows;
      this.cmpCd = this.userInfo[0].mainCmpnyCd;
      this.storeCd = this.userInfo[0].mainStoreCd;
      this.sysModeCd = this.userInfo[0].sysModeCd;
    }else{
      this.userInfo = [];
      this.cmpCd = "";
      this.storeCd = "";
      this.sysModeCd = "";
    }
  }


  initstoreList(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request: InvMonthSearchDto = {
        access: { ...this.commonService.loginUser },
        cmpyCd: this.cmpCd,
        storeCd: this.storeCd,
        sysModeCd: this.sysModeCd

      };
      let subs = this.httpBasic.generalRequest("GetStoreList", request).subscribe(
        response => {
          subs.unsubscribe();
          this.receiveStoreList(response);
          resolve();
        },
        error => {
          subs.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }


  onScan(barcode: string) {
    let productCd = barcode;
    if (this.commonService.config.loginBarcode) {
      productCd = barcode.substring(
        this.commonService.config.loginBarcode.start,
        this.commonService.config.loginBarcode.end);
    }
    this.formGroup.get("productCd").setValue(productCd);
    this.getProductName(productCd);
  }


  OnFocusOut() {
    let productCd = this.formGroup.get("productCd").value;
    this.getProductName(productCd);
  }

  getProductName(item: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const request: ReqNonInventoryDataSearchProductNm = {
        productCd: item,
        cmpyCd: this.cmpCd,
        access: { ...this.commonService.loginUser },
      };
      this.httpBasic.generalRequest("GetProductName", request).subscribe(
        response => {
          this.receiveProductNm(response);
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveProductNm(response) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length < 1) {
      this.formGroup.get("productNm").setValue("");
      return;
    }
    this.formGroup.get("productNm").setValue(response.rows[0].productNm);
  }

  onClickCellSupp(columnId: string, items: any,) {
   
  }

  async fnOnChangeStoreCd() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.commonService.closeSpinner();
  }



  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          let productCd = data;
          let userid = data;
          if (this.commonService.config.loginBarcode) {
            productCd = data.substring(
              this.commonService.config.loginBarcode.start,
              this.commonService.config.loginBarcode.end);
          }
          this.formGroup.get("productCd").setValue(productCd);
          this.getProductName(productCd);
        }
      }
    )
  }


 doSearch(){
  var request: ReqSpor00061Search = {
      storeCd: this.formGroup.get("storeCd").value,
      productCd: this.formGroup.get("productCd").value,
      productNm: this.formGroup.get("productNm").value,
      dateBegin: formatDate(this.formGroup.get("dateBegin").value, 'yyyy/MM/dd', 'en_US'),
      dateEnd: formatDate(this.formGroup.get("dateEnd").value, 'yyyy/MM/dd', 'en_US'),
      dateType: parseInt(this.formGroup.get("dateType").value),
      cmpyCd: this.cmpCd,
      access: { ...this.commonService.loginUser }
  }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.generalRequest("Spor00061Search", request).subscribe(
      result => this.receiveSearchData(result),
      error => {
        this.httpBasic.handleError(error);
      }
    );
  }
 
  
 receiveSearchData(response : RspSpor00061Search){
  this.clearProgressState();
  if (response.fatalError && response.fatalError.length > 0) {
    this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
    return;
  }
  if (response.normalError && response.normalError.length > 0) {
    this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
    return;
  }
  if (response.rows.length === 0) {
    this.spor00061SearchList = [];
    this.spor00061SearchListDisplay.data = [];
    return;
  }

  this.spor00061SearchList = [];
  this.formGroups = [];
  
  for (var data of response.rows) {
    this.spor00061SearchList.push(data);
    this.qtyFormGroup = new FormGroup({
        outgoingStockQty: new FormControl('', [
            Validators.min(0),
            Validators.max(parseInt(data.remainingStockQty)),
            Validators.max(parseInt(data.requestedQty)),
            this.integerValidator()
        ]),
        requestedQty: new FormControl(data.requestedQty),
        transferReqId: new FormControl(data.transferReqId),
        inStoreCd : new FormControl(data.storeCd),
        productCd : new FormControl(data.productCd),
        gMainClassCd : new FormControl(data.gMainClassCd),
        gMidClassCd : new FormControl(data.gMidClassCd),
        gSmallClassCd : new FormControl(data.gSmallClassCd),
        gSubClassCd : new FormControl(data.gSubClassCd),
        updateTime : new FormControl(data.updateTime),
    });
    this.formGroups.push(this.qtyFormGroup);
  }
  this.recordCount = this.spor00061SearchList?.length ?? 0;
  this.pageChanged();
 }

  doClear() {
    this.initLists();
    this.formGroup.controls["productCd"].setValue('');
    this.formGroup.controls["productNm"].setValue('');
    this.formGroup.controls["dateBegin"].setValue(new Date());
    this.formGroup.controls["dateEnd"].setValue(new Date());
    this.formGroup.controls["dateType"].setValue("1");
    this.spor00061SearchList = [];
    this.spor00061SearchListDisplay.data = [];
  }

  doConfirm(){
    if (this.checkForErrors()) {
    // let msg = "入力エラーがあります。入力内容をご確認下さい。<br>";
    // msg += "<br><table class='no-border'>";
    //   msg += "<tr class='no-border'>";
    //   msg += "<td class='no-border'>" + "希望数より大きい出庫数は入力できません。" + "</td>";
    //   msg += "</tr>";
    //   msg += "</table>";
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.errorMsg);
      return;

    }
    let hasErrors = false;

    const resultArray = this.formGroups.map((formGroup, index) => {
        const outStoreCd = this.formGroup.get("storeCd").value;
        const outgoingStockQty = parseInt(formGroup.get('outgoingStockQty')?.value, 10);
        const requestedQty = parseInt(this.spor00061SearchList[index].requestedQty, 10);
        const remainingStockQty = parseInt(this.spor00061SearchList[index].remainingStockQty, 10);
        const transferReqId = this.spor00061SearchList[index].transferReqId;
        const inStoreCd = this.spor00061SearchList[index].storeCd;
        const productCd = this.spor00061SearchList[index].productCd;
        const gMainClassCd = this.spor00061SearchList[index].gMainClassCd;
        const gMidClassCd = this.spor00061SearchList[index].gMidClassCd;
        const gSmallClassCd = this.spor00061SearchList[index].gSmallClassCd;
        const gSubClassCd = this.spor00061SearchList[index].gSubClassCd;
        const updateTime = this.spor00061SearchList[index].updateTime;
        if (isNaN(outgoingStockQty)) {
            hasErrors = true;
            formGroup.get('outgoingStockQty')?.setErrors({ invalidNumber: true });
            return null; 
        }

        let status; 
        if (outgoingStockQty === 0) {
            status = '2'; 
        } else if (requestedQty === outgoingStockQty) {
            status = '1'; 
        } else if (requestedQty > outgoingStockQty) {
            status = '2'; 
        }
        return {
            transferReqId,
            outgoingStockQty,
            requestedQty,
            status,
            inStoreCd,
            outStoreCd,
            productCd,
            gMainClassCd,
            gMidClassCd,
            gSmallClassCd,
            gSubClassCd,
            updateTime
        };
    }).filter(result => result !== null);
    if(resultArray.length < 1){
      let msg = "入力エラーがあります。入力内容をご確認下さい。<br>";
      this.commonService.openErrorDialog(this.commonService.pageTitle, msg);
      return;
      this.btnDisabled = true;

    }
    // else{
    //   this.btnDisabled = false;
    // }
    
    var request: ReqSpor00061Update = {
      spor00061UpdateStatus: resultArray,
      cmpyCd: this.cmpCd,
      functionId : this.FUNCTIONID,
      notisummary : this.NOTISUMMARY,
      access: { ...this.commonService.loginUser }
  }
    this.httpBasic.generalRequest("Spor00061Update", request).subscribe(
        result => this.receiveUpdatedData(result),
      error => {
        this.httpBasic.handleError(error);
      }
    );
  }
  
  getNotification(){
    var request = {
      cmpyCd: this.cmpCd,
      access: { ...this.commonService.loginUser }
  }
    // this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.generalRequest("GetNotificationData", request).subscribe(
      result => this.receiveNotificationData(result),
      error => {
        this.httpBasic.handleError(error);
      }
    );
  }

  registerNotification(){
    var request = {
      registerNotification: this.notificationList,
      storeList : this.updatedStoreList,
      access: { ...this.commonService.loginUser }
  }
    // this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.generalRequest("RegisterNotification", request).subscribe(
      result => this.receiveRegisteredData(result),
      error => {
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveNotificationData(response : RspGetNotification){
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.notificationList = [];
    if(response.rows.length > 0){
      for(var data of response.rows){
        this.notificationList.push(data);
      }
      this.registerNotification();
    }
  }


  receiveUpdatedData(response : any){
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.updatedStoreList = [];
    if(response.rows.length > 0){

      for (var data of response.rows){
        this.updatedStoreList.push(data.inStoreCd);
      }
      this.getNotification();
  
    }
    else{
      this.doSearch();
    }
    
  }

   receiveRegisteredData(response : any){
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.doSearch();
   }



   checkForErrors(): boolean {
    let hasErrors = false;

    this.formGroups.forEach((formGroup: FormGroup, index: number) => {
        const control = formGroup.get('outgoingStockQty');

        if (control) {
           
            control.updateValueAndValidity();

            if (control.invalid) {
                control.markAsTouched();  // Mark as touched to show validation errors
                hasErrors = true;
            }
        } 
    });

    return hasErrors;
}

 
  receiveStoreList(response: any) {
    if (this.httpBasic.handleAppError(response)) return;
    this.stores = [];
    if(response.rows.length > 0){
      this.formGroup.get("storeCd").setValue(this.storeCd);
      this.stores = response.rows;
    }
    if (this.stores.length === 1) {
      this.formGroup.get("storeCd").disable();
    } else {
      this.formGroup.get("storeCd").enable();
    }
  }

  checkQty() {
    const stockErrors = this.qtyFormGroup.errors;
    
  }


  integerValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value == null || value === '') {
        return null;
      }
      if (!/^-?\d*$/.test(value)) {
        return { integer: true };
      }
      const absValue = value.replace(/^[-+]/, '');
      return null;
    };
  }

  isValidSubmit() {
    if (this.formGroup.get("storeCd").value === "") return false;
    if (this.formGroup.get("dateBegin").value == null) return false;
    if (this.formGroup.get("dateEnd").value == null) return false;
    return true;
  }

  configCountValidator(control: AbstractControl): ValidationErrors | null {

    const outgoingStockQty = parseInt(control.get("outgoingStockQty")?.value);
    
    return null;
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = ["outgoingStockQty"];

    for (var colDef of this.columnDefs) {
      this.displayColumnDefs.unshift(colDef);
      this.displayColumnIds.unshift(colDef.columnId);
      this.calcTableWidth();
    }
  }
  
  calcTableWidth() {
    var width = 1;    
    width = width + 100 + 1 + 8;
    width = width + 100 + 1 + 8;            // For left border
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
    this.paginatorMaxWidth = { "max-width": "" + width + "px" };
  }


  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }


  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }
  

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }


  pageChanged() {
    let start;
    let end;
    this.spor00061SearchListDisplay.data = [];
    this.recordCount = this.spor00061SearchList?.length ?? 0;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
      end = start + this.matPaginator.pageSize;

      if (start >= this.recordCount) {
        this.matPaginator.pageIndex = Math.ceil(this.recordCount / this.matPaginator.pageSize) - 1;
        start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
        end = start + this.matPaginator.pageSize;
      }
    }
    if (end > this.recordCount) {
      end = this.recordCount;
    }
    this.start = start; 
    this.spor00061SearchListDisplay.data = this.spor00061SearchList?.slice(start, end) ?? [];
  }

  lastPage() {
    this.matPaginator.pageIndex = Math.floor(this.recordCount / this.matPaginator.pageSize);
    this.pageChanged();
  }

  // canDeactivate(): Observable<boolean> {
  //   return this.commonService.openYesNoDialog(this.commonService.pageTitle);
  // }

 

  getDateEndMin() {
    if (this.formGroup.get('dateBegin').value != null) {
      let min = this.formGroup.get('dateBegin').value;
    }
  }


  // getConfigChanges(): NonInvConfirmDto[] {
  //   let configs: NonInvConfirmDto[] = [];
  //   const checkboxesArray = this.checkGroup.get('checkboxes') as FormArray;
  //   checkboxesArray.controls.forEach((control: FormGroup) => {
  //     const invIdFv = control.get('invIdFv')?.value;
  //     const checkFlgFv = control.get('checkFlgFv')?.value;
  //     const functionId = control.get('functionId')?.value;
  //     let config: NonInvConfirmDto = {
  //       invIdFv: invIdFv,
  //       checkFlgFv: checkFlgFv,
  //       functionId: functionId,
  //     };
  //     configs.push(config);
  //   });
  //   return configs;
  // }

  getMaxHeight(): string {
    if (this.searchExpandBtn) {
      return '310px';
    } else {
      return '378px';
    }
    return 
  }

  getMinHeight(): string {
    return '310px';
  }

  getClassForColumn(columnId: string): string {
    if (columnId === 'productCdFv') {
      return 'custom-font-color';
    }
    return '';
  }


}

