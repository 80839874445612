import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Spmt10301Dto, ReqSpmt10301Confirm } from 'src/app/request/req-spmt10301-confirm';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Subscription } from 'rxjs';
import { ErrorNotificationDialogComponent } from '../error-notification-dialog/error-notification-dialog.component';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { FormControl } from '@angular/forms';
import { AccessInfo } from 'src/app/page/spmt10301/spmt10301.component';
import { MatPaginator } from '@angular/material/paginator';
import { Rsp } from 'src/app/response/rsp';
import { MI200002, MQ200003 } from 'src/app/common/const-message-id';
import { MessageService } from 'src/app/service/message.service';

class Spmt10301ConfirmDialogConstant{
  public static readonly messageSearching: string = '検索中・・・';
}

interface IMSCConfirmTableColumnDef extends TableColumnDef{
  isInputText: boolean;
}

class ItemSelect {
  slipNo: string;
  orgSlipNo: string;
  outStoreCd: string;
  outStoreNm: string;
  inStoreCd: string;
  inStoreNm: string;
  reqStatus: string;
  reqStatusNm: string;
  redBlackFlg: string;
  totalOutStockQty: string;
  totalOutOrderPriceExc: string;
  slipCreateDate: string;
  outRequesterNm: string;
  outApproverNm: string;
  inApproverNm: string;
  slipComment: string;
  approverComment: FormControl;
  delflg: string;
  updDatetime: string;
}

@Component({
  selector: 'app-spmt10301-confirm-dialog',
  templateUrl: './spmt10301-confirm-dialog.component.html',
  styleUrls: ['./spmt10301-confirm-dialog.component.css']
})
export class Spmt10301ConfirmDialogComponent implements OnInit, AfterViewInit {
  public columnIds: string[] = ["slipNo","orgSlipNo","outStoreCd","outStoreNm","inStoreCd","inStoreNm","reqStatusNm","totalOutStockQty","totalOutOrderPriceExc","slipCreateDate","outRequesterNm","outApproverNm","inApproverNm","slipComment","approverComment"]

  public columnDefsItems: IMSCConfirmTableColumnDef[] = [
    {columnId: "slipNo", header: "伝票番号", width: 90, align: "left", isInputText: false},
    {columnId: "orgSlipNo", header: "元伝票番号", width: 90, align: "left", isInputText: false},
    {columnId: "outStoreCd", header: "出庫店コード", width: 80, align: "left", isInputText: false},
    {columnId: "outStoreNm", header: "出庫店名称", width: 110, align: "left", isInputText: false},
    {columnId: "inStoreCd", header: "入庫店コード", width: 80, align: "left", isInputText: false},
    {columnId: "inStoreNm", header: "入庫店名称", width: 110, align: "left", isInputText: false},
    {columnId: "reqStatusNm", header: "申請状態", width: 80, align: "left", isInputText: false},
    {columnId: "totalOutStockQty", header: "出庫数量合計", width: 87, align: "right", isInputText: false, numberPipe: "1.0-0"},
    {columnId: "totalOutOrderPriceExc", header: "出庫原価金額合計", width: 105, align: "right", isInputText: false, numberPipe: "1.2-2"},
    {columnId: "slipCreateDate", header: "伝票作成日", width: 80, align: "center", isInputText: false},
    {columnId: "outRequesterNm", header: "出庫申請者", width: 85, align: "left", isInputText: false},
    {columnId: "outApproverNm", header: "出庫承認者", width: 85, align: "left", isInputText: false},
    {columnId: "inApproverNm", header: "入庫承認者", width: 85, align: "left", isInputText: false},
    {columnId: "slipComment", header: "出庫申請者コメント", width: 160, align: "left", isInputText: false},
    {columnId: "approverComment", header: "出庫承認者コメント", width: 160, align: "left", isInputText: true}
  ];

  public itemsShow: ItemSelect[] = [];
  public items: ItemSelect[] = [];
  public itemTableWidth: any;
  public itemRecordCount: number = 0;
  private subcriptionYesNoDialog: Subscription;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10301ConfirmDialogComponent>,
    public httpBasic: HttpBasicService,
    private message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: {searchResult: Spmt10301Dto[], accessInfo: AccessInfo},
    private changeDetectorRef: ChangeDetectorRef,
    public commonService: CommonService) { }

  ngOnInit() {
    this.calcTableWidth();
  }

  ngAfterViewInit() {
    this.initTable();
    this.changeDetectorRef.detectChanges();
  }

  initTable(){
    this.data.searchResult.forEach((item: Spmt10301Dto) => {
      this.items.push({
        ...item,
        approverComment: new FormControl(''),
      })
    })
    this.itemRecordCount = this.data.searchResult.length ?? 0;
    this.pageChanged();
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  calcTableWidth() {
    this.itemTableWidth = {"width": "100%"};
  }

  confirmBtn(){
    this.subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200003]).subscribe(data => {
      if(data) this.confirmPrc();
      this.subcriptionYesNoDialog.unsubscribe();
    })
  }

  getRequestData (){
    return this.items.map((item: ItemSelect) => ({
      ...item,
      approverComment: item.approverComment.value
    }));
  }

  confirmPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10301ConfirmDialogConstant.messageSearching);
    const request: ReqSpmt10301Confirm = {
      rows: this.getRequestData(),
      access: this.data.accessInfo
    } 
    const subsc = this.httpBasic.confirmSpmt10301(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: Rsp) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subscription = dialogRef.afterClosed().subscribe(() => {
        this.dialogRef.close(true);
        subscription.unsubscribe();
      })
      return;
    }

    const dialogRef = this.commonService.dialog.open(NotificationDialogComponent, {
      data: {title: this.commonService.pageTitle, message: this.message.message[MI200002]}
    });
    const subscription = dialogRef.afterClosed().subscribe(() => {
      this.dialogRef.close(true);
      subscription.unsubscribe();
    })
  }
}
