import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class JpDateAdapterModule extends NativeDateAdapter {
  getDateNames(): string[] {
    return Array.from(Array(31), (v, k) => `${k + 1}`);
  }

  format(date: Date, displayFormat: Object): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}/${('0' + month).slice(-2)}/${('0' + day).slice(-2)}`;
  }

  parse(value: string): Date {
    const date = new Date(
      +value.substring(0, 4),
      +value.substring(5, 7) - 1,
      +value.substring(8, 10)
    );
    return date;
  }
}
