import { FormControl } from "@angular/forms";
import { CommonService } from "src/app/service/common.service";
import { CaseItemDto } from "src/app/webservice/caseitem";
import { OrderByUserDto } from "../webservice/order-by-user";
import { ItemRenew } from "./item-renew";

export class ItemRec2 {

  public storeCdFv: string              = "";
  public storeNameFv?: string           = "";
  public itemCdFv: string               = "";
  public unitItemCdFv: string           = "";
  public itemNameFv: string             = "";
  public standardFv: string             = "";
  public ctgCd0Fv: string               = "";
  public ctgName0Fv: string             = "";
  public ctgCd1Fv: string               = "";
  public ctgName1Fv: string             = "";
  public ctgCd2Fv: string               = "";
  public ctgName2Fv: string             = "";
  public ctgCd3Fv: string               = "";
  public ctgName3Fv: string             = "";
  public suppCdFv: string               = "";
  public suppMstCdFv: string            = "";
  public suppNameFv: string             = "";
  public orderableDateFv: string        = "";
  public shipLtFv: string               = "";
  public orderLotFn: number             = 0;
  public itemCostPriceFn: number        = 0;
  public itemSalesPriceFn: number       = 0;
  public orderStopFlagFn: number        = 0;
  public orderStopFlagModFn: number     = 0;
  public orderStopFlagVersionFn: number = 0;
  public orderDate: string              = "";
  public orderDateNext: string          = "";
  public deliveryDate: string           = "";
  public deliveryDateNext: string       = "";
  // public shipDate: string               = "";
  // public shipDateNext: string           = "";
  public orderNumFn: number             = 0;
  public orderNumModFn: number          = 0;
  public orderNumVersionFn: number      = 0;
  public orderNumModNextFn: number      = 0;
  public orderNumVersionNextFn: number  = 0;
  public orderBacklog: number           = 0;
  public centerDeliv: number            = 0;
  public orderBaraNumFn: number         = 0;
  public stockNumFn: number             = 0;
  public stockNumModFn: number          = 0;
  public stockNumVersionFn: number      = 0;
  public rankFv: string                 = "";
  public itemClass: string              = "";
  // public orderClass: string             = "";
  public itemLocation: string           = "";
  public orderPointFn: number           = 0;
  public orderPointSubFn: number        = 0;
  public adqNumFn: number               = 0;
  public salesResult1: number           = 0;
  public salesResult2: number           = 0;
  public salesResult3: number           = 0;
  public salesResult4: number           = 0;
  public orderResult1: number           = 0;
  public orderResult2: number           = 0;
  public orderResult3: number           = 0;
  public orderResult4: number           = 0;
  public minZaiIsNull: boolean          = true;
  public minZaiFn: number               = 0;
  public minZaiModIsNull: boolean       = true;
  public minZaiModFn: number            = 0;
  public minZaiVersionFn: number        = 0;
  public maxZaiIsNull: boolean          = true;
  public maxZaiFn: number               = 0;
  public maxZaiModIsNull: boolean       = true;
  public maxZaiModFn: number            = 0;
  public maxZaiVersionFn: number        = 0;
  public minStockDaysIsNull: boolean    = true;
  public minStockDaysFn: number         = 0;
  public minStockDaysModIsNull: boolean = true;
  public minStockDaysModFn: number      = 0;
  public minStockDaysVersionFn: number  = 0;
  public maxStockDaysIsNull: boolean    = true;
  public maxStockDaysFn: number         = 0;
  public maxStockDaysModIsNull: boolean = true;
  public maxStockDaysModFn: number      = 0;
  public maxStockDaysVersionFn: number  = 0;

  public autoOrderLotModFn?: number      = 0;
  public autoOrderLotFn?: number         = 0;
  public autoOrderLotModVersionFn?: number = 0;
  public autoOrderLotModCurrentFv?: string;
  
  public minZaiCtg: number              = 0;
  public maxZaiCtg: number              = 0;
  public minStockDaysCtg: number        = 0;
  public maxStockDaysCtg: number        = 0;
  public closingTime: number            = 0;
  public newItem: boolean               = false;
  public ctg0Fv: string                 = "";
  public ctg1Fv: string                 = "";
  public ctg2Fv: string                 = "";
  public ctg3Fv: string                 = "";
  public caseConvFactFn: number         = 1;
  public orderNumCurrentFn: number;
  public stockNumCurrentFn: number;
  public minZaiCurrentFv: string;
  public maxZaiCurrentFv: string;
  public minStockDaysCurrentFv: string;
  public maxStockDaysCurrentFv: string;
  public orderStopFlagCurrentFn: number;
  public orderStopFlagCurrentFv: string;
  public orderBaraNumCurrentFn: number = 0;

  public isClosed: boolean              = false;
  public orderResult1IsToday            = false;

  public orderNumForm: FormControl = new FormControl(0);
  public orderBaraNumForm: FormControl = new FormControl(0);
  public stockNumForm: FormControl = new FormControl(0);

  public caseItems: CaseItemDto[];
  public caseOrderItems: ItemRec2[];
  public fromItems: ItemRenew[];
  public toItems: ItemRenew[];

  constructor(private commonService: CommonService) {
  }

  initByDto(dto: OrderByUserDto) {
    for (let key of Object.keys(dto)) {
      this[key] = dto[key];
    }
    this.ctg0Fv = this.ctgCd0Fv + ":" + this.ctgName0Fv;
    this.ctg1Fv = this.ctgCd1Fv + ":" + this.ctgName1Fv;
    this.ctg2Fv = this.ctgCd2Fv + ":" + this.ctgName2Fv;
    this.ctg3Fv = this.ctgCd3Fv + ":" + this.ctgName3Fv;

    if (!this.deliveryDate || this.deliveryDate === "" || this.orderNumFn == 0) this.deliveryDate = this.calcDeliveryDate(this.orderDate);
    if (this.orderNumModNextFn > 0) {
      // 翌発注日の登録あり
      if (this.orderableWeekday()) {
        // 発注可能曜日 --> 締め時間を超えているため、翌発注日の登録あり
        this.isClosed = true;
        // 発注実績の更新
        let orderNumPrev = this.getValue("orderNumFn");
        if (orderNumPrev > 0 && this.orderResult1IsToday == false) {
          this.orderResult4 = this.orderResult3;
          this.orderResult3 = this.orderResult2;
          this.orderResult2 = this.orderResult1;
          if (this.commonService.config.orderEditInBara) {
            this.orderResult1 = orderNumPrev * this.orderLotFn;
          } else {
            this.orderResult1 = orderNumPrev;
          }
          this.orderResult1IsToday = true;
        }
      }
      this.orderDate = this.orderDateNext;
      this.deliveryDate = this.deliveryDateNext;
      if (!this.deliveryDate || this.deliveryDate === "") this.deliveryDate = this.calcDeliveryDate(this.orderDate);
      this.orderNumModFn = this.orderNumModNextFn;
      this.orderNumVersionFn = this.orderNumVersionNextFn;
    } else {
      // 翌発注日の登録なし
      if (this.orderableDateFv != "0000000") {
        if (this.orderDate === "") {
          this.orderDate = this.getOrderableDate();
          this.deliveryDate = this.calcDeliveryDate(this.orderDate);
        }
        this.orderDateNext = this.getNextOrderableDate(this.orderDate);
        this.deliveryDateNext = this.calcDeliveryDate(this.orderDateNext);
        if (!this.orderableWeekday() && this.orderDate === this.commonService.formatDate(new Date())) {
          // 発注不可曜日 ---> 翌発注日のデータをセット
          this.orderDate = this.orderDateNext;
          this.deliveryDate = this.deliveryDateNext;
          this.orderNumModFn = 0;
          this.orderNumVersionFn = 0;
        }
      } else {
        // 発注可能曜日の設定なし
        let d = new Date();
        if (this.orderDate === "") this.orderDate = this.commonService.formatDate(d);
        if (this.deliveryDate === "") this.deliveryDate = this.calcDeliveryDate(this.orderDate);
        d.setDate(d.getDate() + 1);
        this.orderDateNext = this.commonService.formatDate(d);
        this.deliveryDateNext = this.calcDeliveryDate(this.orderDateNext);
      }
    }
    this.orderNumForm.setValue(this.getValue("orderNumFn"));
    this.orderBaraNumForm.setValue(this.getValue("orderBaraNumFn"));
    this.stockNumForm.setValue(this.getValue("stockNumFn"));

    if (this.orderNumVersionFn > 0) {
      this.orderNumCurrentFn = this.orderNumModFn;
    } else {
      this.orderNumCurrentFn = this.orderNumFn;
    }
    if (this.stockNumVersionFn > 0) {
      this.stockNumCurrentFn = this.stockNumModFn;
    } else {
      this.stockNumCurrentFn = this.stockNumFn;
    }

    if (this.minZaiVersionFn > 0) {
      this.minZaiCurrentFv = this.paramCurrent(this.minZaiModFn);
    } else {
      this.minZaiCurrentFv = this.paramCurrent(this.minZaiFn);
    }

    if (this.maxZaiVersionFn > 0) {
      this.maxZaiCurrentFv = this.paramCurrent(this.maxZaiModFn);
    } else {
      this.maxZaiCurrentFv = this.paramCurrent(this.maxZaiFn);
    }

    if (this.minStockDaysVersionFn > 0) {
      this.minStockDaysCurrentFv = this.paramCurrent(this.minStockDaysModFn);
    } else {
      this.minStockDaysCurrentFv = this.paramCurrent(this.minStockDaysFn);
    }

    if (this.maxStockDaysVersionFn > 0) {
      this.maxStockDaysCurrentFv = this.paramCurrent(this.maxStockDaysModFn);
    } else {
      this.maxStockDaysCurrentFv = this.paramCurrent(this.maxStockDaysFn);
    }

    if (this.orderStopFlagVersionFn > 0) {
      this.orderStopFlagCurrentFn = this.orderStopFlagModFn;
    } else {
      this.orderStopFlagCurrentFn = this.orderStopFlagFn;
    }

    if (this.autoOrderLotModVersionFn > 0) {
      this.autoOrderLotModCurrentFv = this.paramCurrent(this.autoOrderLotModFn);
    } else {
      this.autoOrderLotModCurrentFv = this.paramCurrent(this.autoOrderLotFn);
    }

    this.orderStopFlagCurrentFv = this.commonService.getOrderStopFlagName(this.orderStopFlagCurrentFn);
    this.orderBaraNumCurrentFn = this.orderNumCurrentFn * this.orderLotFn || 0;

  }

  getValue(key: string) {
    switch(key) {
      case "itemNameFv":
        if (this.newItem) {
          return "新：" + this.itemNameFv;
        } else {
          return this.itemNameFv;
        }
      case "orderNumFn":
        return this.orderNumVersionFn > 0 ? this.orderNumModFn : this.orderNumFn;
      case "orderNumForm":
        return parseInt(this.orderNumForm.value) || 0;
      case "stockNumFn":
        return this.stockNumVersionFn > 0 ? this.stockNumModFn : this.stockNumFn;
      case "stockNumForm":
        return parseInt(this.stockNumForm.value) || 0;
      case "deliveryDate":
        if (this.getValue("orderNumFn") > 0 || this.getValue("orderNumForm") > 0) return this.deliveryDate;
        return "";
      case "orderStopFlagFn":
        let flag = this.orderStopFlagVersionFn > 0 ? this.orderStopFlagModFn : this.orderStopFlagFn;
        return this.commonService.getOrderStopFlagName(flag);
      case "orderStopFlagFnValue":
        return this.orderStopFlagVersionFn > 0 ? this.orderStopFlagModFn : this.orderStopFlagFn;
      case "minZaiFn":
        let minZai = this.minZaiVersionFn > 0 ? this.minZaiModFn : this.minZaiFn;
        if (minZai === 0) return "[" + this.minZaiCtg + "]";
        return minZai;
      case "minZaiFnEdit":
        let minZaiEdit = this.minZaiVersionFn > 0 ? this.minZaiModFn : this.minZaiFn;
        if (minZaiEdit === 0) return "";
        return minZaiEdit;
      case "maxZaiFn":
        let maxZai = this.maxZaiVersionFn > 0 ? this.maxZaiModFn : this.maxZaiFn;
        if (maxZai === 0) return "[" + this.maxZaiCtg + "]";
        return maxZai;
      case "maxZaiFnEdit":
        let maxZaiEdit = this.maxZaiVersionFn > 0 ? this.maxZaiModFn : this.maxZaiFn;
        if (maxZaiEdit === 0) return "";
        return maxZaiEdit;
      case "minStockDaysFn":
        let minStockDays = this.minStockDaysVersionFn > 0 ? this.minStockDaysModFn : this.minStockDaysFn;
        if (minStockDays === 0) return "[" + this.minStockDaysCtg + "]";
        return minStockDays;
      case "minStockDaysFnEdit":
        let minStockDaysEdit = this.minStockDaysVersionFn > 0 ? this.minStockDaysModFn : this.minStockDaysFn;
        if (minStockDaysEdit === 0) return "";
        return minStockDaysEdit;
      case "maxStockDaysFn":
        let maxStockDays = this.maxStockDaysVersionFn > 0 ? this.maxStockDaysModFn : this.maxStockDaysFn;
        if (maxStockDays === 0) return "[" + this.maxStockDaysCtg + "]";
        return maxStockDays;
      case "maxStockDaysFnEdit":
        let maxStockDaysEdit = this.maxStockDaysVersionFn > 0 ? this.maxStockDaysModFn : this.maxStockDaysFn;
        if (maxStockDaysEdit === 0) return "";
        return maxStockDaysEdit;
      case "orderBaraNumFn":
        return this.orderLotFn * this.orderNumForm.value || 0;
      case "orderBacklog":
        if (this.centerDeliv > 0) {
          return "" + this.orderBacklog + "(" + this.centerDeliv + ")";
        } else {
          return this.orderBacklog;
        }
      case "autoOrderLotFn": 
        let autoOrderLot = this.autoOrderLotModVersionFn > 0 ? this.autoOrderLotModFn : this.autoOrderLotFn;
        if(autoOrderLot === 0) return "";
        return autoOrderLot;
      case "autoOrderLotFnEdit":
        let autoOrderLotEdit = this.autoOrderLotModVersionFn > 0 ? this.autoOrderLotModFn : this.autoOrderLotFn;
        if (autoOrderLotEdit === 0) return "";
        return autoOrderLotEdit;
      case "orderResult1":
      case "orderResult2":
      case "orderResult3":
      case "orderResult4":
        // if (this.commonService.config.orderEditInBara) return this[key] * this.orderLotFn;
        return this[key];
      default:
        return this[key];
    }
  }

  isEdited(key: string) {
    switch(key) {
      case "orderNum":
        if (this.orderNumVersionFn > 0) return true;
        if (this.orderNumForm.value !== this.orderNumFn) return true;
        return false
      case "stockNum":
        if (this.stockNumVersionFn > 0) return true;
        if (this.stockNumForm.value !== this.stockNumFn) return true;
        return false
    }
    return false;
  }

  cancel() {
    this.orderNumForm.setValue(this.getValue("orderNumFn"));
    this.orderBaraNumForm.setValue(this.getValue("orderBaraNumFn"));
    this.stockNumForm.setValue(this.getValue("stockNumFn"));
    this.orderNumForm.markAsPristine();
    this.orderBaraNumForm.markAsPristine();
    this.stockNumForm.markAsPristine();
  }

  orderEditable() {
    if (this.orderableDateFv === "0000000") return false;       // 発注可能曜日無し
    let orderStopFlag = this.getValue("orderStopFlagFnValue")
    let def = this.commonService.config.orderStopFlag.find((def) => def.value === orderStopFlag);
    if (def == undefined) return false;
    if (def.canOrder) return true;
    if (def.extendedOrder) return true;
    if (def.caseItem && this.commonService.config.orderEditCaseItem) return true;
    return false;
  }

  stockEditable() {
    let orderStopFlag = this.getValue("orderStopFlagFnValue")
    let def = this.commonService.config.orderStopFlag.find((def) => def.value === orderStopFlag);
    if (def == undefined) return false;
    if (def.caseItem && !this.commonService.config.stockEditCaseItem) return false;
    return true;
  }

  orderableWeekday(orderDate?: string) {
    let pos;
    if (!orderDate) {
      pos = this.commonService.dateSalesDate.getDay();
    } else {
      pos = this.commonService.getDate(orderDate).getDay();
    }
    if (this.orderableDateFv.substring(pos, pos + 1) === "1") return true;
    return false;
  }

  getOrderableDate() {
    let d = this.commonService.copyDate(this.commonService.dateSalesDate);
    let count = 0;
    while (this.orderableDateFv.substring(d.getDay(), d.getDay() + 1) === "0") {
      d.setDate(d.getDate() + 1);
      count++;
      if (count >= 7) return "";
    }
    return this.commonService.formatDate(d);
  }

  getNextOrderableDate(orderDate: string) {
    let d = this.commonService.getDate(orderDate);
    d.setDate(d.getDate() + 1);
    let count = 0;
    while (this.orderableDateFv.substring(d.getDay(), d.getDay() + 1) === "0") {
      d.setDate(d.getDate() + 1);
      count++;
      if (count >= 7) return "";
    }
    return this.commonService.formatDate(d);
  }

  /*
  calcDeliveryDate() {
    if (this.orderDate === "") return "";

    let d = this.commonService.getDate(this.orderDate);
    let lt = parseInt(this.shipLtFv.substring(d.getDay() * 2, d.getDay() * 2 + 2));
    d.setDate(d.getDate() + lt);
    return this.commonService.formatDate(d);
  }
  */
  calcDeliveryDate(orderDate: string) {
    if (orderDate === "") return "";

    let d = this.commonService.getDate(orderDate);
    let lt = parseInt(this.shipLtFv.substring(d.getDay() * 2, d.getDay() * 2 + 2));
    d.setDate(d.getDate() + lt);
    return this.commonService.formatDate(d);
  }

  orderDateAdjusted(orderDate: string, orderNum: number, version: number) {
    // Today's order is adjusted to the next orderable date.
    this.isClosed = true;
    let orgOrderDate = this.orderDate;
    this.orderDate = orderDate;
    this.deliveryDate = this.calcDeliveryDate(orderDate);
    this.orderDateNext = this.getNextOrderableDate(orderDate);
    this.deliveryDateNext = this.calcDeliveryDate(this.orderDateNext);

    let orderNumPrev = this.getValue("orderNumFn");
    let today = this.commonService.formatDate(new Date());
    if (orderNumPrev > 0 && this.orderResult1IsToday == false && orgOrderDate === today) {
      this.orderResult4 = this.orderResult3;
      this.orderResult3 = this.orderResult2;
      this.orderResult2 = this.orderResult1;
      if (this.commonService.config.orderEditInBara) {
        this.orderResult1 = orderNumPrev * this.orderLotFn;
      } else {
        this.orderResult1 = orderNumPrev;
      }
      this.orderResult1IsToday = true;
    }

    this.orderNumFn = 0;
    this.orderNumModFn = orderNum;
    this.orderNumVersionFn = version;

    this.orderNumForm.setValue(this.getValue("orderNumFn"));
    this.orderBaraNumForm.setValue(this.getValue("orderBaraNumFn"));
  }

  addOrderDate(orderDate: string, orderNum: number, version: number) {
    // this.isClosed = true;
    if (this.orderDate < orderDate) {
      // This item should be today's order
      this.orderDateAdjusted(orderDate, orderNum, version);
    } else if (this.orderDate > orderDate) {
      // This item is the next orderable date order
      let today = this.commonService.formatDate(new Date());
      if (orderNum > 0 && this.orderResult1IsToday == false && orderDate === today) {
        this.orderResult4 = this.orderResult3;
        this.orderResult3 = this.orderResult2;
        this.orderResult2 = this.orderResult1;
        if (this.commonService.config.orderEditInBara) {
          this.orderResult1 = orderNum * this.orderLotFn;
        } else {
          this.orderResult1 = orderNum;
        }
        this.orderResult1IsToday = true;
      }
    }
  }

  checkClosingTime(propClosingTime: Date, serverTime: Date) {
    if (this.isClosed) return;              // already closed
    if (this.caseOrderItems) {
      this.caseOrderItems.forEach((citem) => { citem.checkClosingTime(propClosingTime, serverTime); })
    }
    let today = this.commonService.formatDate(new Date());
    if (this.orderableDateFv !== "0000000") {
      if (this.getOrderableDate() != today) return;
    } else {
      if (this.orderDate != today) return;
    }

    let closingTime: Date = propClosingTime;
    if (this.closingTime > 0) {
      closingTime = new Date();
      closingTime.setHours(Math.floor(this.closingTime / 100), this.closingTime % 100, 0);
    }
    if (serverTime.getTime() < closingTime.getTime()) return;

    this.isClosed = true;
    if (today !== this.orderDate) return;   // not today's order
    let orgOrderDate = this.orderDate;
    this.orderDate = this.orderDateNext;
    this.deliveryDate = this.deliveryDateNext;
    /*
    this.deliveryDate = this.calcDeliveryDate(this.orderDate);
    this.orderDateNext = this.getNextOrderableDate(this.orderDate);
    this.deliveryDateNext = this.calcDeliveryDate(this.orderDateNext);
    */

    let orderNumPrev = this.getValue("orderNumFn");
    if (orderNumPrev > 0 && this.orderResult1IsToday == false && orgOrderDate === today) {
      this.orderResult4 = this.orderResult3;
      this.orderResult3 = this.orderResult2;
      this.orderResult2 = this.orderResult1;
      if (this.commonService.config.orderEditInBara) {
        this.orderResult1 = orderNumPrev * this.orderLotFn;
      } else {
        this.orderResult1 = orderNumPrev;
      }
      this.orderResult1IsToday = true;
    }

    this.orderNumFn = 0;
    this.orderNumModFn = 0;
    this.orderNumVersionFn = 0;

    if (!this.orderNumForm.dirty) {
      this.orderNumForm.setValue(this.getValue("orderNumFn"));
      this.orderBaraNumForm.setValue(this.getValue("orderBaraNumFn"));
    }
  }

  paramCurrent(val: number): string {
    return val == 0 ? "" : "" + val;
  }
}
