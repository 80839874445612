import { RspItemSimpleDto } from '../response/rsp-item-simple-seach';
import { CommonService } from '../service/common.service';

export class ItemSimpleRec {
    NO: number;
    storeCdFv: string;
    itemCdFv: string;
    itemNameFv: string;
    standardFv: string;
    ctgCd0Fv: string;
    ctgName0Fv: string;
    ctgCd1Fv: string;
    ctgName1Fv: string;
    ctgCd2Fv: string;
    ctgName2Fv: string;
    ctgCd3Fv: string;
    ctgName3Fv: string;
    ctg0Fv: string;
    ctg1Fv: string;
    ctg2Fv: string;
    ctg3Fv: string;
    constructor(private itemSimpleDto: RspItemSimpleDto, private commonService: CommonService) {
        for (var key of Object.keys(itemSimpleDto)) {
            this[key] = itemSimpleDto[key];
        }
        this.ctg0Fv = this.ctgCd0Fv + ":" + this.ctgName0Fv;
        this.ctg1Fv = this.ctgCd1Fv + ":" + this.ctgName1Fv;
        this.ctg2Fv = this.ctgCd2Fv + ":" + this.ctgName2Fv;
        this.ctg3Fv = this.ctgCd3Fv + ":" + this.ctgName3Fv;
    }
}