import { FormControl, FormGroup } from "@angular/forms";
import * as JSZip from "jszip";
import { LoginStoreDto } from "src/app/response/login-store-dto";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { DeletedStoreAndSubTaskDto, ReqGetStoreGroupList, ReqTaskFile, ReqUpdateTaskRec, RspGetStoreGroupList, RspTaskFileInfo, SubTaskAttachmentDto, SubTaskResultRecDto, TaskAttachmentDto, TaskRecDto, TaskResultRecDto } from "src/app/webservice/task";
import { TaskService } from "../1_service/taskService";
import { StoreGroupListItem, StoreListItem, SubTask, TaskTargetStore } from "./taskDefs";
import { Subscription } from "rxjs";
import { ReqGetUsers } from "src/app/request/req-get-users";
import { RspGetUsers } from "src/app/response/rsp-get-users";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";

export class TaskRec {
  taskId:             number  = -1;
  taskName:           string  = "";
  authorFv:           string  = "";
  authorStoreCd:      string  = "";
  authorStoreName:    string  = "";
  authorLoginUserId:  string  = "";
  authorLoginUserName: string  = "";
  priority:           string  = "A";
  dateBegin:          string  = "";
  dateEnd:            string = "";
  releaseStatus:      number = 0;
  releaseStatusBool:  boolean = false;              // Not in DB Table
  releaseStatusText?: string = "";                  // Not in DB Table
  releaseDate:        string = "";
  isNotActivatedReleaseDate: boolean = false;
  taskStatus:         string = "未着手";
  description:        string = "";
  responsibleClass:   string = "";
  referenceUrl:       string = "";
  attachmentList:     TaskAttachmentDto[] = [];
  subAttachmentList:  SubTaskAttachmentDto[] = [];
  comment:            string = "";                  // Not used at this point
  targetStoreCount:   number = 0;
  completeStoreCount: number = 0;
  targetStores:       TaskTargetStore[] = [];
  subTaskNextId:      number = 2;
  deadlineDate:       Date =new Date( new Date().setMonth(new Date().getMonth() + 1));
  workTime:           number = 0;
  taskCategory:       string = "";
  subTasks:           SubTask[] = [{
    subTaskId: 1, 
    description: "子タスク-1",
    subTaskName: "子タスク名-1",
    deadlineDate: this.commonService.formatDate(this.deadlineDate),
    workTimeH: 0,
    workTimeM: 0,
    photoReport: false,
    subTaskFileName: "",
    subTaskAttachment: "",
    subTaskUrl: ""
  }];
  datesToEnd:         number = 0;                   // Not in DB Table
  datesToEndText:     string = "";                  // Not in DB Table
  progress:           string = "";                  // Not in DB Table
  md5List:            string[] = [];
  edits?: {                                         // Not in DB Table
    formGroup:          FormGroup;
    taskName:           FormControl;
    authorFv:           FormControl;
    authorStoreCd:      FormControl;
    authorStoreName:    string;
    authorLoginUserId:  FormControl;
    authorLoginUserName: string;
    priority:           FormControl;
    dateBegin:          FormControl;
    dateEnd:            FormControl;
    releaseStatus:      FormControl;
    releaseDate:        FormControl;
    description:        FormControl;
    responsibleClass:   FormControl;
    referenceUrl:       FormControl;
    taskCategory:       FormControl;
    attachmentList:     TaskAttachmentDto[];
    subAttachmentList:  SubTaskAttachmentDto[];
    comment:            FormControl;
    subTaskNextId:      number;
    subTasks:           SubTask[];
    storeList:          StoreListItem[];
    storeGroupList:     StoreGroupListItem[];
  };

  public validError: string;
  // public releaseError: string;
  private subsc: Subscription[] = [];
  public loginUsers: {userId: string, userName: string}[] = [];

  constructor(private commonService: CommonService, private httpBasic: HttpBasicService, private taskService: TaskService) {
    let dateBegin: Date = new Date();
    dateBegin.setDate(dateBegin.getDate() + 1);
    let dateEnd = this.commonService.copyDate(dateBegin);
    dateEnd.setMonth(dateEnd.getMonth() + 1);

    this.dateBegin = this.commonService.formatDate(dateBegin);
    this.dateEnd = this.commonService.formatDate(dateEnd);
  }

  patchValue(obj: Object) {
    for (let key in obj) {
      if (Object.keys(this).find((recKey) => recKey === key)) {
        this[key] = obj[key];
      }
    }
    this.releaseStatusBool = this.releaseStatus > 0 ? true : false;

    this.releaseDate = this.isDateValid(this.releaseDate) ?
      this.commonService.formatDate(new Date(this.releaseDate)) :
      '';
    this.isNotActivatedReleaseDate = this.releaseStatusBool &&
      this.isDateValid(this.releaseDate) && this.commonService.copyDate(new Date()) < this.commonService.copyDate(new Date(this.releaseDate));

    this.releaseStatusText = this.releaseStatusBool ? (this.isNotActivatedReleaseDate ? "公開待ち" : "公開済") : "";
    if (this.releaseStatusBool) {
      let dateEnd = this.commonService.getDate(this.dateEnd);
      let d = new Date();
      let today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
      // this.datesToEnd = Math.floor((dateEnd.getTime() - new Date().getTime()) / 86400000);
      this.datesToEnd = Math.floor((dateEnd.getTime() - today.getTime()) / 86400000);
      this.datesToEndText = "" + this.datesToEnd;
      this.progress = "" + this.completeStoreCount + "/" + this.targetStoreCount;
    } else {
      this.datesToEnd = 0;
      this.datesToEndText = "";
      this.progress = "";
    }
  }

  prepareEdit() {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
    this.subsc = [];

    let dateBegin = undefined;
    if (this.dateBegin !== "") {
      dateBegin = this.commonService.getDate(this.dateBegin);
    }
    let dateEnd = undefined;
    if (this.dateEnd !== "") {
      dateEnd = this.commonService.getDate(this.dateEnd);
    }

    let formGroup = new FormGroup({});
    this.edits = {
      formGroup:          formGroup,
      taskName:           new FormControl(this.taskName),
      authorFv:           new FormControl(this.authorFv),
      authorStoreCd:      new FormControl(this.authorStoreCd),
      authorStoreName:    this.authorStoreName,
      authorLoginUserId:  new FormControl(this.authorLoginUserId),
      authorLoginUserName: this.authorLoginUserName,
      priority:           new FormControl(this.priority),
      dateBegin:          new FormControl(dateBegin),
      dateEnd:            new FormControl(dateEnd),
      releaseStatus:      new FormControl(this.releaseStatusBool),
      releaseDate:        new FormControl(this.isDateValid(this.releaseDate) ? this.commonService.copyDate(new Date(this.releaseDate)) : null),
      description:        new FormControl(this.description),
      responsibleClass:   new FormControl(this.responsibleClass),
      referenceUrl:       new FormControl(this.referenceUrl),
      taskCategory:       new FormControl(this.taskCategory),
      attachmentList:     [],
      subAttachmentList:  [],
      comment:            new FormControl(this.comment),
      subTaskNextId:      this.subTaskNextId,
      subTasks:           [],
      storeList:          [],
      storeGroupList:     []
    };
    formGroup.addControl("taskName", this.edits.taskName);
    formGroup.addControl("authorFv", this.edits.authorFv);
    formGroup.addControl("authorStoreCd", this.edits.authorStoreCd);
    formGroup.addControl("authorLoginUserId", this.edits.authorLoginUserId);
    formGroup.addControl("priority", this.edits.priority);
    formGroup.addControl("dateBegin", this.edits.dateBegin);
    formGroup.addControl("dateEnd", this.edits.dateEnd);
    formGroup.addControl("releaseStatus", this.edits.releaseStatus);
    formGroup.addControl("releaseDate", this.edits.releaseDate);
    formGroup.addControl("description", this.edits.description);
    formGroup.addControl("responsibleClass", this.edits.responsibleClass);
    formGroup.addControl("referenceUrl", this.edits.referenceUrl);
    formGroup.addControl("comment", this.edits.comment);
    formGroup.addControl("taskCategory", this.edits.taskCategory);
    this.edits.attachmentList = this.taskId > 0 ? this.attachmentList?.map(file => {return {...file, attachment: ""}}) : this.attachmentList?.map(file => {return{...file}});
    this.edits.subAttachmentList = this.taskId > 0 ? this.subAttachmentList?.map(sub => {return {...sub, attachment: ""}}): this.subAttachmentList?.map(sub => {return {...sub}});

    this.subsc.push(
      this.edits.authorStoreCd.valueChanges.subscribe(
        (value) => { this.authorStoreCdChanged(); }
      )
    );
    this.subsc.push(
      this.edits.authorLoginUserId.valueChanges.subscribe(
        (value) => { this.authorLoginUserIdChanged(); }
      )
    );

    let storeCd = this.edits.authorStoreCd.value;
    if (storeCd === "") {
      this.edits.authorStoreName = "";
      this.loginUsers = [];
    } else {
      let stores: LoginStoreDto[];
      if (this.commonService.config.includeNonAutoOrderStore.task === false) {
        stores = [...this.commonService.stores]
      } else {
        stores = [...this.commonService.allStores]
      }
      let store = stores.find((rec) => rec.storeCd === storeCd)
      if (store) {
        this.edits.authorStoreName = store.storeName;
        this.getUsers(storeCd);
      } else {
        this.edits.authorStoreName = "";
        this.loginUsers = [];
      }
    }

    /* Yabe
    if (this.releaseStatusBool) {
      this.edits.releaseStatus.disable({emitEvent: false});
    } else {
      setTimeout(() => this.edits.releaseStatus.enable({emitEvent: false}), 0);
    }
    */

    for (let subTask of this.subTasks) {
      if (subTask.subTaskTargetStores === undefined) {
        subTask.subTaskTargetStores = [];
        this.targetStores.forEach((store) => {
          subTask.subTaskTargetStores.push({...store});
        });
      }
      if (subTask.workTimeH === undefined) subTask.workTimeH = 0;
      if (subTask.workTimeM === undefined) subTask.workTimeM = 0;
      if (!this.commonService.config.task.workTimeIsHourAndMinutes) {
        if (subTask.workTimeH > 0) {
          subTask.workTimeM += subTask.workTimeH * 60;
          subTask.workTimeH = 0;
        }
      } else {
        if (subTask.workTimeM >= 60) {
          subTask.workTimeH += Math.floor(subTask.workTimeM / 60);
          subTask.workTimeM %= 60;
        }
      }
      subTask.edits = {
        description:        new FormControl(subTask.description),
        photoReport:        new FormControl(subTask.photoReport),
        subTaskName:        new FormControl(subTask.subTaskName),
        deadlineDate:       new FormControl(this.isDateValid(subTask.deadlineDate) ? new Date(subTask.deadlineDate) : ""),
        workTimeH:          new FormControl(subTask.workTimeH),
        workTimeM:          new FormControl(subTask.workTimeM),
        subTaskAttachment:  new FormControl(subTask.subTaskAttachment),
        subTaskFileName:    new FormControl(subTask.subTaskFileName),
        subTaskUrl:         new FormControl(subTask.subTaskUrl),
        subTaskStoreList:   undefined,
        subTaskStoreGroupList:  []
      };
      this.prepareSubTaskStoreList(subTask);

      /*
      if(this.releaseStatusBool) {
        subTask.edits.photoReport.disable();
      }
      */
      this.edits.subTasks.push(subTask);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-desc", subTask.edits.description);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-name", subTask.edits.subTaskName);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-photo", subTask.edits.photoReport);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-dl", subTask.edits.deadlineDate);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-att", subTask.edits.subTaskAttachment);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-filename", subTask.edits.subTaskFileName);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-url", subTask.edits.subTaskUrl);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-work-time-h", subTask.edits.workTimeH);
      formGroup.addControl("subtask-" + subTask.subTaskId + "-work-time-m", subTask.edits.workTimeM);
    }

    this.edits.subTasks.forEach(subTask => {
      this.subAttachmentList.forEach(file => {
        if(subTask.subTaskId == file.subTaskId) {
          subTask.edits.subTaskAttachment.setValue(file.attachment);
        }
      })
    })
    let stores: LoginStoreDto[];
    if (this.commonService.config.includeNonAutoOrderStore.task === false) {
      stores = [...this.commonService.stores]
    } else {
      stores = [...this.commonService.allStores]
    }
    /*
    if (this.commonService.config.honbu !== undefined) {
      stores.push({storeCd: this.commonService.config.honbu.storeCd, storeName: this.commonService.config.honbu.storeName})
    }
    */
    for (let store of stores) {
      let item: StoreListItem = {
        storeCd: store.storeCd,
        storeName: store.storeName,
        storeNameWithCode: store.storeCd + "：" + store.storeName,
        form: new FormControl(false)
      };
      this.edits.storeList.push(item);
      formGroup.addControl("store-" + store.storeCd, item.form);
      /*
      if (this.releaseStatusBool) {
        item.form.disable();
      }
      */
    }
    if (this.targetStores) {
      for (let store of this.targetStores) {
        // if (store.isDeleted) continue;
        let storeItem = this.edits.storeList.find((item) => item.storeCd === store.storeCd);
        if (storeItem) storeItem.form.setValue(true);
      }
    }
  }

  prepareSubTaskStoreList(subTask: SubTask) {
    let list: StoreListItem[] = [];
    let check = subTask.subTaskTargetStores !== undefined && subTask.subTaskTargetStores.length > 0;
    this.targetStores.forEach((store) => {
      let val = true;
      if (check && !subTask.subTaskTargetStores.find((sub) => sub.storeCd === store.storeCd)) {
        val = false;
      }
      let form = new FormControl(val);
      /*
      if (this.releaseStatusBool) {
        form.disable();
      }
      */
      list.push({
        ...store,
        form: form
      });
    });
    subTask.edits.subTaskStoreList = list;
  }

  buildStoreGroupList(typeId: number, subTask: SubTask) {
    if (typeId == -1) {
      if (subTask === undefined) {
        this.edits.storeGroupList = [];
        this.edits.storeList.forEach((store) => {
          let groupStore: StoreGroupListItem = {
            groupTypeId: -1,
            groupId: -1,
            groupName: "",
            store: store,
            allChecked: false,
            cnt: 0
          }
          this.edits.storeGroupList.push(groupStore);
        });
      } else {
        subTask.edits.subTaskStoreGroupList = [];
        subTask.edits.subTaskStoreList.forEach((store) => {
          let groupStore: StoreGroupListItem = {
            groupTypeId: -1,
            groupId: -1,
            groupName: "",
            store: store,
            allChecked: false,
            cnt: 0
          }
          subTask.edits.subTaskStoreGroupList.push(groupStore)
        });
      }
      return;
    }

    let request: ReqGetStoreGroupList = {
      access: this.commonService.loginUser,
      storeGroupTypeId: typeId
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetStoreGroupList", request).subscribe(
      (response: RspGetStoreGroupList) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.receiveStoreGroupList(response, subTask);
      },
      (error) => {
        this.commonService.closeSpinnerForSubComp(ref);
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    )
  }

  receiveStoreGroupList(response: RspGetStoreGroupList, subTask: SubTask) {
    if (this.httpBasic.handleAppError(response)) return;

    let storeMap = {};
    let groupMap = {};

    response.rows.forEach((dto) => {
      let groupKey = "" + dto.groupId;
      if (!groupMap[groupKey]) {
        groupMap[groupKey] = {groupId: dto.groupId, groupTypeId: dto.groupTypeId, groupName: dto.groupName, stores: []};
      }
      storeMap[dto.storeCd] = groupMap[groupKey];
    });
    let cnt = 0;
    groupMap["-1"] = {groupId: -1, groupTypeId: -1, groupName: "店舗グループ対象外", stores: []};
    if (subTask === undefined) {
      this.edits.storeList.forEach((store) => {
        if (storeMap[store.storeCd]) {
          storeMap[store.storeCd].stores.push(store);
        } else {
          groupMap["-1"].stores.push(store);
          cnt++;
        }
      });
      if (cnt === 0) delete groupMap["-1"];
      this.edits.storeGroupList = [];
      let keys = Object.keys(groupMap).sort((a, b) => {
        if (a == "-1") return 1;
        if (b == "-1") return -1;
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
      for (let groupKey of keys) {
        let group = groupMap[groupKey];
        let listRec = {
          groupId: group.groupId,
          groupTypeId: group.groupTypeId,
          groupName: group.groupName,
          store: undefined,
          allChecked: false,
          cnt: group.stores.length
        };
        this.edits.storeGroupList.push(listRec);
        let cntTrue = 0;
        let cntFalse = 0;
        group.stores.forEach((store) => {
          if (store.form.value) {
            cntTrue++;
          } else {
            cntFalse++;
          }
          this.edits.storeGroupList.push({
            groupId: group.groupId,
            groupTypeId: group.groupTypeId,
            groupName: group.groupName,
            store: store,
            allChecked: false,
            cnt: 0
          });
        });
        listRec.allChecked = cntTrue > 0 && cntFalse === 0;
      }
    } else {
      subTask.edits.subTaskStoreList.forEach((store) => {
        if (storeMap[store.storeCd]) {
          storeMap[store.storeCd].stores.push(store);
        } else {
          groupMap["-1"].stores.push(store);
          cnt++;
        }
      });
      if (cnt === 0) delete groupMap["-1"];
      subTask.edits.subTaskStoreGroupList = [];
      let keys = Object.keys(groupMap).sort((a, b) => {
        if (a == "-1") return 1;
        if (b == "-1") return -1;
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
      for (let groupKey of keys) {
        let group = groupMap[groupKey];
        let listRec = {
          groupId: group.groupId,
          groupTypeId: group.groupTypeId,
          groupName: group.groupName,
          store: undefined,
          allChecked: false,
          cnt: group.stores.length
        };
        subTask.edits.subTaskStoreGroupList.push(listRec);
        let cntTrue = 0;
        let cntFalse = 0;
        group.stores.forEach((store) => {
          if (store.form.value) {
            cntTrue++;
          } else {
            cntFalse++;
          }
          subTask.edits.subTaskStoreGroupList.push({
            groupId: group.groupId,
            groupTypeId: group.groupTypeId,
            groupName: group.groupName,
            store: store,
            allChecked: false,
            cnt: 0
          });
        });
        listRec.allChecked = cntTrue > 0 && cntFalse === 0;
      }
    }
  }

  updateAllChecked(groupId: number, subTask: SubTask) {
    if (subTask === undefined) {
      let val = this.edits.storeGroupList.filter((rec) => rec.groupId === groupId && rec.store !== undefined).every((rec) => rec.store.form.value);
      let rec = this.edits.storeGroupList.find((rec) => rec.groupId === groupId && rec.store === undefined);
      if (rec) rec.allChecked = val;
    } else {
      let val = subTask.edits.subTaskStoreGroupList.filter((rec) => rec.groupId === groupId && rec.store !== undefined).every((rec) => rec.store.form.value);
      let rec = subTask.edits.subTaskStoreGroupList.find((rec) => rec.groupId === groupId && rec.store === undefined);
      if (rec) rec.allChecked = val;
    }
  }

  someChecked(groupId: number, subTask: SubTask) {
    if (subTask === undefined) {
      let cntTrue = 0;
      let cntFalse = 0;
      this.edits.storeGroupList.filter((rec) => rec.groupId === groupId && rec.store !== undefined).forEach((rec) => {
        if (rec.store.form.value) {
          cntTrue++;
        } else {
          cntFalse++;
        }
      });
      return cntTrue > 0 && cntFalse > 0;
    } else {
      let cntTrue = 0;
      let cntFalse = 0;
      subTask.edits.subTaskStoreGroupList.filter((rec) => rec.groupId === groupId && rec.store !== undefined).forEach((rec) => {
        if (rec.store.form.value) {
          cntTrue++;
        } else {
          cntFalse++;
        }
      });
      return cntTrue > 0 && cntFalse > 0;
    }
  }

  storeGroupAllCheck(groupId: number, value: boolean, subTask: SubTask) {
    if (subTask === undefined) {
      this.edits.storeGroupList.filter((rec) => rec.groupId === groupId).forEach((rec) => {
        if (rec.store === undefined) {
          rec.allChecked = value;
        } else {
          rec.store.form.setValue(value);
        }
      });
    } else {
      subTask.edits.subTaskStoreGroupList.filter((rec) => rec.groupId === groupId).forEach((rec) => {
        if (rec.store === undefined) {
          rec.allChecked = value;
        } else {
          rec.store.form.setValue(value);
        }
      });
    }
  }

  syncSubTaskStoreList(subTask: SubTask) {
    let list: StoreListItem[] = [];
    this.edits.storeList.forEach((store) => {
      if (store.form.value === true) {
        let form = new FormControl(true);
        /*
        if (this.releaseStatusBool) {
          form.disable();
        }
        */
        list.push({
          ...store,
          form: form
        });
      }
    });

    /*
    if (subTask.edits.subTaskStoreList === undefined) {
      subTask.edits.subTaskStoreList = list;
    } else {
      subTask.edits.subTaskStoreList.forEach((current) => {
        let store = list.find((item) => item.storeCd === current.storeCd);
        if (store) {
          store.form.setValue(current.form.value);
        }
      });
      subTask.edits.subTaskStoreList = list;
    }
    */

    subTask.edits.subTaskStoreList.forEach((current) => {
      let store = list.find((item) => item.storeCd === current.storeCd);
      if (store) {
        store.form.setValue(current.form.value);
      }
    });
    subTask.edits.subTaskStoreList = list;
  }

  commitEdit(): void {
    let dateBegin: Date = this.edits.dateBegin.value;
    let dateEnd: Date = this.edits.dateEnd.value;
    if (!this.releaseStatusBool) {
      this.targetStores = [];
    }

    this.taskName = this.edits.taskName.value;
    this.authorFv = this.edits.authorFv.value;
    this.authorStoreCd = this.edits.authorStoreCd.value;
    this.authorStoreName = this.edits.authorStoreName;
    this.authorLoginUserId = this.edits.authorLoginUserId.value;
    this.authorLoginUserName = this.edits.authorLoginUserName;
    if (this.commonService.config.task.authorIsStoreAndUser) {
      if (this.authorStoreCd !== "") {
        this.authorFv = this.authorStoreName + " : " + this.authorLoginUserName;
      }
    }
    this.priority = this.edits.priority.value;
    this.dateBegin = this.commonService.formatDate(dateBegin);
    this.dateEnd = this.commonService.formatDate(dateEnd);
    this.releaseStatusBool = this.edits.releaseStatus.value;
    this.releaseStatus = this.releaseStatusBool ? 1 : 0;
    this.releaseStatusText = this.releaseStatusBool ? "公開済" : "";
    this.releaseDate = this.isDateValid(this.edits?.releaseDate?.value) ? this.commonService.formatDate(new Date(this.edits?.releaseDate?.value)) : '';
    this.description = this.edits.description.value;
    this.responsibleClass = this.edits.responsibleClass.value;
    this.referenceUrl = this.edits.referenceUrl.value;
    this.comment = this.edits.comment.value;
    // Don't show deleted files when saving is complete
    this.attachmentList = this.edits.attachmentList.filter(f => f.attachment !="deleteFile");
    this.subAttachmentList = this.edits.subAttachmentList.map(sub => {return {...sub, taskId: this.taskId}});
    if (this.releaseStatusBool && this.dateEnd !== "") {
      let d = new Date();
      let today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
      this.datesToEnd = Math.floor((dateEnd.getTime() - today.getTime()) / 86400000);
    } else {
      this.datesToEnd = 0;
    }
    if (this.releaseStatusBool) {
      this.datesToEndText = "" + this.datesToEnd;
      this.progress = "" + this.completeStoreCount + "/" + this.targetStoreCount;
    } else {
      this.datesToEndText = "";
      this.progress = "";
    }
    this.subTaskNextId = this.edits.subTaskNextId;

    this.edits.subTasks.forEach((item) => {
      this.syncSubTaskStoreList(item);
    });
    this.targetStores = [];
    this.edits.storeList.forEach((store) => {
      if (store.form.value) {
        let hasSubTask = false;
        for (let i = 0; i < this.edits.subTasks.length; i++) {
          let subStore = this.edits.subTasks[i].edits.subTaskStoreList.find((rec) => rec.storeCd === store.storeCd);
          if (subStore?.form.value) {
            hasSubTask = true;
            break;
          }
        }
        if (!hasSubTask) {
          store.form.setValue(false);
        } else {
          let target: TaskTargetStore = {
            storeCd: store.storeCd,
            storeName: store.storeName,
            storeNameWithCode : store.storeNameWithCode
          };
          this.targetStores.push(target);
        }
      }
    });
    this.targetStoreCount = this.targetStores.length;
    this.subTaskNextId = this.edits.subTaskNextId;
    this.subTasks = [];

    this.workTime = 0;
    this.edits.subTasks.forEach((item) => {
      // this.syncSubTaskStoreList(item);
      item.description = item.edits.description.value;
      item.photoReport = item.edits.photoReport.value;
      item.subTaskName = item.edits.subTaskName.value;
      item.deadlineDate = this.isDateValid(item.edits.deadlineDate.value) ?  this.commonService.formatDate(item.edits.deadlineDate.value): '';
      item.subTaskAttachment = item.edits.subTaskAttachment.value;
      item.subTaskFileName = item.edits.subTaskFileName.value;
      item.workTimeH = parseInt(item.edits.workTimeH.value);
      item.workTimeM = parseInt(item.edits.workTimeM.value);
      item.subTaskUrl = item.edits.subTaskUrl.value;
      item.subTaskTargetStores = [];
      item.edits.subTaskStoreList?.forEach((store) => {
        if (store.form.value) {
          item.subTaskTargetStores.push({storeCd: store.storeCd, storeName: store.storeName, storeNameWithCode: store.storeNameWithCode});
        }
      });
      this.workTime += item.workTimeH * 60 + item.workTimeM;
      let subtask: SubTask = {
        subTaskId: item.subTaskId,
        description: item.description,
        photoReport: item.photoReport,
        subTaskName: item.subTaskName,
        deadlineDate: item.deadlineDate,
        workTimeH: item.workTimeH,
        workTimeM: item.workTimeM,
        subTaskFileName: item.subTaskFileName,
        subTaskUrl: item.subTaskUrl ?? "",
        subTaskTargetStores: [...item.subTaskTargetStores]
      };
      this.subTasks.push(subtask);
    });
    this.taskCategory = this.edits.taskCategory.value;
    this.edits.formGroup.markAsPristine();
  }

  clearEdit(): void {
    this.prepareEdit();
  }

  endEdit() {
    this.subsc.forEach((subsc) => subsc.unsubscribe());
    this.subsc = [];

    delete this.edits;
  }

  markAsDirty(): void {
    this.edits?.formGroup.markAsDirty();
  }

  markAsPristine(): void {
    this.edits?.formGroup.markAsPristine();
  }

  isDirty(): boolean {
    if (this.edits != undefined) return this.edits.formGroup.dirty;
    return false;
  }

  addNewSubTask() {
    if (!this.edits) return;

    let desc = "子タスク-" + this.edits.subTaskNextId;
    let subTaskName = "子タスク名-" + this.edits.subTaskNextId;
    let deadlineDate = new Date(this.edits.dateEnd.value);
    let subTask: SubTask = {
      // taskId: this.taskId,
      subTaskId: this.edits.subTaskNextId,
      description:  desc,
      subTaskName: subTaskName,
      photoReport: false,
      deadlineDate: this.commonService.formatDate(deadlineDate),
      workTimeH: 0,
      workTimeM: 0,
      subTaskFileName: "",
      subTaskAttachment:  "",
      subTaskUrl:"",
      edits: {
        description: new FormControl(desc),
        photoReport: new FormControl(false),
        subTaskName: new FormControl(subTaskName),
        deadlineDate:   new FormControl(deadlineDate),
        workTimeH: new FormControl(0),
        workTimeM: new FormControl(0),
        subTaskFileName: new FormControl(""),
        subTaskAttachment:  new FormControl(""),
        subTaskUrl:         new FormControl(""),
        subTaskStoreList:   [],
        subTaskStoreGroupList:  []
      }
    };
    this.edits.subTaskNextId++;
    this.edits.subTasks.push(subTask);
    this.edits.formGroup.addControl("subtask-" + subTask.subTaskId + "-desc", subTask.edits.description)
    this.edits.formGroup.addControl("subtask-" + subTask.subTaskId + "-name", subTask.edits.subTaskName)
    this.edits.formGroup.addControl("subtask-" + subTask.subTaskId + "-photo", subTask.edits.photoReport)
    this.edits.formGroup.addControl("subtask-" + subTask.subTaskId + "-dl", subTask.edits.deadlineDate)
    this.edits.formGroup.addControl("subtask-" + subTask.subTaskId + "-att", subTask.edits.subTaskFileName)
    this.edits.formGroup.addControl("subtask-" + subTask.subTaskId + "-filename", subTask.edits.subTaskAttachment)
    this.edits.formGroup.markAsDirty();
  }

  isValid(): boolean {
    this.validError = undefined;

    if (this.edits.taskName.value === "") {
      this.validError = "タスク名が設定されていません。"
      return false;
    }

    for (let subTask of this.edits.subTasks) {
      let subTaskDeadline: Date = this.commonService.copyDate(new Date (subTask.edits.deadlineDate.value));
      let dateBegin: Date = this.commonService.copyDate(new Date(this.edits.dateBegin.value));
      let dateEnd: Date = this.commonService.copyDate(new Date(this.edits.dateEnd.value));
      if (subTask.edits.description.value === "") {
        this.validError = "子タスクの内容が設定されていません。"
        return false;
      }
      if (subTask.edits.subTaskName.value === "") {
        this.validError = "子タスク名が設定されていません。"
        return false;
      }
      if(subTaskDeadline < dateBegin || subTaskDeadline > dateEnd ) {
        this.validError = "子タスクの期限外です。期間を修正してください。"
        return false;
      }
    }

    if (!this.releaseStatusBool && this.edits.releaseStatus.value) {
      let found = false;
      for (let store of this.edits.storeList) {
        if (store.form.value) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.validError = "店舗が選択されていないため、公開できません。";
        return false;
      }
    }

    return true;
  }

  getDeletedStoreAndSubTask(isRelease: boolean, cancelRelease: boolean) {
    let list : DeletedStoreAndSubTaskDto[] = [];
    if (!isRelease) return list;
    if (cancelRelease) return list;

    /* Deleted Store */
    this.targetStores.forEach((store) => {
      let editStore = this.edits.storeList.find((item) => item.storeCd === store.storeCd);
      if (!editStore || !editStore.form.value) {
        list.push({type: "store", storeCd: store.storeCd, subTaskId: -1});
      }
    });

    /* Deleted SubTask */
    this.edits.subTasks.forEach((subTask) => {
      this.syncSubTaskStoreList(subTask);
    });
    this.subTasks.forEach((subTask) => {
      let editSubTask = this.edits.subTasks.find((item) => item.subTaskId === subTask.subTaskId);
      subTask.subTaskTargetStores.forEach((store) => {
        if (!editSubTask) {
          // SubTask is deleted
          list.push({type: "subtask", storeCd: store.storeCd, subTaskId: subTask.subTaskId});
        } else {
          let editStore = editSubTask.edits.subTaskStoreList.find((item) => item.storeCd === store.storeCd);
          if (!editStore || !editStore.form.value) {
            // store is deleted from this subtask
            list.push({type: "subtask", storeCd: store.storeCd, subTaskId: subTask.subTaskId});
          }
        }
      });
    });

    return list;
  }

  getUpdateRequest(): ReqUpdateTaskRec {
    let newRelease = (!this.releaseStatusBool && this.edits.releaseStatus.value) ? true : false;
    let isRelease = this.edits.releaseStatus.value;
    let cancelRelease = (this.releaseStatusBool && !this.edits.releaseStatus.value) ? true : false;
    let deletedStoreAndSubTaskList = this.getDeletedStoreAndSubTask(isRelease, cancelRelease);

    this.commitEdit();
    if (!this.workTime) this.workTime = 0;
    let taskRecDto: TaskRecDto = {
      taskId:             this.taskId,
      taskName:           this.taskName,
      authorFv:           this.authorFv,
      authorStoreCd:      this.authorStoreCd,
      authorLoginUserId:  this.authorLoginUserId,
      authorLoginUserName: this.authorLoginUserName,
      priority:           this.priority,
      dateBegin:          this.dateBegin,
      dateEnd:            this.dateEnd,
      releaseStatus:      this.releaseStatus,
      releaseDate:        this.releaseDate,
      taskStatus:         this.taskStatus,
      description:        this.description,
      responsibleClass:   this.responsibleClass,
      referenceUrl:       this.referenceUrl,
      attachmentList:     this.edits.attachmentList.filter(f => f.attachment),
      subAttachmentList:  this.subAttachmentList.filter(f => f.attachment),
      targetStoreCount:   this.targetStoreCount,
      completeStoreCount: this.completeStoreCount,
      targetStores:       JSON.stringify(this.targetStores),
      targetStoresList:   this.targetStores,
      subTaskNextId:      this.subTaskNextId,
      subTasks:           JSON.stringify(this.subTasks),
      workTime:           this.workTime,
      taskCategory:       this.taskCategory
    };
    let request: ReqUpdateTaskRec = {
      access: this.commonService.loginUser,
      newRelease: newRelease,
      isRelease: isRelease,
      isUpdateRelease: false,
      cancelRelease: cancelRelease,
      taskRec:  taskRecDto,
      taskResultRec:  [],
      // refresh taskList when update or insert
      dateEndMax: '',
      dateEndMin: '',
      releaseStatus: 0,
      taskName: '',
      authorFv: '',
      authorStoreCd: '',
      authorLoginUserId: '',
      responsibleClass: '',
      taskCategory: '',
      taskStatus: [],
      deletedStoreAndSubTask: deletedStoreAndSubTaskList
    }

    // if (newRelease) {
      this.targetStores.forEach((store) => {
        let resultRec: TaskResultRecDto;
        resultRec = {
          taskId:               this.taskId,
          storeCd:              store.storeCd,
          startDateTime:        "",
          resultStatus:         "担当者未設定",
          responsible:          "",
          responsibleUserId:    "",
          responsibleUserName:  "",
          commentStore:         "",
          subTaskResults:       []
        };
        this.subTasks.forEach((item) => {
          if (item.subTaskTargetStores === undefined || item.subTaskTargetStores.find((subStore) => subStore.storeCd === store.storeCd)) {
            let result: SubTaskResultRecDto = {
              taskId:                 this.taskId,
              storeCd:                store.storeCd,
              subTaskId:              item.subTaskId,
              isWorking:              false,
              isComplete:             false,
              completeDateTime:       "",
              subResponsible:         "",
              subResponsibleUserId:   "",
              subResponsibleUserName: "",
              comment:                "",
              workTimeResult:         0,
              deadlineDate:           item.deadlineDate,
              isUpdate:               false
            };
            resultRec.subTaskResults.push(result);
          }
        });
        request.taskResultRec.push(resultRec);
      });
    // }

    return request;
  }

  isDateValid(input: string | Date): boolean {
    if (!input) return false;
    if (typeof input == 'string') {
      if (input.trim() == '') return false;
      const date = new Date(input);
      return (date instanceof Date && !isNaN(date.valueOf()));
    }

    return (input instanceof Date && !isNaN(input.valueOf()));
  }

  downloadMultiFile() {
    const request: ReqTaskFile = {
      taskId: this.taskId,
      access: this.commonService.loginUser,
      mode: -1
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.taskRecFileInfoSearch(request).subscribe(
      (response: RspTaskFileInfo) => {
        if(response){
          this.receiveDownloadMultiFile(response);
        }
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveDownloadMultiFile(response: RspTaskFileInfo) {
    var zip = new JSZip();
    for (let i = 0; i < response.taskRecFileInfo.length; i++) {
    let f = response.taskRecFileInfo[i];
    zip.file(f.attachmentFilename, this.dataURLtoBlob(f.attachment), {comment: f.attachmentTitle});
    }
    zip.generateAsync({type: 'blob'})
    .then((content) => {
        const navigator: any = window.navigator;
        if (navigator.msSaveBlob) {
          // For IE10/11 : No download functionality
          navigator.msSaveBlob(content,  'zip_'+ this.taskService.getDateString(new Date()));
        } else {
          // Other browser
          const url = URL.createObjectURL(content);
          let download = document.createElement("a");
          download.href = url;
          download.setAttribute('download', 'zip_'+ this.taskService.getDateString(new Date()))
          download.click();
        }
        this.commonService.closeSpinner();
    }).catch((err) => {
      this.commonService.closeSpinner();
    });
  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  getMaxFileId(attachmentList: TaskAttachmentDto[]){
    let max = 0;
    attachmentList.forEach(file => {
      if (file.attachmentId > max) {
        max = file.attachmentId;
      }
    });
    return max + 1;
  }

  authorStoreCdChanged() {
    this.edits.authorLoginUserId.setValue("");

    let storeCd = this.edits.authorStoreCd.value;
    if (storeCd === "") {
      this.edits.authorStoreName = "";
      this.loginUsers = [];
    } else {
      let stores: LoginStoreDto[];
      if (this.commonService.config.includeNonAutoOrderStore.task === false) {
        stores = [...this.commonService.stores]
      } else {
        stores = [...this.commonService.allStores]
      }
      let store = stores.find((rec) => rec.storeCd === storeCd)
      if (store) {
        this.edits.authorStoreName = store.storeName;
        this.getUsers(storeCd);
      } else {
        this.edits.authorStoreName = "";
        this.loginUsers = [];
      }
    }
  }

  authorLoginUserIdChanged() {
    if (this.edits.authorLoginUserId.value === "") {
      this.edits.authorLoginUserName = "";
      return;
    }
    let user = this.loginUsers.find((rec) => rec.userId === this.edits.authorLoginUserId.value);
    if (user) {
      this.edits.authorLoginUserName = user.userName;
    } else {
      this.edits.authorLoginUserName = "";
    }
  }

  getUsers(storeCd: string) {
    let request: ReqGetUsers = {
      access: this.commonService.loginUser,
      storeCd: storeCd
    };

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetUsers", request).subscribe(
      (response: RspGetUsers) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveGetUsers(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveGetUsers(response: RspGetUsers) {
    if (this.httpBasic.handleAppError(response)) return;

    this.loginUsers = [];
    response.users.forEach((dto) => {
      let user = {
        userId: dto.userId,
        userName: dto.userName
      };
      this.loginUsers.push(dto);
    });
  }
}
