import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { CommonService } from 'src/app/service/common.service';
import { CameraDef, CameraLayoutConfig } from '../CameraDef';

interface SizeOption {
  value:  number;
  name:   string;
  sizeX:  number;
  sizeY:  number;
}

@Component({
  selector: 'app-camera-layout-config',
  templateUrl: './camera-layout-config.component.html',
  styleUrls: ['./camera-layout-config.component.css']
})
export class CameraLayoutConfigComponent implements OnInit, OnDestroy {

  public stores: LoginStoreDto[];
  public cameraListByStore: CameraDef[] = [];
  public storeForm:  FormControl = new FormControl();
  public sizeForm:  FormControl = new FormControl();
  public sizeOptions: SizeOption[] = [
    {value: 0, name: "320 × 180", sizeX: 320, sizeY: 180},
    {value: 1, name: "640 × 360", sizeX: 640, sizeY: 360},
    {value: 2, name: "800 × 448", sizeX: 800, sizeY: 448},
    {value: 3, name: "1280 × 720", sizeX: 1280, sizeY: 720},
  ];
  public formGroup: FormGroup;

  private subscriptionChange: Subscription;
  private subscriptionStore: Subscription;
  private subscriptionSize: Subscription;

  @Input("cameraList") cameraList: CameraDef[];
  @Input("layoutConfig") layoutConfig: CameraLayoutConfig;
  @Output("onChange") onChange: EventEmitter<any> = new EventEmitter();

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    if (this.commonService.stores.length > 1) {
      this.stores = [{storeCd: "*", storeName:"全店"}, ...this.commonService.stores];
    } else {
      this.stores = [...this.commonService.stores];
    }
    this.formGroup = this.fb.group({
      cameraCd: "",
      sizeX: [0, [Validators.required, Validators.max(9999),  Validators.min(0), Validators.maxLength(4)]],
      sizeY: [0, [Validators.required, Validators.max(9999), Validators.min(0), Validators.maxLength(4)]]
    })

    this.initValue();
    this.listenChange();
    this.subscriptionStore = this.storeForm.valueChanges.subscribe(
      (value) => {this.storeChanged(value);}
    );
    this.subscriptionSize = this.sizeForm.valueChanges.subscribe(
      (value) => {this.sizeChanged(value);}
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptionChange) this.subscriptionChange.unsubscribe();
    if (this.subscriptionStore) this.subscriptionStore.unsubscribe();
    if (this.subscriptionSize) this.subscriptionSize.unsubscribe();
  }

  initValue() {

    if (!this.layoutConfig) return;
    this.formGroup.get("sizeX").setValue(this.layoutConfig.sizeX, {emitEvent: false});
    this.formGroup.get("sizeY").setValue(this.layoutConfig.sizeY, {emitEvent: false});
    if (this.layoutConfig.camera) {
      this.storeForm.setValue(this.layoutConfig.camera.storeCd, {emitEvent: false});
      this.getCameraListByStore(this.layoutConfig.camera.storeCd);
      this.formGroup.get("cameraCd").setValue(this.layoutConfig.camera.cameraCd, {emitEvent: false});
    }
    this.sizeForm.setValue(-1, {emitEvent: false}); // unselect   
  }

  listenChange() {
    this.subscriptionChange = this.formGroup.valueChanges.pipe(distinctUntilChanged()).subscribe(
      (formControl: FormControl) => {
        this.valueChanges(formControl);
      }
    );


  }
  
  unlistenChange() {
    if (this.subscriptionChange) {
      this.subscriptionChange.unsubscribe();
      this.subscriptionChange = undefined;
    }
  }

  valueChanges(formControl: FormControl) {
    this.onChange.emit();
  }

  storeChanged(storeCd: string) {
    if (storeCd != "*" && this.formGroup.get("cameraCd").value !== "") {
      this.formGroup.get("cameraCd").setValue("", {emitEvent: false});
      /* Yabe ... */
      this.formGroup.markAsDirty();
      this.onChange.emit();
      /* ... Yabe */
    }
    /* Yabe
    this.formGroup.markAsDirty();
    this.onChange.emit();
    */
    this.getCameraListByStore(storeCd);
  }

  getCameraListByStore(storeCd: string) {
    if (storeCd == "*") {
      this.cameraListByStore = [...this.cameraList];
      return;
    }
    this.cameraListByStore = [];
    if (storeCd === "") return;
    for (let camera of this.cameraList) {
      if (camera.storeCd === storeCd)
      this.cameraListByStore.push(camera);
    }
  }

  sizeChanged(value: number) {
    if (value >= 0 && value < this.sizeOptions.length) {
      /* Yabe ... */
      if (this.formGroup.get("sizeX").value == this.sizeOptions[value].sizeX &&
          this.formGroup.get("sizeY").value == this.sizeOptions[value].sizeY) return;
      /* ... Yabe */
      this.formGroup.get("sizeX").setValue(this.sizeOptions[value].sizeX, {emitEvent: false});
      this.formGroup.get("sizeY").setValue(this.sizeOptions[value].sizeY, {emitEvent: false});
      this.formGroup.markAsDirty();
      this.onChange.emit();
    }
  }

  cancel() {
    if (!this.layoutConfig) return;
    this.formGroup.reset({
      sizeX: this.layoutConfig.sizeX,
      sizeY: this.layoutConfig.sizeY
    }, {emitEvent: false});
    if (this.layoutConfig.camera) {
      this.storeForm.reset(this.layoutConfig.camera.storeCd,  {emitEvent: false});
      this.getCameraListByStore(this.layoutConfig.camera.storeCd);
      this.formGroup.get("cameraCd").setValue(this.layoutConfig.camera.cameraCd,  {emitEvent: false});
    } else {
      this.storeForm.reset('',  {emitEvent: false});
    }
    this.sizeForm.setValue(-1, {emitEvent: false}); // unselect 
    this.onChange.emit();
  }

  isDirty(): boolean {
    return this.formGroup.dirty;
  }

}
