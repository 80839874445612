import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqTaskEmoteImageUpdate, TaskEmoteImageDto } from 'src/app/request/req-task-emote-image-update';
import { ReqTaskOtherStoreDownloadPdf } from 'src/app/request/req-task-other-store-download-pdf';
import { ReqTaskOtherStoreDownloadXlsx } from 'src/app/request/req-task-other-store-download-xlsx';
import { ReqTaskOtherStoreDownloadZip } from 'src/app/request/req-task-other-store-download-zip';
import { RspEmojiSearch } from 'src/app/response/rsp-emoji-search';
import { RspTaskEmoteImageUpdate } from 'src/app/response/rsp-task-emote-image-update';
import { ReqSubTaskResultOtherStoresImageSearch, ReqSubTaskResultOtherStoresSearch, RspSubTaskResultOtherStoresImageSearch, RspSubTaskResultOtherStoresSearch, SubTaskResultOtherStoresImageDto, TaskImageMarkDto } from 'src/app/webservice/task';
import { ReqEmojiSearch } from '../../../request/req-emoji-search';
import { CommonService } from '../../../service/common.service';
import { HttpBasicService } from '../../../service/http-basic.service';
import { EmojiDto, EmojiImageDto, TaskImageRecDto } from '../../../webservice/task';
import { StoreTargetListItemChecked, SubTask } from '../0_def/taskDefs';
import { SubTaskOtherStoresResultRecDto } from '../0_def/taskOtherStoresRec';
import { TaskStoreRec } from '../0_def/taskStoreRec';
import { TaskService } from '../1_service/taskService';
import { TaskPhotoViewDialogComponent } from '../photo-view-dialog/task-photo-view-dialog.component';
import { TaskImagesExcel } from '../excel/taskImagesExcel';
import { MatTable } from '@angular/material/table';
import { resolve } from 'dns';
import { TaskImagesZip } from '../zip/taskImagesZip';
import { TaskImagesPDF } from '../pdf/taskImagesPDF';

@Component({
  selector: 'view-other-store',
  templateUrl: 'view-other-store.component.html',
  styleUrls: ['view-other-store.component.css'],
})

export class ViewOtherStoreComponent implements OnInit, OnChanges {

  @Input() taskRec: TaskStoreRec;
  @Input() currentSubTask: SubTask;
  @Input() isFromTaskHq: boolean = false;
  @ViewChild('subTaskOtherStoresPaginator', { static: false }) matPaginator: MatPaginator;
  @ViewChild('MatTable', { static: false }) matTable: MatTable<any>;

  public targetStoreSelected: Map<string, boolean> = new Map();
  public subtaskSelected: Map<string, boolean> = new Map();
  public imageMarkMap: Map<string, TaskImageMarkDto> = new Map();
  public emojiMarkMap: Map<string, EmojiImageDto> = new Map();
  public emojiMarkMapOld: Map<string, EmojiImageDto> = new Map();
  public targetStores: StoreTargetListItemChecked[]; 
  public subTaskColumnDefs: TableColumnDef[] = [
    { columnId: "subTaskId", header: "選択", width: 50, align: "center", checkbox: true },
    { columnId: "subTaskName", header: "子タスク名", width: 310, },
    { columnId: "photoReport", header: "写真", width: 40, align: "center"}
  ];
  public storeColumnDefs: TableColumnDef[] = [
    {columnId:'storeNameWithCodeCheck', header: "選択", width: 50, align: "center", checkbox: true },
    {columnId:'storeNameWithCode', header:"店舗", width: 250, align:"left"},
  ];
  public subtasks: SubTask[];
  displayColumnTableSubTask: string[] = [];
  displayColumnTableStore: string[] = [];
  emojiMst: EmojiDto[];
  private reHandle: boolean = true;
  // emojiImgList: EmojiImageDto[];
  public photoReportFormControl: FormControl = new FormControl(true);
  public statusNotStartedFormControl: FormControl = new FormControl(true);
  // public statusStartedFormControl: FormControl = new FormControl(false);
  public statusCompletedFormControl: FormControl = new FormControl(true);
  public isSubTaskSelected: boolean;
  public isStoreSelected: boolean;

  public tableTargetStore = 'tbl-target-store';
  public tableSubtask = 'tbl-subtask'; 
  public groupCheckbox = 'grp-checkbox';

  public subTaskOtherStoresShow: SubTaskOtherStoresResultRecDto[] = [];
  public formOutputTarget: FormControl = new FormControl("1");

  constructor(
    private taskService: TaskService,
    private commonService: CommonService,
    private httpBasic: HttpBasicService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.taskRec) return;
    // if (this.matPaginator?.pageIndex) this.matPaginator.pageIndex = 0;
    if (this.matPaginator) this.matPaginator.pageIndex = 0;
    this.taskService.subTaskOtherStoresRecs = [];
    this.taskService.subTaskOtherStoresRecsRecordCount = 0;
    this.subTaskOtherStoresShow = [];
    this.emojiMarkMapOld.clear();
    this.emojiMarkMap.clear();
    if(changes.taskRec && changes.taskRec.currentValue && changes.taskRec.currentValue != changes.taskRec.previousValue) {
      this.initSubtask();
    }
    if(changes.currentSubTask && changes.currentSubTask.currentValue != undefined ) {
      this.initCurrentSubtask();
    }
  }

  ngOnInit() {
    this.displayColumnTableSubTask = this.subTaskColumnDefs.map(column => column.columnId);
    this.displayColumnTableStore = this.storeColumnDefs.map(column => column.columnId);
  }

  setTableHeight() {
    setTimeout(() => { this.setTableHeightBody(); }, 0);
  }

  setTableHeightBody() {
    let id = "result-table";
    let remHeight = this.commonService.getHeightBelow(id);
    if (remHeight !== undefined) {
      let paginatorHeight = 56;
      let margin = 10 + 5;
      let btnBox = 24;
      let height = remHeight - paginatorHeight - margin - btnBox;
      if (height < 190) height = 190;
  
      let elem = document.getElementById(id);
      if (elem) elem.style.maxHeight = "" + height + "px";
    }
  }

  styleFor(id: string) {
    for (var colDef of this.subTaskColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width ? colDef.width + "px" : '100%',
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
    for (var colDef of this.storeColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width ? colDef.width + "px" : '100%',
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id: string) {
    for (var colDef of this.subTaskColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width ? colDef.width + "px" : '100%'
        }
      }
    }
    for (var colDef of this.storeColumnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width ? colDef.width + "px" : '100%'
        }
      }
    }
  }

  onItemChecked(cellCd: string, checked: boolean) {
    if(this.subtaskSelected.has(cellCd.toString())) this.subtaskSelected.set(cellCd, checked);
    if(this.targetStoreSelected.has(cellCd)) this.targetStoreSelected.set(cellCd, checked);
    this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
  }

  clearAll(action: string) {
    if(action === this.tableTargetStore){
      this.targetStoreSelected.forEach((value, key) => {
        this.targetStoreSelected.set(key, false)
      })
    }
    if(action === this.groupCheckbox){
      this.statusNotStartedFormControl.setValue(false);
      this.statusCompletedFormControl.setValue(false);
    }
    if(action === this.tableSubtask){
      this.subtaskSelected.forEach((value, key) => {
        this.subtaskSelected.set(key, false)
      })
    }
    this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
  }

  initSubtask() {
    this.subtasks = [];
    this.targetStores = [];
    this.subtaskSelected = new Map();
    if(this.taskRec.subTasks.length < 1) return;
    this.taskRec.subTasks.map(subtask => {
      this.subtasks.push(subtask);
      this.subtaskSelected.set(subtask.subTaskId+'', this.currentSubTask == undefined ? false : subtask.photoReport);
    });
    this.taskRec.targetStores.map(store => {
      this.targetStores.push({
        storeCd: store.storeCd,
        storeNameWithCode: store.storeNameWithCode,
        storeNameWithCodeCheck: store.storeNameWithCode,
        storeName: store.storeName,
      })
      this.targetStoreSelected.set(store.storeNameWithCode, true);
    });
    if(this.isFromTaskHq){
      this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
      this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    }
  }

  initCurrentSubtask() {
    if(!this.isFromTaskHq){
      this.subtaskSelected.forEach((val, key) => {
        this.subtaskSelected.set(key, false);
      });
      this.subtaskSelected.set(this.currentSubTask.subTaskId +'', true);
    }
  }

  setAll(action: string){
    if(action === this.tableTargetStore){
      this.targetStoreSelected.forEach((value, key) => {
        this.targetStoreSelected.set(key, true);
      })
    }
    if(action === this.groupCheckbox){
      this.statusNotStartedFormControl.setValue(true);
      this.statusCompletedFormControl.setValue(true);
    }
    if(action === this.tableSubtask){
      this.subtaskSelected.forEach((value, key) => {
        this.subtaskSelected.set(key, true)
      })
    }
    this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
  }

  doSearch() {
    this.matPaginator.pageIndex = 0;
    this.search();

    /*
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.search();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.search();
        }
      }
    );
    */
  }

  search() {
    const listStatus = [];
    this.emojiMarkMap.clear();
    this.emojiMarkMapOld.clear();
    if (this.statusCompletedFormControl.value) listStatus.push(1); 
    if (this.statusNotStartedFormControl.value) listStatus.push(0); 
    const storeTarget = Array.from(this.targetStoreSelected, (([key, value]) => { 
      if (value) {
        for (let index = 0; index < this.targetStores.length; index++) {
          const element = this.targetStores[index];
          if(element.storeNameWithCode == key) return element.storeCd;
        }
      }
    })).filter(item => item != null);

    let request: ReqSubTaskResultOtherStoresSearch = {
      access: this.commonService.loginUser,
      taskId: this.taskRec.taskId,
      imagesFlag: this.photoReportFormControl.value ? 1 : 0,
      listSubTaskIdCond: Array.from(this.subtaskSelected, (([key, value]) => { if (value) return +key})).filter(item => item != null),
      listSubTaskStoreCond: storeTarget,
      listSubTaskStatusCond: listStatus,
      page: {
        pageNum: this.matPaginator?.pageIndex || 0,
        dispNum: this.matPaginator?.pageSize || this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex]
      },
      imageMarkList: Array.from(this.imageMarkMap, (([name, value]) => value)),
      isResponsibleUser: this.commonService.config.task.responsibleIsLoginUser
    };

    const req: ReqEmojiSearch = {
      access: this.commonService.loginUser
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.getEmoji(req).subscribe(
      (response: RspEmojiSearch) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.receiveEmoji(response);
        this.searchSubTaskResultOtherStores(request);
      },
      (error) => {
        this.commonService.closeSpinner();
        subsc.unsubscribe();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveEmoji(response: RspEmojiSearch) {
    if (this.httpBasic.handleAppError(response)) return;
    if(response.result) {
      this.emojiMst = response.result;
    } 
  }

  searchSubTaskResultOtherStores(req: ReqSubTaskResultOtherStoresSearch) {
    const dialogRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.searchSubTaskResultOtherStores(req).subscribe(
      (response: RspSubTaskResultOtherStoresSearch) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(dialogRef);
        this.receiveSubTaskResultOtherStores(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(dialogRef);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveSubTaskResultOtherStores(response: RspSubTaskResultOtherStoresSearch) {
    if (this.httpBasic.handleAppError(response)) return;

    this.taskService.subTaskOtherStoresRecs = [];
    this.taskService.subTaskOtherStoresRecs = response.result.map(item => new SubTaskOtherStoresResultRecDto(item, this.commonService, this.taskService));
    this.taskService.subTaskOtherStoresRecsRecordCount = response.recordCount || 0;
    this.initEmojiImg();

    this.pageChanged(null);
  }

  initEmojiImg() {
    this.taskService.subTaskOtherStoresRecs.map(subtask => {
      subtask.imageList?.map(img => {
        img.emoteList.map(emote => {
          emote.formControl = new FormControl(emote.emoteNumFn);
          const key: string = `${emote.taskIdFn}_${emote.subTaskIdFn}_${emote.storeCdFv}_${img.imageId}_${emote.emoteIdFn}`;
          this.emojiMarkMapOld.set(key, emote);
        })
      })
    });
  }

  clearSearch(){
    this.initSubtask();
    this.initCurrentSubtask();
    this.photoReportFormControl.setValue(true);
    this.statusNotStartedFormControl.reset(true);
    this.statusCompletedFormControl.reset(true);
    this.isSubTaskSelected = Array.from(this.subtaskSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
    this.isStoreSelected = Array.from(this.targetStoreSelected, (([key, value]) =>  value)).filter(item => item == true).length == 0;
  }

  showIcons(item: SubTaskOtherStoresResultRecDto){
    if(!item.imageList[item.currentImageDisplayIndex].isShowEmoji) {
      item.imageList[item.currentImageDisplayIndex].isShowEmoji = true; 
      return;
    }
    if(item.currentImageDisplay == undefined || item.currentImageDisplay == null ) return;
    if(this.emojiMst.length > 0) {
      item.imageList[item.currentImageDisplayIndex].isShowEmoji = !item.imageList[item.currentImageDisplayIndex].isShowEmoji;
    }
  }

  handlerNextImage(item: SubTaskOtherStoresResultRecDto) {    
    item.nextImage((item: SubTaskOtherStoresResultRecDto) => {
      this.updateImageMarkMap(item);
      
      item.imageList.map(img => {
        img.emoteList.map(emote => {
          
          const key: string = `${emote.taskIdFn}_${emote.subTaskIdFn}_${emote.storeCdFv}_${img.imageId}_${emote.emoteIdFn}`;

          if(!this.emojiMarkMapOld.has(key)) {
            emote.formControl = new FormControl(emote.emoteNumFn);
            this.emojiMarkMapOld.set(key, emote);

          }
        })
      })
    });
  }

  handlerPrevImage(item: SubTaskOtherStoresResultRecDto) {
    item.prevImage((item: SubTaskOtherStoresResultRecDto) => {
      this.updateImageMarkMap(item);
      item.imageList.map(img => {
        img.emoteList.map(emote => {
          
          const key: string = `${emote.taskIdFn}_${emote.subTaskIdFn}_${emote.storeCdFv}_${img.imageId}_${emote.emoteIdFn}`;

          if(!this.emojiMarkMapOld.has(key)) {
            emote.formControl = new FormControl(emote.emoteNumFn);
            this.emojiMarkMapOld.set(key, emote);

          }
        })
      });
      if(item.imageList[item.currentImageDisplayIndex].isShowEmoji != undefined) item.imageList[item.currentImageDisplayIndex].isShowEmoji= false;
      // this.initEmojiImg();
    });
  }

  updateImageMarkMap(data: SubTaskOtherStoresResultRecDto) {
    const item = { ...data }
    if (!item.currentImageDisplay) return;
    const key: string = `${item.currentImageDisplay.taskId}_${item.currentImageDisplay.subTaskId}_${item.currentImageDisplay.storeCd}`;

    if (item.currentImageDisplayIndex > 0) {
      if (item.currentImageDisplay)
        this.imageMarkMap.set(key, { ...item.currentImageDisplay });
    } else {
      if (this.imageMarkMap.has(key))
        this.imageMarkMap.delete(key);
    }
    return;
  }

  viewPhoto(item: SubTaskOtherStoresResultRecDto) {
    item.prepareViewImage((photo: TaskImageRecDto) => {
      if (!photo && photo.image) return
      let subsc = this.commonService.dialog.open(TaskPhotoViewDialogComponent, {
        data: photo
      }).afterClosed().subscribe(
        (data) => {
          subsc.unsubscribe();
        }
      );
    });
  }

  async pageChanged(pageEvent: PageEvent): Promise<void> {
    let start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
    let end = start + this.matPaginator.pageSize;
    if (end > this.taskService.subTaskOtherStoresRecs.length) end = this.taskService.subTaskOtherStoresRecs.length;
    this.subTaskOtherStoresShow = this.taskService.subTaskOtherStoresRecs.slice(start, end);
    document.getElementById("result-table")?.scrollTo(0, 0);

    let imageRequire: SubTaskResultOtherStoresImageDto[] = [];
    this.subTaskOtherStoresShow.filter((dto) => dto.imageList === undefined).forEach((rec) => {
      imageRequire.push({
        taskId: rec.taskId,
        storeCd: rec.storeCd,
        subTaskId: rec.subTaskId,
        imageList: undefined,
        currentImageDisplayIndex: -1
      });
    });

    let maxCntPerRequest = 20;
    let getStart = 0;
    let rem = imageRequire.length;
    while (rem > 0) {
      let cnt = maxCntPerRequest;
      if (cnt > rem) cnt = rem;
      let list = imageRequire.slice(getStart, getStart + cnt);
      await this.getImageRequire(list);
      rem -= cnt;
      getStart += cnt;
    }

    return new Promise<void>((resolve) => resolve());

    /*
    let deactivate = this.canDeactivate();
    if (deactivate == true) {
      this.search();
      return;
    }
    (deactivate as Observable<boolean>).subscribe(
      data => {
        if (data == true) {
          this.search();
        }
      }
    );
    */
  }

  async getImageRequire(list: SubTaskResultOtherStoresImageDto[]): Promise<void> {
    if (list.length === 0) return new Promise<void>((resolve) => resolve());

    return new Promise((resolve, reject) => {
      let request: ReqSubTaskResultOtherStoresImageSearch = {
        access: this.commonService.loginUser,
        list: list
      };
      let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "画像取得中・・・");
      let subsc = this.httpBasic.generalRequest("SubTaskResultOtherStoresImageSearch", request).subscribe(
        (response: RspSubTaskResultOtherStoresImageSearch) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.receiveImageRequire(response);
          resolve();
        },
        (error) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.httpBasic.handleError(error);
          reject();
        }
      );
    });
  }

  receiveImageRequire(response: RspSubTaskResultOtherStoresImageSearch): Promise<void> {
    if (this.httpBasic.handleAppError(response)) return;

    response.result.forEach((dto) => {
      let item = this.subTaskOtherStoresShow.find((rec) => rec.taskId === dto.taskId && rec.storeCd === dto.storeCd && rec.subTaskId === dto.subTaskId);
      if (item) {
        item.imageList = dto.imageList;
        item.currentImageDisplayIndex = dto.currentImageDisplayIndex;
        item.maxImageDisplayIndex = item.imageList.length - 1;
        if (item.currentImageDisplayIndex >= 0) {
          item.currentImageDisplay = {...item.imageList[item.currentImageDisplayIndex]};
        }
        item.imageList?.map(img => {
          img.emoteList.map(emote => {
            emote.formControl = new FormControl(emote.emoteNumFn);
            const key: string = `${emote.taskIdFn}_${emote.subTaskIdFn}_${emote.storeCdFv}_${img.imageId}_${emote.emoteIdFn}`;
            this.emojiMarkMapOld.set(key, emote);
          })
        })
        }
    });
  }

  onCheck(img: TaskImageRecDto, emoteDto: EmojiDto, item: SubTaskOtherStoresResultRecDto) {
    if (this.reHandle) {
      this.reHandle = false;
      const key: string = `${img.taskId}_${img.subTaskId}_${img.storeCd}_${img.imageId}_1`;

      if (this.emojiMarkMapOld.has(key)) {
        const emote = this.emojiMarkMapOld.get(key);
        if (emote.hasUserReacted) {
          emote.action = 2;
          emote.hasUserReacted = 0;
          emote.formControl.setValue(emote.formControl.value - 1);
        } else {
          if (emote.action == 2) {
            if (emote.formControl.value + 1 - emote.emoteNumFn >= 1) {
              emote.hasUserReacted = 0;
              emote.formControl.setValue(emote.formControl.value - 1);
            } else {
              emote.action = 1;
              emote.hasUserReacted = 1;
              emote.formControl.setValue(emote.formControl.value + 1);
              this.emojiMarkMap.delete(key);
              return img.isShowEmoji = false;
            }
          } else {
            emote.action = 1;
            emote.hasUserReacted = 1;
            emote.formControl.setValue(emote.formControl.value + 1);
          }
        }
        this.emojiMarkMap.set(key, emote);
      } else {
        if (this.emojiMarkMap.has(key)) {
          this.emojiMarkMap.delete(key);
          item.currentImageDisplay.emoteList = item.currentImageDisplay.emoteList.filter(emote => emote != undefined && emote.emoteIdFn != 1);
        } else {
          const emote: EmojiImageDto = {
            descriptionFv: "thumbs up",
            dispOrderFn: 1,
            emoteBrowserDataFv: "👍",
            emoteCodeFv: "U+1F44D",
            emoteIdFn: 1,
            emoteNameFv: "thumbs up",
            emoteNumFn: 1,
            emotePathFv: "assets/icons/like.png",
            hasUserReacted: 1,
            imageIdFn: img.imageId,
            storeCdFv: img.storeCd,
            subTaskIdFn: img.subTaskId,
            taskIdFn: img.taskId,
            action: 1,
            formControl: new FormControl(1)
          }
          this.emojiMarkMap.set(key, emote);
          item.currentImageDisplay.emoteList.push(emote);
          item.currentImageDisplay.emoteList.sort((a, b) => a.dispOrderFn - b.dispOrderFn);
        }
      }
      img.isShowEmoji = false;
      this.saveAllEmoji();
    }
  }

  clearAllEmoji(){
    if(this.emojiMarkMap.size < 1) return;

    for (let index = 0; index < this.taskService.subTaskOtherStoresRecs.length; index++) {
      const subTask = this.taskService.subTaskOtherStoresRecs[index];
      for (let index = 0; index < subTask.imageList.length; index++) {
        const image = subTask.imageList[index];
        for (let index = 0; index < image.emoteList.length; index++) {
          let emote = image.emoteList[index];
          if(emote) {
            const key: string = `${emote.taskIdFn}_${emote.subTaskIdFn}_${emote.storeCdFv}_${emote.imageIdFn}_${emote.emoteIdFn}`;
            if(this.emojiMarkMapOld.has(key) && !this.emojiMarkMap.has(key)){
              const emojiOld = this.emojiMarkMapOld.get(key);
              emote.formControl.setValue(emojiOld.emoteNumFn);
            } else {
              if(this.emojiMarkMap.has(key) && this.emojiMarkMap.get(key).action == 2) {
                  const emojiOld = this.emojiMarkMapOld.get(key);
                  emote.action = 1;
                  emote.hasUserReacted = 1;
                  emote.formControl.setValue(emojiOld.emoteNumFn);
                  // this.emojiMarkMap.delete(key);               
              } else {
                delete image.emoteList[index];
                this.emojiMarkMapOld.delete(key);   
                this.emojiMarkMap.delete(key);                           
              }
            }
          }
        }
        image.isShowEmoji = false;
      }
      subTask.imageList = subTask.imageList.filter(img => img != undefined);
    }
    this.emojiMarkMap.clear();
    this.emojiMarkMapOld.clear();                           

    this.initEmojiImg();
    this.cdr.detectChanges();
  }

  saveAllEmoji() {
    const emojiChanges = Array.from(this.emojiMarkMap, (([key, value]) => {
      const emoteImgDto: TaskEmoteImageDto = {
        taskId: value.taskIdFn,
        actUserIdFv: this.commonService.loginUser.userId,
        action: value.action,
        emoteIdFn: value.emoteIdFn,
        imageId: value.imageIdFn,
        storeCd: value.storeCdFv,
        subTaskId: value.subTaskIdFn
      }
      return emoteImgDto
    }));
    const req: ReqTaskEmoteImageUpdate = {
      updateList: emojiChanges,
      access: this.commonService.loginUser
    }
    this.httpBasic.updateTaskEmoteImage(req).subscribe((response: RspTaskEmoteImageUpdate) => {
      this.receiveEmojiUpdate(response);
      this.reHandle = true;
    },
      error => {
        this.httpBasic.handleError(error);
        this.reHandle = true;
      });
  }

  receiveEmojiUpdate(response: RspTaskEmoteImageUpdate) {
    if (this.httpBasic.handleAppError(response)) return;

    if(response.successList) {
      response.successList.map(emoteImg => {
        this.taskService.subTaskOtherStoresRecs.map(img => {
          const image = img.imageList.find(img => img.storeCd == emoteImg.storeCd && img.taskId == emoteImg.taskId && img.subTaskId == emoteImg.subTaskId && img.imageId == emoteImg.imageId);
          if(image && image.emoteList) {
            const emote = image.emoteList.find(emote => emote.emoteIdFn == emoteImg.emoteIdFn);
            const key: string = `${img.taskId}_${img.subTaskId}_${img.storeCd}_${emote.imageIdFn}_${emote.emoteIdFn}`;
            if(this.emojiMarkMapOld.has(key)) {
              const emoteOld = this.emojiMarkMapOld.get(key);
              emote.hasUserReacted = emoteOld.hasUserReacted;
              emote.emoteNumFn = emoteOld.formControl.value;
              emote.formControl.setValue(emote.emoteNumFn);
              emote.action = 0;
              this.emojiMarkMapOld.delete(key);
            }
            this.emojiMarkMapOld.set(key, emote);
          }
        });
      });
      this.emojiMarkMap.clear();
    } 
  }

  exportZip() {
    const listStatus = [];
    if (this.statusCompletedFormControl.value) listStatus.push(1); 
    if (this.statusNotStartedFormControl.value) listStatus.push(0); 
    const storeTarget = Array.from(this.targetStoreSelected, (([key, value]) => { 
      if (value) {
        for (let index = 0; index < this.targetStores.length; index++) {
          const element = this.targetStores[index];
          if(element.storeNameWithCode == key) return element.storeCd;
        }
      }
    })).filter(item => item != null);
    let request: ReqTaskOtherStoreDownloadZip = {
      access: this.commonService.loginUser,
      taskId: this.taskRec.taskId,
      listSubTaskIdCond: Array.from(this.subtaskSelected, (([key, value]) => { if (value) return +key})).filter(item => item != null),
      listSubTaskStoreCond: storeTarget,
      listSubTaskStatusCond: listStatus,
    };
    this.taskService.taskOtherStoreDownloadZip(request);
  }

  exportPDF() {
    const listStatus = [];
    this.emojiMarkMap.clear();
    this.emojiMarkMapOld.clear();
    if (this.statusCompletedFormControl.value) listStatus.push(1); 
    if (this.statusNotStartedFormControl.value) listStatus.push(0); 
    const storeTarget = Array.from(this.targetStoreSelected, (([key, value]) => { 
      if (value) {
        for (let index = 0; index < this.targetStores.length; index++) {
          const element = this.targetStores[index];
          if(element.storeNameWithCode == key) return element.storeCd;
        }
      }
    })).filter(item => item != null);
    let request: ReqTaskOtherStoreDownloadPdf = {
      access: this.commonService.loginUser,
      taskId: this.taskRec.taskId,
      imagesFlag: this.photoReportFormControl.value ? 1 : 0,
      listSubTaskIdCond: Array.from(this.subtaskSelected, (([key, value]) => { if (value) return +key})).filter(item => item != null),
      listSubTaskStoreCond: storeTarget,
      listSubTaskStatusCond: listStatus,
      page: {
        pageNum: this.matPaginator?.pageIndex || 0,
        dispNum: this.matPaginator?.pageSize || this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex]
      },
      imageMarkList: Array.from(this.imageMarkMap, (([name, value]) => value)),
      isResponsibleUser: this.commonService.config.task.responsibleIsLoginUser
    }
    this.taskService.exportPdfTaskOtherStores(request);
  }

  exportExcel() {
    const listStatus = [];
    this.emojiMarkMap.clear();
    this.emojiMarkMapOld.clear();
    if (this.statusCompletedFormControl.value) listStatus.push(1); 
    if (this.statusNotStartedFormControl.value) listStatus.push(0); 
    const storeTarget = Array.from(this.targetStoreSelected, (([key, value]) => { 
      if (value) {
        for (let index = 0; index < this.targetStores.length; index++) {
          const element = this.targetStores[index];
          if(element.storeNameWithCode == key) return element.storeCd;
        }
      }
    })).filter(item => item != null);
    let request: ReqTaskOtherStoreDownloadXlsx = {
      access: this.commonService.loginUser,
      taskId: this.taskRec.taskId,
      imagesFlag: this.photoReportFormControl.value ? 1 : 0,
      listSubTaskIdCond: Array.from(this.subtaskSelected, (([key, value]) => { if (value) return +key})).filter(item => item != null),
      listSubTaskStoreCond: storeTarget,
      listSubTaskStatusCond: listStatus,
      page: {
        pageNum: this.matPaginator?.pageIndex || 0,
        dispNum: this.matPaginator?.pageSize || this.commonService.paginatorOption.pageSizeOptions[this.commonService.paginatorOption.pageSizeIndex]
      },
      imageMarkList: Array.from(this.imageMarkMap, (([name, value]) => value)),
      isResponsibleUser: this.commonService.config.task.responsibleIsLoginUser

    };
    this.taskService.taskOtherStoreDownloadXLsx(request);
  }

  async exportPDFNew() {
    let pdf = new TaskImagesPDF(this.commonService, this.httpBasic);
    if (this.formOutputTarget.value === "1") {
      pdf.createAndDownload(this.taskRec, this.subTaskOtherStoresShow);
    } else {
      let currentPageIndex = this.matPaginator.pageIndex;
      let remCnt = Math.ceil(this.taskService.subTaskOtherStoresRecsRecordCount / this.matPaginator.pageSize);
      this.matPaginator.pageIndex = 0;
      while (remCnt > 0) {
        await this.pageChanged(null);
        this.matPaginator.pageIndex++;
        remCnt--;
      }
      this.matPaginator.pageIndex = currentPageIndex;
      await this.pageChanged(null);
      pdf.createAndDownload(this.taskRec, this.taskService.subTaskOtherStoresRecs);
    };
  }

  async exportZipNew() {
    let excel = new TaskImagesZip(this.commonService, this.httpBasic);
    if (this.formOutputTarget.value === "1") {
      excel.createAndDownload(this.subTaskOtherStoresShow);
    } else {
      let currentPageIndex = this.matPaginator.pageIndex;
      let remCnt = Math.ceil(this.taskService.subTaskOtherStoresRecsRecordCount / this.matPaginator.pageSize);
      this.matPaginator.pageIndex = 0;
      while (remCnt > 0) {
        await this.pageChanged(null);
        this.matPaginator.pageIndex++;
        remCnt--;
      }
      this.matPaginator.pageIndex = currentPageIndex;
      await this.pageChanged(null);
      excel.createAndDownload(this.taskService.subTaskOtherStoresRecs);
    };
  }

  async exportExcelNew() {
    let excel = new TaskImagesExcel(this.commonService, this.httpBasic);
    if (this.formOutputTarget.value === "1") {
      excel.createAndDownload(this.taskRec, this.subTaskOtherStoresShow);
    } else {
      let currentPageIndex = this.matPaginator.pageIndex;
      let remCnt = Math.ceil(this.taskService.subTaskOtherStoresRecsRecordCount / this.matPaginator.pageSize);
      this.matPaginator.pageIndex = 0;
      while (remCnt > 0) {
        await this.pageChanged(null);
        this.matPaginator.pageIndex++;
        remCnt--;
      }
      this.matPaginator.pageIndex = currentPageIndex;
      await this.pageChanged(null);
      excel.createAndDownload(this.taskRec, this.taskService.subTaskOtherStoresRecs);
    };
  }

  getValue(item: SubTask, key: string) {
    switch (key) {
      case "photoReport": {
        if (item.photoReport) return "要";
        return "";
      }
      default:
        return item[key];
    }
  }

  canDeactivate() {
    if (this.emojiMarkMap.size == 0) return true;
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

}
