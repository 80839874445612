import { CommonService } from '../service/common.service';
import { RspItemHistDto } from '../response/rsp-item-hist-seach';
export class ItemHistRec {
    NO: number;
    calcTrgtDateFd: string;
    orderBaraNumFn: number;
    orderRecommendBaraNumFn: number;
    stkNumFn: number;
    stkEditNumFn: number;
    salesNumFn: number;
    rcvBaraNumFn: number;
    orderDateFd: number;
    inMoveNumFn: number;
    outMoveNumfn: number;
    disNumFn: number;
    rtnNumFn: number;
    transNumFn: number;
    revNumFn: number;
    isFutureData: number;
    constructor(private itemHistDto: RspItemHistDto, private commonService: CommonService) {
        for (var key of Object.keys(itemHistDto)) {  
            this[key] = itemHistDto[key];
        }        
        var date = new Date(this.calcTrgtDateFd);
        if (commonService.copyDate(date) >= commonService.copyDate(new Date())) {
            this.isFutureData = 1;
        }
       
    }
}