import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CsvData, CsvLineData } from 'src/app/common/csv-data';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CsvTableComponent } from 'src/app/partsCommon/csv-table/csv-table.component';
import { CsvParamItemCheckDto } from 'src/app/request/req-csv-param-item-check';
import { CsvParamItemCsvUpdateDto } from 'src/app/request/req-csv-param-item-update';
import { RspItemCsvUpdateDto } from 'src/app/response/rsp-csv-item-update';
import { RspItemCsvCheckDto } from 'src/app/response/rsp-csv-param-item-check';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CsvParamItemPrioritize } from './csv-param-item-prioritize';

@Component({
  selector: 'app-csv-param-item',
  templateUrl: './csv-param-item.component.html',
  styleUrls: ['./csv-param-item.component.css']
})
export class CsvParamItemComponent implements OnInit, OnDestroy {

  private isReadonly: boolean;
  private subscription$ = new Subject();
  listPrioritize: CsvLineData[] = [];
  public paramList: CsvData;
  public tableWidth: any;
  public displayColumnIds: string[];
  public columnIds: string[] = ["lineNumber", "storeCd", "storeName"];
  public columnIdsWithError: string[] = ["lineNumber", "errorWaring", "storeCd", "storeName"];
  public columnDefs: TableColumnDef[] = [
    { columnId: "storeCd", header: "店舗コード", width: 70, align: "center" },
    { columnId: "storeName", header: "店舗名", width: 150, csvComment: "[空欄可]" },
    { columnId: "itemCd", header: "商品コード", width: 100, align: "center" },
    { columnId: "itemName", header: "商品名", width: 300, align: "left", csvComment: "[空欄可]" },
    { columnId: "autoOrder", header: "自動発注", width: 70, align: "center", csvComment: "[対象、対象外を指定]" },
    { columnId: "minZai", header: "最低陳列", width: 70, align: "right", csvComment: "[設定削除は0を指定]" },
    { columnId: "maxZai", header: "最大陳列", width: 70, align: "right", csvComment: "[設定削除は0を指定]" },
    { columnId: "minStockDays", header: "最低在庫日数", width: 95, align: "right", csvComment: "[設定削除は0を指定]" },
    { columnId: "maxStockDays", header: "最大在庫日数", width: 95, align: "right", csvComment: "[設定削除は0を指定]" },
    { columnId: "autoOrderLot", header: "自発用ロット", width: 95, align: "right", csvComment: "[設定削除は0を指定、ロットの整数倍を指定]" },
  ];
  public displayColumnDefs: TableColumnDef[] = [];

  public layoutComment: string = "空欄の設定項目は更新されません。";

  @ViewChild(CsvTableComponent, { static: false }) csvTableComponent: CsvTableComponent;

  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService
  ) { }
  ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }

  ngOnInit() {
    // this.commonService.pageTitle = 'パラメータ取込み（商品）';
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.isReadonly = this.commonService.checkPrivilege("csvparamitem");

    if (this.commonService.stores.length <= 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "この機能を使用する権限がありません。");
      this.commonService.router.navigate(['top']);
      return;
    }

    this.displayColumnDefs = [...this.columnDefs];
  }

  getCsvData(csvData: CsvData) {
    let listPrioritize: CsvParamItemPrioritize[] = [];
    let items = {};

    if (csvData.lines.length > 0) {
      for (let i = 0; i < csvData.lines.length; i++) {
        listPrioritize.push(
          {
            ...csvData.lines[i],
            isPrioritize: true
          });
        /*
        for (let j = i + 1; j < csvData.lines.length; j++) {
          if (csvData.lines[i].columnData['storeCd'].trim() === csvData.lines[j].columnData['storeCd'].trim() &&
            csvData.lines[i].columnData['itemCd'].trim() === csvData.lines[j].columnData['itemCd'].trim() &&
            csvData.lines[i].lineNumber < csvData.lines[j].lineNumber
          ) {
            listPrioritize[i] =
            {
              ...csvData.lines[i],
              isPrioritize: false
            };
          }
        }
        */
        let storeCd = csvData.lines[i].columnData['storeCd'].trim();
        let itemCd = csvData.lines[i].columnData['itemCd'].trim();
        let key: string = "" + storeCd + "-" + itemCd;
        if (items[key] != undefined) {
          listPrioritize[items[key]].isPrioritize = false;
        }
        items[key] = i;
      }
    }
    const csvDataPrioritize: CsvLineData[] = [];
    listPrioritize.filter(item => item.isPrioritize).map((value: CsvParamItemPrioritize) => {
      const item: CsvLineData = {
        lineNumber: value.lineNumber,
        columnData: value.columnData,
        errorMsg: value.errorMsg,
        warningMsg: value.warningMsg,
      }
      csvDataPrioritize.push(item);
    });
    csvData.lines = csvDataPrioritize;
    this.paramList = csvData;
    if (this.csvTableComponent) {
      this.csvTableComponent.init(this.paramList);
    }
  }

  isDisableUpdateBtn() {
    if (this.paramList == undefined) return true;
    if (this.paramList.errorCount == undefined) return true;
    if (this.paramList.errorCount > 0) return true;
    return false;
  }

  doConfirm() {
    /* TODO: server call */
    const request: CsvParamItemCheckDto[] = this.getDataOnTable();
    if (request.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "データがありません。");
      return;
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "確認中です・・・");
    this.httpBasic.itemCsvErrorCheck(request).pipe(takeUntil(this.subscription$)).subscribe(
      (response: RspItemCsvCheckDto) => {
        this.receiveResult(response);

      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )

  }
  getDataOnTable() {
    const request = [];
    this.paramList.lines.map(value => {
      const item = {
        storeCd: value.columnData['storeCd'],
        storeName: value.columnData['storeName'],
        itemCd: value.columnData['itemCd'],
        itemName: value.columnData['itemName'],
        autoOrder: value.columnData['autoOrder'],
        minZai: value.columnData['minZai'],
        maxZai: value.columnData['maxZai'],
        minStockDays: value.columnData['minStockDays'],
        maxStockDays: value.columnData['maxStockDays'],
        autoOrderLot: value.columnData['autoOrderLot'],
      }
      request.push(item);
    });
    return request;
  }
  receiveResult(response: RspItemCsvCheckDto) {
    this.clearProgressState();
    if (this.httpBasic.handleAppError(response)) return;
    if (response.result.length > 0) {

      response.result.map((item) => {
        if (item.errors) {
          this.paramList.lines[item.line].errorMsg = item.errors;

        }
        // TODO warning 
        // this.paramList.lines[item.line].warningMsg = item.warning;

      });
    }    
    this.csvTableComponent.handleError();
  }
  doUpdate() {
    const request: CsvParamItemCsvUpdateDto[] = this.getDataOnTable();
    if (request.length < 1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, "テストするデータがありません");
      return;
    }

    this.commonService.openSpinner(this.commonService.pageTitle, "登録中・・・");
    this.httpBasic.updateItemCsv(request).pipe(takeUntil(this.subscription$)).subscribe(
      (response: RspItemCsvUpdateDto) => {
        this.clearProgressState();
        if (this.httpBasic.handleAppError(response)) return;
        this.commonService.openNotificationDialog(this.commonService.pageTitle, '登録しました。');
      },
      error => {
        this.clearProgressState();
        this.httpBasic.handleError(error);
      }
    )

  }

  clearProgressState() {
    this.commonService.closeSpinner();
  }

}

