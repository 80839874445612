import { CtgPathResultRowDto } from '../response/rsp-ctg-path';

export class CtgPathRec {
    storeCd: string;
    level: number;
    ctgCd: string[];
    ctgName: string[];

    constructor(ctgPathResultRowDto: CtgPathResultRowDto[]) {
        this.level = 0;
        this.ctgCd = ["", "", "", ""];
        this.ctgName = ["", "", "", ""];

        for (var row of ctgPathResultRowDto) {
            this.storeCd = row.storeCdFv;
            this.ctgCd[row.ctgLevelFn] = row.ctgCdFv;
            this.ctgName[row.ctgLevelFn] = row.ctgNameFv;
            if (row.ctgLevelFn > this.level) {
                this.level = row.ctgLevelFn;
            }
        }
    }
}
