import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-table-item-dialog',
  templateUrl: './table-item-dialog.component.html',
  styleUrls: ['./table-item-dialog.component.css']
})
export class TableItemDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TableItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {display: TableColumnDef[], nonDisplay: TableColumnDef[], disableDisplay?: TableColumnDef[]},
    public commonService: CommonService) { }

  ngOnInit() {
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
  
  isDisableColDef(item: TableColumnDef) {
    if(this.data.disableDisplay?.includes(item)) {
      return true;
    }
    return false;
  }
}
