import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ExpiryGroup, ExpiryItemRec } from '../0_def/expiry-item-rec';
import { ReqGetExpiryItemByJan, RspGetExpiryItemByJan } from 'src/app/webservice/item-expiry';

@Injectable({
  providedIn: 'root'
})
export class ItemExpiryService {

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService,
    private _snackBar: MatSnackBar
    ) {
  }

  getExpiryItemByJan(storeCd: string, itemCd: string, targetGroupUsrCd: string): Promise<ExpiryItemRec> {
    return new Promise<ExpiryItemRec>((resolve) => {
      let request: ReqGetExpiryItemByJan = {
        access: this.commonService.loginUser,
        storeCd: storeCd,
        itemCd: itemCd
      };
  
      let ref= this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
      let subsc = this.httpBasic.generalRequest("GetExpiryItemByJan", request).subscribe(
        (response: RspGetExpiryItemByJan) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          resolve(this.receiveQueryItem(response, targetGroupUsrCd));
        },
        (error) => {
          subsc.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.httpBasic.handleError(error);
          resolve(undefined);
        }
      );
    });
  }

  receiveQueryItem(response: RspGetExpiryItemByJan, targetGroupUsrCd: string): ExpiryItemRec {
    if (this.httpBasic.handleAppError(response)) return undefined;

    if (!response.item) {
      return null;
    }

    let dto = response.item;
    let rec = new ExpiryItemRec(this.commonService, this.httpBasic);
    rec.storeCd = dto.storeCd;
    rec.itemCd = dto.itemCd;
    rec.itemName = dto.itemName;
    rec.itemStandard = dto.itemStandard;
    rec.price = dto.price;
    rec.tax = dto.tax;
    rec.expiryType = dto.expiryType;
    rec.discountType = dto.discountType;
    rec.dateDef = [];
    if (dto.datesToExpiry0 != -1) rec.dateDef.push({datesToExpiry: dto.datesToExpiry0, action: dto.action0, discount: dto.discount0});
    if (dto.datesToExpiry1 != -1) rec.dateDef.push({datesToExpiry: dto.datesToExpiry1, action: dto.action1, discount: dto.discount1});
    if (dto.datesToExpiry2 != -1) rec.dateDef.push({datesToExpiry: dto.datesToExpiry2, action: dto.action2, discount: dto.discount2});
    if (dto.datesToExpiry3 != -1) rec.dateDef.push({datesToExpiry: dto.datesToExpiry3, action: dto.action3, discount: dto.discount3});
    if (dto.datesToExpiry4 != -1) rec.dateDef.push({datesToExpiry: dto.datesToExpiry4, action: dto.action4, discount: dto.discount4});

    rec.groupList = [];
    for (let i = 0; i < response.groups.length; i++) {
      let dto = response.groups[i];
      rec.groupList.push({
        groupId: dto.orderGroupId,
        groupUsrCd: dto.orderGroupUsrCd,
        groupName: dto.orderGroupName,
        beginDate: dto.beginDate,
        endDate: dto.endDate,
        sortKey: "",
        dateActions: []
      });
    }
    rec.targetGroup = rec.groupList.find((rec) => rec.groupUsrCd === targetGroupUsrCd);

    for (let i = 0; i < response.dateActions.length; i++) {
      let dto = response.dateActions[i];
      let group: ExpiryGroup = rec.groupList.find((item) => item.groupId === dto.groupId);
      if (!group) continue;       // oops
      group.dateActions.push(dto);
    }
    for (let i = 0; i < rec.groupList.length; i++) {
      let group = rec.groupList[i];
      group.dateActions.sort((a, b) => {
        if (a.itemDate < b.itemDate) return -1;
        if (a.itemDate > b.itemDate) return 1;
        return 0;
      });
    }

    // rec.setTargetDate(this.kijyunDate);
    rec.setAction(true);
    rec.prepareEdit();

    return rec;
  }

}
