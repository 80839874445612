import { Injectable } from '@angular/core';
import { ReqExecuteQuery } from '../request/req-execute-query';
import { RspExecuteQuery } from '../response/rsp-execute-query';
import { CommonService } from './common.service';
import { HttpBasicService } from './http-basic.service';

export interface CtgNameItem {
  storeCd:          string;          // '*' for 全店
  storeName:        string;
  ctgLevel:         number;         // 0 ～ 3
  ctgCd:            string;
  ctgName:          string;
  ctgNameWithCode:  string;
}

interface CtgTreeItem {
  ctgLevel:   number;         // 0 ～ 3
  ctgCd:      string;
  ctgName:    string;
  parent:     CtgTreeItem;
  child:      {
    // ctgCode: CtgTreeItem
  };
}

interface CtgTreeRoot {
  storeCd:    string;          // '*' for 全店
  storeName:  string;
  child:    {
    // ctgCode: CtgTreeItem
  };
}

interface CtgRec {
  storeCd:    string;
  storeName:  string;
  ctgLevel:   number;
  ctgCd:      string[];
  ctgCdName:  string;
}

/*
export interface CtgGroup {
  ctgGroupCd:         string;
  ctgGroupName:       string;
  svsPageApplyFlag:   number;
  salesPageApplyFlag: number;
  ctgs:               {ctgCd: string[], ctgName: string[]}[];
  expandedCtgs:       string[];
  displayOrder: number;
}
*/

@Injectable({
  providedIn: 'root'
})
export class CtgService {

  private ctgTree: {
    // storeCd: CtgTreeRoot
  };

  private ctgFlat: {
    // storeCd: CtgTreeRoot
  };

  /*
  private ctgGroup: {
    // ctgGroupCd: CtgGroup
  }
  */

  private allCtg: {ctgTree: {}, ctgFlat: {}};

  constructor(
    private commonService: CommonService,
    private httpBasic: HttpBasicService
  ) { }

  init(callback: () => void) {
    /*
    if (this.ctgTree) {
      callback();
      return;
    }
    */
    if (this.allCtg) {
      /* restore all ctg names */
      this.ctgTree = this.allCtg.ctgTree;
      this.ctgFlat = this.allCtg.ctgFlat;

      callback();
      return;
    }
    this.queryAllCtg(callback);
  }

  queryAllCtg(callback: () => void) {
    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: "ctg/getAllCtgName",
      bindVariables: []
    };

    this.queryCtg(true, request, callback);
  }

  initByQuery(queryId: string, bindVariables: string[], callback: ()=>void) {
    this.ctgTree = {};
    this.ctgFlat = {};

    let request: ReqExecuteQuery = {
      access: this.commonService.loginUser,
      queryId: queryId,
      bindVariables: bindVariables
    };

    this.queryCtg(false, request, callback);
  }

  initByCtgList(ctgList: {ctgTree: {}, ctgFlat: {}}) {
    this.ctgTree = ctgList.ctgTree;
    this.ctgFlat = ctgList.ctgFlat;
  }

  getCtgList(): {ctgTree: {}, ctgFlat: {}} {
    return {ctgTree: this.ctgTree, ctgFlat: this.ctgFlat};
  }

  queryCtg(isAll: boolean, request: ReqExecuteQuery, callback: () => void) {
    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.executeQuery(request).subscribe(
      (response: RspExecuteQuery) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.receiveCtg(response, isAll, callback);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinnerForSubComp(ref);
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveCtg(response: RspExecuteQuery, isAll: boolean, callback: () => void) {
    if (this.httpBasic.handleAppError(response)) return;
    this.ctgTree = {};
    this.ctgFlat = {};

    let ctgs: CtgRec[] = [];
    for (let item of response.rows) {
      let ctg: CtgRec = {
        storeCd:    item.colData[0],
        storeName:  item.colData[1],
        ctgLevel:   parseInt(item.colData[2]),
        ctgCd:      [
          item.colData[3],
          item.colData[4],
          item.colData[5],
          item.colData[6],
        ],
        ctgCdName: item.colData[7]
      }
      ctgs.push(ctg);
    }

    this.ctgTree["*"] = { storeCd: "*", storeName: "全店", child: {} };
    this.ctgFlat["*"] = {storeCd: "*", storeName: "全店", child: {}};
    for (let ctg of ctgs) {
      if (!this.ctgTree[ctg.storeCd]) {
        this.ctgTree[ctg.storeCd] = { storeCd: ctg.storeCd, storeName: ctg.storeName, child: {} };
      }
      if (!this.ctgFlat[ctg.storeCd]) {
        this.ctgFlat[ctg.storeCd] = {storeCd: ctg.storeCd, storeName: ctg.storeName, child: {}};
      }
      let parentStore = this.ctgTree[ctg.storeCd];
      let parentAll = this.ctgTree["*"];

      for (let level = 1; level <= ctg.ctgLevel; level++) {
        parentStore = parentStore && parentStore.child && parentStore.child[ctg.ctgCd[level - 1]];
        parentAll = parentAll && parentAll.child && parentAll.child[ctg.ctgCd[level - 1]];
      }
      let treeItem: CtgTreeItem = {
        ctgLevel:   ctg.ctgLevel,
        ctgCd:      ctg.ctgCd[ctg.ctgLevel],
        ctgName:    ctg.ctgCdName,
        parent:     parentStore,
        child:      {}
      };
      if (parentStore && !parentStore.child[ctg.ctgCd[ctg.ctgLevel]]) {
        parentStore.child[ctg.ctgCd[ctg.ctgLevel]] = treeItem;
        this.ctgFlat[ctg.storeCd][ctg.ctgCd[ctg.ctgLevel]] = treeItem;

        if (!parentAll.child[ctg.ctgCd[ctg.ctgLevel]]) {
          treeItem = {
            ctgLevel:   ctg.ctgLevel,
            ctgCd:      ctg.ctgCd[ctg.ctgLevel],
            ctgName:    ctg.ctgCdName,
            parent:     parentAll,
            child:      {}
          };
          parentAll.child[ctg.ctgCd[ctg.ctgLevel]] = treeItem;
          this.ctgFlat["*"][ctg.ctgCd[ctg.ctgLevel]] = treeItem;
        }
      }
    }

    /*
    this.commonService.stores = [];
    for (let key in  this.ctgTree) {
      if (key == "*") continue;
      this.commonService.stores.push({storeCd: this.ctgTree[key].storeCd, storeName: this.ctgTree[key].storeName});
    }
    */
    if (isAll) {
      /* Save all ctg names */
      this.allCtg = {
        ctgTree: this.ctgTree,
        ctgFlat: this.ctgFlat
      };
    }

    callback();
  }

  getChildNameList(storeCd: string, ctgCds: string[]): CtgNameItem[] {
    let tree = this.ctgTree[storeCd];
    if (!tree) return [];
    let storeName = tree["storeName"];
    for (let level = 0; level < ctgCds.length && level < this.commonService.config.maxCtgLevel; level++) {
      if (ctgCds[level] == undefined) break;
      if (!tree["child"]) return [];
      tree = tree["child"][ctgCds[level]];
    }

    let result: CtgNameItem[] = [];
    for (let ctgCd in tree["child"]) {
      let ctg = tree["child"][ctgCd];
      let item: CtgNameItem = {
        storeCd: storeCd,
        storeName: storeName,
        ctgLevel: ctg["ctgLevel"],
        ctgCd: ctg["ctgCd"],
        ctgName: ctg["ctgName"],
        ctgNameWithCode: ctg["ctgCd"] + "：" + ctg["ctgName"]
      };
      result.push(item);
    }

    return result;
  }

  getBottomCtgList(storeCd: string, ctgCds: string[] | string | CtgTreeItem): string[] {
    
    let result: string[] = [];
    let ctg: CtgTreeItem;
    if (ctgCds["ctgCd"]) {
      // typeof CtgTreeItem
      ctg = ctgCds as CtgTreeItem;
    } else {
      let ctgCd: string = "";
      if (typeof ctgCds == "string") {
        ctgCd = ctgCds;
      } else {
        for (let i = 0; i < (ctgCds as string[]).length && i < this.commonService.config.maxCtgLevel; i++) {
          if (ctgCds[i] == undefined || ctgCds[i] === "") break;
          ctgCd = ctgCds[i];
        }
      }
      ctg = this.ctgFlat[storeCd][ctgCd];
      if (!ctg) return result;
    }

    if (Object.keys(ctg.child).length < 1) {
      result.push(ctg.ctgCd);
      return result;
    }
    for (let key in ctg.child) {
      result = result.concat(this.getBottomCtgList(storeCd, ctg.child[key]));
    }
    return result;
  }

  getCtgName(ctgCd: string): string {
    if (ctgCd === '') return '';
    let ctg: CtgTreeItem = this.ctgFlat["*"][ctgCd];
    if (ctg) return ctg.ctgName;
    return '';
  }

  getCtgPath(storeCd: string, ctgCd: string): string[] {
    let path: string[] = ['', '', '', '',];
    let ctg: CtgTreeItem = this.ctgFlat[storeCd][ctgCd];
    if (!ctg) return path;

    path["" + ctg.ctgLevel] = ctg.ctgCd;
    while (ctg.ctgLevel > 0) {
      ctg = ctg.parent;
      path["" + ctg.ctgLevel] = ctg.ctgCd;
    }

    return path;
  }

  getCtgPathWithName(storeCd: string, ctgCd: string): CtgNameItem[] {
    let path: CtgNameItem[] = [];
    let ctg: CtgTreeItem = this.ctgFlat[storeCd][ctgCd];

    if (!ctg) return [];
    while (ctg.ctgLevel >= 0) {
      let ctgNameItem: CtgNameItem = {
        storeCd:          "",
        storeName:        "",
        ctgLevel:         ctg.ctgLevel,
        ctgCd:            ctg.ctgCd,
        ctgName:          ctg.ctgName,
        ctgNameWithCode:  ctg.ctgCd + "：" + ctg.ctgName
      };
      path.push(ctgNameItem);
      ctg = ctg.parent;
    }
    let ctgRoot: CtgTreeRoot = (ctg as unknown) as CtgTreeRoot;
    path.forEach((item) => {
      item.storeCd = ctgRoot.storeCd;
      item.storeName = ctgRoot.storeName;
    });
    path.sort((a, b) => {
      if (a.ctgLevel < b.ctgLevel) return -1;
      if (a.ctgLevel > b.ctgLevel) return  1;
      return 0;
    });

    return path;
  }
}
