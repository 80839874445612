import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CommonService } from 'src/app/service/common.service';
import { Spmt10271Dto } from 'src/app/response/rsp-spmt10271-search';
import { FormControl } from '@angular/forms';
import { Constant } from 'src/app/page/spmt10271/spmt10271-approval-constant';
import { Subscription } from 'rxjs';
import { ReqSpmt10271Confirm } from 'src/app/request/req-spmt10271-dialog-confirm';
import { Spmt10271GetDetailDto } from 'src/app/response/rsp-spmt10271-get-detail';
import { ReqAccess } from 'src/app/request/req-access';
import { MatPaginator } from '@angular/material/paginator';
import { Rsp } from 'src/app/response/rsp';
import { MessageService } from "src/app/service/message.service";
import * as ConstMessageID from 'src/app/common/const-message-id';

interface IMOAConfirmDialogRejected extends TableColumnDef {
  isInputText: boolean;
}

@Component({
  selector: 'spmt10271-rejected-dialog',
  templateUrl: './spmt10271-rejected-dialog.component.html',
  styleUrls: ['./spmt10271-rejected-dialog.component.css']
})

export class Spmt10271RejectedDialogComponent implements OnInit, AfterViewInit {
  access: ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd: Constant.EMPTY_STRING,
    lang: Constant.EMPTY_STRING,
    storeCd: Constant.EMPTY_STRING,
  };
  public itemTableWidth: any;
  public paginatorMaxWidth: any;
  public itemRecordCount: number = 0;
  public items: Spmt10271Dto[] = [];
  public itemsShow: Spmt10271Dto[] = [];
  public check: FormControl;

    // Message
    confirmSuccessMsg = this.messageEntity.message[ConstMessageID.MI200002];
    confirmDialogRejectedMsg = this.messageEntity.message[ConstMessageID.MQ200007];

  public columnId: string[] = ["slipNo", "inStoreCd", "inStoreNm", "reqStatus", "totalQty", "totalCostAmt", "slipCreationDate"
    , "outRequesterCd", "outApproverCd", "inApproverCd", "commentHeader", "approverComment"]

  public columnDefsItems: IMOAConfirmDialogRejected[] = [
    { columnId: "slipNo", header: "伝票番号", width: 80, align: "left", isInputText: false },
    { columnId: "inStoreCd", header: "入庫店コード", width: 80, align: "left", isInputText: false },
    { columnId: "inStoreNm", header: "入庫店名称", width: 100, align: "left", isInputText: false },
    { columnId: "reqStatus", header: "申請状態", width: 70, align: "left", isInputText: false },
    { columnId: "totalQty", header: "出庫数量合計", width: 77, align: "right", isInputText: false, numberPipe: '1.0-0' },
    { columnId: "totalCostAmt", header: "出庫原価金額合計", width: 95, align: "right", isInputText: false, numberPipe: '1.2-2' },
    { columnId: "slipCreationDate", header: "伝票作成日", width: 70, align: "center", isInputText: false },
    { columnId: "outRequesterCd", header: "出庫申請者", width: 80, align: "left", isInputText: false },
    { columnId: "outApproverCd", header: "出庫承認者", width: 80, align: "left", isInputText: false },
    { columnId: "inApproverCd", header: "入庫承認者", width: 80, align: "left", isInputText: false },
    { columnId: "commentHeader", header: "出庫申請者コメント", width: 150, align: "left", isInputText: false },
    { columnId: "approverComment", header: "出庫承認種コメント", width: 150, align: "left", isInputText: true },
  ];
  private subcriptionInfo: Subscription;
  private subcriptionYesNoDialog: Subscription;
  @ViewChild("matPagenatorItem", { static: false }) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10271RejectedDialogComponent>,
    public httpBasic: HttpBasicService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageEntity: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: { searchResult: Spmt10271Dto[] },
    @Inject(MAT_DIALOG_DATA) public results: { detailResult: Spmt10271GetDetailDto[] },
    public commonService: CommonService) { }

  ngOnInit() {
    this.calcTableWidth();
    this.fnGetAccessInfo();
    this.initTable();
  }

  ngAfterViewInit() {
    this.initTable();
    this.changeDetectorRef.detectChanges();
  }

  fnGetAccessInfo() {
    let req = {
      access: this.access
    };
    this.httpBasic.getUserInfo(req).subscribe((res) => {
      if (this.httpBasic.handleAppError(res)) return;
      if (res.rows.length > 0) {
        this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
        this.access.storeCd = res.rows[0].mainStoreCd;
        this.access.sysModeCd = res.rows[0].sysModeCd;
        this.access.lang = res.rows[0].mainLang;
      }
    });
  }

  calcTableWidth() {
    let width = 1
    for (let colDef of this.columnDefsItems) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = { "width": Constant.EMPTY_STRING + width + "px" };
    this.paginatorMaxWidth = { "max-width": Constant.EMPTY_STRING + width + "px" };
  }

  initTable() {
    this.data.searchResult.forEach((item: Spmt10271Dto) => {
      this.items.push({
        ...item,
        approverComment: new FormControl(item.approverComment),
      })
    })

    this.itemRecordCount = this.data.searchResult.length ?? 0;
    this.pageChanged();
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0) {
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  getRequestData() {
    return this.data.searchResult = this.data.searchResult.map((x) => {
      return {
        ...x,
        approverComment: this.itemsShow.find((item) => item.slipNo == x.slipNo).approverComment.value
      }
    });
  }

  confirmRejectedBtn() {
    this.subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.confirmDialogRejectedMsg).subscribe(data => {
      if (data) this.confirmPrc();
      this.subcriptionYesNoDialog.unsubscribe();
    })
  }

  confirmPrc() {
    this.commonService.openSpinner(this.commonService.pageTitle, Constant.MESSAGESEARCHING);
    const request: ReqSpmt10271Confirm = {
      rows: this.getRequestData(),
      access: this.access
    }
    const subsc = this.httpBasic.rejectedConfirm(request).subscribe(
      rows => {
        this.checkResult(rows);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: Rsp) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      let subscription = this.commonService.openErrorDialogWithAfterCloseDialog(this.commonService.pageTitle, response.normalError[0].errMsg).subscribe(() => {
        this.dialogRef.close(true);
        subscription.unsubscribe();
      });
      return;
    }
    this.subcriptionInfo = this.commonService.openNotificationDialogAfterClose(this.commonService.pageTitle, this.confirmSuccessMsg).subscribe(() => {
      this.dialogRef.close(true);
      this.subcriptionInfo.unsubscribe();
    });
  }
}
