import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ME100010, ME200004, ME200005 } from "src/app/common/const-message-id";
import { NumberKeypadComponent } from "src/app/partsCommon/number-keypad/number-keypad.component";
import { RspSpmt10261SearchDto } from "src/app/response/rsp-spmt10261-search";
import { CommonService } from "src/app/service/common.service";
import { MessageService } from "src/app/service/message.service";

export interface ReqInventToryMoveOutRequestDetail {
  product: RspSpmt10261SearchDto;
  btnTitle: string;
  viewMode: boolean;
}

const MOVE_OUT_APPROVED_STATUS = "1";

@Component({
  selector: "app-spmt10261-detail-dialog",
  templateUrl: "./spmt10261-detail-dialog.component.html",
  styleUrls: ["./spmt10261-detail-dialog.component.css"],
})
export class Spmt10261DetailDialogComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  @ViewChild(NumberKeypadComponent, { static: true }) numberKeypadComponent: NumberKeypadComponent;
  requiredMessage: string;
  outStockMessage: string;
  rangeMessage: string;
  viewMode: boolean = true;
  formGroup: FormGroup;
  category: string;

  get MOVE_OUT_APPROVED_STATUS() {
    return MOVE_OUT_APPROVED_STATUS;
  }
  constructor(
    public dialogRef: MatDialogRef<Spmt10261DetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReqInventToryMoveOutRequestDetail,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    message: MessageService
  ) {
    this.requiredMessage = message.message[ME100010];
    this.outStockMessage = message.message[ME200004]?.replace("%1", "出庫数量").replace("%2", "在庫数");
    this.rangeMessage = message.message[ME200005]?.replace("%1", "出庫数量").replace("%2", "1").replace("%3", "99999");
    this.category = `[${data.product.gMidClassCd2} - ${data.product.gMidClassNm}] - [${data.product.gMidClassCd2} - ${data.product.gSmallClassCd3} - ${data.product.gSmallClassNm}] - [${data.product.gMidClassCd2} - ${data.product.gSmallClassCd3} - ${data.product.gSubClassCd4} - ${data.product.gSubClassNm}]`;
    this.formGroup = this.fb.group(
      {
        ...this.data.product,
        outStockQty: [this.data.product.outStockQty, [Validators.required, Validators.min(1), Validators.max(99999)]],
        slipDtlComment: [this.data.product.slipDtlComment, [Validators.maxLength(256)]],
      },
      { validators: this.requiredMoveQtyLessThanLogicStockQty("outStockQty", "logicStockQty") }
    );
    this.viewMode = this.data.viewMode;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      document.getElementById("outStockQty")?.focus();
    }, 300);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    // do nothing
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  private requiredMoveQtyLessThanLogicStockQty(outStockQtyCtrl: string, logicStockQtyCtrl: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      if (Number(formGroup.get(outStockQtyCtrl).value) <= Number(formGroup.get(logicStockQtyCtrl).value)) {
        return null;
      }
      return {
        lessThanError: true,
      };
    };
  }

  onAdd() {
    if (this.formGroup.invalid) {
      return;
    }
    this.dialogRef.close({
      ...this.formGroup.value,
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  is10KeyPadTarget(id: string) {
    return id === this.keypadTargetId;
  }

  keypadTargetId: string;

  set10KeyPadTarget(id: string, form: AbstractControl) {
    if (this.viewMode || !this.commonService.config.use10KeyPad) return;
    let formControl: FormControl = form as FormControl;
    this.keypadTargetId = id;
    this.numberKeypadComponent.setTargetForm(formControl);
  }
}
