import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import * as MessageID from 'src/app/common/const-message-id';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { MessageService } from 'src/app/service/message.service';
import { ValidateService } from 'src/app/service/validate.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Spiv00051Dto, Spiv00051SearchDto } from 'src/app/request/req-spiv00051';
import { ReqSpiv00111, RspSpiv00111, Spiv00111List, ReqStoreProduct, RspStoreProduct, ReqSpiv00111Confirm, RspSpiv00111Confirm  } from 'src/app/request/req-spiv00111';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';

@Component({
  selector: 'app-spiv00111',
  templateUrl: './spiv00111.component.html',
  styleUrls: ['./spiv00111.component.css']
})
export class Spiv00111Component implements OnInit, OnDestroy {
  // Defined variables
  form: FormGroup;
  cmpnyCd: string = "";
  storeCd: string = "";
  yearMonth: string = "";
  invScheduleId: string = "";
  invEntrySearchData: Spiv00051SearchDto;
  invEntryRowData: Spiv00051Dto[];
  parentPage: string;
  confirmButtonDisabled: boolean = false;
  requiredMsg: string = "";
  isOwnPage: boolean = true;
  isMinusQty: boolean = false;
  isMinusPosPrice: boolean = false;
  isMinusSalePrice: boolean = false;
  updatedtime: string;
  statusWithJittana: boolean = false;
  pageTitle: string = '棚卸入力明細';
  tmpItem: any;

  @Output() fpiv0011Exist = new EventEmitter<void>();
  @ViewChild(NumberKeypadComponent, {static: true}) numberKeypad: NumberKeypadComponent;

  // Table
  inventoryEntryDetailList: Spiv00111List[] = [];
  public columnIds: string[] = ["addRow", "inventoryTypeNm", "inventoryQty", "posPriceDisplay", "posPriceAmtDisplay", "salePriceDisplay", "salePriceAmtDisplay", "createdDatetime", "createdAuthorNm"];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'inventoryTypeNm', header: "棚卸種別", width: 70, align: 'center' },
    { columnId: 'inventoryQty', header: "棚卸数", width: 70, align: 'right', editable: true },
    { columnId: 'posPriceDisplay', header: "POS原価(抜)", width: 90, align: 'right' },
    { columnId: 'posPriceAmtDisplay', header: "POS原価金額(抜)", width: 100, align: 'right' },
    { columnId: 'salePriceDisplay', header: "売価(抜)", width: 90, align: 'right' },
    { columnId: 'salePriceAmtDisplay', header: "売価金額(抜)", width: 100, align: 'right' },
    { columnId: 'createdDatetime', header: "入力日時", width: 130, align: 'center' },
    { columnId: 'createdAuthorNm', header: "入力担当者", width: 110 },
  ]; // total width=800px

  constructor (
    private fb: FormBuilder,
    public commonService: CommonService,
    public validate: ValidateService,
    public messageEntity: MessageService,
    public httpBasic: HttpBasicService,
    private decimalPipe: DecimalPipe,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<Spiv00111Component>
  ) {
    this.form = this.fb.group({
      productCd: [{value: "", disabled: true}],
      productNm: [{value:"", disabled: true}],
      standardNm: [{value:"", disabled: true}],
      category: [{value:"", disabled: true}],
      quantity: [{value:"", disabled: true}],
      instock: [{value:"", disabled: true}],
      posPrice: [{value:"", disabled: true}],
      posPriceAmt: [{value:"", disabled: true}],
      salePrice: [{value:"", disabled: true}],
      salePriceAmt: [{value:"", disabled: true}]
    });
  }

  ngOnInit(): void {
    this.setInitPage();
  }
  ngOnDestroy(): void {}

  setInitPage() {
    // Check parent screen
    if (this.commonService.fpiv0011ParentPage !== undefined) {
      this.isOwnPage = false;
      this.invEntryRowData = [];
      this.parentPage = this.commonService.fpiv0011ParentPage;
      this.commonService.fpiv0011ParentPage = undefined; // Clear data after receiving
      this.invEntrySearchData = this.commonService.fpiv0005SearchData;
      this.commonService.fpiv0005SearchData = undefined;

      this.storeCd = this.invEntrySearchData.storeCd ?? "";
      this.yearMonth = this.invEntrySearchData.invYearMonth ?? "";
      this.invScheduleId = this.invEntrySearchData.invScheduleId ?? "";
      this.cmpnyCd = this.invEntrySearchData.cmpnyCd ?? "";

      // Filter the parent page
      if (this.parentPage === 'fpiv0005') {
        this.invEntryRowData.push(this.commonService.fpiv0005RowData[0]);
        this.commonService.fpiv0005RowData = []; // Clear data after receiving
      } else if (this.parentPage === 'fpiv0010') {
        this.invEntryRowData.push(this.commonService.fpiv0010RowData[0]);
        this.commonService.fpiv0010RowData = []; // Clear data after receiving
      }

      // Load init data
      if (this.invEntryRowData.length > 0) {
        this.form.get('productCd').setValue(this.invEntryRowData[0].productCd);
        this.form.get('productNm').setValue(this.invEntryRowData[0].productNm);
        this.form.get('standardNm').setValue(this.invEntryRowData[0].standardNm);
        this.form.get('category').setValue(this.prepareCategory(this.invEntryRowData[0]));
        this.form.get('quantity').setValue(this.invEntryRowData[0].inventoryQty);
        this.form.get('instock').setValue(this.invEntryRowData[0].stockQty);
        this.form.get('posPrice').setValue(this.invEntryRowData[0].posPrice);
        this.form.get('posPriceAmt').setValue(this.invEntryRowData[0].posPriceAmt);
        this.form.get('salePrice').setValue(this.invEntryRowData[0].salePrice);
        this.form.get('salePriceAmt').setValue(this.invEntryRowData[0].salePriceAmt);
        this.updatedtime = this.invEntryRowData[0].updatedtime;
        // Get detail if there is inventory input id
        if (this.invEntryRowData[0].invInputId !== null) {
          this.getInvEntryDetailList(true);
        }
      }
      
      // Check inventroy status
      if (this.ctrlAddBtn()) this.confirmButtonDisabled = true;
    }
  }

  prepareCategory(invEntryRowData: any) {
    const gmainClass = "["+ invEntryRowData.gmainClassCd +":"+ invEntryRowData.gmainClassNm +"]";
    const gmidClass = "["+ invEntryRowData.gmainClassCd +":"+ invEntryRowData.gmidClassCd +":"+ invEntryRowData.gmidClassNm +"]";
    const gsmallClass = "["+ invEntryRowData.gmainClassCd +":"+ invEntryRowData.gmidClassCd +":"+ invEntryRowData.gsmallClassCd +":"+ invEntryRowData.gsmallClassNm +"]";
    const gsubClass = "["+ invEntryRowData.gmainClassCd +":"+ invEntryRowData.gmidClassCd +":"+ invEntryRowData.gsmallClassCd +":"+ invEntryRowData.gsubClassCd +":"+ invEntryRowData.gsubClassNm +"]";
    return gmainClass +"-"+ gmidClass +"-"+ gsmallClass +"-"+ gsubClass;
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }
  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }
  styleForRow(colDef: TableColumnDef) {
    let initStyle = {
      "width": "" + colDef.width + "px",
      "min-width": "" + colDef.width + "px",
      "max-width": "" + (colDef.maxWidth != undefined ? colDef.maxWidth : colDef.width) + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
    return initStyle;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0'); // Format with thousand separators and no decimal places
  }
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2'); // Format with thousand separators and two decimal places
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  is10KeyPadTarget(form: FormControl) {
    if (!this.commonService.config.use10KeyPad) return false;
    if (form === this.numberKeypad.inputField) return true;
    return false;
  }

  set10KeyPadTarget(form: FormControl, event: any, item: Spiv00111List) {
    this.tmpItem = item; // Keep item to handle for value changes from keypad
    if (!this.commonService.config.use10KeyPad) return;
    this.numberKeypad.setTargetForm(form);
    this.numberKeypad.setMaxCol(6);
    this.numberKeypad.clearAtFirstInput(false);
    this.changeInvQty(event.target.value, item);
  }

  isKeyboardLocked() {
    if (!this.commonService.config.use10KeyPad) return false;
    if (this.numberKeypad.isKeyboardLocked()) return true;
    return false;
  }

  // Handle value changes from number keypad
  onValueChange(newValue: number) {
    this.changeInvQty(newValue, this.tmpItem);
  }

  // Get inventory entry detail list
  getInvEntryDetailList(init: boolean) {
    let request: ReqSpiv00111 = {
      access: this.commonService.loginUser,
      ivInputId: this.invEntryRowData[0].invInputId
    }
    this.commonService.openSpinner(this.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("Spiv00111Search", request).subscribe(
      (response: RspSpiv00111) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveInventoryEntryDetailList(response, init);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveInventoryEntryDetailList(response: RspSpiv00111, init: boolean) {
    if (this.httpBasic.handleAppError(response)) return;
    // Check result data is exists or not
    if (response.rows.length > 0) {
      this.inventoryEntryDetailList = [];
      // Set response data to table list
      const newRows = response.rows.map(row => new Spiv00111List(row, this.decimalPipe));
      this.inventoryEntryDetailList = [...this.inventoryEntryDetailList, ...newRows];

      // Recalculate total price
      this.calculateTotalPrice();

      // Check for "実棚" in the list
      this.checkForJitsudana(true);
    } else {
      if (init) this.commonService.openNotificationDialog(this.pageTitle, "該当データが存在しません。");
    }
  }

  // Calculate total price of inventory entry detail list
  calculateTotalPrice() {
    let qty = this.inventoryEntryDetailList.reduce((sum, item) => sum + Number(item.inventoryQty), 0);
    let totPosAmt = this.inventoryEntryDetailList.reduce((sum, item) => sum + parseFloat(item.posPriceAmt), 0)
    let totSaleAmt = this.inventoryEntryDetailList.reduce((sum, item) => sum + parseFloat(item.salePriceAmt), 0);
    // Check valuse is minus value or not: if minus value, display with red color
    this.isMinusQty = qty < 0;
    this.isMinusPosPrice = totPosAmt < 0;
    this.isMinusSalePrice = totSaleAmt < 0;
    // Set update values
    this.form.get('quantity').setValue(this.formatNumber(qty));
    this.form.get('posPriceAmt').setValue(this.formatDecimal(totPosAmt));
    this.form.get('salePriceAmt').setValue(this.formatDecimal(totSaleAmt));
  }

  // Control confirm button to enable or disable
  checkForJitsudana(isSearchProcess: boolean) {
    const exists = this.inventoryEntryDetailList.some(item => item.inventoryTypeNm === '実棚');
    if (isSearchProcess && exists) {
      this.statusWithJittana = true; // for control existing data of 実棚
    } else if (isSearchProcess && !exists) {
      this.statusWithJittana = false;
    }
    if (!this.statusWithJittana && !exists) {
      this.confirmButtonDisabled = true;
    } else {
      this.confirmButtonDisabled = false;
    }
    // Check inventory status
    if (this.ctrlAddBtn()) this.confirmButtonDisabled = true;
  }
  
  // Add a new row
  addRow() {
    let request: ReqStoreProduct = {
      access: this.commonService.loginUser,
      cmpnyCd: this.cmpnyCd,
      storeCd: this.storeCd,
      productCd: this.form.get('productCd').value
    }
    this.commonService.openSpinner(this.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("GetStoreProductPrice", request).subscribe(
      (response: RspStoreProduct) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveStoreProductPrice(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveStoreProductPrice(response: RspStoreProduct) {
    if (this.httpBasic.handleAppError(response)) return;
    // Check result data is exists or not
    if (response.rows.length > 0) {
      if (!this.inventoryEntryDetailList) this.inventoryEntryDetailList = [];

      const newRows = response.rows.map(row => new Spiv00111List(row, this.decimalPipe));
      this.inventoryEntryDetailList = [...newRows, ...this.inventoryEntryDetailList];

      // Recalculate total price
      this.calculateTotalPrice();

      // Recheck for "実棚" after removing a row
      this.checkForJitsudana(false);
    } else {
      this.commonService.openErrorDialog(this.pageTitle, "該当データが存在しません。");
    }
  }

  changeInvQty(invQty: any, item: Spiv00111List) {
    item.required = (invQty == "" || invQty == undefined) ? true : false; // to handle required

    const targetVal = Number.isNaN(parseInt(invQty)) ? 0 : parseInt(invQty);
    item.invEntryDetailForm.setValue(targetVal);
    // Recalculate when value is changes
    if (invQty != item.inventoryQty) {
      item.inventoryQty = targetVal;
      item.posPriceAmt = targetVal * parseFloat(item.posPrice);
      item.salePriceAmt = targetVal * parseFloat(item.salePrice);
      item.posPriceAmtDisplay = this.formatDecimal(item.posPriceAmt);
      item.salePriceAmtDisplay = this.formatDecimal(item.salePriceAmt);

      // Recalculate total price
      this.calculateTotalPrice();
    }
  }

  removeRow(index: number) {
    if (index >= 0 && index < this.inventoryEntryDetailList.length) {
      this.inventoryEntryDetailList.splice(index, 1);
      this.inventoryEntryDetailList = [...this.inventoryEntryDetailList];
      this.cdr.detectChanges(); // Trigger change detection manually

      // Recalculate total price
      this.calculateTotalPrice();

      // Recheck for "実棚" after removing a row
      this.checkForJitsudana(false);
    } else console.error('Invalid index or row not found.');
  }

  onClickCell(columnId: string, item: Spiv00111List) {
    if (columnId === "inventoryQty" && item.editable && !this.ctrlAddBtn()) {
      item.inputable = true;
    }
  }

  // If negative value, change text color to red
  isNegativeVal(value): boolean {
    if (parseInt(value) < 0) return true;
    return false;
  }

  doConfirm() {
    let requiredFlg: boolean = false;
    this.requiredMsg = "";
    let updateRows = [];
    let insertRows = [];
    let insertHeaderData = [];
    let inventoryInputId = this.invEntryRowData[0].invInputId;
    for (let i = 0; i < this.inventoryEntryDetailList.length; i++) {
      const item = this.inventoryEntryDetailList[i];
      if (item.inventoryQty == null || item.inventoryQty === undefined || Number.isNaN(item.inventoryQty)) {
        requiredFlg = true;
        break;
      } else {
        if (item.invInputDetailId !== "" && item.invInputDetailId !== undefined && item.invInputDetailId != null) {
          let updDto = {
            invInputDetailId: item.invInputDetailId,
            inventoryQty: item.inventoryQty,
          }
          updateRows.push(updDto);
        } else {
          let insDto = {
            inventoryQty: item.inventoryQty,
            posPirce: item.posPrice,
            salePrice: item.salePrice,
            inventoryType: item.inventoryType
          }
          insertRows.push(insDto);
        }
      }
    }
    // Check require input 
    if (requiredFlg) {
      this.requiredMsg = "棚卸数に未入力のデータがあるため確定できません。";
      return;
    } else {
      // Check inventory count is greater than 999999 or less than -999999
      let invQty = this.validate.convertStringToNumber(this.form.get('quantity').value);
      if (invQty > 999999 || invQty < -999999) {
        this.commonService.openErrorDialog('棚卸入力明細', this.messageEntity.message[MessageID.ME100015].replace('%1', '-999999').replace('%2', '999999'));
      } else {
        // If inventory input id is null, needs to insert header table
        if (inventoryInputId == null) {
          insertHeaderData.push(this.setInsertHeaderData());
        }
        let subsc = this.commonService.openYesNoDialog(this.pageTitle, "内容を保存します。よろしいですか？").subscribe(
          (response: boolean) => {
            subsc.unsubscribe();
            if (response) {
              this.doConfirmBody(inventoryInputId, updateRows, insertRows, insertHeaderData);
            }
          }
        );
      }
    }
  }

  doConfirmBody(inventoryInputId: any, updateRows: any, insertRows: any, insertHeaderData: any) {
    let masterData = [];
    masterData.push(this.setInsertHeaderData()); // Set master data to check for existence
    let request: ReqSpiv00111Confirm = {
      access: this.commonService.loginUser,
      cmpnyCd: this.cmpnyCd,
      invInputId: inventoryInputId,
      updatedtime: this.updatedtime,
      updateItems: updateRows,
      insertItems: insertRows,
      insertHeaderItems: insertHeaderData,
      masterItems: masterData
    }
    this.commonService.openSpinner(this.pageTitle, "検索中・・・");
    let subsc = this.httpBasic.generalRequest("Spiv00111Confirm", request).subscribe(
      (response: RspSpiv00111Confirm) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.receiveConfirm(response);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveConfirm(response: RspSpiv00111Confirm) {
    if (this.httpBasic.handleAppError(response)) { return; }
    this.commonService.openNotificationDialog(this.pageTitle, '登録しました。');
    // Set updated info of header table
    this.invEntryRowData[0].invInputId = response.invInputId;
    this.updatedtime = response.updatedtime;
    this.getInvEntryDetailList(false); // Re-call search list
  }

  setInsertHeaderData() {
    return {
      ivscheduleId: this.invScheduleId,
      inventoryMonth: this.yearMonth,
      storeCd: this.storeCd,
      partnerCd: this.invEntryRowData[0].partnerCd,
      gmainClassCd: this.invEntryRowData[0].gmainClassCd,
      gmidClassCd: this.invEntryRowData[0].gmidClassCd,
      gsmallClassCd: this.invEntryRowData[0].gsmallClassCd,
      gsubClassCd: this.invEntryRowData[0].gsubClassCd,
      productCd: this.invEntryRowData[0].productCd,
      inventoryCnt: this.validate.convertStringToNumber(this.form.get('quantity').value),
      costPrice: this.inventoryEntryDetailList[0].posPrice,
      sellPrice: this.inventoryEntryDetailList[0].salePrice
    }
  }

  doExit() {
    this.dialogRef.close();
    this.fpiv0011Exist.emit();
  }

  ctrlAddBtn() {
    return (this.invEntrySearchData.invStatus === '店舗確定済' || this.invEntrySearchData.invStatus === '本部確定済') ? true : false;
  }

}
