import { Component, OnInit, Inject, ViewChild, HostListener, AfterViewChecked, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpiryDashboardDto } from 'src/app/webservice/item-expiry';
import { CommonService } from 'src/app/service/common.service';
import { ItemExpiryEditComponent } from '../item-expiry-edit/item-expiry-edit.component';

@Component({
  selector: 'app-item-expiry-edit-dialog',
  templateUrl: './item-expiry-edit-dialog.component.html',
  styleUrls: ['./item-expiry-edit-dialog.component.css']
})
export class ItemExpiryEditDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(ItemExpiryEditComponent, {static: true}) itemExpiryEditComponent: ItemExpiryEditComponent;

  constructor(
    public dialogRef: MatDialogRef<ItemExpiryEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExpiryDashboardDto,
    public commonService: CommonService) {
    }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setHeightBody();
      this.itemExpiryEditComponent?.setHeight();
    }, 500);
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.setHeight();
  }

  setHeight() {
    setTimeout(() => {this.setHeightBody();}, 0);
  }

  setHeightBody() {
    let id = "app-item-expiry-edit";
    let remHeight = this.commonService.getHeightBelow(id);
    let margin = 10;
    let btnBox = 24;
    let height = remHeight - margin - btnBox;
    if (height < 200) height = 200;

    let elem = document.getElementById(id);
    if (elem) elem.style.height = "" + height + "px";
  }

  isDirty() {
    return this.itemExpiryEditComponent?.isDirty();
  }

  closeDialog(dashBoardRequery?: boolean) {
    if (dashBoardRequery){
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close();
    }
  }
}
