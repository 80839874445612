import { ReportRecDto } from '../response/rsp-report';
import { ReportColumnDefDto } from '../response/rsp-report-def';

export class ReportRec {

    itemcdKey: string;

    constructor(recDto: ReportRecDto, repColDef: ReportColumnDefDto[]) {
        let i = 0;
        for (let col of repColDef) {
            this[col.selectCol] = recDto.colData[i];
            if (col.itemcd) {
                this.itemcdKey = col.selectCol;
            }
            i++;
        }
    }

    getItemCd() {
        if (this.itemcdKey) {
            return this[this.itemcdKey];
        }
        return undefined;
    }
}
