import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  constructor() { }

  // Required field validator
  static required(control: AbstractControl): ValidationErrors | null {
    return control.value ? null : { required: true };
  }

  // Email validator
  static email(control: AbstractControl): ValidationErrors | null {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    return emailRegex.test(control.value) ? null : { email: true };
  }

  // Minimum length validator
  static minLength(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Check if value is exists
      if (control.value !== null && control.value !== "" && control.value !== undefined) {
        return control.value && control.value.length >= min ? null : { minLength: { requiredLength: min } };
      }
    };
  }

  // Maximum length validator
  static maxLength(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Check if value is exists
      if (control.value !== null && control.value !== "" && control.value !== undefined) {
        return control.value && control.value.length <= max ? null : { maxLength: { requiredLength: max } };
      }
    };
  }

  // Pattern validator
  static pattern(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return regex.test(control.value) ? null : { pattern: true };
    };
  }

  // Custom validator example: must match another control
  static mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent;
      if (!formGroup) {
        return null;
      }
      const controlToMatch = formGroup.get(matchingControlName);
      if (!controlToMatch) {
        return null;
      }
      return control.value === controlToMatch.value ? null : { mustMatch: true };
    };
  }

  // Maximum value validator
  static maxValue(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value <= max ? null : { maxValue: true };
    };
  }

  // Minimum value validator
  static minValue(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value >= min ? null : { minValue: true };
    };
  }

  // Start vaule and end value validator
  static startLessThanEnd(startControlName: string, endControlName: string, errorKey: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const startControl = formGroup.get(startControlName);
      const endControl = formGroup.get(endControlName);

      if (startControl && endControl && parseInt(startControl.value) > parseInt(endControl.value)) {
        formGroup.setErrors({ ...formGroup.errors, [errorKey]: true });
        return { [errorKey]: true };
      } else {
        if (formGroup.errors) {
          delete formGroup.errors[errorKey];
          if (!Object.keys(formGroup.errors).length) {
            formGroup.setErrors(null);
          } else {
            formGroup.setErrors({ ...formGroup.errors });
          }
        }
        return null;
      }
    };
  }

  // Convert string to number(eg. "2,300" => 2300)
  convertStringToNumber(input: string): number | null {
    // Check if input is a valid string
    if (!input || typeof input !== 'string') {
      return null;
    }
  
    // Remove commas from the string
    const cleanedString = input.replace(/,/g, '');
    // Convert the cleaned string to a number
    const numberValue = parseInt(cleanedString, 10);
  
    // Check if the result is a valid number
    if (isNaN(numberValue)) {
      return null;
    }
  
    return numberValue;
  }

  // Convert string to decimal number(eg. "2,300.23" => 2300.23)
  convertStringToDecimal(input: string): number | null {
    // Check if input is a valid string
    if (!input || typeof input !== 'string') {
      return null;
    }

    // Remove commas from the string
    const cleanedString = input.replace(/,/g, '');
    // Convert the cleaned string to a decimal number
    const numberValue = parseFloat(cleanedString);

    // Check if the result is a valid number
    if (isNaN(numberValue)) {
      return null;
    }

    return numberValue;
  }

  
}
