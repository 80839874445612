import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './guard/login.guard';
import { TopPageComponent } from './page/top-page/top-page.component';
import { LoginComponent } from './page/login/login.component';
import { OrderComponent } from './page/order/order.component';
import { StockComponent } from './page/stock/stock.component';
import { CtgParamEditComponent } from './page/ctg-param-edit/ctg-param-edit.component';
import { ItemParamEditComponent } from './page/item-param-edit/item-param-edit.component';
import { ItemOrderStopEditComponent } from './page/item-order-stop-edit/item-order-stop-edit.component';
import { OpestatPageComponent } from './page/opestat/opestat-page/opestat-page.component';
import { ItemHistComponent } from './page/item-hist/item-hist.component';
import { ReportComponent } from './page/report/report.component';
import { RoleComponent } from './page/mng/role/role.component';
import { CanDeactivateGuard } from './module/can-deactivate.guard.module';
import { NoisecutComponent } from './page/noisecut/noisecut.component';
import { PasswdComponent } from './page/mng/passwd/passwd.component';
import { UserComponent } from './page/mng/user/user.component';
import { DownldComponent } from './page/downld/downld.component';
import { ItemComponent } from './page/item/item.component';
import { RackComponent } from './page/rack/rack.component';
import { ScanItemEditComponent } from './page/scan-item-edit/scan-item-edit.component';
import { CtgHistComponent } from './page/ctg-hist/ctg-hist.component';
import { OrderStopTermCtgComponent } from './page/order-stop-term-ctg/order-stop-term-ctg.component';
import { AllStoreParamItemComponent } from './page/all-store-param-item/all-store-param-item.component';
import { AllStoreParamCtgComponent } from './page/all-store-param-ctg/all-store-param-ctg.component';
import { OrderStopTermSuppComponent } from './page/order-stop-term-supp/order-stop-term-supp.component';
import { OrderStopTermStoreComponent } from './page/order-stop-term-store/order-stop-term-store.component';
import { OrderStopTermItemComponent } from './page/order-stop-term-item/order-stop-term-item.component';
import { ItemReplaceComponent } from './page/item-replace/item-replace.component';
import { ForecastUpDownComponent } from './page/forecast-up-down/forecast-up-down.component';
import { StoreGroupMngComponent } from './page/store-group-mng/store-group-mng.component';
import { CsvParamItemComponent } from './page/csv-param-item/csv-param-item.component';
import { CsvSeasonalCategoryTargetComponent } from './page/csv-seasonal-category-target/csv-seasonal-category-target.component';
import { OperationLogComponent } from './page/operation-log/operation-log.component';
import { CameraComponent } from './page/camera/camera/camera.component';
import { PbiAnalysisComponent } from './page/power-bi/pbi-analysis/pbi-analysis.component';
import { PbiReportListComponent } from './page/power-bi/pbi-report-list/pbi-report-list.component';
import { CameraLayoutComponent } from './page/camera/camera-layout/camera-layout.component';
import { CsvNoisecutPromComponent } from './page/csv-noisecut-prom/csv-noisecut-prom.component';
import { CsvVisitorsComponent } from './page/csv-visitors/csv-visitors.component';
import { CsvInventoryComponent } from './page/csv-inventory/csv-inventory.component';
import { CsvOrderComponent } from './page/csv-order/csv-order.component';
import { MenuItemComponent } from './page/mng/menu-item/menu-item.component';
import { MenuEditComponent } from './page/mng/menu-edit/menu-edit.component';
import { PbiMenuComponent } from './page/power-bi/pbi-menu/pbi-menu.component';
import { PromOrderComponent } from './page/prom-order/prom-order/prom-order.component';
import { ForecastUpDownNewComponent } from './page/forecast-up-down-new/forecast-up-down-new.component';
import { CsvUpDownComponent } from './page/csv-up-down/csv-up-down.component';
import { NoisecutNewComponent } from './page/noisecut-new/main/noisecut-new.component';
import { HqMainComponent } from './page/task/2_hq-main/hq-main.component';
import { StoreMainComponent } from './page/task/5_store-main/store-main.component';
import { ItemReplaceNewComponent } from './page/item-replace-new/item-replace-new.component';
import { CsvParamStoreItemComponent } from './page/csv-param-store-item/csv-param-store-item.component';
import { PromSimComponent } from './page/prom-sim/prom-sim/prom-sim.component';
import { OrderByUserComponent } from './page/order-by-user/order-by-user.component';
import { LossRateComponent } from './page/loss-rate/loss-rate.component';
import { OrderGroupUserSingleComponent } from './page/order-group-user-single/order-group-user-single.component';
import { OrderStockComponent } from './page/order-stock/order-stock.component';
import { ScanItemEdit2Component } from './page/scan-item-edit2/scan-item-edit2.component';
import { OrderApprovalComponent } from './page/order-approval/order-approval/order-approval.component';
import { OrderGroupUserMultiComponent } from './page/order-group-user-multi/order-group-user-multi.component';
import { OrderGroupUserMulti2Component } from './page/order-group-user-multi2/order-group-user-multi2.component';
import { Role2Component } from './page/mng/role2/role2.component';
import { NotificationEditComponent } from './page/notification/notification-edit/notification-edit.component';
import { TaskWholeStatusMainComponent } from './page/task/whole-status/task-whole-status-main/task-whole-status-main.component';
import { ExpiryLogComponent } from './page/expiry-log/expiry-log.component';
import { CsvImportDeadlineMasterComponent } from './page/csv-import-deadline-master/csv-import-deadline-master.component';
import { ItemExpiryMasterComponent } from './page/item-expiry/item-expiry-master/item-expiry-master.component';
import { PrinterLayoutComponent } from './page/printer/printer-layout/printer-layout.component';
import { ItemExpiryItemMasterComponent } from './page/item-expiry/item-expiry-item-master/item-expiry-item-master.component';

import { HtmlSelectItemEditComponent } from './page/mng/html-select-item-edit/html-select-item-edit.component';
import { ImageDbEditComponent } from './page/image-db/image-db-edit/image-db-edit.component';
import { ImageDbViewComponent } from './page/image-db/image-db-view/image-db-view.component';
import { WeeklyOrderComponent } from './page/eob/weekly_order/weekly_order.component';
import { OrderListComponent } from './page/eob/order-list/order-list.component';
import { UrgentOrderListComponent } from './page/eob/urgent-order-list/urgent-order-list.component';
import { PreOrderListComponent } from './page/eob/pre-order-list/pre-order-list.component';
import { GuideComponent } from './page/eob/guide/guide.component';
import { ItemExpiryComponent } from './page/item-expiry/item-expiry/item-expiry.component';
import { ItemExpiryInitialMainComponent } from './page/item-expiry/item-expiry-initial-main/item-expiry-initial-main.component';
import { Spmt10301Component } from './page/spmt10301/spmt10301.component';
import { Spvw10241Component } from './page/spvw10241/spvw10241.component';
import { Spmt10331Component } from './page/spmt10331/spmt10331.component';
import { Spmt10311Component } from './page/spmt10311/spmt10311.component';
import { Spiv00061Component } from './page/spiv00061/spiv00061.component';
import { Spiv00101Component } from './page/spiv00101/spiv00101.component';
import { Spiv00081Component } from './page/spiv00081/spiv00081.component';
import { SPIV00091Component } from './page/spiv00091/spiv00091.component';
import { Spbs00031Component } from './page/spbs00031/spbs00031.component';
import { Spiv00051Component } from './page/spiv00051/spiv00051.component';
import { Spiv00111Component } from './page/spiv00111/spiv00111.component';
import { Spbs00041Component } from './page/spbs00041/spbs00041.components';
import { Spmt10271Component } from './page/spmt10271/spmt10271.component';
import { Spmt10341Component } from './page/spmt10341/spmt10341.component';
import { Spmt10261Component } from './page/spmt10261/spmt10261.component';
import { Spmt10291Component } from './page/spmt10291/spmt10291.component';
import { Spmt10321Component } from './page/spmt10321/spmt10321.component';
import { Spor00051Component } from './page/spor00051/spor00051.component';
import { Spor00061Component } from './page/spor00061/spor00061.component';
import { Spvw10231Component } from './page/spvw10231/spvw10231.component';
import { Spvw10251Component } from './page/spvw10251/spvw10251.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'top', component: TopPageComponent, canActivate: [LoginGuard] },
  { path: 'order', component: OrderComponent, canActivate: [LoginGuard] },
  { path: 'orderByUser', component: OrderByUserComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'orderStockByUser', component: OrderByUserComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'stockByUser', component: OrderByUserComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },

  { path: 'order2', component: OrderStockComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'orderStock', component: OrderStockComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'stock2', component: OrderStockComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'spmt10291', component: Spmt10291Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'stock', component: StockComponent, canActivate: [LoginGuard] },
  { path: 'scanitem', component: ScanItemEditComponent, canActivate: [LoginGuard] },
  { path: 'scanitem2', component: ScanItemEdit2Component, canActivate: [LoginGuard] },
  { path: 'spvw10241', component: Spvw10241Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'spvw10231', component: Spvw10231Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'spvw10251', component: Spvw10251Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'spmt10311', component: Spmt10311Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'spmt10361', component: Spmt10311Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },

  { path: 'ctgparam', component: CtgParamEditComponent, canActivate: [LoginGuard] },
  { path: 'itemparam', component: ItemParamEditComponent, canActivate: [LoginGuard] },
  { path: 'autotrgt', component: ItemOrderStopEditComponent, canActivate: [LoginGuard] },
  { path: 'noisecut', component: NoisecutComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'noisecutNew', component: NoisecutNewComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'csvNoisecutProm', component: CsvNoisecutPromComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'rack', component: RackComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'orderstoptermctg', component: OrderStopTermCtgComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'orderstoptermsupp', component: OrderStopTermSuppComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'orderstoptermstore', component: OrderStopTermStoreComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'orderstoptermitem', component: OrderStopTermItemComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'itemreplace', component: ItemReplaceComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'itemreplaceNew', component: ItemReplaceNewComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'item', component: ItemComponent, canActivate: [LoginGuard] },
  { path: 'ctghist', component: CtgHistComponent, canActivate: [LoginGuard] },
  { path: 'itemhist', component: ItemHistComponent, canActivate: [LoginGuard] },
  { path: 'opestat', component: OpestatPageComponent, canActivate: [LoginGuard] },
  { path: 'report', component: ReportComponent, canActivate: [LoginGuard] },
  // { path: 'externallink/:linkid', component: ExternalLinkComponent, canActivate: [LoginGuard] },
  { path: 'camera', component: CameraComponent, canActivate: [LoginGuard] },
  { path: 'cameraLayout', component: CameraLayoutComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'downld', component: DownldComponent, canActivate: [LoginGuard] },
  { path: 'role', component: RoleComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'role2', component: Role2Component, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'user', component: UserComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'passwd', component: PasswdComponent, canActivate: [LoginGuard] },
  { path: 'allstoreparamitem', component: AllStoreParamItemComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'allstoreparamctg', component: AllStoreParamCtgComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'forecastupdownOld', component: ForecastUpDownComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'forecastupdown', component: ForecastUpDownNewComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'storegroupmng', component: StoreGroupMngComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'csvparamitem', component: CsvParamItemComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'csvParamStoreItem', component: CsvParamStoreItemComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'csvseasonalcategorytarget', component: CsvSeasonalCategoryTargetComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'csvVisitors', component: CsvVisitorsComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'csvInventory', component: CsvInventoryComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'csvOrder', component: CsvOrderComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'csvUpDown', component: CsvUpDownComponent, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  // { path: 'vendorshortage', component: VendorShortageComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  // { path: 'analysis', component: AnalysisMainComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  // { path: 'pbiprom', component: PbiAnalysisComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  // { path: 'pbi/:repName', component: PbiAnalysisComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'pbi', component: PbiAnalysisComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'pbireports', component: PbiReportListComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'pbiMenu', component: PbiMenuComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'opelog', component: OperationLogComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'menuItem', component: MenuItemComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'menuItemMobile', component: MenuItemComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'menuEdit', component: MenuEditComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'menuEditMobile', component: MenuEditComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'promOrder', component: PromOrderComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'promSim', component: PromSimComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'taskHq', component: HqMainComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'taskStore', component: StoreMainComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'taskWholeStatus', component: TaskWholeStatusMainComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'lossRate', component: LossRateComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'orderGroupUserSingle', component: OrderGroupUserSingleComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'orderGroupUserMulti', component: OrderGroupUserMultiComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'orderGroupUserMulti2', component: OrderGroupUserMulti2Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'itemExpiryMaster', component: ItemExpiryMasterComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'itemExpiryMaster2', component: ItemExpiryMasterComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'itemExpiryItemMaster', component: ItemExpiryItemMasterComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'itemExpiry', component: ItemExpiryComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'itemExpiry2', component: ItemExpiryComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'ItemExpiryInitial', component: ItemExpiryInitialMainComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'orderAppoval', component: OrderApprovalComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'orderApprovalStore', component: OrderApprovalComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'notificationEdit', component: NotificationEditComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'notificationEditBrc', component: NotificationEditComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'expiryLog', component: ExpiryLogComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'csvImportDeadlineMaster', component: CsvImportDeadlineMasterComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'printerLayout', component: PrinterLayoutComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
	{ path: 'imageDbEdit', component: ImageDbEditComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'imageDbView', component: ImageDbViewComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'taskCategoryEdit', component: HtmlSelectItemEditComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'taskAuthorEdit', component: HtmlSelectItemEditComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'weeklyOrder', component: WeeklyOrderComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'orderList', component: OrderListComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'preOrderList', component: PreOrderListComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'urgentOrderList', component: UrgentOrderListComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'guide', component: GuideComponent, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'spmt10301', component: Spmt10301Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'spmt10331', component: Spmt10331Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPIV00061', component: Spiv00061Component , canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPIV00101', component: Spiv00101Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPIV00081', component: Spiv00081Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'SPIV00091', component: SPIV00091Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPBS00031', component: Spbs00031Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPIV00051', component: Spiv00051Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPIV00111', component: Spiv00111Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPBS00041', component: Spbs00041Component, canActivate: [LoginGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'spmt10271', component: Spmt10271Component,canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'spmt10341', component: Spmt10341Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'spmt10261', component: Spmt10261Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard]},
  { path: 'spmt10321', component: Spmt10321Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPOR00051', component: Spor00051Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: 'SPOR00061', component: Spor00061Component, canActivate: [LoginGuard], canDeactivate:[CanDeactivateGuard] },
  { path: '', redirectTo: '/top', pathMatch: 'full' },
  { path: '**', redirectTo: '/top' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
