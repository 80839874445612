import * as ExcelJS from 'exceljs';
import { CommonService } from 'src/app/service/common.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ListData } from '../whole-status/task-whole-status-progress/task-whole-status-progress.component';
import { TableColumnDef } from 'src/app/common/table-column-def';

export class WholeStatusExcel {

  private rowHeight: number = 19;

  private listData: ListData[];
  private columnDefsStoreGroupHeader: TableColumnDef[];
  private columnDefsStoreCdHeader: TableColumnDef[];
  private columnDefsStoreNameHeader: TableColumnDef[];
  private workbook: ExcelJS.Workbook;
  private worksheet: ExcelJS.Worksheet;
  private spinnerRef : MatDialogRef<any, any>;

  constructor(
    private commonService: CommonService
  ) {
  }

  async createAndDownload(
    listData: ListData[],
    columnDefsStoreGroupHeader: TableColumnDef[],
    columnDefsStoreCdHeader: TableColumnDef[],
    columnDefsStoreNameHeader: TableColumnDef[]
  ) {
    this.spinnerRef = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "EXCEL作成中・・・");

    this.listData = listData;
    this.columnDefsStoreGroupHeader = columnDefsStoreGroupHeader;
    this.columnDefsStoreCdHeader = columnDefsStoreCdHeader;
    this.columnDefsStoreNameHeader = columnDefsStoreNameHeader;

    this.workbook = new ExcelJS.Workbook();
    this.worksheet = this.workbook.addWorksheet("全店ステータス");

    let cols = [
      {width: 15, style: { font: { name: "メイリオ", size: 10 } }},                 // 期間
      {width: 35, style: { font: { name: "メイリオ", size: 10 } }},                 // タスク名
      {width: 15, style: { font: { name: "メイリオ", size: 10 } }},                 // 指示者
      {width: 10, style: { font: { name: "メイリオ", size: 10 } }},                 // 優先度
      {width: 10, style: { font: { name: "メイリオ", size: 10 }, numFmt: '@' }},    // 進捗
      {width: 10, style: { font: { name: "メイリオ", size: 10 } }},                 // 時間
      {width: 10, style: { font: { name: "メイリオ", size: 10 } }}                  //
    ];
    for (let i = 0; i < columnDefsStoreNameHeader.length; i++) {
      cols.push(
        {width: 15, style: { font: { name: "メイリオ", size: 10 } }}     // 店舗ステータス
      );
    }

    this.worksheet.columns = cols;
    this.worksheet.views = [{showRuler: false}];

    this.titleLine();
    let startLine = 4;
    for (let i = 0; i < listData.length; i += 3) {
      this.detailLine(i, startLine);
      startLine += 3;
    }

    const uint8Array = await this.workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    let timestamp = this.commonService.formatDateTime(new Date());
    timestamp = timestamp.replace(/\//g, "");
    timestamp = timestamp.replace(/\:/g, "");
    timestamp = timestamp.replace(" ", "_");
    a.href = url;
    a.download = "全店ステータス_" + timestamp + ".xlsx";
    a.click();
    a.remove();

    this.commonService.closeSpinnerForSubComp(this.spinnerRef);
  }

  titleLine() {
    let rowNum = 1;
    let row: ExcelJS.Row = this.worksheet.getRow(rowNum);
    let row1: ExcelJS.Row = this.worksheet.getRow(rowNum + 1);
    let row2: ExcelJS.Row = this.worksheet.getRow(rowNum + 2);

    row.height = this.rowHeight;
    row1.height = this.rowHeight;
    row2.height = this.rowHeight;

    this.worksheet.mergeCells(rowNum, 1, rowNum + 1, 5);
    this.headerCell(row.getCell(1), "タスク詳細");
    this.headerCell(row.getCell(6), "計画時間");
    this.headerCell(row1.getCell(6), "実績合計");

    let colNum = 1;
    this.headerCell(row2.getCell(colNum++), "期間");
    this.headerCell(row2.getCell(colNum++), "タスク名");
    this.headerCell(row2.getCell(colNum++), "指示者");
    this.headerCell(row2.getCell(colNum++), "優先度");
    this.headerCell(row2.getCell(colNum++), "進捗");
    this.headerCell(row2.getCell(colNum), "実績平均");
    row2.getCell(colNum++).note = "作業完了店舗の実績時間平均";

    this.headerCell(row.getCell(7), "店区分");
    colNum = 8;
    for (let i = 0; i < this.columnDefsStoreGroupHeader.length; i++) {
      let def = this.columnDefsStoreGroupHeader[i];
      this.worksheet.mergeCells(rowNum, colNum, rowNum, colNum + def.colspan - 1);
      this.headerCell(row.getCell(colNum), def.header);
      colNum += def.colspan;
    }

    this.headerCell(row1.getCell(7), "店番号");
    colNum = 8;
    for (let i = 0; i < this.columnDefsStoreCdHeader.length; i++) {
      this.headerCell(row1.getCell(colNum), this.columnDefsStoreCdHeader[i].header);
      colNum ++;
    }

    this.headerCell(row2.getCell(7), "店名");
    colNum = 8;
    for (let i = 0; i < this.columnDefsStoreNameHeader.length; i++) {
      this.headerCell(row2.getCell(colNum), this.columnDefsStoreNameHeader[i].header);
      colNum ++;
    }
  }

  headerCell(cell: ExcelJS.Cell, name: string) {
    cell.value = name;
    this.setBorder(cell);
    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    cell.fill = {
      type: "pattern",
      pattern:"solid",
      // fgColor: {argb: "D0D0D0"}
      fgColor: {argb: "609926"}
    };
    cell.font = {
      color: { argb: 'FFFFFF' },
    };
  }

  detailLine(index: number, startLine: number) {
    let listData0 = this.listData[index];
    let listData1 = this.listData[index + 1];
    let listData2 = this.listData[index + 2];
    let row0 = this.worksheet.getRow(startLine);
    let row1 = this.worksheet.getRow(startLine + 1);
    let row2 = this.worksheet.getRow(startLine + 2);
    row0.height = this.rowHeight;
    row1.height = this.rowHeight;
    row2.height = this.rowHeight;

    let colNum: number = 1;
    this.worksheet.mergeCells(startLine, colNum, startLine + 2, colNum);
    colNum++;
    this.worksheet.mergeCells(startLine, colNum, startLine + 2, colNum);
    colNum++;
    this.worksheet.mergeCells(startLine, colNum, startLine + 2, colNum);
    colNum++;
    this.worksheet.mergeCells(startLine, colNum, startLine + 2, colNum);
    colNum++;
    this.worksheet.mergeCells(startLine, colNum, startLine + 2, colNum);
    colNum++;
    /*
    this.worksheet.mergeCells(startLine, colNum, startLine + 2, colNum);
    colNum++;
    */

    colNum = 1;
    let dateRange = listData0.rowData.dateRange.replace(/\<br\>/g, "\n");
    let author = listData0.rowData.author.replace(/ : /, "\n");
    this.detailCell(row0.getCell(colNum++), dateRange, "center");
    this.detailCell(row0.getCell(colNum++), listData0.rowData.taskName, "left");
    this.detailCell(row0.getCell(colNum++), author, "left");
    this.detailCell(row0.getCell(colNum++), listData0.rowData.priority, "center");
    this.detailCell(row0.getCell(colNum++), this.convProgress(listData0.rowData.progress), "center");
    this.detailCell(row0.getCell(colNum), "" + listData0.rowData.workTime, "center");
    this.detailCell(row1.getCell(colNum), "" + listData0.rowData.workTimeResultSum, "center");
    this.detailCell(row2.getCell(colNum), "" + listData0.rowData.workTimeResultAvg, "center");
    colNum++;

    this.detailCell(row0.getCell(colNum), "担当者", "left");
    this.detailCell(row1.getCell(colNum), "作業時間", "left");
    this.detailCell(row2.getCell(colNum), "コメント", "left");
    colNum++

    for (let i = 0; i < this.columnDefsStoreCdHeader.length; i++) {
      let storeCd = this.columnDefsStoreCdHeader[i].header;
      let store = listData0.rowData.storeStatus.find((item) => item.storeCd === storeCd);
      let responsible = store.responsible;
      let workTime: number | string = store.workTimeResult;
      let comment = store.comment;
      if (store.responsible === "") {
        responsible = "未設定";
        workTime =  "";
        comment = "";
      } else if (store.responsible === "対象外") {
        workTime =  "";
        comment = "";
      } else if (!store.isComplete) {
        workTime = "未完了：" + store.workTimeResult
      }
      let colorResponsible = this.getColor(listData0, storeCd);
      let colorWorkTime = this.getColor(listData1, storeCd);
      let colorComment = this.getColor(listData2, storeCd);

      this.detailCell(row0.getCell(colNum), responsible, "center");
      this.detailCell(row1.getCell(colNum), "" + workTime, "center");
      this.detailCell(row2.getCell(colNum), comment, "center");

      this.detailFill(row0.getCell(colNum), colorResponsible);
      this.detailFill(row1.getCell(colNum), colorWorkTime);
      this.detailFill(row2.getCell(colNum), colorComment);
      colNum++
    }
  }

  convProgress(progress: string) {
    let tmp = progress.split("/");
    if (tmp.length < 2) return progress;
    return progress + "\n" + Math.round((parseInt(tmp[0].trim()) / parseInt(tmp[1].trim())) * 100) + "%";
  }

  getColor(item: ListData, storeCd: string) {
    let store = item.rowData.storeStatus.find((item) => item.storeCd === storeCd);
    if (!store) return 0;
    if (item.rowDataType == "担当者") {
      if (store.responsible === "") return 1;           // Red
      if (store.responsible === "対象外") return 2;     // Lite gray
    } else if (item.rowDataType == "作業時間") {
      if (store.responsible === "") return 1;                       // Red
      if (store.responsible === "対象外") return 2;                  // Lite gray
      if (!store.isComplete) return 3;                              // Pink
      if (item.rowData.workTime < store.workTimeResult) return 4;   // Yellow
    } else {
      if (store.responsible === "") return 1;           // Red
      if (store.responsible === "対象外") return 2;     // Lite gray
    }
    return 0;
  }

  detailCell(cell: ExcelJS.Cell, value: string, align?: "left"|"center"|"right") {
    if (!align) align = "left";
    cell.value = value;
    this.setBorder(cell);
    cell.alignment = { vertical: 'middle', horizontal: align, wrapText: true };
  }

  detailFill(cell: ExcelJS.Cell, color: number) {
    let rgbStr: string = "";
    switch (color) {
      case 1: {
        rgbStr = "F44336";
        break;
      }
      case 2: {
        rgbStr = "DBDBDB";
        break;
      }
      case 3: {
        rgbStr = "FFCDD2";
        break;
      }
      case 4: {
        rgbStr = "FFD740";
        break;
      }
    }

    cell.fill = {
      type: "pattern",
      pattern:"solid",
      fgColor: {argb: rgbStr}
    };
    if (color === 1) {
      cell.font = {
        color: { argb: 'FFFFFF' },
      };
    }
  }

  setBorder(cell: ExcelJS.Cell) {
    cell.border = {
      top:    { style: "thin", color: { argb: "000000" }},
      left:   { style: "thin", color: { argb: "000000" }},
      right:  { style: "thin", color: { argb: "000000" }},
      bottom: { style: "thin", color: { argb: "000000" }}
    }
  }

}
