import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqExecuteQuery } from 'src/app/request/req-execute-query';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { RspExecuteQuery } from 'src/app/response/rsp-execute-query';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';

interface Summary {
  storeCd: string;
  storeName: string;
  suppCd: string;
  suppName: string;
  orderCount: number;
  shortageCount: number;
  shortageRatio: number;
}

interface ListItem {
  storeCd: string;
  storeName: string;
  suppCd: string;
  suppName: string;
  ctgName1: string;
  ctgName2: string;
  ctgName3: string;
  ctgName4: string;
  itemCd: string;
  itemName: string;
  orderDate: string;
  rcvDate: string;
  orderType: string;
  orderNum: number;
  rcvNum: number;
}

@Component({
  selector: 'app-vendor-shortage',
  templateUrl: './vendor-shortage.component.html',
  styleUrls: ['./vendor-shortage.component.css']
})
export class VendorShortageComponent implements OnInit, OnDestroy {

  public stores: LoginStoreDto[];
  public storeForm: FormControl = new FormControl();
  public dateBeginForm: FormControl = new FormControl();
  public dateEndForm: FormControl = new FormControl();
  public maxDate: Date;

  public columnIdsByVendor: string[] = ["storeName", "suppName", "orderCount", "shortageCount", "shortageRatio"];
  public columnDefsByVendor: TableColumnDef[] = [
    {columnId:'storeName', header:"店舗", width:120},
    {columnId:'suppName', header:"仕入先", width:150, editable: true},
    {columnId:'orderCount', header:"発注件数", width:65, align:"right", numberPipe: "", editable: true},
    {columnId:'shortageCount', header:"欠品件数", width:65, align:"right", numberPipe: "", editable: true},
    {columnId:'shortageRatio', header:"欠品率(%)", width:65, align:"right", numberPipe: "1.2-2", editable: true}
  ];
  public columnIdsByStore: string[] = ["suppName", "storeName", "orderCount", "shortageCount", "shortageRatio"];
  public columnDefsByStore: TableColumnDef[] = [
    {columnId:'storeName', header:"店舗", width:120},
    {columnId:'suppName', header:"仕入先", width:150},
    {columnId:'orderCount', header:"発注件数", width:65, align:"right", numberPipe: "", editable: true},
    {columnId:'shortageCount', header:"欠品件数", width:65, align:"right", numberPipe: "", editable: true},
    {columnId:'shortageRatio', header:"欠品率(%)", width:65, align:"right", numberPipe: "1.2-2", editable: true}
  ];
  public columnIdsItem: string[] = ["storeName", "suppName", "itemCd", "itemName", "orderDate", "rcvDate", "orderType", "orderNum", "rcvNum"];
  public columnDefsItem: TableColumnDef[] = [
    {columnId:'storeName', header:"店舗", width:120},
    {columnId:'suppName', header:"仕入先", width:150},
    {columnId:'ctgName1', header:this.commonService.literal.ctg0Name, width:200},
    {columnId:'ctgName2', header:this.commonService.literal.ctg1Name, width:200},
    {columnId:'ctgName3', header:this.commonService.literal.ctg2Name, width:200},
    {columnId:'ctgName4', header:this.commonService.literal.ctg3Name, width:200},
    {columnId:'itemCd', header:"商品コード", width:100},
    {columnId:'itemName', header:"商品名", width:200},
    {columnId:'orderDate', header:"発注日", width:80, align:"center"},
    {columnId:'rcvDate', header:"納品日", width:80, align:"center"},
    {columnId:'orderType', header:"発注種別", width:65, align:"center"},
    {columnId:'orderNum', header:"発注数", width:65, align:"right", numberPipe: ""},
    {columnId:'rcvNum', header:"納品数", width:65, align:"right", numberPipe: ""}
  ];

  public summaryDataByVendor: Summary[];
  public summaryDataByStore: Summary[];
  public itemListData: ListItem[];

  private subscriptionStore: Subscription;
  private subscriptionDateBegin: Subscription;
  private subscriptionDateEnd: Subscription;
  
  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder
    ) { }

  ngOnInit(): void {
    // this.commonService.pageTitle = "ベンダー欠品";
    this.commonService.pageTitle = this.commonService.pageMenuName;

    if (this.commonService.stores.length > 1) {
      this.stores = [{storeCd: "*", storeName:"全店"}, ...this.commonService.stores];
      this.storeForm.setValue("*");
    } else {
      this.stores = [...this.commonService.stores];
      this.storeForm.setValue(this.commonService.loginUser.storeCd);
    }

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 1);

    this.dateEndForm.setValue(this.commonService.copyDate(this.maxDate));
    let begin = new Date();
    begin.setDate(this.maxDate.getDate() - 7 * 4);
    this.dateBeginForm.setValue(begin);

    this.subscriptionStore = this.storeForm.valueChanges.subscribe(
      () => {this.clearData();}
    );

    this.columnIdsItem.push("ctgName1");
    if (this.commonService.config.maxCtgLevel > 1) this.columnIdsItem.push("ctgName2");
    if (this.commonService.config.maxCtgLevel > 2) this.columnIdsItem.push("ctgName3");
    if (this.commonService.config.maxCtgLevel > 3) this.columnIdsItem.push("ctgName4");
  }

  ngOnDestroy() {
    if (this.subscriptionStore) this.subscriptionStore.unsubscribe();
    if (this.subscriptionDateBegin) this.subscriptionDateBegin.unsubscribe();
    if (this.subscriptionDateEnd) this.subscriptionDateEnd.unsubscribe();
  }

  getDateBeginMax() {
    let max: Date;
    (this.dateEndForm.value)
      ? max = this.commonService.copyDate(this.dateEndForm.value)
      : max = this.commonService.copyDate(this.maxDate);

    max.setDate(max.getDate() - 1);
    return max;
  }

  getDateEndMax() {
    let max: Date;
    (this.dateEndForm.value)
      ? max = this.commonService.copyDate(this.dateEndForm.value)
      : max = this.commonService.copyDate(this.maxDate);

    max.setDate(max.getDate() - 1);
    return max;
  }

  getDateEndMin() {
    let min: Date = this.commonService.copyDate(this.dateBeginForm.value);

    min.setDate(min.getDate() + 1);
    return min;
  }

  dateChange() {
    this.clearData();
  }

  disableQuery() {
    if (this.dateBeginForm.value == undefined) return true;
    if (this.dateEndForm.value == undefined) return true;
    return false;
  }

  styleForByVendor(id:string) {
    return this.styleFor(id, this.columnDefsByVendor);
  }
  styleForHeaderByVendor(id:string) {
    return this.styleForHeader(id, this.columnDefsByVendor);
  }

  styleForByStore(id:string) {
    return this.styleFor(id, this.columnDefsByStore);
  }
  styleForHeaderByStore(id:string) {
    return this.styleForHeader(id, this.columnDefsByStore);
  }

  styleForItem(id:string) {
    return this.styleFor(id, this.columnDefsItem);
  }
  styleForHeaderItem(id:string) {
    return this.styleForHeader(id, this.columnDefsItem);
  }

  styleFor(id:string, columnDefs:TableColumnDef[]) {
    for (var colDef of columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeader(id:string, columnDefs:TableColumnDef[]) {
    for (var colDef of columnDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  clickByVendor(colDef: TableColumnDef, item: Summary) {
    if (colDef.columnId == "suppName") {
      this.queryByStore(item);
      return;
    }
    if (colDef.columnId == "orderCount" || colDef.columnId == "shortageCount" || colDef.columnId == "shortageRatio") {
      this.queryItemList(item);
    }
  }

  clickByStore(colDef: TableColumnDef, item: Summary) {
    if (colDef.columnId == "orderCount" || colDef.columnId == "shortageCount" || colDef.columnId == "shortageRatio") {
      this.queryItemList(item);
    }
  }

  clearData() {
    this.summaryDataByVendor = undefined;
    this.summaryDataByStore = undefined;
    this.itemListData = undefined;
  }

  queryByVendor() {
    let request: ReqExecuteQuery = {
      access: {...this.commonService.loginUser},
      queryId: "vendorShortage/summary",
      bindVariables: [
        this.storeForm.value,
        "",
        this.commonService.formatDate(this.dateBeginForm.value),
        this.commonService.formatDate(this.dateEndForm.value)
      ]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    const subsc = this.httpBasic.executeQuery(request).subscribe(
      (response: RspExecuteQuery) => {
        subsc.unsubscribe();
        this.receiveByVendor(response);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveByVendor(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.summaryDataByVendor = [];
    for (let row of response.rows) {
      let orderCount = parseInt(row.colData[4]);
      let shortageCount = parseInt(row.colData[5]);
      let shortageRatio = orderCount > 0 ? shortageCount * 100 / orderCount : 0;
      let summary: Summary = {
        storeCd: row.colData[0],
        storeName: row.colData[1],
        suppCd: row.colData[2],
        suppName: row.colData[3],
        orderCount: orderCount,
        shortageCount: shortageCount,
        shortageRatio: shortageRatio
      };
      this.summaryDataByVendor.push(summary);
    }
  }

  queryByStore(item: Summary) {
    let request: ReqExecuteQuery = {
      access: {...this.commonService.loginUser},
      queryId: "vendorShortage/summary",
      bindVariables: [
        (this.commonService.stores.length > 1) ? "" : this.commonService.loginUser.storeCd,
        item.suppCd,
        this.commonService.formatDate(this.dateBeginForm.value),
        this.commonService.formatDate(this.dateEndForm.value)
      ]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    const subsc = this.httpBasic.executeQuery(request).subscribe(
      (response: RspExecuteQuery) => {
        subsc.unsubscribe();
        this.receiveByStore(response);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveByStore(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.summaryDataByStore = [];
    for (let row of response.rows) {
      let orderCount = parseInt(row.colData[4]);
      let shortageCount = parseInt(row.colData[5]);
      let shortageRatio = orderCount > 0 ? shortageCount * 100 / orderCount : 0;
      let summary: Summary = {
        storeCd: row.colData[0],
        storeName: row.colData[1],
        suppCd: row.colData[2],
        suppName: row.colData[3],
        orderCount: orderCount,
        shortageCount: shortageCount,
        shortageRatio: shortageRatio
      };
      this.summaryDataByStore.push(summary);
    }
  }

  queryItemList(item: Summary) {
    let request: ReqExecuteQuery = {
      access: {...this.commonService.loginUser},
      queryId: "vendorShortage/itemList",
      bindVariables: [
        item.storeCd,
        item.suppCd,
        this.commonService.formatDate(this.dateBeginForm.value),
        this.commonService.formatDate(this.dateEndForm.value)
      ]
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    const subsc = this.httpBasic.executeQuery(request).subscribe(
      (response: RspExecuteQuery) => {
        subsc.unsubscribe();
        this.receiveItemList(response);
      },
      error => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  receiveItemList(response: RspExecuteQuery) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;

    this.itemListData = [];
    for (let row of response.rows) {
      let item: ListItem = {
        storeCd: row.colData[0],
        storeName: row.colData[1],
        suppCd: row.colData[2],
        suppName: row.colData[3],
        ctgName1: row.colData[4],
        ctgName2: row.colData[5],
        ctgName3: row.colData[6],
        ctgName4: row.colData[7],
        itemCd: row.colData[8],
        itemName: row.colData[9],
        orderDate:row.colData[10],
        rcvDate:row.colData[11],
        orderType: row.colData[12],
        orderNum: parseInt(row.colData[13]),
        rcvNum: parseInt(row.colData[14])
      };
      this.itemListData.push(item);
    }
  }
}
